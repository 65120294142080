/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './styles.scss';
import Styled from 'styled-components';
import { Container, Col } from 'reactstrap';
import * as R from 'ramda';
import { withBlanketCtxt } from '../../../Contexts/BlanketContext/index';
// eslint-disable-next-line import/no-cycle
import { WrapperWizard } from '../index';

const sm = `
  width: 60vw !important;
  height: inherit  !important;`;

const xl = `
  width: 100vw !important;
  height: 100vw  !important;`;

const Main = Styled(Container)`
${(props) => props.size === 'sm' && sm}
${(props) => props.size === 'xl' && xl}
`;
const Title = Styled.div``;
const Body = Styled.div`
  justify-content: ${(props) => props.verticalAlign || 'center'}!important;
`;
const Footer = Styled.div``;

class Modal extends React.Component {
    ClickOutRef = null;

    componentDidUpdate(prevProps) {
      const { handleRefreshBlanketVisibility, children, entity } = this.props;
      if (prevProps && children) {
        const prevPropsSpecial = this.buildObjectToCompare(prevProps.children);
        const propsSpecial = this.buildObjectToCompare(children);
        if (prevPropsSpecial.count !== propsSpecial.count
          || !R.equals(prevPropsSpecial.indexes, propsSpecial.indexes)
          || prevProps.entity !== entity) {
          handleRefreshBlanketVisibility(this.renderedContent);
        }
      }
    }

    buildObjectToCompare = (data) => {
      const object = { count: 0, indexes: [] };
      data.forEach((o, index) => {
        if (!o) {
          object.count += 1;
          object.indexes.push(index);
        }
      });
      return object;
    };

    showModal = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { handleBlanketVisibility } = this.props;

      handleBlanketVisibility(true, this.renderedContent);
    };

    hideModal = () => {
      const { handleBlanketVisibility } = this.props;
      handleBlanketVisibility(false);
    };

    handleLister = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());

      if (!path.includes(this.ClickOutRef)) {
        window.removeEventListener('click', this.handleLister, true);
      }
    };

    renderedContent = () => {
      const {
        className,
        size,
        ModalTitle,
        ModalButtons,
        ModalFooter,
        verticalAlign,
        children,
        entity,
        newEntity,
        metadata,
      } = this.props;
      return (
        <div id="modal" className={className}>
          <Main fluid size={size} ref={(node) => { this.ClickOutRef = node; }}>
            {(ModalTitle !== undefined) && (
            <Title className="titulo">
              <Col><ModalTitle {...this.props} /></Col>
            </Title>
            )}
            {
              <Body className="body" verticalAlign={verticalAlign}>
                <div className="d-flex justify-content-center align-items-center">
                  <WrapperWizard
                // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                    newEntity={newEntity}
                    entity={entity}
                    metadata={metadata}
                    hideModal={this.hideModal}
                  >
                    { children.filter((o) => !!o) }
                  </WrapperWizard>
                  {/* <ModalContent {...this.props} hideModal={this.hideModal} /> */}
                </div>
              </Body>
            }
            {(ModalButtons !== undefined) && (
            <ModalButtons
              {...this.props}
              showModal={this.showModal}
              hideModal={this.hideModal}
            />
            )}
            {(ModalFooter !== undefined) && (
            <Footer className="footer">
              <Col><ModalFooter {...this.props} /></Col>
            </Footer>
            )}

          </Main>
        </div>
      );
    };

    render() {
      const { ButtonOpen, customButton } = this.props;
      return (
        <>
          <ButtonOpen showModal={this.showModal} customButton={customButton} {...this.props} />
        </>
      );
    }
}

Modal.defaultProps = {
  show: true,
  className: '',
  size: '',
  verticalAlign: undefined,
  customButton: undefined,
};

export default withBlanketCtxt(Modal);
