import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import ruleActions, { getGeneralDataForAction } from '../../../../../data/ruleActions';

const ActionsGrid = ({ alertActionPresent, selectedAction, onChange}) => {
  const getClassName = (actionName) => (selectedAction === actionName ? ' selected' : '');
  const getIcon = (actionName) => getGeneralDataForAction(actionName).icon;
  const ruleActionsCopy = [...ruleActions];

  if (alertActionPresent && ruleActionsCopy.length === 5) {
    ruleActionsCopy.splice(3, 1);
  }

  return (
    <div className="actionsGrid">
      {ruleActionsCopy.map((action) => (
        <div
          className={`action${getClassName(action.type)}`}
          key={action.type}
          onClick={() => onChange(action.type)}
          onKeyUp={() => onChange(action.type)}
          role="link"
          tabIndex={-1}
        >
          <div className="icon"><img src={getIcon(action.type)} alt={action.type} /></div>
          <div className="text">
            <FormattedMessage
              id={`rules.wizard.actionsGrid.${action.type}.title`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

ActionsGrid.propTypes = {
  alertActionPresent: PropTypes.bool,
  selectedAction: PropTypes.string,
  onChange: PropTypes.func,
};

ActionsGrid.defaultProps = {
  alertActionPresent: false,
  selectedAction: undefined,
  onChange: () => {},
};

export default ActionsGrid;
