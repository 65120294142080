const getBreakpoint = () => {
  const innerWidth = window.innerWidth;

  if (innerWidth <  576 )        return 'xs';
  if (innerWidth >= 1200)        return 'xl';
  if (innerWidth >= 992 )        return 'lg';
  if (innerWidth >= 768 )        return 'md';
  if (innerWidth >= 576 )        return 'sm';
};

const isMobile = () => ['xs', 'sm'].includes(getBreakpoint());

const isDesktop = () => ['md', 'lg'].includes(getBreakpoint());

const isLargerDevice = () => ['xl'].includes(getBreakpoint());


export {isMobile, isDesktop, isLargerDevice};
export default getBreakpoint;
