import React from 'react';
import './styles.scss';
import NestedIcon from '../../configuration/icons/svg/nested-rule.svg';
import TimeIcon from '../../configuration/icons/svg/timewindow-ico.svg';

const getIcon = name => {
  switch (name) {
    case 'plus':
      return 'plus';
    case 'minus':
      return 'minus';
    case 'nest':
      return 'nest';
    case 'send':
      return 'message';
    case 'hourglass':
      return 'hourglass';
    default:
      return 'ellipsis-v';
  }
};

const CircularButton = props => (
  <div className={`circularButton ${props.disabled ? 'disabled' : 'enabled'} ${props.className ? props.className : ""}`} onClick={!props.disabled ? props.onClick : undefined}>
    {getIcon(props.name) === 'nest' ? (
      <img src={NestedIcon} alt="nested rule" className="nestedIcon" />
    ) : getIcon(props.name) === 'hourglass' ? (
      <img src={TimeIcon} alt="time event" className="nestedIcon" />
    ) : (
      <i className={`uil uil-${getIcon(props.name)}`} />
    )}
  </div>
);

export default CircularButton;
