import React from 'react';
import { clone } from 'ramda';
import WidgetTypeV2 from './steps/WidgetTypeV2';
import Success from './steps/Success';
import { getDefaultMeasure } from '../../Dashboards/WidgetDefaultMeasures';
import Wizard from '../../../components/Wizard';
import { FormattedMessage } from '../../../Contexts/LanguageContext';

// steps
import BasicInfo from './steps/basicInfo';
import AssignPermissions from '../../helpers/AssignPermissions';
import { isMobile } from '../../../helpers/getBreakpoint';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';
import CreateWidgteIcon from '../../../configuration/icons/svg/ico-widget.svg';
import WizardStep from '../../../components/Wizard/components/WizardStep';
import Dashboard from '../../../models/Dashboard/index';
import Widget from '../../../models/WidgetV2';
import ButtonOutline from '../../../elements/ButtonOutline';
import { getWidgetModel } from '../../../models/WidgetV2/utils';
import widgets from '../../../models/WidgetV2/inherited/index';
import './styles.scss';

const ButtonOpen = ({ showModal, customButton }) => {
  if (customButton) {
    const {
      disabled, label, bgwhite, ico,
    } = customButton;
    return (
      <ButtonOutline
        onClick={showModal}
        disabled={disabled}
        label={label}
        bgwhite={bgwhite}
        ico={ico}
      />
    );
  }
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/rules"
        permission="create"
      >
        <div className="divAddWidgetButtonClass">
          <div
            onClick={showModal}
            className="addWidgetButtonClass"
            role="presentation"
          >
            <div className="buttonTex">
              <div>
                <i className="uil uil-plus" />
              </div>
              <div>
                <FormattedMessage id="dashboard.button.addWidget" />
              </div>
            </div>
          </div>
        </div>
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/rules"
      permission="create"
    >
      <div className="divAddWidgetButtonClass">
        <div
          onClick={showModal}
          className="addWidgetButtonClass"
          role="presentation"
        >
          <div className="buttonTex">
            <div>
              <i className="uil uil-plus" />
            </div>
            <div>
              <FormattedMessage id="dashboard.button.addWidget" />
            </div>
          </div>
        </div>
      </div>
    </PrivateComponent>
  );
};

class CreateWidgets extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      widgetType: null,
      widget: Widget,
    };
  }

  setWidgetType = (type) => {
    this.setState({ widgetType: type, widget: this.getWidgetClass(type) });
  };

  updateDashboard = (widget) => {
    const { dashboard } = this.props;
    const clonedDashboard = clone(dashboard);
    const dashboardToUpdate = new Dashboard(clonedDashboard);

    if (!dashboardToUpdate.layoutProperties) {
      dashboardToUpdate.layoutProperties = {
        lg: [], md: [], sm: [], xs: [], xxs: [],
      };
    }

    const defaultMeasures = getDefaultMeasure(widget, dashboardToUpdate.layoutProperties);

    dashboardToUpdate.layoutProperties.lg.push(defaultMeasures.lg);
    dashboardToUpdate.layoutProperties.md.push(defaultMeasures.md);
    dashboardToUpdate.layoutProperties.sm.push(defaultMeasures.sm);
    dashboardToUpdate.layoutProperties.xs.push(defaultMeasures.xs);
    dashboardToUpdate.layoutProperties.xxs.push(defaultMeasures.xxs);

    dashboardToUpdate.widgets = dashboardToUpdate.widgets.map((o) => o.id);
    dashboardToUpdate.widgets.push(widget.id);
    dashboardToUpdate.update();
  };

  handleOnSave = (state, _, onContinue) => {
    const newWidget = clone(state.newEntity);
    const widgetToSave = getWidgetModel(newWidget);
    widgetToSave.injectUrnAndFields(newWidget.sources);
    widgetToSave.save(this.updateDashboard);
    onContinue();
  }

  getWidgetClass = (type) => (Object.values(widgets).find((value) => value.widgetType === type));

  render() {
    const { customButton } = this.props;
    const { widgetType, widget } = this.state;

    return (
      <Wizard
        buttonOpen={ButtonOpen}
        customButton={customButton}
        entity={widget}
        onSave={this.handleOnSave}
        metadata={{
          setWidgetType: this.setWidgetType,
          wizardType: 'widgets',
        }}
      >
        <WizardStep
          component={BasicInfo}
          title={<FormattedMessage id="widgets.wizard.stepBasicInfo.title" />}
          lead={<FormattedMessage id="widgets.wizard.stepBasicInfo.text" />}
          icon={CreateWidgteIcon}
        />

        <WizardStep
          component={WidgetTypeV2}
          title={<FormattedMessage id="widgets.wizard.stepWidgetType.title" />}
          icon={CreateWidgteIcon}
          lead={<FormattedMessage id="widgets.wizard.stepWidgetType.text" />}
          goToStep={(step) => step}
          extraActionOnStepChange={this.setWidgetType}
        />

        {widget && widget.wizardSteps && Object.entries(widget.wizardSteps).map((step) => (
          <WizardStep
            key={step[0]}
            component={step[1]}
            title={<FormattedMessage id={`widgets.wizard.step${step[0]}.title`} />}
            lead={<FormattedMessage id={`widgets.wizard.step${step[0]}.text`} />}
            customIcon
            blackTitle
          />
        ))}

        {widgetType && (
          <WizardStep
            component={AssignPermissions}
            title={<FormattedMessage id="widgets.wizard.stepAssignPermissions.title" />}
            lead={<FormattedMessage id="widgets.wizard.stepAssignPermissions.text" />}
            customIcon
            blackTitle
            onSave={this.handleOnSave}
          />
        )}

        <Success />
      </Wizard>
    );
  }
}

export default CreateWidgets;
