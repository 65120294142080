/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { updateWidget } from '../../../services/redux/widgets/actions';
import EditPermissions from '../../helpers/Profile/EditPermissions';
import Widget from '../../../models/Widget';
import EditConfigurationWidgetType from './components/EditConfigurationWidgetType';
import EditLinkedWidgets from './components/EditLinkedWidgets';
import WidgetInfo from './components/WidgetInfo';
import { havePermissionToEdit } from '../../../helpers/utils';
import LinkedWidgetInfo from './newcomponents/LinkedWidgetInfo';
import { getWidgetModel } from '../../../models/WidgetV2/utils';

export class Profile extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data: { ...data },
      // eslint-disable-next-line react/no-unused-state
      selectedOption: null,
      isLinked: this.isLinked(),
    };
  }

  componentDidMount() {
    const { data } = this.props;
    havePermissionToEdit(data.permissions_policy, data.owner)
      .then((permissionToEdit) => this.setState({ permissionToEdit }));
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data: { ...data } });
    }
  }

  handleOnSave = (id, data) => {
    const { data: stateData } = this.state;
    if (stateData[id] !== data) {
      const newData = getWidgetModel({ ...stateData, [id]: data });
      updateWidget(newData.getData());
      this.setState({ data: newData });
    }
  };

  handleSelectChange = (id, value) => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      selectedOption: value,
    });
  };

  ifDescription = () => {
    const { data: { widgetType } } = this.props;
    const widgetsWithoutDescription = ['IMAGE'];
    return !widgetsWithoutDescription.includes(widgetType);
  }

  isLinked = () => {
    const { data } = this.props;
    const { widgetType } = data;
    return widgetType === 'LINKED';
  }

  getParentWidgetType = (widget) => {
    const { getParentWidget } = this.props;
    return getParentWidget(widget)?.widgetType ?? null;
  }

  getTitles = () => {
    const { isLinked } = this.state;
    const titles = [
      <FormattedMessage id="widgets.profile.config" />,
      <FormattedMessage id="widgets.profile.info" />,
      <FormattedMessage id="widgets.profile.permissions" />,
    ];
    const editLinkedWidgetsTitle = <FormattedMessage id="widgets.profile.editLinkedWidgets" />;
    if (isLinked) titles.splice(1, 0, editLinkedWidgetsTitle);
    return titles;
  }

  render() {
    const { permissionToEdit, isLinked } = this.state;
    const {
      data, selection, widgetList, profileType,
    } = this.props;
    return (
      <>
        {profileType === 'edit' && (
          <ProfileComponent
            data={data}
            titles={this.getTitles()}
            save={this.handleOnSave}
            editable={permissionToEdit}
            withDescription={this.ifDescription()}
          >
            <EditConfigurationWidgetType
              data={data}
              entity={Widget}
              getParentWidgetType={this.getParentWidgetType}
              isLinked={isLinked}
            />
            {(isLinked) && <EditLinkedWidgets />}
            {(isLinked)
              ? <LinkedWidgetInfo data={data} entity={Widget} />
              : (
                <WidgetInfo
                  data={data}
                  isLinked={isLinked}
                  entity={Widget}
                  selection={selection}
                  widgetList={widgetList}
                />
              )}
            <EditPermissions permissionToEdit={permissionToEdit} data={data} entity={Widget} />
          </ProfileComponent>
        )}
        {profileType === 'share' && (
          <ProfileComponent
            data={data}
            titles={[
              <FormattedMessage id="widgets.profile.permissions" />,
            ]}
            save={this.handleOnSave}
          >
            <EditPermissions permissionToEdit={permissionToEdit} data={data} entity={Widget} />
          </ProfileComponent>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  widgets: state.get('widgets').get('list').toJS(),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
