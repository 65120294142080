/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import React, {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { StaticMap } from 'react-map-gl';
import { clone } from 'ramda';
import { connect } from 'react-redux';
import DeckGL from '@deck.gl/react';
import { MapView } from '@deck.gl/core';
import { IconLayer, ScatterplotLayer, PathLayer } from '@deck.gl/layers';
import { SelectionLayer } from '@nebula.gl/layers';
import debounce from 'just-debounce-it';
import * as R from 'ramda';
import Header from './components/Header';
import WrapperDiv from './components/WrapperMap';
import IconAtlas from './data/location-icon-atlas.png';
import IconMapping from './data/location-icon-mapping.json';
import { mapStyles } from './helpers/constants';
import Legend from './components/Legend';
import ToggleScroll from './components/ToggleScroll';
import AutoZoom from './components/AutoZoom';
import ZoomIn from './components/ZoomIn';
import ZoomOut from './components/ZoomOut';
import FullScreen from './components/FullScreen';
import BoardHistorical from './components/BoardHistorical';
import { getWidgetModel } from '../../models/WidgetV2/utils';
import { FormattedMessage } from '../../Contexts/LanguageContext';
import './styles.scss';
import {
  ActionsWrapper, calculateInitialViewState, getColorCategory, getFilterDateBySampling,
  getHistorical,
} from './util';

const MAPBOX_TOKEN = 'pk.eyJ1IjoianVhbmFuOTEiLCJhIjoiY2pvc2ZvZ2pnMDQzczNrcWZjeHcxYWhmYiJ9.MCr17mXwiNhzw37MucyfbQ'; // Set your mapbox token here

// Source data CSV
// const DATA_URL = 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/icon/meteorites.json'; // Set your mapbox token here

export const SATELLITE = 'satellital';

const DATA_TYPE_HISTORICAL = 'historical';
const DATA_TYPE_LAST_VALUE = 'last-value';

const WidgetMapUber = ({ allHistorical, ...props }) => {
  const {
    data: _data,
    config: _config,
    title,
    actions: Actions,
    widgetType,
    legendPosition,
    widget,
    hasBeenResized,
    updateWidget,
    isLinked,
    selectedItems = [],
    drawableSelection = false,
    onSelect,
  } = props;

  // * --------------------- [STATE] --------------------- * //

  const [initialViewState, setInitialViewState] = useState(calculateInitialViewState(_data));
  const [x, setX] = useState(false);
  const [y, setY] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [expandedObjects, setExpandedObjects] = useState(false);
  const [hoveredObject, setHoveredObject] = useState(false);
  const [object, setObject] = useState(null);
  const [filterDate, setFilterDate] = useState({
    startDate: getFilterDateBySampling('', 0, true),
    endDate: getFilterDateBySampling('', 48, true),
  });
  const [isSetted, setIsSetted] = useState(false);
  const [firstTime, setFirsTime] = useState(true);

  const data = widgetType === 'HEATMAP' ? _data.data : _data;

  const [config, setConfig] = useState(_config);
  const [newWidget, setNewWidget] = useState(widget);
  const [devices] = useState(data);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [deckRef, setDeck] = useState(null);
  const [mapStyle, setMapStyle] = useState(mapStyles[config.custom.MAP.viewType ?? 'default']);
  const [scroll, setScroll] = useState(true);
  const [updateW, setUpdateW] = useState(false);

  // * --------------------- [STATE] --------------------- * //

  const {
    labels, appearance, conditions = {}, hideLayer,
  } = config;
  const { type } = config.data;
  const { alias, categoryAlias } = labels;
  const { hidden } = appearance;
  const { categoryColors = {} } = conditions;

  // * --------------------- [LIFECYCLE EFFECTS] --------------------- * //

  useEffect(() => {
    if (firstTime) {
      setFirsTime(false);
      const localWidget = getWidgetModel(widget);
      getHistorical(localWidget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTime]);

  useEffect(() => {
    setMapStyle(mapStyles[_config.custom.MAP.viewType ?? 'default']);
    setConfig(_config);
  }, [_config]);

  useEffect(() => {
    if (!isSetted && _data.length) {
      setInitialViewState(calculateInitialViewState(_data));
      setIsSetted(true);
    }
  }, [isSetted, _config, _data]);

  // * --------------------- [LIFECYCLE EFFECTS] --------------------- * //

  // * --------------------- [AUXILIARY FUNCS] --------------------- * //

  const ZOOM_INCREMENT_VALUE = 0.5;
  const ZOOM_MIN_VALUE = 1;
  const ZOOM_MAX_VALUE = 20;

  const zoomControl = (params) => {
    const newInitialViewState = R.clone(initialViewState);
    newInitialViewState.zoom += params;
    newInitialViewState.zoom = newInitialViewState.zoom < ZOOM_MIN_VALUE
      ? ZOOM_MIN_VALUE
      : newInitialViewState.zoom;
    newInitialViewState.zoom = newInitialViewState.zoom > ZOOM_MAX_VALUE
      ? ZOOM_MAX_VALUE
      : newInitialViewState.zoom;
    setInitialViewState(newInitialViewState);
  };

  const autoZoom = () => {
    setInitialViewState(calculateInitialViewState(_data));
  };

  const fn1 = debounce(() => false, 1000);

  const dataComparator = () => fn1();

  const renderhoveredItems = () => {
    if (expandedObjects) {
      return (
        <div className="tooltip interactive" style={{ left: x, top: y }}>
          {expandedObjects.map(({
            name, year, mass, class: meteorClass,
          }) => (
            <div key={`${name}${year}`}>
              <h5>{name}</h5>
              <div>
                Year:
                {year || 'unknown'}
              </div>
              <div>
                Class:
                {meteorClass}
              </div>
              <div>
                Mass:
                {mass}
                g
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (!hoveredObject) {
      return null;
    }

    return hoveredObject.cluster ? (
      <div className="tooltip" style={{ left: x, top: y }}>
        <h5>
          {hoveredObject.point_count}
          {' '}
          records
        </h5>
      </div>
    ) : (
      <div className="tooltip" style={{ left: x, top: y }}>
        <h5>
          {hoveredObject.name}
          {' '}
          {hoveredObject.year ? `(${hoveredObject.year})` : ''}
        </h5>
      </div>
    );
  };

  const findHistoryInRedux = (index) => (
    index === -1 ? null : allHistorical[index].data.historicals
  );

  const updateHideLayer = (list) => {
    const widgetVar = clone(props.widget);
    widgetVar.config.hideLayer = list;
    setConfig(widgetVar.config);
    setNewWidget(widgetVar);
    setUpdateW(true);
  };

  const findDeviceIndexInReduxHistorical = (dvc) => (
    allHistorical?.findIndex((h) => (h.data.historicals?.[0]?.entityId === dvc.data.entityId
    || h.data.historicals?.[0]?.entityId === dvc.data.id)));

  const filterRangeDate = (o) => {
    if (!o.TimeInstant) return false;
    if (filterDate.startDate && filterDate.endDate) {
      return o.TimeInstant >= filterDate.startDate && filterDate.endDate >= o.TimeInstant;
    }
    if (filterDate.startDate) {
      return o.TimeInstant >= filterDate.startDate;
    }
    if (filterDate.endDate) {
      return filterDate.endDate >= o.TimeInstant;
    }
    return true;
  };

  const changeDataType = () => {
    const currentWidget = getWidgetModel(widget);
    currentWidget.config.data.type = (
      config.data.type === DATA_TYPE_HISTORICAL ? DATA_TYPE_LAST_VALUE : DATA_TYPE_HISTORICAL);
    getHistorical(currentWidget);
    updateWidget(currentWidget);
  };

  const changeRange = (value) => {
    setFilterDate({
      startDate: getFilterDateBySampling('', value[0], true),
      endDate: getFilterDateBySampling('', value[1], true),
    });
  };

  // * --------------------- [AUXILIARY FUNCS] --------------------- * //

  const MAP_VIEW = new MapView({ repeat: true });

  // * --------------------- [EVENT HANDLING] --------------------- * //

  const fullScreen = () => {
    const { mapRef } = props;
    const toFullScreen = isLinked
      ? mapRef.current
      // eslint-disable-next-line no-underscore-dangle
      : deckRef._containerRef.current;

    if (toFullScreen.requestFullscreen) {
      toFullScreen.requestFullscreen();
    } else if (toFullScreen.webkitRequestFullscreen) { /* Safari */
      toFullScreen.webkitRequestFullscreen();
    } else if (toFullScreen.msRequestFullscreen) { /* IE11 */
      toFullScreen.msRequestFullscreen();
    }
  };

  const handleChangeDraw = () => {
    setIsDrawing((old) => !old);
  };

  const closePopup = () => {
    const { onItemClick } = props;
    if (expandedObjects) {
      setHoveredObject(null);
      setExpandedObjects(null);
    }
    if (object) {
      if (onItemClick) onItemClick({ ...object, selected: false });
      setObject(null);
    }
  };

  const toggleScroll = () => {
    setScroll(!scroll);
  };

  const onClick = (info) => {
    const { showCluster = true, onItemClick } = props;
    if (info.object && showCluster) {
      setX(info.x);
      setY(info.y);
      setExpandedObjects(info.objects || [info.object]);
      setObject(info.object);
      if (onItemClick) onItemClick({ ...info.object, selected: true });
    } else if (isLinked) {
      if (onItemClick) onItemClick({});
    } else {
      closePopup();
    }
  };

  const onUpdateWidget = () => {
    setUpdateW(false);
    updateWidget(newWidget);
  };

  if (updateW) {
    onUpdateWidget();
  }

  // * --------------------- [EVENT HANDLING] --------------------- * //

  const Popup = () => {
    const hiddens = appearance.hidden || {};
    const deviceHiddens = hiddens[object.id] || [];
    if (object && object.attributes) {
      return (
        <div className="popupContainer">
          <div className="headerPopup">
            <h1>
              {alias[object.id] && alias[object.id].name
                ? alias[object.id].name : object.device_name}
            </h1>
            <h2>{object.device_id}</h2>
          </div>
          <div className="bodyPopup">
            {object.attributes.filter((att) => !deviceHiddens.includes(att)).map((att) => (
              <div className="att">
                <h2>
                  {alias[object.id]?.atributes[att] ?? att}
                </h2>
                <h3>{object.data[att]}</h3>
              </div>
            ))}

          </div>
        </div>
      );
    }
    return null;
  };

  // * --------------------- [MAP LAYERS] --------------------- * //

  const renderLayers = useMemo(() => {
    const { iconCategory } = appearance;
    const layers = [];

    // generate id
    if (categoryColors) {
      Object.entries(categoryColors).map((_cat) => {
        const cat = { ..._cat };
        data.forEach((d) => {
          if (d.categories === cat[0]) {
            if (!cat[1].id) {
              cat[1].id = [];
              cat[1].id.push(d.id);
            } else if (cat[1].id.indexOf(d.id) === -1) {
              cat[1].id.push(d.id);
            }
          }
        });
        return cat;
      });
    }
    // show/hide layer
    const dataLayer = [];

    if (config?.hideLayer && config.hideLayer[0] !== undefined) {
      data.forEach((d) => {
        if (config.hideLayer.indexOf(d.id) === -1) {
          dataLayer.push(d);
        }
      });
    } else {
      config.hideLayer = [];
      data.forEach((d) => {
        dataLayer.push(d);
      });
    }

    const icons = new IconLayer({
      id: 'markers',
      data: dataLayer,
      pickable: false,
      iconAtlas: IconAtlas,
      iconMapping: IconMapping,
      sizeScale: 70,
      // mask:true,
      getPosition: (d) => [parseFloat(d.location.y), parseFloat(d.location.x), 0],
      radiusScale: 200,
      // outline: false,
      dataComparator,
      getIcon: (d) => d.categories,
      getAngle: (d) => 45 + (d.true_track * 180) / Math.PI,
      getColor: () => [0, 0, 0],
    });

    const plots = new ScatterplotLayer({
      data: dataLayer,
      id: 'plots',
      opacity: 0.8,
      sizeScale: 60,
      radiusScale: 200,
      radiusMinPixels: 30,
      radiusMaxPixels: 30,
      lineWidthMinPixels: 10,
      filled: true,
      pickable: true,
      stroked: true,
      onClick: (d) => onClick(d),
      getPosition: (d) => [parseFloat(d.location.y), parseFloat(d.location.x)],
      getFillColor: (d) => (categoryColors ? getColorCategory(categoryColors[d.categories], d)
        : getColorCategory(undefined, d)),
      getLineColor: (d) => {
        const isSelected = selectedItems.some((i) => i === d.id);
        const categoriesColor = getColorCategory(categoryColors?.[d.categories], d);
        return isSelected ? [128, 128, 128] : categoriesColor;
      },
    });
    if (config?.data?.type === 'historical') {
      const dataPath = [];

      devices.forEach((deviceData) => {
        const { locationName } = deviceData;
        let history = findHistoryInRedux(findDeviceIndexInReduxHistorical(deviceData));
        if (history) {
          history = history.filter(filterRangeDate);

          history = history.map((h) => (h[locationName] ? h[locationName].split(',').map((dt) => parseFloat(dt)).reverse() : undefined))
            .filter((o) => o !== undefined);

          const dpath = {
            name: deviceData.id,
            color: getColorCategory(
              categoryColors ? categoryColors[deviceData.categories] : undefined,
              deviceData,
            ),
            path: history,
          };
          dataPath.push(dpath);
        }
      });

      const tLayer = new PathLayer({
        id: 'line-layer',
        data: dataPath,
        getWidth: () => 5,
        getColor: (d) => d.color,
        widthMinPixels: 5,
      });
      layers.push(tLayer);
    }

    layers.push(plots);
    if (iconCategory) {
      layers.push(icons);
    }
    if (drawableSelection && isDrawing) {
      layers.push(new SelectionLayer({
        id: 'selection',
        selectionType: 'polygon',
        onSelect: ({ pickingInfos }) => {
          const ids = pickingInfos.map((p) => ({ id: p.object.id }));
          setSelectedDevices(ids);
          onSelect(ids);
          setIsDrawing(false);
        },
        layerIds: ['plots'],
        getTentativeFillColor: () => [87, 126, 232, 100],
        getTentativeLineColor: () => [87, 126, 232, 255],
        getTentativeLineDashArray: () => [0, 0],
        getColor: () => [87, 126, 232, 255],
        pointRadiusScale: 0,
        lineWidthMinPixels: 1,
      }));
    }
    return layers;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawing, selectedItems]);

  // * --------------------- [MAP LAYERS] --------------------- * //

  return (
    <WrapperDiv isLinked={isLinked}>
      {title && (
        <Header>
          {title}
          {' '}
          {Actions && (
            <ActionsWrapper>
              <Actions selectedDevices={selectedDevices} />
            </ActionsWrapper>
          )}
          {' '}
        </Header>
      )}
      {legendPosition === 'top'
        && (
          <div />
        )}
      <Legend
        updateHideLayer={updateHideLayer}
        hideList={hideLayer || []}
        categoryColors={categoryColors}
        categoryAlias={categoryAlias}
        data={data}
        isLinked={isLinked}
      />

      {isLinked && drawableSelection && isDrawing && showTitle && (
        <div className="multiselection-title">
          <FormattedMessage id="widgetmap.multiselection.title" />
          <i className="uil uil-times" role="presentation" onClick={() => setShowTitle(false)} />
        </div>
      )}

      {isLinked && (
        <div className="multiselection-tooltip">
          <FormattedMessage id="widgetmap.multiselection.tooltip" />
        </div>
      )}

      {drawableSelection && (
        <div role="presentation" className={`drawable-button ${isDrawing ? 'active' : ''}`} onClick={handleChangeDraw}>
          <span />
          <b><FormattedMessage id="widget.config.map.draw" /></b>
        </div>
      )}

      {
        object && !isLinked
          ? <Popup object={object} alias={alias} hiddenAttributes={hidden} />
          : null
        }

      <ToggleScroll isLinked={isLinked} scroll={scroll} onClick={toggleScroll} />

      <div className={`mapZoomControls ${isLinked && 'isLinked'}`}>
        <ZoomIn zoomIn={() => zoomControl(ZOOM_INCREMENT_VALUE)} />
        <ZoomOut zoomOut={() => zoomControl(-ZOOM_INCREMENT_VALUE)} />
        <FullScreen fullScreen={() => fullScreen()} />
        <AutoZoom autoZoom={() => autoZoom()} />
      </div>

      <DeckGL
        style={{ bottom: '58px', top: 'auto', height: 'calc(100% - 122px)' }}
        className="deckGLWrapper"
        layers={renderLayers}
        views={MAP_VIEW}
        initialViewState={initialViewState}
        controller={{ scrollZoom: !scroll, doubleClickZoom: !isDrawing }}
        ref={(deck) => setDeck(deck)}
        container="mapdeck"
        onClick={onClick}
      >

        <StaticMap
          container="mapStatic"
          reuseMaps
          mapStyle={mapStyle}
          preventStyleDiffing
          mapboxApiAccessToken={MAPBOX_TOKEN}
        />

        {renderhoveredItems}
      </DeckGL>

      {legendPosition === 'bottom'
        && (
          <div />
        )}

      {hasBeenResized ? null : (
        <BoardHistorical
          checked={type === 'historical'}
          onClick={changeDataType}
          onChangeSlider={changeRange}
        />
      )}
    </WrapperDiv>
  );
};

WidgetMapUber.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any),
  allHistorical: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.objectOf(PropTypes.any),
  connectedDevices: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  hasBeenResized: PropTypes.bool,
  refreshMap: PropTypes.bool,
  setRefreshMap: PropTypes.func,
  title: PropTypes.string,
  updateWidget: PropTypes.func,
  widget: PropTypes.objectOf(PropTypes.any),
  widgetType: PropTypes.string,
};

WidgetMapUber.defaultProps = {
  allHistorical: [{}],
  actions: {},
  config: {},
  connectedDevices: [{}],
  data: [{}],
  hasBeenResized: false,
  refreshMap: false,
  setRefreshMap: () => {},
  title: '',
  updateWidget: () => {},
  widget: {},
  widgetType: '',
};

const mapStateToProps = (state) => ({
  allHistorical: state.get('historicals').get('historicalList'),
});

export default connect(mapStateToProps)(WidgetMapUber);
