import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import CommonInput from '../CommonInput';

const InputCtr = styled('input')`
/*   box-shadow: unset;
  border-radius: 3px;
  line-height: 1.5em;
  font-size: 0.8em;
  padding: 0.5em;
  box-sizing: border-box;
  margin-bottom:0;
  border: 0; */
  
  ${CommonInput}
  
`;

const Input = (props) => <InputCtr {...props} className="inputCtr" />;

Input.propTypes = {
  /** A placeholder for the input */
  placeholderId: PropTypes.string,

  /** The type of the input (html) */
  type: PropTypes.string,

  /** The name of the input */
  name: PropTypes.string,

  /** The id of the input */
  id: PropTypes.string,

  /** Style of different state */
  mode: PropTypes.oneOf(['edit', 'view', 'insert']),
  intl: PropTypes.objectOf(PropTypes.any),
};

Input.defaultProps = {
  placeholderId: 'widget.config.map.writeHere',
  type: 'text',
  name: 'InputName',
  id: 'inputId',
  mode: 'insert',
  intl: {},
};

export default injectIntl(Input);
