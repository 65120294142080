/* eslint-disable import/prefer-default-export */
import Faker from 'faker';
import { range } from 'ramda';
import { kpiManagement } from '../../configuration/config';
import parseFilters from '../../helpers/parseFilters';
import { requestAPI } from '../../helpers';

const endPoint = kpiManagement.urlAPI.concat(kpiManagement.paths.kpi);

export const exampleDataFn = (numberOfKpiToGenerate = 50) => {
  const KpiFaker = range(0, numberOfKpiToGenerate).map(() => ({
    id: Faker.random.uuid(),
    name: Faker.random.word(),
    owner: Faker.random.uuid(),
    description: Faker.random.word(),
    createdAt: new Date(),
    updatedAt: new Date(),
  }));
  KpiFaker.unshift({
    updatedAt: new Date(),
    createdAt: new Date(),
    description: 'Kpi Descripción',
    definition: { type: 'manual' },
    permissions_policy: { users: [], groups: [], public: true },
    id: 'b7f723f0-22df-47fb-b210-486bcc4dd351',
    name: 'Kpi Muestra',
    owner: '2d8d8294-d0c5-4f67-bcd8-9ec6f8ee02af',
    onCascadeUpdate: true,

  });
  return { kpis: KpiFaker };
};

const exampleDataUniqueFn = (kpi) => {
  const newKpi = JSON.parse(kpi);
  const KpiFaker = {
    ...newKpi,
    id: Faker.random.uuid(),
    name: Faker.random.word(),
    owner: Faker.random.uuid(),
    description: Faker.random.word(),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return { kpis: KpiFaker };
};

export const readKpis = async (page, size, filters, ckan) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';
  const queryCKAN = ckan === true ? '&subscriptions.ckan' : '';

  const params = {
    endPoint: endPoint + '/list/all?'.concat(queryPage, '&', querySize, queryFilters, queryCKAN),
    statusOK: 200,
    toJSON: true,
    returnData: 'kpis',
  };

  return requestAPI(params, exampleDataFn);
};

export const readMyKpis = async (page, size, filters) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'kpis',
  };
  return requestAPI(params, exampleDataFn);
};

export const readKpi = async (kpi) => {
  if (!kpi.id) return { status: 301, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${kpi.id}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'kpi',
  };

  const answer = await requestAPI(params, exampleDataUniqueFn);
  return answer;
};

export const readPublicKpi = async (kpi) => {
  if (!kpi.id) return { status: 301, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${kpi.id}/public`,
    statusOK: 200,
    toJSON: true,
    returnData: 'kpi',
  };

  const answer = await requestAPI(params, exampleDataUniqueFn, true);
  return answer;
};

export const addKpi = async (kpi) => {
  const params = {
    endPoint: `${endPoint}/`,
    statusOK: 201,
    toJSON: true,
    returnData: 'kpi',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(kpi),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataUniqueFn);
};

export const updateKpi = (kpi) => {
  if (!kpi.id) {
    return {
      status: 400,
      error: 'missing id attr',
    };
  }
  const params = {
    endPoint: `${endPoint}/${kpi.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: kpi,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(kpi),
      headers: { 'Content-Type': 'application/json' },
    },
  };
  return requestAPI(params, exampleDataFn);
};

export const deleteKpi = (kpi) => {
  if (!kpi.id) {
    return {
      status: 400,
      error: 'missing id attr',
    };
  }
  const params = {
    endPoint: `${endPoint}/${kpi.id}`,
    statusOK: 204,
    toJSON: false,
    returnData: kpi,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataFn);
};

export const executeKpi = (kpi) => {
  if (!kpi.id) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint}/${kpi.id}/execute`,
    statusOK: 200,
    toJSON: false,
    returnData: kpi,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(kpi),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataFn);
};
