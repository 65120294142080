export const BUSINESSDAY = 'businessDay';

const isMonthly = (minutes, hour, day, month, range) => minutes !== '*'
  && hour !== '*'
  && day !== '*'
  && month.includes('*/')
  && range === '*';

const isWeekly = (minutes, hour, day, month, range) => minutes !== '*'
  && hour !== '*'
  && day === '*'
  && month === '*'
  && (range.includes(',') || Number(range) > -1);

const isDaily = (minutes, hour, day, month, range) => minutes !== '*'
  && hour !== '*'
  && day === '*'
  && month === '*'
  && (range === '*' || range === '1-5')
  && !minutes.includes('*/')
  && !hour.includes('*/');

export const convertFromMinutes = (minute) => `*/${minute} * * * *`;

export const convertFromHours = (hour) => `0 */${hour} * * *`;

export const convertFromDaily = (minute, hour, when) => (when === BUSINESSDAY
  ? `${minute === '00' ? '0' : minute} ${hour} * * 1-5`
  : `${minute === '00' ? '0' : minute} ${hour} * * *`);

export const convertFromWeekly = (minute, hour, indexes) => `${minute === '00' ? '0' : minute} ${hour} * * ${indexes || '*'}`;

export const convertFromWeeklyRange = (startHour, startMinute, endHour, endMinute, indexes) => ({
  startRange: `${startMinute === '00' ? '0' : startMinute} ${startHour} * * ${indexes || '*'}`,
  endRange: `${endMinute === '00' ? '0' : endMinute} ${endHour} * * ${indexes || '*'}`,
});

export const convertFromMonthly = (minute, hour, day, month) => `${minute === '00' ? '0' : minute} ${hour} ${day} ${month.includes('*/') ? month : '*/'.concat(month)} *`;

export const getTypeOfCron = (cron) => {
  if (!cron) return 'manually';
  if (typeof cron !== 'string' && cron.property) return 0;
  const [minutes, hour, day, month, range] = cron.split(' ');
  if (isMonthly(minutes, hour, day, month, range)) {
    // parse cron and check if is equal of monthly pattern
    return 'monthly';
  } if (isWeekly(minutes, hour, day, month, range)) {
    return 'weekly';
  } if (isDaily(minutes, hour, day, month, range)) {
    return 'daily';
  } if (minutes.includes('*/')) {
    return 'minutes';
  } if (hour.includes('*/') && minutes === '0') {
    return 'hours';
  }
  return 0;
};

export const getDataFromCron = (cron) => {
  const [minutes, hour, day, month, range] = cron.split(' ');
  const type = getTypeOfCron(cron);
  switch (type) {
    case 'monthly':
      return {
        minutes, hour, day, month,
      };
    case 'weekly':
      return { minutes, hour, range };
    case 'daily':
      return { minutes, hour, range };
    case 'minutes':
      return { minutes };
    case 'hours':
      return { hour };
    default:
  }
  return 0;
};

export const makeExampleCronEditable = (cronType) => {
  switch (cronType) {
    case 'monthly':
      return {
        minute: '0', hour: '0', day: '1', month: '*/1',
      };
    case 'minutes':
      return { minutes: '*/30' };
    case 'hours':
      return { hour: '*/1' };
    case 'daily':
      return { minute: '0', hour: '0', day: '*' };
    case 'weekly':
      return { minute: '0', hour: '0', range: '6' };
    default:
      return '*/5 * * * *';
  }
};

export const makeExampleCron = (cronType) => {
  switch (cronType) {
    case 'monthly':
      return '0 0 1 */1 *';
    case 'minutes':
      return '*/30 * * * *';
    case 'hours':
      return '0 */1 * * *';
    case 'daily':
      return '0 0 * * *';
    case 'weekly':
      return '0 0 * * 1';
    default:
      return '*/5 * * * *';
  }
};
