import {
  all, call, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import ETLProcedure from '../../../models/ETLProcedure';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/etlProcedures';
import * as TYPES from './types';
import ETLExecution from '../../../models/ETLExecution';
import { parseToSources } from '../../../helpers/utils';

/**
 * Actions for WATCHERS
 * */

// Try to recover all etlProcedure form DB
export function* getETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readETLProcedures,
      payload.page,
      payload.size,
      payload.filters,
      payload.ckan,
    );
    if (response.status === 200) {
      const etlProcedures = response.data.objects.map(
        (etlProcedure) => new ETLProcedure(etlProcedure),
      );
      const { total } = response.data;

      yield put({
        type: TYPES.GET_ETL_PROCEDURES_SUCCESS,
        payload: { etlProcedures, total },
      });
      yield put({
        type: TYPES.GET_ETL_TO_SOURCE_LIST,
        payload: etlProcedures.map(parseToSources)
      })

    } else {
      yield put({ type: TYPES.GET_ETL_PROCEDURES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_ETL_PROCEDURES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}
// Try to recover all  My etlProcedures form DB
export function* getMyETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readMyETLProcedures,
      payload.page,
      payload.size,
      payload.filters,
    );

    if (response.status === 200) {
      const etlProcedures = response.data.objects.map(
        (etlProcedure) => new ETLProcedure(etlProcedure),
      );
      const { total } = response.data;

      yield put({
        type: TYPES.GET_MY_ETL_PROCEDURES_SUCCESS,
        payload: { etlProcedures, total },
      });
    } else {
      yield put({ type: TYPES.GET_MY_ETL_PROCEDURES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MY_ETL_PROCEDURES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all etlProcedure form DB
export function* getPublicETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readPublicETLProcedures,
      payload.page,
      payload.size,
      payload.filters,
    );

    if (response.status === 200) {
      const etlProcedures = response.data.objects.map(
        (etlProcedure) => new ETLProcedure(etlProcedure),
      );
      const { total } = response.data;

      yield put({
        type: TYPES.GET_PUBLIC_ETL_PROCEDURES_SUCCESS,
        payload: { etlProcedures, total },
      });
    } else {
      yield put({ type: TYPES.GET_PUBLIC_ETL_PROCEDURES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_PUBLIC_ETL_PROCEDURES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an etlProcedure form DB
export function* getETLProcedureThroughApi({ payload }) {
  const { isPublicDashboard } = payload;
  const endpoint = isPublicDashboard ? Api.readPublicETLProcedure : Api.readETLProcedure;
  try {
    const response = yield call(endpoint, payload);
    if (response.status === 200) {
      const etlProcedureFromDB = new ETLProcedure(response.data.object);
      yield put({
        type: TYPES.GET_ETL_PROCEDURE_SUCCESS,
        payload: etlProcedureFromDB,
      });
      yield put({ type: TYPES.GET_ETL_V2, payload: etlProcedureFromDB });
    } else {
      yield put({ type: TYPES.GET_ETL_PROCEDURE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_ETL_PROCEDURE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to create etlProcedure in DB
export function* addETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(Api.addETLProcedure, payload);
    if (response.status === 201) {
      const etlProcedureFromDB = new ETLProcedure(response.data.object);
      yield put({
        type: TYPES.ADD_ETL_PROCEDURE_SUCCESS,
        payload: etlProcedureFromDB,
      });
    } else {
      yield put({ type: TYPES.ADD_ETL_PROCEDURE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_ETL_PROCEDURE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update etlProcedure in DB
export function* updateETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateETLProcedure, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_ETL_PROCEDURE_SUCCESS,
        payload: new ETLProcedure(response.data.object),
      });
    } else {
      yield put({ type: TYPES.MODIFY_ETL_PROCEDURE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_ETL_PROCEDURE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete etlProcedure in DB
export function* deleteETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteETLProcedure, payload);

    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_ETL_PROCEDURE_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_ETL_PROCEDURE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_ETL_PROCEDURE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to VErify etlProcedure in DB
export function* verifyETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(Api.verifyETLProcedure, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.VERIFY_ETL_PROCEDURE_SUCCESS,
        payload: new ETLProcedure(response.data.object),
      });
    } else {
      yield put({ type: TYPES.VERIFY_ETL_PROCEDURE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.VERIFY_ETL_PROCEDURE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to VErify etlProcedure in DB
export function* executeETLProceduresThroughApi({ payload }) {
  try {
    const response = yield call(Api.executeETLProcedure, payload);
    if (response.status === 201) {
      yield put({
        type: TYPES.EXECUTE_ETL_PROCEDURE_SUCCESS,
        payload,
      });
    } else {
      yield put({ type: TYPES.EXECUTE_ETL_PROCEDURE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.EXECUTE_ETL_PROCEDURE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to VErify etlProcedure in DB
export function* getETLExecutionsThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.getETLExecutions,
      payload.page,
      payload.size,
      payload.filters,
      payload.id,
    );
    if (response.status === 200) {
      const etlExecutions = response.data.objects.map(
        (etlEx) => new ETLExecution(etlEx),
      );
      const { total } = response.data;

      yield put({
        type: TYPES.GET_ETL_EXECUTIONS_SUCCESS,
        payload: { etlExecutions, total },
      });
    } else {
      yield put({ type: TYPES.GET_ETL_EXECUTIONS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_ETL_EXECUTIONS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_ETL_PROCEDURES_REQUEST
function* watcherGetETLProcedures() {
  yield takeLatest('GET_ETL_PROCEDURES_REQUEST', getETLProceduresThroughApi);
}

function* watcherGetETLExecutions() {
  yield takeLatest('GET_ETL_EXECUTIONS_REQUEST', getETLExecutionsThroughApi);
}

// Watcher looking for GET_MY_ETL_PROCEDURES_REQUEST
function* watcherGetMyETLProcedures() {
  yield takeLatest('GET_MY_ETL_PROCEDURES_REQUEST', getMyETLProceduresThroughApi);
}

// Watcher looking for GET_ETL_PROCEDURES_REQUEST
function* watcherGetPublicETLProcedures() {
  yield takeLatest(
    'GET_PUBLIC_ETL_PROCEDURES_REQUEST',
    getPublicETLProceduresThroughApi,
  );
}

// Watcher looking for GET_ETL_PROCEDURE_REQUEST
function* watcherGetETLProcedure() {
  yield takeLatest('GET_ETL_PROCEDURE_REQUEST', getETLProcedureThroughApi);
}

// Watcher looking for ADD_ETL_PROCEDURE_REQUEST
function* watcherAddETLProcedures() {
  yield takeEvery('ADD_ETL_PROCEDURE_REQUEST', addETLProceduresThroughApi);
}

// Watcher looking for MODIFY_ETL_PROCEDURE_REQUEST
function* watcherUpdateETLProcedures() {
  yield takeLatest('MODIFY_ETL_PROCEDURE_REQUEST', updateETLProceduresThroughApi);
}

// Watcher looking for DELETE_ETL_PROCEDURE_REQUEST
function* watcherDeleteETLProcedures() {
  yield takeEvery('DELETE_ETL_PROCEDURE_REQUEST', deleteETLProceduresThroughApi);
}

function* watcherVerifyETLProcedure() {
  yield takeLatest('VERIFY_ETL_PROCEDURE_REQUEST', verifyETLProceduresThroughApi);
}

function* watcherExecuteETLProcedure() {
  yield takeLatest('EXECUTE_ETL_PROCEDURE_REQUEST', executeETLProceduresThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetETLProcedures),
    fork(watcherGetPublicETLProcedures),
    fork(watcherGetETLProcedure),
    fork(watcherAddETLProcedures),
    fork(watcherUpdateETLProcedures),
    fork(watcherDeleteETLProcedures),
    fork(watcherGetMyETLProcedures),
    fork(watcherVerifyETLProcedure),
    fork(watcherExecuteETLProcedure),
    fork(watcherGetETLExecutions),
  ]);
}
