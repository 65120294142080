import Styled, { withTheme } from 'styled-components';

export const SideOfHeader = Styled('div')`
    min-height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    
    ${(p) => p.reverse && `
      flex-direction: row-reverse;
    `}
  
    ${(p) => p.css}
`;

SideOfHeader.defaultProps = {
  minHeight: { value: 60, unit: 'px' },
  css: '',
};

export default withTheme(SideOfHeader);
