import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../../../CommonView';
import { withWizardStep } from '../../../../../../Contexts/WizardStepContext';
import './styles.scss';

const Html = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetHTML })),
);

class Preview extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {};
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  validate = () => {
    this.props.onContinue();
  };

  render() {
    const { newEntity } = this.props;
    return (
      <>
        <Row>
          <Col sm={{ size: 12 }}>
            <Suspense fallback="cargando">
              <Html
                config={newEntity.config}
              />
            </Suspense>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addedWidget: state.get('widgets').get('added'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(Preview)));
