import Command from '../../../../models/Attribute/Command';
import Static from '../../../../models/Attribute/Static';
import Lazy from '../../../../models/Attribute/Lazy';
import Attribute from '../../../../models/Attribute/Attribute';
import { protocolData } from '../../../../data/protocols';

export const parseSigfox = internalAttrs => {
  let mapping = '';
  internalAttrs.forEach((attribute, i) => {
    mapping += `${attribute.name}:`
    attribute.offset ? mapping += `${attribute.offset}:` : mapping += ':'
    mapping += `${attribute.type}:${attribute.parameter}`;
    if (attribute.type && (attribute.type === 'uint' || attribute.type === 'int' || attribute.type === 'float')) {
      if(attribute.endian){
        mapping += `:${attribute.endian}`;
      }
    }
    if (i < internalAttrs.length - 1) {
      mapping += ' ';
    }
  });
  return mapping;
};

const getProtocolData = entity => {
  return protocolData.protocol.find(p => p.value === entity.protocol);
};

const saveEntity = (state, entity, onContinue) => {
  const newEntity = { ...state.newEntity };
  const attributes = newEntity.allAttributes;
  const parser = getProtocolData(newEntity).parser;
  let created;

  if (parser) {
    created = parser(state, entity);
  } else {
    [
      'command_attributes',
      'lazy_attributes',
      'attributes',
      'static_attributes',
      'internal_attributes',
    ].forEach(key => {
      newEntity[key] = [];
    });

    if (newEntity.protocol === 'SIGFOX') {
      newEntity.internal_attributes = [{ mapping: parseSigfox(attributes.filter(a => a.property === 'attribute')) }];
      newEntity.transport = 'NONE';
    }

    if (newEntity.protocol === 'LORA') {
      newEntity.transport = undefined;
    }

    attributes.forEach(a => {
      switch (a.property) {
        case 'attribute':
          return newEntity.attributes.push(new Attribute({ ...a }).getData());
        case 'lazy':
          return newEntity.lazy_attributes.push(new Lazy({ ...a }).getData());
        case 'static':
          return newEntity.static_attributes.push(new Static({ ...a }).getData());
        case 'command':
          a.available_commands = a.value;
          return newEntity.command_attributes.push(new Command({ ...a }).getData());
        default:
          return null;
      }
    });

    const service = newEntity.fiware;
    newEntity.fiware = { ...service, servicepath: '/fiwoo' };
    created = new entity({ ...newEntity });
    if (!['SIGFOX', 'LORA'].includes(created.protocol)) {
      delete created.internal_attributes;
    }
  }

  created.save();

  onContinue();
};

export default saveEntity;
