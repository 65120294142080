import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { typography } from '../../../configuration/fonts';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import Switch from '../../../elements/Switch';

const ButtonToggleScroll = styled.div`
  left: 1em;
  padding: 5px;
  font-family: ${typography};
  color: #2B334A;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  z-index: 1;
  position: absolute;
  height: 30px;
  // width: 150px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.08);
  cursor: pointer;
  display: flex;
  align-content: center;
}
`;

const ToggleScroll = ({ isLinked, onClick, scroll }) => (
  <ButtonToggleScroll style={{ bottom: isLinked ? '1em' : '5.5em' }} onClick={onClick}>
    <div style={{ 'margin-right': '15px' }}><FormattedMessage id="widget.config.lock.zoom" /></div>
    <Switch
      checked={scroll}
    />
  </ButtonToggleScroll>
);

ToggleScroll.propTypes = {
  onClick: PropTypes.func.isRequired,
  scroll: PropTypes.bool.isRequired,
};

export default ToggleScroll;
