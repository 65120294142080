/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import Moment from 'moment';
import { WebMercatorViewport } from '@deck.gl/core';
import styled from 'styled-components';
import { getMultipleHistoricalList } from '../../services/redux/historicals/actions';
import colors from '../../configuration/colors';

export const calculateInitialViewState = (data) => {
  const latitudesArray = [];
  const longitudesArray = [];

  if (data.length) {
    data.forEach((d) => {
      latitudesArray.push(parseFloat(d.location.x));
      longitudesArray.push(parseFloat(d.location.y));
    });
    const maxLatitude = Math.max(...latitudesArray);
    const minLatitude = Math.min(...latitudesArray);
    const maxLongitude = Math.max(...longitudesArray);
    const minLongitude = Math.min(...longitudesArray);
    const latitudeCenter = (maxLatitude && minLatitude) ? (
      minLatitude + (Math.abs(maxLatitude - minLatitude) / 2)) : 0;
    const longitudeCenter = (maxLongitude && minLongitude) ? (
      minLongitude + (Math.abs(maxLongitude - minLongitude) / 2)) : 0;

    const wmViewport = new WebMercatorViewport({
      width: 500,
      height: 500,
      longitude: longitudeCenter,
      latitude: latitudeCenter,
      zoom: 13,
      pitch: 0,
      bearing: 0,
    });

    if (data.length === 1) {
      return wmViewport;
    }
    return wmViewport.fitBounds([[minLongitude, minLatitude], [maxLongitude, maxLatitude]], {
      padding: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
    });
  }
  // * If there is no data we return a default position value.
  return new WebMercatorViewport({
    width: 500,
    height: 500,
    longitude: -5.994072,
    latitude: 37.392529,
    zoom: 1,
    pitch: 0,
    bearing: 0,
  });
};

export const hexToRgbA = (hex, transp) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255, transp];
  }
  throw new Error('Bad Hex');
};

export const getColorCategory = (colorCategory, device, transp = 255) => {
  let color = colors['ui-main-color'];

  if (colorCategory && device) {
    color = colorCategory.color;

    switch (colorCategory.mode) {
      case 'range':
        if (colorCategory.range) {
          Object.keys(colorCategory.range).forEach((attr) => {
            colorCategory.range[attr].forEach((condition) => {
              if (condition?.color && condition.from && condition.to && device.data[attr]) {
                const from = Number(condition.from);
                const to = Number(condition.to);
                const equalTo = Number(condition.equalTo);
                const value = Number(device.data[attr]);

                if (value >= from && value <= to) {
                  color = condition.color;
                }
                if (value === equalTo) {
                  color = condition.color;
                }
              }
            });
          });
        }
        break;

      case 'element':
        if (colorCategory.element) {
          colorCategory.element.forEach((elem) => {
            if (elem.devices.find((d) => d.id === device.id)) {
              color = elem.color;
            }
          });
        }
        break;
      default:
        break;
    }
  }
  return hexToRgbA(color, transp);
};

export const getFilterDateBySampling = (sampling, value, isStartDate) => {
  switch (sampling) {
    case 'lastDay':
    default: {
      const hours = Math.trunc(value / 2).toString().padStart(2, '0');
      const minutes = (value / 2).toFixed(2).toString().split('.')[1] === '50' ? '30' : '00';
      const seconds = isStartDate ? '00' : '59';
      const currentDate = `${Moment().local().format('YYYY-MM-DD')} ${hours}:${minutes}:${seconds}`;
      return `${Moment(currentDate).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')}`;
    }
  }
};

export const getDateBySampling = (sampling, isStartDate) => {
  switch (sampling) {
    case 'lastDay':
    default:
      return isStartDate ? Moment.utc().local().format('YYYY-MM-DD')
        : Moment.utc().local().add(1, 'day').format('YYYY-MM-DD');
  }
};

const DATA_TYPE_HISTORICAL = 'historical';

export const getHistorical = (currentWidget) => {
  let queries = currentWidget.getQueryHistorical();
  if (currentWidget.config.dataType === DATA_TYPE_HISTORICAL) {
    queries = queries.map((o, i) => {
      queries[i].multipleRequest = true;
      queries[i].size = 2000;
      queries[i].startDate = getDateBySampling(null, true);
      queries[i].endDate = getDateBySampling(null, false);
      return o;
    });
  }
  getMultipleHistoricalList(queries);
};

export const ActionsWrapper = styled.div`
  float: right;
  color: ${colors['ui-Black']};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -10px;
`;
