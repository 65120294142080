import React from 'react';
import RemoveHoc from "./../../helpers/RemoveComponent";
import { FormattedMessage } from "../../../Contexts/LanguageContext/index";
import Widget from '../../../models/Widget';

const Delete = <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
/>;

const View = <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
/>;

const Edit = <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
/>;

const Clone = <FormattedMessage
    id="options.clone"
    defaultMessage="clone"
    description="Title of clone"
/>;


const OptionsForRow = ({ rowContent, viewClick }) => {

    const ButtonOpenRemove = ({ showModal }) => <li onClick={showModal}>{Delete}</li>;

    const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Widget, nameData: "widgets" });

    return (
        <div className="tableMenu">
            <ul className="list-unstyled">
                <li onClick={() => { viewClick(rowContent) }} state={{ widget: rowContent }}>{View}<i className='uil uil-arrow-right'/></li>
                <li className="divider" />
                <li onClick={() => { viewClick(rowContent) }} state={{ widget: rowContent, mode: 'edit' }}>{Edit}</li>
                <RemoveDOM rowContent={rowContent} />
                <li onClick={() => { }} state={{ widget: rowContent }}>{Clone}</li>
            </ul>
        </div>
    )
};

export default OptionsForRow;