/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import Field from '../../../../../elements/Field';
import { sampling as samplingOptions } from '../../../../../configuration/variables';
import { Components } from '../../../../../models/Widget/configurationSheets/utils';

export default function ({
  widget,
  config: {
    appearance: {
      orientation: attrOrientation,
    },
    data: {
      type,
      operation,
      sampling,
    },
  },
  handler,
  errors,
}) {
  const [localType, setLocalType] = useState(null);
  const [localWidget] = useState(widget);
  const [localOrientation, setLocalOrientation] = useState(null);
  const [localOperation, setLocalOperation] = useState(null);
  const [localSampling, setLocalSampling] = useState(null);
  const attOrientation = ['rows', 'columns'];
  const typeOptions = ['real-time', 'historical'];
  const [operationOptions, setOperationOptions] = useState(type === 'real-time'
    ? ['last-value'] : ['min', 'avg', 'max']);

  useEffect(() => {
    if (operation !== localOperation) {
      setLocalOperation(operation);
    }
    if (type !== localType) {
      setLocalType(type);
    }
    if (sampling !== localSampling) {
      setLocalSampling(sampling);
    }
    if (attrOrientation !== localOrientation) {
      setLocalOrientation(attrOrientation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, type, sampling, attrOrientation]);

  useEffect(() => {
    setOperationOptions(localType === 'real-time' ? ['last-value'] : ['min', 'avg', 'max']);
    setLocalOperation(localType === 'real-time' ? 'last-value' : 'avg');
    setLocalSampling(localType === 'real-time' ? '' : 'lastDay');
  }, [localType]);

  const onChangeSelect = (name, value, category) => {
    if (value) {
      if (name === 'type') {
        setLocalType(value);
        const defaultOperation = value === 'real-time' ? 'last-value' : 'avg';
        handler({ name: 'operation', value: defaultOperation }, 'data', Components.tableData);
        const defaultSampling = value === 'real-time' ? '' : 'lastDay';
        handler({ name: 'sampling', value: defaultSampling }, 'data', Components.tableData);
      }
      if (name === 'orientation') setLocalOrientation(value);
      if (name === 'operation') setLocalOperation(value);
      if (name === 'sampling') setLocalSampling(value);
      handler({ name, value }, category, Components.tableData);
    }
  };

  return (
    <div className="dataConfiguration">
      {localWidget?.container
        ? (
          <Row>
            <Field
              label={<FormattedMessage id="widget.config.type" />}
              name="type"
              className="fieldTable"
              type="select"
              options={typeOptions.map((d) => ({ value: d, name: <FormattedMessage id={`widget.config.${d}`} /> }))}
              value={localType}
              onChange={(name, value) => onChangeSelect(name, value, 'data')}
              error={!!errors['data.type']}
              helperText={errors['data.type']}
            />
            <Field
              label={<FormattedMessage id="widget.config.operation" />}
              name="operation"
              className="fieldTable"
              type="select"
              options={operationOptions.map((o) => ({ value: o, name: <FormattedMessage id={`widget.config.${o}`} /> }))}
              value={localOperation}
              onChange={(name, value) => onChangeSelect(name, value, 'data')}
              error={!!errors['data.operation']}
              helperText={errors['data.operation']}
            />
            {localOperation !== 'last-value'
              && (
                <Field
                  label={<FormattedMessage id="widget.config.sampling" />}
                  name="sampling"
                  className="fieldTable"
                  type="select"
                  options={samplingOptions.map((t) => ({ value: t, name: <FormattedMessage id={`widget.config.${t}`} /> }))}
                  value={localSampling}
                  onChange={(name, value) => onChangeSelect(name, value, 'data')}
                  error={!!errors['data.sampling']}
                  helperText={errors['data.sampling']}
                />
              )}
          </Row>
        )
        : (
          <>
            <Row>
              <Field
                label={<FormattedMessage id="widget.config.orientation" />}
                className="fieldTable"
                name="orientation"
                type="select"
                options={attOrientation.map((at) => ({ value: at, name: <FormattedMessage id={`widget.config.${at}`} /> }))}
                value={localOrientation}
                onChange={(name, value) => onChangeSelect(name, value, 'appearance')}
                error={!!errors['appearance.orientation']}
                helperText={errors['appearance.orientation']}
              />
            </Row>
            <Row>
              <Field
                label={<FormattedMessage id="widget.config.type" />}
                name="type"
                className="fieldTable"
                type="select"
                options={typeOptions.map((d) => ({ value: d, name: <FormattedMessage id={`widget.config.${d}`} /> }))}
                value={localType}
                onChange={(name, value) => onChangeSelect(name, value, 'data')}
                error={!!errors['data.type']}
                helperText={errors['data.type']}
              />
              <Field
                label={<FormattedMessage id="widget.config.operation" />}
                name="operation"
                className="fieldTable"
                type="select"
                options={operationOptions.map((o) => ({ value: o, name: <FormattedMessage id={`widget.config.${o}`} /> }))}
                value={localOperation}
                onChange={(name, value) => onChangeSelect(name, value, 'data')}
                error={!!errors['data.operation']}
                helperText={errors['data.operation']}
              />
              {localOperation !== 'last-value'
                && (
                  <Field
                    label={<FormattedMessage id="widget.config.sampling" />}
                    name="sampling"
                    className="fieldTable"
                    type="select"
                    options={samplingOptions.map((t) => ({ value: t, name: <FormattedMessage id={`widget.config.${t}`} /> }))}
                    value={localSampling}
                    onChange={(name, value) => onChangeSelect(name, value, 'data')}
                    error={!!errors['data.sampling']}
                    helperText={errors['data.sampling']}
                  />
                )}
            </Row>
          </>
        )}
    </div>
  );
}
