/* eslint-disable import/no-cycle */
import imageWidgets from '../widgets/index';
import { genericChildrenModelParser } from '../../../utils';

const allImageWidgets = {
  TEXT_ACCOUNTANT: imageWidgets.ImageKpi,
  PARAMETRIZED_TEXT: imageWidgets.ImageParametrizedText,
};

const childrenModelParser = (widget, noInstanced) => (
  genericChildrenModelParser(allImageWidgets, widget, noInstanced, 'IMAGE'));

export default childrenModelParser;
