import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableCard from '../../helpers/EditableCard/EditableCard';
import { clone } from 'ramda';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { injectIntl } from 'react-intl';
import './styles.scss';
import { validate } from 'validate.js';
import Rule from '../../../models/Rule';
import { ButtonCustom } from '../../../elements/Button/index';
import Menu from '../../../components/Menu/index';
import EditNotificationComponent from './components/EditNotificationsComponent';
import EditUpdateComponent from './components/EditUpdateComponent';
import {
  getDevices,
  updateDevicesState,
  clearDevicesState,
} from '../../../services/redux/devices/actions';

import { addRuleAction, updateRuleAction, deleteRuleAction } from '../../../services/redux/rules/actions';

import EditCommandComponent from './components/EditCommandComponent';
import { readDevices } from '../../../services/api/devices';
import { internalRulesManagement } from '../../../configuration/config';
import EditTweetComponent from './components/EditTweetComponent';
import EditAlertComponent from './components/EditAlertComponent';

const STRING_TRANSLATION_HEADER = 'rules.profile.actions';

const OpenButton = ({ onClick }) => (
  <ButtonCustom
    label={<FormattedMessage id="rules.profile.actions" />}
    type="secondary"
    icon="plus"
    handleOnClick={onClick}
    className="ml-auto"
  />
);

const ActionMenu = ({ onClick, alertActionPresent, ...rest }) => (
  <Menu
    {...rest}
    top="40px"
    openComponent={OpenButton}
    openFrom="top right"
    className="more"
  >
    <ul>
      <li onClick={() => onClick('notification')}>
        <FormattedMessage id="rules.wizard.action.notification" />
      </li>
      {/* <li onClick={() => onClick('command')}>
        <FormattedMessage id="rules.wizard.action.command" />
      </li>
      <li onClick={() => onClick('update')}>
        <FormattedMessage id="rules.wizard.action.update" />
      </li>
      <li onClick={() => onClick('tweet')}>
        <FormattedMessage id="rules.wizard.action.tweet" />
      </li> */}
      {!alertActionPresent() && (
      <li onClick={() => onClick('alert')}>
        <FormattedMessage id="rules.wizard.action.alert" />
      </li>
      )}
    </ul>
  </Menu>
);

export class EditActions extends Component {
  constructor(props) {
    super(props);
    const {
      updating, data, allDevices,
    } = this.props;
    this.state = {
      saved: clone(this.props.data.actions),
      actions: clone(this.props.data.actions),
      ruleMainDevice: this.props.allDevices.find(
        (d) => d.device_id === this.props.data.engine.entities[0].id,
      ),
      ruleDevices: updating ? allDevices.filter(
        (d) => d.fiware.service === data.fiware.service,
      ) : allDevices,
      activeCards: this.initActiveCards(this.props.data.actions),
      errors: {},
      page: 1,
      maxTotal: 0,
    };
  }

  componentDidMount() {
    if (this.props.updating) {
      this.state.actions.map((action) => {
        if (!this.getDevice(this.props.allDevices, action)) {
          getDevices({
            filters: { deviceId: action.deviceId },
            page: 1,
            size: 1,
            mode: '',
          });
        }
      });

      this.getSameServiceDevices();
    }

    if (this.getUpdateDevices() !== undefined) {
      // filters.deviceId = updateDevices;
      this.getSameServiceDevices();
    }

    if (this.areSendCommandActions() !== undefined) {
      this.state.actions.forEach((action) => {
        if (action.type === 'post') {
          this.getFilteredDevices(
            { deviceId: action.parameters.json.deviceId },
            1,
            5,
          );
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { allDevices } = this.props;
    if (prevState.page !== this.state.page) {
      const filters = {};
      filters.fiwareService = this.state.ruleMainDevice.fiware.service;
      getDevices({
        filters,
        page: this.state.page,
        size: 5,
      });
    }

    if (JSON.stringify(allDevices) !== JSON.stringify(prevProps.allDevices)) {
      this.updateAfterAllDevicesChange();
    }

    if (prevProps.data.actions !== this.props.data.actions) {
      this.setState({ actions: clone(this.props.data.actions) });
    }

    if (this.props.total > prevState.maxTotal) {
      this.setState({ maxTotal: this.props.total });
    }
  }

  getFilteredDevices = (filter, page, size = 5) => {
    readDevices(page, size, filter)
      .then((res) => updateDevicesState({ devices: res.data.devices }))
      .catch((err) => console.log(err));
  };

  getSameServiceDevices = () => {
    const filters = {};
    filters.fiwareService = this.state.ruleMainDevice.fiware.service;
    getDevices({
      filters,
      page: 1,
      size: 5,
    });
  };

  getUpdateDevices = () => {
    const updateTypeDevices = this.state.actions.filter(
      (action) => action.type === 'update',
    );
    return updateTypeDevices.length > 0
      ? updateTypeDevices.map((action) => action.parameters.id)
      : undefined;
  };

  updateAfterAllDevicesChange = () => {
    const { allDevices } = this.props;
    this.setState({
      ruleDevices: allDevices.filter(
        (d) => d.fiware.service === this.props.data.fiware.service,
      ),
    });
  }

  areSendCommandActions = () => this.state.actions.some((action) => action.type === 'send_command');

  initActiveCards = (data) => data.map(() => false);

  areSelectedDevices = () => this.props.selectedDevices && this.props.selectedDevices.length > 0;

  getDevice = (devices, data) => (devices ? devices.find((d) => d.device_id === data.deviceId) : {})

  validateAction = (index) => {
    const rawAction = this.state.actions[index];
    let action;
    const constraints = {};
    switch (rawAction.type) {
      case 'email':
        ['from', 'to', 'subject', 'template'].forEach((index) => {
          constraints[index] = {
            presence: {
              allowEmpty: false,
              message: (
                <FormattedMessage id="rules.profile.validation.empty" />
              ),
            },
          };

          if (['from', 'to'].includes(index)) {
            constraints[index] = {
              ...constraints[index],
              email: {
                message: <FormattedMessage id="Validation.email" />,
              },
            };
          }
        });

        action = { ...rawAction };
        break;
      case 'device_update':
      case 'send_command':
        ['device', 'attribute', 'value'].forEach((index) => {
          constraints[index] = {
            presence: {
              allowEmpty: false,
              message: (
                <FormattedMessage id="rules.profile.validation.empty" />
              ),
            },
          };
        });

        if (rawAction.type === 'device_update') {
          action = {
            device: rawAction.deviceId,
            attribute: rawAction.attributes[0].name,
            value: rawAction.attributes[0].value,
          };
        } else {
          action = {
            device: rawAction.deviceId,
            attribute: rawAction.command.name,
            value: rawAction.command.value,
          };
        }
        break;
      case 'twitter':
        constraints.template = {
          presence: {
            allowEmpty: false,
            message: <FormattedMessage id="rules.profile.validation.empty" />,
          },
          // length: {
          //   minimum: 0,
          //   maximum: 280,
          //   message: <FormattedMessage id="validation.tweet.length" />,
          // },
        };

        constraints.lengthOk = {
          presence: {
            allowEmpty: false,
            message: <FormattedMessage id="validation.tweet.length" />,
          },
        };

        action = { template: rawAction.template, lengthOk: rawAction.lengthOk };
        break;
      case 'alert':
        ['name', 'description', 'severity'].forEach((index) => {
          constraints[index] = {
            presence: {
              allowEmpty: false,
              message: (
                <FormattedMessage id="rules.profile.validation.empty" />
              ),
            },
          };
        });

        action = {
          name: rawAction.name,
          description: rawAction.description,
          severity: rawAction.severity,
        };
        break;
      default:
        return null;
    }
    return validate(action, constraints);
  };

  getDevices = (data) => {
    if (Array.isArray(data) && !Array.isArray(data[0])) return [data];
    return this.getDevices(data[0]);
  };

  updateRule = (payload) => {
    const editedRule = new Rule(payload);
    editedRule.update();
    return editedRule;
  };

  addAction = (payload) => {
    const addAction = addRuleAction(payload);
    return addAction;
  }

  updateAction = (payload) => {
    const uAction = updateRuleAction(payload);
    return uAction;
  }

  deleteAction = (payload) => {
    const dAction = deleteRuleAction(payload);
    return dAction;
  }

  addNewNotification = () => {
    const activeCards = [...this.state.activeCards];
    const blankNotification = {
      type: 'email',
      to: '',
      from: '',
      subject: '',
      template: '',
    };

    const actions = [...this.state.actions];
    actions.push(blankNotification);
    activeCards.push(false);

    this.setState({
      actions,
      activeCards,
    });
  };

  addNewTweet = () => {
    const activeCards = [...this.state.activeCards];
    const blankTweet = {
      type: 'twitter',
      template: '',
    };

    const actions = [...this.state.actions];
    actions.push(blankTweet);
    activeCards.push(false);

    this.setState({
      actions,
      activeCards,
    });
  };

  addNewAlert = () => {
    const activeCards = [...this.state.activeCards];
    const blankTweet = {
      type: 'alert',
      name: '',
      description: '',
      severity: '',
    };

    const actions = [...this.state.actions];
    actions.push(blankTweet);
    activeCards.push(false);

    this.setState({
      actions,
      activeCards,
    });
  };

  addNewUpdate = () => {
    const activeCards = [...this.state.activeCards];
    const blankUpdate = {
      type: 'device_update',
      attributes: [{ name: '', type: '', value: '' }],
      deviceId: '',
      version: 2,

    };

    const actions = [...this.state.actions];
    actions.push(blankUpdate);
    activeCards.push(false);

    this.setState({
      actions,
      activeCards,
    });
  };

  addNewCommand = () => {
    const activeCards = [...this.state.activeCards];
    const blankUpdate = {
      type: 'send_command',
      deviceId: '',
      entity_type: '',
      command: {
        name: '',
        value: '',
      },
      headers: {
        service: '',
        servicepath: '',
      },
    };

    const actions = [...this.state.actions];
    actions.push(blankUpdate);
    activeCards.push(false);

    this.setState({
      actions,
      activeCards,
    });
  };

  getAttribute = (attributes, data) => {
    if (data.variable === '' || attributes.length === 0) {
      return '';
    }
    const attributesList = attributes.find(
      (b) => data.variable === b.name || data.variable.name === b.name,
    );
    return attributesList ? attributesList.value : undefined;
  };

  handleOnSave = (index) => {
    const validation = this.validateAction(index);

    if (validation) {
      this.setState({
        errors: validation,
      });
    } else {
      // const formerRule = { ...this.props.data };
      // formerRule.actions = [...this.state.actions];
      // formerRule.fiware = this.state.ruleMainDevice.fiware;
      const activeCards = this.initActiveCards(this.state.actions);

      if (!this.state.actions[index].id) {
        this.addAction({ ruleId: this.props.data.id, payload: this.state.actions[index] });
      } else {
        this.updateAction({ ruleId: this.props.data.id, payload: this.state.actions[index] });
      }

      // const editedRule = this.updateRule(formerRule);

      this.setState({
        saved: clone(this.state.actions),
        // actions: clone(editedRule.actions),
        activeCards,
        errors: {},
      });
    }
  };

  handleOnEditStatusChange = (value, index) => {
    const activeCards = this.initActiveCards(this.state.actions);
    activeCards[index] = !value;
    let { actions } = this.state;
    if (this.state.activeCards.some((c) => c === true)) {
      actions = clone(this.state.saved);
    }
    this.setState({
      actions,
      activeCards,
      errors: {},
    });
  };

  handleFieldChange = (payload) => {
    const {
      index, id, value, type,
    } = payload;
    const actions = [...this.state.actions];
    const { parameters } = actions[index];
    switch (type) {
      case 'device_update':
        switch (id) {
          case 'device':
            actions[index].deviceId = value.device_id;
            actions[index].attributes[0].name = '';
            break;
          case 'attribute':
            actions[index].attributes[0].name = value.name;
            actions[index].attributes[0].type = value.type;
            break;
          case 'value':
            actions[index].attributes[0].value = value;
            break;
          default:
            break;
        }
        break;
      case 'send_command': {
        switch (id) {
          case 'device':
            actions[index].deviceId = value.device_id;
            actions[index].entity_type = value.entity_type;
            actions[index].headers.service = value.fiware.service;
            actions[index].headers.servicepath = value.fiware.servicepath;
            actions[index].command.name = '';
            break;
          case 'attribute':
            actions[index].command.name = value.name;
            break;
          case 'value':
            actions[index].command.value = value;
            break;
          default:
            break;
        }
        break;
      }
      case 'email':
        actions[index][id] = value;
        break;

      case 'twitter':
        if (id === 'template') {
          actions[index].template = value;
        }
        if (id === 'lengthOk') {
          actions[index].lengthOk = value;
        }
        break;
      case 'alert':
        actions[index][id] = value;
        break;
      default:
        break;
    }
    this.setState({
      actions,
    });
  };

  handleOnClickAddButton = (actionType) => {
    switch (actionType) {
      case 'command':
        this.addNewCommand();
        break;
      case 'update':
        this.addNewUpdate();
        break;
      case 'tweet':
        this.addNewTweet();
        break;
      case 'alert':
        this.addNewAlert();
        break;
      default:
        this.addNewNotification();
    }
  };

  handleOnDelete = (index) => {
    const activeCards = this.initActiveCards(this.state.actions);
    const actions = clone(this.state.actions);
    [activeCards, actions].forEach((key) => {
      key.splice(index, 1);
    });

    // this.updateRule({
    //   ...this.props.data,
    //   actions: actions,
    //   fiware: this.state.ruleMainDevice.fiware,
    // });
    this.deleteAction({ ruleId: this.props.data.id, payload: this.state.actions[index] });

    // this.setState({
    //   saved: actions,
    //   actions,
    //   activeCards,
    // });
  };

  handleFetchInfo = () => {
    const { fetching } = this.props;
    const { maxTotal, ruleDevices } = this.state;
    if (
      (!fetching && maxTotal > ruleDevices.length)
      || (!fetching && ruleDevices.length < 5)
    ) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { devices } = this.state;
    if (init) clearDevicesState();
    if (devices && devices.length === 1) {
      getDevices({ filters: { fiwareService: devices[0].fiware.service, name: value }, page: 1, size: 5 });
    } else {
      getDevices({ filters: { name: value }, page: 1, size: 5 });
    }
  };

  handleOnCancel = () => {
    const activeCards = this.initActiveCards(this.state.saved);
    this.setState({
      actions: clone(this.state.saved),
      errors: {},
      activeCards,
    });
  };

  alertActionAlreadyPresent = () => {
    const { actions } = this.state;
    return actions.find(action => action.type === 'alert') ? true : false;
  };

  render() {
    const {
      actions, activeCards, errors, ruleMainDevice, ruleDevices,
    } = this.state;
    const { intl, data } = this.props;
    return (
      <div className="tabSection">
        {this.props.permissionToEdit && (
        <div className="actionTypeInfo d-flex justify-content-end align-items-center">
          <ActionMenu onClick={this.handleOnClickAddButton} alertActionPresent={this.alertActionAlreadyPresent} />
        </div>
        )}
        <div className="editableAction">
          {actions.map((a, index) => (
            <EditableCard
              stringTranslationHeader={STRING_TRANSLATION_HEADER}
              isEditable={this.props.permissionToEdit}
              data={actions}
              onSave={() => this.handleOnSave(index)}
              onEdit={activeCards[index]}
              onDelete={() => this.handleOnDelete(index)}
              onCancel={this.handleOnCancel}
              header={<FormattedMessage id={`rules.profile.${a.type}.title`} />}
              className={a.type}
              key={index}
              deletable={actions.length !== 1}
              editStatusChange={(value) => this.handleOnEditStatusChange(value, index)}
              render={(onEdit) => {
                switch (a.type) {
                  case 'email':
                    return (
                      <EditNotificationComponent
                        notification={a}
                        errors={errors}
                        intl={intl}
                        onEdit={onEdit}
                        index={index}
                        onChange={this.handleFieldChange}
                        devices={data.engine.entities}
                      />
                    );
                  case 'device_update':
                    return (
                      <EditUpdateComponent
                        update={a}
                        errors={errors}
                        intl={intl}
                        onEdit={onEdit}
                        index={index}
                        onChange={this.handleFieldChange}
                        allDevices={ruleDevices}
                        service={ruleMainDevice.fiware.service}
                        fetchInfo={this.handleFetchInfo}
                        onSearch={this.handleOnSearch}
                      />
                    );
                  case 'send_command':
                    return (
                      <EditCommandComponent
                        command={a}
                        errors={errors}
                        intl={intl}
                        onEdit={onEdit}
                        index={index}
                        onChange={this.handleFieldChange}
                        allDevices={ruleDevices}
                        fetchInfo={this.handleFetchInfo}
                        onSearch={this.handleOnSearch}
                      />
                    );
                  case 'twitter':
                    return (
                      <EditTweetComponent
                        tweet={a}
                        errors={errors}
                        intl={intl}
                        onEdit={onEdit}
                        index={index}
                        onChange={this.handleFieldChange}
                        devices={data.engine.entities}
                      />
                    );
                  case 'alert':
                    return (
                      <EditAlertComponent
                        alert={a}
                        errors={errors}
                        intl={intl}
                        onEdit={onEdit}
                        index={index}
                        onChange={this.handleFieldChange}
                        devices={data.engine.entities}
                      />
                    );
                  default:
                    return <p>To-DO</p>;
                }
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}

EditActions.defaultProps = {
  saveOnTheFly: true,
  onChange: () => {},
  updating: true,
};

const mapStateToProps = (state) => ({
  allDevices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(injectIntl(EditActions));
