import React from 'react';
import Table from '../../../components/Table';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import RemoveHoc from '../../helpers/RemoveComponent';
import { injectIntl } from 'react-intl';

import './styles.scss';
import FiltersFinder from '../../helpers/FiltersFinder/index';
import BasicOptionsForRow from './BasicOptionsForRows';
import PropTypes from 'prop-types';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const RemoveComponent = RemoveHoc(({ showModal }) => (
    <i onClick={showModal} className="uil uil-trash-alt remove" />
));

const TablePaginated = Finder(
  Pagination(({ data, cssForTable, typeOfData, tableComponent, ...rest }) => {
    const TableComponent = tableComponent;
    return !tableComponent ? (
      <Table
        {...rest}
        typeOfData={typeOfData}
        css={cssForTable}
        body={data}
        insertable={false}
      />
    ) : (
      <TableComponent
        {...rest}
        typeOfData={typeOfData}
        css={cssForTable}
        body={data}
        insertable={false}
      />
    );
  }),
  null,
  FiltersFinder
);

export class BasicList extends React.Component {
  constructor(props) {
    super(props);

    const { page, itemsPerPage, itemsShowed } = this.props;

    this.state = {
      page,
      itemsPerPage,
      itemsShowed,
      profileOpened: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    ['page', 'itemsPerPage', 'itemsShowed'].forEach(o => {
      if (prevProps[o] !== this.props[o])
        this.setState({
          [o]: this.props[o],
        });
    });
  }

  isLoggedUser = activeItem => {
    return activeItem && this.props.userMe
      ? activeItem.id === this.props.userMe.id
      : false;
  };

  handleOnSubmit = filters => {
    const { page, itemsPerPage } = this.state;
    this.props.onSubmit({ filters, page, itemsPerPage });
  };

  handleOnPageChange = (page, itemsPerPage, itemsShowed) => {
    this.setState({
      page: page,
      itemsPerPage: itemsPerPage,
      itemsShowed: itemsShowed,
    });
  };

  handleOnProfileEvent = opened => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = data => {
    this.setState({
      profileOpened: true,
      activeItem: data,
    });
  };

  render() {
    const { path, intl, data, entity, entityName, optionsForRow, onRemove, ...rest } = this.props;
    const {
      itemsShowed,
      itemsPerPage,
      page,
      profileOpened,
      activeItem,
      ...restState
    } = this.state;
 
    const OptionsForRow = optionsForRow;
    const OnRemove = onRemove
    return (
      <>
        <TablePaginated
          {...rest}
          {...restState}
          placeholder={intl.formatMessage({ id: 'finder.box.placeholder' })}
          data={data}
          entity={entity}
          typeOfData={entityName}
          optionsForRow={props =>
            optionsForRow ? (
              <OptionsForRow {...props} viewClick={this.handleOpenProfile} />
            ) : (
              <BasicOptionsForRow
                {...props}
                entity={entity}
                entityName={entityName}
                viewClick={this.handleOpenProfile}
              />
            )
          }
          onRemove={(props) =>
            onRemove ? (
            <OnRemove {...props} viewClick={this.handleOpenProfile} />
            ) : (
            <RemoveComponent {...props} typeOfData={{class: entity, nameData: entityName}}/>)}
          onRowClick={this.handleOpenProfile}
        />
        {profileOpened && activeItem && (
          <this.props.profileComponent
            onChange={this.handleOnProfileEvent}
            data={activeItem}
            path={path}
            enableChangePassword={this.isLoggedUser(activeItem)}
          />
        )}
      </>
    );
  }
}

BasicList.propTypes = {
  /* Main data source for the table */
  data: PropTypes.array.isRequired,
  /* Total number of members in the table */
  total: PropTypes.number,
  /* Name of the entity used. Frecuenty used for translations */
  entityName: PropTypes.string,
  /* Entity class or model */
  entity: PropTypes.func,
  /* Entity example. Frequently used for translations */
  exampleEntity: PropTypes.object,
  /* Headers for the table */
  header: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      key: PropTypes.string,
    })
  ).isRequired,
  /* Wizard component to create a new entity */
  createEntityComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /* Filters used for searching */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      value: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  /* If true, Filter component will trigger a external request. If false, filter will filter locally */
  externalFilter: PropTypes.func,
  /* If true, list could be selectable */
  selectable: PropTypes.bool,
  /* Actual page */
  page: PropTypes.number,
  /* Items per page */
  itemsPerPage: PropTypes.number,
  /* Items shown at the moment */
  itemsShowed: PropTypes.number,

  /* Callback that triggers on submit */
  onSubmit: PropTypes.func,

  /* Callback that triggers on page change */
  onPageChange: PropTypes.func,

  /* Table component for using in the list. If there are not one, the Table component will be used */
  tableComponent: PropTypes.func,
};

BasicList.defaultProps = {
  data: [{ text: 'text' }],

  total: 0,

  entityName: 'entity',

  entity: null,

  exampleEntity: null,

  header: {
    label: 'text',
    key: 'text',
  },



  filters: {
    name: 'name',
    value: 'name',
    key: 'name',
  },

  externalFilter: false,

  selectable: true,

  page: 0,

  itemsPerPage: 5,

  itemsShowed: 5,

  onSubmit: () => {},

  onPageChange: () => {},
};

export default injectIntl(BasicList);
