/* eslint-disable import/no-cycle */
import Widget from '../..';
import { LinkedTableConfigurationSheet, MapConfigurationSheet } from './utils/configurationSheet';
import LinkedDefaultConfiguration, { injectLinkedData } from './utils/defaultConfiguration';
import LinkedComponent from './component';
import { validationTable } from '../table/utils/configurationSheet';
import formatToMapWidget from '../map/utils/formatData';
import formatToTableWidget from '../table/utils/formatData';
import CustomWidgetCard from './utils/widgetCard';
import childrenModelParser from './utils/childrenModelsParser';
import { ReactComponent as LinkedIcon } from '../../../../configuration/icons/svg/ico-widget-linked.svg';
import SelectDatasourcesForLinkedWidgetV2 from '../../../../pages/Widgets/Add/steps/SelectDatasourcesForLinkedWidgetV2';
import WidgetPreviewV2 from '../../../../pages/Widgets/Add/steps/WidgetPreviewV2';

export const NAME_WIDGET = 'LINKED';
export default class LinkedWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = LinkedIcon;

  static wizardSteps = {
    SelectDatasourcesForLinkedWidgetV2,
    WidgetPreviewV2,
  };

  static getChildModel(widget, noInstanced) {
    return childrenModelParser(widget, noInstanced);
  }

  skeleton = LinkedIcon;

  defaultConfig = ({ ...LinkedDefaultConfiguration });

  templateConfig = () => null;

  customWidgetCard = CustomWidgetCard;

  isWidgetContainer = true;

  hasMultipleSources = true;

  hasChildrenSameSources = true;

  component = LinkedComponent;

  injectData = () => injectLinkedData(this.sources, this.config)

  instanceChildren = (widgetChildren) => childrenModelParser(widgetChildren);

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => {
    if (this.config.custom.LINKED.mode === 'MAP') {
      return () => null;
    }

    return (config) => validationTable(config);
  }

  getConfigurationSheet(basic = true) {
    const { config } = this;
    const noBasic = config.custom.LINKED.mode === 'MAP' ? MapConfigurationSheet : LinkedTableConfigurationSheet;
    return basic ? '' : noBasic;
  }

  getDefaultConfig() {
    return this.defaultConfig;
  }

  getWidgetChildBodyQuery() {
    this.showError('getWidgetChildBodyQuery', ['dataForQueries']);
  }

  formatToData = (historicalValues, widgetSources) => ({
    MAP: formatToMapWidget(historicalValues, widgetSources),
    TABLE: formatToTableWidget(historicalValues, this.config, widgetSources, true),
  })

  getQueryHistorical() {
    const dataForQueries = this.getEmptyHistoricalBody();
    const datasources = this.getDataForQueries({
      type: 'DEVICE',
      sources: this.sources,
      queryType: 'lastValue',
    });
    dataForQueries[0].sources = datasources;
    return dataForQueries;
  }

  parsedLinkedData = () => null;
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
