import React from 'react';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const Finder = ({ onKeyUp }) => (
  <div className="finderWrapper">

    <h2>
      <FormattedMessage
        id="widgets.profile.devices.and.properties"
      />
    </h2>

    <div>
      <div className="filtersFinder">
        <div className="cerICR">
          <div className="caret">
            <span>
              <span>
                <FormattedMessage
                  id="widgets.profile.name.or.alias"
                />
              </span>
            </span>
            <i className="uil uil-angle-down" />
          </div>
        </div>

        <div className="textField">
          <form onSubmit={() => true}>
            <input
              placeholder="Filtra por nombre o alias"
              onKeyUp={onKeyUp}
              className="input"
            />
          </form>
        </div>
        <i className="uil uil-search" />

      </div>
    </div>

  </div>
);

export default Finder;
