/* eslint-disable import/no-cycle */
import Widget from '../..';
import {
  OnOffConfigurationSheetBasic, OnOffConfigurationSheet, validationOnOff,
  OnOffDefaultTemplateConfig,
} from './utils/configurationSheet';
import OnOffDefaultConfiguration from './utils/defaultConfiguration';
import OnOffComponent from './component';
import { ReactComponent as OnOffWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-onoff.svg';
import SelectDatasources from '../../../../pages/Widgets/Add/steps/SelectDatasourcesV2';
import ConfigureWidgetOnOffV2 from '../../../../pages/Widgets/Add/steps/customSteps/Commands/OnOff/ConfigureWidgetOnOffV2';
import WidgetPreviewV2 from '../../../../pages/Widgets/Add/steps/WidgetPreviewV2';

export const NAME_WIDGET = 'ONOFF';
export default class OnOffWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = OnOffWidgetIcon;

  static wizardSteps = { SelectDatasources, ConfigureWidgetOnOffV2, WidgetPreviewV2 };

  defaultConfig = ({ ...OnOffDefaultConfiguration });

  templateConfig = OnOffDefaultTemplateConfig;

  component = OnOffComponent;

  hasMultipleSources = true;

  needsSocket = false;

  // *! --------------------- [COMMON BODY] --------------------- !* //
  injectData = () => null;

  getValidatorConfig = () => (config) => validationOnOff(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? OnOffConfigurationSheetBasic
      : OnOffConfigurationSheet
  )

  getWidgetChildBodyQuery = () => null;

  getQueryHistorical = () => null;

  parsedLinkedData = () => null;
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
