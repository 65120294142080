import React from 'react';
import { connect } from 'react-redux';

class PrivateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsLogged: false,
      userRoleIsOk: false,
    };
  }

  componentDidMount() {
    const { checkLogin, checkPermission } = this.props;
    checkLogin && this.checkUser();
    checkPermission && this.checkPermission();
  }

  componentDidUpdate(prevProps) {
    const { userMe } = this.props;
    if (userMe !== prevProps.userMe) {
      this.checkPermission();
      this.checkUser();
    }
  }

    checkUser = () => {
      const { userMe } = this.props;
      if (userMe) {
        this.setState({
          userIsLogged: true,
        });
      }
    };

    checkPermission = () => {
      const { microsService, permission } = this.props;
      const { userMe } = this.props;
      if (typeof permission === 'object') {
        let userRoleIsOk = false;

        permission.forEach((p) => {
          if (userMe && userMe.permissions
            && userMe.permissions[microsService] && userMe.permissions[microsService][p]) {
            userRoleIsOk = true;
          }
        });

        this.setState({ userRoleIsOk }, () => {});
      } else if (userMe && userMe.permissions
        && userMe.permissions[microsService] && userMe.permissions[microsService][permission]) {
        this.setState({ userRoleIsOk: true }, () => {});
      }
    };

    render() {
      const { children } = this.props;
      const { userIsLogged, userRoleIsOk } = this.state;
      return (
        (userIsLogged && userRoleIsOk) ? children : null
      );
    }
}

PrivateComponent.defaultProps = {
  microsService: '/app/',
  permission: 'none',
  checkLogin: true,
  checkPermission: false,
};

const mapStateToProps = (state) => ({
  userMe: state.get('users').get('userMe'),
});

export default connect(mapStateToProps, {})(PrivateComponent);
