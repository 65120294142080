export const GET_HISTORICAL = 'GET_HISTORICAL';
export const GET_HISTORICAL_REQUEST = 'GET_HISTORICAL_REQUEST';
export const GET_HISTORICAL_SUCCESS = 'GET_HISTORICAL_SUCCESS';
export const GET_HISTORICAL_ERROR = 'GET_HISTORICAL_ERROR';

export const GET_HISTORICALS_REQUEST = 'GET_HISTORICALS_REQUEST';
export const GET_HISTORICALS_ERROR = 'GET_HISTORICALS_ERROR';
export const GET_HISTORICALS_SUCCESS = 'GET_HISTORICALS_SUCCESS';

export const GET_HISTORICAL_LIST_SUCCESS = 'GET_HISTORICAL_LIST_SUCCESS';
export const GET_HISTORICAL_LIST_REQUEST = 'GET_HISTORICAL_LIST_REQUEST';
export const GET_HISTORICAL_LIST_ERROR = 'GET_HISTORICAL_LIST_ERROR';

export const UPDATE_HISTORICAL_LIST_V2_FROM_SOCKET = 'UPDATE_HISTORICAL_LIST_V2_FROM_SOCKET';

export const GET_HISTORICAL_LIST_V2_SUCCESS = 'GET_HISTORICAL_LIST_V2_SUCCESS';
export const GET_HISTORICAL_LIST_V2_REQUEST = 'GET_HISTORICAL_LIST_V2_REQUEST';
export const GET_HISTORICAL_LIST_V2_ERROR = 'GET_HISTORICAL_LIST_V2_ERROR';

export const GET_HISTORICAL_LIST_PREVIEW_SUCCESS = 'GET_HISTORICAL_LIST_PREVIEW_SUCCESS';
export const GET_HISTORICAL_LIST_PREVIEW_REQUEST = 'GET_HISTORICAL_LIST_PREVIEW_REQUEST';
export const GET_HISTORICAL_LIST_PREVIEW_ERROR = 'GET_HISTORICAL_LIST_PREVIEW_ERROR';

export const GET_MULTIPLE_HISTORICAL_LIST_REQUEST = 'GET_MULTIPLE_HISTORICAL_LIST_REQUEST';
export const GET_MULTIPLE_HISTORICAL_LIST_SUCCESS = 'GET_MULTIPLE_HISTORICAL_LIST_SUCCESS';
export const GET_MULTPLE_HISTORICAL_LIST_ERROR = 'GET_MULTPLE_HISTORICAL_LIST_ERROR';

export const UPDATE_HISTORICAL_LIST_SUCCESS = 'UPDATE_HISTORICAL_LIST_SUCCESS';
export const UPDATE_HISTORICAL_LIST_REQUEST = 'UPDATE_HISTORICAL_LIST_REQUEST';
export const UPDATE_HISTORICAL_LIST_ERROR = 'UPDATE_HISTORICAL_LIST_ERROR';

export const ERRORFETCHING_HISTORICAL_RESET = 'ERRORFETCHING_HISTORICAL_RESET';
