import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: {
        message: "can't be empty",
      },
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
    },
  },
  {
    key: 'description',
    defaultValue: '',
    constraints: {
      length: {
        maximum: 300,
        message: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '300' }}
          />
        ),
      },
    },
  },
  {
    key: 'permissions',
    defaultValue: [],
  },
];

export default ModelExpected;
