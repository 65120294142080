import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Col } from 'reactstrap';
import { colorList } from '../../../../configuration/variables';
import { Components } from '../../../../models/Widget/configurationSheets/utils';

const SelectColor = ({
  colorSelected,
  handleChange,
}) => {

  const [localColorSelected, setLocalColorSelected] = useState(colorSelected);
  const handleContentEditableChange = (name, value, category) => {
    setLocalColorSelected(value);
    handleChange({ name, value}, category, Components.selectColor);
  };

  return (
    <Col className="colorOptions">
      <div className="colorsContainer">
        <div className="colorPickerBody">
          {colorList.map((colorStyle) => {
            if (localColorSelected) {
              return (
                <button
                  className={`colorFrame ${localColorSelected === colorStyle
                    ? 'selectedColor' : 'unselectedColor'}`}
                  onClick={() => handleContentEditableChange('colorSelected', colorStyle, 'colors')}
                  type="button"
                >
                  <div className={`${`colorContent ${colorStyle}`}`} />
                </button>
              );
            }
            return (
              <button
                className="colorFrame"
                onClick={() => handleContentEditableChange('colorSelected', colorStyle, 'colors')}
                type="button"
              >
                <div className={`${`colorContent ${colorStyle}`}`} />
              </button>
            );
          })}
        </div>
      </div>
    </Col>

  );
};

export default injectIntl(SelectColor);
