import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext/index';
import PrivateComponent from '../../../../../components/PrivateComponent/privateComponent';

const fromTo = (
  <FormattedMessage
    id="widgetTable.fromTo"
    defaultMessage="From / To"
    description="Title of fromTo"
  />
);

const lessThan = (
  <FormattedMessage
    id="widgetTable.lessThan"
    defaultMessage="Less than"
    description="Title of lessThan"
  />
);

const greaterThan = (
  <FormattedMessage
    id="widgetTable.greaterThan"
    defaultMessage="Greater than"
    description="Title of greaterThan"
  />
);

const equals = (
  <FormattedMessage
    id="widgetTable.equals"
    defaultMessage="Equals"
    description="Title of equals"
  />
);

const OptionsForRow = ({ onChangeChoice }) => (
  <div className="tableMenu">
    <ul className="list-unstyled">
      <li className="optionTitle">
        <FormattedMessage id="widgetTable.changeTo" />
      </li>
      <PrivateComponent checkLogin checkPermission microsService="/app/widget" permission="update">
        <li
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          onClick={() => { onChangeChoice('fromTo'); }}
          role="presentation"
        >
          {fromTo}
        </li>
      </PrivateComponent>
      <PrivateComponent checkLogin checkPermission microsService="/app/widget" permission="update">
        <li
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          onClick={() => { onChangeChoice('lessThan'); }}
          role="presentation"
        >
          {lessThan}
        </li>
      </PrivateComponent>
      <PrivateComponent checkLogin checkPermission microsService="/app/widget" permission="update">
        <li
          style={{ paddingTop: '5px', paddingBottom: '5px' }}
          onClick={() => { onChangeChoice('greaterThan'); }}
          role="presentation"
        >
          {greaterThan}
        </li>
      </PrivateComponent>
      <PrivateComponent checkLogin checkPermission microsService="/app/widget" permission="update">
        <li
          style={{ paddingTop: '5px' }}
          onClick={() => { onChangeChoice('equals'); }}
          role="presentation"
        >
          {equals}
        </li>
      </PrivateComponent>
    </ul>
  </div>
);

OptionsForRow.propTypes = {
  onChangeChoice: PropTypes.func,
};

OptionsForRow.defaultProps = {
  onChangeChoice: () => {},
};

export default OptionsForRow;
