import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';
import CommonView from '../../../../../CommonView';
import { FieldComponent } from '../helpers';

import { clearDevicesState, getDevices } from '../../../../../../services/redux/devices/actions';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { getGeneralDataForAction } from '../../../../../../data/ruleActions';

class ActionComponent extends CommonView {
  constructor(props) {
    super({ props });
    const { allDevices, newEntity: { devices } } = this.props;
    this.state = {
      device: {},
      attribute: {},
      value: '',
      allDevices: allDevices ? [...allDevices.filter(
        (o) => o.fiware.service === devices[0].fiware.service,
      )] : [],
      page: 1,
      size: 5,
    };
  }

  componentDidMount() {
    const { onRef, actionComponentRef, newEntity: { devices } } = this.props;
    const { page, size } = this.state;
    onRef(this);
    actionComponentRef(this);

    getDevices({
      filters: {
        fiwareService: devices[0].fiware.service,
      },
      page,
      size,
    });
  }

  validate = (callback = null) => {
    const generalActionData = getGeneralDataForAction(this.props.newEntity.actionToCreate);
    const constraints = {};
    ['device', 'attribute', 'value'].forEach((v) => {
      constraints[v] = {
        presence: {
          allowEmpty: false,
          message: (<FormattedMessage
            id="Validation.presence"
          />),
        },
      };
    });

    const errors = validate(this.state, constraints);

    if (errors) {
      this.setState({
        errors,
      });
    } else this.props.saveAction(callback, generalActionData.parseAction(this.state));
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size, device } = this.state;
    const {
      allDevices, newEntity: { devices }, fetching, action,
    } = this.props;

    if (JSON.stringify(prevProps.allDevices) !== JSON.stringify(allDevices)) {
      const newDevice = device
        ? allDevices.find((d) => d.id === device.id)
        : {};

      this.updateAfterAllDevicesChange(allDevices, devices, newDevice);
    }

    if (!fetching && prevState.page < page) {
      getDevices({
        filters: {
          fiwareService: devices[0].fiware.service,
        },
        page,
        size,
      });
    }

    if (JSON.stringify(prevProps.action) !== JSON.stringify(this.props.action)) {
      // happens???
      this.updateAfterActionChanges(action.device, action.attribute, action.value);
    }
  }

  updateAfterAllDevicesChange = (allDevices, devices, newDevice) => {
    this.setState({
      allDevices: [...allDevices.filter((o) => o.fiware.service === devices[0].fiware.service)],
      device: newDevice,
      // command: command
    });
  }

  updateAfterActionChanges = (device, attribute, value) => {
    this.setState({
      device,
      attribute,
      value,
    });
  }

  getOptions = (entities) => (entities && Array.isArray(entities) && entities.length > 0
    ? entities.map((o) => ({ name: o.name, id: o.id, value: o }))
    : []);

  getOption = (entities, entity) => (entities
    && entity
    && Array.isArray(entities)
    && entities.length > 0
    ? entities.find((o) => o.id === entity.id)
    : {})

  isComplete = () => Object.entries(this.state.device).length > 0
    && Object.entries(this.state.command).length > 0
    && this.state.value !== '';

  getCommands = (device) => (device && device.command_attributes
    ? this.getOptions(device.command_attributes)
    : []);

  handleInnerSelectChange = (name, value) => {
    if (name === 'device' && value) {
      this.setState({
        [name]: value,
        attribute: '',
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInnerInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
    );
  };

  joinedAttributes = (device) => (device && Object.entries(device).length > 0
    ? [
      // ...device.command_attributes,
      ...device.lazy_attributes,
      // ...device.static_attributes,
      ...device.attributes,
    ]
    : []);

  handleFetchInfo = () => {
    const { total, allDevices } = this.props;
    if (total > allDevices.length) this.setState((prev) => ({ page: prev.page + 1 }));
  };

  handleOnSearch = (value, init = false) => {
    const { size } = this.state;
    if (init) clearDevicesState();
    getDevices({
      filters: {
        name: value,
        fiwareService: this.props.newEntity.devices[0].fiware.service,
      },
      page: 1,
      size,
    });
  };

  render() {
    const { intl, fetching } = this.props;
    const {
      device, value, attribute, allDevices, errors,
    } = this.state;

    return (
      <div className=" action-component">
        <FieldComponent
          name="device"
          type="select"
          options={this.getOptions(allDevices)}
          intl={intl}
          value={this.getOption(allDevices, device)}
          selectedOption={device}
          onChange={this.handleInnerSelectChange}
          onSearch={this.handleOnSearch}
          fetchInfo={this.handleFetchInfo}
          loading={fetching}
          filterBy="name"
          async
          helperText={errors.device}
          error={!!errors.device}
        />
        <FieldComponent
          name="attribute"
          type="select"
          options={device ? this.getOptions(this.joinedAttributes(device)) : []}
          intl={intl}
          value={attribute}
          onChange={this.handleInnerSelectChange}
          helperText={errors.attribute}
          error={!!errors.attribute}
        />
        <FieldComponent
          name="value"
          type="text"
          intl={intl}
          value={value}
          onChange={this.handleInnerInputChange}
          helperText={errors.value}
          error={!!errors.value}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allDevices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});
export default connect(
  mapStateToProps,
  {},
)(injectIntl(ActionComponent));
