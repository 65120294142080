/* eslint-disable import/no-cycle */
import { typesOfNumericAttributes } from '../../../../../configuration/variables';
import { getUrnId } from '../../../../../helpers/utils';

const formatToHeatMapWidget = (historicalValues, widgetSources) => {
  let min = 9999999999;
  let max = -9999999999;
  const lastValues = historicalValues.filter((h) => h.urn);
  const details = {
    data: [],
    devices: lastValues
      .filter((source) => source.value.type === 'geo:point' || source.value.type === 'geo:json')
      .map((source) => {
        const widgetSource = widgetSources.find((o) => o.id === getUrnId(source.urn));
        return {
          id: widgetSource.id,
          name: widgetSource.name,
          entityId: widgetSource.device_id,
          urn: source.urn,
          attrName: source.attrName,
          categories: widgetSource.categories?.[0],
          location: {
            x: source.value.value.split(',')[0],
            y: source.value.value.split(',')[1],
          },
          attributes: [],
        };
      }),
  };

  widgetSources.forEach((source) => {
    const sourceHistoricals = historicalValues.filter((histVal) => (
      getUrnId(histVal.urn) === source.id));
    const sourceAttributes = source.attributes.filter(
      (attr) => typesOfNumericAttributes.includes(attr.type.toLowerCase()),
    );
    const historicalData = [];
    sourceAttributes.forEach((attribute) => {
      const filtered = sourceHistoricals.find(
        (dt) => (dt.attrName === attribute.name),
      );
      if (filtered) historicalData.push(filtered);
    });

    if (historicalData) {
      let avgPoint = 0;
      historicalData.forEach((point) => {
        avgPoint += Number(point.value.value);
      });
      avgPoint /= historicalData.length;

      const attrGeo = source.attributes.find((attr) => attr.type === 'geo:point' || attr.type === 'geo:json');
      const location = sourceHistoricals.find(
        (dt) => (dt.attrName === attrGeo.name),
      );

      const attributeValues = [];
      historicalValues.forEach((hv) => {
        if (source.id === getUrnId(hv.urn)) {
          attributeValues.push({ attrName: hv.attrName, value: hv.value.value });
        }
      });

      const data = {
        id: source.id,
        attributes: source.attributes,
        attributeValues,
        device_id: source.sourceId,
        device_name: source.name,
        data: {},
        categories: source.categories?.[0],
        location: {
          x: location?.value.value.split(',')[0],
          y: location?.value.value.split(',')[1],
        },
        locationName: attrGeo.name,
        heatmap: {
          value: avgPoint,
        },
      };
      const value = avgPoint;
      if (value > max) {
        max = value;
      }
      if (value < min) {
        min = value;
      }

      details.data.push(data);
    }
  });
  return { data: details, maxValue: max, minValue: min };
};

export default formatToHeatMapWidget;
