/* eslint-disable import/no-cycle */
import { clone } from 'lodash';
import Widget from '../..';
import {
  TableConfigurationSheetBasic, TableConfigurationSheet, validationTable,
  TableDefaultTemplateConfig,
} from './utils/configurationSheet';
import TableDefaultConfiguration, { injectTableData } from './utils/defaultConfiguration';
import TableComponent from './component';
import { ReactComponent as TableWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-table.svg';
import formatToTableWidget from './utils/formatData';

export const NAME_WIDGET = 'TABLE';
export default class TableWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = TableWidgetIcon;

  hasMultipleAttributes = true;

  hasMultipleSources = true;

  templateConfig = TableDefaultTemplateConfig;

  skeleton = TableWidgetIcon;

  defaultConfig = ({ ...TableDefaultConfiguration });

  component = TableComponent;

  // *! --------------------- [COMMON BODY] --------------------- !* //

  injectData = (widget) => injectTableData(widget);

  getValidatorConfig = () => (config) => validationTable(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? TableConfigurationSheetBasic
      : TableConfigurationSheet
  )

  formatToData(historicalValues, widgetSources) {
    return formatToTableWidget(historicalValues, this.config, widgetSources);
  }

  getWidgetChildBodyQuery = (data, { allSourcesParsed }) => {
    const dataForQueries = clone(data);
    dataForQueries[0].sources = allSourcesParsed;
    return dataForQueries;
  }

  getQueryHistorical() {
    const {
      startDate, endDate, data, data: { sampling },
      period,
    } = this.config;
    const dataForQueries = this.getEmptyHistoricalBody();
    if (data.type !== 'real-time') {
      const historicalQueryBody = this.getDataForQueries({
        sources: this.sources,
        queryType: 'aggregate',
        operation: data.operation,
        sampling,
        periodTime: {
          startDate,
          endDate,
          period,
        },
      });
      dataForQueries.push(historicalQueryBody);
    } else {
      const sources = this.getDataForQueries({ sources: this.sources });
      dataForQueries[0].sources = sources;
    }
    return dataForQueries;
  }

  needsSocketForHistorical() {
    return true;
  };
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
