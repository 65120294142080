import { debugMode, requestApiTimeout, requestApiTimeoutHistorical } from '../configuration/config';
// eslint-disable-next-line import/no-cycle
import { getToken, getXDataAccessToken } from '.';

// React has fetch by default, but test environment on node.js (Jest) does not.
// So, for debugging purposes in Jest, it must be imported as follows:
// const fetch = require('node-fetch');
const parseJSON = async (obj) => {
  const objStr = await obj.text();

  try {
    return JSON.parse(objStr);
  } catch (e) {
    return objStr;
  }
};

export const request = (params, exampleBodyFn, token, isPublic) => {
  const defaultHeaders = { 'Content-Type': 'application/json' };

  if (!isPublic) defaultHeaders['X-Auth-token'] = token;
  else defaultHeaders['x-data-access'] = token;

  const { endPoint, statusOK, requestConfig = {} } = params;
  const body = requestConfig.body ?? undefined;
  const method = requestConfig.method ?? 'GET';
  const headers = requestConfig.headers
    ? { ...requestConfig.headers, ...defaultHeaders }
    : defaultHeaders;

  if (debugMode) {
    return ({
      status: statusOK,
      data: typeof exampleBodyFn === 'function' ? exampleBodyFn(body) : exampleBodyFn,
    });
  }

  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    const { signal } = controller; // get signal

    const timeoutId = setTimeout(() => controller.abort(), endPoint.includes('historical')
      ? requestApiTimeoutHistorical : requestApiTimeout);

    fetch(endPoint, {
      body,
      headers,
      method,
      signal,
    })
      .then(async (response) => {
        clearTimeout(timeoutId);
        const { status } = response;
        // eslint-disable-next-line no-param-reassign
        response = await parseJSON(response);
        if (Array.isArray(statusOK) ? ![...statusOK].includes(status) : statusOK !== status) {
          // eslint-disable-next-line no-throw-literal
          throw ({ status, errors: response.errors });
        }
        resolve({ status: statusOK, data: response });
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        reject(error);
      });
  });
};

export default async (params, exampleBodyFn, isPublic) => {
  const token = !isPublic ? getToken() : getXDataAccessToken();
  return request(params, exampleBodyFn, token, isPublic);
};
