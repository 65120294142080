import React from 'react';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { AlertRow } from './severitySelect';
import Chip from '../../../elements/Chip';

const parseToTag = (value) => {
  const regExp = new RegExp(/\${[^{.}]*.{1}[^{.}]*}/);
  const parts = value.split(/\s+/);
  const parsedValue = [];
  parts.forEach((p) => {
    if (regExp.test(p)) {
      const withoutBrackets = p.substring(2, p.length - 1);
      const withDoubleDots = withoutBrackets.replace('.', ':');
      parsedValue.push(<Chip text={withDoubleDots} dismissible={false} />);
      parsedValue.push(' ');
    } else {
      parsedValue.push(p);
      parsedValue.push(' ');
    }
  });

  return parsedValue;
};

const getBriefComponent = (array, action) => array.map((key) => (
  <dl key={key}>
    <dt><FormattedMessage id={`rules.wizard.${key}.label`} /></dt>
    <dd>{action[key]}</dd>
  </dl>
));

const getUpdateBriefComponent = (action) => (
  <>
    <dl>
      <dt><FormattedMessage id="rules.wizard.device.label" /></dt>
      <dd>{action.deviceName}</dd>
    </dl>
    { getBriefComponent(['name', 'value'], action.attributes[0]) }
  </>
);

const getEmailBriefComponent = (action) => (
  <>
    { getBriefComponent(['from', 'to', 'subject'], action) }
    <dl>
      <dt><FormattedMessage id="rules.wizard.template.label" /></dt>
      <dd>{parseToTag(action.template)}</dd>
    </dl>
  </>
);

const getAlertBriefComponent = (action) => (
  <>
    {getBriefComponent(['name'], action)}
    <dl>
      <dt><FormattedMessage id="rules.wizard.description.label" /></dt>
      <dd>{parseToTag(action.description)}</dd>
    </dl>
    <dl>
      <dt><FormattedMessage id="rules.wizard.severity.label" /></dt>
      <dd><AlertRow item={{ value: action.severity.toLowerCase() }} /></dd>
    </dl>
  </>
);

const getTwitterBriefComponent = (action) => (
  <>
    <dl>
      <dt><FormattedMessage id="rules.wizard.template.label" /></dt>
      <dd>{parseToTag(action.template)}</dd>
    </dl>
  </>
);

const getSendCommandBriefComponent = (action) => (
  <>
    <dl>
      <dt><FormattedMessage id="rules.wizard.deviceId.label" /></dt>
      <dd>{action.deviceId}</dd>
    </dl>
    <dl>
      <dt><FormattedMessage id="rules.wizard.command.name.label" /></dt>
      <dd>{action.command.name}</dd>
    </dl>
    <dl>
      <dt><FormattedMessage id="rules.wizard.command.value.label" /></dt>
      <dd>{action.command.value}</dd>
    </dl>
  </>
);

export {
  getAlertBriefComponent,
  getUpdateBriefComponent,
  getEmailBriefComponent,
  getTwitterBriefComponent,
  getSendCommandBriefComponent,
  parseToTag,
};
export default getBriefComponent;
