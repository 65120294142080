import { requestAPI } from './../../helpers'
import { userManagement } from '../../configuration/config'

const endPoint = userManagement.urlAPI.concat(userManagement.paths.user);

export const loginUser = (user) => {
    const params = {
        endPoint: endPoint + '/login',
        statusOK: 201,
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(user)
        }
    };
    return requestAPI(params, {}, true);
};
