const mapStyles = {
  fiwooDevice: "mapbox://styles/mapbox/light-v9",
  light: "mapbox://styles/mapbox/light-v9",
  dark: "mapbox://styles/mapbox/dark-v9",
  basic: "mapbox://styles/mapbox/basic-v9",
  outdoor: "mapbox://styles/mapbox/outdoors-v10",
  sat: "mapbox://styles/mapbox/satellite-v9",
  city: "mapbox://styles/mapbox/basic-v9",
  default:"mapbox://styles/mapbox/basic-v9",
  silver: "mapbox://styles/mapbox/light-v9",
};

export { mapStyles };
