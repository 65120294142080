import React from 'react';
import User, { SampleUser } from '../../../../models/User/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Field from '../../../../elements/Field/index';
import CommonView from '../../../CommonView';
import { injectIntl } from 'react-intl';
import { additionalPassConstraint } from '../../../../models/User/attributes';
import Alert from '../../../../components/Alert/Alert';
import { ButtonCustom } from '../../../../elements/Button';
import { clone } from 'ramda';
import { connect } from 'react-redux';


class ChangePassword extends CommonView {

  constructor(props) {
    super({ props });

    this.state = {
      errors: this.props.errors || '',
      onEditPassword: this.props.onEditPassword,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.changedPassword && this.props.changedPassword !== prevProps.changedPassword) {
      this.setState({
        success: true,
        errorFetching: false,

      }, () =>
        setTimeout(
          () =>
            this.setState({
              onEditPassword: false,
              success: false,
              oldPassword: '',
              newPassword: '',
              repeatNewPassword: '',
              errors: ''
            }),
          5000
        ))
    }

    if (this.props.errorFetching && this.props.errorFetching !== prevProps.errorFetching) {
      this.setState({
        onEditPassword: true,
        errorFetching: true,
        success: false,
      })
    }
  }


  checkFields() {
    const constraintPass = additionalPassConstraint.find(
      x => x.key === 'password'
    ).constraints.length;
    let errorsLength = [];

    //Check equality between new and repeatNew passwords
    if (this.state.newPassword !== this.state.repeatNewPassword) {
      this.setErrors({
        newPassword: (
          <FormattedMessage id="users.changePassword.missMatchError" />
        ),
        repeatNewPassword: (
          <FormattedMessage id="users.changePassword.missMatchError" />
        )
      });
      return false;
    }

    //Check constraint of pressence and length of all fields
    ['oldPassword', 'newPassword', 'repeatNewPassword'].forEach(key => {
      if (
        this.state[key] === null ||
        this.state[key] === '' ||
        this.state[key].length < constraintPass.minimum
      ) {
        errorsLength[key] = constraintPass.message;
      }
    });

    if (Object.keys(errorsLength).length) {
      this.setErrors({ ...errorsLength });
      return false;
    }
    //If arrive here, everything went ok
    return true;
  }

  changePassword = () => {
    const { user } = this.props;
    const { oldPassword, newPassword, repeatNewPassword } = this.state;
    this.setErrors({});

    if (this.checkFields()) {
      let NewUser = new User(clone(user));
      NewUser.oldPassword = oldPassword;
      NewUser.newPassword = newPassword;
      NewUser.repeatNewPassword = repeatNewPassword;
      const updatedPass = NewUser.changePassword();
      if (updatedPass.error) {
        this.setErrors(updatedPass);
      }
    }
  };

  render() {
    const { intl } = this.props;
    const { errors, onEditPassword, success, errorFetching } = this.state;

    return (
      <>
        {!onEditPassword && <>
          <dt>{intl.formatMessage(SampleUser.plainTranslations.password)}</dt>
          <dd>*********</dd>
          <ButtonCustom
            label={<FormattedMessage id={'users.changePassword.button'} />}
            type={'secondary'}
            handleOnClick={() =>
              this.setState({
                onEditPassword: true
              })
            }
          /></>}

        {onEditPassword && (
          <>
            <dt>{intl.formatMessage(SampleUser.plainTranslations.password)}</dt>
            {['oldPassword', 'newPassword', 'repeatNewPassword'].map(
              (key, i) => (
                <div className="fieldGroup w-50" key={i}>
                  <Field
                    name={key}
                    placeholder={intl.formatMessage({
                      id: `users.changePassword.${key}`
                    })}
                    id={key}
                    value={this.state[key]}
                    type={'password'}
                    onChange={this.handleInputChange}
                    helperText={errors[key]}
                    error={!!errors[key]}
                    key={`${key}-${i}`}
                    mode={'edit'}
                    save={this.handleOnSave}
                    autoComplete={'new-password'}
                  />
                  <i className="uil uil-edit-alt"></i>
                </div>
              )
            )}
            {success && (
              <Alert
                text={intl.formatMessage({
                  id: 'users.changePassword.success'
                })}
                mode="success"
              />
            )}
            {errorFetching && (
              <Alert
                text={intl.formatMessage({
                  id: 'users.changePassword.error'
                })}
                mode="danger"
              />
            )}
            <ButtonCustom
              label={<FormattedMessage id={'users.changePassword.button'} />}
              type={'primary'}
              handleOnClick={() => this.changePassword()}
            />
            <ButtonCustom
              label={<FormattedMessage id={'users.changePassword.cancel'} />}
              type={'secondary'}
              handleOnClick={() =>
                this.setState({
                  onEditPassword: false
                })
              }
            />{' '}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  changedPassword: state.get('users').get('changedPassword'),
  errorFetching: state.get('users').get('errorFetching')
});

export default connect(
  mapStateToProps,
  {}
)(injectIntl(ChangePassword));
