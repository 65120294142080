import { validate } from 'validate.js';
// eslint-disable-next-line import/no-cycle
import { deleteTemplate, addTemplate, updateTemplate } from '../../services/redux/templates/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';

// Class Template with Utils functions
export default class Template extends Utils {
  static entityName = 'Template';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = Template;
    if (this.categories.length === 0) {
      this.categories.push('OTHER');
    }
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      if (d === 'fiware') {
        filteredConstraints['fiware.service'] = this.constraints[d];
      } else {
        filteredConstraints[d] = this.constraints[d];
      }
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? validation
      : { error: true, ...validation };
  }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('Template', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);

    /** Call Redux action for Save Template in DB and Redux */
    save() {
      // const filteredConstraints = clone(this.constraints);
      // delete filteredConstraints.id;
      // const validation = validate(this.getData(), filteredConstraints);
      //
      // return validation === undefined
      //     ? addTemplate({
      //         ...this.getData()
      //     })
      //     : { error: true, ...validation };

      addTemplate({
        ...this.getData(),
      });
    }

    /** Call Redux action for Update Template in DB and Redux */
    update() {
      const validation = this.validate(Object.keys(this.getData()));
      return validation === undefined
        ? updateTemplate({
          ...this.getData(),
        })
        : { error: true, ...validation };
    }

    delete() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? deleteTemplate(this.getData()) : {
        error: true, ...validation,
      };
    }
}
const SampleTemplate = new Template();
export { SampleTemplate };
