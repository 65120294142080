import React from 'react'
import Styled from 'styled-components'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { typography } from '../../configuration/fonts';
import colors from '../../configuration/colors';

const CrumbStyle = (component) => Styled(component)`
    & , & + span {
        font-family: ${typography};
        font-size: 0.933em;
        font-weight: 600;
        line-height: 1.2em;
        text-align: right;
        color: ${colors['ui-Black']};
        text-transform: capitalize;
    }
    
    ${p => p.last && `
        &:hover{
            cursor: pointer;
            color: blue;
        }
    `}
`;

class Index extends React.PureComponent {
    handleOnClick = (e, path = this.props.path) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.props.last)
            navigate(path);
    };

    render() {
        const { name, path, last } = this.props;
        const CrumbComponent = CrumbStyle(last ? 'span' : 'a');
        return (
            <>
                <CrumbComponent
                    href={last ? null : path}
                    last={!last}
                    onClick={this.handleOnClick}
                >
                    {name}
                </CrumbComponent>
                {!last && <span> {'/'} </span>}
            </>
        )
    }
}

Index.defaultProps = {
    last: false,
    name: 'name',
    path: '/previous/name'
};

Index.propTypes = {
    /** Identify if the Crumb is the last, for not include link and apply different styles. */
    last: PropTypes.bool,

    /** Crumb name to show user*/
    name: PropTypes.string,

    /** Link where go*/
    path: PropTypes.string
};

export default Index;