import React from 'react';
import Styled from 'styled-components';
import Icon from '../../elements/Icons/index';

const Conjuntion = Styled('span')`
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #9DA7B0;
`;

const ExpandIcon = (Childs) => {
  class InnerExpandIcon extends React.PureComponent {
    render() {
      const {
        expandLessIcon, expandMoreIcon, revert, ...rest
      } = this.props;
      let { isOpen } = this.props;
      isOpen = revert ? !isOpen : isOpen;
      const iconToShow = isOpen ? expandLessIcon : expandMoreIcon;
      return (
        <Conjuntion>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {typeof Childs === 'string' ? Childs : <Childs className="expandIconText" {...rest} /> }
          <Icon svg={iconToShow} />
        </Conjuntion>
      );
    }
  }

  InnerExpandIcon.defaultProps = {
    isOpen: false,
    revert: false,
    expandMoreIcon: 'ExpandMore',
    expandLessIcon: 'ExpandLess',
  };

  return InnerExpandIcon;
};
export default ExpandIcon;

export const WithExpandIcon = ExpandIcon('With Expand Icon');
