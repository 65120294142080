import * as TYPES from './types';
import { store } from '../store';

export const getAlert = alert =>
  store.dispatch({
    type: TYPES.GET_ALERT_REQUEST,
    payload: alert,
  });

export const getAlerts = filters =>
  store.dispatch({
    type: TYPES.GET_ALERTS_REQUEST,
    payload: filters,
  });

export const errorFetchingAlertReset = () =>
  store.dispatch({
    type: TYPES.ERRORFETCHING_ALERT_RESET,
  });

export const clearAlertsState = () =>
  store.dispatch({
    type: TYPES.CLEAR_ALERT_SUCCESS,
  });
