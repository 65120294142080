/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { PropTypes } from 'prop-types';
import { clone } from 'ramda';
import BasicInfo from '../../../../../pages/Widgets/Add/steps/basicInfo';
import SelectDatasources from '../../../../../pages/Widgets/Add/steps/SelectDatasourcesV2';
import ConfigureWidgetV2 from '../../../../../pages/Widgets/Add/steps/ConfigureWidgetV2';
import WidgetPreviewV2 from '../../../../../pages/Widgets/Add/steps/WidgetPreviewV2';
import Success from '../../../../../pages/Widgets/Add/steps/Success';
import { isMobile } from '../../../../../helpers/getBreakpoint';
import PrivateComponent from '../../../../../components/PrivateComponent/privateComponent';
import CreateWidgteIcon from '../../../../../configuration/icons/svg/ico-widget.svg';
import DeviceIcon from '../../../../../configuration/icons/svg/ico-device.svg';
import WizardStep from '../../../../../components/Wizard/components/WizardStep';
import Dashboard from '../../../../Dashboard/index';
import '../../../../../pages/Widgets/Add/styles.scss';
import Wizard from '../../../../../components/Wizard';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import ButtonOutline from '../../../../../elements/ButtonOutline';
import { getDefaultMeasure } from '../../../../../pages/Dashboards/WidgetDefaultMeasures';
import AssignPermissions from '../../../../../pages/helpers/AssignPermissions';
import { cloneInstance } from '../../../../../helpers/utils';
import ImageKpi from '../widgets/kpi';
import getChildrenImageWidgetModel from './childrenModelsParser';

const addWidgetFormZeroLabel = (
  <FormattedMessage id="widgetImage.button.addWidget.numeric" />
);

const ButtonOpen = ({ showModal, customButton }) => {
  if (customButton) {
    const {
      disabled, label, bgwhite, ico,
    } = customButton;
    return (
      <ButtonOutline
        onClick={showModal}
        disabled={disabled}
        label={label}
        bgwhite={bgwhite}
        ico={ico}
      />
    );
  }
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/widget"
        permission="create"
      >
        <p onClick={showModal}>{addWidgetFormZeroLabel}</p>
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/widget"
      permission="create"
    >
      <button type="button" onClick={showModal}>{addWidgetFormZeroLabel}</button>
    </PrivateComponent>
  );
};

class CreateWidgets extends React.PureComponent {
  updateImageWidget = (newWidget) => {
    const { dashboard, widget } = this.props;
    const newDashboard = new Dashboard(dashboard);
    const newImageWidget = cloneInstance(widget);

    if (!newImageWidget.config.appearance.layoutProperties) {
      newImageWidget.config.appearance.layoutProperties = {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      };
    }
    const defaultMeasures = getDefaultMeasure(
      newWidget,
      newImageWidget.config.appearance.layoutProperties,
    );

    Object.keys(newImageWidget.config.appearance.layoutProperties).forEach((size) => {
      if (newImageWidget.config.appearance.layoutProperties[size]) {
        newImageWidget.config.appearance.layoutProperties[size].push(defaultMeasures[size]);
      } else {
        (newImageWidget.config.appearance.layoutProperties[size] = [defaultMeasures[size]]);
      }
    });
    newImageWidget.update(newImageWidget);
    newDashboard.widgets.push(newWidget);
    newDashboard.update();
  };

  handleOnSave = (state, entity, onContinue) => {
    const { widgetImageId } = this.props;
    const newWidget = clone(state.newEntity);
    newWidget.widgetType = 'TEXT_ACCOUNTANT';
    newWidget.container = widgetImageId;
    newWidget.typeContainer = 'IMAGE';
    newWidget.sources = newWidget.sources.map((source) => {
      const d = {
        urn: `fiwoo:${source.type.toLowerCase()}:${source.id}`,
        fields: [],
      };

      source.joinedAttributes.forEach((attribute) => {
        if (attribute.selected) d.fields.push(attribute.name);
      });
      return d;
    });

    const widgetToSave = getChildrenImageWidgetModel(newWidget);
    widgetToSave.save(this.updateImageWidget);
    onContinue();
  }

  render() {
    const { customButton, setPressed } = this.props;
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        customButton={customButton}
        entity={ImageKpi}
        onSave={this.handleOnSave}
      >

        <WizardStep
          component={BasicInfo}
          title={<FormattedMessage id="widgets.wizard.step1.title" />}
          icon={CreateWidgteIcon}
          lead={<FormattedMessage id="widgets.wizard.step1.text" />}
          extraActionOnEnter={setPressed}
        />

        <WizardStep
          component={SelectDatasources}
          title={<FormattedMessage id="widgets.wizard.step2.title" />}
          icon={DeviceIcon}
          lead={<FormattedMessage id="widgets.wizard.step2.text" />}
        />

        <WizardStep
          component={ConfigureWidgetV2}
          title={<FormattedMessage id="widgets.wizard.step4.title" />}
          lead={<FormattedMessage id="widgets.wizard.step4.text" />}
        />

        <WizardStep
          component={WidgetPreviewV2}
          title={<FormattedMessage id="widgets.wizard.step5.title" />}
          lead={<FormattedMessage id="widgets.wizard.step5.text" />}
        />

        <WizardStep
          component={AssignPermissions}
          title={<FormattedMessage id="widgets.wizard.step6.title" />}
          lead={<FormattedMessage id="widgets.wizard.step6.text" />}
          onSave={this.handleOnSave}
        />

        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateWidgets;
