import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import {
  addDeviceSuccessFlag,
  errorFetchingDeviceReset,
} from '../../../services/redux/devices/actions';
import Field from '../../../elements/Field';
import CommonView from '../../CommonView';
import { protocolData } from '../../../data/protocols';
import { withWizardStep } from '../../../Contexts/WizardStepContext';
import {
  addServiceSuccessFlag,
  errorFetchingServiceReset,
} from '../../../services/redux/services/actions';

const getFiwareService = (fiware) => {
  let res = '';
  if (fiware) {
    if (fiware.service) res = fiware.service;
    else res = '';
  }
  return res;
};

const getExtraConfiguration = (entity) => {
  if (entity.protocol === 'LORA' && !entity.extraConfigurations?.data_model) {
    return {
      ...entity.extraConfigurations,
      data_model: entity.internal_attributes?.lorawan?.data_model,
    };
  }
  return entity.extraConfigurations ?? {};
};

export class ConfigureProtocol extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      protocol: this.props.newEntity.protocol || '',
      transport: this.props.newEntity.transport || '',
      fiware: { service: getFiwareService(this.props.newEntity.fiware) },
      errors: this.props.errors,
      sampleEntity: new this.props.entity(),
      extraConfigurations: getExtraConfiguration(this.props.newEntity),
    };
  }

  validate = () => {
    this.saveEntity();
  };

  saveEntity = () => {
    const { protocol, transport, extraConfigurations } = this.state;

    this.setErrors({});

    let created;
    const obj = this.state;
    const newEntity = new this.props.entity(obj);

    const validator = this.state.protocol !== '' ? this.getProtocolValidator(protocol) : undefined;

    if (validator) {
      created = validator(newEntity, this.state.extraConfigurations, this.props.entity);
    } else {
      if (['LORA', 'SIGFOX'].includes(protocol)) {
        newEntity.transport = 'OWN TRANSPORT';
      }
      created = newEntity.validate(['protocol', 'transport', 'fiware', 'extraConfigurations']);
    }

    if (created) {
      delete created.error;
      this.setState({
        errors: created,
      });
      this.props.onError(created.error);
    } else {
      const { errors, ...rest } = this.state;
      let data = { ...rest };

      if (protocol !== this.props.newEntity.protocol || transport !== this.props.newEntity.transport
        || extraConfigurations !== this.props.newEntity.extraConfigurations) {
        data = { ...data, ...this.cleanAttributes() };
      }
      this.props.onContinue(data);
    }
  };

  cleanAttributes = () => ({
    internal_attributes: [],
    attributes: [],
    static_attributes: [],
    lazy_attributes: [],
    command_attributes: [],
    allAttributes: [],
  })

  componentDidUpdate(prevProps) {
    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach((err) => {
          errors[err.field] = <FormattedMessage id={err.message} />;
        });
        this.setErrors(errors);
        this.errorFetchingEntityReset();
      }
    }

    if (this.props.addSuccess) {
      this.addEntitySuccessFlag();
    }
  }

  errorFetchingEntityReset() {
    const { entityName } = this.props.entity;
    if (entityName === 'Device') {
      errorFetchingDeviceReset();
    } else if (entityName === 'Service') {
      errorFetchingServiceReset();
    }
  }

  addEntitySuccessFlag() {
    const { entityName } = this.props.entity;
    if (entityName === 'Device') {
      addDeviceSuccessFlag();
    } else if (entityName === 'Service') {
      addServiceSuccessFlag();
    }
  }

  handleInputChangeFiware = (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      fiware: { service: value },
    });
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  getProtocolExtraConfigurations = (protocol) => protocolData.protocol
    .find((p) => p.value === protocol).extraConfigurations;

  getProtocolValidator = (protocol) => protocolData.protocol
    .find((p) => p.value === protocol).validator;

  getProtocolExtraConfigurationsComponent = (protocol) => {
    const ExtraConfigurationsComponent = protocolData.protocol.find(
      (p) => p.value === protocol,
    ).extraConfigurationsComponent;

    return ExtraConfigurationsComponent ? (
      <ExtraConfigurationsComponent
        protocol={protocol}
        onChange={this.handleProtocolExtraConfigurationsChange}
        errors={this.state.errors}
        newEntity={{...this.props.newEntity, ...this.state}}
        entity={this.props.entity}
      />
    ) : null;
  };

  handleProtocolInput = (data) => {
    const { target } = data;
    const { value } = target;
    const { name } = target;

    this.setState({
      extraConfigurations: { ...this.state.extraConfigurations, [name]: value },
    });
  };

  handleProtocolSelect = (name, value) => {
    this.setState({
      extraConfigurations: { ...this.state.extraConfigurations, [name]: value },
    });
  };

  handleProtocolExtraConfigurationsChange = (type, data, selectData) => {
    switch (type) {
      case 'select':
        return this.handleProtocolSelect(data, selectData);
      case 'text':
      case 'password':
        return this.handleProtocolInput(data);
      default:
        return null;
    }
  };

  render() {
    const { intl } = this.props;
    const { errors, sampleEntity, protocol } = this.state;
    const entityName = Object.entries(sampleEntity.plainTranslations)[0][1]
      .id.toLowerCase()
      .split('.')[0]
      .concat('s');

    return (
      <>
        <div className="wizardWrapper">
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                {['protocol'].map((key, i) => (
                  <Field
                    name={key}
                    label={intl.formatMessage(
                      sampleEntity.plainTranslations[key],
                    )}
                    placeholder={intl.formatMessage({
                      id: `${entityName}.wizard.${key}.placeholder`,
                    })}
                    id={key}
                    value={this.state[key]}
                    type="select"
                    onChange={this.handleSelectChange}
                    helperText={errors[key]}
                    error={!!errors[key]}
                    key={`${key}-protocol-${i}`}
                    options={protocolData[key]}
                  />
                ))}

                {protocol
                  && protocolData.protocol.find((p) => p.value === protocol)
                    .transports
                  && ['transport'].map((key, i) => (
                    <Field
                      name={key}
                      label={intl.formatMessage(
                        sampleEntity.plainTranslations[key],
                      )}
                      placeholder={intl.formatMessage({
                        id: `${entityName}.wizard.${key}.placeholder`,
                      })}
                      id={key}
                      value={this.state[key]}
                      type="select"
                      onChange={this.handleSelectChange}
                      helperText={errors[key]}
                      error={!!errors[key]}
                      key={`${key}-transport-${i}`}
                      options={
                        protocolData.protocol.find((p) => p.value === protocol)
                          .transports
                      }
                    />
                  ))}

              </Col>
              <Col lg={{ size: 8, offset: 2 }} className="extraConfigurationsZone" id="configZone">
                {protocol && this.getProtocolExtraConfigurations(protocol) && (
                  <>
                    {this.getProtocolExtraConfigurationsComponent(protocol)
                      ? this.getProtocolExtraConfigurationsComponent(protocol)
                      : this.getProtocolExtraConfigurations(protocol).map(
                        (configuration, i) => (
                          <Field
                            key={`extra-configurations-${i}`}
                            type={configuration.type}
                            name={configuration.value}
                            label={intl.formatMessage({
                              id: `allEntities.wizard.${configuration.value}.label`,
                            })}
                            placeholder={intl.formatMessage({
                              id: `allEntities.wizard.${configuration.value}.placeholder`,
                            })}
                            onChange={
                              this.handleProtocolExtraConfigurationsChange
                            }
                            error={!!errors[configuration.value]}
                            helperText={errors[configuration.value]}
                          />
                        ),
                      )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ConfigureProtocol.defaultProps = {
  errors: {},
};

export default withWizardStep(injectIntl(ConfigureProtocol));
