import React from 'react';
import RemoveHoc from './../../helpers/RemoveComponent';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const View = (
  <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const BasicOptionsForRows = ({ rowContent, viewClick, entity, entityName }) => {
  const ButtonOpen = ({ showModal }) => {
    return <li onClick={showModal}>{Delete}</li>;
  };

  const RemoveDOM = RemoveHoc(ButtonOpen, { class: entity, nameData: entityName });

  return (
    <div className="tableMenu">
      <ul className="list-unstyled">
        <li
          onClick={() => {
            viewClick(rowContent);
          }}
          state={{ [entityName]: rowContent }}
        >
          {View}
          <i className="uil uil-arrow-right"/>
        </li>
        <li className="divider"></li>
        <li
          onClick={() => {
            viewClick(rowContent);
          }}
          state={{ [entityName]: rowContent, mode: 'edit' }}
        >
          {Edit}
        </li>
        <RemoveDOM rowContent={rowContent} />
        {/* <li onClick={() => {}} state={{ user: rowContent }}>{Clone}</li> */}
      </ul>
    </div>
  );
};

export default BasicOptionsForRows;
