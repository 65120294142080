import validate from 'validate.js';
import { pick } from 'ramda';

import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';

import {
  getReport, addReport, deleteReports, updateReport, generateReports
} from '../../services/redux/reports/actions';


/** Class Report with Utils functions */
export default class Report extends Utils {
  static entityName = 'Report';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = Report;
  }

  validate(data) {
    const filteredConstraints = pick(data, this.constraints);
    const validation = validate(this.getData(), filteredConstraints);

    return validation === undefined
      ? undefined
      : { error: true, ...validation };
  }

  get() {
    const validation = validate(
      this.getData(),
      { id: this.constraints.id },
    );

    return validation === undefined
      ? getReport(this.getData())
      : { error: true, ...validation };
  }

  save() {
    addReport({
      ...this.getData(),
    });
  }

  update() {
    const validation = this.validate(Object.keys(this.getData()));
    return validation === undefined
      ? updateReport({ ...this.getData() })
      : { error: true, ...validation };
  }

  delete() {
    const validation = validate(
      this.getData(), { id: this.constraints.id },
    );
    return validation === undefined
      ? deleteReports(this.getData())
      : { error: true, ...validation };
  }

  generate() {
    const validation = validate(
      this.getData(), { id: this.constraints.id },
    );
    return validation === undefined
      ? generateReports(this.getData())
      : { error: true, ...validation };
  }

  plainTranslations = CreateTranslationPlainForModels('Report', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}

const SampleReport = new Report();
export { SampleReport };
