import React from 'react';
import CommonView from '../../../../../CommonView';
import TabComponent from '../../../../../../components/Tab/index';
import EmailComponent from './EmailComponent';

class NotificationComponent extends CommonView {
  constructor(props) {
    super({ props });
  }


  render() {
    return (
      <>
        <TabComponent titles={['Email']}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <EmailComponent {...this.props} />
        </TabComponent>
      </>
    );
  }
}

export default NotificationComponent;
