import React, { memo, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import colors from '../../../../configuration/colors';
import { typography } from '../../../../configuration/fonts';

const Bars = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetBarChart })),
);

function BarsComponent(props) {
  const {
    values, widget, onChangeHistoricalConfig, intl, globalSelection,
  } = props;

  const Skeleton = useMemo(() => widget.skeletonComp, []);

  const configTheme = {
    name: 'fiwoo',
    colors,
    typography,
  };

  const localeTranslate = (key, defaultValue) => (
    intl ? intl.formatMessage({ id: key }) : defaultValue
  );

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    widget.config?.data,
    globalSelection,
  ]);

  if (values === undefined) {
    return <Skeleton />;
  }

  return (
    <Bars
      onTranslate={localeTranslate}
      height="100%"
      details={values}
      title={widget.name}
      theme={configTheme}
      config={widget.config}
    />
  );
}

export default memo(injectIntl(BarsComponent));
