import React, { memo, useEffect, useMemo } from 'react';

const Needle = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetNeedle })),
);

function NeedleComponent(props) {
  const {
    values, widget, onChangeHistoricalConfig, globalSelection,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Skeleton = useMemo(() => widget.skeletonComp, []);

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.config?.attributeFilter, widget?.sources]);

  if (values === undefined) {
    return <Skeleton />;
  }
  return (
    <Needle
      value={values}
      config={widget?.config}
      widget={widget}
      selectedDevices={globalSelection?.length ?? 0}
    />
  );
}

export default memo(NeedleComponent);
