import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../components/Menu';
import OptionsForDashboard from './OptionsForDashboard';
import './styles.scss';

const DasboardOptionsMenu = (props) => {
  const { title, date } = props;

  // eslint-disable-next-line no-shadow
  const openButton = (props) => (

    <div className="row">
      <div
        className="dashdoardTitle"
        onClick={props.onClick}
        role="presentation"
      >
        <span className="titleText">
          {title}
          <i className="uil uil-angle-down" />
          {' '}

        </span>

      </div>
      <div className="rectangle">
        {' '}
        <p className="dateText">
          {date}
          {' '}
        </p>
        {' '}
      </div>
    </div>

  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="top left"
      className="more"
    >
      <OptionsForDashboard />
    </Menu>
  );
};

DasboardOptionsMenu.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  onClick: PropTypes.func,
};

DasboardOptionsMenu.defaultProps = {
  title: '',
  date: '',
  onClick: () => {},
};

const DashboardMenuTittle = (props) => {
  const { title, date } = props;

  return (
    <DasboardOptionsMenu title={title} date={date} />

  );
};

DashboardMenuTittle.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
};

DashboardMenuTittle.defaultProps = {
  title: '',
  date: '',
};

export default DashboardMenuTittle;
