import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import {
  Components, categories, options, valuesData, getCategory,
} from '../utils';

export default {
  unit: [{
    name: 'unit',
    component: Components.text,
    categories: [categories.LABELS],
  }],

  extraElements: [
    {
      name: 'iconCategory',
      component: Components.switch,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'showHistorical',
      component: Components.kpiHistoricalSwitch,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'showIncrement',
      component: Components.switch,
      categories: [categories.APPEARANCE],
    },
  ],

  colourOfElements: [
    {
      name: 'iconColor',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
    {
      name: 'negativeColor',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
    {
      name: 'positiveColor',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
  ],

  sampling: [{
    name: 'sampling',
    component: Components.select,
    categories: [categories.DATA],
    options: options.sampling,
  }],
};

export const TextNumberConfigurationSheetBasic = {
  unit: [{
    name: 'unit',
    component: Components.text,
    categories: [categories.LABELS],
  }],
};

export const validationTextNumber = (type, config) => {
  const constraints = {};
  valuesData[type].forEach((property) => {
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }
    constraints[`${category}.${property}`] = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };
  });

  return validate(config, constraints);
};
