import React, { useState } from 'react';
import { navigate, Link } from '@reach/router';
import { injectIntl } from 'react-intl';
import { Avatar } from '../../elements/Avatar';
import Menu from '../Menu';
import PrivateComponent from '../PrivateComponent/privateComponent';
import { logout } from '../../services/redux/auth/actions';
import { FormattedMessage } from '../../Contexts/LanguageContext/index';

import './styles.scss';
import AlertIcon from '../../configuration/icons/svg/ico-alerts.svg';
import MenuIcon from '../../configuration/icons/svg/ico-menu.svg';
import { ButtonCustom } from '../../elements/Button/index';
import Badge from '../Badge/index';
import MenuMobile from '../MenuMobile/index';
import MenuSections from '../MenuSections/index';

const AvatarComponent = ({ onClick, userMe }) => (
  <Avatar
    onClick={onClick}
    size="custom"
    shape="square"
    className="navAvatar"
    backgroundColor="random"
    color="#fff"
    letter={userMe.name.charAt(0)}
  >
    <div className="navColorAvatar">{userMe.name.charAt(0).toUpperCase()}</div>
  </Avatar>
);
const DefaultRightSide = ({
  userMe,
  onViewProfileClick,
  intl,
  menuElementsSelected,
  hiddenSubmenu,
}) => {
  const [menuMobileVisibility, setMenuMobileVisibility] = useState(false);

  return (
    <div className="defaultRightSide">
      <ul className="list-inline d-flex align-items-center">
        {/*         <li>
          <i className="uil uil-question-circle"></i>
        </li> */}
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/alerts"
          permission="list-all"
        >
          <li>
            <Link to="/app/alerts">
              <Badge visible={false}>
                {' '}
                {/* TO-DO: Implement when notification system is ready */}
                <img
                  src={AlertIcon}
                  id="alertIcon"
                  alt={`${intl.formatMessage({ id: 'Alerts.generic.name' })}s`}
                />
              </Badge>
            </Link>
          </li>
        </PrivateComponent>
        <li
          className="d-visible d-md-none"
          onClick={() => setMenuMobileVisibility(!menuMobileVisibility)}
        >
          <img
            src={MenuIcon}
            id="menuIcon"
            alt={`${intl.formatMessage({ id: 'Alerts.generic.name' })}s`}
          />
        </li>
        <li className="d-none d-md-block">
          <Menu
            openComponent={({ onClick }) => (
              <AvatarComponent onClick={onClick} userMe={userMe} />
            )}
            openFrom="top right"
            className="navHelpButton"
            maxHeight="90vh"
          >
            <div className="menuProfile">
              <Avatar
                size="md"
                shape="circle"
                backgroundColor="random"
                color="#fff"
                letter={userMe.name.charAt(0)}
              >
                <div className="navColorAvatar">{userMe.name.charAt(0)}</div>
              </Avatar>
              <div className="data">
                <p>{userMe.name.concat(' ', userMe.surname)}</p>
                <p>{userMe.email}</p>

                <PrivateComponent
                  checkLogin
                  checkPermission
                  microsService="/app/users"
                  permission="read-me"
                >
                  <ButtonCustom
                    type="primary"
                    id="viewUserMeProfile"
                    handleOnClick={onViewProfileClick}
                    label={<FormattedMessage id="top.menu.profile" />}
                  />
                </PrivateComponent>
              </div>

              <hr />
            </div>
            <MenuSections />
            <div className="menuFooter">
              <div
                role="button"
                tabIndex={-1}
                className="logOut"
                onClick={() => {
                  logout();
                  navigate('/app/login');
                }}
              >
                <FormattedMessage id="profile.logout" />
              </div>
            </div>
          </Menu>
        </li>
      </ul>
      {menuMobileVisibility && (
        <MenuMobile
          menuElementsSelected={menuElementsSelected}
          hiddenSubmenu={hiddenSubmenu}
          userMe={userMe}
          onChange={() => setMenuMobileVisibility(!menuMobileVisibility)}
          onProfileClick={onViewProfileClick}
          reverseCloseIcon
        />
      )}
    </div>
  );
};

export default injectIntl(DefaultRightSide);
