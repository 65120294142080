import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      // presence: true,
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: (<FormattedMessage
          id="Validation.presence"
        />),
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'organization',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 24,
        maximum: 32,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '24' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '32' }}
          />
        ),
      },
    },
  },
  {
    key: 'dataset',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 24,
        maximum: 32,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '24' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '32' }}
          />
        ),
      },
    },
  },
  { key: 'cron', defaultValue: '' },
  {
    key: 'description',
    defaultValue: '',
  },
  {
    key: 'owner',
    defaultValue: '',
  },
  {
    key: 'version',
    defaultValue: {},
  },
  {
    key: 'cron',
    defaultValue: '',
  },
  {
    key: 'layoutProperties',
    defaultValue: {},
    constraints: {
      presence: true,
    },
  },
  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
    constraints: {
      presence: true,
    },
  },

  {
    key: 'datasources',
    defaultValue: [],
    constraints: {
      // presence: true,
    },
  },
  {
    key: 'process',
    defaultValue: [],
    constraints: {
      // presence: true,
    },
  },
  {
    key: 'state',
    defaultValue: 'created',
    constraints: {
      // presence: false,
    },
  },
];

export default ModelExpected;
