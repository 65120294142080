import * as TYPES from './types';
import { Map, List } from 'immutable';

/** Initial State of UsersGroups */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  createdUsersGroup: {},
});

/** USERSGROUPS REDUCER*/
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  /** Cases */
  switch (type) {
    case TYPES.GET_USERSGROUPS_REQUEST:
    case TYPES.GET_USERSGROUP_REQUEST:
    case TYPES.ADD_USERSGROUP_REQUEST:
    case TYPES.MODIFY_USERSGROUP_REQUEST:
    case TYPES.DELETE_USERSGROUP_REQUEST:
    case TYPES.DELETE_USER_FROM_USERSGROUP_REQUEST:
    case TYPES.ADD_USER_TO_USERSGROUP_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    case TYPES.GET_USERSGROUPS_ERROR:
    case TYPES.GET_USERSGROUP_ERROR:
    case TYPES.ADD_USERSGROUP_ERROR:
    case TYPES.MODIFY_USERSGROUP_ERROR:
    case TYPES.DELETE_USERSGROUP_ERROR:
    case TYPES.DELETE_USER_FROM_USERSGROUP_ERROR:
    case TYPES.ADD_USER_TO_USERSGROUP_ERROR:
      return state.set('errorFetching', payload).set('fetching', false);

    /** GET ALL USERSGROUPS FROM DB TO STATE */
    case TYPES.GET_USERSGROUPS_SUCCESS:
      return state
        .set('total', payload.length)
        .set('list', List(payload))
        .set('fetching', false);

    /** GET A USERSGROUP FROM DB TO STATE */
    case TYPES.GET_USERSGROUP_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      return (index === -1
        ? state.update('list', usersGroupList => usersGroupList.push(payload))
        : state.update('list', usersGroupList =>
            usersGroupList.splice(index, 1, payload)
          )
      ).set('fetching', false);

    /** ADD NEW USERSGROUP TO STATE */
    case TYPES.ADD_USERSGROUP_SUCCESS:
      return state
        .update('list', usersGroupList => usersGroupList.push(payload))
        .set('fetching', false)
        .set('createdUsersGroup', payload);

    /** SET AS FALSE THE addSuccess STATE FLAG */
    case TYPES.ADD_USERSGROUP_SUCCESS_FLAG:
      return state.set('addSuccess', false);

    /** UPDATE USERSGROUP IN STATE */
    case TYPES.MODIFY_USERSGROUP_SUCCESS:
    case TYPES.ADD_USER_TO_USERSGROUP_SUCCESS:
    case TYPES.DELETE_USER_FROM_USERSGROUP_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      return (index !== -1
        ? state.update('list', usersGroupList =>
            usersGroupList.splice(index, 1, payload)
          )
        : state
      )
        .update('total', total => total + 1)
        .set('fetching', false)
        .set('modifySuccess', true);

    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_USERSGROUP_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    /** DELETE USERSGROUP IN STATE */
    case TYPES.DELETE_USERSGROUP_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      return (index !== -1
        ? state.update('list', usersGroupList => usersGroupList.delete(index))
        : state
      )
        .update('total', total => total - 1)
        .set('fetching', false);

    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_USERSGROUP_RESET:
      return state.set('errorFetching', false);

    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
};
