import * as TYPES from './types';
import { Map, List } from 'immutable';

/** Initial State of Users */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  changedPassword: false,
});

/** USERS REDUCER*/
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    case TYPES.GET_USERS_REQUEST:
    case TYPES.GET_USER_REQUEST:
    case TYPES.GET_USER_ROLES_REQUEST:
    case TYPES.ADD_USER_REQUEST:
    case TYPES.MODIFY_USER_REQUEST:
    case TYPES.DELETE_USER_REQUEST:
    case TYPES.RESET_USER_PASSWORD_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    case TYPES.RESET_USER_PASSWORD_SUCCESS:
      return state.set('newPassword', payload);

    case TYPES.MODIFY_USER_PASSWORD_REQUEST:
      return state.set('fetching', true).set('changedPassword', false).set('errorFetching', false);

    case TYPES.MODIFY_USER_PASSWORD_SUCCESS:
      return state.set('fetching', true).set('changedPassword', true);

    case TYPES.GET_USERS_ERROR:
    case TYPES.GET_USER_ERROR:
    case TYPES.GET_USER_ROLES_ERROR:
    case TYPES.ADD_USER_ERROR:
    case TYPES.MODIFY_USER_ERROR:
    case TYPES.DELETE_USER_ERROR:
    case TYPES.GET_USER_ME_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;

    /** GET ALL USER FROM DB TO STATE */
    case TYPES.GET_USERS_SUCCESS:
      return state.set('total', payload.length)
        .set('list', List(payload))
        .set('fetching', false);

    /** GET USER FROM DB TO STATE */
    case TYPES.GET_USER_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      //If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1
        ? state.update('list', userList => userList.push(payload))
        : state;

    /** GET USER ROLES FROM DB TO STATE */
    case TYPES.GET_USER_ROLES_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      //Update roles
      if (index !== -1) {
        const newArray = state.get('list').toJS();
        newArray[index].roles = payload.roles;
        aux = state.set('list', List([...newArray]));
        aux = aux.set('fetching', false);
      } else {
        aux = state;
      }
      return aux;

    /** ADD NEW USER TO STATE */
    case TYPES.ADD_USER_SUCCESS:
      aux = state.update('list', userList => userList.push(payload));
      aux = aux.set('fetching', false);
      aux = aux.set('addSuccess', true);
      aux = aux.set('createdUser', payload);
      aux = aux.update('total', total => total + 1);
      return aux;
    /** SET AS FALSE THE addSuccess STATE FLAG */
    case TYPES.ADD_USER_SUCCESS_FLAG:
      return state.set('addSuccess', false);

    /** UPDATE USER IN STATE */
    case TYPES.MODIFY_USER_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', userList => userList.splice(index, 1, payload))
          : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);
      return aux;
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_USER_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    /** DELETE USER IN STATE */
    case TYPES.DELETE_USER_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', userList => userList.delete(index))
          : state;
      aux = aux.set('fetching', false);
      aux = aux.update('total', total => total - 1);
      return aux;

    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_USER_RESET:
      return state.set('errorFetching', false);

    case TYPES.GET_USER_ME_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    case TYPES.GET_USER_ME_SUCCESS:
      return state.set('userMe', payload).set('fetching', false);

    default:
      return state;
  }
};
