import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext/index';
import Field from '../../../../../elements/Field';
import './styles.scss';

const ShareDashboard = (props) => {
  const {
    newEntity,
    permissionToEdit,
    intl,
    saveOnTheFly,
  } = props;
  const canEdit = permissionToEdit ?? true;
  const options = [
    {
      name: intl.formatMessage({
        id: 'ShareDashboard.public',
      }),
      value: true,
    },
    {
      name: intl.formatMessage({
        id: 'ShareDashboard.restricted',
      }),
      value: false,
    },
  ];

  const [sharedUrl, setSharedUrl] = useState('');
  const [dashboardEntity, setDashboardEntity] = useState({});

  useEffect(() => {
    const newSharedUrl = `${window.location.href}/shared`;
    setSharedUrl(newSharedUrl);
    const dataEntity = !newEntity.permissions_policy
      ? {
        ...newEntity,
        permissions_policy: {
          users: [],
          groups: [],
          public: false,
          worldwide: { active: false },
        },
      }
      : newEntity;

    setDashboardEntity(dataEntity);
  }, []);

  const copyLink = () => {
    navigator.clipboard.writeText(sharedUrl);
  };

  const handleSelectChange = (_, value) => {
    const copyEntity = { ...newEntity };

    newEntity.permissions_policy.worldwide = { active: value };

    setDashboardEntity(copyEntity);

    saveOnTheFly && saveOnTheFly(copyEntity);
  };

  return (
    canEdit
    && (
      <div id="share-dashboard">
        <p className="mb-3 getLink">
          <FormattedMessage
            id="ShareDashboard.getLink"
            defaultMessage="Get link"
            description="Title of get link"
          />
        </p>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 9 }}>
            <Input
              type="text"
              ref={(inputLink) => inputLink}
              value={sharedUrl}
              disabled
              classNamed="disabled"
            />
          </Col>
          <Col sm={{ size: 12 }} md={{ size: 3 }}>
            <Button
              onClick={copyLink}
              className="copy-link"
              role="presentation"
            >
              <i className="uil uil-link-h" />
              <FormattedMessage
                id="ShareDashboard.copyLink"
                defaultMessage="Copy link"
                description="Title of copy link"
              />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 3 }}>
            <Field
              label={intl.formatMessage({
                id: 'ShareDashboard.privacity',
              })}
              type="select"
              name="privacity"
              value={!!dashboardEntity.permissions_policy?.worldwide?.active}
              onChange={handleSelectChange}
              options={options}
              className="select-privacity"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 12 }}>
            <div className="privacity-block">
              <i className="uil uil-info-circle" />
              <div>
                { dashboardEntity.permissions_policy?.worldwide?.active
                  ? (
                    <p className="mb-0">
                      <FormattedMessage
                        id="ShareDashboard.publicDescription"
                        defaultMessage="publicDescription"
                        description="Title of publicDescription"
                      />
                    </p>
                  )
                  : (
                    <>
                      <p className="mb-0">
                        <FormattedMessage
                          id="ShareDashboard.peopleOrGroupsDescription"
                          defaultMessage="peopleOrGroupsDescription description"
                          description="Title of peopleOrGroupsDescription description"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="ShareDashboard.publicPlatformDescription"
                          defaultMessage="publicPlatformDescription description"
                          description="Title of publicPlatformDescription description"
                        />
                      </p>
                    </>
                    )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
};

export default injectIntl(ShareDashboard);
