import React from 'react';
// eslint-disable-next-line import/no-cycle
import { ParagraphContext } from './provider';

const withParagraphDataContext = (Comp) => {
  class WithParagraphDataContext extends React.PureComponent {
    render() {
      const infoFromProvider = this.context;
      return (
        <Comp
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...infoFromProvider}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        />
      );
    }
  }
  WithParagraphDataContext.contextType = ParagraphContext;
  return WithParagraphDataContext;
};

export default withParagraphDataContext;
