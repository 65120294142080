import React, { useState } from 'react';
import CustomInput from 'reactstrap/es/CustomInput';
import { Row } from 'reactstrap';
import { clone } from 'ramda';
import { FormGroup } from '@material-ui/core';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Field from '../../../../elements/Field';
import { sampling } from '../../../../configuration/variables';
import { Components } from '../../../../models/Widget/configurationSheets/utils';
import './style.scss';

const BarData = ({
  config,
  handler,
}) => {
  const [localdata, setLocalData] = useState(config?.data);
  const typeOptions = ['historical', 'real-time'];
  const { data } = config;
  const { type, startDate, endDate } = data;

  const handleChangeType = (Type) => {
    const configDataWidget = clone(localdata);
    switch (Type) {
      case 'real-time':
        configDataWidget.type = 'historical';
        configDataWidget.period = 'last';
        configDataWidget.sampling = 'lastHour';
        break;
      case 'historical':
        configDataWidget.type = 'real-time';
        configDataWidget.sampling = 'lastYear';
        break;
      default:
        configDataWidget.type = 'real-time';
    }
    setLocalData(configDataWidget);
    handler({ name: 'data', value: configDataWidget }, undefined, Components.barData);
  };

  const handlePeriodChange = (e) => {
    const { value } = e.target;
    const newData = { ...localdata };
    newData.period = value;
    newData.sampling = value === 'temporary-period' ? 'lastYear' : 'lastHour';
    setLocalData(newData);
    handler({ name: 'data', value: newData }, undefined, Components.barData);
  };

  const handleChangeSelect = (name, value) => {
    const newData = { ...localdata };
    newData[name] = value;
    setLocalData(newData);
    handler({ name: 'data', value: newData }, undefined, Components.barData);
  };

  const isValidConfig = (_config, isSampling) => {
    let isValid = false;
    if (_config.data.type === 'historical'
            && _config.data.period === 'temporary-period'
            && !isSampling) {
      isValid = true;
    } else if (_config.data.type === 'historical'
            && _config.data.period !== 'temporary-period'
            && isSampling) {
      isValid = true;
    }
    return isValid;
  };

  return (
    <div className="configurationDataContainer">
      <Row className="rowData">
        <Field
          className="field"
          name="type"
          label={<FormattedMessage id="widget.config.type" />}
          type="select"
          options={typeOptions.map((t) => (
            {
              value: t,
              name: <FormattedMessage id={`widget.config.${t}`} />,
            }
          ))}
          value={type}
          onChange={() => handleChangeType(type)}
        />

        {type === 'historical'
          ? (
            <FormGroup className="radioContainer">
              <CustomInput
                onChange={handlePeriodChange}
                value="last"
                name="last"
                type="radio"
                label={<FormattedMessage id="widget.config.last" />}
                id="last"
                checked={(localdata.period === 'last')}
              />

              <CustomInput
                onChange={handlePeriodChange}
                value="temporary-period"
                name="temporary-period"
                type="radio"
                label={<FormattedMessage id="widget.config.temporary-period" />}
                id="temporary-period"
                checked={!(localdata.period === 'last')}
              />
            </FormGroup>
          ) : null}
      </Row>

      {isValidConfig(config, false)
                && (
                <Row className="rowData">
                  <Field
                    className="field fieldData"
                    name="startDate"
                    label={<FormattedMessage id="widget.config.startDate" />}
                    type="date"
                    value={startDate ? new Date(startDate) : ''}
                    onChange={(name, value) => handleChangeSelect(name, value)}
                  />

                  <Field
                    className="field fieldData"
                    name="endDate"
                    label={<FormattedMessage id="widget.config.endDate" />}
                    type="date"
                    value={endDate ? new Date(endDate) : ''}
                    onChange={(name, value) => handleChangeSelect(name, value)}
                  />
                </Row>
                )}

      {isValidConfig(config, true) && (
        <Field
          className="field"
          name="sampling"
          label={<FormattedMessage id="widget.config.sampling" />}
          type="select"
          options={sampling.map((s) => (
            {
              value: s,
              name: <FormattedMessage id={`widget.config.${s}`} />,
            }
          ))}
          value={config.data.sampling}
          onChange={(name, value) => handleChangeSelect(name, value, 'data')}
        />
      )}
    </div>
  );
};

export default BarData;
