import React from 'react';
import ProfileDashboard from '../ProfileV2';
import ProfileSendingCommands from '../ShowV2/ProfileSendingCommands/index';
import ProfileWidget from '../../Widgets/Profile/profileV2';
import WithDrawer from '../../../components/DrawerContainer';

export const ProfileWidgetComponent = WithDrawer(({
  onChange,
  data,
  profileType,
  locked = false,
  isLinked,
  widgetList,
  selection,
  onInfoChange,
  dispatchState,
}) => (
  <ProfileWidget
    onChange={onChange}
    data={data}
    profileType={profileType}
    locked={locked}
    isLinked={isLinked}
    widgetList={widgetList}
    selection={selection}
    onInfoChange={onInfoChange}
    dispatchState={dispatchState}
  />
));

export const ProfileDashboardComponent = WithDrawer(
  ({ onChange, data, profileType }) => (
    <ProfileDashboard
      onChange={onChange}
      data={data}
      profileType={profileType}
    />
  ),
);

export const ProfileSendingCommandComponent = WithDrawer(
  ({
    onChange, data, profileType, checkable, ...rest
  }) => (
    <ProfileSendingCommands
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={onChange}
      data={data}
      profileType={profileType}
      checkable={checkable}
    />
  ),
);
