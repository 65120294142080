/* eslint-disable import/no-cycle */
import BarsWidget from './bars';
import TableWidget from './table';
import KpiWidget from './kpi';
import LinesWidget from './lines';
import NeedleWidget from './needle';
import PercentageWidget from './percentage';
import ParametrizedTextWidget from './parametrizedtext';
import ImageWidget from './image';
import OnOffWidget from './onoff';
import SliderWidget from './slider';
import HtmlWidget from './html';
import MapWidget from './map';
import HeatMapWidget from './heatMap';
import LinkedWidget from './linked';

// *! --------- [INSERT IMPORT] --------- !* //
export default {
  BarsWidget,
  LinesWidget,
  PercentageWidget,
  MapWidget,
  HeatMapWidget,
  TableWidget,
  KpiWidget,
  NeedleWidget,
  OnOffWidget,
  SliderWidget,
  ImageWidget,
  LinkedWidget,
  HtmlWidget,
  ParametrizedTextWidget,
// *! --------- [INSERT EXPORT] --------- !* //
};
