import React from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../Contexts/LanguageContext/index';
import './styles.scss';

const ExpandZone = ({ onClick, label }) => (
  <div role="presentation" onClick={onClick} className="expandTable">
    <span>{label}</span>
  </div>
);

const itemsPerPageText = (
  <FormattedMessage
    id="paginator.items.page"
    defaultMessage="Items per page"
    description="Items per page text for paginator"
  />
);

export default (PaginatedComponent) => {
  class Paginator extends React.Component {
    constructor(props) {
      super(props);

      const { page } = this.props;

      this.state = {
        page,
      };
    }

    componentDidUpdate(prevProps) {
      const { page } = this.props;
      const { page: pageState } = this.state;
      if (prevProps.page !== page && page !== pageState) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          page,
        });
      }
    }

    onExpandClick = () => {
      const { page } = this.state;
      const { onPageChange } = this.props;
      this.setState({
        page: page + 1,
      }, () => onPageChange(page));
    }

    getItemsShown = () => {
      const { page, itemsPerPage } = this.props;
      return (page !== 0 ? page * itemsPerPage : itemsPerPage);
    }

    getMaxPages = () => {
      const { total, itemsPerPage } = this.props;
      return total / itemsPerPage;
    }

    getPaginatedData = () => {
      const { page, data, total } = this.props;
      if (data.length === total && page < this.getMaxPages()) {
        // It is a front-controlled list
        return data.slice(0, this.getItemsShown());
      }
      // It is a external-controlled list
      return data;
    }

    render() {
      const { intl, total } = this.props;

      return (
        <>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <PaginatedComponent {...this.props} data={this.getPaginatedData()} />

          {total > this.getPaginatedData().length
            ? (
              <ExpandZone
                onClick={this.onExpandClick}
                label={intl.formatMessage({ id: 'paginator.expandable.label' })}
              />
            )
            : <ExpandZone />}
        </>
      );
    }
  }

  Paginator.defaultProps = {
    data: [],
    onPageChange: () => {},
    itemPerPageText: <p>{itemsPerPageText}</p>,
    itemsPerPage: 10,
    itemsPerPageAllowed: [5, 10, 15, 20, 25, 30],
  };

  Paginator.WrappedComponent = PaginatedComponent;

  return injectIntl(Paginator);
};
