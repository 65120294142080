/* eslint-disable import/no-cycle */
import {
  takeLatest,
  call,
  put,
  all,
  fork,
  retry,
  takeEvery,
} from 'redux-saga/effects';
import Historical from '../../../models/Historical';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as API from '../../api/historicals';
import { isShowV2 } from '../../../helpers/utils';

/**
 * Actions for WATCHERS
 * */

// Try to recover all historical form DB
export function* getHistoricalsThroughAPI({ payload }) {
  try {
    const response = yield call(API.readHistoricals, payload);
    if (response.status === 200) {
      const historicals = response.data.historicals.map(
        (historical) => new Historical(historical),
      );
      yield put({ type: TYPES.GET_HISTORICALS_SUCCESS, payload: historicals });
    } else {
      yield put({ type: TYPES.GET_HISTORICALS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_HISTORICALS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an historical form DB
export function* getHistoricalThroughAPI({ payload }) {
  try {
    const response = yield call(API.readHistorical, payload);
    if (response.status === 200) {
      const historicalFromDB = new Historical(response.data);
      yield put({
        type: TYPES.GET_HISTORICAL_SUCCESS,
        payload: historicalFromDB,
      });
    } else {
      yield put({ type: TYPES.GET_HISTORICAL_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_HISTORICAL_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// List
export function* getHistoricalListThroughApi({ payload }) {
  try {
    const response = yield all(
      // Now the call will be retried 5 times until give up.
      // This will happen after 250ms of being canceled for any reason.
      payload.map((historical) => retry(5, 250, API.readHistorical, historical)),
    );
    if (response) {
      yield put({
        type: TYPES.GET_HISTORICAL_LIST_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: TYPES.GET_HISTORICAL_LIST_ERROR,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_HISTORICAL_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// IMPORTANT: if you update getHistoricalListV2ThroughApi, maybe you should also
// take a look getPublicHistoricalListV2ThroughApi
// List V2
export function* getHistoricalListV2ThroughApi({ payload }) {
  const { widgetId, isPublicDashboard } = payload;
  const endpoint = isPublicDashboard ? API.readPublicHistoricalV2 : API.readHistoricalV2;
  try {
    const response = yield all(
      // Now the call will be retried 5 times until give up.
      // This will happen after 250ms of being canceled for any reason.
      payload.query.map((historical) => retry(5, 250, endpoint, historical)),
    );
    if (response) {
      const newPayload = response.map((r) => (r.data.objects)).flat();
      yield put({
        type: TYPES.GET_HISTORICAL_LIST_V2_SUCCESS,
        payload: { data: newPayload, widgetId, isPublicDashboard },
      });
    } else {
      yield put({
        type: TYPES.GET_HISTORICAL_LIST_V2_ERROR,
        payload: { data: response, widgetId, isPublicDashboard },
      });
    }
  } catch (error) {
    yield put({
      type: TYPES.GET_HISTORICAL_LIST_V2_ERROR,
      payload: { data: error, widgetId, isPublicDashboard },
    });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Multiple List
// TODO: [v2] Remove v1 implementation when v2 is fully implemented.
export function* getMultipleHistoricalListThroughApi({ payload }) {
  try {
    let response;

    if (isShowV2()) {
      response = yield all(
        payload.map((historical) => retry(5, 250, API.readHistoricalV2, historical)),
      );
    } else {
      response = yield all(
        payload.map((historical) => call(API.readHistorical, historical)),
      );
    }
    if (response) {
      const request = [];
      if (isShowV2()) {
        const currentCount = payload[0].pagination.size * payload[0].pagination.page;
        if (currentCount < response[0].data.total) {
          const newRequest = { ...payload[0] };
          newRequest.pagination.page = newRequest.pagination.page ? newRequest.pagination.page + 1 : 2;
          request.push(newRequest);
        }
        yield put({
          type: TYPES.GET_MULTIPLE_HISTORICAL_LIST_SUCCESS,
          payload: response,
        });
      } else {
        response.forEach((res, index) => {
          if (payload[index].multipleRequest && res.data.count === 2000) {
            const newRequest = { ...payload[index] };
            newRequest.page = newRequest.page ? newRequest.page + 1 : 2;
            request.push(newRequest);
          }
        });
        yield put({
          type: TYPES.GET_MULTIPLE_HISTORICAL_LIST_SUCCESS,
          payload: response,
          multipleRequest: payload[0].multipleRequest,
        });
      }

      if (request.length > 0) {
        yield getMultipleHistoricalListThroughApi({ payload: request });
      }
    } else {
      yield put({
        type: TYPES.GET_MULTPLE_HISTORICAL_LIST_ERROR,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MULTPLE_HISTORICAL_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// List Preview
export function* getHistoricalListPreviewThroughApi({ payload }) {
  try {
    const response = yield all(
      payload.map((historical) => call(isShowV2() ? API.readHistoricalV2 : API.readHistorical, historical)),
    );
    if (response) {
      yield put({
        type: TYPES.GET_HISTORICAL_LIST_PREVIEW_SUCCESS,
        payload: isShowV2()
          ? response.map((entity) => entity.data.objects)
          : response,
      });
    } else {
      yield put({
        type: TYPES.GET_HISTORICAL_LIST_PREVIEW_ERROR,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_HISTORICAL_LIST_PREVIEW_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_HISTORICALS_REQUEST
function* watcherGetHistoricals() {
  yield takeLatest('GET_HISTORICALS_REQUEST', getHistoricalsThroughAPI);
}

// Watcher looking for GET_HISTORICAL_REQUEST
function* watcherGetHistorical() {
  yield takeLatest('GET_HISTORICAL_REQUEST', getHistoricalThroughAPI);
}

// Watcher looking for GET_HISTORICAL_LIST_REQUEST
function* watcherGetHistoricaList() {
  yield takeLatest('GET_HISTORICAL_LIST_REQUEST', getHistoricalListThroughApi);
}

// Watcher looking for GET_HISTORICAL_LIST_V2_REQUEST
function* watcherGetHistoricaListV2() {
  yield takeEvery('GET_HISTORICAL_LIST_V2_REQUEST', getHistoricalListV2ThroughApi);
}

// Watcher looking for GET_HISTORICAL_LIST_PREVIEW_REQUEST
function* watcherGetHistoricaListPreview() {
  yield takeLatest('GET_HISTORICAL_LIST_PREVIEW_REQUEST', getHistoricalListPreviewThroughApi);
}

// Watcher looking for GET_HISTORICAL_REQUEST
function* watcherGetMultipleHistoricaList() {
  yield takeLatest('GET_MULTIPLE_HISTORICAL_LIST_REQUEST', getMultipleHistoricalListThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetHistoricals),
    fork(watcherGetHistorical),
    fork(watcherGetHistoricaList),
    fork(watcherGetHistoricaListV2),
    fork(watcherGetHistoricaListPreview),
    fork(watcherGetMultipleHistoricaList),
  ]);
}
