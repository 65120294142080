// MENUSECTIONS
import React, { useRef, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { FormattedMessage } from 'react-intl';

import UsersManagementIcon from '../../configuration/icons/svg/usersManagement.svg';
import DevicesManagementIcon from '../../configuration/icons/svg/devicesManagement.svg';
import TemplatesManagementIcon from '../../configuration/icons/svg/templatesManagement.svg';
import RulesManagementIcon from '../../configuration/icons/svg/rulesManagement.svg';
import DashboardManagementIcon from '../../configuration/icons/svg/dashboardManagement.svg';
import PermissionsManagementIcon from '../../configuration/icons/svg/permissionManagement.svg';
import ServicesManagementIcon from '../../configuration/icons/svg/servicesManagement.svg';
import KpiManagementIcon from '../../configuration/icons/svg/kpiManagement.svg';

import UsersManagementIconCompressed from '../../configuration/icons/svg/usersManagement-compressed.svg';
import DevicesManagementIconCompressed from '../../configuration/icons/svg/devicesManagement-compressed.svg';
import TemplatesManagementIconCompressed from '../../configuration/icons/svg/templatesManagement-compressed.svg';
import RulesManagementIconCompressed from '../../configuration/icons/svg/rulesManagement-compressed.svg';
import DashboardManagementIconCompressed from '../../configuration/icons/svg/dashboardManagement-compressed.svg';
import PermissionsManagementIconCompressed from '../../configuration/icons/svg/permissionsManagement-compressed.svg';
import ServicesManagementIconCompressed from '../../configuration/icons/svg/servicesManagement-compressed.svg';
import KpiManagementIconCompressed from '../../configuration/icons/svg/kpiManagement.svg';

import './styles.scss';
import SubMenuParent from './subMenu';

const MENU_ITEMS = [
  { item: 'users' },
  { item: 'permissions' },
  { item: 'devices' },
  { item: 'templates' },
  { item: 'services' },
  { item: 'rules' },
  { item: 'dashboard' },
  { item: 'kpis' },

];

const MenuSections = ({ compressed, onClickSection }) => {
  const [isSubmenuOpened, setIsSubmenuOpened] = useState({});
  const [someSubMenuIsOpen, setSomeSubMenuIsOpen] = useState(false);
  const itemRef = useRef(null);
  const getIcon = (service) => {
    let icon;
    switch (service) {
      case 'users':
        icon = !compressed
          ? UsersManagementIcon
          : UsersManagementIconCompressed;
        break;
      case 'devices':
        icon = !compressed
          ? DevicesManagementIcon
          : DevicesManagementIconCompressed;
        break;
      case 'templates':
        icon = !compressed
          ? TemplatesManagementIcon
          : TemplatesManagementIconCompressed;
        break;
      case 'rules':
        icon = !compressed
          ? RulesManagementIcon
          : RulesManagementIconCompressed;
        break;
      case 'dashboard':
        icon = !compressed
          ? DashboardManagementIcon
          : DashboardManagementIconCompressed;
        break;
      case 'permissions':
        icon = !compressed
          ? PermissionsManagementIcon
          : PermissionsManagementIconCompressed;
        break;
      case 'services':
        icon = !compressed
          ? ServicesManagementIcon
          : ServicesManagementIconCompressed;
        break;
      case 'kpis':
        icon = !compressed
          ? KpiManagementIcon
          : KpiManagementIconCompressed;
        break;
      default:
        icon = null;
    }
    return icon;
  };

  const getList = (service, isSubMenu) => (
    <>
      <div>
        <img src={getIcon(service)} alt={`${service} management`} id={`${service}-management-menu-icon`} />
      </div>
      <span className={isSubMenu ? 'submenu-title' : ''}>
        <FormattedMessage id={`${service}.list.title`} />
      </span>
    </>
  );

  const handleOnClick = (service) => {
    navigate(`/app/${service}`);
    onClickSection();
  };

  const handleOnClickSubMenuItem = (service) => {
    setIsSubmenuOpened((old) => ({ [service.item]: old !== null ? !old[service.item] : true }));
  };

  useEffect(() => {
    if (isSubmenuOpened && Object.values(isSubmenuOpened).some((i) => i)) {
      setSomeSubMenuIsOpen(true);
    } else if (someSubMenuIsOpen) {
      setSomeSubMenuIsOpen(false);
    }
  }, [isSubmenuOpened, someSubMenuIsOpen]);

  return (
    <div className="menuIcons">
      {MENU_ITEMS.map((service) => (
        !service.subMenu
          ? (
            <div
              ref={itemRef}
              onClick={() => handleOnClick(service.item)}
              className={`menuSections ${compressed ? 'compressed' : ''}${someSubMenuIsOpen ? 'submenu-opened' : ''}`}
              key={service.item}
              role="presentation"
            >
              {getList(service.item)}
            </div>
          )
          : (
            <SubMenuParent
              disabled={someSubMenuIsOpen && !isSubmenuOpened[service.item]}
              height={itemRef?.current?.offsetHeight}
              width={itemRef?.current?.offsetWidth}
              toggleOtherIcons={() => handleOnClickSubMenuItem(service)}
              getIcon={getList}
              isOpened={isSubmenuOpened[service.item]}
              item={service}
            />
          )
      ))}
    </div>
  );
};

MenuSections.defaultProps = {
  compressed: '',
  onClickSection: () => {},
};

export default MenuSections;
