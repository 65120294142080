import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import Menu from '../../../../../components/Menu';
import Options from '../../../../../elements/Field/Select/Options';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const SearchBar = ({
  filters,
  selectedFilter,
  onListClick,
  textFieldValue,
  handleInputChange,
  intl,
}) => (
  <>
    <span className="filterText">
      <FormattedMessage id="widgetLinked.searchBar.filter" />
    </span>
    <div className="searchBar">
      <div className="filtersFinder">
        <Menu
          openComponent={({ onClick }) => (
            <div className="caret" onClick={onClick} role="button" tabIndex={-1}>
              <span>{selectedFilter.name}</span>
              <i className="uil uil-angle-down" />
            </div>
          )}
          openFrom="top left"
          maxHeigth="300px"
          top="46px"
          className="optionsList"
        >
          <Options
            data={filters}
            handleOnChange={onListClick}
            selected={selectedFilter.name}
          />
        </Menu>
        <div className="textField">
          <input
            type="text"
            value={textFieldValue}
            placeholder={intl.formatMessage({ id: 'widgetLinked.searchBar.placeholder' })}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </div>
        <i className="uil uil-search" />
      </div>
    </div>
  </>
);

SearchBar.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.array]),
  handleInputChange: PropTypes.func,
  intl: PropTypes.oneOfType([PropTypes.object]),
  onListClick: PropTypes.func,
  selectedFilter: PropTypes.oneOfType([PropTypes.object]),
  textFieldValue: PropTypes.string,
};

SearchBar.defaultProps = {
  filters: [],
  handleInputChange: () => {},
  intl: {},
  onListClick: () => {},
  selectedFilter: {},
  textFieldValue: '',
};

export default injectIntl(SearchBar);
