import React from 'react';
import PropTypes from 'prop-types';
import RemoveHoc from '../../helpers/RemoveComponent';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import Rule from '../../../models/Rule';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const OptionsForRow = ({ rowContent, viewClick }) => {
  const ButtonOpenRemove = ({ showModal }) => (
    <li onClick={showModal}>{Delete}</li>
  );

  ButtonOpenRemove.propTypes = {
    showModal: PropTypes.func.isRequired,
  };

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, {
    class: Rule,
    nameData: 'rules',
  });

  return (
    <PrivateComponent checkLogin checkPermission microsService="/app/rules" permission={['update', 'read', 'delete']}>
      <div className="tableMenu">
        <ul className="list-unstyled">
          <PrivateComponent checkLogin checkPermission microsService="/app/rules" permission="update">
            <li
              onClick={() => {
                viewClick(rowContent);
              }}
              state={{ rule: rowContent, mode: 'edit' }}
            >
              {Edit}
            </li>
          </PrivateComponent>
          <PrivateComponent checkLogin checkPermission microsService="/app/rules" permission="delete">
            <RemoveDOM rowContent={rowContent} />
          </PrivateComponent>
        </ul>
      </div>
    </PrivateComponent>
  );
};

OptionsForRow.propTypes = {
  rowContent: PropTypes.objectOf(PropTypes.any).isRequired,
  viewClick: PropTypes.func.isRequired,
};

export default OptionsForRow;
