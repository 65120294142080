import * as TYPES from './types';
import { store } from '../store';

export const addDataSource = (DataSource) => store.dispatch({
  type: TYPES.ADD_DATASOURCE_REQUEST,
  payload: DataSource,
});

export const updateDataSource = (DataSource) => store.dispatch({
  type: TYPES.MODIFY_DATASOURCE_REQUEST,
  payload: DataSource,
});


export const deleteDataSource = (DataSource) => store.dispatch({
  type: TYPES.DELETE_DATASOURCE_REQUEST,
  payload: DataSource,
});

export const getDataSources = (payload = {}) => store.dispatch({
  type: TYPES.GET_DATASOURCES_REQUEST,
  payload,
});

export const getDataSourcesFromDevices = (Devices) => store.dispatch({
  type: TYPES.GET_DATASOURCES_FROM_DEVICES_REQUEST,
  payload: Devices,
});

export const getPublicDataSources = (payload = {}) => store.dispatch({
  type: TYPES.GET_PUBLIC_DATASOURCES_REQUEST,
  payload,
});

export const getMineDataSources = (payload = {}) => store.dispatch({
  type: TYPES.GET_MINE_DATASOURCES_REQUEST,
  payload,
});

export const getDataSource = (DataSource) => store.dispatch({
  type: TYPES.GET_DATASOURCE_REQUEST,
  payload: DataSource,
});

export const clearDataSources = () => store.dispatch({
  type: TYPES.CLEAR_DATASOURCES_STATE,
});
