import React, { useState } from 'react';
import { Avatar } from '../../../elements/Avatar/index';
import MenuSections from '../../MenuSections/index';

const AvatarCard = ({ userMe, onCloseMenu, onProfileClick }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);

  const handleOnCloseMenu = () => {
    setMenuVisibility(!menuVisibility);
    onCloseMenu();
  };

  return (
    <>
      <div className="avatarCardWrapper">
        <div className="avatarCard">
          <Avatar
            size="md"
            shape="circle"
            backgroundColor="random"
            color="#fff"
            letter={userMe.name.charAt(0)}
            onClick={onProfileClick}
          >
            <div className="navColorAvatar">{userMe.name.charAt(0)}</div>
          </Avatar>
          <div
            className="userInfo"
            onClick={onProfileClick}
            role="presentation"
          >
            <p>
              {userMe.name}
              {' '}
              {userMe.surname}
            </p>
            <p>{userMe.email}</p>
          </div>
          <i
            className="uil uil-angle-down iconExpandMenu"
            onClick={() => setMenuVisibility(!menuVisibility)}
            role="presentation"
          />
        </div>
        {menuVisibility && (
          <MenuSections compressed onClickSection={() => handleOnCloseMenu()} />
        )}
      </div>
    </>
  );
};

AvatarCard.defaultProps = {
  userMe: {},
  onCloseMenu: () => {},
  onProfileClick: () => {},
};
export default AvatarCard;
