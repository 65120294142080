import React from 'react';
import Styled from 'styled-components';
import ReactDOM from 'react-dom';

const defaultValue = { blanketVisible: false };

export const Context = React.createContext(defaultValue);

const Blanket = Styled.div`
  background: rgba(9, 30, 66, 0.54) none repeat scroll 0% 0%;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: opacity 220ms ease 0s;
    top: 0;
    left: 0;
    z-index: 2000; 
    overflow: hidden;
    width
  `;
const ComponentInBlanket = Styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    ;`;

class Provider extends React.Component {
  ClickOutComponent = null;

  constructor(props) {
    super(props);

    // eslint-disable-next-line prefer-destructuring
    this.nodeElement = document.getElementsByTagName('BODY')[0];

    this.functions = {
      handleBlanketVisibility: this.handleBlanketVisibility,
      handleRefreshBlanketVisibility: this.handleRefreshBlanketVisibility,
      closeInForegroundClick: this.closeInForegroundClick,
    };
    this.state = {
      blanketVisible: false,
      componentToDraw: null,
      ...this.functions,
    };
  }

  handleBlanketVisibility = (blanketVisible, componentToDraw = null) => {
    if (componentToDraw !== null && blanketVisible === true) {
      this.setState({ blanketVisible: true, componentToDraw });
    } else {
      this.setState({ blanketVisible: false });
    }
  };

  handleRefreshBlanketVisibility = (componentToRefresh = null) => {
    this.setState({ componentToDraw: componentToRefresh });
  };

  handleLister = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    const { closeInForeground } = this.state;

    if (!path.includes(this.ClickOutComponent) && closeInForeground) {
      this.setState({ blanketVisible: false, componentToDraw: null });
      window.removeEventListener('click', this.handleLister, true);
    }
  }

  closeInForegroundClick = (option) => {
    this.setState({
      closeInForeground: option,
    });
  };

  handleEvent = () => {
    const { blanketVisible } = this.state;
    if (blanketVisible) {
      window.addEventListener('click', this.handleLister, true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { componentToDraw, blanketVisible } = this.state;
    const ComponentToDraw = componentToDraw;
    this.handleEvent();

    return (
      <Context.Provider value={{ ...this.state }}>
        {
          (blanketVisible
            && this.nodeElement)
          && (
            ReactDOM.createPortal(
              <Blanket>
                {/* eslint-disable-next-line no-return-assign */}
                <ComponentInBlanket ref={(node) => this.ClickOutComponent = node}>
                  <ComponentToDraw />
                </ComponentInBlanket>
              </Blanket>,
              this.nodeElement,
            )
          )
        }
        {children}
      </Context.Provider>
    );
  }
}

export default Provider;
