import { combineReducers } from 'redux-immutable';
import templates from './templates/reducer';
import devices from './devices/reducer';
import reports from './reports/reducer';
import reportHistorical from './reportHistorical/reducer';
import services from './services/reducer';
import users from './users/reducer';
import roles from './roles/reducer';
import auth from './auth/reducer';
import notify from './notify/reducer';
import permissions from './permissions/reducer';
import dashboards from './dashboards/reducer';
import widgets from './widgets/reducer';
import historicals from './historicals/reducer';
import rules from './rules/reducer';
import menuElements from './menuElements/reducer';
import usersGroups from './usersGroups/reducer';
import alerts from './alerts/reducer';
import dataSources from './dataSources/reducer';
import etlProcedures from './etlProcedures/reducer';
import opendata from './opendata/reducer';
import kpis from './kpis/reducer';

export default combineReducers({
  users,
  roles,
  auth,
  notify,
  devices,
  permissions,
  templates,
  services,
  rules,
  menuElements,
  dashboards,
  widgets,
  historicals,
  usersGroups,
  alerts,
  dataSources,
  etlProcedures,
  reports,
  reportHistorical,
  opendata,
  kpis,
});
