import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { validate } from 'validate.js';
import CommonView from '../../../../../CommonView';
import { FieldComponent } from '../helpers';
import {
  getDevices,
  getDevice,
  clearDevicesState,
} from '../../../../../../services/redux/devices/actions';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { getGeneralDataForAction } from '../../../../../../data/ruleActions';

class CommandComponent extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      device: {},
      command: {},
      value: '',
      allDevices: [
        ...this.props.allDevices.filter(
          (o) => o.fiware.service === this.props.newEntity.devices[0].fiware.service,
        ),
      ],
      page: 1,
      size: 5,
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    const { newEntity } = this.props;
    getDevices({
      filters: {
        fiwareService: newEntity.devices[0].fiware.service,
      },
      page,
      size,
    });

    this.props.onRef(this);
    this.props.actionComponentRef(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page, size, device, command,
    } = this.state;
    const { newEntity, allDevices, action } = this.props;

    if (
      JSON.stringify(prevProps.allDevices)
      !== JSON.stringify(allDevices)
    ) {
      const newDevice = device
        ? this.props.allDevices.find((d) => d.id === this.state.device.id)
        : {};
      const newCommand = command
        ? this.joinedAttributes(device).find(
          (c) => c.name === this.state.command.name,
        )
        : {};

      this.updateAfterAllDevicesChanges(allDevices, newDevice, newCommand, newEntity.devices);
    }

    if (!this.props.fetching && prevState.page < page) {
      getDevices({
        filters: {
          fiwareService: this.props.newEntity.devices[0].fiware.service,
        },
        page,
        size,
      });
    }

    if (this.state.device && prevState.device !== this.state.device) {
      getDevice({ id: this.state.device.id });
    }

    if (
      JSON.stringify(prevProps.action) !== JSON.stringify(this.props.action)
    ) {
      if (JSON.stringify(prevProps.action) !== JSON.stringify(this.props.action)) {
        this.updateAfterActionChanges(action.device, action.attribute, action.value);
      }
    }
  }

  updateAfterAllDevicesChanges = (allDevices, device, command, devices) => {
    this.setState({
      allDevices: [
        ...allDevices.filter(
          (o) => o.fiware.service === devices[0].fiware.service,
        ),
      ],
      device,
      command,
    });
  }

  updateAfterActionChanges = (device, attribute, value) => {
    this.setState({
      device,
      attribute,
      value,
    });
  }

  joinedAttributes = (device) => (device && Object.entries(device).length > 0
    ? [
      ...device.command_attributes,
      ...device.lazy_attributes,
      ...device.static_attributes,
      ...device.attributes,
    ]
    : []);

  getOptions = (entities) => (entities && entities.length > 0
    ? entities.map((o) => ({ name: o.name, id: o.id, value: o }))
    : []);

  getOption = (entities, entity) => (entities
    && entity
    && Array.isArray(entities)
    && entities.length > 0
    ? entities.find((o) => o.id === entity.id)
    : {});

  getCommands = (device) => (device && device.command_attributes
    ? this.getOptions(device.command_attributes)
    : []);

  handleInnerSelectChange = (name, value) => {
    if (name === 'device' && value) {
      this.setState({
        [name]: value,
        command: '',
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInnerInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleFetchInfo = () => {
    const { total, allDevices } = this.props;
    if (total > allDevices.length) this.setState((prev) => ({ page: prev.page + 1 }));
  };

  handleOnSearch = (value, init = false) => {
    const { size } = this.state;
    if (init) clearDevicesState();
    getDevices({
      filters: {
        name: value,
        fiwareService: this.props.newEntity.devices[0].fiware.service,
      },
      page: 1,
      size,
    });
  };

  validate = (callback = null) => {
    const generalActionData = getGeneralDataForAction(this.props.newEntity.actionToCreate);
    const constraints = {};
    ['device', 'command', 'value'].forEach((v) => {
      constraints[v] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="Validation.presence" />,
        },
      };
    });

    const errors = validate(this.state, constraints);
    if (errors) {
      this.setState({
        errors,
      });
    } else this.props.saveAction(callback, generalActionData.parseAction(this.state));
  };

  render() {
    const { intl, fetching } = this.props;
    const {
      device, command, value, allDevices, errors,
    } = this.state;

    return (
      <div className="commands">
        <FieldComponent
          name="device"
          type="select"
          options={this.getOptions(allDevices)}
          intl={intl}
          value={this.getOption(allDevices, device)}
          selectedOption={device}
          onChange={this.handleInnerSelectChange}
          onSearch={this.handleOnSearch}
          fetchInfo={this.handleFetchInfo}
          loading={fetching}
          filterBy="name"
          async
          helperText={errors.device}
          error={!!errors.device}
        />
        <FieldComponent
          name="command"
          type="select"
          options={this.getCommands(this.state.device)}
          intl={intl}
          value={command}
          onChange={this.handleInnerSelectChange}
          helperText={errors.command}
          error={!!errors.command}
        />
        <FieldComponent
          name="value"
          type="text"
          intl={intl}
          value={value}
          onChange={this.handleInnerInputChange}
          helperText={errors.value}
          error={!!errors.value}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  allDevices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});
export default connect(mapStateToProps, {})(injectIntl(CommandComponent));
