import React from 'react';
import UsersList  from '../helpers/UsersList';

const EditUsersList = ({ data, permissionToEdit }) => {
  return (
    <div className={'tabSection p-5'}>
      <UsersList newEntity={data} listTitle={'usersGroups.profile.members'} readOnly={false} 
          permissionToEdit={permissionToEdit} />
    </div>
  );
};

export default EditUsersList;