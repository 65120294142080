import React from 'react';
import { Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { ButtonCustom } from '../../../../elements/Button';
import FormattedMessage from '../../../../Contexts/LanguageContext/Components/FormattedMessage';

const LinkedStepButtons = ({ buttons }) => (
  <Row className="justify-content-center button-gap mt-3">
    {buttons.map((button) => (
      <ButtonCustom
        type={button.type}
        id={button.id}
        handleOnClick={button.handleOnClick}
        label={<FormattedMessage id={button.label} />}
        disabled={button.disabled}
      />
    ))}
  </Row>

);

export default injectIntl(LinkedStepButtons);
