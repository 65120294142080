import React from 'react';

const Image = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetImage })),
);

export default function ImageComponent({
  widget,
  renderChildrenWidgets,
  updateContainerLayout,
  isDraggable,
  isResizable,
  childrenWidgetsLength,
}) {
  return (
    <Image
      config={widget.config}
      widget={widget}
      containedWidgets={widget.containedWidgets}
      renderImageWidgets={renderChildrenWidgets}
      updateWidgetImageLayout={updateContainerLayout}
      isDraggable={isDraggable}
      isResizable={isResizable}
      childrenWidgetsLength={childrenWidgetsLength}
    />
  );
}
