import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import Profile from '../../../helpers/Profile/Profile';
import { SendCommandList } from './components/SendCommandList';
import { sendCommand } from './components/utils';
import Footer from './components/Footer';
import Title from './components/Title';

const ProfileSendingCommands = ({
  data, intl, checkable, onClose,
}) => {
  const [devicesInfo, setDevicesInfo] = useState({});
  const [listData, setListData] = useState([]);
  const [errors, setErrors] = useState([]);

  // eslint-disable-next-line no-shadow
  const sendBulkCommands = (data) => {
    setErrors({});

    const validated = [];
    data.forEach((d, i) => {
      if (d && d.checked && !d.isDisabled && d.validated) {
        validated.push(d);
      } else if (d && d.checked && !d.isDisabled && !d.validated) {
        const error = {};
        if (d.command === '') error.command = <FormattedMessage id="Validation.presence" />;
        if (Object.entries(d.attribute).length === 0) error.attributes = <FormattedMessage id="Validation.presence" />;

        errors[i] = error;
      }
    });

    if (errors.length > 1) {
      setErrors(errors);
    } else {
      validated.forEach((valid) => {
        sendCommand(
          valid.device,
          valid.attribute.name,
          valid.command,
          valid.setStatus,
        );
      });
    }
  };

  const getUniqueDevices = (array) => array.filter(
    (obj, index, self) => index === self.findIndex((t) => (
      t.connectedDevices.id === obj.connectedDevices.id
    )),
  );

  // eslint-disable-next-line no-shadow
  const handleSetListData = (data) => {
    const formerListData = [...listData];
    formerListData[data.index] = data;
    setListData(formerListData);
  };

  // at least a validated row to enable send button
  const isValidated = () => listData.find((d) => d && d.validated && d.checked);

  const parseSelectedDevices = (devices) => {
    if (!devices) return null;
    const uniqueDevices = getUniqueDevices(devices);
    return uniqueDevices.map((d) => (d.device ? d.device : d));
  };

  const parseConnectedDevices = (devices) => {
    const uniqueDevices = getUniqueDevices(devices);
    return uniqueDevices.map((o) => o.connectedDevices.readDevice);
  };

  const getData = () => ({
    name: `${intl.formatMessage({
      id: 'SendCommandList.send.command.title',
    })} (${data.name})`,
    widget: { ...data },
  });

  const getFooter = () => (checkable ? (
    <Footer
      onSend={() => sendBulkCommands(listData)}
      onClose={onClose}
      isValidated={isValidated}
    />
  ) : false);

  return (
    <Profile
      data={getData()}
      editable={false}
      withTabs={false}
      withDescription={false}
      withFooter={getFooter()}
      className="sendingCommandsProfileMain"
    >
      <div className="sendingCommandsProfile">
        <Title devicesInfo={devicesInfo} />
        <SendCommandList
          devices={
            parseConnectedDevices(
              data.origins,
            )
          }
          // eslint-disable-next-line no-shadow
          devicesInfo={(data) => setDevicesInfo(data)}
          checkable={checkable}
          onChange={handleSetListData}
          errors={errors}
          nameless={false}
          id={data.id}
        />
      </div>
    </Profile>
  );
};

ProfileSendingCommands.defaultProps = {
// data: { name: '' },
  data: '',
  checkable: true,
  onClose: () => {},
  intl: {},
};

ProfileSendingCommands.propTypes = {
/* Widget data */
// data: PropTypes.shape({ name: PropTypes.string }),
  data: PropTypes.string,
  /* If list is checkable or not */
  checkable: PropTypes.bool,
  /* Event that triggers on closing */
  onClose: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any),
};

export default injectIntl(ProfileSendingCommands);
