/* eslint-disable import/no-cycle */
import validate from 'validate.js';

import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import { ModelExpected } from './attributes';

import {
  getKpi, addKpi, deleteKpis, updateKpi, executeKpi,
} from '../../services/redux/kpis/actions';

import {
  getDevice,
} from '../../services/redux/devices/actions';

/** Class Kpi with Utils functions */
export default class Kpi extends Utils {
  static entityName = 'Kpi';

  constructor(obj = {}) {
    const objCopy = { ...obj };
    if (!objCopy.type) {
      objCopy.type = (
        objCopy.definition?.type === 'calculate'
        && objCopy.definition?.process.step === 'arithmetic'
      )
        ? 'arithmetic'
        : 'simple';
    }
    // TODO: Improve this
    if (objCopy.value === 0) {
      objCopy.value = String(objCopy.value)
    }
    super(ModelExpected, objCopy);
    this.Model = Kpi;
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });
    return validate(this.getData(), filteredConstraints);
  }

  get() {
    const error = validate(
      this.getData(),
      { id: this.constraints.id },
    );

    return !error
      ? getKpi(this.getData())
      : { error };
  }

  save() {
    addKpi({ ...this.getData() });
  }

  update() {
    const error = this.validate(Object.keys(this.getData()));
    const entity = { ...this.getData() };
    return !error
      ? updateKpi(entity)
      : { error };
  }

  delete() {
    const error = validate(
      this.getData(), { id: this.constraints.id },
    );
    return !error
      ? deleteKpis(this.getData())
      : { error };
  }

  getDevices() {
    const errors = this.validate(['id']);
    return errors
      ? getDevice(this.getData())
      : errors;
  }

  execute() {
    const error = this.validate(Object.keys(this.getData()));
    const entity = { ...this.getData() };
    return !error
      ? executeKpi(entity)
      : { error };
  }

  plainTranslations = CreateTranslationPlainForModels('Kpi', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}

const SampleKpi = new Kpi();
export { SampleKpi };
