import Attribute from '../../../../models/Attribute/Attribute/index';
export const loraParser = (state, entity) => {
  const { newEntity } = state;
  const attributes = newEntity.attributes;

  const internal_attributes = {
    lorawan: {
      application_server: {
        host: 'eu.thethings.network',
        username: newEntity.extraConfigurations.username,
        password: newEntity.extraConfigurations.password,
        provider: 'TTN',
      },
      data_model: newEntity.extraConfigurations.data_model,
      dev_eui: newEntity.extraConfigurations.dev_eui,
      app_eui: newEntity.extraConfigurations.app_eui,
      application_id: newEntity.extraConfigurations.application_id,
      application_key: newEntity.extraConfigurations.application_key,
    },
  };

  const entityToCreate = { ...newEntity, internal_attributes };

  const service = newEntity.fiware;
  entityToCreate.fiware = { ...service, servicepath: '/fiwoo' };
  entityToCreate.transport = 'CUSTOM';

  entityToCreate.attributes = [];
  attributes.forEach(a => {
    return entityToCreate.attributes.push(new Attribute({ ...a }).getData());
  });

  if (entity.entityName === 'Service')
    delete entityToCreate.internal_attributes.lorawan.dev_eui;

  return new entity(entityToCreate);
};
