/* eslint-disable import/no-cycle */
import React from 'react';
import Moment from 'moment';
import { validate as validateJsLib } from 'validate.js';
import { clone } from 'ramda';
import {
  isEqual, sortBy, cloneDeep, isNil,
} from 'lodash';
import ModelExpected from './attributes';
import {
  formatToCommonWidgetSingleDevice,
  getAllDeviceAttributes,
  removeDeprecatedHistoricals,
  getDateOrigin,
} from './utils';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import Skeleton from '../../components/Skeleton';
import { ReactComponent as DefaultWidgetIcon } from '../../configuration/icons/svg/ico-widget.svg';
import { FormattedMessage } from '../../Contexts/LanguageContext';
import WidgetCard from '../../elements/WidgetCard';
import { getDateFromSampling, getResolution, getResolutionByDateRange } from '../../helpers/samplingHistorical';
import { getEntityForUrn, havePermissionToEdit } from '../../helpers/utils';
import {
  addWidget,
  deleteWidget,
  getWidget,
  updateWidget,
} from '../../services/redux/widgets/actions';
import SelectDatasources from '../../pages/Widgets/Add/steps/SelectDatasourcesV2';
import ConfigureWidgetV2 from '../../pages/Widgets/Add/steps/ConfigureWidgetV2';
import WidgetPreviewV2 from '../../pages/Widgets/Add/steps/WidgetPreviewV2';

/** Class Widget with Utils functions */
export default class Widget extends Utils {
  static entityName = 'Widget';

  static widgetIcon = DefaultWidgetIcon;

  static wizardSteps = {
    SelectDatasources,
    ConfigureWidgetV2,
    WidgetPreviewV2,
  };

  hasMultipleAttributes = false;

  hasOnlyAverageOperation = false;

  hasMultipleSources = false;

  skeleton = DefaultWidgetIcon;

  sendCommandOption = true;

  customWidgetCard = null;

  hiddenWidgetCard = false;

  typeContainer = null;

  needsSocket = true;

  component = () => (
    <FormattedMessage id="Widget.no-valid" />
  );

  componentSetted = null;

  props = {}

  hasPermissionsToEdit = false;

  constructor(obj = {}) {
    super(ModelExpected, obj);
    if (this.version === 'v1') {
      this.sources = this.origins.map((o) => ({
        urn: `fiwoo:${o.type.toLowerCase()}:${o.connectedDevices.id}`,
        fields: getAllDeviceAttributes(o.connectedDevices).filter((f) => typeof f === 'string'),
      }));
      this.version = 'v2';
    }
    havePermissionToEdit(obj.permissions_policy, obj.owner)
      .then((permission) => {
        this.hasPermissionsToEdit = permission;
      });
  }

  // * --------------------- [DATA METHODS] --------------------- * //

  cleanOrigins = (origins) => origins.map((origin) => ({
    categories: origin.categories,
    type: origin.type,
    connectedDevices: {
      attributes: origin.connectedDevices.attributes,
      command_attributes: origin.connectedDevices.command_attributes,
      device_id: origin.connectedDevices.device_id,
      id: origin.connectedDevices.id,
      lazy_attributes: origin.connectedDevices.lazy_attributes,
      static_attributes: origin.connectedDevices.static_attributes,
    },
  }));

  getBodyQueryHistoricalLinked = (selectedDevices, linkedWidgets) => {
    let dataForQueries = [{
      type: 'matrix-last-value',
      sources: [],
    }];

    linkedWidgets.forEach((widget) => {
      const { endDate, startDate } = widget.config.data;
      const usedAttributes = widget.config.data.attributeFilter;
      const usedDevices = selectedDevices.filter(
        (device) => {
          const attrs = getAllDeviceAttributes(device.connectedDevices);
          const isThere = attrs.filter((attr) => usedAttributes.includes(attr));
          return isThere.length;
        },
      );
      const usedSampling = widget.config.data.sampling;
      const fromTo = widget.config.data.type === 'historical' && endDate && startDate ? { endDate, startDate } : null;

      const historicalQueryBody = this.getDataForQueries({
        type: 'DEVICE',
        devices: usedDevices,
        attributes: usedAttributes,
        queryType: 'aggregate',
        operation: 'avg',
        sampling: usedSampling,
        periodTime: fromTo,
      });

      const lastValueSources = this.getDataForQueries({
        type: 'DEVICE',
        devices: usedDevices,
        attributes: usedAttributes,
      });

      // * Group petitions to the same device/attribute/sampling but with different operation.
      const { sources } = dataForQueries[0];

      const allSources = [...lastValueSources, ...sources];
      const allSourcesParsed = [];
      allSources.forEach((s) => {
        const index = allSourcesParsed.findIndex((ls) => s.urn === ls.urn);
        if (index >= 0) {
          allSourcesParsed[index].fields = [
            ...new Set([...allSourcesParsed[index].fields, ...s.fields]),
          ];
          return;
        }
        allSourcesParsed.push(s);
      });

      // * We iterate through the array of linked widgets and act according to the widget type.
      // * We identify the attributes used in each widget and browse the list of devices
      // * looking for those that contain them.
      dataForQueries = this.getWidgetChildBodyQuery(
        dataForQueries, { historicalQueryBody, allSourcesParsed },
      );
    });
    if (!dataForQueries[0].sources.length) delete dataForQueries[0];
    return this.mergeAggregateBody(dataForQueries);
  }

  // eslint-disable-next-line class-methods-use-this
  formatToData(historicalValues) {
    return formatToCommonWidgetSingleDevice(
      historicalValues,
    );
  }

  updateHistoricalBySocket(historicals, data) {
    if (!this.needsSocketForHistorical()) return null;
    const { TimeInstant: { value: newDataOrigin } } = data;
    const { data: { sampling } } = this.config;
    const updatedHistoricals = cloneDeep(historicals);
    const resolution = getResolution(sampling);
    const originDate = getDateOrigin(Moment.utc(newDataOrigin), resolution);
    const indexHistorical = historicals.findIndex((o) => o.origin === originDate.origin);
    // * Suscribed fields of the source that is being updated by the socket.
    const affectedSourceFields = this.sources.find(
      (source) => source.urn === data.urn,
    ).fields;
    // * Only the suscribed fields that are being updated by the socket.
    const updatedFields = affectedSourceFields.filter((field) => data[field]);

    if (indexHistorical >= 0) {
      // * If the data coming from the socket has an origin that we've already registered on
      // * historicals we have the two following cases:

      // * If there is an attribute coming from the socket that we don't have in our historical
      // * registry yet we make the body for it and push it to an array that will be containing
      // * the updated historicals.
      const attrsInEntities = historicals[indexHistorical].entities.map(
        (entity) => entity.attrName,
      );
      updatedFields.forEach((field) => {
        if (!attrsInEntities.includes(field)) {
          updatedHistoricals[indexHistorical].entities.push(
            {
              attrName: field,
              entityId: data.id,
              entityType: data.type,
              points: [],
              resolution,
              urn: data.urn,
            },
          );
        }
      });

      // * We loop through all the entities available (attributes) in the historical that is
      // * being updated.
      historicals[indexHistorical].entities.forEach((entity, entityIndex) => {
        if (!isNil(data[entity.attrName]?.value)) {
          const indexOffset = entity.points.findIndex((o) => o.offset === originDate.offset);
          const newValue = Number(data[entity.attrName].value);
          if (indexOffset >= 0) {
            // * 1. Case: in case we have a register for the attribute being updated at that offset
            const { avg, samples } = entity.points[indexOffset];
            updatedHistoricals[indexHistorical].entities[entityIndex]
              .points[indexOffset].avg = ((avg * samples) + newValue) / (samples + 1);
            updatedHistoricals[indexHistorical].entities[entityIndex]
              .points[indexOffset].samples = samples + 1;
          } else {
            // * 2. Case: in case we don't have a register for the attribute being updated
            // * at that offset
            updatedHistoricals[indexHistorical].entities[entityIndex].points.push({
              offset: originDate.offset,
              samples: 1,
              avg: newValue,
            });
          }
        }
      });
    } else {
      // * If the new data coming does not have it's origin registered on historicals we proceed
      // * to inject the necessary body to save the data.
      updatedHistoricals.push({
        origin: originDate.origin,
        entities: updatedFields.map(
          (field) => ({
            attrName: field,
            entityId: data.id,
            entityType: data.type,
            points: [
              {
                offset: originDate.offset,
                samples: 1,
                avg: Number(data[field].value),
              },
            ],
            resolution,
            urn: data.urn,
          }),
        ),
      });
    }

    return removeDeprecatedHistoricals(updatedHistoricals, sampling);
  }
  // * --------------------- [DATA METHODS] --------------------- * //

  // *! --------------------- [COMMON BODY] --------------------- !* //

  injectData = () => {
    this.showError('injectData', ['widget']);
  }

  getValidatorConfig = () => {
    this.showError('getValidatorConfig');
  }

  getConfigurationSheet() {
    this.showError('getConfigurationSheet', ['basic']);
  }

  getWidgetChildBodyQuery() {
    this.showError('getWidgetChildBodyQuery', ['dataForQueries']);
  }

  getQueryHistorical() {
    this.showError('getQueryHistorical');
  }

  parsedLinkedData() {
    this.showError('parsedLinkedData', ['values', 'selection', 'historical', 'intl']);
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //

  // * --------------------- [REDUX & CRUDS] --------------------- * //

  /** Call Redux action for Save Widget in DB and Redux */
  save(updateDashboardCallback, parent) {
    const widget = this.getData();
    /*
      This method inject old "validate" injection. If Widget need
      to inject extra config with datasources, here it can be done.
    */
    const injectedData = this.injectData(widget);
    if (injectedData) widget.config = injectedData;

    const filteredConstraints = clone(this.constraints);
    delete filteredConstraints.id;

    widget.sources = widget.sources.map(({ urn, fields }) => ({ urn, fields }));

    return this.validateWithAction(
      () => addWidget({ ...widget, updateDashboardCallback, parent }),
      widget,
      false,
      filteredConstraints,
    );
  }

  saveWithoutCallback() {
    const filteredConstraints = clone(this.constraints);
    delete filteredConstraints.id;
    return this.validateWithAction(addWidget, this.getData(), false, filteredConstraints);
  }

  transformToAPI = (data) => {
    const clonedData = cloneDeep(data);
    clonedData.container = clonedData.container?.id || clonedData.container;
    delete clonedData.ModelExpected;
    delete clonedData.constraints;
    delete clonedData.dashboard;
    delete clonedData.defaultConfig;
    delete clonedData.translations;
    delete clonedData.plainTranslations;
    return clonedData;
  }

  /** Call Redux action for Update Widget in DB and Redux */
  update = (data, oldData, sources) => {
    const newWidget = data ?? this.getData();
    if (this.cleanConfigOnChange && newWidget.config.data.type !== oldData.data.type) {
      const newConfig = this.injectData(newWidget, oldData, sources);
      newWidget.config = newConfig;
    }
    const transformedData = this.transformToAPI(newWidget);
    return this.validateWithAction(() => updateWidget(transformedData));
  }

  delete() {
    return this.validateWithAction(deleteWidget, this.getData(), true);
  }

  get() {
    const { id } = this.getData();
    return this.validateWithAction(getWidget, { id }, true);
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => { filteredConstraints[d] = this.constraints[d]; });

    const validation = validateJsLib(this.getData(), filteredConstraints);

    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  // * --------------------- [REDUX & CRUDS] --------------------- * //

  // * --------------------- [AUX METHODS] --------------------- * //

  skeletonComp = () => <Skeleton svg={this.skeleton} />

  getUrnSources() {
    return this.sources.map((s) => s.urn);
  }

  getDataForQueries = ({
    sources,
    queryType = 'lastValue',
    operation = 'avg',
    sampling = 'lastYear',
    periodTime,
  }) => {
    const rawSources = sources
      .filter((s) => s.fields.length)
      .map((source) => ({
        urn: source.urn,
        fields: sortBy(source.fields),
      }));

    const availableQueries = {
      lastValue: this.getLastValueQuery,
      aggregate: this.getAggregateQuery,
      standard: this.getStandardQuery,
    };

    if (queryType && availableQueries[queryType]) {
      return availableQueries[queryType](
        rawSources, operation, sampling, periodTime,
      );
    }
    return availableQueries.lastValue(sources);
  }

  getLastValueQuery = (sources) => sources;

  getAggregateQuery = (sources, operation, sampling, periodTime) => {
    const { startDate, endDate, period } = periodTime;
    const from = period === 'temporary-period'
      ? startDate : getDateFromSampling('startDate', sampling);
    const to = period === 'temporary-period'
      ? endDate : getDateFromSampling('endDate');
    const res = period === 'temporary-period' ? getResolutionByDateRange(startDate, endDate) : getResolution(sampling);
    const aggregateBodyQuery = {
      type: 'matrix-aggr',
      sources,
      ops: [operation],
      res,
      from,
      to,
      pagination: {
        page: 1,
        size: 25,
      },
    };
    return aggregateBodyQuery;
  }

  getStandardQuery = (sources) => sources.map((source) => ({
    type: 'matrix-last-value',
    urn: source.urn,
  }))

  mergeAggregateBody = (bodies) => {
    const newAggregates = [];
    const notAggregates = bodies.filter((b) => b.type !== 'matrix-aggr');
    const aggregates = bodies.filter((body) => body.type === 'matrix-aggr');
    aggregates.forEach((bodyAggr) => {
      const {
        res: resolution,
        from,
        to,
        sources,
      } = bodyAggr;

      if (!sources.length) return;

      const index = newAggregates.findIndex(
        (aggr) => aggr.res === resolution && aggr.from === from && aggr.to === to,
      );
      if (index >= 0) {
        const allSources = [...newAggregates[index].sources, ...bodyAggr.sources];
        const deleteDuplicates = [];
        allSources.forEach((source) => {
          const i = deleteDuplicates.findIndex((s) => s.urn === source.urn);
          if (i >= 0) {
            deleteDuplicates[i] = {
              ...deleteDuplicates[i],
              fields: [...new Set([...deleteDuplicates[i].fields, ...source.fields])],
            };
            return;
          }
          deleteDuplicates.push(source);
        });
        newAggregates[index] = {
          ...newAggregates[index],
          ops: [...new Set([...newAggregates[index].ops, ...bodyAggr.ops])],
          sources: deleteDuplicates.map((source) => {
            const sourceOfMap = bodyAggr.sources.find((s) => s.urn === source.urn);
            const map = sourceOfMap
              ? [...source.fields, ...sourceOfMap?.fields]
              : [...source.fields];
            return {
              urn: source.urn,
              fields: [...new Set(map)],
            };
          }),
        };
      } else {
        newAggregates.push(bodyAggr);
      }
    });
    return [...notAggregates, ...newAggregates];
  }

  checkFields = (fields, lastValuesFields) => isEqual(fields, lastValuesFields)

  getEmptyHistoricalBody = () => [{
    type: 'matrix-last-value',
    sources: [],
  }]

  showError = (functionName, args = [], file = 'ParentWidgetModel') => {
    console.error(`[${file}] Error --> You need to overwrite this function: ${functionName}`);
    if (args.length) {
      args.forEach((arg, i) => {
        console.error(`[${file}] ${i + 1}º Argument Expected ---> ${arg}`);
      });
    } else {
      console.error(`[${file}] This function doesn't expect any input argument`);
    }
  }

  injectUrnAndFields(sources) {
    const newSources = [];
    sources.forEach((source) => {
      const d = {
        urn: `fiwoo:${getEntityForUrn(source.type)}:${source.id}`,
        fields: [],
        metadata: {},
      };

      if (source.joinedAttributes) {
        source.joinedAttributes.forEach((attribute) => {
          if (attribute.selected) {
            d.fields.push(attribute.name);
          }
        });
      }
      newSources.push({ ...source, ...d });
    });

    this.sources = newSources;
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('Widget', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);

  // * --------------------- [AUX METHODS] --------------------- * //

  // * --------------------- [COMPONENTS] --------------------- * //

  getWidgetCard = () => {
    const { hiddenWidgetCard, customWidgetCard: CustomComp, widgetType } = this;
    return ({
      children, title, hasPermissionToEdit, actions, ...rest
    }) => {
      const Comp = CustomComp
        ? (
          <CustomComp
            hasPermissionToEdit={hasPermissionToEdit}
            title={title}
            actions={actions}
            dashboard={this.dashboard}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          >
            {children}
          </CustomComp>
        )
        : (
          <WidgetCard
            hasPermissionToEdit={hasPermissionToEdit}
            title={title}
            actions={actions}
            widgetType={widgetType}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          >
            {children}
          </WidgetCard>
        );
      const EmptyComp = <>{children}</>;
      return hiddenWidgetCard ? EmptyComp : Comp;
    };
  }

  getComponent = () => {
    if (this.componentSetted) return this.componentSetted;
    const WidgetCardComp = this.getWidgetCard();
    const Component = this.component;
    const { props, widgetType } = this;
    const ComponentMerged = ({
      values, activeWidget, actions, widgetCard, ...componentProps
    }) => (
      <WidgetCardComp
        actions={actions}
        activeWidget={activeWidget}
        hasPermissionToEdit={componentProps.hasPermissionToEdit}
        title={componentProps.widget.name}
        transparent={componentProps.widget.transparent}
        widgetCard={widgetCard}
        widget={componentProps.widget}
        widgetType={widgetType}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component values={values} {...componentProps} {...widgetCard} {...props} />
      </WidgetCardComp>
    );

    this.componentSetted = ComponentMerged;
    return ComponentMerged;
  }

  needsSocketForHistorical() {
    return false;
  }
  // * --------------------- [COMPONENTS] --------------------- * //
}
const SampleWidget = new Widget();
export { SampleWidget };
