import React from 'react';
import Button from '../../../../elements/Button/index';
import Styled from 'styled-components';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import User from '../../../../models/User';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

const CancelMsg = () => (
  <FormattedMessage
    id="options.cancel"
    defaultMessage="Cancel"
    description="Cancel option"
    component={'span'}
  />
);

const DeleteMsg = () => (
  <FormattedMessage
    id="options.reset.password"
    defaultMessage="Reset password"
    description="ResetPassword option"
    component={'span'}
  />
);

const Wrapper = Styled.div`
padding: 54px;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`;

class ResetModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      continue: true
    };
  }

  resetUserPassword = (e, user, callback) => {
    e.preventDefault();
    e.stopPropagation();

    user = new User(user);
    user.resetPassword();
  };

  componentDidUpdate = () => {};

  render() {
    if (this.props.newPassword) {
      return (
        <Wrapper>
          <div className="info mt-auto text-center">
            <FormattedMessage
              id="users.reset.password.success"
              defaultMessage="Password reset success"
              description="Message for delete user modal window"
              component={'h3'}
            />
            <div className="passwordPanel">
              <div>
                <dt>
                  <FormattedMessage
                    id="users.reset.user.title"
                    defaultMessage="User"
                    description="User Title"
                  />
                </dt>
                <dd>{this.props.user.user.email}</dd>
              </div>
              <div className="divisor"></div>
              <div>
                <dt>
                  <FormattedMessage
                    id="users.reset.password.title"
                    defaultMessage="Password"
                    description="Password Title"
                  />
                </dt>
                <dd>{this.props.newPassword || 'Sin contraseña'}</dd>
              </div>
            </div>
          </div>
          <div className="buttons mt-auto text-right">
            <Button
              className="btn btn-outline-primary"
              onClick={this.props.hideModal}
            >
              <CancelMsg />
            </Button>
            <Button
              className="btn btn-primary"
              onClick={e => {
                this.resetUserPassword(
                  e,
                  this.props.user.user,
                  this.props.hideModal
                );
              }}
            >
              <DeleteMsg />
            </Button>
          </div>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <div className="info mt-auto text-center">
            <FormattedMessage
              id="users.reset.password.confirmMessage"
              defaultMessage="Confirm you want to delete the user:"
              description="Message for delete user modal window"
              component={'h3'}
            />
            <div className="passwordPanel">
              <div>
                <dt>
                  <FormattedMessage
                    id="users.reset.user.title"
                    defaultMessage="User"
                    description="User Title"
                  />
                </dt>
                <dd>{this.props.user.user.email}</dd>
              </div>
              <div className="divisor"></div>
              <div>
                <dt>
                  <FormattedMessage
                    id="users.reset.password.title"
                    defaultMessage="Password"
                    description="Password Title"
                  />
                </dt>
                <dd>
                  <FormattedMessage
                    id="users.reset.password.hidden"
                    defaultMessage="**********"
                    description="Hidden Password"
                  />
                </dd>
              </div>
            </div>
          </div>
          <div className="buttons mt-auto text-right">
            <Button
              className="btn btn-outline-primary"
              onClick={this.props.hideModal}
            >
              <CancelMsg />
            </Button>
            <Button
              className="btn btn-primary"
              onClick={e => {
                this.resetUserPassword(
                  e,
                  this.props.user.user,
                  this.props.hideModal
                );
              }}
            >
              <DeleteMsg />
            </Button>
          </div>
        </Wrapper>
      );
    }
  }
}

const mapStateToProps = state => ({
  newPassword: state.get('users').get('newPassword')
});

export default connect(mapStateToProps)(injectIntl(ResetModalContent));
