import React from 'react';
import './styles.scss';
import SaveButton from '../../../elements/SaveButton/index';
import { ButtonCustom } from '../../../elements/Button/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import RemoveHoc from '../RemoveComponentSimplify';


class EditableCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onEdit: this.props.onEdit,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.onEdit !== this.props.onEdit) {
      this.setState({
        onEdit: this.props.onEdit,
      });
    }
  }

  handleOnSave = () => {
    // this.setState({ onEdit: !onEdit }, this.props.onSave);
    this.props.onSave();
  };

  handleOnEdit = () => {
    const newOnEdit = this.state.onEdit;
    // if component is controlled by parent internal state is not changed internally
    if (this.props.onEdit !== undefined) this.props.editStatusChange(this.state.onEdit);
    else { this.setState({ onEdit: !newOnEdit }, () => this.props.editStatusChange(this.state.onEdit)); }
  };

  handleOnDelete = () => {
    this.props.onDelete();
  };

  handleOnCancel = () => {
    this.props.onCancel();
  };

  render() {
    const ButtonOpenRemove = ({ showModal }) => <i className="uil uil-times" onClick={showModal} />;
    const RemoveDOM = RemoveHoc(ButtonOpenRemove);

    const {
      deletable, isEditable, style, header, name, stringTranslationHeader, confirmationModalForDeleting, onClickTimeButton, disableTimeButton, ruleCondition, ...rest
    } = this.props;
    const { onEdit } = this.state;
    return (
      <div
        {...rest}
        style={style}
        className={`editableCard ${onEdit ? 'onEdit' : ''} ${
          this.props.className
        }`}
      >
        {isEditable && !onEdit && deletable && confirmationModalForDeleting && (
          <RemoveDOM onDelete={this.handleOnDelete} data={{ name }} stringTranslationHeader={stringTranslationHeader} />
        )}

        {isEditable && !onEdit && deletable && !confirmationModalForDeleting && (
          <i className="uil uil-times" onClick={this.handleOnDelete} />
        )}
        {header
          && (
          <div className="editableCard-header">
            {header}
          </div>
          )}
        <div className="editableCard-body">
          {this.props.render(onEdit)}
        </div>
        <div className={`editableCard-footer ${onEdit && ruleCondition ? 'twoBtns' : ''}`}>
          {onEdit && ruleCondition && (<ButtonCustom
            type="circular"
            name="hourglass"
            onClick={onClickTimeButton}
            disabled={disableTimeButton}
          />
          )}
          {!isEditable ? null : !onEdit ? (
            <i className="uil uil-edit-alt" onClick={this.handleOnEdit} />
          ) : (
            <div>
              <SaveButton onClick={this.handleOnSave} />
              <ButtonCustom
                type="third"
                label={<FormattedMessage id="options.cancel" />}
                color="#6C7A88"
                handleOnClick={this.handleOnCancel}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

EditableCard.defaultProps = {
  deletable: true,
  isEditable: true,
  className: '',
  confirmationModalForDeleting: true,
};

export default EditableCard;
