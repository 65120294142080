import React, { memo, useMemo } from 'react';

const Html = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetHTML })),
);

function HtmlComponent(props) {
  const { widget, grid } = props;

  const Skeleton = useMemo(() => widget.skeletonComp, []);

  // * Change the condition of the next if statement when its necessary to render a Skeleton
  // * in case the Widget HTML is receiving data from backend.
  // eslint-disable-next-line no-constant-condition
  if (false) {
    return <Skeleton />;
  }
  return (
    <Html
      config={widget.config}
      refresh={grid.isResizable}
    />
  );
}

export default memo(HtmlComponent);
