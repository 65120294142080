import React from 'react';
import { FormattedMessage } from 'react-intl';
import WithDrawer from '../../DrawerContainer/index';

import '../styles.scss';

const ContextMenuForMobile = WithDrawer(({ title, onClose, children }) => (
  <div className="contextMenuForMobile">
    <header>
      {title || <FormattedMessage id="ContextMenuForMobile.title" /> }
      {
        <div
          onClick={onClose}
          role="presentation"
        >
          <FormattedMessage id="options.done" />
        </div>
      }
    </header>
    <section>
      {children}
    </section>
  </div>
));

export default ContextMenuForMobile;
