import NeedleDefaultConfiguration from '../../../../needle/utils/defaultConfiguration';

// eslint-disable-next-line import/prefer-default-export
export const NeedleDefaultConfigurationForLinked = {
  ...NeedleDefaultConfiguration,
  appearance: {
    min: 0,
    max: 100,
  },
};
