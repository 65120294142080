import React from 'react';
import { Redirect, Router } from '@reach/router';

/** Validate Login */
import PrivateRoute from './components/PrivateRoutes';

/** Pages */
import Login from './pages/Login/login';
import NotFound from './pages/404';

// Users
import ListUsers from './pages/Users';
import CopyUser from './pages/Users/Users/Copy/Copy';

// Permissions
import ListPermission from './pages/Permissions/List/List';

// Devices
import ListDevices from './pages/Devices/List/List';

// Devices
import ListRules from './pages/Rules/List/List';

// Services
import ListServices from './pages/Services/List/List';

// Templates
import ListTemplates from './pages/Templates/List/List';

// Dashboard
import ListDashboards from './pages/Dashboards/List/List';
import ShowV2 from './pages/Dashboards/ShowV2';
import ShowThumbnail from './pages/Dashboards/ShowThumbnail/ShowThumbnail';
import SharedDashboard from './pages/Dashboards/Shared';
import ForbiddenDashboard from './pages/Dashboards/Forbidden';

// Widget
import ListWidgets from './pages/Widgets/List/List';
import { ExternalPage } from './pages/ExternalPage';
import Home from './components/Home';
import { enterRoute } from './configuration/config';

// Alerts
import ListAlerts from './pages/Alerts/List';

// KPIs
import ListKpis from './pages/Kpis/List/List';

const Level = ({ children }) => <>{children}</>;
export default () => (
  <Router>

    <Redirect from="/" to={enterRoute} default noThrow />

    <Login path="app/login" component={Login} />
    <Level path="app">
      {/**  Default Routes * */}
      <PrivateRoute default component={NotFound} />
      <PrivateRoute path="/" component={ListDashboards} />

      {/** Home Routes */}
      <Level path="/home">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={Home} />
      </Level>

      {/** Dashboard Routes * */}
      <Level path="/dashboard">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListDashboards} />

        <PrivateRoute path="/:id" component={ShowV2} />
        <PrivateRoute
          path="/:id/show-thumbnail"
          component={ShowThumbnail}
        />
        <SharedDashboard path="/:id/shared" component={SharedDashboard} />
        <ForbiddenDashboard path="/forbidden" component={ForbiddenDashboard} />
      </Level>

      {/** Widgets Routes * */}
      <Level path="/widgets">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListWidgets} />
      </Level>

      {/** Users Routes * */}
      <Level path="/users">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListUsers} />
        <PrivateRoute path="/copy" component={CopyUser} />
      </Level>

      {/** Permissions Routes * */}
      <Level path="/permissions">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListPermission} />
      </Level>

      {/** Devices Routes * */}
      <Level path="/devices">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListDevices} />
      </Level>

      {/** Rules Routes * */}
      <Level path="/rules">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListRules} />
      </Level>

      {/** Services Routes * */}
      <Level path="/services">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListServices} />
      </Level>

      {/** Templates Routes * */}
      <Level path="/templates">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListTemplates} />
      </Level>

      {/** External Page Routes * */}
      <Level path="/iframe">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ExternalPage} hiddenPageHeader />
      </Level>

      {/** Alerts Routes * */}
      <Level path="/alerts">
        <PrivateRoute default component={NotFound} />
        <PrivateRoute path="/" component={ListAlerts} />
      </Level>

      { /* KPIs Routes */ }
      <Level path="/kpis">
        <PrivateRoute path="/" component={ListKpis} />
      </Level>

    </Level>
  </Router>
);
