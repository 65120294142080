/* eslint-disable import/no-cycle */
import linkedWidgets from '../widgets';
import { genericChildrenModelParser } from '../../../utils';

const allLinkedWidgets = {
  BARS: linkedWidgets.LinkedBars,
  TEXT_ACCOUNTANT: linkedWidgets.LinkedKpi,
  LINES: linkedWidgets.LinkedLines,
  NEEDLE: linkedWidgets.LinkedNeedle,
  PERCENTAGE: linkedWidgets.LinkedPercentage,
  TABLE: linkedWidgets.LinkedTable,
};

const childrenModelParser = (widget, noInstanced) => (
  genericChildrenModelParser(allLinkedWidgets, widget, noInstanced, 'LINKED'));

export default childrenModelParser;
