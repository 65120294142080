import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

export const loraProtocolValidator = (data, extraConfigurations, entity) => {
  const extraConfig = {
    username: '',
    password: '',
    data_model: '',
    app_eui: '',
    dev_eui: '',
    application_id: '',
    application_key: '',
    ...extraConfigurations,
  };

  const { protocol, fiware } = data;
  let {
    username,
    password,
    data_model,
    app_eui,
    dev_eui,
    application_id,
    application_key,
  } = extraConfig;
  let constraints = {};

  let fieldsToValidate = [];

  if(entity.entityName === 'Template') {
    fieldsToValidate = ['data_model'];
  } else {
    fieldsToValidate = [
      'protocol',
      'username',
      'password',
      'data_model',
      'app_eui',
      'dev_eui',
      'application_id',
      'application_key',
    ];
  };    
  
  fieldsToValidate.forEach(key => {
    constraints[key] = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id={'Validation.presence'} />,
      },
    };
  });

  if (entity.entityName === 'Service') dev_eui = 'DEV_EUI';

  if (entity.entityName === 'Template') {
    return validate(
      { fiware,data_model },
      constraints
    );
  } else {
    return validate(
      {
        protocol,
        fiware,
        username,
        password,
        data_model,
        app_eui,
        dev_eui,
        application_id,
        application_key,
      },
      constraints
    );
  };  
};
