/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { Col } from 'reactstrap';
import CustomInput from 'reactstrap/es/CustomInput';
import EditableCard from '../../helpers/EditableCard/EditableCard';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { getDevices, clearDevicesState } from '../../../services/redux/devices/actions';
import Switch from '../../../elements/Switch/index';

import './styles.scss';
import { ButtonCustom } from '../../../elements/Button';
import { AttributeSelect, OperatorsSelect, DeviceSelect } from './components/helpers/helpers';
import ConditionsUtils from '../helpers/ConditionsUtils';
import ConditionalButton from '../Add/steps/components/ConditionalComponents/ConditionalButton';
import TimeRuleEditable from './components/TimeRuleEditable';

import { updateRuleStatus, modifyRuleCron } from '../../../services/redux/rules/actions';
import { Weekly } from '../../../components/Scheduler/planner';

const parseCronToPlanner = (startRange, endRange) => {
  const [startMinute, startHour] = startRange.split(' ');
  const [endMinute, endHour] = endRange.split(' ');
  return ({
    startMinute,
    startHour,
    endMinute,
    endHour,
    range: startRange.split(' ').pop(),
  });
};
class EditConditions extends ConditionsUtils {
  constructor(props) {
    super({ props });
    const {
      updating, data, allDevices, devices,
    } = this.props;
    const conditionsRs = updating ? this.translateCriterionToConditions(
      data.engine.criterion,
    ) : data.events;
    this.state = {
      saved: clone(conditionsRs),
      conditions: conditionsRs,
      enable: data.status === 'enabled',
      criterion: clone(data.engine.criterion),
      ruleDevices: updating ? allDevices.filter(
        (d) => d.fiware.service === data.fiware.service,
      ) : devices,
      activeCards: this.initActiveCards(conditionsRs),
      errors: {},
      mainCondition: conditionsRs[1] ? conditionsRs[1][0] : undefined,
      page: 1,
      enableRuleCron: data.enable_cron,
      disableRuleCron: data.disable_cron,
      selected: !!data.enable_cron,
      planner: !!data.enable_cron
      && parseCronToPlanner(data.enable_cron, data.disable_cron),
    };
    this.state.selected = !!Object.keys(this.state.planner).length;
  }

  componentDidMount() {
    if (this.props.updating) {
      this.state.conditions.map((condition) => condition
        .map((c) => {
          if (!this.getDevice(this.props.allDevices, c)) {
            getDevices({
              filters: { deviceId: c.device },
              page: 1,
              size: 1,
              mode: '',
            });
          }
        }));

      getDevices({
        filters: { fiwareService: this.props.data.fiware.service },
        page: 1,
        size: 5,
      });
    }
  }

  updateAfterAllDevicesChange = () => {
    const { allDevices } = this.props;
    this.setState({
      ruleDevices: allDevices.filter(
        (d) => d.fiware.service === this.props.data.fiware.service,
      ),
    });
  }

  updateAfterDataChanges = (conditionsRs) => {
    const { allDevices, data } = this.props;
    this.setState({
      saved: clone(conditionsRs),
      conditions: conditionsRs,
      criterion: clone(data.engine.criterion),
      ruleDevices: allDevices.filter(
        (d) => d.fiware.service === this.props.data.fiware.service,
      ),
      activeCards: this.initActiveCards(conditionsRs),
      errors: {},
    });
  }

  updateAfterDevicesChanges = (devices) => {
    this.setState({
      ruleDevices: [...devices],
    });
  }

  updateStatus = (status) => {
    this.setState({
      enable: status === 'enabled',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { allDevices, data, devices } = this.props;
    if (prevState.page !== this.state.page) {
      const filters = {};
      filters.fiwareService = this.props.data.fiware.service;
      getDevices({
        filters,
        page: this.state.page,
        size: 5,
      });
    }

    if (data.status !== prevProps.data.status) {
      this.updateStatus(data.status);
    }

    if (JSON.stringify(allDevices) !== JSON.stringify(prevProps.allDevices)) {
      this.updateAfterAllDevicesChange();
    }

    if (JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
      const conditionsRs = this.translateCriterionToConditions(
        this.props.data.engine.criterion,
      );
      this.updateAfterDataChanges(conditionsRs);
    }

    if (devices !== prevProps.devices) {
      this.updateAfterDevicesChanges(devices);
    }
    if (prevState.enableRuleCron !== this.state.enableRuleCron
      || prevState.disableRuleCron !== this.state.disableRuleCron) {
      this.updateRule();
    }
  }

  handleEnableDisable(status) {
    updateRuleStatus({ ruleId: this.props.data.id, payload: { enabled: status } });
  }

  handleFetchInfo = () => {
    const { ruleDevices } = this.state;
    const { total, fetching } = this.props;
    if (
      (!fetching && total > ruleDevices.length)
      || (!fetching && ruleDevices.length < 5)
    ) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { devices } = this.state;
    if (init) clearDevicesState();
    if (devices && devices.length === 1) {
      getDevices({
        filters: { fiwareService: devices[0].fiware.service, name: value },
        page: 1,
        size: 5,
      });
    } else {
      getDevices({ filters: { name: value }, page: 1, size: 5 });
    }
  };

  handleRadioChange = () => {
    const { selected } = this.state;

    this.setState({
      selected: !selected,
      enableRuleCron: '',
      disableRuleCron: '',
    });
  }

  updateRule = () => {
    const { data } = this.props;
    const { enableRuleCron, disableRuleCron } = this.state;

    modifyRuleCron({
      ruleId: data.id,
      payload: { enable_cron: enableRuleCron, disable_cron: disableRuleCron },
    });
  }

  setPlanner = ({ startRange, endRange }) => {
    this.setState({ enableRuleCron: startRange, disableRuleCron: endRange });
  }

  render() {
    const {
      conditions,
      activeCards,
      errors,
      ruleDevices,
      enable,
      mainCondition,
      selected,
    } = this.state;
    const { intl, stringTranslationHeader } = this.props;

    return (
      <div className="tabSection">
        {this.props.permissionToEdit && (
        <div className="deviceInfo d-flex align-items-center">
          {this.props.updating && (
          <div className="d-flex align-items-center">
            <p>
              <strong>
                <FormattedMessage id="rules.enable" />
              </strong>
            </p>
            <div style={{ 'margin-left': '20px' }}>
              <Switch checked={enable} onChange={(status) => this.handleEnableDisable(status)} />
            </div>
          </div>
          )}
          <ButtonCustom
            label={intl.formatMessage({ id: 'rules.profile.condition' })}
            type="secondary"
            icon="plus"
            handleOnClick={() => this.handleOnClickAddButton(
              this.getButtonType(),
            )}
            className="ml-auto"
          />
        </div>
        )}
        {conditions.map((condition, orIndex) => condition.map((c, andIndex) => {
          const deviceInfo = ruleDevices && this.getDevice(ruleDevices, c);
          const deviceName = deviceInfo ? deviceInfo.name : c.device;
          const deviceId = conditions[orIndex][0].device;
          const nestedType = conditions[orIndex][1] ? conditions[orIndex][1].type : 'and';

          if (!this.conditionIsEmpty(c)) {
            return (
              <>
                <div
                  className={`d-flex ${
                    andIndex === 0
                      ? 'orConditionWrapper'
                      : 'andConditionWrapper'
                  }`}
                  key={`${orIndex}${andIndex}`}
                >
                  {andIndex === 0 && this.props.permissionToEdit && (
                  <>
                    <ButtonCustom
                      type="circular"
                      name="nest"
                      onClick={() => this.handleOnClickNestButton(
                        orIndex,
                        andIndex,
                        deviceId,
                        nestedType,
                      )}
                      className="buttonLine"
                    />
                  </>

                  )}
                  <EditableCard
                    name={c.variable.name || c.variable}
                    stringTranslationHeader={stringTranslationHeader}
                    isEditable={this.props.permissionToEdit && this.areRuleDevices()}
                    data={conditions}
                    disableTimeButton={c.timeWindow}
                    onSave={() => this.handleOnSave(orIndex, andIndex, ruleDevices)}
                    onEdit={activeCards[orIndex][andIndex]}
                    onDelete={() => this.handleOnDelete(orIndex, andIndex, ruleDevices)}
                    onCancel={this.handleOnCancel}
                    className={andIndex === 0 ? 'orCondition' : 'andCondition'}
                    onClickTimeButton={() => {
                      this.handleClickTimeButton(orIndex, andIndex, ruleDevices);
                    }}
                    condition={c}
                    confirmationModalForDeleting={this.props.updating}
                    editStatusChange={(value) => this.handleOnEditStatusChange(
                      value,
                      orIndex, andIndex,
                    )}
                    deletable={orIndex !== 0 || andIndex !== 0}
                    ruleCondition
                    render={(onEdit) => (
                      <>
                        <ConditionalButton
                          type={this.getConditionType({
                            andIndex, orIndex, mainCondition, condition: c,
                          })}
                          orIndex={orIndex}
                          andIndex={andIndex}
                          onClick={(selectedType) => this.handleOnChangeConditionalButton(
                            selectedType,
                            orIndex,
                            andIndex,
                          )}
                          fixed={(andIndex === 0 && orIndex > 1) || andIndex > 1}
                          onEdit={onEdit}
                        />
                        <dl>
                          <dt className="col-3">
                            <FormattedMessage
                              id="rules.wizard.attribute.device"
                            />
                          </dt>
                          <dd className="col col-md-7">
                            {!onEdit ? (
                              deviceName
                            ) : (
                              <DeviceSelect
                                onChange={(id, value) => this.handleSelectChange({
                                  id,
                                  value,
                                  orIndex,
                                  andIndex,
                                })}
                                value={ruleDevices && this.getDevice(ruleDevices, c)}
                                intl={intl}
                                async
                                fetchInfo={this.handleFetchInfo}
                                onSearch={this.handleOnSearch}
                                orIndex={orIndex}
                                andIndex={andIndex}
                                options={this.getOptions(ruleDevices)}
                                errors={errors.device}
                                disabled={andIndex !== 0}
                              />
                            )}
                          </dd>
                        </dl>
                        <dl>
                          <dt className="col-3">
                            <FormattedMessage
                              id="rules.wizard.attribute.label"
                            />
                          </dt>
                          <dd className="col col-md-7">
                            {!onEdit ? (
                              c.variable.name || c.variable
                            ) : (
                              <AttributeSelect
                                onChange={(id, value) => this.handleSelectChange({
                                  id,
                                  value,
                                  orIndex,
                                  andIndex,
                                })}
                                value={c.device && this.getAttribute(this.areRuleDevices()
                                  ? this.getOptions(
                                    this.joinedAttributes(
                                      this.getDevice(ruleDevices, c),
                                    ),
                                  )
                                  : [],
                                c)}
                                intl={intl}
                                orIndex={orIndex}
                                andIndex={andIndex}
                                options={this.areRuleDevices()
                                  ? this.getOptions(this.joinedAttributes(
                                    this.getDevice(ruleDevices, c),
                                  ))
                                  : []}
                                errors={errors.variable}
                              />
                            )}
                          </dd>
                        </dl>
                        <dl>
                          <dt className="col col-md-3">
                            <FormattedMessage
                              id="rules.wizard.operator.label"
                            />
                          </dt>
                          <dd className="col col-md-7">
                            {!onEdit ? (
                              c.operator
                            ) : (
                              <OperatorsSelect
                                onChange={(id, value) => this.handleSelectChange({
                                  id,
                                  value,
                                  orIndex,
                                  andIndex,
                                })}
                                value={c.operator}
                                intl={intl}
                                errors={errors.operator}
                                entity={this.getDevice(ruleDevices, c)}
                                attribute={c}
                              />
                            )}
                          </dd>
                        </dl>
                        <dl>
                          <dt className="col col-md-3">
                            <FormattedMessage id="rules.wizard.value.label" />
                          </dt>
                          <dd className="col-7">
                            {!onEdit
                              ? this.getValue({
                                criteria: c.criteria,
                                operator: c.operator,
                                value: c.value,
                              })
                              : this.getValueField(
                                this.handleSelectChange,
                                c,
                                errors,
                                orIndex,
                                andIndex,
                                intl,
                                this.getDevice(ruleDevices, c),
                              )}
                          </dd>
                        </dl>
                      </>
                    )}
                  />
                </div>
                {c.timeWindow && (
                <TimeRuleEditable
                  stringTranslationHeader={stringTranslationHeader}
                  onEdit={activeCards[orIndex][andIndex]}
                  onDelete={() => {
                    this.handleDeleteTimeCondition(orIndex, andIndex, ruleDevices);
                  }}
                  condition={c}
                  updateCondition={(updatedCondition) => {
                    this.setTimeCondition(updatedCondition, orIndex, andIndex);
                  }}
                  errors={errors}
                />
                )}
              </>
            );
          }
          return null;
        }))}
        <Col className="profilePlannerContainer">
          <div classNamed-flex>
            <h3>
              <FormattedMessage id="rules.wizard.time.mode" />
            </h3>
          </div>
          <div className="radioOption mb-4">
            <CustomInput
              name="always"
              label={<FormattedMessage id="rules.wizard.time.always" />}
              id="always"
              value={<FormattedMessage id="rules.wizard.time.always" />}
              type="radio"
              onChange={this.handleRadioChange}
              checked={!selected}
            />
            <CustomInput
              name="range"
              label={<FormattedMessage id="rules.wizard.time.range" />}
              id="range"
              value={<FormattedMessage id="rules.wizard.time.range" />}
              type="radio"
              onChange={this.handleRadioChange}
              checked={selected}
            />
          </div>
          {selected && (
            <Weekly
              setValue={this.setPlanner}
              range
              planner={this.state.planner}
              profile
            />
          )}
        </Col>
      </div>
    );
  }
}

EditConditions.defaultProps = {
  saveOnTheFly: true,
  onChange: () => {},
  updating: true,
};

const mapStateToProps = (state) => ({
  allDevices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(mapStateToProps, {})(injectIntl(EditConditions));
