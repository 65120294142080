/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import TableWidget from '../../../table';
import { formatToCommonWidgetSingleDevice, calculate } from '../../../../utils';
import formatToTableWidget from '../../../table/utils/formatData';
import { getUrnId } from '../../../../../../helpers/utils';
import { TableDefaultConfigurationForLinked, injectLinkedTableData } from './utils/defaultConfiguration';
import { TableConfigurationSheetForLinked, validationLinkedTable } from './utils/configurationSheet';

export default class LinkedTable extends TableWidget {
    defaultConfig = ({ ...TableDefaultConfigurationForLinked });

    getQueryHistorical = () => null

    ifUsesContainerValues = true

    injectData = (widget) => injectLinkedTableData(widget)

    getValidatorConfig = () => (config) => validationLinkedTable(config);

    getConfigurationSheet = () => TableConfigurationSheetForLinked;

    formatToData = (values, sources, config, selection) => {
      const { attributeFilter, operation } = config.data;
      if (values?.length || values?.data?.length) {
        if (selection.length === 1) {
          const selectedSourceValues = [];
          const includedAttr = [];
          values.forEach((v) => {
            if (attributeFilter.includes(v.attrName)
              && getUrnId(v.urn) === selection[0]) {
              selectedSourceValues.push(v);
              if (!includedAttr.includes(v.attrName)) includedAttr.push(v.attrName);
            }
          });
          const sourceSelected = sources.filter(
            (s) => selection.some((id) => id === s.id),
          );

          if (includedAttr.length !== attributeFilter.length) {
            const attrNotIncluded = attributeFilter.filter((o) => !includedAttr.includes(o));
            attrNotIncluded.forEach((attr) => selectedSourceValues.push({
              attrName: attr,
              recvTime: undefined,
              value: undefined,
              entityId: sourceSelected[0].sourceId,
            }));
          }
          return formatToTableWidget(selectedSourceValues, config, sourceSelected, true);
        }
        if (selection.length > 1) {
          const selectedSourceValues = [];
          const includedAttr = [];
          values.forEach((v) => {
            if (attributeFilter.includes(v.attrName)
              && selection.find((s) => s === getUrnId(v.urn))) {
              selectedSourceValues.push(v);
              if (!includedAttr.includes(v.attrName)) includedAttr.push(v.attrName);
            }
          });

          const sourcesSelected = sources.filter(
            (s) => selection.some((id) => id === s.id),
          );

          if (includedAttr.length !== attributeFilter.length) {
            const attrNotIncluded = attributeFilter.filter((o) => !includedAttr.includes(o));
            attrNotIncluded.forEach((attr) => selectedSourceValues.push({
              attrName: attr,
              recvTime: undefined,
              value: { value: undefined },
              entityId: 'custom',
            }));
          }
          const fakeSource = [cloneDeep(sourcesSelected[0])];
          const obj = [];
          attributeFilter.forEach((a) => {
            const data = selectedSourceValues.filter((v) => v.attrName === a);
            const allAttributeValues = [];
            data.forEach((d) => {
              const { value } = d.value;
              if (value) {
                allAttributeValues.push(Number(value));
              }
            });

            obj.push({
              attrName: a,
              value: allAttributeValues.length
                ? calculate(allAttributeValues, operation, 2)?.toString()
                : undefined,
              recvTime: undefined,
              entityId: 'custom',

            });
          });
          fakeSource[0].sourceId = 'custom';
          return formatToTableWidget(
            obj,
            config,
            fakeSource,
            true,
          );
        }
      }
      return formatToCommonWidgetSingleDevice(this.sources[0]);
    }
}
