import React, { useState } from 'react'
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { injectIntl } from 'react-intl';

const Button = (props) => {
    //const [isDone, setIsDone] = useState(false)
    const { data } = props

    const doneOrError = (data.response === 'done' || data.response === 'wrong')

    return (
        <div className={'buttonContainer'}>

{doneOrError ? 
    <div><button className={data.response + 'Button'} onClick={props.reset}> <i className={"uil uil-"+(data.response ==='done' ? 'check':'times')}></i></button><p className={data.response+'Text'}> <FormattedMessage id={`widget.sendCommand.single.`+data.response} /></p></div> :
<div><button className={data.status + 'Button'} onClick={props.onClick}> <div className={data.status ? 'checkOn' : 'checkOff' }></div></button><p className={'stateText'}>{data.label}</p></div>
}
            

        </div>
    )
}

export default injectIntl(Button)
