import React from 'react';
import styled from 'styled-components';
import { typography } from '../../configuration/fonts';
import colors from '../../configuration/colors';
import useAnimation from '../../pages/helpers/Hooks/useAnimation';
import './styles.scss';

const Wrapper = styled.div`
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(46,91,255,0.08);
    background-color: #FFFFFF;
    box-shadow: 0 8px 40px 0 rgba(210,218,240,0.4);
    height:100%;
    ${(p) => p.css}
`;

const Header = styled.div`

    padding: 20px 0px 15px 15px;
    color: ${colors['ui-Black']};
    font-family: ${typography};
    font-size: 1.07em;
    font-weight: 600;
    line-height: 1.2em;

`;

const Actions = styled.div`
float: right;

        color: ${colors['ui-Black']};
        font-size: 1em;
        font-weight: 600;
        line-height: 1.2em;
        margin-top: -10px;
`;

const Body = styled.div`
    display:flex;
    position: relative;
    padding: 0 2em 2em 2em;
    justify-content: center;
    align-items: center;
    height: calc(100% - 64px);
`;

const Footer = styled.div`
    border-radius: 0 0 6px 6px;
    background-color: ${colors['ui-LightBG']};
    color: ${colors['ui-Grey1']};
    font-size: 1.07em;
    line-height: 1.2em;
    padding: 9px 23px;
    text-align: center;

    button {
        border: 1px solid ${colors['ui-primary']};
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 9px 0 rgba(0,0,0,0.03);

        color: ${colors['ui-primary']};
        font-size: 1em;
        font-weight: 600;
        line-height: 1.2em;
        text-align: center;
        margin-left: 10px;
        padding: 9px 30px;
    }
`;

const WidgetCard = React.memo(({
  className, 
  children, 
  title, 
  footer, 
  actions, 
  dark, 
  hasPermissionToEdit,
  widgetType,
  transparent,
}) => {
  const { ref } = useAnimation({ distance: -100, duration: 1, delay: 0.15 });
  const existPermissions = typeof hasPermissionToEdit === 'boolean'; // TODO: Delete when widgetV2 is implemented.
  // TODO: [v2] Remove dark prop when v2 is fully implemented.
  return (
    <Wrapper ref={ref} className={(transparent || dark) ? 'wrapper-rgba' : 'wrapper-hex'}>
      {title
                && (
                <Header className={(transparent || dark) ? 'header-dark' : ''}>
                  {title}
                  {' '}
                  {existPermissions
                    ? actions && hasPermissionToEdit && <Actions className={(transparent || dark) ? 'actions-dark' : ''}>{actions}</Actions>
                    : actions && <Actions className={(transparent || dark) ? 'actions-dark' : ''}>{actions}</Actions>}
                </Header>
                )}
      <Body className={`wbody ${className} ${widgetType?.toLowerCase()}`}>{children}</Body>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
});

export default WidgetCard;
