import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { updateDashboard } from '../../../services/redux/dashboards/actions';
import EditPermissions from '../../helpers/Profile/EditPermissions';
import Dashboard from '../../../models/Dashboard';
import { havePermissionToEdit } from '../../../helpers/utils';
import checkPermission from '../../../components/PrivateComponent/checkPrivateComponent';

export class Profile extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;

    this.state = {
      data: { ...data },
    };
  }

  componentDidMount() {
    const { userMe, data } = this.props;
    const backendPermisssion = checkPermission(userMe, '/app/dashboard', 'update');
    havePermissionToEdit(data.permissions_policy, data.owner)
      .then((permissionToEdit) => this.setState(
        { permissionToEdit: permissionToEdit && backendPermisssion },
      ));
  }

  componentDidUpdate(prevProps) {
    const { data, dashboards } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.updateStateWithProps(data);
    }

    if (JSON.stringify(prevProps.dashboards) !== JSON.stringify(dashboards)) {
      const foundData = dashboards.find((o) => o.id === data.id);
      this.updateStateWithFoundData(foundData);
    }
  }

  handleOnSave = (idArg, dataArg) => {
    const { id, data } = this.state;
    if ([id] !== dataArg) {
      const newData = { ...data, [idArg]: dataArg };

      updateDashboard(newData);

    // this.setState({ data: newData});
    }
  };

  updateStateWithProps(data) {
    this.setState({ data: { ...data } });
  }

  updateStateWithFoundData(foundData) {
    this.setState({ data: foundData });
  }

  render() {
    const { profileType } = this.props;
    const { data, permissionToEdit } = this.state;
    if (profileType === 'edit') {
      return (
        <ProfileComponent
          data={data}
          titles={[
            <FormattedMessage id="dashboards.profile.permissions" />,
          ]}
          save={this.handleOnSave}
          editable={permissionToEdit}
        >
          <EditPermissions
            data={data}
            permissionToEdit={permissionToEdit}
            entity={Dashboard}
          />
        </ProfileComponent>
      );
    }

    if (profileType === 'clone') {
      return (
        <ProfileComponent
          data={data}
          titles={[
            <FormattedMessage id="dashboards.profile.infoClone" />,
          ]}
          save={this.handleOnSave}
        >
          {/* <CloneDashboard data={data} entity={Dashboard}></CloneDashboard> */}
        </ProfileComponent>
      );
    }

    if (profileType === 'share') {
      return (
        <ProfileComponent
          data={data}
          titles={[
            <FormattedMessage id="dashboards.profile.permissions" />,
          ]}
          save={this.handleOnSave}
        >
          <EditPermissions
            data={data}
            permissionToEdit={permissionToEdit}
            entity={Dashboard}
          />
        </ProfileComponent>
      );
    }
    
    return ('');
  }
}

Profile.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  userMe: PropTypes.objectOf(PropTypes.any),
  dashboards: PropTypes.arrayOf(PropTypes.any),
  profileType: PropTypes.string,
};

Profile.defaultProps = {
  data: {},
  userMe: {},
  dashboards: [],
  profileType: '',
};

const mapStateToProps = (state) => ({
  dashboards: state.get('dashboards').get('list').toJS(),
  userMe: state.get('users').get('userMe'),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
