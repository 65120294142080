import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withTheme } from '../../../../Contexts/ThemeContext/index';
import { getRoles } from '../../../../services/redux/roles/actions';
import Role, { SampleRole } from '../../../../models/Role/index';
import WithDrawer from '../../../../components/DrawerContainer';
import CreateRole from '../Add/AddWizard';
import Profile from '../Profile';
import BasicList from '../../../helpers/BasicList/index';
import OptionsForRow from './OptionsForRows';
import RemoveHoc from '../../../helpers/RemoveComponent';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';

const RemoveComponent = RemoveHoc(({ showModal }) => (
  <PrivateComponent
    checkLogin
    checkPermission
    microsService="/app/roles"
    permission="delete"
  >
    <i onClick={showModal} className="uil uil-trash-alt remove" />
  </PrivateComponent>
),
{ class: Role, nameData: 'roles' });

const headers = [
  {
    label: SampleRole.translations.name,
    key: 'name',
  },
];

const filters = [
  {
    name: SampleRole.translations.name,
    key: 'name',
    value: 'name',
  },
];

const ProfileComponent = WithDrawer(({ onChange, data, ...rest }) => (
  <Profile {...rest} onChange={onChange} data={data} />
));

export class List extends React.Component {
  state = {
    page: 1,
    itemsPerPage: 5,
    itemsShowed: 5,
    profileOpened: false,
  };

  componentDidMount() {
    const { page, itemsPerPage } = this.state;
    getRoles({ filters: [], page, itemsPerPage });
  }

  handleOnSubmit = (filters2) => {
    const { page, itemsPerPage } = this.state;
    getRoles({ filters2, page, itemsPerPage });
  };

  handleOnPageChange = (page, itemsPerPage, itemsShowed) => {
    this.setState({
      page,
      itemsPerPage,
      itemsShowed,
    });
  };

  handleOnProfileEvent = (opened) => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = (data) => {
    this.setState({
      profileOpened: true,
      activeRole: data,
    });
  };

  render() {
    const { roles, total } = this.props;
    const roleByData = roles.map((role) => role.toJson());
    return (
      <>
        <BasicList
          {...this.state}
          data={roleByData}
          total={total}
          onSubmit={this.handleOnSubmit}
          entityName="roles"
          entity={Role}
          exampleEntity={SampleRole}
          header={headers}
          profileComponent={ProfileComponent}
          optio
          createEntityComponent={CreateRole}
          filters={filters}
          externalFilter={false}
          expandable
          selectable
          onPageChange={this.handleOnPageChange}
          optionsForRow={OptionsForRow}
          onRemove={RemoveComponent}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.get('roles').get('list').toJS(),
  total: state.get('roles').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(injectIntl(List)));
