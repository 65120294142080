const ParametrizedTextDefaultConfiguration = {
  appearance: {
    aligment: 'center',
  },
  custom: {
    PARAMETRIZED_TEXT: {
      text: '...',
      content: { },
      parameters: { },
    },
  },
};

export default ParametrizedTextDefaultConfiguration;
