import validate from 'validate.js';

import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

/** Class ETLExecution with Utils functions */
export default class ETLExecution extends Utils {
  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = ETLExecution;
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? validation
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('ETLExecution', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleETLExecution = new ETLExecution();
export { SampleETLExecution };
