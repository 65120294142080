import Moment from 'moment';
import _, {
  sum, max, min, mean, pullAll, round, cloneDeep, merge, sortBy,
} from 'lodash';
import { getLanguageFromBrowser } from '../../helpers';
import { getDateOfSampling } from '../../helpers/samplingHistorical';

export const getAllDeviceAttributes = (device) => [
  ...(device && device.attributes ? device.attributes : []),
  ...(device && device.lazy_attributes ? device.lazy_attributes : []),
  ...(device && device.command_attributes ? device.command_attributes : []),
  ...(device && device.static_attributes ? device.static_attributes : []),
];

export const buildQuery = (origin, aggregate, configuration, size = 1, attr = undefined) => {
  let objectReturn = {};
  if (origin?.type === 'DEVICE') {
    const connectedDevice = origin.connectedDevices;
    const { readDevice } = connectedDevice;
    const defaultFiware = { service: 'service', servicepath: '/fiwoo' };
    const fiware = readDevice
      ? readDevice.fiware
      : defaultFiware;
    const entityType = readDevice ? readDevice.entity_type : '';
    objectReturn = {
      id: connectedDevice.device_id,
      entity_type: entityType,
      attribute: attr,
      fiware,
      aggregate,
      configuration,
      size,
    };
  } else if (origin?.type === 'ETL') {
    // We need the V2 of historical to use that (so far, it is branch 19-etl-query of historical)
    // Here is an exemple of what it will look like
    objectReturn = {
      type: 'standard',
      urn: '', // It will be something like`${fiwoo:etl:5fa3d550517cd304299a1b9e}`
      query: {},
      order: [
      ],
      pagination: {
        page: 1,
        size: 1,
      },
    };
  } else if (origin?.type === 'KPI') {
    const connectedDevice = origin.connectedDevices;
    const { readDevice } = connectedDevice;
    const defaultFiware = { service: 'service', servicepath: '/fiwoo' };
    const fiware = readDevice
      ? readDevice.fiware
      : defaultFiware;
    const entityType = readDevice?.entity_type ?? '';
    objectReturn = {
      id: connectedDevice.id,
      entity_type: entityType,
      attribute: undefined,
      fiware,
      aggregate,
      configuration,
      size,
    };
  }
  return objectReturn;
};

export const getTimeFromHistorical = (sampling, origin, offset) => {
  let xTime;
  if (sampling) {
    switch (sampling) {
      case 'year':
        xTime = `${Moment.utc(origin).local().format('MM-DD')}-${offset.toString()}`;
        break;
      case 'moth':
        xTime = `${offset.toString().padStart(2, '0')}-${Moment.utc(origin).local().format('DD-YYYY')}`;
        break;
      case 'lastThreeMonth':
      case 'lastYear':
        xTime = Moment.utc(origin).local().format('YYYY/MM/DD');
        xTime = xTime.slice(0, -2) + offset.toString().padStart(2, '0');
        break;

      case 'lastFifteenDays':
      case 'lastMonth':
        xTime = `${Moment.utc(origin).local().format('YYYY/MM/DD')} ${offset.toString().padStart(2, '0')}:00`;
        break;
      case 'lastDay':
      case 'lastHour':
        xTime = `${Moment.utc(origin).local().format('YYYY/MM/DD HH')}:${offset.toString().padStart(2, '0')}`;
        break;

      default:
        xTime = `${Moment.utc(origin).local().format('YYYY/MM/DD HH')}:${offset.toString().padStart(2, '0')}`;
        break;
    }
  }
  return xTime;
};

export const formatToLineWidget = (origins, configuration, linkedOperation, simpleWidget) => {
  const { operation } = configuration.data;
  const data = { series: [], legendX: 'Fecha', legendY: 'Valor' };
  const deviceNames = [];
  Moment.locale(getLanguageFromBrowser() || 'en');
  origins && origins.forEach((o) => {
    const connectedDevice = o.connectedDevices;
    if (!connectedDevice.historicaldata) return;
    const filter = operation
      ? connectedDevice.historicaldata.filter(
        (h) => configuration.data.attributeFilter.some((e) => e === h.attribute),
      )
      : connectedDevice.historicaldata;
    filter.forEach((historical) => {
      if (historical.data) {
        let name = connectedDevice.readDevice ? `${connectedDevice.readDevice.name}-${historical.attribute}` : '';
        const equalName = deviceNames.filter((deviceName) => deviceName.name === name);
        if (equalName.length > 0) {
          name = `${equalName[0].name}(${equalName[0].number})`;
          equalName[0].number += 1;
        } else {
          deviceNames.push({ name, number: 1 });
        }
        const rowData = {
          attribute: typeof historical.attribute === 'object' ? historical.attribute[0] : historical.attribute,
          device_id: linkedOperation && simpleWidget ? 'data' : connectedDevice.device_id,
          name,
          type: 'line',
          showSymbol: false,
          hoverAnimation: false,
          data: [],
        };
        historical.data.forEach((dataAttr) => {
          if (dataAttr.points) {
            dataAttr.points.forEach((point) => {
              const sampling = configuration.data.sampling ?? 'lastMonth';
              const xTime = getTimeFromHistorical(sampling, dataAttr.id.origin, point.offset);
              rowData.data.push({
                name,
                value: [xTime, Number.parseFloat(point.avg).toFixed(2)],
                offset: point.offset,
                samples: point.samples,
              });
            });
          }
        });
        rowData.data = sortBy(rowData.data, (o) => new Date(o.value[0]).getTime());

        if (o.connectedDevices.attributes.includes(rowData.attribute)) {
          data.series.push(rowData);
        }
      }
    });
  });

  const dataSorted = {
    ...data,
    series: data.series.map((serie) => {
      const newSerie = { ...serie };
      newSerie.data = sortBy(newSerie.data, (d) => new Date(d.value[0]).getTime());
      return newSerie;
    }),
  };
  return dataSorted;
};

export const formatToBarWidget = (origins, config, operation, linked) => {
  const details = { data: [], index: [] };
  if (config.data.type === 'historical') {
    const deviceNames = [];

    Moment.locale(getLanguageFromBrowser() || 'en');
    if (origins) {
      origins.forEach((o) => {
        const connectedDevice = o.connectedDevices;
        connectedDevice.historicaldata.forEach((historical) => {
          if (
            config.data.attributeFilter
            && !config.data.attributeFilter.includes(historical.attribute)
          ) {
            return;
          }
          if (historical.data) {
            let name = connectedDevice.readDevice ? `${connectedDevice.readDevice.device_id}-${historical.attribute}` : '';
            const equalName = deviceNames.filter((deviceName) => deviceName.device_id === name);
            if (equalName.length > 0) {
              name = `${equalName[0].device_id}(${equalName[0].number})`;
              equalName[0].number += 1;
            } else {
              deviceNames.push({ name, number: 1 });
            }

            historical.data.forEach((dataAttr) => {
              dataAttr.points.forEach((point) => {
                const sampling = config.data.sampling || 'lastMonth';
                const xTime = getTimeFromHistorical(sampling, dataAttr.id.origin, point.offset);
                const datafound = details.data.find((d) => (d.device_id === xTime));

                if (origins.length > 1) {
                  if (datafound) {
                    datafound[`${connectedDevice.readDevice.device_id}-${historical.attribute}`] = Number.parseFloat(point.avg).toFixed(2);
                  } else {
                    details.data.push({
                      device_id: xTime,
                      [`${connectedDevice.readDevice.device_id}-${historical.attribute}`]: Number.parseFloat(point.avg).toFixed(2),
                    });
                  }
                } else if (datafound) {
                  datafound[historical.attribute] = Number.parseFloat(point.avg).toFixed(2);
                } else {
                  details.data.push({
                    [historical.attribute]: Number.parseFloat(point.avg).toFixed(2),
                    device_id: xTime,
                  });
                }
              });
            });
          }
        });
      });
    }
  } else {
    const attrs = {};
    origins.forEach((o) => {
      const connectedDevice = o.connectedDevices;
      if (connectedDevice.readDevice) {
        const attributes = operation
          ? config.data.attributeFilter
          : getAllDeviceAttributes(connectedDevice);
        const mergeAllAttributes = operation ? merge(...connectedDevice.historicaldata) : [];
        const historicalData = operation ? mergeAllAttributes : connectedDevice.historicaldata[0];
        const rowData = {
          device_id: connectedDevice.device_id,
          id: connectedDevice.id,
        };
        if (linked || !operation) delete rowData.id;
        attributes.forEach((attr) => {
          if (!historicalData?.[attr]) return;
          if (attrs[attr]) {
            attrs[attr].entities.push({
              entityId: connectedDevice.device_id,
              value: historicalData[attr],
            });
          } else {
            attrs[attr] = {
              entities: [{
                entityId: connectedDevice.device_id,
                value: historicalData[attr],
              }],
            };
          }
          details.index.push(`${connectedDevice.readDevice.device_id}-${attr}`);
        });
      }
    });
    details.data = Object.keys(attrs).map((attr) => ({
      attrName: attr,
      name: config.labels.alias?.[attr] || attr,
      entities: attrs[attr].entities,
    }));
  }
  if (linked) {
    const attributes = config.data.attributeFilter;
    attributes.forEach((attr) => {
      details.data.forEach((d) => {
        if (!Object.keys(d).some((a) => a === attr)) {
          d[attr] = undefined;
        }
        const ordered = {};
        _(d).keys().sort().each((key) => {
          ordered[key] = d[key];
        });

        d = ordered;
      });
    });
  } else {
    details.data.forEach((d) => {
      const ordered = {};
      _(d).keys().sort().each((key) => {
        ordered[key] = d[key];
      });

      d = ordered;
    });
  }

  return details;
};

export const formatToParametrizedWidget = (origins) => {
  const details = { data: [], index: [] };

  details.data = origins.map((o) => {
    const connectedDevice = o.connectedDevices;
    if (connectedDevice.readDevice) {
      const attributes = getAllDeviceAttributes(connectedDevice);
      const historicalData = connectedDevice.historicaldata[0];
      const rowData = {
        device: connectedDevice.readDevice.name,
        device_id: connectedDevice.device_id,
      };

      attributes.forEach((attr) => {
        rowData[attr] = historicalData ? historicalData[attr] : [];
        details.index.push(`${attr}-${connectedDevice.readDevice.name}`);
      });
      return rowData;
    }
    return {};
  });

  return details;
};

export const formatToMapWidget = (origins, v2) => {
  const details = [];
  origins.forEach((o) => {
    const connectedDevice = o.connectedDevices;
    if (connectedDevice.readDevice) {
      const attributes = getAllDeviceAttributes(connectedDevice);
      const deviceAttributes = getAllDeviceAttributes(connectedDevice.readDevice);

      const points = deviceAttributes.filter(
        (deviceAttribute) => deviceAttribute.type.toLowerCase() === 'point'
          || deviceAttribute.type.toLowerCase() === 'geo:point',
      );

      const pointAttr = points.length > 0 ? points[0] : undefined;

      if (pointAttr && connectedDevice?.historicaldata?.[0]) {
        const stringLocation = connectedDevice.historicaldata[0][pointAttr.name];
        let x = '0';
        let y = '0';
        if (stringLocation.split(',').length >= 2) {
          [x, y] = stringLocation.split(',');
        }

        const data = {
          location: { x, y },
          locationName: pointAttr.name,
          device_id: connectedDevice.device_id,
          categories: connectedDevice.readDevice.categories[0] ? connectedDevice.readDevice.categories[0] : 'OTHER',
          device_name: connectedDevice.readDevice.name,
          id: connectedDevice.id,
          data: connectedDevice.historicaldata[0]
            ? connectedDevice.historicaldata[0]
            : {},
          attributes,
        };

        details.push(data);
      }
    }
  });
  return details;
};

export const formatToHeatMapWidget = (origins) => {
  const details = [];
  let min = 9999999999;
  let max = -9999999999;

  origins.forEach((o) => {
    const connectedDevice = o.connectedDevices;
    if (connectedDevice.readDevice) {
      const attributes = getAllDeviceAttributes(connectedDevice);
      const deviceAttributes = getAllDeviceAttributes(connectedDevice.readDevice);

      const points = deviceAttributes.filter(
        (deviceAttribute) => deviceAttribute.type.toLowerCase() === 'point'
          || deviceAttribute.type.toLowerCase() === 'geo:point',
      );
      const pointAttr = points.length > 0 ? points[0] : undefined;

      if (
        pointAttr
        && connectedDevice.historicaldata?.[0]
        && connectedDevice.lastState?.[0]
      ) {
        let stringLocation = connectedDevice.lastState[0][pointAttr.name];
        let x = '0';
        let y = '0';

        if (typeof stringLocation === 'string') {
          stringLocation = stringLocation.split(',');
        } else if (stringLocation && stringLocation.value) {
          stringLocation = stringLocation.value.split(',');
        }

        if (stringLocation.length >= 2) {
          [x, y] = stringLocation;
        }

        connectedDevice.historicaldata.forEach((aggregateData) => {
          const attr = aggregateData.attribute;
          const historicalData = connectedDevice.historicaldata.find(
            (dt) => (dt.attribute === attr),
          );

          if (historicalData) {
            const datahistoricals = connectedDevice.historicaldata[0]
              ? connectedDevice.historicaldata[0]
              : {};
            let avgPoint = 0;

            if (connectedDevice.realtime) {
              avgPoint = Number(connectedDevice.lastState[0][attr]);
            } else if (datahistoricals.data[0]) {
              datahistoricals.data[0].points.forEach((point) => {
                avgPoint += point.avg;
              });
              avgPoint /= datahistoricals.data[0].points.length;
            }

            const data = {
              location: { x, y },
              device_id: connectedDevice.device_id,
              device_name: connectedDevice.readDevice.name,
              attribute: attr,
              id: connectedDevice.id,
              data: datahistoricals,
              attributes,
              heatmap: {
                value: avgPoint,
              },
            };

            const value = avgPoint;
            if (value > max) {
              max = value;
            }
            if (value < min) {
              min = value;
            }

            details.push(data);
          }
        });
      }
    }
  });
  return { data: details, maxValue: max, minValue: min };
};

export const formatToTableWidget = (origins, configuration, isLinked) => {
  const details = [];
  if ((configuration.data.operation === 'last-value' && configuration.data.type === 'real-time') || isLinked) {
    origins.forEach((o) => {
      const connectedDevice = o.connectedDevices;
      if (connectedDevice.readDevice) {
        const attributes = isLinked
          ? configuration.data.attributeFilter
          : getAllDeviceAttributes(connectedDevice);

        const linkedData = [];
        if (isLinked) {
          connectedDevice.historicaldata.forEach((historical) => {
            const obj = {};
            Object.keys(historical).forEach((h) => {
              obj[h] = historical[h];
            });
            linkedData.push(obj);
          });
          if (linkedData.length) {
            linkedData.forEach((e) => details.push({
              data: e,
              device: {
                id: connectedDevice.id,
                device_id: connectedDevice.device_id,
                device_name: connectedDevice.readDevice.name,
                hasCommandAttributes: connectedDevice.readDevice.command_attributes.length > 0,
                attributes,
              },
            }));
          }
        } else {
          let data = isLinked ? linkedData : (connectedDevice.historicaldata?.[0] || {});
          data.device_name = connectedDevice.readDevice.name;
          data.device_id = connectedDevice.device_id;
          if (Object.keys(data).length < 3) data = null;

          details.push({
            device: {
              id: connectedDevice.id,
              device_id: connectedDevice.device_id,
              device_name: connectedDevice.readDevice.name,
              hasCommandAttributes: connectedDevice.readDevice.command_attributes.length > 0,
              attributes,
            },
            data,
          });
        }
      }
    });
  } else {
    const operation = configuration.data.operation || 'avg';
    origins.forEach((o) => {
      const connectedDevice = o.connectedDevices;
      if (connectedDevice.readDevice) {
        const attributes = getAllDeviceAttributes(connectedDevice);
        connectedDevice.historicaldata.forEach((historical) => {
          if (!historical.data) return;
          historical.data.forEach((dataAttr) => {
            dataAttr.points.forEach((point) => {
              const sampling = configuration.data.sampling || 'lastMonth';
              const xTime = getTimeFromHistorical(sampling, dataAttr.id.origin, point.offset);
              const datafound = details.find(
                (d) => d.device.time === xTime && d.device.id === connectedDevice.readDevice.id,
              );

              if (datafound) {
                datafound.data[dataAttr.id.attrName] = Number.parseFloat(
                  point[operation],
                ).toFixed(2);
              } else {
                details.push({
                  device: {
                    id: connectedDevice.id,
                    device_id: connectedDevice.device_id,
                    device_name: connectedDevice.readDevice.name,
                    hasCommandAttributes: connectedDevice.readDevice.command_attributes.length > 0,
                    attributes,
                    time: xTime,
                  },
                  data: {
                    [dataAttr.id.attrName]: Number.parseFloat(point[operation]).toFixed(2),
                    recvTime: xTime,
                  },
                });
              }
            });
          });
        });
      }
    });
  }
  return details;
};

export const formatToCommonWidgetSingleDevice = (origin, index = 0) => {
  const connectedDevice = origin?.connectedDevices;
  const attributes = getAllDeviceAttributes(connectedDevice);
  let historicalData = connectedDevice ? connectedDevice.historicaldata?.[0] : [];
  historicalData = historicalData ? historicalData[index || attributes[index]] : 0;
  return historicalData;
};

export const formatToCommonWidgetSingleDeviceV2 = (origins, attribute) => (
  origins.map((origin) => ({
    id: origin.connectedDevices.id,
    data: origin.connectedDevices.historicaldata.filter(
      (d) => d[attribute] || d.attribute === attribute,
    ),
  }))
);

export const getDataFromUrn = (urn, data = 'id') => {
  const urnArray = urn.split(':');
  return urnArray[2];
};

export const calculate = (values, operation, decimals = 2) => {
  const cleanValues = [...values];
  pullAll(cleanValues, [null, NaN, undefined]);
  if (cleanValues.length) {
    switch (operation) {
      case 'min':
        return round(min(cleanValues), decimals);
      case 'max':
        return round(max(cleanValues), decimals);
      case 'sum':
        return round(sum(cleanValues), decimals);
      case 'avg':
        return round(mean(cleanValues), decimals);
      default:
        return null;
    }
  }
};

export const getValuesOfAttribute = (data, attributeToExtract) => {
  const values = [];
  data.forEach((device) => {
    device.data.forEach((d) => d[attributeToExtract] && values.push(Number(d[attributeToExtract])));
  });
  return values;
};

export const calculateSimpleLastValue = (values, attribute, operation, selection, precision) => {
  const data = values.filter((v) => {
    const isTable = !!v.data && !!v.device;
    return selection.includes(isTable ? v.device.id : v.id);
  });
  const allValues = getValuesOfAttribute(data, attribute);
  return calculate(allValues, operation, precision);
};

const getCalculatedAttributes = (
  attrName,
  resolution,
  endDate,
  startDate,
  historical,
  operationWidget,
) => {
  // * We iterate through historical looking for a origin that fits inside our range.
  const globalObj = {};
  historical.forEach((registry) => {
    const obj = {};
    const originDate = new Date(registry.origin);
    const startDateParsed = new Date(startDate);
    const endDateParsed = new Date(endDate);
    if (!registry.entities) return;
    // * We identify the records whose attrName and resolution matches the ones in our widget.
    registry.entities.forEach((entity) => {
      if (entity.attrName === attrName && entity.resolution === resolution) {
        entity.points.forEach((point) => {
          const newOriginWithOffset = new Date(Moment(originDate).add(point.offset, 'minutes'));
          if (newOriginWithOffset >= startDateParsed && newOriginWithOffset <= endDateParsed) {
            Object.keys(point).forEach((property) => {
              if (property !== 'samples' && property !== 'offset') {
                if (!obj[entity.attrName]) obj[entity.attrName] = {};
                if (!obj[entity.attrName][point.offset]) obj[entity.attrName][point.offset] = {};
                if (!obj[entity.attrName][point.offset][property]) {
                  obj[entity.attrName][point.offset][property] = [point[property]];
                } else {
                  obj[entity.attrName][point.offset][property].push(point[property]);
                }
              }
            });
          }
        });
      }
    });

    // let's calculate
    Object.keys(obj).forEach((attribute) => {
      Object.keys(obj[attribute]).forEach((offset) => {
        Object.keys(obj[attribute][offset]).forEach((operation) => {
          const selectedOperation = operationWidget || operation;
          obj[attribute][offset][operation] = calculate(
            obj[attribute][offset][operation], selectedOperation,
          );
        });
      });
    });
    if (Object.entries(obj).length) {
      globalObj[registry.origin] = obj;
    }
  });
  return globalObj;
};

export const calculateAggregateValues = (widget, historical, selection, operation) => {
  const originsSelected = widget.origins.filter((o) => selection.includes(o.connectedDevices.id));
  const origin = originsSelected.filter(
    (o) => o.connectedDevices?.historicaldata?.find(
      (h) => widget.config.data.attributeFilter.some(
        (a) => a === h.attribute,
      ) && h.data?.length,
    ),
  );
  const checkHistorical = origin?.[0]?.connectedDevices?.historicaldata.find(
    (h) => widget.config.data.attributeFilter.some((a) => a === h.attribute),
  );

  const historicalData = widget.widgetType === 'TEXT_ACCOUNTANT'
    ? checkHistorical?.data?.[0]
    : originsSelected.map((o) => (
      o.connectedDevices.historicaldata.filter(
        (d) => widget.config.data.attributeFilter.includes(d.attribute),
      ))).flat();

  if (historicalData && widget.widgetType === 'TEXT_ACCOUNTANT') {
    const { sampling } = widget.config.data;
    const { attrName, resolution } = historicalData.id;
    const endDate = new Date().getTime();
    const startDate = new Date(getDateOfSampling(sampling)).getTime();

    return getCalculatedAttributes(
      attrName,
      resolution,
      endDate,
      startDate,
      historical[widget.container],
      operation,
    );
  }

  if (historicalData) {
    const objectArray = [];
    historicalData.forEach((attr) => {
      attr.data.forEach((h) => {
        const {
          sampling,
          type,
          endDate: endDateConfig,
          startDate: startDateConfig,
        } = widget.config.data;
        const isHistorical = type === 'historical';
        const { attrName, resolution } = h.id;
        const endDate = isHistorical && endDateConfig
          ? new Date(endDateConfig)
          : new Date().getTime();
        const samplingDate = getDateOfSampling(sampling);
        const startDate = isHistorical && startDateConfig
          ? new Date(startDateConfig)
          : new Date(samplingDate).getTime();
        const calculatedValues = getCalculatedAttributes(
          attrName, resolution, endDate, startDate, historical[widget.container], operation,
        );
        objectArray.push(
          calculatedValues,
        );
      });
    });
    return objectArray;
  }
  return null;
};

export const mergeAggregateOrigins = (widget, globalObj, selection) => {
  const originsSelected = widget.origins.filter((o) => selection.includes(o.connectedDevices.id));
  const originSingleSelected = originsSelected.filter(
    (o) => o.connectedDevices?.historicaldata.find(
      (h) => widget.config.data.attributeFilter.some((a) => a === h.attribute) && h.data?.length,
    ),
  );
  const newWidgetOrigins = originSingleSelected[0] ? [cloneDeep(originSingleSelected[0])] : [];
  if (widget.widgetType === 'TEXT_ACCOUNTANT') {
    const oldHistorical = newWidgetOrigins[0].connectedDevices.historicaldata.find(
      (h) => h.attribute === widget.config.data.attributeFilter[0],
    );
    const attribute = widget.config.data.attributeFilter[0];
    const indexOfData = newWidgetOrigins[0].connectedDevices.historicaldata.findIndex(
      (h) => h.attribute === attribute,
    );
    newWidgetOrigins[0].connectedDevices.historicaldata[indexOfData].data = Object.keys(globalObj)
      .map((origin) => {
        const id = {
          ...oldHistorical.data[0].id,
          origin,
        };
        const points = Object.keys(globalObj[origin][attribute]).map((originDate) => ({
          offset: parseInt(originDate, 10),
          ...globalObj[origin][attribute][originDate],
        }));

        return { id, points };
      });
    return newWidgetOrigins;
  }
  const newGlobal = merge({}, ...globalObj);
  const attributes = [];
  originsSelected
    .forEach((o) => o.connectedDevices.attributes
      .forEach((a) => !attributes.includes(a) && attributes.push(a)));
  if (!newWidgetOrigins.length) return;
  newWidgetOrigins[0].connectedDevices.attributes = attributes;
  newWidgetOrigins[0].connectedDevices.historicaldata = widget.config.data.attributeFilter
    .map((attribute) => {
      const data = Object.keys(newGlobal).map((origin) => {
        if (!newGlobal[origin][attribute]) return { id: {}, points: [] };
        const id = {
          ...originsSelected[0].connectedDevices.historicaldata[0].data[0]?.id,
          origin,
        };

        const points = Object.keys(newGlobal[origin][attribute]).map((offset) => ({
          offset: Number(offset),
          ...newGlobal[origin][attribute][offset],
        }));

        return { id, points };
      });

      return { attribute, data };
    });
  return newWidgetOrigins;
};
