import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'key',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'property',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
      format: {
        pattern: /^([a-z][a-z0-9_]*?)$/,
        flags: 'g',
        message: <FormattedMessage id="Validation.regex.attributeNames" />,
      },
    },
  },

  {
    key: 'type',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'size',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'unit',
    constraints: {
      presence: false,
    },
  },
];

export default ModelExpected;
