import Styled from 'styled-components';

const Tag = Styled('div')`
    padding: 6px;
    margin: 5px;
    float: left;
    background: #4a74fd;
    border-radius: 5px;
    font-size: 0.8125rem;
    font-weight: 400;    
    color: rgba(255, 255, 255, 0.87);
`;

export default Tag;