import Command from '../models/Attribute/Command';
import Static from '../models/Attribute/Static';
import Lazy from '../models/Attribute/Lazy';
import Attribute from '../models/Attribute/Attribute';
import Internal from '../models/Attribute/Internal';
import GeneralAttribute from '../models/Attribute';
import { loraProtocolValidator } from '../pages/helpers/Add/utils/validators';
import { loraParser } from '../pages/helpers/Add/utils/parsers';
import { LoraExtraConfigurations } from '../pages/helpers/Add/utils/extraConfigurations';
import { loraTranslator, sigfoxTranslator } from '../pages/helpers/Add/utils/translators';
import { uintSigfoxData, floatSigfoxData, offsetSigfoxData, charSigfoxData, boolSigfoxData } from './subtypes';

const commandProperties = ['property', 'key', 'name', 'type', 'value'];
const lazyProperties = ['property', 'key', 'name', 'type', 'unit'];
const staticProperties = ['property', 'name', 'type', 'value'];
const attributeProperties = ['property', 'key', 'name', 'type', 'unit'];
const loraProperties = ['property', 'key', 'name', 'type', 'unit'];
const sigfoxProperties = ['property', 'key', 'name', 'type', 'parameter', 'endian', 'offset', 'unit'];

const null_ = {value: 'null', name: 'Null'};
const number = {value: 'number', name: 'Number'};
const string = {value: 'string', name: 'String'};
const object = {value: 'object', name: 'Object'};
const array = {value: 'array', name: 'Array'};
const boolean = {value: 'boolean', name: 'Boolean'};
const command = {value: 'command', name: 'Command'};
const point = {value: 'geo:point', name: 'Point'};
const lineString = {value: 'lineString', name: 'LineString'};
const polygon = {value: 'polygon', name: 'Polygon'};

const bigEndian = {value: 'big-endian', name: 'Big Endian'};
const littleEndian = {value: 'little-endian', name: 'Little Endian'};
const uint = {value: 'uint', name: 'uint', subtype: [{value: 'parameter', data: uintSigfoxData}, {value: 'endian', data: [littleEndian, bigEndian]}, {value: 'offset', data: offsetSigfoxData}]}
const float = {value: 'float', name: 'float', subtype: [{value: 'parameter', data: floatSigfoxData}, {value: 'endian', data: [littleEndian, bigEndian]}, {value: 'offset', data: offsetSigfoxData}]}
const int = {value: 'int', name: 'int', subtype: [{value: 'parameter', data: uintSigfoxData}, {value: 'endian', data: [littleEndian, bigEndian]}, {value: 'offset', data: offsetSigfoxData}]}
const char = {value: 'char', name: 'char', subtype: [{value: 'parameter', data: charSigfoxData}]}



const fiwareTypes = [null_, number, string, object, array, boolean, point, lineString, polygon];
const sigfoxTypes = [uint/* , {value: 'bool', name: 'Boolean', subtype: [{value: 'parameter', data: boolSigfoxData}]}, float, char, int */]


export const protocolData = {
  protocol: [
    {
      name: 'IOTA-UL',
      value: 'IOTA_UL',
      transports: [
        { name: 'HTTP', value: 'HTTP' },
        { name: 'MQTT', value: 'MQTT' },
        { name: 'AMQP', value: 'AMQP' }
      ],
      attributes: [
        {
          name: 'Command',
          value: 'command',
          key: 'command_attributes',
          properties: commandProperties,
          class: Command,
          type: [command]
        },
        {
          name: 'Attribute',
          value: 'attribute',
          key: 'attributes',
          properties: attributeProperties,
          class: Attribute,
          type: fiwareTypes
        },
        {
          name: 'Static',
          value: 'static',
          key: 'static_attributes',
          properties: staticProperties,
          class: Static,
          type: fiwareTypes
        }
      ]
    },
    {
      name: 'IOTA-JSON',
      value: 'IOTA_JSON',
      transports: [
        { name: 'HTTP', value: 'HTTP' },
        { name: 'MQTT', value: 'MQTT' },
        { name: 'AMQP', value: 'AMQP' }
      ],
      attributes: [
        {
          name: 'Command',
          value: 'command',
          key: 'command_attributes',
          properties: commandProperties,
          class: Command,
          type: [command]
        },
        {
          name: 'Attribute',
          value: 'attribute',
          key: 'attributes',
          properties: attributeProperties,
          class: Attribute,
          type: fiwareTypes
        },
        {
          name: 'Static',
          value: 'static',
          key: 'static_attributes',
          properties: staticProperties,
          class: Static,
          type: fiwareTypes
        }
      ]
    },
    {
      name: 'SIGFOX',
      value: 'SIGFOX',
      attributes: [
        {
          name: 'Attribute',
          value: 'attribute',
          key: 'attributes',
          properties: sigfoxProperties,
          class: Internal,
          type: sigfoxTypes, //[uint]
          subtypes:['parameter','endian','offset']
        },
        {
          name: 'Static',
          value: 'static',
          key: 'static_attributes',
          properties: staticProperties,
          class: Internal,
          type: fiwareTypes,
        }
      ],
      translator: sigfoxTranslator,
    },
    {
      name: 'LORA',
      value: 'LORA',
      attributes: [
        {
          name: 'Attribute',
          value: 'attribute',
          key: 'attributes',
          properties: loraProperties,
          class: GeneralAttribute,
          type: fiwareTypes
        }
      ],
      extraConfigurations: [
        {
          value: 'data_model',
          type: 'select',
          showInWizard: [
            'Device',
            'Service',
            'Template',
          ],
          options: [
            { name: 'CayenneLPP', value: 'cayennelpp' },
            { name: 'CBOR', value: 'cbor' },
            { name: 'Application Server', value: 'application_server' },
          ],
        },
        {
          value: 'username',
          type: 'text',
          showInWizard: [
            'Device',
            'Service',
          ],
        },
        {
          value: 'password',
          type: 'password',
          showInWizard: [
            'Device',
            'Service',
          ],
        },
        {
          value: 'dev_eui',
          type: 'text',
          showInWizard: [
            'Device',
          ],
        },
        {
          value: 'app_eui',
          type: 'text',
          showInWizard: [
            'Device',
            'Service',
          ],
        },
        {
          value: 'application_id',
          type: 'text',
          showInWizard: [
            'Device',
            'Service',
          ],
        },
        {
          value: 'application_key',
          type: 'text',
          showInWizard: [
            'Device',
            'Service',
          ],
        },
      ],
      validator: loraProtocolValidator,
      parser: loraParser,
      extraConfigurationsComponent: LoraExtraConfigurations,
      translator: loraTranslator
    }
  ]
};
