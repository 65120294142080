/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Container, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import StaticListComponent from '../components/staticListComponent';
import CommonView from '../../../CommonView';
import SelectableList from '../../../../components/SelectableList';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import { getKpis, clearKpisState } from '../../../../services/redux/kpis/actions';
import { getDevices, clearDevicesState } from '../../../../services/redux/devices/actions';
import { getETLProcedures, clearETLProcedures } from '../../../../services/redux/etlProcedures/actions';

const filterBy = (arr, type) => arr.filter((item) => item.type === type);

const getFunc = (type) => {
  switch (type) {
    case 'kpis':
      return getKpis;
    case 'etls':
      return getETLProcedures;
    case 'devices':
    default:
      return getDevices;
  }
};

const ENTITY_TYPES = {
  ETL: 'ETLProcedure',
  KPI: 'Kpi',
  DEVICE: 'Device',
};

export const FILTER_ONLY_DEVICES = [
  'ONOFF',
  'SLIDER',
  'LINKED',
];

class SelectDatasources extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      errors: {},
      filter: 'devices',
      devices: this.props.newEntity.sources?.length
        ? filterBy(this.props.newEntity.sources, ENTITY_TYPES.DEVICE)
        : [],
      etls: this.props.newEntity.sources?.length
        ? filterBy(this.props.newEntity.sources, ENTITY_TYPES.ETL)
        : [],
      kpis: this.props.newEntity.sources?.length
        ? filterBy(this.props.newEntity.sources, ENTITY_TYPES.KPI)
        : [],
      page: 1,
      size: 6,
      openedPanels: this.props.newEntity.sources
        ? this.initOpenedStatus(this.props.newEntity.sources)
        : [],
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    this.props.onRef(this);
    getDevices({ filters: [], page, size });
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size, filter } = this.state;
    const {
      fetchingDevices,
      fetchingEtls,
      fetchingKpis,
    } = this.props;

    if (
      (!fetchingDevices && !fetchingEtls && !fetchingKpis && prevState.page < page)
      || (prevState.filter !== filter)
    ) {
      if (filter === 'devices') getDevices({ filters: [], page, size });
      if (filter === 'etls') getETLProcedures({ filters: [], page, size });
      if (filter === 'kpis') getKpis({ filters: [], page, size });
    }
  }

  initOpenedStatus = (sources) => sources.map(() => false);

  validate = () => {
    const { devices, kpis, etls } = this.state;
    if (devices.length || kpis.length || etls.length) {
      this.props.onContinue({
        sources: [...this.state.devices, ...this.state.kpis, ...this.state.etls],
      });
    } else {
      this.props.onError(
        <FormattedMessage id="widgets.wizard.no.datasource.selected" />,
      );
    }
  };

  addJoinedAttrs = (sources) => sources.map((source) => (!source.joinedAttributes
    ? {
      ...source,
      joinedAttributes: source.attributes.map((field) => ({
        ...field,
        selected: source.attributes.length === 1,
      })),
    }
    : source));

  handleSelectableListChanges = (data) => {
    const etlsWithoutJoinedAttrs = data.filter(
      (etl) => etl.type === ENTITY_TYPES.ETL,
    );

    const etls = this.addJoinedAttrs(etlsWithoutJoinedAttrs);

    const devicesWithoutJoinedAttrs = data.filter(
      (device) => device.type === ENTITY_TYPES.DEVICE,
    );
    const devices = this.addJoinedAttrs(devicesWithoutJoinedAttrs);

    const kpisWithoutJoinedAttrs = data.filter(
      (kpi) => kpi.type === ENTITY_TYPES.KPI,
    );

    const kpis = this.addJoinedAttrs(kpisWithoutJoinedAttrs);

    this.setState({ kpis, devices, etls });
  };

  handleOnPanelOpen = (index, status) => {
    const openedPanels = [...this.state.openedPanels];
    openedPanels[index] = status;
    this.setState({
      openedPanels,
    });
  };

  handleFetchInfo = () => {
    const { total, devices } = this.props;
    if (total > devices.length) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { size, filter } = this.state;
    filter === 'devices' && init && clearDevicesState();
    filter === 'etls' && init && clearETLProcedures();
    filter === 'kpi' && init && clearKpisState();
    const getAction = getFunc(filter);
    getAction({ filters: { name: value }, page: 1, size });
  };

  whichtFilterisLoading = () => {
    const { fetchingDevices, fetchingEtls, fetchingKpis } = this.props;
    const { filter } = this.state;

    if (filter === 'devices') return fetchingDevices;
    if (filter === 'etls') return fetchingEtls;
    if (filter === 'kpis') return fetchingKpis;
    return fetchingDevices;
  }

  getOptionsWithFilters = () => {
    const { newEntity: { widgetType }, devices } = this.props;
    const { filter } = this.state;
    if (FILTER_ONLY_DEVICES.includes(widgetType)) {
      const dataList = [];
      devices && devices.forEach((device) => {
        if (device.commands?.length) {
          dataList.push(device);
        }
      });
      return dataList && dataList.map((o) => ({ name: o.name, id: o.id, value: o }));
    }
    return this.props[filter] && this.props[filter]
      .map((o) => ({ name: o.name, id: o.id, value: o }));
  }

  handleChangeFilter = (e) => {
    const { target: { value: filter } } = e;
    this.setState({ filter, page: 1 });
  }

  getFilters = () => {
    const { newEntity: { widgetType } } = this.props;
    if (FILTER_ONLY_DEVICES.includes(widgetType)) {
      return [
        {
          name: 'devices',
          translation: 'devices.list.title',
        },
      ];
    }
    return [
      {
        name: 'devices',
        translation: 'devices.list.title',
      },
      {
        name: 'etls',
        translation: 'etl.list.title',
      },
      {
        name: 'kpis',
        translation: 'kpis.list.title',
      },
    ];
  }

  resetSelections = () => {
    this.setState({
      devices: [],
      kpis: [],
      etls: [],
    });
  }

  render() {
    const {
      errors,
      openedPanels,
      filter,
      devices,
      etls,
      kpis,
    } = this.state;

    const { hasMultipleSources } = this.props.newEntity;

    return (
      <>
        <Container fluid>
          <Row>
            <Col lg="12">
              <SelectableList
                data={this.getOptionsWithFilters()}
                selected={[...devices, ...etls, ...kpis]}
                name="datasources"
                translatePrefix="widgets.wizard"
                error={errors}
                listComponent={(props) => StaticListComponent(
                  { ...props, trendline: null },
                  this.handleOnPanelOpen,
                  this.resetSelections,
                )}
                onChange={this.handleSelectableListChanges}
                fetchInfo={this.handleFetchInfo}
                loading={this.whichtFilterisLoading()}
                openedPanels={openedPanels}
                onSearch={this.handleOnSearch}
                multiOption={hasMultipleSources}
                filterBy="name"
                selectOnTheFly
                filter={filter}
                selectableFilter={{
                  handler: this.handleChangeFilter,
                  filters: this.getFilters(),
                  checked: filter,
                }}
                smallInput
              />
              {hasMultipleSources
              && (
              <div className="warning">
                <i className="uil uil-info-circle" />
                <FormattedMessage id="widget.wizard.selectDatasources" />
              </div>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: Object.values(state
    .get('devices')
    .get('listSources')),
  etls: Object.values(state
    .get('etlProcedures')
    .get('listSources')),
  kpis: Object.values(state
    .get('kpis')
    .get('listSources')),
  fetchingDevices: state.get('devices').get('fetching'),
  fetchingEtls: state.get('etlProcedures').get('fetching'),
  fetchingKpis: state.get('kpis').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(SelectDatasources)));
