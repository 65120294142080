import React, { useState, useEffect, useRef } from 'react';
import SelectableList from '../../../../../../components/SelectableList';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { readDevices } from '../../../../../../services/api/devices';
import ListComponent from '../../../../../Widgets/Add/components/ListComponent';

const SELECT_SIZE = 6;

const SelectSourceOfData = ({ handleChange, onError, selected }) => {
  const [query, setQuery] = useState({ total: 0, page: 1 });
  const [devices, setDevices] = useState(selected?.devices ?? []);
  const [devicesOptions, setDevicesOptions] = useState([]);
  const [selectorData, setSelectorData] = useState(selected?.selectorData ?? []);
  const [errors, setErrors] = useState(null);

  const [openedPanels, setOpenedPanels] = useState([]);
  const firstRenderDone = useRef(false);
  const getOptions = () => (
    devicesOptions.map((o) => ({ name: o.name, id: o.id, value: o }))
  );

  const updateObjects = () => {
    const localDevices = selectorData.map((o) => ({
      id: o.id,
      device_id: o.device_id,
      attributes: o.joinedAttributes.reduce((acc, attr) => (
        attr.selected === true
          ? (acc.push({ name: attr.name, type: attr.type }), acc)
          : acc),
      []),
    })).filter((o) => o.attributes.length !== 0);

    setDevices(localDevices);
  };

  const handleAttributeStatusChange = (status, attribute, device) => {
    const newSelector = selectorData.map((selector) => {
      if (selector.id === device.id) {
        selector.joinedAttributes.find((a) => attribute.name === a.name).selected = status;
      }
      return selector;
    });

    setSelectorData(newSelector);
    updateObjects();
  };

  const handleSelectAllChange = (status, device) => {
    const oldSelector = selectorData.map((selector) => {
      if (selector.id === device.id) {
        selector.joinedAttributes = selector.joinedAttributes.map(
          (attr) => ({ ...attr, selected: status }),
        );
      }

      return selector;
    });
    setSelectorData(oldSelector);
    updateObjects();
  };

  const handleOnPanelOpen = (index, status) => {
    const panels = openedPanels;
    panels[index] = status;
    setOpenedPanels(panels);
  };

  const joinedAttributes = (device) => {
    if (device && Object.entries(device).length > 0) {
      const { lazy_attributes, static_attributes, attributes } = device;
      const attributesList = {
        lazy: lazy_attributes,
        static: static_attributes,
        attribute: attributes,
      };

      const attributesArray = Array.prototype.concat(
        ...Object.keys(attributesList).map(
          (attrIndex) => attributesList[attrIndex].map((attribute) => ({
            ...attribute,
            attrType: attrIndex,
          })),
        ),
      );
      const attributesToRemove = [];
      attributesArray.forEach((a) => {
        if (a.type !== 'float' && a.type !== 'int' && a.type !== 'integer'
        && a.type !== 'number' && a.type !== 'number') {
          attributesToRemove.push(a);
        }
      });
      attributesToRemove.forEach((a) => {
        attributesArray.splice(attributesArray.indexOf(a), 1);
      });
      return attributesArray;
    }
    return [];
  };

  const handleSelectableListChanges = (data) => {
    const newData = data.map((d) => (!d.joinedAttributes
      ? {
        ...d,
        joinedAttributes: joinedAttributes(d).map((a) => ({
          ...a,
          selected: false,
        })),
      }
      : d));
    setSelectorData(newData);
  };
  const handleFetchInfo = () => {
    const { total } = query;
    if (total > devicesOptions.length) {
      setQuery((old) => ({ ...old, page: old.page + 1 }));
    }
  };

  const updateApiOptions = ({ total, objects }, clear) => {
    const currentOptions = !clear
      ? devicesOptions
      : [];
    setDevicesOptions([
      ...currentOptions,
      ...objects.filter((o) => (
        !currentOptions.find((current) => current.id === o.id)
      ))]);
    setQuery((old) => ({ ...old, total }));
  };

  const handleGetDeviceOptions = (page, clear = false, filters = []) => {
    readDevices(page, SELECT_SIZE, filters, false)
      .then((response) => { updateApiOptions(response.data, clear); })
      .catch((error) => {
        onError(
          <FormattedMessage id={`Api.error.response.status.${error.status}`} />,
        );
      });
  };

  const handleOnSearch = (value, init = false) => {
    handleGetDeviceOptions(1, init, { name: value });
  };

  useEffect(() => {
    handleGetDeviceOptions(query.page, true);
    firstRenderDone.current = true;
  }, []);

  useEffect(() => {
    firstRenderDone.current && handleGetDeviceOptions(query.page);
  }, [query.page]);

  useEffect(() => {
    handleChange(devices, selectorData);
  }, [devices, selectorData]);

  useEffect(() => {
    if (selected) {
      setSelectorData(selected.selectorData);
      setDevices(selected.devices);
    }
  }, [selected]);

  return (
    <div>
      <SelectableList
        data={getOptions()}
        selected={selectorData}
        name="devices"
        translatePrefix="widgets.wizard"
        error={errors}
        listComponent={(props) => ListComponent(
          props,
          handleAttributeStatusChange,
          handleSelectAllChange,
          handleOnPanelOpen,
        )}
        onChange={handleSelectableListChanges}
        fetchInfo={handleFetchInfo}
        openedPanels={openedPanels}
        onSearch={handleOnSearch}
        multiOption
        filterBy="name"
        selectOnTheFly
      />
    </div>
  );
};

export default SelectSourceOfData;
