import * as TYPES from './types';
import { store } from '../store';

export const addWidget = (Widget) => store.dispatch({
  type: TYPES.ADD_WIDGET_REQUEST,
  payload: Widget,
});

export const addWidgetSuccessFlag = () => store.dispatch({
  type: TYPES.ADD_WIDGET_SUCCESS_FLAG,
});

export const updateWidget = (Widget) => store.dispatch({
  type: TYPES.MODIFY_WIDGET_REQUEST,
  payload: Widget,
});

export const modifyWidgetSuccessFlag = () => store.dispatch({
  type: TYPES.MODIFY_WIDGET_SUCCESS_FLAG,
});

export const deleteWidget = (Widget) => store.dispatch({
  type: TYPES.DELETE_WIDGET_REQUEST,
  payload: Widget,
});

export const getWidgets = () => store.dispatch({
  type: TYPES.GET_WIDGETS_REQUEST,
});

export const getPublicWidget = (...filters) => store.dispatch({
  type: TYPES.GET_PUBLIC_WIDGETS_REQUEST,
  payload: filters,
});

export const getMineWidget = (...filters) => store.dispatch({
  type: TYPES.GET_MINE_WIDGETS_REQUEST,
  payload: filters,
});

export const getWidget = (Widget) => store.dispatch({
  type: TYPES.GET_WIDGET_REQUEST,
  payload: Widget,
});

export const cloneWidget = (Widget) => store.dispatch({
  type: TYPES.CLONE_WIDGET_REQUEST,
  payload: Widget,
});

export const errorFetchingWidgetReset = () => store.dispatch({
  type: TYPES.ERRORFETCHING_WIDGET_RESET,
});
