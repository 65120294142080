import validate, { validate as validateJsLib } from 'validate.js';
import { clone } from 'ramda';
import { isEqual, sortBy, pullAll } from 'lodash';
// eslint-disable-next-line import/no-cycle
import {
  addWidget, deleteWidget, getWidget, updateWidget,
} from '../../services/redux/widgets/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';
import TableDefaultConfiguration from './defaultConfiguration/Table';

import getConfigSheet, { getValidation, getInjectedData } from './configurationSheets';
import { getDateFromSampling, getResolution } from '../../helpers/samplingHistorical';
import {
  calculate,
  buildQuery,
  calculateAggregateValues,
  calculateSimpleLastValue,
  formatToBarWidget,
  formatToCommonWidgetSingleDevice,
  formatToCommonWidgetSingleDeviceV2,
  formatToHeatMapWidget,
  formatToLineWidget,
  formatToMapWidget,
  formatToParametrizedWidget,
  formatToTableWidget,
  getAllDeviceAttributes,
  mergeAggregateOrigins,
} from './utils';

/** Class Widget with Utils functions */
export default class Widget extends Utils {
  static entityName = 'Widget';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    const objCopy = { ...obj };
    const aggregateTypes = ['HEATMAP', 'LINES'];
    if (objCopy.widgetType === 'BARS' && objCopy.config.data?.type === 'historical') {
      aggregateTypes.push('BARS');
    }
    if (!objCopy.container && objCopy.widgetType === 'TABLE' && (objCopy.config.data?.operation !== 'last-value' || objCopy.config.data?.type !== 'real-time')) {
      aggregateTypes.push('TABLE');
    }
    if (objCopy.widgetType) objCopy.widgetAggregate = aggregateTypes.includes(objCopy.widgetType);
    super(ModelExpected, objCopy);
  }

  injectData = (widget, linked) => getInjectedData(widget, linked)

  cleanOrigins = (origins) => origins.map((origin) => ({
    categories: origin.categories,
    type: origin.type,
    connectedDevices: {
      attributes: origin.connectedDevices.attributes,
      command_attributes: origin.connectedDevices.command_attributes,
      device_id: origin.connectedDevices.device_id,
      id: origin.connectedDevices.id,
      lazy_attributes: origin.connectedDevices.lazy_attributes,
      static_attributes: origin.connectedDevices.static_attributes,
    },
  }));

  /** Call Redux action for Save Widget in DB and Redux */
  save(updateDashboardCallback, widgetLinked) {
    const widget = this.getData();
    /*
      This method inject old "validate" injection. If Widget need
      to inject extra config with datasources, here it can be done.
    */
    const injectedData = this.injectData(widget, widgetLinked);
    if (injectedData) widget.config = injectedData;
    /** */
    const filteredConstraints = clone(this.constraints);
    delete filteredConstraints.id;
    widget.origins = this.cleanOrigins(widget.origins);
    const validation = validate(widget, filteredConstraints);
    return validation === undefined ? addWidget({
      ...widget, updateDashboardCallback,
    }) : { error: true, ...validation };
  }

  saveWithoutCallback() {
    const filteredConstraints = clone(this.constraints);
    delete filteredConstraints.id;
    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined ? addWidget({ ...this.getData() }) : {
      error: true, ...validation,
    };
  }

  /** Call Redux action for Update Widget in DB and Redux */
  update() {
    const validation = validate(this.getData(), this.constraints);
    return validation === undefined ? updateWidget({ ...this.getData() }) : {
      error: true, ...validation,
    };
  }

  delete() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined ? deleteWidget(this.getData()) : {
      error: true, ...validation,
    };
  }

  get() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined ? getWidget(this.getData()) : { error: true, ...validation };
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => { filteredConstraints[d] = this.constraints[d]; });

    const validation = validateJsLib(this.getData(), filteredConstraints);

    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  getValidatorConfig() {
    const widget = this.getData();
    const validationMethod = getValidation(widget.widgetType, widget.config);
    return (config, container) => validationMethod(widget.widgetType, config, container);
  }

  getConfigurationSheet(basic = true) {
    const widget = this.getData();
    return getConfigSheet(widget.widgetType, basic, widget);
  }

  getDataForQueries = (type, devices, attributes, aggregate, operation = 'avg', sampling = 'lastYear', fromTo) => {
    if (type === 'DEVICE') {
      const sources = devices.map((device) => ({
        urn: `fiwoo:device:${device.connectedDevices.id}`,
        fields: sortBy(attributes),
      }));

      if (aggregate) {
        const aggregateBodyQuery = {
          type: 'matrix-aggr',
          sources,
          ops: [operation],
          res: getResolution(sampling),
          from: fromTo ? fromTo.startDate : getDateFromSampling('startDate', sampling),
          to: fromTo ? fromTo.endDate : getDateFromSampling('endDate'),
          pagination: {
            page: 1,
            size: 25,
          },
        };

        return aggregateBodyQuery;
      }
      return sources;
    } if (type === 'ETL') {
      // TODO: complete this with the historicals v2 implementation.
    } else if (type === 'KPI') {
      // TODO: complete this with the historicals v2 implementation.
    }
  }

  mergeAggregateBody = (bodies) => {
    const newAggregates = [];
    const notAggregates = bodies.filter((b) => b.type !== 'matrix-aggr');
    const aggregates = bodies.filter((body) => body.type === 'matrix-aggr');
    aggregates.forEach((bodyAggr) => {
      const {
        res: resolution,
        from,
        to,
        sources,
      } = bodyAggr;

      if (!sources.length) return;

      const index = newAggregates.findIndex(
        (aggr) => aggr.res === resolution && aggr.from === from && aggr.to === to,
      );
      if (index >= 0) {
        const allSources = [...newAggregates[index].sources, ...bodyAggr.sources];
        const deleteDuplicates = [];
        allSources.forEach((source) => {
          const i = deleteDuplicates.findIndex((s) => s.urn === source.urn);
          if (i >= 0) {
            deleteDuplicates[i] = {
              ...deleteDuplicates[i],
              fields: [...new Set([...deleteDuplicates[i].fields, ...source.fields])],
            };
            return;
          }
          deleteDuplicates.push(source);
        });
        newAggregates[index] = {
          ...newAggregates[index],
          ops: [...new Set([...newAggregates[index].ops, ...bodyAggr.ops])],
          sources: deleteDuplicates.map((source) => {
            const sourceOfMap = bodyAggr.sources.find((s) => s.urn === source.urn);
            const map = sourceOfMap
              ? [...source.fields, ...sourceOfMap?.fields]
              : [...source.fields];
            return {
              urn: source.urn,
              fields: [...new Set(map)],
            };
          }),
        };
      } else {
        newAggregates.push(bodyAggr);
      }
    });
    return [...notAggregates, ...newAggregates];
  }

  checkFields = (fields, lastValuesFields) => isEqual(fields, lastValuesFields)

  // HISTORICALV2
  // * PARAMETERS:
  // *  - selected: current device selection performed by the user. [Array of devices].
  // *  - linkedWidgets: all the widgets contained in a LINKED WIDGET. [Array of widgets].
  getBodyQueryHistoricalLinked = (selectedDevices, linkedWidgets) => {
    const dataForQueries = [{
      type: 'matrix-last-value',
      sources: [],
    }];

    linkedWidgets.forEach((widget) => {
      const { widgetType } = widget;
      const { endDate, startDate } = widget.config.data;
      const usedAttributes = widget.config.data.attributeFilter;
      const usedDevices = selectedDevices.filter(
        (device) => {
          const attrs = getAllDeviceAttributes(device.connectedDevices);
          const isThere = attrs.filter((attr) => usedAttributes.includes(attr));
          return isThere.length;
        },
      );
      const usedSampling = widget.config.data.sampling;
      const fromTo = widget.config.data.type === 'historical' && endDate && startDate ? { endDate, startDate } : null;
      const historicalQueryBody = this.getDataForQueries('DEVICE', usedDevices, usedAttributes, true, 'avg', usedSampling, fromTo);

      const lastValueSources = this.getDataForQueries('DEVICE', usedDevices, usedAttributes, false);

      // Group petitions to the same device/attribute/sampling but with different operation.
      const { sources } = dataForQueries[0];

      const allSources = [...lastValueSources, ...sources];
      const allSourcesParsed = [];
      allSources.forEach((s) => {
        const index = allSourcesParsed.findIndex((ls) => s.urn === ls.urn);
        if (index >= 0) {
          allSourcesParsed[index].fields = [
            ...new Set([...allSourcesParsed[index].fields, ...s.fields]),
          ];
          return;
        }
        allSourcesParsed.push(s);
      });

      // * We iterate through the array of linked widgets and act according to the widget type.
      // * We identify the attributes used in each widget and browse the list of devices
      // * looking for those that contain them.

      switch (widgetType) {
        case 'NEEDLE':
        case 'PERCENTAGE':
        case 'TABLE': {
          dataForQueries[0].sources = allSourcesParsed;
          break;
        }
        case 'BARS':
        case 'TEXT_ACCOUNTANT': {
          dataForQueries[0].sources = allSourcesParsed;
          dataForQueries.push(historicalQueryBody);
          break;
        }
        case 'LINES': {
          dataForQueries.push(historicalQueryBody);
          break;
        }
        case 'SWITCH': {
          // TODO: complete when the Switch Widget is implemented.
          break;
        }
        case 'REGULATOR': {
          // TODO: complete when the Regulator Widget is implemented.
          break;
        }
        default: {
          break;
        }
      }
    });
    if (!dataForQueries[0].sources.length) delete dataForQueries[0];
    return this.mergeAggregateBody(dataForQueries);
  }

  getQueryHistorical() {
    const widget = this.getData();
    const queries = [];
    const size = 1;

    switch (widget.widgetType) {
      case 'NEEDLE':
      case 'DIGITAL_ACCOUNTANT':
      case 'PERCENTAGE':
        return [buildQuery(widget.origins[0], false, widget.config)];

      case 'TEXT_ACCOUNTANT':
        return [buildQuery(widget.origins[0], false, widget.config),
          buildQuery(widget.origins[0], true, widget.config,
            size,
            widget.origins[0] ? widget.origins[0].connectedDevices.attributes[0] : undefined)];

      case 'MAP':
      case 'LINKED':
        widget.origins.forEach((o) => {
          queries.push(buildQuery(o, false, widget.config, size));
        });
        return queries;

      case 'TABLE':
        if (widget.config.data.operation === 'last-value' && widget.config.data.type === 'real-time') {
          widget.origins.forEach((o) => {
            queries.push(buildQuery(o, false, widget.config, size));
          });
        } else {
          widget.origins.forEach((o) => {
            const connectedDevice = o.connectedDevices;
            let attributes = getAllDeviceAttributes(connectedDevice);

            // PREVENt TOO MUCH QUERIES
            attributes = attributes.slice(-1 * 15);

            attributes.forEach((attr) => {
              queries.push(
                buildQuery(o, true, widget.config, size, attr),
              );
            });
          });
        }
        return queries;

      case 'BARS':
        if (widget.config.data.type === 'historical') {
          widget.origins.forEach((o) => {
            const connectedDevice = o.connectedDevices;
            const attributes = getAllDeviceAttributes(connectedDevice);
            attributes.forEach((attr) => {
              queries.push(
                buildQuery(o, true, widget.config, size, attr),
              );
            });
          });
        } else {
          widget.origins.forEach((o) => {
            queries.push(buildQuery(o, false, widget.config, size));
          });
        }
        return queries;

      case 'LINES':
        widget.origins.forEach((o) => {
          const connectedDevice = o.connectedDevices;
          const attributes = getAllDeviceAttributes(connectedDevice);
          attributes.forEach((attr) => {
            queries.push(buildQuery(o, true, widget.config, size, attr));
          });
        });
        return queries;

      case 'HEATMAP':
        widget.origins.forEach((o) => {
          const connectedDevice = o.connectedDevices;
          queries.push(buildQuery(o, false, widget.config));
          const attributes = getAllDeviceAttributes(connectedDevice);
          attributes.forEach((attr) => {
            queries.push(buildQuery(o, true, widget.config, size, attr));
          });
        });
        return queries;

      case 'PARAMETRIZED_TEXT':
        if (widget.config.custom?.[widget.widgetType].parameters) {
          widget.config.custom[widget.widgetType].parameters.forEach(
            (e) => (e.device ? queries.push(
              buildQuery({
                type: 'DEVICE',
                connectedDevices: {
                  readDevice: e.device, ...e.device,
                },
              }, false, widget.config.custom[widget.widgetType], size),
            ) : null),
          );
        }
        return queries;

      default:
        return [];
    }
  }

  formatToDataV2() {
    const widget = this.getData();
    switch (widget.widgetType) {
      case 'MAP':
      case 'LINKED': {
        let data;
        if (widget.config.custom?.LINKED?.mode === 'MAP') {
          data = formatToMapWidget(widget.origins);
          return data;
        }
        data = formatToTableWidget(widget.origins, TableDefaultConfiguration);
        return data;
      }
      case 'TABLE':
        return formatToTableWidget(widget.origins, widget.config, true);
      case 'BARS':
        return formatToBarWidget(widget.origins, widget.config, widget.config.data.operation);
      case 'LINES':
        return formatToLineWidget(widget.origins, widget.config, widget.config.data.operation);
      case 'IMAGE': {
        return null;
      }
      case 'HEATMAP':
      case 'HTML':
      case 'TEXT':
      case 'PARAMETRIZED_TEXT':
      case 'TEXT_ACCOUNTANT': {
        return [
          formatToCommonWidgetSingleDeviceV2(
            widget.origins,
            widget.config.data.attributeFilter?.[0] || widget.origins[0].connectedDevices.attributes[0],
          ),
          formatToLineWidget(widget.origins, widget.config),
        ];
      }
      case 'NEEDLE':
      case 'PERCENTAGE':
      default:
        return formatToCommonWidgetSingleDeviceV2(
          widget.origins,
          widget.config.data.attributeFilter[0],
        );
    }
  }

  formatToDataV1() {
    const widget = this.getData();
    switch (widget.widgetType) {
      case 'MAP':
        return formatToMapWidget(widget.origins);
      case 'LINKED': {
        let data;
        if (widget.config.custom?.LINKED?.mode === 'MAP') {
          data = formatToMapWidget(widget.origins);
          return data;
        }
        data = formatToTableWidget(widget.origins, TableDefaultConfiguration);
        return data;
      }
      case 'TABLE':
        return formatToTableWidget(widget.origins, widget.config);
      case 'BARS':
        return formatToBarWidget(widget.origins, widget.config);
      case 'LINES':
        return formatToLineWidget(widget.origins, widget.config);
      case 'HEATMAP':
        return formatToHeatMapWidget(widget.origins);
      case 'HTML':
      case 'TEXT':
      case 'IMAGE':
        return null;
      case 'TEXT_ACCOUNTANT': {
        return [
          formatToCommonWidgetSingleDevice(
            widget.origins[0],
            widget.config?.data?.attributeFilter?.[0] || 0,
          ),
          formatToLineWidget(widget.origins, widget.config)];
      }
      case 'PARAMETRIZED_TEXT':
        return formatToParametrizedWidget(widget.origins);
      default:
        return formatToCommonWidgetSingleDevice(widget.origins[0]);
    }
  }

  formatToData = (v2) => (v2 && this.container ? this.formatToDataV2() : this.formatToDataV1());

  parsedLinkedData(values, selection, historical, intl) {
    const widget = this.getData();
    const { operation } = widget.config.data;
    if (values?.length || values?.data?.length) {
      switch (widget.widgetType) {
        case 'TEXT_ACCOUNTANT': {
          const attributeOfWidget = widget.config?.data?.attributeFilter?.[0];
          if (selection.length === 1) {
            const device = values.find((d) => {
              const isTable = !!d.data && !!d.device;
              return (isTable ? d.data.id : d.id) === selection[0];
            });
            let exist = true;
            widget.origins.forEach((dev) => {
              if (dev.connectedDevices.id === device.id
                && !dev.connectedDevices?.attributes.includes(attributeOfWidget)) {
                exist = false;
              }
            });
            let returned = [];
            const origin = [widget.origins.find((o) => o.connectedDevices.id === selection[0])];
            if (exist) {
              const value = device.data[0] && device.data[0][attributeOfWidget] !== ' '
                ? device.data[0][attributeOfWidget] : 0;
              if (value === 0 && device.data[0]) {
                returned = [
                  value,
                  formatToLineWidget(origin, widget.config),
                ];
              } else {
                returned = [
                  value || intl.formatMessage({ id: 'widget.status.loading' }),
                  formatToLineWidget(origin, widget.config),
                ];
              }
            } else {
              returned = [
                intl.formatMessage({ id: 'widget.status.noData' }),
                formatToLineWidget(origin, widget.config),
              ];
            }
            return returned;
          }
          if (selection.length > 1) {
            const aggregateValues = calculateAggregateValues(
              widget,
              historical,
              selection,
              operation,
            );
            const calculatedLastValue = calculateSimpleLastValue(
              values,
              attributeOfWidget,
              operation,
              selection,
              3,
            );
            if (!aggregateValues && calculatedLastValue >= 0) {
              return [calculatedLastValue, formatToLineWidget(null, widget.config)];
            }
            if (aggregateValues) {
              const mergedOrigin = mergeAggregateOrigins(widget, aggregateValues, selection);
              return [
                calculatedLastValue,
                formatToLineWidget(mergedOrigin, widget.config, operation),
              ];
            }
            return [
              intl.formatMessage({ id: 'widget.status.noData' }),
              formatToLineWidget(null, widget.config),
            ];
          }

          return [
            intl.formatMessage({ id: 'widget.status.loading' }),
            formatToLineWidget(widget.origins, widget.config),
          ];
        }
        case 'PERCENTAGE':
        case 'NEEDLE': {
          const attributeOfWidget = widget.config.data.attributeFilter[0];
          if (selection.length === 1) {
            const device = values.find((d) => {
              const isTable = !!d.data && !!d.device;
              return (isTable ? d.data.id : d.id) === selection[0];
            });
            return device.data?.[0]?.[attributeOfWidget] || 0;
          }
          if (selection.length > 1) {
            const calculatedValues = calculateSimpleLastValue(
              values,
              attributeOfWidget,
              operation,
              selection,
              3,
            );
            return calculatedValues || 0;
          }
          return 0;
        }
        case 'LINES': {
          if (selection.length === 1) {
            const origin = [widget.origins.find((o) => o.connectedDevices.id === selection[0])];
            return formatToLineWidget(origin, widget.config, operation, true);
          }
          if (selection.length > 1) {
            const operationWidget = widget.config?.data?.operation;
            const aggregateValues = calculateAggregateValues(
              widget,
              historical,
              selection,
              operationWidget,
            );
            if (aggregateValues) {
              const mergedOrigin = mergeAggregateOrigins(widget, aggregateValues, selection);
              return formatToLineWidget(
                mergedOrigin,
                widget.config,
                operation,
                true,
              );
            }
          }
          return formatToLineWidget(widget.origins, widget.config, operation, true);
        }

        case 'BARS': {
          if (selection.length === 1) {
            const origin = [widget.origins.find((o) => o.connectedDevices.id === selection[0])];
            return formatToBarWidget(origin, widget.config, widget.config.data.operation, true);
          }
          if (selection.length > 1) {
            if (widget.config.data.type === 'real-time') {
              const selectedDevicesValues = values.data.filter(
                (d) => selection.some((id) => id === d.id),
              );
              const originsSeleted = widget.origins.filter(
                (o) => selection.some((id) => id === o.connectedDevices.id),
              );
              const fakeOrigin = [originsSeleted[0]];
              const obj = {};
              widget.config.data.attributeFilter.forEach((a) => {
                const data = selectedDevicesValues.map((v) => Number(v[a]));
                const cleanedData = pullAll(data, [NaN]);
                if (!cleanedData.length) return;
                obj[a] = calculate(data, widget.config.data.operation, 2).toString();
              });
              fakeOrigin[0].connectedDevices.historicaldata = [obj];
              fakeOrigin[0].connectedDevices.device_id = 'custom';
              return formatToBarWidget(
                fakeOrigin,
                widget.config,
                widget.config.data.operation,
                true,
              );
            }
            const aggregateValues = calculateAggregateValues(widget, historical, selection);
            if (aggregateValues) {
              const mergedOrigin = mergeAggregateOrigins(widget, aggregateValues, selection);

              return formatToBarWidget(
                mergedOrigin,
                widget.config,
                widget.config.data.operation,
                true,
              );
            }
          }
          return formatToBarWidget(widget.origins, widget.config, operation, true);
        }
        case 'TABLE': {
          if (selection.length === 1) {
            const origin = [widget.origins.find((o) => o.connectedDevices.id === selection[0])];
            return formatToTableWidget(origin, widget.config, true);
          }
          if (selection.length > 1) {
            const selectedDevicesValues = values.filter((v) => v.data);
            const originsSeleted = widget.origins.filter(
              (o) => selection.some((id) => id === o.connectedDevices.id),
            );
            const fakeOrigin = [originsSeleted[0]];
            const obj = [];
            widget.config.data.attributeFilter.forEach((a) => {
              const data = selectedDevicesValues.filter((v) => !!v.data[a]);
              const allAttributeValues = [];
              data.forEach((d) => {
                const value = d.data[a];
                if (value) {
                  allAttributeValues.push(Number(value));
                }
              });
              obj.push({
                [a]: allAttributeValues.length
                  ? calculate(allAttributeValues, widget.config.data.operation, 2)?.toString()
                  : undefined,
                recvTime: undefined,
              });
            });
            fakeOrigin[0].connectedDevices.historicaldata = obj;
            fakeOrigin[0].connectedDevices.device_id = 'custom';
            return formatToTableWidget(
              fakeOrigin,
              widget.config,
              true,
            );
          }
          return formatToTableWidget(widget.origins, widget.config, true);
        }
        default:
          return formatToCommonWidgetSingleDevice(widget.origins[0]);
      }
    }
    return formatToCommonWidgetSingleDevice(widget.origins[0]);
  }

  isSmallWidget = () => ([
    'NEEDLE',
    'PERCENTAGE',
    'ONOFF',
    'SLIDER',
    'PARAMETRIZED_TEXT',
  ].includes(this.getData().widgetType));

  /* Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('Widget', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleWidget = new Widget();
export { SampleWidget };
