import React from 'react';
import RemoveHoc from "../../helpers/RemoveComponent";
import { FormattedMessage } from "../../../Contexts/LanguageContext/index";
import Permission from '../../../models/Permission';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent'

const Delete = <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
/>

const View = <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
/>

const Edit = <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
/>

const Clone = <FormattedMessage
    id="options.clone"
    defaultMessage="clone"
    description="Title of clone"
/>


const OptionsForRow = ({ rowContent, viewClick }) => {
    const ButtonOpen = ({ showModal }) => {
        return (
            <li onClick={showModal}>{Delete}</li>
        )

    };

    //const ResetDOM = ResetPermissionPasword(ButtonOpenReset);
    const RemoveDOM = RemoveHoc(ButtonOpen, {class: Permission, nameData: "permissions" })


    return (
        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/permissions'} permission={'read'} >
            <div className="tableMenu">
                <ul className="list-unstyled">
                    <li onClick={() => { viewClick(rowContent) }} state={{ permission: rowContent }}>{View}<i className='uil uil-arrow-right'></i></li>
                </ul>
            </div>
        </PrivateComponent>
    )
};

export default OptionsForRow;