import React from 'react';
import Tagify from '@yaireo/tagify/dist/tagify.min';
import '@yaireo/tagify/dist/tagify.css';
import { PropTypes } from 'prop-types';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.handleRef = this.handleRef.bind(this);
  }

  componentDidMount() {
    const { value, settings } = this.props;
    if (value) this.component.value = value;
    this.tagify = new Tagify(this.component, settings || {});
  }

  shouldComponentUpdate(nextProps) {
    const { tagify } = this;
    const { value } = this.props;

    // check if value has changed (only access one time)
    if (value && nextProps.value && nextProps.value !== value && value.length === 0) {
      tagify.loadOriginalValues(nextProps.value);
      // this.tagify.addTags(nextProps.value, true, true)
    }

    this.tagify.settings.whitelist = nextProps.settings.whitelist;

    if (nextProps.showDropdown) {
      tagify.dropdown.show.call(tagify, nextProps.showDropdown);
    }

    // do not allow react to re-render since the component is modifying its own HTML
    return false;
  }

  handleRef(component) {
    this.component = component;
  }

  render() {
    const {
      name, className, placeholder, autofocus, value, mode, onChange,
    } = this.props;

    const attrs = {
      ref: this.handleRef,
      name,
      className,
      placeholder,
      autoFocus: autofocus,
      value,
      onChange,
    };

    return React.createElement(
      'div',
      { className },
      React.createElement(
        mode,
        { ...attrs, defaultValue: value },
      ),
    );
  }
}

Tags.defaultProps = {
  value: undefined,
  mode: 'input',
  showDropdown: undefined,
  className: '',
  placeholder: '',
  autofocus: false,
};

Tags.propTypes = {
  settings: PropTypes.objectOf(PropTypes.object).isRequired,
  value: PropTypes.string,
  showDropdown: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
  mode: PropTypes.string,
  onChange: PropTypes.string.isRequired,
};

export default Tags;
