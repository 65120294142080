import { getUrnEntity } from '../../../../../helpers/utils';
import { getRandomColor } from '../../../../../pages/Widgets/Add/components/utils';

const LinkedDefaultConfiguration = {
  appearance: {
    hidden: [],
    hiddenAttributes: null,
    iconCategory: true,
    orientation: 'columns',
  },
  colors: {
    headerColor: '#FFFFFF',
    headerTableBackground: '#FFFFFF',
    headerTableColorText: '#2B334A',
  },
  conditions: {
    categoryColors: null,
  },
  custom: {
    MAP: {
      viewType: 'default',
    },
    LINKED: {
      mode: 'MAP',
    },
  },
  data: {
    operation: 'last-value',
    realTimeUpdates: false,
    sampling: '',
    type: 'real-time',
    category: '',
  },
  labels: {
    alias: null,
    categoryAlias: null,
  },
};

export default LinkedDefaultConfiguration;

export const injectLinkedData = (sources, config) => {
  const newConfig = { ...config };
  const categoryColors = {};
  const categories = [];
  sources.forEach((source) => {
    if (getUrnEntity(source.urn).toLowerCase() !== 'kpi') {
      source.fields.forEach((attr) => {
        if (!newConfig.labels.alias) newConfig.labels.alias = {};
        if (!newConfig.labels.alias[attr]) {
          newConfig.labels.alias[attr] = attr;
        }
      });
    }
    if (source.categories && !categories.includes(source.categories[0] || 'OTHER')) {
      categories.push(source.categories[0] || 'OTHER');
      categoryColors[source.categories[0] || 'OTHER'] = {
        color: getRandomColor(),
        mode: '',
        range: '',
        element: [],
      };
    }
  });
  newConfig.conditions.categoryColors = categoryColors;
  return newConfig;
};
