import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [

  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: {
        message: "can't be empty",
      },
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: true,
      length: {
        minimum: 1,
        message:
  <FormattedMessage
    id="Validation.minimum"
    values={{ minimum: '1' }}
  />,

      },
    },
  },
  {
    key: 'description',
    defaultValue: '',
    constraints: {
      length: {
        minimum: 5,
        message:
  <FormattedMessage
    id="Validation.minimum"
    values={{ minimum: '5' }}
  />,

      },
    },
  },
  {
    key: 'action',
    defaultValue: '',
    constraints: {
      presence: true,
      length: {
        minimum: 1,
        message:
  <FormattedMessage
    id="Validation.minimum"
    values={{ minimum: '1' }}
  />,

      },
    },
  },
  {
    key: 'resource',
    defaultValue: '',
    constraints: {
      presence: true,
    },
  },
];

export default ModelExpected;
