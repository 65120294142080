/* eslint-disable import/no-cycle */
// --------------------- [IMPORTS] ----------------------- //
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { updateParagraphText } from '../../../ShowV2/reducer';
import usePrevious from '../../../../helpers/Hooks/usePrevious';
import Field from '../../../../../elements/Field/index';
import { buildUrn } from '../../../../../helpers/utils';
import { ButtonCustom } from '../../../../../elements/Button/index';
import { getWidgetModel } from '../../../../../models/WidgetV2/utils';
import { getKpis, clearKpisState } from '../../../../../services/redux/kpis/actions';
import { getDevices, clearDevicesState } from '../../../../../services/redux/devices/actions';
import { getETLProcedures, clearETLProcedures } from '../../../../../services/redux/etlProcedures/actions';
// --------------------- [IMPORTS] ----------------------- //

// --------------------- [MAIN COMPONENT] ----------------------- //
function SelectableV2({
  params, defaultParams, setParams, intl, widget, dispatchState,
}) {
  // --------------------- [STATE] ----------------------- //
  const [page, setPage] = useState(1);
  const [size] = useState(6);
  const [filter, setFilter] = useState('devices');
  const [selectedSource, setSelectedSource] = useState(undefined);
  const [selectedAttribute, setSelectedAttribute] = useState(undefined);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  // --------------------- [STATE] ----------------------- //

  // --------------------- [REDUX] ----------------------- //
  const devices = useSelector((state) => Object.values(state.get('devices').get('listSources')));
  const etls = useSelector((state) => Object.values(state.get('etlProcedures').get('listSources')));
  const kpis = useSelector((state) => Object.values(state.get('kpis').get('listSources')));
  const fetchingDevices = useSelector((state) => state.get('devices').get('fetching'));
  const fetchingEtls = useSelector((state) => state.get('etlProcedures').get('fetching'));
  const fetchingKpis = useSelector((state) => state.get('kpis').get('fetching'));
  const totalDevices = useSelector((state) => state.get('devices').get('total'));
  const totalEtls = useSelector((state) => state.get('etlProcedures').get('total'));
  const totalKpis = useSelector((state) => state.get('kpis').get('total'));
  // --------------------- [REDUX] ----------------------- //

  // --------------------- [AUX FUNC] ----------------------- //
  const prevPage = usePrevious(page);

  const prevFilter = usePrevious(filter);

  const getData = () => {
    const allData = { devices, etls, kpis };
    return allData[filter]?.map((source) => (
      {
        name: source.name,
        urn: buildUrn(source),
        value: source,
      }
    )) ?? [];
  };

  const getSource = ({ filters = [], pageToRequest = page, sizeToRequest = size }) => {
    const availableSources = {
      devices: getDevices,
      etls: getETLProcedures,
      kpis: getKpis,
    };
    return availableSources[filter]({ filters, page: pageToRequest, size: sizeToRequest });
  };

  const handleSourceSelectChange = (name, value) => {
    const allParams = [...params, ...defaultParams];
    const formattedAttributes = value.attributes.map(
      (attribute) => ({ name: attribute.name, value: attribute, sourceUrn: buildUrn(value) }),
    );
    const filteredAttributes = formattedAttributes.filter(
      (attribute) => (!allParams?.some(
        (param) => param.selectedSource && buildUrn(param.selectedSource) === attribute.sourceUrn
          && param.name.split(':')[1] === attribute.name,
      )),
    );
    setAvailableAttributes([...filteredAttributes]);
    setSelectedSource(value);
  };

  const handleAttributeSelectChange = (name, value) => {
    setSelectedAttribute(value);
  };

  const handleFetchInfo = () => {
    const totalStatus = {
      devices: totalDevices,
      etls: totalEtls,
      kpis: totalKpis,
    };
    const allData = { devices, etls, kpis };
    if (totalStatus[filter] > allData[filter].length) {
      setPage((previousPage) => previousPage + 1);
    }
  };

  const isLoading = () => {
    const fetchingStatus = {
      devices: fetchingDevices,
      etls: fetchingEtls,
      kpis: fetchingKpis,
    };
    return fetchingStatus[filter];
  };

  const handleOnSearch = (value, init = false) => {
    const onSearchStatus = {
      devices: clearDevicesState,
      etls: clearETLProcedures,
      kpis: clearKpisState,
    };
    if (init) onSearchStatus[filter]();
    getSource({ filters: { name: value }, pageToRequest: 1 });
  };

  const handleChangeFilter = (event) => {
    const { target: { value: selectedFilter } } = event;
    setFilter(selectedFilter);
    setPage(1);
  };

  const getFilters = () => [
    {
      name: 'devices',
      translation: 'devices.list.title',
    },
    // TODO: Uncomment the following lines when you want to enable ETLS sources.
    // {
    //   name: 'etls',
    //   translation: 'etl.list.title',
    // },
    {
      name: 'kpis',
      translation: 'kpis.list.title',
    },
  ];

  const updateWidgetSources = () => {
    const widgetCopy = cloneDeep(widget);
    const updatedWidget = getWidgetModel(widgetCopy).getData();
    const selectedSourceUrn = buildUrn(selectedSource);
    const sourceIndex = updatedWidget.sources.findIndex(
      (source) => source.urn === selectedSourceUrn,
    );

    if (sourceIndex !== -1) {
      updatedWidget.sources[sourceIndex].fields.push(selectedAttribute.name);
    } else {
      updatedWidget.sources.push({
        urn: selectedSourceUrn,
        fields: [selectedAttribute.name],
      });
    }
    updateParagraphText(updatedWidget, dispatchState);
  };

  const addParam = () => {
    const paramsToBeSetted = params.filter(
      (param) => !defaultParams.some((d) => d.name === param.name),
    );
    const newParam = { name: `${selectedSource.name}:${selectedAttribute.name}`, selectedSource };
    paramsToBeSetted.push(newParam);
    setParams(paramsToBeSetted);
    updateWidgetSources();
    const newVariables = availableAttributes.filter((elem) => elem.name !== selectedAttribute.name);
    setAvailableAttributes(newVariables);
    setSelectedAttribute(null);
  };

  // --------------------- [AUX FUNC] ----------------------- //

  // --------------------- [LIFECYCLES] ----------------------- //
  useEffect(() => {
    if ((!fetchingDevices && !fetchingEtls && !fetchingKpis && prevPage < page)
      || (prevFilter !== filter)) {
      getSource({});
    }
  }, [fetchingDevices, fetchingEtls, fetchingKpis, page, filter]);
  // --------------------- [LIFECYCLES] ----------------------- //

  // --------------------- [RETURN] ----------------------- //
  return (
    <>
      <Field
        type="select"
        name="datasources"
        placeholder={intl.formatMessage({
          id: 'widgets.parametrizedText.device.placeholder',
        })}
        options={getData()}
        onChange={handleSourceSelectChange}
        value={selectedSource}
        async
        fetchInfo={handleFetchInfo}
        loading={isLoading()}
        onSearch={handleOnSearch}
        filterBy="name"
        className="mr-2"
        filter={{
          handler: handleChangeFilter,
          filters: getFilters(),
          checked: filter,
        }}
      />
      {availableAttributes.length > 0 && (
        <>
          <Field
            type="select"
            name="attributes"
            placeholder={intl.formatMessage({
              id: 'widgets.parametrizedText.variable.placeholder',
            })}
            options={availableAttributes}
            onChange={handleAttributeSelectChange}
            value={selectedAttribute}
            async
            fetchInfo={() => {}}
            filterBy="name"
            className="mr-2"
          />
          {selectedAttribute
            && <ButtonCustom handleOnClick={addParam} style={{ marginBottom: '1.6em' }} className="btn btn-primary" label="+" />}
        </>
      )}
    </>
  );
  // --------------------- [RETURN] ----------------------- //
}
export default SelectableV2;
// --------------------- [MAIN COMPONENT] ----------------------- //
