import React, { Component } from 'react';
import './styles.scss';

class ExpansionPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: this.props.opened || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        opened: this.props.opened,
      });
    }
  }

  handleOnClick = () => {
    this.setState({
      opened: !this.state.opened,
    }, () => this.props.onOpen(this.state.opened));
  };

  render() {
    const { opened } = this.state;
    const { data, noStylish, image } = this.props;
    const Header = this.props.header;
    const Content = this.props.content;
    const ProfileData = this.props.profileData;
    return (
      <div className={`expansion-panel ${opened ? 'opened' : ''} ${noStylish ? 'no-expansion-style' : ''}`}>
        <div
          role="presentation"
          className="expansion-panel-header w-100"
          onClick={this.handleOnClick}
        >
          {Header(data, opened, ProfileData)}
        </div>
        {opened && <div className="expansion-panel-body w-100">{Content(data, opened, ProfileData)}</div>}
      </div>
    );
  }
}

export default ExpansionPanel;
