import React from 'react';
import { injectIntl } from 'react-intl';
import { validate } from 'validate.js';
import { Row, Col } from 'reactstrap';
import CommonView from '../../../../../CommonView';
import { FieldComponent, joinedAttributes } from '../helpers';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../../../Contexts/WizardStepContext';
import { getGeneralDataForAction } from '../../../../../../data/ruleActions';
import withVariables from '../../../../../../components/WithVariables';

const FieldComponentWithVariables = withVariables(FieldComponent);

class EmailComponent extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      to: '',
      from: '',
      subject: '',
      template: '',
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.props.actionComponentRef(this);
  }

  handleInnerInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validate = (callback = null) => {
    const generalActionData = getGeneralDataForAction(this.props.newEntity.actionToCreate)
      .subTypes.find((s) => s.type === 'email');
    const constraints = {};
    ['to', 'from', 'subject', 'template'].forEach((v) => {
      constraints[v] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="Validation.presence" />,
        },
      };

      if (v === 'to' || v === 'from') {
        constraints[v] = {
          ...constraints[v],
          email: {
            message: <FormattedMessage id="Validation.email" />,
          },
        };
      }

      if (v === 'template') {
        constraints[v] = {
          ...constraints[v],
          length: {
            minimum: 2,
            tooShort: <FormattedMessage id="Validation.presence" />,
          },
        };
      }
    });

    const errors = validate(this.state, constraints);
    if (errors) {
      this.setState({
        errors,
      });
    } else this.props.saveAction(callback, generalActionData.parseAction(this.state));
  };

  render() {
    const { intl, newEntity } = this.props;
    const { errors } = this.state;
    return (
      <Row>
        <Col>
          <FieldComponent
            intl={intl}
            name="to"
            type="text"
            value={this.state.to}
            onChange={this.handleInnerInputChange}
            helperText={errors.to}
            error={!!errors.to}
          />
          <FieldComponent
            intl={intl}
            name="from"
            type="text"
            value={this.state.from}
            helperText={errors.from}
            onChange={this.handleInnerInputChange}
            error={!!errors.from}
          />
          <FieldComponent
            intl={intl}
            name="subject"
            type="text"
            value={this.state.subject}
            onChange={this.handleInnerInputChange}
            helperText={errors.subject}
            error={!!errors.subject}
          />
        </Col>
        <Col>
          <FieldComponentWithVariables
            intl={intl}
            name="template"
            type="complexTextarea"
            value={this.state.template}
            helperText={
              (errors.template ? errors.template[0] : errors.template)
              || intl.formatMessage({ id: 'complexTextarea.pattern' })
            }
            error={!!errors.template}
            onChange={this.handleInnerInputChange}
            rows={10}
            devices={newEntity.devices.map(
              (d) => ({
                device: d.device_id,
                attributes: joinedAttributes(d),
              }),
            )}
          />
        </Col>
      </Row>
    );
  }
}

export default withWizardStep(injectIntl(EmailComponent));
