import { ImageConfigurationSheetBasic, ImageConfigurationSheet } from './configurationSheets/Image';
import BarConfigurationSheet, { validationBars } from './configurationSheets/Bars';
import HtmlConfigurationSheet from './configurationSheets/Html';
import LinesConfigurationSheetBasic, { LinesConfigurationSheet, validationLines } from './configurationSheets/Lines';
import MapConfigurationSheet from './configurationSheets/Map';
import NeedleConfigurationSheet, { NeedleConfigurationSheetBasic, validationNeedle } from './configurationSheets/Needle';
import PercentageConfigurationSheet, { PercentageConfigurationSheetBasic, validationPercentage } from './configurationSheets/Percentage';
import TableConfigurationSheet, { TableConfigurationSheetBasic, validationTable, LinkedTableConfigurationSheet } from './configurationSheets/Table';
import TextNumberConfigurationSheet, { TextNumberConfigurationSheetBasic, validationTextNumber } from './configurationSheets/TextNumber';
import validationSendCommand from './configurationSheets/SendCommand';
import { OnOffDefaultConfiguration } from './defaultConfiguration/OnOff';
import BarDefaultConfiguration, { BarDefaultConfigurationForLinked, injectBarsData } from './defaultConfiguration/Bars';
import HeatMapDefaultConfiguration from './defaultConfiguration/HeatMap';
import HtmlDefaultConfiguration from './defaultConfiguration/Html';
import ImageDefaultConfiguration from './defaultConfiguration/Image';
import LinesDefaultConfiguration, { LinesDefaultConfigurationForLinked, injectLinesData } from './defaultConfiguration/Lines';
import LinkedDefaultConfiguration, { injectLinkedData } from './defaultConfiguration/Linked';
import { OnOffConfigurationSheet, OnOffConfigurationSheetBasic, validationOnOff } from './configurationSheets/OnOff';
import { SliderDefaultConfiguration } from './defaultConfiguration/Slider';
import { SliderConfigurationSheetBasic, SliderConfigurationSheet, validationSlider } from './configurationSheets/Slider';
import MapDefaultConfiguration, { injectMapData } from './defaultConfiguration/Map';
import NeedleDefaultConfiguration, { NeedleDefaultConfigurationForLinked } from './defaultConfiguration/Needle';
import { ParametrizedTextDefaultConfiguration } from './defaultConfiguration/ParametrizedText';
import { PercentageDefaultConfiguration, PercentageDefaultConfigurationForLinked } from './defaultConfiguration/Percentage';
import TableDefaultConfiguration, { TableDefaultConfigurationForLinked, injectTableData } from './defaultConfiguration/Table';
import { TextNumberDefaultConfiguration, TextNumberDefaultConfigurationForLinked } from './defaultConfiguration/TextNumber';

export const getValidation = (widgetType, widgetConfig) => {
  /*
  The config is necessary for linkedWidget
  */
  switch (widgetType) {
    case 'NEEDLE':
      return validationNeedle;
    case 'PERCENTAGE':
      return validationPercentage;
    case 'TEXT_ACCOUNTANT':
      return validationTextNumber;
    case 'LINES':
      return validationLines;
    case 'BARS':
      return validationBars;
    case 'SEND_COMMAND':
      return validationSendCommand;
    case 'TABLE':
      return validationTable;
    case 'MAP':// MAP... dont need validation, so we can use a func that return null
    case 'HEATMAP':
    case 'IMAGE':
      return () => null;
    case 'LINKED':
      return widgetConfig.custom.LINKED.mode === 'MAP' ? () => null : validationTable;
    case 'ONOFF':
      return validationOnOff;
    case 'SLIDER':
      return validationSlider;
    default:
      return null;
  }
};

export const getDefaultConfig = (widgetType, isLinked) => {
  switch (widgetType) {
    case 'PERCENTAGE':
      return isLinked
        ? PercentageDefaultConfigurationForLinked
        : PercentageDefaultConfiguration;
    case 'BARS':
      return isLinked
        ? BarDefaultConfigurationForLinked
        : BarDefaultConfiguration;
    case 'HTML':
      return HtmlDefaultConfiguration;
    case 'NEEDLE':
      return isLinked
        ? NeedleDefaultConfigurationForLinked
        : NeedleDefaultConfiguration;
    case 'TEXT_ACCOUNTANT':
      return isLinked
        ? TextNumberDefaultConfigurationForLinked
        : TextNumberDefaultConfiguration;
    case 'IMAGE':
      return ImageDefaultConfiguration;
    case 'TABLE':
      return isLinked
        ? TableDefaultConfigurationForLinked
        : TableDefaultConfiguration;
    case 'LINES':
      return isLinked
        ? LinesDefaultConfigurationForLinked
        : LinesDefaultConfiguration;
    case 'MAP':
      return MapDefaultConfiguration;
    case 'PARAMETRIZED_TEXT':
      return ParametrizedTextDefaultConfiguration;
    case 'HEATMAP':
      return HeatMapDefaultConfiguration;
    case 'LINKED':
      return LinkedDefaultConfiguration;
    case 'ONOFF':
      return OnOffDefaultConfiguration;
    case 'SLIDER':
      return SliderDefaultConfiguration;
    default:
      return {};
  }
};

const getConfigSheet = (widgetType, basic, widget) => {
  /*
    El tercer parámetro, es para widgets dinámicos,
    en el caso del LinkedWidget por ejemplo
  */
  switch (widgetType) {
    case 'PERCENTAGE':
      return basic ? PercentageConfigurationSheetBasic : PercentageConfigurationSheet;
    case 'BARS':
      return BarConfigurationSheet;
    case 'HTML':
      return HtmlConfigurationSheet;
    case 'NEEDLE':
      return basic ? NeedleConfigurationSheetBasic : NeedleConfigurationSheet;
    case 'TEXT_ACCOUNTANT':
      return basic ? TextNumberConfigurationSheetBasic : TextNumberConfigurationSheet;
    case 'IMAGE':
      return basic ? ImageConfigurationSheetBasic : ImageConfigurationSheet;
    case 'TABLE':
      return basic ? TableConfigurationSheetBasic : TableConfigurationSheet;
    case 'LINES':
      return basic ? LinesConfigurationSheetBasic : LinesConfigurationSheet;
    case 'MAP':
      return basic ? '' : MapConfigurationSheet;
    case 'LINKED': {
      const noBasic = widget.config.custom.LINKED.mode === 'MAP' ? MapConfigurationSheet : LinkedTableConfigurationSheet;
      return basic ? '' : noBasic;
    }
    case 'ONOFF':
      return basic ? OnOffConfigurationSheetBasic : OnOffConfigurationSheet;
    case 'SLIDER':
      return basic ? SliderConfigurationSheetBasic : SliderConfigurationSheet;
    default:
      return {};
  }
};

export default getConfigSheet;

export const getInjectedData = (widget, linked) => {
  switch (widget.widgetType) {
    case 'TABLE':
      return injectTableData(widget.origins, widget.config);
    case 'LINKED':
      return injectLinkedData(widget.origins, widget.config);
    case 'MAP':
      return injectMapData(widget.origins, widget.config);
    case 'LINES':
      return injectLinesData(widget.origins, widget.config, linked);
    case 'BARS':
      return injectBarsData(widget.origins, widget.config, linked);
    case 'PARAMETRIZED_TEXT':
      /*
        PARAMETRIZED_TEXT doesnt enter on configure widget,
        so config is empty. We need to inject on save
      */
      return getDefaultConfig('PARAMETRIZED_TEXT');
    default:
      return null;
  }
};
