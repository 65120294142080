import * as TYPES from './types';
import { Map, List } from 'immutable';

/** Initial State of Alerts */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  createdAlert: {},
});

/** ALERTS REDUCER*/
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  /** Cases */
  switch (type) {
    case TYPES.GET_ALERTS_REQUEST:
    case TYPES.GET_ALERT_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    case TYPES.GET_ALERTS_ERROR:
    case TYPES.GET_ALERT_ERROR:
      return state.set('errorFetching', payload).set('fetching', false);

    /** GET ALL ALERTS FROM DB TO STATE */
    case TYPES.GET_ALERTS_SUCCESS:
      const incidentsId = payload.incidents.map(o => o.id);
      const incidents = state
        .get('list')
        .toJS()
        .filter(o => !incidentsId.includes(o.id));
      return state
        .set('total', payload.total)
        .update('list', alertList => List([...incidents, ...payload.incidents]))
        .set('fetching', false);

    /** GET A ALERT FROM DB TO STATE */
    case TYPES.GET_ALERT_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      return (index === -1
        ? state.update('list', alertList => alertList.push(payload))
        : state.update('list', alertList => alertList.splice(index, 1, payload))
      ).set('fetching', false);

    case TYPES.CLEAR_ALERT_SUCCESS:
      return state.set('list', List([]));

    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
};
