/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'definitionId',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'initiated',
    defaultValue: '',
  },
  {
    key: 'state',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'steps',
    defaultValue: {},
    constraints: {
      presence: true,
    },
  },

];

export default ModelExpected;
