import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import MenuElement from '../../../models/MenuElement';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/menu';
import * as TYPES from './types';
import parseResource from '../../../components/MenuNav/helpers/index';

const findType = resource => {
  if (resource && resource.type) return resource.type.toLowerCase();
  if (resource && resource.link) {
    if (!resource.link.host && resource.link.path.includes('/app/dashboard/')) {
      return 'dashboard';
    } else {
      if (parseResource(resource).includes('knowage')) {
        return 'knowage';
      } else {
        return 'external';
      }
    }
  } else {
    return 'parent';
  }
};

/**
 * Actions for WATCHERS
 * */

// Try to recover all menuElement form DB
export function* getTreeThroughApi() {
  try {
    const response = yield call(Api.readTree);

    if (response.status === 200) {
      //Todo Maybe do recursive function
      const roots = response.data.object.roots.map(
        menuElement => new MenuElement(menuElement)
      );

      yield put({
        type: TYPES.GET_TREE_SUCCESS,
        payload: { roots: roots },
      });
    } else {
      yield put({ type: TYPES.GET_TREE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_TREE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an menuElement form DB
export function* getMenuElementThroughApi({ payload }) {
  try {
    const response = yield call(Api.readMenuElement, payload);
    if (response.status === 200) {
      const menuElementFromDB = new MenuElement(response.data.object);
      yield put({
        type: TYPES.GET_MENUELEMENT_SUCCESS,
        payload: menuElementFromDB,
      });
    } else {
      yield put({ type: TYPES.GET_MENUELEMENT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MENUELEMENT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to create menuElement in DB
export function* addMenuElementsThroughApi({ payload }) {
  try {
    const response = yield call(Api.addMenuElement, payload);
    if (response.status === 201) {
      const menuElementFromDB = new MenuElement(response.data.object);
      yield put({
        type: TYPES.ADD_MENUELEMENT_SUCCESS,
        payload: menuElementFromDB,
      });
      yield getHomeThroughApi();
    } else {
      yield put({ type: TYPES.ADD_MENUELEMENT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_MENUELEMENT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update menuElement in DB
export function* updateMenuElementsThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateMenuElement, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_MENUELEMENT_SUCCESS,
        payload: new MenuElement(response.data.object),
      });
      yield getHomeThroughApi();
    } else {
      yield put({ type: TYPES.MODIFY_MENUELEMENT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_MENUELEMENT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update positionElement in DB
export function* updatePositionElementsThroughApi({ payload }) {
  try {
    const response = yield call(Api.updatePositionElement, payload);
    if (response.status === 200)
      yield put({
        type: TYPES.MODIFY_POSITION_ELEMENT_SUCCESS,
        payload: response.data,
      });
    else {
      yield put({
        type: TYPES.MODIFY_POSITION_ELEMENT_ERROR,
        payload: response,
      });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_POSITION_ELEMENT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete menuElement in DB
export function* deleteMenuElementsThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteMenuElement, payload);

    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_MENUELEMENT_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_MENUELEMENT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_MENUELEMENT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getSettingsMenuThroughApi() {
  try {
    const response = yield call(Api.getSettingsMenu);
    response.data['settings'] = response.data.object
    if (response.status === 200) {
      yield put({
        type: TYPES.GET_SETTINGS_MENU_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: TYPES.GET_SETTINGS_MENU_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_SETTINGS_MENU_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* updateSettingsMenuThroughApi(payload) {
  try {
    const response = yield call(Api.updateSettingsMenu, payload);

    if (response.status === 204) {
      yield put({ type: TYPES.UPDATE_SETTINGS_MENU_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.UPDATE_SETTINGS_MENU_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.UPDATE_SETTINGS_MENU_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getHomeThroughApi() {
  try {
    const response = yield call(Api.getSettingsMenu);

    if (response.status === 200) {
      if (response.data.object.homeElementId) {
        const responseMenuElement = yield call(Api.readMenuElement, {
          id: response.data.object.homeElementId,
        });

        if (responseMenuElement.status === 200) {
          const type = findType(responseMenuElement.data.object.resource);
          let payload;
          switch (type) {
            case 'dashboard':
              const dashboardId = responseMenuElement.data.object.resource.link.path.split(
                '/'
              );
              payload = { resource: dashboardId[dashboardId.length - 1], type };
              break;
            case 'external':
            case 'knowage':
              payload = {
                resource: parseResource(responseMenuElement.data.object.resource),
                type,
              };
              break;
            default:
              payload = { resource: null, type };
          }

          yield put({ type: TYPES.GET_HOME_SUCCESS, payload });
        } else {
          yield put({ type: TYPES.GET_HOME_ERROR, payload: response });
          yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
      } else {
        yield put({ type: TYPES.GET_HOME_SUCCESS, payload: { resource: null, type: 'noMenu' } });
      }
    } else {
      yield put({ type: TYPES.GET_HOME_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_HOME_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* setHomeThroughApi(payload) {
  try {
    const response = yield call(Api.setHome, payload);

    if (response.status === 200) {
      yield put({ type: TYPES.SET_HOME_SUCCESS, payload: response.data.object });
    } else {
      yield put({ type: TYPES.SET_HOME_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.SET_HOME_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* clearHomeThroughApi() {
  try {
    const response = yield call(Api.clearHome);

    if (response.status === 200) {
      yield put({ type: TYPES.CLEAR_HOME_SUCCESS, payload: response.data.object });
    } else {
      yield put({ type: TYPES.CLEAR_HOME_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.CLEAR_HOME_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_TREE_REQUEST
function* watcherGetTree() {
  yield takeLatest('GET_TREE_REQUEST', getTreeThroughApi);
}

// Watcher looking for GET_MENUELEMENT_REQUEST
function* watcherGetMenuElement() {
  yield takeLatest('GET_MENUELEMENT_REQUEST', getMenuElementThroughApi);
}

// Watcher looking for ADD_MENUELEMENT_REQUEST
function* watcherAddMenuElements() {
  yield takeEvery('ADD_MENUELEMENT_REQUEST', addMenuElementsThroughApi);
}

// Watcher looking for MODIFY_MENUELEMENT_REQUEST
function* watcherUpdateMenuElements() {
  yield takeLatest('MODIFY_MENUELEMENT_REQUEST', updateMenuElementsThroughApi);
}
// Watcher looking for MODIFY_POSITION_ELEMENTS_REQUEST
function* watcherUpdatePositionElements() {
  yield takeLatest(
    'MODIFY_POSITION_ELEMENT_REQUEST',
    updatePositionElementsThroughApi
  );
}

// Watcher looking for DELETE_MENUELEMENT_REQUEST
function* watcherDeleteMenuElements() {
  yield takeEvery('DELETE_MENUELEMENT_REQUEST', deleteMenuElementsThroughApi);
}

function* watherGetSettingsMenu() {
  yield takeEvery('GET_SETTINGS_MENU_REQUEST', getSettingsMenuThroughApi);
}

function* watherUpdateSettingsMenu() {
  yield takeEvery('UPDATE_SETTINGS_MENU_REQUEST', updateSettingsMenuThroughApi);
}

function* watcherSetHome() {
  yield takeEvery('SET_HOME_REQUEST', setHomeThroughApi);
}

function* watcherGetHome() {
  yield takeEvery('GET_HOME_REQUEST', getHomeThroughApi);
}

function* watcherClearHome() {
  yield takeEvery('CLEAR_HOME_REQUEST', clearHomeThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetTree),
    fork(watcherGetMenuElement),
    fork(watcherAddMenuElements),
    fork(watcherUpdateMenuElements),
    fork(watcherDeleteMenuElements),
    fork(watcherUpdatePositionElements),
    fork(watherGetSettingsMenu),
    fork(watherUpdateSettingsMenu),
    fork(watcherSetHome),
    fork(watcherGetHome),
    fork(watcherClearHome),
  ]);
}
