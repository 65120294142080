import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CommonView from '../../../CommonView';
import Field from '../../../../elements/Field/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Dashboard, { SampleDashboard } from '../../../../models/Dashboard/index';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

const title = (
  <FormattedMessage
    id="dashboards.add.title"
    defaultMessage="Add dashboard"
    description="Title of add dashboard"
  />
);

class Add extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      name: this.props.newEntity.name,
      description: this.props.newEntity.description,
      errors: this.props.errors,
      onValidation: false,
    };
  }

  validate = () => {
    this.savePartialDashboard();
  };

  savePartialDashboard = () => {
    this.setErrors({});

    const obj = this.state;
    // delete obj.id;
    const NewDashboard = new Dashboard(obj);
    const created = NewDashboard.validate(['name', 'description']);

    if (created.error) {
      delete created.error;
      this.setErrors(created);
      this.props.onError(created.error);
    } else {
      const { errors, onValidation, ...rest } = this.state;
      this.props.onContinue(rest);
    }

    //
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  render() {
    const { intl } = this.props;
    const { errors } = this.state;

    return (
      <>
        <Container>
          <div className="wizardWrapper">
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                {['name', 'description'].map((key) => (
                  <Field
                    name={key}
                    label={intl.formatMessage(
                      SampleDashboard.plainTranslations[key],
                    )}
                    placeholder={intl.formatMessage({
                      id: `dashboards.wizard.${key}.placeholder`,
                    })}
                    id={key}
                    value={this.state[key]}
                    type={key === 'name' ? 'description' : 'text'}
                    onChange={this.handleInputChange}
                    helperText={errors[key]}
                    error={!!errors[key]}
                    key={`${key}-dashboardCreationwizard`}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addDashboardSuccessFlag: state.get('dashboards').get('addSuccess'),
  errorFetching: state.get('dashboards').get('errorFetching'),
  notify: state.get('notify'),
});

Add.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  description: PropTypes.string,
};

Add.defaultProps = {
  title,
  name: '',
  description: '',
  permissionsPolicy: [],
  errors: {},
};

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(Add)));
