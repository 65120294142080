import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { Col, Row } from 'reactstrap';
import ConditionalButton from './ConditionalButton';
import PolygonMapModal from '../PolygonMapModal';
import {
  AttributeSelector,
  DeviceSelector,
  OperatorSelector,
  ValueField,
} from '../helpers';
import ActionButtons from '../ActionButtons';
import Device from '../../../../../../models/Device/index';
import usePrevious from '../../../../../helpers/Hooks/usePrevious';
import {
  condition as conditionStyle, commonFields, conditionalButton, actionsButtons, nested, noSelector,
} from './ConditionalRule.module.scss';

const ConditionalRule = ({
  condition: _condition,
  errors,
  intl,
  newIndependentCondition,
  removeCondition,
  orIndex,
  andIndex,
  occurIndex,
  newNestedCondition,
  devices,
  onChange,
  mainCondition,
  forceUpdate,
  handleTimeCondition,
}) => {
  const [condition, setCondition] = useState(clone(_condition));
  const [showMap, setShowMap] = useState(false);
  const prevProps = usePrevious({ condition: _condition });
  const updateFather = () => {
    onChange({
      ...condition,
      orIndex,
      andIndex,
      type: condition.type,
    });
  };

  useEffect(() => {
    if (prevProps && (prevProps.condition !== _condition || condition !== _condition)) {
      updateFather();
    }
  }, [condition]);

  useEffect(() => {
    if (forceUpdate) setCondition(_condition);
  }, [forceUpdate]);

  const handlePlusButtonClick = () => {
    newIndependentCondition();
  };

  const handleMinusButtonClick = (orIndx, andIndx) => {
    removeCondition(orIndx, andIndx);
  };

  const handleNestButtonClick = () => {
    newNestedCondition(condition.device, orIndex, 'and');
  };

  const handleTimeButtonClick = (orIndx, andIndx, type) => {
    handleTimeCondition(orIndx, andIndx, type);
  };

  const assignAreaToValue = (features) => {
    if (features && features[0] && features[0].properties) {
      setCondition((prev) => ({ ...prev, value: features }));
      setShowMap((prev) => !prev);
    } else {
      setCondition((prev) => ({ ...prev, value: 'empty' }));
    }
  };

  const handleOnChange = (newData) => {
    const data = { ...newData };
    setCondition((prev) => {
      setCondition({ ...prev, ...data });
    });
  };

  const isNested = () => andIndex !== 0;

  const isFirstOfItsType = () => andIndex === 1;

  const isIfCondition = () => orIndex === 0 && andIndex === 0;

  const hasConditionalButtonSelector = () => isIfCondition() || !isNested() || isFirstOfItsType();

  return (
    <>
      <div
        className={`${conditionStyle} ${_condition.type} ${isNested() ? nested : ''} ${!hasConditionalButtonSelector() ? noSelector : ''}`}
      >
        <div className={conditionalButton}>
          {hasConditionalButtonSelector() && (
          <ConditionalButton
            type={andIndex === 0 && orIndex > 1 ? mainCondition.type : _condition.type}
            orIndex={orIndex}
            andIndex={andIndex}
            onClick={(selectedType) => setCondition((prev) => ({ ...prev, type: selectedType }))}
            fixed={andIndex === 0 && orIndex > 1}
            onEdit
          />
          )}
        </div>
        <Col lg={9} className="px-0">
          <div className="d-flex align-items-center">
            <Col lg={10} className={commonFields}>
              <DeviceSelector
                condition={_condition}
                onChange={handleOnChange}
                errors={errors}
                intl={intl}
                devices={devices}
                disabled={!!isNested()}
              />
              <AttributeSelector
                condition={_condition}
                onChange={handleOnChange}
                errors={errors}
                intl={intl}
              />
              <OperatorSelector
                condition={_condition}
                onChange={handleOnChange}
                errors={errors}
                intl={intl}
              />

              <ValueField
                condition={_condition}
                onChange={handleOnChange}
                errors={errors}
                intl={intl}
                setShowMap={setShowMap}
              />
            </Col>
            <div className="d-flex">
              <ActionButtons
                handlePlusButtonClick={handlePlusButtonClick}
                handleMinusButtonClick={handleMinusButtonClick}
                handleNestButtonClick={handleNestButtonClick}
                handleTimeButtonClick={handleTimeButtonClick}
                type={_condition.type}
                orIndex={orIndex}
                andIndex={andIndex}
                condition={_condition}
              />
            </div>
          </div>
        </Col>
      </div>
      <PolygonMapModal
        features={_condition && _condition.value ? _condition.value : []}
        showMap={showMap}
        assignAreaToValue={assignAreaToValue}
        togglePolygonalMapModal={() => setShowMap((prev) => !prev)}
        mode={_condition && _condition.value ? new EditingMode() : new DrawPolygonMode()}
      />
    </>
  );
};
ConditionalRule.defaultProps = {
  condition: {},
  errors: {},
  intl: {},
  devices: [],
  forceUpdate: false,
};

ConditionalRule.propTypes = {
  condition: PropTypes.shape({
    type: PropTypes.oneOf(['and', 'or', 'if']),
    device: PropTypes.instanceOf(Device),
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  }),
  errors: PropTypes.objectOf(PropTypes.object),
  intl: PropTypes.objectOf(PropTypes.object),
  newIndependentCondition: PropTypes.func.isRequired,
  removeCondition: PropTypes.func.isRequired,
  newNestedCondition: PropTypes.func.isRequired,
  orIndex: PropTypes.number.isRequired,
  andIndex: PropTypes.number.isRequired,
  devices: PropTypes.arrayOf(PropTypes.instanceOf(Device)),
  onChange: PropTypes.func.isRequired,
  mainCondition: PropTypes.objectOf(PropTypes.object).isRequired,
  forceUpdate: PropTypes.bool,
};

export default injectIntl(ConditionalRule);
