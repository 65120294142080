import { Map, List } from 'immutable';
import { cloneInstance } from '../../../helpers/utils';
import * as TYPES from './types';

export const exampleFiware = [
  { service: 'service1', servicepath: 'servicepath1' },
  { service: 'service2', servicepath: 'servicepath2' },
];
export const examplePermissionPolicy = ['Group1', 'Group2', 'Group3', 'Group4'];
export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];
export const exampleProtocol = ['IoTA-UL', 'json', 'SIGFOX', 'LoRa'];

/** Initial State of ETLProcedures */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  readETLProcedure: undefined,
  executionList: List([]),
  totalexecutionList: 0,
  fetchingexecutionList: false,
  errorFetchingexecutionList: false,
  verified: false,
  listSources: {},
});

/** ETL_PROCEDURES REDUCER */
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    case TYPES.GET_PUBLIC_ETL_PROCEDURES_SUCCESS:
    case TYPES.GET_ETL_PROCEDURES_SUCCESS:
    case TYPES.GET_MY_ETL_PROCEDURES_SUCCESS:
    case TYPES.UPDATE_ETL_PROCEDURES_STATE:
      const etlProcedureId = payload.etlProcedures.map((o) => o.id);
      const etlProcedures = state
        .get('list')
        .toJS()
        .filter((o) => !etlProcedureId.includes(o.id));

      return state
        .set('total', payload.total)
        .set('list', List([...etlProcedures, ...payload.etlProcedures]))
        .set('fetching', false)
        .set('etlProcedureAdded', false)
        .set('etlProcedureDeleted', false);

    case TYPES.GET_ETL_EXECUTIONS_SUCCESS:
      const etlExecutionId = payload.etlExecutions.map((o) => o.id);
      const etlExecutions = state
        .get('executionList')
        .toJS()
        .filter((o) => !etlExecutionId.includes(o.id));

      return state
        .set('totalexecutionList', payload.total)
        .set('executionList', List([...etlExecutions, ...payload.etlExecutions]))
        .set('fetchingexecutionList', false);

    /** INITIALIZE GET ETL_PROCEDURES FROM DB */
    case TYPES.GET_MY_ETL_PROCEDURES_ERROR:
      return state
        .set('errorFetching', payload)
        .set('fetching', false)
        .set('list', List([]))
        .set('etlProcedureAdded', false)
        .set('etlProcedureDeleted', false);

    /** GET ETL_PROCEDURE FROM DB TO STATE */
    case TYPES.GET_ETL_PROCEDURE_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      // TODO
      // If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1
        ? (state.update('list', (list) => list.push(payload))
          .set('readETLProcedure', payload))
          .set('verified', false)
        : state.set('readETLProcedure', payload);

    case TYPES.GET_ETL_V2:
      return state
      .update('listSources', (oldList) => {
        const listCopy = cloneInstance(oldList);
        return {
          ...listCopy,
          [payload.id]: payload,
        }
      });

    case TYPES.GET_ETL_TO_SOURCE_LIST:{
      const sources = {};
      payload.forEach((source) => {
        sources[source.id] = source
      })
      return state
      .update('listSources', (oldList) => {
        const listCopy = cloneInstance(oldList);
        return {
          ...listCopy,
          ...sources,
        }
      })
    }

      // //////////////////////////////////////////////////////
      // ////// GET SINGLE ETL_PROCEDURE ROLES
      // //////////////////////////////////////////////////////

      // //////////////////////////////////////////////////////
      // ////// ADD AN ETL_PROCEDURE
      // //////////////////////////////////////////////////////

    /** ADD NEW ETL_PROCEDURE TO STATE */
    case TYPES.ADD_ETL_PROCEDURE_SUCCESS:
      return state
        .update('list', (userList) => userList.push(payload))
        .set('fetching', false)
        .set('addSuccess', true)
        .set('etlProcedureAdded', true)
        .set('etlProcedureDeleted', false)
        .set('errorFetching', false)
        .update('total', (total) => total + 1);

      // //////////////////////////////////////////////////////
      // ////// UPDATE AN ETL_PROCEDURE
      // //////////////////////////////////////////////////////

    /** UPDATE ETL_PROCEDURE IN STATE */
    case TYPES.MODIFY_ETL_PROCEDURE_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      aux = index !== -1
        ? state.update('list', (etlProcedureList) => etlProcedureList.splice(index, 1, payload))
        : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);

      if (aux.get('readETLProcedure').id === payload.id) {
        aux = aux.set('readETLProcedure', payload);
      }
      aux.set('verified', false);
      // aux = aux.set('etlProcedureAdded', false);
      // aux = aux.set('etlProcedureDeleted', false);
      return aux;

    case TYPES.VERIFY_ETL_PROCEDURE_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      aux = index !== -1
        ? state.update('list', (etlProcedureList) => etlProcedureList.splice(index, 1, payload))
        : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);
      if (aux.get('readETLProcedure').id === payload.id) {
        aux = aux.set('readETLProcedure', payload);
      }
      aux.set('verified', true);
      // aux = aux.set('etlProcedureAdded', false);
      // aux = aux.set('etlProcedureDeleted', false);
      return aux;

    /** plural used in opendata request */
    case TYPES.DELETE_ETL_PROCEDURES_SUCCESS:
      return state.merge(
        Map({
          list: state.get('list').filterNot((r) => payload.includes(r.id)),
          fetching: false,
          etlProcedureAdded: false,
          etlProcedureDeleted: true,
          total: state.get('total') - payload.length,
        }),
      );

      // //////////////////////////////////////////////////////
      // ////// DELETE AN ETL_PROCEDURE
      // //////////////////////////////////////////////////////

    /** DELETE ETL_PROCEDURE IN STATE */
    case TYPES.DELETE_ETL_PROCEDURE_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      aux = index !== -1
        ? state.update('list', (userList) => userList.delete(index))
        : state;
      aux = aux.set('fetching', false);
      aux = aux.set('etlProcedureAdded', false);
      aux = aux.set('etlProcedureDeleted', true);
      aux = aux.update('total', (total) => total - 1);
      return aux;

    // //////////////////////////////////////////////////////
    // ////// RESET ERROR FETCHING TO FALSE
    // //////////////////////////////////////////////////////
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_ETL_PROCEDURE_RESET:
      return state
        .set('errorFetching', false)
        .set('etlProcedureAdded', false)
        .set('etlProcedureDeleted', false);

      // //////////////////////////////////////////////////////
      // ////// FETCHING
      // //////////////////////////////////////////////////////

    /** INITIALIZE GET ETL_PROCEDURES FROM DB */
    /** INITIALIZE GET ETL_PROCEDURES FROM DB */
    /** INITIALIZE GET ETL_PROCEDURE ROLES FROM DB */
    /** INITIALIZE ADD ETL_PROCEDURE TO DB AND STATE */
    /** INITIALIZE UPDATE ETL_PROCEDURE TO DB */
    /** INITIALIZE DELATION OF A ETL_PROCEDURE IN DB */
    case TYPES.GET_ETL_PROCEDURES_REQUEST:
    case TYPES.GET_MY_ETL_PROCEDURES_REQUEST:
    case TYPES.GET_ETL_PROCEDURE_ROLES_REQUEST:
    case TYPES.ADD_ETL_PROCEDURE_REQUEST:
    case TYPES.MODIFY_ETL_PROCEDURE_REQUEST:
    case TYPES.DELETE_ETL_PROCEDURE_REQUEST:
      return state
        .set('fetching', true)
        .set('etlProcedureAdded', false)
        .set('errorFetching', false)
        .set('etlProcedureDeleted', false);

    case TYPES.GET_ETL_EXECUTIONS_REQUEST:
      return state
        .set('fetchingexecutionList', true)
        .set('errorFetchingexecutionList', false);

    /** SET AS FALSE THE addSuccess STATE FLAG */
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.ADD_ETL_PROCEDURE_SUCCESS_FLAG:
    case TYPES.MODIFY_ETL_PROCEDURE_SUCCESS_FLAG:
      return state
        .set('fetching', false)
        .set('etlProcedureAdded', true)
        .set('etlProcedureDeleted', false);

      // //////////////////////////////////////////////////////
      // ////// ERRORS
      // //////////////////////////////////////////////////////

    /** INITIALIZE GET ETL_PROCEDURE FROM DB */
    /** INITIALIZE GET ETL_PROCEDURE FROM DB ERROR */
    /** INITIALIZE GET ETL_PROCEDURE ROLES FROM DB ERROR */
    /** INITIALIZE ERROR ADD ETL_PROCEDURE */
    /** UPDATE ETL_PROCEDURE ERROR */
    /** INITIALIZE DELATION OF AN ETL_PROCEDURE IN DB */
    case TYPES.GET_ETL_PROCEDURE_ERROR:
    case TYPES.GET_ETL_PROCEDURE_ROLES_ERROR:
    case TYPES.ADD_ETL_PROCEDURE_ERROR:
    case TYPES.MODIFY_ETL_PROCEDURE_ERROR:
    case TYPES.DELETE_ETL_PROCEDURE_ERROR:
    case TYPES.GET_ETL_PROCEDURES_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      aux = aux.set('etlProcedureAdded', false);
      aux = aux.set('etlProcedureDeleted', false);
      return aux;

    case TYPES.GET_ETL_EXECUTIONS_ERROR:
      aux = state.set('errorFetchingexecutionList', payload);
      aux = aux.set('fetchingexecutionList', false);
      return aux;

      // //////////////////////////////////////////////////////
      // ////// CLEARS
      // //////////////////////////////////////////////////////

    case TYPES.CLEAR_ETL_PROCEDURES_STATE:
      return state.set('list', List([]));
    case TYPES.CLEAR_ETL_EXECUTIONS_STATE:
      return state.set('executionList', List([]));

      // //////////////////////////////////////////////////////
      // ////// DEFAUKLT
      // //////////////////////////////////////////////////////

    default:
      return state;
  }
};
