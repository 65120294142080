import React from 'react';
import { navigate, Link } from '@reach/router';
import {
  enterRoute,
  developmentEnvironment,
  defaultLogin,
  defaultPassword,
} from '../../configuration/config';
import Fiwoo_Logo from '../../configuration/icons/svg/logo-fiwoo.svg';
import Login_Gif from '../../images/login.gif';
import { ButtonCustom } from '../../elements/Button';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import User from '../../models/User';
import { imLogged } from '../../helpers';
import Loading from '../../elements/Loading';
import { Col, Container, Row } from 'reactstrap';
import Field from '../../elements/Field';
import Form from 'reactstrap/es/Form';
import CommonView from '../CommonView';
import { injectIntl } from 'react-intl';
import CheckBox from '../../elements/CheckBox';
import './styles.scss';
import { FormattedMessage } from '../../Contexts/LanguageContext/index';
import Alert from '../../components/Alert';

class Login extends CommonView {
  constructor(props) {
    super({ props: props });
    this.state = {
      email: '',
      //email: developmentEnvironment ? defaultLogin : '',
      password: '',
      //password: developmentEnvironment ? defaultPassword : '',
      errors: {}
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.handleFieldChange(name, value);
  };

  handleSubmit = event => {
    event.preventDefault && event.preventDefault();
    event.preventDefault && event.stopPropagation();
    const user = new User(this.state);
    const login = user.login();

    if (login.error) {
      delete login.error;
      this.setErrors(login);
      // this.props.onError(login.error);
    } else {
      this.setErrors({});
    }
  };

  render() {
    imLogged() && navigate(enterRoute);
    const { intl, loading, errorMessages } = this.props;
    const { errors } = this.state;

    {
      errorMessages &&
        errorMessages.length > 0 &&
        errorMessages.map(err => <p>{err.message}</p>);
    }
 
    return (
      <>
        {loading && <Loading />}
        <Container className="login-container" fluid={true}>
          <Row>
            <Col xs={12}>
              <div className="logoWrapper">
                <Link to={'/'}>
                  {Fiwoo_Logo ? (
                    <img src={Fiwoo_Logo} alt="logotype" />
                  ) : (
                    <h1>{this.props.siteTitle}</h1>
                  )}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="login-container">
          <Row>
            <Col sm={{ size: 6, order: 2, offset: 1 }}>
              <div className={'login-block'}>
                <h1 className="mb-5">
                  <FormattedMessage id="login.title" />
                </h1>

                <Form onSubmit={event => this.handleSubmit(event)} noValidate>
                  {['email', 'password'].map((key, i) => (
                    <Field
                      name={key}
                      label={intl.formatMessage({ id: `User.model.${key}` })}
                      placeholder={key}
                      id={key}
                      value={this.state[key]}
                      type={
                        key === 'email'
                          ? 'email'
                          : key === 'password'
                          ? 'password'
                          : 'text'
                      }
                      onChange={this.handleInputChange}
                      helperText={errors[key]}
                      error={!!errors[key]}
                      key={`${key}-${i}`}
                    />
                  ))}
                  {this.props.errorMessages &&
                    this.props.errorMessages.map(message => (
                      <Alert
                        text={
                          <FormattedMessage
                            id={`login.validation.error.${message.type.toLowerCase()}`}
                          />
                        }
                        mode={'info'}
                      />
                    ))}

                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <CheckBox
                        name="remember"
                        label={intl.formatMessage({
                          id: 'login.remember.password.label',
                        })}
                        positionCheck={'left'}
                        reverse={true}
                      />
                    </div>
                    <div>
                      <a href={"mailto:dlopez@iturri.com"}>
                        <FormattedMessage id="login.forgot.pass" />
                      </a>
                    </div>
                  </div>
                  <ButtonCustom
                    id={'login-submit-btn'}
                    label={intl.formatMessage({ id: 'login.button.label' })}
                    type="primary"
                    className="mb-5"
                    handleOnClick={this.handleOnClick}
                  />
                  <p className="policy-text ml-1">
                    <FormattedMessage id="login.terms.and.conditions.1" />
                    <a href="mailto:dlopez@iturri.com">
                      <FormattedMessage id="login.terms" />
                    </a>
                    <FormattedMessage id="login.terms.and.conditions.2" />{' '}
                    <a href={'/'}>
                      <FormattedMessage id="login.privacy" />
                    </a>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="image-login">
          <img
            alt={'login background'}
            src={Login_Gif}
            className="image-vw-vh-100"
          />
        </div>
      </>
    );
  }
}

const mapStatToProps = state => ({
  loading: state.get('auth').get('fetching'),
  errorMessages: state.get('auth').get('errorMessages'),
});

export default connect(mapStatToProps)(injectIntl(withTheme(Login)));
