import React, { memo, useState } from 'react';
import styled from '@emotion/styled';
import {
  Editor,
} from 'draft-js';
import PropTypes from 'prop-types';

import './style.scss';

const TextArea = styled.div`
  padding: 20px;
  border: 1px solid #E2E5E7;
  border-radius: 2px;
  margin: 20px 0;
`;

export function handleStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return contentState.getEntity(entityKey).getMutability() === 'IMMUTABLE';
    },
    callback,
  );
}
export const Mention = memo((props) => {
  const [styla] = useState(
    props.contentState.getEntity(props.entityKey).getData().type === 'default'
      ? 'default-var'
      : 'operation',
  );
  return (
    <span className={styla}>
      {props.children}
    </span>
  );
});

function replaceRange(s, start, end, substitute) {
  return s.substring(0, start) + substitute + s.substring(end);
}

export const getRawData = (rawContent) => {
  let fullString = '';
  rawContent.blocks.forEach((block) => {
    const { text, entityRanges } = block;
    let newText = text;
    entityRanges.reverse().forEach((entity) => {
      const newEntityValue = replaceRange(
        newText,
        entity.offset,
        entity.offset + entity.length,
        rawContent.entityMap[entity.key].data.value,
      );
      newText = newEntityValue;
    });
    fullString = fullString.concat(newText);
  });
  return fullString;
};

export default function AritmethicEditor({ handleChange, editorState }) {
  return (
    <TextArea>
      <Editor
        editorState={editorState}
        onChange={handleChange}
        placeholder="..."
      />
    </TextArea>
  );
}

Mention.propTypes = {
  contentState: PropTypes.objectOf(PropTypes.any),
  entityKey: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.any),
};

Mention.defaultProps = {
  contentState: undefined,
  entityKey: undefined,
  children: undefined,
};

AritmethicEditor.propTypes = {
  handleChange: PropTypes.func,
  editorState: PropTypes.objectOf(PropTypes.any),
};

AritmethicEditor.defaultProps = {
  handleChange: undefined,
  editorState: undefined,
};
