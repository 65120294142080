import React from 'react';
import '../styles.scss';

import { navigate } from '@reach/router';
import { withBlanketCtxt } from '../../../Contexts/BlanketContext';
import { FormattedMessage } from '../../../Contexts/LanguageContext';

import Stepper from '../../../elements/Stepper';
import Alert from '../../Alert';
import { ButtonCustom } from '../../../elements/Button';
import { imLogged } from '../../../helpers';
import { loginRoute } from '../../../configuration/config';
import { WizardStepProvider } from '../../../Contexts/WizardStepContext';

class InnerWizard extends React.Component {
  constructor(props) {
    super(props);
    const { errors, closeInForegroundClick } = props;
    this.state = {
      step: 0,
      onValidation: false,
      errors,
      isNextDisabled: false,
    };

    closeInForegroundClick(false);
  }

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  componentDidUpdate(prevProps) {
    const { onValidation } = this.state;
    const { errors, disableNextButton } = this.props;

    if (onValidation) {
      this.onValidationChange();
    }

    if (errors !== prevProps.errors) {
      this.onErrorsChange(errors);
    }

    if (disableNextButton !== prevProps.disableNextButton) {
      this.onDisableNextChange(disableNextButton);
    }
  }

  componentWillUnmount() {
    const { closeInForegroundClick } = this.props;
    closeInForegroundClick(false);
  }

  onValidationChange = () => {
    this.setState({
      onValidation: false,
    });
  };

  onErrorsChange = (errors) => {
    this.setState({
      errors,
    });
  };

  onDisableNextChange = (value) => {
    this.setState({
      isNextDisabled: value,
    });
  }

  getChildrenTotal = () => {
    const { children } = this.props;
    return React.Children.count(children);
  };

  handleOnClick = (number) => {
    const { handleBlanketVisibility } = this.props;
    const { step } = this.state;
    const currentStep = step + number;
    if (currentStep === this.getChildrenTotal()) {
      handleBlanketVisibility(false);
      return;
    }

    if (number > 0) {
      if (this.child.goToStep && this.child.goToStep() !== undefined) {
        const steps = this.child.goToStep();
        this.setState({
          step: steps,
          errors: undefined,
        });
      } else if (number > 0 && this.child.validate) {
        this.child.validate();
      }
    } else {
      const steps = this.child.prevSteps && this.child.prevSteps() !== undefined
        ? this.child.prevSteps()
        : currentStep;
      this.setState({
        step: steps,
        errors: '',
      });
    }
  };

  handleOnContinue = () => {
    const { step } = this.state;
    const currentStep = step + 1;
    this.setState({
      step: currentStep,
      errors: undefined,
    });
  };

  getActiveComponent = (step, errors) => {
    const {
      children,
      starter,
      success,
      handleError,
      handleContinue,
      handleDisableNext,
      setState,
      newEntity,
      metadata,
      extraData,
    } = this.props;
    const total = this.getChildrenTotal();

    return React.Children.map(children, (child, i) => {
      if (child) {
        if (step === i) {
          return (
            <div className={`activeComponent ${this.isSuccessPage() && 'success'}`} key={child.name}>
              <WizardStepProvider
                onRef={(ref) => { this.child = ref; }}
                onExternalClick={this.handleOnClick}
                actualStep={step + 1}
                maxStep={total}
                starter={starter}
                success={success}
                onError={handleError}
                onContinue={handleContinue}
                onDisableNext={handleDisableNext}
                setState={setState}
                newEntity={newEntity}
                metadata={metadata}
                extraData={extraData}
              >
                {child}
              </WizardStepProvider>
              {!this.isSuccessPage()
                ? (
                  <div className="footer">
                    {errors && typeof errors === 'object' && (
                    <Alert text={errors} mode="warning" />
                    )}
                    {this.getButtons(step)}
                  </div>
                ) : this.waitAndClose()}
            </div>
          );
        }
      }
      return null;
    });
  };

  hideModal = () => {
    const { handleBlanketVisibility } = this.props;
    handleBlanketVisibility(false);
  };

  getButtons = (step) => {
    const { isNextDisabled } = this.state;
    const max = this.getChildrenTotal() - 1;
    return (
      <div className="buttons">
        {step !== 0 && max !== step && (
          <ButtonCustom
            type="secondary"
            className="btn prevButton mr-3"
            handleOnClick={() => this.handleOnClick(-1)}
            label={(
              <FormattedMessage
                id="Wizard.back"
                defaultMessage="Back"
                description="Title of back"
              />
            )}
          />
        )}
        {step === 0 && (
          <ButtonCustom
            type="secondary"
            className="mr-3 btn prevButton"
            handleOnClick={() => this.hideModal()}
            label={(
              <FormattedMessage
                id="Wizard.cancel"
                defaultMessage="Cancel"
                description="Title of cancel"
              />
            )}
          />
        )}
        <ButtonCustom
          type="primary"
          className="btn nextButton"
          disabled={isNextDisabled}
          handleOnClick={() => this.handleOnClick(1)}
          label={
            max === step ? (
              <FormattedMessage
                id="Wizard.finish"
                defaultMessage="finish"
                description="Title of finish"
              />
            ) : (
              <FormattedMessage
                id="Wizard.next"
                defaultMessage="Next"
                description="Title of next"
              />
            )
          }
        />
      </div>
    );
  };

  getStepsTitles = () => {
    const { children } = this.props;
    return React.Children.map(
      children,
      (child, i) => {
        if (child) {
          return (child.props.shortTitle || child.props.title || `Step ${(i + 1)}`);
        }
        return null;
      },
    );
  };

  isStarterPage = () => {
    const { starter } = this.props;
    const { step } = this.state;
    return starter && step === 0;
  };

  isSuccessPage = () => {
    const total = this.getChildrenTotal();
    const { success } = this.props;
    const { step } = this.state;
    return success && step === total - 1;
  };

  waitAndClose = () => {
    setTimeout(() => {
      this.hideModal();
    }, 2000);
  }

  render() {
    const {
      stepper, starter, success, handleBlanketVisibility,
    } = this.props;
    const { step, errors } = this.state;
    const total = this.getChildrenTotal();

    if (!imLogged()) {
      handleBlanketVisibility(false);
      navigate(loginRoute);
      return null;
    }

    return (
      <div id="wizard">
        {stepper
          && !this.isStarterPage()
          && !this.isSuccessPage() && (
            <div className="mainWrapper">
              <div className="d-flex position-relative w-100 h-100 justify-content-center">
                <i
                  className="uil uil-times closeButton"
                  onClick={this.hideModal}
                  onKeyUp={this.hideModal}
                  role="link"
                  label="hide-moda"
                  tabIndex={-1}
                />
                <Stepper
                  actualStep={step + 1}
                  maxStep={total}
                  stepsTitles={this.getStepsTitles()}
                  starter={starter}
                  success={success}
                  new
                />
              </div>

            </div>
        )}
        {this.getActiveComponent(step, errors)}

      </div>
    );
  }
}

InnerWizard.defaultProps = {
  onRef: () => { },
  success: true,
  starter: true,
  stepper: false,
  errors: undefined,
  metadata: undefined,
  extraData: undefined,
};

export default withBlanketCtxt(InnerWizard);
