import {
  takeLatest, call, put, fork, all,
} from 'redux-saga/effects';

import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as TYPESdevices from '../devices/types';
import * as TYPESETLs from '../etlProcedures/types';
import * as Api from '../../api/opendata';

/**
 * Actions for WATCHERS
 * */
// Try to recover all organizations form DB
export function* getOrganizationListThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readOrganizations,
      payload.page,
      payload.size,
      payload.filter,
      'all',
    );

    if (response.status === 200) {
      const { objects } = response.data;
      yield put({
        type: TYPES.GET_ORGANIZATION_LIST_SUCCESS,
        payload: { objects, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_ORGANIZATION_LIST_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_ORGANIZATION_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getOrganizationByIdThroughApi({ payload }) {
  try {
    const response = yield call(Api.getOrganization, payload);
    if (response.status === 200) {
      const { object } = response.data;
      yield put({ type: TYPES.GET_ORGANIZATION_BY_ID_SUCCESS, payload: object });
    } else {
      yield put({ type: TYPES.GET_ORGANIZATION_BY_ID_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_ORGANIZATION_BY_ID_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete device subscription in DB
export function* removeDataSetSubscriptionThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.removeDataSetSubscription,
      payload.objects,
      payload.type,
    );
    if (response.status === 204) {
      // We use plural types to remove them from redux
      const type = payload.type === 'devices'
        ? TYPESdevices.DELETE_DEVICES_SUCCESS
        : TYPESETLs.DELETE_ETL_PROCEDURES_SUCCESS;
      yield put({
        type,
        payload: payload.objects.map((object) => object.id),
      });
      yield put({ type: TYPES.REMOVE_DATASET_SUBSCRIPTION_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.REMOVE_DATASET_SUBSCRIPTION_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.REMOVE_DATASET_SUBSCRIPTION_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* publishDataSetSubscriptionThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.publishDataSetSubscription,
      payload.objects,
      payload.type,
    );
    if (response.status === 204) {
      // FIXME: se podria forzar añadir un device aquí pero realmente
      // solo sabemos que se ha recibido la información no se que haya publicado
      /*
      yield put({
        type: TYPESdevices.ADD_DEVICES_SUCCESS,
        payload: payload.map((device) => device.id),
      });
      */
      yield put({ type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* createGISLayerThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.createGISLayer,
      payload.object,
      payload.dataset,
    );
    if (response.status === 201) {
      yield put({ type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all datasets form DB
export function* getDataSetListThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readDataSet,
      payload.page,
      payload.size,
      payload.filter,
      payload.gis,
      'all',
    );

    if (response.status === 200) {
      const { objects } = response.data;
      yield put({
        type: TYPES.GET_DATASET_LIST_SUCCESS,
        payload: { objects, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_DATASET_LIST_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DATASET_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all datasets form DB
export function* getDataSetListByOrganizationThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readDataSetByOrganization,
      payload.organization,
      payload.page,
      payload.size,
      payload.filter,
      'all',
    );

    if (response.status === 200) {
      const { objects } = response.data;
      yield put({
        type: TYPES.GET_DATASET_LIST_SUCCESS,
        payload: { objects, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_DATASET_LIST_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DATASET_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* addDataSetThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.addDataSet,
      payload.organization,
      payload.dataset_title,
    );
    if (response.status === 201) {
      const { object } = response.data;
      yield put({
        type: TYPES.ADD_DATASET_LIST_SUCCESS,
        payload: object,
      });
    } else {
      yield put({ type: TYPES.ADD_DATASET_LIST_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (e) {
    yield put({ type: TYPES.ADD_DATASET_LIST_ERROR, payload: e });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: e });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_ORGANIZATION_LIST_REQUEST
function* watcherGetOrganizationList() {
  yield takeLatest(TYPES.GET_ORGANIZATION_LIST_REQUEST, getOrganizationListThroughApi);
}

function* watcherGetOrganizationById() {
  yield takeLatest(TYPES.GET_ORGANIZATION_BY_ID_REQUEST, getOrganizationByIdThroughApi);
}

// Watcher looking for GET_DATASET_LIST_REQUEST
function* watcherGetDataSetList() {
  yield takeLatest(TYPES.GET_DATASET_LIST_REQUEST, getDataSetListThroughApi);
}

// Watcher looking for GET_DATASET_LIST_REQUEST_BY_ORG
function* watcherGetDataSetListByOrganization() {
  yield takeLatest(TYPES.GET_DATASET_LIST_REQUEST_BY_ORG, getDataSetListByOrganizationThroughApi);
}

// Watcher looking for REMOVE_DATASET_SUBSCRIPTION_REQUEST
function* watcherRemoveDataSetSubscription() {
  yield takeLatest(TYPES.REMOVE_DATASET_SUBSCRIPTION_REQUEST, removeDataSetSubscriptionThroughApi);
}

// Watcher looking for PUBLISH_DATASET_SUBSCRIPTION_REQUEST
function* watcherPublishDataSetSubscription() {
  yield takeLatest(
    TYPES.PUBLISH_DATASET_SUBSCRIPTION_REQUEST,
    publishDataSetSubscriptionThroughApi,
  );
}

// Watcher looking for CREATE_GIS_LAYER_REQUEST
function* watcherCreateGISLayer() {
  yield takeLatest(TYPES.CREATE_GIS_LAYER_REQUEST, createGISLayerThroughApi);
}

// Watcher looking for ADD_DATASET_LIST_REQUEST
function* watcherAddDataSetList() {
  yield takeLatest(TYPES.ADD_DATASET_LIST_REQUEST, addDataSetThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetOrganizationList),
    fork(watcherGetOrganizationById),
    fork(watcherGetDataSetListByOrganization),
    fork(watcherGetDataSetList),
    fork(watcherPublishDataSetSubscription),
    fork(watcherCreateGISLayer),
    fork(watcherRemoveDataSetSubscription),
    fork(watcherAddDataSetList),
  ]);
}
