import React from 'react';
import PropTypes from 'prop-types';
import RemoveHoc from '../../helpers/RemoveComponent';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import Device from '../../../models/Device';
import SendCommandHoc from '../SendCommand';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const View = (
  <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

// const Clone = <FormattedMessage
// id="options.clone"
// defaultMessage="clone"
// description="Title of clone"
// />;

const SendCommand = (
  <FormattedMessage
    id="options.sendCommand"
    defaultMessage="send command"
    description="Title of send command"
  />
);

const OptionsForRow = ({ rowContent, viewClick }) => {
  const ButtonOpenRemove = ({ showModal }) => (
    <li
      onClick={showModal}
      onKeyPress={() => {}}
      role="presentation"
    >
      {Delete}
    </li>
  );

  ButtonOpenRemove.propTypes = {
    showModal: PropTypes.string,
  };

  ButtonOpenRemove.defaultProps = {
    showModal: '',
  };

  const ButtonOpenSendCommand = ({ showModal }) => (
    <li
      onClick={showModal}
      onKeyPress={() => {}}
      role="presentation"
    >
      {SendCommand}
    </li>
  );

  ButtonOpenSendCommand.propTypes = {
    showModal: PropTypes.string,
  };

  ButtonOpenSendCommand.defaultProps = {
    showModal: '',
  };

  // const ResetDOM = ResetDevicePasword(ButtonOpenReset);
  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Device, nameData: 'devices' });
  const SendCommandDOM = SendCommandHoc(ButtonOpenSendCommand, { class: Device, nameData: 'devices' });

  const sendCommand = (
    <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission="send-command">
      <SendCommandDOM rowContent={rowContent} />
    </PrivateComponent>
  );

  const update = (
    <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission="update">
      <li
        onClick={() => { viewClick(rowContent); }}
        onKeyPress={() => {}}
        role="presentation"
        state={{ device: rowContent, mode: 'edit' }}
      >
        {Edit}
      </li>
    </PrivateComponent>
  );

  const deleteoption = (
    <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission="delete">
      <RemoveDOM rowContent={rowContent} />
    </PrivateComponent>
  );

  return (
    <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission={['delete', 'update', 'send-command', 'devices']}>
      <div className="tableMenu">
        <ul className="list-unstyled">
          {update}
          {sendCommand}
          {deleteoption}
        </ul>
      </div>
    </PrivateComponent>

  );
};

OptionsForRow.propTypes = {
  rowContent: PropTypes.string,
  viewClick: PropTypes.string,
};

OptionsForRow.defaultProps = {
  rowContent: '',
  viewClick: '',
};

export default OptionsForRow;
