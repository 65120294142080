import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import AssignPermissions from '../../../helpers/AssignPermissions';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import './styles.scss';
import UsersGroup from "../../../../models/UsersGroup";

export class AddPermissions extends React.Component {
  render() {
    return (
      <>
        <Container>
          <div className="wizardWrapper" id={'addPermissions'}>
            <Row>
              <Col sm={{ size: 8, offset: 2 }}>
                <AssignPermissions {...this.props} entity={UsersGroup}/>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

export default injectIntl(withWizardStep(AddPermissions));
