import { categories, Components } from '../../utilsConfigurationSheet';

export const ImageConfigurationSheetBasic = {

  image: [
    {
      name: 'imageSelected',
      component: Components.selectImage,
      categories: [categories.APPEARANCE],
    },
  ],

  color: [
    {
      name: 'colorSelected',
      component: Components.selectColor,
      categories: [categories.COLORS],
    },
  ],

};

export const ImageConfigurationSheet = {
  image: [
    {
      name: 'imageSelected',
      component: Components.selectImage,
      categories: [categories.APPEARANCE],
    },
  ],

  color: [
    {
      name: 'colorSelected',
      component: Components.selectColor,
      categories: [categories.COLORS],
    },
  ],

};

// eslint-disable-next-line no-unused-vars
export const validationImage = (config) => null;
