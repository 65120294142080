/* eslint-disable import/no-cycle */
import {
  calculateSimpleLastValue,
} from '../../../../utils';
import { calculateAggregateValues } from '../../utils';

import KpiWidget from '../../../kpi';
import { KpiDefaultConfigurationForLinked } from '../../../kpi/utils/defaultConfiguration';
import formatToLineWidget from '../../../lines/utils/formatData';
import { getUrnId } from '../../../../../../helpers/utils';
import { KpiConfigurationSheetForLinked } from './utils/configurationSheet';

export default class LinkedKpi extends KpiWidget {
  defaultConfig = ({ ...KpiDefaultConfigurationForLinked })

  getConfigurationSheet = () => KpiConfigurationSheetForLinked;

  getQueryHistorical(selection) {
    const {
      sampling, startDate, endDate, attributeFilter,
    } = this.config.data;
    const dataForQueries = this.getEmptyHistoricalBody();
    const sourcesFilter = this.sources
      .filter((o) => selection.includes(getUrnId(o.urn)))
      .map((o) => {
        o.fields = o.fields.filter((f) => attributeFilter.includes(f));
        return o;
      })
      .filter((o) => o.fields.length);
    const historialQueryBody = this.getDataForQueries({
      sources: sourcesFilter,
      queryType: 'aggregate',
      sampling,
      periodTime: {
        startDate,
        endDate,
      },
    });
    const sources = this.getDataForQueries({ sources: sourcesFilter });
    dataForQueries[0].sources = sources;
    dataForQueries.push(historialQueryBody);
    return dataForQueries;
  }

  formatToData = (values, sources, config, selection, intl) => {
    const widget = this.getData();
    const { operation, attributeFilter } = config.data;
    const data = sources.find((s) => s.id === selection[0]);
    const existProperty = data.attributes.find((a) => a.name === attributeFilter[0]);
    if (existProperty) {
      if (values?.length) {
        const attributeOfWidget = config.data.attributeFilter[0];
        if (selection.length === 1) {
          const sourceData = values
            .filter((d) => d.urn)
            .find(
              (source) => getUrnId(source?.urn) === selection[0]
              && source.attrName === attributeOfWidget,
            );
          if (sourceData) {
            return [sourceData.value.value, formatToLineWidget(values, config)];
          }
          return [
            intl.formatMessage({ id: 'widget.status.loading' }),
            formatToLineWidget(null, config),
          ];
        }
        if (selection.length > 1) {
          const aggregateValues = calculateAggregateValues(
            widget,
            values,
          );
          const calculatedLastValue = calculateSimpleLastValue(
            values,
            attributeOfWidget,
            operation,
            selection,
            3,
          );
          if (!aggregateValues && calculatedLastValue >= 0) {
            return [calculatedLastValue, formatToLineWidget(null, config)];
          }
          if (aggregateValues) {
            return [
              calculatedLastValue,
              formatToLineWidget(aggregateValues, config, operation, undefined, operation),
            ];
          }
          return [
            intl.formatMessage({ id: 'widget.status.noData' }),
            formatToLineWidget(null, config),
          ];
        }
      }
      return [
        intl.formatMessage({ id: 'widget.status.loading' }),
        formatToLineWidget(widget.origins, config),
      ];
    }
    return [
      intl.formatMessage({ id: 'widget.status.noData' }),
      formatToLineWidget(null, config),
    ];
  }
}
