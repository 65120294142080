export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';

export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR';

export const GET_PERMISSION_PERMISSIONS_REQUEST = 'GET_PERMISSION_PERMISSIONS_REQUEST';
export const GET_PERMISSION_PERMISSIONS_SUCCESS = 'GET_PERMISSION_PERMISSIONS_SUCCESS';
export const GET_PERMISSION_PERMISSIONS_ERROR = 'GET_PERMISSION_PERMISSIONS_ERROR';

export const ADD_PERMISSION_REQUEST = 'ADD_PERMISSION_REQUEST';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_ERROR = 'ADD_PERMISSION_ERROR';
export const ADD_PERMISSION_SUCCESS_FLAG = 'ADD_PERMISSION_SUCCESS_FLAG';



export const MODIFY_PERMISSION_REQUEST = 'MODIFY_PERMISSION_REQUEST';
export const MODIFY_PERMISSION_SUCCESS = 'MODIFY_PERMISSION_SUCCESS';
export const MODIFY_PERMISSION_ERROR = 'MODIFY_PERMISSION_ERROR';
export const MODIFY_PERMISSION_SUCCESS_FLAG = 'MODIFY_PERMISSION_SUCCESS_FLAG';


export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_ERROR = 'DELETE_PERMISSION_ERROR';

export const ERRORFETCHING_PERMISSION_RESET = 'ERRORFETCHING_PERMISSION_RESET';


export const GET_PERMISSION = 'GET_PERMISSION';


