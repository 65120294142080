import { Map, List } from 'immutable';
import * as TYPES from './types';
import MenuElement from '../../../models/MenuElement';

/** Initial State of MenuElements */
const initialState = Map({
  root: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  menuElementsSelected: [],
  hiddenSubmenu: true,
  settingsMenu: false,
  fetchingHome: false,
});

const updateCascade = (element, menuElements) => {
  let selected = element;

  const newMenuElements = [];
  menuElements.forEach((menuElement, index) => {
    if (index === 0) {
      menuElement.children = element;
      selected = new MenuElement(menuElement);
      newMenuElements.push(selected);
    } else {
      menuElement.children = menuElement.children.map((o) => (selected.id === o.id ? selected : o));
      selected = new MenuElement(menuElement);
      newMenuElements.push(selected);
    }
  });
  return newMenuElements;
};

const updatePosition = (elements, menuElement) => menuElement.children.map((o) => {
  const el = elements.object.find((e) => e.id === o.id);
  return {
    ...o,
    position: el ? el.position : o.position,
  };
});

const updateRootPosition = (elements, menuElement) => menuElement._tail.array.map((o) => {
  const el = elements.object.find((e) => e.id === o.id);
  return {
    ...o,
    position: el ? el.position : o.position,
  };
});

/** MENUELEMENTS REDUCER */
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let menuElementsSelected;
  let menuElementSelectedUpdated;
  let menuElementUpdated;
  let elementRoot;
  let root;
  let rootElementsSelected;
  let menuRootUpdated;
  let newElementRoot;

  /** Cases */
  switch (type) {
    case TYPES.GET_TREE_SUCCESS:
      return state.set('root', List([...payload.roots])).set('fetching', false);

    /** GET MENUELEMENT FROM DB TO STATE */
    case TYPES.GET_MENUELEMENT_SUCCESS:
      // TODO
      return console.log('TODO');

      /// /////////////////////////////////////////////////////
      /// ///// ADD AN MENUELEMENT
      /// /////////////////////////////////////////////////////

    /** ADD NEW MENUELEMENT TO STATE */
    case TYPES.ADD_MENUELEMENT_SUCCESS:
      const menuElementAdded = payload;
      menuElementAdded.position = -1;

      menuElementsSelected = state.get('menuElementsSelected');
      if (menuElementAdded.parent) {
        menuElementsSelected[0].children = [menuElementAdded, ...menuElementsSelected[0].children];
        menuElementSelectedUpdated = updateCascade(menuElementsSelected[0].children, menuElementsSelected);
        elementRoot = menuElementSelectedUpdated[menuElementSelectedUpdated.length - 1];
        root = state.get('root').map((o) => (o.id !== elementRoot.id ? o : elementRoot));
      } else {
        root = state.get('root').push(menuElementAdded);
        menuElementSelectedUpdated = menuElementsSelected;
      }

      return state
        .set('root', root)
        .set('menuElementsSelected', menuElementSelectedUpdated)
        .set('fetching', false)
        .set('addSuccess', true)
        .set('menuElementAdded', true)
        .set('menuElementDeleted', false);

      /// /////////////////////////////////////////////////////
      /// ///// UPDATE AN MENUELEMENT
      /// /////////////////////////////////////////////////////

    /** UPDATE MENUELEMENT IN STATE */
    case TYPES.MODIFY_MENUELEMENT_SUCCESS:
      const menuElementEdited = payload;

      const assignChildren = (object, menuEdited) => {
        const isEqualId = object.id === menuEdited.id;

        if (isEqualId) menuEdited.children = object.children;

        return !isEqualId ? object : menuEdited;
      };

      menuElementsSelected = state.get('menuElementsSelected');
      if (menuElementEdited.parent) {
        menuElementsSelected[0].children = menuElementsSelected[0].children.map(
          (o) => assignChildren(o, menuElementEdited),
        );
        menuElementSelectedUpdated = updateCascade(
          menuElementsSelected[0].children,
          menuElementsSelected,
        );
        elementRoot = menuElementSelectedUpdated[menuElementSelectedUpdated.length - 1];
        root = state.get('root').map((o) => (o.id !== elementRoot.id ? o : elementRoot));
      } else {
        root = state.get('root').map((o) => assignChildren(o, menuElementEdited));
        menuElementSelectedUpdated = menuElementsSelected;
      }

      return state
        .set('root', root)
        .set('menuElementsSelected', menuElementSelectedUpdated)
        .set('fetching', false)
        .set('addSuccess', true)
        .set('menuElementAdded', false)
        .set('menuElementDeleted', false);

      /// /////////////////////////////////////////////////////
      /// ///// UPDATE AN POSITION ELEMENT
      /// /////////////////////////////////////////////////////

    /** UPDATE POSITION ELEMENT IN STATE */
    case TYPES.MODIFY_POSITION_ELEMENT_SUCCESS:
      menuElementsSelected = state.get('menuElementsSelected');
      rootElementsSelected = state.get('root');
      if(menuElementsSelected.length){
        menuElementUpdated = updatePosition(payload, menuElementsSelected[0]);
        menuElementSelectedUpdated = updateCascade(
        menuElementUpdated,
        menuElementsSelected,
        );
        elementRoot = menuElementSelectedUpdated[menuElementSelectedUpdated.length - 1];
        menuRootUpdated = updateRootPosition(payload, rootElementsSelected);
        newElementRoot = menuRootUpdated.find((m) => m.id === elementRoot.id);
        rootElementsSelected._tail.array = menuRootUpdated;
        root = rootElementsSelected.map((o) => (o.id !== newElementRoot.id ? o : newElementRoot));
      } else {
        menuRootUpdated = updateRootPosition(payload, rootElementsSelected);
        rootElementsSelected._tail.array = menuRootUpdated;
        root = rootElementsSelected;
        menuElementSelectedUpdated = menuElementsSelected;
      }
      
      return state
        .set('root', root)
        .set('menuElementsSelected', menuElementSelectedUpdated)
        .set('fetching', false)
        .set('modifySuccess', true);

      /// /////////////////////////////////////////////////////
      /// ///// DELETE AN MENUELEMENT
      /// /////////////////////////////////////////////////////

    /** DELETE MENUELEMENT IN STATE */
    case TYPES.DELETE_MENUELEMENT_SUCCESS:
      menuElementsSelected = state.get('menuElementsSelected');
      if (
        menuElementsSelected.length > 0
        && menuElementsSelected[0].children.find((o) => o.id === payload.id)
      ) {
        menuElementUpdated = menuElementsSelected[0].children.filter(
          (o) => o.id !== payload.id,
        );
        menuElementSelectedUpdated = updateCascade(
          menuElementUpdated,
          menuElementsSelected,
        );
        elementRoot = menuElementSelectedUpdated[menuElementSelectedUpdated.length - 1];
        root = state
          .get('root')
          .map((o) => (o.id !== elementRoot.id ? o : elementRoot));
      } else {
        root = state.get('root');
        root = root.filter((o) => o.id !== payload.id);
        menuElementSelectedUpdated = menuElementsSelected;
      }

      return state
        .set('root', root)
        .set('menuElementsSelected', menuElementSelectedUpdated)
        .set('fetching', false)
        .set('menuElementAdded', false)
        .set('menuElementDeleted', true);

    /// /////////////////////////////////////////////////////
    /// ///// RESET ERROR FETCHING TO FALSE
    /// /////////////////////////////////////////////////////
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_MENUELEMENT_RESET:
      return state
        .set('errorFetching', false)
        .set('menuElementAdded', false)
        .set('menuElementDeleted', false);

      /// /////////////////////////////////////////////////////
      /// ///// FETCHING
      /// /////////////////////////////////////////////////////

    /** INITIALIZE GET MENUELEMENTS FROM DB */
    /** INITIALIZE GET MENUELEMENTS FROM DB */
    /** INITIALIZE GET MENUELEMENT ROLES FROM DB */
    /** INITIALIZE ADD MENUELEMENT TO DB AND STATE */
    /** INITIALIZE UPDATE MENUELEMENT TO DB */
    /** INITIALIZE DELATION OF A MENUELEMENT IN DB */
    case TYPES.GET_TREE_REQUEST:
    case TYPES.ADD_MENUELEMENT_REQUEST:
    case TYPES.MODIFY_MENUELEMENT_REQUEST:
    case TYPES.MODIFY_POSITION_ELEMENT_REQUEST:
    case TYPES.GET_SETTINGS_MENU_REQUEST:
    case TYPES.CLEAR_HOME_REQUEST:
    case TYPES.SET_HOME_REQUEST:
    case TYPES.UPDATE_SETTINGS_MENU_REQUEST:
    case TYPES.DELETE_MENUELEMENT_REQUEST:
      return state
        .set('fetching', true)
        .set('menuElementAdded', false)
        .set('menuElementDeleted', false);

    /** SET AS FALSE THE addSuccess STATE FLAG */
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.ADD_MENUELEMENT_SUCCESS_FLAG:
    case TYPES.MODIFY_MENUELEMENT_SUCCESS_FLAG:
      return state
        .set('fetching', false)
        .set('menuElementAdded', true)
        .set('menuElementDeleted', false);

      /// /////////////////////////////////////////////////////
      /// ///// ERRORS
      /// /////////////////////////////////////////////////////

    /** INITIALIZE GET MENUELEMENT FROM DB */
    /** INITIALIZE GET MENUELEMENT FROM DB ERROR */
    /** INITIALIZE GET MENUELEMENT ROLES FROM DB ERROR */
    /** INITIALIZE ERROR ADD MENUELEMENT */
    /** UPDATE MENUELEMENT ERROR */
    /** INITIALIZE DELATION OF AN MENUELEMENT IN DB */
    case TYPES.GET_MENUELEMENT_ERROR:
    case TYPES.ADD_MENUELEMENT_ERROR:
    case TYPES.MODIFY_MENUELEMENT_ERROR:
    case TYPES.MODIFY_POSITION_ELEMENT_ERROR:
    case TYPES.GET_SETTINGS_MENU_ERROR:
    case TYPES.GET_HOME_ERROR:
    case TYPES.CLEAR_HOME_ERROR:
    case TYPES.SET_HOME_ERROR:
    case TYPES.UPDATE_SETTINGS_MENU_ERROR:
    case TYPES.DELETE_MENUELEMENT_ERROR:
      return state
        .set('errorFetching', payload)
        .set('fetching', false)
        .set('menuElementAdded', false)
        .set('menuElementDeleted', false);

    /// /////////////////////////////////////////////////////
    /// ///// CLEARS
    /// /////////////////////////////////////////////////////
    case 'CLEAR_STATE':
    case TYPES.CLEAR_MENUELEMENTS:
      return initialState;

    case TYPES.ADD_MENUELEMENT_SELECTED_STATE:
      menuElementsSelected = state.get('menuElementsSelected');
      const existMenuElement = !!menuElementsSelected.find(
        (o) => o.id === payload.id,
      );
      return existMenuElement
        ? state
        : state.set('menuElementsSelected', [payload, ...menuElementsSelected]);

    case TYPES.UPDATE_MENUELEMENTS_SELECTED_STATE:
      return state.set('menuElementsSelected', [...payload]);

    case TYPES.CLEAR_MENUELEMENTS_SELECTED_STATE:
      return state.set('menuElementsSelected', []);

    case TYPES.HIDDEN_SUBMENU:
      return state.set('hiddenSubmenu', payload);

    case TYPES.GET_SETTINGS_MENU_SUCCESS:
      return state.set('settingsMenu', payload);

    case TYPES.GET_HOME_REQUEST:
      return state.set('fetchingHome', true);

    case TYPES.GET_HOME_SUCCESS:
      return state.set('homeMenu', payload).set('fetchingHome', false);

    case TYPES.SET_HOME_SUCCESS:
      // TODO: give any feedback to user
      return state.set('settingsMenu', payload);

    case TYPES.CLEAR_HOME_SUCCESS:
      return state.set('settings', payload);
      /// /////////////////////////////////////////////////////
      /// ///// DEFAULT
      /// /////////////////////////////////////////////////////

    default:
      return state;
  }
};
