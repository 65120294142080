import React from 'react';
import { PropTypes } from 'prop-types';

import Wizard from '../../../components/Wizard';
import WizardStep from '../../../components/Wizard/components/WizardStep';

// Steps
import Starter from './steps/Starter';
import ConfigureProtocol from '../../helpers/Add/ConfigureProtocol';
import AddProperties from '../../helpers/Add/AddProperties';
import Success from './steps/Success';

import AddTableButton from '../../../components/AddTableButton';
import ModalHOC from '../../../elements/Modal';

import { FormattedMessage } from '../../../Contexts/LanguageContext';
import AssignPermissions from '../../helpers/Add/AssignPermissions';
import CreateEntity from "../../helpers/Add/CreateEntity";
import Service from "../../../models/Service";
import saveEntity from '../../helpers/Add/utils/saveEntity';
import { ButtonCustom } from '../../../elements/Button';
import { isMobile } from '../../../helpers/getBreakpoint';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent'


const addServicesLabel = (
  <FormattedMessage
    id="services.add.title"
    defaultMessage="add services"
    description="Label of add services"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile())
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/services'}
        permission={'create'}
      >
        <ButtonCustom type={'circular'} name={'plus'} onClick={showModal} />
      </PrivateComponent>
    );
  else
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/services'}
        permission={'create'}
      >
        <AddTableButton onClick={showModal} label={addServicesLabel} />
      </PrivateComponent>
    );
};

class CreateServices extends React.Component {
  render() {
    return (
      <Wizard 
        buttonOpen={ButtonOpen}
        entity={Service}
        metadata={{
          wizardType: 'services',
        }}
      >
        <WizardStep
          component={Starter}
          title={<FormattedMessage id="services.wizard.step1.title" />}
          lead={<FormattedMessage id="services.wizard.step1.text" />}
          entity={Service}
        />
         <WizardStep
          component={CreateEntity}
          title={<FormattedMessage id="services.wizard.step2.title" />}
          lead={<FormattedMessage id="services.wizard.step2.text" />}
          entity={Service}
        />
        <WizardStep
          component={ConfigureProtocol}
          title={<FormattedMessage id="services.wizard.step3.title" />}
          lead={<FormattedMessage id="services.wizard.step3.text" />}
          entity={Service}
        />

        <WizardStep
          component={AddProperties}
          title={<FormattedMessage id="services.wizard.step4.title" />}
          lead={<FormattedMessage id="services.wizard.step4.text" />}
          entity={Service}
        />
        <WizardStep
          component={AssignPermissions}
          title={<FormattedMessage id="services.wizard.step5.title" />}
          lead={<FormattedMessage id="services.wizard.step5.text" />}
          entity={Service}
          onSave={saveEntity}
        />
       
       <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateServices;
