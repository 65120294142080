import Faker from 'faker';
import {
  exampleFiware, exampleProtocol, exampleTransport, examplePermissionPolicy,
} from '../redux/etlProcedures/reducer';
import { runtimeManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = runtimeManagement.urlAPI.concat(runtimeManagement.paths.etl);

const queryPageParam = 'x-query-page';
const querySizeParam = 'x-query-size';

const exampleBodyFn = (data = null, numberOfETLProcedureToGenerate = 50) => {
  const ETLProceduresFaker = [];
  for (let i = 0; i < numberOfETLProcedureToGenerate; i += 1) {
    ETLProceduresFaker.push({
      id: Faker.random.uuid(),
      name: Faker.random.word(),
      permissions_policy: Faker.random.arrayElement(examplePermissionPolicy),
      owner: Faker.random.uuid(),
      entity_type: Faker.random.word(),
      fiware: Faker.random.arrayElement(exampleFiware),
      transport: Faker.random.arrayElement(exampleTransport),
      attributes: [],
      lazy_attributes: [],
      command_attributes: [],
      static_attributes: [],
      internal_attributes: [],
      protocol: Faker.random.arrayElement(exampleProtocol),
    });
  }
  const etlProcedures = { etlProcedures: ETLProceduresFaker };
  return etlProcedures;
};

/** * First version of ETLProcedure CRUD */
// PLURAL
export const readETLProcedures = async (page, size, filters, ckan, datasources) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';
  const queryCKAN = ckan === true ? '&ckanSubscribed=true' : '&ckanSubscribed=false';
  const queryFilters = filters ? parseFilters(filters) : '';

  const url = `${endPoint}/list/all`;
  const params = {
    endPoint: `${url}?${queryPage}&${querySize}${queryFilters}${
      ckan === undefined ? '' : queryCKAN
    }`,
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };
  return requestAPI(params, exampleBodyFn);
};

export const readMyETLProcedures = async (page, size, filters) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };
  return requestAPI(params, exampleBodyFn);
};

/** * First version of ETLProcedure CRUD */
// PLURAL
export const readPublicETLProcedures = async (page, size, filters) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/all?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };

  return requestAPI(params, exampleBodyFn);
};

// SINGULAR
export const readETLProcedure = async (etlProcedureId) => {
  if (!etlProcedureId.id) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${etlProcedureId.id}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'etlProcedure',
  };

  const answer = await requestAPI(params, exampleBodyFn);

  return answer;
};

export const readPublicETLProcedure = async (etlProcedureId) => {
  if (!etlProcedureId.id) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${etlProcedureId.id}/public`,
    statusOK: 200,
    toJSON: true,
    returnData: 'etlProcedure',
  };

  const answer = await requestAPI(params, exampleBodyFn, true);

  return answer;
};

export const addETLProcedure = async (etlProcedure) => {
  if (etlProcedure.id) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'etlProcedure',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(etlProcedure),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  return requestAPI(params, exampleBodyFn);
};

export const updateETLProcedure = (etlProcedure) => {
  if (!etlProcedure.id) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: `${endPoint}/${etlProcedure.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: etlProcedure,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(etlProcedure),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const deleteETLProcedure = (etlProcedure) => {
  if (!etlProcedure.id) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint}/${etlProcedure.id}`,
    statusOK: 204,
    toJSON: false,
    returnData: etlProcedure,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const verifyETLProcedure = (etlProcedure) => {
  if (!etlProcedure.id) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint}/${etlProcedure.id}/verify`,
    statusOK: 200,
    toJSON: false,
    returnData: 'etlProcedure',
    requestConfig: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const executeETLProcedure = (etlProcedure) => {
  if (!etlProcedure.id) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint}/${etlProcedure.id}/executions/launch`,
    statusOK: 204,
    toJSON: false,
    returnData: 'etlProcedure',
    requestConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const getETLExecutions = (page, size, filters, id) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';

  const params = {
    endPoint: `${endPoint}/${id}/executions/list/all?`.concat(queryPage, '&', querySize),
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };

  return requestAPI(params, exampleBodyFn);
};
