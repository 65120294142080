import React from 'react';
import { connect } from 'react-redux';
import CreateDeviceFromScratch from '../../../../configuration/icons/svg/create-device-scratch.svg';
import CreateDeviceFromTemplate from '../../../../configuration/icons/svg/create-device-template.svg';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Field from '../../../../elements/Field';
import { getTemplates, clearTemplates } from '../../../../services/redux/templates/actions';
import StarterComponent from '../../../helpers/StarterPage';
import CommonView from '../../../CommonView';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import '../styles.scss';

const ATTRIBUTE = 'attribute';
const LAZY = 'lazy';
const COMMAND = 'command';
const STATIC = 'static';

class Starter extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      template: null,
      page: 1,
      size: 5,
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    this.props.onRef(this);
    getTemplates({ page, size });
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size } = this.state;
    if (!this.props.fetching && prevState.page < page) {
      getTemplates({ filters: [], page, size });
    }
  }

getOptions = () => {
  const { templates } = this.props;

  return templates && templates.map(this.mapToSelect);
};

mapToSelect = (o) => ({ name: o.name, value: o });

handleOnClick = () => {
  this.props.onContinue();
};

validate = () => {
  this.loadEntity();
};

handleFetchInfo = () => {
  const { total, templates } = this.props;
  if (total > templates.length) {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }
};

handleOnSearch = (value, init = false) => {
  const { size } = this.state;
  if (init) {
    clearTemplates();
  }
  getTemplates({ filters: { name: value }, page: 1, size });
};

loadEntity = () => (!this.state.template
  ? this.props.onContinue()
  : this.props.onContinue(this.parseEntity({ ...this.state.template })));

parseEntity = (template) => {
  const entity = { ...template };
  entity.template_id = template.id;
  entity.fiware = template.fiware;
  entity.categories = template.categories;
  delete entity.id;
  const attributes = template.attributes.map((o) => ({
    property: ATTRIBUTE,
    ...o,
  }));
  const lazy = template.lazy_attributes.map((o) => ({ property: LAZY, ...o }));
  const command = template.command_attributes.map((o) => ({
    property: COMMAND,
    value: o.available_commands,
    ...o,
  }));
  const staticAttribute = template.static_attributes.map((o) => ({
    property: STATIC,
    ...o,
  }));

  // eslint-disable-next-line new-cap
  const newEntity = new this.props.entity(entity);

  newEntity.allAttributes = [
    ...attributes,
    ...lazy,
    ...command,
    ...staticAttribute,
  ];

  return newEntity;
};

render() {
  const { templates, fetching } = this.props;
  const { template } = this.state;

  return (
    <StarterComponent className="devicesStarter">
      <div className="d-flex flex-column flex-md-row align-items-center mainContent">
        <div
          className="cardButton"
          onClick={this.handleOnClick}
          role="presentation"
        >
          <img
            src={CreateDeviceFromScratch}
            alt="create device from scratch"
          />
          <h3>
            <FormattedMessage id="devices.wizard.create.from.scratch.title" />
          </h3>
          <p>
            <FormattedMessage id="devices.wizard.create.from.scratch.text" />
          </p>
        </div>
        <div className="verticalDivisor" />
        <div>
          <img
            src={CreateDeviceFromTemplate}
            alt="create device from template"
          />
          <h3>
            <FormattedMessage id="devices.wizard.create.from.template.title" />
          </h3>
          <p>
            <FormattedMessage id="devices.wizard.create.from.template.text" />
          </p>
          <Field
            type="select"
            name="template"
            options={(templates && this.getOptions(templates)) || []}
            onChange={this.handleSelectChange}
            value={template}
            async
            fetchInfo={this.handleFetchInfo}
            loading={fetching}
            onSearch={this.handleOnSearch}
            filterBy="name"
          />
        </div>
      </div>
    </StarterComponent>
  );
}
}

const mapStateToProps = (state) => ({
  templates: state
    .get('templates')
    .get('list')
    .toJS(),
  total: state.get('templates').get('total'),
  fetching: state.get('templates').get('fetching'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(Starter));
