import React from 'react';
import CheckBox from '../../../../elements/CheckBox';
import Menu from '../../../../components/Menu';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

const openButton = props =>{
  const disabled = props.permissionToEdit ? '':'disabled';
  return (
  <button className={`btn btn-default ${disabled}`} disabled={disabled} onClick={props.onClick}>
    {props.write ? (
      <i className="uil uil-edit-alt"/>
    ) : (
      <i className="uil uil-eye"/>
    )}
    {props.permissionToEdit && <i className="uil uil-angle-down"/>}
  </button>
)};

const AddPermissionMenu = props => (
  <Menu
    top={'40px'}
    openComponent={openButton}
    openFrom={'top right'}
    className={'morePermissions'}
    write={props.write}
    permissionToEdit={props.permissionToEdit}
  >
    {props.children}
  </Menu>
);

const AddPermissionButton = props => {
  const { write, onClick, user, permissionToEdit } = props;

  return (
    <AddPermissionMenu write={write} permissionToEdit={permissionToEdit}>
      <ul>
        {['read', 'write'].map((key, i) => (
          <li key={i}>
            <CheckBox
              checked={props[key]}
              onClick={user ? () => onClick(key, user) : () => onClick(key)}
              fixed={key === 'read'}
              label={
                <FormattedMessage id={`ListOfMembers.${key}.permission`} />
              }
              reverse={false}
              minimal={true}
            />
          </li>
        ))}
      </ul>
    </AddPermissionMenu>
  );
};

export default AddPermissionButton;
