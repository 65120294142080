import React from 'react';
import './styles.scss';
import SubMenuNav from '../MenuNav/SubMenuNav';
import getBreakpoint from '../../helpers/getBreakpoint';

class SecondNav extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', () => true);
    window.addEventListener('scroll', () => true);
  }

  isMobile = () => ['xs', 'sm'].includes(getBreakpoint());

  getWidthAside = () => {
    const aside = document.getElementById('aside');
    return !aside ? '-200px' : ''.concat(aside.offsetWidth + 30, 'px');
  };

  getHeightAside = () => {
    const aside = document.getElementById('aside');
    return !aside ? '-200px' : ''.concat(aside.offsetHeight, 'px');
  };

  getStyle = () => {
    if (!this.isMobile()) {
      return {
        left: this.getWidthAside(),
        height: this.getHeightAside(),
        width: this.getWidthAside(),
      };
    }
    return {
      left: '0px', top: '104px', height: 'calc(100vh - 104px)', width: '100vw',
    };
  }

  render() {
    return (
      <div id="subAside" style={this.getStyle()}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <SubMenuNav {...this.props} />
      </div>
    );
  }
}

export default (SecondNav);
