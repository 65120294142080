// ToDo: remove this file for v2
import React from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { clone, isEmpty } from 'ramda';
import CommonView from '../../../../../../CommonView';
import { withWizardStep } from '../../../../../../../Contexts/WizardStepContext';
import WidgetSliderAttributes from '../../../../../Profile/newcomponents/Commands/Slider/WidgetSliderAttributes';
import Field from '../../../../../../../elements/Field';
import { getDefaultConfig } from '../../../../../../../models/Widget/configurationSheets';
import { FormattedMessage } from '../../../../../../../Contexts/LanguageContext';
import Widget from '../../../../../../../models/Widget';
import WidgetSliderPoints from '../../../../../Profile/newcomponents/Commands/Slider/WidgetSliderPoints';

const getCommandsGroup = (config) => {
  const commandsGroups = {};
  config.conditions.numberOfPoints.forEach((p) => {
    commandsGroups[p.state] = {};
  });
  return commandsGroups;
};
class ConfigureWidgetSlider extends CommonView {
  constructor(props) {
    super({ props });
    const { newEntity } = this.props;

    this.state = {
      second_state: false,
      page: 1,
      size: 5,
      devices: newEntity.origins,
      commandsToShow: [],
      attributesSelected: '',
      commandSelected: '',
      offRowsNumber: 0,
      onRowsNumber: 0,
      config: props.config ?? getDefaultConfig(newEntity.widgetType),
      availableCommands: [
        {
          state: 1,
          commands: [],
        },
        {
          state: 2,
          commands: [],
        }
      ],
      localErrors: {
        device: [],
        attribute: [],
        command: [],
      },
      showModalCommandsInGroup: false,
      allAttributes: [],
      commandsGroups: getCommandsGroup(props.config ??
        getDefaultConfig(this.props.newEntity.widgetType)),
    };
  }

  handleCommandGroup = (commands, state) => {
    const newList = clone(this.state.commandsGroups);
    const { devices } = this.state;
    devices.forEach((device) => {
      device.command_attributes.forEach((att, index) => {
        const found = commands.find((at) => at.commandName === att.name);
        if (found) {
          const obj = {
            commandName: found.commandName,
            devicesId: [device.id],
            value: found.value,
            groupId: new Date().getTime() + index,
          };

          const commandFound = newList[state].length && newList[state].find((c) => c.commandName === found.commandName
          && c.value === found.value)

          if (commandFound) {
            commandFound.devicesId.push(device.id);
          } else if (Object.keys(newList[state]).length) {
            newList[state].push(obj);
          } else newList[state] = [obj];
        }
      });
    });
    this.setState({ commandsGroups: newList });
  }

    handleEditCommandsGroup = (point, state) => {
      const list = clone(this.state.commandsGroups);
      const index = list[state].findIndex((c) => c.groupId === point.groupId);
      if (index >= 0) {
        if (point.commandName !== list[state][index].commandName) {
          delete list[state][index];
        }
        list[state][index] = point;
      }
      this.setState({ commandsGroups: list });
    };

    handleDeleteCommandsGroup = (group, state, index) => {
      const list = clone(this.state.commandsGroups);
      list[state].splice(index, 1);
      this.setState({
        commandsGroups: list,
      });
    }

    handleDeleteIndividualCommand = (commands, state) => {
      const list = clone(this.state.commandsGroups);
      const commandIndex = list[state].findIndex((c) => c.groupId === commands.groupId);
      list[state][commandIndex] = commands;
      this.setState({
        commandsGroups: list,
      });
    };

    handleChange = (name, value, index, state, availableCommands) => {
      const commands = value?.available_commands?.map((c) => c);
      const configClone = clone(this.state.config);
      const commandsToShowClone = clone(this.state.commandsToShow);
      const availableCommandsClone = clone(this.state.availableCommands);
      if (name === 'device') {
        configClone.conditions.numberOfPoints.forEach((point) => {
          if (point.state === state) {
            const aux = point;
            aux.commands[index].devicesId = [value.id];
            aux.commands[index].commandName = '';
            aux.commands[index].value = '';
          }
        });
        commandsToShowClone[value.id] = value.command_attributes;

        this.setState({
          commandsToShow: commandsToShowClone,
          config: configClone,
        });
      }
      if (name === 'attribute') {
        configClone.conditions.numberOfPoints.forEach((point) => {
          if (point.state === state) {
            const aux = point;
            aux.commands[index].commandName = value.name;
            aux.commands[index].value = '';
          }
        });
        const found = availableCommandsClone.find((c) => c.state === state).commands[index];
        if (found) availableCommandsClone.find((c) => c.state === state).commands[index] = commands;
        else availableCommandsClone.find((c) => c.state === state).commands.push(commands);  
        this.setState({
          availableCommands: availableCommandsClone,
          config: configClone,
        });
      }
      if (name === 'command') {
        let indexOfCommand = 0;
        let addCommand = false;
        const commandsState = availableCommandsClone.find((c) => c.state === state).commands;
        const v = commandsState[commandsState.length - 1].find((co) => co.name === value)?.value;
        let val = v || value;
        configClone.conditions.numberOfPoints.forEach((point) => {
          if (point.state === state) {
            const aux = point;
            availableCommands.forEach((p) => {
              if (p.state === state) {
                p.commands.forEach((c) => {
                  c.forEach((v) => {
                    if (v.name === value) {
                      val = v.value;
                    }
                  });
                });
              }
            });
            aux.commands[index].value = val;
            if (aux.commands[index].commandName !== ''
                && aux.commands[index].value !== ''
                && aux.commands[index].devicesId.length > 0) {
              indexOfCommand = index;
              addCommand = true;
            }
          }
        });
        this.setState({
          config: configClone,
        });
        if (addCommand) this.addCommand(indexOfCommand, state, false, configClone);
      }
    };

    componentDidMount() {
      this.props.onRef(this);
    }

  validateConmmand = (i, state, addNewCommand, config) => {
    const { localErrors } = this.state;
    const localErrorsClone = clone(localErrors);
    const configClone = config || clone(this.state.config);

    configClone.conditions.numberOfPoints.forEach((point) => {
      if (point.state === state) {
        point.commands.forEach((c, index) => {
          if (index === i) {
            if (c.devicesId.length === 0) {
              localErrorsClone.device.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.device" />],
              });
            } else {
              localErrorsClone.device = [];
            }
            if (c.commandName === '') {
              localErrorsClone.attribute.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.attribute" />],
              });
            } else {
              localErrorsClone.attribute = [];
            }
            if (c.value === '') {
              localErrorsClone.command.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.command" />],
              });
            } else {
              localErrorsClone.command = [];
            }
          }
        });
        if (!localErrorsClone.device.length
          && !localErrorsClone.command.length
          && !localErrorsClone.attribute.length
          && addNewCommand) {
          point.commands.push({
            commandName: '',
            devicesId: [],
            value: '',
          });
        }
      }
    });
    return { config: configClone, errors: localErrorsClone };
  }

  addCommand = (i, state, addNewCommand, config) => {
    const validate = this.validateConmmand(i, state, addNewCommand, config);
    this.setState({
      config: validate.config,
      localErrors: validate.errors,
    });
  };

  removeCommand = (state, index) => {
    const configClone = clone(this.state.config);
    configClone.conditions.numberOfPoints.forEach((num) => {
      if (num.state === state) {
        num.commands.splice(index, 1);
      }
    });
    this.setState({
      config: configClone,
    });
  }

  getAllAttributes = () => {
    const { allAttributes } = this.state;
    const { newEntity } = this.props;
    const { origins } = newEntity;

    const attributes = clone(allAttributes);
    origins.forEach((origin) => {
      origin.command_attributes.forEach((attribute) => {
        const exits = attributes.find((att) => att.name === attribute.name);
        if (!exits) {
          attributes.push(attribute);
        }
      });
    });
    return attributes;
  }

  validate = () => {
    const { commandsGroups, config } = this.state;
    const configClone = clone(config);
    const newEntityClone = clone(this.props.newEntity);
    // add commandsGroup to the config
    Object.keys(commandsGroups).forEach((stateCommand) => {
      Object.keys(commandsGroups[stateCommand]).forEach((index) => {
        const commandsGroupToAdd = commandsGroups[stateCommand][index];
        configClone.conditions.numberOfPoints.forEach((p) => {
          if (parseInt(stateCommand, 10) === p.state) {
            let v = '';
            if (commandsGroupToAdd.value.split(' ').length === 2) {
              const result = commandsGroupToAdd.value.split(' ')[1];
              v = result;
            } else {
              v = commandsGroupToAdd.value;
            }
            const commandAdd = {
              commandName: commandsGroupToAdd.commandName,
              devicesId: commandsGroupToAdd.devicesId,
              value: v,
              groupId: commandsGroupToAdd.groupId,
            };
            p.commands.push(commandAdd);
          }
        });
      });
    });
    configClone.conditions.numberOfPoints = configClone.conditions.numberOfPoints
      .map((p) => {
        const aux = p;
        aux.commands = aux.commands.filter((c) => (c.devicesId !== ''
        && c.commandName !== '' && c.value !== ''));
        return aux;
      });

    newEntityClone.config = configClone;
    const newWidget = new Widget(newEntityClone);
    const validator = newWidget.getValidatorConfig();
    const validation = validator(configClone);

    if (validation) {
      this.setState({ errors: validation });
    } else {
      this.props.onContinue({
        config: configClone,
        origins: this.state.devices,
      });
    }
  };

  handleDescription = (e) => {
    const configClone = clone(this.state.config);
    const { value } = e.target;
    configClone.labels.description = value;
    this.setState({ config: configClone });
  };

  handleNumberOfPoints = (points) => {
    const { config, availableCommands } = this.state;
    const configClone = clone(config);
    const availableCommandsClone = clone(availableCommands);
    const numberOfPoints = configClone.conditions.numberOfPoints.length;
    const difference = points - numberOfPoints;
    if (difference > 0) {
      for (let i = 1; i <= difference; i += 1) {
        configClone.conditions.numberOfPoints.push(
          {
            state: numberOfPoints + i,
            alias: `${numberOfPoints + i}`,
            commands: [
              {
                commandName: '',
                devicesId: [],
                value: '',
              },
            ],
          },
        );
        availableCommandsClone.push({
          state: configClone.conditions.numberOfPoints.length,
          commands: [],
        });
      }
    } else if (difference < 0) {
      const indexOfRemove = numberOfPoints + difference;
      const positiveDifference = difference * -1;
      configClone.conditions.numberOfPoints.splice(indexOfRemove, positiveDifference);
      availableCommandsClone.splice(indexOfRemove, positiveDifference);
    }
    this.setState({
      config: configClone,
      commandsGroups: getCommandsGroup(configClone),
      availableCommands: availableCommandsClone
    });
  }

  handleAlias = (value, point) => {
    const { config } = this.state;
    const configClone = clone(config);
    configClone.conditions.numberOfPoints.forEach((p) => {
      if (p.state === point) {
        p.alias = value;
      }
    });
    this.setState({ config: configClone });
  }

  render() {
    const { intl } = this.props;
    const {
      commandsToShow, devices, config, availableCommands, commandsGroups, errors,
    } = this.state;
    return (
      <div className="wizardWrapper addWidget">
        <Row className="justify-content-center mb-4">
          <Col sm={6}>
            <Field
              key={1}
              name="description"
              label={intl.formatMessage({ id: 'widgets.wizard.sendCommand.buttonDescription' })}
              placeholder={intl.formatMessage({
                id: 'widgets.wizard.sendCommand.buttonDescription.placeholder',
              })}
              type="textarea"
              onChange={this.handleDescription}
              value={config.labels.description}
            />
          </Col>
        </Row>
        <WidgetSliderPoints
          config={config}
          handleNumberOfPoints={this.handleNumberOfPoints}
        />
        {config.conditions.numberOfPoints.map((statePoint) => (
          <WidgetSliderAttributes
            key={`att${statePoint.state}`}
            handleCommandGroup={this.handleCommandGroup}
            handleInputChange={this.handleInputChange}
            devices={devices}
            statePoint={statePoint}
            config={config}
            handleChange={this.handleChange}
            commandsToShow={commandsToShow}
            addCommand={this.addCommand}
            availableCommands={availableCommands}
            errors={this.state.localErrors}
            removeCommand={this.removeCommand}
            showModalCommandsInGroup={this.state.showModalCommandsInGroup}
            getAllAttributes={this.getAllAttributes}
            newEntity={this.props.newEntity}
            commandsGroups={commandsGroups}
            handleEditCommandsGroup={this.handleEditCommandsGroup}
            handleDeleteCommandsGroup={this.handleDeleteCommandsGroup}
            handleAlias={this.handleAlias}
            handleDeleteIndividualCommand={this.handleDeleteIndividualCommand}
          />
        ))}
        {(!isEmpty(errors))
          && (
          <div className="validation m-auto">
            <p className="validationError">
              <FormattedMessage
                id="rules.profile.validation.command.slider.empty"
              />
            </p>
          </div>
          )}
      </div>
    );
  }
}
export default withWizardStep(injectIntl(ConfigureWidgetSlider));
