import * as TYPES from './types';
import {store} from '../store';

export const addTemplate = (Template) => store.dispatch({
    type: TYPES.ADD_TEMPLATE_REQUEST,
    payload: Template,
});

export const modifyTemplate = (Template) => store.dispatch({
    type: TYPES.MODIFY_TEMPLATE_REQUEST,
    payload: Template,
});

export const updateTemplate = Template =>
  store.dispatch({
      type: TYPES.MODIFY_TEMPLATE_REQUEST,
      payload: Template
  });

export const deleteTemplate = (Template) => store.dispatch({
    type: TYPES.DELETE_TEMPLATE_REQUEST,
    payload: Template,
});

export const getTemplates = (payload) => store.dispatch({
    type: TYPES.GET_TEMPLATES_REQUEST,
    payload: payload
});

export const getTemplate = (Template) => store.dispatch({
    type: TYPES.GET_TEMPLATE_REQUEST,
    payload: Template,
});

export const getMyTemplates = (payload) => store.dispatch({
    type: TYPES.GET_MY_TEMPLATES_REQUEST,
    payload: payload
});

export const getPublicTemplates = (payload) => store.dispatch({
    type: TYPES.GET_PUBLIC_TEMPLATES_REQUEST,
    payload: payload
});

export const getTemplateDeviceCount = () => store.dispatch({
    type: TYPES.GET_TEMPLATE_DEVICES_COUNT_REQUEST,
});

export const clearTemplates = () => store.dispatch({
    type: TYPES.CLEAR_TEMPLATES_STATE
});

export const updateTemplatesState = payload =>
  store.dispatch({
      type: TYPES.UPDATE_TEMPLATES_STATE,
      payload: payload
    });
