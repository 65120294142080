/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import Widget from '../..';
import Dashboard from '../../../Dashboard';
import { ImageConfigurationSheetBasic, ImageConfigurationSheet, validationImage } from './utils/configurationSheet';
import WidgetImageOptions from './utils/WidgetImageOptions';
import ImageDefaultConfiguration from './utils/defaultConfiguration';
import ImageComponent from './component';
import { ReactComponent as ImageWidgetIcon } from '../../../../configuration/icons/svg/imagen-ic.svg';
import AddWidgetImage from '../../../../pages/Widgets/Add/steps/customSteps/Image/AddWidgetImage';
import childrenModelParser from './utils/childrenModelsParser';

export const NAME_WIDGET = 'IMAGE';

const WidgetCard = ({
  children, actions, widget, hasPermissionToEdit, widgetCard: { dispatchState },
}) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [isResizable, setIsResizable] = useState(false);

  const handleSwicht = () => {
    setIsDraggable(!isDraggable);
    setIsResizable(!isResizable);
  };
  return (
    <div>
      <div className="addWidgetToImage">
        <WidgetImageOptions
          dashboard={widget.dashboard}
          widgetImageId={widget.id}
          actions={actions}
          hasPermissionToEdit={hasPermissionToEdit}
          dispatchState={dispatchState}
          widget={widget}
          handleSwicht={handleSwicht}
          isDraggable={isDraggable}
          isResizable={isResizable}
        />
      </div>
      {React.cloneElement(children,
        {
          isDraggable, isResizable,
        })}
    </div>
  );
};

export default class ImageWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = ImageWidgetIcon;

  static wizardSteps = { AddWidgetImage };

  static getChildModel(widget, noInstanced) {
    return childrenModelParser(widget, noInstanced);
  }

  skeleton = ImageWidgetIcon;

  defaultConfig = ({ ...ImageDefaultConfiguration });

  isWidgetContainer = true;

  needsSocket = false;

  component = ImageComponent;

  customWidgetCard = WidgetCard;

  instanceChildren = (widgetChildren) => childrenModelParser(widgetChildren);

  constructor(obj = {}) {
    super(obj);
    this.dashboard = new Dashboard(obj.dashboard, { instanceWidgets: false });
  }

  // *! --------------------- [COMMON BODY] --------------------- !* //

  injectData = () => null

  getValidatorConfig = () => (config) => validationImage(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? ImageConfigurationSheetBasic
      : ImageConfigurationSheet
  )

  getBodyQueryHistoricalLinked = () => null

  getQueryHistorical = () => {}

  formatToData = () => null

  parsedLinkedData = () => null
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
