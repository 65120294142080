import {css} from 'styled-components'

const CommonInput = css`
    /* transition: border 300ms, box-shadow 300ms;

   ${props => props.mode === 'view' && `
    box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0), 0 -1px 1px #fff, 0 1px 0 #fff; 
    border: 1px solid transparent;
    &::placeholder {
      opacity: 0;
    }
  `}  
  
  ${props => (props.mode === 'insert' || props.mode === undefined) && `
    box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
    border: 1px solid transparent;
  `}
  
  ${props => props.mode === 'edit' && `
    box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
    border: 1px solid #577ee8;
  `}

  &::placeholder {
    color: #ccc;
  }
  
  &:focus {
      border: 1px solid #577ee8;
  } */

`;

export default CommonInput