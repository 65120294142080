const injectDataFromHistorical = (content, values) => {
  if (!content) return '';
  const newContent = { ...content };
  Object.keys(newContent.entityMap).forEach((key) => {
    const historical = values.data.find(
      (d) => newContent.entityMap[key].data.selectedSource?.sourceId === d.device_id,
    );
    if (historical) {
      newContent.entityMap[key].data.value = historical[newContent.entityMap[key].data.name.split(':')[1]];
    }
  });
  return newContent;
};

export default injectDataFromHistorical;
