import React from 'react';
import Styled from 'styled-components';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';
import Switch from '../../../../elements/Switch';

const Edit = (
  <FormattedMessage
    id="options.editAndPermissions"
    defaultMessage="edit and permissions"
    description="Title of edit and permissions"
  />
);

const ButtonAnimation = Styled('div')`

`;

const OptionsForDashboard = ({
  rowContent,
  clickView,
  handleAnimationResizable,
  isAnimationResizable,
}) => (
  <div className="tableMenu">
    <ul className="list-unstyled">
      <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
        <li
          onClick={() => { clickView(rowContent, 'edit'); }}
          state={{ dashboard: rowContent, profileType: 'edit' }}
          role="presentation"
        >
          {Edit}
        </li>
      </PrivateComponent>
      <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
        <li
          role="presentation"
        >
          <ButtonAnimation>
            <div onClick={handleAnimationResizable} id="animateWidgets" role="presentation">
              <span><FormattedMessage id="dashboard.button.widgetAnimation" /></span>
              <Switch checked={!isAnimationResizable} />
            </div>
          </ButtonAnimation>
        </li>
      </PrivateComponent>
    </ul>
  </div>
);

export default OptionsForDashboard;
