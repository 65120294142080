import React from 'react';
import InnerWizard from './components/InnerWizard';
// eslint-disable-next-line import/no-cycle
import Modal from './components/Modal';

class WrapperWizard extends React.Component {
  constructor(props) {
    super(props);
    const { entity: Entity, newEntity } = this.props;
    this.state = {
      newEntity: newEntity || (Entity ? new Entity() : {}),
      disableNextButton: false,
    };
  }

  // what happens when this.props.onError triggers on child component
  // onError prop triggers when there are errors
  handleError = (errors) => {
    this.setState({
      errors,
    });
  };

  handleDisableNext = (value) => {
    this.setState({
      disableNextButton: value,
    });
  }

  // what happens when this.props.onContinue triggers on child component
  // onContinue prop triggers when everything is ok
  handleContinue = (created = null, metadata) => {
    const { newEntity } = this.state;
    const { entity: Entity } = this.props;

    if (created !== null) {
      this.setState({
        newEntity: Entity ? new Entity({ ...newEntity, ...created }) : { ...newEntity, ...created },
        errors: undefined,
        extraData: metadata,
      });
    }
    this.child.handleOnContinue();
  };

  partialSaving = (created) => {
    const { newEntity } = this.state;
    const { entity: Entity } = this.props;
    this.setState({
      newEntity: Entity ? new Entity({ ...newEntity, ...created }) : { ...newEntity, ...created },
      errors: undefined,
    });
  };

  render() {
    const {
      errors,
      newEntity,
      extraData,
      disableNextButton,
    } = this.state;
    const { children, metadata } = this.props;

    return (
      <InnerWizard
        onRef={(ref) => { this.child = ref; }}
        stepper
        starter={false}
        success
        errors={errors}
        new
        handleError={this.handleError}
        handleContinue={this.handleContinue}
        handleDisableNext={this.handleDisableNext}
        setState={this.partialSaving}
        newEntity={newEntity}
        metadata={metadata}
        extraData={extraData}
        disableNextButton={disableNextButton}
      >
        {children}
      </InnerWizard>
    );
  }
}

const Wizard = ({
  buttonOpen, customButton, ...rest
// eslint-disable-next-line react/jsx-props-no-spreading
}) => <Modal {...rest} customButton={customButton} ButtonOpen={buttonOpen} className="wizard" />;

WrapperWizard.defaultProps = {
  metadata: {},
};

export default Wizard;
export { WrapperWizard };
