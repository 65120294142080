import React, { Component } from 'react';
import './styles.scss';

class ExpansionLabel extends Component {
  constructor(props) {
    super(props);
    const { opened } = this.props;
    this.state = {
      opened: opened || false,
    };
  }

  componentDidUpdate(prevProps) {
    const { opened } = this.props;
    if (prevProps.opened !== opened) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        opened,
      });
    }
  }

  handleOnClick = () => {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
    });
  };

  render() {
    const { opened } = this.state;
    const {
      data, header: Header, content: Content, children,
    } = this.props;

    return (
      <div className={`expansion-label ${opened ? 'opened' : ''}`}>
        <button
          type="button"
          className={`expansion-label-header w-100 no-button ${opened ? 'opened' : ''}`}
          onClick={this.handleOnClick}
        >
          {Header(data, opened)}
        </button>
        <div className={`expansion-label-body w-100 ${opened ? 'd-block' : 'd-none'}`}>
          {children}
          {Content(data, opened)}
        </div>
      </div>
    );
  }
}

export default ExpansionLabel;
