import { Map, List } from 'immutable';

import * as TYPES from './types';

/** Initial State of Reports */
const initialState = Map({
  list: List([]),
  total: 0,
  link: null,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  report: {},
});

/** Report reducer */
export default (state = initialState, { type, payload }) => {

  switch (type) {
    case TYPES.DOWNLOAD_REPORT_HISTORICAL_REDIRECT:
        // FIXME: decide how to redirect users externally
        window.location.replace(payload.link);
        return state;
  
    /** initialize DB requests */
    case TYPES.DOWNLOAD_REPORT_HISTORICAL_REQUEST:
    case TYPES.GET_REPORT_HISTORICAL_REQUEST:
      return state.merge(Map({
        fetching: true,
        errorFetching: null,
      }));

    case TYPES.GET_REPORT_HISTORICAL_ERROR:
      return state.merge(Map({
        fetching: false,
        errorFetching: payload,
      }));

    /** reports received from DB */
    case TYPES.GET_REPORT_HISTORICAL_SUCCESS:
      return state.merge(Map({
        total: payload.length,
        list: List(payload),
        fetching: false,
        link: null,
      }));

    case TYPES.CLEAR_REPORT_HISTORICAL_STATE:
      return state.set('list', List([]));

    default:
      return state;
  }
};
