const isExpirated = () => new Date() > new Date(localStorage.getItem('expiration_date'));

export default () => {
  if (isExpirated()) {
    const xDataToken = localStorage.getItem('x_data_access');
    localStorage.clear();
    localStorage.setItem('x_data_access', xDataToken);
    return false;
  }
  return (
    localStorage.getItem('access_token')
      && localStorage.getItem('access_token').length > 0
  );
};
