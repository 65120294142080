/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Styled from 'styled-components';
import { Container, Col } from 'reactstrap';
import { uniqBy } from 'lodash';
import { withBlanketCtxt } from '../../Contexts/BlanketContext/index';

const sm = `
  width: 60vw !important;
  height: inherit  !important;`;

const xl = `
  width: 100vw !important;
  height: 100vw  !important;`;

const Main = Styled(Container)`
${(props) => props.size === 'sm' && sm}
${(props) => props.size === 'xl' && xl}
`;
const Title = Styled.div``;
const Body = Styled.div`
  justify-content: ${(props) => props.verticalAlign || 'center'}!important;
`;
const Footer = Styled.div``;

const ModalHOC = (WrappedElements, options) => {
  const {
    ButtonOpen, ModalTitle, ModalContent, ModalButtons, ModalFooter, verticalAlign, customButton,
  } = WrappedElements;
  const checkItems = options?.checkItems;

  class Modal extends React.Component {
    ClickOutRef = null;

    constructor(props) {
      super(props);
      this.state = {
        isAvailable: false,
      };
    }

    componentDidMount() {
      const { rowContent } = this.props;
      if (Array.isArray(rowContent)) {
        const types = uniqBy(rowContent, 'source.type');
        if (types.length > 1) this.setState({ isAvailable: true });
      }
    }

    showModal = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { handleBlanketVisibility } = this.props;

      handleBlanketVisibility(true, this.renderedContent);
    };

    hideModal = () => {
      const { handleBlanketVisibility } = this.props;
      handleBlanketVisibility(false);
    };

    handleLister = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());

      if (!path.includes(this.ClickOutRef)) {
        window.removeEventListener('click', this.handleLister, true);
      }
    };

    renderedContent = () => {
      const { className, size } = this.props;
      return (
        <div id="modal" className={className}>
          <Main fluid size={size} ref={(node) => { this.ClickOutRef = node; }}>
            {(ModalTitle !== undefined) && (
              <Title className="titulo">
                <Col><ModalTitle {...this.props} hideModal={this.hideModal} /></Col>
              </Title>
            )}
            {(ModalContent !== undefined) && (
              <Body className="body" verticalAlign={verticalAlign}>
                <div className="d-flex justify-content-center align-items-center w-100">
                  <ModalContent {...this.props} hideModal={this.hideModal} />
                </div>
              </Body>
            )}
            {(ModalButtons !== undefined) && (
              <ModalButtons
                {...this.props}
                showModal={this.showModal}
                hideModal={this.hideModal}
              />
            )}
            {(ModalFooter !== undefined) && (
              <Footer className="footer">
                <Col><ModalFooter {...this.props} /></Col>
              </Footer>
            )}

          </Main>
        </div>
      );
    };

    render() {
      const { isAvailable } = this.state;
      return (
        <>
          <ButtonOpen
            isAvailable={isAvailable}
            showModal={this.showModal}
            customButton={customButton}
            {...this.props}
          />
        </>
      );
    }
  }

  Modal.propTypes = {
    show: PropTypes.bool,
    handleBlanketVisibility: PropTypes.func.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
  };

  Modal.defaultProps = {
    show: true,
    className: '',
    size: '',
  };

  return withBlanketCtxt(Modal);
};

export default ModalHOC;
