import React from 'react';
import validate from 'validate.js';

import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import {
  Components, categories, valuesData, getCategory,
} from '../utils';

export const NeedleConfigurationSheetBasic = {
  unitValue: [
    {
      name: 'min',
      component: Components.number,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'max',
      component: Components.number,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'unit',
      component: Components.text,
      categories: [categories.LABELS],
    },
  ],
};
const NeedleConfigurationSheet = {
  unitValue: [
    {
      name: 'min',
      component: Components.number,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'max',
      component: Components.number,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'unit',
      component: Components.text,
      categories: [categories.LABELS],
    },
  ],
  ranges: [
    {
      name: 'colorsAndRange',
      component: Components.needleRange,
      categories: [categories.CONDITIONS, categories.COLORS],
    },
  ],
};

export default NeedleConfigurationSheet;

export const validationNeedle = (type, config) => {
  const constraints = {};
  valuesData[type].forEach((property) => {
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }
    constraints[`${category}.${property}`] = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };
    if (property === 'max') {
      constraints[`${category}.${property}`] = {
        ...constraints[`${category}.${property}`],
        numericality: {
          onlyInteger: true,
          greaterThan: parseInt(config[category].min, 10),
          message: (
            <FormattedMessage
              id="widget.wizard.validation.greaterthan"
              values={{ minimum: config[category].min }}
            />
          ),
        },
      };
    }
    if (property === 'min' && config[category].max) {
      constraints[`${category}.${property}`] = {
        ...constraints[`${category}.${property}`],
        numericality: {
          onlyInteger: true,
          lessThanOrEqualTo: parseInt(config[category].max, 10),
          message: (
            <FormattedMessage
              id="widget.wizard.validation.lessthan"
              values={{
                minimum: config[category].max || 'max',
              }}
            />
          ),
        },
      };
    }
  });

  return validate(config, constraints);
};
