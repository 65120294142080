import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { clone } from 'ramda';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import Finder from './Finder';
import Switch from '../../../../../elements/Switch';
import ListComponent from './ListComponentMap';
import { Components } from '../../../../../models/Widget/configurationSheets/utils';
// eslint-disable-next-line import/no-cycle
import { isShowV2 } from '../../../../../helpers/utils';

const AdvancedLegendNames = ({
  widget,
  widgetSources,
  categorySelected,
  handler,
}) => {
  const { origins, config } = widget;
  const { appearance, labels } = config;

  const getDevices = () => {
    let devices = [];
    // ToDo Remove when implement showV2
    if (isShowV2()) {
      devices = widgetSources;
    } else {
      origins.forEach((o) => {
        if (o.connectedDevices.readDevice) {
          devices.push(o.connectedDevices.readDevice);
        }
      });
    }

    return devices.filter((d) => d.categories.includes(categorySelected));
  };

  const [devices, setDevices] = useState(getDevices());
  const [originalDevices] = useState(getDevices());
  const [hiddenAttributes, setHiddenAttributes] = useState(appearance?.hidden ?? {});
  const [alias, setAlias] = useState(labels?.alias ?? {});

  let allChecked = true;

  devices.forEach((d) => {
    if (hiddenAttributes[d.id] && hiddenAttributes[d.id].length) {
      allChecked = false;
    }
  });

  const onSearch = (event) => {
    const { target } = event;
    const { value } = target;
    const deviceCopy = clone(originalDevices);

    originalDevices.forEach((d, i) => {
      const excluded = [];
      d.attributes.forEach((attr) => {
        if (!(attr.name.includes(value)) && !(alias[d.id]
          && alias[d.id].atributes[attr.name]
          && alias[d.id].atributes[attr.name].includes(value))) {
          excluded.push(attr.name);
        }
        deviceCopy[i].attributes = d.attributes.filter((at) => (!excluded.includes(at.name)));
      });
    });
    setDevices(deviceCopy);
  };

  const handleContentEditableChangeAlias = (id, data) => {
    const aliasCopy = clone(alias);
    if (!aliasCopy[id]) {
      aliasCopy[id] = { name: data, atributes: {} };
    } else {
      aliasCopy[id].name = data;
    }
    setAlias(aliasCopy);
    handler({ name: 'alias', value: aliasCopy }, 'labels', Components.advancedLegendNames);
  };

  const onAttributeCheck = (status, attribute, device) => {
    const hiddens = clone(hiddenAttributes);

    if (!hiddens[device.id]) {
      hiddens[device.id] = [];
    }

    if (status) {
      hiddens[device.id] = hiddens[device.id].filter((a) => a !== attribute.name);
    } else {
      hiddens[device.id].push(attribute.name);
    }
    setHiddenAttributes(hiddens);
    handler({ name: 'hidden', value: hiddens }, 'appearance', 'advancedLegendNames');
  };

  const handleContentEditableChangeAtributeAlias = (id, devicename, atribute, data) => {
    const aliasCopy = clone(alias);

    if (!aliasCopy[id]) {
      aliasCopy[id] = { name: devicename, atributes: {} };
    }

    aliasCopy[id].atributes[atribute] = data;

    setAlias(aliasCopy);
    handler({ name: 'alias', value: aliasCopy }, 'labels', Components.advancedLegendNames);
  };

  const onCheckAll = (status, device) => {
    const hiddens = clone(hiddenAttributes);

    if (!hiddens[device.id]) {
      hiddens[device.id] = [];
    }

    if (status) {
      hiddens[device.id] = [];
    } else {
      device.joinedAttributes.forEach((attr) => {
        hiddens[device.id].push(attr.name);
      });
    }
    setHiddenAttributes(hiddens);
    handler({ name: 'hidden', value: hiddens }, 'appearance', Components.advancedLegendNames);
  };

  const onCheckAllGeneral = (status) => {
    const hiddens = clone(hiddenAttributes);

    devices.forEach((device) => {
      if (!hiddens[device.id]) {
        hiddens[device.id] = [];
      }
      if (status) {
        hiddens[device.id] = [];
      } else {
        device.attributes.forEach((attr) => {
          hiddens[device.id].push(attr.name);
        });
      }
    });

    setHiddenAttributes(hiddens);
    handler({ name: 'hidden', value: hiddens }, 'appearance', Components.advancedLegendNames);
  };

  return (

    <div className="nameDevicesMap container-fluid">
      <Finder onKeyUp={onSearch} />
      <div style={{ flexDirection: 'row-reverse', display: 'flex' }}>
        <Row>
          <Col sm={12} lg={12}>

            <ul className="list-unstyled checkAllContainer">
              <li>
                <FormattedMessage id="widgets.wizard.check.all" />
                {' '}
                <Switch
                  checked={allChecked}
                  onChange={(status) => onCheckAllGeneral(status)}
                />
              </li>

            </ul>

          </Col>
        </Row>
      </div>

      {devices.map((d, i) => (
        <ListComponent
          hiddenAttributes={hiddenAttributes}
          alias={alias}
          onPanelOpen={() => null}
          index={i}
          data={{ ...d, joinedAttributes: d.attributes }}
          activeAttribute={false}
          openedPanel={false}
          onContentEditableChange={handleContentEditableChangeAlias}
          onCheckAll={onCheckAll}
          onAttributeCheck={onAttributeCheck}
          onContentEditableChangeAtribute={handleContentEditableChangeAtributeAlias}
        />
      ))}
    </div>
  );
};

AdvancedLegendNames.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  categorySelected: PropTypes.string,
  handler: PropTypes.func,
  origins: PropTypes.objectOf(PropTypes.any),
  widget: PropTypes.objectOf(PropTypes.any),
};

AdvancedLegendNames.defaultProps = {
  config: { appearance: {}, labels: {} },
  categorySelected: '',
  handler: () => {},
  origins: {},
  widget: {},
};

export default injectIntl(AdvancedLegendNames);
