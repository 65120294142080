import React from 'react';
import CheckBox from '../../CheckBox';
import Option from './Option';

const Loader = (props) => (
  <div className={`loading ${props.className}`}>
    <div className="loading-bar"></div>
    <div className="loading-bar"></div>
    <div className="loading-bar"></div>
    <div className="loading-bar"></div>
  </div>
);

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.getChecked(this.props.selected)
    };
  }

  getChecked = value => {
    const { data } = this.props;
    return data.map(opt => JSON.stringify(opt.value) === JSON.stringify(value));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        checked: this.getChecked(this.props.selected)
      });
    }
  }

  handleOptionChange = (e, opt) => {
    const { handleOnChange } = this.props;
    this.setState(
      {
        checked: this.getChecked(opt.value)
      },
      () => setTimeout(() => handleOnChange(e, opt), 100)
    );
  };

  render() {
    const { data, onScroll, loading, check, footerWithAction } = this.props;
    const { checked } = this.state;
    return (
      <div className="options" onScroll={onScroll}>
        {data.map((opt, i) => {

          return (
            <Option value={opt.value} key={`opt.value${i}`} onClick={e => this.handleOptionChange(e, opt)} subtitle={opt.subtitle}>
              <CheckBox
                checked={footerWithAction ? check?.some((o) => o.id === opt.value.id) : checked[i]}
              />
              <span> 
                {opt.name}
                <br/>
                <span className='subtitle'>{opt.subtitle}</span>
              </span>
            </Option>
          );
        })}
        {<Loader className={loading ? 'visible' : ''} />}
      </div>
    );
  }
}
export default Options;
