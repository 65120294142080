import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AssignPermissions from '../../../pages/helpers/AssignPermissions/index';
import { withWizardStep } from '../../../Contexts/WizardStepContext';

class ConfigurePermissions extends React.Component {
  constructor(props) {
    super(props);
    const { newEntity } = this.props;

    this.state = {
      original: newEntity,
    };
  }

  componentDidUpdate(prevProps) {
    const { currentEntities, newEntity } = this.props;
    const { original } = this.state;
    if (currentEntities !== prevProps.currentEntities && original.id !== null) {
      const currentEntity = currentEntities.find((g) => g.id === newEntity.id);
      this.updateState(currentEntity, newEntity);
    }
  }

  getMembers = () => {
    const { original } = this.state;
    const { users, groups } = original.permissions_policy;
    const { owner } = original;
    const { usersGroups } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const usersWithPermissions = this.props.users.filter(
      (o) => users.map((u) => u.id).includes(o.id),
    );
    const usersGroupsWithPermissions = usersGroups.filter(
      (o) => groups.map((g) => g.id).includes(o.id),
    );
    // eslint-disable-next-line react/destructuring-assignment
    const userOwner = this.props.users.find((o) => o.id === owner);

    this.setState({
      usersWithPermissions,
      usersGroupsWithPermissions,
      userOwner,
    });
  };

  handleOnSave = (payload) => {
    const { Entity } = this.props;
    const editedEntity = new Entity(payload);
    editedEntity.update();
  };

  updateState(currentEntity, newEntity) {
    this.setState({ original: currentEntity || newEntity },
      () => this.getMembers());
  }

  render() {
    const { original } = this.state;
    const data = original;
    const { userOwner, usersWithPermissions, usersGroupsWithPermissions } = this.state;
    const { permissionToEdit, onSave } = this.props;

    return (
      <div className="tabSection p-5">
        <AssignPermissions
          userOwner={userOwner}
          usersWithPermissions={usersWithPermissions}
          usersGroupsWithPermissions={usersGroupsWithPermissions}
          newEntity={data}
          permissionToEdit={permissionToEdit}
          onSave={onSave}
        />
      </div>
    );
  }
}

ConfigurePermissions.defaultProps = {
  users: '',
  usersGroups: '',
  newEntity: '',
  currentEntities: [],
  Entity: '',
  permissionToEdit: () => {},
  onSave: () => {},
};

const mapStateToProps = (state) => ({
  usersGroups: state.get('usersGroups').get('list').toJS(),
  users: state.get('users').get('list').toJS(),
  modifySuccess: state.get('usersGroups').get('modifySuccess'),
  errorFetching: state.get('usersGroups').get('errorFetching'),
  currentEntities: state.get('menuElements').get('root').toJS(),
});

export default connect(mapStateToProps, {})(withWizardStep(injectIntl(ConfigurePermissions)));
