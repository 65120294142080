import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import {
  addDevice,
  updateDevice,
  deleteDevice,
  getDevice,
} from '../../services/redux/devices/actions';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

/** Class Device with Utils functions */
export default class Device extends Utils {
  static entityName = 'Device';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = Device;
    if (this.categories.length === 0) {
      this.categories.push('OTHER');
    }
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      if (d === 'fiware') {
        filteredConstraints['fiware.service'] = this.constraints[d];
      } else {
        filteredConstraints[d] = this.constraints[d];
      }
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? validation
      : { error: true, ...validation };
  }

  /** Call Redux action for Save Device in DB and Redux */
  save() {
    addDevice({
      ...this.getData(),
    });
  }

  /** Call Redux action for Update Device in DB and Redux */
  update() {
    const validation = this.validate(Object.keys(this.getData()));
    return validation === undefined
      ? updateDevice({
        ...this.getData(),
      })
      : { error: true, ...validation };
  }

  delete() {
    const validation = this.validate(['id']);

    return validation === undefined
      ? deleteDevice(this.getData())
      : { error: true, ...validation };
  }

  get() {
    const validation = this.validate(['id']);
    return validation === undefined
      ? getDevice(this.getData())
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('Device', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleDevice = new Device();
export { SampleDevice };
