import colors from '../../../../configuration/colors';

export const OnOffDefaultConfiguration = {
  labels: {
    description: '',
  },
  appearance: {
    state: true,
  },
  conditions: {
    numberOfPoints: [
      {
        state: false,
        commands: [
          {
            commandName: '',
            devicesId: [],
            value: '',
          },
        ],
      },
      {
        state: true,
        commands: [
          {
            commandName: '',
            devicesId: [],
            value: '',
          },
        ],
      },
    ],
  },
  colors: {
    off: colors['ui-LightGray'],
    on: colors['ui-main-color'],
  },
};
export default OnOffDefaultConfiguration;
