/* eslint-disable import/no-cycle */
import { random } from 'lodash';
import { getRandomColor } from '../../../../../../../pages/Widgets/Add/components/utils';
import LinesDefaultConfiguration, { injectLinesData } from '../../../../lines/utils/defaultConfiguration';

export const LinesDefaultConfigurationForLinked = {
  ...LinesDefaultConfiguration,
  labels: {
    xAxis: 'Time',
    yAxis: 'Value',
    alias: { },
  },
};

export const injectLinkedLinesData = (widget) => {
  const defaultLineWidgetConfig = injectLinesData(widget);
  const alias = { };
  const colors = { };
  widget.config.data.attributeFilter.forEach((attr) => {
    alias[`data-${attr}`] = attr;
    colors[`data-${attr}`] = getRandomColor(random(0, 12));
  });
  defaultLineWidgetConfig.colors = colors;
  defaultLineWidgetConfig.labels.alias = alias;
  return defaultLineWidgetConfig;
};

export default injectLinkedLinesData;
