import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import RemoveHoc from '../../../../helpers/RemoveComponent';
import PrivateComponent from '../../../../../components/PrivateComponent/privateComponent';
import Widget from '../../../../../models/Widget';
import { cloneInstance } from '../../../../../helpers/utils';
import { TYPES, updateState } from '../../../../Dashboards/ShowV2/reducer';

function OptionsForLinkedWidget(props) {
  const {
    widget, permissionToEdit, clickView, dispatchState,
  } = props;

  const ButtonOpenRemove = ({ showModal }) => (
    <li
      onClick={showModal}
      role="presentation"
    >
      <FormattedMessage id="widgetLinked.profile.widgets.options.delete" />
    </li>
  );

  ButtonOpenRemove.propTypes = {
    showModal: PropTypes.func,
  };

  ButtonOpenRemove.defaultProps = {
    showModal: () => {},
  };

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Widget, nameData: 'widgets' });

  const changeWidgetOperation = (operation) => {
    const clonedWidget = cloneInstance(widget);
    clonedWidget.config.data.operation = operation;
    const updateWidget = new Promise((resolve) => {
      resolve(
        updateState(
          TYPES.setWidgetSection,
          { active: clonedWidget },
          dispatchState,
        ),
      );
    });
    updateWidget.then(() => clonedWidget.update(clonedWidget, widget));
  };

  return (
    <div className="tableMenu">
      <ul className="list-unstyled">
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            onClick={() => { clickView(widget, 'edit'); }}
            role="presentation"
          >
            <FormattedMessage id="widgetLinked.profile.widgets.options.edit" />
          </li>
        </PrivateComponent>
        {widget.widgetType !== 'PERCENTAGE' && (
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            role="presentation"
            className="showOption"
          >
            <span className="showOptionText">
              <FormattedMessage id="widgetLinked.profile.widgets.options.show" />
              <i className="uil uil-angle-right" />
            </span>
            <div className="operationType">
              <span className="operationTypeTitle">
                <FormattedMessage id="widgetLinked.profile.widgets.options.show.operationType" />
              </span>
              <ul>
                <li
                  className={widget.config.data.operation === 'avg' ? 'active' : ''}
                  onClick={() => changeWidgetOperation('avg')}
                >
                  <FormattedMessage id="widgetLinked.profile.widgets.options.show.mean" />
                </li>
                <li
                  className={widget.config.data.operation === 'max' ? 'active' : ''}
                  onClick={() => changeWidgetOperation('max')}
                >
                  <FormattedMessage id="widgetLinked.profile.widgets.options.show.maximum" />
                </li>
                <li
                  className={widget.config.data.operation === 'min' ? 'active' : ''}
                  onClick={() => changeWidgetOperation('min')}
                >
                  <FormattedMessage id="widgetLinked.profile.widgets.options.show.minimum" />
                </li>
                <li
                  className={widget.config.data.operation === 'sum' ? 'active' : ''}
                  onClick={() => changeWidgetOperation('sum')}
                >
                  <FormattedMessage id="widgetLinked.profile.widgets.options.show.addition" />
                </li>
              </ul>
            </div>
          </li>
        </PrivateComponent>
        )}

        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="delete"
        >
          {permissionToEdit && <RemoveDOM rowContent={widget} />}
        </PrivateComponent>
      </ul>
    </div>
  );
}

OptionsForLinkedWidget.propTypes = {
  widget: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  permissionToEdit: PropTypes.bool,
};

OptionsForLinkedWidget.defaultProps = {
  widget: {},
  clickView: () => {},
  permissionToEdit: undefined,
};

export default OptionsForLinkedWidget;
