import * as TYPES from './types';
import { store } from '../store';

export const getReportHistorical = (payload) => store.dispatch({
  type: TYPES.GET_REPORT_HISTORICAL_REQUEST,
  payload,
});

export const clearReportHistorical = (payload) => store.dispatch({
  type: TYPES.CLEAR_REPORT_HISTORICAL_STATE,
  payload,
});

export const downloadReportHistorical = (Report) => store.dispatch({
  type: TYPES.DOWNLOAD_REPORT_HISTORICAL_REQUEST,
  payload: Report,
});
