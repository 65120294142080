import React from 'react';
import { injectIntl } from 'react-intl';
import { validate } from 'validate.js';
import CommonView from '../../../../../CommonView';
import Field from '../../../../../../elements/Field';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { getGeneralDataForAction } from '../../../../../../data/ruleActions';
import withVariables from '../../../../../../components/WithVariables/index';
import { joinedAttributes } from '../helpers';

const FieldWithVariables = withVariables(Field);

class TweetComponent extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      tweetMsg: '',
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.props.actionComponentRef(this);
  }

  validate = (callback = null) => {
    const generalActionData = getGeneralDataForAction(this.props.newEntity.actionToCreate);
    const constraints = {};
    ['tweetMsg'].forEach((v) => {
      constraints[v] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="Validation.presence" />,
        },
        length: {
          minimum: 2,
          tooShort: <FormattedMessage id="Validation.presence" />,
        },
      };
    });

    constraints.lengthOk = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="validation.tweet.length" />,
      },
    };

    const errors = validate(this.state, constraints);

    if (errors) {
      this.setState({
        errors,
      });
    } else this.props.saveAction(callback, generalActionData.parseAction(this.state));
  };


  handleInnerInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
    );
  };

  render() {
    const { intl, newEntity } = this.props;
    const { errors } = this.state;

    return (
      <>
        <div className="d-flex flex-column justify-content-start align-items-center tweet inline-form">
          <FieldWithVariables
            name="tweetMsg"
            type="complexTwitterTextarea"
            value={this.state.tweetMsg}
            onChange={this.handleInnerInputChange}
            helperText={
              (errors.tweetMsg ? errors.tweetMsg[0] : errors.tweetMsg)
              || errors.lengthOk
              || intl.formatMessage({ id: 'twitterTextarea.placeholder' })
                .concat('. ', intl.formatMessage({ id: 'complexTextarea.pattern' }))
            }
            error={!!errors.tweetMsg || !!errors.lengthOk}
            label={intl.formatMessage({
              id: 'twitterTextarea.label',
            })}
            devices={newEntity.devices.map((d) => ({
              device: d.device_id,
              attributes: joinedAttributes(d),
            }))}
          />
        </div>
      </>
    );
  }
}

export default injectIntl(TweetComponent);
