import * as TYPES from './types';
import {store} from '../store';

export const addRole = (Role) => store.dispatch({
    type: TYPES.ADD_ROLE_REQUEST,
    payload: Role,
});

export const addRoleSuccessFlag = () => store.dispatch({
    type: TYPES.ADD_ROLE_SUCCESS_FLAG
});

export const updateRole = (Role) => store.dispatch({
    type: TYPES.MODIFY_ROLE_REQUEST,
    payload: Role,
});

export const modifyRoleSuccessFlag = () => store.dispatch({
    type: TYPES.MODIFY_ROLE_SUCCESS_FLAG
});

export const deleteRole = (Role) => store.dispatch({
    type: TYPES.DELETE_ROLE_REQUEST,
    payload: Role,
});

export const getRole = (Role) => store.dispatch({
    type: TYPES.GET_ROLE_REQUEST,
    payload: Role,
});

export const getRoles = () => store.dispatch({
    type: TYPES.GET_ROLES_REQUEST
});

export const getRolePermissions = (Role) => store.dispatch({
    type: TYPES.GET_ROLE_PERMISSIONS_REQUEST,
    payload: Role
});

export const errorFetchingRoleReset = () => store.dispatch({
    type: TYPES.ERRORFETCHING_ROLE_RESET
});