/* eslint-disable import/no-cycle */
/* eslint-disable dot-notation */
import React, {
  useState, useEffect, Suspense, useMemo, memo,
} from 'react';
import { injectIntl } from 'react-intl';
// eslint-disable-next-line import/no-unresolved
import { registerThemeWebApp } from '@fiwoo/lib-commons-front';
import { pullAll } from 'lodash';
import { clone, isEmpty } from 'ramda';
import Widget from '../../../../models/Widget';
import { updateWidget } from '../../../../services/redux/widgets/actions';
import usePrevious from '../../../helpers/Hooks/usePrevious';
import WidgetMap from '../../../../components/WidgetMap';
import WidgetMapUber from '../../../../widgets/WidgetMapUber';
import WidgetCard from '../../../../elements/WidgetCard/WidgetCard';
import WidgetSendCommandSingle from '../../../../widgets/SendCommandSingle';
import WidgetSendCommandDual from '../../../../widgets/SendCommandDual';
import WidgetMenuButton from './WidgetMenuButton';
import { spreadObject } from '../../../../helpers';
import WidgetImageOptions from './WidgetImageOptions';
import useNearScreen from '../../../../elements/UseNearScreen';
import colors from '../../../../configuration/colors';
import { typography } from '../../../../configuration/fonts';
import ParagraphDataProvider from '../../../../Contexts/ParagraphWidgetContext/provider';
import Selectable from './CustomWidgetParagraphWrapper/Selectable';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import ModalHOC from '../../../../elements/Modal';
import CreateLinkedWidget from '../../../Widgets/AddLinkedWidget/AddLinkedWizard';
import OpenIcon from '../../../../configuration/icons/svg/createDashboard';
import { sendCommands } from '../ProfileSendingCommands/components/utils';
import { updateDashboardWithoutSave } from '../../../../services/redux/dashboards/actions';
import useDashboardContext from '../../../../Contexts/DashboardContext/consumer';
import { getAllDeviceAttributes } from '../../../../models/Widget/utils';

const Needle = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetNeedle })),
);
const LineChart = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetLineChart })),
);
const Image = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetImage })),
);
const CustomWidgetParagraphWrapper = React.lazy(
  () => import('./CustomWidgetParagraphWrapper'),
);
const Kpi = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTextNumber })),
);
const Html = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetHTML })),
);
const Table = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTable })),
);
const Percentage = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetPercentage })),
);
const Linked = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetLinked })),
);
const BarChart = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetBarChart })),
);

const OnOff = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetOnOff })),
);

const Slider = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetSlider })),
);

const getSelectionFromLocalStorage = (widgetId) => {
  const currentSelectionsRaw = localStorage.getItem('linkedSelections');
  const currentSelection = currentSelectionsRaw ? JSON.parse(currentSelectionsRaw)[widgetId] : [];
  return currentSelection;
};

const echarts = registerThemeWebApp(typography);
const configTheme = {
  name: 'fiwoo',
  colors,
  typography,
};

const VersionError = ({ widget }) => (
  <WidgetCard title={widget.name}>
    <FormattedMessage id="Widget.no-valid" />
  </WidgetCard>
);

const RenderWidget = injectIntl((props) => {
  const {
    widget, intl, dataWidgetRead, wValues, profileType, handleOpenProfileWidget,
    isResizable, handleOpenProfileSendingCommands, permissionToEdit, disableOptions,
    hasBeenResized, refreshMap, setRefreshMap, previewMode,
    containedWidgets = [], renderImageWidgets, updateWidgetImageLayout,
    renderLinkedWidgets, updateWidgetLinkedLayout, dark, dashboard, isPreview,
    updateWidgetLinkedMode, selectLinkedWidget, globalSelection, updateGlobalSelection, isLinked,
    widgetSize,
  } = props;

  // Render scroll
  const { isNearScreen, fromRef } = useNearScreen({ once: false });

  // Set currentprops on prevProps with hook
  const prevProps = usePrevious(props);

  /// Set Current prop values to state, for force re-render with new data
  const [widgetValues, setWidgetValue] = useState(wValues);

  const { historical } = useDashboardContext();
  // TODO: Move to LinkedWidget when Map will be in LibCommons
  const [selection, setSelection] = useState(
    globalSelection || getSelectionFromLocalStorage(widget.id) || [],
  );

  const mapRef = React.useRef(null);

  const [shiftPressed, setShiftPressed] = useState(false);

  const updateLocalStorage = (widgetId, newSelection) => {
    if (isLinked) {
      return;
    }
    const newObject = widgetId ? { [widgetId]: newSelection } : null;
    const currentLocalStorage = localStorage.getItem('linkedSelections');

    if (!newObject) {
      return;
    }

    if (!currentLocalStorage) {
      localStorage.setItem('linkedSelections', JSON.stringify(newObject));
      return;
    }

    const parsed = JSON.parse(currentLocalStorage);
    const newLocal = { ...parsed, [widgetId]: newSelection };
    localStorage.setItem('linkedSelections', JSON.stringify(newLocal));
    updateGlobalSelection && updateGlobalSelection(widgetId, newSelection);
  };

  // WillUpdate with prevprops
  useEffect(() => {
    if (!prevProps || !Object.is(prevProps.wValues, wValues)) {
      setWidgetValue(wValues);
    }
  }, [prevProps, wValues]);

  const handleMouseOverMap = (event) => {
    if (shiftPressed !== event) setShiftPressed(event);
  };

  const getValues = (type, id, values) => {
    switch (type) {
      case 'BARS':
        return values?.[widget.id];
      case 'LINES':
        return values?.[widget.id]?.series;
      case 'TEXT_ACCOUNTANT':
        return values?.[widget.id]?.[0];
      case 'TABLE':
        return values?.[widget.id];
      case 'NEEDLE':
      default:
        return values?.[id];
    }
  };

  const formatLayout = useMemo(() => {
    if (dashboard && widget.widgetType === 'LINKED') {
      const dashboardCloned = clone(dashboard);
      const layouts = dashboardCloned.layoutProperties;
      if (!layouts) return;
      const layoutProperties = Object.keys(dashboardCloned.layoutProperties);
      const updateLayout = {};
      layoutProperties.forEach((layoutProperty) => {
        updateLayout[layoutProperty] = dashboardCloned.layoutProperties[layoutProperty]
          .map((data) => {
            const dataLayout = clone(data);
            if (dataLayout.i === widget.id) {
              if (selection.length > 0) {
                if (dataLayout?.oldH >= 12) {
                  dataLayout.h = dataLayout.oldH;
                } else if (dataLayout.h < 12) {
                  dataLayout.h = 12;
                  dataLayout.oldH = 12;
                } else {
                  dataLayout.oldH = dataLayout.h;
                }
              } else {
                if (dataLayout.h !== 12) {
                  dataLayout.oldH = dataLayout.h;
                }
                dataLayout.h = 12;
              }
            }
            return dataLayout;
          });
      });
      // updateDashboardWithoutSave({ ...dashboardCloned, layoutProperties: updateLayout });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard?.layoutProperties, selection]);

  const parsedValueToLinked = useMemo(() => {
    if (!isLinked) return 0;
    const values = getValues(widget.widgetType, widget.id, widgetValues);
    if (!widgetValues || !values) return [];
    if (widget.container) {
      const returned = widget.parsedLinkedData(
        values,
        selection,
        historical,
        intl,
      );
      return returned;
    }
    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, widgetValues]);

  useEffect(() => {
    // llamar a selectLinkedWidget (widget, origins (filtro -> los incluidos en selection))
    const filteredList = [];
    widget.origins.forEach((w) => {
      if (selection.some((s) => s === w.connectedDevices.id)) {
        filteredList.push(w);
      }
    });
    selectLinkedWidget && selectLinkedWidget(widget, filteredList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    if (!isLinked && widget.widgetType === 'LINKED') {
      updateLocalStorage(widget.id, selection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    if (
      (globalSelection?.length !== selection?.length && globalSelection)
      || JSON.stringify(globalSelection) !== JSON.stringify(selection)) {
      isLinked && setSelection(globalSelection);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSelection]);

  const handleClickItem = (item) => {
    // At first we need to check if exist on array.
    const isThere = !!selection.find((i) => i === item?.id);
    if ((shiftPressed && widget.config.custom.LINKED.mode === 'MAP') || widget.config.custom.LINKED.mode === 'TABLE') {
      // If Shift pressed we add on array
      if (!isEmpty(item)) {
        if (isThere) {
          const newArr = selection.filter((deviceId) => deviceId !== item.id);
          pullAll(newArr, [null]);
          setSelection(newArr);
        } else {
          setSelection([...selection, item.id]);
        }
      }
      return;
    }

    // In this case only one device can be pressed
    if (isThere || isEmpty(item)) {
      setSelection([]);
    } else if (item.id) {
      setSelection([item.id]);
    }
  };

  const handleTableSelection = (selectionArray) => {
    const selectionIdArray = selectionArray.map((device) => device.id);
    pullAll(selectionIdArray, [null, NaN, undefined]);
    if (selectionArray.length !== selection.length) {
      setSelection(selectionIdArray);
    }
  };

  const localeTranslate = (key, defaultValue) => (
    intl ? intl.formatMessage({ id: key }) : defaultValue
  );

  const actions = !disableOptions ? (
    <WidgetMenuButton
      widget={widget}
      profileType={profileType}
      entity="widget"
      clickView={handleOpenProfileWidget}
      clickSendingCommandsView={handleOpenProfileSendingCommands}
      permissionToEdit={permissionToEdit}
    />
  ) : (
    <div />
  );
  const actionsDark = !disableOptions ? (
    <WidgetMenuButton
      widget={widget}
      profileType={profileType}
      entity="widget"
      clickView={handleOpenProfileWidget}
      clickSendingCommandsView={handleOpenProfileSendingCommands}
      permissionToEdit={permissionToEdit}
      dark
    />
  ) : (
    <div />
  );

  const ActionsForMaps = React.memo((propsAct) => (
    !disableOptions ? (
      <WidgetMenuButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsAct}
        widget={widget}
        profileType={profileType}
        entity="widget"
        clickView={handleOpenProfileWidget}
        clickSendingCommandsView={handleOpenProfileSendingCommands}
        permissionToEdit={permissionToEdit}
      />
    ) : (
      <div />
    )
  ), () => true);

  if ((widget.widgetType !== 'TEXT'
    && widget.widgetType !== 'HTML'
    && widget.widgetType !== 'MAP'
    && widget.widgetType !== 'SEND_COMMAND_SINGLE'
    && widget.widgetType !== 'SEND_COMMAND_DUAL'
    && (!dataWidgetRead || widget.isInvalid)
  )) {
    return (
      <WidgetCard title={widget.name} actions={actions}>
        No ha sido posible cargar los datos del dispositivo, compruebe la
        configuración e inténtelo de nuevo.
      </WidgetCard>
    );
  }

  const getWidget = () => {
    // if (!isNearScreen) {
    //   return null;
    // }

    const OpenIconButton = ({ showModal }) => (
      <button
        type="button"
        className="btn-link no-button mr-3"
        onClick={showModal}
      >
        <OpenIcon
          width={25}
          fill={colors['ui-Black2']}
        />
      </button>
    );

    const OpenTextButton = ({ showModal }) => (
      <button
        type="button"
        className="btn-link no-button mr-3"
        onClick={showModal}
      >
        <FormattedMessage id="widgetLinked.addwidget" />
      </button>
    );

    const ModalDOM = ModalHOC({
      ButtonOpen: ({ showModal, buttonModal: ButtonModal } = props) => (
        <ButtonModal showModal={showModal} />
      ),
      ModalContent: ({ hideModal }) => CreateLinkedWidget({ ...props, hideModal }),
      ModalTitle: ({ hideModal }) => (
        <i
          className="uil uil-times closeButton"
          onClick={hideModal}
          role="presentation"
        />
      ),
    });

    const LinkedWidgetFooter = () => {
      const someWidgets = props?.containedWidgets?.length;
      let footer;
      if (selection.length > 0) {
        if (someWidgets === 0) {
          footer = (
            <div className="linkedFooter">
              {formatLayout}
              <FormattedMessage id="widgetLinked.no-device" />
              <ModalDOM buttonModal={OpenTextButton} />
            </div>
          );
        } else {
          footer = (
            <>
              {formatLayout}
              <Linked
                config={widget.config}
                widget={widget}
                containedWidgets={
                  containedWidgets.filter(
                    (w) => w.origins.some(
                      (o) => selection.some(
                        (id) => o.connectedDevices.id === id,
                      ),
                    ),
                  )
                }
                renderLinkedWidgets={renderLinkedWidgets}
                updateWidgetLinkedLayout={updateWidgetLinkedLayout}
              />
            </>
          );
        }
      } else {
        footer = (
          <div className="linkedFooter">
            {formatLayout}
            <FormattedMessage id="widgetLinked.somedevices" />
          </div>
        );
      }
      return footer;
    };

    switch (widget.widgetType) {
      case 'BARS': {
        const values = isLinked && widgetValues[widget.container]
          ? parsedValueToLinked
          : widgetValues[widget.id];
        const dataBoolean = values && Object.keys(values).length && values.data.length;
        return (
          <WidgetCard
            className={`d-block ${!dataBoolean ? 'd-flex align-items-center' : ''}`}
            title={widget.name}
            actions={actions}
          >
            {dataBoolean
              ? (
                <BarChart
                  onTranslate={localeTranslate}
                  height="100%"
                  details={values}
                  title={widget.name}
                  actions={actions}
                  theme={configTheme}
                  config={widget.config}
                />
              )
              : <h1 className="text-center"><FormattedMessage id="widget.status.noData" /></h1>}

          </WidgetCard>
        );
      }

      case 'LINES': {
        const values = isLinked && widgetValues[widget.container]
          ? parsedValueToLinked?.series
          : widgetValues[widget.id]?.series;
        return (
          <WidgetCard
            className={`d-block ${!values?.length ? 'd-flex align-items-center' : ''}`}
            title={widget.name}
            actions={actions}
          >
            {values?.length
              ? (
                <LineChart
                  onTranslate={localeTranslate}
                  height="100%"
                  series={values}
                  echarts={echarts}
                  theme={configTheme}
                  config={widget.config}
                  intl={intl}
                />
              )
              : <h1 className="text-center"><FormattedMessage id="widget.status.noData" /></h1>}
          </WidgetCard>
        );
      }

      case 'NEEDLE': {
        const value = isLinked && widgetValues[widget.container]
          ? parsedValueToLinked
          : parseInt(widgetValues[widget.id], 10);
        const filteredOrigins = widget.origins.filter(
          (origin) => selection.includes(origin.connectedDevices.id),
        );
        const filteredSelection = filteredOrigins.filter((fO) => {
          const allAttributes = getAllDeviceAttributes(fO.connectedDevices);
          return allAttributes.includes(widget.config.data.attributeFilter[0]);
        });
        const selectedDevices = (isLinked && selection) ? filteredSelection.length : 0;

        return (
          <WidgetCard title={widget.name} actions={actions} dark={dark}>
            <Needle
              name={widget.origins[0].connectedDevices.device_id}
              widget={widget}
              value={value}
              config={widget.config}
              hasBeenResized={hasBeenResized}
              selectedDevices={selectedDevices}
            />
          </WidgetCard>

        );
      }
      case 'TEXT_ACCOUNTANT': {
        let index = 0;
        const values = isLinked && widgetValues[widget.container]
          ? parsedValueToLinked
          : widgetValues?.[widget.id];
        const connectedDevice = widget?.origins?.[0]?.connectedDevices;
        const atributes = connectedDevice ? [...connectedDevice.attributes,
          ...connectedDevice.command_attributes,
          ...connectedDevice.lazy_attributes,
          ...connectedDevice.static_attributes,
        ] : [];

        if (!values || typeof values !== 'object' || Array.isArray(values)) {
          index = -1;
        } else {
          index = (
            Object.keys(values?.[1].series).findIndex((key, i) => (
              atributes.includes(values[1].series[i].attribute)
              && values[1].series[i].device_id === connectedDevice.device_id
            ))
          );
        }
        return (
          <WidgetCard title={widget.name} actions={dark ? actionsDark : actions} dark={dark}>
            <Kpi
              onTranslate={localeTranslate}
              text={widget.name}
              deviceCategory={connectedDevice?.readDevice
                ? connectedDevice.readDevice.categories
                : []}
              number={values}
              widgetNumber={index}
              config={widget.config}
              hasBeenResized={hasBeenResized}
              dark={dark}
            />
          </WidgetCard>
        );
      }

      case 'MAP':
        // ToDo Improve that
        if (!widgetValues[widget.id]) {
          return (
            <WidgetCard title={widget.name} actions={actions}>
              No ha sido posible cargar los datos del dispositivo, compruebe
              la configuración e inténtelo de nuevo.
            </WidgetCard>
          );
        }
        return (
          <div className="h-100" ref={mapRef}>
            <WidgetMapUber
              refreshMap={refreshMap}
              setRefreshMap={setRefreshMap}
              config={widget.config}
              title={widget.name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              actions={React.memo((propsAct) => <ActionsForMaps {...propsAct} />, () => true)}
              data={widgetValues[widget.id]
                ? widgetValues[widget.id].map((device) => ({ ...spreadObject(device) }))
                : []}
              connectedDevices={
                widget.origins
                  .map((o) => o.connectedDevices.readDevice)
                  .filter((o) => o !== undefined)
              }
              widgetType={widget.widgetType}
              widget={widget}
              hasBeenResized={hasBeenResized}
              updateWidget={updateWidget}
              mapRef={mapRef}
            />
          </div>
        );

      case 'HEATMAP':
        if (!widgetValues[widget.id]) {
          return (
            <WidgetCard title={widget.name} actions={actions}>
              No ha sido posible cargar los datos del dispositivo, compruebe
              la configuración e inténtelo de nuevo.
            </WidgetCard>
          );
        }
        return (
          <WidgetMap
            configuration={widgetValues[widget.id]}
            config={widget.config}
            title={widget.name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            actions={(propsAct) => <ActionsForMaps {...propsAct} />}
            data={widgetValues[widget.id]}
            connectedDevices={
              widget.origins.map((o) => o.connectedDevices.readDevice)
                .filter((o) => o !== undefined)
            }
            widgetType={widget.widgetType}
          />
        );

      case 'TABLE': {
        const values = isLinked && widgetValues[widget.container]
          ? parsedValueToLinked
          : widgetValues[widget.id];
        return (
          <WidgetCard title={widget.name} actions={actions}>
            {values?.length
              ? (
                <Table
                  data={values}
                  sendCommand={handleOpenProfileSendingCommands}
                  config={widget.config}
                  origins={
                    widget.origins.map((o) => o.connectedDevices.readDevice)
                      .filter((o) => o !== undefined)
                  }
                  containerId={widget.container}
                  onTranslate={localeTranslate}
                />
              )
              : <h1 className="text-center"><FormattedMessage id="widget.status.noData" /></h1>}
          </WidgetCard>
        ); }

      case 'HTML':
        return (
          <Html
            config={widget.config}
            actions={actions}
            refresh={isResizable}
          />
        );

      case 'SEND_COMMAND_SINGLE':
        return (
          <WidgetSendCommandSingle
            text={widget.name}
            subtext={widget.description}
            state_a={widget.config?.state_a ?? []}
            actions={actions}
          />
        );

      case 'SEND_COMMAND_DUAL':
        return (
          <WidgetSendCommandDual
            text={widget.name}
            subtext={widget.description}
            state_a={widget.config.state_a}
            state_b={widget.config.state_b}
            actions={actions}
          />
        );

      case 'PARAMETRIZED_TEXT':
        return (
          <ParagraphDataProvider>
            <CustomWidgetParagraphWrapper
              widget={widget}
              widgetModel={Widget}
              updateWidget={updateWidget}
              values={widgetValues[widget.id]}
              text={widget.config?.custom?.['PARAMETRIZED_TEXT'].content}
              parameters={widget.config?.custom?.['PARAMETRIZED_TEXT'].parameters}
              config={widget.config}
              clickView={handleOpenProfileWidget}
              dashboard
              dark={dark}
              permissionToEdit={permissionToEdit}
            >
              <Selectable />
            </CustomWidgetParagraphWrapper>

          </ParagraphDataProvider>
        );
      case 'PERCENTAGE': {
        const value = isLinked && widgetValues[widget.container]
          ? parsedValueToLinked
          : widgetValues[widget.id];
        return (
          <WidgetCard title={widget.name} actions={actions}>
            <Percentage
              value={value}
              config={widget.config}
            />
          </WidgetCard>
        );
      }
      case 'IMAGE':
        return (
          <div>
            <div className="addWidgetToImage">
              <WidgetImageOptions
                dashboard={dashboard}
                widgetImageId={widget.id}
                actions={actions}
              />
            </div>
            <Image
              config={widget.config}
              widget={widget}
              containedWidgets={containedWidgets}
              renderImageWidgets={renderImageWidgets}
              updateWidgetImageLayout={updateWidgetImageLayout}
            />
          </div>
        );
      case 'LINKED': {
        // TODO: "Move LINKED WIDGET to an external file located in thw Widgets Folder."
        return (
          <div className="linkedWidgetWrapper">
            <div className="widgetLinkedHeader">
              <div className="widgetLinkedName">
                <h2>
                  {widget.name}
                </h2>
                <button className="btn btn-link no-button" onClick={() => updateWidgetLinkedMode(widget)} type="button">
                  <i className="uil uil-exchange-alt" />
                  {widget.config.custom.LINKED.mode === 'MAP'
                    ? <span><FormattedMessage id="widgetLinked.switch.table" /></span>
                    : <span><FormattedMessage id="widgetLinked.switch.map" /></span>}
                </button>
              </div>
              <div className="widgetOptions">
                <ModalDOM buttonModal={OpenIconButton} className="modal-linked" />
                {actions}
              </div>
            </div>
            {widgetValues[widget.id] && (
              <div className={`widgetLinkedMapAndTable ${widget.config.custom.LINKED.mode === 'MAP' ? 'map-mode' : 'table-mode'}`} ref={mapRef}>
                {widget.config.custom.LINKED.mode === 'MAP'
                  ? (
                    <div
                      onMouseMove={(e) => handleMouseOverMap(e.shiftKey)}
                      role="presentation"
                    >
                      <WidgetMapUber
                        refreshMap={refreshMap}
                        setRefreshMap={setRefreshMap}
                        config={widget.config}
                        onItemClick={handleClickItem}
                        onSelect={handleTableSelection}
                        // title={widget.name}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        actions={React.memo(
                          (propsAct) => <ActionsForMaps {...propsAct} />, () => true,
                        )}
                        data={widgetValues?.[widget.id]?.map(
                          (device) => ({ ...spreadObject(device) }),
                        ) || []}
                        connectedDevices={
                          widget.origins
                            .map((o) => o.connectedDevices.readDevice)
                            .filter((o) => o !== undefined)
                        }
                        widgetType={widget.widgetType}
                        widget={widget}
                        hasBeenResized
                        updateWidget={updateWidget}
                        isLinked
                        selectedItems={selection}
                        mapRef={mapRef}
                        drawableSelection
                      />
                    </div>
                  )
                  : (
                    <Table
                      data={widgetValues[widget.id]}
                      enableFirstLastButton={false}
                      sendCommand={handleOpenProfileSendingCommands}
                      config={widget.config}
                      origins={
                        widget.origins.map(
                          (o) => o.connectedDevices.readDevice,
                        ).filter((o) => o !== undefined)
                      }
                      onTranslate={localeTranslate}
                      selectable={handleTableSelection}
                      selectedItems={selection}
                    />
                  )}
              </div>
            )}
            <div
              className={
              `${selection.length === 0 ? 'collapsed' : 'widgetLinkedDragAndDrop'} ${props?.containedWidgets?.length === 0 ? 'widgetLinkedDragAndDropCollapsed' : ''} ${widgetSize?.h > 8 ? 'normal' : 'bigger'}`
              }
            >
              {LinkedWidgetFooter()}
            </div>
          </div>
        );
      }
      case 'ONOFF':
        return (
          <WidgetCard title={widget.name} actions={actions} dark={dark}>
            <OnOff
              permissionToEdit={actions?.props?.permissionToEdit}
              updateWidget={updateWidget}
              widget={widget}
              config={widget.config}
              sendCommands={sendCommands}
              isPreview={isPreview}
            />
          </WidgetCard>
        );
      case 'SLIDER':
        return (
          <WidgetCard title={widget.name} actions={actions}>
            <Slider
              updateWidget={updateWidget}
              widget={widget}
              config={widget.config}
              sendCommands={sendCommands}
              isPreview={!!isPreview}
              permissionToEdit={actions?.props?.permissionToEdit}
            />
          </WidgetCard>
        );
      default:
        return (
          <WidgetCard title={widget.name} actions={actions}>
            <FormattedMessage id="widgetLinked.error-loading" />
          </WidgetCard>
        );
    }
  };

  const classNamePreview = widget.isSmallWidget() ? 'smallWidgetPreview' : 'longWidgetPreview';
  return (
    <>
      <Suspense fallback="">
        <div ref={fromRef} className={`${isPreview ? classNamePreview : 'h-100'}`}>
          {widget.version === 'v2'
            ? <VersionError widget={widget} actions={actions} />
            : getWidget()}
        </div>
      </Suspense>
    </>
  );
});

export default RenderWidget;
