import React from 'react';
import Menu from '../../../../components/Menu';
import OptionsForWidget from './optionsForWidget';
import './styles.scss';

const openButton = (props) => (
  <div
    onClick={props.onClick}
    role="presentation"
  >
    <span className="titleText">
      <i className="uil uil-ellipsis-v" />
    </span>
  </div>
);

const WidgetOptionsMenu = ({
  widget, clickView, clickSendingCommandsView, sources, ...rest
}) => (
  <Menu
    top="40px"
    openComponent={openButton}
    openFrom="right"
    className="more"
  >
    <OptionsForWidget
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      widget={widget}
      sources={sources}
      clickView={clickView}
      clickSendingCommandsView={clickSendingCommandsView}
    />
  </Menu>
);

const WidgetMenuButton = (props) => {
  const {
    title, date, widget, clickView, clickSendingCommandsView, permissionToEdit, sources, ...res
  } = props;
  return (
    <WidgetOptionsMenu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...res}
      clickSendingCommandsView={
        // eslint-disable-next-line no-shadow
        (widget, profileType) => clickSendingCommandsView(widget, profileType, props.entity)
      }
      clickView={
        // eslint-disable-next-line no-shadow
        (widget, profileType) => clickView(widget, profileType, props.entity)
      }
      title={title}
      date={date}
      widget={widget}
      sources={sources}
      permissionToEdit={widget.hasPermissionsToEdit}
    />

  );
};

export default WidgetMenuButton;
