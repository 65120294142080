import React, { useState, useEffect } from 'react';
import '../styles.scss';
import {
  getEnabledNumber,
  initCheckedList,
  updateCheckedList,
  areAllChecked,
} from './utils';
import SendCommandItem from './SendCommandItem';
import ListHeader from './ListHeader';

const SendCommandList = ({
  devices, devicesInfo, checkable, onChange, errors, nameless,
}) => {
  const [checkedList, setCheckedList] = useState(
    initCheckedList(devices, false),
  );

  useEffect(() => {
    devicesInfo({
      total: devices.length,
      enabled: getEnabledNumber(devices),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sendCommandList">
      { devices && getEnabledNumber(devices) > 0 && (
      <ListHeader
        checkedList={checkedList}
        onClick={() => setCheckedList(initCheckedList(devices, !areAllChecked(checkedList)))}
        nameless={nameless}
        checkable={checkable}
      />
      )}

      {devices
&& devices.map((d, i) => (
  <SendCommandItem
// eslint-disable-next-line react/no-array-index-key
    key={i}
    device={d}
    checkable={checkable}
    checked={checkedList[i]}
    onCheckboxClick={() => updateCheckedList(i, checkedList, setCheckedList)}
    onChange={(data) => onChange({ ...data, index: i })}
    errors={errors[i] || {}}
    nameless={nameless}
  />
))}
    </div>
  );
};

export { SendCommandList };
export default SendCommandList;
