import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as LuminarieIcon } from '../../configuration/icons/svg/devices/Luminary.svg';
import { ReactComponent as ElectricMeterIcon } from '../../configuration/icons/svg/devices/Electric_meter.svg';
import { ReactComponent as ElectricPanelIcon } from '../../configuration/icons/svg/devices/Electric_panel.svg';
import { ReactComponent as EnvironmentalQualityIcon } from '../../configuration/icons/svg/devices/Environmental_quality.svg';
import { ReactComponent as GenericIcon } from '../../configuration/icons/svg/devices/Generic.svg';
import { ReactComponent as GPSIcon } from '../../configuration/icons/svg/devices/GPS.svg';
import { ReactComponent as HealthIcon } from '../../configuration/icons/svg/devices/Health.svg';
import { ReactComponent as IrrigationIcon } from '../../configuration/icons/svg/devices/Irrigation.svg';
import { ReactComponent as ParkingIcon } from '../../configuration/icons/svg/devices/Parking.svg';
import { ReactComponent as VideoCameraIcon } from '../../configuration/icons/svg/devices/Video_camera.svg';
import { ReactComponent as WasteIcon } from '../../configuration/icons/svg/devices/Waste.svg';
import { ReactComponent as WaterMeterIcon } from '../../configuration/icons/svg/devices/Water_meter.svg';
import { ReactComponent as WeatherIcon } from '../../configuration/icons/svg/devices/Weather.svg';
import { FormattedMessage } from '../../Contexts/LanguageContext';


class DeviceIcon extends React.PureComponent {
  getIcon() {
    const { categories, className } = this.props;
    const category = categories && categories[0] ? categories[0] : 'other';
    switch (category) {
      case 'LUMINAIRE':
        return <LuminarieIcon title={<FormattedMessage id="devices.wizard.categories.luminaire" />} className="deviceIcon" />;
      case 'WASTE_CONTAINER':
        return <WasteIcon title={<FormattedMessage id="devices.wizard.categories.waste_container" />} className="deviceIcon" />;
      case 'WEATHER_STATION':
        return <WeatherIcon title={<FormattedMessage id="devices.wizard.categories.weather_station" />} className="deviceIcon" />;
      case 'ENVIRONMENTAL_QUALITY':
        return <EnvironmentalQualityIcon title={<FormattedMessage id="devices.wizard.categories.environmental_quality" />} className="deviceIcon" />;
      case 'GPS':
        return <GPSIcon title={<FormattedMessage id="devices.wizard.categories.gps" />} className="deviceIcon" />;
      case 'PARKING':
        return <ParkingIcon title={<FormattedMessage id="devices.wizard.categories.parking" />} className="deviceIcon" />;
      case 'ELECTRIC_PANEL':
        return <ElectricPanelIcon title={<FormattedMessage id="devices.wizard.categories.electric_panel" />} className="deviceIcon" />;
      case 'HEALTH':
        return <HealthIcon title={<FormattedMessage id="devices.wizard.categories.health" />} className="deviceIcon" />;
      case 'VIDEO_CAMERA':
        return <VideoCameraIcon title={<FormattedMessage id="devices.wizard.categories.video_camera" />} className="deviceIcon" />;
      case 'ELECTRIC_METER':
        return <ElectricMeterIcon title={<FormattedMessage id="devices.wizard.categories.electric_meter" />} className="deviceIcon" />;
      case 'WATER_METER':
        return <WaterMeterIcon title={<FormattedMessage id="devices.wizard.categories.water_meter" />} className="deviceIcon" />;
      case 'IRRIGATION':
        return <IrrigationIcon title={<FormattedMessage id="devices.wizard.categories.irrigation" />} className="deviceIcon" />;
      default:
        return <GenericIcon title={<FormattedMessage id="devices.wizard.categories.other" />} className="deviceIcon" />;
    }
  }

  render() {
    return this.getIcon();
  }
}

DeviceIcon.defaultProps = {
  categories: [],
  className: '',
};

DeviceIcon.propTypes = {
  /** Allows any MaterialUI icon by sending a string in CamelCase with the icon name. */
  categories: PropTypes.array.isRequired,
  /** Allows specify a css class that overrides the default styles fixed by MaterialUI */
  className: PropTypes.string,
};


export default DeviceIcon;
