import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { clone as cloneRamda } from 'ramda';
import React from 'react';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import Field from '../../../elements/Field/index';
import CommonView from '../../CommonView';
import { getUsers } from '../../../services/redux/users/actions';
import ListOfMembers, {
  Member,
} from '../Add/components/ListOfMembers';
import CardHoc from '../../../elements/Card/index';
import Finder from '../../../components/Finder/index';
import { getUsersGroups } from '../../../services/redux/usersGroups/actions';
import User from '../../../models/User/index';
import CheckBox from '../../../elements/CheckBox';
import { withWizardStep } from '../../../Contexts/WizardStepContext';
import { clone } from '../../../helpers/utils';

import './styles.scss';

const Card = CardHoc(
  Finder((props) => props.data.map((d, i) => (
    <div
      key={i}
      className="memberWrapper"
      onClick={(e) => props.handleOnChange(e, { value: d })}
    >
      <Member user={d} />
    </div>
  ))),
);

class AssignPermissions extends CommonView {
  constructor(props) {
    super({ props });

    const {
      usersWithPermissions, usersGroupsWithPermissions, usersGroups, users, newEntity, errors,
    } = this.props;
    this.state = {
      errors: errors || '',
      onValidation: false,
      availableUsers: usersWithPermissions ? this.filterMembers(users, usersWithPermissions) : [],
      availableUsersGroups: usersGroupsWithPermissions ? this.filterMembers(usersGroups, usersGroupsWithPermissions) : [],
      savedUsers: usersWithPermissions || [],
      savedUsersGroups: usersGroupsWithPermissions || [],
      newEntity: !newEntity.permissions_policy
        ? {
          ...newEntity,
          permissions_policy: { users: [], groups: [], public: false },
        }
        : newEntity,
    };
  }

  validate = () => {
    this.saveEntity();
  };

  saveEntity = () => {
    if (this.props.onSave) {
      this.props.onSave(this.state, this.props.entity, this.props.onContinue);
    } else {
      this.setErrors({});
      const created = new this.props.entity(this.state.newEntity).save();

      if (created.error) {
        if (created.error === true) {
          delete created.error;
        }
        this.setErrors(created);
        this.props.onError(true);
      } else {
        this.props.onContinue(this.state.newEntity, this.props.extraData);
      }
    }
  };

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    getUsers();
    getUsersGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      users, usersGroups, usersWithPermissions, usersGroupsWithPermissions,
    } = this.props;

    if (prevProps.users !== users) {
      this.setState({
        availableUsers: this.filterMembers(users, this.state.savedUsers),
      });
    }

    if (prevProps.usersGroups !== usersGroups) {
      this.setState({
        availableUsersGroups: this.filterMembers(usersGroups, this.state.savedUsersGroups),
      });
    }

    if (prevProps.usersWithPermissions !== usersWithPermissions) {
      this.setState({
        savedUsers: usersWithPermissions || [],
        availableUsers: usersWithPermissions ? this.filterMembers(users, usersWithPermissions) : [],
      });
    }

    if (prevProps.usersGroupsWithPermissions !== usersGroupsWithPermissions) {
      this.setState({
        savedUsersGroups: usersGroupsWithPermissions || [],
        availableUsersGroups: usersGroupsWithPermissions ? this.filterMembers(usersGroups, usersGroupsWithPermissions) : [],
      });
    }
  }

  handleUsersComboChange = (name, value) => {
    let saved;

    value instanceof User
      ? (saved = clone(this.state.savedUsers))
      : (saved = clone(this.state.savedUsersGroups));

    saved.push(value);

    const newEntity = cloneRamda(this.state.newEntity);
    const typeOfMember = value instanceof User ? 'users' : 'groups';

    newEntity.permissions_policy[typeOfMember].push({
      id: value.id,
      read: true,
      write: false,
    });

    if (value instanceof User) {
      this.setState({
        newEntity,
        savedUsers: saved,
        availableUsers: this.filterMembers(this.props.users, saved),
      });
    } else {
      this.setState({
        newEntity,
        savedUsersGroups: saved,
        availableUsersGroups: this.filterMembers(this.props.usersGroups, saved),
      });
    }

    this.props.saveOnTheFly && this.props.saveOnTheFly(newEntity);
  };

  handleOnDelete = (member) => {
    let saved;

    member instanceof User
      ? (saved = clone(this.state.savedUsers).filter((u) => u.id !== member.id))
      : (saved = clone(this.state.savedUsersGroups).filter((u) => u.id !== member.id));

    const newEntity = cloneRamda(this.state.newEntity);
    const typeOfMember = member instanceof User ? 'users' : 'groups';
    newEntity.permissions_policy[typeOfMember] = newEntity.permissions_policy[
      typeOfMember
    ].filter((u) => u.id !== member.id);

    if (member instanceof User) {
      this.setState({
        newEntity,
        savedUsers: saved,
        availableUsers: this.filterMembers(this.props.users, saved),
      });
    } else {
      this.setState({
        newEntity,
        savedUsersGroups: saved,
        availableUsersGroups: this.filterMembers(this.props.usersGroups, saved),
      });
    }
    this.props.saveOnTheFly && this.props.saveOnTheFly(newEntity);
  };

  handleCheckBoxChange = (permission, member = null) => {
    if (permission !== 'read') {
      const memberType = member instanceof User ? 'users' : 'groups';
      const newEntity = { ...this.state.newEntity };
      const selectedMember = newEntity.permissions_policy[memberType].find(
        (u) => member.id === u.id,
      );
      selectedMember[permission] = !selectedMember[permission];

      this.setState({
        newEntity,
      });

      this.props.saveOnTheFly && this.props.saveOnTheFly(newEntity);
    }
  };

  handleOnPublicAccessClick = () => {
    const newEntity = { ...this.state.newEntity };
    newEntity.permissions_policy.public = !newEntity.permissions_policy.public;
    this.setState({ newEntity });

    this.props.saveOnTheFly && this.props.saveOnTheFly(newEntity);
  };

  filterMembers = (users, usersSaved) => {
    const ownerId = !this.props.userOwner ? '' : this.props.userOwner.id;
    return users.filter((u) => ownerId !== u.id
      && !usersSaved.some((uwp) => uwp.id === u.id));
  };

  getTheOwner = () => {
    const { userMe, userOwner } = this.props;
    if (userOwner) {
      return [userOwner];
    } if (userMe) {
      return [userMe];
    }
    return [];
  };

  getAvailableMembers = () => {
    const { availableUsers } = this.state;
    const { availableUsersGroups } = this.state;
    return [...availableUsers, ...availableUsersGroups].sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
  };

  render() {
    const { intl } = this.props;
    const { savedUsers, savedUsersGroups, newEntity } = this.state;
    const permissionToEdit = this.props.permissionToEdit === undefined
      ? true
      : this.props.permissionToEdit;
    return (
      <>
        {permissionToEdit
        && (
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 8, offset: 2 }}>
            <div className="d-flex align-items-center">
              {['selectedUsers'].map((key, i) => (
                <Field
                  name={key}
                  id={key}
                  placeholder={intl.formatMessage({
                    id: 'ListOfMembers.select.users.permissions',
                  })}
                  type="customSelect"
                  value=""
                  mappedBy="name"
                  formatOption={['name', 'surname']}
                  equalsBy="id"
                  options={this.getAvailableMembers()}
                  onChange={this.handleUsersComboChange}
                  key={`${key}-${i}`}
                  customOptions={Card}
                  newEntity={newEntity}
                />
              ))}
            </div>
          </Col>
        </Row>
        )}
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 8, offset: 2 }}>
            <div className="d-flex justify-content-end">
              <CheckBox
                label={<FormattedMessage id="ListOfMembers.publicAccess" />}
                checked={newEntity.permissions_policy.public}
                onClick={this.handleOnPublicAccessClick}
                fixed={!permissionToEdit}
                id="publicAccessCheckbox"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 8, offset: 2 }}>
            <ListOfMembers
              newEntity={newEntity}
              title="ListOfMembers.owner.list"
              users={this.getTheOwner()}
              options={false}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 8, offset: 2 }}>
            {savedUsers.length > 0 && (
              <ListOfMembers
                newEntity={newEntity}
                title="ListOfMembers.users.list"
                users={savedUsers || []}
                onDelete={this.handleOnDelete}
                permissionsOptions
                options
                onCheckBoxChange={this.handleCheckBoxChange}
                typeOfMembers="users"
                permissionToEdit={permissionToEdit}
                saveOnTheFly={this.props.saveOnTheFly}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 8, offset: 2 }}>
            {savedUsersGroups.length > 0 && (
              <ListOfMembers
                newEntity={newEntity}
                title="ListOfMembers.usersGroups.list"
                users={savedUsersGroups || []}
                onDelete={this.handleOnDelete}
                permissionsOptions
                options
                onCheckBoxChange={this.handleCheckBoxChange}
                typeOfMembers="groups"
                permissionToEdit={permissionToEdit}
                saveOnTheFly={this.props.saveOnTheFly}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.get('users').get('list').toJS(),
  usersGroups: state.get('usersGroups').get('list').toJS(),
  errorFetching: state.get('users').get('errorFetching'),
  notify: state.get('notify'),
  userMe: state.get('users').get('userMe'),
});

export const AssignPermissionsWithIntl = injectIntl(AssignPermissions);

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(AssignPermissions)));
