import {
  takeLatest, takeEvery, call, put, all, fork,
} from 'redux-saga/effects';
import Service from '../../../models/Service';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/services';

/**
 * Actions for WATCHERS
 * */

// Try to recover all service form DB
export function* getServicesThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readServices,
      payload.page,
      payload.size,
      payload.filters,
    );
    if (response.status === 200) {
      const services = response.data.objects.map((service) => new Service(service));
      const { total } = response.data;
      yield put({
        type: TYPES.GET_SERVICES_SUCCESS,
        payload: { services, total },
      });
    } else {
      yield put({ type: TYPES.GET_SERVICES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_SERVICES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getMyServicesThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readMyServices,
      payload.page,
      payload.size,
      payload.filters,
    );
    if (response.status === 200) {
      const services = response.data.objects.map((service) => new Service(service));
      const { total } = response.data;
      yield put({
        type: TYPES.GET_MY_SERVICES_SUCCESS,
        payload: { services, total },
      });
    } else {
      yield put({ type: TYPES.GET_MY_SERVICES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MY_SERVICES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an service form DB
export function* getServiceThroughApi({ payload }) {
  try {
    const response = yield call(Api.readService, payload);
    if (response.status === 200) {
      const serviceFromDB = new Service(response.data.object);
      yield put({ type: TYPES.GET_SERVICE_SUCCESS, payload: serviceFromDB });
    } else {
      yield put({ type: TYPES.GET_SERVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_SERVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


// Try to create service in DB
export function* addServicesThroughApi({ payload }) {
  try {
    const response = yield call(Api.addService, payload);
    if (response.status === 201) {
      const serviceFromDB = new Service(response.data.object);
      yield put({ type: TYPES.ADD_SERVICE_SUCCESS, payload: serviceFromDB });
    } else {
      yield put({ type: TYPES.ADD_SERVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_SERVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update service in DB
export function* updateServicesThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateService, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_SERVICE_SUCCESS,
        payload: new Service(payload),
      });
    } else {
      yield put({ type: TYPES.MODIFY_SERVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_SERVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete service in DB
export function* deleteServicesThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteService, payload);
    if (response.status === 204) yield put({ type: TYPES.DELETE_SERVICE_SUCCESS, payload });
    else {
      yield put({ type: TYPES.DELETE_SERVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_SERVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_SERVICES_REQUEST
function* watcherGetServices() {
  yield takeLatest('GET_SERVICES_REQUEST', getServicesThroughApi);
}

// Watcher looking for GET_MY_SERVICES_REQUEST
function* watcherGetMyServices() {
  yield takeLatest('GET_MY_SERVICES_REQUEST', getMyServicesThroughApi);
}

// Watcher looking for GET_SERVICE_REQUEST
function* watcherGetService() {
  yield takeLatest('GET_SERVICE_REQUEST', getServiceThroughApi);
}

// Watcher looking for ADD_SERVICE_REQUEST
function* watcherAddServices() {
  yield takeEvery('ADD_SERVICE_REQUEST', addServicesThroughApi);
}

// Watcher looking for MODIFY_SERVICE_REQUEST
function* watcherUpdateServices() {
  yield takeLatest('MODIFY_SERVICE_REQUEST', updateServicesThroughApi);
}

// Watcher looking for DELETE_SERVICE_REQUEST
function* watcherDeleteServices() {
  yield takeEvery('DELETE_SERVICE_REQUEST', deleteServicesThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetServices),
    fork(watcherGetMyServices),
    fork(watcherGetService),
    fork(watcherAddServices),
    fork(watcherUpdateServices),
    fork(watcherDeleteServices),
  ]);
}
