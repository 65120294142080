import React from 'react';
import { Responsive, WidthProvider } from '@fiwoo/react-grid-layout';
import './style.scss';
import { cloneDeep } from 'lodash';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const columns = 12;

class Grid extends React.Component {
  constructor(props) {
    super(props);
    const { layouts } = this.props;
    this.state = {
      layoutsState: { ...layouts },
    };
  }

  componentDidUpdate(prevProps) {
    const { layouts, children } = this.props;
    const { layoutsState } = this.state;
    if (JSON.stringify(layouts) !== JSON.stringify(layoutsState)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ layoutsState: { ...layouts } });
    }
    if (prevProps?.children.length !== children.length) {
      const layout = cloneDeep(layoutsState);
      const typeLayout = ['lg', 'md', 'sm', 'xs', 'xxs'];
      const updatedLayout = { i: `updated-${new Date().getTime()}` };
      typeLayout.forEach((type) => {
        if (layout[type]) layout[type].push(updatedLayout);
        else layout[type] = [updatedLayout];
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ layoutsState: { ...layout } });
    }
  }

  render() {
    const {
      breakpoints, isDraggable, rowHeight, className, layouts, isResizable,
      cols, rows, margin, padding, children, uniqueName, onChange, ...rest
    } = this.props;

    const { layoutsState } = this.state;
    return (
      <ResponsiveReactGridLayout
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        breakpoints={breakpoints}
        useCSSTransforms={false}
        isDraggable={isDraggable}
        isResizable={isResizable}
        className={'layout '.concat(className)}
        cols={cols}
        rowHeight={rowHeight}
        margin={[14, 14]}
        containerPadding={padding}
        layouts={layoutsState}
        draggableCancel=".MyNonDraggableAreaClassName, .input, .innerField, .inputCtr, input"
        onLayoutChange={
          (layout, currentLayouts) => onChange(layout, currentLayouts, uniqueName)
        }
        onChange={onChange}
      >
        {children}
      </ResponsiveReactGridLayout>
    );
  }
}

Grid.defaultProps = {
  className: '',
  rows: 0,
  margin: undefined,
  padding: undefined,
  children: null,
  layouts: {},
  breakpoints: {
    lg: 2000,
    md: 1470,
    sm: 940,
    xs: 570,
    xxs: 0,
  },
  cols: {
    lg: columns,
    md: columns,
    sm: columns,
    xs: columns,
    xxs: columns,
  },
  onChange: () => {},
  rowHeight: 50,
  uniqueName: 'layout-by-default',
  width: 1200,
  isDraggable: false,
  isResizable: false,
};

export default Grid;
