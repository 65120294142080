import React from 'react';
import Crumb from '../../elements/Crumb';
import { getHash } from '../../helpers/utils';

const getBreadCrumbs = (path) => {
  const ArrayOfPaths = path.split('/');
  return ArrayOfPaths.filter(() => path.trim() !== '');
};

class BreadCrumbs extends React.PureComponent {
  render() {
    const { path } = this.props;

    const ArrayOfBreadCrumbs = getBreadCrumbs(path);

    return (
      <div className="BreadCrumbs">
        {
          ArrayOfBreadCrumbs.map((crumb, index) => {
            let pathCleaned = path.split(crumb)[0];
            pathCleaned += crumb;

            return (
              <Crumb
                key={getHash(`${crumb}_${index}`)}
                last={index === (ArrayOfBreadCrumbs.length - 1)}
                name={crumb}
                path={pathCleaned}
              />
            );
          })
                }
      </div>
    );
  }
}

BreadCrumbs.defaultProps = {
  /** Accepts any route as a string. It will be splited by the '/' char */
  path: 'prop/path/in/breadcrumb',
};

export default BreadCrumbs;
