import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';


import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { Container, Row, Col } from 'reactstrap';
import UsersList from '../helpers/UsersList/index';

import './styles.scss';

export class AddUsers extends React.Component {
  
  render() {
    const { newEntityCreated } = this.props;
    return (
        <Container>
          <div className="wizardWrapper" id={'addUsers'}>
            <Row>
              <Col sm={{ size: 8, offset: 2 }}>
                <h1 className="text-center">
                  {newEntityCreated.name}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 8, offset: 2 }}>
                <UsersList {...this.props} newEntity={newEntityCreated} />
              </Col>
            </Row>
          </div>
        </Container>
    );
  }
}
const mapStateToProps = state => ({
  newEntityCreated: state.get('usersGroups').get('createdUsersGroup'),
});

export default connect(mapStateToProps, {})(injectIntl(withWizardStep(AddUsers)));
