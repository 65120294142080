/* eslint-disable import/no-cycle */
import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { clone } from 'ramda';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import CommonView from '../../../CommonView';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import { getHistoricalListPreview } from '../../../../services/redux/historicals/actions';
import { getDate, getResolution } from '../../../../helpers/samplingHistorical';
import RenderWidget from '../../../Dashboards/Show/components/RenderWidget';
import animationData from '../../../Dashboards/Show/components/Loading/dashboardLoadingLottie.json';
import Widget from '../../../../models/Widget';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

class WidgetPreview extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      widgetType: this.props.newEntity.widgetType,
      newEntity: this.props.newEntity,
      previewLoaded: false,
      errors: {},
    };
  }

  validate = () => {
    this.props.onContinue();
  };

  isEqualAggregate = (data, deviceId, attr, configuration) => data.entityId === deviceId
    && data.attrName === attr
    && data.resolution === getResolution(configuration.data.sampling)
    && data.origin >= getDate('startDate', { configuration }, 'YYYY-MM-DD')
    && data.origin <= getDate('endDate', { configuration });

  getAllDeviceAttributes = (device) => [
    ...(device.attributes || []),
    ...(device.lazy_attributes || []),
    ...(device.command_attributes || []),
    ...(device.static_attributes || []),
  ];

  addDataHistoricalTowidget = () => {
    const { widgetData } = this.props;
    const { widget } = this.state;
    const simpleData = widgetData.map((o) => o.data)
      .filter((o) => o.historicals && o.historicals.length > 0);
    const aggregateData = widgetData.map((o) => o.data)
      .filter((o) => o.values && o.values.length > 0);

    const simple = (
      !widget.widgetAggregate
      && widget.widgetType !== 'TEXT'
      && widget.widgetType !== 'SEND_COMMAND_SINGLE'
      && widget.widgetType !== 'SEND_COMMAND_DUAL');

    if (simple) {
      widget.origins.forEach((origin) => {
        const connectedDevice = origin.connectedDevices;
        const readHistorical = simpleData.find((o) => (
          o.historicals[0].entityId === connectedDevice.device_id));
        // eslint-disable-next-line no-param-reassign
        connectedDevice.historicaldata = readHistorical ? readHistorical.historicals : [];
        if (widget.widgetType === 'TEXT_ACCOUNTANT') {
          const deviceId = connectedDevice.device_id;
          const configuration = widget.config;
          const attr = connectedDevice.attributes[0];
          const readHistoricalAggregate = aggregateData.find((o) => (
            this.isEqualAggregate(o.values[0].id, deviceId, attr, configuration)));

          connectedDevice.historicaldata.push({
            attribute: attr,
            data: readHistoricalAggregate ? readHistoricalAggregate.values : [],
          });
        }
      });
    } else {
      widget.origins.forEach((origin) => {
        const connectedDevice = origin.connectedDevices;
        // eslint-disable-next-line no-param-reassign
        connectedDevice.historicaldata = [];
        if (widget.widgetType === 'HEATMAP') {
          const readHistorical = simpleData.find((o) => (
            o.historicals[0].entityId === connectedDevice.device_id));
          if (readHistorical) {
            // eslint-disable-next-line no-param-reassign
            connectedDevice.realtime = widget.config.type === 'real-time';
            // eslint-disable-next-line no-param-reassign
            connectedDevice.lastState = readHistorical.historicals;
          }
        }

        const attributes = this.getAllDeviceAttributes(connectedDevice);
        attributes.forEach((attr) => {
          const deviceId = connectedDevice.device_id;
          const configuration = widget.config;
          const readHistorical = aggregateData.find((o) => (
            this.isEqualAggregate(o.values[0].id, deviceId, attr, configuration)));
          connectedDevice.historicaldata.push({
            attribute: attr,
            data: readHistorical ? readHistorical.values : [],
          });
        });
      });
    }

    this.setState({
      widget,
      previewLoaded: true,
    });
  };

  componentDidMount() {
    this.props.onRef(this);

    const { newEntity } = this.state;

    const newWidget = clone(newEntity);
    // Todo is necessary?
    // newWidget.permissions_policy = newWidget.permissions_policy;
    newWidget.origins = newWidget.origins.map(
      (dataSource) => {
        const d = {
          readDevice: dataSource,
          device_id: dataSource.device_id,
          id: dataSource.id,
          attributes: [],
          lazy_attributes: [],
          static_attributes: [],
          command_attributes: [],
        };
        if (dataSource.joinedAttributes) {
          dataSource.joinedAttributes.forEach((attribute) => {
            if (attribute.selected) {
              switch (attribute.attrType) {
                case 'lazy':
                  d.lazy_attributes.push(attribute.name);
                  break;
                case 'static':
                  d.static_attributes.push(attribute.name);
                  break;
                default:
                  d.attributes.push(attribute.name);
              }
            }
          });
        } else {
          d.attributes.push('data');
          d.device_id = d.id;
        }

        let type = '';
        if (this.props.newEntity.origins[0].Model.entityName === 'Device') {
          type = 'DEVICE';
        } else if (this.props.newEntity.origins[0].Model.entityName === 'ETLProcedure') {
          type = 'ETL';
        } else if (this.props.newEntity.origins[0].Model.entityName === 'Kpi') {
          type = 'KPI';
        }

        return { type, connectedDevices: d };
      },
    );

    if (newWidget.widgetType === 'SEND_COMMAND') {
      newWidget.widgetType = (newWidget.config.second_state ? 'SEND_COMMAND_DUAL' : 'SEND_COMMAND_SINGLE');
    }

    // QueryWidget realData
    const widgetToSave = new Widget(newWidget);
    const injectedData = widgetToSave.injectData(widgetToSave);
    if (injectedData) widgetToSave.config = injectedData;
    const query = widgetToSave.getQueryHistorical();
    getHistoricalListPreview(query);

    this.setState({
      widget: widgetToSave,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.widgetData !== this.props.widgetData) {
      this.addDataHistoricalTowidget();
    }
  }

  render() {
    const { widget, previewLoaded } = this.state;
    const wvalues = [];

    if (previewLoaded) {
      wvalues[widget.id] = widget.formatToData();
    }

    return (
      <>
        <Container>
          <div className="WizardPreview">
            <Row>
              {/* ToDo Remove class fix preview widgetMap */}
              <Col className={`preview-${widget?.widgetType?.toLowerCase()}`} lg={{ size: 8, offset: 2 }}>
                {(!widget || !previewLoaded)
                  ? (
                    <Lottie
                      className="lottie"
                      options={defaultOptions}
                      height={280}
                      width={280}
                    />
                  ) : (
                    <RenderWidget
                      previewMode
                      widget={widget}
                      index={0}
                      array={1}
                      dataWidgetRead
                      wValues={wvalues}
                      profileType={false}
                      handleOpenProfileWidget={false}
                      // setWidgetValues={setDataFromWidget}
                      handleOpenProfileSendingCommands={false}
                      permissionToEdit={false}
                      disableOptions
                    />
                  )}

              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fetching: state.get('dashboards').get('fetching'),
  dashboard: state.get('dashboards').get('readDashboard'),
  errorFetching: state.get('dashboards').get('errorFetching'),
  widgetData: state.get('historicals').get('previewList'),
  devices: state.get('devices').get('devicesList'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(WidgetPreview)));
