import { v4 as uuidv4 } from 'uuid';
import { calculate } from '../../../utils';
import { getResolution } from '../../../../../helpers/samplingHistorical';

const mergePoints = (historicalData, operation, precision = 2) => {
  const newPoints = [];
  const origins = {};
  historicalData.forEach((historical) => {
    historical.points.forEach((point) => {
      if (origins[point.offset]) {
        origins[point.offset].push(point[operation] ?? point.avg);
      } else {
        origins[point.offset] = [point[operation] ?? point.avg];
      }
    });
  });
  Object.keys(origins).forEach((offset) => {
    newPoints.push({
      offset: Number(offset),
      [operation]: calculate(origins[offset], operation, precision),
    });
  });

  return newPoints;
};

export const calculateAggregateValues = (widget, historical) => {
  const {
    attributeFilter,
    operation,
    sampling,
  } = widget.config.data;
  const aggregateValues = historical
    .filter((h) => h.entities);

  const objectArray = aggregateValues.map((aggregate) => {
    const origin = { ...aggregate };
    const attrsEntities = {};
    attributeFilter.forEach((attr) => {
      attrsEntities[attr] = aggregate.entities.filter((entity) => entity.attrName === attr);
    });
    const entities = attributeFilter.map((attr) => {
      const points = mergePoints(attrsEntities[attr], operation);
      const data = {
        attrName: attr,
        entityId: 'data',
        resolution: getResolution(sampling),
        urn: 'fiwoo:device:random',
        points,
      };
      return data;
    });
    return { ...origin, entities };
  });

  return objectArray;
};

export default calculateAggregateValues;
