import * as TYPES from './types';
import { Map, List } from 'immutable';

export const exampleFiware = [
  { service: 'service1', servicepath: 'servicepath1' },
  { service: 'service2', servicepath: 'servicepath2' },
];
export const examplePermissionPolicy = ['Group1', 'Group2', 'Group3', 'Group4'];
export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];
export const exampleProtocol = ['IoTA-UL', 'json', 'SIGFOX', 'LoRa'];

/** Initial State of Templatess */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
});

/** TEMPLATES REDUCER */
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    case TYPES.GET_PUBLIC_TEMPLATES_SUCCESS:
    case TYPES.GET_TEMPLATES_SUCCESS:
    case TYPES.GET_MY_TEMPLATES_SUCCESS:
    case TYPES.UPDATE_TEMPLATES_STATE:
      const templateId = payload.templates.map(o => o.id);
      const templates = state
        .get('list')
        .toJS()
        .filter(o => !templateId.includes(o.id));

      return state
        .set('total', payload.total)
        .set('list', List([...templates, ...payload.templates]))
        .set('fetching', false)
        .set('templateAdded', false)
        .set('templateDeleted', false);

    /** INITIALIZE GET TEMPLATES FROM DB */
    case TYPES.GET_MY_TEMPLATES_ERROR:
      return state
        .set('errorFetching', payload)
        .set('fetching', false)
        .set('list', List([]))
        .set('templateAdded', false)
        .set('templateDeleted', false);

    /* case TYPES.GET_MY_TEMPLATES_SUCCESS:
            return state
                .set('total', payload.total)
                .set('list', List(payload.templates))
                .set('fetching', false) */

    /** GET TEMPLATE FROM DB TO STATE */
    case TYPES.GET_TEMPLATE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      //TODO
      //If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1
        ? state.update('list', userList => userList.push(payload))
        : state;

    ////////////////////////////////////////////////////////
    //////// GET SINGLE TEMPLATE ROLES
    ////////////////////////////////////////////////////////

    /** GET TEMPLATE ROLES FROM DB TO STATE */
    case TYPES.GET_TEMPLATE_ROLES_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      //TODO
      //Update roles
      if (index !== -1) {
        const newArray = state.get('list').toJS();
        newArray[index].roles = payload.roles;
        aux = state.set('list', List(newArray));
        aux = aux.set('fetching', false);
      } else {
        aux = state;
      }
      return aux;

    ////////////////////////////////////////////////////////
    //////// ADD AN TEMPLATE
    ////////////////////////////////////////////////////////

    /** ADD NEW TEMPLATE TO STATE */
    case TYPES.ADD_TEMPLATE_SUCCESS:
      return state
        .update('list', userList => userList.push(payload))
        .set('fetching', false)
        .set('addSuccess', true)
        .set('templateAdded', true)
        .set('templateDeleted', false)
        .set('errorFetching', false)
        .update('total', total => total + 1);

    ////////////////////////////////////////////////////////
    //////// UPDATE AN TEMPLATE
    ////////////////////////////////////////////////////////

    /** UPDATE TEMPLATE IN STATE */
    case TYPES.MODIFY_TEMPLATE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', templateList =>
              templateList.splice(index, 1, payload)
            )
          : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);
      // aux = aux.set('templateAdded', false);
      // aux = aux.set('templateDeleted', false);
      return aux;

    ////////////////////////////////////////////////////////
    //////// DELETE AN TEMPLATE
    ////////////////////////////////////////////////////////

    /** DELETE TEMPLATE IN STATE */
    case TYPES.DELETE_TEMPLATE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', userList => userList.delete(index))
          : state;
      aux = aux.set('fetching', false);
      aux = aux.set('templateAdded', false);
      aux = aux.set('templateDeleted', true);
      aux = aux.update('total', total => total - 1);
      return aux;

    ////////////////////////////////////////////////////////
    //////// RESET ERROR FETCHING TO FALSE
    ////////////////////////////////////////////////////////
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_TEMPLATE_RESET:
      return state
        .set('errorFetching', false)
        .set('templateAdded', false)
        .set('templateDeleted', false);

    ////////////////////////////////////////////////////////
    //////// FETCHING
    ////////////////////////////////////////////////////////

    /** INITIALIZE GET TEMPLATES FROM DB */
    /** INITIALIZE GET TEMPLATES FROM DB */
    /** INITIALIZE GET TEMPLATE ROLES FROM DB */
    /** INITIALIZE ADD TEMPLATE TO DB AND STATE */
    /** INITIALIZE UPDATE TEMPLATE TO DB */
    /** INITIALIZE DELATION OF A TEMPLATE IN DB */
    case TYPES.GET_TEMPLATES_REQUEST:
    case TYPES.GET_TEMPLATE_REQUEST:
    case TYPES.GET_MY_TEMPLATES_REQUEST:
    case TYPES.GET_TEMPLATE_ROLES_REQUEST:
    case TYPES.ADD_TEMPLATE_REQUEST:
    case TYPES.MODIFY_TEMPLATE_REQUEST:
    case TYPES.DELETE_TEMPLATE_REQUEST:
      return state
        .set('fetching', true)
        .set('templateAdded', false)
        .set('errorFetching', false)
        .set('templateDeleted', false);

    /** SET AS FALSE THE addSuccess STATE FLAG */
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.ADD_TEMPLATE_SUCCESS_FLAG:
    case TYPES.MODIFY_TEMPLATE_SUCCESS_FLAG:
      return state
        .set('fetching', false)
        .set('templateAdded', true)
        .set('templateDeleted', false);

    ////////////////////////////////////////////////////////
    //////// ERRORS
    ////////////////////////////////////////////////////////

    /** INITIALIZE GET TEMPLATE FROM DB */
    /** INITIALIZE GET TEMPLATE FROM DB ERROR*/
    /** INITIALIZE GET TEMPLATE ROLES FROM DB ERROR*/
    /** INITIALIZE ERROR ADD TEMPLATE */
    /** UPDATE TEMPLATE ERROR */
    /** INITIALIZE DELATION OF AN TEMPLATE IN DB */
    case TYPES.GET_TEMPLATE_ERROR:
    case TYPES.GET_TEMPLATE_ROLES_ERROR:
    case TYPES.ADD_TEMPLATE_ERROR:
    case TYPES.MODIFY_TEMPLATE_ERROR:
    case TYPES.DELETE_TEMPLATE_ERROR:
    case TYPES.GET_TEMPLATES_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      aux = aux.set('templateAdded', false);
      aux = aux.set('templateDeleted', false);
      return aux;

    ////////////////////////////////////////////////////////
    //////// CLEARS
    ////////////////////////////////////////////////////////

    case TYPES.CLEAR_TEMPLATES_STATE:
      return state.set('list', List([]));

    ////////////////////////////////////////////////////////
    //////// DEFAUKLT
    ////////////////////////////////////////////////////////

    default:
      return state;
  }
};
