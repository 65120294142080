import React from 'react';

import Add from './Add';
import CreateUsersGroupSuccess from './Success';
import Wizard from '../../../../components/Wizard';
import WizardStep from '../../../../components/Wizard/components/WizardStep';
import { PropTypes } from 'prop-types';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'

import AddTableButton from '../../../../components/AddTableButton';
import ModalHOC from '../../../../elements/Modal';

import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import AddPermissions from './AddPermissions';
import { isMobile } from '../../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../../elements/Button';
import UsersGroup from '../../../../models/UsersGroup';
import GroupsIcon from '../../../../configuration/icons/svg/ico-groups.svg';

const addUsersGroupLabel = (
  <FormattedMessage
    id="usersGroups.wizard.add.title"
    defaultMessage="add usersGroup"
    description="Label of add usersGroup"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile())
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/groups'}
        permission={'create'}
      >
        <ButtonCustom type={'circular'} name={'plus'} onClick={showModal} />
      </PrivateComponent>
    );
  else
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/groups'}
        permission={'create'}
      >
        <AddTableButton onClick={showModal} label={addUsersGroupLabel} />
      </PrivateComponent>
    );
};


class CreateUsersGroup extends React.Component {
  render() {
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        entity={UsersGroup}
        metadata={{
          wizardType: 'users.group',
        }}
      >

        <WizardStep
          component={Add}
          title={<FormattedMessage id="usersGroups.wizard.add.title" />}
          lead={<FormattedMessage id="usersGroups.wizard.step1.text" />}
          icon={GroupsIcon}
          entity={UsersGroup}
        />
        <WizardStep
          component={AddPermissions}
          title={<FormattedMessage id="usersGroups.wizard.addPermissions.title" />}
          lead={<FormattedMessage id="usersGroups.wizard.step2.text" />}
          entity={UsersGroup}
        />
        <CreateUsersGroupSuccess /> 
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateUsersGroup;
