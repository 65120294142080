/* eslint-disable import/no-cycle */
import { getUrnId } from '../../../../../../helpers/utils';
import { calculateSimpleLastValue, formatToCommonWidgetSingleDevice } from '../../../../utils';
import PercentageWidget from '../../../percentage';
import { PercentageDefaultConfigurationForLinked } from '../../../percentage/utils/defaultConfiguration';

export default class LinkedPercentage extends PercentageWidget {
  defaultConfig = ({ ...PercentageDefaultConfigurationForLinked })

  getQueryHistorical = () => null

  ifUsesContainerValues = true

  hasOnlyAverageOperation = true;

  formatToData = (values, sources, config, selection) => {
    const { operation } = config.data;
    if (values?.length || values?.data?.length) {
      const attributeOfWidget = config.data.attributeFilter[0];
      if (selection.length === 1) {
        const data = values.find((d) => {
          const deviceId = getUrnId(d.urn);
          return deviceId === selection[0] && d.attrName === attributeOfWidget;
        });
        return data?.value?.value || 0;
      }
      if (selection.length > 1) {
        const calculatedValues = calculateSimpleLastValue(
          values,
          attributeOfWidget,
          operation,
          selection,
          3,
        );
        return calculatedValues || 0;
      }
      return 0;
    }
    return formatToCommonWidgetSingleDevice(this.sources[0]);
  }
}
