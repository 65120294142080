import React, { useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import {
  Power3, TimelineMax,
} from 'gsap/all';
import styled from '@emotion/styled';

const SubMenuItemWrapper = styled.div`
position: absolute;
opacity: 0;
width: ${(props) => props.size.width}px;
height: ${(props) => props.size.height}px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
top: 0;
left: 0;
`;
const CircleIcon = styled.div`
border-radius: 50%;
background: white;
box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
width: 75px;
height: 75px;
img{
  width: 100%
}
`;

const SubMenuItem = ({
  item, item: { position }, width, height, getIcon, root,
}) => {
  const itemRef = useRef(null);
  useEffect(() => {
    new TimelineMax()
      .from(itemRef.current,
        0.5,
        {
          alpha: 0,
          x: 0,
          y: 0,
          ease: Power3.easeInOut,
          scaleX: 0.5,
          scaleY: 0.5,
        }, 0.1)
      .to(itemRef.current,
        0.5,
        {
          alpha: 1,
          x: position.x,
          y: position.y,
          ease: Power3.easeInOut,
          scaleX: 1,
          scaleY: 1,
        }, 0.1)
      .play();
  }, [position.x, position.y]);

  const handleClick = () => {
    navigate(`/app/${root}/${item.item}`);
  };
  return (
    <SubMenuItemWrapper
      ref={itemRef}
      size={{ width, height }}
      onClick={handleClick}
    >
      <CircleIcon>
        {getIcon(item.item, true)}
      </CircleIcon>
    </SubMenuItemWrapper>
  );
};

export default SubMenuItem;
