import React from 'react';
import Styled from 'styled-components';
import ModalHOC from '../../../elements/Modal/index';
import { ButtonCustom } from '../../../elements/Button/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { setHomeMenuElement } from '../../../services/redux/menuElements/actions';

const StyledTitleName = Styled.em``;

const ModalTitle = () => (
  <FormattedMessage
    id="menu.home.title"
    defaultMessage="Home menu"
    description="Title for Home modal window"
    component="p"
  />
);

const CancelMsg = () => (
  <FormattedMessage
    id="options.cancel"
    defaultMessage="Cancel"
    description="Cancel option"
    component="span"
  />
);

const SaveMsg = () => (
  <FormattedMessage
    id="options.accept"
    defaultMessage="Accept"
    description="Accept option"
    component="span"
  />
);

const ModalContent = (title) => (
  <h1>
    <FormattedMessage
      id="menu.home.confirmMessage"
      defaultMessage="Confirm you want set as Home the menu named:"
      description="Message for home widget modal window"
    />
    <StyledTitleName>{ title }</StyledTitleName>
  </h1>
);

const onClickMenuItem = (e, menuElement, hideModal) => {
  e.preventDefault();
  e.stopPropagation();
  setHomeMenuElement(menuElement.id);
  hideModal();
};

const ModalButtons = (menuElement, hideModal) => (
  <div className="buttons">
    <ButtonCustom
      type="primary"
      handleOnClick={(e) => onClickMenuItem(e, menuElement, hideModal)}
      label={<SaveMsg />}
    />
    <ButtonCustom
      handleOnClick={hideModal}
      className="btn btn-secondary"
      label={<CancelMsg />}
    />
  </div>
);

export default (ButtonOpen) => {
  class RemoveInner extends React.PureComponent {
    render() {
      const { title, menuElement } = this.props;
      const ModalDOM = ModalHOC({
        ButtonOpen,
        ModalTitle,
        ModalContent: () => ModalContent(title),
        ModalButtons: ({ hideModal }) => ModalButtons(menuElement, hideModal),
      });

      return <ModalDOM title={title} menuElement={menuElement} />;
    }
  }

  RemoveInner.defaultProps = {
    title: '',
    menuElement: {},
  };

  return RemoveInner;
};
