import React from 'react';
import { withTheme } from '../../../Contexts/ThemeContext/index';
import { connect } from 'react-redux';
import { getPermissions } from '../../../services/redux/permissions/actions';
import Permission, { SamplePermission } from '../../../models/Permission/index';
import WithDrawer from '../../../components/DrawerContainer';
import Profile from '../Profile';
import { injectIntl } from "react-intl";
import BasicList from '../../helpers/BasicList';
import OptionsForRow from './OptionsForRows'
import RemoveHoc from '../../helpers/RemoveComponent';



const headers = [];
const filters = [];

['name', 'description', 'action', 'resource'].forEach(
  index => {
    headers.push({ label: SamplePermission.translations[index], key: index });
    filters.push({ name: SamplePermission.translations[index], key: index, value: index })
  }
);




const ProfileComponent = WithDrawer(({ onChange, data, ...rest }) => (
  <Profile {...rest} onChange={onChange} data={data} />
));

const RemoveComponent = RemoveHoc(({ showModal }) => (
  <></>
));

export class List extends React.Component {
  state = {
    page: 1,
    itemsPerPage: 5,
    itemsShowed: 5,
    profileOpened: false
  };
  componentDidMount() {
    const { page, itemsPerPage } = this.state;
    getPermissions({ filters: [], page, itemsPerPage });
  }

  handleOnSubmit = filters => {
    const { page, itemsPerPage } = this.state;
    getPermissions({ filters, page, itemsPerPage });
  };

  handleOnPageChange = (page, itemsPerPage, itemsShowed) => {
    this.setState({
      page: page,
      itemsPerPage: itemsPerPage,
      itemsShowed: itemsShowed
    });
  };

  handleOnProfileEvent = opened => {
    this.setState({
      profileOpened: opened
    });
  };

  handleOpenProfile = data => {
    this.setState({
      profileOpened: true,
      activePermission: data
    });
  };

  render() {
    const { permissions, total } = this.props;
    const permissionByData = permissions.map(permission => permission.toJson());
    return (
      <>
        <BasicList
          {...this.state}
          data={permissionByData}
          total={total}
          onSubmit={this.handleOnSubmit}
          entityName={'permissions'}
          entity={Permission}
          exampleEntity={SamplePermission}
          header={headers}
          profileComponent={ProfileComponent}
          filters={filters}
          externalFilter={false}
          expandable={true}
          selectable={true}
          onPageChange={this.handleOnPageChange}
          optionsForRow={OptionsForRow}
          onRemove={RemoveComponent}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.get('permissions').get('list').toJS(),
  total: state.get('permissions').get('total')
});

export default connect(
  mapStateToProps,
  {}
)(withTheme(injectIntl(List)));
