import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { clone } from 'ramda';

import CommonView from '../../../CommonView';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import Kpi from '../../../../models/Kpi';
import AggregationOptionsComponent from './Components/AggregationOptionsComponent';

import '../styles.scss';

class SimpleKPIConfiguration extends CommonView {
  constructor(props) {
    super({ props });
    const { newEntity } = this.props;
    const definition = {
      type: newEntity.definition.type,
      value: newEntity.definition.value,
      unit: newEntity.definition.unit,
      process: {
        step: newEntity.definition.process.step,
        attribute: (
          newEntity.definition.process.attribute.title
          || newEntity.definition.process.attribute
        ),
        operation: 'last_occurence',
        urn: newEntity.definition.process.urn,
      },
    };
    this.state = {
      definition,
      option: this.props.newEntity.option,
      aggregation: false,
      errors: this.props.errors,
      onValidation: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.errors)
      !== JSON.stringify(this.props.errors)) {
      this.updateError();
    }
    if (JSON.stringify(prevProps.newEntity.definition)
      !== JSON.stringify(this.props.newEntity.definition)) {
      this.setDefinition();
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  savePartialKpi = () => {
    const { errors, onValidation, ...rest } = this.state;
    const { selectedDevice } = this.props.extraData;
    this.setErrors({});

    const obj = this.state;
    const NewKpi = new Kpi(obj);
    const validationErrors = NewKpi.validate(['definition']);

    if (validationErrors) {
      this.setErrors(validationErrors);
      this.props.onError(true);
    } else {
      this.props.onContinue(rest, { selectedDevice });
    }
  };

  validate = () => {
    const { aggregation, definition } = this.state;
    if (aggregation) {
      if (
        definition.process.operation === 'last_occurence'
        || definition.process.options.resolution === ''
      ) {
        this.props.onError(
          <FormattedMessage id="kpis.wizard.aggregation.noOptionsSelected" />,
        );
      } else {
        this.savePartialKpi();
      }
    } else {
      this.savePartialKpi();
    }
  };

  setDefinition = () => {
    this.setState({ definition: this.props.newEntity.definition });
  }

  handleEnableDisable = () => {
    const { aggregation, definition } = this.state;

    this.setState({
      definition: {
        ...definition,
        process: {
          attribute: definition.process.attribute,
          operation: 'last_occurence',
          step: definition.process.step,
          urn: definition.process.urn,
          options: aggregation
            ? undefined
            : { resolution: '' },
        },
      },
      aggregation: !aggregation,
    });
  }

  updateError = () => {
    this.setState({ errors: this.props.errors });
  };

  handleSelectChange = (name, value) => {
    const definition = clone(this.state.definition);

    if (name === 'operation') {
      definition.process.operation = value;
      this.setState({ definition });
    }

    if (name === 'resolution') {
      definition.process.options.resolution = value;
      this.setState({ definition });
    }

    this.handleFieldChange('definition', definition);
  };

  handleAggregationChange = () => {
    const { aggregation, definition } = this.state;

    this.setState({
      definition: {
        ...definition,
        process: {
          attribute: definition.process.attribute,
          operation: 'last_occurence',
          step: definition.process.step,
          urn: definition.process.urn,
          options: aggregation
            ? undefined
            : { resolution: '' },
        },
      },
      aggregation: !aggregation,
    });
  }

  render() {
    const { intl } = this.props;
    const { errors, aggregation, definition } = this.state;
    const { selectedDevice } = this.props.extraData;
    return (
      <>
        <Container>
          <div className="d-flex flex-column flex-md-row align-items-center mainContent">
            <div className="wizardWrapper">
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <div>
                    <span className="sourceOfData">
                      {intl.formatMessage({
                        id: 'kpis.wizard.sourceOfData',
                      })}
                    </span>
                    <div className="card-base">
                      <span className="deviceName">
                        {selectedDevice.name}
                      </span>
                      <span className="deviceId">
                        (
                        {selectedDevice.device_id}
                        )
                      </span>
                    </div>

                    <div className="card-base-copy">
                      <span className="attribute">
                        {definition.process.attribute.title || definition.process.attribute}
                      </span>
                      <span className="lastOccurence">
                        {intl.formatMessage({
                          id: 'kpis.wizard.attribute.lastOccurence',
                        })}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <AggregationOptionsComponent
                    handleChange={this.handleAggregationChange}
                    handleSelectChange={this.handleSelectChange}
                    selected={aggregation}
                    intl={intl}
                    errors={errors}
                    value={{
                      operation: definition.process.operation,
                      resolution: definition.process.options?.resolution,
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addKpiSuccessFlag: state.get('kpis').get('addSuccess'),
  errorFetching: state.get('kpis').get('errorFetching'),
  notify: state.get('notify'),
  definition: state.get('definition'),
});

SimpleKPIConfiguration.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
};

SimpleKPIConfiguration.defaultProps = {
  value: 0,
  unit: '',
  permissionsPolicy: [],
  errors: {},
};

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(SimpleKPIConfiguration)));
