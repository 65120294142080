import React from 'react';
import usePrevious from '../../../helpers/Hooks/usePrevious';

export const ProfileKpiContext = React.createContext();

const ProfileKpiProvider = ({ children, ...props }) => {
  const prevValue = usePrevious({ ...props });
  return (
    <ProfileKpiContext.Provider value={{
      prevValue,
      ...props,
    }}
    >
      {children}
    </ProfileKpiContext.Provider>
  );
};

export default ProfileKpiProvider;
