import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withTheme } from '../../../Contexts/ThemeContext';
import Table from '../../../components/Table';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import TabComponent from '../../../components/Tab';
import {
  getDevices,
  getMyDevices,
  clearDevicesState,
} from '../../../services/redux/devices/actions';

import Device, { SampleDevice } from '../../../models/Device/index';
import OptionsForRows from './OptionsForRows';
import WithDrawer from '../../../components/DrawerContainer';
import CreateDevice from '../Add/AddWizard';
import RemoveHoc from '../../helpers/RemoveComponent';
// eslint-disable-next-line import/no-named-as-default
import Profile from '../Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import FiltersFinder from '../../helpers/FiltersFinder/index';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const RemoveComponent = RemoveHoc(
  ({ showModal }) => (
    <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission="delete">
      <i
        className="uil uil-trash-alt remove"
        onClick={showModal}
        role="presentation"
      />
    </PrivateComponent>
  ),
  { class: Device, nameData: 'devices' },
);
const ProfileComponent = WithDrawer(({ onChange, data }) => (
  <Profile onChange={onChange} data={data} />
));

const headers = [];
const filters = [];

headers.push({ label: '', key: 'categories', type: 'device-icon' });

['device_id', 'name', 'description'].forEach((index) => {
  headers.push({
    label: SampleDevice.translations[index],
    key: index,
  });
  filters.push({
    name: SampleDevice.translations[index],
    key: index === 'device_id' ? 'deviceId' : index,
    value: index,
  });
});

filters.push({
  name: SampleDevice.translations.categories,
  key: 'categories',
  value: 'categories',
});

headers.unshift({ label: '', key: 'deviceAddOn', type: 'deviceAddOn' });
headers.push({ label: SampleDevice.translations.createdAt, key: 'createdAt', type: 'date' });
headers.push({ label: SampleDevice.translations.updatedAt, key: 'updatedAt', type: 'date' });

// eslint-disable-next-line react/jsx-props-no-spreading
const TablePaginated = Pagination(({ data, ...rest }) => (<Table {...rest} body={data} />));

const FindableTabsComponent = Finder((props) => {
  const {
    data,
    theme,
    itemsPerPage,
    page,
    optionsForRow,
    onPageChange,
    onSubmit,
    onRowClick,
    typeOfData,
    itemsShowed,
    total,
    ...rest
  } = props;

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <TabComponent {...rest}>
      <TablePaginated
        optionsForRow={optionsForRow}
        data={data}
        header={headers}
        align="left"
        filters={filters}
        onSubmit={onSubmit}
        onPageChange={onPageChange}
        itemsPerPage={itemsPerPage}
        page={page}
        total={total}
        expandable
        itemsShowed={itemsShowed}
        typeOfData={typeOfData}
        selectable
        onRemove={RemoveComponent}
        onRowClick={onRowClick}
        entity={Device}
      />
      <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission="list-mine">
        <TablePaginated
          optionsForRow={optionsForRow}
          data={data}
          header={headers}
          align="left"
          filters={filters}
          onSubmit={onSubmit}
          onPageChange={onPageChange}
          itemsPerPage={itemsPerPage}
          page={page}
          total={total}
          expandable
          itemsShowed={itemsShowed}
          typeOfData="devices"
          selectable
          onRemove={RemoveComponent}
          onRowClick={onRowClick}
          entity={Device}
        />
      </PrivateComponent>
    </TabComponent>
  );
}, CreateDevice, FiltersFinder);

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      itemsPerPage: 5,
      profileOpened: false,
      selectedtab: 0,
      itemsShowed: 5,
      selectedFilter: filters[0],
      selectedValueForFilter: '',
    };
  }

  componentDidMount() {
    const { page, itemsPerPage, selectedFilter } = this.state;
    // need it because the state is also used in opendata
    clearDevicesState();
    getDevices({ filters: { [selectedFilter.key]: '' }, page, size: itemsPerPage });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedtab, selectedFilter, itemsPerPage, selectedValueForFilter,
    } = this.state;

    if (selectedtab !== prevState.selectedtab) {
      this.updateState(selectedFilter, itemsPerPage, selectedValueForFilter);
    }
  }

  handleFilteredSearch = (value, filter) => {
    const { itemsPerPage } = this.state;
    if (filter && filter.key) {
      clearDevicesState();
      this.selectDataSource({ [filter.key]: value }, 1, itemsPerPage, true);
      this.setState({
        selectedFilter: filter,
        selectedValueForFilter: value,
        page: 1,
      });
    }
  };

  handleOnProfileEvent = (opened) => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = (data) => {
    this.setState({
      profileOpened: true,
      activeDevice: data,
    });
  };

  handleOnPageChange = (page) => {
    const { selectedValueForFilter, selectedFilter, itemsPerPage } = this.state;
    this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter }, page, itemsPerPage);
    this.setState({
      page,
    });
  };

  // Do not remove 'filters', it is not the same as the one in the upper scope
  // eslint-disable-next-line no-shadow
  selectDataSource = (filters, page, size, init = false) => {
    const { selectedtab } = this.state;
    switch (selectedtab) {
      case 0:
        if (init) {
          clearDevicesState();
        }
        getDevices({ filters, page, size });
        break;
      case 1:
        if (init) {
          clearDevicesState();
        }
        getMyDevices({ filters, page, size });
        break;
      default:
        break;
    }
  }

  handleOnTabsChange = (selectedtabInput) => {
    const { selectedtab } = this.state;
    if (selectedtab !== selectedtabInput) {
      this.setState({
        selectedtab: selectedtabInput,
      });
    }
  };

  updateState(selectedFilter, itemsPerPage, selectedValueForFilter) {
    this.setState({
      page: 1,
    }, () => this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter || '' }, 1, itemsPerPage, true));
  }

  render() {
    const {
      path, theme, devices, fetching, total, intl,
    } = this.props;
    const {
      itemsPerPage,
      selectedtab,
      profileOpened,
      activeDevice,
      page,
      itemsShowed,
      selectedFilter,
    } = this.state;

    const devicesByData = devices.map((device) => device.toJson());

    return (
      <>
        <FindableTabsComponent
          titles={[<FormattedMessage id="devices.list.tab.devices" />,
            <PrivateComponent checkLogin checkPermission microsService="/app/devices" permission="list-mine">
              <FormattedMessage id="devices.list.tab.mydevices" />
            </PrivateComponent>]}
          placeholder={intl.formatMessage({ id: 'finder.box.placeholder' })}
          onChange={this.handleOnTabsChange}
          selectedtab={selectedtab}
          fetching={fetching}
          theme={theme}
          data={devicesByData}
          optionsForRow={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <OptionsForRows {...props} viewClick={this.handleOpenProfile} />
          )}
          page={page}
          total={total}
          expandable
          itemsShowed={itemsShowed}
          itemsPerPage={itemsPerPage}
          onRowClick={this.handleOpenProfile}
          typeOfData="devices"
          filters={filters}
          externalFilter={this.handleFilteredSearch}
          selectedFilter={selectedFilter}
          onPageChange={this.handleOnPageChange}
        />
        {profileOpened && activeDevice && (
          <ProfileComponent
            onChange={this.handleOnProfileEvent}
            data={activeDevice}
            path={path}
          />
        )}
      </>
    );
  }
}

List.propTypes = {
  path: PropTypes.string,
  theme: PropTypes.string,
  devices: PropTypes.string,
  fetching: PropTypes.string,
  total: PropTypes.string,
  intl: PropTypes.string,
};

List.defaultProps = {
  path: '',
  theme: '',
  devices: '',
  fetching: '',
  total: '',
  intl: '',
};

const mapStateToProps = (state) => ({
  devices: state.get('devices').get('list').toJS(),
  total: state.get('devices').get('total'),
  fetching: state.get('devices').get('fetching'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(injectIntl(List)));
