/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { switchElement, disabledSwitch, point } from './index.module.scss';

const ExternalSwitch = styled.div`
    ${(p) => !p.checked && 'background-color: #CED3D7!important'}
`;

const InternalSwitch = styled.div`
    ${(p) => (p.checked ? 'margin-left: auto' : 'margin-right: auto')}
`;
const ExternalSwitchWithTitle = styled.span`
    ${(p) => (p.title ? 'display: flex; align-items: center; marginBottom: 25px;' : '')}
`;
const SwitchTitleLeft = styled.span`
    ${(p) => (p.title ? 'margin-right: 8px;' : '')}
`;
const SwitchTitleRight = styled.span`
    ${(p) => (p.title ? 'margin-left: 8px;' : '')}
`;
class Switch extends React.Component {
  constructor(props) {
    super(props);
    const { checked } = this.props;
    this.state = {
      checked: checked || false,
    };
  }

  componentDidUpdate(prevProps) {
    const { checked } = this.props;
    if (checked !== prevProps.checked) this.setState({ checked });
  }

  getPositionTitle = (positionTitle) => (
    positionTitle === 'right' || positionTitle === 'left' ? positionTitle : null
  );

  handleOnChange = () => {
    const { onChange } = this.props;
    const { checked } = this.state;
    onChange(checked);
  };

  handleOnClick = () => {
    const { widgetType, disabled } = this.props;
    const { checked } = this.state;
    if (widgetType !== 'MAP' && widgetType !== 'TABLE' && !disabled) {
      this.setState({ checked: !checked }, this.handleOnChange);
    }
  }

  render() {
    const { title, positionTitle, disabled } = this.props;
    const { checked } = this.state;
    const position = this.getPositionTitle(positionTitle);

    return (
      <ExternalSwitchWithTitle title={title} className={disabled ? disabledSwitch : ''}>
        {position === 'left'
          ? (
            <SwitchTitleLeft title={title}>
              { title }
            </SwitchTitleLeft>
          )
          : null}
        <ExternalSwitch className={switchElement} checked={checked} onClick={this.handleOnClick}>
          <InternalSwitch className={point} checked={checked} />
        </ExternalSwitch>
        {position === 'right'
          ? (
            <SwitchTitleRight title={title}>
              {title}
            </SwitchTitleRight>
          )
          : null}
      </ExternalSwitchWithTitle>
    );
  }
}

Switch.defaultProps = {
  checked: true,
  onChange: () => {},
  disabled: false,
  title: undefined,
  positionTitle: undefined,
  widgetType: '',
};

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  positionTitle: PropTypes.string,
  widgetType: PropTypes.string,
};

export default Switch;
