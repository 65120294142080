/* eslint-disable import/no-cycle */
import React from 'react';
import Style from 'styled-components';
import { clone } from 'ramda';
import Chip from '../../elements/Chip';
import { FormattedMessage } from '../../Contexts/LanguageContext/index';
import { getHash } from '../../helpers/utils';

import './styles.scss';

const FinderStyle = Style.div.attrs({
  paddingTop: '4px',
  paddingRight: '8px',
})`
      
      ${(p) => p.css}
  `;

const SearchOptionsList = ({
  options,
  onListClick,
  optionDropdownref,
  onGoSearchClick,
}) => {
  const optionList = options.map((option, i) => (
    <li key={getHash(i + 1)} onClick={(e) => onListClick(option.index, e)}>
      <i className="uil uil-search" />
      {option.label}
    </li>
  ));
  return (
    <ul ref={optionDropdownref}>
      <li key={0} onClick={onGoSearchClick}>
        <i className="uil uil-angle-down" />
        <FormattedMessage id="finder.go.search.click" />
      </li>
      {optionList}
    </ul>
  );
};

const SelectedOptions = ({ selectedOptions, onChipClick }) => {
  const selected = clone(selectedOptions);
  const newSelected = selected.map((option, i) => (
    <span key={getHash(i)}>
      <Chip text={option.label} dismissible={false} />

      {option.term && option.term !== '' && (
      <Chip
        text={option.term}
        innerClick={(e) => onChipClick(option.index, e)}
      />
      )}
    </span>
  ));

  return <>{newSelected}</>;
};

class DefaultFinder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputText = React.createRef();
    this.auxiliarInput = React.createRef();
  }

  handleCreateAuxiliar = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      css,
      typeOfData,
      intl,
      value,
      availableOptions,
      selectedOptions,
      expanded,
      AuxItem,
      onClickChip,
      onSubmit,
      onFocus,
      onKeyUp,
      onBlur,
      onListClick,
      filterDropdownRef,
      footer,
      footerWithAction,
      error,
      ...rest
    } = this.props;
    return (
      <div className={`finderWrapper ${footerWithAction ? 'footerFinder' : ''}`}>
        {typeOfData && (
          <div className="optionsForTop">
            <p className="total">
              {intl.formatMessage({ id: `${typeOfData}.table.total` })}
              <span>{rest.total}</span>
            </p>
          </div>
        )}

        <FinderStyle
          css={css}
          className={`finder ${expanded ? 'expanded' : ''}`}
        >
          <div>
            <i className="uil uil-search" />
            {selectedOptions.length > 0 && (
              <SelectedOptions
                selectedOptions={selectedOptions}
                onChipClick={onClickChip}
              />
            )}
            <form onSubmit={onSubmit}>
              <input
                placeholder={intl.formatMessage({ id: 'finder.box.placeholder' })}
                onKeyUp={onKeyUp}
                defaultValue={value}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={this.inputText}
                className="input"
              />
            </form>
            {availableOptions.length > 0 && (
              <SearchOptionsList
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...this.props}
                onListClick={onListClick}
                options={availableOptions}
                optionDropdownref={filterDropdownRef}
                onGoSearchClick={onSubmit}
              />
            )}
          </div>
        </FinderStyle>
        {AuxItem && <AuxItem error={error} />}
      </div>
    );
  }
}

export default DefaultFinder;
