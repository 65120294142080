import { categories, Components, options } from '../../../../utilsConfigurationSheet';
import { NeedleConfigurationSheet } from '../../../../needle/utils/configurationSheet';

// eslint-disable-next-line import/prefer-default-export
export const NeedleConfigurationSheetForLinked = {
  ...NeedleConfigurationSheet,
  operationType: [
    {
      name: 'operation',
      component: Components.select,
      categories: [categories.DATA],
      options: options.operation,
    },
  ],
};
