import * as TYPES from './types'
import { Map, List } from 'immutable';
import {debugMode, errorMode} from '../../../configuration/config';
import uuidv4 from 'uuid/v4';
import { deleteNotification } from './actions';

/** Initial State of Roles */
const initialState = Map({
    danger: List([]),
    warning: List([]),
    info: List([])
});


/** ROLES REDUCER*/
export default (state = initialState, { type, payload }) => {
    let aux;
    let alert;

    /** Cases */
    switch (type) {

        //DELETE A NOTIFICATION

        case TYPES.NOTIFY_DELETE:
            const index = state.get(payload.mode).findIndex(key => key.id === payload.id);
            aux = index !== -1 ? state.update(payload.mode, list => list.delete(index)) : state;
            return aux;

        //////// NOTIFY ERRORS

        case TYPES.NOTIFY_ERROR:
            if (!debugMode && errorMode) {

                if (payload.errors != null)
                    payload.errors.forEach(err => console.log(err.constraints))
            }
            return state;

        case TYPES.NOTIFY_ADD_USER_SUCCESS:

            alert = {
                id: uuidv4(),
                mode: 'info',
                text: 'The user has been created successfully.',
                dismissible: true,
                autoDismissible: true,
                onDismiss: deleteNotification
            };
            aux = state.update(alert.mode, list => list.push(alert));
            return state;

        case TYPES.NOTIFY_INFO_ADD_USER_SUCCESS_PASSWORD:

            alert = {
                id: uuidv4(),
                mode: 'warning',
                text: `User has been created or updated. Copy the password and send to the user: ${payload}`,
                dismissible: true,
                onDismiss: deleteNotification
            };
            aux = state.update(alert.mode, list => list.push(alert));
            return aux;

        case 'CLEAR_STATE':
            return initialState;

        default:
            return state
    }
};
