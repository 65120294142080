/* eslint-disable import/no-cycle */
import React, { Suspense } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import Lottie from 'react-lottie';
import CommonView from '../../../CommonView';
import animationData from '../../../Dashboards/Show/components/Loading/dashboardLoadingLottie.json';
import FormattedMessage from '../../../../Contexts/LanguageContext/Components/FormattedMessage';
import { getWidget } from '../../../Dashboards/ShowThumbnail/ShowThumbnail';
import LinkedStepTitle from '../components/LinkedStepTitle';
import LinkedStepButtons from '../components/LinkedStepButtons';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

class Step4Linked extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      localPreviewLoader: false,
      errors: {},
    };
  }

  localeTranslate = (key, defaultValue) => {
    const { intl } = this.props;
    return intl ? intl.formatMessage({ id: key }) : defaultValue;
  };

  render() {
    const { localPreviewLoader } = this.state;
    const {
      previousStep,
      currentStep,
      totalSteps,
      saveWidget,
      previewLoaded,
      widgetChild,
      isActive,
    } = this.props;

    const buttons = [
      {
        type: 'secondary', id: 'prevStepLinkedWidget', handleOnClick: previousStep, label: 'linkedWidget.previousStep',
      },
      {
        type: 'primary', id: 'nextStepLinkedWidget', handleOnClick: saveWidget, label: 'linkedWidget.finalize',
      },
    ];

    if (previewLoaded && !localPreviewLoader) {
      this.setState({ localPreviewLoader: true });
    }

    if (isActive) {
      return (
        <Row className="linkedStepsContainer pixelHeight">
          <div className="h-100">
            <LinkedStepTitle
              currentStep={currentStep}
              totalSteps={totalSteps}
              title="widgets.linked.wizard.step4.title"
              subtitle="widgets.linked.wizard.step4.subtitle"
            />
            <Row className="WizardPreviewLinked">
              <Col className={`linkedPreview-${widgetChild?.widgetType?.toLowerCase()}`} sm="12" md="6">
                <div>
                  {(!widgetChild || !localPreviewLoader)
                    ? (
                      <Lottie
                        className="lottie"
                        options={defaultOptions}
                        height={280}
                        width={280}
                      />
                    ) : (
                      <Suspense fallback={(
                        <Lottie
                          className="lottie"
                          options={defaultOptions}
                          height={280}
                          width={280}
                        />
                        )}
                      >
                        {getWidget(widgetChild, this.localeTranslate)}
                      </Suspense>
                    )}
                </div>
              </Col>
              <Col className="mb-4 mt-3" sm="12">
                <div className="linkedPreviewInfo">
                  <i className="uil uil-info-circle i linkedPreviewIcon" />
                  <span className="linkedPreviewText">
                    <FormattedMessage id="widgets.linked.wizard.step4.info" />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <LinkedStepButtons buttons={buttons} />
        </Row>
      );
    }
    return null;
  }
}

export default injectIntl(Step4Linked);
