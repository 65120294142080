/* eslint-disable import/no-cycle */
import LinesWidget from '../../../lines';
import {
  LinesDefaultConfigurationForLinked,
  injectLinkedLinesData,
} from './utils/defaultConfiguration';
import formatToLineWidget from '../../../lines/utils/formatData';
import { calculateAggregateValues } from '../../utils';
import { LinesConfigurationSheetForLinked } from './utils/configurationSheet';
import { getUrnId } from '../../../../../../helpers/utils';

export default class LinkedLines extends LinesWidget {
    defaultConfig = ({ ...LinesDefaultConfigurationForLinked });

    injectData = (widget) => injectLinkedLinesData(widget)

    getConfigurationSheet = () => LinesConfigurationSheetForLinked;

    getQueryHistorical = (selection) => {
      const {
        sampling, startDate, endDate, period, attributeFilter,
      } = this.config.data;
      const dataForQueries = this.getEmptyHistoricalBody();

      const sourcesFilter = this.sources
        .filter((o) => selection.includes(getUrnId(o.urn)))
        .map((o) => {
          // eslint-disable-next-line no-param-reassign
          o.fields = o.fields.filter((f) => attributeFilter.includes(f));
          return o;
        })
        .filter((o) => o.fields.length);
      const historialQueryBody = this.getDataForQueries({
        sources: sourcesFilter,
        queryType: 'aggregate',
        sampling,
        periodTime: {
          startDate,
          endDate,
          period,
        },
      });
      const sources = this.getDataForQueries({ sources: sourcesFilter });
      dataForQueries[0].sources = sources;
      dataForQueries.push(historialQueryBody);
      return dataForQueries;
    }

    formatToData = (values, sources, config, selection) => {
      const widget = this.getData();
      const { operation } = config.data;
      if (values?.length) {
        if (selection.length === 1) {
          return formatToLineWidget(values, config, operation);
        }
        if (selection.length > 1) {
          const aggregateValues = calculateAggregateValues(widget, values);
          if (aggregateValues) {
            return formatToLineWidget(
              aggregateValues,
              config,
              operation,
              undefined,
              operation,
            );
          }
        }
      }
      return formatToLineWidget([], config);
    }
}
