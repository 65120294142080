/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { isEqual } from 'lodash';
import { Button } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import Field from '../../../../../elements/Field';
import './styles.scss';
import { ButtonCustom } from '../../../../../elements/Button';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import useOutsideClick from '../../../../helpers/Hooks/useOutsideClick';
import ModalCommands from './modalCommands';

const WidgetCommandsGroup = (props) => {
  const {
    sources, getAllAttributes, acceptCommandsModal, commandsToShow,
    deleteCommandGroup, state, command, commandName, getAlias, handleDeleteIndividualCommand,
    isProfile,
  } = props;
  const [stateCommand, setStateCommand] = useState(command);
  const [stateButton] = useState(state);
  const [showCommands, setShowCommands] = useState(false);
  const [stateCommandName, setStateCommandName] = useState(commandName);
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const optionsRef = useRef(null);
  const close = () => setShowOptions(false);
  useOutsideClick(optionsRef, () => close());

  const removeCommand = (index) => {
    const commandClone = clone(stateCommand);
    commandClone.sourcesId.splice(index, 1);
    setStateCommand(commandClone);
    handleDeleteIndividualCommand(commandClone, stateButton);
    if (!commandClone.sourcesId.length) {
      deleteCommandGroup(commandClone, stateButton, stateCommandName);
    }
  };

  useEffect(() => {
    if (!isEqual(command, stateCommand)) {
      setStateCommand(command);
    }

    if (commandName !== stateCommandName) {
      setStateCommandName(commandName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command, commandName]);

  const handleShowCommands = () => {
    setShowCommands(!showCommands);
  };

  const showOptionsAction = () => {
    setShowOptions(!showOptions);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const editGroup = () => {
    toggleModal();
    showOptionsAction();
  };

  const getOptionsAttributes = (row) => {
    if (commandsToShow && commandsToShow?.length) {
      return commandsToShow.map((c) => (
        {
          value: c,
          name: c.name,
        }
      ));
    }
    return sources.find((source) => source.id === row)?.commands.map((c) => (
      {
        value: c,
        name: c.name,
      }
    ));
  };

  const getValueAttribute = (row) => {
    if (commandsToShow && commandsToShow?.length) {
      return commandsToShow.find((c) => c.name === stateCommand.commandName);
    }
    return sources.find((source) => source.id === row).commands.find(
      (c) => c.name === stateCommand.commandName,
    );
  };

  return (
    <>
      { showModal && (
        <ModalCommands
          sources={sources}
          getAllAttributes={getAllAttributes}
          state={stateButton}
          toggleShowModal={toggleModal}
          acceptCommandsModal={acceptCommandsModal}
          editGroupCommands
          commandsGroup={stateCommand}
          getAlias={getAlias}
        />
      )}
      <Col sm={12} className="commandGroupContainer">
        <Row className={`${isProfile ? 'commandGroupHeader commandHeaderSpace' : 'commandGroupHeader'}`}>
          {stateCommand && (
            <>
              <Col sm={`${isProfile ? 10 : 9}`}>
                <Row className="commandGroupTittle">
                  <Col className="d-flex justify-content-between">
                    <p className="d-flex mb-0" onClick={handleShowCommands}>
                      <span className="commandGroupToggler">{showCommands ? '‒ ' : '+ '}</span>
                      <span className="commandName">{stateCommandName}</span>
                      <span className="ml-2 commandGroupNumberOfCommands">
                        {' '}
                        {stateCommand.sourcesId.length}
                        {' '}
                      </span>
                    </p>
                    <div>
                      <Button className="mx-0 p-0 commandGroupOptions" onClick={() => showOptionsAction()}>
                        ...
                      </Button>

                      {showOptions && (
                      <div className="tableMenu">
                        <ul className="list-unstyled">
                          <li onClick={() => editGroup()}>
                            <FormattedMessage
                              id="options.edit"
                              defaultMessage="edit"
                              description="Title of edit"
                            />
                          </li>
                          <li onClick={() => {
                            deleteCommandGroup(stateCommand, stateButton, stateCommandName);
                            showOptionsAction();
                          }}
                          >
                            <FormattedMessage
                              id="options.delete"
                              defaultMessage="delete"
                              description="Title of delete"
                            />
                          </li>
                        </ul>
                      </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
        {showCommands && stateCommand.sourcesId.map((row, index) => (
          <Row className={`${isProfile ? 'commandGroupHeader commandHeaderSpace' : 'commandGroupHeader'}`}>
            <Col sm={`${isProfile ? 10 : 9}`} className={`d-flex pl-4 ${isProfile ? 'spaceButton' : ''}`}>
              <Row className="w-100">
                <Col sm={`${isProfile ? 8 : 9}`}>
                  <Row>
                    <Col sm={6} className={`commandGroupFields fieldCol ${isProfile ? 'pr-1' : ''}`}>
                      <Field
                        key={1}
                        name="device"
                        type="select"
                        options={sources.map((d) => (
                          {
                            value: d,
                            name: d.name,
                          }
                        ))}
                        value={sources.find((source) => source.id === row)}
                      />
                    </Col>
                    <Col sm={6} className={`commandGroupFields fieldCol ${isProfile ? 'pr-1' : ''}`}>
                      <Field
                        key={2}
                        name="attribute"
                        type="select"
                        options={getOptionsAttributes(row)}
                        value={getValueAttribute(row)}
                        blocked
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={`${isProfile ? 4 : 3}`} className="commandGroupFields commandCol fieldCol">
                  <Field
                    key={3}
                    name="command"
                    type="autoComplete"
                    value={getAlias(stateCommand.commandName, stateCommand.value)}
                    blocked
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={1} className="commandGroupFields align-self-center d-flex">
              <Row>
                <ButtonCustom
                  type="circular"
                  name="minus"
                  onClick={() => removeCommand(index)}
                />
              </Row>
            </Col>
          </Row>
        ))}
      </Col>
    </>
  );
};

export default injectIntl(WidgetCommandsGroup);
