import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import SelectableComponent from './SelectableComponent';
import TableStyled from './table';
import Row, { HeaderRow } from '../../Table/components/row';
import TH from './th';
import FlexInliner from '../../../elements/FlexInliner/index';
import TD from './td';
import CheckBox from '../../../elements/CheckBox';
import { getHash } from '../../../helpers/utils';

const NoResults = styled.div`
  width: 100%;
  text-align: center;
`;

const BasicTable = ({
  intl,
  onCheckedAll,
  checked,
  typeOfData,
  onRemove,
  body,
  header,
  optionsForRow,
  optionsForTop,
  selectable,
  onChecked,
  rowComponent,
  onRowClick,
  optionsMenu,
  css,
  addIconByOrder,
  noOptions: NoOptionsComponent,
}) => {
  const OptionsMenu = optionsMenu;

  const getRow = (row) => {
    if (NoOptionsComponent && !optionsForRow) {
      return <TD><NoOptionsComponent rowContent={row} /></TD>;
    }
    if (optionsForRow && !NoOptionsComponent) {
      return (
        <TD>
          <OptionsMenu
            rowContent={row}
            OptionsForRow={optionsForRow}
          />
        </TD>
      );
    }
    return (
      <TD>
        <i
          role="presentation"
          className="uil uil-ellipsis-v"
          // eslint-disable-next-line react/no-this-in-sfc
          onClick={() => this.handleOnMoreButton(row)}
        />
      </TD>
    );
  };

  return (
    <>
      {optionsForTop}
      {selectable && (
        <SelectableComponent
          onCheckedAll={onCheckedAll}
          checked={checked}
          typeOfData={typeOfData}
          onRemove={onRemove}
          data={body}
        />
      )}
      <TableStyled css={css} className="table-responsive customTable">
        <colgroup>
          {selectable && <col key="colgroup-col-selectable" />}

          {header
            && header.map((key, i) => <col key={getHash(`colgroup-col-${i}`)} />)}
          {(optionsForRow || optionsForTop) && <col className="lastCol" />}
        </colgroup>
        <thead>
          <HeaderRow>
            {selectable && (
              <TH key="th_selectable" className="th_selectable" />
            )}
            {header.map((h, i) => (
              // eslint-disable-next-line react/no-this-in-sfc
              <TH key={getHash(`th_${i}`)} onClick={() => this.handleHeaderClick(h)}>
                <FlexInliner justifyContent="start">
                  {h.label}
                  {addIconByOrder(h)}
                </FlexInliner>
              </TH>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {body.map((row, index) => (
            <React.Fragment key={getHash(`tr-${index}`)}>
              <Row className={selectable && checked[index] ? 'selected' : ''}>
                {selectable && (
                <TD key={getHash(`th_selectable-${index}`)}>
                  <CheckBox
                    label={' '}
                    checked={checked[index]}
                    onClick={() => onChecked(index)}
                  />
                </TD>
                )}
                {rowComponent(row, () => onRowClick(row))}
                {getRow(row)}
              </Row>
              <Row />
            </React.Fragment>
          ))}
          {body.length === 0 && (
            <Row>
              <TD colSpan={header.length + 1}>
                <NoResults>
                  {intl.formatMessage({ id: 'table.no.results' })}
                </NoResults>
              </TD>
            </Row>
          )}
        </tbody>
      </TableStyled>
    </>
  );
};

export default injectIntl(BasicTable);
