import React from 'react';
import { SelectedOptionsList } from './helpers';

export const SelectedOptionWrapper = ({
  removeEvent,
  removeChipEvent,
  openEvent,
  data,
  blurEvent,
  ...props
}) => {
  return (
    <div className="selected">
      <SelectedOptionsList {...props} data={data} chipClick={removeChipEvent} />
      {data && data.length !== 0 && (
        <i className="uil uil-times custom" onClick={removeEvent}></i>
      )}
      <i className="uil uil-angle-down expandMore" onClick={openEvent}></i>
    </div>
  );
};
