/* eslint-disable import/no-cycle */
import React from 'react';
import { cloneDeep } from 'lodash';
import getMe from './getMe';
import { FormattedMessage } from '../Contexts/LanguageContext';
import { getDevice } from '../services/redux/devices/actions';
import { getKpi } from '../services/redux/kpis/actions';
import { getETLProcedure } from '../services/redux/etlProcedures/actions';
import { operations, planners, sampling } from '../configuration/variables';

export const clone = (entity) => (
  !Array.isArray(entity)
    ? new entity.Model(cloneDeep(entity))
    : cloneDeep(entity).map((o) => new o.Model(o))
);

export const isShowV2 = () => true;

export const cloneInstance = (instance) => Object.assign(
  Object.create(Object.getPrototypeOf(instance)),
  instance,
);

export const getEntityType = (nameModel) => nameModel.charAt(0).toLowerCase().concat(nameModel.slice(1), 's');

const checkPermissionToEdit = (user, permissionsPolicy, owner) => {
  const groups = !user.groups ? [] : user.groups.map((o) => o.id);

  return owner === user.id || !!permissionsPolicy.users.find((o) => o.id === user.id && o.write)
    || !!permissionsPolicy.groups.find((o) => groups.includes(o.id) && o.write);
};

export const havePermissionToEdit = async (permissionsPolicy, owner) => {
  const user = await getMe();
  return user && permissionsPolicy
    ? checkPermissionToEdit(user, permissionsPolicy, owner)
    : false;
};

export const haveMenuPermissionToEdit = (user, permissionsPolicy, owner) => (
  user && permissionsPolicy
    ? checkPermissionToEdit(user, permissionsPolicy, owner)
    : false
);

export const parseOptions = (option) => ({ value: option, name: <FormattedMessage id={`widgets.wizard.${option}`} /> });

export const genericParseOptions = (option, entityType, componentType) => (
  { value: option, name: <FormattedMessage id={`${entityType}.${componentType}.${option}`} /> }
);

export const isNumber = (value) => !Number.isNaN(Number(value));

export const getURN = (urn, isPublicDashboard = false) => {
  const URN_API = 0;
  const URN_SERVICE = 1;
  const URN_ID = 2;
  const match = urn.match(/\w+/g);

  if (match.length !== 3 || match[URN_API] !== 'fiwoo') {
    throw new Error('IGNORED MALFORMED URN:', urn);
  }
  switch (match[URN_SERVICE]) {
    case 'device':
      return getDevice({ id: match[URN_ID], isPublicDashboard });
    case 'kpi':
      return getKpi({ id: match[URN_ID], isPublicDashboard });
    case 'etl':
      return getETLProcedure({ id: match[URN_ID], isPublicDashboard });
    default:
      throw new Error('UNKNOWN URN-SERVICE:', urn);
  }
};

export const getUrnId = (urn) => {
  const match = urn.match(/\w+/g);

  if (match.length !== 3) {
    throw new Error('IGNORED MALFORMED URN:', urn);
  }
  return match[2];
};

export const getEntityForUrn = (entity = 'Device') => {
  const entities = {
    Device: 'device',
    ETLProcedure: 'etl',
    Kpi: 'kpi',
  };

  return entities[entity];
};

export const buildUrn = (source) => `fiwoo:${source.type.toLowerCase()}:${source.id}`;

export const getUrnEntity = (urn) => {
  const match = urn.match(/\w+/g);

  if (match.length !== 3) {
    throw new Error('IGNORED MALFORMED URN:', urn);
  }

  switch (match[1]) {
    case 'device':
    case 'kpi':
      return match[1].charAt(0).toUpperCase().concat(match[1].slice(1));
    default:
      return match[1].toUpperCase().concat('Procedure');
  }
};

const getVariableWithTranslation = (name, data, intl, exclude = [], add = []) => (
  [...add, ...data]
    .filter((o) => !exclude.includes(o))
    .map((o, index) => ({
      id: index,
      name: intl.formatMessage({ id: `${name}.${o}` }),
      value: o,
    }))
);

export const getSamplingWithTranslation = (intl, exclude = [], add = []) => (
  getVariableWithTranslation('sampling', sampling, intl, exclude, add)
);

export const getOperationsWithTranslation = (intl, exclude = []) => (
  getVariableWithTranslation('operations', operations, intl, exclude)
);

export const getPlannersWithTranslation = (intl, exclude = []) => (
  getVariableWithTranslation('planner', planners, intl, exclude)
);

export const getAllDeviceAttributes = (device, exclude = []) => {
  const deviceCopy = cloneDeep(device);
  exclude.forEach((o) => { deviceCopy[o] = undefined; });
  return [
    ...(deviceCopy && deviceCopy.attributes ? deviceCopy.attributes : []),
    ...(deviceCopy && deviceCopy.lazy_attributes ? deviceCopy.lazy_attributes : []),
    ...(deviceCopy && deviceCopy.command_attributes ? deviceCopy.command_attributes : []),
    ...(deviceCopy && deviceCopy.static_attributes ? deviceCopy.static_attributes : []),
  ];
};

export const parseToSources = (source) => {
  // Source structure to follow

  const parsedSource = {
    id: source.id,
    name: source.name,
    type: source.constructor.entityName,
    categories: source.categories ?? [],
    sourceId: '',
    attributes: [],
    commands: [],
    permissions_policy: source.permissions_policy,
  };

  switch (source.constructor.entityName) {
    case 'Device':
      parsedSource.sourceId = source.device_id;
      parsedSource.attributes = getAllDeviceAttributes(source, ['command_attributes']);
      parsedSource.commands = getAllDeviceAttributes(source, ['attributes', 'lazy_attributes', 'static_attributes']);
      return parsedSource;
    case 'Kpi':
      parsedSource.sourceId = source.id;
      parsedSource.attributes = [{
        name: 'data',
        type: 'number',
        unit: source.unit,
      }];
      return parsedSource;
    default:
      console.error(`This source its no defined ${source.constructor.entityName}` , source);
      return parsedSource;
  }
};

export const getHash = (string) => {
  let hash = 0;
  string.split('').forEach((char, i) => {
    const character = string.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + character;
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  });
  return hash;
};
