import Faker from 'faker';

import { runtimeManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = runtimeManagement.urlAPI.concat(runtimeManagement.paths.datasource);

const queryPageParam = 'x-query-page';
const querySizeParam = 'x-query-size';

const exampleBodyFn = (numberOfDataSourceToGenerate = 50) => {
  const DataSourcesFaker = [];
  for (let i = 0; i < numberOfDataSourceToGenerate; i += 1) {
    DataSourcesFaker.push({
      id: Faker.random.uuid(),
      name: Faker.random.word(),
      owner: Faker.random.uuid(),
      entity_type: Faker.random.word(),
      attributes: [],
      lazy_attributes: [],
      command_attributes: [],
      static_attributes: [],
    });
  }
  const dataSources = { dataSources: DataSourcesFaker };
  return dataSources;
};

/** * First version of DataSource CRUD */
// PLURAL
export const readDataSources = async (page, size, filters, external, gis) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const queryExternal = external ? '&source.domain=external' : '&source.domain=fiwoo';
  const queryGIS = gis ? '&source.type=geoserver' : ''

  const params = {
    endPoint: endPoint + '/list/all?'.concat(
      queryPage, '&',
      querySize,
      queryFilters,
      queryExternal,
      queryGIS
    ),
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };

  return requestAPI(params, exampleBodyFn);
};

export const readMyDataSources = async (page, size, filters) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };
  return requestAPI(params, exampleBodyFn);
};

export const readDataSourceLayers = async (dataSourceId) => {
  if (!dataSourceId) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/check/geoserver/${dataSourceId.toLowerCase()}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'object',
  };

  return requestAPI(params, exampleBodyFn);

};

/** * First version of DataSource CRUD */
// PLURAL
export const readPublicDataSources = async (page, size) => {
  const queryPage = page ? `${queryPageParam}=${page}` : '';
  const querySize = size ? `${querySizeParam}=${size}` : '';

  const params = {
    endPoint: endPoint + '/list/public?'.concat(queryPage, '&', querySize),
    statusOK: 200,
    toJSON: true,
    returnData: 'objects',
  };

  return requestAPI(params, exampleBodyFn);
};

// SINGULAR
export const readDataSource = async (dataSourceId) => {
  if (!dataSourceId) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${dataSourceId}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'dataSource',
  };

  const answer = await requestAPI(params, exampleBodyFn);

  return answer;
};

// SINGULAR
export const readDataSourceFromDevice = async (deviceId) => {
  if (!deviceId) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/from/device/${deviceId}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'dataSource',
    requestConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },

  };

  const answer = await requestAPI(params, exampleBodyFn);

  return answer;
};

export const readDataSourceFromGeoServer = (data) => {

  const params = {
    endPoint: `${endPoint}/check/geoserver`,
    statusOK: 200,
    toJSON: true,
    returnData: 'geoserver',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  return requestAPI(params, exampleBodyFn);
}

export const addDataSource = async (dataSource) => {
  if (dataSource.id) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'dataSource',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(dataSource),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  return requestAPI(params, exampleBodyFn);
};

export const updateDataSource = (dataSource) => {
  if (!dataSource.id) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: `${endPoint}/${dataSource.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: dataSource,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(dataSource),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const deleteDataSource = (dataSource) => {
  if (!dataSource.id) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint}/${dataSource.id}`,
    statusOK: 204,
    toJSON: false,
    returnData: dataSource,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};
