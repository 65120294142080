import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import Menu from '../../../../components/Menu';
import OptionsForDashboard from './OptionsForDashboard';
import Switch from '../../../../elements/Switch';
import { havePermissionToEdit } from '../../../../helpers/utils';
import './styles.scss';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';


const ButtonsBloq = Styled('div')`

`;

const ButtonAnimation = Styled('div')`

`;


const DasboardOptionsMenu = (props) => {
  const {
    title, date, isResizable, handleResizable, isAnimationResizable, handleAnimationResizable,
    dashboard, clickView, profileType, menuElement,
  } = props;
 
  const permissionToEdit = havePermissionToEdit(dashboard.permissions_policy, dashboard.owner);
  const openButton = (propsVar) => (
    <Row>
      <Col className="d-flex justify-content-start align-items-center flex-wrap">
        <div
          className="dashdoardTitle"
          onClick={propsVar.onClick}
          role="presentation"
        >
          <span className="titleText">
            {title}
            <i className="uil uil-angle-down" />
          </span>
        </div>
        <div className="d-flex justify-content-end w-100">
          <div className="rectangle">
            <p className="dateText">
              {date}
              {' '}
            </p>
          </div>
          <div className="animation-button">

            {permissionToEdit && (
            <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
              <ButtonAnimation>
                <button type="button" onClick={handleAnimationResizable} id="animateWidgets">

                  {isAnimationResizable ? (
                    <i
                      style={{ fontSize: '18px!important' }}
                      className="uil uil-lock-open-alt"
                    >
                      <FormattedMessage id="dashboard.button.widgetAnimation" />
                    </i>
                  ) : (
                    <i className="uil uil-padlock">
                      <FormattedMessage id="dashboard.button.widgetAnimation" />
                    </i>
                  )}
                  <span>
                    <Switch checked={!isAnimationResizable} />
                  </span>
                </button>
              </ButtonAnimation>
            </PrivateComponent>
            )}
          </div>
          <div className="block-button">
            {permissionToEdit && (
            <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
              <ButtonsBloq>
                <button type="button" onClick={handleResizable} id="blockWidgets">

                  {isResizable ? (
                    <i
                      style={{ 'font-size': '18px!important' }}
                      className="uil uil-lock-open-alt"
                    >
                      <FormattedMessage id="dashboard.button.lockWidgets" />
                    </i>
                  ) : (
                    <i className="uil uil-padlock">
                      <FormattedMessage id="dashboard.button.lockWidgets" />
                    </i>
                  )}
                  <span>
                    <Switch checked={!isResizable} />
                  </span>
                </button>
              </ButtonsBloq>
            </PrivateComponent>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="top left"
      className="more"
    >
      <OptionsForDashboard
        dashboard={dashboard}
        clickView={clickView}
        profileType={profileType}
      />

    </Menu>
  );
};

DasboardOptionsMenu.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  isResizable: PropTypes.bool,
  handleResizable: PropTypes.func,
  isAnimationResizable: PropTypes.bool,
  handleAnimationResizable: PropTypes.func,
  dashboard: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  profileType: PropTypes.string,
};

DasboardOptionsMenu.defaultProps = {
  title: '',
  date: '',
  isResizable: undefined,
  handleResizable: () => {},
  isAnimationResizable: undefined,
  handleAnimationResizable: () => {},
  dashboard: {},
  clickView: () => {},
  profileType: '',
};

const DashboardMenuTittle = (props) => {
  const {
    title, date, isResizable, handleResizable, isAnimationResizable, handleAnimationResizable,
    dashboard,
  } = props;
  return (
    <DasboardOptionsMenu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title={title}
      date={date}
      isResizable={isResizable}
      handleResizable={handleResizable}
      isAnimationResizable={isAnimationResizable}
      handleAnimationResizable={handleAnimationResizable}
      dashboard={dashboard}
    />

  );
};

DashboardMenuTittle.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  isResizable: PropTypes.bool,
  handleResizable: PropTypes.func,
  isAnimationResizable: PropTypes.bool,
  handleAnimationResizable: PropTypes.func,
  dashboard: PropTypes.objectOf(PropTypes.any),
};

DashboardMenuTittle.defaultProps = {
  title: '',
  date: '',
  isResizable: undefined,
  handleResizable: () => {},
  isAnimationResizable: undefined,
  handleAnimationResizable: () => {},
  dashboard: {},
};

export default DashboardMenuTittle;
