/* eslint-disable import/no-cycle */
import Moment from 'moment';
import { orderBy, cloneDeep } from 'lodash';
import { getTimeFromHistorical } from '../../../../../utils';
import { getLanguageFromBrowser } from '../../../../../../../helpers';
import { getUrnId } from '../../../../../../../helpers/utils';

const parseLinked = (details, configuration) => {
  const attributes = configuration.data.attributeFilter;
  const detailsCopy = cloneDeep(details);

  detailsCopy.data = detailsCopy.data.map((d) => {
    const data = { sourceId: d.sourceId };
    attributes.forEach((attr) => { data[attr] = d[attr]; });
    return data;
  });

  detailsCopy.index = details.index.filter((d) => attributes.includes(d.attrName));

  return detailsCopy;
};

const formatToLinkedBarWidget = (
  historicalValues,
  configuration,
  intl,
) => {
  const details = { data: [], index: [] };
  const lastValuesFromHistoricals = historicalValues.filter((h) => !h.entities);
  const aggregatesHistoricalValues = historicalValues.filter((h) => h.entities);
  const devices = [];

  if (configuration.data.type === 'historical') {
    const deviceNames = [];

    Moment.locale(getLanguageFromBrowser() || 'en');

    aggregatesHistoricalValues.forEach((h) => {
      h.entities.forEach((e) => {
        const index = devices.findIndex((d) => d.entityId === e.entityId);
        if (index > -1) {
          // We check that the attribute exists on the device.
          if (!Object.keys(devices[index].attributes).includes(e.attrName)) {
            // If it doesn't exist, we create it and then we inject the origin points
            devices[index].attributes[e.attrName] = { [h.origin]: e.points };
          } else {
            // If it exists, the only thing we have to do is create the new key
            // with the origin and its points.
            devices[index].attributes[e.attrName][h.origin] = e.points;
          }
        } else {
          devices.push({
            id: getUrnId(e.urn),
            entityId: e.entityId,
            attributes: {
              [e.attrName]: {
                [h.origin]: e.points,
              },
            },
          });
        }
      });
    });

    devices.forEach((device) => {
      Object.keys(device.attributes).forEach((attribute) => {
        if (
          configuration.data.attributeFilter
          && !configuration.data.attributeFilter.includes(attribute)
        ) {
          return;
        }
        let name = `${device.entityId}-${attribute}`;
        const equalName = deviceNames.filter((deviceName) => deviceName.entityId === name);
        if (equalName.length) {
          name = `${equalName[0].entityId}(${equalName[0].number})`;
          equalName[0].number += 1;
        } else {
          deviceNames.push({ name, number: 1 });
        }

        Object.keys(device.attributes[attribute]).forEach((origin) => {
          device.attributes[attribute][origin].forEach((point) => {
            const sampling = configuration.data.sampling || 'lastMonth';
            const xTime = getTimeFromHistorical(sampling, origin, point.offset);
            const datafound = details.data.find((d) => (d.device_id === xTime));
            if (devices.length > 1) {
              if (datafound) {
                datafound[`${device.entityId}-${attribute}`] = Number.parseFloat(point.avg).toFixed(2);
              } else {
                details.data.push({
                  device_id: xTime,
                  [`${device.entityId}-${attribute}`]: Number.parseFloat(point.avg).toFixed(2),
                });
              }
            } else if (datafound) {
              datafound[attribute] = Number.parseFloat(point.avg).toFixed(2);
            } else {
              details.data.push({
                [attribute]: Number.parseFloat(point.avg).toFixed(2),
                device_id: xTime,
              });
            }
          });
        });
      });
    });
    details.index = deviceNames.map((d) => d.name);
    details.data = orderBy(details.data, ['device_id'], ['asc']);
  } else {
    const attrs = [];
    const data = [];

    lastValuesFromHistoricals.forEach((h) => {
      if (!h.value) return;
      const index = attrs.findIndex((attr) => attr.name === h.attrName);
      if (index >= 0) {
        attrs[index].entities.push(
          {
            entityId: h.entityId,
            value: h.value.value,
          },
        );
      } else {
        attrs.push({
          name: configuration.labels.alias[h.attrName] || h.attrName,
          attrName: h.attrName,
          entities: [{
            entityId: h.entityId,
            value: h.value.value || h.value,
          }],
        });
      }
      if (data.length) {
        data[0][`${h.attrName}`] = h.value.value || h.value;
      } else {
        data.push({
          sourceId: intl.formatMessage({ id: 'widget.bars.lastValue' }),
          [`${h.attrName}`]: h.value.value || h.value,
        });
      }
    });

    details.data = data;
    details.index = attrs;
  }
  return parseLinked(details, configuration);
};

export default formatToLinkedBarWidget;
