import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { areAllChecked } from './utils';
import CheckBox from '../../../../../elements/CheckBox';

const ListHeader = ({
  checkedList, onClick, checkable, nameless,
}) => (
  <div className="sendCommandItem header">
    {checkable && (
    <div className="listCheckbox">
      <CheckBox id="checkAll" checked={areAllChecked(checkedList)} onClick={onClick} />
    </div>
    )}
    {!nameless && (
    <div className="deviceName">
      <FormattedMessage id="SendCommandList.header.name" />
    </div>
    )}
    <div>
      <FormattedMessage id="SendCommandList.header.attribute" />
    </div>
    <div>
      <FormattedMessage id="SendCommandList.header.command" />
    </div>
    <div className="sendingButton" />
    <div className="errorsMessages" />
  </div>
);

ListHeader.defaultProps = {
  checkable: true,
  nameless: undefined,
  onClick: () => {},
  checkedList: [],
};

ListHeader.propTypes = {
/* If it is a checkable list */
  checkable: PropTypes.bool,
  /* If the header must display a device name colum */
  nameless: PropTypes.bool,
  /* Event that trigger when clicking in the checkAll checkbox */
  onClick: PropTypes.func,
  /* Boolean list with the checked state */
  checkedList: PropTypes.arrayOf(PropTypes.bool),
};

export default ListHeader;
