import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const Footer = ({ onClose, onSend, isValidated }) => (
  <div className="footer">
    <div
      onClick={onClose}
      id="closeProfileButton"
      role="presentation"
    >
      <FormattedMessage id="SendCommandList.button.cancel" />
    </div>
    <div
      onClick={() => isValidated() && onSend()}
      className={!isValidated() ? 'disabled' : 'enabled'}
      id="sendingCommandButton"
      role="presentation"
    >
      <i className="uil uil-check" />
      {' '}
      <FormattedMessage id="SendCommandList.button.sendCommands" />
    </div>
  </div>
);

Footer.defaultProps = {
  onClose: () => {},
  onSend: () => {},
  isValidated: () => {},
};

Footer.propTypes = {
/* Event that triggers on closing */
  onClose: PropTypes.func,
  /* Event that triggers on send click */
  onSend: PropTypes.func,
  /* Function that tests if everything is validated */
  isValidated: PropTypes.func,
};
export default Footer;
