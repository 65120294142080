export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST';
export const ADD_SERVICE_SUCCESS_FLAG = 'ADD_SERVICE_SUCCESS_FLAG';
export const ADD_SERVICE_ERROR = 'ADD_SERVICE_ERROR';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';

export const MODIFY_SERVICE_REQUEST = 'MODIFY_SERVICE_REQUEST';
export const MODIFY_SERVICE_SUCCESS_FLAG = 'MODIFY_SERVICE_SUCCESS_FLAG';
export const MODIFY_SERVICE_SUCCESS = 'MODIFY_SERVICE_SUCCESS';
export const MODIFY_SERVICE_ERROR  = 'MODIFY_SERVICE_ERROR';


export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_ERROR = 'DELETE_SERVICE_ERROR';

export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICE_REQUEST = 'GET_SERVICE_REQUEST';
export const GET_SERVICE_ROLES_REQUEST = 'GET_SERVICE_ROLES_REQUEST';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR';

export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_ERROR = 'GET_SERVICES_ERROR';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';

export const GET_MY_SERVICES_REQUEST = 'GET_MY_SERVICES_REQUEST';
export const GET_MY_SERVICES_ERROR = 'GET_MY_SERVICES_ERROR';
export const GET_MY_SERVICES_SUCCESS = 'GET_MY_SERVICES_SUCCESS';

export const CLEAR_SERVICES_STATE = 'CLEAR_SERVICES_STATE';


export const ERRORFETCHING_SERVICE_RESET = 'ERRORFETCHING_SERVICE_RESET';
export const UPDATE_SERVICES_STATE = 'UPDATE_SERVICES_STATE';


