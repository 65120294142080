import React from 'react';
import Styled from 'styled-components';
import BreadCrumbs from '../BreadCrumbs';
import './styles.scss';

const SubTitle = Styled('h2')`
    margin: 0;
    font-weight: 100;
    color: #444;
`;

const PageHeaderStyle = Styled('div')`
    width: 100%;
    display: block;
    ${(p) => p.css}
`;

class PageHeader extends React.PureComponent {
  render() {
    const { subtitle, path, css } = this.props;
    return (
      <PageHeaderStyle css={css}>
        {subtitle && <SubTitle>{ subtitle }</SubTitle>}
        {path && <BreadCrumbs path={path} />}
      </PageHeaderStyle>
    );
  }
}

PageHeader.defaulProps = {
  subtitle: null,
  path: null,
  css: '',
};

export default PageHeader;
