import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CommonView from '../../../CommonView';
import Field from '../../../../elements/Field/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Kpi, { SampleKpi } from '../../../../models/Kpi/index';
import { protocolData } from '../../../../data/protocols';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import {
  requestOrganizationList,
  requestDataSetList,
  resetDataSetList,
  requestDataSetListByOrganization,
} from '../../../../services/redux/opendata/actions';
import {
  selectOrganizationList,
  selectOrganizationListTotal,
  selectDataSetList,
  selectDataSetListTotal,
  selectErrorOpenData,
} from '../../../../services/redux/opendata/selectors';
import DynamicSelect from '../../../helpers/Add/components/opendata/DynamicSelect';

const title = (
  <FormattedMessage
    id="kpis.add.title"
    defaultMessage="Create kpi"
    description="Title of create kpi"
  />
);
const categoriesList = [
  { name: <FormattedMessage id="devices.wizard.categories.other" />, id: 13, value: 'OTHER' },
  // { name: <FormattedMessage id="devices.wizard.categories.luminaire" />, id: 1, value: 'LUMINAIRE' },
  // { name: <FormattedMessage id="devices.wizard.categories.waste_container" />, id: 2, value: 'WASTE_CONTAINER' },
  // { name: <FormattedMessage id="devices.wizard.categories.weather_station" />, id: 3, value: 'WEATHER_STATION' },
  // { name: <FormattedMessage id="devices.wizard.categories.environmental_quality" />, id: 4, value: 'ENVIRONMENTAL_QUALITY' },
  // { name: <FormattedMessage id="devices.wizard.categories.gps" />, id: 5, value: 'GPS' },
  // { name: <FormattedMessage id="devices.wizard.categories.parking" />, id: 6, value: 'PARKING' },
  // { name: <FormattedMessage id="devices.wizard.categories.electric_panel" />, id: 7, value: 'ELECTRIC_PANEL' },
  // { name: <FormattedMessage id="devices.wizard.categories.health" />, id: 8, value: 'HEALTH' },
  // { name: <FormattedMessage id="devices.wizard.categories.video_camera" />, id: 9, value: 'VIDEO_CAMERA' },
  // { name: <FormattedMessage id="devices.wizard.categories.electric_meter" />, id: 10, value: 'ELECTRIC_METER' },
  // { name: <FormattedMessage id="devices.wizard.categories.water_meter" />, id: 11, value: 'WATER_METER' },
  // { name: <FormattedMessage id="devices.wizard.categories.irrigation" />, id: 12, value: 'IRRIGATION' },
];

class CreateKpi extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      name: this.props.newEntity.name,
      description: this.props.newEntity.description,
      categories: this.props.newEntity.categories,
      organization: this.props.newEntity.organization,
      dataset: this.props.newEntity.dataset,
      errors: this.props.errors,
      onValidation: false,
    };
  }

  validate = () => {
    this.savePartialKpi();
  };

  savePartialKpi = () => {
    this.setErrors({});

    const obj = this.state;
    const NewKpi = new Kpi(obj);
    const validationErrors = NewKpi.validate(['name', 'description', 'categories', 'organization', 'dataset']);

    if (validationErrors) {
      this.setErrors(validationErrors);
      this.props.onError(true);
    } else {
      const { errors, onValidation, ...rest } = this.state;
      this.props.onContinue(rest);
    }
  };

  handleSelectChange = (name, value) => {
    const { categories } = this.state;
    if (categories[0]) {
      categories[0] = value;
    } else {
      categories.push(value);
    }
    this.setState({
      categories,
    });
  }

  handleSetOrganization = (organization) => {
    this.setState({ organization });
    resetDataSetList();
    requestDataSetListByOrganization({
      organization,
      page: 1,
      size: 5,
      filter: [],
    });
  };

  handleGetOrganizationList = (page, size, filter) => {
    requestOrganizationList({ page, size, filter });
  };

  handleGetDatasetList = (page, size, filter) => {
    const { organization } = this.state;
    if (!organization) {
      return;
    }

    requestDataSetListByOrganization({
      organization,
      page,
      size,
      filter,
    });
  };

  handleMapOptions = (o) => ({ name: o.title, value: o.id });

  handleSetDataSet = (dataset) => {
    const { datasetTotal } = this.props;
    if (datasetTotal > 0) this.setState({ dataset });
  };

  componentDidMount() {
    const { newEntity } = this.props;
    this.props.onRef(this);

    if (newEntity && newEntity.protocol) {
      protocolData.protocol.find((p) => (
        p.value === this.props.newEntity.protocol).translator && this.translateEntity());
    }
  }

  render() {
    const fields = ['organization', 'dataset'];
    const {
      intl,
      organizationList,
      organizationTotal,
      datasetList,
      datasetTotal,
    } = this.props;
    const { errors, organization } = this.state;
    return (
      <>
        <Container>
          <div className="wizardWrapper">
            <Row>
              <Col lg={{ size: 4, offset: 4 }}>
                {/* <Field
                  name="categories"
                  label={intl.formatMessage({
                    id: 'kpis.wizard.categories.label',
                  })}
                  // placeholder={intl.formatMessage({
                  //   id: 'kpis.wizard.categories.placeholder',
                  // })}
                  id="categories"
                  value={intl.formatMessage({
                    id: 'categories.other',
                  })}
                  type="text"
                  // onChange={this.handleSelectChange}
                  // helperText={errors.categories}
                  // error={!!errors.categories}
                  key="categories"
                  // options={categoriesList}
                /> */}

                {fields.map(
                  (key) => (
                    <DynamicSelect
                      name={key}
                      placeholder={intl.formatMessage({
                        id: `kpis.wizard.${key}.placeholder`,
                      })}
                      id={key}
                      total={key === 'organization'
                        ? organizationTotal
                        : datasetTotal}
                      value={this.state[key]}
                      error={!!errors[key]}
                      options={key === 'organization'
                        ? organizationList.toJS()
                        : datasetList.toJS()}
                      helperText={errors[key]}
                      getOptions={key === 'organization'
                        ? this.handleGetOrganizationList
                        : this.handleGetDatasetList}
                      mapOptions={this.handleMapOptions}
                      onChange={this.handleSelectChange}
                      setValue={key === 'organization'
                        ? this.handleSetOrganization
                        : this.handleSetDataSet}
                      key={key}
                      disabled={key === 'dataset' && !organization}
                    />
                  ),
                )}
                {['name', 'description'].map((key) => (
                  <Field
                    name={key}
                    label={intl.formatMessage(
                      SampleKpi.plainTranslations[key],
                    )}
                    placeholder={intl.formatMessage({
                      id: `Kpi.model.${key}`,
                    })}
                    id={key}
                    value={this.state[key]}
                    type="text"
                    onChange={this.handleInputChange}
                    helperText={errors[key]}
                    error={!!errors[key]}
                    key={`${key}-kpiCreationwizard`}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addKpiSuccessFlag: state.get('kpis').get('addSuccess'),
  errorFetching: state.get('kpis').get('errorFetching'),
  notify: state.get('notify'),
  organizationList: selectOrganizationList(state),
  organizationTotal: selectOrganizationListTotal(state),
  datasetList: selectDataSetList(state),
  datasetTotal: selectDataSetListTotal(state),
  opendataError: selectErrorOpenData(state),
});

CreateKpi.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  description: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.any),
  organization: PropTypes.string,
  dataset: PropTypes.string,
};

CreateKpi.defaultProps = {
  title,
  name: '',
  description: '',
  permissionsPolicy: [],
  errors: {},
  categories: [],
  organization: '',
  dataset: '',
};

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(CreateKpi)));
