import { categories, Components, subCategories } from '../../utilsConfigurationSheet';

export const MapConfigurationSheetBasic = '';

export const MapConfigurationSheet = {
  configuration: [
    {
      name: 'iconCategory',
      component: Components.switch,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'realTimeUpdates',
      component: Components.switch,
      categories: [categories.DATA],
    },
  ],
  mapStyle: [{
    name: 'viewType',
    component: Components.mapStyle,
    categories: [categories.CUSTOM],
    subCategories: [subCategories.MAP],
  }],
  colorsDataConfig: [{
    name: 'colorsDataMap',
    component: Components.colorsDataMap,
    categories: [categories.COLORS, categories.LABELS],
    subCategories: [subCategories.categoryColors, subCategories.categoryAlias],
    subMenus: [
      {
        name: 'advancedLegendColors',
        component: Components.advancedLegendColors,
        categories: [categories.COLORS, categories.LABELS],
        subCategories: [subCategories.categoryColors, subCategories.categoryAlias],
      },
      {
        name: 'advancedLegendNames',
        component: Components.advancedLegendNames,
        categories: [categories.LABELS],
        subCategories: [subCategories.categoryColors, subCategories.categoryAlias],
      },
    ],
  }],
};

export const MapDefaultTemplateConfig = [];

// TODO Implement validationMap
// eslint-disable-next-line no-unused-vars
export const validationMap = (config, templateConfig = MapDefaultTemplateConfig) => null;
