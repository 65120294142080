export const GET_TREE_REQUEST = 'GET_TREE_REQUEST';
export const GET_TREE_SUCCESS = 'GET_TREE_SUCCESS';
export const GET_TREE_ERROR = 'GET_TREE_ERROR';

export const GET_MY_MENUELEMENTS_REQUEST = 'GET_MY_MENUELEMENTS_REQUEST';
export const GET_MY_MENUELEMENTS_SUCCESS = 'GET_MY_MENUELEMENTS_SUCCESS';
export const GET_MY_MENUELEMENTS_ERROR = 'GET_MY_MENUELEMENTS_ERROR';

export const GET_PUBLIC_MENUELEMENTS_REQUEST = 'GET_PUBLIC_MENUELEMENTS_REQUEST';
export const GET_PUBLIC_MENUELEMENTS_SUCCESS = 'GET_PUBLIC_MENUELEMENTS_SUCCESS';
export const GET_PUBLIC_MENUELEMENTS_ERROR = 'GET_PUBLIC_MENUELEMENTS_ERROR';

export const GET_MENUELEMENT_REQUEST = 'GET_MENUELEMENT_REQUEST';
export const GET_MENUELEMENT_SUCCESS = 'GET_MENUELEMENT_SUCCESS';
export const GET_MENUELEMENT_ERROR = 'GET_MENUELEMENT_ERROR';

export const ADD_MENUELEMENT_REQUEST = 'ADD_MENUELEMENT_REQUEST';
export const ADD_MENUELEMENT_SUCCESS = 'ADD_MENUELEMENT_SUCCESS';
export const ADD_MENUELEMENT_ERROR = 'ADD_MENUELEMENT_ERROR';

export const MODIFY_MENUELEMENT_REQUEST = 'MODIFY_MENUELEMENT_REQUEST';
export const MODIFY_MENUELEMENT_SUCCESS = 'MODIFY_MENUELEMENT_SUCCESS';
export const MODIFY_MENUELEMENT_ERROR = 'MODIFY_MENUELEMENT_ERROR';

export const MODIFY_POSITION_ELEMENT_REQUEST = 'MODIFY_POSITION_ELEMENT_REQUEST';
export const MODIFY_POSITION_ELEMENT_SUCCESS = 'MODIFY_POSITION_ELEMENT_SUCCESS';
export const MODIFY_POSITION_ELEMENT_ERROR = 'MODIFY_POSITION_ELEMENT_ERROR';

export const DELETE_MENUELEMENT_REQUEST = 'DELETE_MENUELEMENT_REQUEST';
export const DELETE_MENUELEMENT_SUCCESS = 'DELETE_MENUELEMENT_SUCCESS';
export const DELETE_MENUELEMENT_ERROR = 'DELETE_MENUELEMENT_ERROR';

export const ADD_MENUELEMENT_SUCCESS_FLAG = 'ADD_MENUELEMENT_SUCCESS_FLAG';
export const ERRORFETCHING_MENUELEMENT_RESET = 'ERRORFETCHING_MENUELEMENT_RESET';

export const GET_MENUELEMENT_ROLES_ERROR = 'GET_MENUELEMENT_ROLES_ERROR';
export const GET_MENUELEMENT_ROLES_REQUEST = 'GET_MENUELEMENT_ROLES_REQUEST';
export const GET_MENUELEMENT_ROLES_SUCCESS = 'GET_MENUELEMENT_ROLES_SUCCESS';
export const MODIFY_MENUELEMENT_SUCCESS_FLAG = 'MODIFY_MENUELEMENT_SUCCESS_FLAG';

export const CLEAR_MENUELEMENTS = 'CLEAR_MENUELEMENTS';
export const UPDATE_MENUELEMENTS_STATE = 'UPDATE_MENUELEMENTS_STATE';
export const ADD_MENUELEMENT_SELECTED_STATE = 'ADD_MENUELEMENT_SELECTED_STATE';
export const UPDATE_MENUELEMENTS_SELECTED_STATE = 'UPDATE_MENUELEMENTS_SELECTED_STATE';
export const CLEAR_MENUELEMENTS_SELECTED_STATE = 'CLEAR_MENUELEMENTS_SELECTED_STATE';
export const HIDDEN_SUBMENU = 'HIDDEN_SUBMENU';

export const GET_SETTINGS_MENU_REQUEST = 'GET_SETTINGS_MENU_REQUEST';
export const GET_SETTINGS_MENU_ERROR = 'GET_SETTINGS_MENU_ERROR';
export const GET_SETTINGS_MENU_SUCCESS = 'GET_SETTINGS_MENU_SUCCESS';

export const UPDATE_SETTINGS_MENU_REQUEST = 'UPDATE_SETTINGS_MENU_REQUEST';
export const UPDATE_SETTINGS_MENU_ERROR = 'UPDATE_SETTINGS_MENU_ERROR';
export const UPDATE_SETTINGS_MENU_SUCCESS = 'UPDATE_SETTINGS_MENU_SUCCESS';

export const SET_HOME_REQUEST = 'SET_HOME_REQUEST';
export const SET_HOME_ERROR = 'SET_HOME_ERROR';
export const SET_HOME_SUCCESS = 'SET_HOME_SUCCESS';

export const GET_HOME_REQUEST = 'GET_HOME_REQUEST';
export const GET_HOME_ERROR = 'GET_HOME_ERROR';
export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';

export const CLEAR_HOME_REQUEST = 'CLEAR_HOME_REQUEST';
export const CLEAR_HOME_ERROR = 'CLEAR_HOME_ERROR';
export const CLEAR_HOME_SUCCESS = 'CLEAR_HOME_SUCCESS';