import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import './styles.scss';

/** Components * */
import { injectIntl, FormattedDate } from 'react-intl';
import { clone } from 'ramda';
import moment from 'moment';
import Menu from '../Menu';

import TD from './components/td';

/** Contexts * */

import Chip from '../../elements/Chip';
import { Avatar } from '../../elements/Avatar';
import DeviceIcon from '../../elements/DeviceIcon';

/** Icons */
import ServiceIcon from '../../configuration/icons/svg/service-icon.svg';

import StatusIndicator from '../../pages/Rules/List/components/StatusIndicator';
import BasicTable from '../CustomTable/components/BasicTable';
import { isMobile } from '../../helpers/getBreakpoint';
import MobileTable from '../CustomTable/components/MobileTable';

const R = require('ramda');

const ButtonForOpenOptionMenu = (props) => {
  const { onClick } = props;
  return (
    <div className="text-right">
      <i
        className="uil uil-ellipsis-v"
        onClick={onClick}
        role="presentation"
      />
    </div>
  );
};

ButtonForOpenOptionMenu.defaultProps = {
  onClick: () => {},
};

const OptionsMenu = (props) => {
  const { OptionsForRow } = props;
  return (
    <Menu
      top="40px"
      openComponent={ButtonForOpenOptionMenu}
      openFrom="top right"
      className="more"
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <OptionsForRow {...props} />
    </Menu>
  );
};

OptionsMenu.defaultProps = {
  OptionsForRow: () => {},
};

class Table extends React.Component {
  constructor(props) {
    super(props);

    const { selectable, body, header } = this.props;

    let checkboxes = [];
    if (selectable === true) {
      checkboxes = this.initCheckboxes(body.length, false);
    }

    this.state = {
      body,
      header,
      inOrderState: {},
      checkedState: checkboxes,
    };
  }

  componentDidUpdate(prevProps) {
    const { body, selectable } = this.props;
    if (prevProps.body !== body) {
      let checked;
      if (selectable) {
        checked = this.initCheckboxes(body.length);
      }
      this.updateState(body, checked);
    }
  }

  initCheckboxes = (length, option = false) => {
    const checkboxes = [];
    let index = 0;
    while (index < length) {
      checkboxes.push(option);
      index += 1;
    }

    return checkboxes;
  };

  orderDataByKey = (data) => {
    const comparators = [];
    const { inOrderState } = this.state;

    Object.entries(inOrderState).forEach((ord) => {
      const key = ord[0];
      if (inOrderState[key] === 'asc') comparators.push(R.ascend(R.prop(key)));
      else if (inOrderState[key] === 'desc') comparators.push(R.descend(R.prop(key)));
    });

    const SortArray = R.sortWith(comparators);
    return SortArray(data);
  };

  checkOrderDirection = (order = undefined) => {
    if (!order) return 'desc';
    if (order === 'desc') return 'asc';
    return undefined;
  };

  handleHeaderClick = (header) => {
    const { inOrderState } = this.state;
    const { key } = header;
    if (!header.noOrder) {
      const inOrder = { ...inOrderState };
      inOrder[key] = this.checkOrderDirection(inOrder[key]);
      this.setState({ inOrderState: inOrder });
    }
  };

  addIconByOrder = (header) => {
    const { inOrderState } = this.state;
    const { key } = header;
    if (inOrderState[key] === 'asc') return <i className="uil uil-angle-down expandMore" />;
    if (inOrderState[key] === 'desc') return <i className="uil uil-angle-up expandLess " />;
    return null;
  };

  handleOnMoreButton = (data) => {
    const { onClickMore } = this.props;
    onClickMore(data);
  };

  handleOnCheckedAll = (checked) => {
    const { onSelectItem } = this.props;
    const { checkedState } = this.state;
    let checkboxes = clone(checkedState);
    checkboxes = this.initCheckboxes(checkboxes.length, checked);
    this.setState({
      checkedState: checkboxes,
    });
    if (onSelectItem) {
      onSelectItem(checkboxes);
    }
  };

  handleOnChecked = (index) => {
    const { onSelectItem } = this.props;
    const { checkedState } = this.state;
    const checkboxes = clone(checkedState);
    checkboxes[index] = !checkboxes[index];

    this.setState({
      checkedState: checkboxes,
    });
    if (onSelectItem) {
      onSelectItem(checkboxes);
    }
    // props clacbak para recuperar arriba
  };

  getTranslate = () => {
    const res = {};
    const properties = [
      'prefixAgo',
      'prefixFromNow',
      'suffixAgo',
      'suffixFromNow',
      'seconds',
      'minute',
      'minutes',
      'hour',
      'hours',
      'day',
      'days',
      'month',
      'months',
      'year',
      'years',
    ];

    properties.forEach((property) => {
      const { intl } = this.props;
      const translate = intl.formatMessage({ id: `widget.date.${property}` });
      res[property] = translate === ' ' ? null : translate;
    });
    return res;
  };

  defaultRowContent = (row, onRowClick) => {
    const { header } = this.state;
    return header.map((h) => {
      const { intl } = this.props;
      switch (h.type) {
        case 'date':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              <FormattedDate value={row[h.key]} />
            </TD>
          );
        case 'dateWithHours':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {moment(row[h.key]).format('DD/MM/YYYY - h:mm:ss')}
            </TD>
          );
        case 'array':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {row[h.key].map((r) => (
                <Chip
                  key={`td-elem-${h.key}`}
                  text={r.name ? r.name : ''}
                  dismissable={false}
                />
              ))}
            </TD>
          );

        case 'array:translated':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {' '}
              { row[h.key].map((data) => `${intl.formatMessage({
                id: `${h.key}.${data.toLowerCase()}`,
              })}  `)}
              {' '}
            </TD>
          );

        case 'userInfo':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              <Avatar
                size="sm"
                backgroundColor="random"
                color="#fff"
                letter={row[h.key].charAt(0).toUpperCase()}
                className="avatar"
              >
                <div className="navColorAvatar">
                  {row[h.key].charAt(0).toUpperCase()}
                </div>
              </Avatar>
              <span>{row[h.key]}</span>
            </TD>
          );

        case 'device-icon':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              <DeviceIcon categories={row[h.key]} />
            </TD>
          );

        case 'deviceAddOn':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {row.template_id && (
              <i className="uil uil-window-grid" />
              )}
              {row.service_id && (
              <img src={ServiceIcon} alt="service" />
              )}
            </TD>
          );
        case 'ruleStatus':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              <StatusIndicator status={row[h.key]} />
            </TD>
          );
        case 'dataType':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              <span className="text-uppercase">{row[h.key].mime}</span>
            </TD>
          );
        case 'value':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {row.value.toString().concat(' ', row.unit.toString())}
            </TD>
          );
        case 'state':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {row.state === 'OPERATIONAL' && <div className="status-operational circle" />}
              {row.state !== 'OPERATIONAL' && <div className="status-not-operational circle" />}
              {row.state.charAt(0).toUpperCase() + row.state.slice(1).toLowerCase()}
            </TD>
          );
        case 'timeAgo':
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              <TimeAgo date={row.updatedAt} formatter={buildFormatter(this.getTranslate())} />
            </TD>
          );
        default:
          return (
            <TD
              key={`td-${h.key}`}
              onClick={() => onRowClick(row)}
            >
              {' '}
              {row[h.key]}
              {' '}
            </TD>
          );
      }
    });
  };

  updateState(body, checked) {
    this.setState({
      body,
      checkedState: checked,
    });
  }

  render() {
    const {
      optionsForRow,
      optionsForTop,
      css,
      selectable,
      typeOfData,
      onRemove,
      entity,
      onRowClick,
      rowComponentForMobile,
      noOptions,
    } = this.props;
    const { body, header, checkedState } = this.state;
    const bodyOrdered = this.orderDataByKey(body);

    return !isMobile() ? (
      <BasicTable
        css={css}
        onCheckedAll={this.handleOnCheckedAll}
        checked={checkedState}
        typeOfData={typeOfData}
        onRemove={onRemove}
        body={bodyOrdered}
        header={header}
        optionsForRow={optionsForRow}
        optionsForTop={optionsForTop}
        selectable={selectable}
        onChecked={this.handleOnChecked}
        rowComponent={this.defaultRowContent}
        onRowClick={onRowClick}
        optionsMenu={OptionsMenu}
        addIconByOrder={this.addIconByOrder}
        entity={entity}
        noOptions={noOptions}
      />
    ) : (
      <MobileTable
        body={bodyOrdered}
        header={header}
        checked={checkedState}
        onChecked={this.handleOnChecked}
        onCheckedAll={this.handleOnCheckedAll}
        optionsMenu={OptionsMenu}
        optionsForRow={optionsForRow}
        rowComponentForMobile={rowComponentForMobile}
        typeOfData={typeOfData}
        onRowClick={onRowClick}
        selectable={selectable}
        entity={entity}
      />
    );
  }
}

Table.defaultProps = {
  body: [],
  header: [],
  optionsForRow: null,
  optionsForTop: null,
  css: '',
  selectable: false,
  onRowClick: () => {},
  onRemove: () => {},
  onClickMore: () => {},
  onSelectItem: () => {},
  intl: {},
  typeOfData: '',
  entity: () => {},
  rowComponentForMobile: '',
  noOptions: '',
};

export default injectIntl(Table);
