import {
  Components, categories, options,
} from '../../utils';

export default {
  operation: [{
    name: 'operation',
    component: Components.select,
    categories: [categories.DATA],
    options: options.operation,
  }],
};
