/* eslint-disable import/no-cycle */
import React, { memo, useEffect } from 'react';

import { sendCommands } from '../../../../pages/Dashboards/Show/ProfileSendingCommands/components/utils';
import { TYPES, updateState } from '../../../../pages/Dashboards/ShowV2/reducer';

const OnOff = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetOnOff })),
);

function OnOffComponent(props) {
  const {
    hasPermissionToEdit, widget,
    previewMode, dispatchState, onChangeHistoricalConfig,
  } = props;

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.config.attributeFilter, widget.sources]);

  const updateOnOff = (widgetUpdate) => {
    const updateStateLayout = new Promise((resolve) => {
      resolve(updateState(
        TYPES.setWidgetSection,
        { active: widgetUpdate },
        dispatchState,
      ));
    });
    updateStateLayout.then(() => {
      widget.update(widgetUpdate);
    });
  };

  return (
    <OnOff
      permissionToEdit={hasPermissionToEdit}
      updateWidget={updateOnOff}
      widget={widget}
      config={widget.config}
      sendCommands={sendCommands}
      isPreview={previewMode}
    />
  );
}

export default memo(OnOffComponent);
