import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CardMenuButton from './components/CardMenuButton';
import DashboardCard from '../../../components/DashboardCard';

import './styles.scss';

class CardList extends React.Component {
  /*  handleOnProfileEvent = opened => {
     this.setState({
       profileOpened: opened
     });
   };

   handleOpenProfile = data => {
     this.setState({
       profileOpened: true,
       activeDashboard: data
     });
   }; */

  renderDashBoardCard = (dashboard, props) => (
    <DashboardCard
      key={dashboard.id}
      dashboardThumbnail={dashboard}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading
      actions={<CardMenuButton {...props} dashboard={dashboard} />}
    />
  )

  render() {
    const { dashboards } = this.props;
    return (
      <Row>
        {dashboards.map((dashboard) => this.renderDashBoardCard(dashboard, this.props))}
      </Row>
    );
  }
}

CardList.propTypes = {
  dashboards: PropTypes.arrayOf(PropTypes.any),
};

CardList.defaultProps = {
  dashboards: [],
};

const mapStateToProps = (state) => ({
  dashboards: state.get('dashboards').get('list').toJS(),
  total: state.get('dashboards').get('total'),
  fetching: state.get('dashboards').get('fetching'),
});

export default connect(
  mapStateToProps,
  {},
)(injectIntl(CardList));
