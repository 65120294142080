/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import { sortBy, cloneDeep } from 'lodash';
import {
  addDashboard, deleteDashboard, getDashboard, updateDashboard,
} from '../../services/redux/dashboards/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';
import { getWidgetModel } from '../WidgetV2/utils';
import { isShowV2 } from '../../helpers/utils';

/** Class Dashboard with Utils functions */
export default class Dashboard extends Utils {
    static entityName = 'Dashboard';

    constructor(obj = {}, options = { instanceWidgets: true }) {
      /** Send to utils class the Model and the object passed for build this class */
      const objCopy = { ...obj };
      if (isShowV2()) {
        if (objCopy.widgets && options.instanceWidgets) {
          const primitiveWidgets = [];
          let childrenWidgets = [];

          for (const widget of objCopy.widgets) {
            if (widget.container) {
              childrenWidgets.push(widget);
            } else {
              primitiveWidgets.push(widget);
            }
          }

          const primitiveWidgetsInstanced = primitiveWidgets.map((widget) => {
            const widgetInstanced = getWidgetModel(widget);

            if (widgetInstanced.isWidgetContainer) {
              widgetInstanced.dashboard = obj;
              widgetInstanced.containedWidgets = childrenWidgets
                .filter((w) => w.container === widget.id)
                .map((w) => {
                  if (widgetInstanced.hasChildrenSameSources) {
                    // eslint-disable-next-line no-param-reassign
                    w.sources = cloneDeep(widgetInstanced.sources);
                  }
                  return widgetInstanced.instanceChildren(w);
                });
              childrenWidgets = childrenWidgets
                .map((o) => widgetInstanced.containedWidgets.find((u) => u.id === o.id) ?? o);
            }
            return widgetInstanced;
          });

          objCopy.widgets = primitiveWidgetsInstanced.concat(childrenWidgets);
        }
      } else {
        // Sort firts containers
        const sortedWidgets = sortBy(objCopy.widgets, (w) => w.container);
        const addedContainedWidgets = sortedWidgets.map((widget) => {
          const widgetCopy = { ...widget };
          if (widget.container) {
            widgetCopy.typeContainer = sortedWidgets.find(
              (w) => w.id === widget.container,
            )?.widgetType;
          }

          const widgetInstanced = getWidgetModel(widgetCopy);

          if (widgetInstanced.isWidgetContainer) {
            widgetInstanced.dashboard = obj;
            widgetInstanced.containedWidgets = sortedWidgets
              .filter((w) => w.container === widget.id);
          }
          return widgetInstanced;
        });

        objCopy.widgets = addedContainedWidgets;
      }
      super(ModelExpected, objCopy);
      this.Model = Dashboard;
    }

    validate(data) {
      const filteredConstraints = {};
      data.forEach((d) => {
        filteredConstraints[d] = this.constraints[d];
      });
      const validation = validate(this.getData(), filteredConstraints);
      return validation === undefined
        ? { ...validation }
        : { error: true, ...validation };
    }

    /** Call Redux action for Save Dashboard in DB and Redux */
    save() {
      /* const filteredConstraints = clone(this.constraints);
        delete filteredConstraints.id;
        const validation = validate(this.getData(), filteredConstraints);
        return validation === undefined ? addDashboard({ ...this.getData() }) : {
             error: true, ...validation,
        } */
      addDashboard({
        ...this.getData(),
      });
    }

    /** Call Redux action for Update Dashboard in DB and Redux */
    update() {
      const validation = validate(this.getData(), this.constraints);
      return validation === undefined ? updateDashboard({ ...this.getData() }) : {
        error: true, ...validation,
      };
    }

    delete() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? deleteDashboard(this.getData()) : {
        error: true, ...validation,
      };
    }

    get() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getDashboard(this.getData()) : {
        error: true, ...validation,
      };
    }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('Dashboard', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleDashboard = new Dashboard();
export { SampleDashboard };
