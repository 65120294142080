import React from 'react';
import CardHoc from '../../../Card/'
import getBreakpoint from '../../../../helpers/getBreakpoint';

function alphabetically(a, b, mappedBy = null) {

  if (mappedBy == null) return a < b ? -1 : a > b ? 1 : 0;

  return a[mappedBy] < b[mappedBy] ? -1 : a[mappedBy] > b[mappedBy] ? 1 : 0;

}

const Card = CardHoc(({children, optionList}) => 
  <div className="dropDown">
    {children}
  <ul>{optionList}</ul>
  </div>
);

/** Main option select list */
export const OptionList = ({ data, itemClick, footer, formatOption, mappedBy = null, ...props }) => {

  var options = data;
  options.sort((a, b) => alphabetically(a, b, mappedBy));

  const parseOption = (format, option) => {
    let formattedOption= '';
    format.forEach(f => {
      formattedOption += option[f] + ' ';
    })

    return formattedOption;
  }

  let optionList = options.map((option, i) => (
    <li key={i} onClick={e => itemClick(option, i)}>
      {formatOption ? parseOption(formatOption, option) : mappedBy !== null ? option[mappedBy] : option}
    </li>
  ));
  return (
    <Card children={props.children} optionList={optionList} footer={footer} />
  );
};
