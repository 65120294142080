import React from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { clone } from 'ramda';
import { getRoles } from '../../../../services/redux/roles/actions';
import { connect } from 'react-redux';
import ProfileComponent from '../../../helpers/Profile/Profile';
import EditPermissions from '../../../helpers/Profile/EditPermissions';
import { getUsersGroup } from '../../../../services/redux/usersGroups/actions';
import EditUsersList from './EditUsersList';
import UsersGroup from '../../../../models/UsersGroup';
import {havePermissionToEdit} from "../../../../helpers/utils";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      original: this.props.data,
    };
  }

  componentDidMount() {
    getUsersGroup(this.props.data);
    havePermissionToEdit(this.props.data.permissions_policy, this.props.data.owner)
      .then(permissionToEdit => this.setState({permissionToEdit}))
  }

  componentDidUpdate(prevProps) {
    if (this.props.usersGroups !== prevProps.usersGroups)
      this.setState(
        {
          original: this.props.usersGroups.find(
            g => g.id === this.props.data.id
          ),
        }
      );
  }


  getUsersFilter = i =>
    this.props.allUsers.filter(o => {
      const filter = this.props.data.permissions_policy.users
        .map(u => u.id)
        .includes(o.id);
      return i ? !filter : filter;
    });

  updateUserToState() {
    const StoredUser = clone(
      this.props.userList.find(u => u.id === this.props.data.id)
    );
    if (StoredUser && this.state.original !== StoredUser) {
      //prevent no data
      if (StoredUser.name === '') StoredUser.name = StoredUser.id;
      this.setState({ original: StoredUser });
      return true;
    }
    return false;
  }

  handleOnClose = () => {
    this.setState({
      opened: !this.state.opened,
    });

    this.props.onChange(this.state.opened);
  };

  handleOnTabsChange = (index) => {
    index === 0 && getUsersGroup(this.props.data);
  };

  handleOnSave = (id, data) => {
    if (this.state[id] !== data) {
      const newData = { ...this.state.original, [id]: data };
      const updated = new UsersGroup(newData).update();

      if (updated.error) {
        delete updated.error;
        this.setState({
          errors: updated,
        });
      } else {
        this.setState({
          errors: undefined,
        });
      }
    }
  };

  render() {
    const data = this.state.original;

    const {permissionToEdit} = this.state;

    return (
      <ProfileComponent
        data={data}
        titles={[
          <FormattedMessage id={'users.list.title'} />,
          <FormattedMessage id={'permissions.list.title'} />,
        ]}
        save={this.handleOnSave}
        onTabsChange={this.handleOnTabsChange}
        errors={this.state.errors}
      >
        <EditUsersList data={data} permissionToEdit={permissionToEdit}/>
        <EditPermissions
          data={data}
          entity={UsersGroup}
          permissionToEdit={permissionToEdit}
        />
      </ProfileComponent>
    );
  }
}

const mapStateToProps = state => ({
  usersGroups: state
    .get('usersGroups')
    .get('list')
    .toJS(),
  users: state
    .get('users')
    .get('list')
    .toJS(),
  modifySuccess: state.get('usersGroups').get('modifySuccess'),
  errorFetching: state.get('usersGroups').get('errorFetching'),
});

Profile.defaultProps = {
  enableChangePassword: false,
};

export default connect(
  mapStateToProps,
  { getRoles }
)(injectIntl(Profile));
