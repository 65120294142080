/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import Widget from '../..';
import {
  MapConfigurationSheet, MapConfigurationSheetBasic, MapDefaultTemplateConfig, validationMap,
} from './utils/configurationSheet';
import MapDefaultConfiguration, { injectMapData } from './utils/defaultConfiguration';
import MapComponent from './component';
import formatToMapWidget from './utils/formatData';
import { getUrnId } from '../../../../helpers/utils';
import { readDevice, readPublicDevice } from '../../../../services/api/devices';
import { getAllDeviceAttributes } from '../../utils';
import { ReactComponent as MapWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-map.svg';
import { imLogged } from '../../../../helpers';

export const NAME_WIDGET = 'MAP';
export default class MapWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = MapWidgetIcon;

  skeleton = MapWidgetIcon;

  defaultConfig = ({ ...MapDefaultConfiguration });

  templateConfig = MapDefaultTemplateConfig;

  component = MapComponent;

  hasMultipleSources = true;

  constructor(obj = {}) {
    super(obj);
    if (obj.version === 'v1') {
      this.sources.forEach((source, index) => {
        const getDevice = !imLogged() ? readPublicDevice : readDevice;
        getDevice({ id: getUrnId(source.urn) })
          .then((deviceData) => {
            const allAttributes = getAllDeviceAttributes(deviceData.data.object, ['command_attributes']);
            const geoAttribute = allAttributes.find(
              (attribute) => attribute.type === 'geo:point' || attribute.type === 'geo:json',
            );
            if (geoAttribute && !source.fields.includes(geoAttribute.name)) {
              const newSources = cloneDeep(this.sources);
              newSources[index].fields.push(geoAttribute.name);
              this.sources = newSources;
            }
          });
      });
    }
  }

  injectData = (widget) => injectMapData(widget);

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => (config) => validationMap(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? MapConfigurationSheetBasic
      : MapConfigurationSheet
  )

  getWidgetChildBodyQuery = () => null;

  formatToData = (historicalValues, widgetSources) => formatToMapWidget(
    historicalValues,
    widgetSources,
    this.config,
  )

  updateHistoricalBySocket(historicals, data) {
    const updatedHistoricals = cloneDeep(historicals);
    // * Suscribed fields of the source that is being updated by the socket.
    const affectedSourceFields = this.sources.find(
      (source) => source.urn === data.urn,
    ).fields;
    // * Only the suscribed fields that are being updated by the socket.
    const updatedFields = affectedSourceFields.filter((field) => data[field]);

    updatedFields.forEach((field) => {
      historicals.forEach((h) => {
        if (h.attrName === field && h.urn === data.urn) {
          if (h.value?.value !== data[field].value) {
            updatedHistoricals.push({
              attrName: field,
              entityId: data.id,
              entityType: data.type,
              urn: data.urn,
              value: h.value,
            });
          }
        }
      });
    });
  }

  getQueryHistorical() {
    const {
      data,
    } = this.config;
    const dataForQueries = this.getEmptyHistoricalBody();
    const sources = this.getDataForQueries({ sources: this.sources });

    if (data.type === 'historical') {
      const standardBodyQuery = this.getDataForQueries({ sources: this.sources, queryType: 'standard' });

      dataForQueries[0].sources = sources;
      dataForQueries.push(...standardBodyQuery);
    } else {
      dataForQueries[0].sources = sources;
    }
    return dataForQueries;
  }

  parsedLinkedData = () => null;

  needsSocketForHistorical() {
    return true;
  };
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
