import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import {
  addMenuElementInSelectedState,
  updateMenuElementsSelectedState,
  clearMenuElementsSelectedState, 
  hiddenSubmenu,
} from '../../../services/redux/menuElements/actions';
import GroupMenuTitle from '../components/GroupMenuTitle';
import CreateWizardMenu from '../Add/AddWizard';
import parseResource from '../helpers/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import PrivateComponent from '../../PrivateComponent/privateComponent';

class SubMenuNav extends React.Component {
  constructor(props) {
    super(props);
    const { menuElementsSelected } = this.props;
    this.state = {
      menuElementsSelected,
    };

    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClickToggle = this.handleClickToggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickToggle);
  }

  componentDidUpdate(prevProps) {
    const { menuElementsSelected } = this.props;
    if (menuElementsSelected !== prevProps.menuElementsSelected) {
      this.updateState();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickToggle);
  }

  handleOpenMenu = (menuElement) => {
    addMenuElementInSelectedState(menuElement);
  };

  handleViewContent = (menuElement) => {
    if (menuElement.resource && menuElement.resource.type !== 'PARENT') {
      if (menuElement.resource.type === 'EXTERNAL') {
        navigate(
          '/app/iframe?url='.concat(parseResource(menuElement.resource)),
        );
      } else {
        navigate(menuElement.resource.link.path);
      }
      hiddenSubmenu(true);
      const { onCloseMenu } = this.props;
      if (onCloseMenu) {
        onCloseMenu();
      }
    } else {
      addMenuElementInSelectedState(menuElement);
    }
  };

  handleChangeMenu = (menuElement) => {
    const { menuElementsSelected } = this.state;
    let newMenuElementsSelected = [...menuElementsSelected];
    newMenuElementsSelected = newMenuElementsSelected.slice(
      newMenuElementsSelected.indexOf(menuElement),
      newMenuElementsSelected.length,
    );
    updateMenuElementsSelectedState(newMenuElementsSelected);
  };

  handleDeleteMenu = () => {
    clearMenuElementsSelectedState();
  };

  updateState() {
    const { menuElementsSelected } = this.props;
    this.setState({ menuElementsSelected });
  }

  setMenuRef(ref) {
    this.subMenuRef = ref;
  }

  handleClickToggle(event) {
    const eventId = event.target.id;
    if (this.subMenuRef && !this.subMenuRef.contains(event.target) && 
      (eventId === 'svg8' || eventId.includes('path826') || eventId === 'path858' )) {
      this.handleDeleteMenu();
    }
  }

  render() {
    const { menuElementsSelected } = this.state;
    const menuElementsSelectedArray = [...menuElementsSelected];
    const { children } = menuElementsSelectedArray[0];
    
    menuElementsSelectedArray.reverse();

    return (
      <section className="section-menu" ref={this.setMenuRef}>
        <i
          className="uil uil-times closeIcon"
          onClick={() => this.handleDeleteMenu()}
          role="presentation"
        />
        <h3 className="pl-4 pb-3">
          {menuElementsSelectedArray.map((menuElement, index) => (
            <>
              {menuElementsSelectedArray.length <= 1 || index > 0 ? null : (
                <i
                  className="uil uil-arrow-left text-white icon-arrow"
                  onClick={() => this.handleChangeMenu(
                    menuElementsSelectedArray[menuElementsSelectedArray.length - 2],
                  )}
                  role="presentation"
                />
              )}
              <span
                className={
                  index === menuElementsSelectedArray.length - 1 ? '' : 'opacity-06'
                }
                onClick={() => this.handleChangeMenu(menuElement)}
                role="presentation"
              >
                {index <= 0 ? null : ' / '}
                {menuElement.name}
              </span>
            </>
          ))}
        </h3>

        <GroupMenuTitle
          data={children}
          isSubMenu
          handleOpenMenu={this.handleOpenMenu}
          handleViewContent={this.handleViewContent}
        />
        {children.length === 0 && (
          <p className="pl-4 noContent">
            <FormattedMessage id="menuElement.noEntryText" />
          </p>
        )}
        <PrivateComponent checkLogin checkPermission microsService="/app/menu" permission="create-element">
          <div className="mt-4 text-right pr-4">
            <CreateWizardMenu
              parent={menuElementsSelectedArray[menuElementsSelectedArray.length - 1].id}
              noEntry={children.length === 0}
            />
          </div>
        </PrivateComponent>

      </section>
    );
  }
}

SubMenuNav.defaultProps = {
  menuElementsSelected: '',
  onCloseMenu: '',
};

const mapStateToProps = (state) => ({
  menuElementsSelected: state.get('menuElements').get('menuElementsSelected'),
});

export default connect(
  mapStateToProps,
  {},
)(SubMenuNav);
