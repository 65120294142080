import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'reportId',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  { key: 'state', defaultValue: '' },
  { key: 'urn', defaultValue: '' },
  {
    key: 'initiated',
    defaultValue: null,
  },
];

export default ModelExpected;
