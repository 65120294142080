/* eslint-disable import/no-cycle */
import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'reactstrap';
import { Checkbox } from '@material-ui/core';
import colors from '../../../../helpers/colors';
import ExpansionPanel from '../../../../components/ExpansionPanel/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import theme from '../../../../configuration/echarts/fiwoo-echart-theme';
import Switch from '../../../../elements/Switch/index';
import { getAllDeviceAttributes } from '../../../../helpers/utils';

export const HeaderList = styled.div`
  display:flex;
  justify-content: space-between;

  p {
    color: #2E384D;
    font-weight: bold;
    span {
      margin-left: 10px;
      padding: 2px 10px;
      border-radius: 30px;
      background: rgba(46,91,255,.2);
      color: #577EE8;
      font-size: 12px;
    }
  }
  button {
    background: none;
    border: none;
    outline: none;
    color: #577EE8;
  }
`;

export const Card = styled.div`
  box-shadow: 0 0 18px 0 rgba(201,205,215,1);
  border-radius: 1px;
  background: #fff;
  position: relative;
  padding: 20px;
  margin: 10px;
  &:hover {
    button{
      opacity: 1;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;

  h6 {
    color: #2E384D;
    margin: 0;
  }
  p {
    margin: 0;
    color:#6C7A88;
    font-size: 12px;
  }
`;

const SwitchBox = styled.div`
margin-right: 20px;
`;

export const AttributesWrapper = styled.section`
  background: #F7F8FA;
  padding: 0px 0px 0px 7px;
`;

const Attribute = styled.div`
  color: #2E384D;
  display: flex;
  align-items: center;
  padding: 7px;
`;

const AttributeBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TrendlineBox = styled.div`
  background:#EFF0F2;
  min-width: 100px;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #6C7A88;
  size: 12px;
`;

export const DeleteButton = styled.button`
  opacity: 0;
  border-radius: 50%;
  background: ${colors.danger.light};
  outline: none;
  border: none;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;

const ContentDevice = (data, handleChange, trendline) => (
  <AttributesWrapper>
    {handleChange && (
      <AttributeBox>
        {/* TODO SELECT ALL */}
        <div />
        {trendline
        && (
        <TrendlineBox>
          <FormattedMessage id="widgets.wizard.configure.trendline" />
        </TrendlineBox>
        )}
      </AttributeBox>
    )}
    {data.attributes.length > 0 && !data.joinedAttributes
      ? data.attributes.map((attr) => (
        <Attribute>
          {attr.name}
        </Attribute>
      ))
      : data.joinedAttributes.map((attr) => (
        <AttributeBox>
          <Attribute>
            {handleChange && (
              <SwitchBox>
                <Switch
                  title={attr.name}
                  widgetType={data.widgetType}
                  checked={attr.selected || data.joinedAttributes.length === 1}
                  onChange={(status) => handleChange(
                    status,
                    attr,
                    data,
                  )}
                />
              </SwitchBox>
            )}
            {attr.name}
          </Attribute>
          {trendline && (
            <TrendlineBox>
              <Checkbox
                checked={trendline.checked(data.name, attr.name)}
                disabled={!attr.selected}
                onChange={(e) => (
                  trendline.handler(e, data.name, attr.name)
                )}
                color="primary"
                inputProps={{ 'aria-label': 'trendline checkbox' }}
                style={{ padding: '0px' }}
              />
            </TrendlineBox>
          )}
        </AttributeBox>
      ))}
  </AttributesWrapper>
);

export const getETLAttr = (etl) => {
  const outputId = etl.process.find((p) => p.step === 'output').datasources[0];
  const datasource = etl.datasources.find((d) => d.id === outputId);
  return Object.keys(datasource.data.schema.definition);
};

const ContentETL = (data, handleChange, trendline) => {
  const arr = getETLAttr(data);
  return (
    <AttributesWrapper>
      {handleChange && (
        <AttributeBox>
          {/* TODO SELECT ALL */}
          <div />
          {trendline
          && (
          <TrendlineBox>
            <FormattedMessage id="widgets.wizard.configure.trendline" />
          </TrendlineBox>
          )}
        </AttributeBox>
      )}
      {arr.length > 0
        ? data.joinedAttributes.map((attr) => (
          <AttributeBox>
            <Attribute>
              {handleChange && (
              <SwitchBox>
                <Switch
                  title={attr.name}
                  widgetType={data.widgetType}
                  checked={attr.selected}
                  onChange={(status) => handleChange(
                    status,
                    attr,
                    data,
                  )}
                />
              </SwitchBox>
              )}
              {attr.name}
            </Attribute>
            {trendline && (
            <TrendlineBox>
              <Checkbox
                checked={trendline.checked(data.name, attr.name)}
                disabled={!attr.selected}
                onChange={(e) => (
                  trendline.handler(e, data.name, attr.name)
                )}
                color="primary"
                inputProps={{ 'aria-label': 'trendline checkbox' }}
                style={{ padding: '0px' }}
              />
            </TrendlineBox>
            )}
          </AttributeBox>
        ))
        : <FormattedMessage id="widgets.wizard.attribute.empty" />}
    </AttributesWrapper>
  );
};

const HeaderItem = (image, data, totalAttrs) => (
  <HeaderWrapper>
    <Row sm={12}>
      <Col sm={1}>
        <img alt="" src={image} />
      </Col>
      <Col sm={11}>
        <h6>{data.name}</h6>
        {totalAttrs !== null
        && (
        <p>
          {totalAttrs}
          {' '}
          <FormattedMessage id="widgets.wizard.configure.attributes" />
        </p>
        )}

      </Col>
    </Row>
  </HeaderWrapper>
);
// ToDo Create SourceItem and remove different types.
export const DeviceItem = ({
  data, opened, handleOpen, onChange, trendline, image,
}) => (
    <EntityItem
      data={data}
      opened={opened}
      handleOpen={handleOpen}
      onChange={onChange}
      trendline={trendline}
      image={image}
      totalAttrs={data.attributes.length}
      contentItem={ContentDevice}
    />
);

export const EtlItem = ({
  data, opened, handleOpen, onChange, trendline, image,
}) => (
  <EntityItem
    data={data}
    opened={opened}
    handleOpen={handleOpen}
    onChange={onChange}
    trendline={trendline}
    image={image}
    totalAttrs={data.attributes.length}
    contentItem={ContentETL}
  />
);

export const KpiItem = ({
  data, opened, handleOpen, onChange, trendline, image,
}) => (
  <EntityItem
    data={data}
    opened={opened}
    handleOpen={handleOpen}
    onChange={onChange}
    trendline={trendline}
    image={image}
    totalAttrs={data.attributes.length}
    contentItem={ContentDevice}
  />
);

export const EntityItem = ({
  data, opened, handleOpen, onChange, trendline, image, totalAttrs, contentItem,
}) => (
  <ExpansionPanel
    data={data}
    header={() => HeaderItem(image, data, totalAttrs)}
    content={(dataContent) => (contentItem ? contentItem(dataContent, onChange, trendline) : <></>)}
    opened={opened}
    onOpen={handleOpen}
    noStylish
    image={image}
  />
);

export const getRandomColor = () => theme.color[
  Math.floor(Math.random() * (theme.color.length - 1))
];

export const getColor = (index) => theme.colorPicker[index];

export const isRealTime = (type) => type === 'real-time';
export const isHistorical = (type) => type === 'historical' || type === 'historical-data';

export const showValues = (realTime, value, operation) => (
  (!realTime && value.sampling)
  || (realTime && operation === 'last-value' && value.sampling)
  || (realTime && (value === 'endDate' || value === 'startDate'))
);

export const isDate = (value) => value === 'startDate' || value === 'endDate';

export const isNumber = (value, widgetType) => widgetType === 'DIGITAL_ACCOUNTANT' || value === 'min' || value === 'max';

export const isObject = (value) => (
  value.orientation
  || value.type
  || value.dataType
  || value.attribute_orientation
  || value.display
  || value.operation
  || value.sampling
);

export const isRadioButton = (value, realTime) => (
  (value.period && realTime)
);

export const getType = (value, widgetType) => {
  if (isDate(value)) return 'date';

  return isNumber(value, widgetType) ? 'number' : 'text';
};

export const parseOptions = (option) => ({
  value: option,
  name: <FormattedMessage id={`widgets.wizard.${option}`} />,
});
