import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */

export const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'owner',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      length: {
        minimum: 3,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={
                    { minimum: '3' }
                  }
          />
        ),
      },
    },
  },
  {
    key: 'state',
    defaultValue: '',
    constraints: {
      presence: true,
    },
  },
  {
    key: 'value',
    defaultValue: '',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'unit',
    defaultValue: '',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'categories',
    defaultValue: ['OTHER'],
    constraints: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            id="Validation.presence"
          />
        ),
      },
    },
  },
  {
    key: 'organization',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 24,
        maximum: 32,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '24' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '32' }}
          />
        ),
      },
    },
  },
  {
    key: 'dataset',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 24,
        maximum: 32,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '24' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '32' }}
          />
        ),
      },
    },
  },
  {
    key: 'description',
    defaultValue: '',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
    constraints: {
      presence: true,
    },
  },
  {
    key: 'definition',
    defaultValue: {},
    constraints: {
      presence: true,
    },
  },
  {
    key: 'type',
    defaultValue: false,
    constraints: {
      presence: true,
    },

  },
  {
    key: 'fiware',
    defaultValue: {},
    constraints: {
      presence: false,
    },
  },
  {
    key: 'cron',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'createdAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'updatedAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'metadata',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
];

export default ModelExpected;
