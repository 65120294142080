import React from 'react';
import { connect } from 'react-redux';
import WithDrawer from '../../../components/DrawerContainer';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { withTheme } from '../../../Contexts/ThemeContext';
import Template, { SampleTemplate } from '../../../models/Template/index';
import {
  clearTemplates,
  getMyTemplates,
  getTemplates,
} from '../../../services/redux/templates/actions';
import RemoveHoc from '../../helpers/RemoveComponent';
import CreateTemplate from '../Add/AddWizard';
import Profile from '../Profile';
import TabComponent from './../../../components/Tab';
import OptionsForRows from './OptionsForRows';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import FiltersFinder from '../../helpers/FiltersFinder/index';
import Templates from '../../../models/Template/index';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent'


const RemoveComponent = RemoveHoc(
  ({ showModal }) => (
    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/templates'} permission={'delete'} >
      <i onClick={showModal} className="uil uil-trash-alt remove" />
    </PrivateComponent>
  ),
  { class: Template, nameData: 'templates' }
);
const ProfileComponent = WithDrawer(({ onChange, data, ...rest }) => (
  <Profile onChange={onChange} data={data} />
));
const headers = [];
const filters = [];

headers.push({ label: '', key: 'categories', type: 'device-icon' });

['name', 'description'].forEach(index => {
  headers.push({
    label: SampleTemplate.translations[index],
    key: index,
  });
  filters.push({
    name: SampleTemplate.translations[index],
    key: index,
    value: index,
  });
});

filters.push({
  name: SampleTemplate.translations['categories'],
  key: 'categories',
  value: 'categories',
});

headers.unshift({ label: '', key: 'templateAddOn', type: 'templateAddOn' });
headers.push({
  label: SampleTemplate.translations['createdAt'],
  key: 'createdAt',
  type: 'date',
});
headers.push({
  label: SampleTemplate.translations['updatedAt'],
  key: 'updatedAt',
  type: 'date',
});

const TablePaginated = Pagination(({ data, ...rest }) => (
  <Table {...rest} body={data} />
));

const FindableTabsComponent = Finder(
  props => {
    const {
      data,
      theme,
      itemsPerPage,
      page,
      optionsForRow,
      onPageChange,
      onSubmit,
      onRowClick,
      typeOfData,
      itemsShowed,
      total,
      entity,
      ...rest
    } = props;

    return (
      <TabComponent {...rest}>
        <TablePaginated
          optionsForRow={optionsForRow}
          data={data}
          header={headers}
          align={'left'}
          filters={filters}
          onSubmit={onSubmit}
          onPageChange={onPageChange}
          itemsPerPage={itemsPerPage}
          page={page}
          total={total}
          expandable={true}
          itemsShowed={itemsShowed}
          typeOfData={typeOfData}
          selectable={true}
          onRemove={RemoveComponent}
          onRowClick={onRowClick}
          entity={entity}
        />
        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/templates'} permission={'list-mine'} >
          <TablePaginated
            optionsForRow={optionsForRow}
            data={data}
            header={headers}
            align={'left'}
            filters={filters}
            onSubmit={onSubmit}
            onPageChange={onPageChange}
            itemsPerPage={itemsPerPage}
            page={page}
            total={total}
            expandable={true}
            itemsShowed={itemsShowed}
            typeOfData={'templates'}
            selectable={true}
            onRemove={RemoveComponent}
            onRowClick={onRowClick}
            entity={entity}
          />
        </PrivateComponent>
      </TabComponent>
     
    );
  },
  CreateTemplate,
  FiltersFinder
);

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      itemsPerPage: 5,
      profileOpened: false,
      selectedtab: 0,
      itemsShowed: 5,
      selectedFilter: filters[0],
      selectedValueForFilter: '',
    };
  }

  selectDataSource = (filters, page, size, init = false) => {
    switch (this.state.selectedtab) {
      case 0:
        init && clearTemplates();
        getTemplates({ filters, page, size });
        break;
      case 1:
        init && clearTemplates();
        getMyTemplates({ filters, page, size });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    const { page, itemsPerPage, selectedFilter } = this.state;
    this.selectDataSource({ [selectedFilter.key]: '' }, page, itemsPerPage);
  }

  handleOnPageChange = page => {
    const { selectedValueForFilter, selectedFilter, itemsPerPage } = this.state;

    this.selectDataSource(
      { [selectedFilter.key]: selectedValueForFilter },
      page,
      itemsPerPage
    );
    this.setState({
      page: page,
    });
  };

  handleFilteredSearch = (value, filter) => {
    const { itemsPerPage } = this.state;
    if (filter && filter.key) {
      clearTemplates();
      this.selectDataSource({ [filter.key]: value }, 1, itemsPerPage, true);
      this.setState({
        selectedValueForFilter: value,
        selectedFilter: filter,
        page: 1,
      });
    }
  };

  handleOnSubmit = filters => {
    const { page, itemsPerPage } = this.state;
    this.selectDataSource(this.state.selectedtab, {
      filters,
      page,
      size: itemsPerPage,
    });
  };

  handleOnProfileEvent = opened => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = data => {
    this.setState({
      profileOpened: true,
      activeTemplate: data,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedtab,
      selectedFilter,
      itemsPerPage,
      selectedValueForFilter,
    } = this.state;

    if (selectedtab !== prevState.selectedtab) {
      this.setState(
        {
          page: 1,
        },
        () =>
          this.selectDataSource(
            { [selectedFilter.key]: selectedValueForFilter || '' },
            1,
            itemsPerPage,
            true
          )
      );
    }
  }

  handleOnTabsChange = selectedtab => {
    if (this.state.selectedtab !== selectedtab) {
      this.setState({
        selectedtab
      });
    }
  };

  render() {
    const { path, theme, templates, fetching, total } = this.props;
    const {
      itemsPerPage,
      selectedtab,
      profileOpened,
      activeTemplate,
      page,
      itemsShowed,
    } = this.state;

    const templatesByData = templates.map(template => template.toJson());

    return (
      <>
        <FindableTabsComponent
          titles={[
            <FormattedMessage id="templates.list.templates" />,
            <PrivateComponent  checkLogin={true} checkPermission={true} microsService={'/app/templates'} permission={'list-mine'} >
              <FormattedMessage id="templates.list.mytemplates" />
            </PrivateComponent>
            
          ]}
          onChange={this.handleOnTabsChange}
          selectedtab={selectedtab}
          fetching={fetching}
          theme={theme}
          data={templatesByData}
          optionsForRow={props => (
            <OptionsForRows {...props} viewClick={this.handleOpenProfile} />
          )}
          page={page}
          total={total}
          expandable={true}
          // onSubmit={this.handleOnSubmit}
          onPageChange={this.handleOnPageChange}
          itemsShowed={itemsShowed}
          itemsPerPage={itemsPerPage}
          onRowClick={this.handleOpenProfile}
          typeOfData={'templates'}
          entity={Templates}
          filters={filters}
          externalFilter={this.handleFilteredSearch}
          selectedFilter={this.state.selectedFilter}
        />
        {profileOpened && activeTemplate && (
          <ProfileComponent
            onChange={this.handleOnProfileEvent}
            data={activeTemplate}
            path={path}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  templates: state
    .get('templates')
    .get('list')
    .toJS(),
  total: state.get('templates').get('total'),
  clearTemplates: clearTemplates,
  fetching: state.get('templates').get('fetching'),
  templateDeleted: state.get('templates').get('templateDeleted'),
  templateAdded: state.get('templates').get('templateAdded'),
});

export default connect(
  mapStateToProps,
  {}
)(withTheme(List));
