export const ADD_DASHBOARD_REQUEST = 'ADD_DASHBOARD_REQUEST';
export const ADD_DASHBOARD_SUCCESS_FLAG = 'ADD_DASHBOARD_SUCCESS_FLAG';
export const ADD_DASHBOARD_ERROR = 'ADD_DASHBOARD_ERROR';
export const ADD_DASHBOARD_SUCCESS = 'ADD_DASHBOARD_SUCCESS';

export const MODIFY_DASHBOARD_REQUEST = 'MODIFY_DASHBOARD_REQUEST';
export const MODIFY_DASHBOARD_SUCCESS_FLAG = 'MODIFY_DASHBOARD_SUCCESS_FLAG';
export const MODIFY_DASHBOARD_SUCCESS = 'MODIFY_DASHBOARD_SUCCESS';
export const MODIFY_DASHBOARD_ERROR = 'MODIFY_DASHBOARD_ERROR';

export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_ERROR = 'DELETE_DASHBOARD_ERROR';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const CLOSE_DASHBOARD_REQUEST = 'CLOSE_DASHBOARD_REQUEST';
export const GET_DASHBOARD_ROLES_REQUEST = 'GET_DASHBOARD_ROLES_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR';

export const GET_DASHBOARDS_REQUEST = 'GET_DASHBOARDS_REQUEST';
export const GET_DASHBOARDS_ERROR = 'GET_DASHBOARDS_ERROR';
export const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS';

export const GET_MINE_DASHBOARDS_REQUEST = 'GET_MINE_DASHBOARDS_REQUEST';

export const GET_MINE_DASHBOARDS_ERROR = 'GET_MINE_DASHBOARDS_ERROR';
export const GET_MINE_DASHBOARDS_SUCCESS = 'GET_MINE_DASHBOARDS_SUCCESS';

export const GET_PUBLIC_DASHBOARDS_REQUEST = 'GET_PUBLIC_DASHBOARDS_REQUEST';
export const GET_PUBLIC_DASHBOARDS_ERROR = 'GET_PUBLIC_DASHBOARDS_ERROR';
export const GET_PUBLIC_DASHBOARDS_SUCCESS = 'GET_PUBLIC_DASHBOARDS_SUCCESS';

export const CLONE_REF_DASHBOARD_REQUEST = 'CLONE_REF_DASHBOARD_REQUEST';
export const CLONE_REF_DASHBOARD_ERROR = 'CLONE_REF_DASHBOARD_ERROR';
export const CLONE_REF_DASHBOARD_SUCCESS = 'CLONE_REF_DASHBOARD_SUCCESS';

export const CLONE_NOREF_DASHBOARD_REQUEST = 'CLONE_NOREF_DASHBOARD_REQUEST';
export const CLONE_NOREF_DASHBOARD_ERROR = 'CLONE_NOREF_DASHBOARD_ERROR';
export const CLONE_NOREF_DASHBOARD_SUCCESS = 'CLONE_NOREF_DASHBOARD_SUCCESS';

export const MODIFY_DASHBOARD_LAYOUT_REQUEST = 'MODIFY_DASHBOARD_LAYOUT_REQUEST';
export const MODIFY_DASHBOARD_LAYOUT_SUCCESS_FLAG = 'MODIFY_DASHBOARD_LAYOUT_SUCCESS_FLAG';
export const MODIFY_DASHBOARD_LAYOUT_SUCCESS = 'MODIFY_DASHBOARD_LAYOUT_SUCCESS';
export const MODIFY_DASHBOARD_LAYOUT_WITHOUT_SAVE_SUCCESS = 'MODIFY_DASHBOARD_LAYOUT_WITHOUT_SAVE_SUCCESS';
export const MODIFY_DASHBOARD_LAYOUT_ERROR = 'MODIFY_DASHBOARD_LAYOUT_ERROR';

export const MODIFY_DASHBOARD_WIDGET_STATE = 'MODIFY_DASHBOARD_WIDGET_STATE';
export const DELETE_DASHBOARD_WIDGET_STATE = 'DELETE_DASHBOARD_WIDGET_STATE';

export const ERRORFETCHING_DASHBOARD_RESET = 'ERRORFETCHING_DASHBOARD_RESET';
export const CLEAR_DASHBOARDS_STATE = 'CLEAR_DASHBOARDS_STATE';
export const CLEAR_DASHBOARD_STATE = 'CLEAR_DASHBOARD_STATE';
