import React, { memo, useEffect, useMemo } from 'react';

const Percentage = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetPercentage })),
);

function PercentageComponent(props) {
  const { values, widget, onChangeHistoricalConfig } = props;

  const Skeleton = useMemo(() => widget.skeletonComp, []);

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.config?.attributeFilter, widget?.origins]);

  if (values === undefined) {
    return <Skeleton />;
  }
  return (
    <Percentage
      value={values}
      config={widget?.config}
    />
  );
}

export default memo(PercentageComponent);
