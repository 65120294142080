import React from "react";
import Field from "../../../../elements/Field";
import CommonView from "../../../CommonView";
import Role, { SampleRole } from "../../../../models/Role/index";


class Info extends CommonView {
  constructor(props) {
    super({ props: props });

    this.state = {
      errors: {},
      original: props.data,
      role: new Role({ ...props.data })
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleOnEdit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setErrors({});

    const NewRole = new Role({ ...this.state.original, ...this.state });

    if (
      JSON.stringify(new Role(this.state.original).getData()) !==
      JSON.stringify(NewRole.getData())
    ) {
      const updated = NewRole.update();
      if (updated.error) {
        delete updated.error;
        this.setErrors({ errors: updated });
      } else {
        this.props.changeMode();
        this.props.succeed(NewRole);
      }
    } else {
      this.props.changeMode();
    }
  };

  handleOnSave = ({ name, value }) => {
    const role = this.state.role;
    role[name] = value;
    this.setState({
      [name]: value
    });
  };

  handleOnMultiSelectSave = (name, value) => {
    this.setState({
      permissions: value
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== this.state.original || JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.setState({
        original: this.props.data,
        role: new Role({ ...this.props.data })
      });
    }
  }

  render() {
    const { intl, edit, permissionToAddRemoveRole } = this.props;
    const { role, errors } = this.state;
    
    return (
      <div className="tabSection">
        {edit && permissionToAddRemoveRole &&
          ["name"].map((key, i) => (
            <div className="fieldGroup" key={i}>
              <Field
                name={key}
                label={intl.formatMessage(SampleRole.plainTranslations[key])}
                placeholder={intl.formatMessage(
                  SampleRole.plainTranslations[key]
                )}
                id={key}
                value={role[key]}
                type={key === "email" ? "email" : "text"}
                onChange={this.handleInputChange}
                helperText={errors[key]}
                error={!!errors[key]}
                key={`${key}-${i}`}
                mode={"view"}
                save={this.handleOnSave}
              />
              <i className="uil uil-edit-alt"></i>
            </div>
          ))}
        {edit && permissionToAddRemoveRole &&
          ["permissions" /*'groups'*/].map((key, i) => (
            <div className="fieldGroup" key={i}>
              <Field
                name={key}
                id={key}
                label={intl.formatMessage(SampleRole.plainTranslations[key])}
                type={"multiselect"}
                value={role[key]}
                data={this.props[`allowed${key}`]}
                mappedBy={"name"}
                equalsBy={"id"}
                placeholder={intl.formatMessage({
                  id: `roles.finder.${key}.placeholder`
                })}
                selectedOptions={role[key]}
                onChange={this.handleOnMultiSelectSave}
                key={`${key}-${i}`}
                save={this.handleOnMultiSelectSave}
              />
              <i className="uil uil-edit-alt"></i>
            </div> 
          ))}
        {(!edit || !permissionToAddRemoveRole) && (
          <>
            <dt>{intl.formatMessage(SampleRole.plainTranslations.name)}</dt>
            <dd>{this.state.role.name}</dd>
            <dt>{intl.formatMessage(SampleRole.plainTranslations.permissions)}</dt>
            <dd>
              {role.permissions.map((permission, i) =>
                i !== role.permissions.length - 1
                  ? permission.name.concat(", ")
                  : permission.name
              )}{" "}
            </dd>
          </>
        )}
      </div>
    );
  }
}

export default Info;
