import React from 'react';

const defaultValue = { menuOpenState: false };

/** Context Creation. Can be in another external file */
export const Context = React.createContext(defaultValue);

/** Creating Provider */
class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.functions = {
      handleMenuState: this.handleMenuState,
    };

    this.state = {
      menuOpenState: false,
      ...this.functions,
    };
  }

    handleMenuState = (menuOpenState) => {
      const { menuOpenState: moState } = this.state;
      if (moState !== menuOpenState) this.setState({ menuOpenState: moState });
    };

    render() {
      const { children } = this.props;
      return (
        <Context.Provider value={{ ...this.state }}>
          { children }
        </Context.Provider>
      );
    }
}
export default Provider;
