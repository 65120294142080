/* eslint-disable import/no-cycle */
import React from 'react';
import styled from '@emotion/styled';
import ModalHOC from '../../../elements/Modal/index';
import { ButtonCustom } from '../../../elements/Button/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import {
  CancelMsg,
  DeleteMsg,
  parseArrayContent,
  ModalTitle,
} from '../CheckErasureAvailability';
import { cloneInstance, getUrnId } from '../../../helpers/utils';

const Message = styled.h3`
  color: #7B8494;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;
const ButtonsWrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 30px;
button {
  margin-right: 20px;
  &:last-child{
    margin: 0
  }
}
`;

const getNewDeviceList = (a1, a2) => {
  const a = [];
  const diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (const k in a) {
    diff.push(k);
  }

  return diff;
};

const deleteData = (data, callback, typeOfData, externalAction) => {
  // fix new and old delete method
  const Entity = typeOfData.class || typeOfData;
  let newData;
  if (!Array.isArray(data)) {
    newData = [data];
  } else {
    newData = data;
  }
  if (externalAction) {
    const widgetToUpdate = cloneInstance(externalAction);

    const sourcesToDelete = widgetToUpdate.sources
      .filter((o) => newData.some((s) => getUrnId(o.urn) === s.data.id))
      .map((o) => o.urn);

    widgetToUpdate.sources = widgetToUpdate.sources.filter((s) => !sourcesToDelete.includes(s.urn));
    widgetToUpdate.update(widgetToUpdate, externalAction);
  } else {
    newData.forEach((d) => {
      const aux = new Entity(d);
      aux.delete();
    });
  }

  callback(false);
};

const ModalContent = (rowContent, typeOfData) => {
  const type = Array.isArray(rowContent) && rowContent.length > 1
    ? 'plural'
    : 'singular';

  return (
    <Message>
      <FormattedMessage
        id={typeOfData.concat('.delete.confirmMessage.', type)}
      />
      {' '}
      <em>
        {!Array.isArray(rowContent)
          ? rowContent.name
          : parseArrayContent(rowContent)}
      </em>
    </Message>
  );
};

const ModalButtons = (rowContent, hideModal, typeOfData, cleanupFunction, externalAction) => (
  <ButtonsWrapper>
    <ButtonCustom label={<CancelMsg />} type="secondary" handleOnClick={hideModal} />
    <ButtonCustom
      label={<DeleteMsg />}
      id="deleteModalButton"
      type="primary"
      handleOnClick={() => {
        deleteData(rowContent, hideModal, typeOfData, externalAction);
        cleanupFunction && cleanupFunction();
      }}
    />
  </ButtonsWrapper>
);

export default (ButtonOpen, typeOfDataProp) => {
  class RemoveInner extends React.PureComponent {
    render() {
      const {
        typeOfData, rowContent, cleanupFunction, externalAction,
      } = this.props;
      let updatedTypeOfData = typeOfDataProp;
      if (!typeOfDataProp) updatedTypeOfData = typeOfData;
      const ModalDOM = ModalHOC({
        ButtonOpen,
        ModalTitle: () => <ModalTitle typeOfData={updatedTypeOfData.nameData} />,
        ModalContent: () => ModalContent(rowContent, updatedTypeOfData.nameData),
        ModalButtons: ({ hideModal }) => ModalButtons(
          rowContent,
          hideModal,
          updatedTypeOfData.class,
          cleanupFunction,
          externalAction,
        ),
      });

      return <ModalDOM />;
    }
  }

  return RemoveInner;
};
