/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import BarsWidget from '../../../bars';
import {
  BarDefaultConfigurationForLinked,
  injectLinkedBarsData,
} from './utils/defaultConfiguration';
import { calculate, mergeAggregateOrigins } from '../../../../utils';
import { calculateAggregateValues } from '../../utils';
import formatToLinkedBarWidget from './utils/formatData';
import { getUrnId } from '../../../../../../helpers/utils';
import { BarsConfigurationSheetForLinked, validationLinkedBars } from './utils/configurationSheet';

export default class LinkedBars extends BarsWidget {
    defaultConfig = ({ ...BarDefaultConfigurationForLinked });

    getQueryHistorical = () => null

    injectData = (widget) => injectLinkedBarsData(widget.config)

    getValidatorConfig = () => (config) => validationLinkedBars(config);

    getConfigurationSheet = () => BarsConfigurationSheetForLinked;

    ifUsesContainerValues = true

    formatToData = (values, sources, config, selection, intl) => {
      const widget = this;
      const { operation, type, attributeFilter } = config.data;
      if (selection.length === 1) {
        const selectedValues = values.filter((o) => getUrnId(o.urn) === selection[0]);
        return formatToLinkedBarWidget(selectedValues, config, intl);
      }
      if (selection.length > 1) {
        if (type === 'real-time') {
          const selectedDevicesValues = values.filter(
            (v) => attributeFilter.includes(v.attrName)
              && selection.find((s) => s === getUrnId(v.urn)),
          );
          const sourcesSelected = sources.filter(
            (s) => selection.some((id) => id === s.id),
          );
          const fakeSource = [cloneDeep(sourcesSelected[0])];
          const obj = [];
          attributeFilter.forEach((a) => {
            const data = selectedDevicesValues.filter((v) => v.attrName === a);
            const allAttributeValues = [];
            data.forEach((d) => {
              const { value } = d.value;
              if (value) {
                allAttributeValues.push(Number(value));
              }
            });

            obj.push({
              attrName: a,
              value: calculate(allAttributeValues, operation, 2)?.toString() ?? '0',
              entityId: 'custom',
            });
          });

          fakeSource[0].device_id = 'custom';
          return formatToLinkedBarWidget(
            obj,
            config,
            intl,
          );
        }
        const aggregateValues = calculateAggregateValues(widget, values, selection);
        if (aggregateValues) {
          const mergedOrigin = mergeAggregateOrigins(widget, aggregateValues, selection);

          return formatToLinkedBarWidget(
            mergedOrigin,
            config,
            intl,
          );
        }
      }
      return formatToLinkedBarWidget(widget.sources, config, intl);
    }
}
