import React from 'react';
import ReactDOM from 'react-dom';
import RemoveHoc from '../../pages/helpers/RemoveComponent';

import './styles.scss';

const ContextualNav = ({
  checked, onClose, optionsMenu, optionsForRow, entity, typeOfData, data,
}) => {
  const OptionsMenu = optionsMenu;

  const getCheckedItemsTotal = () => checked.filter((o) => o === true).length;

  const getCheckedItems = () => {
    const checkedData = [];
    checked.forEach((c, i) => {
      if (c === true) {
        checkedData.push(data[i]);
      }
    });
    return checkedData;
  };

  const ButtonOpenRemove = ({ showModal }) => (
    <i
      role="presentation"
      className="uil uil-trash"
      onClick={showModal}
    />
  );

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: entity, nameData: typeOfData });

  return ReactDOM.createPortal(
    <>
      {checked && getCheckedItemsTotal() && (
      <div className="contextualNav">
        <div>
          <i role="presentation" className="uil uil-arrow-left" onClick={onClose} />
          <span>{getCheckedItemsTotal()}</span>
        </div>
        <div>
          <RemoveDOM rowContent={getCheckedItems()} />
          {getCheckedItemsTotal() === 1
          && (
          <OptionsMenu
            rowContent={getCheckedItems()[0]}
            OptionsForRow={optionsForRow}
          />
          )}
        </div>

      </div>
      )}
    </>,
    document.body,
  );
};

ContextualNav.defaultProps = {
  checked: [false],
  onClose: () => {},
  optionsMenu: () => {},
  optionsForRow: () => {},
  selectedCard: undefined,
  entity: null,
  typeOfData: '',
  data: [],
};

export default ContextualNav;
