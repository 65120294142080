/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import FormattedMessage from '../../../../Contexts/LanguageContext/Components/FormattedMessage';
import Field from '../../../../elements/Field/Field';
import LinkedAttributeSelector from '../components/LinkedAttributeSelector';
import { getDefaultConfig } from '../../../../models/Widget/configurationSheets';

import './styles.scss';
import Switch from '../../../../elements/Switch';
import LinkedStepTitle from '../components/LinkedStepTitle';
import LinkedStepButtons from '../components/LinkedStepButtons';

const Step2Linked = ({
  currentStep,
  update,
  totalSteps,
  previousStep,
  nextStep,
  attributes,
  widgetType,
  intl,
  isActive,
  widgetChild,
}) => {
  const [filter, setFilter] = useState('');
  const [arrayFiltered, setArrayFiltered] = useState([]);
  const [selectAll, setSelectAll] = useState(
    attributes.every((attr) => attr.selected),
  );
  const [errors, setErrors] = useState(null);

  const handleFilterChange = (event) => {
    const { target } = event;
    const { value } = target;
    setFilter(value);
  };

  const updateConfig = (atributes) => {
    const config = getDefaultConfig((widgetType || 'TEXT_ACCOUNTANT'), true);
    if (!config.data) {
      config.data = {};
    }
    config.data.attributeFilter = atributes;
    update('config', config);
  };

  const handleSelectAll = (bool) => {
    setSelectAll(bool);
    const newConfig = arrayFiltered.length
      ? [
        ...arrayFiltered
          .map((attr) => ({ ...attr, selected: bool }))
          .filter((at) => at.selected)
          .map((a) => a.name),
      ]
      : [
        ...attributes
          .map((attr) => ({ ...attr, selected: bool }))
          .filter((att) => att.selected)
          .map((a) => a.name),
      ];
    updateConfig(newConfig);
  };

  const validate = () => {
    if (!attributes.some((attr) => attr.selected)) {
      setErrors(
        <FormattedMessage id="opendata.wizard.no.attributes.selected" />,
      );
      return;
    }
    nextStep();
  };

  useEffect(() => {
    setSelectAll(false);
  }, [filter]);

  const cleanSelectionAndStepBack = () => {
    updateConfig(attributes.map((attr) => ({ ...attr, selected: false })));
    previousStep();
  };

  const buttons = [
    {
      type: 'secondary', id: 'prevStepLinkedWidget', handleOnClick: cleanSelectionAndStepBack, label: 'linkedWidget.previousStep',
    },
    {
      type: 'primary', id: 'nextStepLinkedWidget', handleOnClick: validate, label: 'linkedWidget.nextStep',
    },
  ];

  if (isActive) {
    return (
      <Row className="linkedStepsContainer pixelHeight">
        <div>
          <LinkedStepTitle
            currentStep={currentStep}
            totalSteps={totalSteps}
            title="widgets.linked.wizard.step2.title"
            subtitle="widgets.linked.wizard.step2.subtitle"
          />
          <Col>
            <Row className="justify-content-center mb-4">
              <Col md="12" lg="3" className="d-flex align-items-center" />
              <Col md="12" lg="5">
                <Field
                  label={intl.formatMessage({
                    id: 'widgets.linked.wizard.step2.label',
                  })}
                  type="text"
                  onChange={handleFilterChange}
                  value=""
                  placeholder={intl.formatMessage({
                    id: 'widgets.linked.wizard.step2.placeholder',
                  })}
                />
              </Col>
              <Col md="12" lg="3" className="d-flex align-items-center">
                <p className="d-flex w-100 align-items-center mb-0">
                  <span
                    className={`mr-2 mb-2 ${
                      !widgetChild.hasMultipleAttributes ? 'disabled-text' : ''
                    }`}
                  >
                    <FormattedMessage id="widgets.wizard.check.all" />
                  </span>
                  {' '}
                  <Switch
                    checked={selectAll}
                    disabled={!widgetChild.hasMultipleAttributes}
                    onChange={handleSelectAll}
                  />
                </p>
              </Col>
            </Row>
          </Col>
          {!widgetChild.hasMultipleAttributes && (
          <Col>
            <Row className="justify-content-center">
              <Col className="ml-3">
                <div className="warning">
                  <i className="uil uil-info-circle mr-1" />
                  <FormattedMessage id="widgets.linked.wizard.step2.warning" />
                </div>
              </Col>
            </Row>
          </Col>
          )}
          {errors && <div className="error text-center mb-3">{errors}</div>}
          <Col>
            <LinkedAttributeSelector
              attributes={attributes}
              filter={filter}
              update={updateConfig}
              multiselection={widgetChild.hasMultipleAttributes}
            />
          </Col>
        </div>
        <LinkedStepButtons buttons={buttons} />
      </Row>
    );
  }
  return null;
};

export default injectIntl(Step2Linked);
