import React from 'react';
import {
  Row, Container, Col, ListGroupItem, ListGroup,
} from 'reactstrap';

import styled from 'styled-components';
import DeleteButton from '../../../../elements/DeleteButton/index';
import ExpansionPanel from '../../../../components/ExpansionPanel/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Switch from '../../../../elements/Switch';
import { isMobile } from '../../../../helpers/getBreakpoint';

const TitleWithStepper = styled.div`
  &:before {
    content: '';
    width: ${(p) => Math.floor((p.totalSelectedAttributes * 100) / p.totalAttributes)}%;
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 3px;
    border-radius: 1px;
    transition: 0.5s ease all;
  }
`;

const ExpansionPanelHeader = (data, opened) => {
  const totalSelectedAttributes = data.joinedAttributes
    ? data.joinedAttributes.filter((a) => a.selected).length
    : 0;
  const totalAttributes = data.joinedAttributes
    ? data.joinedAttributes.length
    : 0;
  return (
    <Container fluid className="deviceHeader">
      <Row>
        <Col sm={6} className="identificator">
          {data.device_id}
        </Col>
        <Col sm={6}>{data.name}</Col>
        {!opened && data.joinedAttributes && (
          <Col sm={3} className="selectedProperties">
            {totalSelectedAttributes}
            /
            {totalAttributes}
            {' '}
            <FormattedMessage id="widgets.wizard.properties" />
          </Col>
        )}
        {' '}
      </Row>
    </Container>
  );
};

const ExpansionPanelContent = (data, activeAttribute, checkedAll) => {
  const totalSelectedAttributes = data.joinedAttributes
    ? data.joinedAttributes.filter((a) => a.selected).length
    : 0;
  const totalAttributes = data.joinedAttributes
    ? data.joinedAttributes.length
    : 0;
  return (
    <Container fluid className="deviceBody">
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={12} lg={12}>
              <TitleWithStepper
                className="title titleWithStepper"
                totalSelectedAttributes={totalSelectedAttributes}
                totalAttributes={totalAttributes}
              >
                <FormattedMessage id="widgets.wizard.properties" />
                {' '}
                (
                {totalSelectedAttributes}
                /
                {totalAttributes}
                )
                {
                  checkedAll
                  && (
                  <p className="selectAll">
                    {!isMobile() && <FormattedMessage id="widgets.wizard.check.all" />}
                    <Switch
                      checked={totalSelectedAttributes >= totalAttributes}
                      onChange={(status) => checkedAll(status, data)}
                    />
                  </p>
                  )
                }

              </TitleWithStepper>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={12}>
              <ul className="list-unstyled">
                { data.joinedAttributes
                  && data.joinedAttributes.map((attribute, i) => (
                    <li key={i}>
                      <Switch
                        checked={attribute.selected}
                        onChange={(status) => activeAttribute(status, attribute, data)}
                      />
                      {' '}
                      <span className="switch-name-attribute">
                        {attribute.name}
                      </span>

                    </li>
                  ))}
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const ListComponent = (
  {
    data, onDelete, openedPanels, uniqueValue,
  },
  onAttributeCheck,
  onCheckAll,
  onPanelOpen,
) => (
  <ListGroup>
    {data.map((s, i) => (
      <ListGroupItem
        key={`list-item-${i}`}
        className="d-flex justify-content-start"
      >
        <ExpansionPanel
          data={s}
          header={ExpansionPanelHeader}
          content={(data) => ExpansionPanelContent(data, onAttributeCheck, onCheckAll, uniqueValue)}
          opened={openedPanels[i]}
          onOpen={(status) => onPanelOpen(i, status)}
        />
        <DeleteButton onClick={() => onDelete(s.id)} />
      </ListGroupItem>
    ))}
  </ListGroup>
);

export default ListComponent;
