import React from "react";
import CommonView from "../../../CommonView";
import {connect} from "react-redux";
import {clearDevicesState, getDevices} from "../../../../services/redux/devices/actions";
import Field from '../../../../elements/Field/index';



const FieldComponent = ({ name, intl, onChange, ...rest }) => {
  return (
    <>
      {[name].map((key, i) => (
        <Field
          {...rest}
          name={key}
          placeholder={intl.formatMessage({
            id: `rules.wizard.${key}.placeholder`
          })}
          label={intl.formatMessage({
            id: `rules.wizard.${key}.label`
          })}
          id={key}
          key={`${key}-${i}`}
          onChange={onChange}
        />
      ))}
    </>
  );
};

export class CommandComponent extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      device: this.props.command.device,
      attribute: this.props.command.attribute,
      value: this.props.command.value,
      //allDevices: [...this.props.allDevices.filter(o => o.fiware.service === this.props.devices[0].fiware.service)],
      allDevices: this.props.allDevices,
      page: 1,
      size: 5
    };
  }
  static propTypes = {};

  componentDidMount() {
    //TO-DO: create async select
    // getDevices(
    //   { fiwareService: this.props.devices[0].fiware.service },
    //   1,
    //   5
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size } = this.state;

    if (JSON.stringify(prevProps.allDevices) !== JSON.stringify(this.props.allDevices)) {
      const device = this.state.device
        ? this.props.allDevices.find(d => d.id === this.state.device.id)
        : {};
      const command = this.state.attribute
        ? this.joinedAttributes(device).find(
          c => c.name === this.state.attribute
        )
        : {};

      this.setState({
        allDevices: [...this.props.allDevices],
        device: device,
        command: command ? command.name : ''
      });
    }

    if (!this.props.fetching && prevState.page < page) {
      getDevices({
        filters: {
       //   fiwareService: this.props.devices[0].fiware.service
        },
        page,
        size
      })
    }

    if (JSON.stringify(prevProps.command) !== JSON.stringify(this.props.command)) {
      this.setState({
        device: this.props.command.device,
        attribute: this.props.command.attribute,
        value: this.props.command.value,
        
      });
    }
  }


  getOptions = entities =>
    entities && Array.isArray(entities) && entities.length > 0
      ? entities.map(o => ({ name: o.name, id: o.id, value: o }))
      : [];

  getOption = (entities, entity) => {
    return entities && entity && Array.isArray(entities) && entities.length > 0 ?
      entities.find(o => { return o.id === entity.id}) : {};}

  isComplete = () =>
    Object.entries(this.state.device).length > 0 &&
    Object.entries(this.state.command).length > 0 &&
    this.state.value !== "";

  getCommands = device =>
    device && device.command_attributes
      ? this.getOptions(device.command_attributes)
      : [];

  handleInnerSelectChange = (name, value) => {
    const { index } = this.props;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.onChange({
          device: this.state.device ? this.state.device : '',
          id:this.state.device ? this.state.device.id : '',
          device_id:this.state.device ? this.state.device.id : '',
          command:(this.state.device && this.state.attribute) ? this.state.attribute.name : '',
          attribute:(this.state.device && this.state.attribute) ? this.state.attribute : '',
          index
        });
      }
    );
  };

  handleInnerInputChange = e => {
    const { name, value } = e.target;
    const { index } = this.props;

    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.onChange({
          value:value,
          index
        });
      }
    );
  };

  joinedAttributes = device => {
    return device && Object.entries(device).length > 0
      ? [
          ...device.command_attributes,
          //...device.lazy_attributes,
          //...device.static_attributes,
          //...device.attributes
        ]
      : [];
  };

  handleFetchInfo = () => {
    const { total, allDevices } = this.props;
    if (total > allDevices.length)
      this.setState({page: this.state.page + 1});
  };

  // handleOnSearch = (value, init = false) => {
  //   const { size } = this.state;
  //   init && clearDevicesState();
  //   getDevices({
  //     filters: {
  //       name: value,
  //       fiwareService: this.props.devices[0].fiware.service
  //     },
  //     page:1,
  //     size
  //   })
  // };

  render() {
    const { intl, errors, fetching } = this.props;
    const { device, value, attribute, allDevices } = this.state;

    return (
      <div className="d-flex justify-content-start align-items-start action-component">
        <FieldComponent
          name={"device"}
          type={"select"}
          options={this.getOptions(allDevices)}
          intl={intl}
          value={this.getOption(allDevices, device)}
          selectedOption={device}
          onChange={this.handleInnerSelectChange}
          //onSearch={this.handleOnSearch}
          fetchInfo={this.handleFetchInfo}
          loading={fetching}
          filterBy={'name'}
          async={true}
          helperText={errors.device_id} 
          error={!!errors.device_id}
        />
        <FieldComponent
          name={"attribute"}
          type={"select"}
          options={device ? this.getOptions(this.joinedAttributes(device)) : []}
          intl={intl}
          value={attribute}
          onChange={this.handleInnerSelectChange}
          helperText={errors.command} 
          error={!!errors.command}
        />
        <FieldComponent
          name={"value"}
          type={"text"}
          intl={intl}
          value={value}
          onChange={this.handleInnerInputChange}
          helperText={errors.value}
          error={!!errors.value}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allDevices: state
    .get("devices")
    .get("list")
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total')
});
export default connect(
  mapStateToProps,
  {}
)(CommandComponent);
