export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';

export const GET_MY_TEMPLATES_REQUEST = 'GET_MY_TEMPLATES_REQUEST';
export const GET_MY_TEMPLATES_SUCCESS = 'GET_MY_TEMPLATES_SUCCESS';
export const GET_MY_TEMPLATES_ERROR = 'GET_MY_TEMPLATES_ERROR';

export const GET_PUBLIC_TEMPLATES_REQUEST = 'GET_PUBLIC_TEMPLATES_REQUEST';
export const GET_PUBLIC_TEMPLATES_SUCCESS = 'GET_PUBLIC_TEMPLATES_SUCCESS';
export const GET_PUBLIC_TEMPLATES_ERROR = 'GET_PUBLIC_TEMPLATES_ERROR';

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR';

export const GET_TEMPLATE_DEVICES_COUNT_REQUEST = 'GET_TEMPLATE_DEVICES_COUNT_REQUEST';
export const GET_TEMPLATE_DEVICES_COUNT_SUCCESS = 'GET_TEMPLATE_DEVICES_COUNT_SUCCESS';
export const GET_TEMPLATE_DEVICES_COUNT_ERROR = 'GET_TEMPLATE_DEVICES_COUNT_ERROR';

export const ADD_TEMPLATE_REQUEST = 'ADD_TEMPLATE_REQUEST';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_ERROR = 'ADD_TEMPLATE_ERROR';

export const MODIFY_TEMPLATE_REQUEST = 'MODIFY_TEMPLATE_REQUEST';
export const MODIFY_TEMPLATE_SUCCESS = 'MODIFY_TEMPLATE_SUCCESS';
export const MODIFY_TEMPLATE_ERROR = 'MODIFY_TEMPLATE_ERROR';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';

export const ADD_TEMPLATE_SUCCESS_FLAG = 'ADD_TEMPLATE_SUCCESS_FLAG';
export const ERRORFETCHING_TEMPLATE_RESET = 'ERRORFETCHING_TEMPLATE_RESET';

export const GET_TEMPLATE_ROLES_ERROR = 'GET_TEMPLATE_ROLES_ERROR';
export const GET_TEMPLATE_ROLES_REQUEST = 'GET_TEMPLATE_ROLES_REQUEST';
export const GET_TEMPLATE_ROLES_SUCCESS = 'GET_TEMPLATE_ROLES_SUCCESS';
export const MODIFY_TEMPLATE_SUCCESS_FLAG = 'MODIFY_TEMPLATE_SUCCESS_FLAG';

export const CLEAR_TEMPLATES_STATE = 'CLEAR_TEMPLATES_STATE';
export const UPDATE_TEMPLATES_STATE = 'UPDATE_TEMPLATES_STATE';
