import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../components/Menu';
import OptionsForWidget from './OptionsForWidget';
import './styles.scss';

const WidgetOptionsMenu = () => {
  const openButton = (props) => (
    <div
      onClick={props.onClick}
      role="presentation"
    >
      <span className="titleText">
        <i className="uil uil-ellipsis-v" />
        {' '}
      </span>
    </div>
  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="top left"
      className="more"
    >
      <OptionsForWidget />
    </Menu>
  );
};

WidgetOptionsMenu.propTypes = {
  onClick: PropTypes.func,
};

WidgetOptionsMenu.defaultProps = {
  onClick: () => {},
};

const WidgetMenuButton = (props) => {
  const { title, date } = props;

  return (
    <WidgetOptionsMenu title={title} date={date} />

  );
};

WidgetMenuButton.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
};

WidgetMenuButton.defaultProps = {
  title: '',
  date: '',
};

export default WidgetMenuButton;
