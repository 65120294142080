import Styled from 'styled-components';
import ReactDOM from 'react-dom';
import React from 'react';
const Loading = Styled('div')`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(33, 62, 127, 0.6);
    z-index:99999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    
    & .loading-inner{
        display: inline-block;
        position: relative;
        width: 128px;
        height: 128px;
        
        & div {
          position: absolute;
          border: 4px solid #fff;
          opacity: 1;
          border-radius: 50%;
          animation: loading-spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        
        & div:nth-child(2) {
          animation-delay: -0.5s;
        }
    }
    
    @keyframes loading-spinner {
      0% {
        top: 56px;
        left: 56px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: -1px;
        left: -1px;
        width: 116px;
        height: 116px;
        opacity: 0;
      }
    }
`;

const appRoot = document.getElementsByTagName('body')[0];

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.id = '';
  }

  componentDidMount() {
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    appRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(<LoadingComponent />, this.el);
  }
}

const LoadingComponent = () => (
  <Loading>
    <div className="loading-inner">
      <div />
      <div />
    </div>
  </Loading>
);
