import React from 'react';
import Wizard from '../../Wizard';
import WizardStep from '../../Wizard/components/WizardStep';
// Steps
// If we put Add between curly brackets, the site crashes
// eslint-disable-next-line import/no-named-as-default
import Add from './Add';

import { FormattedMessage } from '../../../Contexts/LanguageContext';
import Button from '../../../elements/Button';
// If we put SelectAccess between curly brackets, the site crashes
// eslint-disable-next-line import/no-named-as-default
import SelectAccess from './SelectAccess';
// If we put ConfigureAccess between curly brackets, the site crashes
// eslint-disable-next-line import/no-named-as-default
import ConfigureAccess from './ConfigureAccess';
import Success from './Success';
import MenuElement from '../../../models/MenuElement';
import ConfigurePermissions from './ConfigurePermissions';
import parseResource from '../helpers/index';
import PrivateComponent from '../../PrivateComponent/privateComponent';

const ButtonOpen = ({ showModal, noEntry, isSubMenu }) => (
  <PrivateComponent checkLogin checkPermission microsService="/app/menu" permission="create-element">
    <Button
      className={`btn btn-outline-primary d-block mr-4 ${
        noEntry ? 'createFirst' : 'createNew'
      } ${isSubMenu ? 'white' : ''}`}
      onClick={showModal}
    >
      {!noEntry ? (
        <>
          <i className="uil uil-plus" />

          <FormattedMessage
            id="menu.button.add"
            defaultMessage="new"
            description="Add new menu"
            component="span"
          />
        </>
      ) : (
        <FormattedMessage
          id="menu.button.add.first"
          defaultMessage="new"
          description="Add first menu"
          component="span"
        />
      )}
    </Button>
  </PrivateComponent>
);

ButtonOpen.defaultProps = {
  showModal: () => {},
  noEntry: '',
  isSubMenu: '',
};

const EditButton = ({ showModal, isSubMenu }) => (
  <i
    className={'uil uil-edit-alt'.concat(isSubMenu ? ' i-submenu ' : '')}
    onClick={showModal}
    role="presentation"
  />
);

EditButton.defaultProps = {
  showModal: () => {},
  isSubMenu: '',
};

const EditButtonMobile = ({ showModal, isSubMenu }) => (
  <div
    onClick={showModal}
    role="presentation"
  >
    <i className={'uil uil-edit-alt'.concat(isSubMenu ? ' i-submenu ' : '')} />
    <FormattedMessage id="options.edit" />
  </div>
);

EditButtonMobile.defaultProps = {
  showModal: () => {},
  isSubMenu: undefined,
};

export class WizardComponent extends React.Component {
  constructor(props) {
    super(props);
    const { menuToEdit, parent } = this.props;
    this.state = {
      newMenu: menuToEdit
        ? {
          ...menuToEdit,
          type: this.findType(menuToEdit.resource),
        }
        : {
          name: '',
          parent,
          type: '',
        },
    };
  }

  componentDidUpdate(prevProps) {
    const { parent, menuToEdit } = this.props;
    if (prevProps.parent !== parent) {
      this.updateParent();
    }

    if (prevProps.menuToEdit !== menuToEdit) {
      this.updateMenu();
    }
  }

findType = (resource) => {
  if (resource && resource.type) return resource.type;
  if (resource && resource.link) {
    if (
      !resource.link.host
&& resource.link.path.includes('/app/dashboard/')
    ) {
      return 'DASHBOARD';
    } if (parseResource(resource).includes('knowage')) {
      return 'KNOWAGE';
    }
    return 'EXTERNAL';
  }
  return 'PARENT';
};

saveEntity = (state, entity, onContinue) => {
  if (state !== null) {
    const newMenu = { ...state.newEntity };
    if (!newMenu.resource) newMenu.resource = {};
    newMenu.resource.type = state.newEntity.type;

    this.setState(
      {
        newMenu,
      },
      () => {
        const newMenuElement = new MenuElement(newMenu);
        const { menuToEdit, parent } = this.props;
        if (menuToEdit) {
          newMenuElement.update();
        } else {
          newMenuElement.save();
        }

        if (!menuToEdit) {
          this.setState({
            newMenu: {
              name: '',
              parent,
              type: '',
            },
          });
        }
        onContinue();
      },
    );
  }
};

handlePrevStep = () => {
  const { newMenu } = this.state;
  return (newMenu.type === 'PARENT' ? 1 : undefined);
};

updateParent() {
  const { parent } = this.props;
  this.setState({
    newMenu: {
      name: '',
      parent,
      type: '',
    },
  });
}

updateMenu() {
  const { menuToEdit } = this.props;
  this.setState({
    newMenu: {
      ...menuToEdit,
      type: this.findType(menuToEdit.resource),
    },
  });
}

render() {
  const { buttonOpen } = this.props;
  const { newMenu } = this.state;
  return (
    <Wizard
      buttonOpen={buttonOpen}
      entity={MenuElement}
      newEntity={newMenu}
      metadata={{
        wizardType: 'menus',
      }}
    >

      <WizardStep
        component={Add}
        title={<FormattedMessage id="menuElement.add.title" />}
        lead={<FormattedMessage id="menuElement.add.lead" />}
        entity={MenuElement}
      />

      <WizardStep
        component={SelectAccess}
        title={<FormattedMessage id="menuElement.access.title" />}
        lead={<FormattedMessage id="menuElement.access.lead" />}
        entity={MenuElement}
      />
      <WizardStep
        component={ConfigureAccess}
        title={<FormattedMessage id="menuElement.configure.title" />}
        lead={<FormattedMessage id="menuElement.configure.lead" />}
        entity={MenuElement}
      />
      <WizardStep
        component={ConfigurePermissions}
        title={<FormattedMessage id="menuElement.permissions.title" />}
        lead={<FormattedMessage id="menuElement.permissions.lead" />}
        entity={MenuElement}
        onSave={this.saveEntity}
      />
      <Success />
    </Wizard>
  );
}
}

WizardComponent.defaultProps = {
  menuToEdit: '',
  parent: '',
  buttonOpen: () => {},
};

const CreateMenu = ({ noEntry, parent }) => (
  <WizardComponent
    noEntry={noEntry}
    buttonOpen={ButtonOpen}
    parent={parent}
  />
);

CreateMenu.defaultProps = {
  noEntry: undefined,
  parent: '',
};

const EditMenuWizard = ({ menuToEdit, isSubmenu }) => (
  <WizardComponent
    buttonOpen={EditButton}
    menuToEdit={menuToEdit}
    isSubmenu={isSubmenu}
  />
);

EditMenuWizard.defaultProps = {
  menuToEdit: {},
  isSubmenu: '',
};

const EditMenuWizardMobile = ({ menuToEdit, isSubmenu }) => (
  <WizardComponent
    buttonOpen={EditButtonMobile}
    menuToEdit={menuToEdit}
    isSubmenu={isSubmenu}
  />
);

EditMenuWizardMobile.defaultProps = {
  menuToEdit: {},
  isSubmenu: '',
};

export { EditMenuWizard, EditMenuWizardMobile };
export default CreateMenu;
