import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import iconAutoZoom from '../../../configuration/icons/svg/widgets/widgetMap/ico-device-centered.svg';

// const ButtonAutoZoom = styled.div`
//   right: 1em;
//   bottom: 5.2em;
//   z-index: 1;
//   position: absolute;
//   height: 30px;
//   width: 30px;
//   border-radius: 3px;
//   background-color: #FFFFFF;
//   box-shadow: 0 3px 6px 0 rgba(0,0,0,0.08);
//   cursor: pointer;
// }
// `;




const AutoZoom = (props) => {
  const conlog = () => {
    props.autoZoom();
  };
  return (
    <div  className='mapZoomButton' onClick={conlog}>
      <img
        alt="AutoZoom"
        className="widgetmap-autozoom-icon"
        src={iconAutoZoom}
      />
    </div>
  );
};

AutoZoom.propTypes = {
  autoZoom: PropTypes.func.isRequired,
};

export default AutoZoom;
