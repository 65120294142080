import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonWrapper } from '../Icons';

const Wrapper = styled.span`

    padding: 6px 9px;
    display: inline-flex;
    margin: 1px 0.5em 1px 0;
    white-space: nowrap;
    align-items: center;
    justify-content: space-around;

    border-radius: 4px;
    background-color: rgba(46,91,255,0.08);

    span{
        color: #577EE8;
        font-size: 0.8em;
        font-weight: 600;
        line-height: 1.2em;
        text-align: center;
        text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
    }
`;
const CloseIcon = styled.span`
    margin-left: 9px;
    padding: 0;
        & > i{
            color: #577ee8;
        }
`;


const Chip = ({
  text, innerClick, dismissible, uppercase,
}) => (
  <Wrapper uppercase={uppercase}>
    <span>{text}</span>
    {dismissible && (
    <CloseIcon as={ButtonWrapper} onClick={innerClick}>
      <i className="uil uil-times" />
    </CloseIcon>
    )}
  </Wrapper>
);

Chip.defaultProps = {
  text: 'text',
  innerClick: () => { },
  dismissible: true,
  uppercase: true,
};

Chip.propTypes = {
  /** The text of the chip */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /** Inner function that triggers when the close button is clicked  */
  innerClick: PropTypes.func,

  /** If chip have close button or not */
  dismissible: PropTypes.bool,

  /** If chip have uppercased text or not */
  uppercase: PropTypes.bool,
};

export default Chip;
