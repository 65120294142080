import { categories, Components } from '../utils';

export const ImageConfigurationSheetBasic = {

  image: [
    {
      name: 'imageSelected',
      component: Components.selectImage,
      categories: [categories.APPEARANCE],
    },
  ],

  color: [
    {
      name: 'colorSelected',
      component: Components.selectColor,
      categories: [categories.COLORS],
    },
  ],

};

export const ImageConfigurationSheet = {
  image: [
    {
      name: 'imageSelected',
      component: Components.selectImage,
      categories: [categories.APPEARANCE],
    },
  ],

  color: [
    {
      name: 'colorSelected',
      component: Components.selectColor,
      categories: [categories.COLORS],
    },
  ],

};
