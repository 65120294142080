import {cleanLastSlash} from './index'

export default (path, numToDelete = 1) => {

    if(path){
        let previusPath = cleanLastSlash(path).split('/');
        if(previusPath.length > 2){
            return previusPath.slice(0, -numToDelete).join('/');
        }
    }

    return path;

}