import React from 'react';

/** Render of SelectLanguage by Default */
const SelectByDefault = ({
  value, onChange, options, ...rest
}) => (
  <select
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    onChange={onChange}
    value={value}
  >
    {
      options.map((lang) => (
        <option
          value={lang}
          label={lang}
        />
      ))
            }
  </select>
);

export default SelectByDefault;
