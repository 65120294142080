const parseFilters = (filters, mode='~') => {
  if (Array.isArray(filters)) return '';
  let params = '&';
  const paramsLength = Object.keys(filters).length;
  Object.keys(filters).forEach((filter, i) => {
    if (!Array.isArray(filters[filter])) {
      params += `${filter}=${mode}${filters[filter]}`;
    } else {
      filters[filter].forEach(f => {
        params += `${filter}=${mode}${f}`;
        if (i < filters[filter].length - 1) params += '&';
      });
    }
    if (i < paramsLength - 1) params += '&';
  });
  return params;
};

export const getQueryData = (page, size, sort, filters) => {
  const queryPage = `${queryPageParam}=${page || 1}`;
  const querySize = `${querySizeParam}=${size || 5}`;
  const querySort = sort ? `${querySortParam}-${sort.param}=${sort.order || 'asc'}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  return { queryPage, querySize, querySort, queryFilters };
};

export const queryPageParam = "x-query-page";
export const querySizeParam = "x-query-size";
export const querySortParam = "x-query-sort";

export default parseFilters;
