import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

// TODO
// These expressions are suposed to work better than used ones,
// because of generalization, but somethin isn't working propperly.
// Cant start wit space or dot, after first letter, allows letter, spaces and dots
// Have to solve how to avoid @ # and other characters identified as letters.
// ^(?!(\.|\s).*$)(\p{L}|\p{Z}|\p{P})+
// ^(?!\\p{Z}|\\p{P})\\p{L}*[\\p{L}\\p{Z}\\p{P}]*

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [{
  key: 'id',
  defaultValue: null,
  constraints: {
    presence: {
      message: "can't be empty",
    },
  },
},
{
  key: 'name',
  defaultValue: '',
  constraints: {
    length: {
      minimum: 1,
      message:
  <FormattedMessage
    id="Validation.minimum"
    values={
                        { minimum: '1' }
                    }
  />,
    },
  },
},
{
  key: 'description',
  defaultValue: '',
  constraints: {
    presence: true,
  },
},
{
  key: 'owner',
  defaultValue: null,
  constraints: {
    presence: true,
  },
},
{
  key: 'permissions_policy',
  defaultValue: {
    users: [],
    groups: [],
    public: false,
    worldwide: {
      active: false,
    }
  },
  constraints: {
    presence: true,
  },
},
{
  key: 'layoutProperties',
  defaultValue: {
    lg: [],
    md: [],
    sm: [],
  },
  constraints: {
    presence: true,
  },
},
{
  key: 'widgets',
  defaultValue: [],
  constraints: {
    presence: true,
  },
},
{
  key: 'lastUserUpdated',
  defaultValue: null,
  constraints: {
    presence: false,
  },
},
{
  key: 'createdAt',
  defaultValue: null,
  constraints: {
    presence: false,
  },
},
{
  key: 'updatedAt',
  defaultValue: null,
  constraints: {
    presence: false,
  },
},
];

export default ModelExpected;
