import React from 'react';
import { InputSelect } from './styles';
export const InputCombo = props => {
  return (
    <form
      onSubmit={e => {
        props.onKeyPress(e);
      }}
    >
      <i className="uil uil-search"></i>
      <InputSelect
        type="text"
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </form>
  );
};
