/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import Styled from 'styled-components';
import { Container, Col } from 'reactstrap';
import { uniqBy } from 'lodash';
import { withBlanketCtxt } from '../../Contexts/BlanketContext/index';
import './styles.scss';

const sm = `
  width: 60vw !important;
  height: inherit  !important;`;

const xl = `
  width: 100vw !important;
  height: 100vw  !important;`;

const Main = Styled(Container)`
${(props) => props.size === 'sm' && sm}
${(props) => props.size === 'xl' && xl}
`;
const Title = Styled.div``;
const Body = Styled.div`
  justify-content: ${(props) => props.verticalAlign || 'center'}!important;
`;
const Footer = Styled.div``;

const ModalHOC = (WrappedElements, options, externalContext) => {
  const {
    ButtonOpen, ModalTitle, ModalContent, ModalButtons, ModalFooter, verticalAlign, customButton,
  } = WrappedElements;

  const Modal = (props) => {
    const context = useContext(externalContext);
    const clickOutRef = useRef(null);
    const [isAvailable, setIsAvailable] = useState(false);

    useEffect(() => {
      const { rowContent } = props;
      if (Array.isArray(rowContent)) {
        const types = uniqBy(rowContent, 'source.type');
        if (types.length > 1) setIsAvailable(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (JSON.stringify(context.value) !== JSON.stringify(context.prevValue?.value)) {
        props.handleRefreshBlanketVisibility(() => renderedContent(props));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context]);

    const renderedContent = (newProps) => {
      const { className, size } = newProps;
      return (
        <div id="modal" className={className}>
          <Main fluid size={size} ref={clickOutRef}>
            {(ModalTitle !== undefined) && (
              <Title className="titulo">
                <Col><ModalTitle {...newProps} /></Col>
              </Title>
            )}
            {(ModalContent !== undefined) && (
              <Body className="body" verticalAlign={verticalAlign}>
                <div className="d-flex justify-content-center align-items-center">
                  <ModalContent {...newProps} hideModal={hideModal} />
                </div>
              </Body>
            )}
            {(ModalButtons !== undefined) && (
              <ModalButtons
                {...newProps}
                showModal={showModal}
                hideModal={hideModal}
              />
            )}
            {(ModalFooter !== undefined) && (
              <Footer className="footer">
                <Col><ModalFooter {...newProps} /></Col>
              </Footer>
            )}

          </Main>
        </div>
      );
    };
    const hideModal = () => {
      const { handleBlanketVisibility } = props;
      handleBlanketVisibility(false);
    };

    const showModal = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { handleBlanketVisibility } = props;
      handleBlanketVisibility(true, () => renderedContent(props));
    };

    const handleLister = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());

      if (!path.includes(clickOutRef.current)) {
        window.removeEventListener('click', handleLister, true);
      }
    };

    return (
      <ButtonOpen
        isAvailable={isAvailable}
        showModal={showModal}
        customButton={customButton}
      />
    );
  };

  return withBlanketCtxt(Modal);
};

export default ModalHOC;
