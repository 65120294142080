import React from 'react';
import { PropTypes } from 'prop-types';
import {
  briefAction, icon, mainAction, actionButtons, title, content, mainInfo, subtitle,
} from './briefAction.module.scss';
import { getGeneralDataForAction } from '../../../../../../data/ruleActions';


const BriefAction = ({ action, onDelete }) => {
  const generalDataForAction = getGeneralDataForAction(action.type, action.parentType);

  return (
    <div className={briefAction}>
      <div className={icon}>
        <img src={generalDataForAction.icon} alt={generalDataForAction.type} />
      </div>
      <div className={mainAction}>
        <p className={title}>
          {generalDataForAction.titles.title}
          {action.parentType
            && (
            <span className={subtitle}>
              <i className="uil uil-angle-right" />
              {generalDataForAction.titles.subtypeTitle}
            </span>
            )}
        </p>
        <div className={content}>
          <div className={mainInfo}>
            {generalDataForAction.briefComponent(action)}
          </div>
          <div className={actionButtons}>
            <i
              className="uil uil-trash-alt"
              onClick={onDelete}
              onKeyUp={onDelete}
              tabIndex={-1}
              role="link"
              label="delete action"
            />
            {/* <i className="uil uil-edit-alt" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

BriefAction.propTypes = {
  action: PropTypes.shape({ type: PropTypes.string, parentType: PropTypes.string }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BriefAction;
