import React from 'react';
import { withTheme } from 'styled-components';
import Icon, { ButtonWrapper } from '../../elements/Icons';

class DefaultOpenMenuButton extends React.PureComponent {
  render = () => {
    const { onClick, ...rest } = this.props;
    return (
      <ButtonWrapper onClick={onClick}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Icon {...rest} svg="Menu" />
      </ButtonWrapper>
    );
  }
}

export default withTheme(DefaultOpenMenuButton);
