export const defaultWidgetMeasures = {

  BARS: {
    lg: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    md: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    xxs: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    xs: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    sm: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
  },
  LINES: {
    lg: {
      w: 12, h: 10, minH: 6, minW: 4,
    },
    md: {
      w: 12, h: 10, minH: 6, minW: 4,
    },
    xxs: {
      w: 12, h: 10, minH: 6, minW: 4,
    },
    xs: {
      w: 12, h: 10, minH: 6, minW: 4,
    },
    sm: {
      w: 12, h: 10, minH: 6, minW: 4,
    },
  },
  NEEDLE: {
    lg: {
      w: 3, h: 5, minH: 5, minW: 3,
    },
    md: {
      w: 3, h: 5, minH: 5, minW: 3,
    },
    xxs: {
      w: 12, h: 5, minH: 5, minW: 3,
    },
    xs: {
      w: 12, h: 5, minH: 5, minW: 3,
    },
    sm: {
      w: 12, h: 5, minH: 5, minW: 3,
    },
  },
  PERCENTAGE: {
    lg: {
      w: 3, h: 5, minH: 5, minW: 2,
    },
    md: {
      w: 3, h: 5, minH: 3, minW: 2,
    },
    xxs: {
      w: 6, h: 5, minH: 2, minW: 3,
    },
    xs: {
      w: 3, h: 5, minH: 3, minW: 3,
    },
    sm: {
      w: 3, h: 5, minH: 3, minW: 3,
    },
  },
  TEXT: {
    lg: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    md: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    xxs: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    xs: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    sm: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
  },
  TEXT_ACCOUNTANT: {
    lg: {
      w: 4, h: 3, minH: 3, minW: 1,
    },
    md: {
      w: 5, h: 3, minH: 3, minW: 1,
    },
    xxs: {
      w: 12, h: 3, minH: 3, minW: 2,
    },
    xs: {
      w: 12, h: 3, minH: 3, minW: 2,
    },
    sm: {
      w: 6, h: 3, minH: 3, minW: 2,
    },
  },
  MAP: {
    lg: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    md: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xxs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    sm: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
  },
  LINKED: {
    lg: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    md: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xxs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    sm: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
  },
  HEATMAP: {
    lg: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    md: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xxs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    sm: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
  },
  TABLE: {
    lg: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    md: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xxs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    xs: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
    sm: {
      w: 12, h: 8, minH: 4, minW: 4,
    },
  },
  HTML: {
    lg: {
      w: 12, h: 8, minH: 4, minW: 3,
    },
    md: {
      w: 12, h: 8, minH: 4, minW: 3,
    },
    xxs: {
      w: 12, h: 8, minH: 4, minW: 3,
    },
    xs: {
      w: 12, h: 8, minH: 4, minW: 3,
    },
    sm: {
      w: 12, h: 8, minH: 4, minW: 3,
    },
  },
  PARAMETRIZED_TEXT: {
    lg: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    md: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    xxs: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    xs: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
    sm: {
      w: 12, h: 2, minH: 2, minW: 3,
    },
  },
  SEND_COMMAND_SINGLE: {
    lg: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    md: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    xxs: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    xs: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    sm: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
  },
  SEND_COMMAND_DUAL: {
    lg: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    md: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    xxs: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    xs: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
    sm: {
      w: 6, h: 4, minH: 4, minW: 4,
    },
  },
  IMAGE: {
    lg: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    md: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    xxs: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    xs: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
    sm: {
      w: 12, h: 10, minH: 11, minW: 4,
    },
  },
  ONOFF: {
    lg: {
      w: 3, h: 2, minH: 2, minW: 2,
    },
    md: {
      w: 3, h: 2, minH: 2, minW: 2,
    },
    xxs: {
      w: 6, h: 2, minH: 2, minW: 3,
    },
    xs: {
      w: 3, h: 2, minH: 2, minW: 3,
    },
    sm: {
      w: 3, h: 2, minH: 2, minW: 3,
    },
  },
  SLIDER: {
    lg: {
      w: 3, h: 2, minH: 2, minW: 2,
    },
    md: {
      w: 3, h: 2, minH: 2, minW: 2,
    },
    xxs: {
      w: 6, h: 2, minH: 2, minW: 3,
    },
    xs: {
      w: 3, h: 2, minH: 2, minW: 3,
    },
    sm: {
      w: 3, h: 2, minH: 2, minW: 3,
    },
  }
};

export const getDefaultMeasures = (widgets) => {
  const measures = {
    lg: [], xs: [], xxs: [], sm: [], md: [],
  };
  let yCont = 0;
  widgets.forEach((w) => {
    measures.lg.push({
      ...defaultWidgetMeasures[w.widgetType].lg,
      x: 0,
      y: yCont,
      i: w.id,
      moved: false,
      static: false,
    });
    measures.md.push({
      ...defaultWidgetMeasures[w.widgetType].md,
      x: 0,
      y: yCont,
      i: w.id,
      moved: false,
      static: false,
    });
    measures.xs.push({
      ...defaultWidgetMeasures[w.widgetType].xs,
      x: 0,
      y: yCont,
      i: w.id,
      moved: false,
      static: false,
    });
    measures.xxs.push({
      ...defaultWidgetMeasures[w.widgetType].xxs,
      x: 0,
      y: yCont,
      i: w.id,
      moved: false,
      static: false,
    });
    measures.sm.push({
      ...defaultWidgetMeasures[w.widgetType].sm,
      x: 0,
      y: yCont,
      i: w.id,
      moved: false,
      static: false,
    });
    yCont += 5;
  });

  return measures;
};

export const getNextYvalueFromLayout = (layout) => {
  const nextYvalues = {
    lg: 0, sm: 0, md: 0, xs: 0, xxs: 0,
  };
  Object.keys(layout).forEach((meassure) => {
    layout[meassure].forEach((item) => {
      if (item.y >= nextYvalues[meassure]) {
        nextYvalues[meassure] = item.y;
      }
    });
  });
  return nextYvalues;
};

export const getDefaultMeasure = (widget, layout) => {
  const nextYvalues = getNextYvalueFromLayout(layout);
  const measures = {
    lg: undefined, xs: undefined, xxs: undefined, sm: undefined, md: undefined,
  };

  measures.lg = {
    ...defaultWidgetMeasures[widget.widgetType].lg,
    x: 0,
    y: nextYvalues.lg + 1,
    i: widget.id,
    moved: false,
    static: false,
  };
  measures.md = {
    ...defaultWidgetMeasures[widget.widgetType].md,
    x: 0,
    y: nextYvalues.md + 1,
    i: widget.id,
    moved: false,
    static: false,
  };
  measures.xs = {
    ...defaultWidgetMeasures[widget.widgetType].xs,
    x: 0,
    y: nextYvalues.xs + 1,
    i: widget.id,
    moved: false,
    static: false,
  };
  measures.xxs = {
    ...defaultWidgetMeasures[widget.widgetType].xxs,
    x: 0,
    y: nextYvalues.xxs + 1,
    i: widget.id,
    moved: false,
    static: false,
  };
  measures.sm = {
    ...defaultWidgetMeasures[widget.widgetType].sm,
    x: 0,
    y: nextYvalues.sm + 1,
    i: widget.id,
    moved: false,
    static: false,
  };

  return measures;
};

export default defaultWidgetMeasures;
