import React from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import {
  Row, Col, Button,
} from 'reactstrap';
import { clone } from 'ramda';
import CommonView from '../../../CommonView';
import Field from '../../../../elements/Field/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import ConfigureSendCommand from '../components/ConfigureSendCommand';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import StaticListComponent, { getClassByDataLength } from '../components/staticListComponent';
import { getDefaultConfig } from '../../../../models/Widget/configurationSheets';
import Widget from '../../../../models/Widget';
import { getComponent } from '../../Profile/newcomponents/contentProfile';
import Switch from '../../../../elements/Switch';
import { valuesData } from '../../../../models/Widget/configurationSheets/utils';

class ConfigureWidget extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      config: getDefaultConfig(this.props.newEntity.widgetType),
      widgetType: this.props.newEntity.widgetType,
      errors: {},
      datasources: this.props.newEntity.origins || [],
      devices: [],
      ...this.getValuesForInitialState(this.props.newEntity.widgetType),
      disabledType: false,
      period: 'last',
      openedPanels: this.props.newEntity.origins
        ? this.initOpenedStatus(this.props.newEntity.origins)
        : [],
      advancedOptions: false,
      searchValue: '',
      activeAll: false,
      filteredData: [],
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  initOpenedStatus = (datasources) => datasources.map(() => false);

  toggleExpandAttributes = () => {
    const { openedPanels } = this.state;
    if (openedPanels.some((e) => e)) {
      this.setState(
        (old) => ({ openedPanels: old.openedPanels.map(() => false) }),
      );
      return;
    }
    this.setState(
      (old) => ({ openedPanels: old.openedPanels.map(() => true) }),
    );
  }

  toggleAdvancedOptions = () => this.setState(
    (old) => ({ advancedOptions: !old.advancedOptions }),
  )

  handleChangeType = (e) => {
    const { disabledType } = this.state;
    this.setState({
      disabledType: !disabledType,
      period: e.target.id,
    });
  }

  validate = () => {
    const { widgetType } = this.state;
    if (widgetType !== 'SEND_COMMAND') {
      if (this.state.datasources[0].Model.entityName !== 'Kpi'
      && this.getSelectedAttributes().length < 1) {
        this.props.onError(
          <FormattedMessage id="widgets.wizard.no.attributes.selected" />,
        );
      } else {
        const {
          activeAll,
          config,
          datasources,
          devices,
          errors,
          filteredData,
          openedPanels,
          searchValue,
          ...rest
        } = this.state;
        const newWidget = new Widget(widgetType === 'SEND_COMMAND' ? { ...this.state, config: { ...this.state.config, ...rest } } : this.state);
        const validator = newWidget.getValidatorConfig();
        const validation = validator(this.state.config);
        if (validation) {
          this.setState({ errors: validation });
          return;
        }

        this.props.onContinue({
          config: this.state.config,
          origins: this.state.datasources,
        });
      }
    }
  };

  handleOnPanelOpen = (index, status) => {
    const openedPanels = [...this.state.openedPanels];
    openedPanels[index] = status;
    this.setState({
      openedPanels,
    });
  };

  getValuesForInitialState = (widgetType) => {
    // TODO: New config
    const values = {};
    valuesData[widgetType].forEach((value) => {
      const v = typeof value !== 'object' ? value : Object.keys(value)[0];
      values[v] = this.props.newEntity.config
        ? this.props.newEntity.config[v]
        : '';
    });
    return values;
  };

  isMultiValued = () => {
    const { widgetType } = this.state;
    return widgetType === 'BARS' || widgetType === 'LINES' || widgetType === 'TABLE' || widgetType === 'HEATMAP';
  };

  getAttributes = () => {
    const { datasources } = this.state;
    let joinedAttributes = [];
    datasources.forEach((datasource) => {
      if (datasource.Model.entityName !== 'Kpi') {
        joinedAttributes = [
          ...joinedAttributes,
          ...datasource.joinedAttributes.map((attribute) => ({ ...attribute })),
        ];
      }
    });
    return joinedAttributes;
  };

  getSelectedAttributes = () => this.getAttributes().filter((a) => a.selected === true);

  handleAttributeStatusChange = (status, attribute, datasource) => {
    const datasources = clone(this.state.datasources);

    if (!this.isMultiValued()) {
      datasources.forEach((dev) => {
        if (dev.Model.entityName !== 'Kpi') {
          dev.joinedAttributes.forEach((a) => {
            const aValue = a;
            aValue.selected = false;
          });
        }
      });
    }
    if (this.state.datasources[0].Model.entityName === 'Device') {
      datasources
        .find((d) => d.id === datasource.id)
        .joinedAttributes.find((a) => attribute.name === a.name)
        .selected = status;
    } else if (this.state.datasources[0].Model.entityName === 'ETLProcedure') {
      datasources
        .find((d) => d.id === datasource.id)
        .joinedAttributes.find((a) => attribute.name.title === a.name.title)
        .selected = status;
    }
    let { activeAll } = this.state;
    if (status) {
      if (!datasources.find((d) => d.id === datasource.id)
        .joinedAttributes.some((attr) => attr.selected === !status)) {
        activeAll = status;
      }
    } else if (activeAll) {
      activeAll = status;
    }

    const checked = this.isTrendlineActivated(datasource.name, attribute.name);
    if (checked) this.handleCheckTrendline(null, datasource.name, attribute.name);

    this.setState({
      datasources,
      activeAll,
    });
  };

  calcColumns = (columnValuesData) => {
    let dataLength = columnValuesData[this.state.widgetType].length;

    if (dataLength > 4) dataLength = 3;
    else dataLength = dataLength === 1 ? 2 : dataLength;

    return Math.floor(12 / dataLength);
  };

  handleColumnsMap = () => {
    const { datasources: _datasources } = this.state;
    const datasources = [..._datasources];
    datasources.map((device) => {
      if (device.Model.entityName !== 'Kpi') {
        device.joinedAttributes.map((at) => {
          const attribute = at;
          attribute.selected = true;
          return attribute;
        });
      }
      return device;
    });

    this.setState({ datasources });
  };

  isTrendlineActivated = (device, attribute) => {
    const { config } = this.state;
    const { trendlines } = config;
    return trendlines && trendlines[device] ? trendlines[device].includes(attribute) : false;
  }

  handleCheckTrendline = (event, device, attribute) => {
    const config = clone(this.state.config);
    if (!config.trendlines) config.trendlines = {};

    if (!this.isTrendlineActivated(device, attribute)) {
      if (!config.trendlines[device]) config.trendlines[device] = [];
      config.trendlines[device].push(attribute);
    } else {
      config.trendlines[device].splice(config.trendlines[device].indexOf(attribute), 1);
    }

    this.setState({
      config,
    });
  };

  handleInputChange = (event, category = undefined, subCategory = undefined) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    let oldValue = category && !subCategory
      ? this.state.config[category][name]
      : this.state.config[name];

    if (subCategory) oldValue = this.state.config[category][subCategory][name];

    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      this.setState({ config });
    }
  };

  handleGenericChange = (event, category, type, subCategory = undefined) => {
    switch (type) {
      case 'number':
      case 'text':
      case 'textArea':
        this.handleInputChange(event, category, subCategory);
        break;
      case 'checkbox':
        this.handleCheckBoxChange(event.name, event.value, category, subCategory);
        break;
      case 'tableConditions':
      case 'colorPicker':
      case 'colorsData':
      case 'colorsDataMap':
      case 'listComponent':
      case 'barData':
      case 'selectColor':
      case 'selectImage':
      case 'tableData':
      case 'select':
      case 'lineData':
      case 'needleRange':
      case 'advancedLegendColors':
      case 'advancedLegendNames':
        this.handleChangeSelect(event.name, event.value, category, subCategory);
        break;
      case 'switch':
        this.handleSwitchChange(event.name, event.value, category, subCategory);
        break;
      case 'mapStyle':
        this.handleChangeSelect(event.name, event.value, category, subCategory);
        break;
      default:
        return null;
    }
    return true;
  }

  handleChangeSelect = (name, value, category = undefined, subCategory = undefined) => {
    let oldValue = category && !subCategory
      ? this.state.config[category][name]
      : this.state.config[name];
    if (subCategory) oldValue = this.state.config[category][subCategory][name];
    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      this.setState({ config });
    }
  };

  handleSwitchChange = (name, value, category = undefined) => {
    const config = { ...this.state.config };
    if (category) {
      config[category][name] = value;
    } else {
      config[name] = value;
    }
    this.setState({ config });
  }

  handleCheckBoxChange = (name, value, category = undefined, subCategory = undefined) => {
    const config = { ...this.state.config };
    if (category && !subCategory) {
      config[category][name] = value;
    } else {
      config[name] = value;
    }
    if (subCategory) config[category][subCategory][name] = value;
    this.setState({ config });
  };

  handleDateChange = (name, value, category = undefined, subCategory = undefined) => {
    const config = { ...this.state.config };
    if (category && !subCategory) {
      config[category][name] = value;
    } else {
      config[name] = value;
    }
    if (subCategory) config[category][subCategory][name] = value;
    this.setState({ config });
  };

  handleSearch = (e) => {
    const { value } = e.target;
    const { activeAll, datasources } = this.state;

    const filtered = datasources.map((datasource) => {
      const newDataSource = { ...datasource };
      newDataSource.joinedAttributes = datasource.joinedAttributes.filter(
        (attr) => attr.name.includes(value),
      );
      return newDataSource;
    });
    if (activeAll) {
      this.setState({ searchValue: value, filteredData: filtered, activeAll: false });
      return;
    }
    this.setState({ searchValue: value, filteredData: filtered });
  }

  handleCheckAllAttributes = (e) => {
    const { datasources, filteredData } = this.state;
    if (!filteredData.length) {
      const newDatasources = datasources.map((d) => {
        const newDatasource = { ...d };
        newDatasource.joinedAttributes = newDatasource.joinedAttributes.map((att) => {
          const newAtt = { ...att };
          newAtt.selected = e;
          return newAtt;
        });
        return newDatasource;
      });
      this.setState({ activeAll: e, datasources: newDatasources });
      return;
    }

    const newDatasources = datasources.map((datasource) => {
      const newDatasource = { ...datasource };
      filteredData.forEach((filteredDatasource) => {
        const newFilteredDatasource = { ...filteredDatasource };
        if (filteredDatasource.id === newDatasource.id) {
          newDatasource.joinedAttributes = newDatasource.joinedAttributes.map((attr) => {
            const newAttr = { ...attr };
            const index = filteredDatasource.joinedAttributes
              .findIndex((at) => at.name === attr.name);
            if (index < 0) return newAttr;
            newFilteredDatasource.joinedAttributes[index].selected = e;
            return newFilteredDatasource.joinedAttributes[index];
          });
        }
      });
      return newDatasource;
    });

    this.setState({ activeAll: e, datasources: newDatasources });
  }

  render() {
    const { intl } = this.props;
    const {
      datasources,
      openedPanels,
      advancedOptions,
      searchValue,
      activeAll,
      filteredData,
      errors,
    } = this.state;
    const widget = new Widget(this.state);
    const configurationSheet = widget?.getConfigurationSheet();
    const sections = Object.keys(configurationSheet);

    if (this.state.widgetType === 'MAP') {
      datasources.forEach((device) => {
        if (device.Model.entityName !== 'Kpi') {
          device.joinedAttributes.forEach((att) => {
            if (!att.selected) {
              this.handleColumnsMap();
            }
          });
        }
      });
    }

    return (
      <>

        <div className="wizardWrapper configureWidget">
          {this.state.widgetType === 'SEND_COMMAND' ? (
            <ConfigureSendCommand
              onChange={this.handleSelectChange}
              errors={this.state.errors}
            />
          ) : (
            <Row>
              <Col>
                <Row className="d-flex justify-content-center">
                  <Col xs="12" md="6" lg="3">
                    <h2>
                      <FormattedMessage id="widgets.wizard.values.for.widget" />
                    </h2>
                    {/* New config */}

                    {sections.map((section) => (
                      <Row>
                        {configurationSheet[section].map((comp) => (
                          <Col>
                            {getComponent(
                              comp,
                              widget,
                              this.handleGenericChange,
                              () => null, // delayed action to save (disabled on wizard)
                              widget.config,
                              () => null,
                              null,
                              errors,
                            )}
                          </Col>
                        ))}
                      </Row>
                    ))}
                  </Col>
                </Row>

                <Row className="mb-3 d-flex justify-content-center">
                  <Col xs="12" md={getClassByDataLength(datasources.length)} className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="mb-0"><FormattedMessage id="widgets.wizard.configure.attributes" /></h2>
                      <Button color="link" className="shadow-none" onClick={this.toggleAdvancedOptions}>
                        <FormattedMessage id={`widget.config.advancedOptions.${!advancedOptions ? 'enable' : 'disable'}`} />
                      </Button>
                    </div>
                    <Button color="link" className="shadow-none" onClick={this.toggleExpandAttributes}>
                      <FormattedMessage id="widget.config.expand-colapse" />
                    </Button>
                  </Col>
                </Row>
                {advancedOptions && (
                  <Row className="mb-5 d-flex justify-content-center">
                    <Col xs="12" md={getClassByDataLength(datasources.length)}>
                      <Row>
                        <Col>
                          <Field
                            placeHolder={intl.formatMessage({ id: 'widgets.wizard.filterAttributes' })}
                            className="mb-0"
                            value={searchValue}
                            onChange={this.handleSearch}
                          />
                        </Col>
                        {this.isMultiValued()
                        && (
                        <Col className="d-flex align-items-center">
                          <h2 className="mb-2 mr-3"><FormattedMessage id="widgets.wizard.check.all" /></h2>
                          <Switch checked={activeAll} onChange={this.handleCheckAllAttributes} />
                        </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                {StaticListComponent({
                  data: filteredData.length ? filteredData : datasources,
                  openedPanels,
                  noTitle: true,
                  handleChange: this.handleAttributeStatusChange,
                  trendline: this.state.widgetType === 'BARS' ? {
                    checked: this.isTrendlineActivated,
                    handler: this.handleCheckTrendline,
                  } : null,
                }, this.handleOnPanelOpen)}

              </Col>
            </Row>
          )}
        </div>

      </>
    );
  }
}

export default withWizardStep(injectIntl(ConfigureWidget));
