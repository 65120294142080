/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { ButtonCustom } from '../../../../../elements/Button';

import Selectable from '../Selectable';
import Field from '../../../../../elements/Field';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const Form = styled.form`
position:relative;
padding: 8px;
width: 100%;
display: flex;
align-items: center;
&:before{
  transform: translate(0, -50%);
  content: '';
  position: absolute;
  height: 70%;
  width: 1px;
  background: #e0e7ff;
  top: 50%;
  left: 0;
}
& > div {
  margin-bottom: 0;
  & > .innerField{
    border-color: #e0e7ff !important;
  }
}
`;

const createDataSetComp = (createDataSet, createPlaceholder, error) => () => {
  const [name, setName] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    createDataSet(name);
    setName('');
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        type="text"
        placeholder={createPlaceholder}
        value={name}
        onChange={(e) => setName(e.target.value)}
        helperText={error ? <FormattedMessage id="Validation.fetch.error" /> : null}
        error={!!error}
      />
      <ButtonCustom onClick={handleSubmit} type="circular" style={{ marginBottom: '1.6em' }} className="btn btn-primary" name="plus" />
    </Form>
  );
};

const DynamicSelect = ({
  name,
  label,
  placeholder,
  total,
  options,
  getOptions,
  mapOptions,
  onSearch,
  setValue,
  value,
  fetching,
  createDataSet,
  createPlaceholder,
  disabled,
  helperText,
  error,
  id,
}) => {
  const [query, setQuery] = useState({ page: 1, size: 5 });
  const [localErrors, setLocalErrors] = useState(error);

  const handleOptions = useCallback(() => (
    options && options.map(mapOptions)
  ), [options]);

  const handleSelectChange = useCallback((_name, newValue) => {
    setValue(newValue);
    setLocalErrors(null);
  });

  const handleFetchInfo = useCallback(() => {
    if (total > options.length) {
      setQuery({ ...query, page: query.page + 1 });
    }
  }, [options.length, query, total]);

  useEffect(() => {
    const { page, size } = query;
    getOptions(page, size, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (error) {
      setLocalErrors(error);
    }
  }, [error]);
  const CreateDataSetComp = createDataSet && createDataSetComp(
    createDataSet,
    createPlaceholder,
    error,
  );
  return (
    <Selectable
      name={name}
      label={label}
      placeholder={placeholder}
      getOptions={handleOptions}
      handleFetchInfo={handleFetchInfo}
      handleSelectChange={handleSelectChange}
      handleOnSearch={onSearch}
      loading={fetching}
      value={value}
      auxItem={CreateDataSetComp}
      auxError={localErrors}
      disabled={disabled}
      error={!createDataSet && error}
      helperText={helperText}
      id={id}
    />
  );
};

export default DynamicSelect;
