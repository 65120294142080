import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { typography } from '../../../configuration/fonts';
import colors from '../../../configuration/colors';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import Icon from '../../../elements/Icons';

const LedendContainer = styled.div`
  padding: 10px 10px 15px 15px;
  color: #2b334a;
  font-family: ${typography};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  border-bottom: 1px solid ${colors['ui-Grey2']};
  z-index: 1;
  position: absolute;
  background-color: #ffffff;
  float: left;
  width: 12em;
  
`;

const ButtonLegend = styled.div`
  padding: 5px;
  font-family: ${typography};
  color: #2B334A;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  z-index: 1;
  position: absolute;
  height: 30px;
  // width: 135px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.08);
  cursor: pointer;
}
`;

const Range = (props) => {
  const { range, data, deviceId } = props;
  const {
    color, from, to, name,
  } = range;
  let number = 0;

  data.forEach((device) => {
    if (device.id === deviceId) {
      device.attributes.forEach((attribute) => {
        const fromNumber = Number(range.from);
        const toNumber = Number(range.to);
        const equalTo = Number(range.equalTo);
        const value = Number(device.data[attribute]);

        if ((value >= fromNumber && value <= toNumber) || (value === equalTo)) {
          number += 1;
        }
      });
    }
  });

  if (color && from && to && name) {
    return (
      <div className="subElement">
        <OvalBody color={color} />
        {name}
        <OvalNumber color={color} number={number} />
      </div>
    );
  }
  return null;
};

Range.propTypes = {
  range: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  deviceId: PropTypes.string,
};

Range.defaultProps = {
  range: {},
  data: [],
  deviceId: '',
};

const Element = (props) => {
  const { element } = props;
  const { color, name } = element;

  if (color && name) {
    return (
      <div className="subElement">
        <OvalBody color={color} />
        {name}
        <OvalNumber color={color} number={element.devices.length} />
      </div>
    );
  }
  return null;
};

Element.propTypes = {
  element: PropTypes.objectOf(PropTypes.any),
};

Element.defaultProps = {
  element: {},
};

const categories = [
  'GPS',
  'OTHER',
  'LUMINAIRE',
  'WASTE_CONTAINER',
  'WEATHER_STATION',
  'ENVIRONMENTAL_QUALITY',
  'PARKING',
  'ELECTRIC_PANEL',
  'HEALTH',
  'VIDEO_CAMERA',
  'ELECTRIC_METER',
  'WATER_METER',
  'IRRIGATION',
];

const Body = (props) => {
  const {
    category, categoryColor, hideList, data,
  } = props;
  let isHidden = false;
  if (categories.includes(category) && categoryColor.mode && categoryColor[categoryColor.mode]) {
    if (categoryColor && categoryColor.id) {
      categoryColor.id.forEach((id) => {
        if (hideList.indexOf(id) === -1) {
          isHidden = true;
        }
      });
    }
    switch (categoryColor.mode) {
      case 'range': {
        const { range } = categoryColor;

        return (
          <div className={!isHidden ? 'opacity' : null}>
            {Object.keys(range).map((key) => range[key].map((range2) => (
              <Range
                range={range2}
                data={data}
                deviceId={categoryColor.id.toString()}
              />
            )))}
          </div>
        );
      }

      case 'element': {
        const { element } = categoryColor;
        return (
          <div className={!isHidden ? 'opacity' : null}>
            {element.map((ele) => <Element element={ele} data={data} />)}
          </div>
        );
      }

      default:
        return null;
    }
  } else {
    return null;
  }
};

const OvalBody = (props) => {
  const { color } = props;
  return <div className="ovalBody" style={{ backgroundColor: color }} />;
};

OvalBody.propTypes = {
  color: PropTypes.string,
};

OvalBody.defaultProps = {
  color: '',
};

const OvalHeader = (props) => {
  const { color } = props;
  return <div className="ovalHeader" style={{ border: `6px solid ${color}` }} />;
};

OvalHeader.propTypes = {
  color: PropTypes.string,
};

OvalHeader.defaultProps = {
  color: '',
};

function hexToRgb(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return result ? `rgba(${r},${g},${b},${opacity})` : null;
}

const OvalNumber = (props) => {
  const { color, number } = props;
  return (
    <div className="ovalNumber" style={{ backgroundColor: hexToRgb(color, 0.5) }}>
      <p style={{ color, fontWeight: 'bold' }}>
        {number}
      </p>
    </div>
  );
};

OvalNumber.propTypes = {
  color: PropTypes.string,
  number: PropTypes.number,
};

OvalNumber.defaultProps = {
  color: '',
  number: 0,
};

const Header = (props) => {
  const {
    category, categoryColor, categoryAlias, hideList,
  } = props;
  let isHidden = false;
  const hideLayer = () => {
    let updateList = [];
    if (categoryColor && categoryColor.id) {
      updateList = categoryColor.id;
    }
    props.hideLayerMethod(updateList);
  };
  if (categoryColor && categoryColor.id) {
    categoryColor.id.forEach((id) => {
      if (hideList.indexOf(id) === -1) {
        isHidden = true;
      }
    });
  }

  const name = categoryAlias || category;
  const shortname = name.length > 8 ? `${name.substr(0, 8)}...` : name;

  const [isShown, setIsShown] = useState(false);

  return (
    <Row className={isHidden ? 'normalHeader' : 'opacityHeader'}>
      <Col md={2}>
        <OvalHeader color={categoryColor.color} />
      </Col>
      <Col md={8}>
        <div className="headerColMd8">
          <p
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            data-tip={name}
          >
            {shortname}
          </p>
          {isShown && name.length > 8
            && <ReactTooltip place="top" type="dark" effect="float" />}
        </div>
      </Col>
      <Col md={2}>
        <div className="headerColMd2">
          <Icon
            svg={isHidden ? 'VisibilityOutlined' : 'VisibilityOffOutlined'}
            className="svgSmall"
            onClick={hideLayer}
          />
        </div>
      </Col>
    </Row>

  );
};

Header.propTypes = {
  category: PropTypes.string,
  categoryColor: PropTypes.objectOf(PropTypes.any),
  categoryAlias: PropTypes.string,
  hideList: PropTypes.arrayOf(PropTypes.any),
  hideLayerMethod: PropTypes.func,
};

Header.defaultProps = {
  category: '',
  categoryColor: {},
  categoryAlias: '',
  hideList: [],
  hideLayerMethod: () => {},
};

const Legend = (props) => {
  const {
    categoryColors, categoryAlias, hideList, data, isLinked
  } = props;
  const [showLegend, setShowLegend] = useState(false);
  const onToggleShowLegend = () => {
    setShowLegend(!showLegend);
  };

  const hideLayer = (cat) => {
    const list = hideList;
    cat.forEach((c) => {
      if (list.indexOf(c) !== -1) {
        list.splice(list.indexOf(c), 1);
      } else {
        list.push(c);
      }
    });
    props.updateHideLayer([...list]);
  };
  return (
    <div className="legendContainer">
      {showLegend && (
        <LedendContainer style={{ height: isLinked ? 'calc(100% - 0px)' : 'calc(100% - 64px)' }}>
          {categoryColors ? Object.keys(categoryColors).map((cat) => (
            <div>
              <Header
                hideList={hideList}
                hideLayerMethod={hideLayer}
                category={cat}
                categoryColor={categoryColors[cat]}
                categoryAlias={categoryAlias && categoryAlias[cat] ? categoryAlias[cat] : cat}
              />
              <Body
                hideList={hideList}
                category={cat}
                categoryColor={categoryColors[cat]}
                data={data}
              />
            </div>
          )) : null}
        </LedendContainer>
      )}
      <ButtonLegend onClick={onToggleShowLegend} style={{ left: showLegend ? '15.5em' : '1em', top: isLinked ? '0.5em' : '5.2em' }}>
        <div style={{ float: 'left', marginRight: '5px', color: '#577EE8' }}><Icon svg={showLegend ? 'FormatIndentDecrease' : 'FormatIndentIncrease'} fontSize="small" /></div>
        <FormattedMessage component={false} id={`widget.config.legend.${showLegend ? 'hide' : 'show'}`} />
      </ButtonLegend>
    </div>

  );
};

Legend.propTypes = {
  categoryColors: PropTypes.objectOf(PropTypes.any),
  categoryAlias: PropTypes.objectOf(PropTypes.any),
  hideList: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  updateHideLayer: PropTypes.func,
};

Legend.defaultProps = {
  categoryColors: {},
  categoryAlias: {},
  hideList: [],
  data: [],
  updateHideLayer: () => {},
};

export default Legend;
