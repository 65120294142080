import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';

import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import '../styles.scss';
import ActionsGrid from './components/ActionsGrid';

class AssingActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAction: '',
    };
  }

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  validate = () => {
    const { selectedAction } = this.state;
    const {
      onContinue, onError, newEntity: { events },
    } = this.props;

    if (selectedAction !== '') onContinue({ actionToCreate: selectedAction });
    else if (events && events.length > 0) onContinue();
    else {
      onError(
        <FormattedMessage id="rules.wizard.validation.error.validation" />,
      );
    }
  };

  goToStep = () => {
    const { selectedAction } = this.state;
    const {
      newEntity: { events },
    } = this.props;
    return selectedAction === '' && events && events.length > 0 ? 4 : undefined;
  };

  handleOnChange = (selectedAction) => {
    this.setState({
      selectedAction,
    });
  };

  alertActionAlreadyPresent = () => {
    const { events } = this.props.newEntity;
    return (events && events.find(event => event.type === 'alert')) ? true : false;
  };

  render() {
    const { selectedAction } = this.state;
    return (

      <Row>
        <Col sm={{ size: 12 }} lg={{ size: 8, offset: 2 }}>
          <ActionsGrid
            alertActionPresent={this.alertActionAlreadyPresent()}
            selectedAction={selectedAction}
            onChange={this.handleOnChange}
          />
        </Col>
      </Row>
    );
  }
}

AssingActions.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  newEntity: PropTypes.shape({ events: PropTypes.arrayOf(PropTypes.object) }).isRequired,
};

export default withWizardStep(injectIntl(AssingActions));
