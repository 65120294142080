import React from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import Menu from '../../../components/Menu/index';
import Options from '../../../elements/Field/Select/Options';

const DesktopFiltersFinder = ({
  typeOfData,
  intl,
  selectedFilter,
  onClick,
  AuxItem,
  filters,
  textFieldValue,
  onListClick,
  onSearch,
  onTextFieldChange,
  ...rest
}) => {
  return (
    <Row className="finderWrapper">
      <Col className={'mb-3 mb-md-0'} xs={12} md={3}>
        {typeOfData && (
          <div className="optionsForTop">
            <p className="total">
              {intl.formatMessage({ id: `${typeOfData}.table.total` })}
              <span>{rest.total}</span>
            </p>
          </div>
        )}
      </Col>
      <Col
        xs={12}
        md={{ size: 6, offset: AuxItem ? 0 : 3 }}
        className={'mb-3 mb-md-0'}
      >
        <div className={`filtersFinder`}>
          <Menu
            openComponent={({ onClick }) => (
              <div className="caret" onClick={onClick}>
                <span>{selectedFilter.name}</span>
                <i className="uil uil-angle-down" />
              </div>
            )}
            openFrom={'top left'}
            maxHeigth={'300px'}
            top={'46px'}
            className={'optionsList'}
          >
            <Options
              data={filters}
              handleOnChange={onListClick}
              selected={selectedFilter.value}
            />
          </Menu>
          <div className="textField">
            <form onSubmit={onSearch}>
              <input
                type="text"
                value={textFieldValue}
                onChange={onTextFieldChange}
              />
            </form>
          </div>
          <i className="uil uil-search" onClick={onSearch} />
        </div>
      </Col>
      {AuxItem && (
        <Col className={'mb-3 mb-md-0'} xs={12} md={3}>
          <AuxItem />
        </Col>
      )}
    </Row>
  );
};

export default injectIntl(DesktopFiltersFinder);
