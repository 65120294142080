/* eslint-disable import/no-cycle */
import React from 'react';
import { clone } from 'ramda';
import { injectIntl } from 'react-intl';

import './styles.scss';
import DefaultFinder from './DefaultFinder';

const orderByIndex = (a, b) => {
  if (a.index < b.index) {
    return -1;
  }

  if (a.index > b.index) {
    return 1;
  }

  return 0;
};

export default (FinderComponent, AuxItem = null, FinderSelector = undefined) => {
  class Finder extends React.Component {
    constructor(props) {
      super(props);
      let optionsIndexed = [];
      if (this.props.filters) {
        optionsIndexed = clone(this.props.filters);
        optionsIndexed.map((option, i) => (option.index = i));
      }

      this.state = {
        value: '',
        availableOptions: optionsIndexed,
        selectedOptions: [],
        expanded: false,
      };

      this.inputText = React.createRef();
      this.filterDropdown = React.createRef();
    }

    handleOnKeyUp = (e) => {
      const { selectedOptions } = this.state;
      const { onSearch } = this.props;

      clearTimeout(this.typingTimer);
      this.innerValue = e.target.value;
      if (this.innerValue !== '') {
        this.typingTimer = setTimeout(() => {
          this.setState({
            value: this.innerValue,
          });
          onSearch && onSearch(this.innerValue);
        }, 800);
      } else if (e.keyCode === 8 && selectedOptions.length > 0) {
        const lastItem = selectedOptions[selectedOptions.length - 1];
        if (lastItem.term === '') {
          this.removeSelectedFilter(lastItem.index);
        } else {
          const newSelected = clone(selectedOptions);
          newSelected[newSelected.length - 1].term = '';
          this.setState({
            selectedOptions: newSelected,
          });
        }
      } else {
        this.setState({
          value: '',
        }, () => onSearch && onSearch(''));
      }
    };

    handleChangeValue = (e) => {
      this.setState({
        value: e.target.value,
      }, () => {
        this.state.value === '' && this.props.onSearch && this.props.onSearch('');
      });
    };

    handleListClick = (data) => {
      this.addSelectedFilter(data);
      this.filterDropdown.current.style.display = 'none';
    };

    handleOnFocus = () => {
      if (
        this.props.filters
        && this.filterDropdown.current.style.display !== 'block'
      ) {
        this.filterDropdown.current.style.display = 'block';
      }
    };

    handleOnBlur = (e) => {
      if (e.target.value !== '' && this.state.selectedOptions.length > 0) {
        this.addTermValue(e);
      } else if (e.target.value !== this.state.value) {
        this.setState({
          value: e.target.value,
        });
      }
      if (this.state.availableOptions.length > 0) {
        if (this.filterDropdown.current !== e.relatedTarget) {
          this.filterDropdown.current.style.display = 'none';
        }
      }
    };

    handleClickChip = (index) => {
      this.removeSelectedFilter(index);
    };

    handleOnSubmit = (e) => {
      e.stopPropagation();
      e.preventDefault();

      this.props.onSubmit(this.state.selectedOptions);
    };

    /** * */
    handleOnSearch = (value, filter) => {
      const { externalFilter } = this.props;
      if (externalFilter) externalFilter(value, filter);
      this.setState({
        value,
        selectedFilter: filter,
      });
    };

    handleOnFinderChange = (value, filter) => {
      clearTimeout(this.typingTimer);
      if (value !== '' && value.length > 0) {
        this.typingTimer = setTimeout(() => {
          this.handleOnSearch(value, filter);
        }, 800);
      } else {
        this.handleOnSearch(value, filter);
      }
    };

    filterLocally = (data) => {
      const { value, selectedFilter } = this.state;
      if (value !== '' && selectedFilter) {
        return data.filter((d) => d[selectedFilter.value].toLowerCase().includes(value.toLowerCase()));
      }
      return data;
    };

    resetValue = () => {
      const { externalFilter } = this.props;
      const { selectedFilter } = this.state;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        !externalFilter ? this.setState({ value: '' }) : externalFilter('', selectedFilter);
      }, 800);
    };

    removeSelectedFilter = (index) => {
      const newAvailableOptions = clone(this.state.availableOptions);
      let newSelectedOptions = clone(this.state.selectedOptions);

      newAvailableOptions.push(
        newSelectedOptions.find((option) => option.index === index),
      );
      newAvailableOptions[newAvailableOptions.length - 1].term = '';
      newAvailableOptions.sort(orderByIndex);

      newSelectedOptions = newSelectedOptions.filter(
        (option) => option.index !== index,
      );

      this.setState({
        availableOptions: newAvailableOptions,
        selectedOptions: newSelectedOptions,
      });
    };

    addSelectedFilter = (index) => {
      let newAvailableOptions = clone(this.state.availableOptions);
      const newSelectedOptions = clone(this.state.selectedOptions);

      newSelectedOptions.push(
        newAvailableOptions.find((option) => option.index === index),
      );
      newAvailableOptions = newAvailableOptions.filter(
        (option) => option.index !== index,
      );

      this.setState({
        availableOptions: newAvailableOptions,
        selectedOptions: newSelectedOptions,
      });
    };

    addTermValue = (e) => {
      const { selectedOptions } = this.state;
      const selected = clone(selectedOptions);
      selected[selected.length - 1].term = e.target.value;

      this.setState({
        selectedOptions: selected,
        value: '',
      });

      e.target.value = '';
    };

    capitalize = (object) => {
      if (typeof object === 'string') return object.toUpperCase();

      const isArray = Array.isArray(object);
      for (const key in object) {
        const value = object[key];
        let newKey = key;
        if (!isArray) {
          delete object[key];
          newKey = key.toUpperCase();
        }
        let newValue = value;
        if (typeof value !== 'object') {
          if (typeof value === 'string') {
            newValue = value.toUpperCase();
          }
        } else {
          newValue = this.capitalize(value);
        }
        object[newKey] = newValue;
      }
      return object;
    };

    findInArray = (array, value) => JSON.stringify(this.isCaseSensitive(array)).includes(value);

    filterObjByKeys = (obj) => {
      const { findbykeys } = this.props;
      const newObj = {};
      findbykeys.forEach((key) => {
        if (obj[key]) newObj[key] = obj[key];
      });
      return newObj;
    };

    findInObj = (obj, value) => {
      const { findbykeys } = this.props;
      obj = findbykeys && findbykeys.length > 0 ? this.filterObjByKeys(obj) : obj;
      return JSON.stringify(this.isCaseSensitive(obj).NAME).includes(value);
    };

    isCaseSensitive = (v) => (!this.props.casesensitive ? this.capitalize(v) : v);

    filterData = (list, filterBy) => {
      const value = !this.props.casesensitive
        ? this.state.value.toUpperCase()
        : this.state.value;
      const filteredList = [];

      if (Array.isArray(list)) {
        list.forEach((d) => {
          if (filterBy) {
            if (d[filterBy].toUpperCase().includes(value)) filteredList.push(d);
          } else {
            const check = clone(d);
            /** fist, check plain Strings */
            if (
              typeof d === 'string'
              && this.isCaseSensitive(d.toString()).includes(value)
            ) filteredList.push(d);
            else if (Array.isArray(d) && this.findInArray(check, value)) filteredList.push(d);
            else if (typeof d === 'object' && this.findInObj(check, value)) filteredList.push(d);
          }
        });
      }

      return filteredList;
    };

    render() {
      const {
        value, availableOptions, selectedOptions, expanded,
      } = this.state;
      const {
        placeholder,
        css,
        data,
        auxItem,
        typeOfData,
        intl,
        externalFilter,
        filters,
        total,
        filterBy,
        createEntityComponent,
        ...rest
      } = this.props;

      let dataFiltered = selectedOptions.length === 0 && data && value !== ''
        ? this.filterData(data, filterBy)
        : data;

      if (filters) {
        dataFiltered = !externalFilter ? this.filterLocally(data) : data;
      }

      // If placeholder is sent by an external page, use that value, else, use the default value
      this.placeholder = placeholder || intl.formatMessage({ id: 'finder.box.placeholder' });
      return (
        <>
          {!FinderSelector ? (
            <DefaultFinder
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...this.props}
              selectedOptions={selectedOptions}
              value={value}
              availableOptions={availableOptions}
              AuxItem={AuxItem || auxItem}
              expanded={expanded}
              onClickChip={this.handleClickChip}
              onSubmit={this.handleOnSubmit}
              onFocus={this.handleOnFocus}
              onKeyUp={this.handleOnKeyUp}
              onBlur={this.handleOnBlur}
              filterDropdownRef={this.filterDropdown}
              onListClick={this.handleListClick}
            />
          ) : (
            <FinderSelector
              filters={filters}
              externalFilter={externalFilter}
              AuxItem={createEntityComponent || AuxItem}
              filterDropdownRef={this.filterDropdown}
              onSearch={this.handleOnSearch}
              typeOfData={typeOfData}
              intl={intl}
              total={externalFilter ? total : dataFiltered.length}
              onReset={this.resetValue}
              selectedFilter={this.props.selectedFilter || filters[0]}
              onChange={this.handleOnFinderChange}
            />
          )}
          <FinderComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            total={externalFilter ? total : dataFiltered.length}
            data={dataFiltered}
            typeOfData={typeOfData}
          />
        </>
      );
    }
  }

  Finder.defaultProps = {
    placeholder: '',
    findbykeys: [],
    casesensitive: false,
    data: [],
    css: '',
    options: [],
    onSubmit: () => {},
  };

  Finder.WrappedComponent = FinderComponent;

  return injectIntl(Finder);
};
