import React from 'react';
import Field from '../../../elements/Field';
import CommonView from '../../CommonView';
import Permission, { SamplePermission } from "../../../models/Permission/index";

class Info extends CommonView {

    constructor(props) {
        super({ props: props });

        this.state = {
            errors: {},
            original: props.data,
            permission: new Permission({...props.data}),
        }

    }

    componentDidMount() {
        this.props.onRef(this);
    }

    handleOnEdit = (e) => {

        e.preventDefault();
        e.stopPropagation();
        this.setErrors({});

        const NewPermission = new Permission({ ...this.state.original, ...this.state });

        if (JSON.stringify(new Permission(this.state.original).getData()) !== JSON.stringify(NewPermission.getData())) {
            const updated = NewPermission.update();
            if (updated.error) {
                delete updated.error;
                this.setErrors(updated);
            } else {
                this.props.changeMode();
                this.props.succeed(NewPermission);
            }
        } else {
            this.props.changeMode();
        }
    }

    handleOnSave = ({ name, value }) => {
        const permission = this.state.permission;
        permission[name] = value;
        this.setState({
            [name]: value,
        })
    }

    handleOnMultiSelectSave = (name, value) => {
        const permission = this.state.permission;
        permission[name] = value;
        this.setState({
            permission,
        })
    }

    render() {
        const {intl, edit } = this.props;
        const { permission, errors } = this.state;

        return (
            <div className="tabSection">
                {edit &&
                    ['name', 'description', 'action', 'resource'].map((key, i) => (
                        <div className="fieldGroup" key={i}>
                            <Field
                                name={key}
                                label={intl.formatMessage(SamplePermission.plainTranslations[key])}
                                placeholder={intl.formatMessage(SamplePermission.plainTranslations[key])}
                                id={key}
                                value={permission[key]}
                                type={key === "email" ? 'email' : 'text'}
                                onChange={this.handleInputChange}
                                helperText={errors[key]}
                                error={!!errors[key]}
                                key={`${key}-${i}`}
                                mode={'view'}
                                save={this.handleOnSave}

                            />
                            <i className='uil uil-edit-alt'></i>
                        </div>
                    ))
                }
                {!edit &&
                    ['name', 'description', 'action', 'resource'].map((key, i) => (
                        <React.Fragment key={i}>
                            <dt>{intl.formatMessage(SamplePermission.plainTranslations[key])}</dt>
                            <dd>{permission[key]}</dd>
                        </React.Fragment>
                    ))
                }
            </div>
        )
    }
}

export default Info;