import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from 'react-intl';

/** Create Automatically translations for Keys attributes
 * and return React components
 * */
export const CreateTranslationForModelAttributes = (PlainObject) => {
  const Translations = {};
  Object.keys(PlainObject).forEach((key) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    Translations[key] = (<FormattedMessage {...PlainObject[key]} />);
  });
  return Translations;
};

/** Create Automatically translations for Keys attributes
 * and return Object intl Translation
 * */
export const CreateTranslationPlainForModels = (prefix, ModelExpected) => {
  const Translations = {};
  ModelExpected.forEach((row) => {
    Translations[row.key] = {
      id: `${prefix}.model.${row.key}`,
      defaultMessage: row.key.toString(),
      description: `Text of ${row.key} property in ${prefix}`,
    };
  });
  return Translations;
};

/** Take constrains defined in ModelExpected and return an object
 * only with constraints by key
 * */
const createConstraintsByModelExpected = (ModelExpected) => {
  const ConstraintsObj = {};

  ModelExpected.forEach((row) => {
    if (row.constraints) ConstraintsObj[row.key] = row.constraints;
  });

  return ConstraintsObj;
};

class Utils {
  /** Get Model from extender class and object passed
     *  Then build model
     * */
  constructor(ModelExpected, obj = {}) {
    this.ModelExpected = ModelExpected;
    this.ModelExpected.forEach((param) => {
      this[param.key] = obj[param.key] ? obj[param.key] : param.defaultValue;
    });

    this.constraints = createConstraintsByModelExpected(this.ModelExpected);
  }

    /** Returns all the data of the model or only the data filtered by the keys passed
     * (a string or an array of strings).
     * The returned data will always be contained in the expected model.
     * */
    getData = (keyForFilter = null) => (keyForFilter ? this.filter(keyForFilter) : this.toJson());

    /** filter data by the keys passed
     * (a string or an array of strings)
     * */
    filter = (filter = []) => {
      const Json = {};

      const filterByArray = () => {
        filter.forEach((param) => {
          if (this[param]) Json[param] = this[param];
        });
        return Json;
      };

      const filterByString = () => {
        if (this[filter]) Json[filter] = this[filter];
        return Json;
      };

      if (typeof filter === 'string') {
        return filterByString();
      }

      if (Array.isArray(filter)) {
        return filterByArray();
      }

      return Json;
    };

    /** return all user as json obj */
    toJson = () => {
      const Json = {};
      this.ModelExpected.forEach((param) => {
        Json[param.key] = this[param.key];
      });
      return Json;
    };

    validateWithAction(callback, data = this.getData(), partialCheck = false, constraints) {
      /**
       * This method validates with callBack, data can be substituted with only 
       * the data you want to validate, if data is modified, partialCheck must come to True
       */
      const errors = this.getErrors(data, partialCheck, constraints);
      return errors ?? callback(data)
    }

    getErrors(data = this.getData(), partialCheck = false, constraints) {
      const filteredConstraints = partialCheck ? {} : this.constraints;

      if (partialCheck && !constraints) {
        if (Array.isArray(data)) {
          data.forEach((d) => { filteredConstraints[d] = this.constraints[d]; });
        } else {
          Object.keys(data).forEach((d) => { filteredConstraints[d] = this.constraints[d]; })
        }
      }

      const validation = validate(data, constraints ?? filteredConstraints);

      return !validation ? undefined : { ...validation }
    }
}
export default Utils;
