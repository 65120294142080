import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography } from '../../configuration/fonts';
import colors from '../../configuration/colors';
import { sendCommand as sendCommandApi } from "../../services/api/devices";
import SendButton from './components/button'

import './styles.scss';

const Actions = styled.div`
  float: right;
  color: ${colors['ui-Black']};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -10px;;
`;

const sendCommand = async (e, device, command, callback) => {
  //e.preventDefault();
  //e.stopPropagation();
  return await sendCommandApi(device, command);

};

class WidgetSendCommandDual extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      response: '',
      status: true,
      
    }

  }

  handleSendCommands = async e => {
    const { state_a, state_b } = this.props
    const { status } = this.state
    const commands = status ? state_a.commands : (state_b ? state_b.commands: [])
    let allSuccess = true;

   
      for (let index = 0; index < commands.length; index++) {
        try{
          const response = await sendCommand(e, { id: commands[index].id }, { name: commands[index].command, value: commands[index].value });
          if (response.status !== 200) {
            allSuccess = false
          } 
        }catch{
          allSuccess = false
        }
  
      }
  
    
   
    if(allSuccess){
      this.setState({
        response: 'done',
        status: !status
      })
    }else{
      this.setState({
        response: 'wrong'
      })
    }

   
  }
  resetButton = () => {
    this.setState({
      response:''
    })
  }

  render() {
    const { text, subtext, actions, state_a, state_b } = this.props;
    const { status, response } = this.state
    const data = { status, label: status ? state_a.label : (state_b ? state_b.label : ''), response}

    
    return (
      <div className={'containerSendcommandWidgetDual'}>
        <Actions>{actions}</Actions>
        <div>
          <h1>
            {text} 
          </h1>
        </div>
        <div>
          <p>{subtext}</p>
        </div>
        <SendButton onClick={this.handleSendCommands} data={data} reset={this.resetButton}/>
      </div>
    );
  }
}

WidgetSendCommandDual.propTypes = {
  text: PropTypes.string,
  subtext: PropTypes.string,
};

WidgetSendCommandDual.defaultProps = {
  text: '',
  subtext: '',
};

export default WidgetSendCommandDual;
