import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Avatar from '../../../../elements/Avatar';
import AddPermissionButton from './AddPermissionButton';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import RemoveHoc from '../../RemoveComponentSimplify';

const DeleteButtonModal = ({ showModal }) => (
  <div className="deleteButton" onClick={showModal}>
    <i className="uil uil-times" />
  </div>
);

const DeleteButton = props => (
  <div className="deleteButton" onClick={props.onClick}>
    <i className="uil uil-times" />
  </div>
);

const getFirstLetter = (name) => (!name ? '' : name.charAt(0).toUpperCase());

export const Member = ({ user }) => (
  <>
    <Avatar
      size="sm"
      backgroundColor="random"
      color="#fff"
      letter={getFirstLetter(user.name)}
      className="avatar"
    >
      <div className="navColorAvatar">{getFirstLetter(user.name).toUpperCase()}</div>
    </Avatar>
    <div className="info">
      <p>
        {user.name}
        {' '}
        {user.surname}
      </p>
      <p>{user.email}</p>
    </div>
  </>
);

const RemoveDOM = RemoveHoc(DeleteButtonModal);

class ListOfMembers extends React.Component {
  handleOnCheckBoxChange = (permissions, user) => {
    this.props.onCheckBoxChange(permissions, user);
  };

  render() {
    const {
      title,
      users,
      onDelete,
      options,
      newEntity,
      showNumberMembers,
      entityType,
      permissionsOptions,
      typeOfMembers,
      permissionToEdit,
      saveOnTheFly,
    } = this.props;
    const usersWithPermissions = newEntity.permissions_policy
      ? newEntity.permissions_policy[typeOfMembers]
      : null;

    return (
      <div className="listOfMembers">
        <h3>
          {showNumberMembers ? (
            <span>
              {' '}
              {users.length}
              {' '}
            </span>
          ) : null}
          <FormattedMessage id={title} />
        </h3>
        <ListGroup>
          {users.map((u, i) => (
            <ListGroupItem
              key={`list-item-${i}`}
              className="d-flex justify-content-start"
            >
              <Member user={u} />
              {options && (
                <>
                  {permissionsOptions && (
                    <AddPermissionButton
                      write={
                        (usersWithPermissions.find((user) => user.id === u.id)
                          && usersWithPermissions.find((user) => user.id === u.id)
                            .write)
                        || false
                      }
                      user={u}
                      onClick={this.handleOnCheckBoxChange}
                      entityType={entityType}
                      permissionToEdit={permissionToEdit}
                    />
                  )}

                  {permissionToEdit && saveOnTheFly
                    ? (
                      <RemoveDOM
                        onDelete={() => onDelete(u)}
                        data={{ name: u.name }}
                        stringTranslationHeader="usersGroups.profile"
                      />
                    )
                    : (
                      <DeleteButton
                        onClick={() => onDelete(u)}
                      />
                    )}

                </>
              )}
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    );
  }
}

ListOfMembers.defaultProps = {
  permissionsOptions: true,
  entityType: 'ListOfMembers',
  typeOfMembers: 'users',
};

export default ListOfMembers;
