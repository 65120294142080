import { clone } from 'ramda';
import { formatToBarWidget, formatToLineWidget } from '../../../../models/Widget/utils';
import { readHistorical } from '../../../../services/api/historicals';
import { updateHistoricalList } from '../../../../services/redux/historicals/actions';

const refreshWidgetData = (toUpdateList, message, wValues) => {
  const widgetValues = clone(wValues);
  const promisesMain = [];
  const messageCopy = { ...message };
  toUpdateList.forEach((widget) => {
    let connectedDevice;
    let attribute;
    let newValue;
    let point;

    if (widgetValues[widget.id]) {
      switch (widget.widgetType) {
        case 'LINES': {
          const deviceId = message.id;

          const originAffected = widget.origins.find(
            (o) => o.connectedDevices.device_id === deviceId,
          );

          const affectedLines = widgetValues[widget.id].series.filter(
            (d) => d.device_id === deviceId && message[d.attribute],
          );

          const queries = widget.getQueryHistorical().filter((o) => o.id === deviceId);
          // Make Reques for updated data

          const promises = [];

          affectedLines.forEach((line) => {
            // eslint-disable-next-line no-async-promise-executor
            promises.push(new Promise(async (resolve) => {
              const response = await readHistorical(
                queries.find((o) => o.attribute === line.attribute),
              );
              if (response && response.status === 200) {
                const originCopy = { ...originAffected };
                originCopy.connectedDevices.historicaldata = [
                  { attribute: line.attribute, data: response.data.values },
                ];

                const formattedLine = formatToLineWidget(
                  [originCopy],
                  widget.config,
                );
                const dataFormattedLine = formattedLine.series[0];

                if (dataFormattedLine) {
                  widgetValues[widget.id].series.forEach((wLine, i) => {
                    if (wLine.device_id === dataFormattedLine.device_id
                      && wLine.attribute === dataFormattedLine.attribute) {
                      widgetValues[widget.id].series[i].data = dataFormattedLine.data;
                    }
                  });
                }
              }
              resolve();
            }));
          });

          promisesMain.push(Promise.all(promises));

          break;
        }

        case 'PARAMETRIZED_TEXT':
        case 'BARS': {
          if (widget.config.data.type === 'historical') {
            const deviceId = message.id;

            const originAffected = widget.origins.find(
              (o) => o.connectedDevices.device_id === deviceId,
            );

            const affectedLines = [
              ...(originAffected?.connectedDevices.attributes ?? []),
              ...(originAffected?.connectedDevices.lazy_attributes ?? []),
              ...(originAffected?.connectedDevices.command_attributes ?? []),
              ...(originAffected?.connectedDevices.static_attributes ?? [])];

            const queries = widget.getQueryHistorical().filter((o) => o.id === deviceId);
            // Make Reques for updated data

            const promises = [];
            const connectedDeviceCopy = { ...originAffected };

            affectedLines.forEach((line) => {
              // eslint-disable-next-line no-async-promise-executor
              promises.push(new Promise(async (resolve) => {
                const response = await readHistorical(
                  queries.find((o) => o.attribute === line),
                );
                if (response && response.status === 200) {
                  connectedDeviceCopy.connectedDevices.historicaldata = connectedDeviceCopy.connectedDevices
                    .historicaldata.filter((cd) => cd.attribute !== line);
                  connectedDeviceCopy.connectedDevices.historicaldata.push({
                    attribute: line,
                    data: response.data.values,
                  });

                  const formattedLine = formatToBarWidget(
                    [...widget.origins.filter(
                      (o) => o.connectedDevices.device_id !== deviceId,
                    ), connectedDeviceCopy],
                    widget.config,
                  );

                  if (formattedLine) {
                    widgetValues[widget.id] = formattedLine;
                  }
                }
                resolve();
              }));
            });

            promisesMain.push(Promise.all(promises));
          } else {
            connectedDevice = widgetValues[widget.id].data.find(
              (cd) => cd.device_id === message.id,
            );
            Object.keys(connectedDevice).forEach((i) => {
              if (i !== 'device' && i !== 'device_id' && message[i]) {
                connectedDevice[i] = message[i].value;
              }
            });
          }
          break;
        }

        case 'NEEDLE':
        case 'DIGITAL_ACCOUNTANT':
        case 'PERCENTAGE':
          connectedDevice = widget.origins[0].connectedDevices;
          attribute = [
            ...(connectedDevice.attributes || []),
            ...(connectedDevice.lazy_attributes || []),
            ...(connectedDevice.command_attributes || []),
            ...(connectedDevice.static_attributes || []),
          ];
          newValue = message[attribute] ? message[attribute] : 0;
          newValue = newValue ? newValue.value : 0;
          // connectedDevice.historicaldata[0] = message;
          widgetValues[widget.id] = newValue;

          break;

        case 'TEXT_ACCOUNTANT': {
          connectedDevice = widget.origins[0].connectedDevices;
          attribute = [
            ...(connectedDevice.attributes || []),
            ...(connectedDevice.lazy_attributes || []),
            ...(connectedDevice.command_attributes || []),
            ...(connectedDevice.static_attributes || []),
          ];
          if (typeof message[attribute] === 'boolean') {
            newValue = widgetValues[widget.id] ? widgetValues[widget.id][0] : 0;
          } else {
            newValue = message[attribute] ? message[attribute] : 0;
            newValue = newValue ? newValue.value : 0;
          }
          connectedDevice.historicaldata[0] = message;

          const query = widget.getQueryHistorical();

          promisesMain.push(new Promise(async (resolve) => {
            if (query.length < 2) resolve();

            const response = await readHistorical(query[1]);

            if (response && response.status === 200) {
              const originsCopy = {
                ...widget.origins.find((cd) => cd.connectedDevices.device_id === message.id),
              };

              originsCopy.connectedDevices.historicaldata = [
                { attribute, data: response.data.values },
              ];

              const formattedLine = formatToLineWidget(
                [originsCopy],
                widget.config,
              );

              widgetValues[widget.id] = [newValue, formattedLine];
            }
            resolve();
          }));

          break;
        }
        case 'MAP':
          if (widget.config?.data?.realTimeUpdates) {
            point = undefined;

            connectedDevice = widgetValues[widget.id].find((cd) => cd.device_id === message.id);

            if (connectedDevice) {
              Object.keys(message).forEach((i) => {
                if (message[i].type && (message[i].type.toLowerCase() === 'point' || message[i].type.toLowerCase() === 'geo:point')) {
                  point = message[i].value;
                }

                connectedDevice.data[i] = message[i].value;
              });

              if (point) {
                connectedDevice.location = { x: point.split(',')[0], y: point.split(',')[1] };
                const { id, type } = message;
                const mappedMessage = {};
                Object.keys(message).forEach((e) => {
                  if (e !== 'permissionPolicy') mappedMessage[e] = message[e].value || message[e];
                });
                updateHistoricalList({
                  ...mappedMessage,
                  entityId: id,
                  entityType: type,

                });
              }
            }
          }

          break;

        case 'TABLE':
          connectedDevice = widgetValues[widget.id].find(
            (cd) => cd.device.device_id === message.id,
          );
          messageCopy.recvTime = messageCopy.TimeInstant;
          Object.keys(messageCopy).forEach((i) => {
            connectedDevice.data[i] = messageCopy[i].value;
          });
          break;

        case 'HEATMAP':
          connectedDevice = widgetValues[widget.id].data.filter(
            (cd) => cd.device_id === message.id,
          );
          if (widget.config.data.type === 'real-time') {
            connectedDevice.forEach((cd) => {
              connectedDevice[cd].heatmap.value = Number(message[cd.attribute].value);
            });
          }
          break;

        case 'LINKED':
          if (widget.config.custom.LINKED.mode === 'MAP') {
            if (widget.config?.data?.realTimeUpdates) {
              point = undefined;

              connectedDevice = widgetValues[widget.id].find((cd) => cd.device_id === message.id);

              if (connectedDevice) {
                Object.keys(message).forEach((i) => {
                  if (message[i].type && (message[i].type.toLowerCase() === 'point' || message[i].type.toLowerCase() === 'geo:point')) {
                    point = message[i].value;
                  }

                  connectedDevice.data[i] = message[i].value;
                });

                if (point) {
                  connectedDevice.location = { x: point.split(',')[0], y: point.split(',')[1] };
                  const { id, type } = message;
                  const mappedMessage = {};
                  Object.keys(message).forEach((e) => {
                    if (e !== 'permissionPolicy') mappedMessage[e] = message[e].value || message[e];
                  });
                  updateHistoricalList({
                    ...mappedMessage,
                    entityId: id,
                    entityType: type,

                  });
                }
              }
            }
            return;
          }
          connectedDevice = widgetValues[widget.id].find(
            (cd) => cd.device.device_id === message.id,
          );
          messageCopy.recvTime = messageCopy.TimeInstant;
          Object.keys(messageCopy).forEach((i) => {
            connectedDevice.data[i] = messageCopy[i].value;
          });
          break;

        default:
          break;
      }
    }
  });
  return Promise.all(promisesMain).then(() => widgetValues);
};
export default refreshWidgetData;
