import Faker from "faker";
import { exampleGroups } from "../redux/roles/reducer";

export const exampleUsers = ( data = null , numberOfUserToGenerate = 12) => {

  if(data != null){
    const newData = JSON.parse(data);
    if(!newData.id){
      newData.id = Faker.random.uuid();
    }
    return  { "user": newData};
  }

  const UsersFaker = [];
  for (let i = 0; i < numberOfUserToGenerate; i++) {
    UsersFaker.push({
      id: Faker.random.uuid(),
      email: Faker.internet.email(),
      name: Faker.name.firstName(undefined),
      surname: Faker.name.lastName(undefined),
      password: Faker.finance.account(7),
      avatar: Faker.image.avatar(),
      roles: [Faker.random.arrayElement(exampleRoles(null, 1).roles)],
      groups: [Faker.random.arrayElement(exampleGroups)],
    })
  }
  return { "users": UsersFaker };
};


export const exampleRoles = ( data = null , numberOfRoleToGenerate = 12) => {
  if(data != null){
    const newData = JSON.parse(data);
    if(!newData.id){
      newData.id = Faker.random.uuid();
    }

    return  { "role" : newData };
  }

  const RolesFaker = [];
  for (let i = 0; i < numberOfRoleToGenerate; i++) {
    RolesFaker.push({
      id: Faker.random.uuid(),
      name: Faker.name.jobDescriptor(),
      description: Faker.lorem.lines(5),
      permissions: [Faker.random.arrayElement(examplePermissions().permissions)],
    })
  }
  return { "roles": RolesFaker };
};


export const examplePermissions = (data = null) => {

  if(data != null){
    const newData = JSON.parse(data);
    if(!newData.id){
      newData.id = Faker.random.uuid();
    }
    return  { "permission": newData};
  }

  const permissions =
    [
      { id: "B456", name: "Manage",  description: "Can manage", resource:"www.manager.com"},
      { id: "A123", name: "Edit", description: "Can edit", resource:"www.editor.com"},
      { id: "C589", name: "Advise",  description: "Can advise", resource:"www.advisor.com"},
      { id: "D452", name: "Produce",  description: "Can produce", resource:"www.producer.com"},
      { id: "E882", name: "Explore",  description: "Can explore", resource:"www.explorer.com"},
      { id: "E882", name: "Administrate",  description: "Can administrate", resource:"www.administrator.com"}
    ];
  return { "permissions" : permissions};

};

export const exampleUserMe = (data = null) => {
  return {"user": {name: 'User', surname: 'Admin'}}
}
