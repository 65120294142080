import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from './helpers/helpers';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import withVariables from '../../../../components/WithVariables/index';
import { parseToTag } from '../../helpers/briefComponents';

const FieldComponentWithVariables = withVariables(TextField);

const EditNotificationComponent = ({
  notification,
  errors,
  intl,
  onEdit,
  index,
  onChange,
  devices,
}) => (
  <>
    {['to', 'from', 'subject'].map((key) => (
      <dl key={key}>
        <dt className="col col-md-3">
          <FormattedMessage id={`rules.wizard.${key}.label`} />
        </dt>
        <dd className="col col-md-7">
          {!onEdit ? (
            notification[key]
          ) : (
            <TextField
              onChange={(data) => onChange({
                id: data.target.name,
                value: data.target.value,
                index,
                type: notification.type,
              })}
              value={notification[key]}
              errors={errors[key]}
              dataName={key}
              type="text"
              intl={intl}
            />
          )}
        </dd>
      </dl>
    ))}
    {['template'].map((key) => (
      <dl key={key}>
        <dt className="col col-md-3">
          <FormattedMessage id={`rules.wizard.${key}.label`} />
        </dt>
        <dd className="col col-md-7">
          {!onEdit ? (
            parseToTag(notification[key])
          ) : (
            <FieldComponentWithVariables
              onChange={(data) => onChange({
                id: data.target.name,
                value: data.target.value,
                index,
                type: notification.type,
              })}
              value={notification[key]}
              intl={intl}
              errors={errors[key]}
              dataName={key}
              name={key}
              helperText={
                errors.template
                || intl.formatMessage({ id: 'complexTextarea.pattern' })
              }
              type="complexTextarea"
              devices={devices.map(
                ({ id, attributes }) => ({
                  device: id,
                  attributes,
                }),
              )}
            />
          )}
        </dd>
      </dl>
    ))}
  </>
);

EditNotificationComponent.propTypes = {
  notification: PropTypes.objectOf(PropTypes.object).isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  intl: PropTypes.objectOf(PropTypes.object).isRequired,
  onEdit: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EditNotificationComponent.defaultProps = {
  errors: {},
};

export default EditNotificationComponent;
