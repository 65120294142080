import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import CommonView from '../../CommonView';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../Contexts/WizardStepContext';
import { protocolData } from '../../../data/protocols';

import EditableTable from '../../../components/EditableTable';
import Attribute from '../../../models/Attribute';
import { isMobile } from '../../../helpers/getBreakpoint';
import { EditProperties } from '../Profile/ProfileDevice/EditProperties';
import { INTERNAL } from '../Profile/ProfileDevice/helpers';
import { BlanketCtxtProvider } from '../../../Contexts/BlanketContext';

const Title = styled.div``;

const ATTRIBUTE = 'attribute';
const LAZY = 'lazy';
const COMMAND = 'command';
const STATIC = 'static';

export class AddProperties extends CommonView {
  constructor(props) {
    super({ props });

    const newEntity = !this.props.newEntity.allAttributes
      ? {
        ...this.props.newEntity,
        static_attributes: [],
        lazy_attributes: [],
        attributes: [],
        command_attributes: [],
        allAttributes: [],
      }
      : { ...this.props.newEntity };
    this.state = {
      newEntity,
      orderData: !this.props.newEntity.allAttributes ? [] : this.props.newEntity.allAttributes,
    };
    this.getHeaders();

    this.editableTable = new React.createRef();
  }

  static propTypes = {
    errors: PropTypes.object,
  };

  static defaultProps = {
    errors: {},
  };

  header = [
    {
      label: (
        <FormattedMessage id={`${this.props.entity.entityName}.model.property`} />
      ),
      key: 'property',
      type: 'select',
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.id`} />,
      key: 'key',
      unique: true,
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.name`} />,
      key: 'name',
      unique: true,
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.type`} />,
      key: 'type',
    },
    {
      label: (
        <FormattedMessage id={`${this.props.entity.entityName}.model.parameter`} />
      ),
      key: 'parameter',
      type: 'select',
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.value`} />,
      key: 'value',
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.unit`} />,
      key: 'unit',
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.offset`} />,
      key: 'offset',
      type: 'select',
    },
    {
      label: <FormattedMessage id={`${this.props.entity.entityName}.model.endian`} />,
      key: 'endian',
      type: 'select',
    },
  ];

  getModelOfData = () => protocolData.protocol.find((p) => p.value === this.state.newEntity.protocol);

  getUniqueProperties = (attributes) => {
    let allAttributesProtocol = [];
    attributes.forEach((a) => {
      allAttributesProtocol = [...allAttributesProtocol, ...a.properties];
    });
    return Array.from(new Set(allAttributesProtocol));
  };

  // Translates properties names to the formatted message version so that it can be translated to the different languages
  translateProperties = (property) => {
    const translatedProperty = { ...property };

    const entityType = this.props.entity.entityName.toLowerCase().concat('s');
    translatedProperty.name = (
      <FormattedMessage
        id={`${entityType}.attributes.types.${property.name}`}
      />
    );
    return translatedProperty;
  };

  hasTransportAttributes = () => this.getModelOfData(this.state.newEntity).transports
    && this.getModelOfData(this.state.newEntity).transports.find(
      (t) => t.value === this.state.newEntity.transport,
    ).attributes;

  getHeaders = () => {
    const { newEntity } = this.state;
    const allProtocols = protocolData.protocol;

    const actualProtocol = this.getModelOfData(newEntity);
    const getUniqueProperties = this.getUniqueProperties(
      actualProtocol.attributes,
    );

    // Find headers
    const protocols = getUniqueProperties.map((a) => this.header.find((h) => h.key === a));

    // Add data to selects
    protocols.find((h) => h.type === 'select').data = allProtocols.find(
      (p) => p.value === newEntity.protocol,
    ).attributes.map(this.translateProperties);

    return protocols;
  };

  validate = () => {
    const { newEntity, orderData } = this.state;
    if (newEntity.allAttributes && newEntity.allAttributes.length === 0) {
      this.props.onError(<FormattedMessage id="Validation.atLeastOneAttribute" />);
    } else {
      !isMobile() ? this.props.onContinue({ ...newEntity, allAttributes: orderData }) : this.props.onContinue({ ...newEntity });
    }
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  handleOnChange = (data) => {
    const newEntity = { ...this.state.newEntity, allAttributes: data };
    this.setState({ newEntity, orderData: data });
  };

  handleOnChangeLayout = (data, orderData) => {
    if (JSON.stringify(orderData) !== JSON.stringify(this.state.orderData)) {
      this.setState({
        newEntity: { ...this.state.newEntity, attributes: [...data] },
        orderData,
      });
    }
  };

  handleOnChangeMobile = (data) => {
    const newEntity = {
      ...this.state.newEntity,
      ...data,
      ...this.parseEntity(data),
    };
    this.setState({
      newEntity,
    });
  };

  parseEntity = (template) => {
    const entity = { ...template };
    const attributes = template.attributes.map((o) => ({
      property: ATTRIBUTE,
      ...o,
    }));
    const lazy = template.lazy_attributes.map((o) => ({ property: LAZY, ...o }));
    const command = template.command_attributes.map((o) => ({
      property: COMMAND,
      ...o,
    }));
    const static_attribute = template.static_attributes.map((o) => ({
      property: STATIC,
      ...o,
    }));
    const internal_attribute = template.internal_attributes.map((o) => ({
      property: INTERNAL,
      ...o,
    }));

    entity.allAttributes = [
      ...attributes,
      ...lazy,
      ...command,
      ...static_attribute,
      ...internal_attribute,
    ];
    return entity;
  };

  render() {
    const { newEntity } = this.state;
    const entityType = this.props.entity.entityName.toLowerCase().concat('s');
    return (
      <>
        <Container>
          <Row>
            <Col lg={{ size: 12 }}>
              <BlanketCtxtProvider>
              {!isMobile() && (
                <EditableTable
                  ref={this.editableTable}
                  body={newEntity.allAttributes}
                  header={this.getHeaders()}
                  typeOfData={Attribute}
                  onChange={this.handleOnChange}
                  onChangeLayout={this.handleOnChangeLayout}
                  modelOfData={this.getModelOfData().attributes}
                  dataKey="property"
                />
              )}
              {isMobile() && (
                <EditProperties
                  data={this.state.newEntity}
                  entity={this.props.entity}
                  permissionToEdit
                  realTimeEnabled={false}
                  entityType={entityType}
                  onChange={this.handleOnChangeMobile}
                  saveOnTheFly={false}
                  intl={this.props.intl}
                />
              )}
              </BlanketCtxtProvider>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withWizardStep(injectIntl(AddProperties));
