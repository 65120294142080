/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { clone } from 'ramda';
import { isEqual } from 'lodash';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../../elements/Button';
import Field from '../../../../../elements/Field';

const getAvailableCommands = (sources, commands) => {
  const { sourcesId } = commands;
  const avaibleList = [];
  const availableCommands = [];
  sourcesId && sourcesId.forEach((id) => {
    const command = sources.find((source) => source.id === id)
      .commands.find((att) => att.name === commands.commandName).available_commands;
    if (!availableCommands[0]?.includes(command)) availableCommands.push(command);
  });
  if (availableCommands.length > 0) {
    availableCommands.forEach((ac) => ac.forEach((c) => {
      if (!avaibleList.find((al) => al.alias === c.alias && al.value === c.value)) {
        avaibleList.push(c);
      }
    }));
  }
  return avaibleList;
};

const ModalCommands = ({
  intl, getAllAttributes, state, toggleShowModal, acceptCommandsModal, sources, editGroupCommands,
  commandsGroup, handleCommandsChange, getAlias,
}) => {
  const menuRef = useRef(null);
  const [attributesToShow] = useState(getAllAttributes(state));
  const [localSubtitleList, setLocalSubtitleList] = useState([]);
  const [availableCommands, setAvailableCommands] = useState([]);
  const [localConfig, setLocalConfig] = useState({
    commands: [{
      commandName: '',
      sourcesId: [],
      value: '',
    }],
  });

  const [commands, setCommands] = useState(commandsGroup && commandsGroup);
  const [valueAlias, setValue] = useState('');
  const [localErrors, setLocalErrors] = useState({
    attribute: [],
    command: [],
  });
  const [attributeChanged, setAttributeChanged] = useState('');
  const [availableCommandsToAdd, setAvailableCommandsToAdd] = useState(
    getAvailableCommands(sources, commands),
  );

  useEffect(() => {
    if (commands.commandName) {
      setValue(getAlias(commands.commandName, commands.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeConfigModal = (name, value, index) => {
    const commandsToShow = [];
    const availableCommandsClone = clone(availableCommands);
    const arraySources = [];
    const localConfigClone = clone(localConfig);
    if (name === 'attribute') {
      sources.forEach((source) => {
        const command = source.commands.find((c) => c.name === value.name);
        if (command) {
          arraySources.push(source.id);
        }
      });

      if (editGroupCommands) {
        const localCommandsClone = clone(commands);
        localCommandsClone.commandName = value.name;
        localCommandsClone.sourcesId = arraySources;
        setAttributeChanged(value.name);
        setCommands(localCommandsClone);
      } else {
        localConfigClone.commands[index].commandName = value.name;
        localConfigClone.commands[index].value = '';
        setLocalConfig(localConfigClone);
        handleCommandsChange(attributesToShow);
      }
    }
    sources.forEach((source) => {
      const command = source.commands.filter((c) => c.name === value.name
        && c.available_commands.length);
      if (command?.length > 0) {
        command[0].available_commands.forEach((c) => {
          const found = commandsToShow.find((com) => isEqual(com, c));
          if (!found) commandsToShow.push(c);
        });
      }
    });
    if (availableCommandsClone[index - 1] && index !== 0) {
      if (localConfig.commands.length > availableCommands.length) {
        availableCommandsClone[index - 1] = commandsToShow;
      } else {
        availableCommandsClone[index] = commandsToShow;
      }
    } else if (localConfigClone.commands.length > availableCommandsClone.length) {
      availableCommandsClone.unshift(commandsToShow);
    } else {
      availableCommandsClone[index] = commandsToShow;
    }
    setAvailableCommandsToAdd(commandsToShow);
    setAvailableCommands(availableCommandsClone);
  };

  useEffect(() => {
    if (attributeChanged !== '') {
      setValue(getAlias(attributeChanged, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeChanged]);

  const handleChangeTextModal = (event, index) => {
    const { name } = event?.target;
    let value = event.target.value.includes('[') ? JSON.parse(event.target.value) : event.target.value;
    if (typeof (value) === 'object') value = value[0].value;
    if (name === 'command') {
      if (editGroupCommands) {
        const localCommandsClone = clone(commands);
        const command = availableCommandsToAdd.find((c) => c.name === value)?.value;
        localCommandsClone.value = command || value;
        setCommands(localCommandsClone);
      } else {
        const localConfigClone = clone(localConfig);
        const command = availableCommands.find((c) => c.name === value)?.value;
        localConfigClone.commands[index].value = command || value;
        setLocalConfig(localConfigClone);
      }
    }
  };

  const addCommand = (i) => {
    const configClone = clone(localConfig);
    const localErrorsClone = clone(localErrors);

    configClone.commands.forEach((c, index) => {
      if (index === i) {
        if (c.commandName === '') {
          localErrorsClone.attribute.push({
            index,
            error: true,
            message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.attribute" />],
          });
        } else {
          localErrorsClone.attribute = [];
        }

        if (c.value === '') {
          localErrorsClone.command.push({
            index,
            error: true,
            message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.command" />],
          });
        } else {
          localErrorsClone.command = [];
        }
      }
    });

    if (localErrorsClone.attribute.length < 1 && localErrorsClone.command.length < 1) {
      configClone.commands.unshift({
        commandName: '',
        sourcesId: [],
        value: '',
      });
    }
    setLocalErrors(localErrorsClone);
    setLocalConfig(configClone);
  };

  const removeCommand = (index) => {
    const configClone = clone(localConfig);
    configClone.commands.splice(index, 1);
    availableCommands.splice(index - 1, 1);
    setLocalConfig(configClone);
  };

  const getAvaibleCommandsList = (index) => {
    if (index === 0) return availableCommandsToAdd;
    if (localConfig.commands.length > availableCommands.length) return availableCommands[index - 1];
    if (localConfig.commands.length === availableCommands.length) return availableCommands[index];
    return [];
  };

  useEffect(() => {
    if (availableCommandsToAdd.length > 0 && !editGroupCommands) {
      if (localConfig.commands[0].commandName === '') setAvailableCommandsToAdd([{ name: '', value: '' }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localConfig]);

  useEffect(() => {
    const newList = clone(localSubtitleList);
    sources.forEach((source) => {
      source.commands.forEach((att) => {
        const index = attributesToShow.findIndex((at) => at.name === att.name);
        if (newList[index]) {
          newList[index].count += 1;
        } else {
          newList.push({ name: att.name, count: 1 });
        }
      });
    });
    setLocalSubtitleList(newList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources]);

  return (
    <div className="divCommandsModalWrapper">
      <div className="divModalCommands" ref={menuRef}>
        <Row className="w-100 p-2 modalContentOverflow">
          <Col sm={12}>
            <Row className="titleCommandsModal">
              <FormattedMessage id="widgets.wizard.sendCommand.modal.commandsInGroup" />
            </Row>
            <Row className="subtitleCommandsModal">
              <Col sm={12}>
                <FormattedMessage id="widgets.wizard.sendCommand.modal.selectCommandsInGroup" />
              </Col>
            </Row>
            {!editGroupCommands ? localConfig.commands.map((row, index) => (
              <Row className={`rowsCommandsModal ${index === 0 ? 'mb-4' : ''}`}>
                <Col sm={5}>
                  <span className="labelCommandGroupModal">
                    {(index === 0 && intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.modal.commandsInGroup',
                    })) || (index === 1 ? intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.modal.group',
                    }) : '')}

                  </span>
                  <Field
                    key={row.commandName}
                    name="attribute"
                    placeholder={intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.command.selector.placeholder',
                    })}
                    type="select"
                    options={attributesToShow && localSubtitleList.map((a) => (
                      {
                        value: a,
                        name: a.name,
                        subtitle: `–${a.count} ${intl.formatMessage({
                          id: 'widgets.wizard.sendCommand.command.selector.option.subtitle',
                        })}`,
                      }
                    ))}
                    helperText={localErrors?.attribute?.find((d) => d.index === index)?.message}
                    error={localErrors?.attribute?.find((d) => d.index === index)?.error}
                    onChange={(name, value) => handleChangeConfigModal(name, value, index)}
                    value={attributesToShow && localSubtitleList.find((a) => (
                      a.name === row.commandName
                    ))}
                  />
                </Col>
                <Col sm={5} className="commandCol">
                  <span className="labelCommandGroupModal">
                    {(index === 0 && intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.modal.addCommand',
                    })) || (index === 1 ? intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.modal.command',
                    }) : '')}
                  </span>
                  <Field
                    key={row.value}
                    name="command"
                    placeholder={intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.command.placeholder',
                    })}
                    type="autoComplete"
                    helperText={localErrors?.command?.find((d) => d.index === index)?.message}
                    error={localErrors?.command?.find((d) => d.index === index)?.error}
                    onChange={(id, value, e) => handleChangeTextModal(e, index)}
                    value={getAlias(row.commandName, row.value)}
                    availableCommands={getAvaibleCommandsList(index)}
                  />
                </Col>

                {(localConfig.commands.length > 1 && index > 0) && (
                <Col sm={1}>
                  <ButtonCustom
                    type="circular"
                    name="minus"
                    onClick={() => removeCommand(index)}
                  />
                </Col>
                )}

                {(index === 0) && (
                <Col sm={1}>
                  <ButtonCustom
                    type="circular"
                    name="plus"
                    onClick={() => addCommand(index)}
                  />
                </Col>
                )}
              </Row>
            )) : (
              <Row className="rowsCommandsModal">
                <Col sm={5}>
                  <span className="labelCommandGroupModal">
                    {intl.formatMessage({ id: 'widgets.wizard.sendCommand.modal.commandsInGroup' })}
                  </span>
                  <Field
                    key={1}
                    name="attribute"
                    placeholder={intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.command.selector.placeholder',
                    })}
                    type="select"
                    options={attributesToShow && localSubtitleList.map((a) => (
                      {
                        value: a,
                        name: a.name,
                        subtitle: `–${a.count} ${intl.formatMessage({
                          id: 'widgets.wizard.sendCommand.command.selector.option.subtitle',
                        })}`,
                      }
                    ))}
                    onChange={(name, value) => handleChangeConfigModal(name, value)}
                    value={attributesToShow && localSubtitleList.find((a) => (
                      a.name === commands.commandName
                    ))}
                  />
                </Col>
                <Col sm={5} className="commandCol">
                  <span className="labelCommandGroupModal">
                    {intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.modal.addCommand',
                    })}
                  </span>
                  <Field
                    key={2}
                    name="command"
                    placeholder={intl.formatMessage({
                      id: 'widgets.wizard.sendCommand.command.placeholder',
                    })}
                    type="autoComplete"
                    onChange={(id, value, e) => handleChangeTextModal(e)}
                    value={valueAlias}
                    isEdit
                    availableCommands={availableCommandsToAdd}
                  />
                </Col>
              </Row>
            )}
            <Col className="footer d-flex flex-column flex-md-row align-items-center justify-content-center">
              <ButtonCustom
                className="btn prevButton mr-3 secondaryCommandsModal"
                type="secondary"
                handleOnClick={toggleShowModal}
                label={<FormattedMessage id="widgets.wizard.sendCommand.modal.cancel" />}
              />
              <ButtonCustom
                className="btn nextButton primaryCommandsModal"
                type="primary"
                handleOnClick={() => (editGroupCommands ? acceptCommandsModal(commands,
                  state,
                  editGroupCommands,
                  toggleShowModal)
                  : acceptCommandsModal(localConfig, state)
                )}
                label={<FormattedMessage id="widgets.wizard.sendCommand.modal.accept" />}
              />
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

ModalCommands.defaultProps = {
  acceptCommandsModal: () => {},
  commandsGroup: [],
  sources: [],
  editGroupCommands: false,
  getAllAttributes: () => {},
  handleCommandsChange: () => {},
  intl: {},
  state: false,
  toggleShowModal: () => {},
};

export default injectIntl(ModalCommands);
