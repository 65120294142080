import React from 'react';
import { withTheme } from '../../../Contexts/ThemeContext';
import Table from '../../../components/Table';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import { connect } from 'react-redux';
import TabComponent from './../../../components/Tab';
import {
    getMineWidget,
    getPublicWidget
} from '../../../services/redux/widgets/actions';

import Widget, { SampleWidget } from '../../../models/Widget/index';
import OptionsForRows from './OptionsForRows';
import WithDrawer from '../../../components/DrawerContainer';
import CreateWidget from '../Add/AddWizard';
import RemoveHoc from '../../helpers/RemoveComponent';
import Profile from '../Profile';

const RemoveComponent = RemoveHoc(
    ({ showModal }) => (
        <i onClick={showModal} className="uil uil-trash-alt remove" />
    ),
    { class: Widget, nameData: 'widgets' }
);
const ProfileComponent = WithDrawer(({ onChange, data }) => (
    <Profile onChange={onChange} data={data} />
));
const headers = ['name', 'description', 'id'].map(index => ({
    label: SampleWidget.translations[index],
    key: index
}));
headers.unshift({ label: '', key: 'widgetAddOn', type: 'widgetAddOn' });

const TablePaginated = Pagination(({ data, ...rest }) => (<Table {...rest} body={data} />));

const FindableTabsComponent = Finder(props => {
    const {
        data,
        theme,
        itemsPerPage,
        page,
        optionsForRow,
        onPageChange,
        onSubmit,
        onRowClick,
        typeOfData,
        itemsShowed,
        total,
        ...rest
    } = props;

    return (
        <TabComponent {...rest}>
            <TablePaginated
                optionsForRow={optionsForRow}
                data={data}
                header={headers}
                align={'left'}
                filters={headers}
                onSubmit={onSubmit}
                onPageChange={onPageChange}
                itemsPerPage={itemsPerPage}
                page={page}
                total={total}
                expandable={true}
                itemsShowed={itemsShowed}
                typeOfData={typeOfData}
                selectable={true}
                onRemove={RemoveComponent}
                onRowClick={onRowClick}
            />
            <TablePaginated
                optionsForRow={optionsForRow}
                data={data}
                header={headers}
                align={'left'}
                filters={headers}
                onSubmit={onSubmit}
                onPageChange={onPageChange}
                itemsPerPage={itemsPerPage}
                page={page}
                total={total}
                expandable={true}
                itemsShowed={itemsShowed}
                typeOfData={'widgets'}
                selectable={true}
                onRemove={RemoveComponent}
                onRowClick={onRowClick}
            />
        </TabComponent>
    );
}, CreateWidget);

class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            itemsPerPage: 5,
            profileOpened: false,
            selectedtab: 0,
            itemsShowed: 5,
        };
    }

    componentDidMount() {
        const { page, itemsPerPage } = this.state;
        getPublicWidget({ filters: [], page, size: itemsPerPage });
    }

    handleOnSubmit = filters => {
        const { page, itemsPerPage } = this.state;
        getPublicWidget({ filters, page, size: itemsPerPage });
    };

    handleOnPageChange = (page, itemsPerPage, itemsShowed) => {

        if ((itemsShowed - itemsPerPage) < this.props.total) {
            getMineWidget({ page: page + 1, size: itemsPerPage });
            this.setState({
                page: page + 1,
                itemsPerPage: itemsPerPage,
                itemsShowed: itemsShowed
            });
        }
    };

    handleOnProfileEvent = opened => {
        this.setState({
            profileOpened: opened
        });
    };

    handleOpenProfile = data => {
        this.setState({
            profileOpened: true,
            activeWidget: data
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const { selectedtab, page, itemPerPage } = this.state;
        if (selectedtab !== prevState.selectedtab) {
            switch (selectedtab) {
                case 0:
                    getPublicWidget({ page, size: itemPerPage });
                    break;
                case 1:
                    getMineWidget({ page, size: itemPerPage });
                    break;
                default:
                    break;
            }
        }
    }

    handleOnTabsChange = selectedtab => {
        if (this.state.selectedtab !== selectedtab) {
            this.setState({
                selectedtab
            });
        }
    };

    render() {
        const { path, theme, widgets, fetching, total } = this.props;
        const {
            itemsPerPage,
            selectedtab,
            profileOpened,
            activeWidget,
            page,
            itemsShowed
        } = this.state;
        const widgetsByData = widgets.map(widget => widget.toJson());

        return (
            <>
                <FindableTabsComponent
                    titles={['Widgets', 'My widgets']}
                    onChange={this.handleOnTabsChange}
                    selectedtab={selectedtab}
                    fetching={fetching}
                    theme={theme}
                    data={widgetsByData}
                    optionsForRow={props => (
                        <OptionsForRows {...props} viewClick={this.handleOpenProfile} />
                    )}
                    page={page}
                    total={total}
                    expandable={true}
                    onSubmit={this.handleOnSubmit}
                    onPageChange={this.handleOnPageChange}
                    itemsShowed={itemsShowed}
                    itemsPerPage={itemsPerPage}
                    onRowClick={this.handleOpenProfile}
                    typeOfData={'widgets'}
                />
                {profileOpened && activeWidget && (
                    <ProfileComponent
                        onChange={this.handleOnProfileEvent}
                        data={activeWidget}
                        path={path}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    widgets: state.get('widgets').get('list').toJS(),
    total: state.get('widgets').get('total'),
    fetching: state.get('widgets').get('fetching')
});

export default connect(
    mapStateToProps,
    {}
)(withTheme(List));
