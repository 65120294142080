import React from 'react';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../Contexts/WizardStepContext';
import { RenderIcon } from '../../../pages/Widgets/Add/steps/WidgetType';

const RenderIconWithContext = ({ newEntity }) => (
  <>
    <span className="stepperSubtext">
      <FormattedMessage id={`widget.title.${newEntity.widgetType.toLowerCase()}`} />
    </span>
    <RenderIcon type={newEntity.widgetType} active />
  </>
);

export default withWizardStep(RenderIconWithContext);
