import React, {
  memo, useMemo, useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

const Table = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTable })),
);

function TableComponent(props) {
  const {
    values, widget, intl, handleOpenProfileSendingCommands, onChangeHistoricalConfig, sources,
  } = props;

  const Skeleton = useMemo(() => widget.skeletonComp, []);

  const localeTranslate = (key, defaultValue) => (
    intl ? intl.formatMessage({ id: key }) : defaultValue
  );

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    widget?.config?.attributeFilter,
    widget?.origins,
    widget.config?.conditions,
    widget.config?.colors,
    widget?.source,
  ]);

  if (values === undefined) {
    return <Skeleton />;
  }

  return (
    <>
      {sources.length && (
      <Table
        data={values}
        sendCommand={handleOpenProfileSendingCommands}
        config={widget.config}
        origins={sources}
        containerId={widget.container}
        onTranslate={localeTranslate}
      />
      )}
    </>
  );
}

export default memo(injectIntl(TableComponent));
