/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { isEqual } from 'lodash';
import WidgetSliderAttributes from './WidgetSliderAttributes';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import WidgetSliderPoints from './WidgetSliderPoints';
import { Components } from '../../../../../../models/WidgetV2/inherited/utilsConfigurationSheet';
import { getCommandAttributes } from '../../../../helpers/util';

const getCommandsGroup = (config) => {
  const { numberOfPoints } = config.conditions;
  const commandsGroup = { };
  for (let i = 0; i <= numberOfPoints.length; i += 1) {
    commandsGroup[i] = [];
  }
  if (numberOfPoints) {
    numberOfPoints.forEach((point, index) => {
      point.commands.forEach((c) => {
        if (c.groupId && point.state) commandsGroup[index + 1].push(c);
        if (c.groupId && !point.state) commandsGroup[index + 1].push(c);
      });
    });
  }
  return commandsGroup;
};

const putConfig = (config, commandsGroups, addCommand) => {
  const cloneConfig = clone(config);
  Object.keys(commandsGroups).forEach((stateCommand) => {
    Object.keys(commandsGroups[stateCommand]).forEach((command, index) => {
      const commandsGroupToAdd = commandsGroups[stateCommand][index];
      cloneConfig.conditions.numberOfPoints.forEach((point, i) => {
        const p = cloneConfig.conditions.numberOfPoints[i];
        if (stateCommand === p.state.toString()) {
          if (addCommand) {
            const commandFound = p.commands
              .find((com) => com.groupId === commandsGroupToAdd.groupId);
            if (!commandFound) {
              p.commands.push(commandsGroupToAdd);
            }
          } else {
            const indexGroup = p.commands
              .findIndex((c) => c.groupId === commandsGroupToAdd.groupId);
            if (commandsGroupToAdd.sourcesId.length > 0) {
              p.commands[indexGroup] = commandsGroupToAdd;
            } else p.commands.splice(indexGroup, 1);
          }
        }
      });
    });
  });
  return cloneConfig;
};

const getConfig = (config) => {
  const localC = clone(config);

  localC.conditions.numberOfPoints.forEach((p) => {
    if (!p.commands.filter((c) => !c.groupId).length > 0) {
      p.commands.push({
        commandName: '',
        sourcesId: [],
        value: '',
      });
    }
  });
  return localC;
};

const getAvailableCommands = (sources, points) => {
  const availableCommands = [];
  for (let i = 0; i <= points.length; i += 1) {
    availableCommands.push({
      state: i,
      commands: [],
    });
  }

  points.forEach((point) => {
    point.commands.forEach((com) => {
      if (!com.groupId) {
        com.sourcesId.forEach((id) => {
          const command = sources.find((source) => source.id === id)
            .commands.find((att) => att.name === com.commandName)?.available_commands;

          availableCommands.find((ac) => ac.state === point.state).commands.push(command);
        });
      }
    });
  });
  return availableCommands;
};

const CommandsSlider = (props) => {
  const { config, handler, sources } = props;
  const [commandsToShow, setCommandsToShow] = useState([]);
  const [localConfig, setLocalConfig] = useState(getConfig(config));
  const [availableCommands, setAvailableCommands] = useState(getAvailableCommands(
    sources,
    localConfig.conditions.numberOfPoints,
  ));
  const [localErrors, setLocalErrors] = useState({
    source: [],
    attribute: [],
    command: [],
  });
  const [allAttributes] = useState([]);
  const [commandsGroups, setCommandsGroups] = useState(getCommandsGroup(localConfig));

  const handleCommandGroup = (commands, state) => {
    const newList = clone(commandsGroups);
    sources.forEach((source) => {
      source.commands.forEach((att, index) => {
        const found = commands.find((at) => at.commandName === att.name);
        if (found) {
          let v = '';
          if (found.value.split(' ').length === 2) {
            const result = found.value.split(' ')[1];
            v = result;
          } else {
            v = found.value;
          }
          const obj = {
            commandName: found.commandName,
            sourcesId: [source.id],
            value: v,
            groupId: new Date().getTime() + index,
          };
          if (newList[state].length > 0
          && newList[state].find((c) => c.commandName === found.commandName
          && c.value === v)) {
            newList[state].find((c) => c.commandName === found.commandName
            && c.value === v).sourcesId.push(source.id);
          } else if (Object.keys(newList[state]).length > 0) {
            newList[state].push(obj);
          } else newList[state] = [obj];
        }
      });
    });
    setCommandsGroups(newList);
    const updatedConfig = putConfig(localConfig, newList, true);
    if (updatedConfig) {
      setLocalConfig(updatedConfig);
      handler({ name: 'conditions', value: updatedConfig.conditions }, undefined, Components.commandsSlider);
    }
  };

  const handleEditCommandsGroup = (point, state) => {
    const list = clone(commandsGroups);
    const index = list[state].findIndex((c) => c.groupId === point.groupId);
    if (index >= 0) {
      if (point.commandName !== list[state][index].commandName) {
        delete list[state][index];
        list[state][index] = point;
      } else list[state][index] = point;
    }
    setCommandsGroups(list);
    const updatedConfig = putConfig(localConfig, list, false);
    if (updatedConfig) {
      setLocalConfig(updatedConfig);
      handler({ name: 'conditions', value: updatedConfig.conditions }, undefined, Components.commandsSlider);
    }
  };

  const handleDeleteCommandsGroup = (group, state, index) => {
    const list = clone(commandsGroups);
    const configClone = clone(localConfig);
    let isRemovable = false;

    configClone.conditions.numberOfPoints.forEach((point) => {
      if (point.state === state) {
        if (point.commands.length > 1) isRemovable = true;
        const indexCommand = point.commands
          .findIndex((c) => c?.groupId === list[state][index].groupId);
        if (indexCommand || indexCommand === 0) point.commands.splice(indexCommand, 1);
      }
    });
    if (isRemovable) {
      list[state].splice(index, 1);
      setCommandsGroups(list);
    }
    setLocalConfig(configClone);
    handler({ name: 'conditions', value: configClone.conditions }, undefined, Components.commandsSlider);
  };

  // diferente
  const handleDeleteIndividualCommand = (point, state) => {
    const list = clone(commandsGroups);
    const index = list[state].findIndex((c) => c.groupId === point.groupId);
    if (index >= 0) {
      if (point.commandName !== list[state][index].commandName) {
        delete list[state][index];
        list[state][index] = point;
      } else list[state][index] = point;
    }
    setCommandsGroups(list);
    const updatedConfig = putConfig(localConfig, list, false);
    if (updatedConfig) {
      setLocalConfig(updatedConfig);
      handler({ name: 'conditions', value: updatedConfig.conditions }, undefined, Components.commandsOnOff);
    }
  };

  const validateConmmand = (i, state, addNewCommand, newConfig) => {
    const localErrorsClone = clone(localErrors);
    const configClone = newConfig || (localConfig);

    configClone.conditions.numberOfPoints.forEach((point) => {
      if (point.state === state) {
        point.commands.forEach((c, index) => {
          if (index === i) {
            if (c.sourcesId.length === 0) {
              localErrorsClone.source.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.source" />],
              });
            } else {
              localErrorsClone.source = [];
            }
            if (c.commandName === '') {
              localErrorsClone.attribute.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.attribute" />],
              });
            } else {
              localErrorsClone.attribute = [];
            }
            if (c.value === '') {
              localErrorsClone.command.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.command" />],
              });
            } else {
              localErrorsClone.command = [];
            }
          }
        });
        if (localErrorsClone.source.length < 1
          && localErrorsClone.command.length < 1
          && localErrorsClone.attribute.length < 1
          && addNewCommand) {
          point.commands.push({
            commandName: '',
            sourcesId: [],
            value: '',
          });
        }
      }
    });
    return { localConfig: configClone, errors: localErrorsClone };
  };

  const addCommand = (i, state, addNewCommand, configSlider) => {
    const validate = validateConmmand(i, state, addNewCommand, configSlider);
    setLocalErrors(validate.errors);
    setLocalConfig(validate.localConfig);
    if (validate.errors.attribute.length === 0
      && validate.errors.source.length === 0
      && validate.errors.command.length === 0) {
      handler({ name: 'conditions', value: localConfig.conditions }, undefined, Components.commandsSlider);
    }
  };

  const handleChange = (name, value, index, state, availableCommandsChange) => {
    const commands = value?.available_commands?.map((c) => c);
    const configClone = clone(localConfig);
    const commandsToShowClone = clone(commandsToShow);
    const availableCommandsClone = clone(availableCommands);
    if (name === 'device') {
      configClone.conditions.numberOfPoints.forEach((point) => {
        if (point.state === state) {
          const aux = point.commands.filter((com) => !com.groupId);
          aux[index].sourcesId = [value.id];
          aux[index].commandName = '';
          aux[index].value = '';
        }
      });
      commandsToShowClone[value.id] = value.commands;
      setCommandsToShow(commandsToShowClone);
    }
    if (name === 'attribute') {
      configClone.conditions.numberOfPoints.forEach((point) => {
        if (point.state === state) {
          const aux = point.commands.filter((com) => !com.groupId);
          aux[index].commandName = value.name;
          aux[index].value = '';
        }
      });
      const found = availableCommandsClone?.find((c) => c.state === state)?.commands[index];
      if (found && found !== undefined) {
        availableCommandsClone.find((c) => c.state === state).commands[index] = commands;
      } else availableCommandsClone.find((c) => c.state === state).commands.push(commands);
      setAvailableCommands(availableCommandsClone);
    }
    if (name === 'command') {
      if (value !== '') {
        let indexOfCommand = 0;
        let isAdd = false;
        let val = value;
        availableCommandsChange.forEach((ac) => {
          if (ac.state === state) {
            const alias = ac.commands[index].find((c) => c.name === value);
            if (alias) val = alias.value;
          }
        });
        configClone.conditions.numberOfPoints.forEach((point) => {
          if (point.state === state) {
            const aux = point.commands.filter((com) => !com.groupId);
            aux[index].value = val;
            if (aux[index].commandName !== ''
                && aux[index].value !== ''
                && aux[index].sourcesId.length) {
              indexOfCommand = index;
              isAdd = true;
            }
          }
        });
        if (isAdd) addCommand(indexOfCommand, state, false, configClone);
      }
    }
    setLocalConfig(configClone);
  };

  const removeCommand = (state, index) => {
    const configClone = clone(localConfig);
    configClone.conditions.numberOfPoints.forEach((point) => {
      if (point.state === state) {
        const simpleCommand = point.commands.filter((com) => !com.groupId)[index];
        const i = point.commands.findIndex((c) => c.commandName === simpleCommand.commandName
        && c.value === simpleCommand.value && isEqual(c.sourcesId, simpleCommand.sourcesId));
        point.commands.splice(i, 1);
      }
    });
    setLocalConfig(configClone);
    handler({ name: 'conditions', value: configClone.conditions }, undefined, Components.commandsSlider);
  };

  const handleNumberOfPoints = (points) => {
    const configClone = clone(localConfig);
    const numberOfPoints = configClone.conditions.numberOfPoints.length;
    const difference = points - numberOfPoints;
    if (difference > 0) {
      for (let i = 1; i <= difference; i += 1) {
        configClone.conditions.numberOfPoints.push(
          {
            state: numberOfPoints + i,
            alias: `${numberOfPoints + i}`,
            commands: [
              {
                commandName: '',
                sourcesId: [],
                value: '',
              },
            ],
          },
        );
        availableCommands.push({
          state: configClone.conditions.numberOfPoints.length,
          commands: [],
        });
      }
    } else if (difference < 0) {
      const indexOfRemove = numberOfPoints + difference;
      const positiveDifference = difference * -1;
      configClone.conditions.numberOfPoints.splice(indexOfRemove, positiveDifference);
      availableCommands.splice(indexOfRemove, positiveDifference);
    }
    setLocalConfig(configClone);
    setCommandsGroups(getCommandsGroup(configClone));
    handler({ name: 'conditions', value: configClone.conditions }, undefined, Components.commandsSlider);
  };

  const handleAlias = (value, point) => {
    const configClone = clone(localConfig);
    config.conditions.numberOfPoints.forEach((pt, i) => {
      const p = configClone.conditions.numberOfPoints[i];
      if (p.state === point) {
        p.alias = value;
      }
    });
    setLocalConfig(configClone);
    handler({ name: 'conditions', value: configClone.conditions }, undefined, Components.commandsSlider);
  };
  return (
    <div className="wizardWrapper addWidget">
      {localConfig
       && (
       <WidgetSliderPoints
         config={localConfig}
         handleNumberOfPoints={handleNumberOfPoints}
         isProfile
       />
       )}
      {localConfig.conditions.numberOfPoints.map((statePoint, index) => (
        <WidgetSliderAttributes
          key={`att${statePoint.state}`}
          handleCommandGroup={handleCommandGroup}
          sources={sources}
          statePoint={statePoint}
          config={localConfig}
          handleChange={handleChange}
          commandsToShow={commandsToShow}
          addCommand={addCommand}
          availableCommands={availableCommands}
          errors={localErrors}
          removeCommand={removeCommand}
          getAllAttributes={() => getCommandAttributes(sources, allAttributes)}
          commandsGroups={commandsGroups}
          handleEditCommandsGroup={handleEditCommandsGroup}
          handleDeleteCommandsGroup={handleDeleteCommandsGroup}
          handleAlias={handleAlias}
          handleDeleteIndividualCommand={handleDeleteIndividualCommand}
          stateIndex={index}
          isProfile
        />
      ))}
      {(!localConfig?.conditions?.numberOfPoints[0]?.commands?.length
      || !localConfig?.conditions?.numberOfPoints[1]?.commands?.length)
          && (
          <div className="validation m-auto">
            <p className="validationError">
              <FormattedMessage
                id="widgetOnOff.profile.validation.error"
              />
            </p>
          </div>
          )}
    </div>
  );
};

export default injectIntl(CommandsSlider);
