/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import Faker from 'faker';
import { injectIntl } from 'react-intl';
import echarts from 'echarts/lib/echarts';
import DragAndDropGrid from '../../../components/DragAndDropGrid';
import ModalAddDashboard from '../../../components/ModalAddDashboard';
import {dataTestBar, dataTestBarV2} from './dataBar';
import { configTestTable, dataTestTable, originsTestTable } from './dataTable';
import DashboardMenuTittle from './components/DashboardMenuTittle';
import WidgetMenuButton from './components/WidgetMenuButton';
import WidgetMap from '../../../components/WidgetMap';
import { withTheme } from '../../../Contexts/ThemeContext';
import WidgetCard from '../../../elements/WidgetCard/WidgetCard';
import { data } from './data';
import { generateSerie } from './dataLine';
import theme from '../../../configuration/echarts/fiwoo-echart-theme';
import colors from '../../../configuration/colors';
import { typography } from '../../../configuration/fonts';
import {isShowV2} from "../../../helpers/utils";

const Percentage = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetPercentage })),
);
const Needle = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetNeedle })),
);
const LineChart = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetLineChart })),
);

const Kpi = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTextNumber })),
);

const Table = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTable })),
);

const BarChart = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetBarChart })),
);
const Divcontainer = Styled('div')`

margin-left: 150px;
width: 65%!important;
`;

const actions = <WidgetMenuButton />;
const MapActions = (props) => <WidgetMenuButton {...props} />;

echarts.registerTheme('fiwoo', {
  ...theme,
});

const configTheme = {
  name: 'fiwoo',
  colors,
  typography,
};

export const getWidget = (widget, localeTranslate) => {
  switch (widget.widgetType) {
    case 'BARS':
      return (
        <WidgetCard className="d-block" title={widget.name} actions={actions}>
          <BarChart
            details={isShowV2() ? dataTestBarV2 : dataTestBar}
            onTranslate={localeTranslate}
            height="100%"
            theme={configTheme}
            title={widget.name}
            actions={actions}
            config={widget.config}
          />
        </WidgetCard>
      );

    case 'LINES':
      return (
        <WidgetCard className="d-block" title={widget.name} actions={actions}>
          <LineChart
            onTranslate={localeTranslate}
            series={generateSerie(5)}
            height="100%"
            config={widget.config}
            theme={configTheme}
          />
        </WidgetCard>
      );

    case 'NEEDLE':
      return (
        <WidgetCard title={widget.name} actions={actions}>
          <Needle
            config={widget.config}
            value={Faker.random.number({ min: 0, max: 800 })}
            widget={widget}
          />
        </WidgetCard>
      );

    case 'PERCENTAGE':
      return (
        <WidgetCard title={widget.name} actions={actions}>
          <Percentage
            value={Faker.random.number({ min: 0, max: 100 })}
            config={widget.config}
          />
        </WidgetCard>
      );

    case 'TEXT_ACCOUNTANT':
      return (
        <WidgetCard className="d-block" title={widget.name} actions={actions}>
          <Kpi
            text={widget.name}
            number={[Faker.random.number({ min: 0, max: 100 }).toString(), { series: generateSerie(1) }]}
            onTranslate={localeTranslate}
            deviceCategory={['OTHER']}
            config={widget.config}
          />
        </WidgetCard>
      );

    case 'MAP':
      return (
        <WidgetMap
          title={widget.name}
          actions={(props) => <MapActions {...props} />}
          data={[]}
          config={widget.config}
        />
      );

    case 'HEATMAP':
      return (
        <WidgetMap
          title={widget.name}
          actions={(props) => <MapActions {...props} />}
          data={[]}
          config={widget.config}
        />
      );

    case 'TABLE':
      return (
        <WidgetCard title={widget.name} actions={actions}>
          <Table
            onTranslate={localeTranslate}
            config={configTestTable}
            data={dataTestTable}
            origins={originsTestTable}
            containerId={'parentTestId'}
          />
        </WidgetCard>
      );

    default:
      return <WidgetCard title={widget.name}>DEFAULT</WidgetCard>;
  }
};
class ShowThumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowingWidgetModal: false,
    };
  }

  localeTranslate = (key, defaultValue) => {
    const { intl } = this.props;
    return intl ? intl.formatMessage({ id: key }) : defaultValue;
  };

  renderWidget = (widget) => <div key={widget.id}>{getWidget(widget, this.localeTranslate)}</div>;

  render() {
    let { dashboardThumbnail } = this.props;
    const { isShowingWidgetModal } = this.state;

    if (!dashboardThumbnail) {
      dashboardThumbnail = data;
    }

    return (
      <>
        <Divcontainer>
          <DashboardMenuTittle
            title={
              dashboardThumbnail !== undefined ? dashboardThumbnail.name : ''
            }
          />

          <section>
            <Suspense fallback="Loading">
              {dashboardThumbnail && (
                <DragAndDropGrid
                  layouts={dashboardThumbnail.layoutProperties}
                  isDraggable={false}
                  breakpoints={{
                    lg: 10000,
                    md: 9990,
                    sm: 0,
                    xs: -100,
                    xxs: -2000,
                  }}
                  isResizable={false}
                >

                  {dashboardThumbnail.widgets.map((w) => this.renderWidget(w))}

                </DragAndDropGrid>
              )}
              <ModalAddDashboard toggle={isShowingWidgetModal} />
            </Suspense>
          </section>
        </Divcontainer>
      </>
    );
  }
}

ShowThumbnail.propTypes = {
  dashboardThumbnail: PropTypes.objectOf(PropTypes.any),
  intl: PropTypes.objectOf(PropTypes.any),
};

ShowThumbnail.defaultProps = {
  dashboardThumbnail: {},
  intl: undefined,
};

export default injectIntl(withTheme(ShowThumbnail));
