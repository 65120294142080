/* eslint-disable camelcase */
/** Initialization from react-intl for every lang */
import locale_es from 'react-intl/locale-data/es';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';

export default [
  ...locale_de,
  ...locale_en,
  ...locale_es,
];
