import city from './icons/svg/widgets/image/city.jpg';
import people from './icons/svg/widgets/image/people.jpg';
import forest from './icons/svg/widgets/image/forest.jpg';
import road from './icons/svg/widgets/image/road.jpg';
import particles from './icons/svg/widgets/image/particles.jpg';
import cloud from './icons/svg/widgets/image/cloud.jpg';
import seville from './icons/svg/widgets/image/seville.jpg';
import alcazar from './icons/svg/widgets/image/alcazar.jpg';

export const navBarHeight = 72;
export const navBarHeightForMobile = 48;
export const sampling = ['lastHour', 'lastDay', 'lastFifteenDays',
  'lastMonth', 'lastThreeMonth', 'lastYear'];
export const operations = ['avg', 'max', 'min'];
export const planners = ['manually', 'minutes', 'hours', 'daily', 'weekly', 'monthly'];
export const colorList = ['grey', 'purple', 'blue', 'cyan', 'green', 'yellow', 'red'];
export const imageList = [city, forest, people, road, particles, cloud, seville, alcazar];
export const typesOfNumericAttributes = ['number', 'float', 'double', 'integer', 'int'];
