import React from 'react';
import { previousPath, cleanLastSlash } from '../helpers';
import { navigate } from '@reach/router';
import * as R from 'ramda';

class CommonView extends React.Component {
  constructor(obj) {
    super(obj.props);
    const { path } = this.props;
    const backRouteNum = obj.backRouteNum || 1;
    this.previousPath = previousPath(path, backRouteNum);
  }

  componentWillMount() {
    this.setState({
      errors: {}
    });
  }

  getPath(optionalLastBread = null) {
    const { path } = this.props;
    if (optionalLastBread) {
      let path2 = cleanLastSlash(path).split('/');
      path2[path2.length - 1] = optionalLastBread;
      return path2.join('/');
    }
    return path;
  }

  handleCancel = e => this.goBack(e);

  goBack = e => {
    if (e) {
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
    }
    navigate(this.previousPath);
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.handleFieldChange(name, value);
  };

  handleSelectChange = (name, value) => {
    this.handleFieldChange(name, value);
  };

  handleFieldChange = (name, value) => {
    if (!R.equals(this.state[name], value) && name !== 'public') {
      this.setState({ [name]: value });
    }
  };

  setErrors(errors) {
    let errorReg = {};
    const form = Object.assign({}, this.state);
    delete form.errors;
    Object.keys(form).forEach(key => {
      if (errors[key]) errorReg[key] = errors[key];
    });
    this.setState({
      errors: errorReg
    });
  }

  onSave = e => { };

  onCancel = e => this.setState({ [e.name]: e.value });
}

export default CommonView;
