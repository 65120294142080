import validate from 'validate.js';
import { clone } from 'ramda';
// eslint-disable-next-line import/no-cycle
import {
  addRole, updateRole, deleteRole, getRole, getRolePermissions,
} from '../../services/redux/roles/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';

/** Class Role with Utils functions */
export default class Role extends Utils {
    static entityName = 'Role';

    constructor(obj = {}) {
      /** Send to utils class the Model and the object passed for build this class */
      super(ModelExpected, obj);
      this.Model = Role;
    }

    /** Call Redux action for Save Role in DB and Redux */
    save() {
      const filteredConstraints = clone(this.constraints);
      delete filteredConstraints.id;
      const validation = validate(this.getData(), filteredConstraints);
      return validation === undefined ? addRole({
        ...this.getData(), permissions: this.permissions.map((per) => per.id),
      }) : { error: true, ...validation };
    }

    /** Call Redux action for Update Role in DB and Redux */
    update() {
      const validation = validate(this.getData(), this.constraints);
      return validation === undefined ? updateRole({
        ...this.getData(), permissions: this.permissions.map((per) => per.id),
      }) : { error: true, ...validation };
    }

    delete() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? deleteRole(this.getData()) : { error: true, ...validation };
    }

    get() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getRole(this.getData()) : { error: true, ...validation };
    }

    getRolePermissions() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getRolePermissions(this.getData()) : {
        error: true, ...validation,
      };
    }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('Role', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleRole = new Role();
export { SampleRole };
