import React from 'react';
import Field from '../../../../elements/Field';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'
import CommonView from '../../../CommonView';
import User, { SampleUser } from '../../../../models/User/index';
import { Footer } from '.';
import ChangePassword from './ChangePassword';

class Info extends CommonView {
  constructor(props) {
    super({ props: props });
    this.state = {
      errors: {},
      original: props.data,
      user: new User({ ...props.data }),
      onEditPassword: false
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const user = { ...this.state.user };
    user[name] = value;

    this.setState({
      user,
    })

  };

  handleOnEdit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setErrors({});

    const NewUser = new User({ ...this.state.original, ...this.state.user });

    if (
      JSON.stringify(new User(this.state.original).getData()) !==
      JSON.stringify(NewUser.getData())
    ) {
      const updated = NewUser.update();
      if (updated.error) {
        delete updated.error;
        this.setState({
          errors: updated
        })
      } else {
        this.props.changeMode();
        this.props.succeed(NewUser);
      }
    } else {
      this.props.changeMode();
    }
  };

  handleOnSave = ({ name, value }) => {
    const user = this.state.user;
    user[name] = value;
    this.setState({
      user
    });
  };

  handleOnMultiSelectSave = (name, value) => {
    const user = this.state.user;
    user[name] = value;
    this.setState({
      user
    });
  };

  componentDidUpdate() {
    if (this.props.data !== this.state.original) {
      this.setState({
        original: this.props.data,
        user: new User({ ...this.props.data })
      });
    }
  }

  render() {
    const { edit, intl, enableChangePassword } = this.props;
    const { errors, user, onEditPassword } = this.state;

    return (
      <div className="tabSection">
        {edit &&
          ['name', 'surname', 'email'].map((key, i) => (
            <div className="fieldGroup" key={i}>
              <Field
                name={key}
                label={intl.formatMessage(SampleUser.plainTranslations[key])}
                placeholder={intl.formatMessage(
                  SampleUser.plainTranslations[key]
                )}
                id={key}
                value={user[key]}
                type={key === 'email' ? 'email' : 'text'}
                onChange={this.handleInputChange}
                helperText={errors[key]}
                error={!!errors[key]}
                key={`${key}-${i}`}
                mode={!!errors[key] ? 'edit' : 'view'}
                save={this.handleOnSave}
              />
              <i className="uil uil-edit-alt"></i>
            </div>
          ))}
        {edit &&
          ['roles' /*'groups'*/].map((key, i) => (
            <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'add-role'}>
              <div className="fieldGroup" key={i}>
                <Field
                  name={key}
                  id={key}
                  label={intl.formatMessage(SampleUser.plainTranslations[key])}
                  type={'multiselect'}
                  value={user[key]}
                  data={this.props[`allowed${key}`]}
                  mappedBy={'name'}
                  equalsBy={'id'}
                  placeholder={intl.formatMessage({
                    id: `user.finder.${key}.placeholder`
                  })}
                  selectedOptions={user.roles}
                  onChange={this.handleOnMultiSelectSave}
                  footer={Footer(this.props.hideModal)}
                  key={`${key}-${i}`}
                  mode={'view'}
                  save={this.handleOnMultiSelectSave}
                />
                <i className="uil uil-edit-alt"></i>
              </div>
            </PrivateComponent>

          ))}
        {!edit && (
          <>
            <dt>{intl.formatMessage(SampleUser.plainTranslations.email)}</dt>
            <dd>{user.email}</dd>
            <dt> {intl.formatMessage(SampleUser.plainTranslations.roles)}</dt>
            <dd>
              {user.roles &&
                user.roles.map((rol, i) =>
                  i !== user.roles.length - 1 ? rol.name.concat(', ') : rol.name
                )}{' '}
            </dd>
            {enableChangePassword && <ChangePassword user={user} onEditPassword={onEditPassword} />}
          </>
        )}
      </div>
    );
  }
}

export default Info;
