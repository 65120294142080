import { takeLatest, takeEvery, call, put, all, fork } from 'redux-saga/effects';
import Role from '../../../models/Role'
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/roles'

/**
 * Actions for WATCHERS
 * */

// Try to recover all role form DB
export function* getRolesThroughApi() {
    try {
        const response = yield call(Api.readRoles);

        if (response.status === 200) {
            const roles = response.data.objects.map(role => new Role(role));
            yield put({ type: TYPES.GET_ROLES_SUCCESS, payload: roles });
        }
        else {
            yield put({ type: TYPES.GET_ROLES_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
        }
    }
    catch (error) {
        yield put({ type: TYPES.GET_ROLES_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
    }
}

// Try to recover a role form DB
export function* getRoleThroughApi({ payload }) {
    try {
        const response = yield call(Api.readRole, payload);

        if (response.status === 200) {
            const roleFromDB = new Role(response.data.object);
            yield put({ type: TYPES.GET_ROLE_SUCCESS, payload: roleFromDB });
        }
        else {
            yield put({ type: TYPES.GET_ROLE_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
        }
    }
    catch (error) {
        yield put({ type: TYPES.GET_ROLE_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
    }
}

// Try to recover a role permissions form DB
export function* getRolePermissionsThroughApi({ payload }) {
    try {
        const response = yield call(Api.readRolePermissions, payload);

        if (response.status === 200) {
            const permissions = response.data.objects.map(per => per);
            payload.permissions = permissions;
            yield put({ type: TYPES.GET_ROLE_PERMISSIONS_SUCCESS, payload });
        }
        else {
            yield put({ type: TYPES.GET_ROLE_PERMISSIONS_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.GET_ROLE_PERMISSIONS_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
    }
}

// Try to create role in DB
export function* addRolesThroughApi({ payload }) {
    try {
        const response = yield call(Api.addRole, payload);
        if (response.status === 201) {

            const roleFromDB = new Role(response.data.object);
            yield put({ type: TYPES.ADD_ROLE_SUCCESS, payload: roleFromDB });
        }
        else {
            yield put({ type: TYPES.ADD_ROLE_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.ADD_ROLE_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

    }
}

// Try to update role in DB
export function* updateRolesThroughApi({ payload }) {
    try {
        const response = yield call(Api.updateRole, payload);

        if (response.status === 200) {
            yield put({ type: TYPES.MODIFY_ROLE_SUCCESS, payload: new Role({ ...response.data.object }) })
        }
        else {
            yield put({ type: TYPES.MODIFY_ROLE_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.MODIFY_ROLE_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

    }
}

// Try to delete role in DB
export function* deleteRolesThroughApi({ payload }) {
    try {
        const response = yield call(Api.deleteRole, payload);

        if (response.status === 204) yield put({ type: TYPES.DELETE_ROLE_SUCCESS, payload });
        else {
            yield put({ type: TYPES.DELETE_ROLE_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.DELETE_ROLE_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

    }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_ROLES_REQUEST
export function* watcherGetRoles() {
    yield takeLatest('GET_ROLES_REQUEST', getRolesThroughApi);
}

// Watcher looking for GET_ROLE_REQUEST
export function* watcherGetRole() {
    yield takeLatest('GET_ROLE_REQUEST', getRoleThroughApi);
}
// Watcher looking for GET_ROLE_REQUEST
export function* watcherGetRolePermissions() {
    yield takeLatest('GET_ROLE_PERMISSIONS_REQUEST', getRolePermissionsThroughApi);
}

// Watcher looking for ADD_ROLE_REQUEST
function* watcherAddRoles() {
    yield takeEvery('ADD_ROLE_REQUEST', addRolesThroughApi);
}

// Watcher looking for MODIFY_ROLE_REQUEST
function* watcherUpdateRoles() {
    yield takeLatest('MODIFY_ROLE_REQUEST', updateRolesThroughApi);
}

// Watcher looking for DELETE_ROLE_REQUEST
function* watcherDeleteRoles() {
    yield takeEvery('DELETE_ROLE_REQUEST', deleteRolesThroughApi);
}

// Export all together
export default function* rootSaga() {
    yield all([
        fork(watcherGetRoles),
        fork(watcherGetRole),
        fork(watcherGetRolePermissions),
        fork(watcherAddRoles),
        fork(watcherUpdateRoles),
        fork(watcherDeleteRoles),
    ])
}

