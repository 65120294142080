import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
  },
  {
    key: 'api_key',
    defaultValue: null,
    constraints: {
      length: {
        minimum: 3,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '3' }}
          />
        ),
      },
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: (<FormattedMessage
          id="Validation.presence"
        />),
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'description',
    defaultValue: '',
  },
  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
    constraints: {
      presence: true,
    },
  },
  {
    key: 'owner',
    defaultValue: '',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'entity_type',
    defaultValue: 'template',
    constraints: {
      presence: {
        allowEmpty: true,
        message: (<FormattedMessage
          id="Validation.presence"
        />),
      },
      length: {
        minimum: 3,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '3' }}
          />
        ),
      },
      format: {
        pattern: '[a-zA-Z]+',
        flags: 'i',
        message: (
          <FormattedMessage
            id="Validation.noSpecialCharacter.noNumber.noSpace"
          />
        ),
      },
    },
  },
  {
    key: 'categories',
    defaultValue: ['OTHER'],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'fiware',
    defaultValue: {},
  },
  {
    key: 'organization',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 24,
        maximum: 32,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '24' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '32' }}
          />
        ),
      },
    },
  },
  {
    key: 'dataset',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 24,
        maximum: 32,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '24' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '32' }}
          />
        ),
      },
    },
  },
  {
    key: 'transport',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            id="Validation.presence"
          />
        ),
      },
    },
  },
  {
    key: 'attributes',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'lazy_attributes',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'command_attributes',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'static_attributes',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'internal_attributes',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'protocol',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            id="Validation.presence"
          />
        ),
      },
    },
  },
  {
    key: 'createdAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'updatedAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'extraConfigurations',
    defaultValue: {},
  },
  {
    key: 'allAttributes',
    defaultValue: {},
  },
];

export default ModelExpected;
