import React from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import ContentEditable from '../../../../../helpers/ContentEditable/ContentEditable';

const ButtonPointSlider = ({
  statePoint, onChangeAlias, showRow, handleShowRow, alias, commandsNumber,
}) => (
  <Col sm={12}>
    <Row>
      <Col sm={12} className="pl-4 align-content-start">
        <div>
          <span className="titlePoint">
            <FormattedMessage id="widgets.wizard.slider.point" />
            {statePoint.state}
          </span>
        </div>
        <div className="pt-1 row">
          <div onClick={handleShowRow}>
            <i className={`uil uil-angle-${showRow ? 'up' : 'down'} i`} />
          </div>
          <ContentEditable
            className="labelFocus mt-2"
            tag="at"
            onBlur={onChangeAlias}
            id={alias}
            editable
          >
            {alias}
          </ContentEditable>

          {!showRow
            && (
            <Col sm={8} className="ml-3">
              <div className="foldedCommands">
                <p className="commandGroupLine">
                  <span className="commandLabelList">
                    <FormattedMessage id="widgets.wizard.sendCommand.listCommands" />
                  </span>
                  <span className="ml-2 commandNumberOfCommands">
                    {commandsNumber()}
                  </span>
                </p>
              </div>
            </Col>
            )}
        </div>

      </Col>
    </Row>
  </Col>
);
export default injectIntl(ButtonPointSlider);
