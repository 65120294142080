import React from 'react';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext/index';
import { injectIntl } from 'react-intl';
import User from '../../../../../models/User/index';
import { getRoles } from '../../../../../services/redux/roles/actions';
import {
  addUserSuccessFlag,
  errorFetchingUserReset
} from '../../../../../services/redux/users/actions';
import Field from '../../../../../elements/Field/index';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CommonView from '../../../../CommonView';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { navigate } from '@reach/router';
import CreateRole from '../../../Roles/Add/AddWizard';
import { withWizardStep } from '../../../../../Contexts/WizardStepContext';


const Footer = (hideModal) =>
  <>
    <p className={'d-none d-md-block'}><FormattedMessage
      id="users.createRol.text"
      defaultMessage="Want to create a new role?"
      description="Text for Want to create a new role?"
    /></p>

    <CreateRole fromAnotherComponent={true} />

  </>;

const Form = styled.form`
  padding: 54px 0px 0px 0px;
`;


export class Add extends CommonView {
  constructor(props) {
    super({ props: props });

    this.state = {
      name: this.props.name,
      surname: this.props.surname,
      email: this.props.email,
      roles: Array.isArray(this.props.roles) ? this.props.roles : [],
      ///groups: this.props.groups, TODO
      errors: this.props.errors,
      onValidation: false
    };
  }

  static propTypes = {
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.array,
    //groups: PropTypes.array,
    errors: PropTypes.object
  };

  static defaultProps = {
    name: '',
    surname: '',
    email: '',
    roles: [],
    //groups: [],
    allowedroles: [],
    errors: {}
  };

  validate = () => {
    this.saveUser();
  };

  saveUser = () => {
    this.setErrors({});

    const obj = this.state;
    delete obj.id;
    const NewUser = new User(obj);
    const created = NewUser.save();

    if (created.error) {
      delete created.error;
      this.setErrors(created);
      this.props.onError(created.error);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach(err => {
          if (err.field) {
            errors[err.field] = <FormattedMessage id={err.message} />;
          } else {
            let errField = err.type==="EmailIsAlreadyInUseInIdmError"?"email":err.type;
            errors[errField] = <FormattedMessage id={err.type} />;
          }
        });
        this.setErrors(errors);
        errorFetchingUserReset();
      }
    }

    if (this.props.addSuccess) {
      addUserSuccessFlag();
      //this.goBack()
      const userCreated = new User({
        ...this.props.created,
        password: this.props.newPassword
      });
      this.props.onContinue(userCreated);
    }
  }

  componentDidMount() {
    this.props.getRoles();

    this.props.onRef(this);
  }

  render() {
    const { intl } = this.props;
    const { errors } = this.state;

    return (
      <>
        <Container className="createUser">
          <Form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md={6}>
                {['name', 'surname', 'email'].map((key, i) => (
                  <Field
                    name={key}
                    label={intl.formatMessage({ id: `User.model.${key}` })}
                    placeholder={intl.formatMessage({
                      id: `User.model.${key}`
                    })}
                    id={key}
                    value={this.state[key]}
                    type={key === 'email' ? 'email' : 'text'}
                    onChange={this.handleInputChange}
                    helperText={errors[key]}
                    error={!!errors[key]}
                    key={`${key}-${i}`}
                  />
                ))}
              </Col>
              <Col md={6}>
                {['roles' /*'groups'*/].map((key, i) => (
                  <Field
                    name={key}
                    id={key}
                    label={intl.formatMessage({ id: `User.model.${key}` })}
                    type={'multiselect'}
                    value={this.state[key]}
                    data={this.props[`allowed${key}`]}
                    mappedBy={'name'}
                    equalsBy={'id'}
                    placeholder={intl.formatMessage({
                      id: `user.finder.${key}.placeholder`
                    })}
                    selectedOptions={this.state[key]}
                    onChange={this.handleSelectChange}
                    footer={Footer(this.props.hideModal)}
                    key={`${key}-${i}`}
                    helperText={errors[key]}
                    error={!!errors[key]}
                  />
                ))}
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  allowedroles: state
    .get('roles')
    .get('list')
    .toJS(),
  errorFetching: state.get('users').get('errorFetching'),
  addSuccess: state.get('users').get('addSuccess'),
  created: state.get('users').get('createdUser'),
  newPassword: state.get('users').get('newPassword'),
  notify: state.get('notify')
  //allowedgroups: ['Group1', 'Group2', 'Group3', 'Group4']
});

export default connect(
  mapStateToProps,
  { getRoles }
)(withWizardStep(injectIntl(Add)));
