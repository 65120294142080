import * as TYPES from './types';
import { store } from '../store';

export const addMenuElement = (MenuElement) => store.dispatch({
  type: TYPES.ADD_MENUELEMENT_REQUEST,
  payload: MenuElement,
});

export const updateMenuElement = MenuElement => store.dispatch({
  type: TYPES.MODIFY_MENUELEMENT_REQUEST,
  payload: MenuElement
});

export const updatePositionElement = MenuElements => store.dispatch({
  type: TYPES.MODIFY_POSITION_ELEMENT_REQUEST,
  payload: MenuElements
});

export const deleteMenuElement = (MenuElement) => store.dispatch({
  type: TYPES.DELETE_MENUELEMENT_REQUEST,
  payload: MenuElement,
});

export const getTree = (payload) => store.dispatch({
  type: TYPES.GET_TREE_REQUEST,
  payload: payload
});

export const getMenuElement = (MenuElement) => store.dispatch({
  type: TYPES.GET_MENUELEMENT_REQUEST,
  payload: MenuElement,
});

export const clearMenuElements = () => store.dispatch({
  type: TYPES.CLEAR_MENUELEMENTS
});

export const updateMenuElementsState = payload =>
  store.dispatch({
    type: TYPES.UPDATE_MENUELEMENTS_STATE,
    payload: payload
  });

export const addMenuElementInSelectedState = payload =>
  store.dispatch({
    type: TYPES.ADD_MENUELEMENT_SELECTED_STATE,
    payload: payload
  });

export const updateMenuElementsSelectedState = payload =>
  store.dispatch({
    type: TYPES.UPDATE_MENUELEMENTS_SELECTED_STATE,
    payload: payload
  });

export const clearMenuElementsSelectedState = () =>
  store.dispatch({
    type: TYPES.CLEAR_MENUELEMENTS_SELECTED_STATE,
  });

export const hiddenSubmenu = (payload) => {
  store.dispatch({
    type: TYPES.HIDDEN_SUBMENU,
    payload: payload
  })
};

export const getSettingsMenu = () =>
  store.dispatch({
    type: TYPES.GET_SETTINGS_MENU_REQUEST
  })

export const updateSettingsMenu = (payload) =>
  store.dispatch({
    type: TYPES.UPDATE_SETTINGS_MENU_REQUEST,
    payload: payload
  })

export const setHomeMenuElement = (payload) => store.dispatch({
  type: TYPES.SET_HOME_REQUEST,
  payload,
});

export const getHome = () =>
  store.dispatch({
    type: TYPES.GET_HOME_REQUEST
  })

export const clearHome = () => {
  store.dispatch({
    type: TYPES.CLEAR_HOME_REQUEST
  })
}