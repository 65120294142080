import React from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../../../CommonView';
import Field from '../../../../../../elements/Field/index';
import { withWizardStep } from '../../../../../../Contexts/WizardStepContext';
import { getWidgetModel } from '../../../../../../models/WidgetV2/utils';

import './styles.scss';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';

class AddWidgetHTML extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      name: this.props.newEntity?.name || '',
      css: this.props.newEntity?.config?.css ?? '',
      js: this.props.newEntity?.config?.js ?? '',
      html: this.props.newEntity?.config?.html ?? '',
      description: this.props.newEntity?.html || '',
      errors: {},
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newEntity !== this.props.newEntity) {
      this.updateState();
    }
  }

  updateState() {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...this.props.newEntity,
      }),
    );
  }

validate = () => {
  this.savePartialWidget();
};

savePartialWidget = () => {
  this.setErrors({});

  const obj = this.state;
  const newEntity = getWidgetModel(obj);
  let created = newEntity.validate(['name', 'description']);

  const errorHtmlMsg = <FormattedMessage id="Validation.minimum" values={{ minimum: '1' }} />;
  if (!obj.html) {
    created = { ...created, error: true, html: [errorHtmlMsg] };
  }

  if (created.error) {
    delete created.error;
    this.setErrors(created);
    this.props.onError(created.error);
  } else {
    const { errors, onValidation, ...rest } = this.state;
    this.props.onContinue(rest);
  }
};

handleInputChangeP = (event) => {
  const { target } = event;
  const { value } = target;
  const { name } = target;

  this.setState({ [name]: value }, () => {
    const { html, js, css } = this.state;
    const config = { custom: { HTML: { html, js, css } } };

    this.handleSelectChange('config', config);
  });
}

render() {
  const { intl } = this.props;
  return (
    <>
      <Row className="rowImage">
        <Col md={{ size: 6 }}>
          {['name', 'description'].map((index) => (
            <Field
              key={index}
              type={index === 'description' ? 'textarea' : 'text'}
              name={index}
              value={this.state[index]}
              placeholder={intl.formatMessage({
                id: `widgetHTML.wizard.${index}.placeholder`,
              })}
              label={intl.formatMessage({
                id: `widgetHTML.wizard.${index}.label`,
              })}
              helperText={this.state.errors[index]}
              error={!!this.state.errors[index]}
              onChange={this.handleInputChange}
            />
          ))}
          {['css', 'js'].map((index) => (
            <Field
              key={index}
              type={index === 'description' ? 'textarea' : 'text'}
              name={index}
              value={this.state[index]}
              placeholder={intl.formatMessage({
                id: `widgetHTML.wizard.${index}.placeholder`,
              })}
              label={intl.formatMessage({
                id: `widgetHTML.wizard.${index}.label`,
              })}
              helperText={this.state.errors[index]}
              error={!!this.state.errors[index]}
              onChange={this.handleInputChangeP}
            />
          ))}
        </Col>
        <Col className="d-flex flex-column">
          {['html'].map((index) => (
            <Field
              key={index}
              type="textarea"
              name="html"
              value={this.state[index]}
              placeholder={intl.formatMessage({
                id: `widgetHTML.wizard.${index}.placeholder`,
              })}
              label={intl.formatMessage({
                id: `widgetHTML.wizard.${index}.label`,
              })}
              helperText={this.state.errors[index]}
              error={!!this.state.errors[index]}
              onChange={this.handleInputChangeP}
              className="h-100 codeEditor"
              language="html"
            />
          ))}
        </Col>
      </Row>
    </>
  );
}
}

export default withWizardStep(injectIntl(AddWidgetHTML));
