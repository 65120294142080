import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import SuccessComponent from '../../../helpers/SuccessPage';
import DeviceOkIcon from '../../../../configuration/icons/svg/device-ok.svg';

const Success = ({ errorFetching, fetching }) => (
  <SuccessComponent
    title={<FormattedMessage id="devices.add.success.title" />}
    icon={DeviceOkIcon}
    error={errorFetching}
    fetching={fetching}
  />
);

const mapStateToProps = (state) => ({
  errorFetching: state.get('devices').get('errorFetching'),
  fetching: state.get('devices').get('fetching'),
});

Success.propTypes = {
  errorFetching: PropTypes.objectOf(PropTypes.object),
  fetching: PropTypes.objectOf(PropTypes.object),
};

Success.defaultProps = {
  errorFetching: undefined,
  fetching: undefined,
};

export default connect(
  mapStateToProps,
  {},
)(Success);
