import React from 'react';
import { Context } from './provider';

const Consumer = (Component) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class withContext extends React.Component {
    render() {
      const {
        handleBlanketVisibility,
        closeInForegroundClick,
        handleRefreshBlanketVisibility,
      } = this.context;

      return (
        <Component
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...this.props}
          handleRefreshBlanketVisibility={handleRefreshBlanketVisibility}
          handleBlanketVisibility={handleBlanketVisibility}
          closeInForegroundClick={closeInForegroundClick}
        />
      );
    }
  }
  withContext.contextType = Context;

  return withContext;
};

export default Consumer;
