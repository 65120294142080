import React from 'react';
import Styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import OptionsForDashboard from './optionsForDashboard';
import Menu from '../../../../components/Menu';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Switch from '../../../../elements/Switch';
import { havePermissionToEdit } from '../../../../helpers/utils';
import './styles.scss';

const WidgetLockButton = Styled('div')`
`;

const DashboardOptionsMenu = (props) => {
  const {
    title, date, isResizable, handleResizable, dashboard, clickView, profileType,
  } = props;

  const permissionToEdit = havePermissionToEdit(dashboard.permissions_policy, dashboard.owner);
  const openButton = (propsVar) => (
    <Row>
      <Col className="d-flex justify-content-start align-items-center flex-wrap flex-lg-nowrap">
        <div
          className="dashdoardTitle"
          role="presentation"
        >
          <span className="titleText">
            {title}
          </span>
        </div>
        <div className="rectangle">
          <p className="dateText">
            {date}
            {' '}
          </p>
        </div>
        <div className="block-button ml-auto">
          {permissionToEdit && (
          <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
            <WidgetLockButton>
              <button type="button" onClick={handleResizable} id="blockWidgets">
                <span>
                  <Switch checked={!isResizable} />
                </span>
                <FormattedMessage id="dashboard.button.lockWidgets" />
              </button>
            </WidgetLockButton>
          </PrivateComponent>
          )}
        </div>
        <div className="ml-auto">
          {permissionToEdit && (
          <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
            <div id="dashboardSettings" onClick={propsVar.onClick} role="presentation">
              <span>
                <i className="uil uil-cog" />
              </span>
            </div>
          </PrivateComponent>
          )}
        </div>
      </Col>
    </Row>
  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="top left"
      className="more"
    >
      <OptionsForDashboard
        dashboard={dashboard}
        clickView={clickView}
        profileType={profileType}
      />

    </Menu>
  );
};

const DashboardMenuTitle = (props) => {
  const {
    title, date, isResizable, handleResizable, isAnimationResizable, handleAnimationResizable,
    dashboard,
  } = props;

  return (
    <DashboardOptionsMenu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title={title}
      date={date}
      isResizable={isResizable}
      handleResizable={handleResizable}
      isAnimationResizable={isAnimationResizable}
      handleAnimationResizable={handleAnimationResizable}
      dashboard={dashboard}
    />
  );
};

export default DashboardMenuTitle;
