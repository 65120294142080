import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import EditConditions from './EditConditions';
import EditActions from './EditActions';
import EditPermissions from './EditPermissions';
import Rule from '../../../models/Rule/index';
import checkPermission from '../../../components/PrivateComponent/checkPrivateComponent';
import { havePermissionToEdit } from '../../../helpers/utils';
import { getRule, modifyRule } from '../../../services/redux/rules/actions';

const STRING_TRANSLATION_HEADER = 'rules.profile.conditions';

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { ...this.props.data},
    };
  }

  componentDidMount() {
    getRule({idRules: this.props.data.id});
    const backendPermisssion = checkPermission(this.props.userMe, '/app/rules', 'update');
    havePermissionToEdit(this.props.data.permissions_policy, this.props.data.owner)
      .then((permissionToEdit) => this.setState({ permissionToEdit: permissionToEdit && backendPermisssion }));
  }


  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({
        data: { ...this.props.data },
      });
    }

    if (JSON.stringify(prevProps.rules) !== JSON.stringify(this.props.rules)) {
      const foundData = this.props.rules.find(
        (r) => r.id === this.props.data.id,
      );
      this.setState({
        data: foundData,
      });
    }
  }

  updateRule = (payload) => {
    const uRule = modifyRule(payload);
    return uRule;
  }

  handleOnSave = (id, data) => {
    if (this.state[id] !== data) {
      const newData = {
        ...this.state.data,
        [id]: data,
        fiware: {
          service: this.state.data.service,
          servicepath: this.state.data.subservice,
        },
      };


      const editedRule = new Rule(newData);


      const validation = false;
      this.updateRule({ ruleId: this.props.data.id, payload: { [id]: data } });

      if (validation) {
        this.setState({
          errors: validation,
        });
      } else {
        this.setState({
          data: newData,
        });
      }
    }
  };

  render() {
    const { data, errors, permissionToEdit } = this.state;
    return (
      <ProfileComponent
        data={data}
        titles={[
          <FormattedMessage id="rules.profile.conditions" />,
          <FormattedMessage id="rules.profile.actions" />,
          <FormattedMessage id="rules.profile.permissions" />,
        ]}
        save={this.handleOnSave}
        errors={errors}
        editable={permissionToEdit}
      >

        <EditConditions data={data} permissionToEdit={permissionToEdit} stringTranslationHeader={STRING_TRANSLATION_HEADER} />
        <EditActions data={data} permissionToEdit={permissionToEdit} />
        <EditPermissions data={data} permissionToEdit={permissionToEdit} entity={Rule} />
      </ProfileComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  rules: state
    .get('rules')
    .get('list')
    .toJS(),
  userMe: state.get('users').get('userMe'),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
