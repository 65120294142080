import * as React from 'react';
import styled from '@emotion/styled';
import SecondaryMenuWhite from '../../../../../configuration/icons/svg/drag-drop-black.svg';

export interface TrayItemWidgetProps {
    model: any;
    color?: string;
    name: string;
    icon: string;
    draggable: boolean;
    onClick: any;
}


 // border: solid 1px ${p => p.color};
export const Tray = styled.div<{ color: string }>`
		color: #2B334A;
		font-family: Source Sans Pro;
		padding: 5px;
		margin: 0px 0px;
        font-size: 16px;
        line-height: 20px;
		border-radius: 5px;
		margin-bottom: 2px;
        cursor: pointer;
        
        :hover {
            background-color: #E8EDFC;
            cursor:pointer;
          }
	`;

export class TrayItemWidget extends React.Component<TrayItemWidgetProps> {
    render() {
        return (
            <Tray
                color={this.props.color}
                draggable={this.props.draggable}
                onDragStart={event => {
                    event.dataTransfer.setData('storm-diagram-node', JSON.stringify(this.props.model));
                }}
                onClick={this.props.onClick}
                className="tray-item">
                <img
                    alt={'open menu'}
                    className={'icon-dragable'}
                    src={SecondaryMenuWhite}
                />
                  { this.props.icon && <img
                    alt={'open menu'}
                    className={'etl-tool-icon'}
                    src={this.props.icon}
                />   
                }
                <label style={{marginLeft:'6px'}}>{this.props.name}</label>
            </Tray>
        );
    }
}

export default TrayItemWidget;