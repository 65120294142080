import Faker from 'faker';
import {
  exampleFiware,
  exampleProtocol,
  exampleTransport,
  examplePermissionPolicy,
} from '../redux/devices/reducer';
import { deviceManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = deviceManagement.urlAPI.concat(deviceManagement.paths.device);

const exampleBodyFn = (numberOfDeviceToGenerate = 50) => {
  const DevicesFaker = [];
  for (let i = 0; i < numberOfDeviceToGenerate; i++) {
    DevicesFaker.push({
      id: Faker.random.uuid(),
      device_id: Faker.random.uuid(),
      name: Faker.random.word(),
      template_id: Faker.random.uuid(),
      service_id: Faker.random.uuid(),
      permissions_policy: Faker.random.arrayElement(examplePermissionPolicy),
      owner: Faker.random.uuid(),
      entity_type: Faker.random.word(),
      fiware: Faker.random.arrayElement(exampleFiware),
      transport: Faker.random.arrayElement(exampleTransport),
      attributes: [],
      lazy_attributes: [],
      command_attributes: [],
      static_attributes: [],
      internal_attributes: [],
      protocol: Faker.random.arrayElement(exampleProtocol),
    });
  }
  return { devices: DevicesFaker };
};

/** * First version of Device CRUD */
// PLURAL
export const readDevices = async (page, size, filters, ckan, dataset) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryCKAN = ckan === true ? '&subscriptions.ckan' : '&subscriptions.ckan=!';
  const queryDataset = dataset ? `&organization.dataset.id=${dataset}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const url = `${endPoint}/list/all`;
  const params = {
    endPoint: `${url}?${queryPage}&${querySize}${queryFilters}${queryDataset}${
      ckan === undefined ? '' : queryCKAN
    }`,
    statusOK: 200,
    toJSON: true,
    returnData: 'devices',
  };
  return requestAPI(params, exampleBodyFn);
};

export const readMyDevices = async (page, size, filters) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'devices',
  };
  return requestAPI(params, exampleBodyFn);
};

// SINGULAR
export const readDevice = async (device) => {
  if (!device.id) return { status: 400, error: 'invalid id attr' };
  const params = {
    endPoint: `${endPoint}/${device.id}`,
    statusOK: [200, 404, 403],
    toJSON: true,
    returnData: 'device',
  };
  const answer = await requestAPI(params, exampleBodyFn);
  return answer;
};

export const readPublicDevice = async (device) => {
  if (!device.id) return { status: 400, error: 'invalid id attr' };
  const params = {
    endPoint: `${endPoint}/${device.id}/public`,
    statusOK: [200, 404, 403],
    toJSON: true,
    returnData: 'device',
  };
  const answer = await requestAPI(params, exampleBodyFn, true);
  return answer;
};

export const addDevice = async (device) => {
  if (device.id) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'device',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(device),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  const answer = await requestAPI(params, exampleBodyFn);
  return answer;
};

export const updateDevice = (device) => {
  if (!device.id) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: `${endPoint}/${device.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: device,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(device),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const sendCommands = async (commands) => {
  if (!commands.targets) return { status: 400, error: 'missing targets' };

  const params = {
    endPoint: `${endPoint}/sendMultipleCommands`,
    statusOK: 200,
    toJSON: true,
    returnData: commands,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(commands),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const sendCommand = async (device, command) => {
  if (!device.id) return { status: 400, error: 'invalid id attr' };
  if (!command.name || !command.value) return { status: 400, error: 'Invalid command' };

  const params = {
    endPoint: `${endPoint}/${device.id}/sendCommand`,
    statusOK: 200,
    toJSON: true,
    returnData: device,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(command),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  const answer = await requestAPI(params, () => ({
    command: { name: 'debug', value: 'test' },
  }));
  return answer;
};

export const deleteDevice = (device) => {
  if (!device.id) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: `${endPoint}/${device.id}`,
    statusOK: 204,
    toJSON: false,
    returnData: device,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};
