import { Map, List } from 'immutable';
import * as TYPES from './types';
import { clone } from '../../../helpers/utils';

export const examplePermissionPolicy = ['Group1', 'Group2', 'Group3', 'Group4'];
export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];

/** Initial State of Dashboards */
const initialState = Map({
  list: List([]),
  readDashboard: false,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  listDataSourcesFromDevices: List([]),
});

/** DASHBOARDS REDUCER */
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    case TYPES.GET_PUBLIC_DATASOURCES_SUCCESS:
    case TYPES.GET_DATASOURCES_SUCCESS:
    case TYPES.GET_MINE_DATASOURCES_SUCCESS:
      const dataSourceId = payload.dataSources.map((o) => o.id);
      const dataSources = state
        .get('list')
        .toJS()
        .filter((o) => !dataSourceId.includes(o.id));

      return state
        .set('total', payload.total)
        .set('list', List([...dataSources, ...payload.dataSources]))
        .set('fetching', false)
        .set('dataSourceAdded', false)
        .set('dataSourceDeleted', false);

    /** INITIALIZE GET DATASOURCES FROM DB */
    case TYPES.GET_MINE_DATASOURCES_ERROR:
    case TYPES.GET_PUBLIC_DATASOURCES_ERROR:
    case TYPES.GET_DATASOURCES_ERROR:

      return state
        .set('errorFetching', payload)
        .set('fetching', false)
        .set('list', List([]))
        .set('dataSourceAdded', false)
        .set('dataSourceDeleted', false);


    /** GET DATASOURCE FROM DB TO STATE */
    case TYPES.GET_DATASOURCE_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      // TODO
      // If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1
        ? (state.update('list', (list) => list.push(payload))
          .set('readETLProcedure', payload))
        : state.set('readETLProcedure', payload);


      /** GET DATASOURCE FROM DB TO STATE */
    case TYPES.GET_DATASOURCES_FROM_DEVICES_SUCCESS:
      return state.set('listDataSourcesFromDevices', List([...payload]));


      // //////////////////////////////////////////////////////
      // ////// ADD AN DATASOURCE
      // //////////////////////////////////////////////////////

    /** ADD NEW DATASOURCE TO STATE */
    case TYPES.ADD_DATASOURCE_SUCCESS:
      return state
        .update('list', (userList) => userList.unshift(payload))
        .set('fetching', false)
        .set('addSuccess', true)
        .set('dataSourceAdded', true)
        .set('dataSourceDeleted', false)
        .set('errorFetching', false)
        .update('total', (total) => total + 1);

      // //////////////////////////////////////////////////////
      // ////// UPDATE AN DATASOURCE
      // //////////////////////////////////////////////////////

    /** UPDATE DATASOURCE IN STATE */
    case TYPES.MODIFY_DATASOURCE_SUCCESS:
      return state
        .update('list', (dataSourceList) => dataSourceList.map((dataSource) => {
          if (dataSource.id === payload.id) {
            return payload;
          }
          return dataSource;
        }))
        .merge(Map({
          fetching: false,
          modifySuccess: true,
        }));

      // //////////////////////////////////////////////////////
      // ////// DELETE AN DATASOURCE
      // //////////////////////////////////////////////////////

    /** DELETE DATASOURCE IN STATE */
    case TYPES.DELETE_DATASOURCE_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      aux = index !== -1
        ? state.update('list', (userList) => userList.delete(index))
        : state;
      aux = aux.set('fetching', false);
      aux = aux.set('dataSourceAdded', false);
      aux = aux.set('dataSourceDeleted', true);
      aux = aux.update('total', (total) => total - 1);
      return aux;


    /** INITIALIZE GET DATASOURCES FROM DB */
    /** INITIALIZE GET DATASOURCES FROM DB */
    /** INITIALIZE UPDATE DATASOURCE TO DB */
    /** INITIALIZE DELATION OF A DATASOURCE IN DB */
    case TYPES.GET_DATASOURCES_REQUEST:
    case TYPES.GET_DATASOURCE_REQUEST:
    case TYPES.GET_MINE_DATASOURCES_REQUEST:
    case TYPES.ADD_DATASOURCE_REQUEST:
    case TYPES.MODIFY_DATASOURCE_REQUEST:
    case TYPES.DELETE_DATASOURCE_REQUEST:
    // case TYPES.GET_DATASOURCES_FROM_DEVICES_REQUEST:
      return state
        .set('fetching', true)
        .set('dataSourceAdded', false)
        .set('errorFetching', false)
        .set('dataSourceDeleted', false);

    /** SET AS FALSE THE addSuccess STATE FLAG */
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.ADD_DATASOURCE_SUCCESS_FLAG:
    case TYPES.MODIFY_DATASOURCE_SUCCESS_FLAG:
      return state
        .set('fetching', false)
        .set('dataSourceAdded', true)
        .set('dataSourceDeleted', false);

      // //////////////////////////////////////////////////////
      // ////// ERRORS
      // //////////////////////////////////////////////////////

    /** INITIALIZE GET DATASOURCE FROM DB */
    /** INITIALIZE GET DATASOURCE FROM DB ERROR */
    /** INITIALIZE GET DATASOURCE ROLES FROM DB ERROR */
    /** INITIALIZE ERROR ADD DATASOURCE */
    /** UPDATE DATASOURCE ERROR */
    /** INITIALIZE DELATION OF AN DATASOURCE IN DB */
    case TYPES.GET_DATASOURCE_ERROR:
    case TYPES.ADD_DATASOURCE_ERROR:
    case TYPES.MODIFY_DATASOURCE_ERROR:
    case TYPES.DELETE_DATASOURCE_ERROR:
    case TYPES.GET_DATASOURCES_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      aux = aux.set('dataSourceAdded', false);
      aux = aux.set('dataSourceDeleted', false);
      return aux;

      // //////////////////////////////////////////////////////
      // ////// CLEARS
      // //////////////////////////////////////////////////////

    case TYPES.CLEAR_DATASOURCES_STATE:
      return state.set('list', List([]));

      // //////////////////////////////////////////////////////
      // ////// DEFAUKLT
      // //////////////////////////////////////////////////////

    default:
      return state;
  }
};
