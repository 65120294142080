/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import Widget from '../..';
import {
  HeatMapConfigurationSheet,
  HeatMapConfigurationSheetBasic,
  HeatMapDefaultTemplateConfig,
  validationHeatMap,
} from './utils/configurationSheet';
import { HeatMapDefaultConfiguration, injectHeatMapData } from './utils/defaultConfiguration';
import HeatMapComponent from './component';
import { ReactComponent as HeatMapWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-heatmap.svg';
import formatToHeatMapWidget from './utils/formatData';
import { getEntityForUrn, getUrnId } from '../../../../helpers/utils';
import { readDevice, readPublicDevice } from '../../../../services/api/devices';
import { getAllDeviceAttributes } from '../../utils';
import { imLogged } from '../../../../helpers';

export const NAME_WIDGET = 'HEATMAP';
export default class HeatMapWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = HeatMapWidgetIcon;

  constructor(obj = {}) {
    super(obj);
    if (obj.version === 'v1') {
      this.sources.forEach((source, index) => {
        const getDevice = !imLogged() ? readPublicDevice : readDevice;
        getDevice({ id: getUrnId(source.urn) })
          .then((deviceData) => {
            const allAttributes = getAllDeviceAttributes(deviceData.data.object, ['command_attributes']);
            const geoAttribute = allAttributes.find(
              (attribute) => attribute.type === 'geo:point' || attribute.type === 'geo:json',
            );
            if (geoAttribute && !source.fields.includes(geoAttribute.name)) {
              const newSources = cloneDeep(this.sources);
              newSources[index].fields.push(geoAttribute.name);
              this.sources = newSources;
            }
          });
      });
    }
  }

  skeleton = HeatMapWidgetIcon;

  defaultConfig = HeatMapDefaultConfiguration;

  templateConfig = HeatMapDefaultTemplateConfig;

  component = HeatMapComponent;

  hasMultipleSources = true;

  injectData = (widget) => injectHeatMapData(widget);

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => (config) => validationHeatMap(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? HeatMapConfigurationSheetBasic
      : HeatMapConfigurationSheet
  )

  getWidgetChildBodyQuery = () => null;

  formatToData = (historicalValues, widgetSources) => formatToHeatMapWidget(
    historicalValues,
    widgetSources,
    this.config,
  )

  getQueryHistorical() {
    const dataForQueries = this.getEmptyHistoricalBody();
    const sources = this.getDataForQueries({ sources: this.sources });
    const standardBodyQuery = this.getDataForQueries({ sources: this.sources, queryType: 'standard' });
    dataForQueries[0].sources = sources;
    dataForQueries.push(...standardBodyQuery);

    return dataForQueries;
  }

  parsedLinkedData = () => null;

  injectUrnAndFields(sources) {
    const newSources = [];
    sources.forEach((source) => {
      const d = {
        urn: `fiwoo:${getEntityForUrn(source.type)}:${source.id}`,
        fields: [],
        metadata: {},
      };

      if (source.joinedAttributes) {
        source.joinedAttributes.forEach((attribute) => {
          if (attribute.selected || (attribute.type === 'geo:json' || attribute.type === 'geo:point')) {
            d.fields.push(attribute.name);
          }
        });
      }
      newSources.push({ ...source, ...d });
    });

    this.sources = newSources;
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
