import Styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import CircularButton from '../CircularButton';
import './styles.scss';
import { FormattedMessage } from '../../Contexts/LanguageContext';

const Button = Styled.button`
  color: ${p => p.color ? p.color :'inherit'}!important;
  ${p => p.css}
`;

Button.propTypes = {
  /**Primary, Secondary, Danger, Warning y Success están definidos de manera predeterminada, pero pueden añadirse nuevos
   tipos desde la propiedad color en el theme, al igual que se pueden anidar modes*/
  color: PropTypes.string,

  /** Con esta propiedad se puede añadir css a los botones, así como pisar propiedades existentes*/
  css: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Esta propiedad actúa como divisor (en decimal como multiplicador) del theme.themeMargin */
  divisor: PropTypes.number,

  /** The type of the button. At the moment, it can be primary (default), secondary, third or circular */
  type: PropTypes.string,

  /** The icon of the button. Its value can be any Unicons icon name. For example: 'plus'  */
  icon: PropTypes.string,

  name: PropTypes.string,

  /** The title/text of the button */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(FormattedMessage)]),
};

Button.defaultProps = {
  color: 'default',
  css: ``,
  divisor: 4
};

export class ButtonCustom extends React.Component {
  getClass() {
    const { type, className } = this.props;
    let classButton = `btn ${className? className : ''} `;

    switch (type) {
      case 'primary':
        classButton += 'btn-primary ';
        break;
      case 'secondary':
        classButton += 'btn-secondary ';
        break;
      case 'third':
        classButton += 'btn-third ';
        break;
      default:
        classButton += '';
    }
    classButton += 'align-items-center justify-content-center '
    return classButton;
  }

  render() {
    const { label, handleOnClick, icon, svgIcon, disabled, type, ...rest } = this.props;
    const SVGIcon = svgIcon;
    return type === 'circular' ? (
      <CircularButton {...this.props} />
    ) : (
      <Button
        {...rest}
        className={this.getClass()}
        onClick={(e) => (handleOnClick ? handleOnClick(e) : null)}
        disabled={disabled ? 'disabled' : ''}
      >
        {icon && <i className={`uil uil-${icon}`} />}
        {svgIcon && <SVGIcon  />}{' '}
        {label}
      </Button>
    );
  }
}

export default Button;
