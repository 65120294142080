/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import CommonView from '../../pages/CommonView';
import Field from '../../elements/Field';
import AddButton from '../../elements/AddButton';
import './styles.scss';
import { withBlanketCtxt } from '../../Contexts/BlanketContext';
import { ButtonCustom } from '../../elements/Button';
import { cloneInstance, getHash } from '../../helpers/utils';

class SelectableList extends CommonView {
  constructor(props) {
    super({ props });

    const { name } = this.props;
    this.state = {
      errors: { ...this.props.error },
      saved: this.props.selected || [],
      [name]: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { afterAdd, setAfterAdd } = this.props;

    if (prevProps.selected !== this.props.selected) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        saved: [...this.props.selected],
      });
    }

    if (JSON.stringify(prevProps.error) !== JSON.stringify(this.props.error)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        errors: this.props.error,
      });
    }

    if (afterAdd) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        saved: [],
      });
      setAfterAdd(false);
    }
  }

  filterData = (allData, savedData) => allData.filter(
    (data) => !savedData.some((d) => d.id === data.id),
  );

  handleAddButtonClick = () => {
    this.addOption();
  };

  handleDeleteButtonClick = (id) => {
    let saved = [...this.state.saved];
    saved = saved.filter((s) => s.id !== id);
    this.setState({
      saved,
      [this.props.name]: null,
    }, () => this.props.onChange(this.state.saved));
  };

  showModal = () => {
    const { handleBlanketVisibility } = this.props;
    handleBlanketVisibility(true, this.modalContent);
  };

  hideModal = () => {
    const { handleBlanketVisibility } = this.props;
    handleBlanketVisibility(false);
  };

  modalContent = () => {
    const {
      onConfirmationChange: { add: { title, description } },
      onChange,
      footerWithAction,
    } = this.props;
    return (
      <div id="modal">
        <Container fluid ref={(node) => { this.ClickOutRef = node; }}>

          <div className="title-selectable">
            <Col>{title}</Col>
          </div>

          <div className="body">
            <div className="d-flex justify-content-center align-items-center w-100">
              <p>
                {description}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <ButtonCustom
              className="mr-3"
              type="secondary"
              handleOnClick={this.hideModal}
              label={<FormattedMessage id="options.cancel" />}
            />
            <ButtonCustom
              type="primary"
              handleOnClick={footerWithAction ? () => onChange(this.state.saved)
                : () => this.handleSelectorChange(
                  this.state.modalVars.name,
                  this.state.modalVars.value,
                  true,
                )}
              label={<FormattedMessage id="options.accept" />}
            />
          </div>

        </Container>
      </div>
    );
  }

  handleSelectorChange = (name, value, checkOk = false) => {
    const { onConfirmationChange } = this.props;
    if (onConfirmationChange && !checkOk) {
      this.setState({ modalVars: { name, value } });
      this.showModal();
      return;
    }
    if (checkOk) {
      this.hideModal();
    }
    this.setState({ [name]: value }, () => {
      this.addOption();
    });
  };

  handleMultiSelectorChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.addOption();
    });
  }

  addOption = () => {
    const {
      name, multiOption, onlyOneItem, footerWithAction,
    } = this.props;
    const errors = { ...this.state.errors };
    if (!this.isSelectedDataInSelect()) {
      errors[name] = (
        <FormattedMessage id={`${name}.selectableList.error.empty.select`} />
      );
      this.setState({
        errors,
      });
    } else if (!multiOption && this.state.saved.length === 1) {
      errors[name] = (
        <FormattedMessage
          id={`${name}.selectableList.error.only.one.value.select`}
        />
      );
      this.setState({
        errors,
      });
    } else {
      let saved = [...this.state.saved];
      const value = this.state[name];
      const itemToPush = typeof this.state[name] === 'string'
        ? { name: value, id: value, value } : cloneInstance(this.state[name]);
      if (onlyOneItem) saved = [];
      if (footerWithAction && saved.some((o) => o.id === itemToPush.id)) {
        saved = saved.filter((o) => o.id !== itemToPush.id);
      } else {
        saved.push(itemToPush);
      }
      this.setState({
        saved,
        [name]: null,
        errors: {},
      }, () => !footerWithAction && this.props.onChange(this.state.saved));
    }
  }

  isSelectedDataInSelect = () => this.state[this.props.name]
    || (Array.isArray(this.state[this.props.name])
      && this.state[this.props.name].length > 0);

  dataLength = () => this.state[this.props.name].length;

  render() {
    const {
      name,
      disabled,
      intl,
      translatePrefix,
      selectOnTheFly,
      filterForOptions = null,
      label,
      type,
      footer,
      smallInput,
      onlyOneItem,
      onConfirmationChange,
      footerWithAction,
      ...rest
    } = this.props;
    const { errors, saved } = this.state;
    const currentData = saved && type !== 'LinkedProfile'
    ? this.filterData([...this.props.data], saved)
    : [...this.props.data];
    const ListComponent = this.props.listComponent;
    return (
      <div className="selectableList">
        <div className="selectWrapper">
          <div className="w-100">
            <Row className="justify-content-center">
              {/* {filterForOptions && (
                <Col xs="3">
                  <Field
                    type="select"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...filterForOptions}
                  />
                </Col>
              )} */}
              <Col xs={filterForOptions ? '5' : '12'} lg={filterForOptions || smallInput ? '5' : '12'}>
                {[name].map((key, i) => (
                  <>
                    <Field
                      label={label}
                      name={key}
                      placeholder={intl.formatMessage({
                        id: `${translatePrefix}.${key}.placeholder`,
                      })}
                      id={key}
                      value={this.state[key]}
                      check={this.state.saved}
                      type="select"
                      options={currentData}
                      onChange={footerWithAction
                        ? this.handleMultiSelectorChange : this.handleSelectorChange}
                      helperText={errors[key]}
                      error={!!errors[key]}
                      key={getHash(`${key}-${i}`)}
                      async
                      fetchInfo={this.props.fetchInfo}
                      loading={this.props.loading}
                      onSearch={this.props.onSearch}
                      filterBy={this.props.filterBy}
                      filter={this.props.selectableFilter}
                      disabled={disabled}
                      noClose
                      footer={footer}
                      footerWithAction={footerWithAction}
                      showModal={this.showModal}
                    />
                  </>
                ))}
              </Col>
            </Row>
            {saved && (
              <ListComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                data={saved}
                onDelete={this.handleDeleteButtonClick}
              />
            )}
          </div>
          {!selectOnTheFly && <AddButton onClick={() => this.handleAddButtonClick()} />}
        </div>
      </div>
    );
  }
}

SelectableList.defaultProps = {
  selectOnTheFly: false,
  name: 'name',
  error: {},
  multiOption: true,
  listComponent: () => <p>No component</p>,
  onChange: () => { },
  translatePrefix: 'list',
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {},
)(withBlanketCtxt(injectIntl(SelectableList)));
