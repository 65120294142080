import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clone } from 'ramda';
import Switch from '../../../../elements/Switch';
// eslint-disable-next-line import/no-cycle
import { updateDashboardLayout } from '../../../../services/redux/dashboards/actions';

function KpiHistoricalSwitch(props) {
  const [screen] = useState(window.screen.width);
  const {
    widget, title, onChange, dashboard, currentLayouts,
  } = props;
  const { config } = widget;
  const { appearance } = config;
  const { showHistorical } = appearance;

  const updateDashboardLayoutWidget = (hasHistorical) => {
    const dashboardCopy = clone(dashboard);
    const layoutProperties = Object.keys(currentLayouts);
    const updateLayout = {};

    layoutProperties.forEach((layoutProperty) => {
      updateLayout[layoutProperty] = currentLayouts[layoutProperty]
        .map((dataLayout) => {
          const dataLayoutCopy = clone(dataLayout);
          if (dataLayout.i !== widget.id) return dataLayoutCopy;
          if (screen > 1350) {
            dataLayoutCopy.w = hasHistorical ? 4 : 2;
          } else if (screen > 960) {
            dataLayoutCopy.w = hasHistorical ? 5 : 3;
          } else if (screen > 750) {
            dataLayoutCopy.w = hasHistorical ? 7 : 4;
          } else {
            dataLayoutCopy.w = hasHistorical ? 12 : 12;
          }
          return dataLayoutCopy;
        });
    });

    const updateDashboard = { ...dashboardCopy, layoutProperties: { ...updateLayout } };
    updateDashboardLayout(updateDashboard);
  };

  const toggleCheckboxHistorical = async (checked) => {
    updateDashboardLayoutWidget(checked);
    onChange({ name: 'showHistorical', value: checked }, 'appearance', 'kpiHistoricalSwitch');
  };

  return (
    <div>
      <Switch
        className="switch"
        positionTitle="right"
        checked={showHistorical === undefined ? true : showHistorical}
        onChange={toggleCheckboxHistorical}
        title={title}
      />
    </div>
  );
}

KpiHistoricalSwitch.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  title: PropTypes.string,
  widget: PropTypes.objectOf(PropTypes.any),
};

KpiHistoricalSwitch.defaultProps = {
  dashboard: {},
  onChange: () => {},
  title: '',
  widget: {},
};

const mapStateToProps = (state) => ({
  dashboard: state.get('dashboards').get('readDashboard'),
  currentLayouts: state.get('dashboards').get('currentLayouts'),
});

export default connect(
  mapStateToProps,
  {},
)(KpiHistoricalSwitch);
