import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  fork
} from 'redux-saga/effects';
import Device from '../../../models/Device';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/devices';
import { parseToSources } from '../../../helpers/utils';

/**
 * Actions for WATCHERS
 * */

// Try to recover all device form DB
export function* getDevicesThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readDevices,
      payload.page,
      payload.size,
      payload.filters,
      payload.ckan,
    );
    if (response.status === 200) {
      const devices = response.data.objects.map(device => new Device(device));
      const total = response.data.total;
      yield put({
        type: TYPES.GET_DEVICES_SUCCESS,
        payload: { devices, total }
      });
      yield put({
        type: TYPES.GET_DEVICE_TO_SOURCE_LIST,
        payload: devices.map(parseToSources)
      })
    } else {
      yield put({ type: TYPES.GET_DEVICES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DEVICES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getMyDevicesThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readMyDevices,
      payload.page,
      payload.size,
      payload.filters
    );
    if (response.status === 200) {
      const devices = response.data.objects.map(device => new Device(device));
      const total = response.data.total;
      yield put({
        type: TYPES.GET_MY_DEVICES_SUCCESS,
        payload: { devices, total }
      });
    } else {
      yield put({ type: TYPES.GET_MY_DEVICES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MY_DEVICES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an device form DB
export function* getDeviceThroughApi({ payload }) {
  const { isPublicDashboard } = payload;
  const endpoint = isPublicDashboard ? Api.readPublicDevice : Api.readDevice;
  try {
    const response = yield call(endpoint, payload);
    if (response.status === 200 || response.status.includes(200)) {
      const deviceFromDB = new Device(response.data.object);
      yield put({ type: TYPES.GET_DEVICE_SUCCESS, payload: deviceFromDB });
      yield put({ type: TYPES.GET_DEVICE_V2, payload: parseToSources(deviceFromDB) })
    } else {
      yield put({ type: TYPES.GET_DEVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DEVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getDeviceListThroughApi({payload, type, nameSpace}) {
  try {
    const response = yield all(
      payload.map(device => call(Api.readDevice, device))
    );
    if (response) {
      const devices = response.filter(o => !o.data.errors).map(d => new Device(d.data.object));
      yield put({
        type: type === 'GET_DEVICE_LIST_REQUEST' ? TYPES.GET_DEVICE_LIST_SUCCESS : TYPES.GET_DEVICE_LIST_FOR_SENDING_COMMANDS_SUCCESS,
        payload: devices,
        nameSpace
      });
    } else {
      yield put({
        type: type === 'GET_DEVICE_LIST_REQUEST' ? TYPES.GET_DEVICE_LIST_ERROR : TYPES.GET_DEVICE_LIST_FOR_SENDING_COMMANDS_ERROR,
        payload: response
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DEVICE_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
 }

 export function* getDeviceListWidgetsThroughApi({payload, type, nameSpace}) {
  try {
    const response = yield all(
      payload.map(device => call(Api.readDevice, device))
    );
    if (response) {
      const devices = response.filter(o => !o.data.errors).map(d => new Device(d.data.object));
      yield put({
        type: TYPES.GET_DEVICE_LIST_FOR_WIDGETS_SUCCESS,
        payload: devices,
        nameSpace
      });
    } else {
      yield put({
        type: TYPES.GET_DEVICE_LIST_FOR_WIDGETS_ERROR,
        payload: response
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DEVICE_LIST_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
 }


// Try to create device in DB
export function* addDevicesThroughApi({ payload }) {
  try {
    const response = yield call(Api.addDevice, payload);
    if (response.status === 201) {
      const deviceFromDB = new Device(response.data.object);
      yield put({ type: TYPES.ADD_DEVICE_SUCCESS, payload: deviceFromDB });
    } else {
      yield put({ type: TYPES.ADD_DEVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_DEVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update device in DB
export function* updateDevicesThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateDevice, payload);

    if (response.status === 200)
      yield put({
        type: TYPES.MODIFY_DEVICE_SUCCESS,
        payload: new Device(payload)
      });
    else {
      yield put({ type: TYPES.MODIFY_DEVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_DEVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete device in DB
export function* deleteDevicesThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteDevice, payload);
    if (response.status === 204)
      yield put({ type: TYPES.DELETE_DEVICE_SUCCESS, payload });
    else {
      yield put({ type: TYPES.DELETE_DEVICE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_DEVICE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getDevicesForRulesThroughApi({ payload }) {
  try {
    const response = yield all(
      payload.map(device => call(Api.readDevice, device))
    );

    if (response) {
      // FIXME: objects need it?
      const devices = response.map(d => new Device(d.data.object));
      yield put({
        type: TYPES.GET_DEVICES_FOR_RULES_SUCCESS,
        payload: devices
      });
    } else {
      yield put({
        type: TYPES.GET_DEVICES_FOR_RULES_ERROR,
        payload: response
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DEVICES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_DEVICES_REQUEST
function* watcherGetDevices() {
  yield takeEvery('GET_DEVICES_REQUEST', getDevicesThroughApi);
}

// Watcher looking for GET_MY_DEVICES_REQUEST
function* watcherGetMyDevices() {
  yield takeLatest('GET_MY_DEVICES_REQUEST', getMyDevicesThroughApi);
}

// Watcher looking for GET_DEVICE_REQUEST
function* watcherGetDevice() {
  yield takeEvery('GET_DEVICE_REQUEST', getDeviceThroughApi);
}

//Watcher looking gro GET_DEVICES_LIST_REQUEST
function* watcherGetDeviceList(){
  yield takeLatest(['GET_DEVICE_LIST_REQUEST', 'GET_DEVICE_LIST_FOR_SENDING_COMMANDS_REQUEST'],getDeviceListThroughApi)
}

//Watcher looking gro GET_DEVICE_LIST_FOR_WIDGETS_REQUEST
function* watcherGetDeviceListForWidgets(){
  yield takeLatest('GET_DEVICE_LIST_FOR_WIDGETS_REQUEST', getDeviceListWidgetsThroughApi)
}

// Watcher looking for ADD_DEVICE_REQUEST
function* watcherAddDevices() {
  yield takeEvery('ADD_DEVICE_REQUEST', addDevicesThroughApi);
}

// Watcher looking for MODIFY_DEVICE_REQUEST
function* watcherUpdateDevices() {
  yield takeLatest('MODIFY_DEVICE_REQUEST', updateDevicesThroughApi);
}

// Watcher looking for DELETE_DEVICE_REQUEST
function* watcherDeleteDevices() {
  yield takeEvery('DELETE_DEVICE_REQUEST', deleteDevicesThroughApi);
}

// Watcher looking for GET_DEVICES_REQUEST
function* watcherGetDevicesForRules() {
  yield takeLatest(
    'GET_DEVICES_FOR_RULES_REQUEST',
    getDevicesForRulesThroughApi
  );
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetDevices),
    fork(watcherGetMyDevices),
    fork(watcherGetDevice),
    fork(watcherAddDevices),
    fork(watcherUpdateDevices),
    fork(watcherDeleteDevices),
    fork(watcherGetDeviceList),
    fork(watcherGetDevicesForRules),
    fork(watcherGetDeviceListForWidgets),
  ]);
}
