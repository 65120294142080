import React from 'react';
import { PropTypes } from 'prop-types';
import PolygonMap from '../../../../../components/WidgetMap/PolygonMap';
import { ButtonCustom } from '../../../../../elements/Button/index';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import PolygonMapModal from '../../../Add/steps/components/PolygonMapModal';
import { ReactComponent as IcoOpenMap } from '../../../../../configuration/icons/svg/ico-open-map.svg';
import { mapButton, editable } from '../../../Add/RulesWizard.module.scss';

const EditGeoFenceComponent = ({
  features, onShowPolygonMapModal, onAssignAreaToValue, showMap, condition, mode,
}) => (
  <div className="mapWrapper">
    <PolygonMap
      zoom={8}
      features={features}
      onePolygon
      mode={null}
    />
    <ButtonCustom
      type="secondary"
      handleOnClick={onShowPolygonMapModal}
      label={<FormattedMessage id={`rules.wizard.mapButton.${features.geometry && features.geometry.coordinates ? 'edit' : 'open'}`} />}
      disabled={!condition.operator}
      svgIcon={features.geometry && features.geometry.coordinates ? undefined : () => <IcoOpenMap />}
      icon={features.geometry && features.geometry.coordinates ? 'check-circle' : ''}
      className={mapButton.concat(' ', features.geometry && features.geometry.coordinates ? editable : '')}

      id="showPolygonMap"
    />
    <PolygonMapModal
      features={features}
      showMap={showMap}
      assignAreaToValue={onAssignAreaToValue}
      togglePolygonalMapModal={onShowPolygonMapModal}
      mode={mode}
    />
  </div>
);

EditGeoFenceComponent.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object),
  onShowPolygonMapModal: PropTypes.func.isRequired,
  onAssignAreaToValue: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired,
  condition: PropTypes.shape({ value: PropTypes.string, operator: PropTypes.string }).isRequired,
};

EditGeoFenceComponent.defaultProps = {
  features: [],
};
export default EditGeoFenceComponent;
