import Faker from 'faker';
import { userManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';

const endPoint = userManagement.urlAPI.concat(userManagement.paths.usersGroup);

const exampleBodyFn = (data = null, numberOfUsersGroupToGenerate = 50) => {
  const UsersGroupsFaker = [];
  for (let i = 0; i < numberOfUsersGroupToGenerate; i++) {
    UsersGroupsFaker.push({
      id: Faker.random.uuid(),
      name: Faker.random.word(),
      description: Faker.random.words(),
      users: [],
      permissions_policy: {
        users: [],
        groups: [],
        public: false,
      }
    });
  }
  const groups = { groups: UsersGroupsFaker };
  return groups;
};


export const readUsersGroups = async () => {
  //const {queryPage, querySize, queryFilters} = getQueryData(page, size, filters);

  const params = {
    endPoint: endPoint,
    statusOK: 200,
    toJSON: true,
    returnData: 'groups',
  };

  return requestAPI(params, exampleBodyFn);
};

export const readUsersGroup = async usersGroup => {
  if (!usersGroup['id']) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: endPoint + '/' + usersGroup.id,
    statusOK: 200,
    toJSON: true,
    returnData: 'group',
  };

  let answer = await requestAPI(params, usersGroup);

  return answer;
};

export const addUsersGroup = async usersGroup => {
  if (usersGroup['id']) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'usersGroup',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(usersGroup),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  return requestAPI(params, { groups: usersGroup });
};

export const updateUsersGroup = usersGroup => {
  if (!usersGroup['id']) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: endPoint + '/' + usersGroup.id,
    statusOK: 200,
    toJSON: false,
    returnData: usersGroup,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(usersGroup),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, { groups: usersGroup });
};

export const deleteUsersGroup = usersGroup => {
  if (!usersGroup['id']) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: endPoint + '/' + usersGroup.id,
    statusOK: 204,
    toJSON: false,
    returnData: usersGroup,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, { groups: usersGroup });
};

export const addUserToUsersGroup = ({usersGroup, user}) => {
  if (!usersGroup['id'] || !user['id']) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: endPoint + '/' + usersGroup.id + '/user',
    statusOK: 200,
    toJSON: false,
    returnData: usersGroup,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify({
          userId: user.id
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, { groups: usersGroup });
};

export const deleteUserFromUsersGroup = ({usersGroup, user}) => {
    if (!usersGroup['id'] || !user['id']) return { status: 400, error: 'missing id attr' };

    const params = {
      endPoint: endPoint + '/' + usersGroup.id + '/user/' + user.id ,
      statusOK: 204,
      toJSON: false,
      returnData: usersGroup,
      requestConfig: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    return requestAPI(params, { groups: usersGroup });
  };
