import React from 'react';
import { withTheme } from '../../../Contexts/ThemeContext';
import Table from '../../../components/Table';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import { connect } from 'react-redux';
import TabComponent from './../../../components/Tab';
import {
  getServices,
  getMyServices,
  clearServicesState
} from '../../../services/redux/services/actions';

import Service, { SampleService } from '../../../models/Service/index';
import OptionsForRows from './OptionsForRows';
import WithDrawer from '../../../components/DrawerContainer';
import CreateService from '../Add/AddWizard';
import RemoveHoc from '../../helpers/RemoveComponent';
import Profile from '../Profile';
import { injectIntl } from "react-intl";
import { FormattedMessage } from "../../../Contexts/LanguageContext/index";
import FiltersFinder from '../../helpers/FiltersFinder';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent'

const RemoveComponent = RemoveHoc(
  ({ showModal }) => (
    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/services'} permission={'delete'} >
      <i onClick={showModal} className="uil uil-trash-alt remove" />
    </PrivateComponent>
  ),
  { class: Service, nameData: 'services' }
);
const ProfileComponent = WithDrawer(({ onChange, data }) => (
  <Profile onChange={onChange} data={data} />
));
const headers = [];
const filters = [];

headers.push({ label: '', key: 'categories', type: 'device-icon' });

['name', 'description'].forEach(index => {
  headers.push({
    label: SampleService.translations[index],
    key: index
  });
  filters.push({
    name: SampleService.translations[index],
    key: index,
    value: index
  });
});

filters.push({
  name: SampleService.translations['categories'],
  key: 'categories',
  value: 'categories',
});

headers.unshift({ label: '', key: 'serviceAddOn', type: 'serviceAddOn' });
headers.push({ label: SampleService.translations['createdAt'], key: 'createdAt', type: 'date' });
headers.push({ label: SampleService.translations['updatedAt'], key: 'updatedAt', type: 'date' });

const TablePaginated = Pagination(({ data, ...rest }) => (<Table {...rest} body={data} />));

const FindableTabsComponent = Finder(props => {
  const {
    data,
    theme,
    itemsPerPage,
    page,
    optionsForRow,
    onPageChange,
    onSubmit,
    onRowClick,
    typeOfData,
    itemsShowed,
    total,
    ...rest
  } = props;

  return (
    <TabComponent {...rest}>
      <TablePaginated
        optionsForRow={optionsForRow}
        data={data}
        header={headers}
        align={'left'}
        filters={filters}
        onSubmit={onSubmit}
        onPageChange={onPageChange}
        itemsPerPage={itemsPerPage}
        page={page}
        total={total}
        expandable={true}
        itemsShowed={itemsShowed}
        typeOfData={typeOfData}
        selectable={true}
        onRemove={RemoveComponent}
        onRowClick={onRowClick}
        entity={Service}
      />
      <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/services'} permission={'list-mine'} >
        <TablePaginated
          optionsForRow={optionsForRow}
          data={data}
          header={headers}
          align={'left'}
          filters={filters}
          onSubmit={onSubmit}
          onPageChange={onPageChange}
          itemsPerPage={itemsPerPage}
          page={page}
          total={total}
          expandable={true}
          itemsShowed={itemsShowed}
          typeOfData={'services'}
          selectable={true}
          onRemove={RemoveComponent}
          onRowClick={onRowClick}
          entity={Service}
        />
      </PrivateComponent>
    </TabComponent>
  );
}, CreateService, FiltersFinder);

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      itemsPerPage: 5,
      profileOpened: false,
      selectedtab: 0,
      itemsShowed: 5,
      selectedFilter: filters[0],
      selectedValueForFilter: '',
    };
  }

  componentDidMount() {
    const { page, itemsPerPage, selectedValueForFilter, selectedFilter } = this.state;
    getServices({ filters: { [selectedFilter.key]: selectedValueForFilter }, page, size: itemsPerPage });
  }

  selectDataSource = (filters, page, size, init = false) => {
    switch (this.state.selectedtab) {
      case 0:
        init && clearServicesState();
        getServices({ filters, page, size });
        break;
      case 1:
        init && clearServicesState();
        getMyServices({ filters, page, size });
        break;
      default:
        break;
    }
  }

  handleFilteredSearch = (value, filter) => {
    const { itemsPerPage } = this.state;
    if (filter && filter.key) {
      clearServicesState();
      this.selectDataSource({ [filter.key]: value }, 1, itemsPerPage, true);
      this.setState({
        selectedFilter: filter,
        selectedValueForFilter: value,
        page: 1,
      });
    }
  };

  handleOnPageChange = (page) => {
    const {selectedValueForFilter, selectedFilter, itemsPerPage} = this.state;
      this.selectDataSource({[selectedFilter.key]: selectedValueForFilter}, page, itemsPerPage)
      this.setState({
        page: page
      });
  };

  handleOnProfileEvent = opened => {
    this.setState({
      profileOpened: opened
    });
  };

  handleOpenProfile = data => {
    this.setState({
      profileOpened: true,
      activeService: data
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedtab, itemsPerPage, selectedFilter, selectedValueForFilter } = this.state;
    if (selectedtab !== prevState.selectedtab) {
      this.setState({
        page: 1
      }, () => this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter || '' }, 1, itemsPerPage, true))
    }
  }

  handleOnTabsChange = selectedtab => {
    if (this.state.selectedtab !== selectedtab) {
      this.setState({
        selectedtab
      });
    }
  };

  render() {
    const { path, theme, services, fetching, total, intl } = this.props;
    const {
      itemsPerPage,
      selectedtab,
      profileOpened,
      activeService,
      page,
      itemsShowed
    } = this.state;
    const servicesByData = services.map(service => service.toJson());

    return (
      <>
        <FindableTabsComponent
          titles={[
          <FormattedMessage id='services.list.tab.services' />, 
          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/services'} permission={'list-mine'} >
          <FormattedMessage id='services.list.tab.myservices' /></PrivateComponent>]}
          placeholder={intl.formatMessage({ id: "finder.box.placeholder" })}
          onChange={this.handleOnTabsChange}
          selectedtab={selectedtab}
          fetching={fetching}
          theme={theme}
          data={servicesByData}
          optionsForRow={props => (
            <OptionsForRows {...props} viewClick={this.handleOpenProfile} />
          )}
          page={page}
          total={total}
          expandable={true}
          onSubmit={this.handleOnSubmit}
          onPageChange={this.handleOnPageChange}
          itemsShowed={itemsShowed}
          itemsPerPage={itemsPerPage}
          onRowClick={this.handleOpenProfile}
          typeOfData={'services'}
          filters={filters}
          externalFilter={this.handleFilteredSearch}
          selectedFilter={this.state.selectedFilter}
        />
        {profileOpened && activeService && (
          <ProfileComponent
            onChange={this.handleOnProfileEvent}
            data={activeService}
            path={path}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  services: state.get('services').get('list').toJS(),
  total: state.get('services').get('total'),
  fetching: state.get('services').get('fetching')
});

export default connect(
  mapStateToProps,
  {}
)(withTheme(injectIntl(List)));
