export const ADD_REPORT_REQUEST = 'ADD_REPORT_REQUEST';
export const ADD_REPORT_SUCCESS_FLAG = 'ADD_REPORT_SUCCESS_FLAG';
export const ADD_REPORT_ERROR = 'ADD_REPORT_ERROR';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';

export const ADD_REPORTPAGE_REQUEST = 'ADD_REPORTPAGE_REQUEST';
export const ADD_REPORTPAGE_SUCCESS_FLAG = 'ADD_REPORTPAGE_SUCCESS_FLAG';
export const ADD_REPORTPAGE_ERROR = 'ADD_REPORTPAGE_ERROR';
export const ADD_REPORTPAGE_SUCCESS = 'ADD_REPORTPAGE_SUCCESS';

export const MODIFY_REPORT_REQUEST = 'MODIFY_REPORT_REQUEST';
export const MODIFY_REPORT_SUCCESS_FLAG = 'MODIFY_REPORT_SUCCESS_FLAG';
export const MODIFY_REPORT_SUCCESS = 'MODIFY_REPORT_SUCCESS';
export const MODIFY_REPORT_ERROR = 'MODIFY_REPORT_ERROR';


export const DELETE_REPORTS_REQUEST = 'DELETE_REPORTS_REQUEST';
export const DELETE_REPORTS_SUCCESS = 'DELETE_REPORTS_SUCCESS';
export const DELETE_REPORTS_ERROR = 'DELETE_REPORTS_ERROR';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_ROLES_REQUEST = 'GET_REPORT_ROLES_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

export const GET_MY_REPORTS_REQUEST = 'GET_MY_REPORTS_REQUEST';
export const GET_MY_REPORTS_ERROR = 'GET_MY_REPORTS_ERROR';
export const GET_MY_REPORTS_SUCCESS = 'GET_MY_REPORTS_SUCCESS';

export const CLEAR_REPORTS_STATE = 'CLEAR_REPORTS_STATE';

export const GENERATE_REPORTS_REQUEST = 'GENERATE_REPORTS_REQUEST';
export const GENERATE_REPORTS_ERROR = 'GENERATE_REPORTS_ERROR';

export const ERRORFETCHING_REPORT_RESET = 'ERRORFETCHING_REPORT_RESET';
export const UPDATE_REPORTS_STATE = 'UPDATE_REPORTS_STATE';
