import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withTheme } from '../../../Contexts/ThemeContext';
import Table from '../../../components/Table';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import TabComponent from '../../../components/Tab';
import {
  getKpis,
  getMyKpis,
  clearKpisState,
} from '../../../services/redux/kpis/actions';

import Kpi, { SampleKpi } from '../../../models/Kpi/index';
import OptionsForRows from './OptionsForRows';
import WithDrawer from '../../../components/DrawerContainer';
// import CreateKpi from '../Add/AddWizard';
import RemoveHoc from '../../helpers/RemoveComponent';
import Profile from '../Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import FiltersFinder from '../../helpers/FiltersFinder/index';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';
import CreateKpi from '../Add/AddWizard';

const RemoveComponent = RemoveHoc(
  ({ showModal }) => (
    <PrivateComponent checkLogin checkPermission microsService="/app/kpis" permission="delete">
      <i onClick={showModal} className="uil uil-trash-alt remove" />
    </PrivateComponent>
  ),
  { class: Kpi, nameData: 'kpis' },
);
const ProfileComponent = WithDrawer(({ onChange, data }) => (
  <Profile onChange={onChange} data={data} />
));

const headers = [];
const filters = [];

['name'].forEach((index) => {
  headers.push({
    label: SampleKpi.translations[index],
    key: index,
  });
});
['name', 'categories'].forEach((index) => {
  filters.push({
    name: SampleKpi.translations[index],
    key: index,
    value: index,
  });
});

headers.push({ label: SampleKpi.translations.value, key: 'value', type: 'value' });
headers.push({ label: SampleKpi.translations.state, key: 'state', type: 'state' });
headers.push({ label: SampleKpi.translations.updatedAt, key: 'updatedAt', type: 'timeAgo' });
headers.push({ label: SampleKpi.translations.categories, key: 'categories', type: 'array:translated' });


const TablePaginated = Pagination(({ data, ...rest }) => (<Table {...rest} body={data} />));

const FindableTabsComponent = Finder((props) => {
  const {
    data,
    theme,
    itemsPerPage,
    page,
    optionsForRow,
    onPageChange,
    onSubmit,
    onRowClick,
    typeOfData,
    itemsShowed,
    total,
    ...rest
  } = props;

  return (
    <TabComponent {...rest}>
      <TablePaginated
        optionsForRow={optionsForRow}
        data={data}
        header={headers}
        align="left"
        filters={filters}
        onSubmit={onSubmit}
        onPageChange={onPageChange}
        itemsPerPage={itemsPerPage}
        page={page}
        total={total}
        expandable
        itemsShowed={itemsShowed}
        typeOfData={typeOfData}
        selectable
        onRemove={RemoveComponent}
        onRowClick={onRowClick}
        entity={Kpi}
      />
      <PrivateComponent checkLogin checkPermission microsService="/app/kpis" permission="list-mine">
        <TablePaginated
          optionsForRow={optionsForRow}
          data={data}
          header={headers}
          align="left"
          filters={filters}
          onSubmit={onSubmit}
          onPageChange={onPageChange}
          itemsPerPage={itemsPerPage}
          page={page}
          total={total}
          expandable
          itemsShowed={itemsShowed}
          typeOfData="kpis"
          selectable
          onRemove={RemoveComponent}
          onRowClick={onRowClick}
          entity={Kpi}
        />
      </PrivateComponent>
    </TabComponent>
  );
}, CreateKpi, FiltersFinder);

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      itemsPerPage: 5,
      profileOpened: false,
      selectedtab: 0,
      itemsShowed: 5,
      selectedFilter: filters[0],
      selectedValueForFilter: '',
    };
  }

  componentDidMount() {
    const { page, itemsPerPage, selectedFilter } = this.state;
    getKpis({ filters: { [selectedFilter.key]: '' }, page, size: itemsPerPage });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedtab, selectedFilter, itemsPerPage, selectedValueForFilter,
    } = this.state;

    if (selectedtab !== prevState.selectedtab) {
      this.setState({
        page: 1,
      }, () => this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter || '' }, 1, itemsPerPage, true));
    }
  }

  handleFilteredSearch = (value, filter) => {
    const { itemsPerPage } = this.state;
    if (filter && filter.key) {
      clearKpisState();
      this.selectDataSource({ [filter.key]: value }, 1, itemsPerPage, true);
      this.setState({
        selectedFilter: filter,
        selectedValueForFilter: value,
        page: 1,
      });
    }
  };

  handleOnProfileEvent = (opened) => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = (data) => {
    this.setState({
      profileOpened: true,
      activeKpi: data,
    });
  };

  handleOnPageChange = (page) => {
    const { selectedValueForFilter, selectedFilter, itemsPerPage } = this.state;
    this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter }, page, itemsPerPage);
    this.setState({
      page,
    });
  };

  selectDataSource = (filters, page, size, init = false) => {
    switch (this.state.selectedtab) {
      case 0:
        init && clearKpisState();
        getKpis({ filters, page, size });
        break;
      case 1:
        init && clearKpisState();
        getMyKpis({ filters, page, size });
        break;
      default:
        break;
    }
  }

  handleOnTabsChange = (selectedtab) => {
    if (this.state.selectedtab !== selectedtab) {
      this.setState({
        selectedtab,
      });
    }
  };

  render() {
    const {
      path, theme, kpis, fetching, total, intl,
    } = this.props;
    const {
      itemsPerPage,
      selectedtab,
      profileOpened,
      activeKpi,
      page,
      itemsShowed,
    } = this.state;

    const kpisByData = kpis.map((kpi) => kpi.toJson());

    return (
      <>
        <FindableTabsComponent
          titles={[<FormattedMessage id="kpis.list.tab.kpis" />,
            <PrivateComponent checkLogin checkPermission microsService="/app/kpis" permission="list-mine">
              <FormattedMessage id="kpis.list.tab.mykpis" />
            </PrivateComponent>]}
          placeholder={intl.formatMessage({ id: 'finder.box.placeholder' })}
          onChange={this.handleOnTabsChange}
          selectedtab={selectedtab}
          fetching={fetching}
          theme={theme}
          data={kpisByData}
          optionsForRow={(props) => (
            <OptionsForRows {...props} viewClick={this.handleOpenProfile} />
          )}
          page={page}
          total={total}
          expandable
          itemsShowed={itemsShowed}
          itemsPerPage={itemsPerPage}
          onRowClick={this.handleOpenProfile}
          typeOfData="kpis"
          filters={filters}
          externalFilter={this.handleFilteredSearch}
          selectedFilter={this.state.selectedFilter}
          onPageChange={this.handleOnPageChange}
        />
        {profileOpened && activeKpi && (
          <ProfileComponent
            onChange={this.handleOnProfileEvent}
            data={activeKpi}
            path={path}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  kpis: state.get('kpis').get('list').toJS(),
  total: state.get('kpis').get('total'),
  fetching: state.get('kpis').get('fetching'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(injectIntl(List)));
