import React from 'react';
import { Link } from '@reach/router';
import AppBar from '../../elements/Bar';
import DefaultOpenMenuButton from './DefaultOpenMenuButton';
import { withTheme } from '../../Contexts/ThemeContext';
import { withMenuCtxt } from '../../Contexts/MenuContext';
import { SideOfHeader } from './SideOfHeader';
import LogoEmplacement from './LogoEmplacement';
import DefaultRightSide from './RightSide';

class Header extends React.PureComponent {
  render() {
    const {
      children,
      reverse,
      logotype,
      logoRoute,
      theme,
      position,
      userMe,
      onViewProfileClick,
      menuElementsSelected,
      hiddenSubmenu,
      siteTitle,
    } = this.props;
    return (
      <AppBar
        position={position}
        background={theme.appBar.background}
        minHeight={theme.appBar.height}
        reverse={reverse}
        css="z-index: 5000; justify-content: flex-start"
      >
        <SideOfHeader minHeight={theme.appBar.height} reverse={reverse}>
          <LogoEmplacement className='logoFiwooCont'>
            <Link to={logoRoute}>
              {logotype ? (
                <img src={logotype} alt="logotype" className="logo" />
              ) : (
                <h1>{siteTitle}</h1>
              )}
            </Link>
          </LogoEmplacement>
          {/* <div>
            <ExpandMenuButton />
          </div> */}
        </SideOfHeader>
        {children}
        <SideOfHeader>
          <DefaultRightSide
            userMe={userMe}
            onViewProfileClick={onViewProfileClick}
            menuElementsSelected={menuElementsSelected}
            hiddenSubmenu={hiddenSubmenu}
          />
        </SideOfHeader>
      </AppBar>
    );
  }
}

Header.defaultProps = {
  position: 'static',
  nodeElement: 'body',
  reverse: false,
  siteTitle: 'Site Title',
  logoRoute: '/',
  openComponent: DefaultOpenMenuButton,
  rightSide: null,
  InnerMenu: null,
  logotype: null,
  handleMenuState: () => {},
};

export default withTheme(withMenuCtxt(Header));
