import {
  takeLatest, takeEvery, call, put, all, fork,
} from 'redux-saga/effects';

import Report from '../../../models/Report';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/reports';

/**
 * Actions for WATCHERS
 * */
// Try to recover one report from db
export function* getReportThroughApi({ payload }) {
  try {
    const response = yield call(Api.readReport, payload);
    if (response.status === 200) {
      const reportFromDB = new Report(response.data.object);
      yield put({ type: TYPES.GET_REPORT_SUCCESS, payload: reportFromDB });
    } else {
      yield put({ type: TYPES.GET_REPORT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_REPORT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all service form DB
export function* getReportsThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readReports,
      payload.page,
      payload.size,
      payload.filters,
      'all',
    );
    if (response.status === 200) {
      const reports = response.data.objects.map((report) => new Report(report));
      yield put({
        type: TYPES.GET_REPORTS_SUCCESS,
        payload: { reports, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_REPORTS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_REPORTS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getMyReportsThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readReports,
      payload.page,
      payload.size,
      payload.filters,
      'mine',
    );
    if (response.status === 200) {
      const reports = response.data.objects.map((report) => new Report(report));
      yield put({
        type: TYPES.GET_MY_REPORTS_SUCCESS,
        payload: { reports, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_MY_REPORTS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MY_REPORTS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* addReportThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.addReport,
      payload,
    );
    if (response.status === 201) {
      const report = new Report(response.data.object);
      yield put({
        type: TYPES.ADD_REPORT_SUCCESS,
        payload: report,
      });
    } else {
      yield put({ type: TYPES.ADD_REPORT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (e) {
    yield put({ type: TYPES.ADD_REPORT_ERROR, payload: e });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: e });
  }
}

// Try to update report in DB
export function* updateReportThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateReport, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_REPORT_SUCCESS,
        payload: new Report(response.data.object),
      });
    } else {
      yield put({ type: TYPES.MODIFY_REPORT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_REPORT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete report in DB
export function* deleteReportsThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteReports, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_REPORTS_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_REPORTS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_REPORTS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to generate report in DB
export function* generateReportsThroughApi({ payload }) {
  try {
    const response = yield call(Api.generateReports, payload);
    if (response.status !== 200) {
      yield put({ type: TYPES.GENERATE_REPORTS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.GENERATE_REPORTS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


/**
 * WATCHERS
 */
// Watcher looking for GET_REPORTS_REQUEST
function* watcherGetReports() {
  yield takeLatest('GET_REPORTS_REQUEST', getReportsThroughApi);
}

// Watcher looking for GET_REPORT_REQUEST
function* watcherGetReport() {
  yield takeLatest('GET_REPORT_REQUEST', getReportThroughApi);
}
// Watcher looking for GET_REPORTS_REQUEST
function* watcherGetMyReports() {
  yield takeLatest('GET_MY_REPORTS_REQUEST', getMyReportsThroughApi);
}

function* watcherAddReports() {
  yield takeLatest('ADD_REPORT_REQUEST', addReportThroughApi);
}

// Watcher looking for MODIFY_REPORT_REQUEST
function* watcherUpdateReports() {
  yield takeLatest('MODIFY_REPORT_REQUEST', updateReportThroughApi);
}

// Watcher looking for DELETE_REPORTS_REQUEST
function* watcherDeleteReports() {
  yield takeEvery('DELETE_REPORTS_REQUEST', deleteReportsThroughApi);
}

// Watcher looking for GENERATE_REPORTS_REQUEST
function* watcherGenerateReports() {
  yield takeEvery('GENERATE_REPORTS_REQUEST', generateReportsThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetReport),
    fork(watcherGetReports),
    fork(watcherGetMyReports),
    fork(watcherAddReports),
    fork(watcherUpdateReports),
    fork(watcherDeleteReports),
    fork(watcherGenerateReports),
  ]);
}
