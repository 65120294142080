import React from 'react';
import './styles.scss';
import { FormattedMessage } from '../../Contexts/LanguageContext';
export class CardButton extends React.Component {
    constructor(props) {
        super(props)
        
    }



    render() {
        const { label, text, icon } = this.props;
        return <div onClick={this.props.onClick} className={`cardContainer${this.props.isActive ? ' active' : ''}`}>


            <div>
                <img src={icon} alt="Groups" />
            </div>
            <div>
                <h2><FormattedMessage id={label}/></h2>
                <span><FormattedMessage id={text}/></span>
            </div>


        </div>

    }
}

export default CardButton;
