import { takeLatest, call, put, all, fork } from 'redux-saga/effects';

import {
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILED
} from './types';

import * as Api from '../../api/auth';
import { getUserMeThroughApi } from '../users/saga';

// import * as TYPESnotify from "../notify/types";

export function* loginUserThroughApi({ payload }) {
  try {
    const response = yield call(Api.loginUser, payload);
    if (response.status === 201) {
      yield call(saveUserTokenInLocalStorage, response.data.object.token);
      yield* getUserMeThroughApi();
      yield put({ type: LOGIN_REQUEST_SUCCESS });

    } else {
      yield put({ type: LOGIN_REQUEST_FAILED, payload: response });
    }
  } catch (error) {
    yield put({ type: LOGIN_REQUEST_FAILED, payload: error });
  }
}

const saveUserTokenInLocalStorage = data => {
  localStorage.setItem('user_id', data.value);
  localStorage.setItem('access_token', data.value);
  localStorage.setItem('refresh_token', data.refreshToken);
  data.expirationDate &&
    localStorage.setItem('expiration_date', data.expirationDate);
};

export const saveUserDataInLocalStorage = data => {
    //Because some users may not have name or surname...
    const completeData = { name: data.id, surname: '', ...data };
    localStorage.setItem("user_data", JSON.stringify(completeData));
};

function* watcherLoginUsers() {
  yield takeLatest('LOGIN_REQUEST', loginUserThroughApi);
}

export default function* rootSaga() {
  yield all([fork(watcherLoginUsers)]);
}
