import React, { useEffect, useState, useCallback } from 'react';
import Chip from '../../elements/Chip';
import { FormattedMessage } from '../../Contexts/LanguageContext';
import { chipsPlacement } from './WithVariables.module.scss';

const withVariables = (Component) => ({
  devices, value: _value, onChange, name, ...props
}) => {
  const [componentValue, setComponentValue] = useState([]);
  const [tweetLength, setTweetLength] = useState(0);

  const getSnippers = useCallback(() => devices.map(
    (d) => d.attributes.map((a, i) => ({
      id: i,
      value: `${d.device}:${a.name}`,
      title: `${d.device}:${a.name}`,
    })),
  ).flat(), [devices]);

  const replaceDots = (snipper) => `${snipper.replace(':', '.')}`;

  const parseToValue = (value) => {
    let parsedValue = value;
    getSnippers().forEach((snipper) => {
      const regex = new RegExp(`${snipper.value}`, 'g');
      parsedValue = parsedValue.replace(regex, '${'.concat(replaceDots(snipper.value), '}'));
    });
    const noEspaceBeforeRegex = new RegExp(/(\S)\${/);
    const noEspaceAfterRegex = new RegExp(/}(\S)/);

    parsedValue = parsedValue.replace(noEspaceBeforeRegex, (str, p1) => p1.concat(' ${'));
    parsedValue = parsedValue.replace(noEspaceAfterRegex, (str, p1) => '} '.concat(p1));

    return parsedValue;
  };

  const parseToTag = useCallback((value) => {
    const parsedSnippers = getSnippers().map((snipper) => ({ dotValue: `$\{${replaceDots(snipper.value)}}`, value: snipper }));
    let parsedValue = value;
    parsedSnippers.forEach((snipper) => {
      const regex = new RegExp(`\\${snipper.dotValue}`, 'gi');
      if (regex.test(value)) {
        parsedValue = parsedValue.replace(regex, JSON.stringify([[snipper.value]]));
      }
    });

    return parsedValue;
  }, [getSnippers]);

  const adjustedCharCounter = useCallback((value) => {
    const parsedValue = parseToTag(value);

    const regexp = /\[\[\{(.*?)\}\]\]/g;
    const matches = parsedValue.match(regexp);
    if (matches !== null) {
      const matchesLength = matches.reduce((acc, curr) => acc + curr.length, 0);

      return parsedValue.length - matchesLength + matches.length * 10;
    }

    return (parsedValue.trim()).length;
  }, [parseToTag]);

  const handleOnChange = (e) => {
    if (e.detail && e.detail.textContent) {
      onChange({
        target: { value: parseToValue(e.detail.textContent), name },
      });
    } else if (e.detail && e.detail.textContent === '') {
      onChange({
        target: { value: '', name },
      });
    }

    if (e.lengthOk) {
      onChange({
        target: { value: 'true', name: 'lengthOk' },
      });
    } else if (!e.lengthOk) {
      onChange({
        target: { value: '', name: 'lengthOk' },
      });
    }

    if (e.type === 'add') {
      let newValue = _value;

      const index = _value.indexOf('#');
      if (index >= 0) newValue = _value.substring(0, index).concat(`$\{${replaceDots(e.detail.data.value)}}`);

      onChange({
        target: { value: newValue, name },
      });
    }

    if (e.type === 'remove') {
      const newValue = _value.replace(`$\{${replaceDots(e.detail.data.value)}}`, '');

      onChange({
        target: { value: newValue.trim(), name },
      });
    }
  };

  const getChips = () => devices.map(
    (d) => d.attributes.map((a) => (
      <Chip
        dismissible={false}
        text={`${d.device}:${a.name}`}
        uppercase={false}
      />
    )),
  );

  useEffect(() => {
    setComponentValue(parseToTag(_value));
  }, [_value, parseToTag]);

  useEffect(() => {
    setTweetLength(adjustedCharCounter(_value));
  }, [_value, adjustedCharCounter]);

  return (
    <>
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        value={componentValue}
        tweetLength={tweetLength}
        onChange={handleOnChange}
        tags={getSnippers()}
        name={name}

      />
      <p className={chipsPlacement}>
        <FormattedMessage id="withVariables.availableTags" />
        :
        {getChips()}
      </p>
    </>
  );
};

export default withVariables;
