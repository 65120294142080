import React, { useState, useRef, useEffect } from 'react';
import {
  TweenMax, Power3, TimelineMax,
} from 'gsap/all';
import { ReactComponent as Toggler } from '../../images/toggler.inline.svg';
import './styles.scss';

const MenuToggler = (props) => {
  const isOpen = props;
  const [isOpenValue, setIsOpenValue] = useState(isOpen);
  const isOver = useRef(false);
  const togglerRef = useRef(null);

  useEffect(() => {
    if (togglerRef) {
      TweenMax.staggerFromTo(togglerRef.current.children[0].children[1].children, 1, {
        alpha: 0,
        force3D: !0,
        x: -5,
      }, {
        alpha: 1,
        x: 0,
        ease: Power3.easeInOut,
      }, 0.2);
    }
  }, []);

  const onClick = () => {
    if (isOver.current) {
      new TimelineMax()
        .to(togglerRef.current.children[0].children[1], 0.2, {
          onStart() {
            isOver.current = false;
          },
          transformOrigin: '50% 50%',
          scale: 1,
          ease: Power3.easeInOut,
        });
    }
    if (isOpenValue) {
      new TimelineMax()
        .to([togglerRef.current.children[0].children[1].children[1],
          togglerRef.current.children[0].children[1].children[3]],
        0.4,
        {
          onStart() {
            TweenMax.to(togglerRef.current.children[0].children[1].children[3], 1, {
              alpha: 0,
            });
          },
          x: -2.6,
          ease: Power3.easeInOut,
        });
    } else {
      new TimelineMax()
        .to(
          [togglerRef.current.children[0].children[1].children[1],
            togglerRef.current.children[0].children[1].children[3]],
          0.4,
          {
            onStart() {
              TweenMax.to(togglerRef.current.children[0].children[1].children[3], 1, {
                alpha: 1,
              });
            },
            x: 0,
            ease: Power3.easeInOut,
          },
        );
    }
    setIsOpenValue(!isOpenValue);
  };

  const onOver = () => {
    if (!isOpenValue && !isOver.current) {
      new TimelineMax()
        .to(togglerRef.current.children[0].children[1], 0.2, {
          onStart() {
            isOver.current = true;
          },
          transformOrigin: '50% 50%',
          scale: 0.9,
          ease: Power3.easeInOut,
        });
    } else {
      TweenMax.to(
        [togglerRef.current.children[0].children[1].children[1],
          togglerRef.current.children[0].children[1].children[3]],
        0.5, {
          x: 1,
          ease: Power3.easeOut,
        },
      );
    }
  };

  const onOut = () => {
    if (!isOpenValue && isOver.current) {
      new TimelineMax()
        .to(togglerRef.current.children[0].children[1], 0.2, {
          onStart() {
            isOver.current = false;
          },
          transformOrigin: '50% 50%',
          scale: 1,
          ease: Power3.easeInOut,
        });
    } else if (!isOver.current && isOpenValue) {
      new TimelineMax().to(
        [togglerRef.current.children[0].children[1].children[1],
          togglerRef.current.children[0].children[1].children[3]],
        0.5,
        {
          x: 0,
          ease: Power3.easeOut,
          onComplete() {
            isOver.current = false;
          },
        },
      );
    }
  };

  return (
    <div
      ref={togglerRef}
      onMouseEnter={onOver}
      onMouseLeave={onOut}
      onClick={onClick}
      onKeyPress={() => {}}
      role="presentation"
    >
      <Toggler className="toggler" />
    </div>
  );
};

export default MenuToggler;
