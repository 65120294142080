import { categories, Components, subCategories } from '../../utilsConfigurationSheet';

export const LinkedTableConfigurationSheet = {
  headerColors: [{
    name: 'headerTableBackground',
    component: Components.colorPicker,
    categories: [categories.COLORS],

  },
  {
    name: 'headerTableColorText',
    component: Components.colorPicker,
    categories: [categories.COLORS],

  }],
  colorConditions: [{
    name: 'conditions',
    component: Components.tableConditions,
    categories: [categories.CONDITIONS],
  }],
  columns: [{
    name: 'listComponent',
    component: Components.listComponent,
    categories: [categories.LABELS, categories.APPEARANCE],
  }],
};

export const MapConfigurationSheet = {
  configuration: [
    {
      name: 'iconCategory',
      component: Components.switch,
      categories: [categories.APPEARANCE],
    },
    {
      name: 'realTimeUpdates',
      component: Components.switch,
      categories: [categories.DATA],
    },
  ],
  mapStyle: [{
    name: 'viewType',
    component: Components.mapStyle,
    categories: [categories.CUSTOM],
    subCategories: [subCategories.MAP],
  }],
  colorsDataConfig: [{
    name: 'colorsDataMap',
    component: Components.colorsDataMap,
    categories: [categories.COLORS, categories.LABELS],
    subCategories: [subCategories.categoryColors, subCategories.categoryAlias],
    subMenus: [
      {
        name: 'advancedLegendColors',
        component: Components.advancedLegendColors,
        categories: [categories.COLORS, categories.LABELS],
        subCategories: [subCategories.categoryColors, subCategories.categoryAlias],
      },
      {
        name: 'advancedLegendNames',
        component: Components.advancedLegendNames,
        categories: [categories.LABELS],
        subCategories: [subCategories.categoryColors, subCategories.categoryAlias],
      },
    ],
  }],
};
