/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ModalHOC from '../../../elements/Modal/index';
import Button from '../../../elements/Button/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import Field from '../../../elements/Field';
import CommonView from '../../CommonView';
import Device from '../../../models/Device';
import { sendCommand as sendCommandApi } from '../../../services/api/devices';
import SendCommandOkIcon from '../../../configuration/icons/svg/send-command-ok.svg';
import './styles.scss';
import SuccessComponent from '../../helpers/SuccessPage';

const ModalTitle = ({ typeOfData, hideModal }) => (
  <>
    <FormattedMessage id={typeOfData.concat('.info.sendCommand')} />
    <i className="uil uil-times close-button" onClick={hideModal} />
  </>
);

ModalTitle.propTypes = {
  typeOfData: PropTypes.string,
  hideModal: PropTypes.func,
};

ModalTitle.defaultProps = {
  typeOfData: '',
  hideModal: () => {},
};

const SendCommandMsg = () => (
  <FormattedMessage
    id="options.sendCommand"
    defaultMessage="Send command"
    description="Send command option"
    component="span"
  />
);

const FinishMsg = () => (
  <FormattedMessage
    id="Wizard.finish"
    defaultMessage="Finish"
    description="Finish"
    component="span"
  />
);

const sendCommand = async (e, device, command) => {
  e.preventDefault();
  e.stopPropagation();

  return sendCommandApi(device, command);
};

const closeModal = (callback) => callback(false);

const mapStateToProps = (state) => ({
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
});

const ModalContent = injectIntl(
  connect(
    mapStateToProps,
    {},
  )(
    class ModalContentClass extends CommonView {
      constructor(props) {
        super({ props });
        this.state = {
          command: {
            name: '',
            value: '',
          },
          errors: {},
          isAdvancedOption: false,
        };
      }

      async componentWillMount() {
        const device = new Device(this.props.rowContent);
        device.get();
      }

      getDevice() {
        return this.props.devices.find((o) => o.id === this.props.rowContent.id);
      }

      handleSelectChangeCustom = (name, value) => {
        const command = { ...this.state.command };
        command[name] = value;
        if (name === 'name') {
          this.setState({ command, isAdvancedOption: this.isEmptyAvailableCommands(value) });
        } else {
          this.setState({ command });
        }
      };

      isEmptyAvailableCommands = (commandName) => {
        const device = this.getDevice();
        if (device && device.command_attributes) {
          const command = device.command_attributes.find((o) => o.name === commandName);
          if (command.available_commands) {
            return command.available_commands.length === 0;
          }
        }
        return true;
      };

      handleInputChangeCustom = (event) => {
        const command = { ...this.state.command };
        const { target } = event;
        command.value = target.value;
        this.setState({ command });
      };

      handleButton = async (e) => {
        const errors = this.validate();
        if (errors) {
          this.setState({ errors });
        } else {
          const response = await sendCommand(
            e,
            this.getDevice(),
            this.state.command,
            this.props.hideModal,
          );
          if (response.status === 200) {
            this.setState({ successful: true });
          } else {
            // TODO Show error
          }
        }
      };

      validate = () => {
        const { command } = this.state;
        const errors = {};
        if (!command.name) {
          errors.name = (
            <FormattedMessage id="devices.sendCommand.select.validate" />
          );
        }
        if (!command.value) {
          errors.value = (
            <FormattedMessage id="devices.sendCommand.value.validate" />
          );
        }
        return Object.keys(errors).length > 0 ? errors : null;
      };

      changeAdvancedOption = () => this.setState(
        { isAdvancedOption: !this.state.isAdvancedOption },
      );

      getTextOption = () => (this.state.isAdvancedOption
        ? (
          <FormattedMessage
            id="options.sendCommand.simple"
            component="span"
          />
        )
        : (
          <FormattedMessage
            id="options.sendCommand.advanced"
            component="span"
          />
        ));

      render() {
        const { errors, isAdvancedOption } = this.state;
        const device = this.getDevice();
        const options = !device || !device.command_attributes
          ? []
          : device.command_attributes.map((o) => ({
            name: o.name,
            value: o.name,
          }));

        let valueOptions = [];
        if (!isAdvancedOption) {
          if (device?.command_attributes?.find(
            (o) => (o.name === this.state.command.name),
          )) {
            valueOptions = device.command_attributes
              .find((o) => o.name === this.state.command.name).available_commands;
          } else {
            valueOptions = [];
          }
        }

        return this.state.successful ? (
          <>
            <Success
              className="modal-content-sendcommand"
              title={this.props.intl.formatMessage({
                id: 'devices.sendCommand.successful',
              })}
              icon={SendCommandOkIcon}
              created={this.state.command}
            />
            <div className="w-100 text-right">
              <Button
                className="btn btn-primary btn-sendcommand btn-finish m-5"
                onClick={() => closeModal(this.props.hideModal)}
              >
                <FinishMsg />
              </Button>
            </div>
          </>
        ) : (
          <>
            <Row className="w-100">
              <Col md={4}>
                <Field
                  name="name"
                  type="select"
                  options={options}
                  placeholder={this.props.intl.formatMessage({
                    id: 'devices.sendCommand.select',
                  })}
                  value={this.state.command.name}
                  onChange={this.handleSelectChangeCustom}
                  helperText={errors.name}
                  error={!!errors.name}
                />
              </Col>

              <Col md={4}>
                { isAdvancedOption
                  ? (
                    <Field
                      name="value"
                      type="text"
                      placeholder={this.props.intl.formatMessage({
                        id: 'devices.sendCommand.value',
                      })}
                      value={this.state.command.value}
                      onChange={this.handleInputChangeCustom}
                      helperText={errors.value}
                      error={!!errors.value}
                    />
                  )
                  : (
                    <Field
                      name="value"
                      type="select"
                      options={valueOptions}
                      placeholder={this.props.intl.formatMessage({
                        id: 'devices.sendCommand.selectValue',
                      })}
                      value={this.state.command.value}
                      onChange={this.handleSelectChangeCustom}
                      helperText={errors.name}
                      error={!!errors.name}
                    />
                  )}
                <a onClick={this.changeAdvancedOption}>{this.getTextOption()}</a>
              </Col>
              <Col md={4}>
                <Button
                  className="btn btn-primary m-0 btn-sendcommand w-100"
                  onClick={this.handleButton}
                >
                  <SendCommandMsg />
                </Button>
              </Col>
            </Row>
          </>
        );
      }
    },
  ),
);

export const Content = ({ created }) => (
  <div className="passwordPanel w-100">
    <div>
      <dt>Commando</dt>
      <dd>{created.name}</dd>
    </div>
    <div className="divisor" />
    <div>
      <dt>Valor</dt>
      <dd>{created.value}</dd>
    </div>
  </div>
);

Content.propTypes = {
  created: PropTypes.string,
};

Content.defaultProps = {
  created: '',
};

export const Success = ({
  created, title, className, icon,
}) => (
  <SuccessComponent
    className={className}
    title={title}
    content={<Content created={created} />}
    icon={icon}
  />
);

Success.propTypes = {
  created: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Success.defaultProps = {
  created: '',
  title: '',
  className: '',
  icon: '',
};

export default (ButtonOpen, typeOfData) => {
  class SendCommandInner extends React.PureComponent {
    render() {
      const { rowContent } = this.props;
      const ModalDOM = ModalHOC({
        ModalTitle: ({ hideModal }) => (
          <ModalTitle typeOfData={typeOfData.nameData} hideModal={hideModal} />
        ),
        ButtonOpen,
        ModalContent: ({ hideModal }) => (
          <ModalContent
            rowContent={rowContent}
            typeOfData={typeOfData.nameData}
            hideModal={hideModal}
          />
        ),
      });

      return <ModalDOM size="sm" />;
    }
  }

  SendCommandInner.defaultProps = {
    rowContent: {},
  };

  SendCommandInner.propTypes = {
    rowContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  return SendCommandInner;
};
