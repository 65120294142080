import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

class Starter extends React.Component {
  render() {
    const { children, className } = this.props;
    return (
      <Container fluid={true} className="starterWrapper">
        <Row className="text-center">
          <Col sm={{ size: 8, offset: 2 }}>
            <div className={`starter ${className}`}>{children}</div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Starter.defaultProps = {
  children: () => {}
};

Starter.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default Starter;
