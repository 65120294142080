import React from 'react';
import { PropTypes } from 'prop-types';

import Wizard from '../../../components/Wizard';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import AddTableButton from '../../../components/AddTableButton';
import { ButtonCustom } from '../../../elements/Button';
import { isMobile } from '../../../helpers/getBreakpoint';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';
import CreateRuleIcon from '../../../configuration/icons/svg/ico-create-rule.svg';
import WizardStep from '../../../components/Wizard/components/WizardStep';
import AssignPermissions from '../../helpers/AssignPermissions/index';
import ConditionsUtils from '../helpers/ConditionsUtils';
import Rule from '../../../models/Rule/index';

import AssignActions from './steps/AssignActions';
import CreateRule from './steps/CreateRule';
import ConfigureRule from './steps/ConfigureRule';
import CreateAction from './steps/CreateAction';
import EstablishedActions from './steps/EstablishedActions';
import Success from './steps/Success';

const addRulesLabel = (
  <FormattedMessage
    id="rules.add.title"
    defaultMessage="add rules"
    description="Label of add rules"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/rules"
        permission="create"
      >
        <ButtonCustom type="circular" name="plus" onClick={showModal} />
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/rules"
      permission="create"
    >
      <AddTableButton onClick={showModal} label={addRulesLabel} />
    </PrivateComponent>
  );
};

class CreateRules extends ConditionsUtils {
  constructor(props) {
    super({ props });
  }

  handleOnSave = (state, entity, onContinue) => {
    const { newEntity } = state;
    const { conditions, disableRuleCron, enableRuleCron } = newEntity;
    const conditionsDevices = [];
    conditions.forEach((conditionGroup) => conditionGroup.forEach((condition) => {
      if (!conditionsDevices.some((c) => c.id === condition.device.id)) conditionsDevices.push(condition.device);
    }));
    const ruleDevices = Array.from(new Set(conditionsDevices));
    const entities = ruleDevices.map((d) => ({ id: d.device_id, attributes: this.joinedAttributes(d), type: 'device' }));
    const criterion = this.translateConditionsToCriterion(conditions);

    const newRule = new Rule(
      {
        ...newEntity,
        engine: { criterion, entities },
        fiware: newEntity.devices[0].fiware,
        actions: newEntity.events,
        disable_cron: disableRuleCron,
        enable_cron: enableRuleCron,
      },
    );

    newRule.save();

    onContinue();
  }

  render() {
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        metadata={{
          wizardType: 'rules',
        }}
        /* entity={Rule} */
      >
        <WizardStep
          component={CreateRule}
          shortTitle={<FormattedMessage id="rules.wizard.create.rule" />}
          title={<FormattedMessage id="rules.wizard.step1.title" />}
          icon={CreateRuleIcon}
          lead={<FormattedMessage id="rules.wizard.step1.text" />}
        />

        <WizardStep
          component={ConfigureRule}
          shortTitle={<FormattedMessage id="rules.wizard.configure.rule" />}
          title={<FormattedMessage id="rules.wizard.step2.title" />}
          lead={<FormattedMessage id="rules.wizard.step2.text" />}
          className="configureRule"
          icon={CreateRuleIcon}
        />

        <WizardStep
          component={AssignActions}
          title={<FormattedMessage id="rules.wizard.step3.title" />}
          lead={<FormattedMessage id="rules.wizard.step3.text" />}
          goToStep={(step) => step}
        />

        <WizardStep
          component={CreateAction}
          shortTitle={<FormattedMessage id="rules.wizard.create.actions" />}
          title={<FormattedMessage id="rules.wizard.step4.title" />}
          lead={<FormattedMessage id="rules.wizard.step4.text" />}
        />

        <WizardStep
          component={EstablishedActions}
          shortTitle={<FormattedMessage id="rules.wizard.established.actions" />}
          title={<FormattedMessage id="rules.wizard.step5.title" />}
          lead={<FormattedMessage id="rules.wizard.step5.text" />}
          goToStep={(step) => step}
          prevSteps={(step) => step}
        />

        <WizardStep
          component={AssignPermissions}
          shortTitle={<FormattedMessage id="rules.wizard.permissions" />}
          title={<FormattedMessage id="rules.wizard.step6.title" />}
          lead={<FormattedMessage id="rules.wizard.step6.text" />}
          onSave={this.handleOnSave}
        />

        <Success />

      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default CreateRules;
