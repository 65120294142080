import Faker from 'faker'

import { dashboardManagement, dashboardManagementV2 } from '../../configuration/config'
import { requestAPI } from './../../helpers/'


const endPoint = dashboardManagement.urlAPI.concat(dashboardManagement.paths.widget);
const endPointV2 = dashboardManagementV2.urlAPI.concat(dashboardManagementV2.paths.widget);

const exampleBodyFn = (numberOfWidgetToGenerate = 50) => {

    const WidgetsFaker = [];

    for (let i = 0; i < numberOfWidgetToGenerate; i++) {
        WidgetsFaker.push({
            id: Faker.random.uuid(),
            name: Faker.random.word(),
            description: Faker.random.words(),
            owner: Faker.name.firstName(),
            permissionsPolicy: {
                "users": [{
                    "id": Faker.name.firstName(),
                    "write": Faker.random.boolean(),
                    "read": Faker.random.boolean()
                }],
                "groups": [{
                    "id": Faker.name.firstName(),
                    "write": Faker.random.boolean(),
                    "read": Faker.random.boolean()
                }]
            },
            widgetType: {
                "name": Faker.random.word(),
                "width": Faker.random.number(10),
                "height": Faker.random.number(10)
            }
        })
    }

    return { "widgets": WidgetsFaker };
};


export const getAllWidgets = async () => {
    const params = {
        endPoint: endPoint + '/',
        statusOK: 200,
        toJSON: true,
        returnData: 'widgets'
    };
    return requestAPI(params, exampleBodyFn);
};



export const readPublicWidget = async () => {

    /*     if (!dashboard['id']) return { status: 301, error: 'invalid id attr' };
     */
    const params = {
        endPoint: endPoint + "/list/public",
        statusOK: 200,
        toJSON: true,
        returnData: 'widgets'
    };

    return await requestAPI(params, exampleBodyFn);
};


export const readMineWidget = async () => {
    /*     if (!dashboard['id']) return { status: 301, error: 'invalid id attr' };
     */
    const params = {
        endPoint: endPoint + "/list/mine",
        statusOK: 200,
        toJSON: true,
        returnData: 'widgets'
    };

    return await requestAPI(params, exampleBodyFn);
};


// SINGULAR
export const readWidget = async (widget) => {

    if (!widget['id']) return { status: 301, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint + "/" + widget.id,
        statusOK: 200,
        toJSON: true,
        returnData: 'widget'
    };

    return await requestAPI(params, exampleBodyFn);
};

export const addWidget = async (widget) => {


    if (widget['id']) return { status: 301, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint,
        statusOK: 201,
        toJSON: true,
        returnData: 'widget',
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(widget),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};

export const addWidgetV2 = async (widget) => {
  if (widget['id']) return { status: 301, error: 'invalid id attr' };
  const params = {
    endPoint: endPointV2,
    statusOK: 201,
    toJSON: true,
    returnData: 'widget',
    requestConfig: {
        method: 'POST',
        body: JSON.stringify(widget),
        headers: {
            'Content-Type': 'application/json'
        }
    }
};
return requestAPI(params, exampleBodyFn);
}

export const updateWidget = (widget) => {

    if (!widget['id']) return { status: 301, error: 'missing id attr' };

    const params = {
        endPoint: endPoint + "/" + widget.id,
        statusOK: 200,
        toJSON: false,
        returnData: widget,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify(widget),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};

export const updateWidgetV2 = (widget) => {

    if (!widget['id']) return { status: 301, error: 'missing id attr' };

    const params = {
        endPoint: endPointV2 + "/" + widget.id,
        statusOK: 200,
        toJSON: false,
        returnData: widget,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify(widget),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};





export const deleteWidget = (widget) => {

    if (!widget['id']) return { status: 301, error: 'missing id attr' };

    const params = {
        endPoint: endPoint + "/" + widget.id,
        statusOK: 204,
        toJSON: false,
        returnData: widget,
        requestConfig: {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};


export const cloneWidget = async (widget) => {

    if (!widget['id']) return { status: 301, error: 'missing id attribute' };
    const params = {
        endPoint: endPoint + "/clone/" + widget.id,
        statusOK: 201,
        toJSON: false,
        returnData: widget,
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(widget),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};

