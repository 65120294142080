import React from 'react';
import {
  Row, Container, Col, ListGroupItem, ListGroup,
} from 'reactstrap';

import styled from 'styled-components';
import { clone } from 'ramda';
import DeleteButton from '../../../../../elements/DeleteButton/index';
import ExpansionPanel from '../../../../../components/ExpansionPanel/index';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import Switch from '../../../../../elements/Switch';
import { isMobile } from '../../../../../helpers/getBreakpoint';
import ContentEditable from '../../../../helpers/ContentEditable/ContentEditable';
import './styles.scss';

const TitleWithStepper = styled.div`
  &:before {
    content: '';
    width: ${(p) => Math.floor((p.totalSelectedAttributes * 100) / p.totalAttributes)}%;
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 3px;
    border-radius: 1px;
    transition: 0.5s ease all;
  }
`;

const ExpansionPanelHeader = (data, opened, contentEditableChange, alias) => {
  const totalSelectedAttributes = data.joinedAttributes
    ? data.joinedAttributes.filter((a) => a.selected).length
    : 0;
  const totalAttributes = data.joinedAttributes
    ? data.joinedAttributes.length
    : 0;
  return (
    <Container fluid className="deviceHeader">
      <Row>
        <Col sm={6} className="identificatorWidgetMap">
          <ContentEditable
            className="labelFocus"
            tag="at"
            onBlur={contentEditableChange}
            id={data.id}
            editable
          >

            {alias[data.id] ? alias[data.id].name : data.name}
          </ContentEditable>
          <p>{data.name}</p>
        </Col>
        {data.joinedAttributes && (
          <Col sm={6} className="selectedProperties">
            {totalSelectedAttributes}
            /
            {totalAttributes}
            {' '}
            <FormattedMessage id="widgets.wizard.properties" />
          </Col>
        )}
        {' '}
      </Row>
    </Container>
  );
};

const ExpansionPanelContent = (data, activeAttribute, checkedAll, onContentEditableChangeAtribute, alias) => {
  const totalSelectedAttributes = data.joinedAttributes
    ? data.joinedAttributes.filter((a) => a.selected).length
    : 0;
  const totalAttributes = data.joinedAttributes
    ? data.joinedAttributes.length
    : 0;
  return (
    <Container fluid className="deviceBody">
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={12} lg={12}>

              <ul className="list-unstyled checkAllContainer">
                <li>
                  <Switch
                    checked={totalSelectedAttributes >= totalAttributes}
                    onChange={(status) => checkedAll(status, data)}
                  />
                  {' '}
                  {!isMobile() && <FormattedMessage id="widgets.wizard.check.all" />}
                </li>

              </ul>

            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={12}>
              <ul className="list-unstyled">
                {data.joinedAttributes.map((attribute, i) => (
                  <li key={i}>
                    <Switch
                      checked={attribute.selected}
                      onChange={(status) => activeAttribute(status, attribute, data)}
                    />
                    {' '}
                    <ContentEditable
                      className="labelFocus"
                      tag="at"
                      onBlur={(id, dat) => onContentEditableChangeAtribute(data.id, data.name, id, dat)}
                      id={attribute.name}
                      editable
                    >
                      {alias[data.id] && alias[data.id].atributes[attribute.name] ? alias[data.id].atributes[attribute.name] : attribute.name}
                    </ContentEditable>
                    <div className="originalNameTableConf">
                      {' '}
                      {attribute.name}
                    </div>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const getCheckedAttributes = (data, hiddenAttributes) => {
  const dataCopy = clone(data);
  const hiddens = hiddenAttributes || {};
  dataCopy.joinedAttributes.map((attribute, i) => {
    if (hiddens[data.id] && hiddens[data.id].includes(attribute.name)) {
      attribute.selected = false;
    } else {
      attribute.selected = true;
    }
  });
  return dataCopy;
};

const ListComponent = (
  {
    data, onDelete, openedPanel, onAttributeCheck, onCheckAll, onPanelOpen, onContentEditableChange, alias, onContentEditableChangeAtribute, hiddenAttributes,
  },
) => {
  const dataChecked = getCheckedAttributes(data, hiddenAttributes);
  return (

    <ExpansionPanel
      data={data}
      header={(d) => ExpansionPanelHeader(
        dataChecked, openedPanel, onContentEditableChange, alias,
      )}
      content={(d) => ExpansionPanelContent(dataChecked, onAttributeCheck, onCheckAll, onContentEditableChangeAtribute, alias)}
      opened={openedPanel}
      onOpen={(status) => onPanelOpen(0, status)}
    />

  );
};

export default ListComponent;
