import React from 'react';
import RemoveHoc from '../../../helpers/RemoveComponent';
import ResetUserPasword from '../ResetPassword/reset';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import PrivateComponent from "../../../../components/PrivateComponent/privateComponent"
import User from '../../../../models/User';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const View = (
  <FormattedMessage
    id="users.options.view"
    defaultMessage="View"
    description="Title of view"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="Edit"
    description="Title of edit"
  />
);

// const Clone = (
//   <FormattedMessage
//     id="options.clone"
//     defaultMessage="clone"
//     description="Title of clone"
//   />
// );

const OptionsForRow = ({ rowContent, viewClick }) => {
  const ButtonOpen = ({ showModal }) => {
    return <li onClick={showModal}>{Delete}</li>;
  };

  const RemoveDOM = RemoveHoc(ButtonOpen, { class: User, nameData: 'users' });

  return (
    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={['delete','update','reset-paswword','read']} >
      <div className="tableMenu">
        <ul className="list-unstyled">
          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'read'} >
            <li
              onClick={() => {
                viewClick(rowContent);
              }}
              state={{ user: rowContent }}
            >
              {View}
              <i className="uil uil-arrow-right" />
            </li>
          </PrivateComponent>
          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'reset-password'} >
            <ResetUserPasword user={{ user: rowContent }} />
          </PrivateComponent>
          <li className="divider"></li>

          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'update'} >
            <li
              onClick={() => {
                viewClick(rowContent);
              }}
              state={{ user: rowContent, mode: 'edit' }}
            >
              {Edit}
            </li>
          </PrivateComponent>
          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'delete'} >
            <RemoveDOM rowContent={rowContent} />
          </PrivateComponent>
          {/* <li onClick={() => {}} state={{ user: rowContent }}>{Clone}</li> */}
        </ul>
      </div>
    </PrivateComponent>
  );
};

export default OptionsForRow;
