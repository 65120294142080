import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Alert from '../../../../components/Alert';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../elements/Button';
import ButtonOutline from '../../../../elements/ButtonOutline';
import ModalHOC from '../../../../elements/ModalWithExternalContext';
import { CancelMsg } from '../../../helpers/CheckErasureAvailability';
import OperationBuilder from '../../Add/steps/ArithmeticOperation/OperationBuilder';
import ConfirmModal from './ConfirmModal';
import ProfileKpiProvider, { ProfileKpiContext } from './context';
import * as Builder from '../../Add/steps/ArithmeticOperation/helpers';

const ButtonOpen = ({ showModal }) => (
  <ButtonOutline
    onClick={showModal}
    label={<FormattedMessage id="kpis.profile.edit.type" />}
  />
);
const ModalContent = ({ kpiConfig, arithmeticalData, handleModalChanges }) => (
  <>
    <OperationBuilder
      datasources={arithmeticalData.datasources}
      kpi={kpiConfig}
      handleChange={handleModalChanges}
      errors
    />
  </>
);

const ModalButtons = ({
  hideModal, errors, handleValidation, handleConfirm, isValid, handleCancel,
}) => (
  <>
    <Col>
      <Row className="justify-content-center">
        <Col className="ml-3 mt-3">
          {errors.props && (
            <Alert text={errors} mode="warning" />
          )}
        </Col>
      </Row>
    </Col>
    <div className="d-flex justify-content-center">
      <ButtonCustom className="mr-3" type="secondary" handleOnClick={() => handleCancel(hideModal)} label={<CancelMsg />} />
      {isValid
        ? (
          <ConfirmModal
            update={() => handleConfirm(hideModal)}
            cancel={() => handleCancel(hideModal)}
          />
        )
        : <ButtonCustom handleOnClick={() => handleValidation()} type="primary" label={<FormattedMessage id="kpi.profile.validate" />} />}
    </div>
  </>
);

const ArithmeticModal = ({
  kpi,
  arithmeticalData,
  update,
  handleEditorChange,
}) => {
  const [cloneEditorData, setCloneEditorData] = useState(null);
  const [kpiConfig, setKpiConfig] = useState(kpi);
  const [editorDataChanges, setEditorDataChanges] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [validate, setValidate] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [cancel, setCancel] = useState(false);

  const handleModalChanges = useCallback((editorChanges) => {
    const { definition, metadata, rawText: rawTextEditor } = editorChanges;
    setEditorDataChanges(editorChanges);
    if (rawTextEditor !== editorDataChanges?.rawText) {
      setIsValid(false);
      setKpiConfig((old) => (
        { ...old, definition, metadata: { ...old.metadata, editorConfig: metadata } }
      ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, editorDataChanges]);

  const handleValidation = () => {
    setValidate(true);
  };

  useEffect(() => {
    if (validate) {
      setErrors([]);

      const { definition: { process: { options } } } = kpiConfig;

      const processValidation = (
        Builder.builderArithmetical(editorDataChanges.rawText) === undefined
        || !Builder.validateListElements(Builder.getListElements(editorDataChanges.rawText)))
        ? false : Builder.validateProcess(editorDataChanges.rawText);

      if (!processValidation) {
        setErrors(
          <FormattedMessage id="kpis.wizard.step4.arithmetical.invalid.operation" />,
        );
      } else {
        const stateCopy = { ...kpiConfig };
        stateCopy.definition.process = Builder
          .builderArithmetical(editorDataChanges.rawText, options);
        stateCopy.metadata = { editorConfig: kpiConfig.metadata.editorConfig };
        setIsValid(true);
      }
      setValidate(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate]);

  const handleConfirm = (hideModal) => {
    setConfirm({ value: true, hideModal });
  };

  useEffect(() => {
    if (confirm.value) {
      setIsValid(false);
      handleEditorChange(editorDataChanges);
      setCloneEditorData(editorDataChanges);
      update(confirm.hideModal);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm]);

  const handleCancel = (hideModal) => {
    setCancel(true);
    setIsValid(false);
    hideModal();
  };

  useEffect(() => {
    if (cancel) {
      const { definition, metadata } = cloneEditorData;
      setKpiConfig((old) => (
        { ...old, definition, metadata: { ...old.metadata, editorConfig: metadata } }
      ));
      setErrors([]);
      setCancel(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancel]);

  useEffect(() => {
    if (!cloneEditorData && editorDataChanges) {
      setCloneEditorData(editorDataChanges);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorDataChanges]);

  const Modal = ModalHOC({
    ButtonOpen,
    ModalContent: () => <ModalContent {...{ kpiConfig, arithmeticalData, handleModalChanges }} />,
    ModalButtons: ({ hideModal }) => (
      <ModalButtons
        {...{
          hideModal,
          update,
          handleValidation,
          handleConfirm,
          isValid,
          errors,
          handleCancel,
        }}
      />
    ),
  }, {}, ProfileKpiContext);

  return (
    <ProfileKpiProvider value={{
      handleEditorChange,
      kpi,
      update,
      isValid,
      errors,
      handleValidation,
      handleModalChanges,
      handleConfirm,
    }}
    >
      <Modal />
    </ProfileKpiProvider>
  );
};

export default ArithmeticModal;
