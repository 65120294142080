import React from 'react';
import styled from 'styled-components';
import Icon from '../../elements/Icons';

const timeToAutoDissmis = 5000;

const AlertWrapper = styled.div`
    border-radius: 15px;
    background-color: #ececec;
    box-sizing: border-box;
    padding: 2%;
    font-size: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 1em;

    svg:last-child:not(:first-child) {
        float: right;
        position: absolute;
        right: 0.5em;
        cursor: pointer;
    }

    svg:first-child {
        margin-right: 0.5em;
        margin-right: 0.5em;
    }
    &.customTheme {
        background-color: ${(props) => props.customBgColor || 'unset'}
        color: ${(props) => props.customFontColor || 'unset'}
    }

    &.primary {
        background: #cce5ff;
        color: #004085;
    }

    &.secondary {
        background: #e2e3e5;
        color: #383d41;
    }

    &.success {
        background: #d4edda;
        color: #155720;
    }

    &.danger {
        background: #f8d7da;
        color: #721c24;
    }

    &.warning {
        background: #fff3cd;
        color: #856404;
    }

    &.info {
        background: #bee5eb;
        color: #0c5460;
    }

`;

export default class Alert extends React.PureComponent {
  componentDidMount() {
    const { autoDismissible, dismissible, onDismiss } = this.props;
    if (
      autoDismissible
      && dismissible
      && onDismiss
    ) {
      setTimeout(onDismiss, timeToAutoDissmis);
    }
  }

  customTheme = () => {
    const { mode } = this.props;
    if (typeof mode !== 'undefined') {
      return mode;
    }
    return 'customTheme';
  };

  customIcon = () => {
    const { icon, mode } = this.props;

    if (typeof mode !== 'undefined' && typeof icon === 'undefined') {
      switch (mode) {
        case 'success':
          return 'CheckCircle';
        case 'warning':
          return 'Warning';
        case 'danger':
          return 'Error';
        case 'info':
          return 'Info';
        default:
          return 'Help';
      }
    } else {
      return icon;
    }
  };

  handleClick = (e) => {
    const { onDismiss } = this.props;
    onDismiss(e);
  };

  render() {
    const { dismissible, text } = this.props;
    return (
      <AlertWrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        className={`alertItem ${this.customTheme()}`}
      >
        <Icon svg={this.customIcon()} />
        {text}
        {dismissible && <Icon svg="Close" onClick={this.handleClick} />}
      </AlertWrapper>
    );
  }
}

Alert.defaultProps = {
  text: 'Put your text here',
  dismissible: false,
  autoDismissible: false,
};
