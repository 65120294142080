import * as TYPES from './types';
import { Map, List } from 'immutable';
import * as R from 'ramda';
import { cloneInstance } from '../../../helpers/utils';

export const exampleFiware = [
  { service: 'service1', servicepath: 'servicepath1' },
  { service: 'service2', servicepath: 'servicepath2' }
];
export const examplePermissionPolicy = ['Group1', 'Group2', 'Group3', 'Group4'];
export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];
export const exampleProtocol = ['IoTA-UL', 'json', 'SIGFOX', 'LoRa'];

/** Initial State of Devices */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  listDataSourcesFromDevices : List([]),
  listSources : {},
});

/** DEVICES REDUCER*/
export default (state = initialState, { type, payload, nameSpace }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    /** INITIALIZE GET DEVICES FROM DB */
    case TYPES.GET_DEVICES_REQUEST:
    case TYPES.GET_DEVICE_LIST_REQUEST:
    case TYPES.GET_DEVICE_LIST_FOR_WIDGETS_REQUEST:
    case TYPES.GET_DEVICES_FOR_RULES_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    case TYPES.GET_DEVICE_LIST_FOR_SENDING_COMMANDS_REQUEST:
      return state.set('devicesListForSendingCommands', []);

    /** INITIALIZE GET DEVICES FROM DB */
    case TYPES.GET_DEVICES_ERROR:
    case TYPES.MODIFY_DEVICE_ERROR:
    case TYPES.DELETE_DEVICE_ERROR:
    case TYPES.ADD_DEVICE_ERROR:
    case TYPES.GET_DEVICE_ERROR:
    case TYPES.GET_DEVICE_LIST_FOR_SENDING_COMMANDS_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;

    /** GET ALL DEVICES FROM DB TO STATE */
    case TYPES.GET_DEVICES_SUCCESS:
    case TYPES.GET_MY_DEVICES_SUCCESS:
    case TYPES.UPDATE_DEVICES_STATE:
      //TODO: Sure it is correct?
      const devicesId = payload.devices.map(o => o.id);
      const devices = state
        .get('list')
        .toJS()
        .filter(o => !devicesId.includes(o.id));

      aux = state.set('total', payload.total);
      aux = aux.set('list', List([...devices, ...payload.devices]));
      aux = aux.set('fetching', false);
      return aux;

    /** GET DEVICE FROM DB TO STATE */
    case TYPES.GET_DEVICE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload);
      //TODO
      //If doesn't exist in the current state, PUSH. // If it exist ?
      // return index === -1 ? state.update('list', deviceList => deviceList.push(payload)) : state;
      if (index === -1) {
        return state
        .update('list', deviceList => deviceList.push(payload))
        
      } else {
        const devices = state.get('list').toJS();
        devices[index] = payload;
        return state.set('list', List([...devices]));
      }

    case TYPES.GET_DEVICE_V2:
      return state
      .update('listSources', (oldList) => {
        const listCopy = cloneInstance(oldList);
        return {
          ...listCopy,
          [payload.id]: payload,
        }
      })
    case TYPES.GET_DEVICE_TO_SOURCE_LIST:{
      const sources = {};
      payload.forEach((source) => {
        sources[source.id] = source
      })
      return state
      .update('listSources', (oldList) => {
        const listCopy = cloneInstance(oldList);
        return {
          ...listCopy,
          ...sources,
        }
      })
    }
      
    case TYPES.GET_DEVICE_LIST_SUCCESS:
      return state
        .set('devicesList', payload)
        .set('fetching', false);

    case TYPES.GET_DEVICE_LIST_FOR_WIDGETS_SUCCESS:
      return state
        .set('devicesListForWidgets', payload)
        .set('fetching', false);

    case TYPES.GET_DEVICE_LIST_FOR_SENDING_COMMANDS_SUCCESS:
      return state
        .set(`devicesListForSendingCommands${nameSpace || ''}`, payload)
        .set('fetching', false);

    //////// ADD AN DEVICE
    /** INITIALIZE ADD DEVICE TO DB AND STATE */
    case TYPES.ADD_DEVICE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** ADD NEW DEVICE TO STATE */
    case TYPES.ADD_DEVICE_SUCCESS:
      aux = state.update('list', deviceList => deviceList.push(payload));
      aux = aux.set('fetching', false);
      aux = aux.set('errorFetching', false);
      aux = aux.set('addSuccess', true);
      aux = aux.update('total', total => total + 1);
      return aux;
    /** SET AS FALSE THE addSuccess STATE FLAG */
    case TYPES.ADD_DEVICE_SUCCESS_FLAG:
      return state.set('addSuccess', false);

    //////// UPDATE AN DEVICE
    /** INITIALIZE UPDATE DEVICE TO DB */
    case TYPES.MODIFY_DEVICE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** UPDATE DEVICE IN STATE */
    case TYPES.MODIFY_DEVICE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', deviceList =>
              deviceList.splice(index, 1, payload)
            )
          : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);
      return aux;
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_DEVICE_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    //////// DELETE AN DEVICE
    /** INITIALIZE DELATION OF A DEVICE IN DB */
    case TYPES.DELETE_DEVICE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** DELETE DEVICE IN STATE */
    case TYPES.DELETE_DEVICE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', deviceList => deviceList.delete(index))
          : state;
      aux = aux.set('fetching', false);
      aux = aux.update('total', total => total - 1);
      return aux;

    /** plural used in opendata request */
    case TYPES.DELETE_DEVICES_SUCCESS:
      return state.merge(
        Map({
          list: state.get('list').filterNot((r) => payload.includes(r.id)),
          fetching: false,
          total: state.get('total') - payload.length,
        }),
      );

    //////// RESET ERROR FETCHING TO FALSE
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_DEVICE_RESET:
      return state.set('errorFetching', false);

    case TYPES.GET_DEVICES_FOR_RULES_SUCCESS:
      return state.set('devicesForRules', payload).set('fetching', false);

    case TYPES.CLEAR_DEVICES_STATE:
      return state.set('list', List([]));

    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
};
