import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';

const View = (
  <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
  />
);

const OptionsForRow = ({ rowContent, viewClick }) => {
  const entityName = 'alert';

  return (
    <div className="tableMenu">
      <ul className="list-unstyled">
        <li
          onClick={() => {
            viewClick(rowContent);
          }}
          role="presentation"
          state={{ [entityName]: rowContent }}
        >
          {View}
          <i className="uil uil-arrow-right" />
        </li>
        {/*         <li className="divider"></li>
        <RemoveDOM rowContent={rowContent} /> */}
      </ul>
    </div>
  );
};

OptionsForRow.propTypes = {
  rowContent: PropTypes.string,
  viewClick: PropTypes.string,
};

OptionsForRow.defaultProps = {
  rowContent: '',
  viewClick: '',
};

export default OptionsForRow;
