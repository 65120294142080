import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import Options from '../../../elements/Field/Select/Options';
import ReactDOM from 'react-dom';
import WithDrawer from '../../../components/DrawerContainer/index';
import { ButtonCustom } from '../../../elements/Button';
import { PropTypes } from 'prop-types';

const FiltersDrawer = WithDrawer(({onApplyFilter, currentlySelected, onClear, ...rest}) => (
  <div className={'filtersDrawer'}>
    <header>
      <div><FormattedMessage id={'filtersFinder.filterBy'} /> </div>
      <div onClick={onClear}><FormattedMessage id={'filtersFinder.clean'} /></div>
    </header>
    <section>
      <Options
        {...rest}
        handleOnChange={currentlySelected}
      />
      <ButtonCustom label={<FormattedMessage id={'filtersFinder.applyFilter'} />} type={'secondary'} handleOnClick={onApplyFilter} />
    </section>
  </div>
));

const MobileFiltersFinder = ({
  selectedFilter,
  AuxItem,
  filters,
  textFieldValue,
  onListClick,
  onSearch,
  onTextFieldChange}) => {
    const [visibility, setVisibility] = useState(false);
    const [currentlySelected, setCurrentlySelected] = useState(undefined);
    
  const displayFilters = () => {
    setVisibility(!visibility);
  };

  const handleOnApplyFilter = () => {
    setVisibility(!visibility);
    onListClick(null, currentlySelected);
  }

  const handleOnCurrentlySelected = (e, value) => {
    setCurrentlySelected(value)
  } 

  const handleOnClear = () => {
    setVisibility(!visibility);
    setCurrentlySelected(undefined);
  }
  return (
    <>
      <Row className="finderWrapper">
        <Col
          xs={12}
          md={{ size: 6, offset: AuxItem ? 0 : 3 }}
          className={'mb-3 mb-md-0'}
        >
          <div className={`filtersFinder`}>
            <div>
              <div className="caret">
                <i className="uil uil-search" />
              </div>
            </div>
            <div className="textField">
              <form onSubmit={onSearch}>
                <input
                  type="text"
                  value={textFieldValue}
                  onChange={onTextFieldChange}
                />
              </form>
            </div>
            <i className="uil uil-sliders-v-alt" onClick={displayFilters}></i>
          </div>
        </Col>
      </Row>
      {AuxItem &&
        ReactDOM.createPortal(
          <div className="auxItem addButton">
            <AuxItem />
          </div>,
          document.body
        )}

      {visibility && (
        <FiltersDrawer
          data={filters}
          handleOnChange={onListClick}
          selected={selectedFilter.value}
          maxWidth="90"
          preserveNavBar
          closeIcon={false}
          onChange={() => setVisibility(!visibility)}
          onApplyFilter={handleOnApplyFilter}
          currentlySelected={handleOnCurrentlySelected}
          onClear={handleOnClear}
        />
      )}
    </>
  );
};

MobileFiltersFinder.propTypes = {
  selectedFilter: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.any,
  }),
  AuxItem: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  textFieldValue: PropTypes.string,
  onListClick: PropTypes.func,
  onSearch: PropTypes.func,
  onTextFieldChange: PropTypes.func,
};

MobileFiltersFinder.defaultProps = {
  selectedFilter: {},
  AuxItem: () => {},
  filters: [],
  textFieldValue: '',
  onListClick: () => {},
  onSearch: () => {},
  onTextFieldChange: () => {},
};

export default injectIntl(MobileFiltersFinder);
