import React, {
  useState, useEffect, memo, useCallback,
} from 'react';
import CustomInput from 'reactstrap/es/CustomInput';
import Form from 'reactstrap/es/Form';
import { FormGroup } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Input, Button } from 'reactstrap';
import { clone } from 'ramda';
import { FormattedMessage } from '../../Contexts/LanguageContext';

import {
  convertFromMinutes,
  convertFromHours,
  convertFromDaily,
  convertFromWeekly,
  convertFromMonthly,
  convertFromWeeklyRange,
} from '../../helpers/cron';

import './style.scss';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
const totalDays = [...Array(31).keys()];
const months = ['1', '2', '3', '4', '6', '12'];
const hours = [...Array(24).keys()];
const minutes = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];
const newMinutes = ['05', '10', '15', '20', '30'];
const newHours = ['1', '2', '3', '4', '6', '12'];

const MonthlyOptionWrapper = memo(
  ({
    children, disabled,
  }) => (
    <FormGroup className={`custom-row py-4${disabled ? ' disabled' : ''}`}>
      {children}
    </FormGroup>
  ),
);

const OptionOne = memo(({ handleChange, planner: { day, month } }) => (
  <>
    <span>
      <FormattedMessage id="reports.config.planner.day" />
    </span>
    <Input type="select" name="day" id="day" onChange={handleChange}>
      {totalDays.map((i, h) => (
        <option key={i} selected={Number(day) === h + 1}>{h + 1}</option>
      ))}
    </Input>
    <span>
      <FormattedMessage id="reports.config.planner.every" />
    </span>
    <Input type="select" name="month" id="month" onChange={handleChange}>
      {months.map((i) => (
        <option key={i} selected={month.substring(2) === i}>{i}</option>
      ))}
    </Input>
    <span>
      <FormattedMessage id="reports.config.planner.month" />
    </span>
  </>
));

const Manually = memo((props) => (
  !props.label
    ? (
      <div>
        <FormattedMessage id="reports.config.planner.manually.text" />
      </div>
    )
    : (
      <div>
        <FormattedMessage id={props.label} />
      </div>
    )
));

const NumberSelect = memo(({
  property, setValue, planner,
}) => {
  let useStatevalue;

  if (planner) useStatevalue = planner[property].substring(2);
  else if (property === 'minutes') useStatevalue = '05';
  else useStatevalue = '1';

  const [num, setNum] = useState(useStatevalue);

  const [options, setOptions] = useState(property === 'minutes' ? newMinutes : newHours);

  const setVal = () => {
    setValue(
      property === 'minutes' ? convertFromMinutes(num) : convertFromHours(num),
    );
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setNum(value);
  };

  useEffect(() => {
    setOptions(property === 'minutes' ? newMinutes : newHours);
  }, [property]);

  useEffect(() => {
    if (planner) setNum(planner[property].substring(2));
  }, [options, planner, property]);

  useEffect(() => {
    setVal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num]);

  return (
    <div className="col-planner d-flex justify-content-center">
      <span>
        <FormattedMessage
          id={`reports.config.planner.${
            property === 'hour' ? 'hours' : property
          }.each`}
        />
      </span>
      <div>
        <Input type="select" name="minute" id="minute" onChange={handleChange} value={num}>
          {options.map((h) => (
            <option key={h}>
              {h}
            </option>
          ))}
        </Input>
      </div>
      <span style={{ marginLeft: 10 }}>
        <FormattedMessage
          id={`reports.config.planner.${
            property === 'hour' ? 'hours' : property
          }`}
        />
      </span>
    </div>
  );
});

const Daily = memo(({ setValue, planner }) => {
  const [state, setState] = useState({
    property: 'daily',
    when: planner?.range !== '*' ? 'businessDay' : 'day',
    hour: planner?.hour ?? '0',
    minute: planner?.minutes ?? '00',
  });

  const setVal = useCallback(() => {
    setValue(convertFromDaily(state.minute, state.hour, state.when));
  }, [setValue, state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'hour' || name === 'minute') {
      setState((old) => ({ ...old, [name]: value }));
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setVal();
  }, [setVal, state]);

  return (
    <Form>
      <FormGroup>
        <div className="col-daily">
          <div className={planner ? 'w-100' : ''}>
            <CustomInput
              onChange={handleChange}
              value="day"
              id="day"
              name="when"
              type="radio"
              checked={state.when === 'day'}
              label={
                <FormattedMessage id="reports.config.planner.daily.every" />
              }
            />
            <CustomInput
              onChange={handleChange}
              id="businessDay"
              value="businessDay"
              name="when"
              type="radio"
              checked={state.when === 'businessDay'}
              label={
                <FormattedMessage id="reports.config.planner.daily.every_business" />
              }
            />
          </div>
          <div className={planner ? 'w-100 mt-3' : ''}>
            <span>
              <FormattedMessage id="reports.config.planner.daily.begin" />
            </span>
            <Input
              type="select"
              name="hour"
              id="hour"
              value={state.hour}
              onChange={handleChange}
            >
              {hours.map((h) => (
                <option key={h}>{h}</option>
              ))}
            </Input>
            :
            <Input
              type="select"
              name="minute"
              id="minute"
              value={state.minute}
              onChange={handleChange}
            >
              {minutes.map((h) => (
                <option key={h}>{h}</option>
              ))}
            </Input>
          </div>
        </div>
      </FormGroup>
    </Form>
  );
});
const getDayValue = (day) => {
  const index = days.indexOf(day);
  switch (index) {
    case 6:
      return 0;
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return index + 1;
    default: return index;
  }
};
const Weekly = memo(({
  setValue, planner, range, profile,
}) => {
  const [state, setState] = useState({
    property: 'weekly',
    days: days.map((day) => ({
      [day]: (planner?.range?.includes(getDayValue(day))) || false,
    })),
    hour: planner?.hour ?? '0',
    minute: planner?.minutes ?? '00',
    startHour: planner?.startHour ?? '0',
    startMinute: planner?.startMinute ?? '00',
    endHour: planner?.endHour ?? '0',
    endMinute: planner?.endMinute ?? '00',
  });
  const [markAll, setMarkAll] = useState(false);

  const timeMeasure = ['hour', 'minute', 'startHour', 'startMinute', 'endHour', 'endMinute'];

  const setVal = useCallback(() => {
    let indexes = null;
    state.days.forEach((day) => {
      const key = Object.keys(day)[0];
      if (day[key]) {
        indexes = `${indexes ? `${indexes},` : ''}${
          getDayValue(key)
        }`;
      }
    });
    if (range) {
      setValue(convertFromWeeklyRange(state.startHour, state.startMinute,
        state.endHour, state.endMinute, indexes));
    } else {
      setValue(convertFromWeekly(state.minute, state.hour, indexes));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, state]);

  const handleChange = (e) => {
    if (!days.includes(e.target.name)) {
      setState({ ...state, [e.target.name]: e.target.checked });
    } else {
      const anotherDays = state.days.filter((day) => day[e.target.name] === undefined);
      setState({
        ...state,
        days: [...anotherDays, { [e.target.name]: e.target.checked }],
      });
    }
    const { name, value } = e.target;
    if (timeMeasure.includes(name)) {
      setState((old) => ({ ...old, [name]: value }));
    }
  };

  const handleMarkAll = () => {
    const daysCopy = clone(state.days);
    daysCopy.forEach((day) => {
      // eslint-disable-next-line no-param-reassign
      day[Object.keys(day)[0]] = !markAll;
    });
    setMarkAll(!markAll);
    setState({ ...state, days: daysCopy });
  };

  useEffect(() => {
    setVal();
  }, [setVal, state]);

  return (
    <>
      <Form className={`row mx-0 rangeRule ${range ? 'rangeBackground' : ''}`}>
        <div className={profile ? 'col-xxl mt-4 mt-xxl-0' : 'col-xxl-5'}>
          <div className="hourLabel">
            <span className="weeklyLabel">
              <FormattedMessage id="rules.wizard.time.hour" />
            </span>
          </div>
          <div className="timeRangeCont">
            <div className="d-flex w-50">
              <FormGroup className="custom-row">
                <div>
                  <span className="ml-0">
                    <FormattedMessage id={range ? 'rules.wizard.time.from' : 'reports.config.planner.daily.begin'} />
                  </span>
                </div>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <Input
                      type="select"
                      name={range ? 'startHour' : 'hour'}
                      id={range ? 'startHour' : 'hour'}
                      value={range ? Number.parseInt(state.startHour, 10) : state.hour}
                      onChange={handleChange}
                    >
                      {hours.map((h) => (
                        <option key={h}>{h}</option>
                      ))}
                    </Input>
                    <span>:</span>
                    <Input
                      type="select"
                      name={range ? 'startMinute' : 'minute'}
                      id={range ? 'startMinute' : 'minute'}
                      value={range ? state.startMinute : state.minute}
                      onChange={handleChange}
                    >
                      {minutes.map((h) => (
                        <option key={h}>{h}</option>
                      ))}
                    </Input>
                  </div>
                </div>
              </FormGroup>
            </div>
            {range && (
            <div className="d-flex w-50">
              <FormGroup className="custom-row">
                <div className="d-flex">
                  <span className="ml-0">
                    <FormattedMessage id="rules.wizard.time.to" />
                  </span>
                </div>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <Input
                      type="select"
                      name="endHour"
                      id="endHour"
                      value={Number.parseInt(state.endHour, 10)}
                      onChange={handleChange}
                    >
                      {hours.map((h) => (
                        <option key={h}>{h}</option>
                      ))}
                    </Input>
                    <span>:</span>
                    <Input
                      type="select"
                      name="endMinute"
                      id="minute"
                      value={state.endMinute}
                      onChange={handleChange}
                    >
                      {minutes.map((h) => (
                        <option key={h}>{h}</option>
                      ))}
                    </Input>
                  </div>
                </div>
              </FormGroup>
            </div>
            )}
          </div>
        </div>
        <div className={`col-xxl mt-4 ${profile ? 'mt-xxl-2' : 'mt-xxl-0'} daysCont`}>
          <div className="daysTitleCont">
            <span className="weeklyLabel">
              <FormattedMessage id="rules.wizard.time.days" />
            </span>
            <Button color="link" className="markAll" onClick={() => handleMarkAll()}>
              <FormattedMessage id="rules.wizard.time.markAll" />
            </Button>
          </div>
          <FormGroup className="custom-row mt-0">
            {days.map((day, value) => (
              <CustomInput
                key={day}
                id={day}
                value={value + 1}
                checked={state.days.some((d) => d[day])}
                name={day}
                type="checkbox"
                label={<FormattedMessage id={`days.${day}`} />}
                onChange={handleChange}
              />
            ))}
          </FormGroup>
        </div>
      </Form>
    </>
  );
});

const Monthly = memo(({ setValue, planner, intl }) => {
  const initialState = {
    property: 'monthly',
    options: [OptionOne],
    day: planner ? planner.day : '1',
    dayName: planner ? days[Number(planner.day)] : 'monday',
    dayposition: planner ? planner.day : '1',
    month: planner ? planner.month : '1',
    hour: planner ? planner.hour : '0',
    minute: planner ? (planner.minutes || planner.minute) : '00',
    selectedId: planner ? planner.selectedId : 0,
    selectedOption: planner ? planner.selectedOption : {},
  };

  const [state, setState] = useState(initialState);
  const setVal = useCallback(() => {
    setValue(
      convertFromMonthly(
        state.minute,
        state.hour,
        state.day,
        state.month,
      ),
    );
  }, [setValue, state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'hour' || name === 'minute') {
      setState((old) => ({ ...old, [name]: value }));
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setVal();
  }, [setVal, state]);

  return (
    <Form>
      {state.options.map((Option, ind) => (
        <MonthlyOptionWrapper
          key={ind.id}
        >
          <Option planner={state} handleChange={handleChange} intl={intl} />
        </MonthlyOptionWrapper>
      ))}

      <FormGroup className="custom-row">
        <span>
          <FormattedMessage id="reports.config.planner.daily.begin" />
        </span>
        <Input type="select" name="hour" id="hour" onChange={handleChange}>
          {hours.map((h) => (
            <option key={h} selected={Number(state.hour) === h}>{h}</option>
          ))}
        </Input>
        <span>:</span>
        <Input type="select" name="minute" id="minute" onChange={handleChange}>
          {minutes.map((h) => (
            <option key={h} selected={state.minute === h}>{h}</option>
          ))}
        </Input>
      </FormGroup>
    </Form>
  );
});
export default injectIntl(Monthly);
export {
  Manually, NumberSelect, Daily, Weekly,
};
