import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

// TODO
// These expressions are suposed to work better than used ones,
// because of generalization, but somethin isn't working propperly.
// Cant start wit space or dot, after first letter, allows letter, spaces and dots
// Have to solve how to avoid @ # and other characters identified as letters.
// ^(?!(\.|\s).*$)(\p{L}|\p{Z}|\p{P})+
// ^(?!\\p{Z}|\\p{P})\\p{L}*[\\p{L}\\p{Z}\\p{P}]*

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: {
        message: "can't be empty",
      },
    },
  },
  {
    key: 'config',
    defaultValue: {},
    constraints: {
      presence: {
        message: "can't be empty",
      },
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message:
  <FormattedMessage
    id="Validation.minimum"
    values={
                            { minimum: '1' }
                        }
  />,
      },
    },
  },
  {
    key: 'description',
    defaultValue: '',
  },
  {
    key: 'owner',
    defaultValue: '',
  },
  {
    key: 'container',
    defaultValue: undefined,
  },
  {
    key: 'permissions_policy',
    defaultValue: { users: [], groups: [], public: false },
  },
  {
    key: 'widgetType',
    defaultValue: 'TEXT_ACCOUNTANT',
    constraints: {
      presence: true,
    },

  },
  {
    key: 'config',
    defaultValue: {},
    constraints: {
      presence: true,
    },

  },
  {
    key: 'widgetAggregate',
    defaultValue: false,
    constraints: {
      presence: true,
    },

  },
  {
    key: 'lastUserUpdated',
    defaultValue: '',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'connectedDevicesOld',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'isInvalid',
    defaultValue: false,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'createdAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'origins',
    defaultValue: [],
    constraints: {
      presence: false,
    },
  },
  {
    key: 'updatedAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'version',
    defaultValue: 'v1',
    constraints: {
      presence: false,
    },
  },
];

export default ModelExpected;
