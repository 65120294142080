const genRandomNumber = () => (100 * Math.random()).toFixed(2);

export const dataTestTable = [
  {
    device: {
      id: 'deviceTest',
      device_id: 'dId',
      device_name: 'Example_Device',
      hasCommandAttributes: false,
      attributes: [
        'temperature',
        'humidity',
        'ozone',
        'oxygen',
      ],
    },
    data: {
      id: 'deviceTest',
      entityId: 'dId',
      device_id: 'dId',
      device_name: 'Example_Device',
      entityType: 'test',
      TimeInstant: '2021-02-22T16:17:02.122Z',
      recvTime: '2021-02-22T16:17:02.145Z',
      temperature: genRandomNumber(),
      humedity: genRandomNumber(),
      ozone: genRandomNumber(),
      oxygen: genRandomNumber(),
    },
  },
];

export const originsTestTable = {
  id: "deviceTest",
  device_id: "dId",
  name: "Example_Device",
  description: "example_device",
  permissions_policy: {
     public: false,
     users: [
        {
           id: "admin",
           write: true,
           read: true
        }
     ],
     groups: []
  },
  owner: "admin-fiwoo",
  fiware: {
     service: "aaaaaaaaa",
     servicepath: "/fiwoo"
  },
  entity_type: "bar",
  transport: "HTTP",
  organization: "ffffff000000000000ffffff",
  dataset: "ffffff000000000000ffffff",
  categories: [
     "GPS"
  ],
  attributes: [
     {
       name: "temperature",
       type: "number",
       unit: "1",
       key: "temperature"
     },
     {
       name: "humidity",
       type: "number",
       unit: "1",
       key: "humidity"
     },
     {
       name: "ozone",
       type: "number",
       unit: "1",
       key: "oxygen"
     },
     {
       name: "oxygen",
       type: "number",
       unit: "1",
       key: "oxygen"
     },
  ],
  lazy_attributes: [],
  command_attributes: [],
  static_attributes: [],
  protocol: "IOTA_UL",
  createdAt: "2020-05-15T07:37:34.230Z",
  updatedAt: "2021-01-20T09:48:35.048Z",
  constraints: {},
};

export const configTestTable = {
  appearance: {
     hidden: [],
     orientation: "rows"
  },
  colors: {
     headerColor: "#FFFFFF",
     headerTableBackground: "#FFFFFF",
     headerTableColorText: "#2B334A"
  },
  conditions: {},
  effects: {},
  custom: {},
  data: {
     operation: "last-value",
     type: "real-time",
     sampling: "",
     attributeFilter: [
      'temperature',
      'humidity',
      'ozone',
      'oxygen',
     ]
  },
  labels: {
      alias: {
        temperature: "temperature",
        humidity: "humidity",
        ozone: "ozone",
        oxygen: "oxygen", 
      }
  },
  trendlines: {},
  values: {}
};
