import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row, Container } from 'reactstrap';
import ModalHOC from '../../../../elements/Modal';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import CommonView from '../../../CommonView';
import Button, { ButtonCustom } from '../../../../elements/Button';
import Field from '../../../../elements/Field';
import { CancelMsg } from '../../CheckErasureAvailability';

const ModalTitle = ({ typeOfData }) => (
  <FormattedMessage id={typeOfData.concat('.addValueCommand.info')} />
);
const mapStateToProps = () => ({});

const closeModal = (callback) => callback(false);

const AddCommandValueMsg = () => (
  <FormattedMessage
    id="devices.options.addValueCommand"
    defaultMessage="Add Value"
    description="Add value command"
    component="span"
  />
);

const ModalContent = injectIntl(
  connect(
    mapStateToProps,
    {},
  )(
    class ModalContentClass extends CommonView {
      constructor(props) {
        super({ props });
        this.state = {
          command: {
            name: '',
            value: '',
          },
          errors: {},
        };
      }

      handleInputChangeCustom = (event) => {
        const command = { ...this.state.command };
        const { target } = event;
        command[target.name] = target.value;
        this.setState({ command });
      };

      handleButton = async (e) => {
        const errors = this.validate();
        if (errors) {
          this.setState({ errors });
        } else {
          this.props.addValueCommandOptions(this.state.command);
          closeModal(this.props.hideModal);
        }
      };

      validate = () => {
        const { command } = this.state;
        const errors = {};
        if (!command.name) {
          errors.name = (
            <FormattedMessage id="devices.addValueCommand.name.validate" />
          );
        }
        if (!command.value) {
          errors.value = (
            <FormattedMessage id="devices.addValueCommand.value.validate" />
          );
        }
        return Object.keys(errors).length > 0 ? errors : null;
      };

      render() {
        const { errors } = this.state;
        const { hideModal } = this.props;

        return (
          <Container>
            <Row className="w-100">
              <Col xs={6} md={6} xl={3}>
                <Field
                  name="name"
                  type="text"
                  placeholder={this.props.intl.formatMessage({
                    id: 'devices.addValueCommand.name',
                  })}
                  value={this.state.command.name}
                  onChange={this.handleInputChangeCustom}
                  helperText={errors.name}
                  error={!!errors.name}
                />
              </Col>
              <Col xs={6} md={6} xl={3}>
                <Field
                  name="value"
                  type="text"
                  placeholder={this.props.intl.formatMessage({
                    id: 'devices.addValueCommand.value',
                  })}
                  value={this.state.command.value}
                  onChange={this.handleInputChangeCustom}
                  helperText={errors.value}
                  error={!!errors.value}
                />
              </Col>
              <Col xs={6} md={6} xl={3}>
                <ButtonCustom label={<CancelMsg />} type="secondary" handleOnClick={hideModal} />
              </Col>
              <Col xs={6} md={6} xl={3}>
                <Button
                  className="btn btn-primary m-0 btn-sendcommand w-100"
                  onClick={this.handleButton}
                >
                  <AddCommandValueMsg />
                </Button>
              </Col>
            </Row>
          </Container>
        );
      }
    },
  ),
);

export default (ButtonOpen, typeOfData) => {
  class AddCommandValueInner extends React.Component {
    render() {
      const ModalDOM = ModalHOC({
        ModalTitle: () => <ModalTitle typeOfData={typeOfData.nameData} />,
        ButtonOpen,
        ModalContent: ({ hideModal }) => (
          <ModalContent
            addValueCommandOptions={this.props.addValueCommandOptions}
            typeOfData={typeOfData.nameData}
            hideModal={hideModal}
          />
        ),
      });

      return <ModalDOM size="sm" />;
    }
  }

  AddCommandValueInner.defaultProps = {
    addValueCommandOptions: () => {},
  };

  AddCommandValueInner.propTypes = {
    addValueCommandOptions: () => {},
  };

  return AddCommandValueInner;
};
