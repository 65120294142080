import React, { useState } from 'react';

const defaultValue = '';

export const Context = React.createContext(defaultValue);

const Provider = ({ children, metadata: propMetadata, ...rest }) => {
  const [metadata, setMetadata] = useState(propMetadata);

  return (
    <Context.Provider value={{ ...rest, metadata, setMetadata }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
