import React from 'react';
import { PropTypes } from 'prop-types';
import Wizard from '../../../components/Wizard';
import WizardStep from '../../../components/Wizard/components/WizardStep';

// Steps
import Starter from './steps/Starter';
import ConfigureProtocol from '../../helpers/Add/ConfigureProtocol';
import AddProperties from '../../helpers/Add/AddProperties';
import Success from './steps/Success';

import AddTableButton from '../../../components/AddTableButton';
import ModalHOC from '../../../elements/Modal';

import { FormattedMessage } from '../../../Contexts/LanguageContext';
import AssignPermissions from '../../helpers/AssignPermissions';
import CreateEntity from "../../helpers/Add/CreateEntity";
import Template from "../../../models/Template";
import saveEntity from '../../helpers/Add/utils/saveEntity';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent'
import { isMobile } from '../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../elements/Button';

const addTemplatesLabel = (
  <FormattedMessage
    id="templates.add.title"
    defaultMessage="add templates"
    description="Label of add templates"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile())
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/templates'}
        permission={'create'}
      >
        <ButtonCustom type={'circular'} name={'plus'} onClick={showModal} />
      </PrivateComponent>
    );
  else
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/templates'}
        permission={'create'}
      >
        <AddTableButton onClick={showModal} label={addTemplatesLabel} />
      </PrivateComponent>
    );
};

class CreateTemplates extends React.Component {
  render() {
    return (
      <Wizard  
      buttonOpen={ButtonOpen}
      entity={Template}
      metadata={{
        wizardType: 'templates',
      }}
      >

        <WizardStep
          component={Starter}
          title={<FormattedMessage id="templates.wizard.step1.title" />}
          lead={<FormattedMessage id="templates.wizard.step1.text" />}
          entity={Template}
        />

        <WizardStep
          component={CreateEntity}
          title={<FormattedMessage id="templates.wizard.step2.title" />}
          lead={<FormattedMessage id="templates.wizard.step2.text" />}
          entity={Template}
        />
        <WizardStep
          component={ConfigureProtocol}
          title={<FormattedMessage id="templates.wizard.step3.title" />}
          lead={<FormattedMessage id="templates.wizard.step3.text" />}
          entity={Template}
        />
        <WizardStep
            component={AddProperties}
            title={<FormattedMessage id="templates.wizard.step4.title" />}
            lead={<FormattedMessage id="templates.wizard.step4.text" />}
            entity={Template}
          />
        <WizardStep
          component={AssignPermissions}
          title={<FormattedMessage id="templates.wizard.step5.title" />}
          lead={<FormattedMessage id="templates.wizard.step5.text" />}
          entity={Template}
          onSave={saveEntity}
        />
        
        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};


export default CreateTemplates;
