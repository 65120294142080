import React from 'react';
import { PropTypes } from 'prop-types';
import Menu from '../../../../../../components/Menu';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import CheckBox from '../../../../../../elements/CheckBox/index';

const openButton = ({ onClick, type, disabled }) => (
  <>
    <button type="button" className={`btn condition-${type}`} onClick={onClick}>
      <FormattedMessage id={`options.${type}`} />
      {' '}
      {!disabled && <i className="uil uil-angle-down" />}
    </button>
  </>
);

const ConditionalButtonMenu = ({ type, children, disabled }) => (
  <Menu
    top="40px"
    left="0"
    right="0"
    openComponent={openButton}
    openFrom="top left"
    className="moreConditionals"
    type={type}
    disabled={disabled}
    onRight
  >
    {children}
  </Menu>
);

const ConditionalButton = (props) => {
  const {
    type, onClick, fixed, onEdit,
  } = props;

  const isIfCondition = () => (type === 'if');
  const isKeyAllowed = (key) => !((type === 'if' && key === 'or') || (type !== 'if' && key === 'if') || isIfCondition());
  return (
    <ConditionalButtonMenu type={type} disabled={fixed || !onEdit || isIfCondition()}>

      <ul className="list-unstyled">
        {['and', 'or', 'if'].map((key) => (
          isKeyAllowed(key) && (
          <li key={key}>
            <CheckBox
              checked={type === key}
              onClick={() => onClick(key)}
              label={<FormattedMessage id={`options.${key}`} />}
              reverse={false}
              minimal
            />
          </li>
          )
        ))}
      </ul>
    </ConditionalButtonMenu>
  );
};

ConditionalButton.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fixed: PropTypes.bool,
};

ConditionalButton.defaultProps = {
  fixed: false,
};

ConditionalButtonMenu.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool.isRequired,
};

openButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ConditionalButton;
