import { connect } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { array, PropTypes } from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { clone } from 'ramda';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Field from '../../../../elements/Field';
import ExpansionLabel from '../../../../components/ExpansionLabel';
import {
  genericParseOptions,
  getURN,
  getUrnId,
} from '../../../../helpers/utils';
import SimpleSourceOfDataKPI from '../../../helpers/Add/components/SimpleSourceOfDataKPI';
import {
  selectDevices,
  selectDevicesIsFetching,
  selectDevicesTotal,
} from '../../../../services/redux/devices/selectors';
import { ButtonCustom } from '../../../../elements/Button';
import '../styles.scss';
import Kpi from '../../../../models/Kpi';
import { clearDevicesState, getDevices } from '../../../../services/redux/devices/actions';
import PeriodTimeKPI from '../../../helpers/Add/components/PeriodTimeKPI';
import ExpandablePlanner from '../../../helpers/Profile/Planner';
import { getTypeOfCron, makeExampleCron } from '../../../../helpers/cron';
import usePrevious from '../../../helpers/Hooks/usePrevious';
import { clearETLProcedures, getETLProcedures } from '../../../../services/redux/etlProcedures/actions';
import ArithmeticModal from './ArithmeticModal';
import SelectSourceOfData from '../../Add/steps/Components/selectSourceOfData';
import * as Builder from '../../Add/steps/ArithmeticOperation/helpers';

const translateSampling = (sampling) => {
  switch (sampling) {
    case 'second':
      return 'lastSecond';
    case 'minute':
      return 'lastMinute';
    case 'hour':
      return 'lastHour';
    case 'day':
      return 'lastDay';
    case 'month':
      return 'lastMonth';
    default:
      return 'undefined';
  }
};

const translateSamplingToOptions = (sampling) => {
  switch (sampling) {
    case 'lastSecond':
      return 'second';
    case 'lastMinute':
      return 'minute';
    case 'lastHour':
      return 'hour';
    case 'lastDay':
      return 'day';
    case 'lastMonth':
      return 'month';
    default:
      return 'undefined';
  }
};

const ExpansionLabelSelectTypeHeader = () => (
  <FormattedMessage component={false} id="kpis.profile.configuration.selectType" />
);
const ExpansionLabelSimpleDataSourceHeader = () => (
  <FormattedMessage component={false} id="kpis.profile.configuration.simple.dataSource" />
);
const ExpansionLabelTimePeriodHeader = () => (
  <FormattedMessage component={false} id="kpis.profile.configuration.simple.timePeriod" />
);

const ExpansionLabelSelectTypeContent = (type, handleOnChange) => (
  <div>
    <Field
      name="kpiType"
      type="select"
      options={['simple', 'arithmetic'].map((o) => genericParseOptions(o, 'kpis', 'profile.configuration.selectType'))}
      value={type}
      onChange={handleOnChange}
    />
  </div>
);

const ExpansionLabelSourceContent = (
  kpi,
  handleChangeSourceSelect,
  arithmeticalData,
  updateKpi,
  editorValidation,
  handleEditorChange,
  rawText,
  errors,
) => (
  <Container>
    <Row>
      {arithmeticalData.selectorData.length > 0 && arithmeticalData.devices.length > 0
             && (
             <Col xs="12" className="mb-3 d-flex justify-content-center">
               <ArithmeticModal {...{
                 kpi,
                 arithmeticalData,
                 update: updateKpi,
                 editorValidation,
                 handleEditorChange,
                 rawText,
                 errors,
               }}
               />
             </Col>
             )}

      <Col xs="12" className="px-5">
        <SelectSourceOfData
          handleChange={handleChangeSourceSelect}
          onError={() => console.log('error')}
          selected={arithmeticalData}
        />
      </Col>
    </Row>
  </Container>
);

const ExpansionLabelSimpleDataSourceContent = (
  filter,
  type,
  options,
  selected,
  value,
  unit,
  errors,
  fetching,
  openedPanels,
  handleChangeMode,
  handleOnSearch,
  handleManualChange,
  handleAttributeStatusChange,
  handleOnPanelOpen,
  handleSelectableListChanges,
  handleFetchInfo,
  handleChangeFilter,
) => (
  <div>
    <SimpleSourceOfDataKPI
      filter={filter}
      selectableFilter={{
        handler: handleChangeFilter,
        filters: [{ name: 'devices', translation: 'devices.list.title' }, { name: 'etls', translation: 'etl.list.title' }],
        checked: filter,
      }}
      mode={type}
      options={options}
      selected={selected}
      errors={errors}
      value={value}
      unit={unit}
      fetching={fetching}
      openedPanels={openedPanels}
      handleChangeMode={handleChangeMode}
      handleManualChange={handleManualChange}
      handleOnSearch={handleOnSearch}
      handleFetchInfo={handleFetchInfo}
      handleSelectableListChanges={handleSelectableListChanges}
      handleOnPanelOpen={handleOnPanelOpen}
      handleAttributeStatusChange={handleAttributeStatusChange}
      isProfile
    />
  </div>
);

const ExpansionLabelTimePeriodContent = (
  aggregation,
  samplingValue,
  operationValue,
  handleEnableDisable,
  handleSelectChange,
  errors,
) => (
  <Container>
    <PeriodTimeKPI
      isProfile
      aggregation={aggregation}
      resolutionValue={samplingValue}
      operationValue={operationValue}
      handleEnableDisable={handleEnableDisable}
      handleSelectChange={handleSelectChange}
      errors={errors}
    />
  </Container>
);

const recursiveGetSource = (process) => {
  if (!process || process.step === 'value') return undefined;
  if (process.step === 'source') {
    return { urn: process.urn, attribute: process.attribute };
  }

  if (process.step === 'arithmetic') {
    switch (process.operation) {
      case 'div':
        return [recursiveGetSource(process.dividend), recursiveGetSource(process.divisor)];
      case 'sub':
        return [recursiveGetSource(process.minuend), recursiveGetSource(process.subtrahend)];
      case 'sum':
      case 'mul':
        return process.process.map((p) => recursiveGetSource(p));
      default:
        return undefined;
    }
  }

  return undefined;
};

const getSelectedDefintion = (kpi) => {
  if (kpi.definition?.process?.step === 'arithmetic') {
    const arrayUrn = [...new Set(recursiveGetSource(kpi.definition.process).flat(1000))].filter((e) => e);

    const devices = [];
    for (let i = 0; i < arrayUrn.length; i++) {
      const idDevice = getUrnId(arrayUrn[i].urn);
      if (!devices.some((d) => d.id === idDevice)) {
        getURN(arrayUrn[i].urn);
        devices.push({
          id: idDevice,
          attributes: [{ name: arrayUrn[i].attribute }],
          joinedAttributes: [],
        });
      } else {
        const index = devices.findIndex((d) => d.id === idDevice);
        devices[index].attributes.push({ name: arrayUrn[i].attribute });
      }
    }

    return devices;
  } if (kpi.definition.process?.urn) {
    return [{
      id: getUrnId(kpi.definition.process.urn),
      name: 'Default',
      joinedAttributes: [],
    }];
  }
  return [];
};

const EditType = (props) => {
  const {
    data,
    devices,
    etls,
    total,
    totalETL,
    fetchingETL,
    fetching,
    intl,
  } = props;

  const [kpi, setKpi] = useState(data);
  const [rawText, setRawText] = useState({ valid: false });
  const previousKpi = usePrevious(kpi);
  const [errors, setErrors] = useState([]);
  const [openedPanels, setOpenedPanels] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [pageEtl, setPageEtl] = useState(1);
  const [filter, setFilter] = useState('devices');
  const [typeKpi, setTypeKpi] = useState(kpi?.type);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [arithmeticalData, setArithmeticalData] = useState({});
  const [aggregation, setAggregation] = useState(kpi.definition.process?.operation !== 'last_occurence');
  const [samplingValue, setSamplingValue] = useState(
    translateSampling(kpi.definition.process?.options?.resolution),
  );
  const [operationValue, setOperationValue] = useState(kpi.definition.process?.operation);
  const [planner, setPlanner] = useState(getTypeOfCron(kpi.cron));
  const [cron, setCron] = useState(kpi.cron);

  const joinedAttributes = (device) => {
    if (device && Object.entries(device).length > 0) {
      const { lazy_attributes, static_attributes, attributes } = device;
      const attributesList = {
        lazy: lazy_attributes,
        static: static_attributes,
        attribute: attributes,
      };

      const attributesArray = Array.prototype.concat(
        ...Object.keys(attributesList).map(
          (attrIndex) => attributesList[attrIndex].map((attribute) => ({
            ...attribute,
            attrType: attrIndex,
          })),
        ),
      );
      const attributesToRemove = [];
      attributesArray.forEach((a) => {
        if (a.type !== 'float' && a.type !== 'int' && a.type !== 'integer'
          && a.type !== 'number' && a.type !== 'number') {
          attributesToRemove.push(a);
        }
      });
      attributesToRemove.forEach((a) => {
        attributesArray.splice(attributesArray.indexOf(a), 1);
      });
      return attributesArray;
    }
    return [];
  };

  const addJoinedAttributes = (device) => (
    !device.joinedAttributes
      ? {
        ...device,
        joinedAttributes: joinedAttributes(device).map((a) => ({
          ...a,
          selected: false,
        })),
      }
      : device
  );

  const activateAttributeOnDevice = (device, attributes, status) => {
    device.joinedAttributes.forEach((a) => {
      const b = a;
      if (attributes.includes(b.name)) {
        b.selected = status;
      } else {
        b.selected = false;
      }
    });
    return device;
  };

  const injectDeviceID = (oldArithmeticalData) => {
    const newDevices = oldArithmeticalData.devices.map((d) => ({
      ...d,
      device_id: devices.find((dv) => dv.id === d.id)?.device_id,
    }));
    return {
      ...oldArithmeticalData,
      devices: newDevices,
    };
  };

  useEffect(() => {
    if (devices.length && arithmeticalData.devices) {
      setArithmeticalData(injectDeviceID);
    }
    if (typeKpi === 'simple') {
      const device = devices.find((o) => o.id === selectedDevice[0]?.id);
      if (device) {
        setSelectedDevice([
          activateAttributeOnDevice(
            addJoinedAttributes(device),
            [kpi.definition.process?.attribute],
            true,
          ),
        ]);
      }
    } else {
      const devicesFiltered = devices.filter(
        (o) => arithmeticalData.devices?.map((u) => u.id).includes(o.id),
      );
      const devicesWithAttributes = devicesFiltered.map((df) => {
        const attributes = arithmeticalData.devices
          .filter((d) => d.id === df.id)
          .map((d) => d.attributes.map((a) => a.name))
          .reduce((a, b) => a.concat(b));
        return activateAttributeOnDevice(
          addJoinedAttributes(df),
          attributes,
          true,
        );
      });
      if (devicesWithAttributes.length) {
        setArithmeticalData((old) => ({ ...old, selectorData: devicesWithAttributes }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  useEffect(() => {
    if (!fetching && page) {
      getDevices({ filters: [], page, size });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (kpi.definition?.process?.urn) {
      getURN(kpi.definition.process.urn);
    }
    if (devices.length === 0) {
      getDevices({ filters: [], page, size });
    }
    setSelectedDevice(getSelectedDefintion(kpi));
    setArithmeticalData({
      devices: getSelectedDefintion(kpi),
      selectorData: [],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter === 'devices' && !fetching && devices.length === 0) {
      getDevices({ filters: [], page, size });
    } else if (filter === 'etls' && !fetchingETL && etls.length === 0) {
      getETLProcedures({ filters: [], page: pageEtl, size });
    }
  }, [filter]);

  const handleChangeMode = (mode) => {
    if (mode === 'manual') {
      setKpi({
        ...kpi,
        cron: '',
        definition: {
          type: mode,
          value: kpi.definition.value,
          unit: kpi.definition.unit,
        },
      });
    } else {
      setKpi({
        ...kpi,
        devices: [],
        definition: {
          type: 'calculate',
          value: previousKpi.definition.value,
          unit: previousKpi.definition.unit,
          process: {
            step: 'source',
            attribute: previousKpi.definition.process?.attribute,
            operation: previousKpi.definition.process?.operation,
            urn: previousKpi.definition.process?.urn,
          },
        },
      });
    }
  };

  const getOptions = () => devices?.map((o) => ({ name: o.name, id: o.id, value: o })) ?? [];

  const getOptionsEtl = () => etls?.map((e) => ({ name: e.name, id: e.id, value: e })) ?? [];

  const getOptionsWithFilters = () => (filter === 'devices' ? getOptions() : getOptionsEtl());

  const handleAttributeStatusChange = (status, attribute, device) => {
    const selectedDevices = [...selectedDevice];
    let selected;
    selectedDevices
      .find((d) => d.id === device.id)
      .joinedAttributes.forEach((a) => {
        const b = a;
        if (attribute.name === b.name) {
          b.selected = status;
          if (status) {
            selected = a;
          }
        } else {
          b.selected = false;
        }
      });

    const definition = {
      type: kpi.definition.type,
      value: kpi.definition.value,
      unit: selected ? selected.unit : kpi.definition.unit,
      process: {
        step: kpi.definition.process.step,
        attribute: selected ? selected.name : undefined,
        operation: kpi.definition.process.operation,
        urn: kpi.definition.process.urn,
      },
    };

    const cloneKpi = R.clone(kpi);
    cloneKpi.definition = definition;
    setKpi(cloneKpi);
    setSelectedDevice([selectedDevices[0]]);
  };

  const handleSelectableListChanges = (selected) => {
    if (filter === 'devices') {
      const newData = selected.map(addJoinedAttributes);

      const definition = {
        type: kpi.definition.type,
        value: kpi.definition.value,
        unit: '',
        process: {
          step: 'source',
          attribute: '',
          operation: 'last_occurence',
          urn: selected.length > 0 ? `fiwoo:device:${selected[0].id}` : '',
        },
      };

      const cloneKpi = R.clone(kpi);
      cloneKpi.definition = definition;
      setKpi(cloneKpi);
      setSelectedDevice(newData);
    }
  };

  const handleManualChange = (event) => {
    const { target: { value, name } } = event;
    const cloneKpi = R.clone(kpi);
    cloneKpi.definition[name] = name==='value' ? Number(value) : value;
    setKpi(cloneKpi);
  };

  const handleFetchInfo = () => {
    if (filter === 'devices' && total > devices.length) {
      setPage(page + 1);
    } else if (filter === 'etls' && totalETL > etls.length) {
      setPageEtl(pageEtl + 1);
    }
  };

  const handleOnSearch = (value, init = false) => {
    if (filter === 'devices') {
      if (init) clearDevicesState();
      getDevices({ filters: { name: value }, page: 1, size });
    } else if (filter === 'etls') {
      if (init) clearETLProcedures();
      getETLProcedures({ filters: { name: value }, page: 1, size });
    }
  };

  const handleOnchangeSelectType = (name, val) => {
    setTypeKpi(val);
  };

  const handleSendKPI = (callback) => {
    const updatedKpi = new Kpi(kpi);
    updatedKpi.update();
    callback && callback();
  };

  const handleEnableDisable = () => {
    setAggregation(!aggregation);
  };

  const handleSelectChange = (name, value) => {
    const cloneKpi = clone(kpi);

    if (name === 'operation') {
      cloneKpi.definition.process.operation = value;
      setOperationValue(value);
      setKpi(cloneKpi);
    }

    if (name === 'resolution') {
      cloneKpi.definition.process.options = {
        ...cloneKpi.definition.process.options,
        resolution: translateSamplingToOptions(value),
      };
      setSamplingValue(value);
      setKpi(cloneKpi);
    }
  };

  const handlerPlanner = (e, value) => {
    // if manually clean planner props
    if (planner !== value) {
      const currentCron = value === 'manually' ? '' : makeExampleCron(value);
      setPlanner(value);
      setCron(currentCron);

      if (value === 'manually') {
        setKpi((old) => ({
          ...old,
          cron: currentCron,
        }));
      }
    }
  };

  const handleChangeSourceSelect = (newDevices, selectorData) => {
    setArithmeticalData({ devices: newDevices, selectorData });
  };

  const handleEditorChange = (editorChanges) => {
    const { definition, metadata, rawText: rawTextEditor } = editorChanges;
    if (rawTextEditor !== rawText.text) {
      setRawText({ text: rawTextEditor, valid: false });
      setKpi((old) => (
        { ...old, definition, metadata: { ...old.metadata, editorConfig: metadata } }
      ));
    }
  };

  const editorValidation = () => {
    setErrors([]);
    const { definition: { process: { options } } } = kpi;

    const processValidation = (
      Builder.builderArithmetical(rawText.text) === undefined
      || !Builder.validateListElements(Builder.getListElements(rawText.text)))
      ? false : Builder.validateProcess(rawText.text);

    if (!processValidation) {
      setErrors(
        <FormattedMessage id="kpis.wizard.step4.arithmetical.invalid.operation" />,
      );
    } else {
      const stateCopy = { ...kpi };
      stateCopy.definition.process = Builder.builderArithmetical(rawText.text, options);
      stateCopy.metadata = { editorConfig: kpi.metadata.editorConfig };
      setRawText((old) => ({ ...old, valid: true }));
    }
  };

  const onChangePlanner = (plannerCron) => {
    if (plannerCron !== cron) {
      setCron(plannerCron);

      setKpi((old) => ({
        ...old,
        cron: plannerCron,
      }));
    }
  };

  const handleChangeFilter = (e) => {
    const { target: { value } } = e;
    setFilter(value);
  };

  return (
    <section className="whiteSpace p-3">
      <div className="styleAndConfiguration">
        <ExpansionLabel
          header={ExpansionLabelSelectTypeHeader}
          content={() => ExpansionLabelSelectTypeContent(typeKpi, handleOnchangeSelectType)}
          opened
        />
        { typeKpi === 'simple'
        && (
          <ExpansionLabel
            header={ExpansionLabelSimpleDataSourceHeader}
            content={() => ExpansionLabelSimpleDataSourceContent(
              filter,
              kpi.definition.type === 'calculate' ? 'simple' : 'manual',
              getOptionsWithFilters(),
              selectedDevice,
              kpi.value,
              kpi.unit,
              errors,
              filter === 'devices' ? fetching : fetchingETL,
              openedPanels,
              handleChangeMode,
              handleOnSearch,
              handleManualChange,
              handleAttributeStatusChange,
              () => {},
              handleSelectableListChanges,
              handleFetchInfo,
              handleChangeFilter,
            )}
            opened
          />
        )}
        {(typeKpi === 'arithmetic' && arithmeticalData.selectorData && arithmeticalData.devices && devices.length > 0)
        && (
          <ExpansionLabel
            header={ExpansionLabelSimpleDataSourceHeader}
            content={() => ExpansionLabelSourceContent(
              kpi,
              handleChangeSourceSelect,
              arithmeticalData,
              handleSendKPI,
              editorValidation,
              handleEditorChange,
              rawText,
              errors,
            )}
            opened
          />
        )}
        { typeKpi === 'simple' && kpi.definition.type === 'calculate'
        && (
            <ExpansionLabel
              header={ExpansionLabelTimePeriodHeader}
              content={() => ExpansionLabelTimePeriodContent(
                aggregation,
                samplingValue,
                operationValue,
                handleEnableDisable,
                handleSelectChange,
                errors,
              )}
              opened
            />
        )}
        {kpi.definition.type === 'calculate'
          && (
            <ExpandablePlanner
              plannerSelected={planner}
              cron={cron}
              handlerPlanner={handlerPlanner}
              onChangePlanner={onChangePlanner}
            />
        )}

      </div>
      <ButtonCustom
        type="primary"
        name="sendValue"
        label={intl.formatMessage({
          id: 'kpi.profile.update',
        })}
        handleOnClick={handleSendKPI}
      />
    </section>
  );
};

EditType.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  devices: PropTypes.arrayOf(PropTypes.any),
  etls: PropTypes.arrayOf(PropTypes.any),
  total: PropTypes.number,
  totalETL: PropTypes.number,
  fetching: PropTypes.bool,
  fetchingETL: PropTypes.bool,
  intl: PropTypes.func,
};

EditType.defaultProps = {
  devices: [],
  etls: [],
  total: 0,
  totalETL: 0,
  fetching: false,
  fetchingETL: false,
  intl: () => {},
};

const mapStateToProps = (state) => ({
  devices: selectDevices(state).toJS(),
  total: selectDevicesTotal(state),
  fetching: selectDevicesIsFetching(state),
  etls: state
    .get('etlProcedures')
    .get('list')
    .toJS(),
  fetchingETL: state.get('etlProcedures').get('fetching'),
  totalETL: state.get('etlProcedures').get('total'),
});

export default connect(mapStateToProps, {})(injectIntl(EditType));
