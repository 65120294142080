import Faker from 'faker';
import { dashboardManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = dashboardManagement.urlAPI.concat(dashboardManagement.paths.dashboard);

const exampleBodyFn = (numberOfDashboardToGenerate = 50) => {
  const DashboardsFaker = [];

  for (let i = 0; i < numberOfDashboardToGenerate; i += 1) {
    DashboardsFaker.push({
      id: Faker.random.uuid(),
      name: Faker.random.word(),
      description: Faker.random.words(),
      owner: Faker.name.firstName(),
      permissionsPolicy: {
        users: [{
          id: Faker.name.firstName(),
          write: Faker.random.boolean(),
          read: Faker.random.boolean(),
        }],
        groups: [{
          id: Faker.name.firstName(),
          write: Faker.random.boolean(),
          read: Faker.random.boolean(),
        }],
      },
      layoutProperties: {
        lg: [
          {
            h: Faker.random.number(10),
            i: Faker.uuid(),
            isDraggable: Faker.random.boolean(),
            isResizable: Faker.random.boolean(),
            maxH: Faker.random.number(10),
            maxW: Faker.random.number(10),
            minH: Faker.random.number(10),
            minW: Faker.random.number(10),
            moved: Faker.random.boolean(),
            static: Faker.random.boolean(),
            w: Faker.random.number(5),
            x: Faker.random.number(5),
            y: Faker.random.number(5),
          },
        ],
        md: [
          {
            h: Faker.random.number(10),
            i: Faker.uuid(),
            isDraggable: Faker.random.boolean(),
            isResizable: Faker.random.boolean(),
            maxH: Faker.random.number(10),
            maxW: Faker.random.number(10),
            minH: Faker.random.number(10),
            minW: Faker.random.number(10),
            moved: Faker.random.boolean(),
            static: Faker.random.boolean(),
            w: Faker.random.number(5),
            x: Faker.random.number(5),
            y: Faker.random.number(5),
          },
        ],
        sm: [
          {
            h: Faker.random.number(10),
            i: Faker.uuid(),
            isDraggable: Faker.random.boolean(),
            isResizable: Faker.random.boolean(),
            maxH: Faker.random.number(10),
            maxW: Faker.random.number(10),
            minH: Faker.random.number(10),
            minW: Faker.random.number(10),
            moved: Faker.random.boolean(),
            static: Faker.random.boolean(),
            w: Faker.random.number(5),
            x: Faker.random.number(5),
            y: Faker.random.number(5),
          },
        ],
      },
      widgets: [Faker.uuid()],
    });
  }

  const dashboards = { dashboards: DashboardsFaker };
  return dashboards;
};

/** * First version of Dashboard CRUD */
// PLURAL
export const readDashboards = async (page, size, filters) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/all?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'dashboards',
  };

  const answer = await requestAPI(params, exampleBodyFn);
  return answer;
};

export const readPublicDashboard = async (page, size, filters) => {
  /*     if (!dashboard['id']) return { status: 301, error: 'invalid id attr' };
     */

  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + '/list/public?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'dashboards',
  };

  const answer = await requestAPI(params, exampleBodyFn);
  return answer;
};

export const readMineDashboard = async (page, size, filters) => {
  /*     if (!dashboard['id']) return { status: 301, error: 'invalid id attr' };
     */
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';
  const params = {
    endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'dashboards',
  };

  const answer = await requestAPI(params, exampleBodyFn);

  return answer;
};

// SINGULAR
export const readDashboard = async (dashboard) => {
  if (!dashboard.id) return { status: 301, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${dashboard.id}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'dashboard',
  };

  const answer = await requestAPI(params, exampleBodyFn);
  return answer;
};

export const readSharedDashboard = async (dashboard) => {
  if (!dashboard.id) return { status: 301, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${dashboard.id}/share`,
    statusOK: 200,
    toJSON: true,
    returnData: 'dashboard',
  };

  const answer = await requestAPI(params, exampleBodyFn);
  return answer;
};

export const addDashboard = async (dashboard) => {
  if (dashboard.id) return { status: 301, error: 'invalid id attr' };

  const params = {
    endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'dashboard',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(dashboard),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const updateDashboard = async (dashboard) => {
  if (!dashboard.id) return { status: 301, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint}/${dashboard.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: dashboard,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(dashboard),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const deleteDashboard = async (dashboard) => {
  if (!dashboard.id) return { status: 301, error: 'missing id attribute' };
  const params = {
    endPoint: `${endPoint}/${dashboard.id}`,
    statusOK: 204,
    toJSON: false,
    returnData: dashboard,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const cloneRefDashboard = async (dashboard) => {
  if (!dashboard.id) return { status: 301, error: 'missing id attribute' };
  const params = {
    endPoint: `${endPoint}/clone/ref/${dashboard.id}`,
    statusOK: 201,
    toJSON: false,
    returnData: dashboard,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(dashboard),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const cloneNoRefDashboard = async (dashboard) => {
  if (!dashboard.id) return { status: 301, error: 'missing id attribute' };
  const params = {
    endPoint: `${endPoint}/clone/noref/${dashboard.id}`,
    statusOK: 204,
    toJSON: true,
    returnData: dashboard,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(dashboard),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleBodyFn);
};
