import { getRandomColor } from '../../../../pages/Widgets/Add/components/utils';

const MapDefaultConfiguration = {
  data: {
    realTimeUpdates: false,
  },
  labels: {
    alias: { },
    categoryAlias: { },
  },
  conditions: {
    categoryColors: { },
  },
  appearance: {
    hiddenAttributes: { },
    iconCategory: true,
  },
  custom: {
    MAP: {
      viewType: 'default',
    },
  },
};

export default MapDefaultConfiguration;

export const injectMapData = (origins, config) => {
  const newConfig = { ...config };
  const categoryColors = {};
  const categories = [];

  origins.forEach((origin) => {
    if (origin.type !== 'KPI') { // TODO: Delete when kpi accepts joinedAttrs
      const attrs = [
        ...origin.connectedDevices.attributes,
        ...origin.connectedDevices.lazy_attributes,
        ...origin.connectedDevices.static_attributes,
      ];
      attrs.forEach((attr) => {
        if (!newConfig.labels.alias[attr]) {
          newConfig.labels.alias[attr] = attr;
        }
      });
    }
    if (!origin.categories) {
      if (!categories.includes(origin.connectedDevices.readDevice.categories[0] || 'OTHER')) {
        categories.push(origin.connectedDevices.readDevice.categories[0] || 'OTHER');
        categoryColors[origin.connectedDevices.readDevice.categories[0] || 'OTHER'] = {
          color: getRandomColor(),
          mode: '',
          range: '',
          element: [],
        };
      }
    } else if (!categories.includes(origin.categories[0] || 'OTHER')) {
      categories.push(origin.categories[0] || 'OTHER');
      categoryColors[origin.categories[0] || 'OTHER'] = {
        color: getRandomColor(),
        mode: '',
        range: '',
        element: [],
      };
    }
  });

  newConfig.conditions.categoryColors = categoryColors;

  return newConfig;
};
