import React from 'react';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import Add from './steps/Add';
import Success from './steps/Success';
import Wizard from '../../../../components/Wizard';
import WizardStep from '../../../../components/Wizard/components/WizardStep';
import AddTableButton from '../../../../components/AddTableButton';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { isMobile } from '../../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../../elements/Button';
import User from '../../../../models/User';

const addUserLabel = (
  <FormattedMessage
    id="users.add.title"
    defaultMessage="add user"
    description="Label of add user"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/users"
        permission="create"
      >
        <ButtonCustom type="circular" name="plus" onClick={showModal}/>
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/users"
      permission="create"
    >
      <AddTableButton onClick={showModal} label={addUserLabel} />
    </PrivateComponent>
  );
};

class CreateUser extends React.Component {
  render() {
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        entity={User}
        metadata={{
          wizardType: 'users',
        }}
      >
        <WizardStep
          component={Add}
          title={<FormattedMessage id="users.add.title" />}
          //lead={<FormattedMessage id="users.add.lead" />}
          entity={User}
        />
        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateUser;
