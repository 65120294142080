import Faker from 'faker';
import { alertManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import {
  getQueryData,
} from '../../helpers/parseFilters';

const endPoint = alertManagement.urlAPI.concat(alertManagement.paths.incident);

const exampleBodyFn = (data = null, numberOfAlertToGenerate = 50) => {
  const AlertsFaker = [];
  for (let i = 0; i < numberOfAlertToGenerate; i++) {
    AlertsFaker.push({
      id: Faker.random.uuid(),
      fiware: {
        description: Faker.random.words(),
        dateIssued: Faker.date.recent(),
        severity: Faker.random.arrayElement(['informational', 'low', 'medium', 'high', 'critical']),
      },
      permissions_policy: {
        users: [],
        groups: [],
        public: false,
      }
    });
  }
  const alerts = { incidents: AlertsFaker };
  return alerts;
};


export const readAlerts = async ({page, size, sort, filters}) => {
  const {queryPage, querySize, queryFilters, querySort} = getQueryData(page, size, sort, filters);

  const params = {
    endPoint: endPoint + `/list/all?${queryPage}&${querySize}&${queryFilters}&${querySort} `,
    statusOK: 200,
    toJSON: true,
    returnData: 'incidents',
  };

  return requestAPI(params, exampleBodyFn);
};

export const readAlert = async alert => {
  if (!alert['id']) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: endPoint + '/' + alert.id,
    statusOK: 200,
    toJSON: true,
    returnData: 'incident',
  };

  let answer = await requestAPI(params, alert);

  return answer;
};
