import React from 'react';
import RemoveHoc from "./../../../helpers/RemoveComponent";
import { FormattedMessage } from "../../../../Contexts/LanguageContext/index";
import Role from '../../../../models/Role';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'


const Delete = <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
/>

const View = <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
/>

const Edit = <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
/>

const Clone = <FormattedMessage
    id="options.clone"
    defaultMessage="clone"
    description="Title of clone"
/>


const OptionsForRow = ({ rowContent, viewClick }) => {
    const ButtonOpen = ({ showModal }) => {
        return (
            <li onClick={showModal}>{Delete}</li>
        )

    };

    //const ResetDOM = ResetRolePasword(ButtonOpenReset);
    const RemoveDOM = RemoveHoc(ButtonOpen, { class: Role, nameData: "roles" })


    return (
        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/roles'} permission={['read','update','delete']} >
            <div className="tableMenu">
                <ul className="list-unstyled">
                    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/roles'} permission={'read'} >
                        <li onClick={() => { viewClick(rowContent) }} state={{ role: rowContent }}>{View}<i className='uil uil-arrow-right'></i></li>
                        <li className="divider"></li>
                        
                    </PrivateComponent>
                    
                    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/roles'} permission={'update'} >
                        <li onClick={() => { viewClick(rowContent) }} state={{ role: rowContent, mode: 'edit' }}>{Edit}</li>
                    </PrivateComponent>
                    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/roles'} permission={'delete'} >
                        <RemoveDOM rowContent={rowContent} />
                    </PrivateComponent>
                </ul>
            </div>
        </PrivateComponent>
    )
};

export default OptionsForRow;