import * as TYPES from './types';
import {store} from '../store';

export const loginUser = (User) => store.dispatch({
    type: TYPES.LOGIN_REQUEST,
    payload: User,
});

export const logout = () => {
    store.dispatch({
        type: TYPES.LOGOUT_REQUEST_SUCCESS,
    });
    store.dispatch({type: 'CLEAR_STATE'});
};
