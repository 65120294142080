/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaulValue: null,
  },
  {
    key: 'owner',
    defaulValue: null,
  },
  {
    key: 'name',
    defaulValue: '',
  },
  {
    key: 'description',
    defaulValue: '',
  },
  {
    key: 'permissions_policy',
    defaulValue: {
      users: [],
      groups: [],
      public: false,
    },
  },
  {
    key: 'createdAt',
    defaulValue: {},
  },
  {
    key: 'dataModel',
    defaulValue: {},
  },
  {
    key: 'fiware',
    defaulValue: {
      type: '',
      category: '',
      dateIssued: '',
      alertSource: '',
      data: {},
      severity: '',
    },
  },
];

export default ModelExpected;
