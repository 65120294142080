import React from 'react';
import { PropTypes } from 'prop-types';

import AddUsers from './AddUsers';
import Wizard from '../../../../components/Wizard';
import ModalHOC from '../../../../elements/Modal';
import WizardStep from '../../../../components/Wizard/components/WizardStep';
import UserOkIcon from '../../../../configuration/icons/svg/ico-groups.svg';
import CreateUsersGroupSuccess from './Success';

import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../elements/Button/index';
import UsersGroup from '../../../../models/UsersGroup';

const addUserLabel = (
  <FormattedMessage
    id="usersGroups.wizard.add.users.title"
    defaultMessage="add usersGroup"
    description="Label of add usersGroup"
  />
);

const ButtonOpen = ({ showModal }) => (
  <ButtonCustom handleOnClick={showModal} label={addUserLabel} type={'secondary'} />
);

class AddUsersWizard extends React.Component {

  render() {
    return (
      <Wizard
      buttonOpen={ButtonOpen}
      entity={UsersGroup}
      metadata={{
        wizardType: 'users.group',
      }}
      >

        <WizardStep
          component={AddUsers}
          title={<FormattedMessage id="usersGroups.wizard.addUsers.title" />}
          lead={<FormattedMessage id="usersGroups.wizard.step4.text" />}
          icon={UserOkIcon}
          entity={UsersGroup}
        />
        {/* <AddUsers
          onError={this.handleError}
          onContinue={this.handleContinue}
          hideModal={this.props.hideModal}
          title={<FormattedMessage id="usersGroups.wizard.addUsers.title" />}
        /> 
          <CreateUsersGroupSuccess /> 
        */}
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default AddUsersWizard;
