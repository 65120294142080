/* eslint-disable import/no-cycle */
import React, {
  memo, useState, useEffect, useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { getAllDeviceAttributes } from '../../utils';

const Kpi = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTextNumber })),
);

function KpiComponent(props) {
  const {
    widget,
    values,
    hasBeenResized,
    onChangeHistoricalConfig,
    intl,
    globalSelection,
    sources,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Skeleton = useMemo(() => widget.skeletonComp, []);

  const attributes = useMemo(() => getAllDeviceAttributes(widget.sources[0]), [widget]);

  const localeTranslate = (key, defaultValue) => (
    intl ? intl.formatMessage({ id: key }) : defaultValue
  );

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.config?.attributeFilter,
    widget?.origins, widget.config?.data?.sampling,
    globalSelection]);

  useEffect(() => {
    if (!values || typeof values !== 'object' || Array.isArray(values)) {
      setIndex(-1);
    } else {
      setIndex(
        Object.keys(values?.[1].series).findIndex((key, i) => (
          attributes.includes(values[1].series[i].attribute)
        )),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  if (values === undefined) {
    return <Skeleton />;
  }
  return (
    <Kpi
      onTranslate={localeTranslate}
      text={widget.name}
      deviceCategory={sources[0].categories}
      number={values}
      widgetNumber={index}
      config={widget.config}
      hasBeenResized={hasBeenResized}
      dark={widget.transparent}
    />
  );
}

export default memo(injectIntl(KpiComponent));
