import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedDate } from 'react-intl';
import CommonView from '../../CommonView';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import parseDate from '../../../helpers/timeAgo';
import './styles.scss';
import { AlertRow } from '../../Rules/helpers/severitySelect';

class Info extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      errors: {},
      original: this.props.data.fiware,
    };
  }

  getInfo = (original) => ['device', 'values', 'dateIssued', 'severity'].map((key) => (
    <dl key={key}>
      <dt>
        <FormattedMessage id={`Alerts.${key}`} />
      </dt>
      <dd>
        {(() => {
          switch (key) {
            case 'dateIssued':
              return this.getDate(original[key]);
            case 'severity':
              return this.getSeverity(original[key]);
            case 'device':
              return this.getDevice(original.data);
            case 'values':
              return this.getValues(original.data);
            default:
              return null;
          }
        })()}
      </dd>
    </dl>
  ));

  getDevice = (data) => Object.keys(data)[0];

  getDate = (data) => (
    <>
      {parseDate(data)}
      <span className="pl-1">
        (
        <FormattedDate value={data} />
        )
      </span>
    </>
  );

  getSeverity = (data) => <AlertRow item={{ value: data.toLowerCase() }} />;

  getValues = (data) => {
    const deviceName = Object.keys(data)[0];
    const attributesNames = Object.keys(data[deviceName]).filter(
      (k) => k !== 'type',
    );

    return (
      <div className="alertValues">
        {attributesNames.map((attribute, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <dl key={index}>
            <dt>{attribute}</dt>
            <dd>{data[deviceName][attribute]}</dd>
          </dl>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="tabSection alertsProfile p-5">{this.getInfo(this.state.original)}</div>
    );
  }
}

Info.defaultProps = {
  onRef: () => {},
  data: {
    fiware: { data: { deviceName: { type: {}, attribute: '' } }, severity: 'INFORMATIONAL', dateIssued: '' },
  },
};

Info.propTypes = {
  onRef: PropTypes.func,
  data: PropTypes.shape,
};

export default Info;
