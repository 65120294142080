import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import * as Icons from '@material-ui/icons';

import Tags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import './style.scss';

function AutoComplete(props) {
  const {
    value, availableCommands, intl, blocked, name, isEdit,
  } = props;
  const tagifyRef = useRef();
  const [tagifyValue, setTagifyValue] = useState(value || '');
  const [tagifyProps] = useState({
    showFilteredDropdown: 'a',
    delimiters: null,
    loading: false,
    mode: 'select',
    dropdown: {
      enabled: 0,
    },
  });
  useEffect(() => {
    if (!isEqual(value, tagifyValue)) {
      setTagifyValue(value);
      if (value.value === '') {
        tagifyRef.current.removeTags();
      }
    }
    if (typeof value === 'string' && value !== tagifyValue.alias && value !== tagifyValue.value) {
      const newValue = {
        alias: value,
      };
      setTagifyValue(newValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = (e) => {
    const {
      onChange,
    } = props;
    const tagValue = e.target.value || '';
    if (tagValue !== ' ' && tagValue !== '') {
      let v = tagValue.includes('[') ? JSON.parse(tagValue) : tagValue;
      v = typeof v !== 'string' ? v[0].value : tagValue;
      const arraySplit = v.split(' ');
      if (arraySplit.length === 2) {
        const valueSplit = arraySplit[1];
        const result = {
          alias: arraySplit[0],
          value: valueSplit,
        };
        setTagifyValue(result);
        onChange(e, name, valueSplit);
      } else {
        const result = {
          value: v,
        };
        setTagifyValue(result);
        onChange(e, name, v);
      }
    } else {
      onChange(e, name, tagValue);
    }
  };
  return (
    <Tags
      name={name}
      className={`${blocked ? 'autoCompleteTagsBlocked' : 'autoCompleteTags'}`}
      tagifyRef={tagifyRef}
      settings={!blocked && tagifyProps} // tagify settings object
      whitelist={availableCommands?.length > 0 ? availableCommands
        .map((command) => `${command.name} ${command.value}`) : []}
      value={isEdit ? tagifyValue.alias || '' : tagifyValue.alias || tagifyValue.value || ''}
      onChange={(e) => handleOnChange(e)}
      placeholder={!blocked && intl.formatMessage({ id: 'widget.sendCommand.value' })}
      readOnly={blocked}
    />
  );
}

export default injectIntl(AutoComplete);