import { takeLatest, takeEvery, call, put, all, fork } from 'redux-saga/effects';
import Widget from '../../../models/Widget'
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as API from './../../api/widgets'
import * as TYPES_DASHBOARD from '../dashboards/types';
import { getWidgetModel } from '../../../models/WidgetV2/utils';

/**
 * Actions for WATCHERS
 * */

// Try to recover all widget form DB
export function* getWidgetsThroughAPI() {
  try {
    const response = yield call(API.getAllWidgets);
    if (response.status === 200) {
      const widgets = response.data.objects.map(widget => {
        return getWidgetModel(widget)
      });
      yield put({ type: TYPES.GET_WIDGETS_SUCCESS, payload: widgets });
    }
    else {
      yield put({ type: TYPES.GET_WIDGETS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  }
  catch (error) {
    yield put({ type: TYPES.GET_WIDGETS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


// Try to recover a widget from DB
export function* getPublicWidgetsThroughAPI({ payload }) {
  try {
    const response = yield call(API.readPublicWidget, payload);
    if (response.status === 200) {
      const widgets = response.data.objects.map(widget => {
        return getWidgetModel(widget)
      });
      yield put({ type: TYPES.GET_PUBLIC_WIDGETS_SUCCESS, payload: widgets });
    } else {
      yield put({ type: TYPES.GET_PUBLIC_WIDGETS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_PUBLIC_WIDGETS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}



// Try to recover a widget from DB
export function* getMineWidgetsThroughAPI({ payload }) {
  try {
    const response = yield call(API.readMineWidget, payload);
    if (response.status === 200) {
      const widgets = response.data.objects.map(widget => {
        return getWidgetModel(widget)
      });
      yield put({ type: TYPES.GET_MINE_WIDGETS_SUCCESS, payload: widgets });
    } else {
      yield put({ type: TYPES.GET_MINE_WIDGETS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MINE_WIDGETS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}




// Try to recover an widget form DB
export function* getWidgetThroughAPI({ payload }) {
  try {
    const response = yield call(API.readWidget, payload);
    if (response.status === 200) {
      const widgetFromDB = getWidgetModel(response.data.object)
      yield put({ type: TYPES.GET_WIDGET_SUCCESS, payload: widgetFromDB });
    }
    else {
      yield put({ type: TYPES.GET_WIDGET_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  }
  catch (error) {
    yield put({ type: TYPES.GET_WIDGET_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

  }
}


// Try to create widget in DB
export function* addWidgetsThroughAPI({ payload }) {
  try {
    const { parent, ...rest } = payload
    const response = payload.version === 'v2' 
          ? yield call(API.addWidgetV2, rest)
          : yield call(API.addWidget, rest)
    if (response.status === 201) {
      const widgetFromDB = parent
        ? parent.instanceChildren(response.data.object)
        : getWidgetModel(response.data.object)
      if (payload.updateDashboardCallback) {
        payload.updateDashboardCallback(widgetFromDB);
      }
      yield put({ type: TYPES.ADD_WIDGET_SUCCESS, payload: widgetFromDB });
    }
    else {
      yield put({ type: TYPES.ADD_WIDGET_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  }
  catch (error) {
    yield put({ type: TYPES.ADD_WIDGET_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

const cleanWidgetData = (widget) => {
  if (widget) {
    const cleanedWidget = { ...(widget instanceof Widget ? widget.getData() : widget) }
    const cleanedOrigins = []
    if (widget.origins) {
      widget.origins.forEach((o) => {
        if (o.type === 'DEVICE' && o.connectedDevices) {
          cleanedOrigins.push({
            type: 'DEVICE',
            connectedDevices: {
              id: o.connectedDevices.id,
              device_id: o.connectedDevices.device_id,
              attributes: o.connectedDevices.attributes,
              command_attributes: o.connectedDevices.command_attributes,
              lazy_attributes: o.connectedDevices.lazy_attributes,
              static_attributes: o.connectedDevices.static_attributes,

            }
          })
        }
        else {
          cleanedOrigins.push(0);
        }
      })
      cleanedWidget.origins = cleanedOrigins;
    }

    return cleanedWidget
  }
  return widget
}

// Try to update widget in DB
export function* updateWidgetsThroughAPI({ payload }) {
  try {
    const response = payload.version === 'v2'
      ? yield call(API.updateWidgetV2, payload)
      : yield call(API.updateWidget, cleanWidgetData(payload))
    if (response.status === 200) {
      const updatedWidget = response.data.object;
      if(payload.containedWidgets) updatedWidget.containedWidgets = payload.containedWidgets;
        const widget = getWidgetModel(updatedWidget)
        yield put({ type: TYPES.MODIFY_WIDGET_SUCCESS, payload: widget });
        yield put({ type: TYPES_DASHBOARD.MODIFY_DASHBOARD_WIDGET_STATE, payload: widget });
    }
    else {
        yield put({ type: TYPES.MODIFY_WIDGET_ERROR, payload: response });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  }
  catch (error) {
    yield put({ type: TYPES.MODIFY_WIDGET_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete widget in DB
export function* deleteWidgetsThroughAPI({ payload }) {
  try {
    const response = yield call(API.deleteWidget, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_WIDGET_SUCCESS, payload });
      yield put({ type: TYPES_DASHBOARD.DELETE_DASHBOARD_WIDGET_STATE, payload });
      if (payload.container) {
        yield put({ type: TYPES.DELETE_WIDGET_IN_PARENT_WIDGET_SUCCESS, payload });
      }
    } else {
      yield put({ type: TYPES.DELETE_WIDGET_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_WIDGET_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}



// Try to delete widget in DB
export function* cloneWidgetsThroughAPI({ payload }) {
  try {
    const response = yield call(API.cloneWidget, payload);
    if (response.status === 204) yield put({ type: TYPES.CLONE_WIDGET_SUCCESS, payload });
    else {
      yield put({ type: TYPES.CLONE_WIDGET_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  }
  catch (error) {
    yield put({ type: TYPES.CLONE_WIDGET_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_WIDGETS_REQUEST
function* watcherGetWidgets() {
  yield takeLatest('GET_WIDGETS_REQUEST', getWidgetsThroughAPI);
}

function* watcherGetPublicWidgets() {
  yield takeLatest('GET_PUBLIC_WIDGETS_REQUEST', getPublicWidgetsThroughAPI);
}

function* watcherGetMineWidgets() {
  yield takeLatest('GET_MINE_WIDGETS_REQUEST', getMineWidgetsThroughAPI)
}

// Watcher looking for GET_WIDGET_REQUEST
function* watcherGetWidget() {
  yield takeLatest('GET_WIDGET_REQUEST', getWidgetThroughAPI);
}

// Watcher looking for ADD_WIDGET_REQUEST
function* watcherAddWidgets() {
  yield takeEvery('ADD_WIDGET_REQUEST', addWidgetsThroughAPI);
}

// Watcher looking for MODIFY_WIDGET_REQUEST
function* watcherUpdateWidgets() {
  yield takeLatest('MODIFY_WIDGET_REQUEST', updateWidgetsThroughAPI);
}

// Watcher looking for DELETE_WIDGET_REQUEST
function* watcherDeleteWidgets() {
  yield takeEvery('DELETE_WIDGET_REQUEST', deleteWidgetsThroughAPI);
}

// Watcher looking for DELETE_WIDGET_REQUEST
function* watcherCloneWidgets() {
  yield takeEvery('CLONE_WIDGET_REQUEST', cloneWidgetsThroughAPI);
}



// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetWidgets),
    fork(watcherGetPublicWidgets),
    fork(watcherGetMineWidgets),
    fork(watcherGetWidget),
    fork(watcherAddWidgets),
    fork(watcherUpdateWidgets),
    fork(watcherDeleteWidgets),
    fork(watcherCloneWidgets)
  ])
}

