import React from 'react';
import { Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import FormattedMessage from '../../../../Contexts/LanguageContext/Components/FormattedMessage';

const LinkedStepTitle = ({
  currentStep, totalSteps, title, subtitle,
}) => (
  <>
    <Row className="justify-content-center mb-4">
      <div className="stepperTitle">
        <FormattedMessage id="Stepper.step" />
        {` ${currentStep - 1} `}
        <FormattedMessage id="Stepper.of" />
        {` ${totalSteps - 1}`}
      </div>
    </Row>
    <Row className="justify-content-center">
      <h1><FormattedMessage id={title} /></h1>
    </Row>
    <Row className="justify-content-center mb-4">
      <h3><FormattedMessage id={subtitle} /></h3>
    </Row>
  </>
);

export default injectIntl(LinkedStepTitle);
