/* eslint-disable import/prefer-default-export */
import * as TYPES from './types';
import { store } from '../store';

export const requestOrganizationList = (payload) => store.dispatch({
  type: TYPES.GET_ORGANIZATION_LIST_REQUEST,
  payload,
});

export const requestOrganizationById = (payload) => store.dispatch({
  type: TYPES.GET_ORGANIZATION_BY_ID_REQUEST,
  payload,
});

export const requestDataSetListByOrganization = (payload) => store.dispatch({
  type: TYPES.GET_DATASET_LIST_REQUEST_BY_ORG,
  payload,
});

export const requestDataSetList = (payload) => store.dispatch({
  type: TYPES.GET_DATASET_LIST_REQUEST,
  payload,
});

export const requestAddDataSetList = (payload) => store.dispatch({
  type: TYPES.ADD_DATASET_LIST_REQUEST,
  payload,
});

export const requestPublishDataSetSubscription = (payload) => store.dispatch({
  type: TYPES.PUBLISH_DATASET_SUBSCRIPTION_REQUEST,
  payload,
});

export const requestCreateGISLayer = (payload) => store.dispatch({
  type: TYPES.CREATE_GIS_LAYER_REQUEST,
  payload,
});

export const requestRemoveDataSetSubscription = (payload) => store.dispatch({
  type: TYPES.REMOVE_DATASET_SUBSCRIPTION_REQUEST,
  payload,
});

export const resetDataSetList = () => store.dispatch({
  type: TYPES.RESET_DATASET_LIST,
});
