import React, { useState, useEffect } from 'react';
import {
  EditorState,
  Modifier,
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js';
import { clone } from 'ramda';
import { Container, Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';

import ArithmeticEditor, { Mention, handleStrategy, getRawData } from '../Components/Editor';
import Switch from '../../../../../elements/Switch/index';

import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import Button from '../../../../../elements/Button';
import SourceSelectorComponent from '../Components/SourceSelectorComponent';
import Field from '../../../../../elements/Field';

const resolutions = [
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.minute" />, id: 0, value: 'minute' },
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.hour" />, id: 1, value: 'hour' },
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.day" />, id: 2, value: 'day' },
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.month" />, id: 3, value: 'month' },
];

const InformationComponent = () => (
  <div className="info-circle-arithmetical">
    <p className="uil-info-circle iconify ">
      <span className="info-text arithmetical">
        <FormattedMessage id="kpis.wizard.step5.arithmetical.info" />
      </span>
    </p>
  </div>
);

const ArithmeticOperationButtons = ({ handleAddValue, handleOnClickArithmeticOperation }) => {
  const [value, setValue] = useState(null);

  const handleChange = (e) => {
    const { value: InputValue } = e.target;
    setValue(InputValue);
  };

  const localAdd = () => {
    handleAddValue(value);
    setValue('');
  };

  return (
    <div className="arithmeticOperationButtons d-flex align-items-center">
      <Button
        className="arithmeticOperationButton"
        onClick={() => handleOnClickArithmeticOperation('', '+')}
      >
        <FormattedMessage
          id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.addition"
        />
      </Button>
      <Button
        className="arithmeticOperationButton"
        onClick={() => handleOnClickArithmeticOperation('', '-')}
      >
        <FormattedMessage
          id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.subtraction"
        />
      </Button>
      <Button
        className="arithmeticOperationButton"
        onClick={() => handleOnClickArithmeticOperation('', '*')}
      >
        <FormattedMessage
          id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.multiplication"
        />
      </Button>
      <Button
        className="arithmeticOperationButton"
        onClick={() => handleOnClickArithmeticOperation('', '/')}
      >
        <FormattedMessage
          id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.division"
        />
      </Button>
      <div className="d-flex pl-3 border-left">
        <Field
          value={value}
          onChange={handleChange}
          className="m-0 mr-2"
          type="number"
        />
      </div>
      <Button
        className="arithmeticOperationButton"
        onClick={localAdd}
      >
        <FormattedMessage
          id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.addValue"
        />
      </Button>

    </div>
  );
};

const getInitialEditorState = (kpi) => {
  const decorator = new CompositeDecorator([
    {
      strategy: handleStrategy,
      component: Mention,
    },
  ]);
  const block = kpi.metadata?.editorConfig
    ? convertFromRaw(kpi.metadata.editorConfig)
    : null;
  return kpi.metadata?.editorConfig
    ? EditorState.createWithContent(block, decorator)
    : EditorState.createEmpty(decorator);
};

const OperationBuilder = ({
  devices, kpi, intl, errors: errorsProps, handleChange,
}) => {
  const [editorState, setEditorState] = useState(getInitialEditorState(kpi));
  const [kpiConfig, setKpiConfig] = useState(kpi.definition);
  const [aggregation, setAggregation] = useState(false);
  const [errors, setErrors] = useState(errorsProps);

  const handleEditorState = (newEditorState, flag) => {
    if (!flag) {
      setEditorState(newEditorState);
      return;
    }

    const modifiedText = Modifier.insertText(
      newEditorState.getCurrentContent(),
      newEditorState.getSelection(),
      ' ',
    );
    const newEditorWithSpace = EditorState.push(
      newEditorState,
      modifiedText,
      'insert-space',
    );
    setEditorState(newEditorWithSpace);
  };

  const handleClearEditor = () => {
    const newEditorState = EditorState.push(editorState, ContentState.createFromText(''));
    handleEditorState(newEditorState);
  };

  const addMention = (data) => {
    const tagName = data.name;
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const contentStateWithEntity = currentContent.createEntity('VARIABLE', 'IMMUTABLE', { ...data });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const suggestionReplacedContent = Modifier.insertText(
      currentContent,
      selection,
      tagName,
      null,
      entityKey,
    );
    const newEditorState = EditorState.push(editorState, suggestionReplacedContent, 'insert-suggestion');
    handleEditorState(newEditorState, true);
  };

  const handleOnClickArithmeticOperation = (value, event) => {
    addMention({ name: event, value: `[${event}]`, type: 'default' });
  };

  const handleAddValue = (value) => {
    addMention({ name: value, value: `value:::${value}`, type: 'default' });
  };

  const handleOnClickSource = (device, attribute, operation) => {
    addMention({ name: `${device.device_id}:${attribute.name}:${operation}`, value: `device:::${device.id}:::${device.device_id}:::${attribute.name}:::${operation}`, type: 'device' });
  };

  const handleSelectResolutionChange = (name, value) => {
    const clonedConfig = clone(kpiConfig);
    clonedConfig.process.options = { resolution: value };
    setKpiConfig(clonedConfig);
  };

  const handleAggregate = () => {
    setKpiConfig((old) => ({
      ...old,
      process: {
        attribute: kpiConfig.process.attribute,
        operation: 'last_occurence',
        step: kpiConfig.process.step,
        urn: kpiConfig.process.urn,
        options: aggregation
          ? undefined
          : { resolution: '' },
      },
    }));
    setAggregation((old) => !old);
  };

  useEffect(() => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const objectToSend = {
      definition: kpiConfig,
      metadata: rawContent,
      rawText: getRawData(rawContent).replace(/ /g, ''),
      aggregation,
    };
    handleChange(objectToSend);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);
  return (
    <Container>
      <div className="d-flex flex-column flex-md-row align-items-center mainContent">
        <div className="wizardWrapper">
          <Row>
            {/* listado de devices */}
            <Col lg={{ size: 4 }}>
              <div className="titles-margin">
                <span className="arithmeticTitles">
                  <FormattedMessage id="kpis.wizard.step5.arithmetical.sourceSelector.title" />
                </span>
              </div>
              <SourceSelectorComponent
                devices={devices}
                handleOnClickSource={handleOnClickSource}
              />
            </Col>

            {/* textarea para realizar las operaciones aritmeticas */}
            <Col lg={{ size: 8 }}>
              <div className="titles-margin">
                <span className="arithmeticTitles">
                  <FormattedMessage id="kpis.wizard.step5.arithmetical.operation.title" />
                </span>
              </div>
              <span className="arithmeticSubtitle">
                <FormattedMessage id="kpis.wizard.step5.arithmetical.operation.subtitle" />
              </span>
              <ArithmeticOperationButtons
                handleAddValue={handleAddValue}
                handleOnClickArithmeticOperation={handleOnClickArithmeticOperation}
              />
              <div>
                <Row>
                  <Col lg={{ size: 12 }}>
                    {editorState
              && (
              <ArithmeticEditor
                editorState={editorState}
                handleChange={handleEditorState}
              />
              )}
                  </Col>
                  <Col lg={{ size: 12 }}>

                    <Button
                      className="clearButton floatRight"
                      type="button"
                      label={intl.formatMessage({
                        id: 'kpis.wizard.step5.arithmetical.editor.clear',
                      })}
                      onClick={handleClearEditor}
                    >
                      <FormattedMessage
                        id="kpis.wizard.step5.arithmetical.editor.clear"
                      />
                    </Button>
                  </Col>
                </Row>
              </div>
              <InformationComponent />

              <div className="time-aggregation">
                <Row className="time-aggregation">
                  <Col lg={{ size: 6 }}>
                    <div className="maxwidth-aggregation">

                      <Switch
                        className="switch"
                        positionTitle="left"
                        title={intl.formatMessage({
                          id: 'kpis.wizard.activateTimeAggregation',
                        })}
                        checked={aggregation}
                        onChange={handleAggregate}
                      />

                    </div>
                  </Col>
                  {!aggregation ? null
                    : (
                      <Col lg={{ size: 6 }}>
                        <Field
                          name="resolution"
                          placeholder={intl.formatMessage({
                            id: 'kpis.wizard.simple.resolution.placeholder',
                          })}
                          id="resolution"
                          value={kpiConfig.process?.options?.resolution ?? undefined}
                          type="select"
                          onChange={handleSelectResolutionChange}
                          helperText={errors.resolution}
                          error={!!errors.resolution}
                          key="resolution"
                          options={resolutions}
                          disabled={!aggregation}
                        />
                      </Col>
                    )}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

    </Container>
  );
};

export default injectIntl(OperationBuilder);
