import React from 'react';
import { connect } from 'react-redux';
import CommonView from '../../../CommonView';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Kpi from '../../../../models/Kpi/index';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import { getDevices, clearDevicesState } from '../../../../services/redux/devices/actions';
import { isNumber } from '../../../../helpers/utils';
import { clearETLProcedures, getETLProcedures } from '../../../../services/redux/etlProcedures/actions';
import SimpleSourceOfDataKPI from '../../../helpers/Add/components/SimpleSourceOfDataKPI';

class SelectSimpleSourceOfData extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      definition: {
        type: this.props.newEntity?.definition?.type ?? 'calculate',
        value: undefined,
        unit: undefined,
        process: {
          step: 'source',
          attribute: '',
          operation: 'last_occurence',
          urn: '',
        },
      },
      errors: this.props.errors,
      onValidation: false,
      devices: this.props.newEntity.connectedDevices ? this.props.newEntity.connectedDevices : [],
      openedPanels: this.props.newEntity.connectedDevices
        ? this.initOpenedStatus(this.props.newEntity.connectedDevices)
        : [],
      etls: [],
      page: 1,
      size: 5,
      mode: this.props.newEntity?.type ?? 'simple',
      pageEtl: 1,
      sizeEtl: 5,
      selectedDevice: this.props.metadata.selectedDevice,
      filter: 'devices',
    };
  }

  savePartialKpi = () => {
    this.setErrors({});

    const obj = this.state;
    const { mode } = this.state;
    const NewKpi = new Kpi(obj);
    const validationErrors = mode === 'manual'
      ? NewKpi.validate(['value', 'unit'])
      : NewKpi.validate(['definition']);

    if (validationErrors) {
      this.setErrors(validationErrors);
      this.props.onError(true);
    } else {
      const { errors, onValidation, ...rest } = this.state;
      this.props.onContinue(rest, { selectedDevice: this.state.selectedDevice });
    }
  };

  validate = () => {
    const { mode } = this.state;

    if (mode === 'manual') {
      this.validateManual();
    } else {
      this.validateSimple();
    }
  }

  validateManual = () => {
    const { value } = this.state.definition;

    const errors = {};
    if (!value) {
      errors.value = (
        <FormattedMessage
          id="Validation.presence"
        />
      );
    } else if (!isNumber(value)) {
      errors.value = (
        <FormattedMessage
          id="Validation.NaN"
        />
      );
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      const newDefinition = this.state.definition;
      newDefinition.value = Number(value);
      this.setState({ definition: newDefinition });
      this.savePartialKpi();
    }
  }

  validateSimple = () => {
    const { process } = this.state.definition;
    if (process.urn) {
      if (!process.attribute) {
        this.props.onError(
          <FormattedMessage id="widgets.wizard.no.attributes.selected" />,
        );
      } else {
        this.savePartialKpi();
      }
    } else {
      this.props.onError(
        <FormattedMessage id="widgets.wizard.no.device.selected" />,
      );
    }
  };

  componentDidMount() {
    const {
      page, pageEtl, sizeEtl, size,
    } = this.state;
    this.props.onRef(this);
    getDevices({ filters: [], page, size });
    getETLProcedures({ filters: [], page: pageEtl, size: sizeEtl });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page, size, filter, pageEtl, sizeEtl,
    } = this.state;
    if (filter === 'devices' && !this.props.fetching && prevState.page < page) {
      getDevices({ filters: [], page, size });
    } else if (filter === 'etls' && !this.props.fetchingETL && prevState.pageEtl < pageEtl) {
      getETLProcedures({ filters: [], page: pageEtl, size: sizeEtl });
    }
  }

  handleChangeFilter = (e) => {
    const { target: { value: filter } } = e;
    this.setState({ filter });
  }

  joinedETLAttributes = (etl) => {
    if (etl && Object.entries(etl).length > 0) {
      const { process, datasources } = etl;
      const outputID = process.find((p) => p.step === 'output').datasources[0];
      const Attributes = Object.keys(datasources.find((d) => d.id === outputID).data.schema.definition).map((k) => ({ title: k }));
      return Attributes;
    }
  }

  joinedAttributes = (device) => {
    if (device && Object.entries(device).length > 0) {
      const { lazy_attributes, static_attributes, attributes } = device;
      const attributesList = {
        lazy: lazy_attributes,
        static: static_attributes,
        attribute: attributes,
      };

      const attributesArray = Array.prototype.concat(
        ...Object.keys(attributesList).map(
          (attrIndex) => attributesList[attrIndex].map((attribute) => ({
            ...attribute,
            attrType: attrIndex,
          })),
        ),
      );
      const attributesToRemove = [];
      attributesArray.forEach((a) => {
        if (a.type !== 'float' && a.type !== 'int' && a.type !== 'integer'
        && a.type !== 'number' && a.type !== 'number') {
          attributesToRemove.push(a);
        }
      });
      attributesToRemove.forEach((a) => {
        attributesArray.splice(attributesArray.indexOf(a), 1);
      });
      return attributesArray;
    }
    return [];
  };

  getOptions = () => {
    const { devices } = this.props;
    return devices && devices.map((o) => ({ name: o.name, id: o.id, value: o }));
  };

  getOptionsEtl = () => {
    const { etls } = this.props;
    return etls && etls.map((e) => ({ name: e.name, id: e.id, value: e }));
  }

  mapDevicesJoined = (d) => this.joinedAttributes(d).map((a) => ({
    ...a,
    selected: false,
  }))

  mapEtlJoined = (d) => this.joinedETLAttributes(d).map((a) => ({
    name: a,
    selected: false,
  }))

  handleSelectableListChanges = (data) => {
    const { filter, selectedDevice, definition: definitionState } = this.state;

    const newData = data.map((d) => (!d.joinedAttributes
      ? {
        ...d,
        joinedAttributes: filter === 'devices' ? this.mapDevicesJoined(d) : this.mapEtlJoined(d),
      }
      : d));

    const definition = {
      type: definitionState.type,
      value: definitionState.value,
      unit: '',
      process: {
        step: 'source',
        attribute: '',
        operation: 'last_occurence',
        urn: data.length > 0
          ? `fiwoo:${filter === 'devices' ? 'device' : 'datasource'}:${data[0].id}`
          : '',
      },
    };

    this.setState({
      [filter]: newData,
      definition,
      selectedDevice: data[0] || selectedDevice,
    });
  };

  handleAttributeStatusChange = (status, attribute, device) => {
    const {
      filter, devices, etls, definition,
    } = this.state;
    const state = filter === 'devices' ? devices : etls;

    const newData = [...state];
    let selected;

    newData
      .find((d) => d.id === device.id)
      .joinedAttributes.forEach((a) => {
        const b = a;
        if (attribute.name === b.name) {
          b.selected = status;
          if (status) {
            selected = a;
          }
        } else {
          b.selected = false;
        }
      });
    const newDefinition = {
      type: definition.type,
      value: definition.value,
      unit: selected ? selected.unit : definition.unit,
      process: {
        step: definition.process.step,
        attribute: selected ? selected.name : undefined,
        operation: definition.process.operation,
        urn: definition.process.urn,
      },
    };

    this.setState({
      [filter]: newData,
      definition: newDefinition,
    });

    this.handleSelectChange('definition', newDefinition);
  };

  handleOnPanelOpen = (index, status) => {
    const openedPanels = [...this.state.openedPanels];
    openedPanels[index] = status;
    this.setState({
      openedPanels,
    });
  };

  handleFetchInfo = () => {
    const {
      total, totalETL, devices, etls,
    } = this.props;
    const { page, filter, pageEtl } = this.state;
    if (filter === 'devices' && total > devices.length) {
      this.setState({
        page: page + 1,
      });
    } else if (filter === 'etls' && totalETL > etls.length) {
      this.setState({
        page: pageEtl + 1,
      });
    }
  };

  handleSelectAllChange = (status, device) => {
    const { filter, devices, etls } = this.state;
    if (filter === 'devices') {
      const devicesL = [...devices];
      const dev = devicesL.find((d) => d.id === device.id);
      dev.joinedAttributes = dev.joinedAttributes.map((attribute) => ({
        ...attribute, selected: status,
      }));

      this.setState({
        devices: devicesL,
      });
    } else {
      const etlsL = [...etls];
      const etl = etlsL.find((e) => e.id === device.id);
      etl.joinedAttributes = etl.joinedAttributes.map((attribute) => ({
        ...attribute, selected: status,
      }));

      this.setState({
        etls: etlsL,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { size, filter, sizeEtl } = this.state;
    if (filter === 'devices') {
      if (init) clearDevicesState();
      getDevices({ filters: { name: value }, page: 1, size });
    } else {
      if (init) clearETLProcedures();
      getETLProcedures({ filters: { name: value }, page: 1, sizeEtl });
    }
  };

  handleChangeMode = (mode) => {
    if (mode === 'manual') {
      this.setState({
        mode,
        definition: {
          type: mode,
          value: this.state.definition.value,
          unit: this.state.definition.unit,
        },
      });
    } else {
      this.setState({
        mode,
        devices: [],
        definition: {
          type: 'calculate',
          value: this.state.definition.value,
          unit: this.state.definition.unit,
          process: {
            step: 'source',
            attribute: '',
            operation: 'last_occurence',
            urn: '',
          },
        },
      });
    }

    this.props.metadata.setKpiType(mode);
  };

  updateMode = () => {
    const mode = this.state.mode ? this.state.mode : 'simple';
    this.setState({
      mode,
    });
  };

  handleManualChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    const { definition } = this.state;
    definition[name] = value;
    this.setState({
      definition,
    });
  }

  getOptionsWithFilters = () => {
    const { filter } = this.state;
    return filter === 'devices' ? this.getOptions() : this.getOptionsEtl();
  }

  render() {
    const { fetching, fetchingETL } = this.props;
    const {
      errors, openedPanels, mode, value, unit, filter,
    } = this.state;
    return (
      <SimpleSourceOfDataKPI
        filter={filter}
        selectableFilter={{
          handler: this.handleChangeFilter,
          filters: [{ name: 'devices', translation: 'devices.list.title' }, { name: 'etls', translation: 'etl.list.title' }],
          checked: filter,
        }}
        mode={mode}
        options={this.getOptionsWithFilters()}
        selected={filter === 'devices' ? this.state.devices : this.state.etls}
        errors={errors}
        value={value}
        unit={unit}
        fetching={filter === 'devices' ? fetching : fetchingETL}
        openedPanels={openedPanels}
        handleChangeMode={this.handleChangeMode}
        handleManualChange={this.handleManualChange}
        handleOnSearch={this.handleOnSearch}
        handleFetchInfo={this.handleFetchInfo}
        handleSelectableListChanges={this.handleSelectableListChanges}
        handleOnPanelOpen={this.handleOnPanelOpen}
        handleAttributeStatusChange={this.handleAttributeStatusChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  addKpiSuccessFlag: state.get('kpis').get('addSuccess'),
  errorFetching: state.get('kpis').get('errorFetching'),
  notify: state.get('notify'),
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
  etls: state
    .get('etlProcedures')
    .get('list')
    .toJS(),
  fetchingETL: state.get('etlProcedures').get('fetching'),
  totalETL: state.get('etlProcedures').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(SelectSimpleSourceOfData));
