/* eslint-disable import/no-cycle */
import { getUrnId } from '../../../../../../helpers/utils';
import { calculateSimpleLastValue, formatToCommonWidgetSingleDevice } from '../../../../utils';
import NeedleWidget from '../../../needle';
import { NeedleDefaultConfigurationForLinked } from './utils/defaultConfiguration';
import { NeedleConfigurationSheetForLinked } from './utils/configurationSheet';

export default class LinkedNeedle extends NeedleWidget {
  defaultConfig = ({ ...NeedleDefaultConfigurationForLinked })

  getQueryHistorical = () => null;

  ifUsesContainerValues = true

  getConfigurationSheet = () => NeedleConfigurationSheetForLinked;

  formatToData = (values, sources, config, selection) => {
    const { operation } = config.data;
    if (values?.length || values?.data?.length) {
      const attributeOfWidget = config.data.attributeFilter[0];
      let value;
      if (selection.length === 1) {
        const data = values.find(
          (d) => getUrnId(d.urn) === selection[0] && d.attrName === attributeOfWidget,
        );
        value = data?.value?.value || 0;
      } else if (selection.length > 1) {
        const calculatedValues = calculateSimpleLastValue(
          values,
          attributeOfWidget,
          operation,
          selection,
          3,
        );
        value = calculatedValues || 0;
      } else {
        value = 0;
      }
      return value;
    }
    return formatToCommonWidgetSingleDevice(this.sources[0]);
  }
}
