/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Editor, DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import MapGL from 'react-map-gl';
import { getEditHandleStyle, getFeatureStyle } from './components/style';

const center = [-5.98554253578186, 37.420685466315014];
class PolygonMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.editorRef = null;
    const { zoom, mode } = this.props;
    this.state = {
      viewport: {
        longitude: center[0],
        latitude: center[1],
        zoom: zoom || 12,
      },
      mode,
      selectedFeatureIndex: null,
    };
  }

  _getFeatures = () => (this.editorRef ? this.editorRef.getFeatures() : null);

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  _onSelect = (options) => {
    this.setState({ selectedFeatureIndex: options && options.selectedFeatureIndex });
  };

  _onDelete = () => {
    const { selectedFeatureIndex } = this.state;
    const selectedIndex = selectedFeatureIndex;
    if (selectedIndex !== null && selectedIndex >= 0) {
      this.editorRef.deleteFeatures(selectedIndex);
    }
  };

  _onUpdate = ({ editType, data }) => {
    const { onePolygon } = this.props;

    if (onePolygon && editType === 'addFeature') {
      const lastFeature = data.pop();
      this.editorRef.deleteFeatures(data.length - 1);
      this.editorRef.addFeatures(lastFeature);
    }

    if (editType === 'addFeature') {
      this.setState({
        mode: new EditingMode(),
      });
    }
  };

  _renderDrawTools = () => (
    <div className="mapboxgl-ctrl-top-left">
      <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
          title="Añadir área"
          onClick={() => this.setState({ mode: new DrawPolygonMode() })}
          type="button"
        >
          <i className="uil uil-pen" />
        </button>
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
          title="Borrar área"
          onClick={this._onDelete}
          type="button"
        >
          <i className="uil uil-trash" />
        </button>
      </div>
    </div>
  );

  assignRef = (_) => {
    const { features } = this.props;
    this.editorRef = _;
    if (this.editorRef && features !== 'empty') this.editorRef.addFeatures(features);
  };

  render() {
    const { accessToken, styleMap } = this.props;
    const { viewport, mode } = this.state;

    return (

      <MapGL
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={styleMap}
        mapboxApiAccessToken={accessToken}
        onViewportChange={this._updateViewport}
      >
        <Editor
          key="map-gl-editor"
          ref={this.assignRef}
          style={{ width: '100%', height: '100%' }}
          clickRadius={24}
          mode={mode}
          onSelect={this._onSelect}
          onUpdate={this._onUpdate}
          editHandleShape="circle"
          featureStyle={getFeatureStyle}
          editHandleStyle={getEditHandleStyle}
        />

        {mode !== null && this._renderDrawTools()}
      </MapGL>
    );
  }
}

PolygonMap.defaultProps = {
  accessToken: 'pk.eyJ1IjoianVhbmFuOTEiLCJhIjoiY2pvc2ZvZ2pnMDQzczNrcWZjeHcxYWhmYiJ9.MCr17mXwiNhzw37MucyfbQ',
  styleMap: 'mapbox://styles/mapbox/light-v9',
  onePolygon: false,
  zoom: 12,
  features: [],
};

export default PolygonMap;
