import { clone } from 'ramda';

export const getCommandAttributes = (sources, allAttributes) => {
  const attributes = clone(allAttributes);
  sources.forEach((origin) => {
    // ToDo When source kpi is implement we need change this condition
    origin.commands.forEach((attribute) => {
      const exits = attributes.find((att) => att.name === attribute.name);
      if (!exits) {
        attributes.push(attribute);
      }
    });
  });
  return attributes;
};

export default getCommandAttributes;
