import React from 'react';
import PropTypes from 'prop-types';
// import RemoveHoc from "./../../helpers/RemoveComponent";
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';

const View = (
  <FormattedMessage
    id="options.view"
    defaultMessage="view"
    description="Title of view"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const Clone = (
  <FormattedMessage
    id="options.clone"
    defaultMessage="clone"
    description="Title of clone"
  />
);

// const RemoveDOM = RemoveHoc(ButtonOpen, { class: Dashboard, nameData: "dashboards" })

const OptionsForWidget = ({ rowContent: dashboardContent, viewClick }) => (
  <div className="tableMenu">
    <ul className="list-unstyled">
      <li
        onClick={() => { viewClick(dashboardContent); }}
        state={{ dashboard: dashboardContent }}
        role="presentation"
      >
        {View}
        <i className="uil uil-arrow-right" />
      </li>
      <li className="divider" />
      <li
        onClick={() => { viewClick(dashboardContent); }}
        state={{ dashboard: dashboardContent, mode: 'edit' }}
        role="presentation"
      >
        {Edit}
      </li>
      {/* <RemoveDOM rowContent={dashboardContent} /> */}
      <li
        onClick={() => { }}
        state={{ dashboard: dashboardContent }}
        role="presentation"
      >
        {Clone}
      </li>
    </ul>
  </div>
);

OptionsForWidget.propTypes = {
  rowContent: PropTypes.string,
  viewClick: PropTypes.func,
};

OptionsForWidget.defaultProps = {
  rowContent: '',
  viewClick: () => {},
};

export default OptionsForWidget;
