const TableDefaultConfiguration = {
  appearance: {
    hidden: [],
    orientation: 'columns',
  },
  colors: {
    headerColor: '#FFFFFF',
    headerTableBackground: '#FFFFFF',
    headerTableColorText: '#2B334A',
  },
  conditions: { },
  effects: { },
  custom: { },
  data: {
    operation: 'last-value',
    type: 'real-time',
    sampling: '',
  },
  labels: {
    alias: { },
  },
  trendlines: { },
  values: { },
};

export default TableDefaultConfiguration;

export const injectTableData = (widget) => {
  const { config, sources } = widget;
  const newConfig = { ...config };
  sources.forEach((source) => {
    source.fields.forEach((attr) => {
      if (!newConfig.labels.alias[attr]) {
        newConfig.labels.alias[attr] = attr;
      }
    });
  });
  return newConfig;
};
