import React from 'react';
import './styles.scss';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import Menu from '../Menu';
import BasicTable from './components/BasicTable';
import { isMobile } from '../../helpers/getBreakpoint';
import MobileTable from './components/MobileTable';

const R = require('ramda');

const ButtonForOpenOptionMenu = ({ onClick }) => (
  <div className="text-right">
    <i role="presentation" className="uil uil-ellipsis-v" onClick={onClick} />
  </div>
);

const OptionsMenu = (props) => {
  const { OptionsForRow } = props;
  return (
    <Menu
      top="40px"
      openComponent={ButtonForOpenOptionMenu}
      openFrom="top right"
      className="more"
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <OptionsForRow {...props} />
    </Menu>
  );
};

class CustomTable extends React.Component {
  constructor(props) {
    super(props);

    const {
      selectable, body, header, inOrder,
    } = this.props;

    let checkboxes = [];
    if (selectable === true) {
      checkboxes = this.initCheckboxes(body.length, false);
    }

    this.state = {
      body,
      header,
      inOrder: inOrder || {},
      checked: checkboxes,
    };
  }

  componentDidUpdate(prevProps) {
    const { body, selectable } = this.props;
    if (prevProps.body !== body) {
      let checked;
      if (selectable) {
        checked = this.initCheckboxes(body.length);
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        body,
        checked,
      });
    }
  }

  initCheckboxes = (length, option = false) => {
    const checkboxes = [];
    let index = 0;
    while (index < length) {
      checkboxes.push(option);
      index += 1;
    }

    return checkboxes;
  };

  getHeader = (key) => {
    const { header } = this.props;
    return header.find((h) => h.key === key);
  }
    ;

  orderDataByKey = (data) => {
    const comparators = [];
    const { inOrder } = this.state;
    Object.entries(inOrder).forEach((ord) => {
      const key = ord[0];
      if (!this.getHeader(key).comparator) {
        if (inOrder[key] === 'asc') comparators.push(R.ascend(R.prop(key)));
        else if (inOrder[key] === 'desc') comparators.push(R.descend(R.prop(key)));

        const SortArray = R.sortWith(comparators);
        return SortArray(data);
      }
      return data.sort(this.getHeader(key).comparator(inOrder[key]));
    });
    return data;
  };

  checkOrderDirection = (order = undefined) => {
    if (!order) return 'desc';
    if (order === 'desc') return 'asc';
    return undefined;
  };

  handleHeaderClick = (header) => {
    const { key } = header;
    const { inOrder: _inOrder } = this.state;
    if (!header.noOrder) {
      // eslint-disable-next-line prefer-object-spread
      const inOrder = Object.assign({}, _inOrder);
      inOrder[key] = this.checkOrderDirection(inOrder[key]);
      this.setState({ inOrder });
    }
  };

  addIconByOrder = (header) => {
    const { key } = header;
    const { inOrder } = this.state;
    if (inOrder[key] === 'asc') return <i className="uil uil-angle-down expandMore" />;
    if (inOrder[key] === 'desc') { return <i className="uil uil-angle-up expandLess " />; }
    return null;

    // return <i className='uil uil-angle-down expandMore'></i>
  };

  handleOnMoreButton = (data) => {
    const { onClickMore } = this.props;
    onClickMore(data);
  };

  handleOnCheckedAll = (checked) => {
    const { checked: _checked } = this.state;
    let checkboxes = clone(_checked);
    checkboxes = this.initCheckboxes(checkboxes.length, checked);
    this.setState({
      checked: checkboxes,
    });
  };

  handleOnChecked = (index) => {
    const { checked: _checked } = this.state;
    const checkboxes = clone(_checked);
    checkboxes[index] = !checkboxes[index];

    this.setState({
      checked: checkboxes,
    });
  };

  render() {
    const {
      optionsForRow,
      optionsForTop,
      css,
      selectable,
      typeOfData,
      onRemove,
      rowComponent,
      onRowClick,
      rowComponentForMobile,
      entity,
    } = this.props;
    const { body, header, checked } = this.state;
    const bodyOrdered = this.orderDataByKey(body);

    return !isMobile() ? (
      <BasicTable
        css={css}
        onCheckedAll={this.handleOnCheckedAll}
        checked={checked}
        typeOfData={typeOfData}
        onRemove={onRemove}
        body={bodyOrdered}
        header={header}
        optionsForRow={optionsForRow}
        optionsForTop={optionsForTop}
        selectable={selectable}
        onChecked={this.handleOnChecked}
        rowComponent={rowComponent}
        onRowClick={onRowClick}
        optionsMenu={OptionsMenu}
        addIconByOrder={this.addIconByOrder}
        entity={entity}
      />
    ) : (
      <MobileTable
        body={bodyOrdered}
        header={header}
        checked={checked}
        onChecked={this.handleOnChecked}
        onCheckedAll={this.handleOnCheckedAll}
        optionsMenu={OptionsMenu}
        optionsForRow={optionsForRow}
        rowComponentForMobile={rowComponentForMobile}
        typeOfData={typeOfData}
        onRowClick={onRowClick}
        selectable={selectable}
        entity={entity}
      />
    );
  }
}

CustomTable.defaultProps = {
  body: [],
  header: [],
  optionsForRow: null,
  optionsForTop: null,
  css: '',
  selectable: false,
  onRowClick: () => {},
  onClick: () => {},
  onChange: () => {},
};

export default injectIntl(CustomTable);
