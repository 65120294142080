import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { ConfigureProtocol } from '../../helpers/Profile/ProfileDevice/ConfigureProtocol';
import EditProperties from '../../helpers/Profile/ProfileDevice/EditProperties';
import EditPermissions from '../../helpers/Profile/EditPermissions';
import Service from '../../../models/Service';
import { havePermissionToEdit } from "../../../helpers/utils";
import checkPermission from '../../../components/PrivateComponent/checkPrivateComponent'

const STRING_TRANSLATION_HEADER = "services.profile.properties";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { ...this.props.data },
    };
  }

  componentDidMount() {
    const backendPermisssion = checkPermission(this.props.userMe, '/app/services', 'update');
    havePermissionToEdit(this.props.data.permissions_policy, this.props.data.owner)
      .then(permissionToEdit => this.setState({permissionToEdit: permissionToEdit && backendPermisssion}))

  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ data: { ...this.props.data } });
    }

    if (
      JSON.stringify(prevProps.services) !== JSON.stringify(this.props.services)
    ) {
      const foundData = this.props.services.find(
        o => o.id === this.props.data.id
      );
      this.setState({ data: foundData });
    }
  }

  handleOnSave = (id, data) => {
    if (this.state[id] !== data) {
      const newData = { ...this.state.data, [id]: data };
      // TODO remove when all devices are updated.
      newData.organization = newData.organization ? newData.organization : '123456789123456789123456789';
      newData.dataset = newData.dataset ? newData.dataset : '123456789123456789123456789';
      const updated = new Service(newData).update();

      if (updated.error) {
        delete updated.error;
        this.setState({
          errors: updated,
        });
      } else {
        this.setState({
          errors: undefined,
        });
      }
      // this.setState({ data: newData});
    }
  };

  render() {
    const { data, errors, permissionToEdit } = this.state;
    return (
      <ProfileComponent
        data={data}
        titles={[
          <FormattedMessage id={`services.profile.protocols`} />,
          <FormattedMessage id={`services.profile.properties`} />,
          <FormattedMessage id={`services.profile.permissions`} />,
        ]}
        save={this.handleOnSave}
        errors={errors}
        editable={permissionToEdit}
      >
        <ConfigureProtocol data={data} entity={Service} />
        <EditProperties data={data} permissionToEdit={permissionToEdit} entity={Service} stringTranslationHeader={STRING_TRANSLATION_HEADER}/>
        <EditPermissions data={data} permissionToEdit={permissionToEdit} entity={Service} />
      </ProfileComponent>
    );
  }
}

const mapStateToProps = state => ({
  services: state
    .get('services')
    .get('list')
    .toJS(),
  userMe: state.get('users').get('userMe'),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
