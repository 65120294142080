import Moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
const DEFAULT_DATE = {
  startDate: '2000-01-01T00:00:00Z',
  endDate: '2200-01-01T00:00:00Z',
};

const isNotRealTime = (configuration) => configuration.type !== 'real-time';

export const getResolution = (sampling) => {
  switch (sampling) {
    case 'lastHour':
    case 'lastDay':
      return 'minute';

    case 'lastFifteenDays':
    case 'lastMonth':
      return 'hour';

    case 'lastThreeMonth':
    case 'lastYear':
      return 'day';

    default:
      return 'hour';
  }
};

export const getResolutionByDateRange = (startDate, endDate) => {
  const totalDays = (
    new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24);
  let resolution = 'hour';

  // -1 day --> minute
  if (totalDays <= 1) resolution = 'minute';

  // +1 day --> hour
  else if (totalDays <= 60) resolution = 'hour';

  // +2 month --> days
  else if (totalDays > 60) resolution = 'day';

  return resolution;
};

export const getDateOfSampling = (sampling) => {
  const date = Moment.utc().set('second', 0);
  switch (sampling) {
    case 'lastHour':
      return date.subtract(1, 'hour');

    case 'lastDay':
      return date.subtract(1, 'day');

    case 'lastFifteenDays':
      return date.subtract(15, 'day');

    case 'lastMonth':
      return date.subtract(1, 'month');

    case 'lastThreeMonth':
      return date.subtract(3, 'month');

    case 'lastYear':
      return date.subtract(1, 'year');

    default:
      return date;
  }
};

export const getDate = (type, historical, format = DATE_FORMAT) => {
  const { configuration } = historical;
  const { data } = configuration;
  if (!data) {
    return Moment.utc(DEFAULT_DATE[type]).format(format);
  } if (isNotRealTime(data) && data[type]) {
    return Moment.utc(data[type]).format(format);
  } if (type === 'endDate') {
    return Moment.utc().format(format);
  } if (data.sampling) {
    return getDateOfSampling(data.sampling).format(format);
  }
  return Moment.utc(DEFAULT_DATE[type]).format(format);
};

export const getDateFromSampling = (type, sampling = undefined, format = DATE_FORMAT) => {
  if (type === 'endDate') {
    return Moment.utc().set('second', 0).format(format);
  }
  if (type === 'startDate' && sampling) {
    return getDateOfSampling(sampling).format(format);
  }
  if (type === 'lastHour') {
    return Moment.utc().set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).format();
  }
};
