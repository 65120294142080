import {
  takeLatest, takeEvery, call, put, all, fork,
} from 'redux-saga/effects';

import ReportHistorical from '../../../models/ReportHistorical';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/reportHistorical';

/**
 * Actions for WATCHERS
 * */

// Try to recover all service form DB
export function* getReportHistoricalThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readReportHistorical,
      payload.page,
      payload.size,
      payload.filters,
      payload.id,
    );
    if (response.status === 200) {
      const historical = response.data.objects.map((rh) => new ReportHistorical(rh));
      yield put({
        type: TYPES.GET_REPORT_HISTORICAL_SUCCESS,
        payload: historical,
      });
    } else {
      yield put({ type: TYPES.GET_REPORT_HISTORICAL_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_REPORT_HISTORICAL_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Get URL to download ReportHistorical on minio
export function* downloadReportHistoricalThroughApi({ payload }) {
  try {
    const response = yield call(Api.downloadReportHistorical, payload);

    if (response.status === 201) {
      yield put({ type: TYPES.DOWNLOAD_REPORT_HISTORICAL_REDIRECT, payload: response.data });
    } else {
      yield put({ type: TYPES.DOWNLOAD_REPORT_HISTORICAL_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DOWNLOAD_REPORT_HISTORICAL_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */

// Watcher looking for GET_REPORT_HISTORICAL_REQUEST
function* watcherGetReportHistorical() {
  yield takeEvery('GET_REPORT_HISTORICAL_REQUEST', getReportHistoricalThroughApi);
}

// Watcher looking for DOWNLOAD_REPORT_HISTORICAL_REQUEST
function* watcherDownloadReportHistorical() {
  yield takeEvery('DOWNLOAD_REPORT_HISTORICAL_REQUEST', downloadReportHistoricalThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetReportHistorical),
    fork(watcherDownloadReportHistorical),
  ]);
}
