/* eslint-disable import/no-cycle */
import {
  takeLatest, takeEvery, call, put, all, fork,
} from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import Kpi from '../../../models/Kpi';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/kpis';
import { parseToSources } from '../../../helpers/utils';

/**
 * Actions for WATCHERS
 * */
// Try to recover one kpi from db
export function* getKpiThroughApi({ payload }) {
  const { isPublicDashboard } = payload;
  const endpoint = isPublicDashboard ? Api.readPublicKpi : Api.readKpi;
  try {
    const response = yield call(endpoint, payload);
    if (response.status === 200) {
      const kpiFromDB = new Kpi(response.data.object);
      yield put({ type: TYPES.GET_KPI_SUCCESS, payload: kpiFromDB });
      yield put({ type: TYPES.GET_KPI_V2, payload: parseToSources(kpiFromDB) });
    } else {
      yield put({ type: TYPES.GET_KPI_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_KPI_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all service form DB
export function* getKpisThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readKpis,
      payload.page,
      payload.size,
      payload.filters,
      payload.ckan,
      'all',
    );
    if (response.status === 200) {
      const kpis = response.data.objects.map((kpi) => new Kpi(kpi));
      yield put({
        type: TYPES.GET_KPIS_SUCCESS,
        payload: { kpis, total: response.data.total },
      });
      yield put({
        type: TYPES.GET_KPI_TO_SOURCE_LIST,
        payload: kpis.map(parseToSources),
      });
    } else {
      yield put({ type: TYPES.GET_KPIS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_KPIS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getKpiTableThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readKpis,
      payload.page,
      payload.size,
      payload.filters,
      payload.ckan,
      'all',
    );
    if (response.status === 200) {
      const kpis = response.data.objects.map((kpi) => new Kpi(kpi));
      yield put({
        type: TYPES.GET_KPI_TABLE_SUCCESS,
        payload: { kpis, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_KPI_TABLE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_KPI_TABLE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getMyKpisThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.readMyKpis,
      payload.page,
      payload.size,
      payload.filters,
      'mine',
    );
    if (response.status === 200) {
      const kpis = response.data.objects.map((kpi) => new Kpi(kpi));
      yield put({
        type: TYPES.GET_MY_KPIS_SUCCESS,
        payload: { kpis, total: response.data.total },
      });
    } else {
      yield put({ type: TYPES.GET_MY_KPIS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MY_KPIS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* addKpiThroughApi({ payload }) {
  try {
    const response = yield call(
      Api.addKpi,
      payload,
    );
    if (response.status === 201) {
      const kpi = new Kpi(response.data.object);
      yield put({
        type: TYPES.ADD_KPI_SUCCESS,
        payload: kpi,
      });
    } else {
      yield put({ type: TYPES.ADD_KPI_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (e) {
    yield put({ type: TYPES.ADD_KPI_ERROR, payload: e });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: e });
  }
}

// Try to update kpi in DB
export function* updateKpiThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateKpi, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.UPDATE_KPI_SUCCESS,
        payload: new Kpi(response.data.object),
      });
    } else {
      yield put({ type: TYPES.UPDATE_KPI_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.UPDATE_KPI_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete kpi in DB
export function* deleteKpiThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteKpi, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_KPI_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_KPI_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_KPI_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* executeKpiThroughApi({ payload }) {
  try {
    const response = yield call(Api.executeKpi, payload);
    if (response.status === 201) {
      yield put({
        type: TYPES.EXECUTE_KPI_SUCCESS,
        payload,
      });
    } else {
      yield put({ type: TYPES.EXECUTE_KPI_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.EXECUTE_KPI_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_KPIS_REQUEST
function* watcherGetKpis() {
  yield takeLatest(TYPES.GET_KPIS_REQUEST, getKpisThroughApi);
}

// Watcher looking for GET_KPI_REQUEST
function* watcherGetKpi() {
  yield takeEvery(TYPES.GET_KPI_REQUEST, getKpiThroughApi);
}

// Watcher looking for GET_MY_KPIS_REQUEST
function* watcherGetMyKpis() {
  yield takeLatest(TYPES.GET_MY_KPIS_REQUEST, getMyKpisThroughApi);
}

function* watcherAddKpi() {
  yield takeLatest(TYPES.ADD_KPI_REQUEST, addKpiThroughApi);
}

// Watcher looking for UPDATE_KPI_REQUEST
function* watcherUpdateKpi() {
  yield takeLatest(TYPES.UPDATE_KPI_REQUEST, updateKpiThroughApi);
}

// Watcher looking for DELETE_KPIS_REQUEST
function* watcherDeleteKpi() {
  yield takeEvery(TYPES.DELETE_KPI_REQUEST, deleteKpiThroughApi);
}

// Watcher looking for EXECUTE_KPI_REQUEST
function* watcherExecuteKpi() {
  yield takeEvery(TYPES.EXECUTE_KPI_REQUEST, executeKpiThroughApi);
}

// Watcher looking for GET_KPI_TABLE_REQUEST
function* watcherGetKpiTable() {
  yield takeLatest(TYPES.GET_KPI_TABLE_REQUEST, getKpiTableThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetKpi),
    fork(watcherGetKpis),
    fork(watcherGetMyKpis),
    fork(watcherAddKpi),
    fork(watcherUpdateKpi),
    fork(watcherDeleteKpi),
    fork(watcherExecuteKpi),
    fork(watcherGetKpiTable),
  ]);
}
