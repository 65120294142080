import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UsersList from './Users/List/List';
import UsersGroupsList from './UsersGroups/List';
import RolesList from './Roles/List/List';
import TabComponent from '../../components/Tab';

import './styles.scss';
import { FormattedMessage } from '../../Contexts/LanguageContext';
import PrivateComponent from '../../components/PrivateComponent/privateComponent';

class List extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  render() {
    return (
      <TabComponent
        titles={[
            <FormattedMessage id={'users.list.title'} />,
            <FormattedMessage id={'usersGroups.list.title'} />,
            <FormattedMessage id={'roles.list.title'} />
        ]}
        id={'UsersList'}
      >
        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'list'}>
          <UsersList />
        </PrivateComponent>
        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/groups'} permission={'list'}>
          <UsersGroupsList />
        </PrivateComponent>
        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/roles'} permission={'list'}>
          <RolesList />
        </PrivateComponent>
      </TabComponent>
    );
  }
}

export default List;
