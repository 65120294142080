export const ADD_DATASOURCE_REQUEST = 'ADD_DATASOURCE_REQUEST';
export const ADD_DATASOURCE_SUCCESS_FLAG = 'ADD_DATASOURCE_SUCCESS_FLAG';
export const ADD_DATASOURCE_ERROR = 'ADD_DATASOURCE_ERROR';
export const ADD_DATASOURCE_SUCCESS = 'ADD_DATASOURCE_SUCCESS';

export const MODIFY_DATASOURCE_REQUEST = 'MODIFY_DATASOURCE_REQUEST';
export const MODIFY_DATASOURCE_SUCCESS_FLAG = 'MODIFY_DATASOURCE_SUCCESS_FLAG';
export const MODIFY_DATASOURCE_SUCCESS = 'MODIFY_DATASOURCE_SUCCESS';
export const MODIFY_DATASOURCE_ERROR = 'MODIFY_DATASOURCE_ERROR';

export const DELETE_DATASOURCE_REQUEST = 'DELETE_DATASOURCE_REQUEST';
export const DELETE_DATASOURCE_SUCCESS = 'DELETE_DATASOURCE_SUCCESS';
export const DELETE_DATASOURCE_ERROR = 'DELETE_DATASOURCE_ERROR';

export const GET_DATASOURCE = 'GET_DATASOURCE';
export const GET_DATASOURCE_REQUEST = 'GET_DATASOURCE_REQUEST';
export const CLOSE_DATASOURCE_REQUEST = 'CLOSE_DATASOURCE_REQUEST';
export const GET_DATASOURCE_ROLES_REQUEST = 'GET_DATASOURCE_ROLES_REQUEST';
export const GET_DATASOURCE_SUCCESS = 'GET_DATASOURCE_SUCCESS';
export const GET_DATASOURCE_ERROR = 'GET_DATASOURCE_ERROR';

export const GET_DATASOURCES_REQUEST = 'GET_DATASOURCES_REQUEST';
export const GET_DATASOURCES_ERROR = 'GET_DATASOURCES_ERROR';
export const GET_DATASOURCES_SUCCESS = 'GET_DATASOURCES_SUCCESS';

export const GET_DATASOURCES_FROM_DEVICES_REQUEST = 'GET_DATASOURCES_FROM_DEVICES_REQUEST';
export const GET_DATASOURCES_FROM_DEVICES_ERROR = 'GET_DATASOURCES_FROM_DEVICES_ERROR';
export const GET_DATASOURCES_FROM_DEVICES_SUCCESS = 'GET_DATASOURCES_FROM_DEVICES_SUCCESS';

export const GET_MINE_DATASOURCES_REQUEST = 'GET_MINE_DATASOURCES_REQUEST';

export const GET_MINE_DATASOURCES_ERROR = 'GET_MINE_DATASOURCES_ERROR';
export const GET_MINE_DATASOURCES_SUCCESS = 'GET_MINE_DATASOURCES_SUCCESS';

export const GET_PUBLIC_DATASOURCES_REQUEST = 'GET_PUBLIC_DATASOURCES_REQUEST';
export const GET_PUBLIC_DATASOURCES_ERROR = 'GET_PUBLIC_DATASOURCES_ERROR';
export const GET_PUBLIC_DATASOURCES_SUCCESS = 'GET_PUBLIC_DATASOURCES_SUCCESS';

export const CLEAR_DATASOURCES_STATE = 'CLEAR_DATASOURCES_STATE';
