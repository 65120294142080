import colors from '../../../../configuration/colors';

export function getEditHandleStyle({ state }) {
  switch (state) {
    case 'SELECTED':
    case 'HOVERED':
    case 'UNCOMMITTED':
      return {
        fill: 'white',
        fillOpacity: 1,
        stroke: colors['ui-secondary'],
        strokeWidth: 2,
        r: 8,
      };

    default:
      return {
        fill: 'white',
        fillOpacity: 1,
        stroke: colors['ui-secondary'],
        strokeWidth: 2,
        r: 6,
      };
  }
}

export function getFeatureStyle({ state }) {
  switch (state) {
    case 'SELECTED':
    case 'HOVERED':
    case 'UNCOMMITTED':
    case 'CLOSING':
      return {
        stroke: colors['ui-secondary'],
        strokeWidth: 2,
        fill: colors['ui-secondary'],
        fillOpacity: 0.3,
      };

    default:
      return {
        stroke: colors['ui-secondary'],
        strokeWidth: 2,
        fill: colors['ui-secondary'],
        fillOpacity: 0.2,
      };
  }
}
