import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { isMobile } from '../../helpers/getBreakpoint';
import DesktopStepper from './Components/DesktopStepper';
import MobileStepper from './Components/MobileStepper';

class Stepper extends React.Component {
  isStep = (i) => {
    const { success, starter, maxStep } = this.props;
    if (starter && success) {
      return i !== 0 && i !== maxStep - 1;
    }
    if (starter) {
      return i !== 0;
    }
    if (success) {
      return i !== maxStep - 1;
    }
    return true;
  };

  getActualStep = () => {
    const { starter, actualStep } = this.props;
    return starter ? actualStep - 1 : actualStep;
  };

  getMaxStep = () => {
    const { success, starter, maxStep } = this.props;
    if (success && starter) return maxStep - 2;
    return success ? maxStep - 1 : maxStep + 2;
  };

  getClassName = (i, actualStep) => {
    const { starter } = this.props;
    const index = starter ? i : i + 1;
    if (actualStep === index) return 'enable';
    return actualStep < index ? 'disable' : 'done';
  };

  getBullet = (i, actualStep) => {
    const { starter } = this.props;
    const index = starter ? i : i + 1;
    return actualStep === index ? (
      <span className="bullet" />
    ) : (
      <div className="tick"><i className="uil uil-check" /></div>
    );
  };

  getStepper = () => {
    const { stepsTitles, maxStep } = this.props;
    const actualStep = this.getActualStep();
    if (isMobile()) {
      return (
        <MobileStepper
          currentTitle={stepsTitles[actualStep]}
          maxStep={maxStep}
          actualStep={actualStep}
        />
      );
    }
    return stepsTitles.map(
      (step, i) => this.isStep(i) && (
      <DesktopStepper
        key={step.id}
        className={this.getClassName(i, actualStep)}
        bullet={this.getBullet(i, actualStep)}
        step={step}
      />
      ),
    );
  }

  render() {
    return (
      <div className="stepperWrapper">
        <div className="stepper">
          {this.getStepper()}
        </div>
      </div>
    );
  }
}

Stepper.propTypes = {
  actualStep: PropTypes.number,
  maxStep: PropTypes.number,
  success: PropTypes.bool,
  starter: PropTypes.bool,
  stepsTitles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Stepper.defaultProps = {
  actualStep: 0,
  maxStep: 0,
  success: true,
  starter: false,
};

export default Stepper;
