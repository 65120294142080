import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
// if we put BasicList between curly brackets, we cannot access the alerts page
// eslint-disable-next-line import/no-named-as-default
import BasicList from '../../helpers/BasicList';
import {
  getAlerts,
  clearAlertsState,
} from '../../../services/redux/alerts/actions';
import Alert, { SampleAlert } from '../../../models/Alert';
import WithDrawer from '../../../components/DrawerContainer/index';
import { Profile } from '../Profile';
import CustomTable from '../../../components/CustomTable';
import TD from '../../../components/CustomTable/components/td';
import { SeverityTag } from '../../../elements/SeverityTag';
import parseDate from '../../../helpers/timeAgo';
import {
  generalComparator,
  dateIssuedComparator,
} from '../../../helpers/comparators';
import OptionsForRow from './OptionsForRow';

const headers = [
  {
    label: <FormattedMessage id="Alerts.description" />,
    comparator: (direction) => generalComparator(direction, 'description'),
    key: 'description',
  },
  {
    label: <FormattedMessage id="Alerts.dateIssued" />,
    key: 'dateIssued',
    comparator: (direction) => dateIssuedComparator(direction),
    type: 'date',
  },
  {
    label: <FormattedMessage id="Alerts.severity" />,
    comparator: (direction) => generalComparator(direction, 'severity'),
    key: 'severity',
  },
];

const filters = [
  {
    name: <FormattedMessage id="Alerts.description" />,
    value: 'description',
    key: 'description',
  },
];

const ProfileComponent = WithDrawer(
  ({
    onChange, data, enableChangePassword, ...rest
  }) => (
    <Profile
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={onChange}
      data={data}
      enableChangePassword={enableChangePassword}
    />
  ),
);

const IncidentsTableRows = (data, onRowClick) => (
  <>
    <TD onClick={onRowClick}>{data.description}</TD>
    <TD onClick={onRowClick}>{parseDate(data.dateIssued)}</TD>
    <TD onClick={onRowClick}>
      <SeverityTag severity={data.severity} />
    </TD>
  </>
);

const IncidentsTableRowsForMobile = (data, header) => (
  <>
    {header.map((h, j) => (
      // eslint-disable-next-line react/no-array-index-key
      <dl key={j}>
        <dt>{h.label}</dt>
        <dd>
          {(() => {
            switch (h.key) {
              case 'description':
                return data.description;
              case 'dateIssued':
                return parseDate(data.dateIssued);
              case 'severity':
                return <SeverityTag severity={data.severity} />;
              default:
                return '';
            }
          })()}
        </dd>
      </dl>
    ))}
  </>
);

const IncidentsTable = (props) => (
  <CustomTable
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    inOrder={{ dateIssued: 'desc' }}
    rowComponent={IncidentsTableRows}
    rowComponentForMobile={IncidentsTableRowsForMobile}
  />
);

export class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      itemsPerPage: 5,
      itemsShowed: 5,
      selectedValueForFilter: '',
      selectedFilter: filters[0],
    };
  }

  componentDidMount() {
    const { page, itemsPerPage } = this.state;
    getAlerts({
      filters: [],
      page,
      itemsPerPage,
      sort: { param: 'createdAt', order: 'desc' },
    });
  }

  handleOnSubmit = () => {
    const { page, itemsPerPage } = this.state;
    getAlerts({ filters, page, itemsPerPage });
  };

  getActiveFilter = () => {
    const { selectedValueForFilter, selectedFilter } = this.state;
    return { [selectedFilter.key]: selectedValueForFilter };
  };

  handleOnPageChange = (page) => {
    const { itemsPerPage } = this.state;

    getAlerts({
      filters: { ...this.getActiveFilter },
      page,
      size: itemsPerPage,
      sort: { param: 'createdAt', order: 'desc' },
    });
    this.setState({
      page,
    });
  };

  handleFilteredSearch = (value, filter) => {
    const { itemsPerPage } = this.state;
    if (filter && filter.key) {
      clearAlertsState();
      getAlerts({
        filters: { ...this.getActiveFilter },
        page: 1,
        size: itemsPerPage,
        sort: { param: 'createdAt', order: 'desc' },
      });
      this.setState({
        selectedFilter: filter,
        selectedValueForFilter: value,
        page: 1,
      });
    }
  };

  render() {
    const { total, alerts } = this.props;
    const {
      selectedFilter, page, itemsPerPage, itemsShowed, selectedValueForFilter,
    } = this.state;
    const parsedData = alerts.map((d) => ({
      description: d.description,
      dateIssued: d.fiware.dateIssued,
      severity: d.fiware.severity.toLowerCase(),
      entity: d,
    }));
    return (
      <>
        <BasicList
          page={page}
          itemsPerPage={itemsPerPage}
          itemsShowed={itemsShowed}
          selectedValueForFilter={selectedValueForFilter}
          item
          data={parsedData}
          total={total}
          onSubmit={this.handleOnSubmit}
          entityName="alerts"
          entity={Alert}
          exampleEntity={SampleAlert}
          header={headers}
          profileComponent={ProfileComponent}
          filters={filters}
          expandable
          selectable={false}
          onPageChange={this.handleOnPageChange}
          tableComponent={IncidentsTable}
          optionsForRow={OptionsForRow}
          externalFilter={this.handleFilteredSearch}
          selectedFilter={selectedFilter}
        />
      </>
    );
  }
}

List.propTypes = {
  total: PropTypes.string,
  alerts: PropTypes.string,
};

List.defaultProps = {
  total: '',
  alerts: '',
};

const mapStateToProps = (state) => ({
  alerts: state
    .get('alerts')
    .get('list')
    .toJS(),
  total: state.get('alerts').get('total'),
  userMe: state.get('users').get('userMe'),
});

export default connect(mapStateToProps, {})(injectIntl(List));
