import React from 'react';
import Faker from 'faker';
import { SampleUser } from '../../../models/User'
import Tag from '../../../elements/Tag';

const Roles = [
    {id: Faker.random.uuid(), name: 'Administrator'},
    {id: Faker.random.uuid(), name: 'Installator'},
    {id: Faker.random.uuid(), name: 'Viewer'},
];

const Groups = [
    {id: Faker.random.uuid(), name: 'Málaga'},
    {id: Faker.random.uuid(), name: 'Madrid'},
    {id: Faker.random.uuid(), name: 'Sevilla'},
];

const createRandomTags = (Array) =>{
    const arrayOfTags = [];
    for (let i=0; i < Faker.random.number({min:0, max:2}); i++){

        let aux = {};

        do{
            aux = Array[Faker.random.number({min:0, max:2})];
        } while (arrayOfTags.includes(aux));

        arrayOfTags.push(aux)
    }
    return arrayOfTags.map(aux => <Tag key={aux.id}> {aux.name} </Tag> );
};

const exampleBodyFn = (numberOfUserToGenerate = 20) => {
    const UsersFaker = [];
    for (let i = 0; i < numberOfUserToGenerate; i++) {
        UsersFaker.push({
            id: Faker.random.uuid(),
            email: Faker.internet.email(),
            name: Faker.name.firstName(undefined),
            surname: Faker.name.lastName(undefined),
            roles: createRandomTags(Roles),
            groups: createRandomTags(Groups),
        })
    }
    return UsersFaker;
};

const filters = [{
    name: SampleUser.translations.email,
    value: 'email',
    key: 'email',
},
{
    name: SampleUser.translations.name,
    value: 'name',
    key: 'name',
},
{
    name: SampleUser.translations.surname,
    value: 'surname',
    key: 'surname',
}];

const exampleHeader = [
    /*{
        label: SampleUser.translations.id,
        key: 'id'
    },*/
    {
        label: SampleUser.translations.email,
        key: 'email'
    },
    {
        label: SampleUser.translations.name,
        key: 'name',
        type: 'userInfo',
    },
    {
        label: SampleUser.translations.surname,
        key: 'surname',
    },
    /*
    {
        label: SampleUser.translations.roles,
        key: 'roles',
        noOrder: true
    },
    {
        label: SampleUser.translations.groups,
        key: 'groups',
        noOrder: true
    }
    */
];


export {
    exampleBodyFn,
    exampleHeader,
    createRandomTags,
    Groups,
    Roles,
    filters
}
export default exampleBodyFn