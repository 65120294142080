import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const withLongPress = (Component, ms) => {
  return (props) => {
    const [startLongPress, setStartLongPress] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    //scrolling control has been disabled by the moment.
    //check if there are some bad-effects in the list's performance.

    //window.addEventListener('scroll', () => setScrolling(true) );

    useEffect(() => {
      let callback = props.onSelect;
      let timerId;
      if (startLongPress && !scrolling) {
        timerId = setTimeout(callback, ms);
      } else {
        clearTimeout(timerId);
      }

      return () => {
        clearTimeout(timerId);
      };
    }, [startLongPress]);

    useEffect(() => {

      let timerId;
      if (scrolling) {
        timerId = setTimeout(() => setScrolling(false), 100);
      } else {
        clearTimeout(timerId);
      }

      return () => {
        clearTimeout(timerId);
      };
    }, [scrolling]);

    const getProps = () => ({
      onMouseDown: () => setStartLongPress(true),
      onMouseUp: () => setStartLongPress(false),
      onMouseLeave: () => setStartLongPress(false),
      onTouchStart: () => setStartLongPress(true),
      onTouchEnd: () => setStartLongPress(false),
    });

    return <Component {...props} {...getProps()} />;
  };
};

withLongPress.defaultProps = {
  onSelect: () => {}
}

withLongPress.propTypes = {
  onSelect: PropTypes.function,
}

export default withLongPress;
