import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { Row } from 'reactstrap';
import { ButtonCustom } from '../../../../../elements/Button';
import Field from '../../../../../elements/Field';
import './styles.scss';
// eslint-disable-next-line import/no-cycle
import Conditions from './Conditions';
import { Components } from '../../../../../models/Widget/configurationSheets/utils';

export const Buttons = ({ onClikAdd, hideModal }) => (
  <div className="buttons">
    <ButtonCustom type="primary" handleOnClick={onClikAdd} label="Guardar" />
    <ButtonCustom handleOnClick={hideModal} className="btn btn-secondary" label="Cancelar" />
  </div>
);

Buttons.propTypes = {
  onClikAdd: PropTypes.func,
  hideModal: PropTypes.string,
};

Buttons.defaultProps = {
  onClikAdd: () => {},
  hideModal: '',
};

export const getDefaultCondition = () => ({
  id: Date.now(),
  from: '',
  to: '',
  color: '',
  textColor: '',
  lessThan: '',
  greaterThan: '',
  equals: '',
  type: '',
});

class EditColorConditions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      attributes: props.attributes,
      optionSelected: undefined,
      conditions: Object.entries(props.conditions).length !== 0 ? props.conditions : {
        [props.attributes[0]]: [getDefaultCondition()],
      },
    };
  }

  onChangeSelect = (value, option) => {
    const { conditions: _conditions } = this.state;
    const conditions = { ..._conditions };
    let isNewAtt = true;

    if (!option) {
      this.setState({
        optionSelected: option,
      });
    } else {
      if (conditions[option]) {
        this.setState({ optionSelected: option });
        isNewAtt = false;
      }

      if (isNewAtt) {
        conditions[option] = [getDefaultCondition()];
        this.setState({
          optionSelected: option,
          conditions,
        });
      }
    }
  };

  updateCondition = (data) => {
    const { handleConditions } = this.props;
    const { optionSelected, conditions } = this.state;
    const attName = optionSelected;
    const conditionsState = clone(conditions);
    conditionsState[attName] = conditionsState[attName].map((c) => {
      if (c && c.id === data.id) {
        // eslint-disable-next-line no-param-reassign
        c = data;
        return c;
      }
      return c;
    });
    this.setState({ conditions: conditionsState });
    handleConditions({ name: 'conditions', value: conditionsState }, undefined, Components.tableConditions);
  };

  removeCondition = (data) => {
    const { handleConditions } = this.props;
    const { optionSelected, conditions } = this.state;
    const conditionsState = clone(conditions);
    const attName = optionSelected;

    conditionsState[attName] = conditionsState[attName].filter((c) => c.id !== data.id);

    if (conditionsState[attName].length === 0) {
      conditionsState[attName].push(getDefaultCondition());
    }
    this.setState({ conditions: conditionsState });
    handleConditions({ name: 'conditions', value: conditionsState }, undefined, Components.tableConditions);
  };

  addCondition = () => {
    const { handleConditions } = this.props;
    const { optionSelected } = this.state;
    const { conditions: _conditions } = this.state;
    const attributeSelected = optionSelected;
    const conditions = clone(_conditions);
    if (conditions[attributeSelected]) {
      const newCondition = getDefaultCondition();
      newCondition.type = 'fromTo';
      conditions[attributeSelected].push(newCondition);
    }
    this.setState({ conditions });
    handleConditions(conditions);
  };

  clearSimpleCondition = () => {
    const { handleConditions } = this.props;
    const { optionSelected } = this.state;
    const { conditions: _conditions } = this.state;
    const attributeSelected = optionSelected;
    const conditions = clone(_conditions);
    conditions[attributeSelected] = [getDefaultCondition()];
    this.setState({ conditions });
    handleConditions({ name: 'conditions', value: conditions }, undefined, Components.tableConditions);
  };

  render() {
    const { conditions, optionSelected, attributes } = this.state;
    const attributesVar = [];
    Object.keys(attributes).forEach((att) => {
      attributesVar.push({ value: att, name: attributes[att] });
    });
    return (
      <div className="mb-3">
        <Row style={{ padding: '25px' }}>
          <div className="selectStyle">
            <Field
              name="conditions"
              type="select"
              label={
                <FormattedMessage id="widget.config.select.attribute" />
              }
              options={attributesVar}
              value={optionSelected}
              className="field"
              onChange={this.onChangeSelect}
            />
          </div>
        </Row>
        {conditions[optionSelected] && (
          conditions[optionSelected].map((att, index) => (
            <Conditions
              lastIteration={
                conditions[optionSelected].length > 0
                && index === conditions[optionSelected].length - 1
              }
              addCondition={this.addCondition}
              removeCondition={this.removeCondition}
              updateCondition={this.updateCondition}
              condition={att}
            />
          ))
        )}

        {optionSelected && (
        <span
          className="clearCondition"
          onClick={this.clearSimpleCondition}
          role="presentation"
        >
          <FormattedMessage tagName="label" id="widgetTable.conditions.clearList" />
        </span>
        )}
      </div>
    );
  }
}

EditColorConditions.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.any),
  conditions: PropTypes.objectOf(PropTypes.any),
  handleConditions: PropTypes.func,
};

EditColorConditions.defaultProps = {
  attributes: [],
  conditions: {},
  handleConditions: () => {},
};

export default injectIntl(EditColorConditions);
