import getLanguageFromBrowser from './getLanguageAutomaticallyFromBrowser'
import asyncForEach from './asyncForEach'
import cleanLastSlash from './cleanLastSlash'
import previousPath from './previousPath'
import isBrowser from './isBrowser'
import requestAPI from './requestAPI'
import imLogged from './imLogged'
import getToken from './getToken'
import hasRoutePermission from './hasRoutePermission'
import spreadObject from './spreadObject'
import getXDataAccessToken from './getXDataAccessToken'

export {
    getLanguageFromBrowser,
    asyncForEach,
    cleanLastSlash,
    previousPath,
    isBrowser,
    requestAPI,
    imLogged,
    getToken,
    hasRoutePermission,
    spreadObject,
    getXDataAccessToken,
}
