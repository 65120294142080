import React from 'react';
import RemoveHoc from '../../helpers/RemoveComponent';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import Kpi from '../../../models/Kpi';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

// const Clone = <FormattedMessage
//     id="options.clone"
//     defaultMessage="clone"
//     description="Title of clone"
// />;

// const SendCommand = <FormattedMessage
//     id="options.sendCommand"
//     defaultMessage="send command"
//     description="Title of send command"
// />;

const OptionsForRow = ({ rowContent, viewClick }) => {
  const ButtonOpenRemove = ({ showModal }) => <li onClick={showModal}>{Delete}</li>;

  // const ResetDOM = ResetKpiPasword(ButtonOpenReset);
  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Kpi, nameData: 'kpis' });

  const update = (
    <PrivateComponent checkLogin checkPermission microsService="/app/kpis" permission="update">
      <li onClick={() => { viewClick(rowContent); }} state={{ kpi: rowContent, mode: 'edit' }}>{Edit}</li>
    </PrivateComponent>
  );

  const deleteoption = (
    <PrivateComponent checkLogin checkPermission microsService="/app/kpis" permission="delete">
      <RemoveDOM rowContent={rowContent} />
    </PrivateComponent>
  );

  return (
    <PrivateComponent checkLogin checkPermission microsService="/app/kpis" permission={['delete', 'update', 'kpis']}>
      <div className="tableMenu">
        <ul className="list-unstyled">
          {update}
          {deleteoption}
        </ul>
      </div>
    </PrivateComponent>

  );
};

export default OptionsForRow;
