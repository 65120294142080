import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { validate } from 'validate.js';
import CommonView from '../../../CommonView';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import { ReactComponent as TextAccountantWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-counter.svg';
import { ReactComponent as LineWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-line.svg';
import { ReactComponent as MapWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-map.svg';
import { ReactComponent as NeedleWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-gauge.svg';
import { ReactComponent as PercentWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-percent.svg';
import { ReactComponent as BarsWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-bars.svg';
import { ReactComponent as WidgetTableIcon } from '../../../../configuration/icons/svg/ico-widget-table.svg';
import { ReactComponent as WidgetHeatmapIcon } from '../../../../configuration/icons/svg/ico-widget-heatmap.svg';
import { ReactComponent as WidgetSendCommandIcon } from '../../../../configuration/icons/svg/ico-enviocomando.svg';
import { ReactComponent as WidgetHtml } from '../../../../configuration/icons/svg/html-ic.svg';
import { ReactComponent as WidgetImage } from '../../../../configuration/icons/svg/imagen-ic.svg';
import { ReactComponent as WidgetParametrizedText } from '../../../../configuration/icons/svg/text-ic.svg';
import { ReactComponent as WidgetLinkedIcon } from '../../../../configuration/icons/svg/ico-widget-linked.svg';
import { ReactComponent as WidgetSliderIcon } from '../../../../configuration/icons/svg/ico-slider.svg';
import { ReactComponent as WidgetOnOffIcon } from '../../../../configuration/icons/svg/ico-widget-onoff.svg';

import './widgetTypeStyle.scss';
import { getDefaultConfig } from '../../../../models/Widget/configurationSheets';

const svgIcons = {
  NEEDLE: NeedleWidgetIcon,
  BARS: BarsWidgetIcon,
  MAP: MapWidgetIcon,
  TEXT_ACCOUNTANT: TextAccountantWidgetIcon,
  PERCENTAGE: PercentWidgetIcon,
  LINES: LineWidgetIcon,
  HEATMAP: WidgetHeatmapIcon,
  TABLE: WidgetTableIcon,
  SEND_COMMAND: WidgetSendCommandIcon,
  HTML: WidgetHtml,
  IMAGE: WidgetImage,
  PARAMETRIZED_TEXT: WidgetParametrizedText,
  LINKED: WidgetLinkedIcon,
  SLIDER: WidgetSliderIcon,
  ONOFF: WidgetOnOffIcon,
};

export const RenderIcon = ({ type, active }) => {
  switch (type) {
    case 'NEEDLE':
      return <NeedleWidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'BARS':
      return <BarsWidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'MAP':
      return <MapWidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'TEXT_ACCOUNTANT':
      return <TextAccountantWidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'PERCENTAGE':
      return <PercentWidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'LINES':
      return <LineWidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'HEATMAP':
      return <WidgetHeatmapIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'TABLE':
      return <WidgetTableIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'SEND_COMMAND':
      return <WidgetSendCommandIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'HTML':
      return <WidgetHtml className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'IMAGE':
      return <WidgetImage className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'PARAMETRIZED_TEXT':
      return <WidgetParametrizedText className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'LINKED':
      return <WidgetLinkedIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    case 'SLIDER':
      return <WidgetSliderIcon className={active ? 'item-widget-slider selected' : 'item-widget'} />;
    case 'ONOFF':
      return <WidgetOnOffIcon className={active ? 'item-widget selected' : 'item-widget'} />;
    default:
      return null;
  }
};

export const WidgetItem = ({
  name, className, active, onClick,
}) => (
  <div
    role="presentation"
    className={`${className} ${active ? 'active' : ''}`}
    onClick={() => onClick(name)}
  >
    <div className="icon">
      <RenderIcon type={name} active={active} />
      <span className="name">
        <FormattedMessage
          id={`widgets.wizard.widgetType.${name.toLowerCase()}`}
        />
      </span>
    </div>
  </div>
);

class WidgetType extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      widgetType: undefined,
      errors: {},
      origins: [...this.props.newEntity.origins],
      prevWidgetType: this.props.newEntity.widgetType || undefined,
    };
  }

  validate = () => {
    const constraints = {};
    const { widgetType, origins } = this.state;
    ['widgetType'].forEach((index) => {
      constraints[index] = {
        presence: {
          allowEmpty: false,
          message:
            index !== 'widgetType' ? (
              <FormattedMessage id="widgets.wizard.validation.empty" />
            ) : (
              <FormattedMessage id="widgets.wizard.validation.widget.empty" />
            ),
        },
      };
    });

    const validation = validate({ widgetType }, constraints);

    if (validation) {
      this.setErrors(validation);
      if (validation.widgetType) {
        this.props.onError(validation.widgetType);
      } else {
        this.props.onError('');
      }
    } else {
      this.props.onContinue({ widgetType, origins, errors: '' });
    }
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  handleSelectType = (widgetType) => {
    if (widgetType !== this.state.prevWidgetType) {
      this.setState({
        widgetType,
        prevWidgetType: widgetType,
        origins: [],
      }, () => this.props.extra(widgetType));
    } else {
      this.setState({ widgetType }, () => this.props.extra(widgetType));
    }
  }

  getAvailableWidgets = () => (
    [
      'BARS',
      'LINES',
      'PERCENTAGE',
      'MAP',
      'HEATMAP',
      'TABLE',
      'TEXT_ACCOUNTANT',
      'NEEDLE',
      'SEND_COMMAND',
      'ONOFF',
      'SLIDER',
      'IMAGE',
      'LINKED',
      'HTML',
      'PARAMETRIZED_TEXT',
    ]);

  render() {
    return (
      <>
        <Container>
          <div className="addWidget">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <div className="widgetsGrid justify-content-center">
                  {this.getAvailableWidgets().map((widget, i) => (
                    <WidgetItem
                      key={`item${i}`}
                      className="widget available wizard-widget p-4"
                      name={widget}
                      active={this.state.widgetType === widget}
                      onClick={(widgetName) => this.handleSelectType(widgetName)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

WidgetType.propTypes = {};

WidgetType.defaultProps = {
  errors: {},
};

export default withWizardStep(injectIntl(WidgetType));