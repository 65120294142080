const translatePermissionToFront = (permissions) => {
  const formatedPermissions = {};
  // Check devices Permission
  const devicePermisions = permissions.filter((p) => p.resource?.includes('/device'));

  if (devicePermisions.length > 0) {
    formatedPermissions['/app/devices'] = {};
    formatedPermissions['/app/devices']['list-all'] = !!devicePermisions.find((p) => p.resource?.includes('/device/list/all') && p.action === 'GET');
    formatedPermissions['/app/devices']['list-mine'] = !!devicePermisions.find((p) => p.resource?.includes('/device/list/mine') && p.action === 'GET');
    formatedPermissions['/app/devices'].read = !!devicePermisions.find((p) => p.resource?.includes('/device/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/devices'].create = !!devicePermisions.find((p) => p.resource?.includes('/device[^/]*') && p.action === 'POST');
    formatedPermissions['/app/devices'].update = !!devicePermisions.find((p) => p.resource?.includes('/device/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/devices'].delete = !!devicePermisions.find((p) => p.resource?.includes('/device/[^/]*$') && p.action === 'DELETE');
    formatedPermissions['/app/devices']['send-command'] = !!devicePermisions.find((p) => p.resource?.includes('/sendCommand') && p.action === 'POST');
  }

  // Check template Permission
  const templatePermisions = permissions.filter((p) => p.resource?.includes('/template'));

  if (templatePermisions.length > 0) {
    formatedPermissions['/app/templates'] = {};
    formatedPermissions['/app/templates']['list-all'] = !!templatePermisions.find((p) => p.resource?.includes('/template/list/all') && p.action === 'GET');
    formatedPermissions['/app/templates']['list-mine'] = !!templatePermisions.find((p) => p.resource?.includes('/template/list/mine') && p.action === 'GET');
    formatedPermissions['/app/templates'].read = !!templatePermisions.find((p) => p.resource?.includes('/template/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/templates'].create = !!templatePermisions.find((p) => p.resource?.includes('/template[^/]*') && p.action === 'POST');
    formatedPermissions['/app/templates'].update = !!templatePermisions.find((p) => p.resource?.includes('/template/') && p.action === 'PUT');
    formatedPermissions['/app/templates'].delete = !!templatePermisions.find((p) => p.resource?.includes('/template/') && p.action === 'DELETE');
  }

  // Check service Permission
  const servicePermisions = permissions.filter((p) => p.resource?.includes('/service'));

  if (servicePermisions.length > 0) {
    formatedPermissions['/app/services'] = {};
    formatedPermissions['/app/services']['list-all'] = !!servicePermisions.find((p) => p.resource?.includes('/service/list/all') && p.action === 'GET');
    formatedPermissions['/app/services']['list-mine'] = !!servicePermisions.find((p) => p.resource?.includes('/service/list/mine') && p.action === 'GET');
    formatedPermissions['/app/services'].read = !!servicePermisions.find((p) => p.resource?.includes('/service/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/services'].create = !!servicePermisions.find((p) => p.resource?.includes('/service[^/]*') && p.action === 'POST');
    formatedPermissions['/app/services'].update = !!servicePermisions.find((p) => p.resource?.includes('/service/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/services'].delete = !!servicePermisions.find((p) => p.resource?.includes('/service/[^/]*$') && p.action === 'DELETE');
  }

  // Check rules Permission
  const rulesPermisions = permissions.filter((p) => p.resource?.includes('/rule'));

  if (rulesPermisions.length > 0) {
    formatedPermissions['/app/rules'] = {};
    formatedPermissions['/app/rules']['list-all'] = !!rulesPermisions.find((p) => p.resource?.includes('/rule[^/]') && p.action === 'GET');
    formatedPermissions['/app/rules'].read = !!rulesPermisions.find((p) => p.resource?.includes('/rule/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/rules'].create = !!rulesPermisions.find((p) => p.resource?.includes('/rule[^/]*') && p.action === 'POST');
    formatedPermissions['/app/rules'].update = !!rulesPermisions.find((p) => p.resource?.includes('/rule/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/rules'].delete = !!rulesPermisions.find((p) => p.resource?.includes('/rule/[^/]*$') && p.action === 'DELETE');
  }

  // Check permissions Permission
  const permissionsPermisions = permissions.filter((p) => p.resource?.includes('/permission'));

  if (permissionsPermisions.length > 0) {
    formatedPermissions['/app/permissions'] = {};
    formatedPermissions['/app/permissions']['list-all'] = !!permissionsPermisions.find((p) => p.resource?.includes('/permission[^/]') && p.action === 'GET');
    formatedPermissions['/app/permissions'].read = !!permissionsPermisions.find((p) => p.resource?.includes('/permission/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/permissions'].create = !!permissionsPermisions.find((p) => p.resource?.includes('/permission[^/]*') && p.action === 'POST');
    formatedPermissions['/app/permissions'].update = !!permissionsPermisions.find((p) => p.resource?.includes('/permission/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/permissions'].delete = !!permissionsPermisions.find((p) => p.resource?.includes('/permission/[^/]*$') && p.action === 'DELETE');
  }

  // Check dashboards Permission
  const dashboardsPermisions = permissions.filter((p) => p.resource?.includes('/dashboard'));

  if (dashboardsPermisions.length > 0) {
    formatedPermissions['/app/dashboard'] = {};
    formatedPermissions['/app/dashboard']['list-public'] = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/list/public') && p.action === 'GET');
    formatedPermissions['/app/dashboard']['list-mine'] = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/list/mine') && p.action === 'GET');
    formatedPermissions['/app/dashboard']['list-all'] = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/list/all') && p.action === 'GET');
    formatedPermissions['/app/dashboard'].read = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/dashboard'].create = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard[^/]*') && p.action === 'POST');
    formatedPermissions['/app/dashboard'].clone = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/clone/[^/]/[^/]*$') && p.action === 'POST');
    formatedPermissions['/app/dashboard'].update = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/dashboard'].delete = !!dashboardsPermisions.find((p) => p.resource?.includes('/dashboard/[^/]*$') && p.action === 'DELETE');
  }

  // Check widget Permission
  const widgetPermisions = permissions.filter((p) => p.resource?.includes('/widget'));

  if (widgetPermisions.length > 0) {
    formatedPermissions['/app/widget'] = {};
    formatedPermissions['/app/widget']['list-public'] = !!widgetPermisions.find((p) => p.resource?.includes('/widget/list/public') && p.action === 'GET');
    formatedPermissions['/app/widget']['list-mine'] = !!widgetPermisions.find((p) => p.resource?.includes('/widget/list/mine') && p.action === 'GET');
    formatedPermissions['/app/widget']['list-all'] = !!widgetPermisions.find((p) => p.resource?.includes('/widget/list/all') && p.action === 'GET');
    formatedPermissions['/app/widget'].read = !!widgetPermisions.find((p) => p.resource?.includes('/widget/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/widget'].create = !!widgetPermisions.find((p) => p.resource?.includes('/widget[^/]*') && p.action === 'POST');
    formatedPermissions['/app/widget'].clone = !!widgetPermisions.find((p) => p.resource?.includes('/widget/clone/[^/]*$') && p.action === 'POST');
    formatedPermissions['/app/widget'].update = !!widgetPermisions.find((p) => p.resource?.includes('/widget/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/widget'].delete = !!widgetPermisions.find((p) => p.resource?.includes('/widget/[^/]*$') && p.action === 'DELETE');
  }

  // Check user Permission
  const usersPermisions = permissions.filter((p) => p.resource?.includes('/user'));
  // const usersPermisions = [];
  if (usersPermisions.length > 0) {
    formatedPermissions['/app/users'] = {};
    formatedPermissions['/app/users'].create = !!usersPermisions.find((p) => p.resource?.includes('/user[^/]*') && p.action === 'POST');
    formatedPermissions['/app/users'].update = !!usersPermisions.find((p) => p.resource?.includes('/user/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/users']['update-me'] = !!usersPermisions.find((p) => p.resource?.includes('/user/me[^/]*') && p.action === 'PUT');
    formatedPermissions['/app/users']['add-role'] = !!usersPermisions.find((p) => p.resource?.includes('/user/[^/]*/roles/[^/]*$') && p.action === 'POST');
    formatedPermissions['/app/users'].read = !!usersPermisions.find((p) => p.resource?.includes('/user/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/users'].login = !!usersPermisions.find((p) => p.resource?.includes('/user/login[^/]*') && p.action === 'GET');
    formatedPermissions['/app/users'].list = !!usersPermisions.find((p) => p.resource?.includes('/user[^/]*') && p.action === 'GET');
    formatedPermissions['/app/users']['get-roles'] = !!usersPermisions.find((p) => p.resource?.includes('/user/[^/]*/roles[^]*') && p.action === 'GET');
    formatedPermissions['/app/users']['reset-password'] = !!usersPermisions.find((p) => p.resource?.includes('user/[^/]*/password/reset[^/]*') && p.action === 'POST');
    formatedPermissions['/app/users'].delete = !!usersPermisions.find((p) => p.resource?.includes('/user/') && p.action === 'DELETE');
    formatedPermissions['/app/users']['remove-role'] = !!usersPermisions.find((p) => p.resource?.includes('/user/[^/]*/roles/[^/]*$') && p.action === 'DELETE');
    formatedPermissions['/app/users']['update-me-password'] = !!usersPermisions.find((p) => p.resource?.includes('user/me/password/update[^/]*') && p.action === 'POST');
    formatedPermissions['/app/users']['read-me'] = !!usersPermisions.find((p) => p.resource?.includes('/user/me') && p.action === 'GET');
    formatedPermissions['/app/users']['add-to-group'] = !!usersPermisions.find((p) => p.resource?.includes('/group/[^/]*/user*$') && p.action === 'PUT');
    formatedPermissions['/app/users']['remove-from-group'] = !!usersPermisions.find((p) => p.resource?.includes('/group/[^/]*/user/[^/]*$') && p.action === 'DELETE');
  }

  // Check role Permission
  const rolePermisions = permissions.filter((p) => p.resource?.includes('/role'));

  if (rolePermisions.length > 0) {
    formatedPermissions['/app/roles'] = {};
    formatedPermissions['/app/roles'].update = !!rolePermisions.find((p) => p.resource?.includes('/role/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/roles']['get-permissions'] = !!rolePermisions.find((p) => p.resource?.includes('/role/[^/]*/permission[^/]*') && p.action === 'GET');
    formatedPermissions['/app/roles'].create = !!rolePermisions.find((p) => p.resource?.includes('/role[^/]*') && p.action === 'POST');
    formatedPermissions['/app/roles'].delete = !!rolePermisions.find((p) => p.resource?.includes('/role/') && p.action === 'DELETE');
    formatedPermissions['/app/roles'].read = !!rolePermisions.find((p) => p.resource?.includes('/role/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/roles']['remove-permission'] = !!rolePermisions.find((p) => p.resource?.includes('/role/[^/]*/permission/[^/]*$') && p.action === 'DELETE');
    formatedPermissions['/app/roles'].list = !!rolePermisions.find((p) => p.resource?.includes('/role[^/]*') && p.action === 'GET');
    formatedPermissions['/app/roles']['add-permission'] = !!rolePermisions.find((p) => p.resource?.includes('/role/[^/]*/permission/[^/]*$') && p.action === 'POST');
  }

  // Check group Permission
  const groupPermisions = permissions.filter((p) => p.resource?.includes('/group'));

  if (groupPermisions.length > 0) {
    formatedPermissions['/app/groups'] = {};
    formatedPermissions['/app/groups'].list = !!groupPermisions.find((p) => p.resource?.includes('/group[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/groups'].read = !!groupPermisions.find((p) => p.resource?.includes('/group/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/groups'].create = !!groupPermisions.find((p) => p.resource?.includes('/group[^/]*$') && p.action === 'POST');
    formatedPermissions['/app/groups'].update = !!groupPermisions.find((p) => p.resource?.includes('/group/[^/]*/user*$') && p.action === 'PUT');
    formatedPermissions['/app/groups'].delete = !!groupPermisions.find((p) => p.resource?.includes('/group/[^/]*$') && p.action === 'DELETE');
  }

  // Check menu Permission
  const menuPermisions = permissions.filter((p) => p.resource?.includes('/menu'));

  if (menuPermisions.length > 0) {
    formatedPermissions['/app/menu'] = {};
    formatedPermissions['/app/menu']['all-trees'] = !!menuPermisions.find((p) => p.resource?.includes('/menu/tree[^/]*') && p.action === 'GET');
    formatedPermissions['/app/menu']['create-element'] = !!menuPermisions.find((p) => p.resource?.includes('/menu/element[^/]*') && p.action === 'POST');
    formatedPermissions['/app/menu'].share = !!menuPermisions.find((p) => p.resource?.includes('/menu/element/[^/]*/share/user/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/menu'].update = !!menuPermisions.find((p) => p.resource?.includes('/menu/element/[^/]*$') && p.action === 'PUT');
    formatedPermissions['/app/menu']['read-element'] = !!menuPermisions.find((p) => p.resource?.includes('/menu/element/[^/]*$') && p.action === 'GET');
    formatedPermissions['/app/menu'].delete = !!menuPermisions.find((p) => p.resource?.includes('/menu/element/[^/]*$') && p.action === 'DELETE');
    formatedPermissions['/app/menu']['update-position'] = !!menuPermisions.find((p) => p.resource?.includes('/menu/positions[^/]*$') && p.action === 'PUT');
  }

  // Check alerts Permissions

  const alertPermisions = permissions.filter((p) => p.resource?.includes('/incident'));

  if (alertPermisions.length > 0) {
    formatedPermissions['/app/alerts'] = {};
    formatedPermissions['/app/alerts']['list-all'] = !!alertPermisions.find((p) => p.resource?.includes('/incident/list/all[^/]*') && p.action === 'GET');
    formatedPermissions['/app/alerts']['list-mine'] = !!alertPermisions.find((p) => p.resource?.includes('/incident/list/mine[^/]*') && p.action === 'GET');
    formatedPermissions['/app/alerts']['list-public'] = !!alertPermisions.find((p) => p.resource?.includes('/incident/list/public[^/]*') && p.action === 'GET');
    formatedPermissions['/app/alerts'].read = !!alertPermisions.find((p) => p.resource?.includes('/incident/[^/]*$') && p.action === 'GET');
  }

  // Check Kpi Permissions

  const kpiPermisions = permissions.filter((p) => p.resource?.includes('/kpi'));

  if (kpiPermisions.length > 0) {
    formatedPermissions['/app/kpis'] = {};
    formatedPermissions['/app/kpis']['list-all'] = !!kpiPermisions.find((p) => p.resource?.includes('/kpi/list/all$') && p.action === 'GET');
    formatedPermissions['/app/kpis']['list-mine'] = !!kpiPermisions.find((p) => p.resource?.includes('/kpi/list/mine$') && p.action === 'GET');
    formatedPermissions['/app/kpis']['list-public'] = !!kpiPermisions.find((p) => p.resource?.includes('/kpi/list/public$') && p.action === 'GET');
    formatedPermissions['/app/kpis'].read = !!kpiPermisions.find((p) => p.resource?.includes('/kpi/[^/]*') && p.action === 'GET');
    formatedPermissions['/app/kpis'].create = !!kpiPermisions.find((p) => p.resource?.includes('/kpi[^/]*') && p.action === 'POST');
    formatedPermissions['/app/kpis'].delete = !!kpiPermisions.find((p) => p.resource?.includes('/kpi/[^/]*') && p.action === 'DELETE');
    formatedPermissions['/app/kpis'].update = !!kpiPermisions.find((p) => p.resource?.includes('/kpi/[^/]*') && p.action === 'PUT');
  }

  // Check Datasource Permissions

  const datasourcePermissions = permissions.filter((p) => p.resource?.includes('/datasource'));
  if (datasourcePermissions.length > 0) {
    formatedPermissions['/app/datasources'] = {};
    formatedPermissions['/app/datasources']['list-all'] = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource/list/all$') && p.action === 'GET');
    formatedPermissions['/app/datasources']['list-mine'] = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource/list/mine$') && p.action === 'GET');
    formatedPermissions['/app/datasources']['list-public'] = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource/list/public$') && p.action === 'GET');
    formatedPermissions['/app/datasources'].read = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource/[^/]*') && p.action === 'GET');
    formatedPermissions['/app/datasources'].create = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource$') && p.action === 'POST');
    formatedPermissions['/app/datasources'].delete = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource/[^/]*') && p.action === 'DELETE');
    formatedPermissions['/app/datasources'].update = !!datasourcePermissions.find((p) => p.resource?.includes('/datasource/[^/]*') && p.action === 'PUT');
  }

  return formatedPermissions;
};

export default translatePermissionToFront;
