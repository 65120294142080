// --------------------- [IMPORTS] ----------------------- //
import React, {
  useEffect, useRef, useReducer, useState, Suspense, useCallback, useMemo,
} from 'react';
import Websocket from 'react-websocket';
import { useSelector } from 'react-redux';
import { cloneDeep, differenceBy } from 'lodash';
import { Col, Row } from 'reactstrap';
import RenderWidget from './renderWidget';
import { ProfileWidgetComponent, ProfileSendingCommandComponent } from '../profiles';
import reducer, {
  handleChangeBreakpoint, handleOnProfileSendingCommandsEvent, handleOnProfileWidgetEvent,
  handleOpenProfileSendingCommands, handleOpenProfileWidget, InitialState, TYPES, updateState,
} from '../reducer';
import { websocketConnection } from '../../../../configuration/config';
import getToken from '../../../../helpers/getToken';
import { updateDashboardLayout } from '../../../../services/redux/dashboards/actions';
import { updateHistoricalListV2FromSocket } from '../../../../services/redux/historicals/actions';
import DragAndDropGrid from '../../../../components/DragAndDropGrid';
import { updateLastValueBySocket } from '../../../../models/WidgetV2/utils';
import { getUrnId } from '../../../../helpers/utils';
// --------------------- [IMPORTS] ----------------------- //

// --------------------- [MAIN COMPONENT] ----------------------- //
function ShowWidgets(props) {
  // --------------------- [VARIABLES & STATE] ----------------------- //
  const {
    permissionToEdit, isDraggable, isResizable, isPublicDashboard, handleOpenSocket,
  } = props;
  let WSocket = useRef(null);
  const [state, dispatch] = useReducer(reducer, InitialState);
  const [hasBeenResized, setHasBeenResized] = useState(false);
  // --------------------- [VARIABLES & STATE] ----------------------- //

  // --------------------- [REDUX] ----------------------- //
  const widgetDataV2 = useSelector((reduxState) => (
    reduxState.get('historicals').get('historicalListV2')));
  const dashboard = useSelector((reduxState) => (
    reduxState.get('dashboards').get('readDashboard')));
  const layouts = useSelector((reduxState) => (
    reduxState.get('dashboards').get('currentLayouts')));
  const updatedWidget = useSelector((reduxState) => (
    reduxState.get('widgets').get('updatedWidget')));
  // --------------------- [REDUX] ----------------------- //

  // --------------------- [SOCKET] ----------------------- //
  const handleOpenDefaultSocket = (socket) => {
    if (dashboard?.widgets) {
      const sourcesUrn = [];
      dashboard.widgets.forEach((w) => {
        if (w.needsSocket) {
          w.sources.forEach((source) => {
            sourcesUrn.push(getUrnId(source.urn));
          });
        }
      });
      const message = {
        token: getToken(),
        sourcesUrn: [...new Set(sourcesUrn)].toString(),
      };
      socket.sendMessage(JSON.stringify(message));
    }
  };

  const handleOpen = handleOpenSocket || handleOpenDefaultSocket;

  const getAttributesByWebSocket = (message) => {
    try {
      const decodedMessage = JSON.parse(message);
      const sourceUrn = decodedMessage?.urn;
      if (sourceUrn) {
        const newValues = updateLastValueBySocket(
          widgetDataV2, decodedMessage,
        );
        const widgetsConnectedToSource = state.sources[sourceUrn];
        widgetsConnectedToSource.forEach((widgetConnected) => {
          newValues[widgetConnected.id] = widgetConnected.updateHistoricalBySocket(
            newValues[widgetConnected.id], decodedMessage,
          ) ?? newValues[widgetConnected.id];
        });
        updateHistoricalListV2FromSocket(newValues);
      }
    } catch (e) {
      console.error('Unexpected error ocurred: ', e);
    }
  };
  // --------------------- [SOCKET] ----------------------- //

  // --------------------- [AUX METHODS] ----------------------- //
  const newWidgetList = useMemo(() => {
    const widgetList = [];
    Object.keys(state.widget.list).forEach((w) => {
      if (!state.widget.list[w].container) widgetList.push(state.widget.list[w]);
    });
    return widgetList;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.widget.updatedList, state.widget.list]);

  const onLayoutChanges = (newLayouts) => {
    if (dashboard
      && JSON.stringify(layouts) !== JSON.stringify(newLayouts)
      && (isDraggable || isResizable)) {
      new Promise((resolve) => {
        resolve(updateState(
          TYPES.updateDashboardContainerWidgets,
          newLayouts,
          dispatch,
        ));
      }).then(() => {
        const newDash = cloneDeep(dashboard);
        newDash.layoutProperties = newLayouts;
        updateDashboardLayout(newDash);
      });
    }
    setHasBeenResized(true);
  };

  const updateWidgetLayout = useCallback((widget) => {
    new Promise((resolve) => {
      resolve(updateState(
        TYPES.setWidgetSection,
        { active: widget },
        dispatch,
      ));
    }).then(() => {
      widget.update(widget);
    });
  }, []);

  const updateWidgetList = useCallback(() => {
    const oldWidgetListLength = Object.keys(state.widget.list).length;
    const newWidgetListLength = dashboard.widgets.length;
    const updatedLayout = dashboard.layoutProperties;
    const listToCheck = Object.values(state.widget.list);
    if (newWidgetListLength > oldWidgetListLength) {
      // A widget was added
      const newWidget = differenceBy(
        dashboard.widgets,
        listToCheck,
        'id',
      )[0];
      updateState(TYPES.addItemToWidgetList, { newWidget, updatedLayout }, dispatch);
    } else if (newWidgetListLength < oldWidgetListLength) {
      // A widget was removed
      const removedWidget = differenceBy(listToCheck, dashboard.widgets, 'id')[0];
      updateState(TYPES.deleteItemFromWidgetList, { removedWidget, updatedLayout }, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard.widgets, state.widget.list]);

  const handleOpenProfile = useCallback((widget, profileType) => handleOpenProfileWidget(
    widget,
    profileType,
    dispatch,
  ), []);

  const handleInfoChange = useCallback((newInfo) => {
    updateState(
      TYPES.updateWidgetInfo,
      newInfo,
      dispatch,
    );
  }, []);

  const handleOpenSendCommand = useCallback(
    (widget, profileType) => handleOpenProfileSendingCommands(
      widget,
      profileType,
      dispatch,
    ), [],
  );

  const renderChildrenWidgets = useCallback((childWidget, selection) => (
    <div key={childWidget.id} id={childWidget.id}>
      <RenderWidget
        handleOpenProfileWidget={handleOpenProfile}
        handleOpenProfileSendingCommands={handleOpenSendCommand}
        widget={childWidget}
        updateMySelf={childWidget.updateMySelf
          ? (widget) => childWidget.updateMySelf(widget, dispatch)
          : null}
        dispatchState={dispatch}
        selection={selection}
        isPublicDashboard={isPublicDashboard}
      />
    </div>
  ), [handleOpenSendCommand, isPublicDashboard]);

  const getLinkedParent = (widget, list) => {
    if (widget.container && widget.typeContainer === 'LINKED') {
      return list[widget.container];
    }
    return false;
  };
  // --------------------- [AUX METHODS] ----------------------- //

  // --------------------- [LIFECYCLES] ----------------------- //
  useEffect(() => {
    if (dashboard && !state.runSocket) {
      updateState(TYPES.setRunSocket, true, dispatch);
      const list = {};
      dashboard.widgets.forEach((w) => { list[w.id] = w; });
      updateState(TYPES.setWidgetSection, { list }, dispatch);
    }
    if (dashboard
        && dashboard.widgets?.length !== Object.keys(state.widget.list).length
        && state.runSocket) {
      updateWidgetList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, dashboard.widgets]);

  useEffect(() => {
    if (updatedWidget) {
      updateState(
        TYPES.updateWidgetFromList,
        updatedWidget,
        dispatch,
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedWidget?.id, updatedWidget?.config]);

  useEffect(() => {
    if (hasBeenResized) setHasBeenResized(false);
  }, [hasBeenResized]);
  // --------------------- [LIFECYCLES] ----------------------- //
  // --------------------- [RETURN] ----------------------- //
  return (
    <>
      {state.profile.widgetOpened && state.widget.active && permissionToEdit && (
      <ProfileWidgetComponent
        onChange={(opened) => handleOnProfileWidgetEvent(opened, dispatch)}
        data={state.widget.active}
        profileType={state.profile.widgetType}
        isLinked={getLinkedParent(state.widget.active, state.widget.list)}
        onInfoChange={handleInfoChange}
        dispatchState={dispatch}
      />
      )}

      {state.profile.sendingCommandsOpened && state.widget.active && permissionToEdit && (
        <ProfileSendingCommandComponent
          onChange={(opened) => handleOnProfileSendingCommandsEvent(opened, dispatch)}
          data={state.widget.active}
          profileType={state.profile.widgetType}
          width={50}
          checkable
        />
      )}
      {state.runSocket && (
        <Websocket
          url={websocketConnection.urlAPI.concat(
            websocketConnection.paths.socket,
          )}
          onMessage={getAttributesByWebSocket}
          onOpen={() => handleOpen(WSocket)}
          reconnect
          debug={false}
          // eslint-disable-next-line no-shadow
          ref={(Websocket) => {
            WSocket = Websocket;
          }}
        />
      )}
      <Row>
        <Col>
          <DragAndDropGrid
            layouts={layouts}
            onChange={(layout, lyts) => onLayoutChanges(lyts)}
            isDraggable={isDraggable}
            isResizable={isResizable}
            onBreakpointChange={(bp) => handleChangeBreakpoint(bp, dispatch)}
          >
            {newWidgetList.map((w) => (
              <div key={w.id} id={w.id}>
                <Suspense fallback="">
                  <RenderWidget
                    handleOpenProfileWidget={handleOpenProfile}
                    handleOpenProfileSendingCommands={handleOpenSendCommand}
                    childrenWidgetsLength={w.containedWidgets.length}
                    widget={w}
                    grid={state.grid}
                    hasBeenResized={hasBeenResized}
                    updateMySelf={w.updateMySelf
                      ? (widget) => w.updateMySelf(widget, dispatch)
                      : null}
                    renderChildrenWidgets={renderChildrenWidgets}
                    dispatchState={dispatch}
                    updateContainerLayout={updateWidgetLayout}
                    disableOptions={!permissionToEdit}
                    isPublicDashboard={isPublicDashboard}
                  />
                </Suspense>
              </div>
            ))}
          </DragAndDropGrid>
        </Col>
      </Row>
    </>
  );
  // --------------------- [RETURN] ----------------------- //
}

export default ShowWidgets;
// --------------------- [MAIN COMPONENT] ----------------------- //
