import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CommonView from '../../../../pages/CommonView';
import Field from '../../../../elements/Field';
import {
  clearDashboardState,
  getDashboard, getDashboards,
} from '../../../../services/redux/dashboards/actions';

class ConfigureDashboard extends CommonView {
  constructor(props) {
    super({ props });

    const { name, resource } = this.props.newEntity;

    this.state = {
      name,
      errors: this.props.errors,
      onValidation: false,
      page: 1,
      size: 5,
      dashboard: resource.link.path
        ? this.findDashboard(resource.link.path)
        : {},
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    getDashboards({ page, size });
  }

findDashboard = (path) => {
  const id = path.split('/app/dashboard/')[1];
  const dashboard = this.props.dashboards.find((d) => d.id === id);
  if (!dashboard) {
    getDashboard({ id });
    return {};
  }
  return dashboard;
};

saveMenuElement = (dashboardId) => {
  const { type } = this.props.newEntity.resource;
  const obj = this.state;
  obj.resource = {
    link: {
      path: `/app/dashboard/${dashboardId}`,
    },
    type,
  };
  const newMenuElement = obj;
  delete newMenuElement.id;
  return newMenuElement;
};

componentDidUpdate(prevProps, prevState) {
  const { page, size } = this.state;
  const { resource } = this.props.newEntity;

  if (!this.props.fetching && prevState.page < page) {
    getDashboards({ page, size });
  }

  if (prevProps.dashboards !== this.props.dashboards) {
    this.updateState(resource);
  }
}

updateState(resource) {
  this.setState({
    dashboard: resource.link.path
      ? this.findDashboard(resource.link.path)
      : {},
  });
}

mapToSelect = (o) => ({ name: o.name, value: o });

getOptions = (options) => (!options ? [] : options.map(this.mapToSelect));

handleSelectChange = (name, value) => {
  this.handleFieldChange(name, value);
  const newEntity = this.saveMenuElement(value.id);
  this.props.onChange(newEntity);
};

handelFetchInfo = () => {
  const { total, dashboards } = this.props;
  if (total > dashboards.length) {
    this.setState(
      (prevState) => ({ page: prevState.page + 1 }),
    );
  }
};

handleOnSearch = (value, init = false) => {
  const { size } = this.state;
  if (init) {
    clearDashboardState();
  }
  // init && clearDashboardState();
  getDashboards({ filters: { name: value }, page: 1, size });
};

render() {
  const { dashboards } = this.props;
  const { dashboard } = this.state;

  return (
    <>
      <Container>
        <div className="wizardWrapper">
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Field
                type="select"
                name="dashboard"
                options={this.getOptions(dashboards)}
                onChange={this.handleSelectChange}
                value={dashboard}
                async
                loading={this.props.fetching}
                fetchInfo={this.handelFetchInfo}
                onSearch={this.handleOnSearch}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
}

const mapStateToProps = (state) => ({
  dashboards: state
    .get('dashboards')
    .get('list')
    .toJS(),
  total: state.get('dashboards').get('total'),
  notify: state.get('notify'),
  fetching: state.get('dashboards').get('fetching'),
});

export default connect(
  mapStateToProps,
)(injectIntl(ConfigureDashboard));
