import React from 'react';
import { PropTypes } from 'prop-types';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import BriefAction from './components/BriefAction/BriefAction';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

class EstablishedActions extends React.Component {
  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  saveActionAndContinue = (index, events) => {
    const { setState } = this.props;
    const eventsCopy = [...events];
    eventsCopy.splice(index, 1);
    setState({ events: eventsCopy });
  };

  prevSteps = () => 2;

  validate = () => {
    const { onError, onContinue, newEntity: { events } } = this.props;
    if (events.length < 1) {
      onError(<FormattedMessage id="rules.wizard.validation.error.no.actions" />);
    } else {
      onContinue();
    }
  }

  render() {
    const { newEntity: { events } } = this.props;
    return events.map((event, index) => (
      <BriefAction
        action={event}
        onDelete={() => this.saveActionAndContinue(index, events)}
      />
    ));
  }
}

EstablishedActions.propTypes = {
  onRef: PropTypes.func.isRequired,
  newEntity: PropTypes.objectOf(PropTypes.object).isRequired,
  setState: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default withWizardStep(EstablishedActions);
