import React from 'react';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const categories = [
  {
    key: 'all',
    name: <FormattedMessage id="widgetLinked.categories.all" />,
    value: 'ALL',
  },
  {
    key: 'needle',
    name: <FormattedMessage id="widgetLinked.categories.needle" />,
    value: 'NEEDLE',
  },
  {
    key: 'percentage',
    name: <FormattedMessage id="widgetLinked.categories.percentage" />,
    value: 'PERCENTAGE',
  },
  {
    key: 'numeric',
    name: <FormattedMessage id="widgetLinked.categories.numeric" />,
    value: 'TEXT_ACCOUNTANT',
  },
  {
    key: 'table',
    name: <FormattedMessage id="widgetLinked.categories.table" />,
    value: 'TABLE',
  },
  {
    key: 'bars',
    name: <FormattedMessage id="widgetLinked.categories.bars" />,
    value: 'BARS',
  },
  {
    key: 'lines',
    name: <FormattedMessage id="widgetLinked.categories.lines" />,
    value: 'LINES',
  },
  {
    key: 'switch',
    name: <FormattedMessage id="widgetLinked.categories.switch" />,
    value: 'SWITCH',
  },
  {
    key: 'regulator',
    name: <FormattedMessage id="widgetLinked.categories.regulator" />,
    value: 'REGULATOR',
  },
];

export default categories;
