/* eslint-disable import/no-cycle */
import LinkedNeedle from './needle';
import LinkedPercentage from './percentage';
import LinkedLines from './lines';
import LinkedTable from './table';
import LinkedKpi from './kpi';
import LinkedBars from './bars';

export default {
  LinkedNeedle,
  LinkedPercentage,
  LinkedLines,
  LinkedTable,
  LinkedKpi,
  LinkedBars,
};
