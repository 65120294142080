import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { Card, DeleteButton } from '../../../Add/components/utils';

export default function ListComponent({ data, onDelete }, isSimple, sources) {
  const getUses = (attr) => {
    let count = 0;
    sources.forEach((source) => {
      if (source.fields.includes(attr)) {
        count += 1;
      }
    });
    return count;
  };

  return (
    <Row>
      <Col>
        {isSimple && (
        <div className="warning text-left mb-4">
          <i className="uil uil-info-circle mr-1" />
          <FormattedMessage id="widgets.linked.wizard.step2.warning" />
        </div>
        )}
        <h3 className="mb-3"><FormattedMessage id="widget.attribute.attributes" /></h3>
        {data.map((attr, i) => (
          <Card style={{ margin: '0 0 20px' }}>
            {attr.name}
            {' '}
            <br />
            <span className="uses">
              {getUses(attr.name)}
              {' '}
              <FormattedMessage id="linkedWidget.commonDevices" />
            </span>
            {data.length > 1
            && (
            <DeleteButton onClick={() => onDelete(attr.name)}>
              <i className="uil uil-times" />
            </DeleteButton>
            )}
          </Card>
        ))}
      </Col>
    </Row>
  );
}
