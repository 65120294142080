import { takeLatest, takeEvery, call, put, all, fork } from 'redux-saga/effects';
import UsersGroup from '../../../models/UsersGroup'
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/usersGroups'

/**
 * Actions for WATCHERS
 * */

 function* callToAPI(apiCall, successResponseCode, errorType, callback, payload = null) {
    try {
        const response = yield call(apiCall, payload);
        if (response.status === successResponseCode) {
            yield callback(response);
        }
        else {
            yield put({ type: errorType, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
        }
    }
    catch (error) {
        yield put({ type: errorType, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
    }
 }
 
// Try to recover all usersGroup form DB
export function* getUsersGroupsThroughApi() {
    yield callToAPI(Api.readUsersGroups, 200, TYPES.GET_USERSGROUPS_ERROR, function*(response){
        yield put({ type: TYPES.GET_USERSGROUPS_SUCCESS, payload: response.data.objects.map(o => new UsersGroup(o))});
    });
}

// Try to recover a usersGroup form DB
export function* getUsersGroupThroughApi({ payload }) {
    yield callToAPI(Api.readUsersGroup, 200, TYPES.GET_USERSGROUPS_ERROR, function*(response){
        yield put({ type: TYPES.GET_USERSGROUP_SUCCESS, payload: new UsersGroup(response.data.object) });
    }, payload);
}

// Try to create usersGroup in DB
export function* addUsersGroupsThroughApi({ payload }) {
    yield callToAPI(Api.addUsersGroup, 201, TYPES.ADD_USERSGROUP_ERROR, function*(response){
        yield put({ type: TYPES.ADD_USERSGROUP_SUCCESS, payload: new UsersGroup(response.data.object) });
    }, payload);
}

// Try to update usersGroup in DB
export function* updateUsersGroupsThroughApi({ payload }) {
    yield callToAPI(Api.updateUsersGroup, 200, TYPES.MODIFY_USERSGROUP_ERROR, function*(response){
        yield put({ type: TYPES.MODIFY_USERSGROUP_SUCCESS, payload: new UsersGroup({ ...response.data.object }) })
    }, payload);
}

// Try to delete usersGroup in DB
export function* deleteUsersGroupsThroughApi({ payload }) {
    yield callToAPI(Api.deleteUsersGroup, 204, TYPES.DELETE_USERSGROUP_ERROR, function*(response){
        yield put({ type: TYPES.DELETE_USERSGROUP_SUCCESS, payload })
    }, payload);
}

export function* addUserToUsersGroupThroughApi({payload}){
    yield callToAPI(Api.addUserToUsersGroup, 200, TYPES.ADD_USER_TO_USERSGROUP_ERROR, function*(response){
        yield put({type: TYPES.ADD_USER_TO_USERSGROUP_SUCCESS, payload: new UsersGroup({ ...response.data.object }) })
    }, payload)
}

export function* deleteUserFromUsersGroupThroughApi({payload}){
    yield callToAPI(Api.deleteUserFromUsersGroup, 204, TYPES.DELETE_USER_FROM_USERSGROUP_ERROR, function*(response){
        yield put({type: TYPES.DELETE_USER_FROM_USERSGROUP_SUCCESS, payload })
    }, payload)
}

/**
 * WATCHERS
 */
// Watcher looking for GET_USERSGROUPS_REQUEST
export function* watcherGetUsersGroups() {
    yield takeLatest('GET_USERSGROUPS_REQUEST', getUsersGroupsThroughApi);
}

// Watcher looking for GET_USERSGROUP_REQUEST
export function* watcherGetUsersGroup() {
    yield takeLatest('GET_USERSGROUP_REQUEST', getUsersGroupThroughApi);
}

// Watcher looking for ADD_USERSGROUP_REQUEST
function* watcherAddUsersGroups() {
    yield takeEvery('ADD_USERSGROUP_REQUEST', addUsersGroupsThroughApi);
}

// Watcher looking for MODIFY_USERSGROUP_REQUEST
function* watcherUpdateUsersGroups() {
    yield takeLatest('MODIFY_USERSGROUP_REQUEST', updateUsersGroupsThroughApi);
}

// Watcher looking for DELETE_USERSGROUP_REQUEST
function* watcherDeleteUsersGroups() {
    yield takeEvery('DELETE_USERSGROUP_REQUEST', deleteUsersGroupsThroughApi);
}

// Watcher looking for ADD_USER_TO_USERSGROUP_REQUEST
function* watcherAddUserToUsersGroup() {
    yield takeEvery('ADD_USER_TO_USERSGROUP_REQUEST', addUserToUsersGroupThroughApi);
}

// Watcher looking for DELETE_USER_FROM_USERSGROUP_REQUEST
function* watcherDeleteUserFromUsersGroup() {
    yield takeEvery('DELETE_USER_FROM_USERSGROUP_REQUEST', deleteUserFromUsersGroupThroughApi);
}

// Export all together
export default function* rootSaga() {
    yield all([
        fork(watcherGetUsersGroups),
        fork(watcherGetUsersGroup),
        fork(watcherAddUsersGroups),
        fork(watcherUpdateUsersGroups),
        fork(watcherDeleteUsersGroups),
        fork(watcherAddUserToUsersGroup),
        fork(watcherDeleteUserFromUsersGroup),
    ])
}

