import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Field from '../../../../../elements/Field/index';
import ColorPicker from '../../../../../components/ColorPicker';
import { ButtonCustom } from '../../../../../elements/Button';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import WidgetMenuButton from './WidgetMenuButton';
import {cloneDeep} from 'lodash';
// eslint-disable-next-line import/no-cycle
import { getDefaultCondition } from './EditColorConditions';

export const Buttons = ({ onClick, type }) => {
  if (type === '+') {
    return (
      <div className="buttons">
        <ButtonCustom handleOnClick={() => onClick()} className="btn btn-secondary" label="+" />
      </div>
    );
  }
  return (
    <div className="buttons">
      <ButtonCustom handleOnClick={() => onClick()} className="btn btn-secondary" label="-" />
    </div>
  );
};

Buttons.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
};

Buttons.defaultProps = {
  onClick: () => {},
  type: '',
};

class condition extends React.PureComponent {
  constructor(props) {
    super({ props });
    this.state = {
      conditionState: props.condition ? cloneDeep(props.condition) : getDefaultCondition(),
      choiceString: 'fromTo',
      lastIteration: props.lastIteration,
    };
  }

  componentDidUpdate(prevProps) {
    const { condition, lastIteration } = this.props;
    if (JSON.stringify(prevProps.condition) !== JSON.stringify(condition)) {
      this.updateState1();
    } else if (prevProps.lastIteration !== lastIteration) {
      this.updateState2();
    }
  }

  onChangeTo = (e) => {
    const { conditionState } = cloneDeep(this.state);
    const { updateCondition } = this.props;
    conditionState.to = e.target.value;
    this.setState({ conditionState });
    updateCondition(conditionState);
  };

  onChangeFrom = (e) => {
    const { conditionState } = cloneDeep(this.state);
    const { updateCondition } = this.props;
    conditionState.from = e.target.value;
    this.setState({ conditionState });
    updateCondition(conditionState);
  };

  onChangeLessThan = (e) => {
    const { conditionState } = cloneDeep(this.state);
    const { updateCondition } = this.props;
    conditionState.lessThan = e.target.value;
    this.setState({ conditionState });
    updateCondition(conditionState);
  }

  onChangeGreaterThan = (e) => {
    const { conditionState } = cloneDeep(this.state);
    const { updateCondition } = this.props;
    conditionState.greaterThan = e.target.value;
    this.setState({ conditionState });
    updateCondition(conditionState);
  }

  onChangeEquals = (e) => {
    const { conditionState } = cloneDeep(this.state);
    const { updateCondition } = this.props;
    conditionState.equals = e.target.value;
    this.setState({ conditionState });
    updateCondition(conditionState);
  }

  handleChange = (color, name) => {
    const { updateCondition } = this.props;
    const conditionState = cloneDeep(this.state.conditionState);
    conditionState[name] = color.hex;
    this.setState((prevState) => ({ conditionState: prevState.conditionState }));
    updateCondition(conditionState);
  }

  getTypeCondition = () => {
    const { conditionState } = this.state;
    if (['fromTo', 'lessThan', 'greaterThan', 'equals'].includes(conditionState.type)) {
      return conditionState.type;
    }
    if (conditionState.from || conditionState.to) {
      return 'fromTo';
    }
    if (conditionState.lessThan) {
      return 'lessThan';
    }
    if (conditionState.greaterThan) {
      return 'greaterThan';
    }
    if (conditionState.equals) {
      return 'equals';
    }
    return undefined;
  }

  choice = () => {
    const { conditionState, choiceString } = this.state;
    const choiceStringState = this.getTypeCondition() ? this.getTypeCondition(
    ) : choiceString;
    if (this.getTypeCondition() !== undefined) {
      this.state.choiceString = this.getTypeCondition();
    }
    if (choiceStringState === 'fromTo') {
      return (
        <>
          <div className="fromContainer">
            <span className="title">
              <FormattedMessage id="widgetTable.inRangeEnd" />
            </span>
            <Field className="field" name="from" onBlur={this.onChangeFrom} value={conditionState.from} />
          </div>
          <span className="bar">/</span>
          <div className="toContainer">
            <span className="title">
              <FormattedMessage id="widgetTable.inRangeStart" />
            </span>
            <Field className="field" name="to" onBlur={this.onChangeTo} value={conditionState.to} />
          </div>
        </>
      );
    }
    if (choiceStringState === 'lessThan') {
      return (
        <>
          <div className="lessThanContainer">
            <span className="title">
              <FormattedMessage id="widgetTable.lessThan" />
            </span>
            <Field
              className="field"
              name="lessThan"
              onBlur={this.onChangeLessThan}
              value={conditionState.lessThan}
            />
          </div>
        </>
      );
    }
    if (choiceStringState === 'greaterThan') {
      return (
        <>
          <div className="greaterThanContainer">
            <span className="title">
              <FormattedMessage id="widgetTable.greaterThan" />
            </span>
            <Field
              className="field"
              name="greaterThan"
              onBlur={this.onChangeGreaterThan}
              value={conditionState.greaterThan}
            />
          </div>
        </>
      );
    }
    if (choiceStringState === 'equals') {
      return (
        <>
          <div className="equalsContainer">
            <span className="title">
              <FormattedMessage id="widgetTable.equals" />
            </span>
            <Field className="field" name="equals" onBlur={this.onChangeEquals} value={conditionState.equals} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="fromContainer">
          <span className="title">
            <FormattedMessage id="widgetTable.inRangeEnd" />
          </span>
          <Field className="field" name="from" onBlur={this.onChangeFrom} value={conditionState.from} />
        </div>
        <span className="bar">/</span>
        <div className="toContainer">
          <span className="title">
            <FormattedMessage id="widgetTable.inRangeStart" />
          </span>
          <Field className="field" name="to" onBlur={this.onChangeTo} value={conditionState.to} />
        </div>
      </>
    );
  }

  onChangeChoice = (choiceString) => {
    const { updateCondition } = this.props;
    const { conditionState } = this.state;
    const copyCondition = getDefaultCondition();
    copyCondition.id = conditionState.id;
    copyCondition.type = choiceString;
    this.setState({ choiceString, conditionState: copyCondition });
    updateCondition(copyCondition);
  }

  updateState1() {
    const { condition, lastIteration } = this.props;
    this.setState({ conditionState: condition, lastIteration });
  }

  updateState2() {
    const { lastIteration } = this.props;
    this.setState({ lastIteration });
  }

  render() {
    const {
      removeCondition, addCondition,
    } = this.props;
    const { conditionState, lastIteration } = this.state;
    const choice = this.choice();
    return (
      <div className="conditionContainer">
        <WidgetMenuButton
          onChangeChoice={this.onChangeChoice}
        />
        {choice}

        <div className="colorPicker column">
          <span className="title"><FormattedMessage id="widget.config.background" /></span>
          <ColorPicker
            className="color-picker-body"
            name="color"
            onChangeComplete={this.handleChange}
            color={conditionState.color ? conditionState.color : ''}
            label="Color"
          />
        </div>
        <div className="colorPicker column">
          <span className="title"><FormattedMessage id="widget.config.text" /></span>
          <ColorPicker
            className="color-picker-body"
            name="textColor"
            onChangeComplete={this.handleChange}
            color={conditionState.textColor ? conditionState.textColor : ''}
            label="TextColor"
          />
        </div>

        {/* button remove */}
        <Buttons className="button" type="-" onClick={() => removeCondition(conditionState)} />

        {/* button add */}
        {lastIteration && (
        <Buttons
          className="button"
          type="+"
          onClick={() => addCondition(conditionState)}
        />
        )}

      </div>
    );
  }
}

condition.propTypes = {
  condition: PropTypes.objectOf(PropTypes.any),
  lastIteration: PropTypes.bool,
  updateCondition: PropTypes.func,
  removeCondition: PropTypes.func,
  addCondition: PropTypes.func,
};

condition.defaultProps = {
  condition: {},
  lastIteration: undefined,
  updateCondition: () => {},
  removeCondition: () => {},
  addCondition: () => {},
};

export default injectIntl(condition);
