import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import backIcon from '../../../../configuration/icons/svg/ico-back.svg';

const SubMenu = ({
  onMain,
  type,
  categorySelected,
  children,
}) => {
  const Header = Styled('div')`
  height: 50px;
  width: 100%;
  margin-bottom 25px;
  margin-top -30px;
  margin-left -15px;
`;
  const Back = Styled('div')`
    width: 50px;
    padding 10px;
    height: 50px;
    float: left;
    cursor: pointer;
    background-color: rgba(46,91,255,0.08);
`;
  const Title = Styled('div')`
    margin-left: 50px;
    width: calc(100% - 50px);
    background-color: #F9FAFC;
    padding 15px;
    color: #2B334A;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  
`;
  const typeLabel = type === 'advancedLegendNames' ? 'names' : 'color';

  return (
    <>
      <Header>
        <Back onClick={() => onMain(false, undefined, undefined)}>
          {' '}
          <img src={backIcon} alt="Back" />
          {' '}
        </Back>
        <Title>
          <FormattedMessage component={false} id={`widget.config.map.details.title.${typeLabel}`} />
          {' / '}
          <FormattedMessage component={false} id={`devices.wizard.categories.${categorySelected.toLowerCase()}`} />
        </Title>
      </Header>

      {children}
    </>
  );
};

SubMenu.propTypes = {
  categorySelected: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any),
  onMain: PropTypes.func,
  type: PropTypes.string,
};

SubMenu.defaultProps = {
  categorySelected: '',
  children: {},
  onMain: () => {},
  type: '',
};
export default SubMenu;
