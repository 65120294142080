import React, { Component } from 'react';
import Iframe from 'react-iframe'
import './styles.scss'

export class ExternalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ iframeWidth: this.getWidthIframe() }), true);
  }


  getWidthIframe = () => {
    const content = document.getElementById('content');
    return !content ? '20vw' : '' + (content.offsetWidth + 15) + 'px';
  };

  getUrl = () => this.props.location.search.split('url=')[1];

  render() {
    return <Iframe
        url={this.props.externalUrl || this.getUrl()}
        className="iframe"
        width={this.getWidthIframe()}
      />
  }
}

export default ExternalPage;
