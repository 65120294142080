import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import SubMenuItem from './subMenuItem';
import { getHash } from '../../../helpers/utils';

const SubMenuWrapper = styled.div`
position: relative;
z-index: 9999;
&:after {
  content: '';
  background: #E3EEFB;
  border-radius: 50%;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
`;

const SubMenuParent = ({
  getIcon,
  item,
  item: { subMenu },
  toggleOtherIcons,
  width,
  height,
  disabled,
  isOpened: opened,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [childWithPositions, setChildWithPositions] = useState(null);
  const wrapperRef = useRef(null);

  const toggleSubMenu = () => setIsOpened((old) => !old);
  const handleToggleSubMenu = () => {
    toggleOtherIcons();
    toggleSubMenu();
  };

  const getStep = (position, index) => {
    const { offsetLeft, offsetTop, parentElement } = wrapperRef.current;
    const { offsetWidth, offsetHeight } = parentElement;
    switch (position || index) {
      case 0:
        if (!position && offsetTop > height * 2) {
          return 0;
        } if (offsetTop > height * 2 && offsetLeft + width < offsetWidth) {
          return 1;
        } if (offsetTop < height * 2 && offsetLeft + width < offsetWidth) {
          return 2;
        } if (offsetTop < height * 2 && offsetLeft + width > offsetWidth) {
          return 4;
        }
        return 0;
      case 1:
        if (offsetLeft + width > offsetWidth && offsetTop > offsetHeight) {
          return 6;
        }
        if (offsetLeft + width > offsetWidth) {
          return 4;
        }
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
        return 4;
      case 5:
        return 5;
      case 6:
        return 6;
      case 7:
        return 7;
      default:
        return 0;
    }
  };

  const getPosition = (lastPosition, index) => {
    /*

    The grid is like:

    7   0   1
    6   -   2
    5   4   3

    Each step is a position of "clock"

    */
    const lastStep = lastPosition?.step >= 0 ? getStep(lastPosition.step + 1) : null;
    const step = !lastStep && getStep(lastPosition, index);
    const newHeight = height + 10;
    const newWidth = width + 10;
    switch (lastStep || step) {
      case 0:
        return { x: 0, y: -newHeight, step: step || lastStep };
      case 1:
        return { x: newWidth, y: -newHeight, step: step || lastStep };
      case 2:
        return { x: newWidth, y: 0, step: step || lastStep };
      case 3:
        return { x: newWidth, y: newHeight, step: step || lastStep };
      case 4:
        return { x: 0, y: newHeight, step: step || lastStep };
      case 5:
        return { x: -newWidth, y: newHeight, step: step || lastStep };
      case 6:
        return { x: -newWidth, y: 0, step: step || lastStep };
      case 7:
        return { x: -newWidth, y: -newHeight, step: step || lastStep };
      default:
        return { x: 0, y: -newHeight, step: step || lastStep };
    }
  };

  const setPositions = () => {
    let lastPosition = null;
    const positions = subMenu.map((subMenuItem, index) => {
      const newSubMenuItem = { ...subMenuItem };
      newSubMenuItem.position = getPosition(lastPosition, index);
      lastPosition = newSubMenuItem.position;
      return newSubMenuItem;
    });
    setChildWithPositions(positions);
  };

  useEffect(() => {
    if (wrapperRef.current?.parentElement.offsetHeight > 220) {
      // 220 is on first render (without items on menu)
      setPositions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wrapperRef.current?.parentElement.offsetWidth,
    wrapperRef.current?.parentElement.offsetHeight,
  ]);

  return (
    <SubMenuWrapper className={[disabled !== undefined && disabled ? 'submenu-opened' : '', opened ? ' opened' : '']} ref={wrapperRef} onClick={handleToggleSubMenu}>
      {getIcon(item.item)}
      {isOpened && (
        <>
          {childWithPositions?.map((subMenuItem, i) => (
            <SubMenuItem
              width={width}
              height={height}
              key={getHash(subMenuItem.item + i)}
              item={subMenuItem}
              getIcon={getIcon}
              root={item.item}
            />
          ))}
        </>
      )}
    </SubMenuWrapper>
  );
};

export default SubMenuParent;
