/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import ActionComponent from './components/ActionComponents/ActionComponent';
import TweetComponent from './components/ActionComponents/TweetComponent';
import CommandComponent from './components/ActionComponents/CommandComponent';
import AlertComponent from './components/ActionComponents/AlertComponent';
import NotificationComponent from './components/ActionComponents/NotificationComponent';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import { ButtonCustom } from '../../../../elements/Button/index';
import { continueText } from '../RulesWizard.module.scss';


const CreateAction = (props) => {
  const {
    onExternalClick, onContinue, newEntity, setTitles, setState,
  } = props;
  const [componentReference, setComponentReference] = useState(null);

  useEffect(() => {
    setTitles({
      lead: <FormattedMessage id={`rules.wizard.action.${newEntity.actionToCreate}.text`} />,
      title: <FormattedMessage id={`rules.wizard.action.${newEntity.actionToCreate}`} />,
    });
  }, []);

  const customSaveActionAndContinue = (entity) => {
    setState(entity);
    onExternalClick(-1);
  };

  const handleOnCreateAnotherOne = () => {
    componentReference.validate(customSaveActionAndContinue);
  };

  const handleSaveAction = (callback, action) => {
    const newEntityCopy = { ...newEntity };
    if (!newEntityCopy.events) newEntityCopy.events = [];

    newEntityCopy.events.push(action);

    if (callback) callback(newEntityCopy);
    else onContinue(newEntityCopy);
  };

  const getComponent = () => {
    const componentsProps = {
      ...props,
      saveAction: handleSaveAction,
      actionComponentRef: setComponentReference,
    };
    switch (newEntity.actionToCreate) {
      case 'device_update':
        return <ActionComponent {...componentsProps} />;
      case 'twitter':
        return <TweetComponent {...componentsProps} />;
      case 'send_command':
        return <CommandComponent {...componentsProps} />;
      case 'alert':
        return <AlertComponent {...componentsProps} />;
      case 'notification':
        return <NotificationComponent {...componentsProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Row>
        <Col>
          {getComponent()}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p className={`text-center pt-5 ${continueText} `}>
            <FormattedMessage id="rules.wizard.continue" />
            <ButtonCustom
              type="secondary"
              handleOnClick={handleOnCreateAnotherOne}
              onKeyUp={handleOnCreateAnotherOne}
              role="link"
              tabIndex={-1}
              label={<FormattedMessage id="rules.wizard.another.action" />}
            />
            <FormattedMessage id="rules.wizard.continue.second" />
          </p>
        </Col>
      </Row>
    </>
  );
};

CreateAction.propTypes = {
  onExternalClick: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  newEntity: PropTypes.shape({
    actionToCreate: PropTypes.string,
    events: PropTypes.array,
  }).isRequired,
  setTitles: PropTypes.func,
  setState: PropTypes.func.isRequired,
};

CreateAction.defaultProps = {
  setTitles: () => {},
};

export default withWizardStep(CreateAction);
