import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const Title = ({ devicesInfo }) => (
  <div className="title">
    <h1>
      <FormattedMessage id="SendCommandList.select.device.title" />
      {' '}
      {`(${devicesInfo.enabled}/${devicesInfo.total})`}
    </h1>
    <p>
      <FormattedMessage id="SendCommandList.select.device.text" />
    </p>
    <hr />
  </div>
);

Title.defaultProps = {
  devicesInfo: { enabled: 0, total: 0 },
};

Title.propTypes = {
/* Devices info to show */
  devicesInfo: PropTypes.shape({
    enabled: PropTypes.number,
    total: PropTypes.number,
  }),
};

export default Title;
