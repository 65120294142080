import * as TYPES from './types'
import { Map, List } from 'immutable';

export const exampleGroups = ["Group1", "Group2", "Group3", "Group4"];

/** Initial State of Permissions */
const initialState = Map({
    list: List([]),
    total:0,
    fetching: false,
    errorFetching: false,
    addSuccess: false,
    modifySuccess: false
});

/** PERMISSIONS REDUCER*/
export default (state = initialState, { type, payload }) => {

    /** Helper functions */
    let index = -1;
    let aux;
    /** Cases */
    switch (type) {

        case TYPES.GET_PERMISSIONS_REQUEST:
        case TYPES.GET_PERMISSION_REQUEST:
        case TYPES.GET_PERMISSION_PERMISSIONS_REQUEST:
        case TYPES.ADD_PERMISSION_REQUEST:
        case TYPES.MODIFY_PERMISSION_REQUEST:
        case TYPES.DELETE_PERMISSION_REQUEST:
            return state.set('fetching', true).set('errorFetching', false);

        case TYPES.GET_PERMISSIONS_ERROR:
        case TYPES.GET_PERMISSION_ERROR:
        case TYPES.GET_PERMISSION_PERMISSIONS_ERROR:
        case TYPES.ADD_PERMISSION_ERROR:
        case TYPES.MODIFY_PERMISSION_ERROR:
        case TYPES.DELETE_PERMISSION_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;



        /** GET ALL PERMISSIONS FROM DB TO STATE */
        case TYPES.GET_PERMISSIONS_SUCCESS:
            return state.set('total', payload.length)
              .set('list', List(payload))
              .set('fetching', false);

        /** GET A PERMISSION FROM DB TO STATE */
        case TYPES.GET_PERMISSION_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            //TODO
            aux = index === -1 ? state.update('list', permissionList => permissionList.push(payload)) : state;
            aux = aux.set('fetching', false);
            return aux;


        /** ADD NEW PERMISSION TO STATE */
        case TYPES.ADD_PERMISSION_SUCCESS:
            aux = state.update('list', permissionList => permissionList.push(payload));
            aux = aux.set('fetching', false);
            aux = aux.set('addSuccess', true);
            aux = aux.set('createdPermission', payload);
            aux = aux.update('total', total => total + 1);
            return aux;
        /** SET AS FALSE THE addSuccess STATE FLAG */
            case TYPES.ADD_PERMISSION_SUCCESS_FLAG:
            return state.set('addSuccess', false);

        /** UPDATE PERMISSION IN STATE */
        case TYPES.MODIFY_PERMISSION_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            aux = index !== -1 ? state.update('list', permissionList => permissionList.splice(index, 1, payload)) : state;
            aux = aux.set('fetching', false);
            aux = aux.set('modifySuccess', true);
            return aux;
        /** SET AS FALSE THE modifySuccess STATE FLAG */
            case TYPES.MODIFY_PERMISSION_SUCCESS_FLAG:
            return state.set('modifySuccess', false);

        /** DELETE PERMISSION IN STATE */
        case TYPES.DELETE_PERMISSION_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            aux = index !== -1 ? state.update('list', permissionList => permissionList.delete(index)) : state;
            aux = aux.set('fetching', false);
            aux = aux.update('total', total => total - 1);
            return aux;

        /** RESET ERROR FETCHING TO FALSE */
        case TYPES.ERRORFETCHING_PERMISSION_RESET:
            return state.set('errorFetching', false);


        default:
            return state
    }
};
