import * as TYPES from './types'
import { Map, List } from 'immutable';
import { clone } from 'ramda';

export const exampleGroups = ["Group1", "Group2", "Group3", "Group4"];

/** Initial State of Roles */
const initialState = Map({
    list: List([]),
    total:0,
    fetching: false,
    errorFetching: false,
    addSuccess: false,
    modifySuccess: false
});

/** ROLES REDUCER*/
export default (state = initialState, { type, payload }) => {

    /** Helper functions */
    let index = -1;
    let aux;
    /** Cases */
    switch (type) {

        case TYPES.GET_ROLES_REQUEST:
        case TYPES.GET_ROLE_REQUEST:
        case TYPES.GET_ROLE_PERMISSIONS_REQUEST:
        case TYPES.ADD_ROLE_REQUEST:
        case TYPES.MODIFY_ROLE_REQUEST:
        case TYPES.DELETE_ROLE_REQUEST:
            return state.set('fetching', true).set('errorFetching', false);

        case TYPES.GET_ROLES_ERROR:
        case TYPES.GET_ROLE_ERROR:
        case TYPES.GET_ROLE_PERMISSIONS_ERROR:
        case TYPES.ADD_ROLE_ERROR:
        case TYPES.MODIFY_ROLE_ERROR:
        case TYPES.DELETE_ROLE_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;



        /** GET ALL ROLES FROM DB TO STATE */
        case TYPES.GET_ROLES_SUCCESS:
            return state.set('total', payload.length)
              .set('list', List(payload))
              .set('fetching', false);

        /** GET A ROLE FROM DB TO STATE */
        case TYPES.GET_ROLE_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            //TODO
            aux = index === -1 ? state.update('list', roleList => roleList.push(payload)) : state;
            aux = aux.set('fetching', false);
            return aux;

        /** GET ROLE PERMISSIONS FROM DB TO STATE */
        case TYPES.GET_ROLE_PERMISSIONS_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            //TODO
            //Update permissions
            if (index !== -1) {
                const newArray = clone(state.get('list').toJS());
                newArray[index].permissions = payload.permissions;
                aux = state.set('list', List(newArray));
                aux = aux.set('fetching', false);
            } else {
                aux = state;
            }
            return aux;

        /** ADD NEW ROLE TO STATE */
        case TYPES.ADD_ROLE_SUCCESS:
            aux = state.update('list', roleList => roleList.push(payload));
            aux = aux.set('fetching', false);
            aux = aux.set('addSuccess', true);
            aux = aux.set('createdRole', payload);
            aux = aux.update('total', total => total + 1);
            return aux;
        /** SET AS FALSE THE addSuccess STATE FLAG */
            case TYPES.ADD_ROLE_SUCCESS_FLAG:
            return state.set('addSuccess', false);

        /** UPDATE ROLE IN STATE */
        case TYPES.MODIFY_ROLE_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            aux = index !== -1 ? state.update('list', roleList => roleList.splice(index, 1, payload)) : state;
            aux = aux.set('fetching', false);
            aux = aux.set('modifySuccess', true);
            return aux;
        /** SET AS FALSE THE modifySuccess STATE FLAG */
            case TYPES.MODIFY_ROLE_SUCCESS_FLAG:
            return state.set('modifySuccess', false);

        /** DELETE ROLE IN STATE */
        case TYPES.DELETE_ROLE_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            aux = index !== -1 ? state.update('list', roleList => roleList.delete(index)) : state;
            aux = aux.set('fetching', false);
            aux = aux.update('total', total => total - 1);
            return aux;

        /** RESET ERROR FETCHING TO FALSE */
        case TYPES.ERRORFETCHING_ROLE_RESET:
            return state.set('errorFetching', false);


        default:
            return state
    }
};
