import React from 'react';
import {
  Row, Container, Col, ListGroupItem, ListGroup,
} from 'reactstrap';

import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import DeleteButton from '../../../../elements/DeleteButton/index';
import ExpansionPanel from '../../../../components/ExpansionPanel/index';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Switch from '../../../../elements/Switch';
import CommonView from '../../../CommonView';
import CommandComponent from './CommandComponent';
import Field from '../../../../elements/Field/index';
import { ButtonCustom } from '../../../../elements/Button';
import SelectableList from '../../../../components/SelectableList';


class ConfigureSendCommand extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      errors: {},
      // name: this.props.newRule.name || '',
      commands_a: [this.addEmptyCommandA()],
      commands_b: [this.addEmptyCommandB()],
      state_a: '',
      state_b: '',
      second_state: false,
      page: 1,
      size: 5,
    };

    this.props.onChange('commands_a', this.state.commands_a);
    this.props.onChange('commands_b', this.state.commands_b);
  }

  static propTypes = {};

  static defaultProps = {
    fetching: true,
  };


  addEmptyCommandA = () => ({ device: '', attribute: '', value: '' });

  addEmptyCommandB = () => ({ device: '', attribute: '', value: '' });


  getOptions = () => {
    const { devices, intl } = this.props;
    return devices && devices.map((o) => ({ name: o.name, id: o.id, value: o }));
  };

  saveData_a = (data, init = false) => {
    const commands = [...this.state.commands_a];

    if (!init) {
      commands[data.index] = {
        ...commands[data.index],
        ...data,
      };
    } else {
      commands[data.index] = data;
    }

    this.setState({ commands_a: commands },
      () => this.props.onChange('commands_a', this.state.commands_a));
  };

  saveData_b = (data, init = false) => {
    const commands = [...this.state.commands_b];

    if (!init) {
      commands[data.index] = {
        ...commands[data.index],
        ...data,
      };
    } else {
      commands[data.index] = data;
    }

    this.setState({ commands_b: commands },
      () => this.props.onChange('commands_b', this.state.commands_b));
  };

  handleOnRemove_a = (index) => {
    const commands = clone(this.state.commands_a);
    const removed = commands.splice(index, 1);

    this.setState({ commands_a: commands },
      () => this.props.onChange('commands_a', commands));
  };

  handleOnRemove_b = (index) => {
    const commands = clone(this.state.commands_b);
    commands.splice(index, 1);

    this.setState({ commands_b: commands },
      () => this.props.onChange('commands_b', this.state.commands_b));
  };

  handleOnClickActionButton_a = () => {
    const commands = [...this.state.commands_a];
    commands.push(this.addEmptyCommandA());
    this.setState({ commands_a: commands },
      () => this.props.onChange('commands_a', this.state.commands_a));
  };

  handleOnClickActionButton_b = () => {
    const commands = [...this.state.commands_b];
    commands.push(this.addEmptyCommandB());
    this.setState({ commands_b: commands },
      () => this.props.onChange('commands_b', this.state.commands_b));
  };

  handleInputChange = (e) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;


    this.setState({ name: value },
      () => this.props.onChange(name, value));
  }

  handleAttributeStatusChange = (status) => {
    this.setState({ second_state: status },
      () => this.props.onChange('second_state', status));
  }


  render() {
    const { devices, intl, errors } = this.props;
    const { commands_a, commands_b } = this.state;
    return (
      <div className="configureSendCommand">
        <Row>
          <Col sm={10}>

            <Field
              key={1}
              name="state_a"
              label={intl.formatMessage({ id: 'widgets.wizard.sendCommand.stateA' })}
              placeholder={intl.formatMessage({
                id: 'widgets.wizard.sendCommand.stateA.placeholder',
              })}
              type="text"
              helperText={errors.state_a}
              error={!!errors.state_a}
              onChange={this.handleInputChange}
              value={this.state.state_a}
            />

          </Col>
          <Col sm={2}>


            <ButtonCustom
              label={intl.formatMessage({ id: 'widgets.wizard.sendCommand.addCommandA' })}
              type="secondary"
              icon="plus"
              handleOnClick={this.handleOnClickActionButton_a}
            />
          </Col>
        </Row>
        {commands_a.map((command, i) => (
          <Row>
            <CommandComponent
                // actionType={actionType}
                // onChange={onChange}
              intl={intl}
              allDevices={this.getOptions()}
              command={command}
              key={command.id}
              index={i}
              removeAction={this.handleOnRemove}
              onChange={this.saveData_a}
              errors={errors && errors.commands_a && errors.commands_a[i] ? errors.commands_a[i] : {}}
            />
            {commands_a.length > 1 && (
              <ButtonCustom
                type="circular"
                name="minus"
                onClick={() => this.handleOnRemove_a(i)}
              />
            )}
          </Row>

        ))}


        <Row>
          <div className="d-flex justify-content-between">
            <p className="selectAll">
              Activar segundo estado
              <Switch
                checked={this.state.second_state}
                onChange={(status) => this.handleAttributeStatusChange(status)}
              />
            </p>
          </div>
        </Row>

        {this.state.second_state

          && (
          <Row>
            <Col sm={10}>
              <Field
                key={1}
                name="state_b"
                label={intl.formatMessage({ id: 'widgets.wizard.sendCommand.stateB' })}
                placeholder={intl.formatMessage({
                  id: 'widgets.wizard.sendCommand.stateB.placeholder',
                })}
                type="text"
                helperText={errors.state_b}
                error={!!errors.state_b}
                onChange={this.handleInputChange}
                value={this.state.state_b}
              />

            </Col>
            <Col sm={2}>


              <ButtonCustom
                label={intl.formatMessage({ id: 'widgets.wizard.sendCommand.addCommandB' })}
                type="secondary"
                icon="plus"
                handleOnClick={this.handleOnClickActionButton_b}
              />
            </Col>
          </Row>
          )}

        {this.state.second_state && commands_b.map((command, i) => (
          <Row>
            <CommandComponent
              intl={intl}
              allDevices={this.getOptions()}
              command={command}
              key={command.id}
              index={i}
              removeAction={this.handleOnRemove}
              onChange={this.saveData_b}
              errors={errors && errors.commands_b && errors.commands_b[i] ? errors.commands_b[i] : {}}
            />
            {commands_b.length > 1 && (
              <ButtonCustom
                type="circular"
                name="minus"
                onClick={() => this.handleOnRemove_b(i)}
              />
            )}
          </Row>

        ))}


      </div>


    );
  }
}


export default injectIntl(ConfigureSendCommand);
