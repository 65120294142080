/* eslint-disable import/no-cycle */
import React from 'react';
import Widget from '../..';
import ParametrizedTextDefaultConfiguration from './utils/defaultConfiguration';
import ParametrizedTextComponent from './component';
import formatToParametrizedWidget from './utils/formatData';
import { updateParagraphText } from '../../../../pages/Dashboards/ShowV2/reducer';
import { ReactComponent as ParagraphIcon } from '../../../../configuration/icons/svg/text-ic.svg';

const WidgetCard = ({ children, hasPermissionToEdit }) => (
  <>
    {React.cloneElement(children, { hasPermissionToEdit })}
  </>
);

export const NAME_WIDGET = 'PARAMETRIZED_TEXT';
export default class ParametrizedTextWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = ParagraphIcon;

  static wizardSteps = null;

  updateMySelf = updateParagraphText;

  defaultConfig = ({ ...ParametrizedTextDefaultConfiguration });

  component = ParametrizedTextComponent;

  customWidgetCard = WidgetCard;

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => null

  getConfigurationSheet = () => null

  getWidgetChildBodyQuery = () => null

  injectData = () => this.defaultConfig

  formatToData = (historicalValues) => formatToParametrizedWidget(historicalValues)

  getQueryHistorical() {
    const dataForQueries = this.getEmptyHistoricalBody();
    const sources = this.getDataForQueries({ sources: this.sources });
    dataForQueries[0].sources = sources;
    return dataForQueries;
  }

  parsedLinkedData = () => null
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
