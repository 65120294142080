/* eslint-disable react/no-unused-state */
import React from 'react';
import validate from 'validate.js';
import { clone } from 'ramda';
import { PropTypes } from 'prop-types';
import { DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { CellWifiTwoTone } from '@material-ui/icons';
import EditGeoFenceComponent from '../Profile/components/editConditionsComponents/EditGeoFenceComponent';
import { parseToValue } from './parseToCriterion';
import { isGeoAttribute } from '../Profile/components/helpers/helpers';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import PolygonMap from '../../../components/WidgetMap/PolygonMap';
import { ValueField } from '../../helpers/Profile/ProfileDevice/helpers/index';
import { updateRuleEngine } from '../../../services/redux/rules/actions';
import { GEO_VALUES } from './operatorOptions';
import Alert from '../../../components/Alert';
import { alertWrapperInTab } from '../Add/RulesWizard.module.scss';

class ConditionsUtils extends React.Component {
  constructor(obj) {
    super(obj.props);
  }

  /* Helpers */
  initActiveCards = (data) => data.map((event) => event.map(() => false));

  joinedAttributes = (device) => {
    try {
      return device && Object.entries(device).length > 0
        ? [
          // ...device.command_attributes,
          ...device.lazy_attributes,
          // ...device.static_attributes,
          ...device.attributes,
        ]
        : {};
    } catch (error) {
      return [];
    }
  };

  /* Conditions */
  areRuleDevices = () => {
    const { ruleDevices } = this.state;
    return !!ruleDevices;
  };

  conditionIsEmpty = (condition) => Object.entries(condition).length === 0;

  isPolygonOperator = (operator) => GEO_VALUES.includes(operator);

  /* Saving/Updating */

  updateRuleEngine = (payload) => {
    updateRuleEngine(payload);
  };

  /* Validating */

  validateCondition = (orIndex, andIndex) => {
    const { conditions } = this.state;
    const condition = conditions[orIndex][andIndex];

    const constraints = {};
    ['device', 'variable', 'value', 'operator'].forEach((index) => {
      constraints[index] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.empty" />,
        },
      };
    });
    return validate(condition, constraints);
  };

  validateTimeRule = (orIndex, andIndex) => {
    const { conditions } = this.state;
    const condition = conditions[orIndex][andIndex];
    if (conditions[orIndex][andIndex].timeWindow) {
      const constraints = {};
      ['timeWindow'].forEach((index) => {
        Object.keys(condition[index]).forEach((c) => {
          constraints[c] = {
            presence: {
              allowEmpty: false,
              message: <FormattedMessage id="rules.profile.validation.empty" />,
            },
          };
          if (c === 'repeat') {
            constraints[c].numericality = {
              greaterThanOrEqualTo: 2,
              message: <FormattedMessage id="rules.profile.validation.repeat.min.value" />,
            };
          }
        });
      });
      return validate(condition.timeWindow, constraints);
    }
    return null;
  }

  /* Translating */

  translateCriterionToConditions = (criterion) => {
    const conditions = [];

    // Recorremos todos los grupos de reglas
    criterion.criteria.forEach((crit) => {
      // Si únicamente hay una regla principal sin ninguna anidada
      if (['<', '>', '=', '<=', '>=', '!=', 'geo-in', 'geo-out'].includes(crit.operation)) {
        conditions.push([this.formatCriteriaToCondition(crit, criterion.operation)]);
        // Si hay una regla principal y alguna otra anidada
      } else {
        const nested = [];
        crit.criteria.forEach((c, i) => {
          if (i === 0) {
            nested.push(this.formatCriteriaToCondition(c, criterion.operation));
          } else {
            nested.push(this.formatCriteriaToCondition(c, crit.operation));
          }
        });
        conditions.push(nested);
      }
    });

    return conditions;
  };

  translateConditionsToCriterion = (conditions) => {
    let criterion = null;

    criterion = {
      type: 'logic',
      operation: 'or',
      criteria: [],
    };
    if (conditions.length > 1) {
      criterion.operation = conditions[1][0].type;
      conditions.forEach((orCondition) => {
        if (orCondition.length > 1) {
          criterion.criteria.push({
            type: 'logic',
            operation: orCondition[1].type || 'and',
            criteria: orCondition.map((andcond) => this.formatConditionToCriteria(andcond)),
          });
        } else {
          criterion.criteria.push(
            this.formatConditionToCriteria(orCondition[0]),
          );
        }
      });
    } else if (conditions[0].length > 1) {
      criterion.criteria.push({
        type: 'logic',
        operation: conditions[0][1].type,
        criteria: conditions[0].map((andcond) => this.formatConditionToCriteria(andcond)),
      });
    } else {
      criterion.criteria = [this.formatConditionToCriteria(conditions[0][0])];
    }
    return criterion;
  };

  translateConditionsToEntities = (conditions, ruleDevices) => {
    const entities = [];
    conditions.forEach((group) => group.forEach((condition) => {
      const device = this.getDevice(ruleDevices, condition);

      if (device && !entities.find((d) => d.id === device.device_id)) {
        entities.push({
          attributes: device.attributes,
          id: device.device_id,
          type: 'device',
        });
      }
    }));
    return entities;
  };

  formatConditionToCriteria = (condition) => ({
    type: 'logic',
    operation: condition.operator,
    criteria: [
      {
        type: 'device',
        entityId: condition.device.device_id || condition.device,
        attribute: condition.variable || condition.attribute.name,
      },
      {
        ...parseToValue(condition.operator, condition.value),
      },
    ],
    time_window: condition.timeWindow,
  });

  formatCriteriaToCondition = (criterion, type = undefined) => ({
    operator: criterion.operation,
    criteria: criterion,
    variable: criterion.criteria.find((c) => c.type === 'device').attribute,
    device: criterion.criteria.find((c) => c.type === 'device').entityId,
    value: criterion.criteria.find((c) => c.type === 'value').value,
    type,
    timeWindow: criterion.time_window,
  });

  /* Rendering */

  getValue = ({ criteria, operator, value }) => {
    if (!this.isPolygonOperator(operator)) {
      return value;
    }
    return (
      <div className="mapWrapper">
        <PolygonMap
          zoom={8}
          features={this.getFeatures(value, criteria)}
          mode={null}
          onePolygon
        />
      </div>
    );
  };

  getFeatures = (value, criteria) => ({
    geometry: {
      coordinates: value.coordinates,
      type: 'Polygon',
    },
    properties: {
      id: criteria.id,
      renderType: 'Polygon',
      type: 'Feature',
    },
  });

  getValueField = (
    onChange,
    condition,
    errors,
    orIndex,
    andIndex,
    intl,
    device,
  ) => {
    const { polygonMapVisibility } = this.state;
    const features = condition.criteria && condition.value ? this.getFeatures(condition.criteria.criteria[1].value, condition.value) : 'empty';
    if (condition.operator !== '' && isGeoAttribute(device, condition)) {
      return (
        <div>
          <EditGeoFenceComponent
            showMap={polygonMapVisibility}
            features={features}
            condition={condition}
            onShowPolygonMapModal={this.handleShowPolygonMapModal}
            onAssignAreaToValue={(features) => this.assignAreaToValue({
              id: 'value',
              features,
              orIndex,
              andIndex,
            })}
            mode={(features === 'empty' || (features && features.geometry && !features.geometry.coordinates)) ? new DrawPolygonMode() : new EditingMode()}
          />
          {(features === 'empty' || (features && features.geometry && !features.geometry.coordinates))
          && (
          <div className={alertWrapperInTab}>
            <Alert
              text={(
                <FormattedMessage
                  id="rules.wizard.assign.area.alert"
                  defaultMessage="Please enter an area to continue"
                  description="Please enter an area to continue"
                />
                    )}
              mode="warning"
            />
          </div>
          )}
        </div>
      );
    }
    return (
      <ValueField
        onChange={(data) => onChange({
          id: data.target.name,
          value: data.target.value,
          orIndex,
          andIndex,
        })}
        value={condition.value}
        intl={intl}
        errors={errors.value}
        name="value"
        type={['>', '<'].includes(condition.operator) ? 'number' : 'text'}
      />
    );
  };

  getConditionType = ({
    andIndex, orIndex, mainCondition, condition,
  }) => {
    if (andIndex === 0 && orIndex === 0) return 'if';

    return andIndex === 0 && orIndex > 1 && mainCondition
      ? mainCondition.type
      : condition.type;
  }

  /* Adding */
  addNewCondition = (orIndex = null, andIndex = null, device = null, type = null) => {
    const { conditions: _conditions, activeCards: _activeCards } = this.state;
    const newCondition = {
      variable: '', operator: '', value: '', device, type,
    };
    const conditions = [..._conditions];
    const activeCards = [..._activeCards];
    if (andIndex === null || orIndex === null) {
      // if is an orCondition
      conditions.push([newCondition]);
      activeCards.push([false]);
      this.setState({
        conditions,
        activeCards,
        mainCondition: conditions[1] ? conditions[1][0] : undefined,
      });
    } else {
      // if is an andCondition
      conditions[orIndex].push(newCondition);
      activeCards[orIndex].push(false);
      this.setState({
        conditions,
        activeCards,
        mainCondition: conditions[1] ? conditions[1][0] : undefined,
      });
    }
  };

  handleClickTimeButton = (orIndex, andIndex) => {
    const { conditions: _conditions } = this.state;
    const conditions = clone(_conditions);
    const updateCondition = conditions[orIndex][andIndex];
    if (!updateCondition.timeWindow) {
      conditions[orIndex][andIndex].timeWindow = {
        repeat: '',
        interval: '',
        unit: '',
      };
    } else {
      delete conditions[orIndex][andIndex].timeWindow;
    }
    this.setState({ conditions });
  }

  handleDeleteTimeCondition = (orIndex, andIndex, ruleDevices = undefined) => {
    const { conditions: _conditions } = this.state;
    const { updating, data } = this.props;
    const activeCards = this.initActiveCards(_conditions);
    const conditions = clone(_conditions);
    delete conditions[orIndex][andIndex].timeWindow;
    delete conditions[orIndex][andIndex].criteria.timeWindow;
    if (updating) {
      const criterion = this.translateConditionsToCriterion(conditions);
      const entities = this.translateConditionsToEntities(conditions, ruleDevices);
      this.updateRuleEngine({
        ruleId: data.id,
        payload: {
          criterion,
          entities,
        },
      });
    }

    this.setState({
      activeCards,
      // eslint-disable-next-line react/no-unused-state
      errors: {},
      conditions,
    });
  }

  setTimeCondition = (updatedCondition, orIndex, andIndex) => {
    const { conditions: _conditions } = this.state;
    const conditions = clone(_conditions);
    conditions[orIndex][andIndex] = updatedCondition;

    this.setState({
      errors: {},
      conditions,
    });
  }

  getInnerValue = (id, value) => {
    if (id === 'variable') return value.name;

    if (id === 'device') return value.device_id;

    return value;
  }

  /* Handlers */
  handleSelectChange = (payload) => {
    const { conditions: _conditions, saved, ruleDevices } = this.state;
    const {
      orIndex, andIndex, id, value,
    } = payload;

    const conditions = [..._conditions];
    const newConditions = clone(conditions);
    let parentDeviceChanged = false;

    conditions[orIndex][andIndex][id] = this.getInnerValue(id, value);

    if (conditions[orIndex].length > 1 && id === 'device' && saved[orIndex][andIndex].device !== value.device_id) {
      parentDeviceChanged = true;
      // New Device ID -> value.device_id
      // Variables
      let attNamesUsedBefore = saved[orIndex].map((c) => c.variable); // Name of the variables that were used in the conditions before.
      attNamesUsedBefore = Array.from(new Set(attNamesUsedBefore)); // Delete the duplicates to avoid unneccesary checkings.
      const attWithSameNames = []; // Array containing wholes attributes objects which names were also present on the previous device.
      const oldDeviceAttributes = []; // Array containing all the attributes objects of the previous device.
      const equalAttributes = []; // Array containing the names of the attributes that are equal on each device (oldDevice vs newDevice).

      // Now we check if the new device have attributes with the same name as the ones used in conditions.
      // If true we add the entire attribute object to an array.
      value.attributes.forEach((a) => {
        if (attNamesUsedBefore.includes(a.name)) attWithSameNames.push(a);
      });

      // Now we have to get the entire attributes objects of the old device.
      const oldDevice = ruleDevices.find((d) => d.device_id === saved[orIndex][0].device);
      oldDevice.attributes.forEach((a) => {
        oldDeviceAttributes.push(a);
      });

      // Now we compare the attributes of both devices to know if all properties are the same.
      // We add the matching ones to an array.
      attWithSameNames.forEach((a) => {
        oldDeviceAttributes.forEach((a2) => {
          if (JSON.stringify(a) === JSON.stringify(a2)) {
            equalAttributes.push(a.name);
          }
        });
      });

      // Now we remove the conditions that have an attribute not present on the new device.
      // If present we maintain the condition with that attribute and change the device selected on that condition to the new one.
      const conditionsToRemove = [];
      conditions[orIndex].forEach((c, i) => {
        if (!equalAttributes.includes(conditions[orIndex][i].variable)) {
          if (i !== 0) {
            conditionsToRemove.push(c);
          } else {
            newConditions[orIndex][i].device = this.getInnerValue(id, value);
            newConditions[orIndex][i].variable = '';
            newConditions[orIndex][i].operator = '';
            newConditions[orIndex][i].value = '';
          }
        } else {
          newConditions[orIndex].forEach((nc, j) => {
            newConditions[orIndex][j].device = this.getInnerValue(id, value);
          });
        }
      });
      if (conditionsToRemove) {
        conditionsToRemove.forEach((c) => {
          newConditions[orIndex].splice(newConditions[orIndex].indexOf(c), 1);
        });
      }
    } else if (saved[orIndex] && saved[orIndex][andIndex] && id === 'device' && saved[orIndex][andIndex].device !== value.device_id) {
      conditions[orIndex][andIndex].device = this.getInnerValue(id, value);
      conditions[orIndex][andIndex].variable = '';
      conditions[orIndex][andIndex].operator = '';
      conditions[orIndex][andIndex].value = '';
    } else if (saved[orIndex] && saved[orIndex][andIndex] && id === 'variable' && saved[orIndex][andIndex].variable !== value.key) {
      conditions[orIndex][andIndex].operator = '';
      conditions[orIndex][andIndex].value = '';
    } else if (saved[orIndex] && saved[orIndex][andIndex] && id === 'operator' && saved[orIndex][andIndex].variable !== value) {
      conditions[orIndex][andIndex].value = '';
    }
    this.setState({
      conditions: parentDeviceChanged ? newConditions : conditions,
    });
  };

  handleOnSave = (orIndex, andIndex, ruleDevices = undefined) => {
    const { conditions } = this.state;
    const { data, updating, onChange } = this.props;
    const validation = this.validateCondition(orIndex, andIndex);
    const validateTime = this.validateTimeRule(orIndex, andIndex);

    if (validation || validateTime) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        errors: { ...validation, ...validateTime },
      });
    } else {
      const activeCards = this.initActiveCards(conditions);

      if (updating) {
        const criterion = this.translateConditionsToCriterion(conditions);
        const entities = this.translateConditionsToEntities(conditions, ruleDevices);
        this.updateRuleEngine({
          ruleId: data.id,
          payload: {
            criterion,
            entities,
          },
        });
      } else {
        onChange(conditions);
      }

      this.setState({
        saved: clone(conditions),
        activeCards,
        // eslint-disable-next-line react/no-unused-state
        errors: {},
        // eslint-disable-next-line react/no-unused-state
        mainCondition: conditions[1] ? conditions[1][0] : undefined,
      });
    }
  };

  handleOnClickAddButton = (type) => {
    this.addNewCondition(null, null, null, type);
  };

  handleOnClickNestButton = (orIndex, andIndex, device, type) => {
    this.addNewCondition(orIndex, andIndex, device, type);
  };

  handleOnEditStatusChange = (value, orIndex, andIndex) => {
    const { conditions: _conditions, activeCards: _activeCards, saved } = this.state;
    const activeCards = this.initActiveCards(_conditions);
    activeCards[orIndex][andIndex] = !value;
    let conditions = _conditions;
    if (_activeCards.some((card) => card.some((c) => c === true))) {
      conditions = clone(saved);
    }
    this.setState({
      conditions,
      activeCards,
      // eslint-disable-next-line react/no-unused-state
      errors: {},
    });
  };

  handleOnDelete = (orIndex, andIndex, ruleDevices = undefined) => {
    const { conditions: _conditions } = this.state;
    const { data, updating } = this.props;
    const activeCards = this.initActiveCards(_conditions);
    const conditions = clone(_conditions);
    [activeCards, conditions].forEach((index) => {
      index[orIndex].splice(andIndex, 1);
      if (index[orIndex].length === 0) {
        index.splice(orIndex, 1);
      }
    });

    if (updating) {
      const criterion = this.translateConditionsToCriterion(conditions);
      const entities = this.translateConditionsToEntities(conditions, ruleDevices);
      this.updateRuleEngine({
        ruleId: data.id,
        payload: {
          criterion,
          entities,
        },
      });
    }

    this.setState({
      activeCards,
      // eslint-disable-next-line react/no-unused-state
      errors: {},
      conditions,
    });
  };

  handleOnCancel = () => {
    const { saved } = this.state;
    const activeCards = this.initActiveCards(saved);
    this.setState({
      conditions: clone(saved),
      // eslint-disable-next-line react/no-unused-state
      errors: {},
      activeCards,
    });
  };

  handleOnChangeConditionalButton = (type, orIndex, andIndex) => {
    const { conditions: _conditions } = this.state;
    const conditions = [..._conditions];
    conditions[orIndex][andIndex].type = type;

    conditions[orIndex] = conditions[orIndex].map((c, i) => {
      if (i > 1) {
        return { ...c, type: conditions[orIndex][1].type };
      }
      return c;
    });

    this.setState({
      conditions,
      mainCondition: conditions[1] ? conditions[1][0] : undefined,
    });
  }

  getDevices = (data) => {
    if (Array.isArray(data) && !Array.isArray(data[0])) return [data];
    return this.getDevices(data[0]);
  };

  getOptions = (entities) => (entities && entities.length > 0
    ? entities.map((o) => ({ name: o.name, id: o.name, value: o }))
    : []);

  getAttribute = (attributes, data) => {
    if (data.variable === '' || attributes.length === 0) {
      return '';
    }
    const attributesList = attributes.find(
      (b) => data.variable === b.name || data.variable.name === b.name,
    );
    return attributesList ? attributesList.value : undefined;
  };

  getDevice = (devices, data) => (devices ? devices.find((d) => d.device_id === data.device) : {})

  getButtonType = (orIndex, andIndex, condition) => {
    const { conditions } = this.state;
    if (conditions.length < 1) return 'if';
    if (orIndex === 0 && andIndex === 0) return 'if';
    if (conditions[1]) return conditions[1][0].type;
    if (condition) return condition;
    return andIndex === 0
      ? 'or'
      : 'and';
  }

  // Geo Fence
  assignAreaToValue = ({
    features, orIndex, andIndex, id,
  }) => {
    if (features && features[0] !== 'empty' && features.length > 0) {
      this.handleOnFeatureValueChange({
        features, orIndex, andIndex, id,
      });
    }
  };

  handleOnFeatureValueChange = (payload) => {
    const { orIndex, andIndex, features } = payload;
    const { conditions: _conditions } = this.state;
    const conditions = [..._conditions];

    if (!conditions[orIndex][andIndex].criteria || conditions[orIndex][andIndex].operator !== conditions[orIndex][andIndex].criteria.operation) {
      const { criteria } = this.formatConditionToCriteria(
        conditions[orIndex][andIndex],
      );
      conditions[orIndex][andIndex].criteria = {};
      conditions[orIndex][andIndex].criteria.criteria = criteria;
    }

    if (features[0] !== 'empty') {
      conditions[orIndex][andIndex].criteria.criteria.find(
        (crit) => crit.type === 'value',
      ).value.coordinates = features[0].geometry.coordinates;

      conditions[orIndex][andIndex].value = { coordinates: features[0].geometry.coordinates, type: 'polygon' };

      this.setState(
        {
          conditions,
        },
        () => this.handleShowPolygonMapModal(),
      );
    }
  };

  handleShowPolygonMapModal = () => {
    this.setState((prev) => ({
      polygonMapVisibility: !prev.polygonMapVisibility,
    }));
  };
}

ConditionsUtils.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  updating: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ConditionsUtils.defaultProps = {
  data: [],
  updating: true,
};

export default ConditionsUtils;
