import {
  takeLatest,
  call,
  put,
  all,
  fork,
} from 'redux-saga/effects';
import Alert from '../../../models/Alert';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/alerts';

/**
 * Actions for WATCHERS
 * */

function* callToAPI(
  apiCall,
  successResponseCode,
  errorType,
  callback,
  payload = null
) {
  try {
    const response = yield call(apiCall, payload);
    if (response.status === successResponseCode) {
      yield callback(response);
    } else {
      yield put({ type: errorType, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: errorType, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all alert form DB
export function* getAlertsThroughApi({payload}) {
  yield callToAPI(Api.readAlerts, 200, TYPES.GET_ALERTS_ERROR, function*(
    response
  ) {
    yield put({
      type: TYPES.GET_ALERTS_SUCCESS,
      payload: {incidents: response.data.objects.map(o => new Alert(o)), total: response.data.total},
    });
  }, payload);
}

// Try to recover a alert form DB
export function* getAlertThroughApi({ payload }) {
  yield callToAPI(
    Api.readAlert,
    200,
    TYPES.GET_ALERTS_ERROR,
    function*(response) {
      yield put({
        type: TYPES.GET_ALERT_SUCCESS,
        payload: new Alert(response.data.object),
      });
    },
    payload
  );
}

/**
 * WATCHERS
 */
// Watcher looking for GET_ALERTS_REQUEST
export function* watcherGetAlerts() {
  yield takeLatest('GET_ALERTS_REQUEST', getAlertsThroughApi);
}

// Watcher looking for GET_ALERT_REQUEST
export function* watcherGetAlert() {
  yield takeLatest('GET_ALERT_REQUEST', getAlertThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetAlerts),
    fork(watcherGetAlert),
  ]);
}
