import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  fork,
} from 'redux-saga/effects';
import DataSource from '../../../models/DataSource';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as API from '../../api/dataSources';


/**
 * Actions for WATCHERS
 * */

// Try to recover all dataSource from DB
export function* getDataSourcesThroughAPI({ payload }) {
  try {
    const response = yield call(
      API.readDataSources,
      payload.page,
      payload.size,
      payload.filters,
      payload.external,
      payload.gis,
    );
    if (response.status === 200) {
      const dataSources = response.data.objects.map((dataSource) => new DataSource(dataSource));
      yield put({ type: TYPES.GET_DATASOURCES_SUCCESS, payload: { total: response.data.total, dataSources } });
    } else {
      yield put({ type: TYPES.GET_DATASOURCES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DATASOURCES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover a dataSource from DB
export function* getPublicDataSourcesThroughAPI({ payload }) {
  try {
    const response = yield call(
      API.readPublicDataSources,
      payload.page,
      payload.size,
      payload.filters,
    );
    if (response.status === 200) {
      const dataSources = response.data.dataSources.map(
        (dataSource) => new DataSource(dataSource),
      );
      yield put({
        type: TYPES.GET_PUBLIC_DATASOURCES_SUCCESS,
        payload: { total: response.data.total, dataSources },
      });
    } else {
      yield put({ type: TYPES.GET_PUBLIC_DATASOURCES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_PUBLIC_DATASOURCES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover a dataSource from DB
export function* getMineDataSourcesThroughAPI({ payload }) {
  try {
    const response = yield call(API.readMyDataSources, payload.page,
      payload.size,
      payload.filters);
    if (response.status === 200) {
      const dataSources = response.data.dataSources.map(
        (dataSource) => new DataSource(dataSource),
      );
      yield put({
        type: TYPES.GET_MINE_DATASOURCES_SUCCESS,
        payload: { total: response.data.total, dataSources },
      });
    } else {
      yield put({ type: TYPES.GET_MINE_DATASOURCES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MINE_DATASOURCES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover a dataSource from DB
export function* getDataSourceThroughAPI({ payload }) {
  try {
    const response = yield call(API.readDataSource, payload);
    if (response.status === 200) {
      const dataSourceFromDB = new DataSource(response.data.dataSource);
      yield put({ type: TYPES.GET_DATASOURCE_SUCCESS, payload: dataSourceFromDB });
    } else {
      yield put({ type: TYPES.GET_DATASOURCE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DATASOURCE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to create dataSource in DB
export function* addDataSourcesThroughAPI({ payload }) {
  try {
    const response = yield call(API.addDataSource, payload);
    if (response.status === 201) {
      const dataSourceFromDB = new DataSource(response.data.object);
      yield put({ type: TYPES.ADD_DATASOURCE_SUCCESS, payload: dataSourceFromDB });
    } else {
      yield put({ type: TYPES.ADD_DATASOURCE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_DATASOURCE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update dataSource in DB
export function* updateDataSourcesThroughAPI({ payload }) {
  try {
    const response = yield call(API.updateDataSource, payload);

    if (response.status === 200) {
      const newDataSources = new DataSource(response.data.object);
      yield put({ type: TYPES.MODIFY_DATASOURCE_SUCCESS, payload: newDataSources });
    } else {
      yield put({ type: TYPES.MODIFY_DATASOURCE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_DATASOURCE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete dataSource in DB
export function* deleteDataSourcesThroughAPI({ payload }) {
  try {
    const response = yield call(API.deleteDataSource, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_DATASOURCE_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_DATASOURCE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_DATASOURCE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getDataSourcesFromDEvicesThroughApi({ payload, type, nameSpace }) {
  try {
    const response = yield all(
      payload.map((device) => call(API.readDataSourceFromDevice, device)),
    );
    if (response) {
      const devices = response.filter((o) => !o.data.errors).map((d) => new DataSource(d.data.object));
      yield put({
        type: TYPES.GET_DATASOURCES_FROM_DEVICES_SUCCESS,
        payload: devices,
        nameSpace,
      });
    } else {
      yield put({
        type: TYPES.GET_DATASOURCES_FROM_DEVICES_ERROR,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DATASOURCES_FROM_DEVICES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


/**
 * WATCHERS
 */
// Watcher looking for GET_DATASOURCES_REQUEST
function* watcherGetDataSources() {
  yield takeLatest('GET_DATASOURCES_REQUEST', getDataSourcesThroughAPI);
}

function* watcherGetPublicDataSources() {
  yield takeLatest('GET_PUBLIC_DATASOURCES_REQUEST', getPublicDataSourcesThroughAPI);
}

function* watcherGetMineDataSources() {
  yield takeLatest('GET_MINE_DATASOURCES_REQUEST', getMineDataSourcesThroughAPI);
}

// Watcher looking for GET_DATASOURCE_REQUEST
function* watcherGetDataSource() {
  yield takeEvery('GET_DATASOURCE_REQUEST', getDataSourceThroughAPI);
}

// Watcher looking for ADD_DATASOURCE_REQUEST
function* watcherAddDataSources() {
  yield takeEvery('ADD_DATASOURCE_REQUEST', addDataSourcesThroughAPI);
}

// Watcher looking for MODIFY_DATASOURCE_REQUEST
function* watcherUpdateDataSources() {
  yield takeLatest('MODIFY_DATASOURCE_REQUEST', updateDataSourcesThroughAPI);
}


// Watcher looking for DELETE_DATASOURCE_REQUEST
function* watcherDeleteDataSources() {
  yield takeEvery('DELETE_DATASOURCE_REQUEST', deleteDataSourcesThroughAPI);
}

// Watcher looking gro GET_DEVICE_LIST_FOR_WIDGETS_REQUEST
function* watcherGetDataSourcesFromDevices() {
  yield takeLatest('GET_DATASOURCES_FROM_DEVICES_REQUEST', getDataSourcesFromDEvicesThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetDataSources),
    fork(watcherGetPublicDataSources),
    fork(watcherGetMineDataSources),
    fork(watcherGetDataSource),
    fork(watcherAddDataSources),
    fork(watcherUpdateDataSources),
    fork(watcherDeleteDataSources),
    fork(watcherGetDataSourcesFromDevices),
  ]);
}
