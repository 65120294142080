import validate from 'validate.js';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../../Utils';
import ModelExpected from './attributes';

/** Class Attribute with Utils functions */
export default class Internal extends Utils {
  static entityName = 'Internal';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = Internal;
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels(
    'Internal',
    ModelExpected,
  );

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleInternal = new Internal();
export { SampleInternal };
