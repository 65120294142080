import React from 'react';
import { injectIntl } from 'react-intl';

import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import Field from '../../../../elements/Field';

const Selectable = ({
  loading,
  handleOnSearch,
  getOptions,
  handleSelectChange,
  value,
  handleFetchInfo,
  name,
  label,
  placeholder,
  auxItem,
  disabled,
  error,
  helperText,
  id,
}) => (
  <div className="d-flex">
    <Field
      type="select"
      name={name}
      label={label}
      placeholder={placeholder}
      options={getOptions()}
      onChange={handleSelectChange}
      value={value}
      async
      fetchInfo={handleFetchInfo}
      loading={loading}
      onSearch={handleOnSearch}
      filterBy="name"
      className="mr-2"
      auxItem={auxItem}
      helperText={helperText}
      error={!!error}
      disabled={disabled}
      id={id}
    />
  </div>
);

export default withWizardStep(injectIntl(Selectable));
