/* eslint-disable import/no-cycle */
import { getUrnEntity } from '../../../../../helpers/utils';
import { getRandomColor } from '../../../../../pages/Widgets/Add/components/utils';

const MapDefaultConfiguration = {
  data: {
    realTimeUpdates: false,
  },
  labels: {
    alias: { },
    categoryAlias: { },
  },
  conditions: {
    categoryColors: { },
  },
  appearance: {
    hiddenAttributes: { },
    iconCategory: true,
  },
  custom: {
    MAP: {
      viewType: 'default',
    },
  },
};

export default MapDefaultConfiguration;

export const injectMapData = (widget) => {
  const newConfig = { ...widget.config };
  const categoryColors = {};
  const categories = [];
  widget.sources.forEach((source) => {
    if (getUrnEntity(source.urn).toLowerCase() !== 'kpi') {
      source.fields.forEach((attr) => {
        if (!newConfig.labels.alias[attr]) {
          newConfig.labels.alias[attr] = attr;
        }
      });
    }
    if (!source.categories) {
      categories.push('OTHER');
      categoryColors.OTHER = {
        color: getRandomColor(),
        mode: '',
        range: '',
        element: [],
      };
    } else {
      source.categories.forEach((category) => {
        if (!categories.includes(category)) {
          categories.push(category);
          categoryColors[category] = {
            color: getRandomColor(),
            mode: '',
            range: '',
            element: [],
          };
        }
      });
    }
  });

  newConfig.conditions.categoryColors = categoryColors;

  return newConfig;
};
