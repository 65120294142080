import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import MenuNav from '../MenuNav';

class MainNav extends React.PureComponent {
  render() {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (<MenuNav {...this.props} />);
  }
}

const mapStateToProps = (state) => ({
  root: state.get('menuElements').get('root').toJS(),
});

export default connect(
  mapStateToProps,
  {},
)(MainNav);
