import { categories, Components, options } from '../../../../utilsConfigurationSheet';
import { KpiConfigurationSheet } from '../../../../kpi/utils/configurationSheet';

// eslint-disable-next-line import/prefer-default-export
export const KpiConfigurationSheetForLinked = {
  ...KpiConfigurationSheet,
  operationType: [
    {
      name: 'operation',
      component: Components.select,
      categories: [categories.DATA],
      options: options.operation,
    },
  ],
};
