import React from 'react';
import { injectIntl } from "react-intl";

import { FormattedMessage } from "../../../../Contexts/LanguageContext/index";
import { errorFetchingRoleReset, getRolePermissions } from '../../../../services/redux/roles/actions'
import { modifyRoleSuccessFlag } from '../../../../services/redux/roles/actions';
import { clone } from 'ramda';

import TabComponent from '../../../../components/Tab';
import { getPermissions } from '../../../../services/redux/permissions/actions';
import { connect } from 'react-redux';


import Info from './Info';
import { navigate } from '@reach/router'
import './styles.scss';
import colors from '../../../../configuration/colors';
import Role from '../../../../models/Role/index';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'
import checkPermission from '../../../../components/PrivateComponent/checkPrivateComponent'


const OnEdit = ({ onClick }) => <div className="onEditBand">
    <p><FormattedMessage id="profile.edit.mode" /></p>
    <i className="uil uil-times" onClick={onClick}></i>
</div>;



class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            original: this.props.data,
            edit: false,
            role: {},
        }
    }

    componentDidMount() {
        const backendPermisssionAddRole = checkPermission(this.props.userMe, '/app/roles', 'remove-permission')
        const backendPermisssionRemoveRole = checkPermission(this.props.userMe, '/app/roles', 'add-permission')
        this.setState({permissionToAddRemoveRole: backendPermisssionAddRole && backendPermisssionRemoveRole})
        this.props.iconsColor(colors['ui-Grey1']);
        getPermissions();
        getRolePermissions(this.props.data);

        let r = new Role({ ...this.props.data });
        r.get();
        r.getRolePermissions();


        //this.setState({ original: u, rolePermissions: permissions });
    }

    componentDidUpdate(prevProps) {

        if (!this.state.original.name || JSON.stringify(this.props.roleList) !== JSON.stringify(prevProps.roleList)) {
            this.updateRoleToState();
        }

        if (prevProps.errorFetching !== this.props.errorFetching) {
            const errFromAPI = this.props.errorFetching.errors;
            if (errFromAPI) {
                const errors = {};
                errFromAPI.forEach(err => {
                    errors[err.field] = <FormattedMessage id={err.message} />;
                });

                errorFetchingRoleReset();
            }
        }

        if (this.props.modifySuccess) {
            modifyRoleSuccessFlag();
            ///this.updateRoleToState();
            let r = new Role({ ...this.props.data });
            r.get();
            r.getRolePermissions()
            //this.goBack()
        }
    }

    updateRoleToState() {
        const StoredRole = clone(this.props.roleList.find(u => u.id === this.props.data.id));
        if (StoredRole && this.state.original !== StoredRole) {
            this.setState({ original: StoredRole });
            return true;
        }
        return false;
    }

    handleOnClose = () => {
        this.setState({
            opened: !this.state.opened
        })

        this.props.onChange(this.state.opened);
    }

    editRole = () => {
        this.setState({
            edit: true,
        })
    }

    turnOffEdit = () => {
        this.setState({
            edit: false,
        })
    }

    getState = () => this.state;

    handleOnEdit = (e) => {
        this.child.handleOnEdit(e);
    }

    successfullEdit = (data) => {
        this.setState({
            role: data,
        })
    }

    render() {

        const { edit, original } = this.state;
        return (
            <div className="profile profile-roles">
                <section className="avatar">
                    <h1 className="mb-4">{original.name}</h1>
                    <div className="buttons">
                        <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/roles'} permission={'update'} >
                            <button type="button" className="btn btn-primary" onClick={!edit ? this.editRole : this.handleOnEdit}>
                                {!edit ?
                                    <FormattedMessage
                                        id="roles.update.button"
                                        defaultMessage="Update Role"
                                        description="Text for update role"
                                    />
                                    :

                                    <FormattedMessage
                                        id="roles.apply.changes.button"
                                        defaultMessage="Apply changes"
                                        description="Text for apply changes"
                                    />
                                }
                            </button>
                        </PrivateComponent>
                    </div>
                </section>
                <section>
                    <TabComponent
                        //titles={!edit ? ['Info'/* , <FormattedMessage id='roles.card.title.permissions'/> */] : ['Info']}
                        titles={['Info']}
                        onChange={this.handleOnTabsChange}>
                        <Info {...this.props} data={{ ...original }} edit={this.state.edit} onRef={ref => (this.child = ref)} changeMode={this.turnOffEdit} succeed={this.successfullEdit} permissionToAddRemoveRole={this.state.permissionToAddRemoveRole}  />
                        {/* !edit && <Permissions {...this.props} /> */}
                    </TabComponent>
                </section>

                {edit && <OnEdit onClick={this.turnOffEdit} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permissions: state.get('permissions').get('list').toJS(),
    roleList: state.get('roles').get('list').toJS(),
    allowedpermissions: state.get('permissions').get('list').toJS(),
    //allowedgroups: ['Group1', 'Group2', 'Group3', 'Group4']
    modifySuccess: state.get('roles').get('modifySuccess'),
    errorFetching: state.get('roles').get('errorFetching'),
    userMe: state.get('users').get('userMe'),

})

export default connect(
    mapStateToProps,
    {}
)(injectIntl(Profile));