import * as TYPES from './types';
import { Map, List } from 'immutable';

/** Initial State of Rules */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false
});

/** RULES REDUCER*/
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    /** INITIALIZE GET RULES FROM DB */
    case TYPES.GET_RULES_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** INITIALIZE GET RULES FROM DB */
    case TYPES.GET_RULES_ERROR:
    case TYPES.GET_RULE_ERROR:
    case TYPES.ADD_RULE_ERROR:
    case TYPES.MODIFY_RULE_ERROR:
    case TYPES.MODIFY_RULE_ENGINE_ERROR:
    case TYPES.MODIFY_RULE_PERMISSIONS_ERROR:
    case TYPES.DELETE_RULE_ERROR:
    case TYPES.MODIFY_RULE_CRON_ERROR:

      return state.set('errorFetching', payload)
        .set('fetching', false);

    /** GET ALL RULES FROM DB TO STATE */
    case TYPES.GET_RULES_SUCCESS:

      const rulesId = payload.rules.map(o => o.id);
      const rules = state
        .get('list')
        .toJS()
        .filter(o => !rulesId.includes(o.id));

      aux = state.set('total', payload.total)
      aux = aux.set('list', List([...rules, ...payload.rules]));
      aux = aux.set('fetching', false);
      return aux

    /** INITIALIZE GET RULE FROM DB */
    case TYPES.GET_RULE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    /** GET RULE FROM DB TO STATE */
    case TYPES.GET_RULE_SUCCESS:
      aux = state;
      index = aux.get('list').findIndex(u => u.id === payload.id);

      if (index === -1) {
        aux = aux.update('list', RuleList => RuleList.push(payload));
      } else {
        const rules = aux.get('list').toJS();
        rules[index] = payload;
        aux = aux.set('list', List([...rules]));
      }
      return aux.set('rule', payload)
        .merge(Map({
          fetching: false,
        }));
    
    //////// ADD AN RULE
    /** INITIALIZE ADD RULE TO DB AND STATE */
    case TYPES.ADD_RULE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** ADD NEW RULE TO STATE */
    case TYPES.ADD_RULE_SUCCESS:
      return state.update('list', RuleList => RuleList.push(payload))
        .set('fetching', false)
        .set('addSuccess', true)
        .update('total', total => total + 1);
    /** SET AS FALSE THE addSuccess STATE FLAG */
    case TYPES.ADD_RULE_SUCCESS_FLAG:
      return state.set('addSuccess', false).update('total', total => total + 1);

    //////// UPDATE AN RULE
    /** INITIALIZE UPDATE RULE TO DB */
    case TYPES.MODIFY_RULE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** UPDATE RULE IN STATE */

    case TYPES.UPDATE_RULE_ACTION_SUCCESS:
    case TYPES.DELETE_RULE_ACTION_SUCCESS:
    case TYPES.ADD_RULE_ACTION_SUCCESS:
    case TYPES.MODIFY_RULE_SUCCESS:
    case TYPES.MODIFY_RULE_ENGINE_SUCCESS:
    case TYPES.MODIFY_RULE_PERMISSIONS_SUCCESS:
    case TYPES.MODIFY_RULE_STATUS_SUCCESS:
    case TYPES.MODIFY_RULE_CRON_SUCCESS:
      
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', RuleList =>
            RuleList.splice(index, 1, payload)
          )
          : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);
      return aux;
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_RULE_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_RULE_CRON_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    //////// UPDATE AN RULE ENGINE
    /** INITIALIZE UPDATE RULE TO DB */
    case TYPES.MODIFY_RULE_ENGINE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    
    /** INITIALIZE UPDATE RULE TO DB */
    case TYPES.MODIFY_RULE_CRON_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    
    
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_RULE_ENGINE_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    //////// UPDATE AN RULE PERMISSIONS
    /** INITIALIZE UPDATE RULE TO DB */
    case TYPES.MODIFY_RULE_PERMISSIONS_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    
    
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_RULE_PERMISSIONS_SUCCESS_FLAG:
      return state.set('modifySuccess', false);


    //////// DELETE AN RULE
    /** INITIALIZE DELATION OF A RULE IN DB */
    case TYPES.DELETE_RULE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** DELETE RULE IN STATE */
    case TYPES.DELETE_RULE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      return index < 0 ?
        state.set('fetching', false) :
        state.update('list', ruleList => ruleList.delete(index))
          .set('fetching', false).update('total', total => total - 1);

    //////// RESET ERROR FETCHING TO FALSE
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_RULE_RESET:
      return state.set('errorFetching', false);

    case TYPES.CLEAR_RULES_STATE:
      return state.set('list', List([]))




    ///// ACTIONS
    case TYPES.UPDATE_RULE_ACTION_REQUEST:
    case TYPES.DELETE_RULE_ACTION_REQUEST:
    case TYPES.ADD_RULE_ACTION_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    case TYPES.UPDATE_RULE_ACTION_ERROR:
    case TYPES.DELETE_RULE_ACTION_ERROR:
    case TYPES.ADD_RULE_ACTION_ERROR:
      return state.set('errorFetching', payload).set('fetching', false);


    //// STATUS

    case TYPES.MODIFY_RULE_STATUS_REQUEST:
      return state.set('fetching', true).set('errorFetching', false)

    case TYPES.MODIFY_RULE_STATUS_ERROR:
      return state.set('errorFetching', payload).set('fetching', false);
  

    default:
      return state;
  }
};
