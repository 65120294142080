import * as TYPES from './types';
import {store} from '../store';

export const addUsersGroup = (usersGroup) => store.dispatch({
    type: TYPES.ADD_USERSGROUP_REQUEST,
    payload: usersGroup,
});

export const addUsersGroupSuccessFlag = () => store.dispatch({
    type: TYPES.ADD_USERSGROUP_SUCCESS_FLAG
});

export const updateUsersGroup = (usersGroup) => store.dispatch({
    type: TYPES.MODIFY_USERSGROUP_REQUEST,
    payload: usersGroup,
});

export const modifyUsersGroupSuccessFlag = () => store.dispatch({
    type: TYPES.MODIFY_USERSGROUP_SUCCESS_FLAG
});

export const deleteUsersGroup = (usersGroup) => store.dispatch({
    type: TYPES.DELETE_USERSGROUP_REQUEST,
    payload: usersGroup,
});

export const getUsersGroup = (usersGroup) => store.dispatch({
    type: TYPES.GET_USERSGROUP_REQUEST,
    payload: usersGroup,
});

export const getUsersGroups = () => store.dispatch({
    type: TYPES.GET_USERSGROUPS_REQUEST
});

export const addUserToUsersGroup = (usersGroup, user) => store.dispatch({
    type: TYPES.ADD_USER_TO_USERSGROUP_REQUEST,
    payload: {usersGroup, user}
});

export const deleteUserFromUsersGroup = (usersGroup, user) => store.dispatch({
    type: TYPES.DELETE_USER_FROM_USERSGROUP_REQUEST,
    payload: {usersGroup, user}
});


export const errorFetchingUsersGroupReset = () => store.dispatch({
    type: TYPES.ERRORFETCHING_USERSGROUP_RESET
});