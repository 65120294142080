import * as TYPES from './types';
import { store } from '../store';

export const addETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.ADD_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});

export const modifyETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.MODIFY_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});

export const updateETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.MODIFY_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});


export const deleteETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.DELETE_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});


export const verifyETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.VERIFY_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});

export const executeETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.EXECUTE_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});

export const getETLProcedures = (payload) => store.dispatch({
  type: TYPES.GET_ETL_PROCEDURES_REQUEST,
  payload,
});

export const getETLProcedure = (ETLProcedure) => store.dispatch({
  type: TYPES.GET_ETL_PROCEDURE_REQUEST,
  payload: ETLProcedure,
});

export const getETLExecutions = (ETLProcedure) => store.dispatch({
  type: TYPES.GET_ETL_EXECUTIONS_REQUEST,
  payload: ETLProcedure,
});

export const getMyETLProcedures = (payload) => store.dispatch({
  type: TYPES.GET_MY_ETL_PROCEDURES_REQUEST,
  payload,
});

export const getPublicETLProcedures = (payload) => store.dispatch({
  type: TYPES.GET_PUBLIC_ETL_PROCEDURES_REQUEST,
  payload,
});

export const getETLProcedureDeviceCount = () => store.dispatch({
  type: TYPES.GET_ETL_PROCEDURE_DEVICES_COUNT_REQUEST,
});

export const clearETLProcedures = () => store.dispatch({
  type: TYPES.CLEAR_ETL_PROCEDURES_STATE,
});

export const clearETLExecutions = () => store.dispatch({
  type: TYPES.CLEAR_ETL_EXECUTIONS_STATE,
});

export const updateETLProceduresState = (payload) => store.dispatch({
  type: TYPES.UPDATE_ETL_PROCEDURES_STATE,
  payload,
});
