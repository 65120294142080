/* eslint-disable import/no-cycle */
import { random } from 'lodash';
import { getColor } from '../../../../../pages/Widgets/Add/components/utils';
import { getUrnId } from '../../../../../helpers/utils';
import { getAlias } from '../../../utils';

const BarsDefaultConfiguration = {
  data: {
    type: 'real-time',
    period: '',
    sampling: '',
    startDate: '',
    endDate: '',
  },
  colors: { },
  labels: {
    xAxis: '',
    yAxis: '',
    alias: { },
  },
  conditions: { },
  trendlines: { },
  appearance: {
    orientation: 'vertical',
    legendPosition: 'top',
  },
};

export default BarsDefaultConfiguration;

export const injectBarsData = (widget, oldWidgetConfig, sources) => {
  const newConfig = { ...widget.config };
  const currentConfig = { ...widget.config };

  if (!oldWidgetConfig || oldWidgetConfig?.data.type !== newConfig.data.type) {
    newConfig.colors = {};
    newConfig.labels.alias = {};
  }

  const hasMultiSources = widget.sources.length > 1;
  widget.sources.forEach((source) => {
    source.fields.forEach((attr) => {
      const actualSource = sources ? sources.find((s) => s.id === getUrnId(source.urn)) : source;
      const name = `${actualSource.sourceId}-${attr}`;
      
      if (!newConfig.labels.alias[name]) {
        newConfig.labels.alias[name] = currentConfig.labels.alias[name] ?? getAlias(actualSource, attr, hasMultiSources);
      }
      
      if (!newConfig.colors[name]) {
        newConfig.colors[name] = currentConfig.colors[name] ?? getColor(random(0, 12));
      }

      // This is the code for the future comparator
      // else {
      //   if (!newConfig.labels.alias[attr]) newConfig.labels.alias[attr] = attr;
      //   const property = newConfig.colors[attr];
      //   if (!property) newConfig.colors[attr] = getColor(random(0, 12));
      // }
    });
  });
  return newConfig;
};
