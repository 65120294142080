/* eslint-disable import/no-cycle */
import { Map, List } from 'immutable';
import { cloneInstance } from '../../../helpers/utils';
import * as TYPES from './types';

/** Initial State of Kpis */
const initialState = Map({
  list: List([]),
  listTab: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  kpi: {},
  listSources: {},
});

/** Kpi reducer */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /** initialize DB requests */
    case TYPES.GET_KPIS_REQUEST:
    case TYPES.GET_KPI_TABLE_REQUEST:
    case TYPES.GET_KPI_REQUEST:
    case TYPES.GET_MY_KPIS_REQUEST:
    case TYPES.ADD_KPI_REQUEST:
    case TYPES.UPDATE_KPI_REQUEST:
    case TYPES.DELETE_KPI_REQUEST:
    case TYPES.EXECUTE_KPI_REQUEST:
      return state.merge(Map({
        fetching: true,
        errorFetching: null,
      }));

    case TYPES.GET_KPIS_ERROR:
    case TYPES.GET_KPI_TABLE_ERROR:
    case TYPES.GET_KPI_ERROR:
    case TYPES.GET_MY_KPIS_ERROR:
    case TYPES.ADD_KPI_ERROR:
    case TYPES.UPDATE_KPI_ERROR:
    case TYPES.DELETE_KPI_ERROR:
    case TYPES.EXECUTE_KPI_ERROR:
      return state.merge(Map({
        fetching: false,
        errorFetching: payload,
      }));

    case TYPES.UPDATE_KPI_SUCCESS:
    case TYPES.EXECUTE_KPI_SUCCESS:
      return state.merge(
        Map({
          list: state.get('list').map((r) => (r.id === payload.id ? payload : r)),
          listTab: state.get('listTab').map((r) => (r.id === payload.id ? payload : r)),
          fetching: true,
          KpiAdded: false,
          KpiDeleted: true,
          total: state.get('total'),
        }),
      );

    /** delete kpis in state */
    case TYPES.DELETE_KPI_SUCCESS:
      return state.merge(
        Map({
          list: state.get('list').filterNot((r) => r.id === payload.id),
          listTab: state.get('listTab').filterNot((r) => r.id === payload.id),
          fetching: true,
          KpiAdded: false,
          KpiDeleted: true,
          total: state.get('total') - 1,
        }),
      );

    /** kpis received from DB */
    case TYPES.GET_KPIS_SUCCESS:
      return state
        .update(
          // get current state.list and iterate using immutable
          'list', (kpis) => kpis
            // remove duplicates from state.list
            .filterNot((kpi) => (
              payload.kpis.find((i) => i.id === kpi.id)
            ))
            // concat new backend list
            .concat(payload.kpis),
        )
        // finally merge
        .merge(Map({
          fetching: false,
          total: payload.total,
        }));
    case TYPES.GET_KPI_TO_SOURCE_LIST: {
      const sources = {};
      payload.forEach((source) => {
        sources[source.id] = source;
      });
      return state
        .update('listSources', (oldList) => {
          const listCopy = cloneInstance(oldList);
          return {
            ...listCopy,
            ...sources,
          };
        });
    }

    case TYPES.GET_MY_KPIS_SUCCESS:
    case TYPES.GET_KPI_TABLE_SUCCESS:
      return state
        .update(
          // get current state.list and iterate using immutable
          'listTab', (kpis) => kpis
          // remove duplicates from state.list
            .filterNot((kpi) => (
              payload.kpis.find((i) => i.id === kpi.id)
            ))
          // concat new backend list
            .concat(payload.kpis),
        )
      // finally merge
        .merge(Map({
          fetching: false,
          total: payload.total,
        }));

    /** kpi received from DB */
    case TYPES.GET_KPI_SUCCESS: {
      let aux = state;
      const index = aux.get('list').findIndex((u) => u.id === payload.id);

      if (index === -1) {
        aux = aux.update('list', (kpiList) => kpiList.push(payload));
      } else {
        const kpis = aux.get('list').toJS();
        kpis[index] = payload;
        aux = aux.set('list', List([...kpis]));
      }

      return aux.set('kpi', payload)
        .merge(Map({
          fetching: false,
        }));
    }

    case TYPES.GET_KPI_V2: {
      return state
        .update('listSources', (oldList) => {
          const listCopy = cloneInstance(oldList);
          return {
            ...listCopy,
            [payload.id]: payload,
          };
        });
    }

    case TYPES.CLEAR_KPIS_STATE:
      return state.set('list', List([])).set('listTab', List([]));
    case TYPES.ADD_KPI_SUCCESS:
      return state
        .update('list', (kpiList) => kpiList.unshift(payload))
        .update('listTab', (kpiList) => kpiList.unshift(payload))
        .merge(Map({
          fetching: false,
          addSuccess: true,
          kpiAdded: true,
          kpiDeleted: false,
          errorFetching: false,
        }))
        .update('total', (total) => total + 1);
    case TYPES.ADD_KPI_SUCCESS_FLAG:
      return state.set('addSuccess', false);

    default:
      return state;
  }
};
