import React from 'react';
import './styles.scss';
import * as PropTypes from 'prop-types';
import { isMobile } from '../../../helpers/getBreakpoint';
import DesktopFiltersFinder from './DesktopFiltersFinder';
import MobileFiltersFinder from './MobileFiltersFinder';

class FiltersFinder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: this.props.selectedFilter || this.props.filters[0],
      textFieldValue: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedFilter !== prevProps.selectedFilter) {
      this.setState({
        selectedFilter: this.props.selectedFilter,
      });
    }
  }

  handleOnListClick = (e, opt) => {
    const { onReset, onChange } = this.props;
    const { textFieldValue } = this.state;

    this.setState(
      {
        selectedFilter: opt,
      },
      () => (textFieldValue === '' ? onReset() : onChange(textFieldValue, opt))
    );
  };

  handleOnTextFieldChange = e => {
    const { onReset, onChange } = this.props;
    const { selectedFilter } = this.state;

    const value = e.target.value;
    this.setState(
      {
        textFieldValue: value,
      },
      () => (value === '' ? onReset() : onChange(value, selectedFilter))
    );
  };

  handleOnSearch = e => {
    e.preventDefault();

    // const { textFieldValue, selectedFilter } = this.state;
    // this.props.onSearch(textFieldValue, selectedFilter)
  };

  render() {

    const { selectedFilter, textFieldValue } = this.state;



    return !isMobile() ? (
      <DesktopFiltersFinder
        {...this.props}
        selectedFilter={selectedFilter}
        textFieldValue={textFieldValue}
        onListClick={this.handleOnListClick}
        onSearch={this.handleOnSearch}
        onTextFieldChange={this.handleOnTextFieldChange}
      />
    ) : <MobileFiltersFinder {...this.props}
    selectedFilter={selectedFilter}
    textFieldValue={textFieldValue}
    onListClick={this.handleOnListClick}
    onSearch={this.handleOnSearch}
    onTextFieldChange={this.handleOnTextFieldChange} />;
  }
}

FiltersFinder.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), value: PropTypes.string })).isRequired,
};

export default FiltersFinder;
