import React, { PureComponent } from 'react';
import './styles.scss'
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../Contexts/LanguageContext';

export class SeverityTag extends PureComponent {
    render() {
        const {severity} = this.props;
        return (
            <div className={`severityTag ${severity}`}>
                <FormattedMessage id={`severityTag.${severity}`} />
            </div>
        )
    }
}

SeverityTag.defaultProps = {
    severity: 'informational'
}

SeverityTag.propTypes = {
    severity: PropTypes.oneOf(['informational', 'low', 'medium', 'high', 'critical']).isRequired,
}

export default SeverityTag
