import { userManagement } from '../../configuration/config'
import { requestAPI } from '../../helpers'
import { examplePermissions } from './exampleGenerator'

const endPoint = userManagement.urlAPI.concat(userManagement.paths.permission);

export const readPermissions = async () => requestAPI({ endPoint: endPoint, statusOK: 200 }, examplePermissions);

export const readPermission = async (permission) => {
    const statusOK = 200;
    if (!permission['id']) return { status: 400, error: 'invalid id attr' };
    let answer = await requestAPI({ endPoint: endPoint + "/" + permission.id, statusOK: statusOK }, examplePermissions);
    if (answer['status'] === statusOK)
        answer.data.permissions = await readPermissionPermissions(permission.id);
    return answer;
};

export const readPermissionPermissions = async (permission) => {
    if (!permission['id']) return { status: 400, error: 'invalid id attr' };
    return requestAPI( { endPoint: endPoint + "/" + permission.id + "/permissions", statusOK: 200 }, examplePermissions);
};

export const addPermission = async (permission) => {

    if (permission['id']) return { status: 400, error: 'invalid id attr' };
    const params = {
        endPoint: endPoint,
        statusOK: 201,
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(permission)
        }
    };
    return requestAPI(params, examplePermissions);
};

export const updatePermission = permission => {

    if (!permission['id']) return { status: 400, error: 'missing id attr' };
    const params = {
        endPoint: endPoint + "/" + permission.id,
        statusOK: 200,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify(permission)
        }
    };
    return requestAPI(params, examplePermissions);
};

export const deletePermission = permission => {

    if (!permission['id']) return { status: 400, error: 'missing id attr' };
    const params = {
        endPoint: endPoint + "/" + permission.id,
        statusOK: 204,
        requestConfig: {
            method: 'DELETE'
        }
    };
    return requestAPI(params, examplePermissions);
};
