import React from 'react';
import './styles.scss';

const AddButton = ({
  outline, onClick, minus, ...rest
}) => (
  <button type={'button'} className={`addButton${outline ? ' outline' : ''}`} onClick={onClick} {...rest}>
    <i className={`uil ${minus ? 'uil-minus' : 'uil-plus'}`} />
  </button>
);

export default AddButton;
