import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { clone } from 'ramda';
import { SelectCtr, OptionWrapper } from './components/styles';
import {
  OptionList,
  InputCombo,
  SelectedOptionWrapper,
} from './components/helpers';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';

function alphabetically(a, b, mappedBy = null) {
  if (mappedBy == null) return a < b ? -1 : a > b ? 1 : 0;

  return a[mappedBy] < b[mappedBy] ? -1 : a[mappedBy] > b[mappedBy] ? 1 : 0;
}

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      dropDownVisibility: 'hidden',
      selectedOptions: this.props.selectedOptions,
      filteredList: this.props.data,
      inputValue: '',
      mappedBy: this.props.mappedBy,
      coords: this.getCoords(),
    };
  }

  static propTypes = {
    /** The display status of the dropdown list */
    dropDownVisibility: PropTypes.string,

    /** Options currently selected */
    selectedOptions: PropTypes.arrayOf(PropTypes.object),

    /** Id of the multiselect */
    id: PropTypes.string,

    /** Name of the multiselect. This is required. */
    name: PropTypes.string.isRequired,

    /** All other options not currently selected. Al inicio, todas las opciones del multiselect. */
    data: PropTypes.arrayOf(PropTypes.object).isRequired,

    /** A placeholder for the filter input */
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    name: 'InputName',
    id: 'inputId',
    dropDownVisibility: 'hidden',
    placeholder: <FormattedMessage id="field.multiselect.placeholder" />,
  };

  parentElementRef = null;
  selectRef = null;

  componentDidMount() {
    window.addEventListener('scroll', () => this.setState({ coords: this.getCoords() }), true);
    window.addEventListener('resize', () => this.setState({ coords: this.getCoords() }), true);
    this.setState({ coords: this.getCoords() });
  }

  getCoords = () => (this.selectRef ? ReactDOM.findDOMNode(this.selectRef).getBoundingClientRect() : null)

  componentWillMount() {
    this.setState(
      {
        data: this.props.data,
        name: this.props.name,
        selectedOptions: this.props.selectedOptions,
        filteredList: this.props.data,
      },
      this.calcAvailableOptions,
    );
  }

  componentWillReceiveProps(newProps) {
    if (this.state.filteredList !== newProps.data) {
      this.setState(
        {
          data: newProps.data,
          filteredList: newProps.data,
        },
        this.calcAvailableOptions,
      );
    }
  }

  handleLister = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (
      this.state.dropDownVisibility === 'visible'
      && !path.find((p) => p.classList && p.classList.contains('dropDown'))
    ) {
      this.setState({
        dropDownVisibility: 'hidden',
      });
      window.removeEventListener('click', this.handleLister, true);
    }
  };

  handleEvent = () => {
    window.addEventListener('click', this.handleLister, true);
  };

  calcAvailableOptions = () => {
    const { selectedOptions, data } = this.state;
    if (selectedOptions && selectedOptions.length > 0) {
      const options = selectedOptions;
      const newData = clone(data);

      const { equalsBy } = this.props;

      if (equalsBy !== undefined) {
        options.forEach((res) => {
          newData.splice(newData.findIndex((i) => i[equalsBy] === res[equalsBy]), 1);
        });
      } else {
        options.forEach((res) => {
          newData.splice(newData.findIndex((o) => JSON.stringify(o) === JSON.stringify(res)), 1);
        });
      }

      this.setState({
        data: newData,
        filteredList: newData,
      });
    }
  };

  onArrowButtonClick = () => {
    const status = this.state.dropDownVisibility === 'hidden' ? 'visible' : 'hidden';

    if (status === 'visible') this.handleEvent();
    else window.removeEventListener('click', this.handleLister, true);

    this.setState({
      dropDownVisibility: status,
    });
  };

  onMouseLeave = () => {
    this.setState({
      dropDownVisibility: 'hidden',
    });
  };

  onRemoveButtonClick = () => {
    this.setState({
      selectedOptions: [],
      data: this.props.data,
      filteredList: this.props.data,
    });
  };

  onClickItem = (option) => {
    const selectedData = clone(this.state.selectedOptions);
    const nonSelectedData = clone(this.state.data);

    selectedData.push(option);
    nonSelectedData.splice(nonSelectedData.indexOf(option), 1);

    this.setState({
      dropDownVisibility: 'hidden',
      selectedOptions: selectedData,
      data: nonSelectedData,
      filteredList: nonSelectedData,
      inputValue: '',
    });
  };

  onClickChip = (option, i) => {
    const selectedData = clone(this.state.selectedOptions);
    const nonSelectedData = clone(this.state.data);
    selectedData.splice(i, 1);
    nonSelectedData.push(option);
    nonSelectedData.sort((a, b) => alphabetically(a, b, this.state.mappedBy));

    this.setState({
      selectedOptions: selectedData,
      data: nonSelectedData,
      filteredList: nonSelectedData,
    });
  };

  filterOptions = (e) => {
    const currentValue = e.target.value;
    const filteredList = [];
    const currentOptionList = clone(this.state.data);

    if (currentValue === '') {
      this.setState({
        filteredList: currentOptionList,
      });
    }
    const { mappedBy } = this.state;
    if (mappedBy !== null && mappedBy !== undefined) {
      this.state.data.forEach((option) => {
        if (option[mappedBy].toUpperCase().includes(currentValue.toUpperCase())) filteredList.push(option);
      });
    } else {
      this.state.data.forEach((option) => {
        if (option.toUpperCase().includes(currentValue.toUpperCase())) filteredList.push(option);
      });
    }

    this.setState({
      filteredList,
      dropDownVisibility: 'visible',
      inputValue: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.inputValue !== '') {
      if (this.state.filteredList.length > 0) {
        const selectedData = clone(this.state.selectedOptions);
        const nonSelectedData = clone(this.state.data);

        this.state.filteredList.forEach((res) => {
          nonSelectedData.splice(nonSelectedData.indexOf(res), 1);
          selectedData.push(res);
        });

        this.setState(
          {
            data: nonSelectedData,
            filteredList: nonSelectedData,
            selectedOptions: selectedData,
            dropDownVisibility: 'hidden',
            inputValue: '',
          },
          () => this.props.onChange('', this.state.name, this.state.selectedOptions),
        );
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOptions !== this.state.selectedOptions) {
      this.props.onChange('', this.state.name, this.state.selectedOptions);
    }

    if (
      this.props.selectedOptions !== prevProps.selectedOptions
      && this.props.selectedOptions !== this.state.selectedOptions
    ) {
      this.setState(
        {
          selectedOptions: this.props.selectedOptions,
        },
        this.calcAvailableOptions,
      );
    }

    if (this.props.data !== prevProps.data) {
      this.setState(
        {
          data: this.props.data,
          filteredList: this.props.data,
        },
        this.calcAvailableOptions,
      );
    }
  }

  getHeight = () => {
    const gap = window.innerHeight + window.pageYOffset - this.state.coords.bottom;
    return gap < 300 ? gap : null;
  }

  render() {
    const { id, name, placeholder } = this.props;
    const { mappedBy } = this.state;

    return (
      <SelectCtr ref={(node) => (this.selectRef = node)} id={id} name={name} className="multiselect">
        <SelectedOptionWrapper
          {...this.props}
          removeEvent={this.onRemoveButtonClick}
          openEvent={this.onArrowButtonClick}
          removeChipEvent={this.onClickChip}
          data={this.state.selectedOptions}
          onMainClick={this.onArrowButtonClick}
          mappedBy={mappedBy}
        />
        {this.state.coords !== null && ReactDOM.createPortal(<OptionWrapper
          className={this.state.dropDownVisibility}
          tabIndex="-1"
          ref={(node) => (this.parentElementRef = node)}
          onMouseLeave={(e) => this.onMouseLeave(e)}
          top={this.state.coords.top + window.pageYOffset}
          left={this.state.coords.left}
          width={this.selectRef.clientWidth}
          listHeight={this.getHeight()}
        >
          <OptionList
            {...this.props}
            itemClick={this.onClickItem}
            data={this.state.filteredList}
            mappedBy={mappedBy}
          >
            <InputCombo
              onChange={this.filterOptions}
              type="text"
              value={this.state.inputValue}
              onKeyPress={this.handleSubmit}
              placeholder={placeholder}
            />
          </OptionList>
        </OptionWrapper>, document.body)}
      </SelectCtr>
    );
  }
}

export default MultiSelect;
