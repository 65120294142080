import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import { ButtonCustom } from '../../../../../../elements/Button';
import Field from '../../../../../../elements/Field';
import { ReactComponent as OffStatus } from '../../../../../../configuration/icons/svg/ico-off-status.svg';
import { ReactComponent as OnStatus } from '../../../../../../configuration/icons/svg/ico-on-status.svg';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import ModalCommands from '../modalCommands';
import WidgetCommandsGroup from '../WidgetCommandsGroup';
import ButtonOnOffProfile from './buttonOnOffProfile';

const WidgetCommandsAttributes = ({
  handleCommandGroup, intl, sources, handleChange, commandsToShow, handleDeleteIndividualCommand,
  addCommand, stateButton, availableCommands, errors, removeCommand, stateIndex,
  getAllAttributes, commandsGroups, handleEditCommandsGroup, handleDeleteCommandsGroup, isProfile,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [groupStates] = useState(stateButton.state ? 'on' : 'off');
  const [commandsData, setCommandsData] = useState();
  const [showRow, setShowRow] = useState(true);
  const [simpleCommands, setSimpleCommands] = useState(stateButton.commands.filter((c) => !c.groupId));

  useEffect(() => {
    setSimpleCommands(stateButton.commands.filter((c) => !c.groupId));
  }, [stateButton]);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const acceptCommandsModal = (groupCommands, state, editCommands, toggleEditModal) => {
    const stateLocal = state ? 'on' : 'off';
    const { commands } = groupCommands;
    if (editCommands) {
      handleEditCommandsGroup(groupCommands, state);
      toggleEditModal();
    } else {
      handleCommandGroup(commands, stateLocal);
      toggleShowModal();
    }
  };

  const deleteCommandGroup = (group, state) => {
    const index = commandsGroups[state].findIndex((c) => c.groupId === group.groupId);
    handleDeleteCommandsGroup(group, state, index);
  };

  const handleCommandsChange = (data) => {
    setCommandsData(data);
  };

  const handleShowRow = () => {
    setShowRow(!showRow);
  };

  const numberOfCommands = () => {
    const countStateButton = stateButton.commands.filter((c) => c.sourcesId.length && !c.groupId).length;
    let countCommandsIntoGroups = 0;
    Object.keys(commandsGroups[groupStates]).forEach((index) => {
      countCommandsIntoGroups += commandsGroups[groupStates][index].sourcesId.length;
    });
    return countCommandsIntoGroups + countStateButton;
  };

  const getAlias = (name, value) => {
    const commands = [];
    let final = {
      alias: value,
      value,
    };
    const arraySplit = value.split(' ');
    const valueSplit = arraySplit.length > 1 ? arraySplit[1] : arraySplit[0];    
    sources.forEach((source) => {
      const command = source.commands.filter((c) => c.name === name && c.available_commands.length > 0);
      if (command?.length > 0) {
        command[0].available_commands.forEach((c) => {
          if (c.value === valueSplit || c.value === value) commands.push(c.name);
        });
      }
    });
    if (commands.length > 0) {
      final = {
        alias: commands[0]?.toString(),
        value: valueSplit,
      };
    } else if (arraySplit.length === 2) {
      final = {
        alias: valueSplit,
        value: valueSplit,
      };
    }
    return final;
  };

  const getOptions = (row) => {
    let options = [];
    if (commandsToShow.length) {
      options = commandsToShow.map((c) => (
        {
          value: c,
          name: c.name,
        }
      ));
    }
    options = sources.find((source) => source.id === row)?.commands.map((c) => (
      {
        value: c,
        name: c.name,
      }
    ));
    return options || [];
  };

  const getValueAttribute = (row) => {
    if (commandsToShow.length) {
      return commandsToShow.find((c) => c.name === row.commandName);
    }
    return sources.find((source) => source.id === row.sourcesId[0])?.commands.find(
        (c) => c.name === row.commandName,
      ) || '';
  };

  return (
    <>
      { showModal && (
        <ModalCommands
          sources={sources}
          getAllAttributes={getAllAttributes}
          state={stateButton.state}
          toggleShowModal={toggleShowModal}
          acceptCommandsModal={acceptCommandsModal}
          handleCommandsChange={handleCommandsChange}
          getAlias={getAlias}
        />
      )}
      <Row className={`${stateButton.state ? 'align-items-center' : ''} ${isProfile ? 'mt-2' : ''}`}>
        {!isProfile ? (
          <Col sm={2} className="pr-0 offset-sm-2 align-content-start">
            <Col className="pb-4 offset-6 text-commands">
              {!stateButton.state && (
              <span>
                <FormattedMessage id="widgets.wizard.sendCommand.state" />
              </span>
              )}
            </Col>
            <Row>
              <Col className="px-4 d-flex justify-content-end">
                <Row onClick={handleShowRow}>
                  <i className={`uil uil-angle-${showRow ? 'down' : 'up'} i blockToggleIcon`} />
                </Row>
              </Col>
              <Col>
                <Row>
                  {stateButton.state ? <OnStatus /> : <OffStatus />}
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          <ButtonOnOffProfile
            stateButton={stateButton}
            commandsNumber={numberOfCommands}
            showRow={showRow}
            handleShowRow={handleShowRow}
          />
        )}
        {showRow ? (
          <>
            <Col sm={`${isProfile ? 12 : 7}`} className={`${groupStates === 'on' && !isProfile ? 'pt-5' : ''}`}>
              <Row>
                <Col sm={`${isProfile ? 10 : 9}`} className="d-flex">
                  <Row className={`w-100 ${!isProfile ? 'mb-4' : ''}`}>
                    <Col 
                      sm={`${isProfile ? 8 : 9}`} 
                      className="pr-0 d-flex align-items-center"
                    >
                      <Row className="w-100">
                        <Col sm={6} className="text-uppercase text-commands">
                          <span>
                            <FormattedMessage id="widgets.wizard.sendCommand.device" />
                          </span>
                        </Col>
                        <Col sm={6} className="text-uppercase text-commands">
                          <span>
                            <FormattedMessage id="widgets.wizard.sendCommand.command" />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={`${isProfile ? 4 : 3}`} className="pl-0 text-uppercase text-commands d-flex align-items-center">
                      <span>
                        <FormattedMessage id="widgets.wizard.sendCommand.value" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div />
              {commandsGroups && commandsGroups[groupStates]
              && Object.keys(commandsGroups[groupStates]).length > 0 && (
                Object.keys(commandsGroups[groupStates]).map((command, index) => (
                  <Row className={`${isProfile && index === 0 ? 'mt-4' : ''}`}>
                    <WidgetCommandsGroup
                      commandName={commandsGroups[groupStates][index]?.commandName}
                      command={commandsGroups[groupStates][index]}
                      state={groupStates}
                      sources={sources}
                      getAllAttributes={getAllAttributes}
                      acceptCommandsModal={acceptCommandsModal}
                      commandsToShow={commandsData}
                      deleteCommandGroup={deleteCommandGroup}
                      handleChange={handleChange}
                      getAlias={getAlias}
                      handleDeleteIndividualCommand={handleDeleteIndividualCommand}
                      isProfile={isProfile}
                    />
                  </Row>
                ))
              )}
              <div>
                {simpleCommands?.map((row, index) => (
                  <Row key={`${row.sourcesId}-${row.commandName}-${stateIndex}-${index}`}>
                    <Col
                      sm={`${isProfile ? 10 : 9}`}
                      className={`d-flex ${isProfile && 'spaceButton'} ${index === 0 ? 'mt-3' : ''}`}
                    >
                      <Row className="w-100">
                        <Col sm={`${isProfile ? 8 : 9}`} className="pr-0">
                          <Row className="w-100">
                            <Col sm={6} className={`fieldCol ${isProfile && 'pr-1'}`}>
                              <Field
                                name="device"
                                placeholder={intl.formatMessage({
                                  id: 'widgets.wizard.sendCommand.attribute.placeholder',
                                })}
                                type="select"
                                options={sources.map((source) => (
                                  {
                                    value: source,
                                    name: source.name,
                                  }
                                ))}
                                helperText={errors?.source?.find((d) => d.index === index
                                && d.state === stateButton.state)?.message}
                                error={errors?.source?.find((d) => d.index === index
                                && d.state === stateButton.state)?.error}
                                value={sources.find((source) => source.id === row.sourcesId[0])}
                                onChange={(name, value) => handleChange(name,
                                  value,
                                  index,
                                  stateButton.state)}
                              />
                            </Col>
                            <Col sm={6} className={`fieldCol ${isProfile && 'pr-1'}`}>
                              <Field
                                name="attribute"
                                placeholder={intl.formatMessage({
                                  id: 'widgets.wizard.sendCommand.command.selector.placeholder',
                                })}
                                type="select"
                                options={getOptions(row.sourcesId[0])}
                                helperText={errors?.attribute?.find((d) => d.index === index
                                && d.state === stateButton.state)?.message}
                                error={errors?.attribute?.find((d) => d.index === index
                                && d.state === stateButton.state)?.error}
                                onChange={(name, value) => handleChange(name,
                                  value,
                                  index,
                                  stateButton.state)}
                                value={getValueAttribute(row)}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={`${isProfile ? 4 : 3}`} className="commandCol fieldCol pl-0">
                          <Field
                            name="command"
                            placeholder={intl.formatMessage({
                              id: 'widgets.wizard.sendCommand.command.placeholder',
                            })}
                            type="autoComplete"
                            availableCommands={availableCommands
                              .find((c) => c.state === stateButton.state)?.commands[index]}
                            helperText={errors?.command?.find((d) => d.index === index
                            && d.state === stateButton.state)?.message}
                            error={errors?.command?.find((d) => d.index === index
                            && d.state === stateButton.state)?.error}
                            onChange={(name, value) => handleChange(name,
                              value,
                              index,
                              stateButton.state,
                              availableCommands)}
                            value={getAlias(row.commandName, row.value).alias}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={2} className="d-flex align-self-center">
                      <Row>
                        {stateButton.commands.filter((c) => !c.groupId).length > 1
                && (
                <Col sm={6} className="px-0 align-self-center">
                  <ButtonCustom
                    type="circular"
                    name="minus"
                    onClick={() => removeCommand(stateButton.state, index)}
                  />
                </Col>
                )}
                        {(stateButton.commands.filter((c) => !c.groupId).length < 1
                        || stateButton.commands.filter((c) => !c.groupId).length - 1 === index)
                && (
                  <Col sm={6} className="px-0 align-self-center">
                    <ButtonCustom
                      type="circular"
                      name="plus"
                      onClick={() => addCommand(index, stateButton.state, true)}
                    />
                  </Col>
                )}
                      </Row>
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
          </>
        ) : (
          !isProfile && (
            <Col sm={5}>
              <div className="foldedCommands">
                <p className={`${stateButton.state ? 'onState' : 'offState'} commandGroupLine`}>
                  <span className="commandLabelList">
                    <FormattedMessage id="widgets.wizard.sendCommand.listCommands" />
                  </span>
                  <span className="ml-2 commandNumberOfCommands">
                    {numberOfCommands()}
                  </span>
                </p>
              </div>
            </Col>
          )
        )}
      </Row>
      <Row className={`${!isProfile && 'justify-content-center'} mb-4`}>
        {showRow && (
          <Col sm={9} className={`${!isProfile && 'offset-sm-5'} fieldCol`}>
            <Button onClick={toggleShowModal} className="p-0 groupCommandBtn">
              <FormattedMessage id="widgets.wizard.sendCommand.modal.commandsInGroup" />
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default injectIntl(WidgetCommandsAttributes);
