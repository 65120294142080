import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  fork
} from 'redux-saga/effects';
import Dashboard from '../../../models/Dashboard';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as API from './../../api/dashboards';

const parseWidgetsIdOfDashboard = (dashboard) => {
  dashboard.widgets = dashboard.widgets.map(o => o.id ? o.id : o);
  return dashboard
};

/**
 * Actions for WATCHERS
 * */

// Try to recover all dashboard from DB
export function* getDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(
      API.readDashboards,
      payload.page,
      payload.size,
      payload.filters
    );

    if (response.status === 200) {
      const dashboards = response.data.objects.map(dashboard => new Dashboard(dashboard));
      yield put({ type: TYPES.GET_DASHBOARDS_SUCCESS, payload: { total: response.data.total, dashboards } });
    } else {
      yield put({ type: TYPES.GET_DASHBOARDS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DASHBOARDS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover a dashboard from DB
export function* getPublicDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(
      API.readPublicDashboard,
      payload.page,
      payload.size,
      payload.filters
    );
    if (response.status === 200) {
      const dashboards = response.data.objects.map(
        dashboard => new Dashboard(dashboard)
      );
      yield put({
        type: TYPES.GET_PUBLIC_DASHBOARDS_SUCCESS,
        payload: { total: response.data.total, dashboards }
      });
    } else {
      yield put({ type: TYPES.GET_PUBLIC_DASHBOARDS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_PUBLIC_DASHBOARDS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover a dashboard from DB
export function* getMineDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(API.readMineDashboard, payload.page,
      payload.size,
      payload.filters);
    if (response.status === 200) {
      const dashboards = response.data.objects.map(
        dashboard => new Dashboard(dashboard)
      );
      yield put({
        type: TYPES.GET_MINE_DASHBOARDS_SUCCESS,
        payload: { total: response.data.total, dashboards }
      });
    } else {
      yield put({ type: TYPES.GET_MINE_DASHBOARDS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MINE_DASHBOARDS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

const savexDataAccessTokenInLocalStorage = ((data) => {
  localStorage.setItem('x_data_access', data);
});

// Try to recover a dashboard from DB
export function* getDashboardThroughAPI({ payload }) {
  const { isPublicDashboard } = payload;
  const endpoint = isPublicDashboard ? API.readSharedDashboard : API.readDashboard;
  try {
    const response = yield call(endpoint, payload);
    if (response.status === 200) {
      if (isPublicDashboard) {
        yield call(savexDataAccessTokenInLocalStorage, response.data.xDataAccess);
      }
      const dashboardFromDB = new Dashboard(response.data.object);
      yield put({ type: TYPES.GET_DASHBOARD_SUCCESS, payload: dashboardFromDB });
    } else {
      yield put({ type: TYPES.GET_DASHBOARD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_DASHBOARD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to create dashboard in DB
export function* addDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(API.addDashboard, payload);
    if (response.status === 201) {
      const dashboardFromDB = new Dashboard(response.data.object);
      yield put({ type: TYPES.ADD_DASHBOARD_SUCCESS, payload: dashboardFromDB });
    } else {
      yield put({ type: TYPES.ADD_DASHBOARD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_DASHBOARD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update dashboard in DB
export function* updateDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(API.updateDashboard, parseWidgetsIdOfDashboard(payload));
    if (response.status === 200) {
      yield put({ type: TYPES.MODIFY_DASHBOARD_SUCCESS, payload: new Dashboard(response.data.object) });
    } else {
      yield put({ type: TYPES.MODIFY_DASHBOARD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_DASHBOARD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update dashboard in DB
export function* updateDashboardsLayoutThroughAPI({ payload }) {
  try {
    const response = yield call(API.updateDashboard, parseWidgetsIdOfDashboard(payload));
    if (response.status === 200) {
      yield put({ type: TYPES.MODIFY_DASHBOARD_LAYOUT_SUCCESS, payload: response.data.object.layoutProperties });
    } else {
      yield put({ type: TYPES.MODIFY_DASHBOARD_LAYOUT_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_DASHBOARD_LAYOUT_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete dashboard in DB
export function* deleteDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(API.deleteDashboard, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_DASHBOARD_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_DASHBOARD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_DASHBOARD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to clone referenced dashboard in DB
export function* cloneRefDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(API.cloneRefDashboard, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.CLONE_REF_DASHBOARD_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.CLONE_REF_DASHBOARD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.CLONE_REF_DASHBOARD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


// Try to clone no referenced dashboard in DB
export function* cloneNoRefDashboardsThroughAPI({ payload }) {
  try {
    const response = yield call(API.cloneNoRefDashboard, payload);
    if (response.status === 204) {
      yield put({ type: TYPES.CLONE_NOREF_DASHBOARD_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.CLONE_NOREF_DASHBOARD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.CLONE_NOREF_DASHBOARD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}



/**
 * WATCHERS
 */
// Watcher looking for GET_DASHBOARDS_REQUEST
function* watcherGetDashboards() {
  yield takeLatest('GET_DASHBOARDS_REQUEST', getDashboardsThroughAPI);
}

function* watcherGetPublicDashboards() {
  yield takeLatest('GET_PUBLIC_DASHBOARDS_REQUEST', getPublicDashboardsThroughAPI);
}

function* watcherGetMineDashboards() {
  yield takeLatest('GET_MINE_DASHBOARDS_REQUEST', getMineDashboardsThroughAPI);
}

// Watcher looking for GET_DASHBOARD_REQUEST
function* watcherGetDashboard() {
  yield takeEvery('GET_DASHBOARD_REQUEST', getDashboardThroughAPI);
}

// Watcher looking for ADD_DASHBOARD_REQUEST
function* watcherAddDashboards() {
  yield takeEvery('ADD_DASHBOARD_REQUEST', addDashboardsThroughAPI);
}

// Watcher looking for MODIFY_DASHBOARD_REQUEST
function* watcherUpdateDashboards() {
  yield takeLatest('MODIFY_DASHBOARD_REQUEST', updateDashboardsThroughAPI);
}

// Watcher looking for MODIFY_DASHBOARD_REQUEST
function* watcherUpdateDashboardsLayout() {
  yield takeLatest('MODIFY_DASHBOARD_LAYOUT_REQUEST', updateDashboardsLayoutThroughAPI);
}

// Watcher looking for DELETE_DASHBOARD_REQUEST
function* watcherDeleteDashboards() {
  yield takeEvery('DELETE_DASHBOARD_REQUEST', deleteDashboardsThroughAPI);
}

function* watcherCloneRefDashboard() {
  yield takeEvery('CLONE_REF_DASHBOARD_REQUEST', cloneRefDashboardsThroughAPI);
}

function* watcherCloneNoRefDashboard() {
  yield takeEvery('CLONE_NOREF_DASHBOARD_REQUEST', cloneNoRefDashboardsThroughAPI);
}





// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetDashboards),
    fork(watcherGetPublicDashboards),
    fork(watcherGetMineDashboards),
    fork(watcherGetDashboard),
    fork(watcherAddDashboards),
    fork(watcherUpdateDashboards),
    fork(watcherDeleteDashboards),
    fork(watcherCloneRefDashboard),
    fork(watcherCloneNoRefDashboard),
    fork(watcherUpdateDashboardsLayout)
  ]);
}


