import React from 'react';
import ModalHOC from '../../../../elements/Modal';
import ResetModalContent from './resetContent';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

const ButtonOpen = ({ showModal, props }) => (
  <li onClick={showModal}>
    <FormattedMessage
      id="users.options.reset.password"
      defaultMessage="Reset Password"
      description="Title of reset password"
    />
    <i className="uil uil-arrow-right" />
  </li>
);

const ResetUserPasword = ModalHOC({
  ButtonOpen,
  ModalContent: ResetModalContent,
});


export default ResetUserPasword;
