import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import './styles.scss';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import IconSuccess from '../../../configuration/icons/svg/icon-success-check.svg';
import Loading from '../../../elements/Loading';

class Success extends React.Component {
  getError = (error) => {
    let finalError = 'Error ';
    error.errors
      ? error.errors.forEach((e) => {
        finalError += e.message.concat('. ');
      })
      : error.status
        ? (finalError += error.status)
        : (finalError = undefined);
    return finalError;
  };

  render() {
    const {
      icon,
      title,
      content,
      className,
      error,
      lead,
      showCheck,
      fetching,
    } = this.props;

    return (
      <>
        {fetching ? (
          <Loading />
        ) : (
          <Container fluid className={`successWrapper ${className}`}>
            <Row className="text-center">
              <Col sm={{ size: 8, offset: 2 }}>
                {!error && (
                  <div className="successIcon">
                    {showCheck && (
                      <img className="iconCheck" src={IconSuccess} />
                    )}
                    <img src={icon} alt="user management" />
                  </div>
                )}
                <h1>
                  {!error ? (
                    title
                  ) : (
                    <FormattedMessage id="Wizard.error.in.process" />
                  )}
                </h1>
                {!error && lead && <p className="lead">{lead}</p>}
                {error && this.getError(error)}
                {!error && content}
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

Success.defaultProps = {
  icon: '',
  title: 'This is a title',
  showCheck: false,
  fetching: false,
};

Success.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  lead: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.oneOfType([PropTypes.any]),
  showCheck: PropTypes.bool,
  fetching: PropTypes.bool,
};

export default injectIntl(Success);
