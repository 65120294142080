// --------------------- [IMPORTS] ----------------------- //
import React, {
  useEffect, memo, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DashboardMenuTitle from './components/DashboardMenuTitle';
import ShowWidgets from './components/showWidgets';
import { ProfileDashboardComponent } from './profiles';
import CreateWidgetsV2 from '../../Widgets/Add/AddWizardV2';
import { getDashboard } from '../../../services/redux/dashboards/actions';
import { havePermissionToEdit } from '../../../helpers/utils';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';
// --------------------- [IMPORTS] ----------------------- //

// --------------------- [MAIN COMPONENT] ----------------------- //
function Show(props) {
  // --------------------- [VARIABLES & STATE] ----------------------- //
  const { id, dashboard, layouts } = props;
  const [profileOpened, setProfileOpened] = useState(false);
  const [grid, setGrid] = useState({ isResizable: false, isDraggable: false });
  const permissionToEdit = useMemo(() => havePermissionToEdit(
    dashboard.permissions_policy, dashboard.owner,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dashboard.permissions_policy]);
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  // --------------------- [VARIABLES & STATE] ----------------------- //

  // --------------------- [LIFECYCLES] ----------------------- //
  useEffect(() => {
    getDashboard({ id });
    setLoadingDashboard(true);
  }, [id]);

  useEffect(() => {
    setLoadingDashboard(false);
  }, [dashboard])
  // --------------------- [LIFECYCLES] ----------------------- //

  // --------------------- [AUX METHODS] ----------------------- //
  const handleResizable = () => {
    setGrid({
      isResizable: !grid.isResizable,
      isDraggable: !grid.isDraggable,
    });
  };
  // --------------------- [AUX METHODS] ----------------------- //

  // --------------------- [RETURN] ----------------------- //
  return (
    <>
      <DashboardMenuTitle
        title={dashboard.name ?? ''}
        date={moment(dashboard.updatedAt)
          .startOf('minute')
          .fromNow()}
        isResizable={grid.isResizable}
        handleResizable={handleResizable}
        dashboard={dashboard}
        entity="dashboard"
        clickView={() => setProfileOpened(true)}
        permissionToEdit={permissionToEdit}
      />

      {profileOpened && permissionToEdit && (
        <ProfileDashboardComponent
          onChange={(opened) => setProfileOpened(opened)}
          data={dashboard}
          profileType="edit"
        />
      )}

      {!loadingDashboard && (
        <ShowWidgets
          permissionToEdit={permissionToEdit}
          isResizable={grid.isResizable}
          isDraggable={grid.isDraggable}
        />
      )}

      {permissionToEdit && (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/widget"
        permission="create"
      >
        <CreateWidgetsV2 dashboard={{ ...dashboard, layoutProperties: layouts }} />
      </PrivateComponent>
      )}
    </>
  );
  // --------------------- [RETURN] ----------------------- //
}

// --------------------- [REDUX] ----------------------- //
const mapStateToProps = (state) => ({
  dashboard: state.get('dashboards').get('readDashboard'),
  layouts: state.get('dashboards').get('currentLayouts'),
});
// --------------------- [REDUX] ----------------------- //

export default memo(connect(mapStateToProps)(Show));
// --------------------- [MAIN COMPONENT] ----------------------- //
