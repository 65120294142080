import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { getPermissions } from '../../../services/redux/permissions/actions';
import CommonView from '../../../pages/CommonView';
import ConfigureDashboard from './components/ConfigureDashboard';
import ConfigureLink from './components/ConfigureLink';
import { withWizardStep } from '../../../Contexts/WizardStepContext';

export class ConfigureAccess extends CommonView {
  constructor(props) {
    super({ props });

    const { newEntity, errors } = this.props;

    this.state = {
      name: newEntity.name,
      type: newEntity.type,
      errors,
      onValidation: false,
      resource: newEntity.resource || '',
    };

    this.props.setTitles({ lead: <FormattedMessage id={`menuElement.configure.${newEntity.type.toLowerCase()}.lead`} /> });
  }

  parseLink = (resource, type) => {
    const mainSplit = resource.split('://');
    const hostSplit = mainSplit[1].split('/')[0];
    const schema = mainSplit[0];
    const host = mainSplit[1].split('/')[0].split(':')[0];
    const path = mainSplit[1].split(hostSplit)[1];
    const port = mainSplit[1].split('/')[0].split(':')[1];

    return {
      link: {
        schema: schema || '',
        host: host || '',
        port: port || '',
        path: path || '',
        query: '',
      },
      type,
    };
  };

  saveMenuElement = () => {
    this.setErrors({});
    const { resource, type } = this.state;

    if (this.state.resource !== '' || this.state.resource.path !== '') {
      if (type === 'EXTERNAL' || type === 'KNOWAGE') {
        const pattern = new RegExp('https?://.+');
        if (pattern.test(resource)) {
          this.props.onContinue({ resource: this.parseLink(resource, type) });
        } else {
          this.setState({
            errors: {
              link: (
                <FormattedMessage id="menuElement.wizard.validation.notValidURL" />
              ),
            },
          });
        }
      } else {
        this.props.onContinue({ resource });
      }
    } else {
      this.props.onError(
        <FormattedMessage id="menuElement.wizard.validation.noDashboardSelected" />,
      );
    }
  };

  validate = () => {
    this.saveMenuElement();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach((err) => {
          errors[err.field] = <FormattedMessage id={err.message} />;
        });
        this.setErrors(errors);
      }
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleOnChange = (entity) => this.setState({ ...entity });

  nextStep = () => {
    if (!this.state.onValidate) {
      this.setState({ onValidate: true });
      this.props.onContinue({});
    }
  };

  loadComponent = () => {
    const { type } = this.state;

    switch (type) {
      case 'DASHBOARD':
        return (
          <ConfigureDashboard
            newEntity={this.props.newEntity || this.state}
            onChange={this.handleOnChange}
          />
        );
      case 'EXTERNAL':
      case 'KNOWAGE':
        return (
          <ConfigureLink
            newEntity={this.props.newEntity || this.state}
            onChange={this.handleOnChange}
            type={type}
            errors={this.state.errors.link}
          />
        );
      case 'PARENT':
        return <>{this.nextStep()}</>;
      default:
        return null;
    }
  };

  render() {
    return this.loadComponent();
  }
}

const mapStateToProps = (state) => ({
  notify: state.get('notify'),
});

export default connect(mapStateToProps, { getPermissions })(
  injectIntl(withWizardStep(ConfigureAccess)),
);
