import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import styled from 'styled-components';
import Icon from '../../elements/Icons';
import getBreakpoint, { isMobile } from '../../helpers/getBreakpoint';
import { navBarHeightForMobile, navBarHeight } from '../../configuration/variables';

const getWidth = ({ maxWidth, mobile }) => {
  if (maxWidth) {
    return `width: ${maxWidth}vw!important;`;
  }
  if (mobile) {
    return 'width: 100vw!important;';
  }
  return '';
};

const ProfileWrapper = styled.div`
  ${(props) => (props.preserveNavBar
    ? `height: calc(100vh - ${
      isMobile() ? navBarHeightForMobile : navBarHeight
    }px)!important; top: ${
      isMobile() ? navBarHeightForMobile : navBarHeight
    }px!important;`
    : 'height: 100vh;')}
  .closeIcon,
  .expandIcon,
  .compressIcon {
    ${(props) => props.color && `color: ${props.color}!important`}
  }

  .expandIcon,
  .compressIcon {
    ${(props) => props.mobile && 'visibility: hidden'}
  }
  .card {
    ${(props) => (props.expanded ? 'width: 80vw!important' : `width: ${props.width || '40'}vw!important`)}    
      ${(props) => getWidth(props)}
  }

  .closeIcon {
    ${(props) => (props.reverseCloseIcon ? 'left: unset!important; right: 22px;' : '')}
  }
`;

const WithDrawer = (Component) => {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);

      const { opened } = this.props;

      this.state = {
        opened: opened || false,
        expanded: false,
      };

      this.lightboxContainer = React.createRef();
    }

    componentDidMount() {
      document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
      document.body.style.overflow = 'visible';
    }

    handleOnClose = () => {
      const { opened } = this.state;
      const { onChange } = this.props;
      this.setState({
        opened: !opened,
      });

      onChange(opened);
    };

    handleOnOutClick = (e) => {
      if (e.target !== this.lightboxContainer.current) {
        return;
      }
      this.handleOnClose();
    };

    handleOnExpand = () => {
      const { expanded } = this.state;
      this.setState({
        expanded: !expanded,
      });
    };

    setIconsColor = (color) => {
      this.setState({
        iconsColor: color,
      });
    };

    getWindowWidth = () => window.innerWidth;

    isMobile = () => ['xs', 'sm'].includes(getBreakpoint());

    render() {
      const { expanded, iconsColor } = this.state;
      const {
        locked, width, reverseCloseIcon, maxWidth, preserveNavBar, closeIcon,
      } = this.props;
      return ReactDOM.createPortal(
        <ProfileWrapper
          className={`lightbox ${locked ? 'locked' : ''}`}
          onClick={this.handleOnOutClick}
          ref={this.lightboxContainer}
          expanded={expanded}
          mobile={this.isMobile()}
          color={iconsColor}
          width={width}
          reverseCloseIcon={reverseCloseIcon}
          maxWidth={maxWidth}
          preserveNavBar={preserveNavBar}
        >
          <div className="card">
            {(closeIcon || closeIcon === undefined) && (
              <Icon
                svg="Close"
                onClick={this.handleOnClose}
                className="closeIcon"
              />
            )}
            <i
              role="presentation"
              className={`uil ${
                !expanded
                  ? 'uil-expand-alt expandIcon'
                  : 'uil-compress-alt compressIcon'
              }`}
              onClick={this.handleOnExpand}
            />
            <Component
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...this.props}
              onClose={this.handleOnClose}
              iconsColor={this.setIconsColor}
            />
          </div>
        </ProfileWrapper>,
        document.body,
      );
    }
  }
  Wrapper.defaultProps = {
    closeIcon: true,
    locked: false,
  };

  return Wrapper;
};

export default WithDrawer;
