import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import SuccessComponent from '../../../helpers/SuccessPage';
import UserOkIcon from '../../../../configuration/icons/svg/ico-groups.svg';
import { connect } from 'react-redux';
import AddUsersWizard from './AddUsersWizard';

const CreateUsersGroupSuccess = props => {
  return (
    <SuccessComponent
      title={<FormattedMessage id='usersGroups.wizard.success.title' />}
      lead={<FormattedMessage id='usersGroups.wizard.success.lead' />}
      icon={UserOkIcon}
      error={props.errorFetching}
      showCheck={true}
      content={
        <div><AddUsersWizard /></div>
      }
    />
  );
};

const mapStateToProps = state => ({
  errorFetching: state.get('usersGroups').get('errorFetching'),
});

export default connect(
  mapStateToProps,
  {}
)(CreateUsersGroupSuccess);
