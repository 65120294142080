export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_ERROR = 'GET_USER_ROLES_ERROR';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_SUCCESS_FLAG = 'ADD_USER_SUCCESS_FLAG';

export const MODIFY_USER_REQUEST = 'MODIFY_USER_REQUEST';
export const MODIFY_USER_SUCCESS = 'MODIFY_USER_SUCCESS';
export const MODIFY_USER_ERROR = 'MODIFY_USER_ERROR';
export const MODIFY_USER_SUCCESS_FLAG = 'MODIFY_USER_SUCCESS_FLAG';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const GET_USERS_BY_FILTERS_REQUEST = 'GET_USERS_BY_FILTERS_REQUEST';

export const ERRORFETCHING_USER_RESET = 'ERRORFETCHING_USER_RESET';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_ERROR = 'RESET_USER_PASSWORD_ERROR';
export const MODIFY_USER_PASSWORD_REQUEST = 'MODIFY_USER_PASSWORD_REQUEST';
export const MODIFY_USER_PASSWORD_ERROR = 'MODIFY_USER_PASSWORD_ERROR';
export const MODIFY_USER_PASSWORD_SUCCESS = 'MODIFY_USER_PASSWORD_SUCCESS';


export const GET_USER_ME_REQUEST = 'GET_USER_ME_REQUEST';
export const GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS';
export const GET_USER_ME_ERROR = 'GET_USER_ME_ERROR';

export const GET_USER = 'GET_USER';
