import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Col, Row, Container,
} from 'reactstrap';
import CommonView from '../../../CommonView';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import SelectSourceOfData from './Components/selectSourceOfData';

export class SelectArithmeticalSourceOfData extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      definition: {
        type: 'calculate',
        unit: undefined,
        process: {},
      },
      errors: this.props.errors,
      onValidation: false,
      selectedDevice: [this.props.metadata.selectedDevice],
      type: this.props.newEntity.type,
      devices: [],
      devicesOptions: [],
      total: 0,
      selectorData: [],
      selectedAttr: 0,
      page: 1,
      openedPanels: [],
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  savePartialKpi = () => {
    this.setErrors({});

    const { errors, onValidation, ...rest } = this.state;

    this.props.onContinue(rest, { devices: this.state.devices });
  };

  validate = () => {
    const { type, devices } = this.state;

    // check if any object is selected
    if (this.state.selectorData.length === 0) {
      this.props.onError(
        <FormattedMessage id="widgets.wizard.no.device.selected" />,
      );
    } else if (devices.length === 0) {
      this.props.onError(
        <FormattedMessage id="opendata.wizard.no.attributes.selected" />,
      );
    } else {
      const newEntity = new this.props.entity({ type, objects: devices });
      const hasErrors = newEntity.validate(['type', 'objects']);
      if (hasErrors && hasErrors.error) {
        this.setErrors(hasErrors);
        this.props.onError(hasErrors.error);
      } else {
        this.savePartialKpi();
      }
    }
  };

  handleChangeSelect = (devices, selectorData) => {
    this.setState({ devices, selectorData });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <SelectSourceOfData
              handleChange={this.handleChangeSelect}
              onError={this.props.onError}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withWizardStep(injectIntl(SelectArithmeticalSourceOfData));
