export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR';

export const GET_ROLE_PERMISSIONS_REQUEST = 'GET_ROLE_PERMISSIONS_REQUEST';
export const GET_ROLE_PERMISSIONS_SUCCESS = 'GET_ROLE_PERMISSIONS_SUCCESS';
export const GET_ROLE_PERMISSIONS_ERROR = 'GET_ROLE_PERMISSIONS_ERROR';

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_ERROR = 'ADD_ROLE_ERROR';
export const ADD_ROLE_SUCCESS_FLAG = 'ADD_ROLE_SUCCESS_FLAG';



export const MODIFY_ROLE_REQUEST = 'MODIFY_ROLE_REQUEST';
export const MODIFY_ROLE_SUCCESS = 'MODIFY_ROLE_SUCCESS';
export const MODIFY_ROLE_ERROR = 'MODIFY_ROLE_ERROR';
export const MODIFY_ROLE_SUCCESS_FLAG = 'MODIFY_ROLE_SUCCESS_FLAG';


export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const ERRORFETCHING_ROLE_RESET = 'ERRORFETCHING_ROLE_RESET';


export const GET_ROLE = 'GET_ROLE';
