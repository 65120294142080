import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { clone } from 'ramda';
import rangeIcon from '../../../../../configuration/icons/svg/ico-map-groups.svg';
import elementIcon from '../../../../../configuration/icons/svg/ico-map-elements.svg';
import { CardButton } from '../../../../../elements/CardButton/CardButton';
import EditColorConditions from './EditColorConditions';
import EditElementConditions from './EditElementConditions';
import { Components } from '../../../../../models/Widget/configurationSheets/utils';

const AdvancedLegendColors = ({
  widget, categorySelected, widgetSources, handler,
}) => {
  const { config } = widget;
  const { conditions: _conditions } = config;
  const { categoryColors } = _conditions;

  const getAvailableDevicesToSelect = (elem = undefined) => {
    const elements = elem
      || (categoryColors[categorySelected] ? categoryColors[categorySelected].element : []);
    let availables = [];
    let selected = [];

    const { origins, sources } = widget;

    // ToDo Remove when implement showV2
    if (sources) {
      widgetSources.forEach((source) => {
        if (source.categories[0] === categorySelected) {
          availables.push(source);
        }
      });
    } else if (origins) {
      origins.forEach((d) => {
        if (d.connectedDevices.readDevice?.categories[0] === categorySelected) {
          availables.push(d.connectedDevices.readDevice);
        }
      });
    }

    (elements || []).forEach((el) => {
      selected = selected.concat(el.devices.map((dev) => dev.id));
    });
    availables = availables.filter((a) => !selected.includes(a.id));
    return availables;
  };

  const getAllAttributes = () => {
    const { origins, sources } = widget;
    const allAtributes = [];

    // ToDo Remove when implement showV2
    if (sources) {
      sources.forEach((source) => {
        source.fields.forEach((att) => {
          if (!allAtributes.includes(att)) {
            allAtributes.push(att);
          }
        });
      });
    } else if (origins) {
      origins.forEach((o) => {
        const device = o.connectedDevices;
        device.readDevice.attributes.forEach((att) => {
          if (!allAtributes.includes(att.name)) {
            allAtributes.push(att.name);
          }
        });

        device.readDevice.static_attributes.forEach((att) => {
          if (!allAtributes.includes(att.name)) {
            allAtributes.push(att.name);
          }
        });
      });
    }
    return allAtributes;
  };

  const [mode, setMode] = useState(
    categoryColors[categorySelected] && categoryColors[categorySelected].mode
      ? categoryColors[categorySelected].mode
      : '',
  );
  const [elementConditions, setElementConditions] = useState(categoryColors[categorySelected]
    && categoryColors[categorySelected].element
    && categoryColors[categorySelected].element.length > 0
    ? categoryColors[categorySelected].element
    : [{
      id: Date.now(),
      name: 'Grupo 1',
      color: '#FFFFFF',
      devices: [],
    }]);
  const [attributes] = useState(getAllAttributes());
  const [elementDevicesAvaliables, setElementDevicesAvaliables] = useState(
    getAvailableDevicesToSelect(),
  );
  const [conditions, setConditions] = useState(
    categoryColors[categorySelected] && categoryColors[categorySelected].range
      ? categoryColors[categorySelected].range
      : {},
  );

  const addGroup = () => {
    const elementConditionsCopy = clone(elementConditions);

    const newElement = {
      id: Date.now(),
      name: 'Nuevo Grupo',
      color: '#ffffff',
      devices: [],
    };
    elementConditionsCopy.push(newElement);
    setElementConditions(elementConditionsCopy);
  };

  const removeGroup = (id) => {
    const elementConditionsCopy = clone(elementConditions);
    const categoryColorsCopy = clone(categoryColors);

    const elemenReain = elementConditionsCopy.filter((el) => el.id !== id);

    categoryColorsCopy[categorySelected].element = elemenReain;
    handler(
      { name: 'categoryColors', value: categoryColorsCopy },
      'conditions',
      Components.advancedLegendColors,
    );
  };

  const handleElementCondition = (data) => {
    const categoryColorsCopy = clone(categoryColors);
    let isNew = true;
    if (!categoryColorsCopy[categorySelected].element) {
      categoryColorsCopy[categorySelected].element = elementConditions;
    }
    if (categoryColorsCopy[categorySelected].element.length > 0) {
      categoryColorsCopy[categorySelected].element = categoryColorsCopy[categorySelected]
        .element.map((ele) => {
          if (ele.id === data.id) {
            isNew = false;
            return data;
          }
          return ele;
        });
    }

    if (isNew) {
      categoryColorsCopy[categorySelected].element.push(data);
    }

    handler(
      { name: 'categoryColors', value: categoryColorsCopy },
      'conditions',
      Components.advancedLegendColors,
    );
    setElementDevicesAvaliables(
      getAvailableDevicesToSelect(categoryColorsCopy[categorySelected].element),
    );
    setElementConditions(categoryColorsCopy[categorySelected].element);
  };

  const handleActive = (value) => {
    const categoryColorsCopy = clone(categoryColors);
    setMode(value);
    categoryColorsCopy[categorySelected].mode = value;
    handler(
      { name: 'categoryColors', value: categoryColorsCopy },
      'conditions',
      Components.advancedLegendColors,
    );
  };

  const handleConditions = (data) => {
    const categoryColorsCopy = clone(categoryColors);
    setConditions(data);
    categoryColorsCopy[categorySelected].range = data;
    handler(
      { name: 'categoryColors', value: categoryColorsCopy },
      'conditions',
      Components.advancedLegendColors,
    );
  };

  return (
    <>
      <div className="optionsContainer">
        <CardButton
          onClick={() => handleActive('range')}
          isActive={mode === 'range'}
          icon={rangeIcon}
          label="widget.config.map.label-range"
          text="widget.config.map.range"
        />
        <CardButton
          onClick={() => handleActive('element')}
          isActive={mode === 'element'}
          icon={elementIcon}
          label="widget.config.map.label-element"
          text="widget.config.map.element"
        />
      </div>
      {mode === 'range'
        ? (
          <EditColorConditions
            widgetName={widget.name}
            attributes={attributes}
            conditions={conditions}
            handleConditions={handleConditions}
          />
        )
        : null}

      {elementConditions.length > 0 && mode === 'element'
        ? elementConditions.map((element, index) => (
          <EditElementConditions
            index={index + 1}
            buttonRemove={elementConditions.length > 1}
            categorySelected={categorySelected}
            lastIteration={index === elementConditions.length - 1}
            devices={elementDevicesAvaliables}
            addGroup={addGroup}
            removeGroup={removeGroup}
            name={element.name}
            color={element.color}
            handleElementCondition={handleElementCondition}
            elementConditions={element}
            widgets={widget.origins}
          />
        ))
        : null}
    </>
  );
};

AdvancedLegendColors.propTypes = {
  categorySelected: PropTypes.string,
  handler: PropTypes.func,
  widget: PropTypes.objectOf(PropTypes.any),
};

AdvancedLegendColors.defaultProps = {
  categorySelected: '',
  handler: () => {},
  widget: {},
};

export default AdvancedLegendColors;
