import React from 'react';
import { PropTypes } from 'prop-types';
import { ButtonCustom } from '../../../../../elements/Button/index';

const ActionButtons = ({
  handlePlusButtonClick,
  handleMinusButtonClick,
  handleNestButtonClick,
  handleTimeButtonClick,
  type,
  orIndex,
  andIndex,
  conditionType,
  condition,
}) => (
  <>
    {andIndex === 0 && (
    <ButtonCustom
      type="circular"
      name="plus"
      onClick={handlePlusButtonClick}
    />
    )}
    {type !== 'if' && (
    <ButtonCustom
      type="circular"
      name="minus"
      onClick={() => handleMinusButtonClick(orIndex, andIndex, conditionType)}
    />
    )}
    <ButtonCustom
      type="circular"
      name="hourglass"
      onClick={() => handleTimeButtonClick(condition.orIndex, condition.andIndex, condition.type)}
      disabled={condition.timeWindow}
    />
    {andIndex === 0 && (
    <ButtonCustom
      type="circular"
      name="nest"
      onClick={handleNestButtonClick}
    />
    )}
  </>
);

ActionButtons.propTypes = {
  handlePlusButtonClick: PropTypes.func.isRequired,
  handleMinusButtonClick: PropTypes.func.isRequired,
  handleNestButtonClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  orIndex: PropTypes.number.isRequired,
  andIndex: PropTypes.number.isRequired,
};

export default ActionButtons;
