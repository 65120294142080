const NeedleDefaultConfiguration = {
  appearance: {
    min: '',
    max: '',
  },
  labels: {
    unit: '',
  },
  colors: {
    early: '#e12c50',
    middle: '#acd356',
    late: '#577ee8',
  },
  conditions: {
    type: 'absolute',
    range: {
      middleFrom: 55,
      middleTo: 77,
    },
  },
};

export default NeedleDefaultConfiguration;

export const NeedleDefaultConfigurationForLinked = {
  ...NeedleDefaultConfiguration,
  appearance: {
    min: 0,
    max: 100,
  },
};
