import React from 'react';

import './styles.scss';

const ButtonOutline = React.memo(({
  children, circle, bgwhite, label, ico, onClick, noPadding, disabled,
}) => (
  <button
    type="button"
    onClick={onClick || null}
    className={`custom-btn${circle ? ' circle' : ''}${bgwhite ? ' bg-white' : ''}${noPadding ? ' noPadding' : ''}${disabled ? ' disabled' : ''}`}
  >
    {ico ? <i className={`uil uil-${ico}`} /> : ''}
    {label || children || '' }
  </button>
));

export default ButtonOutline;
