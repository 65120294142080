import Styled from 'styled-components';

const TH = Styled('th')`
    
    &:hover{
        cursor: pointer;
    }
    
    ${(p) => p.css}
`;

export const OptionsForTopRight = Styled(TH)`
    padding-right: 0px;
    padding-left: 0px;
    & > * {
        right: 0;
        top: 0;
    }

`;

export default TH;
