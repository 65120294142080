import * as TYPES from './types';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export const getHistoricals = (Historical) => store.dispatch({
  type: TYPES.GET_HISTORICALS_REQUEST,
  payload: Historical,
});

export const getHistorical = () => store.dispatch({
  type: TYPES.GET_HISTORICAL_REQUEST,
});

export const getHistoricalList = (Historical) => store.dispatch({
  type: TYPES.GET_HISTORICAL_LIST_REQUEST,
  payload: Historical,
});

export const getHistoricalListV2 = (Historical) => store.dispatch({
  type: TYPES.GET_HISTORICAL_LIST_V2_REQUEST,
  payload: Historical,
});

export const updateHistoricalListV2FromSocket = (payload) => store.dispatch({
  type: TYPES.UPDATE_HISTORICAL_LIST_V2_FROM_SOCKET,
  payload,
});

export const getMultipleHistoricalList = (Historical) => store.dispatch({
  type: TYPES.GET_MULTIPLE_HISTORICAL_LIST_REQUEST,
  payload: Historical,
});

export const getHistoricalListPreview = (Historical) => store.dispatch({
  type: TYPES.GET_HISTORICAL_LIST_PREVIEW_REQUEST,
  payload: Historical,
});

export const updateHistoricalList = (Historical) => store.dispatch({
  type: TYPES.UPDATE_HISTORICAL_LIST_REQUEST,
  payload: Historical,
});

export const errorFetchingHistoricalReset = () => store.dispatch({
  type: TYPES.ERRORFETCHING_HISTORICAL_RESET,
});
