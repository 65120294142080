import React from 'react';
import { ButtonCustom } from '../../../../../elements/Button/index';
import Menu from '../../../../../components/Menu/index';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import Field from '../../../../../elements/Field';


export const ATTRIBUTE = 'attributes',
  LAZY = 'lazy_attributes',
  COMMAND = 'command_attributes',
  STATIC = 'static_attributes',
  INTERNAL = 'internal_attributes';

export const ATTRIBUTE_PROPERTY = 'attribute',
  LAZY_PROPERTY = 'lazy',
  COMMAND_PROPERTY = 'command',
  STATIC_PROPERTY = 'static';

const OpenButton = ({ onClick }) => (
  <ButtonCustom
    label={<FormattedMessage id={'devices.profile.properties'} />}
    type={'secondary'}
    icon={'plus'}
    handleOnClick={onClick}
    className={'ml-auto'}
  />
);

export const getNameAttributeTranslate = attribute => {
  let key = '';
  if (attribute === ATTRIBUTE || attribute === ATTRIBUTE_PROPERTY) key = 'Attribute';
  else if (attribute === LAZY || attribute === LAZY_PROPERTY) key = 'Lazy';
  else if (attribute === COMMAND || attribute === COMMAND_PROPERTY) key = 'Command';
  else if (attribute === STATIC || attribute === STATIC_PROPERTY) key = 'Static';

  return <FormattedMessage id={`devices.attributes.types.${key}`} />;
};

export const ActionMenu = ({ onClick, attributes, ...rest }) => {
  attributes = attributes.map(o => {
    let { name, ...rest } = o;
    if (name === 'Attribute') name = ATTRIBUTE_PROPERTY;
    else if (name === 'Lazy') name = LAZY_PROPERTY;
    else if (name === 'Command') name = COMMAND_PROPERTY;
    else if (name === 'Static') name = STATIC_PROPERTY;
    return { name, ...rest };
  });

  return (
    <Menu
      {...rest}
      top={'40px'}
      openComponent={OpenButton}
      openFrom={'top right'}
      className={'more'}
    >
      <ul>
        {attributes.map((attribute, i) => (
          <li key={i} onClick={() => onClick(attribute.key)}>
            {getNameAttributeTranslate(attribute.name)}
          </li>
        ))}
      </ul>
    </Menu>
  );
};

export const ValueField = ({ onChange, name, value, errors, type, options, disabled, data, selectedOptions, footer, mappedBy , placeholder}) => (
  <Field
    name={name}
    // placeholder={intl.formatMessage({
    //   id: `devices.wizard.${key}.placeholder`
    // })}
    placeholder={placeholder}
    id={name}
    value={value}
    type={type || 'text'}
    onChange={onChange}
    key={name}
    helperText={errors}
    error={errors ? !!errors : false}
    options={options}
    disabled={disabled}
    data={data}
    selectedOptions={selectedOptions}
    footer={footer}
    mappedBy={mappedBy}
  />
);

export default {ValueField, ActionMenu, getNameAttributeTranslate, }