import Faker from 'faker';
import { rulesManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = rulesManagement.urlAPI.concat(rulesManagement.paths.rules);

const exampleBodyFn = (numberOfRuleToGenerate = 50) => {
  const RulesFaker = [];
  for (let i = 0; i < numberOfRuleToGenerate; i++) {
    RulesFaker.push({
      name: Faker.random.word(),
      description: Faker.random.word(),
      devices: [[Faker.random.word()]],
      events: [
        [
          {
            variable: Faker.random.word(),
            comparison: '>',
            value: Faker.random.number(100),
          },
        ],
      ],
      actions: [
        {
          type: 'email',
          parameters: {
            to: Faker.random.email(),
            from: Faker.random.email(),
            subject: Faker.random.word(),
          },
          template: Faker.random.word(),
        },
      ],
    });
  }
  return { rules: RulesFaker };
};

/** * First version of Rule CRUD */
// PLURAL
export const readRules = async (page, size, filters) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  const params = {
    endPoint: endPoint + 'list?'.concat(queryPage, '&', querySize, queryFilters),
    statusOK: 200,
    toJSON: true,
    returnData: 'rules',
  };
  return requestAPI(params, exampleBodyFn);
};

// SINGULAR
export const readRule = async (rule) => {
  if (!rule.idRules) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${rule.idRules}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'rule',
  };

  return await requestAPI(params, exampleBodyFn);
};

export const addRule = async (rule) => {
  if (rule.id) return { status: 400, error: 'invalid id attr' };

  const params = {
    endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'rule',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(rule),
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const updateRule = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: endPoint + rule.ruleId,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(rule.payload),
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const updateRulePermissions = (rule) => {
  if (!rule.id) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: `${endPoint + rule.id}/permissions`,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(rule.permissions_policy),
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const updateRuleEngine = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint + rule.ruleId}/engine`,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(rule.payload),
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const addRuleAction = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint + rule.ruleId}/action`,
    statusOK: 201,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(rule.payload),
    },
  };
  return requestAPI(params, exampleBodyFn);
};


export const updateRuleAction = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint + rule.ruleId}/action/${rule.payload.id}`,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(rule.payload),
    },
  };
  return requestAPI(params, exampleBodyFn);
};


export const deleteRuleAction = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint + rule.ruleId}/action/${rule.payload.id}`,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'DELETE',
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const deleteRule = (rule) => {
  if (!rule.id) return { status: 400, error: 'missing id attr' };

  const params = {
    endPoint: endPoint + rule.id,
    statusOK: 204,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'DELETE',
    },
  };
  return requestAPI(params, exampleBodyFn);
};


export const updateRuleStatus = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint + rule.ruleId}/status`,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(rule.payload),
    },
  };
  return requestAPI(params, exampleBodyFn);
};

export const updateRuleCron = (rule) => {
  if (!rule.ruleId) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: `${endPoint + rule.ruleId}/cron`,
    statusOK: 200,
    toJSON: true,
    returnData: rule,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(rule.payload),
    },
  };
  return requestAPI(params, exampleBodyFn);
};
