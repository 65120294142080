const calcAvailableBits = data => {
  const maxBitsNumber = 96;
  let usedBits = 0;
  data.forEach(b => {

    if (b.type === 'uint' || b.type === 'int' || b.type === 'float') {
      b.parameter && (usedBits += b.parameter);
    }
    else if (b.type === 'char') {
      b.parameter && (usedBits += b.parameter * 8);
    }

    else b.parameter && (usedBits += 8);
  });

  return maxBitsNumber - usedBits;
};

export const uintSigfoxData = ({ body, onEditData }) => {
  const remainingBits =
    calcAvailableBits(body) + (onEditData ? onEditData.size : 0);

  const sizes = [];
  for (let i = 1; i < (remainingBits < 64 ? remainingBits : 64) + 1; i++) {
    //if (i % 8 === 0) {
    sizes.push({ name: i + ' bits', value: i });
    //}
  }
  return sizes;
};

export const floatSigfoxData = ({ body, onEditData }) => {
  const remainingBits =
    calcAvailableBits(body) + (onEditData === false ? 32 : onEditData === true ? 64 : 0 );
  const sizes = [];

  if (remainingBits >= 64) {
    sizes.push({ name: '64 bits', value: 64 });
  }
  if (remainingBits >= 32) {
    sizes.push({ name: '32 bits', value: 32 });
  }

  return sizes;
};

export const charSigfoxData = ({ body, onEditData }) => {

  const remainingBits =
    calcAvailableBits(body) + (onEditData ? onEditData.size : 0);

  if (remainingBits < 8) {
    return [];
  }
  const remainingBites = Math.floor(remainingBits / 8)
  const sizes = [];
  for (let i = 0; i <= (remainingBites < 7 ? remainingBites : 7) + 1; i++) {
    sizes.push({ name: i + ' Bytes', value: i })
  }
  return sizes;
};


export const boolSigfoxData = ({ body, onEditData }) => {

  const sizes = [];
  for (let i = 0; i <= 7; i++) {
    sizes.push({ name: i + 'B', value: i })
  }

  return sizes;
};


export const offsetSigfoxData = () => {
  let offsetList = [];
  for (let i = 0; i <= 7; i++) {
    offsetList.push({ name: i, value: i })
  }
  return offsetList;
}
