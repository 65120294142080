const KpiDefaultConfiguration = {
  data: {
    sampling: 'lastFifteenDays',
  },
  colors: {
    iconColor: '#577ee8',
    negativeColor: '#fc381d',
    positiveColor: '#acd356',
  },
  labels: {
    unit: '',
  },
  trendlines: { },
  conditions: { },
  appearance: {
    iconCategory: true,
    showHistorical: true,
    showIncrement: true,
  },
  effects: { },
  custom: { },
};

export default KpiDefaultConfiguration;

export const KpiDefaultConfigurationForLinked = {
  ...KpiDefaultConfiguration,
  data: {
    ...KpiDefaultConfiguration.data,
    type: 'real-time',
  },
};
