import React from 'react';
import { connect } from 'react-redux';
import CreateTemplateFromScratch from '../../../../configuration/icons/svg/create-device-scratch.svg';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { getTemplates } from '../../../../services/redux/templates/actions';
import StarterComponent from '../../../helpers/StarterPage';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';


class Starter extends React.Component {
  
  componentDidMount() {
    this.props.onRef(this);
    getTemplates();
  }

  getOptions = () => {
    const { templates } = this.props;

    return templates && templates.map(o => ({ name: o.name, value: o }));
  };

  handleOnClick = () => {
    this.props.onContinue();
  } 

  validate = () => {
    this.props.onContinue();
  };

  render() {

    return (
      <StarterComponent className="templatesStarter">
        <div className="d-flex justify-content-center align-items-center mainContent">
          <div className="cardButton" onClick={this.handleOnClick}>
            <img
              src={CreateTemplateFromScratch}
              alt="create template from scratch"
            />
            <h3>
              <FormattedMessage id="templates.wizard.create.from.scratch.title" />
            </h3>
            <p>
              <FormattedMessage id="templates.wizard.create.from.scratch.text" />
            </p>
          </div>
        </div>
      </StarterComponent>
    );
  }
}

const mapStateToProps = state => ({
  templates: state
    .get('templates')
    .get('list')
    .toJS()
});

export default connect(
  mapStateToProps,
  { getTemplates }
)(withWizardStep(Starter));
