import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Websocket from 'react-websocket';
import { redirectTo } from '@reach/router';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import differenceBy from 'lodash/differenceBy';
import { isEqual } from 'lodash';
import * as R from 'ramda';
import usePrevious from '../../helpers/Hooks/usePrevious';
import DragAndDropGrid from '../../../components/DragAndDropGrid';
import { withTheme } from '../../../Contexts/ThemeContext';
import {
  clearReadDashboard,
  getDashboard,
  updateDashboardLayout,
} from '../../../services/redux/dashboards/actions';
import { getHistoricalList, getHistoricalListV2 } from '../../../services/redux/historicals/actions';
import { getDeviceList } from '../../../services/redux/devices/actions';
import ModalAddDashboard from '../../../components/ModalAddDashboard';
import DashboardMenuTittle from './components/DashboardMenuTittle';
import Loading from './components/Loading';
import ProfileDashboard from '../Profile';
// eslint-disable-next-line import/no-cycle
import ProfileWidget from '../../Widgets/Profile';
import WithDrawer from '../../../components/DrawerContainer';

import { websocketConnection } from '../../../configuration/config';
import getToken from '../../../helpers/getToken';

import RenderWidget from './components/RenderWidget';
import refreshWidgetData from './components/RefreshWidgetData';
import 'moment/locale/es';
import './styles.scss';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';
import ProfileSendingCommands from './ProfileSendingCommands/index';
import { havePermissionToEdit } from '../../../helpers/utils';
import { getDate, getResolution } from '../../../helpers/samplingHistorical';
import { updateWidget } from '../../../services/redux/widgets/actions';
import CreateWidgets from '../../Widgets/Add/AddWizard';
import useDashboardContext from '../../../Contexts/DashboardContext/consumer';
import { getWidgetModel } from '../../../models/WidgetV2/utils';

moment.locale('es');

const TIME_REFRESH_AGGREGATE_WIDGET = 10000;

const getAllDeviceAttributes = (device) => [
  ...(device.attributes || []),
  ...(device.lazy_attributes || []),
  ...(device.command_attributes || []),
  ...(device.static_attributes || []),
];

const isEqualAggregate = (data, { deviceId, attr, configuration }) => data.entityId === deviceId
  && data.attrName === attr
  && data.resolution === getResolution(configuration.data.sampling)
  && data.origin >= getDate('startDate', { configuration }, 'YYYY-MM')
  && data.origin <= getDate('endDate', { configuration });

const ProfileDashboardComponent = WithDrawer(
  ({ onChange, data, profileType }) => (
    <ProfileDashboard
      onChange={onChange}
      data={data}
      profileType={profileType}
    />
  ),
);

const ProfileWidgetComponent = WithDrawer(({
  onChange,
  data,
  profileType,
  locked = false,
  getParentWidget,
  isLinked,
  widgetList,
  selection,
}) => (
  <ProfileWidget
    onChange={onChange}
    data={data}
    profileType={profileType}
    locked={locked}
    isLinked={isLinked}
    getParentWidget={getParentWidget}
    widgetList={widgetList}
    selection={selection}
  />
));

const ProfileSendingCommandComponent = WithDrawer(
  ({
    onChange, data, profileType, checkable, ...rest
  }) => (
    <ProfileSendingCommands
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={onChange}
      data={data}
      profileType={profileType}
      checkable={checkable}
    />
  ),
);

export const removeQueryDuplicate = (queries) => {
  const resultQueries = [];
  queries.forEach((query) => {
    if (query.aggregate || !resultQueries.find((o) => (!o.aggregate && o.id === query.id))) {
      resultQueries.push(query);
    }
  });

  return resultQueries;
};

export const getContainedWidgets = (widget, widgetList) => {
  if (widget.widgetType === 'IMAGE') return widgetList.filter((w) => w.container === widget.id);
  if (widget.widgetType === 'LINKED') return widgetList.filter((w) => w.container === widget.id);
  return [];
};

const buildMessages = (widget) => {
  let message;

  return widget.origins.map((o) => {
    message = { id: o.connectedDevices.device_id };

    o.connectedDevices.attributes.forEach((attr) => {
      message[attr] = true;
    });

    return message;
  });
};

const getSelectionFromLocalStorage = () => {
  const currentSelectionsRaw = localStorage.getItem('linkedSelections');
  const currentSelection = currentSelectionsRaw ? JSON.parse(currentSelectionsRaw) : [];
  return currentSelection;
};
const Show = (props) => {
  const {
    dashboard,
    devices,
    errorFetching,
    fetching,
    id,
    widgetData,
    updatedWidget,
    path,
    profileType,
    widgetDataV2,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const location = useMemo(() => window.location.pathname, [window.location.pathname]);
  const prevProps = usePrevious(props);

  useEffect(() => {
    if ((location.includes('dashboard') || location.includes('home')) && prevProps?.dashboard && prevProps?.dashboard.id !== dashboard?.id) {
      clearReadDashboard();
    }
  }, [location, prevProps, dashboard]);

  useEffect(() => () => {
    if (!window.location.pathname.includes('dashboard')) {
      clearReadDashboard();
    }
  }, []);

  // Set States with their methods for change their values.
  const [hasBeenResized, setHasBeenResized] = useState(false);
  const [refreshMap, setRefreshMap] = useState(false);
  const [widgetValues, setWidgetValues] = useState({});
  const [dataWidgetRead, setDataWidgetRead] = useState(false);
  const [dataFullLoaded, setDataFullLoaded] = useState(false);
  const [dataFirstLoad, setDataFirstLoad] = useState(false);
  const [runSocket, setRunSocket] = useState(false);
  const [dashboardHook, setDashboardHook] = useState(dashboard);
  const [permissionToEdit, setPermissionToEdit] = useState(false);
  const [isResizable, setIsResizable] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isShowingWidgetModal] = useState(false);
  const [profileDashboardOpened, setProfileDashboardOpened] = useState(null);
  const [, setActiveDashboard] = useState(null);
  const [profileDashboardType, setProfileDashboardType] = useState(null);
  const [profileSendingCommandsOpened, setProfileSendingCommandsOpened] = useState(null);
  const [dataToUpdateAggregate, setDataToUpdateAggregate] = useState([]);
  const [statusToUpdateAggregate, setStatusToUpdateAggregate] = useState('ready');
  const [widgetUpdate, setWidgetUpdate] = useState(null);
  const [selection, setSelection] = useState(getSelectionFromLocalStorage());
  const [widgetValuesV2, setWidgetValuesV2] = useState(false);
  const [currentBreakpoint, setCurrentBreakPoint] = useState('md');
  const {
    activeWidget,
    setActiveWidget,
    widgetList,
    setWidgetList,
    profileWidgetOpened,
    setProfileWidgetOpened,
    profileWidgetType,
    setProfileWidgetType,
    handleOpenProfileWidget,
    setHistorical,
    isProfileOpenedWithLinked,
  } = useDashboardContext();

  useEffect(() => {
    if (dashboard && dashboard.widgets.length >= 0) {
      setDataFullLoaded(true);
    }
  }, [dashboard, dataFullLoaded]);

  // Set UseRef for Socket
  let WSocket = useRef(null);

  // Methods
  const handleResizable = () => {
    setIsResizable(!isResizable);
    setIsDraggable(!isDraggable);
  };

  const handleOnProfileDashboardEvent = (opened) => {
    setProfileDashboardOpened(opened);
  };

  const handleOnProfileSendingCommandsEvent = (opened) => {
    setProfileSendingCommandsOpened(opened);
  };

  const handleOpenProfileDashboard = (dashboardVar, profileTypeVar) => {
    setProfileDashboardOpened(true);
    setActiveDashboard(dashboardVar);
    setProfileDashboardType(profileTypeVar);
  };

  const handleOnProfileWidgetdEvent = (opened) => {
    setProfileWidgetOpened(opened);
  };

  const handleOpenProfileSendingCommands = useCallback((widget, profileTypeVar) => {
    setProfileSendingCommandsOpened(true);
    setActiveWidget(widget);
    setProfileWidgetType(profileTypeVar);
  }, [setProfileSendingCommandsOpened, setActiveWidget, setProfileWidgetType]);

  const readDeviceData = useCallback(() => {
    let ids = [];
    if (!dashboard) return;
    dashboard.widgets.forEach((widget) => {
      if (widget.widgetType !== 'TEXT' && widget.widgetType !== 'SEND_COMMAND_SINGLE' && widget.widgetType !== 'SEND_COMMAND_DUAL');
      ids = ids.concat(
        widget.origins.filter((o) => !ids.find((u) => u.id === o.connectedDevices.id)),
      );
    });
    ids = ids.map((o) => o.connectedDevices);
    getDeviceList(ids);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard.widgets]);

  const addDeviceInfoTowidget = useCallback(() => {
    widgetList.forEach((widget, i) => {
      widget.origins.forEach((connectedDevice, j) => {
        // Use the filter function because it works better than the find function in this case
        const device = devices
          .filter((e) => e.id === widgetList[i].origins[j].connectedDevices.id)[0];

        // check if the device data is correct
        // case a: widget with device deleted (you get a device with id as null)
        // case b: you dont have permision with device (the device is deleted in sagas)
        // in both cases device is undefined (empty list or id not found)
        if (device !== undefined) {
          widgetList[i].origins[j].connectedDevices.readDevice = device;
        } else {
          // then whole widget is mark as invalid,
          // cause widgets dont check his devices props, this is the safest behaivor atm.
          widgetList[i].isInvalid = true;
        }
      });
    });
  }, [devices, widgetList]);

  const readWidgetData = useCallback(() => {
    let queries = [];
    const validWidgetList = widgetList.filter((w) => !w.isInvalid);
    const linkedWidgetsParentsId = validWidgetList.filter((w) => w.widgetType === 'LINKED').map((e) => e.id);

    validWidgetList
      .forEach((o) => {
        if (
          (o.container && linkedWidgetsParentsId.includes(o.container)) || !o.getQueryHistorical
        ) return;
        const historical = o?.getQueryHistorical();
        if (historical) {
          queries = queries.concat(historical);
        }
      });
    queries = queries.sort((o, u) => u.size - o.size);
    getHistoricalList(removeQueryDuplicate(queries));
    setDataWidgetRead(true);
  }, [widgetList]);

  const readWidgetDataSingle = (widget) => {
    getHistoricalList(widget.getQueryHistorical());
    setDataWidgetRead(true);
  };

  const loadWidgetData = (widgets, v2) => {
    const objectValues = {};
    widgets.forEach((widget) => {
      objectValues[widget.id] = widget.formatToData(
        v2 || (!!widget.container
          && widgetList.find((w) => w.id === widget.container && w.widgetType === 'LINKED')),
      );
    });
    setStatusToUpdateAggregate('ready');
    if (v2) {
      if (objectValues) {
        setWidgetValuesV2(objectValues);
      }
    } else {
      setWidgetValues(objectValues);
    }
  };

  const parseToOldFormat = ({ points, ...entity }, origin) => ({
    id: {
      ...entity,
      origin,
    },
    points,
  });

  const getSimpleData = (widgetType, v2, container) => (
    v2 && widgetType !== 'LINKED' && container && widgetDataV2 && Object.keys(widgetDataV2).length && widgetDataV2[container]
      ? widgetDataV2[container]?.filter((data) => data.urn)
      : widgetData.map((o) => o.data)?.filter((o) => o?.historicals && o.historicals.length > 0)
  );

  const getAggregateData = (widgetType, v2, container) => (
    v2 && widgetType !== 'LINKED' && container && widgetDataV2 && Object.keys(widgetDataV2).length && widgetDataV2[container]
      ? widgetDataV2[container]?.filter((data) => data.origin)
      : widgetData.map((o) => o.data)?.filter((o) => o?.values && o.values.length > 0)
  );

  const isContainerLinked = (widgetID) => widgetList.some((w) => w.id === widgetID && w.widgetType === 'LINKED');

  const addDataHistoricalTowidget = useCallback((v2) => {
    const simpleWidgets = widgetList.filter((o) => !o.widgetAggregate && o.widgetType !== 'TEXT' && o.widgetType !== 'SEND_COMMAND_SINGLE' && o.widgetType !== 'SEND_COMMAND_DUAL');
    const widgetAggregate = widgetList.filter((o) => o.widgetAggregate);

    simpleWidgets.forEach((widget, i) => {
      widget.origins.forEach((o, j) => {
        const connectedDevice = o.connectedDevices;
        const simpleData = getSimpleData(widget.widgetType, v2, widget.container);
        const aggregateData = getAggregateData(widget.widgetType, v2, widget.container);
        const readHistorical = (
          v2 && widget.container
            ? simpleData.filter((sd) => sd.entityId === connectedDevice.device_id)
            : simpleData.find((sd) => sd.historicals[0].entityId === connectedDevice.device_id)
        );
        if (!readHistorical && v2) return;
        const isLinked = isContainerLinked(widget.container);
        const historicalData = (
          v2 && widget.container && isLinked
            ? readHistorical.map((data) => ({
              [data.attrName]: data.value?.value,
              recvTime: data.value?.metadata.TimeInstant?.value || 0,
            }))
            : (readHistorical?.historicals ?? [])
        );
        simpleWidgets[i].origins[j].connectedDevices.historicaldata = historicalData;
        if (widget.widgetType === 'TEXT_ACCOUNTANT' && aggregateData.length) {
          const deviceId = connectedDevice.device_id;
          const configuration = widget.config;
          const attr = widget.container && v2 && isLinked
            ? configuration.data.attributeFilter[0]
            : connectedDevice.attributes[0];

          const readHistoricalAggregate = widget.container && v2
            ? aggregateData.map(
              (aD) => {
                const entity = aD.entities.find(
                  (e) => (
                    isEqualAggregate(
                      { ...e, origin: aD?.origin },
                      { deviceId, attr, configuration },
                    )
                  ),
                );
                if (entity) {
                  entity.origin = aD.origin;
                }
                return entity;
              },
            )
              .filter((e) => e)
            : aggregateData.find((a) => (
              isEqualAggregate(a.values?.[0].id, { deviceId, attr, configuration })));
          connectedDevice.historicaldata.push({
            attribute: attr,
            data: v2 && widget.container && isLinked
              ? (readHistoricalAggregate?.map(
                (entity) => parseToOldFormat(entity, entity.origin),
              ) ?? [])
              : (readHistoricalAggregate?.values ?? []),
          });
        }
      });
    });
    widgetAggregate.forEach((widget, i) => {
      widget.origins.forEach((o, j) => {
        const connectedDevice = o.connectedDevices;
        widgetAggregate[i].origins[j].connectedDevices.historicaldata = [];

        if (widget.widgetType === 'HEATMAP') {
          const simpleData = getSimpleData(widget.widgetType, v2);
          const readHistorical = (
            simpleData.find((z) => z.historicals[0].entityId === connectedDevice.device_id));
          if (readHistorical) {
            widgetAggregate[i].origins[j].connectedDevices.realtime = widget.config.data.type === 'real-time';
            widgetAggregate[i].origins[j].connectedDevices.lastState = readHistorical.historicals;
          }
        }
        const isLinked = isContainerLinked(widget.container);

        const attributes = getAllDeviceAttributes(connectedDevice);
        attributes.forEach((attr) => {
          const deviceId = connectedDevice.device_id;
          const configuration = widget.config;
          let aggregateData = getAggregateData(widget.widgetType, v2, widget?.container);
          if (v2 && aggregateData) {
            aggregateData = aggregateData.map(
              (a) => (a.entities ? [...a.entities.map((e) => ({ ...e, origin: a.origin }))] : []),
            ).flat();
          }
          const readHistoricalAggregate = widget.container && v2 && isLinked
            ? aggregateData?.filter((e) => (
              isEqualAggregate(
                { ...e, origin: e.origin },
                { deviceId, attr, configuration },
              )
            ))
            : aggregateData.find((a) => (
              isEqualAggregate(a.values?.[0].id, { deviceId, attr, configuration })));

          connectedDevice.historicaldata.push({
            attribute: attr,
            data: v2 && widget.container && isLinked
              ? (readHistoricalAggregate?.map(
                (entity) => parseToOldFormat(entity, entity.origin),
              ) ?? [])
              : (readHistoricalAggregate?.values ?? []),
          });
        });
      });
    });
    loadWidgetData([...simpleWidgets, ...widgetAggregate], v2);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData, widgetList, widgetDataV2]);

  const layoutsChange = (layouts) => {
    if (
      JSON.stringify(dashboard.layoutProperties) !== JSON.stringify(layouts)
      && isDraggable && isResizable
    ) {
      const newObj = { ...dashboard, layoutProperties: { ...layouts } };
      if (dashboard.widgets.find((widget) => widget.widgetType === 'MAP')) {
        setRefreshMap(true);
      }
      updateDashboardLayout(newObj);
    }
    setHasBeenResized(true);
  };

  useEffect(() => {
    if (hasBeenResized) setHasBeenResized(false);
  }, [hasBeenResized]);

  const deleteWidgetLayout = useCallback((widget) => {
    const layouts = R.clone(dashboard.layoutProperties);

    ['lg', 'md', 'sm', 'xs', 'xxs'].forEach((size) => {
      layouts[size] = dashboard.layoutProperties[size].filter((obj) => obj.i !== widget.id);
    });

    const newObj = { ...dashboard, layoutProperties: { ...layouts } };
    updateDashboardLayout(newObj);
  }, [dashboard]);

  const getWidgetToUpdate = (message) => widgetList.filter((o) => (
    o.widgetType !== 'TEXT'
  && o.widgetType !== 'SEND_COMMAND_SINGLE'
  && o.widgetType !== 'SEND_COMMAND_DUAL'
  && o.widgetType !== 'TEXT_ACCOUNTANT'
  && !o.widgetAggregate
  && o.origins.find((z) => z.connectedDevices.device_id === message.id)));

  const getAggregateWidgetRealTimeToUpdate = useCallback((message) => widgetList.filter((o) => (
    o.widgetType === 'TEXT_ACCOUNTANT'
  || (o.widgetAggregate && o.config.data.type === 'real-time')
  || (o.widgetAggregate && o.config.data.type === 'historical' && o.config.data.period === 'last'))
  && o.origins.find((z) => z.connectedDevices.device_id === message.id)), [widgetList]);

  const getAggregateWidgetToUpdate = useCallback((message, widget) => widgetList.filter((o) => (
    widget.id === o.id
  && (o.widgetAggregate || o.widgetType === 'TEXT_ACCOUNTANT')
  && o.origins.find((z) => z.connectedDevices.device_id === message.id))), [widgetList]);

  const handleOpen = (socket) => {
    if (props.devices && props.devices.length > 0) {
      const message = {
        token: getToken(),
        devicesId: props.devices.map((d) => d.device_id).toString(),
      };
      socket.sendMessage(JSON.stringify(message));
    }
  };

  const setDataFromWidget = useCallback((idVar, data) => {
    if (!Object.is(widgetValues[idVar], data)) {
      const cloneWidgetData = { ...widgetValues };
      cloneWidgetData[idVar] = data;
      setWidgetValues({ ...cloneWidgetData });
    }
  }, [widgetValues]);

  const addDataToUpdateAggregate = useCallback((toUpdateAggregate, message) => {
    const dataOfWidget = dataToUpdateAggregate.filter((o) => (
      o.toUpdateAggregate.find((u) => (
        toUpdateAggregate.find((z) => z.id === u.id)))
    ));

    if (dataOfWidget.length === 0) {
      setDataToUpdateAggregate([...dataToUpdateAggregate, { toUpdateAggregate, message }]);
    } else {
      const widgetId = dataOfWidget[0].toUpdateAggregate[0].id;
      const existMessage = dataOfWidget.find((o) => message.id === o.message.id);

      if (!existMessage) {
        dataOfWidget.push({ toUpdateAggregate, message });
      } else {
        dataOfWidget.map((o) => {
          // eslint-disable-next-line no-param-reassign
          if (o.message.id === message.id) o.message = message;
          return o;
        });
      }

      setDataToUpdateAggregate([
        ...dataToUpdateAggregate.filter((o) => o.toUpdateAggregate[0].id !== widgetId),
        ...dataOfWidget,
      ]);
    }
  }, [dataToUpdateAggregate]);

  const refreshAggregateWidgetData = useCallback((message, widget) => {
    const toUpdateAggregate = widget
      ? getAggregateWidgetToUpdate(message, widget)
      : getAggregateWidgetRealTimeToUpdate(message);

    addDataToUpdateAggregate(toUpdateAggregate, message);
    if (widget) {
      setStatusToUpdateAggregate('executing');
      // Todo kill setTimeOutAggregate
    } else if (statusToUpdateAggregate === 'ready' && toUpdateAggregate.length > 0) {
      setStatusToUpdateAggregate('waiting');
      setTimeout(() => setStatusToUpdateAggregate('executing'), TIME_REFRESH_AGGREGATE_WIDGET);
    }
  }, [addDataToUpdateAggregate, getAggregateWidgetRealTimeToUpdate,
    getAggregateWidgetToUpdate, statusToUpdateAggregate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateValueAggreate = async () => {
    let newValues = R.clone(widgetValues);

    for (let index = 0; index < dataToUpdateAggregate.length; index += 1) {
      const dataAggregate = dataToUpdateAggregate[index];
      // eslint-disable-next-line no-await-in-loop
      await refreshWidgetData(
        dataAggregate.toUpdateAggregate,
        dataAggregate.message,
        newValues,
        // eslint-disable-next-line no-loop-func
      ).then((newValuesLines) => {
        newValues = { ...newValuesLines };
      });

      if (index === dataToUpdateAggregate.length - 1) {
        setStatusToUpdateAggregate('ready');
        setWidgetValues({ ...newValues });
        setDataToUpdateAggregate([]);
      }
    }
  };

  useEffect(() => {
    if (statusToUpdateAggregate === 'executing') {
      updateValueAggreate();
    }
  }, [dataToUpdateAggregate.message, dataToUpdateAggregate.toUpdateAggregate,
    statusToUpdateAggregate, updateValueAggreate, widgetValues]);

  useEffect(() => {
    if (!widgetList) return;
    // add aggregate historical to context for calculations of linked widget
    const linkeds = widgetList.filter((w) => w.widgetType === 'LINKED');
    if (
      linkeds.length
      && !isEqual(prevProps?.widgetDataV2, widgetDataV2)
    ) {
      setHistorical(widgetDataV2);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetDataV2, widgetList]);

  const getAttributesByWebSocket = async (message) => {
    try {
      const messageDecoded = JSON.parse(message);
      if (!messageDecoded.message && messageDecoded.id) {
        const toUpdate = getWidgetToUpdate(messageDecoded);
        const newValues = await refreshWidgetData(toUpdate, messageDecoded, widgetValues);
        refreshAggregateWidgetData(messageDecoded);
        if (newValues) {
          setWidgetValues({ ...newValues });
        }
      }
    } catch (e) {
      // console.log('BadResponse', e);
    }
    return true;
  };

  // Lifecycle Methods

  useEffect(() => {
    if (widgetUpdate) {
      buildMessages(widgetUpdate).forEach((message) => {
        refreshAggregateWidgetData(message, widgetUpdate);
      });
      setWidgetUpdate(null);
    }
  }, [refreshAggregateWidgetData, widgetList, widgetUpdate]);
  // ComponentDidMount
  useEffect(() => {
    getDashboard({ id: props.id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ComponentDidUpdate
  useEffect(() => {
    if (!prevProps) {
      return;
    }
    if (JSON.stringify(prevProps.dashboard) !== JSON.stringify(props.dashboard)) {
      if ((props.dashboard && !dataFirstLoad) || prevProps.dashboard.id !== props.dashboard.id) {
        setDataFirstLoad(true);
        setDataFullLoaded(false);
        if (props.dashboard.widgets) {
          setWidgetList(props.dashboard.widgets);
        }

        readDeviceData();
      } else if (props.dashboard.widgets && prevProps.dashboard.widgets
  && props.dashboard.widgets.length !== prevProps.dashboard.widgets.length) {
        if (props.dashboard.widgets) {
          setWidgetList(props.dashboard.widgets);
        }
        if (props.dashboard.widgets.length > prevProps.dashboard.widgets.length) {
          readDeviceData();
        }
        if (props.dashboard.widgets.length < prevProps.dashboard.widgets.length) {
          const myDifferences = differenceBy(prevProps.dashboard.widgets, props.dashboard.widgets, 'id');
          if (myDifferences.length > 0) {
            const deletedWidget = myDifferences[0];
            deleteWidgetLayout(deletedWidget);
          }
        }
      }

      setRunSocket(false);
      setDashboardHook(props.dashboard);
      setActiveWidget(activeWidget
        ? props.dashboard.widgets?.find((o) => o.id === activeWidget.id)
        : activeWidget);
      havePermissionToEdit(props.dashboard.permissions_policy, props.dashboard.owner)
        .then((currentPermissionToEdit) => setPermissionToEdit(currentPermissionToEdit));
    }

    if (JSON.stringify(prevProps.updatedWidget) !== JSON.stringify(props.updatedWidget)) {
      const widgetListCopy = R.clone(widgetList).map((w) => getWidgetModel(w));
      const widgetIndex = widgetListCopy.findIndex(((w) => w.id === updatedWidget.id));
      const isNecessaryRefreshAggregateWidgetData = (updatedWidget.widgetType === 'LINES'
      || updatedWidget.widgetType === 'TEXT_ACCOUNTANT'
      || updatedWidget.widgetType === 'BARS')
  && (widgetListCopy[widgetIndex].config.data.sampling !== updatedWidget
    .config.data.sampling
  || widgetListCopy[widgetIndex].config.data.type !== updatedWidget
    .config.data.type);

      widgetListCopy[widgetIndex].config = updatedWidget.config;
      widgetListCopy[widgetIndex].name = updatedWidget.name;
      widgetListCopy[widgetIndex].description = updatedWidget.description;
      if (widgetListCopy) {
        setWidgetList(widgetListCopy);
      }
      if (updatedWidget?.id === activeWidget?.id) {
        setActiveWidget(widgetListCopy[widgetIndex]);
      }

      if (isNecessaryRefreshAggregateWidgetData) {
        setWidgetUpdate(widgetListCopy[widgetIndex]);
      }
      if (updatedWidget.widgetType === 'BARS') {
        widgetListCopy[widgetIndex].widgetAggregate = updatedWidget.config.data.type
        === 'historical' || false;
        const widgetValuesCopy = R.clone(widgetValues);
        if (!widgetListCopy[widgetIndex].widgetAggregate) {
          readWidgetDataSingle(widgetListCopy[widgetIndex]);
        }
        widgetValuesCopy[updatedWidget.id] = widgetListCopy[widgetIndex].formatToData();
        setWidgetUpdate(widgetListCopy[widgetIndex]);
        setWidgetValues(widgetValuesCopy);
      }

      if (updatedWidget.widgetType === 'LINKED') {
        const widgetValuesCopy = R.clone(widgetValues);
        widgetValuesCopy[updatedWidget.id] = widgetListCopy[widgetIndex].formatToData();
        setWidgetValues(widgetValuesCopy);
      }
    }

    if (prevProps.devices !== props.devices) {
      addDeviceInfoTowidget();
      readWidgetData();
    }

    if (prevProps.widgetData !== props.widgetData) {
      addDataHistoricalTowidget();
      setDataFullLoaded(true);
      setRunSocket(true);
    }

    // TODO: Ahora mismo estamos solo llamando a HV2 si existe un linked y hay container
    // console.table('NUEVOS DATOS', prevProps.widgetDataV2 , widgetDataV2)
    // if (prevProps.widgetDataV2 !== widgetDataV2) {
    //   addDataHistoricalTowidget(true);
    //   setDataFullLoaded(true);
    //   setRunSocket(true);
    // }

    if (prevProps.id !== props.id) {
      getDashboard({ id: props.id });
    }

    if (props.errorFetching !== prevProps.errorFetching
      && (props.errorFetching && !props.fetching)
      && props.errorFetching.status === 404
    ) {
      redirectTo(`/app/dashboard/${props.id}/not-found`);
    }

    if (props.errorFetching !== prevProps.errorFetching
      && (props.errorFetching && !props.fetching)
      && props.errorFetching.status === 403
    ) {
      redirectTo(`/app/dashboard/${props.id}/not-allowed`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWidget, addDataHistoricalTowidget, addDeviceInfoTowidget, dataFullLoaded, runSocket,
    prevProps, dashboard, devices, errorFetching, fetching, id,
    widgetData, readWidgetData, widgetList?.length, readDeviceData, dataFirstLoad,
    updatedWidget, props, widgetList, deleteWidgetLayout]);

  useEffect(() => {
    if (JSON.stringify(prevProps?.widgetDataV2) !== JSON.stringify(widgetDataV2)) {
      addDataHistoricalTowidget(true);
      setDataFullLoaded(true);
      setRunSocket(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetDataV2]);

  const selectLinkedWidget = (linkedWidget, selectedDevices) => {
    const linkedWidgets = widgetList.filter((w) => w.container === linkedWidget.id);
    if (selectedDevices.length && linkedWidgets.length) {
      const dataForQueries = linkedWidget.getBodyQueryHistoricalLinked(
        selectedDevices, linkedWidgets,
      );
      getHistoricalListV2([...dataForQueries, { widgetId: linkedWidget.id }]);
    }
  };

  const updateSelection = (widgetId, newSelection) => {
    setSelection((old) => ({ ...old, [widgetId]: newSelection }));
  };

  const renderImageWidgets = (widget) => (
    <div id={widget.id} key={widget.id}>
      <RenderWidget
        refreshMap={refreshMap}
        setRefreshMap={setRefreshMap}
        widget={widget}
        dataWidgetRead={dataWidgetRead}
        wValues={widgetValues}
        profileType={profileType}
        handleOpenProfileWidget={handleOpenProfileWidget}
        isResizable={isResizable}
        setWidgetValues={setDataFromWidget}
        handleOpenProfileSendingCommands={handleOpenProfileSendingCommands}
        permissionToEdit={permissionToEdit}
        hasBeenResized={hasBeenResized}
        dark
      />
    </div>
  );

  const renderLinkedWidgets = (widget) => (
    <div id={widget.id} key={widget.id}>
      <RenderWidget
        refreshMap={refreshMap}
        setRefreshMap={setRefreshMap}
        widget={widget}
        dataWidgetRead={dataWidgetRead}
        wValues={widgetValuesV2}
        profileType={profileType}
        handleOpenProfileWidget={handleOpenProfileWidget}
        isResizable={isResizable}
        setWidgetValues={setDataFromWidget}
        handleOpenProfileSendingCommands={handleOpenProfileSendingCommands}
        permissionToEdit={permissionToEdit}
        hasBeenResized={hasBeenResized}
        globalSelection={widget.container ? selection[widget.container] : selection[widget.id]}
        updateGlobalSelection={updateSelection}
        isLinked={!!widget.container && !!widgetList.find((w) => w.id === widget.container && w.widgetType === 'LINKED')}
      />

    </div>
  );

  const updateWidgetLayout = (widget) => {
    if (widget) {
      const newData = getWidgetModel(widget).getData();
      updateWidget(newData);
    }
  };

  const updateWidgetLinkedMode = (widget) => {
    if (widget) {
      const widgetCopy = R.clone(widget);
      const newData = getWidgetModel(widgetCopy).getData();
      const widgetValuesCopy = R.clone(widgetValues);
      const actualMode = newData?.config?.custom?.LINKED?.mode;
      if (actualMode === 'MAP') {
        newData.config.custom.LINKED.mode = 'TABLE';
      } else {
        newData.config.custom.LINKED.mode = 'MAP';
      }
      widgetValuesCopy[widget.id] = undefined;
      setWidgetValues(widgetValuesCopy);
      updateWidget(newData);
    }
  };

  const getParentWidget = (widget) => widgetList.find((w) => w.id === widget.container);

  const newWidgetList = useMemo(() => widgetList.filter((w) => !w.container), [widgetList]);

  return (
    <>
      <DashboardMenuTittle
        title={dashboardHook ? dashboardHook.name : ''}
        date={moment(dashboardHook.updatedAt)
          .startOf('minute')
          .fromNow()}
        isResizable={isResizable}
        handleResizable={handleResizable}
        dashboard={dashboardHook}
        entity="dashboard"
        clickView={handleOpenProfileDashboard}
        permissionToEdit={permissionToEdit}
      />

      {profileDashboardOpened && permissionToEdit && (
        <ProfileDashboardComponent
          onChange={handleOnProfileDashboardEvent}
          data={dashboardHook}
          path={path}
          profileType={profileDashboardType}
        />
      )}

      {profileWidgetOpened && activeWidget && permissionToEdit && (
        <ProfileWidgetComponent
          onChange={handleOnProfileWidgetdEvent}
          data={activeWidget}
          path={path}
          profileType={profileWidgetType}
          isLinked={isProfileOpenedWithLinked}
          getParentWidget={getParentWidget}
        />
      )}

      {profileSendingCommandsOpened && activeWidget && permissionToEdit && (
        <ProfileSendingCommandComponent
          onChange={handleOnProfileSendingCommandsEvent}
          data={activeWidget}
          path={path}
          profileType={profileWidgetType}
          width={50}
          checkable
        />
      )}

      <Row>
        <Col>
          {(!dataFullLoaded || !dashboardHook) && <Loading />}

          {dataFullLoaded && dashboardHook && (
            <DragAndDropGrid
              layouts={dashboardHook.layoutProperties}
              onChange={(layout, layouts) => layoutsChange(layouts)}
              isDraggable={isDraggable}
              isResizable={isResizable}
              className="renderWidget"
              onBreakpointChange={(bp) => setCurrentBreakPoint(bp)}
            >
              {newWidgetList.map((widget, index, array) => {
                const zIndex = widget.widgetType === 'IMAGE' ? array.length - index : '';
                const widgetIn = getContainedWidgets(widget, widgetList);
                const widgetLayout = dashboardHook.layoutProperties[currentBreakpoint]
                  .find((l) => l.i === widget.id);
                return (
                  <div id={widget.id} key={widget.id} style={{ zIndex }}>
                    <RenderWidget
                      refreshMap={refreshMap}
                      setRefreshMap={setRefreshMap}
                      widget={widget}
                      index={index}
                      array={array}
                      dataWidgetRead={dataWidgetRead}
                      wValues={widgetValues}
                      profileType={profileType}
                      handleOpenProfileWidget={handleOpenProfileWidget}
                      isResizable={isResizable}
                      setWidgetValues={setDataFromWidget}
                      handleOpenProfileSendingCommands={handleOpenProfileSendingCommands}
                      permissionToEdit={permissionToEdit}
                      hasBeenResized={hasBeenResized}
                      containedWidgets={widgetIn}
                      renderImageWidgets={renderImageWidgets}
                      updateWidgetImageLayout={updateWidgetLayout}
                      renderLinkedWidgets={renderLinkedWidgets}
                      updateWidgetLinkedLayout={updateWidgetLayout}
                      dashboard={dashboardHook}
                      updateWidgetLinkedMode={updateWidgetLinkedMode}
                      selectLinkedWidget={selectLinkedWidget}
                      updateGlobalSelection={updateSelection}
                      widgetSize={{ w: widgetLayout.w, h: widgetLayout.h }}
                    />

                  </div>
                );
              })}
            </DragAndDropGrid>
          )}
          {runSocket && (
          <Websocket
            url={websocketConnection.urlAPI.concat(
              websocketConnection.paths.socket,
            )}
            onMessage={getAttributesByWebSocket}
            onOpen={() => handleOpen(WSocket)}
            reconnect
            debug={false}
            // eslint-disable-next-line no-shadow
            ref={(Websocket) => {
              WSocket = Websocket;
            }}
          />
          )}
          {permissionToEdit && (
            <PrivateComponent
              checkLogin
              checkPermission
              microsService="/app/widget"
              permission="create"
            >
              <CreateWidgets dashboard={dashboardHook} />
            </PrivateComponent>
          )}

          <ModalAddDashboard toggle={isShowingWidgetModal} />
        </Col>
      </Row>
    </>
  );
};

Show.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
  devices: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  updatedWidget: PropTypes.bool,
  widgetData: PropTypes.arrayOf(PropTypes.any),
  widgetDataV2: PropTypes.arrayOf(PropTypes.any),
  errorFetching: PropTypes.bool,
  fetching: PropTypes.bool,
  path: PropTypes.string,
  profileType: PropTypes.string,
};

Show.defaultProps = {
  dashboard: {},
  devices: [],
  id: '',
  updatedWidget: undefined,
  widgetData: [],
  widgetDataV2: {},
  errorFetching: undefined,
  fetching: undefined,
  path: '',
  profileType: '',
};

const mapStateToProps = (state) => ({
  fetching: state.get('dashboards').get('fetching'),
  dashboard: state.get('dashboards').get('readDashboard'),
  updatedWidget: state.get('widgets').get('updatedWidget'),
  errorFetching: state.get('dashboards').get('errorFetching'),
  widgetData: state.get('historicals').get('historicalList'),
  widgetDataV2: state.get('historicals').get('historicalListV2'),
  devices: state.get('devices').get('devicesList'),
});

export default connect(
  mapStateToProps,
  {},
)(injectIntl(withTheme(Show)));
