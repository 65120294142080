import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withTheme } from '../../../../Contexts/ThemeContext';
import BasicList from '../../../helpers/BasicList';
import { getUsersGroups } from '../../../../services/redux/usersGroups/actions';
import UsersGroup, { SampleUsersGroup } from '../../../../models/UsersGroup';
import WithDrawer from '../../../../components/DrawerContainer/index';
import Profile from '../Profile';
import CreateUsersGroup from '../Add/AddWizard';
import OptionsForRow from './OptionsForRows';
import RemoveHoc from '../../../helpers/RemoveComponent';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';

const headers = [
  {
    label: SampleUsersGroup.translations.name,
    key: 'name',
  },
  {
    label: SampleUsersGroup.translations.description,
    key: 'description',
  },
];
const RemoveComponent = RemoveHoc(({ showModal }) => (
  <PrivateComponent
    checkLogin
    checkPermission
    microsService="/app/groups"
    permission="delete"
  >
    <i onClick={showModal} className="uil uil-trash-alt remove" />
  </PrivateComponent>
),
{ class: UsersGroup, nameData: 'usersGroups' });

const filters = [
  {
    name: SampleUsersGroup.translations.name,
    value: 'name',
    key: 'name',
  },
  {
    name: SampleUsersGroup.translations.description,
    value: 'description',
    key: 'description',
  },
];

const ProfileComponent = WithDrawer(
  ({
    onChange, data, enableChangePassword, ...rest
  }) => (
    <Profile
      {...rest}
      onChange={onChange}
      data={data}
      enableChangePassword={enableChangePassword}
    />
  ),
);

class List extends React.Component {
  state = {
    page: 1,
    itemsPerPage: 5,
    itemsShowed: 5,
  };

  componentDidMount() {
    const { page, itemsPerPage } = this.state;
    getUsersGroups({ filters: [], page, itemsPerPage });
  }

  isLoggedUser = (activeUser) => activeUser.id === this.props.userMe.id;

  handleOnSubmit = (filters2) => {
    const { page, itemsPerPage } = this.state;
    getUsersGroups({ filters2, page, itemsPerPage });
  };

  handleOnPageChange = (page, itemsPerPage, itemsShowed) => {
    this.setState({
      page,
      itemsPerPage,
      itemsShowed,
    });
  };

  render() {
    const { total, usersGroups } = this.props;
    return (
      <>
        <BasicList
          {...this.state}
          data={usersGroups}
          total={total}
          onSubmit={this.handleOnSubmit}
          entityName="usersGroups"
          entity={UsersGroup}
          exampleEntity={SampleUsersGroup}
          header={headers}
          profileComponent={ProfileComponent}
          createEntityComponent={(props) => <CreateUsersGroup {...props} className="newWizard" />}
          filters={filters}
          externalFilter={false}
          expandable
          selectable
          onPageChange={this.handleOnPageChange}
          optionsForRow={OptionsForRow}
          onRemove={RemoveComponent}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersGroups: state
    .get('usersGroups')
    .get('list')
    .toJS(),
  total: state.get('usersGroups').get('total'),
  userMe: state.get('users').get('userMe'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(injectIntl(List)));
