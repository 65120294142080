import React from 'react';
import { Context } from './provider';

const Consumer = (Component) => {
  class withContext extends React.PureComponent {
    render() {
      const infoFromProvider = this.context;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...this.props} {...infoFromProvider} />
      );
    }
  }
  withContext.contextType = Context;

  return withContext;
};

export default Consumer;
