import React from 'react';
import SuccessComponent from '../../../pages/helpers/SuccessPage';
import RuleOkIcon from '../../../configuration/icons/svg/rule-ok.svg';
import { FormattedMessage } from '../../../Contexts/LanguageContext';

const Success = () => (
  <SuccessComponent
    title={<FormattedMessage id="menuElement.wizard.success" />}
    icon={RuleOkIcon}
  />
);

export default Success;
