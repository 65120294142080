/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import './style.scss';
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl';
import styled from 'styled-components';
import TrackingLayers from './components/TrackingLayers';
import Header from './components/Header';
import WrapperDiv from './components/WrapperMap';
import colors from '../../configuration/colors';

import './styles.scss';
// eslint-disable-next-line import/no-cycle
import InfoPanel from './InfoPanel';
import bullet from '../../configuration/icons/svg/ico-active-bullet.svg';
import layerPaintSolid from './helpers/heatMapPainter';
import mapStyles from './helpers/constants';

import usePrevious from '../../pages/helpers/Hooks/usePrevious';
import useLocalContext from '../../pages/helpers/Hooks/useLocalContext';

export const SATELLITE = 'satellital';

const ActionsWrapper = styled.div`
  float: right;
  color: ${colors['ui-Black']};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -10px;
`;

const TokenMap = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoianVhbmFuOTEiLCJhIjoiY2pvc2ZvZ2pnMDQzczNrcWZjeHcxYWhmYiJ9.MCr17mXwiNhzw37MucyfbQ',
  minZoom: 1,
  maxZoom: 20,
  logoPosition: 'top-left',
  attributionControl: false,
});

const mapStyle = {
  overflow: 'visible',
  flex: 1,
  width: '100%',
  height: 'calc(100% - 36px)',
  borderBottomLeftRadius: '10px',
};

// Define layout to use in Layer component
const layoutLayer = { 'icon-image': 'fiwooDevice' };

// Create an image for the Layer
const image = new Image();
image.src = bullet;
const images = ['fiwooDevice', image];

const flyToOptions = {
  speed: 0.8,
};

const getInitialCenter = (data, center) => {
  let initialCenter = center;
  if (data && data.length > 0 && data[0].location.y !== '0') {
    initialCenter = [parseFloat(data[0].location.y), parseFloat(data[0].location.x)];
  }

  return initialCenter;
};

const WidgetMap = (props) => {
  const {
    zoom: _zoom,
    data: _data,
    center: _center,
    configuration,
    title,
    actions: Actions,
    widgetType,
    connectedDevices,
    refreshMap,
    setRefreshMap,
    config,
  } = props;
  const data = widgetType === 'HEATMAP' ? _data.data : _data;
  const { type } = config.data;
  const { viewType } = config?.custom.MAP ?? 'default';

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(getInitialCenter(data, _center));
  const [zoom, setZoom] = useState(_zoom);
  const [devices, setDevices] = useState(data);
  const [point, setPoint] = useState(null);
  const [styleMap, setStyleMap] = useState(
    viewType === SATELLITE ? mapStyles.sat : mapStyles.light,
  );
  const [realTime, setRealTime] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [min, setMin] = useState(configuration.minValue);
  const [max, setMax] = useState(configuration.maxValue);
  const [historicals, setHistoricals] = useState(false);

  const [device, setDevice] = useState(null);

  const prevProps = usePrevious(props);
  const prevState = usePrevious({ devices });

  const ctx = useLocalContext({ devices, device, point });

  const getFirstCoordenates = useCallback(() => {
    if (selectedDevices.length > 0) {
      return [selectedDevices[0].location.y, selectedDevices[0].location.x];
    }
    return [data[0].location.y, data[0].location.x];
  }, [data, selectedDevices]);

  const getUniqueFeatures = (array, comparatorProperty) => {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    const uniqueFeatures = array.filter((el) => {
      if (existingFeatureKeys[el.properties[comparatorProperty]]) {
        return false;
      }
      existingFeatureKeys[el.properties[comparatorProperty]] = true;
      return true;
    });

    if (ctx.devices) {
      return ctx.devices.filter((d) => {
        let found = false;
        uniqueFeatures.forEach((f) => {
          if (f.properties.dataId === d.id) found = true;
        });
        return found;
      });
    }
    return null;
  };

  const getSelectedDevices = (_map) => getUniqueFeatures(_map.querySourceFeatures('marker'), 'id');

  const getCenter = (_map) => {
    const newCenter = _map.getCenter();
    return [newCenter.lng, newCenter.lat];
  };

  const onToggleHover = (cursor) => {
    map.getCanvas().style.cursor = cursor;
  };

  const markerClick = (_point, _device) => {
    if (map.getZoom() < 16) setZoom([16]);

    setPoint(_point);
    setDevice(_device);
    setCenter(
      _device && _device.location
        ? [parseFloat(_device.location.y), parseFloat(_device.location.x)]
        : [0, 0],
    );
  };

  const outMarkerClick = () => {
    setPoint(null);
    setDevice(null);
  };

  const onDrag = (_map) => {
    if (point) {
      setPoint(undefined);
    }
    setSelectedDevices(getSelectedDevices(_map));
    setCenter(getCenter(_map));
  };

  const getFeatures = (deviceData, _point, index) => {
    const {
      location: { y, x },
      id,
    } = deviceData;
    const newDeviceData = Object.assign(
      deviceData,
      connectedDevices.find((o) => o.id === deviceData.id),
    );
    return (
      <Feature
        key={id || `Feature-${index}`}
        onMouseEnter={() => onToggleHover('pointer')}
        onMouseLeave={() => onToggleHover('')}
        onClick={() => markerClick(_point, newDeviceData)}
        coordinates={[y, x]}
        properties={{ id, dataId: id }}
      />
    );
  };

  const onStyleLoad = (_map) => {
    const waiting = () => {
      if (!_map.isStyleLoaded()) {
        setTimeout(waiting, 200);
      } else {
        const newSelected = getSelectedDevices(_map);
        setMap(_map);
        setSelectedDevices(newSelected);
      }
    };
    waiting();
  };

  const onZoomEnd = (_map) => {
    setSelectedDevices(getSelectedDevices(_map));
    setCenter(getCenter(_map));
  };

  const onUpdate = () => {

  };

  useEffect(() => {
    if (map) {
      // map.resize();
      setRefreshMap(false);
    }
  }, [refreshMap]);

  useEffect(() => {
    if (type === 'historical') setHistoricals(true);

    if (type === 'last-value' || configuration.realTime || type === 'real-time') setRealTime(true);

    if (type === SATELLITE) setStyleMap(mapStyles.sat);

    if (data?.length > 0 && data[0].location.y !== '0') setCenter([data[0].location.y, data[0].location.x]);

    data && setDevices([...data]);
  }, []);

  useEffect(() => {
    if (
      configuration.tracking
      && prevState
      && prevState.devices[0] !== devices[0]
      && data[0]
    ) {
      if (devices[0].location && devices[0].location.y !== '0') setCenter(getFirstCoordenates());
    }
  }, [configuration]);

  useEffect(() => {
    if (realTime && !Object.is(prevProps.data, _data)) {
      setDevices(widgetType === 'HEATMAP' ? _data.data : _data);
      setMin(data.minValue || 0);
      setMax(data.maxValue || 0);
    }
    if (configuration.tracking) {
      if (data.length > 0 && data[0].location.y !== '0') setCenter([data[0].location.y, data[0].location.x]);
    }
  }, [data]);

  useEffect(() => {
    setStyleMap(viewType === SATELLITE ? mapStyles.sat : mapStyles.city);
  }, [viewType]);

  const tempRange = [
    Number(min) - 2,
    (Number(min) + Number(max)) / 2,
    Number(max) + 2,
  ];

  const filteredDevices = devices?.filter(
    (dev) => dev && Object.keys(dev).includes('location'),
  ) ?? [];

  return (
    <WrapperDiv>
      {title && (
        <Header>
          {title}
          {' '}
          {Actions && (
            <ActionsWrapper>
              <Actions selectedDevices={selectedDevices} />
            </ActionsWrapper>
          )}
          {' '}
        </Header>
      )}

      <TokenMap
        ref={setMap}
        style={styleMap}
        onStyleLoad={onStyleLoad}
        fitBounds={false}
        zoom={zoom}
        containerStyle={mapStyle}
        center={center}
        onDrag={onDrag}
        flyToOptions={flyToOptions}
        onZoomEnd={onZoomEnd}
        onUpdate={onUpdate}
      >
        <ZoomControl />

        {widgetType === 'HEATMAP' && (
          <Layer
            type="circle"
            paint={layerPaintSolid(tempRange)}
            logoPosition="top-left"
          >
            {devices && devices[0] && devices[0].location && devices[0].location.y !== '0' && devices.map((deviceData) => (
              <Feature
                key={deviceData.id}
                properties={deviceData.heatmap}
                coordinates={[deviceData.location.y, deviceData.location.x]}
              />
            ))}
          </Layer>
        )}

        {historicals
          && devices.map((deviceData) => <TrackingLayers device={deviceData} />)}

        <Layer
          type="symbol"
          id="marker"
          layout={layoutLayer}
          images={images}
          logoPosition="top-left"
        >
          {filteredDevices && filteredDevices[0]
            && filteredDevices[0].location.y !== '0'
            && filteredDevices
              .map((deviceData, index) => getFeatures(deviceData, ctx.point, index))}
        </Layer>
        {device
          && (
          <InfoPanel
            device={devices.find((o) => o.id === device.id)}
            onMarkerClick={outMarkerClick}
            widgetType={widgetType}
          />
          )}
      </TokenMap>
    </WrapperDiv>
  );
};

WidgetMap.defaultProps = {
  zoom: [15],
  center: [-3.70325, 40.4167],
  viewType: mapStyles.city,
  data: [],
  config: { data: { type: '', start_date: '' } },
  configuration: {
    tracking: false,
    realTime: false,
  },
};

export default WidgetMap;
