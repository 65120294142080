/* eslint-disable import/no-cycle */
import { getRandomColor } from '../../../../../pages/Widgets/Add/components/utils';

export const HeatMapDefaultConfiguration = {
  data: {
    startDate: '',
  },
  colors: { },
  labels: { },
  tendlines: { },
  conditions: { },
  appearance: { },
  effects: { },
  custom: {
    viewType: 'from-date',
  },
};

export default HeatMapDefaultConfiguration;

export const injectHeatMapData = (widget) => {
  const newConfig = { ...widget.config };
  const categoryColors = {};
  const categories = [];
  widget.sources.forEach((source) => {
    if (!source.categories) {
      categories.push('OTHER');
      categoryColors.OTHER = {
        color: getRandomColor(),
        mode: '',
        range: '',
        element: [],
      };
    } else {
      source.categories.forEach((category) => {
        if (!categories.includes(category)) {
          categories.push(category);
          categoryColors[category] = {
            color: getRandomColor(),
            mode: '',
            range: '',
            element: [],
          };
        }
      });
    }
  });

  newConfig.conditions.categoryColors = categoryColors;

  return newConfig;
};
