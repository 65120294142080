import React from 'react';
import { OptionStyle, OptionStyleSubtitle } from './AuxiliarStyles'

class Option extends React.Component{
  render(){
    const {children} = this.props;

    return (this.props.subtitle ? 
      <OptionStyleSubtitle onClick={this.props.onClick}> {children} </OptionStyleSubtitle>
      :
      <OptionStyle onClick={this.props.onClick}> {children} </OptionStyle>
    );
  }
}

export default Option;