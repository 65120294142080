import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import './styles.scss';

const SwitchDataSourceMode = ({ className, mode, onChangeMode }) => (
  <div className={`SwitchDataSourceModeSelector ${className}`}>
    <button
      type="button"
      onClick={() => onChangeMode('simple')}
      className={mode === 'simple'
        ? 'SwitchDataSourceModeOption active'
        : 'SwitchDataSourceModeOption'}
    >
      <span>
        <FormattedMessage id="kpis.wizard.type.automatic" />
      </span>
    </button>
    <button
      type="button"
      onClick={() => onChangeMode('manual')}
      className={mode === 'manual'
        ? 'SwitchDataSourceModeOption active'
        : 'SwitchDataSourceModeOption'}
    >
      <span>
        <FormattedMessage id="kpis.wizard.type.manual" />
      </span>
    </button>
  </div>
);

SwitchDataSourceMode.propTypes = {
  mode: PropTypes.string,
  onChangeMode: PropTypes.element,
  className: PropTypes.string,
};

SwitchDataSourceMode.defaultProps = {
  mode: 'simple',
  onChangeMode: '',
  className: '',
};

export default SwitchDataSourceMode;
