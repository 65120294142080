import Styled from 'styled-components';
import PropTypes from 'prop-types';

const FlexInliner = Styled('div')`
    display: flex;
    align-items: ${p=>p.alignItem};
    justify-content: ${p=>p.justifyContent}; 
    
    ${p=>p.css}
`;

FlexInliner.defaultProps = {
    justifyContent: 'center',
    alignItem: 'center',
};

FlexInliner.propTypes = {
    justifyContent: PropTypes.string,
    alignItem: PropTypes.string
};

export default FlexInliner;