import { userManagement } from '../../configuration/config';
import { requestAPI } from '../../helpers';
import { exampleUsers, exampleRoles, exampleUserMe } from './exampleGenerator';

const endPoint = userManagement.urlAPI.concat(userManagement.paths.user);

const parseFilters = data => {

  const filters = data[0];

  let params = '?';

  if (filters) {

    if (filters.filters && filters.filters.length > 0) {
      filters.filters.forEach(filter => {
        params += `${filter.key}=${filter.term}&`;
      });
    }

    params += `page=${filters.page}&itemsPerPage=${filters.itemsPerPage}`
  }

  return params;
};

export const readUsers = filters => {
  const params = { endPoint: endPoint + parseFilters(filters), statusOK: 200 };
  return requestAPI(params, exampleUsers);
};

export const readUser = async user => {
  if (!user['id']) return { status: 400, error: 'invalid id attr' };
  const params = { endPoint: endPoint + '/' + user.id, statusOK: 200 };
  let answer = await requestAPI(params, exampleUsers);
  if (answer['status'] === params.statusOK)
    answer.data.roles = await readUserRoles(user.id);
  return answer;
};

export const readUserRoles = async user => {
  if (!user['id']) return { status: 400, error: 'invalid id attr' };
  const params = {
    endPoint: endPoint + '/' + user.id + '/roles',
    statusOK: 200
  };
  return requestAPI(params, exampleRoles);
};

export const addUser = async user => {
  if (user['id']) return { status: 400, error: 'invalid id attr' };
  const params = {
    endPoint: endPoint,
    statusOK: 201,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(user)
    }
  };
  return await requestAPI(params, exampleUsers);
};

export const updateUser = user => {
  if (!user['id']) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: endPoint + '/' + user.id,
    statusOK: 200,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(user)
    }
  };
  return requestAPI(params, exampleUsers);
};

export const resetPassword = user => {
  if (!user['id']) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: endPoint + '/' + user['id'] + '/password/reset',
    statusOK: 201,
    requestConfig: {
      method: 'POST'
    }
  };
  return requestAPI(params, exampleUsers);
};

export const changePassword = user => {
  if (!user['id']) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: endPoint + '/me/password/update',
    statusOK: 201,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(user)
    }
  };
  return requestAPI(params, exampleUsers);
};

export const deleteUser = user => {
  if (!user['id']) return { status: 400, error: 'missing id attr' };
  const params = {
    endPoint: endPoint + '/' + user.id,
    statusOK: 204,
    requestConfig: {
      method: 'DELETE'
    }
  };
  return requestAPI(params, exampleUsers);
};

export const readUserMe = async () => {
  const params = { endPoint: endPoint + '/me', statusOK: 200 };
  let answer = await requestAPI(params, exampleUserMe);
  return answer;
};
