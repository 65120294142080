import * as TYPES from './types';
import { store } from '../store';

export const addUser = User =>
  store.dispatch({
    type: TYPES.ADD_USER_REQUEST,
    payload: User
  });

export const addUserSuccessFlag = () =>
  store.dispatch({
    type: TYPES.ADD_USER_SUCCESS_FLAG
  });

export const updateUser = User =>
  store.dispatch({
    type: TYPES.MODIFY_USER_REQUEST,
    payload: User
  });
export const changePassword = User =>
  store.dispatch({
    type: TYPES.MODIFY_USER_PASSWORD_REQUEST,
    payload: User
  });

export const modifyUserSuccessFlag = () =>
  store.dispatch({
    type: TYPES.MODIFY_USER_SUCCESS_FLAG
  });

export const resetUserPassword = id =>
  store.dispatch({
    type: TYPES.RESET_USER_PASSWORD_REQUEST,
    payload: id
  });

export const deleteUser = User =>
  store.dispatch({
    type: TYPES.DELETE_USER_REQUEST,
    payload: User
  });

export const getUser = User =>
  store.dispatch({
    type: TYPES.GET_USER_REQUEST,
    payload: User
  });

export const getUsers = (...filters) =>
  store.dispatch({
    type: TYPES.GET_USERS_REQUEST,
    payload: filters
  });

export const getUserRoles = User =>
  store.dispatch({
    type: TYPES.GET_USER_ROLES_REQUEST,
    payload: User
  });

export const errorFetchingUserReset = () =>
  store.dispatch({
    type: TYPES.ERRORFETCHING_USER_RESET
  });

export const getUserMe = () =>
  store.dispatch({
    type: TYPES.GET_USER_ME_REQUEST
  });
