import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS, es, enGB } from 'date-fns/locale';
import { injectIntl } from 'react-intl';
import Input from './Input';
import MultiSelect from './MultiSelect';
import Select from './Select';
import Textarea from './Textarea';
import ColorPicker from '../../components/ColorPicker';
import './styles.scss';
import { FormattedMessage } from '../../Contexts/LanguageContext';
import TwitterTextarea from './TwitterTextarea';
import CustomSelect from './CustomSelect/index';
import 'react-datepicker/dist/react-datepicker.css';
import ComplexTextarea from './ComplexTextarea/ComplexTextarea';
import AutoComplete from './AutoComplete';
import { secondaryTypography } from '../../configuration/fonts';
import colors from '../../configuration/colors';

const locales = {
  'en-US': enUS,
  'en-GB': enGB,
  es,
};

for (const [key, value] of Object.entries(locales)) {
  registerLocale(key, value);
}

/* Para ajustar al estilo de [DE-6.3.1.1], se indicó en la siguiente constante
    width: 60%;
    max-width: 800px;
    margin: 0 auto;
  pero estos cambios afectaban al resto de inputs de fiwoo, por lo que ha revertido, a la
  espera de ver cómo aplicarlo al resto de wizards. */

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6em;
  position: relative;
  outline: transparent 1px solid;

  & > * {
    flex: 0 1 auto;
  }
  &.innerLabel {
    margin-top: 21px;
    border: 1px solid #E0E7FF;
    background: #F9FAFC;

  }
  &.innerLabel > label {
    position: absolute;
    top: -15px;
    left: 10px;
    background-color: ${(props) => props.innerLabel || ''};
    color: ${colors['ui-Black2']};
    font-family: ${secondaryTypography};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    padding: 5px;
    text-transform: none;
  }
`;

export const Label = styled.label``;

const Helper = styled.div`
  font-size: 0.75em;
  color: #999;
  position: relative;
  margin: 0;
  top: 0.6em;
  line-height: 0.9em;
  &.error {
    color: #e12c50;
  }
  
`;

const Control = ({
  type, rows, mode, ...props
}) => {
  let control;
  switch (type) {
    case 'select':
      control = <Select {...props} />;
      break;
    case 'multiselect':
      control = <MultiSelect {...props} />;
      break;
    case 'textarea':
      control = <Textarea {...props} rows={rows = 8} mode={mode} />;
      break;
    case 'complexTextarea':
      control = <ComplexTextarea {...props} mode={mode} />;
      break;
    case 'twitterTextarea':
      control = <TwitterTextarea {...props} rows={rows} mode={mode} />;
      break;
    case 'complexTwitterTextarea':
      control = <TwitterTextarea {...props} complex />;
      break;
    case 'customSelect':
      control = <CustomSelect {...props} mode={mode} />;
      break;
    case 'color':
      control = <ColorPicker {...props} mode={mode} />;
      break;
    case 'date':
      control = <DatePicker locale={props.intl.locale} selected={props.value} onChange={(value) => props.onChange(null, props.name, value)} />;
      break;
    case 'autoComplete':
      control = <AutoComplete {...props} />
      break;
    default:
      control = <Input {...props} type={type} mode={mode} autocapitalize="none" />;
  }

  return control;
};

const types = [
  'multiselect',
  'customSelect',
  'textarea',
  'twitterTextarea',
  'text',
  'password',
  'hidden',
  'number',
  'select',
  'button',
  'date',
  'autoComplete',
];

/**
 This React component is designed to wrap different types of controls (input, selects ...) under a homogeneous look and feel.
 All Fields have a label and a controller component. Allows the implementation of native html attributes, in addition to
 the basic html inputs (text, number, password), specifying it, explicitly, as part of the component.

 <Field name="textInput" id="MyTextInput" value="" type="text" placeholder="Your name here..." />

 In case of MultiSelect Fields components, it is necessary to implement the 'id' prop, to retrieve values ​​(array of strings),
 however, for the rest of components, only the declaration of the 'name' property is necessary.  */

class Field extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      oldValue: '',
      error: this.props.error,
      mode: this.props.mode,
      errorStyle: '',
    };
  }

  static propTypes = {
    /** Style of different state */
    mode: PropTypes.oneOf(['edit', 'view', 'insert']),

    /** The value of the control, when it's not a multiselect */
    value: PropTypes.any,
    /** The helper text of the control. It can be an error message or an informative one */
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

    /** This prop check if the value or its format is correct or not, normally after submit. It could be true or false. */
    error: PropTypes.bool,

    /** The type of control that it will render. Currently, it supports all native types of html input controllers (text, number, date...) and a 'multiselect' version */
    type: PropTypes.oneOf(types).isRequired,

    /** The placeholder of the control. In the case of the multiselect, this will be the text that will be displayed in the filter search */
    placeHolder: PropTypes.string,

    /** The id of the control. */
    id: PropTypes.string,

    /** The label of the field */
    label: PropTypes.oneOfType([
      PropTypes.instanceOf(FormattedMessage),
      PropTypes.string,
    ]),

    /** The data of the multiselect. Required in this case. */
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),

    /** Default or selected values in multiselect */
    selectedOptions: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),

    /** Default or selected values in simple select */
    selectedOption: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ]),
    ),

    /** Options availables for simple Select */
    options: PropTypes.arrayOf(PropTypes.object),

    /** The name of the control. This is required. */
    name: PropTypes.string.isRequired,

    /** The behaviour of the component when it changes */
    onChange: PropTypes.func,

    /** If this props is declared, multiselects' options will show a concat string with de values of the keys from this array */
    formatOption: PropTypes.arrayOf(PropTypes.string),

    /** If the field is a simple select, it specifies whether the data is brought in asynchronously. */
    async: PropTypes.bool,

    /** Event that is triggered when scrolling in the simple select options list. */
    onScroll: PropTypes.func,

    /** If simple select is in loading mode */
    loading: PropTypes.bool,

    innerLabel: PropTypes.string,
  };

  static defaultProps = {
    helperText: '',
    error: false,
    type: 'text',
  };

  errorClass = 'error';

  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }

    if (prevProps.mode !== this.props.mode) {
      this.setState({
        mode: this.props.mode,
      });
    }
  };

  onChange = (e = null, id = '', value = undefined) => {
    if (
      this.props.type === 'multiselect'
      || this.props.type === 'select'
      || this.props.type === 'customSelect'
      || this.props.type === 'date'
    ) {
      this.props.onChange && this.props.onChange(id, value);
    } else if (this.props.type === 'autoComplete') {
      this.props.onChange && this.props.onChange(id, value, e);
    } else if (this.props.type === 'text' && (this.props.value === 'Otra' || this.props.value === 'Other')) {
        return;
    }
    else {
      this.props.onChange && this.props.onChange(e);
      if (e && e.target) {
        this.setState({
          value: e.target.value,
        });
      } 
    }
  };

  onBlur = (e) => {
    if (this.props.onBlur || e.target.value !== this.state.value) {
      this.updateValue(e);
      this.props.onBlur && this.props.onBlur(e);
      this.props.onChange && this.props.onChange(e);
      if (this.state.oldValue === this.state.value) this.setState({ mode: this.originalMode() });
    }
  };

  originalMode = () => this.props.mode;

  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick } = this.props;
    if (onClick) onClick(e);
    else if (this.state.mode === 'view') this.setState({ mode: 'edit', oldValue: this.state.value });
  };

  updateValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      {
        mode: this.originalMode(),
      },
      () => this.props.save
        && this.props.save({ ...this.state, name: this.props.name }),
    );
  };

  // TODO: deprecated?

  // updateCancel = e => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.setState(
  //     {
  //       mode: this.originalMode(),
  //       value: this.state.oldValue,
  //     },
  //     () =>
  //       this.props.onCancel &&
  //       this.props.onCancel({ ...this.state, name: this.props.name })
  //   );
  // };
  //
  // getClassName = () => {
  //   const { error, type, className } = this.props;
  //   return `field ${className ? className : ''} ${
  //     error ? this.errorClass : ''
  //   } ${this.state.mode ? this.state.mode : 'insert'} ${type}`;
  // };

  render() {
    const {
      helperText, error, type, disabled, className, innerLabel,
    } = this.props;

    return (
      <Wrapper
        className={`field ${className || ''} ${
          error ? this.errorClass : ''
        } ${this.state.mode ? this.state.mode : 'insert'} ${type} ${innerLabel ? 'innerLabel' : ''}`}
        innerLabel={innerLabel}
      >
        {this.props.label && (
          <Label htmlFor={this.props.name}>{this.props.label}</Label>
        )}
        {/*
                {
                    this.state.mode === "edit" &&
                    <ButtonsArea>
                        <Button type="button" color={'default'} css={styleButton} onClick={this.updateValue}>&#10003;</Button>
                        <Button type="button" color={'default'} css={styleButton} onClick={this.updateCancel}>&#10005;</Button>
                    </ButtonsArea>
                } */}
        <div tabindex={0} className={`innerField ${disabled ? 'disabled' : ''} ${innerLabel ? 'innerLabel' : ''}`}>
          <Control
            {...this.props}
            mode={this.state.mode}
            onBlur={this.onBlur}
            onChange={this.onChange}
            value={this.state.value}
            onClick={this.onClick}
          />
        </div>
        {helperText !== '' && (
          <Helper className={`helper ${error ? 'error' : ''}`}>
            {Array.isArray(helperText)
              ? helperText.map((h, i) => (
                <span key={i}>
                  {h}
                  {' '}
                  <br />
                </span>
              ))
              : helperText}
          </Helper>
        )}
      </Wrapper>
    );
  }
}

export default injectIntl(Field);
