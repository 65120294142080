import React, { useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import SearchBar from './SearchBar';
import ListLinkedWidgets from './ListLinkedWidgets';
import filters from './categories';
import useDashboardContext from '../../../../../Contexts/DashboardContext/consumer';
// eslint-disable-next-line import/no-cycle
import { getContainedWidgets } from '../../../../Dashboards/Show/Show';
import './styles.scss';
import { isShowV2 } from '../../../../../helpers/utils';

function EditLinkedWidgets(props) {
  const { data, dispatchState } = props;
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const {
    activeWidget,
    widgetList,
  } = useDashboardContext();

  // ToDo Remove when implement ShowV2
  const containedWidgets = useMemo(() => (isShowV2()
    ? data.containedWidgets
    : getContainedWidgets(
      activeWidget,
      widgetList,
    )), [activeWidget, widgetList, data]);
  const [widgetsFilteredByType, setWidgetsFilteredByType] = useState(containedWidgets);
  const [widgetsFilteredByName, setWidgetsFilteredByName] = useState(containedWidgets);
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleInputChange = (value) => {
    if (value !== textFieldValue) setTextFieldValue(value);
    setWidgetsFilteredByName(widgetsFilteredByType.filter(
      (widget) => widget.name.toLowerCase().includes(value.toLowerCase()),
    ));
  };

  const onListClick = (event, filter) => {
    setSelectedFilter(filter);
    const filteredByType = filter.value === 'ALL'
      ? containedWidgets
      : containedWidgets.filter((widget) => widget.widgetType === filter.value);
    setWidgetsFilteredByType(filteredByType);
    const filteredByName = filteredByType.filter(
      (widget) => widget.name.toLowerCase().includes(textFieldValue.toLowerCase()),
    );
    setWidgetsFilteredByName(filteredByName);
  };

  return (
    <div className="editLinkedWidgetsContainer">
      <SearchBar
        filters={filters}
        selectedFilter={selectedFilter}
        onListClick={onListClick}
        textFieldValue={textFieldValue}
        handleInputChange={handleInputChange}
      />
      <ListLinkedWidgets widgets={widgetsFilteredByName} dispatchState={dispatchState} />
    </div>
  );
}

EditLinkedWidgets.propTypes = {

};

EditLinkedWidgets.defaultProps = {

};

export default injectIntl(EditLinkedWidgets);
