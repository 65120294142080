import styled from 'styled-components';

export const OptionStyle = styled.div`
  & > span{
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
`;

export const OptionStyleSubtitle = styled.div`
  & > span{
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-weight: 600;
  color: #2E384D;
  white-space: nowrap;
  line-height: 1.5em;
  margin-bottom: 8px;
  }
  & > span > span.subtitle{
    color: #6C7A88;
    font-style: italic;
    font-weight: normal;
  }
`;


export const OptionSelected = styled.span`

  
`;

export const ListOfOptions = styled.div`
  z-index: 2000;
  position: absolute;
  min-width: 200px;
  width: ${(p) => Math.floor(p.width) + (p.hPadding * 2) || 300}px;
  top: ${(p) => Math.ceil(p.top) + p.vPadding + p.height + 5 || 80}px;
  left: ${(p) => Math.floor(p.left) - p.hPadding}px;

  & .options {
    max-height: 200px;
    height: ${(p) => (Math.floor(p.listHeight) - 100 > 0 ? Math.floor(p.listHeight) - 100 : 200)}px;
  }

`;

export const SelectStyle = styled.div`
  
  cursor:pointer;
  
  ${(p) => p.css}
`;
SelectStyle.defaultProps = {
  translateChild: 30,
};
