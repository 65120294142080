import React from 'react';
import { TextField } from './helpers/helpers';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

const getOptions = entities =>
  entities && Array.isArray(entities) && entities.length > 0
    ? entities.map(o => ({ name: o.name, id: o.id, value: o }))
    : [];

const joinedAttributes = device => {
  return device && Object.entries(device).length > 0
    ? [
        //...device.command_attributes,
        ...device.lazy_attributes,
        //...device.static_attributes,
        ...device.attributes,
      ]
    : undefined;
};

const EditUpdateComponent = ({
  update,
  errors,
  intl,
  onEdit,
  index,
  onChange,
  allDevices,
  fetchInfo,
  onSearch,
  service,
}) => (
  <>
    {['device', 'attribute', 'value'].map((key, i) => {
      const sameServiceDevices = allDevices.filter(
        d => d.fiware.service === service
      );
      const selectedDevice = allDevices.find(
        d => d.device_id === update.deviceId
      );
      const selectedDeviceAttributes = joinedAttributes(selectedDevice);
      const selectedAttribute = selectedDeviceAttributes
        ? selectedDeviceAttributes.find(
            a => a.name === update.attributes[0].name
          )
        : '';

      return (
        <dl key={i}>
          <dt className="col col-md-3">
            <FormattedMessage id={`rules.wizard.${key}.label`} />
          </dt>
          <dd className="col col-md-7">
            {!onEdit ? (
              key === 'device' ? (
                !!selectedDevice ? (
                  selectedDevice.name
                ) : (
                  update.deviceId
                )
              ) : key === 'attribute' ? (
                update.attributes[0].name
              ) : (
                update.attributes[0].value
              )
            ) : (
              <>
                {(() => {
                  switch (key) {
                    case 'device':
                      return (
                        <TextField
                          onChange={(id, value) =>
                            onChange({
                              id,
                              value,
                              index,
                              type: update.type,
                            })
                          }
                          options={getOptions(sameServiceDevices)}
                          value={selectedDevice}
                          errors={errors[key]}
                          dataName={key}
                          type={'select'}
                          intl={intl}
                          async={true}
                          fetchInfo={fetchInfo}
                          onSearch={onSearch}
                        />
                      );
                    case 'attribute':
                      return (
                        <TextField
                          onChange={(id, value) =>
                            onChange({
                              id,
                              value,
                              index,
                              type: update.type,
                            })
                          }
                          options={getOptions(selectedDeviceAttributes)}
                          value={selectedAttribute}
                          errors={errors[key]}
                          dataName={key}
                          type={'select'}
                          intl={intl}
                        />
                      );

                    case 'value':
                      return (
                        <TextField
                          onChange={data =>
                            onChange({
                              id: data.target.name,
                              value: data.target.value,
                              index,
                              type: update.type,
                            })
                          }
                          value={update.attributes[0].value}
                          errors={errors[key]}
                          dataName={key}
                          type={'text'}
                          intl={intl}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </>
            )}
          </dd>
        </dl>
      );
    })}
  </>
);

export default EditUpdateComponent;
