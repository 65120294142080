/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Field from '../../../../../elements/Field/index';

import '../../styles.scss';
import { MATH_OPTIONS, GEO_OPTIONS } from '../../../helpers/operatorOptions';

export const TextField = ({
  onChange,
  intl,
  value,
  errors,
  dataName,
  type,
  helperText,
  ...rest
}) => [dataName].map((key) => (
  <Field
    {...rest}
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value}
    type={type}
    onChange={onChange}
    key={key}
    helperText={errors || helperText}
    error={errors ? !!errors : false}
  />
));

const OperatorsSelect = ({
  onChange,
  intl,
  value,
  errors,
  attribute,
  entity,
}) => ['operator'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value}
    type="select"
    // eslint-disable-next-line no-use-before-define
    options={attribute.variable !== '' ? getOptions(entity, attribute) : []}
    onChange={onChange}
    key={key}
    helperText={errors}
    error={errors ? !!errors : false}
  />
));

const TimeSelect = ({
  onChange,
  intl,
  value,
  errors,
  options,
}) => ['unit'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value}
    type="select"
      // eslint-disable-next-line no-use-before-define
    options={options}
    onChange={onChange}
    key={key}
    helperText={errors}
    error={errors ? !!errors : false}
  />
));

const isGeoAttribute = (device, attribute) => {
  const { attributes } = device;
  if (attributes.length > 0) {
    const found = attributes.find((a) => a.name === attribute.variable);
    return found ? ['point', 'geo:point'].includes(found.type) : false;
  }
  return false;
};

const getOptions = (device, attribute) => {
  let options = MATH_OPTIONS;

  if (isGeoAttribute(device, attribute)) {
    options = GEO_OPTIONS;
  }

  return options;
};

const ValueField = ({
  onChange, intl, value, errors,
}) => ['value'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value}
    type="text"
    onChange={onChange}
    key={key}
    helperText={errors}
    error={errors ? !!errors : false}
  />
));

const AttributeSelect = ({
  onChange, intl, value, options, errors,
}) => ['variable'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value}
    type="select"
    options={options}
    onChange={onChange}
    key={key}
    mappedBy="name"
    helperText={errors}
    error={errors ? !!errors : false}
  />
));
const DeviceSelect = ({
  onChange, intl, value, options, errors, ...rest
}) => ['device'].map((key) => (
  <Field
    {...rest}
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value}
    type="select"
    options={options}
    onChange={onChange}
    key={key}
    mappedBy="name"
    helperText={errors}
    error={errors ? !!errors : false}
  />
));

export {
  OperatorsSelect,
  AttributeSelect,
  ValueField,
  getOptions,
  isGeoAttribute,
  DeviceSelect,
  TimeSelect,
};
export default TextField;
