import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { SampleMenuElement } from '../../../models/MenuElement/index';
import { getPermissions } from '../../../services/redux/permissions/actions';
import { withWizardStep } from '../../../Contexts/WizardStepContext';

import CommonView from '../../../pages/CommonView';
import Field from '../../../elements/Field';
import CheckBox from '../../../elements/CheckBox';
import { getSettingsMenu } from '../../../services/redux/menuElements/actions';

export class Add extends CommonView {
  constructor(props) {
    super({ props });
    const { newEntity, settingsMenu } = this.props;

    this.state = {
      name: newEntity.name || '',
      id: newEntity.id || '',
      errors: this.props.errors || {},
      onValidation: false,
      setAsHomeElement: !!(settingsMenu && newEntity.id
        && settingsMenu.settings.homeElementId === newEntity.id),
    };
  }

  saveMenuElement = () => {
    const { name, setAsHomeElement } = this.state;
    this.setErrors({});

    if (name !== '') this.props.onContinue({ name, setAsHomeElement });
    else {
      this.setState({
        errors: {
          name: <FormattedMessage id="Validation.presence" />,
        },
      });
    }
  };

  validate = () => {
    this.saveMenuElement();
  };

  componentDidUpdate(prevProps) {
    const { newEntity, settingsMenu } = this.props;

    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach((err) => {
          errors[err.field] = <FormattedMessage id={err.message} />;
        });
        this.setErrors(errors);
      }
    }

    if ((settingsMenu && !prevProps.settingsMenu)
    || (settingsMenu && settingsMenu.settings.homeElementId
    !== prevProps.settingsMenu.settings.homeElementId)) {
      this.updateHomeElement(settingsMenu, newEntity);
    }
  }

  updateHomeElement(settingsMenu, newEntity) {
    this.setState({ setAsHomeElement: settingsMenu.settings.homeElementId === newEntity.id });
  }

  componentDidMount() {
    this.props.onRef(this);
    getSettingsMenu();
  }

  handleFavoriteCheckBox() {
    this.setState((prevState) => ({ setAsHomeElement: !prevState.setAsHomeElement }));
  }

  render() {
    const { intl } = this.props;
    const { errors } = this.state;

    return (
      <>
        <Container>
          <div className="wizardWrapper">
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <Field
                  name="name"
                  label={intl.formatMessage(
                    SampleMenuElement.plainTranslations.name,
                  )}
                  placeholder={intl.formatMessage({
                    id: 'menuElement.wizard.name.placeholder',
                  })}
                  id="name"
                  value={this.state.name}
                  type="text"
                  onChange={this.handleInputChange}
                  helperText={errors.name}
                  error={!!errors.name}
                  key="name"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <CheckBox
                  name="favorite"
                  checked={this.state.setAsHomeElement}
                  label={(
                    <>
                      <b><FormattedMessage id="menu.set.favorite.title" /></b>
                      :
                      {' '}
                      <FormattedMessage id="menu.set.favorite.extra" />
                    </>
)}
                  reverse={false}
                  onClick={() => this.handleFavoriteCheckBox()}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

Add.defaultProps = {
  title: 'title',
  name: '',
  errors: {},
};

const mapStateToProps = (state) => ({
  notify: state.get('notify'),
  settingsMenu: state.get('menuElements').get('settingsMenu'),
});

export default connect(mapStateToProps, { getPermissions })(injectIntl(withWizardStep(Add)));
