import { themeColors as COLORS } from './colors';
//
// export default {
//   mode: "light",
//   themeMargin:{
//     value: 32,
//     unit: 'px'
//   },
//   appBar:{
//     value: 60,
//     unit: 'px'
//   },
//   color: COLORS
// }

export const Original = {
  mode: 'light',
  themeMargin: {
    value: 32,
    unit: 'px',
  },
  color: COLORS,
  appBar: {
    background: COLORS.success.background,
    height: {
      value: 72,
      unit: 'px',
    },
  },
  navBar: {
    background: COLORS.success.background,
    width: {
      value: 300,
      unit: 'px',
    },
  },
};

export const Fiwoo = {
  mode: 'light',
  themeMargin: {
    value: 32,
    unit: 'px',
  },
  color: COLORS,
  appBar: {
    background: '#fff',
    height: {
      value: 72,
      unit: 'px',
    },
  },
  navBar: {
    background: COLORS.default.background,
    width: {
      value: 300,
      unit: 'px',
    },
  },
};

export default Fiwoo;
