import Styled, { withTheme } from 'styled-components';
import { _CONCAT_VALUE_UNIT } from '../../helpers/functions';
import { SideOfHeader } from './SideOfHeader';

const LogoEmplacement = Styled(SideOfHeader)`
   & img , & h1 {
    //height: calc(${(p) => _CONCAT_VALUE_UNIT(p.height)});
    padding: 8px;
    display: inline;
    margin: 0;
    margin-right: 39px;
  }
  ${(p) => p.css}
`;

LogoEmplacement.defaultProps = {
  height: { value: 60, unit: 'px' },
  css: '',
};

export default withTheme(LogoEmplacement);
