import React, { useState } from 'react'
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { injectIntl } from 'react-intl';

const Button = (props) => {
    //const [isDone, setIsDone] = useState(false)
    const { data } = props

    const doneOrError = (data.status === 'done' || data.status === 'wrong')


    return (
        <div className={'buttonContainer'}>

{doneOrError ? 
    <div><p className={data.status+'Text'}> <FormattedMessage id={`widget.sendCommand.single.`+data.status} /></p><button className={data.status + 'Button'} onClick={props.reset}> <i className={"uil uil-"+(data.status ==='done' ? 'check':'times')}></i></button></div> :
<button className={data.status + 'Button'} onClick={props.onClick}>{data.label} <i className="uil uil-location-arrow"></i></button>
}
            

        </div>
    )
}

export default injectIntl(Button)
