import React, { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';

import Button from '../../../../elements/Button';
import FormattedMessage from '../../../../Contexts/LanguageContext/Components/FormattedMessage';
import { ReactComponent as IconAverage } from '../../../../configuration/icons/svg/ico-average.svg';
import { ReactComponent as IconMax } from '../../../../configuration/icons/svg/ico-max.svg';
import { ReactComponent as IconMin } from '../../../../configuration/icons/svg/ico-min.svg';
import { ReactComponent as IconPlus } from '../../../../configuration/icons/svg/ico-plus.svg';
import './styles.scss';
import LinkedStepTitle from '../components/LinkedStepTitle';
import LinkedStepButtons from '../components/LinkedStepButtons';

const ArithmeticOperationButtons = ({
  handlePreview,
  handleChangeOperationBuilder,
  widgetChild,
}) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (type) => {
    setSelected(type);
    handleChangeOperationBuilder(type);
    handlePreview();
  };

  return (
    <Row className="d-flex justify-content-center">
      <Col xs="12" md="10">
        <div className="arithmeticOperationButtons button-gap d-flex flex-wrap justify-content-center align-items-center mb-4">
          <Button
            className={`linkedArithmeticOperationButton m-2 ${selected === 'avg' ? 'selected' : ''}`}
            onClick={() => handleSelect('avg')}
          >
            <IconAverage className="linkedArithmeticOperationIcon" />
            <FormattedMessage
              className="linkedArithmeticOperationLabels"
              id="linkedWidget.arithmetic.operation.options.average"
            />
          </Button>
          {!widgetChild.hasOnlyAverageOperation
          && (
          <>
            <Button
              className={`linkedArithmeticOperationButton m-2 ${selected === 'max' ? 'selected' : ''}`}
              onClick={() => handleSelect('max')}
            >
              <IconMax className="linkedArithmeticOperationIcon" />
              <FormattedMessage
                className="linkedArithmeticOperationLabels"
                id="linkedWidget.arithmetic.operation.options.maximum"
              />
            </Button>
            <Button
              className={`linkedArithmeticOperationButton m-2 ${selected === 'min' ? 'selected' : ''}`}
              onClick={() => handleSelect('min')}
            >
              <IconMin className="linkedArithmeticOperationIcon" />
              <FormattedMessage
                className="linkedArithmeticOperationLabels"
                id="linkedWidget.arithmetic.operation.options.minimum"
              />
            </Button>
            <Button
              className={`linkedArithmeticOperationButton m-2 ${selected === 'sum' ? 'selected' : ''}`}
              onClick={() => handleSelect('sum')}
            >
              <IconPlus className="linkedArithmeticOperationIcon" />
              <FormattedMessage
                className="linkedArithmeticOperationLabels"
                id="linkedWidget.arithmetic.operation.options.addition"
              />
            </Button>
          </>
          )}
        </div>
      </Col>
    </Row>
  );
};
const Step3Linked = ({
  currentStep,
  previousStep,
  update,
  totalSteps,
  nextStep,
  handlePreview,
  isActive,
  widgetChild,
}) => {
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);

  const handleChangeOperationBuilder = (operation) => {
    setSelected(operation);
    update('arithmeticOperation', operation);
  };

  const validate = () => {
    setError(null);
    if (!selected) {
      setError('widgets.linked.wizard.step3.error');
      return;
    }
    nextStep();
  };

  const buttons = [
    {
      type: 'secondary', id: 'prevStepLinkedWidget', handleOnClick: previousStep, label: 'linkedWidget.previousStep',
    },
    {
      type: 'primary', id: 'nextStepLinkedWidget', handleOnClick: validate, label: 'linkedWidget.nextStep',
    },
  ];

  if (isActive) {
    return (
      <Container>
        <Row className="linkedStepsContainer pixelHeight">
          <div className="h-100">
            <LinkedStepTitle
              currentStep={currentStep}
              totalSteps={totalSteps}
              title="kpis.wizard.step5.arithmetical.operation.subtitle"
              subtitle="widgets.linked.wizard.step3.subtitle"
            />
            <Container>
              <ArithmeticOperationButtons
                handleChangeOperationBuilder={handleChangeOperationBuilder}
                handlePreview={handlePreview}
                widgetChild={widgetChild}
              />
            </Container>
            {error && (
            <p className="error text-center">
              <FormattedMessage id={error} />
            </p>
            )}
          </div>
          <LinkedStepButtons buttons={buttons} />
        </Row>
      </Container>
    );
  }
  return null;
};

export default Step3Linked;
