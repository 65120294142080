import React from 'react';
import { wrapper } from './MobileStepper.module.scss';

const MobileStepper = ({ actualStep, maxStep, currentTitle }) => (
  <div className={wrapper}>
    <div>{currentTitle}</div>
    <div>{`${actualStep}/${maxStep}`}</div>
  </div>
);

export default MobileStepper;
