/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Container, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../CommonView';
import { getDevices, clearDevicesState } from '../../../../services/redux/devices/actions';
import SelectableList from '../../../../components/SelectableList';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import StaticListComponent from '../components/staticListComponent';
import { getETLAttr } from '../components/utils';
import { getETLProcedures, clearETLProcedures } from '../../../../services/redux/etlProcedures/actions';
import { getKpis, clearKpisState } from '../../../../services/redux/kpis/actions';

const filterBy = (arr, type) => arr.filter((item) => item.Model.entityName === type);
const getFunc = (type) => {
  switch (type) {
    case 'kpis':
      return getKpis;
    case 'etls':
      return getETLProcedures;
    case 'devices':
    default:
      return getDevices;
  }
};

const ENTITY_TYPES = {
  ETL: 'ETLProcedure',
  KPI: 'Kpi',
  DEVICE: 'Device',
};

export const SIMPLE_WIDGETS = [
  'NEEDLE',
  'TEXT_ACCOUNTANT',
  'PERCENTAGE',
  'SEND_COMMAND',
];

export const FILTER_ONLY_DEVICES = [
  'ONOFF',
  'SLIDER',
];

class SelectDatasources extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      errors: {},
      filter: 'devices',
      devices: this.props.newEntity.origins?.length > 0
        ? filterBy(this.props.newEntity.origins, ENTITY_TYPES.DEVICE)
        : [],
      etls: this.props.newEntity.origins?.length > 0
        ? filterBy(this.props.newEntity.origins, ENTITY_TYPES.ETL)
        : [],
      kpis: this.props.newEntity.origins?.length > 0
        ? filterBy(this.props.newEntity.origins, ENTITY_TYPES.KPI)
        : [],
      page: 1,
      size: 6,
      openedPanels: this.props.newEntity.origins
        ? this.initOpenedStatus(this.props.newEntity.origins)
        : [],
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    this.props.onRef(this);
    getDevices({ filters: [], page, size });
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size, filter } = this.state;
    const {
      fetchingDevices,
      fetchingEtls,
      fetchingKpis,
    } = this.props;

    if (
      (!fetchingDevices && !fetchingEtls && !fetchingKpis && prevState.page < page)
      || (prevState.filter !== filter)
    ) {
      if (filter === 'devices') getDevices({ filters: [], page, size });
      if (filter === 'etls') getETLProcedures({ filters: [], page, size });
      if (filter === 'kpis') getKpis({ filters: [], page, size });
    }
  }

  initOpenedStatus = (datasources) => datasources.map(() => false);

  joinedAttributes = (datasource) => {
    if (datasource && Object.entries(datasource).length > 0) {
      if (datasource.Model.entityName === ENTITY_TYPES.DEVICE) {
        // eslint-disable-next-line camelcase
        const { lazy_attributes, static_attributes, attributes } = datasource;
        const attributesList = {
          lazy: lazy_attributes,
          static: static_attributes,
          attribute: attributes,
        };
        return Array.prototype.concat(
          ...Object.keys(attributesList).map(
            (attrIndex) => attributesList[attrIndex].map(
              (attribute) => ({
                ...attribute,
                attrType: attrIndex,
              }),
            ),
          ),
        );
      }

      if (datasource.Model.entityName === ENTITY_TYPES.ETL) {
        const etlAttrs = getETLAttr(datasource).map((attr) => ({ name: attr }));
        return etlAttrs;
      }
    }
    return [];
  };

  validate = () => {
    const { devices, kpis, etls } = this.state;
    if (devices.length > 0 || kpis.length > 0 || etls.length > 0) {
      this.props.onContinue({
        origins: [...this.state.devices, ...this.state.kpis, ...this.state.etls],
      });
    } else {
      this.props.onError(
        <FormattedMessage id="widgets.wizard.no.datasource.selected" />,
      );
    }
  };

  addJoinedAttrs = (arr) => arr.map((d) => (!d.joinedAttributes
    ? {
      ...d,
      joinedAttributes: this.joinedAttributes(d).map((a) => ({
        ...a,
        selected: false,
      })),
    }
    : d));

  handleSelectableListChanges = (data) => {
    const etlsWithoutJoinedAttrs = data.filter(
      (etl) => etl.Model.entityName === ENTITY_TYPES.ETL,
    );

    const etls = this.addJoinedAttrs(etlsWithoutJoinedAttrs);

    const devicesWithoutJoinedAttrs = data.filter(
      (device) => device.Model.entityName === ENTITY_TYPES.DEVICE,
    );
    const devices = this.addJoinedAttrs(devicesWithoutJoinedAttrs);

    const kpis = data.filter((kpi) => kpi.Model.entityName === ENTITY_TYPES.KPI);
    this.setState({ kpis, devices, etls });
  };

  handleOnPanelOpen = (index, status) => {
    const openedPanels = [...this.state.openedPanels];
    openedPanels[index] = status;
    this.setState({
      openedPanels,
    });
  };

  handleFetchInfo = () => {
    const { total, devices } = this.props;
    if (total > devices.length) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { size, filter } = this.state;
    filter === 'devices' && init && clearDevicesState();
    filter === 'etls' && init && clearETLProcedures();
    filter === 'kpi' && init && clearKpisState();
    const getAction = getFunc(filter);
    getAction({ filters: { name: value }, page: 1, size });
  };

  whichtFilterisLoading = () => {
    const { fetchingDevices, fetchingEtls, fetchingKpis } = this.props;
    const { filter } = this.state;

    if (filter === 'devices') return fetchingDevices;
    if (filter === 'etls') return fetchingEtls;
    if (filter === 'kpis') return fetchingKpis;
    return fetchingDevices;
  }

  getOptionsWithFilters = () => {
    const { newEntity: { widgetType }, devices } = this.props;
    const { filter } = this.state;
    if (FILTER_ONLY_DEVICES.includes(widgetType)) {
      const dataList = [];
      devices && devices.forEach((device) => {
        if (device.command_attributes && device.command_attributes.length > 0) {
          dataList.push(device);
        }
      });
      return dataList && dataList.map((o) => ({ name: o.name, id: o.id, value: o }));
    }
    return this.props[filter] && this.props[filter]
      .map((o) => ({ name: o.name, id: o.id, value: o }));
  }

  handleChangeFilter = (e) => {
    const { target: { value: filter } } = e;
    this.setState({ filter, page: 1 });
  }

  getFilsters = () => {
    const { newEntity: { widgetType } } = this.props;
    if (FILTER_ONLY_DEVICES.includes(widgetType)) {
      return [
        {
          name: 'devices',
          translation: 'devices.list.title',
        },
      ];
    }
    return [
      {
        name: 'devices',
        translation: 'devices.list.title',
      },
      {
        name: 'etls',
        translation: 'etl.list.title',
      },
      {
        name: 'kpis',
        translation: 'kpis.list.title',
      },
    ];
  }

  resetSelections = () => {
    this.setState({
      devices: [],
      kpis: [],
      etls: [],
    });
  }

  checkMultiOptions = () => {
    const { newEntity: { widgetType } } = this.props;
    return !SIMPLE_WIDGETS.includes(widgetType);
  }

  render() {
    const {
      errors,
      openedPanels,
      filter,
      devices,
      etls,
      kpis,
    } = this.state;

    return (
      <>
        <Container fluid>
          <Row>
            <Col lg="12">
              <SelectableList
                data={this.getOptionsWithFilters()}
                selected={[...devices, ...etls, ...kpis]}
                name="datasources"
                translatePrefix="widgets.wizard"
                error={errors}
                listComponent={(props) => StaticListComponent(
                  { ...props, trendline: null },
                  this.handleOnPanelOpen,
                  this.resetSelections,
                )}
                onChange={this.handleSelectableListChanges}
                fetchInfo={this.handleFetchInfo}
                loading={this.whichtFilterisLoading()}
                openedPanels={openedPanels}
                onSearch={this.handleOnSearch}
                multiOption={this.checkMultiOptions()}
                filterBy="name"
                selectOnTheFly
                filter={filter}
                selectableFilter={{
                  handler: this.handleChangeFilter,
                  filters: this.getFilsters(),
                  checked: filter,
                }}
                smallInput
              />
              {this.checkMultiOptions() && (
                <div className="warning">
                  <i className="uil uil-info-circle" />
                  <FormattedMessage id="widget.wizard.selectDatasources" />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
  etls: state
    .get('etlProcedures')
    .get('list')
    .toJS(),
  kpis: state
    .get('kpis')
    .get('list')
    .toJS(),
  fetchingDevices: state.get('devices').get('fetching'),
  fetchingEtls: state.get('etlProcedures').get('fetching'),
  fetchingKpis: state.get('kpis').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(SelectDatasources)));
