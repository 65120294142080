/* eslint-disable import/prefer-default-export */
import Faker from 'faker';
import { range } from 'ramda';
import { reportManagement, minioManagement } from '../../configuration/config';

import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = reportManagement.urlAPI.concat(reportManagement.paths.report);
const minioendpoint = `${minioManagement.protocol}://${minioManagement.url}:${minioManagement.port}`;

const exampleDataFn = (numberOfReportToGenerate = 50) => {
  const ReportsFaker = range(0, numberOfReportToGenerate).map(() => ({
    id: Faker.random.uuid(),
    name: Faker.random.word(),
    owner: Faker.random.uuid(),
    execution: Faker.random.word(),
    createdAt: new Date(),
    updatedAt: new Date(),
  }));
  ReportsFaker.unshift({
    updatedAt: new Date(),
    createdAt: new Date(),
    description: 'Report Descripción',
    permissions_policy: { users: [], groups: [], public: true },
    id: 'b7f723f0-22df-47fb-b210-486bcc4dd351',
    name: 'Report Muestra',
    owner: '2d8d8294-d0c5-4f67-bcd8-9ec6f8ee02af',
    onCascadeUpdate: true,

    mails: ['dasdas@asld.com'], // tus actions
    planner: { property: 'minutes', value: 49 }, // WIP ? XD

    orientation: 'vertical',
    size: 'a4', // esto supongo que estará en la pagina
  });
  return { reports: ReportsFaker };
};

const exampleDataUniqueFn = (report) => {
  const newReport = JSON.parse(report);
  const ReportsFaker = {
    ...newReport,
    id: Faker.random.uuid(),
    owner: Faker.random.uuid(),
    execution: Faker.random.word(),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return { report: ReportsFaker };
};

export const downloadReportHistorical = async (historical) => {
  if (!historical.id) {
    return {
      status: 400,
      error: 'missing id attr',
    };
  }

  // check minio status
  try {
    const result = await requestAPI(
      {
        endPoint: `${minioendpoint}/minio/health/ready`,
        statusOK: 200,
        toJSON: false,
        requestConfig: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      },
      exampleDataFn,
      true,
    );

    if (result.status !== 200) {
      // TODO: show message in front
      return {
        status: 401,
        error: 'minio service unavailable',
      };
    }
  } catch {
    return {
      status: 402,
      error: 'minio request unresolved',
    };
  }

  // finally get download link
  const params = {
    endPoint: `${endPoint}/${historical.reportId}/render/${historical.id}/download`,
    statusOK: 201,
    toJSON: false,
    requestConfig: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  };
  return requestAPI(params, exampleDataFn);
};

export const readReportHistorical = async (page, size, filters, id) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  // ToDo remove that when implement correctly list.
  const querySize = size ? `x-query-size=${size}` : 'x-query-size=40';
  const queryFilters = filters ? parseFilters(filters) : '';
  const url = `${endPoint}/${id}/renders/list/all?${queryPage}&${querySize}${queryFilters}`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'reportHistorical',
  };

  return requestAPI(params, exampleDataFn);
};
