/* eslint-disable import/no-cycle */
import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import injectDataFromHistorical from './utils/utilsForWidget';
import Widget from '../../index';
import SelectableV2 from '../../../../pages/Dashboards/Show/components/CustomWidgetParagraphWrapper/SelectableV2';

const ParametrizedText = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetParametrizedText })),
);

function ParametrizedTextComponent(props) {
  const {
    widget, handleOpenProfileWidget, onChangeHistoricalConfig, intl,
    hasPermissionToEdit, updateMySelf, values, dispatchState
  } = props;

  const [params, setParams] = useState([]);
  const [defaultParams, setDefaultParams] = useState([]);
  const [val, setVal] = useState('');

  const { parameters, content } = widget.config.custom?.[`${widget.widgetType}`];

  const translations = {
    closeTools: intl.formatMessage({ id: 'widgetVarParagraph.edit.closeTools' }),
    paragraphStyle: intl.formatMessage({ id: 'widgetVarParagraph.edit.paragraphStyle' }),
    justification: intl.formatMessage({ id: 'widgetVarParagraph.edit.justification' }),
    variables: intl.formatMessage({ id: 'widgetVarParagraph.edit.variables' }),
    title: intl.formatMessage({ id: 'widgetVarParagraph.edit.title' }),
    titleTwo: intl.formatMessage({ id: 'widgetVarParagraph.edit.titleTwo' }),
    subtitle: intl.formatMessage({ id: 'widgetVarParagraph.edit.subtitle' }),
    paragraph: intl.formatMessage({ id: 'widgetVarParagraph.edit.paragraph' }),
    date: intl.formatMessage({ id: 'widgetVarParagraph.edit.date' }),
    hour: intl.formatMessage({ id: 'widgetVarParagraph.edit.hour' }),
    edit: intl.formatMessage({ id: 'widgetVarParagraph.actions.edit' }),
    delete: intl.formatMessage({ id: 'widgetVarParagraph.actions.delete' }),
    share: intl.formatMessage({ id: 'widgetVarParagraph.actions.share' }),
  };

  useEffect(() => {
    if (values) {
      setVal(injectDataFromHistorical(content, values));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, widget?.sources]);

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, widget?.origins, widget?.sources]);

  return (
    <ParametrizedText
      text={val}
      config={widget.config}
      parameters={parameters}
      translations={translations}
      widget={widget}
      widgetModel={Widget}
      clickView={handleOpenProfileWidget}
      dark={widget.transparent}
      permissionToEdit={hasPermissionToEdit}
      dashboard
      updateWidget={updateMySelf}
      params={params}
      setDefaultParams={setDefaultParams}
      defaultParams={defaultParams}
    >
      <SelectableV2
        params={params}
        defaultParams={defaultParams}
        setParams={setParams}
        intl={intl}
        widget={widget}
        dispatchState={dispatchState}
      />
    </ParametrizedText>
  );
}

export default memo(injectIntl(ParametrizedTextComponent));
