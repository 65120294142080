import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext/index';
import SuccessComponent from '../../../../helpers/SuccessPage';
import UserOkIcon from '../../../../../configuration/icons/svg/user-ok.svg'
import { withWizardStep } from '../../../../../Contexts/WizardStepContext';

export const Content = ({ created }) => (
  <div className="passwordPanel w-100">
    <div>
      <dt><FormattedMessage id="users.add.success.user" /></dt>
      <dd>{created.email}</dd>
    </div>
    <div className="divisor" />
    <div>
      <dt><FormattedMessage id="users.add.success.password" /></dt>
      <dd>{created.password || <FormattedMessage id="users.add.success.nopassword" />}</dd>
    </div>
  </div>
);

const Success = (props) => {
  const createComplete = { ...props.newEntity, ...props.newPassword };
  return (
    <SuccessComponent
      title={<FormattedMessage id="users.add.success.title"/>}
      content={<Content created={createComplete} />}
      icon={UserOkIcon}
    />
  );
};

const mapStateToProps = (state) => ({
  newPassword: state.get('users').get('newPassword'),
});

export default connect(mapStateToProps, {})(withWizardStep(Success));
