import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../elements/Button';
import ModalHOC from '../../../../elements/Modal';

const ButtonOpen = ({ showModal }) => (
  <ButtonCustom type="primary" handleOnClick={showModal} label="Confirmar" />
);

const ModalContent = () => (
  <>
    <h1>
      <FormattedMessage
        id="kpi.profile.update.modal.confirm.title"
      />
    </h1>
    <p>
      <FormattedMessage
        id="kpi.profile.update.modal.confirm.message"
      />
    </p>
  </>
);

const ModalButtons = (rowContent, hideModal, update, cancel) => (
  <div className="d-flex justify-content-center">
    <ButtonCustom className="mr-3" type="secondary" handleOnClick={cancel} label={<FormattedMessage id="options.cancel" />} />
    <ButtonCustom type="primary" handleOnClick={update} label={<FormattedMessage id="options.save" />} />
  </div>
);
const ConfirmModal = ({ update, cancel }) => {
  const ModalComp = ModalHOC({
    ButtonOpen,
    ModalContent: () => ModalContent(),
    ModalButtons: ({ hideModal }) => ModalButtons({}, hideModal, update, cancel),
  });
  return (
    <ModalComp />
  );
};

export default ConfirmModal;
