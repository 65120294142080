import React from 'react';
import CardHoc from '../../../elements/Card/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import {
  alert, colorSquare, informational, low, medium, high, critical, text,
} from './alertRow.module.scss';

const getStyle = (severity) => {
  switch (severity) {
    case 'informational':
      return informational;
    case 'low':
      return low;
    case 'medium':
      return medium;
    case 'high':
      return high;
    case 'critical':
      return critical;
    default:
      return informational;
  }
};

export const severityData = ['informational', 'low', 'medium', 'high', 'critical'].map(
  (item) => ({ name: item, value: item }),
);

export const Card = CardHoc((props) => (
  <div className="p-3">
    {props.data.map((item, i) => (
      <AlertRow
        onClick={(e) => props.handleOnChange(e, { value: item.value })}
        key={i}
        item={item}
      />
    ))}
  </div>
));

export const Value = ({ selectedOption }) => <AlertRow item={selectedOption} />;

export const AlertRow = ({ item, ...rest }) => (
  <div {...rest} className={alert}>
    <div className={`${colorSquare} ${getStyle(item.value)}`} />
    <div className={text}>
      <FormattedMessage id={`severityTag.${item.value}`} />
    </div>
  </div>
);
