import React from 'react';
import { ThemeProvider } from 'styled-components';
import * as Theme from '../../configuration/theme';

const defaultValue = 'valor por defecto';

/** Available Themes */
const availableThemes = Object.keys(Theme);

/** Context Creation. Can be in another external file */
export const Context = React.createContext(defaultValue);

/** Creating Provider */
class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.functions = {
      changeTheme: this.handleTheme,
    };

    this.state = {
      currentThemeName: 'default',
      availableThemes,
      ...this.functions,
    };
  }

    handleTheme = (currentThemeName) => {
      const { currentThemeName: ctnState } = this.state;
      if (ctnState !== currentThemeName) this.setState({ currentThemeName: ctnState });
    };

    render() {
      /** Filtering Props * */
      const { children } = this.props;

      /** Filtering State * */
      const { currentThemeName } = this.state;

      /** Logic in render * */
      const theme = Theme[currentThemeName] || Theme.default;

      return (
        <Context.Provider value={{ ...this.state }}>
          <ThemeProvider theme={theme}>
            { children }
          </ThemeProvider>
        </Context.Provider>
      );
    }
}
export default Provider;
