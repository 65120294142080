import React from 'react';

import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import Field from '../../../../../elements/Field/index';
import { ButtonCustom } from '../../../../../elements/Button';


const FieldComponent = ({
  name, intl, onChange, ...rest
}) => (
  <>
    {[name].map((key, i) => (
      <Field
        {...rest}
        name={key}
        placeholder={intl.formatMessage({
          id: `etleditor.transformation.edition.modal.filter.${key}.placeholder`,
        })}
        label={intl.formatMessage({
          id: `etleditor.transformation.edition.modal.filter.${key}.label`,
        })}
        id={key}
        key={`${key}-${i}`}
        onChange={onChange}
      />
    ))}
  </>
);

const operations = [
  { name: '=', id: 1, value: 'equals' },
  { name: '!=', id: 2, value: 'not_equals' },
  { name: '>', id: 3, value: 'greater' },
  { name: '>=', id: 4, value: 'greater_equal' },
  { name: '<', id: 5, value: 'lesser' },
  { name: '<=', id: 6, value: 'lesser_equal' },
  { name: 'Contains', id: 7, value: 'contains' },
  { name: 'Contains full', id: 8, value: 'full_contains' },


];

export class FiltertComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entity: props.entity,
      name: props.filter.name,
      type: props.filter.type,
      condition: props.filter.condition,
      valueCondition: props.filter.valueCondition,
    };
  }

  static propTypes = {};

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
      this.setState({
        entity: this.props.entity,
        name: this.props.filter.name,
        type: this.props.filter.type,
        condition: this.props.filter.condition,
        valueCondition: this.props.filter.valueCondition,
      });
    }
  }

  handleInnerInputChange = (e) => {
    const { name, value } = e.target;
    const { index } = this.props;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.onChange({
          valueCondition: value,
          index,
        });
      },
    );
  };

  handleInnerSelectChange = (name, value) => {
    const { index } = this.props;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.onChange({
          name: this.state.name ? this.state.name.name : '',
          type: this.state.name ? this.state.name.type : '',
          index,
        });
      },
    );
  };

  handleInnerSelectConditionChange = (name, value) => {
    const { index } = this.props;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.onChange({
          condition: this.state.condition ? this.state.condition : '',
          index,
        });
      },
    );
  };

  render() {
    const {
      intl, errors, availableColumns, index, onDelete,
    } = this.props;
    const {
      condition, name, valueCondition,
    } = this.state;

    const selectedName = availableColumns.find((ac) => ac.id === name);
    const valueName = selectedName ? selectedName.value : name;

    return (
      <Row>
        <Col>
          <FieldComponent
            name="name"
            type="select"
            options={availableColumns}
            intl={intl}
            value={valueName}
            onChange={this.handleInnerSelectChange}
            error={errors ? errors.name : undefined}
            helperText={errors ? errors.name : undefined}
          />
        </Col>
        <Col>
          <FieldComponent
            name="condition"
            type="select"
            options={operations}
            intl={intl}
            value={condition}
            onChange={this.handleInnerSelectConditionChange}
            error={errors ? errors.condition : undefined}
            helperText={errors ? errors.condition : undefined}
          />
        </Col>

        <Col>
          <FieldComponent
            name="valueCondition"
            type="text"
            intl={intl}
            value={valueCondition}
            onChange={this.handleInnerInputChange}
            error={errors ? errors.valueCondition : undefined}
            helperText={errors ? errors.valueCondition : undefined}
          />
        </Col>
        <Col style={{ padding: '25px' }}>
          <ButtonCustom
            type="circular"
            name="minus"
            onClick={() => onDelete(index)}
          />
        </Col>


      </Row>

    );
  }
}


export default FiltertComponent;
