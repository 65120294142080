import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import {
  getTree,
  updateMenuElementsSelectedState,
  clearMenuElementsSelectedState,
  hiddenSubmenu,
} from '../../services/redux/menuElements/actions';
import Finder from '../Finder';
import GroupMenuTitle from './components/GroupMenuTitle';
import CreateWizardMenu from './Add/AddWizard';
import parseResource from './helpers/index';
import getBreakpoint from '../../helpers/getBreakpoint';

const MenuWithFinder = Finder((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Menu {...props} />
));

function Menu(props) {
  const { showArrow, data } = props;
  return (
    <section className="section-menu">
      {showArrow && (
      <i
        className={`uil icon-arrow ${
          hiddenSubmenu
            ? 'uil-arrow-right rotate-right'
            : 'uil-arrow-left rotate-left'
        }`}
        onClick={() => hiddenSubmenu(!hiddenSubmenu)}
        role="presentation"
      />
      )}
      <h3 className="d-none d-md-block ml-4">
        <FormattedMessage id="menu.title" />
      </h3>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <GroupMenuTitle {...props} />
      {data.length === 0 && (
      <p className="noContent parentText ml-4">
        <FormattedMessage id="menuElement.noEntryText" />
      </p>
      )} 
      <div className="text-right pr-4 mt-4">
        <CreateWizardMenu noEntry={data.length === 0} />
      </div>
    </section>
  );
}

Menu.defaultProps = {
  showArrow: undefined,
  data: [],
};

class MenuNav extends React.Component {
  constructor(props) {
    super(props);
    getTree();
    this.state = {
      root: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { root } = this.props;
    if (root !== prevProps.root) {
      this.updateState();
    }
  }

  handleOnClick = () => {
    getTree();
  };

  handleOpenMenu = (menuElement) => {
    if (menuElement.children.length > 0 || menuElement.resource) {
      updateMenuElementsSelectedState([menuElement]);
      hiddenSubmenu(false);
    } else {
      // clearMenuElementsSelectedState()
      updateMenuElementsSelectedState([menuElement]);
      hiddenSubmenu(false);
    }
  };

  handleViewContent = (menuElement, isRoot) => {
    if (menuElement.resource && menuElement.resource.type !== 'PARENT') {
      if (menuElement.resource.type === 'EXTERNAL') {
        navigate(
          '/app/iframe?url='.concat(parseResource(menuElement.resource)),
        );
      } else {
        navigate(menuElement.resource.link.path);
      }
      clearMenuElementsSelectedState();
      const { onCloseMenu } = this.props;
      if (onCloseMenu) {
        onCloseMenu();
      }
    } else {
      this.handleOpenMenu(menuElement, isRoot);
    }
  };

  isMobile = () => ['xs', 'sm'].includes(getBreakpoint())

  getMenu = (root) => {
    const { showArrow } = this.props;
    return (
      !this.isMobile() ? (
        <MenuWithFinder
          data={root}
          hiddenSubmenu={hiddenSubmenu}
          showArrow={showArrow}
          handleOpenMenu={this.handleOpenMenu}
          handleViewContent={this.handleViewContent}
        />
      ) : (
        <Menu
          data={root}
          hiddenSubmenu={hiddenSubmenu}
          showArrow={showArrow}
          handleOpenMenu={this.handleOpenMenu}
          handleViewContent={this.handleViewContent}
        />
      ));
  }

  updateState() {
    const { root } = this.props;
    this.setState({ root: root.filter((o) => o.name) });
  }

  render() {
    const { root } = this.state;

    return (
      <aside>
        {this.getMenu(root)}
      </aside>
    );
  }
}

MenuNav.defaultProps = {
  root: [],
  onCloseMenu: '',
  showArrow: undefined,
};

const mapStateToProps = (state) => ({
  root: state.get('menuElements').get('root').toJS(),
  showArrow: state.get('menuElements').get('menuElementsSelected').length > 0,
  hiddenSubmenu: state.get('menuElements').get('hiddenSubmenu'),
});

export default connect(
  mapStateToProps,
  {},
)(MenuNav);
