import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

class ModalAddDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

    toggle = () => {
      this.setState((prevState) => ({
        modal: !prevState.modal,
      }));
    }

    render() {
      const { modal } = this.state;
      return (
        <div>
          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalBody>
              <form>
                <label htmlFor="nombre">
                  Nombre
                  <input id="nombre" type="text" />
                </label>
                <label htmlFor="description">
                  Description
                  <textarea id="description" />
                </label>
              </form>
            </ModalBody>
          </Modal>
        </div>

      );
    }
}

export default ModalAddDashboard;
