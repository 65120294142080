import React from 'react';
import { connect } from 'react-redux';
import { Row, Container, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../CommonView';
import { getDevices, clearDevicesState } from '../../../../services/redux/devices/actions';
import SelectableList from '../../../../components/SelectableList';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import StaticListComponent from '../components/staticListComponent';
import { getDefaultConfig, getInjectedData } from '../../../../models/Widget/configurationSheets';

import { categories } from '../../../helpers/Add/CreateEntity';
import Field from '../../../../elements/Field';
import { SIMPLE_WIDGETS } from './SelectDatasources';

const filterBy = (arr, type) => arr.filter((item) => item.Model.entityName === type);
const getFunc = (type) => {
  switch (type) {
    case 'devices':
    default:
      return getDevices;
  }
};

const ENTITY_TYPES = {
  DEVICE: 'Device',
};
class SelectDatasourcesForLinkedWidget extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      errors: {},
      filter: 'devices',
      devices: this.props.newEntity.origins?.length > 0
        ? filterBy(this.props.newEntity.origins, ENTITY_TYPES.DEVICE)
        : [],
      page: 1,
      size: 6,
      openedPanels: this.props.newEntity.origins
        ? this.initOpenedStatus(this.props.newEntity.origins)
        : [],
      categorySelected: null,
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    this.props.onRef(this);
    // getDevices({ filters: [], page, size });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page, size, filter, categorySelected,
    } = this.state;
    const { fetchingDevices } = this.props;

    if (prevState.categorySelected !== categorySelected) {
      clearDevicesState();
      getDevices({ filters: { categories: categorySelected }, page, size });
    }
    if ((!fetchingDevices && prevState.page < page)) {
      if (filter === 'devices') {
        getDevices({ filters: { categories: categorySelected }, page, size });
      }
    }
  }

  initOpenedStatus = (datasources) => datasources.map(() => false);

  joinedAttributes = (datasource) => {
    if (datasource && Object.entries(datasource).length > 0) {
      if (datasource.Model.entityName === ENTITY_TYPES.DEVICE) {
        const { lazy_attributes, static_attributes, attributes } = datasource;
        const attributesList = {
          lazy: lazy_attributes,
          static: static_attributes,
          attribute: attributes,
        };
        return Array.prototype.concat(
          ...Object.keys(attributesList).map(
            (attrIndex) => attributesList[attrIndex].map(
              (attribute) => ({
                ...attribute,
                attrType: attrIndex,
              }),
            ),
          ),
        );
      }
    }
    return [];
  };

  validate = () => {
    const { newEntity } = this.props;
    const { devices, categorySelected } = this.state;
    const { widgetType } = newEntity;
    const category = devices[0].categories[0];
    let nextStep = true;
    newEntity.widgetType = widgetType;
    newEntity.origins = devices;
    newEntity.config = getDefaultConfig(widgetType);
    newEntity.config.data.category = categorySelected[0];
    if (this.state.devices.length > 0) {
      this.state.devices.forEach((devic) => {
        if (devic.categories[0] !== category) {
          nextStep = false;
          this.props.onError(
            <FormattedMessage id="widgets.wizard.different.category.selected" />,
          );
        }
      });
      if (nextStep) {
        const origins = this.state.devices.map((device) => {
          const newDevice = { ...device };
          newDevice.joinedAttributes = newDevice.joinedAttributes.map(
            (attr) => ({ ...attr, selected: true }),
          );
          return newDevice;
        });
        const newConfig = getInjectedData(newEntity);
        newEntity.config = newConfig;
        this.props.onContinue({
          origins,
          newEntity,
        });
      }
    } else {
      this.props.onError(
        <FormattedMessage id="widgets.wizard.no.device.selected" />,
      );
    }
  };

  addJoinedAttrs = (arr) => arr.map((d) => (!d.joinedAttributes
    ? {
      ...d,
      joinedAttributes: this.joinedAttributes(d).map((a) => ({
        ...a,
        selected: false,
      })),
    }
    : d))

  handleSelectableListChanges = (data) => {
    const devicesWithoutJoinedAttrs = data.filter(
      (device) => device.Model.entityName === ENTITY_TYPES.DEVICE,
    );
    const devices = this.addJoinedAttrs(devicesWithoutJoinedAttrs);
    this.setState({ devices });
  };

  handleOnPanelOpen = (index, status) => {
    const openedPanels = [...this.state.openedPanels];
    openedPanels[index] = status;
    this.setState({
      openedPanels,
    });
  };

  handleFetchInfo = () => {
    const { total, devices } = this.props;
    if (total > devices.length) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { size, filter, categorySelected } = this.state;
    filter === 'devices' && init && clearDevicesState();
    const getAction = getFunc(filter);
    getAction({ filters: { name: value, categories: categorySelected }, page: 1, size });
  };

  whichtFilterisLoading = () => {
    const { fetchingDevices } = this.props;
    const { filter } = this.state;

    if (filter === 'devices') return fetchingDevices;
    return fetchingDevices;
  }

  getExcludedDelocalized = (oldList) => oldList.filter((dev) => {
    const attrs = [
      ...dev.attributes,
      ...dev.command_attributes,
      ...dev.lazy_attributes,
      ...dev.static_attributes];
    return attrs.some((attr) => attr.type === 'geo:point');
  })

  getOptionsWithFilters = () => {
    const { filter } = this.state;
    const completeList = this.getExcludedDelocalized(this.props[filter]);
    return completeList && completeList
      .map((o) => ({ name: o.name, id: o.id, value: o }));
  }

  handleChangeFilter = (e) => {
    const { target: { value: filter } } = e;
    this.setState({ filter, page: 1 });
  }

  resetSelections = () => {
    this.setState({
      devices: [],
    });
  }

  checkMultiOptions = () => {
    const { newEntity: { widgetType } } = this.props;
    return !SIMPLE_WIDGETS.includes(widgetType);
  }

  handleSelectChange = (type, name) => {
    this.setState({ categorySelected: name, page: 1 });
  }

  render() {
    const {
      errors,
      openedPanels,
      filter,
      devices,
      categorySelected,
    } = this.state;

    const { intl } = this.props;

    return (
      <>
        <Container fluid>
          <Row>
            <Col lg={{ size: 12 }}>
              <SelectableList
                label={intl.formatMessage({ id: 'widgetTable.headers.device' })}
                data={this.getOptionsWithFilters()}
                selected={[...devices]}
                name="datasources"
                translatePrefix="widgets.wizard"
                error={errors}
                listComponent={(props) => StaticListComponent(
                  { ...props, trendline: null },
                  this.handleOnPanelOpen,
                  this.resetSelections,
                )}
                onChange={this.handleSelectableListChanges}
                fetchInfo={this.handleFetchInfo}
                loading={this.whichtFilterisLoading()}
                openedPanels={openedPanels}
                onSearch={this.handleOnSearch}
                multiOption={this.checkMultiOptions()}
                filterBy="name"
                selectOnTheFly
                filterForOptions={{
                  name: 'categories',
                  value: categorySelected,
                  options: categories,
                  error: !!errors.categories,
                  helperText: errors.categories,
                  onChange: (type, name) => this.handleSelectChange(type, name),
                  label: intl.formatMessage({
                    id: 'devices.wizard.categories.label',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'devices.wizard.categories.placeholder',
                  }),
                }}
                filter={filter}
                selectableFilter={{
                  handler: this.handleChangeFilter,
                  filters: [
                    {
                      name: 'devices',
                      translation: 'devices.list.title',
                    },
                  ],
                  checked: filter,
                }}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetchingDevices: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(SelectDatasourcesForLinkedWidget)));
