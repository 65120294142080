import React from 'react';
import PropTypes from 'prop-types';
// import RemoveHoc from "./../../helpers/RemoveComponent";
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';

const Share = (
  <FormattedMessage
    id="options.share"
    defaultMessage="share"
    description="Title of share"
  />
);

const CreateCopy = (
  <FormattedMessage
    id="options.clone"
    defaultMessage="clone"
    description="Title of clone"
  />
);

// const RemoveDOM = RemoveHoc(ButtonOpen, { class: Dashboard, nameData: "dashboards" })

const OptionsForDashboard = ({ rowContent: dashboardContent, viewClick }) => (
  <div className="tableMenu">
    <ul className="list-unstyled">
      <li
        onClick={() => { viewClick(dashboardContent); }}
        state={{ dashboard: dashboardContent, mode: 'edit' }}
        role="presentation"
      >
        {CreateCopy}
      </li>
      <li
        onClick={() => { }}
        state={{ dashboard: dashboardContent }}
        role="presentation"
      >
        {Share}
      </li>
    </ul>
  </div>
);

OptionsForDashboard.propTypes = {
  rowContent: PropTypes.string,
  viewClick: PropTypes.func,
};

OptionsForDashboard.defaultProps = {
  rowContent: '',
  viewClick: () => {},
};

export default OptionsForDashboard;
