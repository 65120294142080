/* eslint-disable import/no-cycle */
import Widget from '../..';
import { BarsConfigurationSheet, BarsDefaultTemplateConfig, validationBars } from './utils/configurationSheet';
import BarsDefaultConfiguration, { injectBarsData } from './utils/defaultConfiguration';
import BarsComponent from './component';
import formatToBarWidget from './utils/formatData';
import { ReactComponent as BarsWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-bars.svg';
import { parseToSources } from "../../../../helpers/utils";
import Device from "../../../Device";

export const NAME_WIDGET = 'BARS';
export default class BarsWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = BarsWidgetIcon;

  hasMultipleAttributes = true;

  hasMultipleSources = true;

  skeleton = BarsWidgetIcon;

  defaultConfig = ({ ...BarsDefaultConfiguration });

  templateConfig = BarsDefaultTemplateConfig;

  component = BarsComponent;

  cleanConfigOnChange = true;

  constructor(obj = {}) {
    super(obj);
    if (obj.version === 'v1') {
      const { data } = this.config;
      if (!this.config.appearance.legendPosition) this.config.appearance.legendPosition = 'top';
      this.config.data.type = (data.startDate && data.endDate) ? 'historical' : 'real-time';
      const fakeSources = this.origins.map((source) => parseToSources(new Device(source.connectedDevices)));
      this.config = injectBarsData(this, undefined, fakeSources);
    }
  }

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => (config) => validationBars(config)

  formatToData = (historicalValues, sources, config, selection, intl) => (
    formatToBarWidget(historicalValues, sources, intl, config || this.config)
  )

  injectData = (widget, oldWidget, sources) => injectBarsData(widget, oldWidget, sources)

  getQueryHistorical() {
    const {
      sampling,
      startDate,
      endDate,
      type,
      period,
    } = this.config.data;
    const dataForQueries = this.getEmptyHistoricalBody();

    if (type !== 'historical') {
      const sources = this.getDataForQueries({ sources: this.sources });
      dataForQueries[0].sources = sources;
    } else {
      dataForQueries.shift();
      const historialQueryBody = this.getDataForQueries(
        {
          sources: this.sources,
          queryType: 'aggregate',
          operation: 'avg',
          sampling,
          periodTime: {
            startDate,
            endDate,
            period,
          },
        },
      );
      dataForQueries.push(historialQueryBody);
    }
    return dataForQueries;
  }

  getWidgetChildBodyQuery() {
    this.showError('getWidgetChildBodyQuery', ['dataForQueries']);
  }

  getConfigurationSheet = () => BarsConfigurationSheet;

  needsSocketForHistorical() {
    const { config: { data: { type, period } } } = this;
    return type === 'historical' && period === 'last';
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
