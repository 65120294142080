/* eslint-disable import/no-cycle */
import { clone } from 'lodash';
import Widget from '../..';
import {
  NeedleConfigurationSheetBasic,
  NeedleConfigurationSheet,
  validationNeedle,
} from './utils/configurationSheet';
import NeedleDefaultConfiguration from './utils/defaultConfiguration';
import NeedleComponent from './component';
import {
  formatToCommonWidgetSingleDevice,
} from '../../utils';
import { ReactComponent as NeedleWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-gauge.svg';

export const NAME_WIDGET = 'NEEDLE';
export default class NeedleWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = NeedleWidgetIcon;

  skeleton = NeedleWidgetIcon;

  defaultConfig = ({ ...NeedleDefaultConfiguration });

  templateConfig = ['min', 'max', 'unit'];

  component = NeedleComponent;

  constructor(obj = {}) {
    super(obj);
    if (obj.version === 'v1') {
      if(!this.config.conditions.type || !this.config.conditions.range) {
        this.config.conditions = NeedleDefaultConfiguration.conditions;
      }
    }
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //

  formatToData = (historicalValues) => parseInt(formatToCommonWidgetSingleDevice(
    historicalValues,
  ), 10)

  injectData = () => null;

  getValidatorConfig = () => (config) => validationNeedle(this.templateConfig, config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? NeedleConfigurationSheetBasic
      : NeedleConfigurationSheet
  )

  getDefaultConfig() {
    return this.defaultConfig;
  }

  getWidgetChildBodyQuery = (data, { allSourcesParsed }) => {
    const dataForQueries = clone(data);
    dataForQueries[0].sources = allSourcesParsed;
    return dataForQueries;
  }

  getQueryHistorical() {
    const dataForQueries = this.getEmptyHistoricalBody();
    const sources = this.getDataForQueries({ sources: this.sources });
    dataForQueries[0].sources = sources;
    return dataForQueries;
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
