/* eslint-disable import/no-named-as-default */
import React from 'react';
import { PropTypes } from 'prop-types';

import { clone } from 'ramda';
import Wizard from '../../../../components/Wizard';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

// steps
import BasicInfo from '../../../Widgets/Add/steps/basicInfo';
import SelectDatasources from '../../../Widgets/Add/steps/SelectDatasources';
import ConfigureWidget from '../../../Widgets/Add/steps/ConfigureWidget';
import WidgetPreview from '../../../Widgets/Add/steps/WidgetPreview';
import AssignPermissions from '../../../helpers/AssignPermissions';

import Success from '../../../Widgets/Add/steps/Success';

import { getDefaultMeasure, getDefaultMeasures } from '../../WidgetDefaultMeasures';
import { isMobile } from '../../../../helpers/getBreakpoint';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';

import CreateWidgteIcon from '../../../../configuration/icons/svg/ico-widget.svg';
import DeviceIcon from '../../../../configuration/icons/svg/ico-device.svg';
import WizardStep from '../../../../components/Wizard/components/WizardStep';
import Dashboard from '../../../../models/Dashboard/index';
import Report from '../../../../models/Report';
import Widget from '../../../../models/Widget/index';
import '../../../Widgets/Add/styles.scss';
import ButtonOutline from '../../../../elements/ButtonOutline';
import { getWidgetModel } from '../../../../models/WidgetV2/utils';

const addWidgetFormZeroLabel = (
  <FormattedMessage id="widgetImage.button.addWidget.numeric" />
);

const ButtonOpen = ({ showModal, customButton }) => {
  if (customButton) {
    const {
      disabled, label, bgwhite, ico,
    } = customButton;
    return (
      <ButtonOutline
        onClick={showModal}
        disabled={disabled}
        label={label}
        bgwhite={bgwhite}
        ico={ico}
      />
    );
  }
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/rules"
        permission="create"
      >
        <p onClick={showModal}>{addWidgetFormZeroLabel}</p>
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/rules"
      permission="create"
    >
      <p onClick={showModal}>{addWidgetFormZeroLabel}</p>
    </PrivateComponent>
  );
};

class CreateWidgets extends React.PureComponent {
  updateReport = (widget) => {
    const { report } = this.props;
    const clonedReport = clone(report);
    const reportToUpdate = new Report(clonedReport);

    const section = reportToUpdate.pages[this.props.currentPaper || 0].sections[0];
    const elements = section && section.elements ? section.elements : [];
    // check lastPosition
    let lastY = 0;
    elements.forEach((element) => {
      if (element.layoutConfig.lg[0].y > lastY) {
        lastY = element.layoutConfig.lg[0].y;
      }
    });

    const defaultMeasures = getDefaultMeasures([widget]);
    Object.keys(defaultMeasures).forEach((key) => {
      defaultMeasures[key][0].y = lastY + 1;
      defaultMeasures[key][0].i = widget.id;
    });
    // Prepare widget to push on section
    const widgetToPush = {
      aspectRatio: 'NONE',
      config: { ...widget.config, name: widget.name },
      origins: widget.connectedDevices.map((device) => (
        { urn: `fiwoo:device:${device.id}`, attributes: device.attributes }
      )),
      layoutConfig: defaultMeasures,
      type: widget.widgetType,
    };
    // Search current paper for update
    if (!section) {
      reportToUpdate.pages[this.props.currentPaper || 0].sections.push(
        { sectionConfig: { cols: 1, rows: 1 }, elements: [] },
      );
    }
    reportToUpdate.pages[this.props.currentPaper || 0].sections[0].elements.push(widgetToPush);
    reportToUpdate.update();
  }

  updateDashboard = (widget) => {
    const { dashboard } = this.props;
    const clonedDashboard = clone(dashboard);
    const dashboardToUpdate = new Dashboard(clonedDashboard);

    if (!dashboardToUpdate.layoutProperties) {
      dashboardToUpdate.layoutProperties = {
        lg: [], md: [], sm: [], xs: [], xxs: [],
      };
    }

    const defaultMeasures = getDefaultMeasure(widget, dashboardToUpdate.layoutProperties);

    dashboardToUpdate.layoutProperties.lg.push(defaultMeasures.lg);
    dashboardToUpdate.layoutProperties.md.push(defaultMeasures.md);
    dashboardToUpdate.layoutProperties.sm.push(defaultMeasures.sm);
    dashboardToUpdate.layoutProperties.xs.push(defaultMeasures.xs);
    dashboardToUpdate.layoutProperties.xxs.push(defaultMeasures.xxs);

    dashboardToUpdate.widgets = dashboardToUpdate.widgets.map((o) => o.id);
    dashboardToUpdate.widgets.push(widget.id);
    dashboardToUpdate.update();
  };

  handleOnSave = (state, entity, onContinue) => {
    const { report, widgetImageId } = this.props;
    const newWidget = clone(state.newEntity);
    newWidget.widgetType = 'TEXT_ACCOUNTANT';
    newWidget.container = widgetImageId;
    newWidget.origins = newWidget.origins.map(
      (device) => {
        const d = {
          categories: device.categories,
          type: 'DEVICE',
          connectedDevices: {
            device_id: device.device_id,
            id: device.id,
            attributes: [],
            lazy_attributes: [],
            static_attributes: [],
            command_attributes: [],
          },
        };

        device.joinedAttributes.forEach((attribute) => {
          if (attribute.selected) {
            switch (attribute.attrType) {
              case 'lazy':
                d.connectedDevices.lazy_attributes.push(attribute.name);
                break;
              case 'static':
                d.connectedDevices.static_attributes.push(attribute.name);
                break;
              default:
                d.connectedDevices.attributes.push(attribute.name);
            }
          }
        });
        return d;
      },
    );
    const widgetToSave = getWidgetModel(newWidget);
    if (report) {
      widgetToSave.save(this.updateReport);
    } else {
      widgetToSave.save(this.updateDashboard);
    }
    onContinue();
  }

  render() {
    const { customButton } = this.props;
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        customButton={customButton}
        entity={Widget}
        onSave={this.handleOnSave}
        metadata={{
          wizardType: 'widgets',
        }}
      >

        <WizardStep
          component={BasicInfo}
          title={<FormattedMessage id="widgets.wizard.step1.title" />}
          icon={CreateWidgteIcon}
          lead={<FormattedMessage id="widgets.wizard.step1.text" />}
        />

        <WizardStep
          component={SelectDatasources}
          title={<FormattedMessage id="widgets.wizard.step2.title" />}
          icon={DeviceIcon}
          lead={<FormattedMessage id="widgets.wizard.step2.text" />}
        />

        <WizardStep
          component={ConfigureWidget}
          title={<FormattedMessage id="widgets.wizard.step4.title" />}
          lead={<FormattedMessage id="widgets.wizard.step4.text" />}
        />

        <WizardStep
          component={WidgetPreview}
          title={<FormattedMessage id="widgets.wizard.step5.title" />}
          lead={<FormattedMessage id="widgets.wizard.step5.text" />}
        />

        <WizardStep
          component={AssignPermissions}
          title={<FormattedMessage id="widgets.wizard.step6.title" />}
          lead={<FormattedMessage id="widgets.wizard.step6.text" />}
          onSave={this.handleOnSave}
        />

        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateWidgets;
