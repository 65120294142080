import React, { useState, useEffect } from 'react';
import {
  Row, Container, Col, ListGroupItem, ListGroup,
} from 'reactstrap';
import { clone } from 'ramda';
import styled from 'styled-components';
import ExpansionPanel from '../../../../../components/ExpansionPanel/index';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import Switch from '../../../../../elements/Switch';
import { isMobile } from '../../../../../helpers/getBreakpoint';
import ContentEditable from '../../../../helpers/ContentEditable/ContentEditable';
import { Components } from '../../../../../models/Widget/configurationSheets/utils';

const TitleWithStepper = styled.div`
  &:before {
    content: '';
    width: ${(p) => Math.floor((p.totalSelectedAttributes * 100) / p.totalAttributes)}%;
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 3px;
    border-radius: 1px;
    transition: 0.5s ease all;
  }
`;

const ExpansionPanelHeader = (data, opened) => {
  const totalSelectedAttributes = data.joinedAttributes
    ? data.joinedAttributes().filter((a) => a.selected).length
    : 0;
  const totalAttributes = data.joinedAttributes
    ? data.joinedAttributes().length
    : 0;
  return (
    <Container fluid className="deviceHeader">
      <Row>
        <Col sm={6} className="identificator">
          {data.device_id}
        </Col>
        <Col sm={6}>{data.name}</Col>
        {!opened && data.joinedAttributes && (
          <Col sm={3} className="selectedProperties">
            {totalSelectedAttributes}
            /
            {totalAttributes}
            {' '}
            <FormattedMessage id="widgets.wizard.properties" />
          </Col>
        )}
        {' '}
      </Row>
    </Container>
  );
};

const ExpansionPanelContent = (data, activeAttribute, checkedAll, contentEditableChange) => {
  const totalSelectedAttributes = data.joinedAttributes
    ? data.joinedAttributes().filter((a) => a.selected).length
    : 0;
  const totalAttributes = data.joinedAttributes
    ? data.joinedAttributes().length
    : 0;

  return (
    <Container fluid className="deviceBody">
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={12} lg={12}>
              <TitleWithStepper
                className="title titleWithStepper"
                totalSelectedAttributes={totalSelectedAttributes}
                totalAttributes={totalAttributes}
              >
                <FormattedMessage id="widgets.wizard.properties" />
                {' '}
                (
                {totalSelectedAttributes}
                /
                {totalAttributes}
                )
                <p className="selectAll">
                  {!isMobile() && <FormattedMessage id="widgets.wizard.check.all" />}
                  <Switch
                    checked={totalSelectedAttributes >= totalAttributes}
                    onChange={(status) => checkedAll(status, data)}
                  />
                </p>
              </TitleWithStepper>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={12}>
              <ul className="list-unstyled">
                {data.joinedAttributes().map((attribute) => (
                  <li key={`list-unstyled-${attribute.name}`}>
                    <Switch
                      checked={attribute.selected}
                      onChange={(status) => activeAttribute(status, attribute, data)}
                    />
                    {' '}
                    <ContentEditable
                      className="labelFocus"
                      tag="at"
                      onBlur={contentEditableChange}
                      id={attribute.name}
                      editable
                    >
                      {attribute.alias}
                    </ContentEditable>

                    <div className="originalNameTableConf">
                      {attribute.name}
                    </div>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const getAllAttributesForList = (widget) => {
  const hiddenColumns = widget?.config.appearance.hidden ?? [];
  const alias = widget?.config.labels.alias ?? [];
  const allAtributes = [];
  const allAtributesFull = [];
  // TODO: [v2] Remove when v2 is fully implemented.
  const widgetSources = widget.sources
    ? widget.sources
    : widget.origins.map((w) => w.connectedDevices);
  widgetSources.forEach((source) => {
    if (source.readDevice) {
      source.readDevice.attributes.forEach((att) => {
        if (!allAtributes.includes(att.name)) {
          allAtributes.push(att.name);
          const attrToAdd = att;
          attrToAdd.selected = !hiddenColumns.includes(att.name);
          attrToAdd.alias = alias[att.name] ? alias[att.name] : att.name;
          allAtributesFull.push(attrToAdd);
        }
      });
      source.readDevice.static_attributes.forEach((att) => {
        if (!allAtributes.includes(att.name)) {
          allAtributes.push(att.name);
          const attrToAdd = att;
          attrToAdd.selected = !hiddenColumns.includes(att.name);
          attrToAdd.alias = alias[att.name] ? alias[att.name] : att.name;
          allAtributesFull.push(attrToAdd);
        }
      });
    } else {
      source.fields.forEach((attr) => {
        if (!allAtributes.includes(attr)) {
          allAtributes.push(attr);
          const attrToAdd = {};
          attrToAdd.name = attr;
          attrToAdd.selected = !hiddenColumns.includes(attr);
          attrToAdd.alias = alias[attr] ? alias[attr] : attr;
          allAtributesFull.push(attrToAdd);
        }
      });
    }
  });
  return allAtributesFull;
};

const ListComponent = ({ widget, handler, openedPanels }) => {
  const filterAttributes = (oneWidget) => {
    const filteredAttributes = [];
    getAllAttributesForList(oneWidget).forEach((o) => {
      if (!filteredAttributes.some((s) => s.name === o.name)) filteredAttributes.push(o);
    });
    return filteredAttributes;
  };

  const [data, setData] = useState({ joinedAttributes: () => filterAttributes(widget) });

  const [alias, setAlias] = useState(widget?.config?.labels?.alias);

  const handleAttributeStatusChange = (status, attribute) => {
    const { config } = widget;
    const { appearance } = config;
    const { hidden } = appearance;
    const hiddenColumnsUpdate = clone(hidden);
    if (!status) {
      hiddenColumnsUpdate.push(attribute.name);
    } else {
      const index = hiddenColumnsUpdate.indexOf(attribute.name);
      if (index > -1) {
        hiddenColumnsUpdate.splice(index, 1);
      }
    }
    handler({ name: 'hidden', value: hiddenColumnsUpdate }, 'appearance', Components.listComponent);
  };

  const handleSelectAllChange = (status, device) => {
    const attributesForListClone = clone(data);
    const hiddenColumnsUpdate = [];
    if (!status) {
      device.joinedAttributes().forEach((attr) => {
        hiddenColumnsUpdate.push(attr.name);
      });
    }
    attributesForListClone.joinedAttributes().map((attr) => (attr.selected = status));
    setData(attributesForListClone);
    handler({ name: 'hidden', value: hiddenColumnsUpdate }, 'appearance', Components.listComponent);
  };

  const handleContentEditableChange = (id, data) => {
    const aliascopy = clone(alias);
    aliascopy[id] = data;
    setAlias(aliascopy);
    handler({ name: 'alias', value: aliascopy }, 'labels', Components.listComponent);
  };
  return (
    <ListGroup>
      <ListGroupItem
        key="list-item-0"
        className="d-flex justify-content-start"
      >
        <ExpansionPanel
          data={data}
          header={ExpansionPanelHeader}
          content={() => ExpansionPanelContent(data,
            (status, attribute) => handleAttributeStatusChange(status, attribute, handler, widget),
            (status, device) => handleSelectAllChange(status, device, handler, widget),
            (id, data) => handleContentEditableChange(id, data, handler))}
          opened={openedPanels[0]}
          onOpen={null}
        />
      </ListGroupItem>
    </ListGroup>
  );
};

export default ListComponent;
