import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  fork,
} from 'redux-saga/effects';
import Rule from '../../../models/Rule';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/rules';

/**
 * Actions for WATCHERS
 * */

// Try to recover all rule form DB
export function* getRulesThroughApi({ payload }) {
  try {
    const response = yield call(Api.readRules,
      payload.page,
      payload.size,
      payload.filters);
    if (response.status === 200) {
      const rules = response.data.objects.map(rule => new Rule(rule));
      const total = response.data.total;
      yield put({ type: TYPES.GET_RULES_SUCCESS, payload: {rules, total}});
    } else {
      yield put({ type: TYPES.GET_RULES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_RULES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an rule form DB
export function* getRuleThroughApi({ payload }) {
  try {
    const response = yield call(Api.readRule, payload);
    if (response.status === 200) {
      const ruleFromDB = new Rule(response.data.object);
      yield put({ type: TYPES.GET_RULE_SUCCESS, payload: ruleFromDB });
    } else {
      yield put({ type: TYPES.GET_RULE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_RULE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to create rule in DB
export function* addRulesThroughApi({ payload }) {
  try {
    const response = yield call(Api.addRule, payload);
    if (response.status === 201) {
      const ruleFromDB = new Rule(response.data.object);
      yield put({ type: TYPES.ADD_RULE_SUCCESS, payload: ruleFromDB });
    } else {
      yield put({ type: TYPES.ADD_RULE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_RULE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update rule in DB
export function* updateRulesThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateRule, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_RULE_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.MODIFY_RULE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_RULE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update rule in DB
export function* updateRulesPermissionsThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateRulePermissions, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_RULE_PERMISSIONS_SUCCESS,
        payload: new Rule(response.data.object),
      });
    } else {
      yield put({ type: TYPES.MODIFY_RULE_PERMISSIONS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_RULE_PERMISSIONS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update rule engine in DB
export function* updateRulesengineThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateRuleEngine, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_RULE_ENGINE_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.MODIFY_RULE_ENGINE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_RULE_ENGINE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


// ACTIONS
// TRY to add Action to Rule
export function* addRulesActionThroughApi({ payload }) {
  try {
    const response = yield call(Api.addRuleAction, payload);

    if (response.status === 201) {
      yield put({
        type: TYPES.ADD_RULE_ACTION_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.ADD_RULE_ACTION_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_RULE_ACTION_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


// TRY to update Action to Rule
export function* updateRulesActionThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateRuleAction, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.UPDATE_RULE_ACTION_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.UPDATE_RULE_ACTION_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.UPDATE_RULE_ACTION_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// TRY to delete Action to Rule
export function* deleteRulesActionThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteRuleAction, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.DELETE_RULE_ACTION_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.DELETE_RULE_ACTION_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_RULE_ACTION_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


// Try to delete rule in DB
export function* deleteRulesThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteRule, payload);
    if (response.status === 204) yield put({ type: TYPES.DELETE_RULE_SUCCESS, payload });
    else {
      yield put({ type: TYPES.DELETE_RULE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_RULE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update rule status in DB
export function* updateRulesStatusThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateRuleStatus, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_RULE_STATUS_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.MODIFY_RULE_STATUS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_RULE_STATUS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update rule temporal configuration in DB
export function* updateRulesCronThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateRuleCron, payload);

    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_RULE_CRON_SUCCESS,
        payload: new Rule(response.data.object)
      });
    } else {
      yield put({ type: TYPES.MODIFY_RULE_CRON_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_RULE_CRON_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}


/**
 * WATCHERS
 */
// Watcher looking for GET_RULES_REQUEST
function* watcherGetRules() {
  yield takeLatest('GET_RULES_REQUEST', getRulesThroughApi);
}

// Watcher looking for GET_RULE_REQUEST
function* watcherGetRule() {
  yield takeLatest('GET_RULE_REQUEST', getRuleThroughApi);
}

// Watcher looking for ADD_RULE_REQUEST
function* watcherAddRules() {
  yield takeEvery('ADD_RULE_REQUEST', addRulesThroughApi);
}

// Watcher looking for MODIFY_RULE_REQUEST
function* watcherUpdateRules() {
  yield takeLatest('MODIFY_RULE_REQUEST', updateRulesThroughApi);
}

// Watcher looking for MODIFY_RULE_PERMISSIONS_REQUEST
function* watcherUpdateRulesPermissions() {
  yield takeLatest('MODIFY_RULE_PERMISSIONS_REQUEST', updateRulesPermissionsThroughApi);
}

// Watcher looking for MODIFY_RULE_ENGINE_REQUEST
function* watcherUpdateRulesEngine() {
  yield takeLatest('MODIFY_RULE_ENGINE_REQUEST', updateRulesengineThroughApi);
}

// Watcher looking for DELETE_RULE_REQUEST
function* watcherDeleteRules() {
  yield takeEvery('DELETE_RULE_REQUEST', deleteRulesThroughApi);
}


// Watcher looking for ADD_RULE_ACTION_REQUEST
function* watcherAddRulesActionThroughApi() {
  yield takeLatest('ADD_RULE_ACTION_REQUEST', addRulesActionThroughApi);
}


// Watcher looking for ADD_RULE_ACTION_REQUEST
function* watcherUpdateRulesActionThroughApi() {
  yield takeLatest('UPDATE_RULE_ACTION_REQUEST', updateRulesActionThroughApi);
}


// Watcher looking for DELETE_RULE_ACTION_REQUEST
function* watcherDeleteRulesActionThroughApi() {
  yield takeLatest('DELETE_RULE_ACTION_REQUEST', deleteRulesActionThroughApi);
}


// Watcher looking for MODIFY_RULE_STATUS_REQUEST
function* watcherUpdateRulesStatusThroughApi() {
  yield takeLatest('MODIFY_RULE_STATUS_REQUEST', updateRulesStatusThroughApi);
}

// Watcher looking for MODIFY_RULE_CRON_REQUEST
function* watcherUpdateRulesCronThroughApi() {
  yield takeLatest('MODIFY_RULE_CRON_REQUEST', updateRulesCronThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetRules),
    fork(watcherGetRule),
    fork(watcherAddRules),
    fork(watcherUpdateRules),
    fork(watcherUpdateRulesPermissions),
    fork(watcherDeleteRules),
    fork(watcherUpdateRulesEngine),
    fork(watcherAddRulesActionThroughApi),
    fork(watcherUpdateRulesActionThroughApi),
    fork(watcherDeleteRulesActionThroughApi),
    fork(watcherUpdateRulesStatusThroughApi),
    fork(watcherUpdateRulesCronThroughApi),
  ]);
}
