/* eslint-disable react/no-array-index-key */
import React from 'react';
import { injectIntl } from 'react-intl';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { clone } from 'ramda';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../../elements/Button';
import Field from '../../../../../elements/Field';
import './styles.scss';
import ContentEditable from '../../../../helpers/ContentEditable/ContentEditable';
import ColorPicker from '../../../../../components/ColorPicker';

export const Buttons = ({ onClick, type }) => (
  <div className="buttonAdd">
    <ButtonCustom handleOnClick={() => onClick()} className="btn btn-secondary" label="Añadir grupo" />
  </div>
);

const constraintsFilter = {
  name: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
  condition: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
  valueCondition: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
};

class EditElementConditions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      attributes: props.attributes,
      widgetSelected: props.elementConditions.devices ?? [{}],
      widgets: props.widgets,
      elementConditions: props.elementConditions,
      color: props.elementConditions.color,
      name: props.elementConditions.name,
      id: props.elementConditions.id,
      lastIteration: props.lastIteration,
      categorySelected: props.categorySelected,
      devices: props.devices,
      index: props.index,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.elementConditions) !== JSON.stringify(this.props.elementConditions)) {
      this.setState({
        elementConditions: this.props.elementConditions,
        lastIteration: this.props.lastIteration,
        color: this.props.elementConditions.color,
        name: this.props.elementConditions.name,
        id: this.props.elementConditions.id,
        devices: this.props.devices,
        widgetSelected: this.props.elementConditions.devices,
      });
    } else if (prevProps.lastIteration !== this.props.lastIteration) {
      this.setState({ lastIteration: this.props.lastIteration });
    } else if (JSON.stringify(prevProps.devices) !== JSON.stringify(this.props.devices)) {
      this.setState({ devices: this.props.devices });
    }
  }

    onChangeSelect = (value, option) => {
      const { elementConditions: _elementConditions, widgetSelected: _widgetSelected, widgets } = this.state;
      const elementConditions = clone(_elementConditions);
      const widgetSelected = [..._widgetSelected];
      let newElement = null;
      widgets.forEach((d) => {
        if (d.connectedDevices.id === option) {
          newElement = {
            name: this.state.name,
            color: this.state.color,
            devices: [{
              id: d.connectedDevices.id,
              device_id: d.connectedDevices.device_id,
            }],
          };
        }
      });
      const dev = newElement.devices[0];
      elementConditions.devices.push(dev);

      this.props.handleElementCondition(elementConditions);

      widgetSelected.push(dev);
      this.setState({
        elementConditions,
        widgetSelected,
      });
    }

    removeDevice = (device) => {
      const { widgetSelected: _widgetSelected, elementConditions: _elementConditions } = this.state;
      const widgetSelected = clone(_widgetSelected);
      const elementConditions = clone(_elementConditions);
      const index = null;

      if (elementConditions.name === this.state.name) {
        elementConditions.devices = elementConditions.devices.filter((ec) => ec.id !== device.id);
      }

      this.props.handleElementCondition(elementConditions);
      this.setState({ widgetSelected: widgetSelected.filter((ec) => ec.id !== device.id), elementConditions });
    }

    handleChange = (c) => {
      const { elementConditions: _elementConditions, name } = this.state;
      const elementConditions = { ..._elementConditions };
      elementConditions.color = c.hex;

      this.setState({ elementConditions, color: c.hex });
      this.props.handleElementCondition(elementConditions);
    }

    onChangeName = (id, data) => {
      const { elementConditions: _elementConditions } = this.state;
      const elementConditions = { ..._elementConditions };
      elementConditions.name = data;

      this.setState({ elementConditions, name: data });
      this.props.handleElementCondition(elementConditions);
    }

    render() {
      const {
        name, widgetSelected, color, lastIteration, id, devices, index,
      } = this.state;
      const {
        editable, buttonRemove,
      } = this.props;
      const widgets = [];
      const del = [];
      devices.map((d, index) => {
        widgets.push({ value: d.id, name: d.device_id.charAt(0).toUpperCase() + d.device_id.slice(1) });

        if (widgetSelected.length > 0) {
          widgetSelected.forEach((widget) => {
            if (widget.device_id === d.device_id) {
              del.push(d);
            }
          });
        }
      });

      if (del.length > 0) {
        del.forEach((name) => {
          widgets.splice(name, 1);
        });
      }
      return (
        <div className="elementContainer">
          <Row>
            <h1>
              GRUPO DE ELEMENTOS
              {index}
            </h1>
          </Row>
          <Row className="containerConditions">
            <Col sm={5} md={5} className="colorPickerMap">
              <ColorPicker
                style={{ 'margin-top': '20px', 'border-radius': '5px' }}
                name="prueba"
                onChangeComplete={this.handleChange}
                color={color !== '' ? color : ''}
                label="Color"
              />

              <ContentEditable
                className="labelFocus"
                tag="at"
                onBlur={this.onChangeName}
                id={name}
                editable
              >

                <span className="spanEditable">{name}</span>
              </ContentEditable>
            </Col>

            <Col sm={5} md={5} className="selectStyle">
              <Field
                name="conditions"
                type="select"
                options={widgets}
                className="field"
                onChange={this.onChangeSelect}
              />
            </Col>
            {buttonRemove ? (
              <Col sm={2} md={2} className="buttonRemove">
                <ButtonCustom
                  handleOnClick={() => this.props.removeGroup(id)}
                  className="btn btn-secondary"
                  label="-"
                />
              </Col>
            ) : null}
          </Row>

          <div className="devices">
            {widgetSelected && widgetSelected.length > 0
              ? widgetSelected.map((device) => (
                <div style={{ background: `${color}10` }}>
                  <span>{device.device_id}</span>
                  <i style={{ color }} onClick={() => this.removeDevice(device)} className="uil uil-multiply" />
                </div>
              ))

              : null}
          </div>

          {lastIteration ? <Buttons className="button" type="-" onClick={() => this.props.addGroup()} /> : null}
        </div>
      );
    }
}

EditElementConditions.defaultProps = {
  entity: {},
};

export default injectIntl(EditElementConditions);
