/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { clone } from 'ramda';
import Checkbox from '@material-ui/core/Checkbox';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import categories from './categories';
import RemoveHoc from '../../../../helpers/RemoveComponent';
import Widget from '../../../../../models/Widget/index';
import OptionsButton from './OptionsButton';
import colors from '../../../../../configuration/colors';

const CheckedIcon = (
  <div className="checkedBorder">
    <div className="checkedFill" />
  </div>
);

const UncheckedIcon = (
  <div className="unchecked" />
);

const IndeterminateCheckedIcon = (
  <div className="checkedBorder">
    <div className="checkedIndeterminate" />
  </div>
);

const RemoveButton = ({ showModal }) => (
  <i
    className="uil uil-trash-alt trashIcon"
    onClick={showModal}
    role="button"
    tabIndex={-1}
  >
    <span className="trashText">
      <FormattedMessage id="widgetLinked.list.options.removeSelection" />
    </span>
  </i>
);

const Actions = styled.div`
  float: right;
  color: ${colors['ui-Black']};
  font-size: 1em;
  line-height: 1.2em;
`;

function ListLinkedWidgets(props) {
  const { widgets, dispatchState } = props;

  const initCheckboxList = (widgetList) => {
    const list = {};
    widgetList.forEach((widget) => {
      list[widget.id] = false;
    });
    return list;
  };

  const [checkboxList, setCheckboxList] = useState(initCheckboxList(widgets));
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedIndeterminate, setCheckedIndeterminate] = useState(false);
  const [widgetList, setWidgetList] = useState(widgets);
  const [widgetsToDelete, setWidgetsToDelete] = useState([]);

  const RemoveDOM = RemoveHoc(RemoveButton, { class: Widget, nameData: 'widgetLinked' });

  const handleCheck = (widgetId) => {
    const checkboxListCopy = clone(checkboxList);
    checkboxListCopy[widgetId] = !checkboxListCopy[widgetId];
    setCheckboxList(checkboxListCopy);
    if (!Object.values(checkboxListCopy).includes(true)) {
      setCheckedAll(false);
      setCheckedIndeterminate(false);
    }
    if (!Object.values(checkboxListCopy).includes(false)) {
      setCheckedAll(true);
    }
    if (Object.values(checkboxListCopy).includes(true)
          && Object.values(checkboxListCopy).includes(false)) {
      !checkedIndeterminate && setCheckedIndeterminate(true);
      checkedAll && setCheckedAll(false);
    }
  };

  const handleCheckAll = () => {
    const checkboxListCopy = clone(checkboxList);
    if (!Object.values(checkboxListCopy).includes(false)) {
      Object.keys(checkboxList).forEach((widgetId) => {
        checkboxListCopy[widgetId] = false;
      });
      setCheckedAll(false);
    } else {
      Object.keys(checkboxList).forEach((widgetId) => {
        checkboxListCopy[widgetId] = true;
      });
      setCheckedAll(true);
    }
    setCheckedIndeterminate(false);
    setCheckboxList(checkboxListCopy);
  };

  const updateWidgetList = () => {
    const newList = widgetList.filter((widget) => !checkboxList[widget.id]);
    setWidgetList(newList);
    setCheckedAll(false);
    setCheckboxList(initCheckboxList(newList));
    setCheckedIndeterminate(false);
  };

  useEffect(() => {
    setWidgetsToDelete(widgetList.filter((widget) => checkboxList[widget.id]));
  }, [checkboxList, widgetList]);

  useEffect(() => {
    setWidgetList(widgets);
  }, [widgets]);

  return (
    <div className="listLinkedWidgets">
      <div className="listHeader">
        <Checkbox
          checked={checkedAll}
          checkedIcon={CheckedIcon}
          className="checkAll"
          icon={checkedIndeterminate ? IndeterminateCheckedIcon : UncheckedIcon}
          onChange={() => handleCheckAll()}
        />
        {(checkedIndeterminate || checkedAll)
          && <RemoveDOM rowContent={widgetsToDelete} cleanupFunction={updateWidgetList} />}
      </div>
      <div>
        {widgetList.map((w) => (
          <div className="cardBase">
            <div className="checkAndText">
              <Checkbox
                checked={checkboxList[w.id]}
                checkedIcon={CheckedIcon}
                className="checkbox"
                icon={UncheckedIcon}
                onChange={() => handleCheck(w.id)}
              />
              <div className="textDiv">
                <span className="nameText">{w.name}</span>
                <span className="typeText">
                  {categories.find((c) => c.value === w.widgetType).name}
                </span>
              </div>
            </div>
            <div className="buttonOptions">
              <Actions>
                <OptionsButton
                  widget={w}
                  entity="widget"
                  dispatchState={dispatchState}
                  permissionToEdit
                />
              </Actions>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

ListLinkedWidgets.propTypes = {
  widgets: PropTypes.oneOfType([PropTypes.array]),
};

ListLinkedWidgets.defaultProps = {
  widgets: [],
};

RemoveButton.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default ListLinkedWidgets;
