/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../../components/Menu/index';
import OptionsForLinkedWidget from './OptionsForLinkedWidget';
import useDashboardContext from '../../../../../Contexts/DashboardContext/consumer';
import './styles.scss';
import { handleSetActive } from '../../../../Dashboards/ShowV2/reducer';
import { isShowV2 } from '../../../../../helpers/utils';

function OptionsMenu(props) {
  const {
    widget, clickView, permissionToEdit, dispatchState,
  } = props;

  const openButton = (events) => (
    <div
      onClick={events.onClick}
      role="presentation"
    >
      <span className="titleText">
        <i className="uil uil-ellipsis-v" />
      </span>
    </div>
  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="right"
      className="more menuDiv"
    >
      <OptionsForLinkedWidget
        widget={widget}
        clickView={clickView}
        permissionToEdit={permissionToEdit}
        dispatchState={dispatchState}
      />
    </Menu>
  );
}

OptionsMenu.propTypes = {
  widget: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  permissionToEdit: PropTypes.bool,
};

OptionsMenu.defaultProps = {
  widget: {},
  clickView: () => {},
  permissionToEdit: undefined,
};

function OptionsButton(props) {
  const {
    widget, permissionToEdit, entity, dispatchState,
  } = props;

  // ToDo Remove when implement ShowV2
  let { handleOpenProfileWidget: clickView } = useDashboardContext();
  if (isShowV2()) clickView = () => handleSetActive(widget, dispatchState);

  return (
    <OptionsMenu
      clickView={
        (widgetSelected, profileType) => clickView(widget, profileType, entity)
      }
      widget={widget}
      permissionToEdit={permissionToEdit}
      dispatchState={dispatchState}
    />

  );
}

OptionsButton.defaultProps = {
  widget: {},
  clickView: () => {},
  permissionToEdit: undefined,
  entity: '',
};

export default OptionsButton;
