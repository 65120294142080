import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import EditPermissions from '../../helpers/Profile/EditPermissions';
import Kpi from '../../../models/Kpi';
import { havePermissionToEdit } from '../../../helpers/utils';
import checkPermission from '../../../components/PrivateComponent/checkPrivateComponent';
import EditType from './components/EditType';

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { ...this.props.data },
    };
  }

  componentDidMount() {
    const backendPermisssion = checkPermission(this.props.userMe, '/app/kpis', 'update');
    havePermissionToEdit(this.props.data.permissions_policy, this.props.data.owner).then(
      (permissionToEdit) => (
        this.setState({ permissionToEdit: permissionToEdit && backendPermisssion })),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ data: { ...this.props.data } });
    }

    if (JSON.stringify(prevProps.kpis) !== JSON.stringify(this.props.kpis)) {
      const foundData = this.props.kpis.find((o) => o.id === this.props.data.id);
      this.setState({ data: foundData });
    }
  }

  handleOnSave = (id, data) => {
    if (this.state[id] !== data) {
      const newData = { ...this.state.data, [id]: data };
      const updated = new Kpi(newData).update();
      if (updated.error) {
        delete updated.error;
        this.setState({
          errors: updated,
        });
      } else {
        this.setState({
          errors: undefined,
        });
      }
    }
  };

  render() {
    const { data, errors, permissionToEdit } = this.state;
    return (
      <ProfileComponent
        data={data}
        titles={[
          <FormattedMessage id="kpis.profile.configuration" />,
          <FormattedMessage id="kpis.profile.permissions" />,
        ]}
        save={this.handleOnSave}
        errors={errors}
        editable={permissionToEdit}
      >
        <EditType data={data} entity={Kpi} permissionToEdit={permissionToEdit} />
        <EditPermissions data={data} entity={Kpi} permissionToEdit={permissionToEdit} />
      </ProfileComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  kpis: state.get('kpis').get('list').toJS(),
  userMe: state.get('users').get('userMe'),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
