import Faker from 'faker'
import { exampleFiware, exampleProtocol, exampleTransport, examplePermissionPolicy } from "../redux/services/reducer"
import { deviceManagement } from '../../configuration/config'
import { requestAPI } from './../../helpers/'
import parseFilters from '../../helpers/parseFilters';

const endPoint = deviceManagement.urlAPI.concat(deviceManagement.paths.service);

const exampleBodyFn = (numberOfServiceToGenerate = 50) => {
    const ServicesFaker = [];
    for (let i = 0; i < numberOfServiceToGenerate; i++) {
        ServicesFaker.push({
            id: Faker.random.uuid(),
            api_key: Faker.random.word(),
            resource: Faker.random.word(),
            name: Faker.random.word(),
            template_id: Faker.random.uuid(),
            permissions_policy: Faker.random.arrayElement(examplePermissionPolicy),
            owner: Faker.random.uuid(),
            entity_type: Faker.random.word(),
            fiware: Faker.random.arrayElement(exampleFiware),
            transport: Faker.random.arrayElement(exampleTransport),
            attributes: [],
            lazy_attributes: [],
            command_attributes: [],
            static_attributes: [],
            internal_attributes: [],
            protocol: Faker.random.arrayElement(exampleProtocol)

        })
    }
    return { "services": ServicesFaker };
};

/*** First version of Service CRUD */
// PLURAL
export const readServices = async (page, size, filters) => {
    const queryPage = page ? `x-query-page=${page}` : '';
    const querySize = size ? `x-query-size=${size}` : '';
    const queryFilters = filters ? parseFilters(filters) : '';
    const params = {
        endPoint:
          endPoint + '/list/all?'.concat(queryPage, '&', querySize, queryFilters),
        statusOK: 200,
        toJSON: true,
        returnData: 'services'
    }
    return requestAPI(params, exampleBodyFn);
};

export const readMyServices = async (page, size, filters) => {

    const queryPage = page ? `x-query-page=${page}` : '';
    const querySize = size ? `x-query-page=${size}` : '';
    const queryFilters = filters ? parseFilters(filters) : '';

    const params = {
        endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
        statusOK: 200,
        toJSON: true,
        returnData: 'services'
    }
    return requestAPI(params, exampleBodyFn);
};

// SINGULAR
export const readService = async (service) => {

    if (!service['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint + "/" + service.id,
        statusOK: 200,
        toJSON: true,
        returnData: 'service'
    };

    return await requestAPI(params, exampleBodyFn);
    // let answer = await requestAPI(params, exampleBodyFn);

    // if (answer['status'] === params.statusOK) {
    //     const roles = await readServiceRoles(service.id);
    //     answer.data.roles = roles;
    // }
    //
    // return answer;
};


export const readServiceRoles = async (service) => {
    if (!service['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint + "/" + service.id + "/roles",
        statusOK: 200,
        toJSON: true,
        returnData: 'roles'
    }

    return requestAPI(params, exampleBodyFn);
}


export const addService = async (service) => {

    if (service['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint,
        statusOK: 201,
        toJSON: true,
        returnData: 'service',
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(service),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
};


export const updateService = (service) => {

    if (!service['id']) return { status: 400, error: 'missing id attr' };

    const params = {
        endPoint: endPoint + "/" + service.id,
        statusOK: 200,
        toJSON: false,
        returnData: service,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify(service),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};



export const deleteService = (service) => {

    if (!service['id']) return { status: 400, error: 'missing id attr' }
    const params = {
        endPoint: endPoint + "/" + service.id,
        statusOK: 204,
        toJSON: false,
        returnData: service,
        requestConfig: {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
};
