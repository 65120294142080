import * as TYPES from './types';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export const addKpi = (Kpi) => store.dispatch({
  type: TYPES.ADD_KPI_REQUEST,
  payload: Kpi,
});

export const addKpiSuccessFlag = (Kpi) => store.dispatch({
  type: TYPES.ADD_KPI_SUCCESS_FLAG,
  payload: Kpi,
});

export const deleteKpis = (Kpi) => store.dispatch({
  type: TYPES.DELETE_KPI_REQUEST,
  payload: Kpi,
});

export const getKpiTable = (payload) => store.dispatch({
  type: TYPES.GET_KPI_TABLE_REQUEST,
  payload,
});

export const getKpi = (Kpi) => store.dispatch({
  type: TYPES.GET_KPI_REQUEST,
  payload: Kpi,
});

export const getKpis = (payload) => store.dispatch({
  type: TYPES.GET_KPIS_REQUEST,
  payload,
});

export const getMyKpis = (payload) => store.dispatch({
  type: TYPES.GET_MY_KPIS_REQUEST,
  payload,
});

export const updateKpi = (Kpi) => store.dispatch({
  type: TYPES.UPDATE_KPI_REQUEST,
  payload: Kpi,
});

export const clearKpisState = (Kpi) => store.dispatch({
  type: TYPES.CLEAR_KPIS_STATE,
  payload: Kpi,
});

export const executeKpi = (Kpi) => store.dispatch({
  type: TYPES.EXECUTE_KPI_REQUEST,
  payload: Kpi,
});
