import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { ConfigureProtocol } from '../../helpers/Profile/ProfileDevice/ConfigureProtocol';
// if we put EditProperties between brackets, the site crashes when we edit properties
// eslint-disable-next-line import/no-named-as-default
import EditProperties from '../../helpers/Profile/ProfileDevice/EditProperties';
import EditPermissions from '../../helpers/Profile/EditPermissions';
import Device from '../../../models/Device';
import { havePermissionToEdit } from '../../../helpers/utils';
import checkPermission from '../../../components/PrivateComponent/checkPrivateComponent';

const STRING_TRANSLATION_HEADER = 'devices.profile.properties';

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      data: { ...this.props.data },
    };
  }

  componentDidMount() {
    const { userMe, data } = this.props;
    const fromService = !!data.service_id
    const backendPermisssion = checkPermission(userMe, '/app/devices', 'update');
    havePermissionToEdit(data.permissions_policy, data.owner)
      .then((permissionToEdit) => this.setState(
        { permissionToEdit: permissionToEdit && backendPermisssion && !fromService},
      ));
  }

  componentDidUpdate(prevProps) {
    const { data, devices } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.updateStateData();
    }

    if (JSON.stringify(prevProps.devices) !== JSON.stringify(devices)) {
      const foundData = devices.find((o) => o.id === data.id);
      this.updateStateFoundData(foundData);
    }
  }

  handleOnSave = (id, data) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state[id] !== data) {
      // eslint-disable-next-line react/destructuring-assignment
      const newData = { ...this.state.data, [id]: data };
      // TODO remove when all devices are updated.
      newData.organization = newData.organization ? newData.organization : '123456789123456789123456789';
      newData.dataset = newData.dataset ? newData.dataset : '123456789123456789123456789';

      const updated = new Device(newData).update();

      if (updated.error) {
        delete updated.error;
        this.setState({
          errors: updated,
        });
      } else {
        this.setState({
          errors: undefined,
        });
      }
    }
  };

  updateStateData() {
    // eslint-disable-next-line react/destructuring-assignment
    this.setState({ data: { ...this.props.data } });
  }

  updateStateFoundData(foundData) {
    this.setState({ data: foundData });
  }

  render() {
    const { data, errors, permissionToEdit } = this.state;
    return (
      <ProfileComponent
        data={data}
        titles={[
          <FormattedMessage id="devices.profile.protocols" />,
          <FormattedMessage id="devices.profile.properties" />,
          <FormattedMessage id="devices.profile.permissions" />,
        ]}
        save={this.handleOnSave}
        errors={errors}
        editable={permissionToEdit}
      >
        <ConfigureProtocol data={data} entity={Device} />
        <EditProperties
          data={data}
          entity={Device}
          permissionToEdit={permissionToEdit}
          realTimeEnabled
          stringTranslationHeader={STRING_TRANSLATION_HEADER}
        />
        <EditPermissions data={data} entity={Device} permissionToEdit={permissionToEdit} />
      </ProfileComponent>
    );
  }
}

Profile.propTypes = {
  userMe: PropTypes.string,
  data: PropTypes.string,
  devices: PropTypes.string,
};

Profile.defaultProps = {
  userMe: '',
  data: '',
  devices: '',
};

const mapStateToProps = (state) => ({
  devices: state.get('devices').get('list').toJS(),
  userMe: state.get('users').get('userMe'),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
