import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import styled from '@emotion/styled';
import Switch from '../../../../elements/Switch';

const Attribute = styled.div`
  width: 100%;
  color: #2E384D;
  display: flex;
  align-items: center;
  padding: 18px;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  justify-content: space-between;
  span {
    display: flex;
  }
`;

const AttributeBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AttributesWrapper = styled.section`
  background: #F7F8FA;
  padding: 0px 0px 0px 7px;
  border: 1px solid rgba(46,91,255,0.1);
  border-radius: 1px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const Amount = styled.div`
  color: #577EE8;
  padding: 4px 8px 4px 8px; 
  font-size: 13px;
  font-weight: 9600;
  letter-spacing: 0;
  border-radius: 25px;
  line-height: 14px;
  text-align: center;
  background-color: rgb(46, 91, 255, 0.2);
  margin-right: 5px;
`;

const LinkedAttribute = ({
  attribute: {
    selected = false,
    amount,
    name,
  },
  attribute,
  handleChange,
}) => (
  <AttributesWrapper>
    <AttributeBox>
      <Attribute>
        <span>
          <Amount>
            {amount}
          </Amount>
          {name}
        </span>
        <Switch
          title={name}
          checked={selected}
          onChange={(status) => handleChange(
            status,
            attribute,
          )}
        />

      </Attribute>
    </AttributeBox>
  </AttributesWrapper>

);

const LinkedAttributeSelector = ({
  attributes, multiselection = true, filter, update,
}) => {
  const [selectedAttributes, setAttributes] = useState(attributes);

  const onCheckboxChange = (status, attribute) => {
    const selectedAttributesCopy = [...selectedAttributes.map(
      (el) => (el.name === attribute.name ? { ...el, selected: status } : (multiselection ? el : { ...el, selected: false })),
    )];

    setAttributes(selectedAttributesCopy);

    update(selectedAttributesCopy.filter((at) => at.selected).map((at) => at.name));
  };

  useEffect(() => {
    setAttributes(attributes);
  }, [attributes]);

  return (
    <div className="linkedAttributeSelector">
      <Row className="justify-content-center">
        {
          selectedAttributes
            .filter((attrFilter) => attrFilter.name.includes(filter))
            .map((attr) => (
              <Col lg="4" md="12">
                <LinkedAttribute attribute={attr} handleChange={onCheckboxChange} />
              </Col>
            ))
          }

      </Row>
    </div>
  );
};

export default LinkedAttributeSelector;
