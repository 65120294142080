export const generalComparator = (direction, properties) => {
  if (direction === 'asc') {
    return (a, b) => {
      const elemA = properties.split('.').reduce((o, i) => o[i], a);
      const elemB = properties.split('.').reduce((o, i) => o[i], b);
      return elemA > elemB ? 1 : elemA < elemB ? -1 : 0;
    };
  } else {
    return (a, b) => {
      const elemA = properties.split('.').reduce((o, i) => o[i], a);
      const elemB = properties.split('.').reduce((o, i) => o[i], b);
      return elemA < elemB ? 1 : elemA > elemB ? -1 : 0;
    };
  }
};

export const dateIssuedComparator = direction => {
  if (direction === 'asc') {
    return (a, b) =>
      Date.parse(new Date(a.dateIssued)) >
      Date.parse(new Date(b.dateIssued))
        ? 1
        : Date.parse(new Date(a.dateIssued)) <
          Date.parse(new Date(b.dateIssued))
        ? -1
        : 0;
  } else {
    return (a, b) =>
      Date.parse(new Date(a.dateIssued)) <
      Date.parse(new Date(b.dateIssued))
        ? 1
        : Date.parse(new Date(a.dateIssued)) >
          Date.parse(new Date(b.dateIssued))
        ? -1
        : 0;
  }
};
