import React from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty } from 'ramda';
import Field from '../../../../../elements/Field/index';
import { MATH_OPTIONS, GEO_OPTIONS } from '../../../helpers/operatorOptions';
import { isGeoPointOperator } from '../../../helpers/parseToCriterion';
import { ButtonCustom } from '../../../../../elements/Button/index';
import { ReactComponent as IcoOpenMap } from '../../../../../configuration/icons/svg/ico-open-map.svg';
import { editable, mapButton } from '../../RulesWizard.module.scss';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

export const FieldComponent = ({
  name, intl, onChange, ...rest
}) => (
  <>
    {[name].map((key) => (
      <Field
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        name={key}
        placeholder={intl.formatMessage({
          id: `rules.wizard.${key}.placeholder`,
        })}
        label={intl.formatMessage({
          id: `rules.wizard.${key}.label`,
        })}
        id={key}
        key={key}
        onChange={onChange}
      />
    ))}
  </>
);

const FieldComponentWithoutLabel = ({
  name, intl, onChange, value, className, errors,
}) => (
  <>
    {[name].map((key) => (
      <Field
        name={key}
        placeholder={intl.formatMessage({
          id: `rules.wizard.${key}.placeholder`,
        })}
        id={key}
        key={key}
        onChange={({ target: { name, value } }) => onChange(name, Number(value))}
        value={value[key]}
        type="number"
        className={className}
        error={errors ? !!errors[key] : false}
      />
    ))}
  </>
);

const getOptions = (entities) => (entities && entities.length > 0
  ? entities.map((o) => ({ name: o.name, id: o.id, value: o }))
  : []);

export const joinedAttributes = (device) => (device && Object.entries(device).length > 0
  ? [
    // ...device.command_attributes,
    ...device.lazy_attributes,
    // ...device.static_attributes,
    ...device.attributes,
  ]
  : {});

const calcOperatorOptions = (attribute) => {
  if (attribute && attribute.type === 'geo:point') return GEO_OPTIONS;
  return MATH_OPTIONS;
};

const isNumericOperator = (operator) => ['<', '>', '<=', '>='].includes(operator);

const UnitTimeSelector = ({
  onChange, intl, errors, options, value, className,
}) => ['unit'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={value[key]}
    type="select"
    options={options}
    onChange={(name, value) => onChange(name, value)}
    error={errors ? !!errors[key] : false}
    key={key}
    className={className}
  />
));

const DeviceSelector = ({
  condition, onChange, errors, intl, devices, disabled,
}) => ['device'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={condition[key]}
    type="select"
    options={getOptions(devices)}
    onChange={(name, value) => onChange({ [name]: value })}
      /* helperText={errors[key]} */
    error={errors ? !!errors[key] : false}
    key={key}
    disabled={disabled}
  />
));

const AttributeSelector = ({
  condition,
  onChange,
  errors,
  intl,
}) => ['attribute'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={condition[key]}
    type="select"
    options={getOptions(joinedAttributes(condition.device))}
    onChange={(name, value) => onChange({ [name]: value })}
      /* helperText={errors[key]} */
    error={errors ? !!errors[key] : false}
    key={key}
    disabled={isEmpty(condition.device) || !condition.device}
  />
));

const OperatorSelector = ({
  condition, onChange, errors, intl,
}) => ['operator'].map((key) => (
  <Field
    name={key}
    placeholder={intl.formatMessage({
      id: `rules.wizard.${key}.placeholder`,
    })}
    id={key}
    value={condition[key]}
    type="select"
    options={calcOperatorOptions(condition.attribute)}
    onChange={(name, value) => onChange({ [name]: value })}
      /* helperText={errors[key]} */
    error={errors ? !!errors[key] : false}
    key={key}
    disabled={isEmpty(condition.attribute) || !condition.attribute}
  />
));

const ValueField = ({
  condition,
  onChange,
  errors,
  intl,
  setShowMap,
}) => {
  const getField = () => ['value'].map((key, i) => {
    const props = {
      key: `${key}-${i}`,
      name: key,
      id: key,
      placeholder: intl.formatMessage({
        id: `rules.wizard.${key}.placeholder`,
      }),
      error: errors ? !!errors[key] : false,
    };

    if (condition.operator && isGeoPointOperator(condition.operator)) {
      return (
        <div>
          <ButtonCustom
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            type="secondary"
            handleOnClick={() => setShowMap((prev) => !prev)}
            label={<FormattedMessage id={`rules.wizard.mapButton.${condition.value[0] && condition.value[0].properties && !Array.isArray(condition.value) ? 'edit' : 'open'}`} />}
            disabled={!condition.operator}
            svgIcon={condition.value[0] && condition.value[0].properties ? undefined : () => <IcoOpenMap />}
            icon={condition.value[0] && condition.value[0].properties ? 'check-circle' : ''}
            className={mapButton.concat(' ', condition.value[0] && condition.value[0].properties ? editable : '')}
          />
        </div>
      );
    }
    return (
      <Field
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type={isNumericOperator(condition.operator) ? 'number' : 'text'}
        onChange={({ target: { name, value } }) => onChange({ [name]: value })}
        value={condition.value}
        disabled={isEmpty(condition.operator) || !condition.operator}
      />
    );
  });
  return <>{getField()}</>;
};

FieldComponent.propTypes = {
  name: PropTypes.string.isRequired,
  intl: PropTypes.objectOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

ValueField.propTypes = {
  condition: PropTypes.objectOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  intl: PropTypes.objectOf(PropTypes.object).isRequired,
  setShowMap: PropTypes.bool.isRequired,
};

ValueField.defaultProps = {
  errors: {},
};

export {
  DeviceSelector, AttributeSelector, OperatorSelector, ValueField, calcOperatorOptions, FieldComponentWithoutLabel, UnitTimeSelector,
};
export default FieldComponent;
