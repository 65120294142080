import validate from 'validate.js';
import { clone } from 'ramda';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import {
  addUser, updateUser, deleteUser, getUser, getUserRoles, changePassword, resetUserPassword,
} from '../../services/redux/users/actions';
// eslint-disable-next-line import/no-cycle
import { loginUser } from '../../services/redux/auth/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import { ModelExpected } from './attributes';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Class User with Utils functions */
export default class User extends Utils {
    static entityName = 'User';

    constructor(obj = {}) {
      /** Send to utils class the Model and the object passed for build this class */
      super(ModelExpected, obj);
      this.Model = User;
    }

    /** Call Redux action for Save User in DB and Redux */
    save() {
      const filteredConstraints = clone(this.constraints);
      delete filteredConstraints.id;
      const validation = validate(this.getData(), filteredConstraints);
      return validation === undefined ? addUser({
        ...this.getData(), roles: this.roles.map((role) => ({ id: role.id })),
      }) : { error: true, ...validation };
    }

    /** Call Redux action for Update User in DB and Redux */
    update() {
      const validation = validate(this.getData(), this.constraints);
      return validation === undefined ? updateUser({
        ...this.getData(), roles: this.roles.map((role) => ({ id: role.id })),
      }) : { error: true, ...validation };
    }

    changePassword() {
      const validation = validate(this.getData(), this.constraints);
      return validation === undefined ? changePassword({
        ...this.getData(),
        original_password: this.oldPassword,
        password: this.newPassword,
        repassword: this.repeatNewPassword,
      }) : { error: true, ...validation };
    }

    resetPassword() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? resetUserPassword(this.getData()) : {
        error: true, ...validation,
      };
    }

    delete() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? deleteUser(this.getData()) : { error: true, ...validation };
    }

    get() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getUser(this.getData()) : { error: true, ...validation };
    }

    getUserRoles() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getUserRoles(this.getData()) : {
        error: true, ...validation,
      };
    }

    login() {
      const constraintsPassword = {
        length: {
          minimum: 1,
          message:
  <FormattedMessage
    id="Validation.minimum"
    values={{ minimum: '1' }}
  />,
        },
      };

      const validation = validate(this.getData(), {
        email: this.constraints.email, password: constraintsPassword,
      });
      return validation === undefined ? loginUser({
        email: this.email, password: this.password,
      }) : { error: true, ...validation };
    }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('User', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleUser = new User();
export { SampleUser };
