import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import SuccessComponent from '../../../helpers/SuccessPage';
import RuleOkIcon from '../../../../configuration/icons/svg/rule-ok.svg';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';


const Success = ({ errorFetching }) => (
  <SuccessComponent
    title={<FormattedMessage id="rules.wizard.success" />}
    icon={RuleOkIcon}
    error={errorFetching}
  />
);


const mapStateToProps = (state) => ({
  errorFetching: state.get('rules').get('errorFetching'),
});

Success.propTypes = {
  errorFetching: PropTypes.objectOf(PropTypes.object),
};

Success.defaultProps = {
  errorFetching: undefined,
};

export default connect(
  mapStateToProps,
  {},
)(Success);
