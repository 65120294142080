/* eslint-disable import/no-cycle */
import React, {
  useState, memo, useEffect, useMemo,
} from 'react';
import WidgetMenuButton from '../../../../pages/Dashboards/Show/components/WidgetMenuButton';

const WidgetMapUber = React.lazy(
  () => import('../../../../widgets/WidgetMapUberV2'),
);

function MapComponent(props) {
  const {
    widget,
    disableOptions,
    profileType,
    handleOpenProfileWidget,
    handleOpenProfileSendingCommands,
    hasPermissionToEdit,
    onChangeHistoricalConfig, values, hasBeenResized,
    dispatchState,
    sources,
  } = props;

  const mapRef = React.useRef(null);
  const [refreshMap, setRefreshMap] = useState(false);

  const Skeleton = useMemo(() => widget.skeletonComp, []);

  const ActionsForMaps = React.memo((propsAct) => (
    !disableOptions ? (
      <WidgetMenuButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsAct}
        widget={widget}
        profileType={profileType}
        entity="widget"
        clickView={handleOpenProfileWidget}
        clickSendingCommandsView={handleOpenProfileSendingCommands}
        permissionToEdit={hasPermissionToEdit}
      />
    ) : (
      <div />
    )
  ), () => true);

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.sources, widget.config?.data?.type]);

  if (!values) {
    return <Skeleton />;
  }

  return (
    <div className="h-100 w-100" ref={mapRef}>
      <WidgetMapUber
        refreshMap={refreshMap}
        setRefreshMap={setRefreshMap}
        config={widget.config}
        title={widget.name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        actions={React.memo((propsAct) => <ActionsForMaps {...propsAct} />, () => true)}
        data={values}
        connectedDevices={sources}
        widgetType={widget.widgetType}
        widget={widget}
        mapRef={mapRef}
        hasBeenResized={hasBeenResized}
        dispatchState={dispatchState}
      />
    </div>
  );
}

export default memo(MapComponent);
