import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@material-ui/core';
import CustomInput from 'reactstrap/es/CustomInput';
import { Col, Row } from 'reactstrap';
import Form from 'reactstrap/es/Form';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import Field from '../../../../../../elements/Field';
import './styles.scss';

const resolutions = [
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.minute" />, id: 0, value: 'minute' },
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.hour" />, id: 1, value: 'hour' },
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.day" />, id: 2, value: 'day' },
  { name: <FormattedMessage id="kpis.wizard.simple.aggregate.month" />, id: 3, value: 'month' },
];

const operations = [
  { name: <FormattedMessage id="kpis.wizard.simple.operation.mean" />, id: 0, value: 'avg' },
  { name: <FormattedMessage id="kpis.wizard.simple.operation.maximum" />, id: 1, value: 'max' },
  { name: <FormattedMessage id="kpis.wizard.simple.operation.minimum" />, id: 2, value: 'min' },
];

const AggregationOptionsComponent = ({
  handleChange, handleSelectChange, selected, intl, errors, value,
}) => (
  <div>
    <Row>
      <Form>
        <FormGroup className="aggregationOptions">
          <div className="col-daily">
            <CustomInput
              className="customInputAggregation"
              onChange={handleChange}
              value={<FormattedMessage id="kpis.wizard.arithmetic.aggregation.options.lastOccurence" />}
              name="last_occurence"
              type="radio"
              label={<FormattedMessage id="kpis.wizard.arithmetic.aggregation.options.lastOccurence" />}
              id="last_occurence"
              checked={!selected}
            />
            <CustomInput
              onChange={handleChange}
              value={<FormattedMessage id="kpis.wizard.arithmetic.aggregation.options.aggregation" />}
              name="aggregation"
              type="radio"
              label={<FormattedMessage id="kpis.wizard.arithmetic.aggregation.options.aggregation" />}
              id="aggregation"
              checked={selected}
            />
          </div>
        </FormGroup>
      </Form>
    </Row>
    <br />
    { selected
      && (
      <Row>
        <Col lg={{ size: 5 }}>
          <Field
            name="resolution"
            label={intl.formatMessage({
              id: 'kpis.wizard.simple.resolution.label',
            })}
            placeholder={intl.formatMessage({
              id: 'kpis.wizard.simple.resolution.placeholder',
            })}
            id="resolution"
            value={value.resolution}
            type="select"
            onChange={handleSelectChange}
            helperText={errors.resolution}
            error={!!errors.resolution}
            key="resolution"
            options={resolutions}
          />
        </Col>
        <Col lg={{ size: 5 }}>
          <Field
            name="operation"
            label={intl.formatMessage({
              id: 'kpis.wizard.simple.operation.label',
            })}
            placeholder={intl.formatMessage({
              id: 'kpis.wizard.simple.operation.placeholder',
            })}
            id="operation"
            value={value.operation}
            type="select"
            onChange={handleSelectChange}
            helperText={errors.operation}
            error={!!errors.operation}
            key="operation"
            options={operations}
          />
        </Col>
      </Row>
      )}
  </div>
);

AggregationOptionsComponent.propTypes = {
  handleChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any),
  formatMessage: PropTypes.element,
  selected: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.element),
  value: PropTypes.objectOf(PropTypes.any),
};

AggregationOptionsComponent.defaultProps = {
  handleChange: undefined,
  handleSelectChange: undefined,
  intl: undefined,
  formatMessage: undefined,
  selected: false,
  errors: {},
  value: {},
};

export default AggregationOptionsComponent;
