/* eslint-disable import/no-cycle */
import React from 'react';
import { injectIntl } from 'react-intl';
import CreateNumericWidget from './AddWizard';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { getWidgetModel } from '../../../utils';
import { TYPES, updateState } from '../../../../../pages/Dashboards/ShowV2/reducer';
import { cloneInstance } from '../../../../../helpers/utils';
import { getDefaultMeasure } from '../../../../../pages/Dashboards/WidgetDefaultMeasures';
import Dashboard from '../../../../Dashboard';

const CreateParametrizedTextWidget = ({
  intl, widgetImageId, setPressed, widget, dashboard,
}) => {
  const updateImageWidget = (newWidget) => {
    const newDashboard = new Dashboard(dashboard);
    const newImageWidget = cloneInstance(widget);

    if (!newImageWidget.config.appearance.layoutProperties) {
      newImageWidget.config.appearance.layoutProperties = {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      };
    }
    const defaultMeasures = getDefaultMeasure(
      newWidget,
      newImageWidget.config.appearance.layoutProperties,
    );

    Object.keys(newImageWidget.config.appearance.layoutProperties).forEach((size) => {
      if (newImageWidget.config.appearance.layoutProperties[size]) {
        newImageWidget.config.appearance.layoutProperties[size].push(defaultMeasures[size]);
      } else {
        (newImageWidget.config.appearance.layoutProperties[size] = [defaultMeasures[size]]);
      }
    });
    newImageWidget.update(newImageWidget);
    newDashboard.widgets.push(newWidget);
    newDashboard.update();
  };

  const handleClick = () => {
    const permissionsPolicy = {
      groups: [],
      public: false,
      users: [],
    };
    const newWidget = {
      name: 'Default',
      description: 'Default',
      widgetType: 'PARAMETRIZED_TEXT',
      container: widgetImageId,
      typeContainer: 'IMAGE',
      permissionsPolicy,
      config: {
        content: intl.formatMessage({ id: 'widget.parametrizedText.defaultValue' }),
        parameters: [],
      },
    };
    setPressed(false);
    const createdWidget = getWidgetModel(newWidget);
    createdWidget.save(updateImageWidget);
  };

  return (
    <button
      onClick={handleClick}
      type="button"
    >
      <FormattedMessage id="widgetImage.button.addWidget.paragraph" />
    </button>
  );
};

class OptionsForWidgetImageAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatchState, widget } = this.props;
    updateState(
      TYPES.setWidgetSection,
      { active: widget },
      dispatchState,
    );
  }

  render() {
    const {
      dashboard, intl, widgetImageId, setPressed, widget,
    } = this.props;
    return (
      <div>
        <ul className="list-unstyled">
          <li>
            <CreateNumericWidget
              dashboard={dashboard}
              widgetImageId={widgetImageId}
              setPressed={setPressed}
              widget={widget}
            />
          </li>
          <li>
            <CreateParametrizedTextWidget
              dashboard={dashboard}
              intl={intl}
              widgetImageId={widgetImageId}
              setPressed={setPressed}
              widget={widget}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default injectIntl(OptionsForWidgetImageAddButton);
