import React from 'react';
import Styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { margin, size, findDefaultProp } from '../../helpers/functions';
import SIZES from './sizes';
import SHAPES from './shapes';
import _DEFAULT_COLORS from '../../helpers/colors';
import { mainColors } from '../../configuration/colors';

const borderRadiusFn = props => {
  const DEFAULT = findDefaultProp(props, 'shape', 'circle');
  let shape = props.shape || DEFAULT;

  if (!['square', 'rounded', 'circle'].includes(props.shape)) shape = DEFAULT;

  return size(props, SIZES) / SHAPES[shape];
};

const chooseOneColor = (letter = 'S') => {
  const l = letter.toUpperCase().charCodeAt(0);
  let value = l;
  while (value > 9) {
    value = calculate(value);
  }

  return mainColors[value];
};

const calculate = number =>
  number
    .toString()
    .split('')
    .reduce((r, n) => r + parseInt(n), 0);

export const Avatar = Styled.div`
  ${p => {
    if (p.size !== 'custom') {
      return `
        height: ${size(p, SIZES)}px;
        min-height: ${size(p, SIZES)}px;
        width: ${size(p, SIZES)}px;
        min-width: ${size(p, SIZES)}px;
        font-size: ${size(p, SIZES) / 2}px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin: ${margin};
        background-color: ${p.backgroundColor};
        color:${p.color}
        border-radius: ${borderRadiusFn(p)}px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        & img{
          width: 100%;
          margin: 0;
        }
      `;
    }
  }}

  ${p => {
    if (p.backgroundColor === 'random') {
      return `
        background-color: ${chooseOneColor(p.letter)};
        color: white;`;
    }
  }}

  ${p => p.css}
`;

const Default = props => <Avatar {...props} />;

Default.propTypes = {
  /** Defines the shape of the Avatar element. You can choose between three shapes or create a custom shape using the CSS property*/
  shape: PropTypes.oneOf(['square', 'rounded', 'circle']),

  /** You can select any of the 5 defined sizes or you can choose 'custom' to a full customization */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'custom']),

  /** This property divides the value of the margin and padding of the theme to adapt it to the component.
   * If you want to increase, you must assign decimal values. E.g.: 0.5*/
  divisor: PropTypes.number,

  /** Changes the background color of the component. Accepts colors by names (css standards), HEX code and RGBA code.
   *  Also, you can choose 'random' if you want the component choose a ramdom color for the avatar, based on an letter.
   *  For that, you must use 'letter' prop, to indicate it.
   * */
  backgroundColor: PropTypes.string,

  /** Changes the text color of the component. Accepts colors by names (css standards), HEX code and RGBA code.*/
  color: PropTypes.string,

  /** You can modify any of the existing properties by passing css through string*/
  css: PropTypes.string,

  /** The letter which the avatar with random background color will be based on */
  letter: PropTypes.string
};

Default.defaultProps = {
  size: 'sm',
  divisor: 4,
  shape: 'circle',
  color: _DEFAULT_COLORS.default.light,
  backgroundColor: _DEFAULT_COLORS.default.dark,
  css: ``
};

export { SIZES };

export { Default };
export default withTheme(Default);
