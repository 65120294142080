export default (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key, index, array)=>{
    if (Array.isArray(obj[key]))
      newObj[key] = [...obj[key]];
    else if ( obj[key] instanceof Object)
      newObj[key] = {...obj[key]};
    else
      newObj[key] = obj[key]
  });

  return newObj;
}