import styled from 'styled-components';

const WrapperDiv = styled.div.attrs({})`
${(props) => (!props.isLinked
    ? {
      'border-radius': '10px',
      'background-color': '#FFFFFF',
      'box-shadow': '0 2px 0 0 rgba(90,97,105,0.11), 0 4px 8px 0 rgba(90,97,105,0.12), 0 10px 10px 0 rgba(90,97,105,0.06), 0 7px 70px 0 rgba(90,97,105,0.1)',
      height: '100%',
      'padding-bottom': '2em',
    }
    : '')
};
`;

export default WrapperDiv;
