/* eslint-disable camelcase */
import { Map, List } from 'immutable';

import * as TYPES from './types';

/** Initial State of Opendata */
const initialState = Map({
  organization_list: List([]),
  organization_total: 0,
  dataset_list: List([]),
  dataset_total: 0,
  fetching: false,
  errorFetching: false,
});

/** opendata reducer */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /** initialize DB requests */
    case TYPES.PUBLISH_DATASET_SUBSCRIPTION_REQUEST:
    case TYPES.CREATE_GIS_LAYER_REQUEST:
    case TYPES.REMOVE_DATASET_SUBSCRIPTION_REQUEST:
    case TYPES.GET_ORGANIZATION_LIST_REQUEST:
    case TYPES.GET_DATASET_LIST_REQUEST_BY_ORG:
    case TYPES.GET_DATASET_LIST_REQUEST:
    case TYPES.ADD_DATASET_LIST_REQUEST:
    case TYPES.GET_ORGANIZATION_BY_ID_REQUEST:
      return state.merge(Map({
        fetching: true,
        errorFetching: null,
      }));

    case TYPES.REMOVE_DATASET_SUBSCRIPTION_SUCCESS:
    case TYPES.PUBLISH_DATASET_SUBSCRIPTION_SUCCESS:
      return state.merge(Map({
        fetching: false,
        errorFetching: false,
      }));

    case TYPES.PUBLISH_DATASET_SUBSCRIPTION_ERROR:
    case TYPES.REMOVE_DATASET_SUBSCRIPTION_ERROR:
    case TYPES.GET_ORGANIZATION_LIST_ERROR:
    case TYPES.GET_DATASET_LIST_ERROR:
    case TYPES.ADD_DATASET_LIST_ERROR:
    case TYPES.GET_ORGANIZATION_BY_ID_ERROR:
      return state.merge(Map({
        fetching: false,
        errorFetching: payload,
      }));

    case TYPES.RESET_DATASET_LIST:
      return state.merge(Map({
        dataset_list: List([]),
        dataset_total: 0,
      }));

    /** data received from DB */
    case TYPES.GET_ORGANIZATION_LIST_SUCCESS:
      return state
        .update(
          // get current state.list and iterate using immutable
          'organization_list', (organization_list) => organization_list
            // remove duplicates from state.list
            .filterNot((org) => (
              payload.objects.find((i) => i.id === org.id)
            ))
            // concat new backend list
            .concat(payload.objects),
        )
        // finally merge
        .merge(Map({
          fetching: false,
          organization_total: payload.total,
        }));

    case TYPES.GET_DATASET_LIST_SUCCESS:
      return state
        .update(
          // get current state.list and iterate using immutable
          'dataset_list', (dataset_list) => dataset_list
            // remove duplicates from state.list
            .filterNot((org) => (
              payload.objects.find((i) => i.id === org.id)
            ))
            // concat new backend list
            .concat(payload.objects),
        )
        // finally merge
        .merge(Map({
          fetching: false,
          dataset_total: payload.total,
        }));
    case TYPES.ADD_DATASET_LIST_SUCCESS:
      return state
        .update('dataset_list', (dataset_list) => dataset_list.unshift(payload))
        .merge(Map({
          fetching: false,
          errorFetching: false,
        }))
        .update('dataset_total', (dataset_total) => dataset_total + 1);

    case TYPES.GET_ORGANIZATION_BY_ID_SUCCESS:
    {
      const index = state.get('organization_list').findIndex((u) => u.id === payload.id);
      // TODO
      // If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1 ? state.update('organization_list', (list) => list.push(payload)) : state;
    }
    default:
      return state;
  }
};
