import {
  all, call, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import Template from '../../../models/Template';
import * as TYPESnotify from '../notify/types';
import * as Api from '../../api/templates';
import * as TYPES from './types';

/**
 * Actions for WATCHERS
 * */

// Try to recover all template form DB
export function* getTemplatesThroughApi({ payload }) {
  try {
    const response = yield call(Api.readTemplates, payload.page, payload.size, payload.filters);

    if (response.status === 200) {
      const templates = response.data.objects.map((template) => new Template(template));
      const { total } = response.data;

      yield put({
        type: TYPES.GET_TEMPLATES_SUCCESS,
        payload: { templates, total },
      });
    } else {
      yield put({ type: TYPES.GET_TEMPLATES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_TEMPLATES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}
// Try to recover all  My templates form DB
export function* getMyTemplatesThroughApi({ payload }) {
  try {
    const response = yield call(Api.readMyTemplates, payload.page, payload.size, payload.filters);

    if (response.status === 200) {
      const templates = response.data.objects.map((template) => new Template(template));
      const { total } = response.data;

      yield put({
        type: TYPES.GET_MY_TEMPLATES_SUCCESS,
        payload: { templates, total },
      });
    } else {
      yield put({ type: TYPES.GET_MY_TEMPLATES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_MY_TEMPLATES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover all template form DB
export function* getPublicTemplatesThroughApi({ payload }) {
  try {
    const response = yield call(Api.readPublicTemplates, payload.page, payload.size);

    if (response.status === 200) {
      const templates = response.data.objects.map((template) => new Template(template));
      const { total } = response.data;

      yield put({
        type: TYPES.GET_PUBLIC_TEMPLATES_SUCCESS,
        payload: { templates, total },
      });
    } else {
      yield put({ type: TYPES.GET_PUBLIC_TEMPLATES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_PUBLIC_TEMPLATES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to recover an template form DB
export function* getTemplateThroughApi({ payload }) {
  try {
    const response = yield call(Api.readTemplate, payload);
    if (response.status === 200) {
      const templateFromDB = new Template(response.data.object);
      yield put({ type: TYPES.GET_TEMPLATE_SUCCESS, payload: templateFromDB });
    } else {
      yield put({ type: TYPES.GET_TEMPLATE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_TEMPLATE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to create template in DB
export function* addTemplatesThroughApi({ payload }) {
  try {
    const response = yield call(Api.addTemplate, payload);
    if (response.status === 201) {
      const templateFromDB = new Template(response.data.object);
      yield put({ type: TYPES.ADD_TEMPLATE_SUCCESS, payload: templateFromDB });
    } else {
      yield put({ type: TYPES.ADD_TEMPLATE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_TEMPLATE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to update template in DB
export function* updateTemplatesThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateTemplate, payload);

    if (response.status === 200) yield put({ type: TYPES.MODIFY_TEMPLATE_SUCCESS, payload: new Template(response.data.object) });
    else {
      yield put({ type: TYPES.MODIFY_TEMPLATE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_TEMPLATE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

// Try to delete template in DB
export function* deleteTemplatesThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteTemplate, payload);

    if (response.status === 204) {
      yield put({ type: TYPES.DELETE_TEMPLATE_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.DELETE_TEMPLATE_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_TEMPLATE_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_TEMPLATES_REQUEST
function* watcherGetTemplates() {
  yield takeLatest('GET_TEMPLATES_REQUEST', getTemplatesThroughApi);
}
// Watcher looking for GET_MY_TEMPLATES_REQUEST
function* watcherGetMyTemplates() {
  yield takeLatest('GET_MY_TEMPLATES_REQUEST', getMyTemplatesThroughApi);
}

// Watcher looking for GET_TEMPLATES_REQUEST
function* watcherGetPublicTemplates() {
  yield takeLatest(
    'GET_PUBLIC_TEMPLATES_REQUEST',
    getPublicTemplatesThroughApi,
  );
}

// Watcher looking for GET_TEMPLATE_REQUEST
function* watcherGetTemplate() {
  yield takeLatest('GET_TEMPLATE_REQUEST', getTemplateThroughApi);
}

// Watcher looking for ADD_TEMPLATE_REQUEST
function* watcherAddTemplates() {
  yield takeEvery('ADD_TEMPLATE_REQUEST', addTemplatesThroughApi);
}

// Watcher looking for MODIFY_TEMPLATE_REQUEST
function* watcherUpdateTemplates() {
  yield takeLatest('MODIFY_TEMPLATE_REQUEST', updateTemplatesThroughApi);
}

// Watcher looking for DELETE_TEMPLATE_REQUEST
function* watcherDeleteTemplates() {
  yield takeEvery('DELETE_TEMPLATE_REQUEST', deleteTemplatesThroughApi);
}

// Export all together
export default function* rootSaga() {
  yield all([
    fork(watcherGetTemplates),
    fork(watcherGetPublicTemplates),
    fork(watcherGetTemplate),
    fork(watcherAddTemplates),
    fork(watcherUpdateTemplates),
    fork(watcherDeleteTemplates),
    fork(watcherGetMyTemplates),
  ]);
}
