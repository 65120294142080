import Styled from 'styled-components';

const Table = Styled('table')`
    width:100%;
    max-width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    margin-top: 32px;

    & tr, & td, & th {
        border-collapse: collapse;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: ${(p) => p.align || 'left'};
    }
    
    & td:last-child{
        overflow: unset;
        width: 50px;
        padding: 0 10px 0 0;
        margin: 0; 
    }
    
    & col.lastCol{
        width: 10%;
    }
    
    & th.lastCol *{
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    ${(p) => p.css}

`;

export default Table;

Table.defaultProps = {
  align: 'left',
  css: '',
};
