import React from 'react';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { loginRoute } from '../../configuration/config';
import { imLogged } from '../../helpers';
import MainNav from '../MainNav';
import SecondNav from '../SecondNav';
import BasicLayout from '../../templates/BasicLayout';

const PrivateRoute = ({
  component: Component,
  location,
  hiddenPageHeader,
  me,
  fetching,
  noPaddingLayout,
  ...rest
}) => {
  if (!fetching && !imLogged()) {
    navigate(loginRoute);
    return null;
  }

  return (
    <BasicLayout
      path={location.pathname}
      aside={MainNav}
      subAside={SecondNav}
      hiddenPageHeader={hiddenPageHeader}
      noPadding={noPaddingLayout}
    >
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        location={location}
        path={location.pathname}
        me={me}
      />
    </BasicLayout>
  );
};
const mapStatToProps = (state) => ({
  me: state
    .get('auth')
    .get('me')
    .toJS(),
  fetching: state.get('auth').get('fetching'),
});
export default connect(mapStatToProps)(PrivateRoute);
