import React from 'react';
import { PropTypes } from 'prop-types';

const DesktopStepper = ({ step, className, bullet }) => (
  <div className={className}>
    <div className="hint">{bullet}</div>
    <div className="title">{step}</div>
  </div>
);

DesktopStepper.propTypes = {
  step: PropTypes.objectOf(PropTypes.object).isRequired,
  className: PropTypes.string.isRequired,
  bullet: PropTypes.element.isRequired,
};

export default DesktopStepper;
