import Faker from 'faker';

export const dataTestLineChart = {
  data: [
    {
      name: 'prueba1',
      value: ['2020/1/17 10:00:00', 124],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:00:10', 14],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:00:20', 98],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:00:30', 210],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:00:40', 144],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:00:50', 120],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:01:00', 134],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:01:10', 128],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:01:20', 123],
    },
    {
      name: 'prueba1',
      value: ['2020/1/17 10:01:30', 111],
    },
  ],
  index: ['humidityULattr', 'humidityULstatic', 'tempULstatic'],
};

export const generateRandonData = (number) => {
  let now = +new Date(1997, 9, 3);
  const oneDay = 24 * 3600 * 1000;
  let value = Math.random() * 20;
  const data = [];

  // let date = new Date();

  for (let i = 0; i < number; i += 1) {
    now = new Date(+now + oneDay);
    value = value + Math.random() * 41 - 10;
    data.push({
      name: now.toString(),
      value: [
        [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'),
        Math.round(value),
      ],
    });
  }

  return data;
};

export const generateSerie = (index) => {
  const series = [];
  for (let i = 0; i < index; i += 1) {
    series.push({
      attribute: Faker.words,
      device_id: Faker.words,
      name: Faker.words,
      type: 'line',
      showSymbol: false,
      hoverAnimation: false,
      data: generateRandonData(5),
      lineStyle: { width: 50, smooth: true },
    });
  }
  return series;
};
