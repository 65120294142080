import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import FiwooLogo from '../../../configuration/icons/svg/logo-fiwoo.svg';
import PrivateDashboardLogo from '../../../configuration/icons/svg/dashboardPrivate.svg';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import './styles.scss';

function ForbiddenDashboard() {
  return (
    <>
      <Container className="forbidden-container">
        <Row className="logo-row">
          <Col className="p-2">
            <img src={FiwooLogo} alt="logotype" />
          </Col>
        </Row>
        <Row className="forbidden-content">
          <Col lg={{ size: 12 }} className="text-center mb-4">
            <img src={PrivateDashboardLogo} alt="logotype" />
          </Col>
          <Col sm={{ size: 8, offset: 2 }} className="text-center">
            <p className="mainForbiddenText">
              <FormattedMessage id="dashboard.private.mainForbiddenText" />
            </p>
          </Col>
          <Col sm={{ size: 8, offset: 2 }} className="text-center">
            <p className="shortForbiddenText">
              <FormattedMessage id="dashboard.private.shortForbiddenText" />
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForbiddenDashboard;
