import React from 'react';
import { injectIntl } from 'react-intl';
import CheckBox from '../../../elements/CheckBox';
import Alert from '../../Alert';

const SelectedAlert = ({ total, typeOfData, intl }) => {
  const text = `${
    total > 1
      ? `${typeOfData}.table.alert.total`
      : `${typeOfData}.table.alert.total.singular`
  }`;
  return (
    <Alert
      className="alert"
      text={`${total} ${intl.formatMessage({ id: text })}`}
    />
  );
};

class SelectableComponent extends React.Component {
  constructor(props) {
    super(props);

    const { checked } = this.props;

    this.state = {
      allChecked: false,
      checked,
    };
  }

  componentDidUpdate(prevProps) {
    const { checked } = this.props;
    if (prevProps.checked !== checked) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        checked,
      });
    }
  }

  handleOnCheckedAll = () => {
    const { allChecked } = this.state;
    const { onCheckedAll } = this.props;
    this.setState(
      {
        allChecked: !allChecked,
      },
      () => {
        onCheckedAll(allChecked);
      },
    );
  };

  getCheckedItemsTotal = () => {
    const { checked } = this.state;
    return checked.filter((o) => o === true).length;
  };

  getCheckedItems = () => {
    const { data } = this.props;
    const { checked } = this.state;

    const checkedData = [];
    checked.forEach((c, i) => {
      if (c === true) {
        checkedData.push(data[i]);
      }
    });

    return checkedData;
  };

  render() {
    const {
      typeOfData, intl, entity, onRemove: RemoveDOM,
    } = this.props;
    const total = this.getCheckedItemsTotal();

    return (
      <div className="selectableComponent">
        <div className="alert">
          {total > 0 && (
            <SelectedAlert total={total} typeOfData={typeOfData} intl={intl} />
          )}
        </div>
        <div className="selectableNav">
          <CheckBox
            label={' '}
            checked={false}
            onClick={(e) => this.handleOnCheckedAll(e)}
          />
          <i className="uil uil-angle-down expandMore" />
          {this.getCheckedItemsTotal() > 0 && (
            <RemoveDOM
              typeOfData={typeOfData}
              entity={entity}
              rowContent={this.getCheckedItems()}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(SelectableComponent);
