import React from 'react';
import { navigate } from '@reach/router';
import './styles.scss';

const AddTableButton = ({ route, label, onClick }) => (
  <button
    type="button"
    onClick={route ? () => navigate(route) : onClick}
    className="btn btn-primary btn-add justify-content-center align-items-center"
  >
    {label}
  </button>
);

AddTableButton.defaultProps = {
  label: 'add',
  css: '',
};

export default AddTableButton;
