import React from 'react';
import Styled from 'styled-components';
import { Avatar } from '../../elements/Avatar';
import _DEFAULT_COLORS from '../../helpers/colors';

const Dot = Styled('div')`
  height: 10px;
  padding: 0;
  width: 10px;
  position: absolute;
  transition: transform 200ms;
  transform: translateX(-${(p) => (p.size * 12) / 100}px) translateY(${(p) => (p.size * 12) / 100}px) scale(${(p) => (p.visible ? p.size : 0)});
  background-color: ${(p) => p.backgroundColor};
  border-radius: 100%;
  z-index: 1;
  ${(p) => {
    if (p.position === 'top') {
      return 'top: 0';
    }
    return 'bottom: 0';
  }}
  right: 0;
`;

const BadgeA = Styled(Avatar)`
{
  background-color: ${(p) => p.backgroundColor};
  color: ${(p) => p.color};
  width: max-content;
  padding: 0 4px
  position: absolute;
  transition: transform 200ms;
  transform: translateX(10px) translateY(-10px) scale(${(p) => (p.visible ? 1 : 0)});
  right: 0;
  ${(p) => {
    if (p.position === 'top') {
      return 'top: 0';
    }
    return 'bottom: 0';
  }}

  ${(p) => {
    if (!p.content) {
      return `min-height: 0px;
      min-width: 0px;
      width: 10px;
      height: 10px;
      right: 13px;
      top: 8px;`;
    }
  }}
}
`;
const BadgeS = Styled('div')`
  position: relative;
  display: inline-block;
  width: max-content;
`;

const CustomCSS = Styled('div')`
  ${(p) => p.css}
`;
class Badge extends React.PureComponent {
  render() {
    const {
      color, children, variant, content, backgroundColor, visible, css, size, position,
    } = this.props;
    const Variant = variant === 'dot' ? Dot : BadgeA;

    return (
      <CustomCSS css={css}>
        <BadgeS>
          <Variant
            visible={visible}
            backgroundColor={backgroundColor}
            color={color}
            shape={variant}
            children={variant === 'dot' ? null : content}
            position={position}
          />
          {children}
        </BadgeS>
      </CustomCSS>
    );
  }
}

Badge.defaultProps = {
  visible: true,
  variant: 'circle',
  content: null,
  css: '',
  size: 1,
  color: _DEFAULT_COLORS.white,
  backgroundColor: _DEFAULT_COLORS.danger.dark,
  position: 'bottom',
};

export default Badge;
