/* eslint-disable import/no-cycle */
import React, {
  memo, useState, useEffect, useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { clone, isEmpty } from 'ramda';
import { pullAll, cloneDeep } from 'lodash';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { updateDashboardWithoutSave } from '../../../../services/redux/dashboards/actions';
import { cloneInstance, getUrnId } from '../../../../helpers/utils';

const Linked = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetLinked })),
);
const WidgetMapUber = React.lazy(
  () => import('../../../../widgets/WidgetMapUberV2'),
);

const Table = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetTable })),
);

const OpenTextButton = ({ showModal }) => (
  <button
    type="button"
    className="btn-link no-button mr-3"
    onClick={showModal}
  >
    <FormattedMessage id="widgetLinked.addwidget" />
  </button>
);

const getSelectionFromLocalStorage = (widgetId) => {
  const currentSelectionsRaw = localStorage.getItem('linkedSelections');
  return currentSelectionsRaw ? JSON.parse(currentSelectionsRaw)[widgetId] : [];
};

const updateLocalStorage = (widgetId, newSelection) => {
  const newObject = widgetId ? { [widgetId]: newSelection } : null;
  if (!newObject) return;

  const currentLocalStorage = localStorage.getItem('linkedSelections');

  if (!currentLocalStorage) {
    localStorage.setItem('linkedSelections', JSON.stringify(newObject));
    return;
  }

  const parsed = JSON.parse(currentLocalStorage);
  const newLocal = { ...parsed, [widgetId]: newSelection };
  localStorage.setItem('linkedSelections', JSON.stringify(newLocal));
};

function LinkedComponent(props) {
  const {
    widget, values: currentValues,
    handleOpenProfileSendingCommands, intl,
    onChangeHistoricalConfig, renderChildrenWidgets, widgetSize,
    updateContainerLayout,
    modal: ModalDOM,
    dispatchState, globalSelection, sources,
  } = props;
  const mapRef = React.useRef(null);
  const [refreshMap, setRefreshMap] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  const [selection, setSelection] = useState(
    globalSelection || getSelectionFromLocalStorage(widget.id) || [],
  );
  const [isRender, setIsRender] = useState(!widget.config.custom.LINKED.mode === 'MAP');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Skeleton = useMemo(() => widget.skeletonComp, []);

  const filterSources = useMemo(
    () => sources.filter(
      (s) => widget.sources.some((o) => getUrnId(o.urn) === s.id),
    ), [widget.sources, sources],
  );

  // eslint-disable-next-line consistent-return
  const values = useMemo(() => {
    if (currentValues) {
      const clonedValues = cloneDeep(currentValues);
      clonedValues.MAP.devices = clonedValues.MAP.devices.filter(
        (s) => widget.sources.some((o) => o.urn === s.urn),
      );
      clonedValues.TABLE = clonedValues.TABLE.filter(
        (s) => widget.sources.some((o) => getUrnId(o.urn) === s.source.id) && s.data !== null,
      );
      return clonedValues;
    }
  }, [widget.sources, currentValues]);

  const formatLayout = useMemo(() => {
    if (widget.dashboard) {
      const dashboardCloned = cloneInstance(widget.dashboard);
      const layouts = dashboardCloned.layoutProperties;
      if (!layouts) return;
      const layoutProperties = Object.keys(dashboardCloned.layoutProperties);
      const updateLayout = {};
      layoutProperties.forEach((layoutProperty) => {
        updateLayout[layoutProperty] = dashboardCloned.layoutProperties[layoutProperty]
          .map((data) => {
            const dataLayout = clone(data);
            if (dataLayout.i === widget.id) {
              if (selection.length > 0) {
                if (dataLayout?.oldH >= 12) {
                  dataLayout.h = dataLayout.oldH;
                } else if (dataLayout.h < 12) {
                  dataLayout.h = 12;
                  dataLayout.oldH = 12;
                } else {
                  dataLayout.oldH = dataLayout.h;
                }
              } else {
                if (dataLayout.h !== 12) {
                  dataLayout.oldH = dataLayout.h;
                }
                dataLayout.h = 12;
              }
            }
            return dataLayout;
          });
      });
      dashboardCloned.layoutProperties = updateLayout;
      updateDashboardWithoutSave(dashboardCloned);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.dashboard?.layoutProperties, selection, widget.dashboard]);

  const LinkedWidgetFooter = () => {
    const someWidgets = widget.containedWidgets?.length;
    if (selection.length > 0) {
      if (someWidgets === 0) {
        return (
          <div className="linkedFooter">
            {formatLayout}
            <FormattedMessage id="widgetLinked.no-device" />
            {!widget.id.includes('preview') &&
              <ModalDOM buttonModal={OpenTextButton} widget={widget} dispatchState={dispatchState} className="modal-linked" />
            }
          </div>
        );
      }
      return (
        <>
          {formatLayout}
          <Linked
            config={widget.config}
            widget={widget}
            containedWidgets={widget.containedWidgets}
            renderLinkedWidgets={(w) => renderChildrenWidgets(w, selection)}
            updateWidgetLinkedLayout={updateContainerLayout}
          />
        </>
      );
    }

    return (
      <div className="linkedFooter">
        {formatLayout}
        <FormattedMessage id="widgetLinked.somedevices" />
      </div>
    );
  };

  const localeTranslate = (key, defaultValue) => (
    intl ? intl.formatMessage({ id: key }) : defaultValue
  );

  const handleMouseOverMap = (event) => {
    if (shiftPressed !== event) setShiftPressed(event);
  };

  const handleClickItem = (item) => {
    // At first we need to check if exist on array.
    const isThere = !!selection.find((i) => i === item?.id);
    if ((shiftPressed && widget.config.custom.LINKED.mode === 'MAP') || widget.config.custom.LINKED.mode === 'TABLE') {
      // If Shift pressed we add on array
      if (!isEmpty(item)) {
        if (isThere) {
          const newArr = selection.filter((deviceId) => deviceId !== item.id);
          pullAll(newArr, [null]);
          setSelection(newArr);
        } else {
          setSelection([...selection, item.id]);
        }
      }
      return;
    }

    // In this case only one device can be pressed
    if (isThere || isEmpty(item)) {
      setSelection([]);
    } else if (item.id) {
      setSelection([item.id]);
    }
  };

  const handleTableSelection = (selectionArray) => {
    const selectionIdArray = selectionArray.map((device) => device.id);
    pullAll(selectionIdArray, [null, NaN, undefined]);
    if (selectionArray.length !== selection.length) {
      setSelection(selectionIdArray);
    }
  };

  const setIsRenderWithTimeout = () => {
    setTimeout(
      () => setIsRender(true),
      1,
    );
  };

  useEffect(() => {
    updateLocalStorage(widget.id, selection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.sources, widget.config?.data?.type]);

  if (!values) return <Skeleton />;

  return (
    <>
      <div className={`widgetLinkedMapAndTable ${widget.config.custom.LINKED.mode === 'MAP' ? 'map-mode' : 'table-mode'}`} ref={mapRef}>
        {widget.config.custom.LINKED.mode === 'MAP' ? (
          <div
            onMouseMove={(e) => handleMouseOverMap(e.shiftKey)}
            role="presentation"
          >
            { isRender ? (
              <WidgetMapUber
                refreshMap={refreshMap}
                setRefreshMap={setRefreshMap}
                config={widget.config}
                onItemClick={handleClickItem}
                onSelect={handleTableSelection}
                data={values[widget.config.custom.LINKED.mode]}
                connectedDevices={filterSources}
                widgetType={widget.widgetType}
                widget={widget}
                hasBeenResized
                isLinked
                selectedItems={selection}
                mapRef={mapRef}
                drawableSelection
                handleMouseOverMap={handleMouseOverMap}
              />
            ) : setIsRenderWithTimeout()}
          </div>
        ) : (
          <>
            {filterSources.length && (
              <Table
                data={values[widget.config.custom.LINKED.mode]}
                enableFirstLastButton={false}
                sendCommand={handleOpenProfileSendingCommands}
                config={widget.config}
                origins={filterSources}
                onTranslate={localeTranslate}
                selectable={handleTableSelection}
                selectedItems={selection}
              />
            )}
          </>
        )}
      </div>
      <div
        className={
        `${selection.length === 0 ? 'collapsed' : 'widgetLinkedDragAndDrop'} 
        ${widget.containedWidgets?.length === 0 ? 'widgetLinkedDragAndDropCollapsed' : ''} 
        ${widgetSize?.h > 8 ? 'normal' : 'bigger'}`
        }
      >
        {LinkedWidgetFooter()}
      </div>
    </>
  );
}

export default memo(injectIntl(LinkedComponent));
