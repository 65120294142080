import Styled from 'styled-components';

const TD = Styled('td')`
    

    svg {
        cursor: pointer;
        &.addRow {
            margin-bottom: 1.6em;
        }
    }

    ${(p) => p.css}
`;

export default TD;
