import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CommonInput from '../CommonInput';

const TextareaCtr = styled('textarea')`
  resize:none;
  border: 0;
  border-radius: 3px;
  line-height: 1.5em;
  padding: 0.5em;
  box-sizing: border-box; 

  &:focus{
    outline: 0;
  }
    
  ${CommonInput}

 
`;

const Textarea = (props) => {
  return (
    <TextareaCtr {...props}/>
  );
};


Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  mode: PropTypes.oneOf(['edit','view','insert']),
  rows: PropTypes.number,
  name: PropTypes.string
};

Textarea.defaultProps = {
  label: "Textarea",
  placeholder: "Write here...",
  mode: 'insert',
  name: "TextareaName",
  id: "textAreaId"
};

export default Textarea;