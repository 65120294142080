import { getRandomColor } from '../../../../pages/Widgets/Add/components/utils';

const LinkedDefaultConfiguration = {
  appearance: {
    hidden: [],
    hiddenAttributes: null,
    iconCategory: true,
    orientation: 'columns',
  },
  colors: {
    headerColor: '#FFFFFF',
    headerTableBackground: '#FFFFFF',
    headerTableColorText: '#2B334A',
  },
  conditions: {
    categoryColors: null,
  },
  custom: {
    MAP: {
      viewType: 'default',
    },
    LINKED: {
      mode: 'MAP',
    },
  },
  data: {
    operation: 'last-value',
    realTimeUpdates: false,
    sampling: '',
    type: 'real-time',
    category: '',
  },
  labels: {
    alias: null,
    categoryAlias: null,
  },
};

export default LinkedDefaultConfiguration;

export const injectLinkedData = (origins, config) => {
  const newConfig = { ...config };
  const categoryColors = {};
  const categories = [];

  if (origins[0]?.connectedDevices?.readDevice || newConfig.labels.alias) return newConfig;
  origins.forEach((origin) => {
    origin.joinedAttributes.forEach((attr) => {
      if (!newConfig.labels.alias) newConfig.labels.alias = {};
      if (!newConfig.labels.alias[attr.name]) {
        newConfig.labels.alias[attr.name] = attr.name;
      }
    });
    if (!origin.categories) {
      if (!categories.includes(origin.connectedDevices.readDevice.categories[0] || 'OTHER')) {
        categories.push(origin.connectedDevices.readDevice.categories[0] || 'OTHER');
        categoryColors[origin.connectedDevices.readDevice.categories[0] || 'OTHER'] = {
          color: getRandomColor(),
          mode: '',
          range: '',
          element: [],
        };
      }
    } else if (!categories.includes(origin.categories[0] || 'OTHER')) {
      categories.push(origin.categories[0] || 'OTHER');
      categoryColors[origin.categories[0] || 'OTHER'] = {
        color: getRandomColor(),
        mode: '',
        range: '',
        element: [],
      };
    }
  });

  newConfig.conditions.categoryColors = categoryColors;

  return newConfig;
};
