/* eslint-disable import/no-cycle */
import React from 'react';
import styled from 'styled-components';
import HtmlConfigurationSheet from './utils/configurationSheet';
import HtmlDefaultConfiguration from './utils/defaultConfiguration';
import HtmlComponent from './component';
import Widget from '../..';
import colors from '../../../../configuration/colors';
import { ReactComponent as HtmlWidgetIcon } from '../../../../configuration/icons/svg/html-ic.svg';
import AddWidgetHTML from '../../../../pages/Widgets/Add/steps/customSteps/HTML/AddWidgetHTML';
import Preview from '../../../../pages/Widgets/Add/steps/customSteps/HTML/Preview';

const Actions = styled.div`
  color: ${colors['ui-Black']};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
`;

const HtmlCard = styled.div`
  height: 100%;
  width: 100%;
`;

const WidgetCard = ({
  children, actions,
}) => (
  <HtmlCard>
    <Actions>{actions}</Actions>
    {children}
  </HtmlCard>
);

export const NAME_WIDGET = 'HTML';
export default class HtmlWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = HtmlWidgetIcon;

  static wizardSteps = { AddWidgetHTML, Preview }

  skeleton = HtmlWidgetIcon;

  defaultConfig = ({ ...HtmlDefaultConfiguration });

  templateConfig = [];

  hiddenWidgetCard = false;

  needsSocket = false;

  component = HtmlComponent;

  customWidgetCard = WidgetCard

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => () => null

  getConfigurationSheet = () => HtmlConfigurationSheet

  getDefaultConfig() {
    return this.defaultConfig;
  }

  getWidgetChildBodyQuery = () => null

  getQueryHistorical = () => null

  parsedLinkedData = () => null
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
