export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST';
export const ADD_DEVICE_SUCCESS_FLAG = 'ADD_DEVICE_SUCCESS_FLAG';
export const ADD_DEVICE_ERROR = 'ADD_DEVICE_ERROR';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';

export const MODIFY_DEVICE_REQUEST = 'MODIFY_DEVICE_REQUEST';
export const MODIFY_DEVICE_SUCCESS_FLAG = 'MODIFY_DEVICE_SUCCESS_FLAG';
export const MODIFY_DEVICE_SUCCESS = 'MODIFY_DEVICE_SUCCESS';
export const MODIFY_DEVICE_ERROR = 'MODIFY_DEVICE_ERROR';

export const DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR';
/* plural used in opendata */
export const DELETE_DEVICES_SUCCESS = 'DELETE_DEVICES_SUCCESS';

export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
export const GET_DEVICE_ROLES_REQUEST = 'GET_DEVICE_ROLES_REQUEST';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_ERROR = 'GET_DEVICE_ERROR';

export const GET_DEVICE_LIST_REQUEST ='GET_DEVICE_LIST_REQUEST';
export const GET_DEVICE_LIST_SUCCESS = 'GET_DEVICE_LIST_SUCCESS';
export const GET_DEVICE_LIST_ERROR = 'GET_DEVICE_LIST_ERROR';

export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';

export const GET_MY_DEVICES_REQUEST = 'GET_MY_DEVICES_REQUEST';
export const GET_MY_DEVICES_ERROR = 'GET_MY_DEVICES_ERROR';
export const GET_MY_DEVICES_SUCCESS = 'GET_MY_DEVICES_SUCCESS';

export const GET_DEVICES_FOR_RULES_REQUEST = 'GET_DEVICES_FOR_RULES_REQUEST';
export const GET_DEVICES_FOR_RULES_ERROR = 'GET_DEVICES_FOR_RULES_ERROR';
export const GET_DEVICES_FOR_RULES_SUCCESS = 'GET_DEVICES_FOR_RULES_SUCCESS';

export const GET_DEVICE_LIST_FOR_SENDING_COMMANDS_REQUEST = 'GET_DEVICE_LIST_FOR_SENDING_COMMANDS_REQUEST';
export const GET_DEVICE_LIST_FOR_SENDING_COMMANDS_ERROR = 'GET_DEVICE_LIST_FOR_SENDING_COMMANDS_ERROR';
export const GET_DEVICE_LIST_FOR_SENDING_COMMANDS_SUCCESS = 'GET_DEVICE_LIST_FOR_SENDING_COMMANDS_SUCCESS';

export const GET_DEVICE_LIST_FOR_WIDGETS_REQUEST = 'GET_DEVICE_LIST_FOR_WIDGETS_REQUEST';
export const GET_DEVICE_LIST_FOR_WIDGETS_ERROR = 'GET_DEVICE_LIST_FOR_WIDGETS_ERROR';
export const GET_DEVICE_LIST_FOR_WIDGETS_SUCCESS = 'GET_DEVICE_LIST_FOR_WIDGETS_SUCCESS';


export const CLEAR_DEVICES_STATE = 'CLEAR_DEVICES_STATE';

export const ERRORFETCHING_DEVICE_RESET = 'ERRORFETCHING_DEVICE_RESET';

export const UPDATE_DEVICES_STATE = 'UPDATE_DEVICES_STATE';

export const GET_DEVICE_V2 = 'GET_DEVICE_V2';

export const GET_DEVICE_TO_SOURCE_LIST = 'GET_DEVICE_TO_SOURCE_LIST';
