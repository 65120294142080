import { random } from 'lodash';
import { getColor } from '../../../../pages/Widgets/Add/components/utils';

const LinesDefaultConfiguration = {
  data: {
    type: 'real-time',
    sampling: 'lastFifteenDays',
    startDate: '',
    endDate: '',
  },
  colors: { },
  labels: {
    xAxis: '',
    yAxis: '',
    alias: { },
  },
  trendlines: { },
  conditions: { },
  appearance: {
    legendPosition: 'top',
  },
  effects: { },
  custom: { },

};

export default LinesDefaultConfiguration;

export const LinesDefaultConfigurationForLinked = {
  ...LinesDefaultConfiguration,
  labels: {
    xAxis: 'Time',
    yAxis: 'Value',
    alias: { },
  },
};

export const injectLinesData = (origins, config, linkedWidget) => {
  const newConfig = { ...config };
  if (linkedWidget) {
    newConfig.labels.alias = { };
    newConfig.colors = { data: {} };
    config.data.attributeFilter.forEach((attribute) => {
      newConfig.labels.alias[attribute] = attribute;
      newConfig.colors.data[attribute] = getColor(random(0, 12));
    });
  } else {
    origins.forEach((origin) => {
      if (origin.type !== 'KPI') { // TODO: Delete when kpi accepts joinedAttrs
        const attrs = [
          ...origin.connectedDevices.attributes,
          ...origin.connectedDevices.lazy_attributes,
          ...origin.connectedDevices.static_attributes,
        ];
        attrs.forEach((attr) => {
          if (!newConfig.labels.alias) newConfig.labels.alias = {};
          if (!newConfig.labels.alias[attr]) {
            newConfig.labels.alias[`${attr}-${origin.connectedDevices.device_id}`] = `${attr}-${origin.connectedDevices.device_id}`;
          }
          const property = newConfig.colors[origin.connectedDevices.device_id];
          if (!property) newConfig.colors[origin.connectedDevices.device_id] = {};
          newConfig.colors[origin.connectedDevices.device_id] = {
            ...newConfig.colors[origin.connectedDevices.device_id],
            [attr]: getColor(random(0, 12)),
          };
        });
      }
    });
  }
  return newConfig;
};
