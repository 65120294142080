import { Map, List, Seq } from 'immutable';
import * as R from 'ramda';
import * as TYPES from './types';

export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];

// Initial State of Historicals
const initialState = Map({
  list: List([]),
  previewList: List([]),
  historicalListV2: {},
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  errorListV2: {},
});

// HISTORICALS REDUCER
export default (state = initialState, { type, payload }) => {
  // Helper functions
  let index = -1;
  let aux;

  // Cases
  switch (type) {
    // INITIALIZE GET HISTORICALS FROM DB
    case TYPES.GET_MULTIPLE_HISTORICAL_LIST_REQUEST:
    case TYPES.GET_HISTORICAL_LIST_REQUEST:
    case TYPES.GET_HISTORICAL_LIST_V2_REQUEST:
    case TYPES.GET_HISTORICAL_LIST_PREVIEW_REQUEST:
    case TYPES.GET_HISTORICALS_REQUEST:
      return state.set('fetching', true);

    // INITIALIZE GET HISTORICALS FROM DB
    case TYPES.GET_HISTORICALS_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;

    // GET ALL HISTORICAL FROM DB TO STATE
    case TYPES.GET_HISTORICALS_SUCCESS:
      // TODO: Sure it is correct?
      index = state.get('list').findIndex((u) => u.id === payload.id);
      // aux = state.set('list', List(payload));
      state.set('fetching', false);
      return index === -1 ? state.update('list', (deviceList) => deviceList.push(payload.historicals)) : state;

    case TYPES.GET_HISTORICAL_LIST_SUCCESS:
      return state.set('historicalList', payload).set('fetching', false);

    case TYPES.GET_HISTORICAL_LIST_V2_SUCCESS:
      return state.update('historicalListV2', (oldHistorical) => {
        const historicalList = R.clone(oldHistorical);
        const isThere = Object.keys(historicalList).find((id) => id === payload.widgetId);
        if (isThere) {
          historicalList[isThere] = payload.data;
          return historicalList;
        }
        return {
          ...historicalList,
          [payload.widgetId]: payload.data,
        };
      });

    case TYPES.GET_HISTORICAL_LIST_PREVIEW_SUCCESS:
      return state.set('previewList', payload.flat()).set('fetching', false);

    case TYPES.GET_MULTIPLE_HISTORICAL_LIST_SUCCESS:
      aux = R.clone(state.get('historicalList'));
      payload.forEach((data) => {
        if (data.data.historicals[0]) {
          const historical = aux.find((o) => (
            o.data.historicals[0]
            && o.data.historicals[0].entityId === data.data.historicals[0].entityId));
          if (!historical) {
            aux = [...aux, ...payload];
          }
        }
      });

      return state.update('historicalList', () => (
        aux.map((historical) => {
          const result = R.clone(historical);
          if (historical.data.historicals) {
            payload.forEach((data) => {
              if (historical.data.historicals[0]
                && data.data.historicals[0]
                && historical.data.historicals[0].entityId === data.data.historicals[0].entityId
              ) {
                const ids = [];
                result.data.historicals = (
                  [...historical.data.historicals, ...data.data.historicals])
                  .filter((o) => {
                    if (ids.includes(o.id)) return false;

                    ids.push(o.id);
                    return true;
                  });
                result.data.count = result.data.historicals.length;
              }
            });
          }
          return result;
        })
      )).set('fetching', false);
    // INITIALIZE GET HISTORICAL FROM DB
    case TYPES.GET_HISTORICAL_REQUEST:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;

    case TYPES.UPDATE_HISTORICAL_LIST_V2_FROM_SOCKET: {
      return state.set('historicalListV2', payload);
    }

    case TYPES.UPDATE_HISTORICAL_LIST_REQUEST:
      aux = [...state.get('historicalList')];
      aux.forEach((h) => {
        if (h.data.historicals && h.data.historicals[0].entityId === payload.entityId) {
          h.data.historicals.unshift(payload);
        }
      });
      return state.set('historicalList', aux);

    // INITIALIZE GET HISTORICAL FROM DB ERROR
    case TYPES.GET_HISTORICAL_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;

    // GET HISTORICAL FROM DB TO STATE
    case TYPES.GET_HISTORICAL_SUCCESS:
      index = state.get('list').findIndex((u) => u.id === payload.id);
      // TODO
      // If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1 ? state.update('list', (deviceList) => deviceList.push(payload)) : state;

    // case TYPES.GET_MINE_HISTORICALS_REQUEST:
    //     return state.set('fetching', true);
    // RESET ERROR FETCHING TO FALSE
    case TYPES.ERRORFETCHING_HISTORICAL_RESET:
      return state.set('errorFetching', false);

    case TYPES.GET_HISTORICAL_LIST_V2_ERROR:
      return state.update('errorListV2', (oldErrorList) => {
        const errorList = R.clone(oldErrorList);
        const isThere = Object.keys(errorList).find((id) => id === payload.widgetId);
        if (isThere) {
          errorList[isThere] = payload.data;
          return errorList;
        }

        return {
          ...errorList,
          [payload.widgetId]: payload.data,
        };
      });

    default:
      return state;
  }
};
