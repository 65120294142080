import validate from 'validate.js';
import { pick } from 'ramda';

import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';

import {
  getReportHistorical, downloadReportHistorical
} from '../../services/redux/reportHistorical/actions';


/** Class ReportHistorical with Utils functions */
export default class ReportHistorical extends Utils {
  static entityName = 'ReportHistorical';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = ReportHistorical;
  }

  validate(data) {
    const filteredConstraints = pick(data, this.constraints);
    const validation = validate(this.getData(), filteredConstraints);

    return validation === undefined
      ? undefined
      : { error: true, ...validation };
  }

  get() {
    const validation = validate(
      this.getData(),
      { id: this.constraints.id },
    );

    return validation === undefined
      ? getReportHistorical(this.getData())
      : { error: true, ...validation };
  }

  download() {
    const validation = validate(
      this.getData(),
      { id: this.constraints.id },
    );

    return validation === undefined
      ? downloadReportHistorical(this.getData())
      : { error: true, ...validation };
  }

  plainTranslations = CreateTranslationPlainForModels('ReportHistorical', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}

const SampleReportHistorical = new ReportHistorical();
export { SampleReportHistorical };
