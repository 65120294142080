const PercentageDefaultConfiguration = {
  colors: {
    labelColor: '#e12c50',
    fillColor: '#acd356',
  },
  labels: {
    unit: '',
  },
  effects: {
    waves: false,
  },
};

export default PercentageDefaultConfiguration;

export const PercentageDefaultConfigurationForLinked = {
  ...PercentageDefaultConfiguration,
  labels: {
    unit: '%',
  },
};
