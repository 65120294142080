import { takeLatest, takeEvery, call, put, all, fork } from 'redux-saga/effects';
import Permission from '../../../models/Permission'
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/permissions'

/**
 * Actions for WATCHERS
 * */

// Try to recover all permission form DB
export function* getPermissionsThroughApi() {
    try {
        const response = yield call(Api.readPermissions);

        if (response.status === 200) {
            const permissions = response.data.objects.map(permission => new Permission(permission));
            yield put({ type: TYPES.GET_PERMISSIONS_SUCCESS, payload: permissions });
        }
        else {
            yield put({ type: TYPES.GET_PERMISSIONS_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
        }
    }
    catch (error) {
        yield put({ type: TYPES.GET_PERMISSIONS_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
    }
}

// Try to recover a permission form DB
export function* getPermissionThroughApi({ payload }) {
    try {
        const response = yield call(Api.readPermission, payload);

        if (response.status === 200) {
            const permissionFromDB = new Permission(response.data.object);
            yield put({ type: TYPES.GET_PERMISSION_SUCCESS, payload: permissionFromDB });
        }
        else {
            yield put({ type: TYPES.GET_PERMISSION_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
        }
    }
    catch (error) {
        yield put({ type: TYPES.GET_PERMISSION_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
    }
}


// Try to create permission in DB
export function* addPermissionsThroughApi({ payload }) {
    try {
        const response = yield call(Api.addPermission, payload);
        if (response.status === 201) {
            const permissionFromDB = new Permission(response.data.object);
            yield put({ type: TYPES.ADD_PERMISSION_SUCCESS, payload: permissionFromDB });
        }
        else {
            yield put({ type: TYPES.ADD_PERMISSION_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.ADD_PERMISSION_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

    }
}

// Try to update permission in DB
export function* updatePermissionsThroughApi({ payload }) {
    try {
        const response = yield call(Api.updatePermission, payload);

        if (response.status === 200) {
            yield put({ type: TYPES.MODIFY_PERMISSION_SUCCESS, payload: new Permission({ ...response.data.object }) })
        }
        else {
            yield put({ type: TYPES.MODIFY_PERMISSION_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.MODIFY_PERMISSION_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

    }
}

// Try to delete permission in DB
export function* deletePermissionsThroughApi({ payload }) {
    try {
        const response = yield call(Api.deletePermission, payload);

        if (response.status === 204) yield put({ type: TYPES.DELETE_PERMISSION_SUCCESS, payload });
        else {
            yield put({ type: TYPES.DELETE_PERMISSION_ERROR, payload: response });
            yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
        }
    }
    catch (error) {
        yield put({ type: TYPES.DELETE_PERMISSION_ERROR, payload: error });
        yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });

    }
}

/**
 * WATCHERS
 */
// Watcher looking for GET_PERMISSIONS_REQUEST
export function* watcherGetPermissions() {
    yield takeLatest('GET_PERMISSIONS_REQUEST', getPermissionsThroughApi);
}

// Watcher looking for GET_PERMISSION_REQUEST
export function* watcherGetPermission() {
    yield takeLatest('GET_PERMISSION_REQUEST', getPermissionThroughApi);
}

// Watcher looking for ADD_PERMISSION_REQUEST
function* watcherAddPermissions() {
    yield takeEvery('ADD_PERMISSION_REQUEST', addPermissionsThroughApi);
}

// Watcher looking for MODIFY_PERMISSION_REQUEST
function* watcherUpdatePermissions() {
    yield takeLatest('MODIFY_PERMISSION_REQUEST', updatePermissionsThroughApi);
}

// Watcher looking for DELETE_PERMISSION_REQUEST
function* watcherDeletePermissions() {
    yield takeEvery('DELETE_PERMISSION_REQUEST', deletePermissionsThroughApi);
}

// Export all together
export default function* rootSaga() {
    yield all([
        fork(watcherGetPermissions),
        fork(watcherGetPermission),
        fork(watcherAddPermissions),
        fork(watcherUpdatePermissions),
        fork(watcherDeletePermissions),
    ])
}

