function SimpleAPI(protocol, host, port, api, version, paths) {
  this.protocol = protocol;
  this.host = host;
  this.port = port;
  this.api = api;
  this.version = version;
  if (parseInt(this.port) === 80 || parseInt(this.port) === 443) {
    this.url = `${this.protocol}://${this.host}`;
    this.urlAPI = `${this.protocol}://${this.host}/${this.api}/${this.version}`;
  } else {
    this.url = `${this.protocol}://${this.host}:${this.port}`;
    this.urlAPI = `${this.protocol}://${this.host}:${this.port}/${this.api}/${this.version}`;
  }
  this.paths = paths;
}

function SimpleAPIV2(protocol, host, port, version, paths) {

  this.protocol = protocol;
  this.host = host;
  this.port = port;
  this.version = version;

  if (parseInt(this.port) === 80 || parseInt(this.port) === 443) {

    this.url = `${this.protocol}://${this.host}`;
    this.urlAPI = `${this.protocol}://${this.host}/${this.version}`;

  } else {

    this.url = `${this.protocol}://${this.host}:${this.port}`;
    this.urlAPI = `${this.protocol}://${this.host}:${this.port}/${this.version}`;
  }

  this.paths = paths;
}


export const requestApiTimeout = process.env.REACT_APP_API_TIMEOUT || 5000;
export const requestApiTimeoutHistorical = 20000;

export const userManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    user: '/user',
    roles: '/role',
    permission: '/permission',
    usersGroup: '/group',
  },
);

export const deviceManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    device: '/device',
    service: '/service',
    template: '/template',
  },
);

// FIXME: minio config need to be a backend compatible request
export const minioManagement = {
  protocol: process.env.REACT_APP_MINIO_PROTOCOL || 'http',
  url: process.env.REACT_APP_MINIO_URL || 'storage.fiwoo.local',
  port: process.env.REACT_APP_MINIO_PORT || 80,
};

export const reportManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    report: '/report',
  },
);

export const kpiManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    kpi: '/kpi',
  },
);

export const rulesManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    rules: '/rule/',
    sendCommand: '/rule/sendcommand',
  },
);

export const internalRulesManagement = new SimpleAPIV2(
  process.env.REACT_APP_INTERNAL_RULE_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_INTERNAL_RULE_API_URL || 'rules',
  process.env.REACT_APP_INTERNAL_RULE_HTTP_PORT || 5017,
  'v1',
  {
    rules: '/rules/',
    sendCommand: '/rules/sendcommand',
  },
);

export const menuManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    menu: '/menu',
    element: '/menu/element',
    tree: '/menu/tree',
    position: '/menu/positions',
    settings: '/menu/settings',
  },
);

export const opendataManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    opendata: '/open-data',
    organization: '/open-data/organization',
  },
);

export const runtimeManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 5024,
  'v1',
  {
    datasource: '/runtime/datasource',
    etl: '/runtime/etl',
  },
);

export const websocketConnection = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL && process.env.REACT_APP_HTTP_PROTOCOL.toUpperCase() === 'HTTPS' ? 'wss' : 'ws',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1',
  {
    socket: '/socket',
  },
);

export const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';
export const errorMode = process.env.REACT_APP_ERROR_MODE || false;

export const enterRoute = '/app/home';
export const loginRoute = '/app/login';

export const developmentEnvironment = process.env.REACT_APP_ENV === 'development';

export const defaultLogin = process.env.REACT_APP_DEV_LOGIN || 'admin@admin.com';
export const defaultPassword = process.env.REACT_APP_DEV_PASSWORD || 'Fiwoo.!1234';

//
// export const entitiesEndpoints = {
//     user: "/user",
//     roles: "/role",
//     permission: "/permission"
// };

// const user =  new SimpleAPIV2(
//   'http',
//   'api.fiwoo.local',
//   5600,
//   'api',
//   'v1',
//   {
//     user: "/user",
//     roles: "/role",
//     permission: "/permission"
//   }
// );

// config.userManagement = user;


export const dashboardManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1', {
    dashboard: '/dashboard',
    widget: '/widget',
  },
);

export const dashboardManagementV2 = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v2', {
    dashboard: '/dashboard',
    widget: '/widget',
  },
);

export const historicalManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1', {
    search: '/historical/search',
    aggregates: '/historical/aggregates',
  },
);

export const historicalV2Management = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v2', {
    search: '/historical/search',
  },
);

export const alertManagement = new SimpleAPIV2(
  process.env.REACT_APP_HTTP_PROTOCOL || 'http',
  process.env.REACT_APP_API_URL || 'api.fiwoo.local',
  process.env.REACT_APP_HTTP_PORT || 80,
  'v1', { incident: '/incident' },
);
