import React from 'react';
import './styles.scss';
import { navigate } from '@reach/router';
import Styled from 'styled-components';
import { Button, Col } from 'reactstrap';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { injectIntl } from 'react-intl';
import ShowThumbnail from '../../pages/Dashboards/ShowThumbnail/ShowThumbnail';
import { typography } from '../../configuration/fonts';
import PrivateComponent from '../PrivateComponent/privateComponent';
import { FormattedMessage } from '../../Contexts/LanguageContext';

const viewdashboardLabel = (
  <FormattedMessage
    id="dashboards.view.label"
    defaultMessage="view dashboard"
    description="Label of view dashboard"
  />
);

const ContainerViewDashboardHead = Styled('div')`
  width: 100%;
  height: 50px;
  color: #FFFFFF !important;
  //font-size: 1.33em !important;
  padding: 10px;
`;
const ContainerViewDashboardBody = Styled('div')`
  width: 100%;
  height: 100%;

  button {

    width: 50%!important;
    height: 3em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF !important;
    background-color: Transparent !important;
    background-repeat:no-repeat;
    border: 1px solid #FFFFFF !important;
    font-family: ${typography} !important;
    font-size: 1.2em !important;
    font-weight: 600 !important;
    line-height: 20px !important;
import { Col } from 'reactstrap';

  
  }

`;

class DashboardCard extends React.Component {
    getTranslate = () => {
      const res = {};
      const properties = [
        'prefixAgo',
        'prefixFromNow',
        'suffixAgo',
        'suffixFromNow',
        'seconds',
        'minute',
        'minutes',
        'hour',
        'hours',
        'day',
        'days',
        'month',
        'months',
        'year',
        'years',
      ];
      const { intl } = this.props;
      properties.forEach((property) => {
        const translate = intl.formatMessage({ id: `widget.date.${property}` });
        res[property] = translate === ' ' ? null : translate;
      });
      return res;
    };

    render() {
      const {
        dashboardThumbnail,
        actions,
      } = this.props;
      return (
        <Col sm={12} md={6} lg={3}>
          <div className="card dashboardCard">
            <div className="thumbnail-container">
              <div className="thumbnail">
                <ShowThumbnail dashboardThumbnail={dashboardThumbnail} />
              </div>
            </div>

            <div className="container-view-dashboard">
              <ContainerViewDashboardHead>
                <div className="row">
                  <div className="col right">
                    {actions}
                  </div>

                </div>

              </ContainerViewDashboardHead>
              <ContainerViewDashboardBody>
                <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="read">
                  <Button
                    onClick={() => navigate(`/app/dashboard/${dashboardThumbnail.id}`)}
                    outline
                    color="primary"
                  >
                    {viewdashboardLabel}
                  </Button>
                </PrivateComponent>
              </ContainerViewDashboardBody>

            </div>
            <div className="card-info">
              <p className="card-title">{dashboardThumbnail.name}</p>
              <p className="card-author">
                <TimeAgo
                  date={dashboardThumbnail.updatedAt}
                  formatter={buildFormatter(this.getTranslate())}
                />
              </p>
            </div>
          </div>
        </Col>
      );
    }
}

export default injectIntl(DashboardCard);
