import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import {
  Components, categories, options, valuesData, getCategory,
} from '../utils';

export default {
  headerColors: [{
    name: 'headerTableBackground',
    component: Components.colorPicker,
    categories: [categories.COLORS],

  },
  {
    name: 'headerTableColorText',
    component: Components.colorPicker,
    categories: [categories.COLORS],

  }],
  colorConditions: [{
    name: 'conditions',
    component: Components.tableConditions,
    categories: [categories.CONDITIONS],
  }],
  columns: [{
    name: 'listComponent',
    component: Components.listComponent,
    categories: [categories.LABELS, categories.APPEARANCE],
  }],
  dataConfig: [
    {
      name: 'dataConfig',
      component: Components.tableData,
      categories: [categories.DATA],
    },
  ],
};

export const TableConfigurationSheetBasic = {
  dataConfig: [
    {
      name: 'dataConfig',
      component: Components.tableData,
      categories: [categories.DATA],
    },
  ],
};

export const LinkedTableConfigurationSheet = {
  headerColors: [{
    name: 'headerTableBackground',
    component: Components.colorPicker,
    categories: [categories.COLORS],

  },
  {
    name: 'headerTableColorText',
    component: Components.colorPicker,
    categories: [categories.COLORS],

  }],
  colorConditions: [{
    name: 'conditions',
    component: Components.tableConditions,
    categories: [categories.CONDITIONS],
  }],
  columns: [{
    name: 'listComponent',
    component: Components.listComponent,
    categories: [categories.LABELS, categories.APPEARANCE],
  }],
};

export const validationTable = (type, config, container) => {
  const constraints = {};
  valuesData.TABLE.forEach((oldProperty) => {
    const property = typeof oldProperty === 'object' ? Object.keys(oldProperty)[0] : oldProperty;
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }

    if ((property === 'sampling' && config.data.operation !== 'last-value') || property !== 'sampling') {
      constraints[`${category}.${property}`] = {
        presence: {
          allowEmpty: false,
          message: (
            <FormattedMessage id="rules.profile.validation.empty" />
          ),
        },
      };
    }
  });
  if (container) {
    delete constraints['data.sampling'];
  }

  return validate(config, constraints);
};
