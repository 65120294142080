import React from 'react';
import RemoveHoc from '../../../helpers/RemoveComponent';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';
import Widget from '../../../../models/WidgetV2';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const Share = (
  <FormattedMessage
    id="options.share"
    defaultMessage="Share"
    description="Title of share"
  />
);

const SendCommand = (
  <FormattedMessage
    id="options.sendCommand"
    defaultMessage="Share"
    description="Title of send command"
  />
);

const OptionsForWidget = ({
  widget, clickView, clickSendingCommandsView, selectedDevices, sources,
}) => {
  const ButtonOpenRemove = ({ showModal }) => (
    <li
      onClick={showModal}
      role="presentation"
    >
      {Delete}
    </li>
  );

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Widget, nameData: 'widgets' });

  // eslint-disable-next-line no-shadow
  const hasDeviceCommands = (sources) => {
    let hasCommands = false;
    sources.forEach((o) => {
      if (o.commands.length) hasCommands = true;
    });
    return hasCommands;
  };

  return (
    <div className="tableMenu">
      <ul className="list-unstyled">
        { !!widget.constructor.widgetType  && (
          <PrivateComponent
            checkLogin
            checkPermission
            microsService="/app/widget"
            permission="update"
          >
            <li
              onClick={() => { clickView(widget, 'edit'); }}
              role="presentation"
            >
              {Edit}
            </li>
          </PrivateComponent>
        )}
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            onClick={() => { clickView(widget, 'share'); }}
            role="presentation"
          >
            {Share}
          </li>
        </PrivateComponent>
        { widget.sendCommandOption && hasDeviceCommands(sources) && (
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            onClick={() => {
              clickSendingCommandsView(
                { ...widget, sources, selectedDevices }, 'sendingCommands',
              );
            }}
            role="presentation"
          >
            {SendCommand}
          </li>
        </PrivateComponent>
        ) }
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="delete"
        >
          <RemoveDOM rowContent={widget} />
        </PrivateComponent>
      </ul>
    </div>
  );
};

export default OptionsForWidget;
