import React, { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import FormattedMessage from '../../../../Contexts/LanguageContext/Components/FormattedMessage';
import { WidgetItem } from '../../Add/steps/WidgetType';
import Field from '../../../../elements/Field/Field';
import LinkedStepButtons from '../components/LinkedStepButtons';

const Step1Linked = ({
  currentStep, update, totalSteps, nextStep, isActive,
}) => {
  const [widgetType, setWidgetType] = useState(undefined);
  const [linkedName, setLinkedName] = useState('');
  const [errors, setErrors] = useState({});

  const getAvailableWidgets = () => (
    [
      'NEEDLE',
      'PERCENTAGE',
      'TEXT_ACCOUNTANT',
      'TABLE',
      'BARS',
      'LINES',
    ]);

  const handleInputChange = (event) => {
    const { target: { value, name } } = event;
    setLinkedName(value);
    update(name, value);
  };

  const handleChangeWidgetType = (widgetName) => {
    if (errors.type) setErrors({});
    setWidgetType(widgetName);
    update('widgetType', widgetName);
  };

  const validate = () => {
    if (!widgetType) {
      setErrors({ type: <FormattedMessage id="widgets.wizard.validation.widget.empty" /> });
      return;
    }
    if (linkedName === '') {
      setErrors({ name: <FormattedMessage id="Validation.minimum" values={{ minimum: '1' }} /> });
      return;
    }
    setErrors({});
    nextStep();
  };

  const buttons = [
    {
      type: 'primary', id: 'nextStepLinkedWidget', handleOnClick: validate, label: 'linkedWidget.nextStep', disabled: !widgetType,
    },
  ];

  if (isActive) {
    return (
      <Row className="wizard-step linkedStepsContainer">
        <Row className="justify-content-center wizard-step-title">
          <h1><FormattedMessage id="widgets.wizard.step2.title" /></h1>
        </Row>
        <Row className="justify-content-center mb-4 wizard-step-subtitle">
          <span><FormattedMessage id="widgets.linked.wizard.step1.subtitle" /></span>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs="1" lg="4">
            <Field
              label={<FormattedMessage id="widgets.linked.wizard.step1.name" />}
              type="text"
              name="name"
              onChange={handleInputChange}
              value={linkedName}
              error={!!errors?.name}
              helperText={errors?.name}
              className="required"
            />
          </Col>
        </Row>

        <Container>
          <div className="wizardWrapper addWidget">
            <Row className="justify-content-center">
              <Col lg={{ size: 9 }}>
                <div className="widgetsGrid justify-content-center">
                  {getAvailableWidgets().map((widget, i) => (
                    <WidgetItem
                      key={`item${i}`}
                      className="widget available wizard-widget p-4"
                      name={widget}
                      active={widgetType === widget}
                      onClick={(widgetName) => handleChangeWidgetType(widgetName)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
            {errors?.type && <p className="error text-center mt-2 mb-4">{errors.type}</p>}
          </div>
        </Container>
        <LinkedStepButtons buttons={buttons} />
      </Row>
    );
  }
  return null;
};

export default Step1Linked;
