export const data = {
  id: '5d5e71a5008b9101950cb415',
  name: 'Mi Primer Dashboard',
  description: 'Mi Primer Dashboard',
  owner: 'admin',
  permissions_policy: {
    users: [],
    groups: [],
    public: true,
  },
  layoutProperties: {
    lg: [
      {
        w: 2, h: 6, x: 0, y: 0, i: '5d8368fe6073e301b504c696', minH: 6, moved: false, static: false,
      },
      {
        w: 2, h: 4, x: 0, y: 6, i: '5d836a6e6073e301b504c697', minH: 4, moved: false, static: false,
      },
      {
        w: 1, h: 5, x: 0, y: 10, i: '5d836b606073e301b504c698', minH: 5, moved: false, static: false,
      },
      {
        w: 4, h: 4, x: 0, y: 15, i: '5d8378866073e301b504c699', minH: 4, moved: false, static: false,
      },
      {
        w: 4, h: 10, x: 0, y: 19, i: '5d84cad7894a9101acac76a8', minH: 6, moved: false, static: false,
      },
      {
        w: 4, h: 5, x: 0, y: 29, i: '5d887d89e2b16d01e976c075', minH: 4, moved: false, static: false,
      },
      {
        w: 4, h: 10, x: 0, y: 34, i: '5d888025e2b16d01e976c076', minH: 6, moved: false, static: false,
      },
      {
        w: 4, h: 4, x: 0, y: 44, i: '5d8893e314123e02f53053fe', minH: 4, moved: false, static: false,
      }],
    md: [
      {
        w: 2, h: 2, x: 0, y: 1, i: '5d7f3e4322f54601c96acf0e', moved: false, static: false,
      },
      {
        w: 2, h: 6, minH: 6, x: 0, y: 2, i: '5d8368fe6073e301b504c696', moved: false, static: false,
      },
      {
        w: 2, h: 4, minH: 4, x: 0, y: 3, i: '5d836a6e6073e301b504c697', moved: false, static: false,
      },
      {
        w: 1, h: 5, minH: 5, x: 0, y: 4, i: '5d836b606073e301b504c698', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 5, i: '5d8378866073e301b504c699', moved: false, static: false,
      },
      {
        w: 4, h: 10, minH: 6, x: 0, y: 6, i: '5d84cad7894a9101acac76a8', moved: false, static: false,
      },
      {
        w: 4, h: 5, minH: 4, x: 0, y: 7, i: '5d887d89e2b16d01e976c075', moved: false, static: false,
      },
      {
        w: 4, h: 10, minH: 6, x: 0, y: 8, i: '5d888025e2b16d01e976c076', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 9, i: '5d8893e314123e02f53053fe', moved: false, static: false,
      }],
    sm: [
      {
        w: 2, h: 6, x: 2, y: 15, i: '5d8368fe6073e301b504c696', minH: 6, moved: false, static: false,
      },
      {
        w: 1, h: 5, x: 2, y: 10, i: '5d836a6e6073e301b504c697', minH: 4, moved: false, static: false,
      },
      {
        w: 1, h: 5, x: 3, y: 10, i: '5d836b606073e301b504c698', minH: 5, moved: false, static: false,
      },
      {
        w: 2, h: 4, x: 0, y: 16, i: '5d8378866073e301b504c699', minH: 4, moved: false, static: false,
      },
      {
        w: 2, h: 6, x: 0, y: 10, i: '5d84cad7894a9101acac76a8', minH: 6, moved: false, static: false,
      },
      {
        w: 4, h: 5, x: 0, y: 21, i: '5d887d89e2b16d01e976c075', minH: 4, moved: false, static: false,
      },
      {
        w: 4, h: 10, x: 0, y: 0, i: '5d888025e2b16d01e976c076', minH: 6, moved: false, static: false,
      },
      {
        w: 4, h: 4, x: 0, y: 26, i: '5d8893e314123e02f53053fe', minH: 4, moved: false, static: false,
      }],
    xxs: [
      {
        w: 4, h: 4, x: 0, y: 0, i: '5d6e509e07a1d201e0af1d02', moved: false, static: false,
      },
      {
        w: 4, h: 2, x: 0, y: 1, i: '5d7f3e4322f54601c96acf0e', moved: false, static: false,
      },
      {
        w: 4, h: 6, minH: 6, x: 0, y: 2, i: '5d8368fe6073e301b504c696', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 3, i: '5d836a6e6073e301b504c697', moved: false, static: false,
      },
      {
        w: 1, h: 5, minH: 5, x: 0, y: 4, i: '5d836b606073e301b504c698', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 5, i: '5d8378866073e301b504c699', moved: false, static: false,
      },
      {
        w: 4, h: 10, minH: 6, x: 0, y: 6, i: '5d84cad7894a9101acac76a8', moved: false, static: false,
      },
      {
        w: 4, h: 5, minH: 4, x: 0, y: 7, i: '5d887d89e2b16d01e976c075', moved: false, static: false,
      },
      {
        w: 4, h: 10, minH: 6, x: 0, y: 8, i: '5d888025e2b16d01e976c076', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 9, i: '5d8893e314123e02f53053fe', moved: false, static: false,
      }],
    xs: [
      {
        w: 4, h: 3, x: 0, y: 0, i: '5d6e509e07a1d201e0af1d02', moved: false, static: false,
      },
      {
        w: 4, h: 2, x: 0, y: 1, i: '5d7f3e4322f54601c96acf0e', moved: false, static: false,
      },
      {
        w: 4, h: 6, minH: 6, x: 0, y: 2, i: '5d8368fe6073e301b504c696', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 3, i: '5d836a6e6073e301b504c697', moved: false, static: false,
      },
      {
        w: 1, h: 5, minH: 5, x: 0, y: 4, i: '5d836b606073e301b504c698', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 5, i: '5d8378866073e301b504c699', moved: false, static: false,
      },
      {
        w: 4, h: 10, minH: 6, x: 0, y: 6, i: '5d84cad7894a9101acac76a8', moved: false, static: false,
      },
      {
        w: 4, h: 5, minH: 4, x: 0, y: 7, i: '5d887d89e2b16d01e976c075', moved: false, static: false,
      },
      {
        w: 4, h: 10, minH: 6, x: 0, y: 8, i: '5d888025e2b16d01e976c076', moved: false, static: false,
      },
      {
        w: 4, h: 4, minH: 4, x: 0, y: 9, i: '5d8893e314123e02f53053fe', moved: false, static: false,
      }],
  },
  widgets: [
    {
      connectedDevices: [
        {
          id: '5d5ce7131652fc02841c5caa', device_id: 'ulDeviceId291510', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
        }],
      _id: '5d8368fe6073e301b504c696',
      name: '1111',
      description: '11111',
      widgetType: 'NEEDLE',
      config: { min: '1', max: '100', unit: '1' },
      owner: 'admin',
      permissionsPolicy: {
        users: [],
        groups: [],
        public: true,
      },
      updatedDate: '2019-09-19T11:39:42.313Z',
      lastUserUpdated: 'admin',
      __v: 0,
    },
    {
      connectedDevices: [
        {
          id: '5d5ce8351652fc02841c5cab', device_id: 'ulDeviceId291511', attributes: [], lazy_attributes: ['tempULlazy'], command_attributes: [], static_attributes: [],
        }],
      _id: '5d836a6e6073e301b504c697',
      name: '2222',
      description: '2222',
      widgetType: 'PERCENTAGE',
      config: { unit: 'Bar' },
      owner: 'admin',
      permissionsPolicy: {
        users: [],
        groups: [],
        public: true,
      },
      updatedDate: '2019-09-19T11:45:50.533Z',
      lastUserUpdated: 'admin',
      __v: 0,
    },
    {
      connectedDevices: [{
        id: '5d5ce8411652fc02841c5cad', device_id: 'ulDeviceId291513', attributes: [], lazy_attributes: ['humidityULlazy'], command_attributes: [], static_attributes: [],
      }],
      _id: '5d836b606073e301b504c698',
      name: '33333333333',
      description: '3333333333333333',
      widgetType: 'PERCENTAGE',
      config: { unit: 'Bar' },
      owner: 'admin',
      permissionsPolicy: { users: [], groups: [], public: true },
      updatedDate: '2019-09-19T11:49:52.980Z',
      lastUserUpdated: 'admin',
      __v: 0,
    }, {
      connectedDevices: [{
        id: '5d5ce7131652fc02841c5caa', device_id: 'ulDeviceId291510', attributes: [], lazy_attributes: ['tempULlazy'], command_attributes: [], static_attributes: [],
      }],
      _id: '5d8378866073e301b504c699',
      name: '324',
      description: '234',
      widgetType: 'DIGITAL_ACCOUNTANT',
      config: { decimalPlaces: '5' },
      owner: 'admin',
      permissionsPolicy: { users: [], groups: [], public: true },
      updatedDate: '2019-09-19T12:45:58.680Z',
      lastUserUpdated: 'admin',
      __v: 0,
    }, {
      connectedDevices: [{
        id: '5d5ce7131652fc02841c5caa', device_id: 'ulDeviceId291510', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }, {
        id: '5d5ce8351652fc02841c5cab', device_id: 'ulDeviceId291511', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }, {
        id: '5d5ce8411652fc02841c5cad', device_id: 'ulDeviceId291513', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }],
      _id: '5d84cad7894a9101acac76a8',
      name: 'barras',
      description: 'barras',
      widgetType: 'BARS',
      config: { orientation: 'horizontal', 'xAxis': 'as', 'yAxis': 'as' },
      owner: 'admin',
      permissionsPolicy: { users: [], groups: [], public: true },
      updatedDate: '2019-09-20T12:49:27.520Z',
      lastUserUpdated: 'admin',
      __v: 0,
    }, {
      connectedDevices: [{
        id: '5d5ce7131652fc02841c5caa', device_id: 'ulDeviceId291510', attributes: ['location'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }],
      _id: '5d887d89e2b16d01e976c075',
      name: 'Mapa1',
      description: 'Localozación',
      widgetType: 'MAP',
      config: { mapType: 'city' },
      owner: 'admin',
      permissionsPolicy: { users: [], groups: [], public: true },
      updatedDate: '2019-09-23T08:08:41.522Z',
      lastUserUpdated: 'admin',
      __v: 0,
    }, {
      connectedDevices: [{
        id: '5d5ce8501652fc02841c5cb0', device_id: 'ulDeviceId291516', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }, {
        id: '5d5ce8581652fc02841c5cb2', device_id: 'ulDeviceId291518', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }, {
        id: '5d5ce8631652fc02841c5cb4', device_id: 'ulDeviceId291520', attributes: ['humidityULattr'], lazy_attributes: [], command_attributes: [], static_attributes: [],
      }],
      _id: '5d888025e2b16d01e976c076',
      name: 'Lineas',
      description: '1',
      widgetType: 'LINES',
      config: {
        sampling: 'minute', startDate: '1991-01-05', endDate: '2019-09-30', 'xAxis': 'EJE X', 'yAxis': 'Eje Y',
      },
      owner: 'admin',
      permissionsPolicy: { users: [], groups: [], public: true },
      updatedDate: '2019-09-23T08:19:49.652Z',
      lastUserUpdated: 'admin',
      __v: 0,
    }, {
      connectedDevices: [], _id: '5d8893e314123e02f53053fe', name: 'qweqwe', description: 'qweqweqwe', widgetType: 'TEXT', owner: 'admin', permissionsPolicy: { users: [], groups: [], public: true }, lastUserUpdated: 'admin', createdAt: '2019-09-23T09:44:03.622Z', updatedAt: '2019-09-23T09:44:03.622Z', __v: 0,
    }],
  lastUserUpdated: 'admin',
  updatedAt: '2019-09-23T09:44:33.974Z',
};

export default data;
