const operators = ['+', '-', '*', '/'];

export const getOperator = (character) => {
  if (character === '+') return 'sum';
  if (character === '-') return 'sub';
  if (character === '*') return 'mul';
  if (character === '/') return 'div';

  return undefined;
};

export const validateFormatSource = (arithmetical) => {
  if (arithmetical[0] === 'value') {
    if (!arithmetical[1]) return false;
  } else if (!arithmetical[1] || !arithmetical[2] || !arithmetical[3] || !arithmetical[4]) {
    return false;
  }

  return true;
};

export const builderArithmetical = (arithmetical, options = undefined) => {
  let counterOpen = 0;
  const obj = {};
  const res = [];
  let device = '';
  let operator = '';

  if (!arithmetical.includes('(')) {
    const arithmeticalSplited = arithmetical.split(':::');

    if (!validateFormatSource(arithmeticalSplited)) return false;

    if (arithmeticalSplited[0] === 'value') {
      return {
        step: 'value',
        value: Number(arithmeticalSplited[1]),
      };
    }

    return {
      step: 'source',
      urn: `fiwoo:${arithmeticalSplited[0]}:${arithmeticalSplited[1]}`,
      attribute: arithmeticalSplited[3],
      operation: arithmeticalSplited[4],
      options,
    };
  }

  for (let index = 0; index < arithmetical.length; index += 1) {
    const character = arithmetical[index];
    const nextcharacter = arithmetical[index + 1];

    if (character === '(') {
      counterOpen += 1;

      if (counterOpen !== 1) {
        obj[counterOpen] = {};
        obj[counterOpen].startIndex = index;
      }
    } else if (character === ')') {
      if (counterOpen !== 1) {
        obj[counterOpen].endIndex = index;

        if (counterOpen === 2) {
          res.push(arithmetical.slice(obj[counterOpen].startIndex,
            obj[counterOpen].endIndex + 1));
        }

        counterOpen -= 1;
      }
    } else if (counterOpen === 1 && character === '[') {
      operator = arithmetical[index + 1];
    } else if (counterOpen === 1 && character !== '+' && character !== ']') {
      device += character;
      if (['[', '(', ']', ')'].includes(nextcharacter)) {
        if (device.length > 3) {
          res.push(device);
        }
        device = '';
      }
    }
  }

  let res2;

  switch (getOperator(operator)) {
    case 'sum':
    case 'mul':

      if (res.map((p) => builderArithmetical(p, options)).includes(false)) return;

      res2 = {
        step: 'arithmetic',
        operation: getOperator(operator),
        process: res.map((o) => builderArithmetical(o, options)),
      };

      break;

    case 'sub':

      if (!builderArithmetical(res[0], options)) return;
      if (!builderArithmetical(res[1], options)) return;

      res2 = {
        step: 'arithmetic',
        operation: getOperator(operator),
        minuend: builderArithmetical(res[0], options),
        subtrahend: builderArithmetical(res[1], options),
      };

      break;

    case 'div':

      if (!builderArithmetical(res[0], options)) return;
      if (!builderArithmetical(res[1], options)) return;

      res2 = {
        step: 'arithmetic',
        operation: getOperator(operator),
        dividend: builderArithmetical(res[0], options),
        divisor: builderArithmetical(res[1], options),
      };

      break;

    default:

      if (!builderArithmetical(res[0], options)) return;

      res2 = builderArithmetical(res[0], options);

      break;
  }

  return res2;
};

export const getListElements = (arithmetical) => {
  let device = '';
  const listElements = [];
  for (let index = 0; index < arithmetical.length; index += 1) {
    const character = arithmetical[index];
    const nextcharacter = arithmetical[index + 1];
    if (character === '(') {
      listElements.push('open-bracket');
    } else if (character === ')') {
      listElements.push('end-bracket');
    } else if (character === '[') {
      listElements.push('operator');
    } else if (!operators.includes(character) && character !== ']') {
      device += character;
      if (['[', '(', ']', ')'].includes(nextcharacter)) {
        if (device.length > 3) {
          listElements.push('source');
        }
        device = '';
      }
    }
  }
  return listElements;
};

export const validateListElements = (listElements) => {
  let isValid = true;
  listElements.forEach((element, index) => {
    if ((element === 'operator' && (listElements[index + 1] === 'operator'
      || listElements[index + 1] === 'end-bracket'))
      || (element === 'open-bracket' && listElements[index + 1] === 'operator')) {
      isValid = false;
    }
  });
  return isValid;
};

export const validateBrackets = (arithmetical) => {
  let contStart = 0;
  let contEnd = 0;

  for (let i = 0; i < arithmetical.length; i += 1) {
    if (arithmetical[i] === '(') contStart += 1;
    if (arithmetical[i] === ')') contEnd += 1;
  }

  return contStart === contEnd;
};

export const isOperator = (operator) => {
  if (operator === '+') return true;
  if (operator === '-') return true;
  if (operator === '*') return true;
  if (operator === '/') return true;

  return false;
};

export const validateOperators = (arithmetical) => {
  let validation = false;

  for (let i = 0; i < arithmetical.length; i += 1) {
    if (isOperator(arithmetical[i])) {
      if (arithmetical[i - 1] === '[' && arithmetical[i + 1] === ']') validation = true;
    }
  }

  return validation;
};

export const validateThreeDoblePoints = (arithmetical) => {
  let validation = false;

  for (let i = 0; i < arithmetical.length; i += 1) {
    if (arithmetical[i] === ':') {
      if (arithmetical[i + 1] === ':' && arithmetical[i + 2] === ':') {
        validation = true;
      }
    }
  }

  return validation;
};

export const validateStartAndEnd = (arithmetical) => (
  arithmetical[0] === '(' && arithmetical[arithmetical.length - 1] === ')'
);

export const validateProcess = (arithmetical) => (
  !!(validateBrackets(arithmetical)
    && validateOperators(arithmetical)
    && validateThreeDoblePoints(arithmetical)
    && validateStartAndEnd(arithmetical))
);

export const rebuilderArithmetical = (process) => {
  if (process.step === 'source') {
    return `${process.urn.split(':')[1]}:::${process.urn.split(':')[2]}:::${process.attribute}:::${process.operation}`;
  }
  if (process.step === 'value') {
    return `${process.step}:::${process.value}`;
  }
  if (process.step === 'arithmetic') {
    if (process.operation === 'sum') {
      return `(${process.process.map((p) => rebuilderArithmetical(p)).toString().replace(',', '[+]')})`;
    } if (process.operation === 'mul') {
      return `(${process.process.map((p) => rebuilderArithmetical(p)).toString().replace(',', '[*]')})`;
    } if (process.operation === 'sub') {
      return `(${rebuilderArithmetical(process.minuend)}[-]${rebuilderArithmetical(process.subtrahend)})`;
    } if (process.operation === 'div') {
      return `(${rebuilderArithmetical(process.dividend)}[/]${rebuilderArithmetical(process.divisor)})`;
    }
  }

  return '';
};
