import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import EditableCard from '../../../pages/helpers/EditableCard/EditableCard';
import ContextualNav from '../../ContextualNav/index';
import withLongPress from '../../../helperComponents/WithLongPress/withLongPress';
import { getHash } from '../../../helpers/utils';

const EditableCardWithLongPress = withLongPress(
  // eslint-disable-next-line react/jsx-props-no-spreading
  (props) => <EditableCard {...props} />,
  400,
);

const MobileTable = ({
  body,
  header,
  onChecked,
  checked,
  onCheckedAll,
  optionsMenu,
  optionsForRow,
  typeOfData,
  entity,
  onRowClick,
  rowComponentForMobile,
  viewClick,
  selectable,
}) => {
  const [contextualNavVisibility, setContextualNavVisibility] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleSelected = (index, object) => {
    setContextualNavVisibility(true);
    setSelectedCard(object);
    onChecked(index);
  };

  const handleOnClose = () => {
    setContextualNavVisibility(false);
    onCheckedAll(false);
  };

  const getFormattedData = (data, h) => {
    let formattedData = data[h.key];

    if (header.type === 'date') {
      formattedData = <FormattedDate value={formattedData} />;
    }

    return formattedData;
  };

  return (
    <>
      {selectable && contextualNavVisibility && (
        <ContextualNav
          selectedCard={selectedCard}
          onClose={handleOnClose}
          checked={checked}
          optionsMenu={optionsMenu}
          optionsForRow={optionsForRow}
          entity={entity}
          typeOfData={typeOfData}
          viewClick={viewClick}
          data={body}
        />
      )}
      {body.map((o, i) => (
        <EditableCardWithLongPress
          key={getHash(i)}
          isEditable={false}
          deletable={false}
          className={`mobile-table-card ${
            checked && checked[i] ? 'checked' : ''
          }`}
          onSelect={() => handleSelected(i, o)}
          onClick={() => onRowClick(o)}
          render={() => (rowComponentForMobile
            ? rowComponentForMobile(o, header)
            : header.map((h, j) => (
              <dl key={getHash(`${j}`)}>
                <dt>{h.label}</dt>
                <dd>{getFormattedData(o, h)}</dd>
              </dl>
            )))}
        />
      ))}
    </>
  );
};

MobileTable.defaultProps = {
  body: [],
  header: [],
  onChecked: () => {},
  checked: [],
  onCheckedAll: () => {},
  optionsMenu: () => {},
  optionsForRow: () => {},
  typeOfData: '',
  entity: () => {},
  onRowClick: () => {},
  viewClick: () => {},
  selectable: true,
};

export default MobileTable;
