import { clone } from 'ramda';

export const loraTranslator = (entity, attributeData) => {
  if (!attributeData) {
    const translatedEntity = clone(entity);
    translatedEntity.extraConfigurations = {
      data_model: (
        translatedEntity.extraConfigurations.data_model
        || translatedEntity.internal_attributes.lorawan.data_model
      ),
      dev_eui: translatedEntity.extraConfigurations.dev_eui,
      app_eui: translatedEntity.extraConfigurations.app_eui,
      application_id: translatedEntity.extraConfigurations.application_id,
      application_key: translatedEntity.extraConfigurations.application_key,
      username: translatedEntity.extraConfigurations.username,
      password: translatedEntity.extraConfigurations.password,
    };
    return translatedEntity;
  } else {
    return null;
  }
};

export const sigfoxTranslator = (entity, attributeData) => {
  if (attributeData) {
    const mapping = entity.internal_attributes
      ? entity.internal_attributes[0].mapping.split(' ')
      : [];
    let properties = {};

    if (mapping.length >= 1) {
      mapping.forEach(map => {
        const splitMap = map.split(':');
        if (splitMap[0] === attributeData.name) {
          properties.offset = Number(splitMap[1]);
          properties.type = splitMap[2];
          properties.parameter = Number(splitMap[3]);
          properties.endian = splitMap[4];
        }
      });
    }
    return properties;
  } else {
    return null;
  }
};
