import React, { useState, Suspense } from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import Field from '../../../../../../elements/Field';

const Slider = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetSlider })),
);

const WidgetSliderPoints = ({
  config, handleNumberOfPoints, intl, isProfile,
}) => {
  const handleChange = (name, value) => {
    handleNumberOfPoints(value);
  };
  const [values] = useState([2, 3, 4, 5, 6, 7, 8, 9, 10]);
  return (
    <Row className={`${!isProfile && 'justify-content-center'} mb-4`}>
      <Col sm={`${isProfile ? 3 : 2} `}>
        <Field
          key={1}
          name="numberOfPoints"
          label={intl.formatMessage({ id: 'widgets.wizard.slider.numberOfPoints' })}
          placeholder="1"
          type="select"
          options={values.map((v) => (
            {
              value: v,
              name: `${v}`,
            }
          ))}
          value={config?.conditions.numberOfPoints.length}
          onChange={(name, value) => handleChange(name, value)}
        />
      </Col>
      <Col sm={4} className={`${isProfile ? 'mt-3' : ''}`}>
        <Suspense fallback="">
          <Slider
            isPreview
            configurationStep
            config={config}
          />
        </Suspense>
      </Col>
    </Row>
  );
};

export default injectIntl(WidgetSliderPoints);
