import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import InfoLinked from '../newcomponents/infoLinked';

class WidgetInfo extends React.PureComponent {
  render() {
    const {
      data, entity, isLinked, sources,
    } = this.props;

    if (isLinked || data.typeContainer === 'LINKED') {
      return (
        <InfoLinked
          data={data}
          entity={entity}
          linkedParent={isLinked || data}
        />
      );
    }
    return (
      <div className="tabSection p-5 backgroundWhite">
        <div className="row">
          <dt className="col-12">
            <FormattedMessage id="widget.attribute.widgetType" />
          </dt>
          <dd className="col-12">
            {data.widgetType}
          </dd>
        </div>
        {sources.map((o) => (
          <div>
            <>
              <div className="row">
                <dt className="col-12">
                  <FormattedMessage id="widget.attribute.deviceId" />
                </dt>
                <dd className="col-12">
                  {o.name}
                </dd>
              </div>

              <div className="row">
                <dt className="col-12">
                  <FormattedMessage id="widget.attribute.attributes" />
                </dt>
                {o.attributes.length !== 0
                  ? o.attributes.map((attribute) => (
                    <dd className="col-12">
                      {attribute.name}
                    </dd>
                  ))
                  : (
                    <dd className="col-12">
                      <FormattedMessage id="widget.profile.none" />
                    </dd>
                  )}
              </div>

              <div className="row">
                <dt className="col-12">
                  <FormattedMessage id="widget.attribute.command_attributes" />
                </dt>
                {o.commands.length !== 0
                  ? o.commands.map((commandAttribute) => (
                    <dd className="col-12">
                      {commandAttribute.name}
                    </dd>
                  ))
                  : (
                    <dd className="col-12">
                      <FormattedMessage id="widget.profile.none" />
                    </dd>
                  )}
              </div>
            </>
          </div>
        ))}
      </div>
    );
  }
}

export default WidgetInfo;
