import styled from "styled-components";
import {typography} from "../../../configuration/fonts";
import colors from "../../../configuration/colors";

const Header = styled.div`
  text-transform: uppercase;
  padding: 30px 10px 15px 25px;
  color: #2b334a;
  font-family: ${typography};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  border-bottom: 1px solid ${colors['ui-Grey2']};
`;

export default Header;