import { all } from 'redux-saga/effects';
import usersSagas from './users/saga';
import rolesSagas from './roles/saga';
import authSagas from './auth/saga';
import permissionsSaga from './permissions/saga';
import devicesSaga from './devices/saga';
import templatesSaga from './templates/saga';
import servicesSaga from './services/saga';
import dashboardsSaga from './dashboards/saga';
import widgetsSaga from './widgets/saga';
import historicalsSaga from './historicals/saga';
import rulesSaga from './rules/saga';
import menuElementsSaga from './menuElements/saga';
import usersGroupsSaga from './usersGroups/saga';
import alertsSaga from './alerts/saga';
import dataSources from './dataSources/saga';
import etlProcedures from './etlProcedures/saga';
import reportsSaga from './reports/saga';
import reportHistoricalSaga from './reportHistorical/saga';
import opendataSaga from './opendata/saga';
import kpisSaga from './kpis/saga';

export default function* rootSaga() {
  yield all([
    usersSagas(),
    rolesSagas(),
    permissionsSaga(),
    authSagas(),
    devicesSaga(),
    servicesSaga(),
    templatesSaga(),
    rulesSaga(),
    menuElementsSaga(),
    dashboardsSaga(),
    widgetsSaga(),
    historicalsSaga(),
    usersGroupsSaga(),
    alertsSaga(),
    dataSources(),
    etlProcedures(),
    reportsSaga(),
    reportHistoricalSaga(),
    kpisSaga(),
    opendataSaga(),
  ]);
}
