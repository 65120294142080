import React from 'react';
// eslint-disable-next-line import/no-cycle
import SendCommandListComponent from '../../pages/Dashboards/Show/ProfileSendingCommands/components/SendCommandList';

const getAttributesName = (attrs) => (attrs ? attrs.map((o) => o.name) : []);

const getAllDeviceAttributesName = (device) => [
  ...getAttributesName(device.attributes),
  ...getAttributesName(device.lazy_attributes),
  ...getAttributesName(device.static_attributes),
];

const getHeatMapValue = (device) => (
  <div key={device.attribute}>
    <b>{device.attribute}</b>
    {' '}
    :
    { device.heatmap.value }
  </div>
);

const InfoPanel = ({
  onMarkerClick, device, isOpen, widgetType,
}) => (
  <div className={`panel${isOpen ? ' open' : ''}`}>
    <i onClick={onMarkerClick} onKeyUp={onMarkerClick} role="link" tabIndex="0" label="markerclick" className="uil uil-times closeIcon" />
    <div className="title">
      <h1>{device.device_id}</h1>
      <p>
        (
        {device.device_name}
        )
      </p>
    </div>
    <div className="content">
      {widgetType === 'HEATMAP' ? getHeatMapValue(device) : getAllDeviceAttributesName(device).map((attr) => (
        <div key={attr}>
          <b>{attr}</b>
          :
          {' '}
          {device.data[attr]}
          {' '}
        </div>
      ))}

      <SendCommandListComponent
        devices={[device]}
        devicesInfo={() => {}}
        checkable={false}
        onChange={() => {}}
        errors={[]}
        nameless
        id={device.id}
      />
    </div>
  </div>
);

export default InfoPanel;
