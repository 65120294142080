import PropTypes from 'prop-types';
import { clone } from 'ramda';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext/index';
import { withWizardStep } from '../../../../../Contexts/WizardStepContext';
import CommonView from '../../../../CommonView';
import Button from '../../../../../elements/Button';
import * as Builder from './helpers';
import '../../styles.scss';
import OperationBuilder from './OperationBuilder';

const ArithmeticOperationButtons = ({ handleOnClickArithmeticOperation }) => (
  <div className="arithmeticOperationButtons">
    <Button
      className="arithmeticOperationButton"
      onClick={() => handleOnClickArithmeticOperation('', '+')}
    >
      <FormattedMessage
        id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.addition"
      />
    </Button>
    <Button
      className="arithmeticOperationButton"
      onClick={() => handleOnClickArithmeticOperation('', '-')}
    >
      <FormattedMessage
        id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.subtraction"
      />
    </Button>
    <Button
      className="arithmeticOperationButton"
      onClick={() => handleOnClickArithmeticOperation('', '*')}
    >
      <FormattedMessage
        id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.multiplication"
      />
    </Button>
    <Button
      className="arithmeticOperationButton"
      onClick={() => handleOnClickArithmeticOperation('', '/')}
    >
      <FormattedMessage
        id="kpis.wizard.step5.arithmetical.arithmetic.operation.options.division"
      />
    </Button>
  </div>
);

class ArithmeticOperation extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      errors: this.props.errors,
      onValidation: false,
      editorState: null,
      config: null,
      kpiConfig: null,
      definition: null,
      rawText: null,
      aggregation: false,
    };
  }

  validate = () => {
    const { aggregation, definition } = this.state;
    if (aggregation && definition.process.options.resolution === '') {
      this.props.onError(
        <FormattedMessage id="kpis.wizard.aggregation.noOptionsSelected" />,
      );
      return;
    }
    this.savePartialKpi();
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  savePartialKpi = () => {
    this.setErrors({});
    const { rawText, kpiConfig, definition: { process: { options } } } = this.state;
    const processValidation = (
      Builder.builderArithmetical(rawText) === undefined
      || !Builder.validateListElements(Builder.getListElements(rawText)))
      ? false : Builder.validateProcess(rawText);

    if (!processValidation) {
      this.props.onError(
        <FormattedMessage id="kpis.wizard.step4.arithmetical.invalid.operation" />,
      );
    } else {
      const stateCopy = { ...this.state };
      stateCopy.definition.process = Builder.builderArithmetical(rawText, options);
      stateCopy.metadata = { editorConfig: kpiConfig };

      this.setState(stateCopy, () => {
        const { errors, onValidation, ...rest } = this.state;

        this.props.onContinue({ ...rest }, this.props.extraData);
      });
    }
  }

  handleChangeOperationBuilder = (data) => {
    // data: rawText, definition, metadata, aggregation
    const { metadata, ...rest } = data;
    this.setState({ kpiConfig: metadata, ...rest });
  }

  render() {
    const { errors, newEntity } = this.props;
    const { devices } = this.props.extraData;

    return (
      <>
        <Container>
          <Row>
            <Col>
              <OperationBuilder
                kpi={newEntity}
                devices={devices}
                errors={errors}
                handleChange={this.handleChangeOperationBuilder}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addKpiSuccessFlag: state.get('kpis').get('addSuccess'),
  errorFetching: state.get('kpis').get('errorFetching'),
  notify: state.get('notify'),
  definition: state.get('definition'),
});

ArithmeticOperation.propTypes = {
  unit: PropTypes.string,
};

ArithmeticOperation.defaultProps = {
  unit: '',
  permissionsPolicy: [],
  errors: {},
  handleOnClickArithmeticOperation: undefined,
};

ArithmeticOperationButtons.propTypes = {
  handleOnClickArithmeticOperation: PropTypes.func,
};

ArithmeticOperationButtons.defaultProps = {
  handleOnClickArithmeticOperation: undefined,
};

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(ArithmeticOperation)));
