import React from 'react';
import { injectIntl } from 'react-intl';
import Field from '../../../../../elements/Field/index';
import CommonView from '../../../../CommonView';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { getUsers } from '../../../../../services/redux/users/actions';
import ListOfMembers, {
  Member,
} from '../../../../helpers/Add/components/ListOfMembers';
import './styles.scss';
import { clone } from 'ramda';
import CardHoc from '../../../../../elements/Card/index';
import Finder from '../../../../../components/Finder/index';
import {
  deleteUserFromUsersGroup,
  addUserToUsersGroup,
} from '../../../../../services/redux/usersGroups/actions';


const Card = CardHoc(
  Finder(props =>
    props.data.map((d, i) => (
      <div
        key={i}
        className={'memberWrapper'}
        onClick={e => props.handleOnChange(e, { value: d })}
      >
        <Member user={d} />
      </div>
    ))
  )
);

export class UsersList extends CommonView {
  constructor(props) {
    super({ props: props });

    this.state = {
      errors: this.props.errors || '',
      onValidation: false,
      selectedUsers: [],
      availableUsers: this.props.newEntity.users ? this.filterUsers(this.props.users, this.props.newEntity.users) : [],
      saved: this.props.newEntity.users ? this.props.newEntity.users : [],
      newEntity: this.props.newEntity,
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    getUsers();
  }

  handleUsersComboChange = (name, value) => {
    const { newEntity } = this.state;
    const saved = clone(this.state.saved);
    saved.push(value);
    this.setState(
      {
        saved,
        availableUsers: this.filterUsers(this.props.users, saved),
      },
      () => addUserToUsersGroup(newEntity, value)
    );
  };

  componentDidUpdate(prevProps) {
    const { saved } = this.state;

    if (prevProps.users !== this.props.users)
      this.setState({
        availableUsers: this.filterUsers(this.props.users, saved),
      });

    if(this.props.newEntity !== prevProps.newEntity)
      this.setState({
        newEntity: this.props.newEntity,
        saved: this.props.newEntity.users,
        availableUsers: this.filterUsers(this.props.users, this.props.newEntity.users),
      })
  }

  handleOnDelete = user => {
    const { newEntity } = this.state;

    const saved = clone(this.state.saved).filter(u => u.id !== user.id);
    this.setState(
      {
        saved,
        availableUsers: this.filterUsers(this.props.users, saved),
      },
      () => deleteUserFromUsersGroup(newEntity, user)
    );
  };

  filterUsers = (users, usersSaved) => {
    return users.filter(u => {
      return !usersSaved.some(uwp => uwp.id === u.id);
    });
  };

  render() {
    const { intl, readOnly, listTitle } = this.props;
    const { selectedUsers, saved, availableUsers, newEntity} = this.state;

    return (
      <>
        {!readOnly && <Row>
          <Col sm={{ size: 12 }}>
            <div className="d-flex align-items-center">
              {['selectedUsers'].map((key, i) => (
                <Field
                  name={key}
                  id={key}
                  placeholder={intl.formatMessage({
                    id: `ListOfMembers.select.users.list`,
                  })}
                  type={'customSelect'}
                  value={selectedUsers}
                  options={availableUsers}
                  mappedBy={'name'}
                  formatOption={['name', 'surname']}
                  equalsBy={'id'}
                  selectedOptions={selectedUsers}
                  onChange={this.handleUsersComboChange}
                  key={`${key}-${i}`}
                  customOptions={Card}
                  newEntity={newEntity}
                />
              ))}
            </div>
          </Col>
        </Row>}
        <Row>
          <Col sm={{ size: 12 }}>
            {saved.length > 0 && (
              <ListOfMembers
                newEntity={newEntity}
                title={listTitle || `usersGroups.wizard.users.list`}
                users={saved || []}
                onDelete={this.handleOnDelete}
                permissionsOptions={false}
                options={!readOnly}
                permissionToEdit={this.props.permissionToEdit}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  users: state
    .get('users')
    .get('list')
    .toJS(),
  errorFetching: state.get('usersGroups').get('errorFetching'),
  notify: state.get('notify'),
});

export default connect(
  mapStateToProps,
  {}
)(injectIntl(UsersList));
