import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

// TODO
// These expressions are suposed to work better than used ones,
// because of generalization, but somethin isn't working propperly.
// Cant start wit space or dot, after first letter, allows letter, spaces and dots
// Have to solve how to avoid @ # and other characters identified as letters.
// ^(?!(\.|\s).*$)(\p{L}|\p{Z}|\p{P})+
// ^(?!\\p{Z}|\\p{P})\\p{L}*[\\p{L}\\p{Z}\\p{P}]*

export const additionalPassConstraint = [{
  key: 'password',
  defaultValue: '',
  constraints: {
    length: {
      minimum: 8,
      message:
  <FormattedMessage
    id="Validation.minimum"
    values={{ minimum: '8' }}
  />,
    },
  },
}];

/** Model by keys, values expected and constraints ( optional ) */
export const ModelExpected = [
  { key: 'id', defaultValue: null },
  { key: 'entityId', defaultValue: '' },
  { key: 'entityType', defaultValue: '' },
  { key: 'TimeInstant', defaultValue: '' },
  { key: 'recvTime', defaultValue: '' },
  { key: 'FIWOO-UNIT-ATTRIBUTE', defaultValue: [] },
  { key: 'humidityULstatic', defaultValue: [] },
  { key: 'humidityulcommand_status', defaultValue: '' },
];
