import React, { Component } from 'react';
import './styles.scss';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import Textarea from '../Textarea';
import ComplexTextarea from '../ComplexTextarea/index';

export class TwitterTextarea extends Component {
  constructor(props) {
    super(props);

    const { value, tweetLength } = this.props;

    this.state = {
      tweetMsg: value,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value !== prevProps.value) {
      this.updateAfterValueChange(value);
    }
  }

  updateAfterValueChange = (value) => {
    if (value) {
      this.setState({
        tweetMsg: value,
      });
    } else {
      this.setState({
        tweetMsg: '',
      });
    }
  }

  handleOnChange = (e) => {
    e.preventDefault();
    if (e && e.type && e.type !== 'change') {
      const { onChange, tweetLength } = this.props;
      if (tweetLength <= 280 && tweetLength > 0) {
        e.lengthOk = true;
      } else {
        e.lengthOk = '';
      }
      onChange(e);
    }
  }

  getStatusClass = (actualLength, maxLength) => {
    if (actualLength > maxLength) { return 'overflow'; }

    if (actualLength > maxLength - 10) { return 'almostOverflow'; }

    return '';
  };

  charCounter = () => {
    const { tweetLength } = this.props;
    const maxLength = 280;

    return (
      <small
        className={this.getStatusClass(
          tweetLength,
          maxLength,
        )}
      >
        {
          tweetLength > maxLength ? '-'.concat(tweetLength - maxLength) : tweetLength
        }
      </small>
    );
  };

  render() {
    const { name, complex } = this.props;
    const { tweetMsg } = this.state;

    return (
      <>
        {complex ? (
          <ComplexTextarea
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            type="textarea"
            onChange={this.handleOnChange}
            id={name}
            value={tweetMsg}
          />
        ) : (
          <Textarea
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            type="textarea"
            onChange={this.handleOnChange}
            id={name}
            value={tweetMsg}
          />
        )}
        {this.charCounter()}
      </>
    );
  }
}

TwitterTextarea.propTypes = {
  value: PropTypes.string,
  tweetLength: PropTypes.number,
  complex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  intl: PropTypes.objectOf(PropTypes.object).isRequired,
};

TwitterTextarea.defaultProps = {
  value: '',
  complex: false,
  tweetLength: 0,
};
export default injectIntl(TwitterTextarea);
