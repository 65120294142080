import React from 'react';
import { FormattedMessage } from 'react-intl';

export default ({ component: Component, ...rest }) => (Component ? (
  <FormattedMessage
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {(txt) => <Component>{txt}</Component>}

  </FormattedMessage>
)
  : (
    <FormattedMessage
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {(txt) => txt}

    </FormattedMessage>
  ));
