/* eslint-disable import/no-cycle */
import Widget from '../..';
import {
  SliderConfigurationSheetBasic, SliderConfigurationSheet, validationSlider,
  SliderDefaultTemplateConfig,
} from './utils/configurationSheet';
import { SliderDefaultConfiguration } from './utils/defaultConfiguration';
import SliderComponent from './component';
import { ReactComponent as SliderWidgetIcon } from '../../../../configuration/icons/svg/ico-slider.svg';
import SelectDatasources from '../../../../pages/Widgets/Add/steps/SelectDatasourcesV2';
import ConfigureWidgetSliderV2 from '../../../../pages/Widgets/Add/steps/customSteps/Commands/Slider/ConfigureWidgetSliderV2';
import WidgetPreviewV2 from '../../../../pages/Widgets/Add/steps/WidgetPreviewV2';

export const NAME_WIDGET = 'SLIDER';
export default class SliderWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = SliderWidgetIcon;

  static wizardSteps = { SelectDatasources, ConfigureWidgetSliderV2, WidgetPreviewV2 };

  defaultConfig = ({ ...SliderDefaultConfiguration });

  templateConfig = SliderDefaultTemplateConfig;

  component = SliderComponent;

  hasMultipleSources = true;

  needsSocket = false;

  // *! --------------------- [COMMON BODY] --------------------- !* //
  injectData = () => null;

  getValidatorConfig = () => (config) => validationSlider(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? SliderConfigurationSheetBasic
      : SliderConfigurationSheet
  )

  getWidgetChildBodyQuery = () => null;

  getQueryHistorical = () => null;

  parsedLinkedData = () => null;
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
