import validate from 'validate.js';
import {
  categories, Components, options, getCategory, filterConfigurationSheet,
} from '../../../../utilsConfigurationSheet';
import { TableConfigurationSheet } from '../../../../table/utils/configurationSheet';

// eslint-disable-next-line import/prefer-default-export
export const TableConfigurationSheetForLinked = filterConfigurationSheet(
  {
    ...TableConfigurationSheet,
    operationType: [
      {
        name: 'operation',
        component: Components.select,
        categories: [categories.DATA],
        options: options.operation,
      },
    ],
  }, ['dataConfig'],
);

export const TableLinkedDefaultTemplateConfig = [
  { orientation: ['columns', 'rows'] },
  { type: ['real-time', 'historical-data'] },
  { operation: ['last-value', 'min', 'avg', 'max'] },
];

export const validationLinkedTable = (config,
  templateConfig = TableLinkedDefaultTemplateConfig) => {
  const constraints = {};
  templateConfig.forEach((oldProperty) => {
    const property = typeof oldProperty === 'object' ? Object.keys(oldProperty)[0] : oldProperty;
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }
  });
  return validate(config, constraints);
};
