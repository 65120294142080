import React from 'react';
import { FormattedMessage } from '../../../Contexts/LanguageContext';

export const Components = {
  colorPicker: 'colorPicker',
  checkbox: 'checkbox',
  text: 'text',
  textArea: 'textArea',
  number: 'number',
  colorsData: 'colorsData',
  colorsDataMap: 'colorsDataMap',
  select: 'select',
  barData: 'barData',
  needleRange: 'needleRange',
  switch: 'switch',
  kpiHistoricalSwitch: 'kpiHistoricalSwitch',
  selectImage: 'selectImage',
  selectColor: 'selectColor',
  tableConditions: 'tableConditions',
  commands: 'commands',
  listComponent: 'listComponent',
  tableData: 'tableData',
  lineData: 'lineData',
  mapStyle: 'mapStyle',
  advancedLegendColors: 'advancedLegendColors',
  advancedLegendNames: 'advancedLegendNames',
  date: 'date',
  commandsOnOff: 'commandsOnOff',
  commandsSlider: 'commandsSlider',
};

export const categories = {
  APPEARANCE: 'appearance',
  DATA: 'data',
  LABELS: 'labels',
  EFFECTS: 'effects',
  COLORS: 'colors',
  CONDITIONS: 'conditions',
  TRENDLINES: 'trendlines',
  CUSTOM: 'custom',
};

export const subCategories = {
  HTML: 'HTML',
  MAP: 'MAP',
};

const mapOptionsForSelectComponent = (arr) => arr.map(
  (itm) => ({ name: <FormattedMessage id={`options.widget.profile.${itm}`} />, value: itm }),
);

export const options = {
  orientation: mapOptionsForSelectComponent(['vertical', 'horizontal']),
  legendPosition: mapOptionsForSelectComponent(['top', 'down', 'right']),
  sampling: mapOptionsForSelectComponent(['lastHour', 'lastDay', 'lastFifteenDays', 'lastMonth', 'lastThreeMonth', 'lastYear']),
  operation: mapOptionsForSelectComponent(['avg', 'max', 'min', 'sum']),
};

const categoriesObj = {
  type: 'data',
  period: 'data',
  sampling: 'data',
  startDate: 'data',
  endDate: 'data',
  display: 'data',
  operation: 'data',
  realTime: 'data',
  xAxis: 'labels',
  yAxis: 'labels',
  unit: 'labels',
  alias: 'labels',
  description: 'labels',
  categoryAlias: 'labels',
  trendlines: 'trendlines',
  state: 'appearance',
  min: 'appearance',
  max: 'appearance',
  hiddenColumns: 'appearance',
  imageSelected: 'appearance',
  enabledHeader: 'appearance',
  'legendary-position': 'appearance',
  hiddenAttributes: 'appearance',
  iconCategory: 'appearance',
  alignment: 'appearance',
  showHistorical: 'appearance',
  showIncrement: 'appearance',
  orientation: 'appearance',
  waves: 'effects',
  numberOfPoints: 'conditions',
  conditions: 'conditions',
  range: 'conditions',
  categoryColors: 'conditions',
  colorSelected: 'colors',
  colors: 'colors',
  labelColor: 'colors',
  fillColor: 'colors',
  iconColor: 'colors',
  negativeColor: 'colors',
  positiveColor: 'colors',
  off: 'colors',
  on: 'colors',
  slider: 'colors',
  background: 'colors',
};

export const getCategory = (attribute) => categoriesObj[attribute] ?? 'custom';

export const filterConfigurationSheet = (configurationSheet, keysToFilter) => {
  keysToFilter.forEach((o) => {
    // eslint-disable-next-line no-param-reassign
    delete configurationSheet[o];
  });
  return configurationSheet;
};
