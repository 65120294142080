import React from 'react';
import FormattedMessage from '../../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'key',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'type',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'size',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
    },
  },
];

export default ModelExpected;
