import React from 'react';
import AssignPermissions from '../../../pages/helpers/AssignPermissions/index';
import {connect} from "react-redux";
import {getEntityType} from "../../../helpers/utils";
import { updateRulePermissions } from '../../../services/redux/rules/actions';

class EditPermissions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      original: this.props.data,
    };
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentEntities !== prevProps.currentEntities) {

      const currentEntity = this.props.currentEntities.find(g => g.id === this.props.data.id);

      this.setState({ original: currentEntity || this.props.data },
        () => this.getMembers()
      );
    }
  }

  getMembers = () => {
    const { users, groups } = this.state.original.permissions_policy;
    const { owner } = this.state.original;

    const usersWithPermissions = this.props.users.filter(o =>
      users.map(u => u.id).includes(o.id)
    );
    const usersGroupsWithPermissions = this.props.usersGroups.filter(o =>
      groups.map(g => g.id).includes(o.id)
    );
    const userOwner = this.props.users.find(o => o.id === owner);

    this.setState({
      usersWithPermissions,
      usersGroupsWithPermissions,
      userOwner,
    });
  };

  handleOnSave = (payload) => {
    updateRulePermissions(payload);
  };

  render() {

    const data = this.state.original;
    const { userOwner, usersWithPermissions, usersGroupsWithPermissions } = this.state;

    return (
      <div className={'tabSection p-5'}>
        <AssignPermissions
          userOwner={userOwner}
          usersWithPermissions={usersWithPermissions}
          usersGroupsWithPermissions={usersGroupsWithPermissions}
          newEntity={data}
          saveOnTheFly={this.handleOnSave}
          permissionToEdit={this.props.permissionToEdit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const entityType = getEntityType(props.entity.entityName);

  return ({
    usersGroups: state.get('usersGroups').get('list').toJS(),
    users: state.get('users').get('list').toJS(),
    modifySuccess: state.get('usersGroups').get('modifySuccess'),
    errorFetching: state.get('usersGroups').get('errorFetching'),
    currentEntities: state.get(entityType).get('list').toJS()
  });
};

export default connect(mapStateToProps,{})(EditPermissions);
