import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Field from '../../../../elements/Field';
import withVariables from '../../../../components/WithVariables/index';

import {
  Card,
  severityData,
  Value,
  AlertRow,
} from '../../helpers/severitySelect';
import { parseToTag } from '../../helpers/briefComponents';

const FieldWithVariables = withVariables(Field);

const EditAlertComponent = ({
  alert,
  errors,
  onEdit,
  index,
  onChange,
  intl,
  devices,
}) => (
  <>
    {['name'].map((item) => (
      <dl key={item}>
        <dt className="col col-md-3 align-items-start">
          <FormattedMessage id={`rules.profile.${item}.label`} />
        </dt>
        <dd className="col col-md-7">
          {!onEdit ? (
            alert[item]
          ) : (
            <Field
              type="text"
              onChange={(data) => onChange({
                id: data.target.name,
                value: data.target.value,
                index,
                type: alert.type,
              })}
              name={item}
              value={alert[item]}
              helperText={errors[item]}
              error={!!errors[item]}
            />
          )}
        </dd>
      </dl>
    ))}
    {['description'].map((item) => (
      <dl key={item}>
        <dt className="col col-md-3 align-items-start">
          <FormattedMessage id={`rules.profile.${item}.label`} />
        </dt>
        <dd className="col col-md-7">
          {!onEdit ? (
            parseToTag(alert[item])
          ) : (
            <FieldWithVariables
              type="complexTextarea"
              onChange={(data) => onChange({
                id: data.target.name,
                value: data.target.value,
                index,
                type: alert.type,
              })}
              name={item}
              value={alert[item]}
              helperText={
                errors[item]
                || intl.formatMessage({ id: `rules.wizard.action.${item}.placeholder` })
                  .concat('. ', intl.formatMessage({ id: 'complexTextarea.pattern' }))
              }
              error={!!errors[item]}
              devices={devices.map(
                ({ id, attributes }) => ({
                  device: id,
                  attributes,
                }),
              )}
            />
          )}
        </dd>
      </dl>
    ))}
    <dl>
      <dt className="col col-md-3 align-items-start">
        <FormattedMessage id="rules.profile.severity.label" />
      </dt>
      <dd className="col col-md-7">
        {/* eslint-disable-next-line no-nested-ternary */}
        {!onEdit ? (
          alert.severity !== '' ? (
            <AlertRow item={{ value: alert.severity }} />
          ) : (
            ''
          )
        ) : (
          <Field
            name="severity"
            type="customSelect"
            customOptions={Card}
            options={severityData}
            placeholder={intl.formatMessage({
              id: 'rules.wizard.action.severity.placeholder',
            })}
            onChange={(name, value) => onChange({
              id: name,
              value,
              index,
              type: alert.type,
            })}
            value={alert.severity}
            customSelectedOption={Value}
            helperText={errors.severity}
            error={!!errors.severity}
            css="max-height: 150px;"
          />
        )}
      </dd>
    </dl>
  </>
);

EditAlertComponent.propTypes = {
  alert: PropTypes.objectOf(PropTypes.object).isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  onEdit: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.objectOf(PropTypes.object).isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EditAlertComponent.defaultProps = {
  errors: {},
};

export default injectIntl(EditAlertComponent);
