import React from 'react';
import ReactDOM from 'react-dom';
import Lottie from 'react-lottie';
import animationData from './dashboardLoadingLottie.json';
import './styles.scss';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.id = '';
  }

  componentDidMount() {
    const appRoot = document.getElementById('content');
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    const appRoot = document.getElementById('content');
    appRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(<LoadingComponent />, this.el);
  }
}

const LoadingComponent = () => (
  <div className="dashboard-loading">
    <div className="frame">
      <div className="lottie">
        <Lottie options={defaultOptions} height={280} width={280} className="lottie" />
      </div>
    </div>

  </div>
);

export default Loading;
