import React from 'react';
import './styles.scss';
import { PropTypes } from 'prop-types';

class ContentEditable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      onEdit: false,
    };
  }

  handleOnFocus = e => {
    this.setState({
      onEdit: !this.state.onEdit,
    });
  };

  handleOnBlur = e => {
    const value = e.target.textContent;
    const id = e.target.id;
    this.setState(
      {
        onEdit: !this.state.onEdit,
      },
      () => this.props.onBlur(id, value)
    );
  };

  render() {
    const { tag: Tag, children, className, errors, editable, ...rest } = this.props;
    return (
      <>
        <Tag
          {...rest}
          suppressContentEditableWarning={true}
          contentEditable={editable}
          className={`${editable ? 'editable' : 'editable read-only'} ${className ? className : ''} ${
            errors ? 'error' : ''
          }`}
          onBlur={editable ? this.handleOnBlur : () => {}}
          onFocus={editable ? this.handleOnClick : () => {}}
        >
          {children}
        </Tag>
        {errors && errors.map((error, i) => <span key={i}>{error}. </span>)}
      </>
    );
  }
}

ContentEditable.propTypes = {
  /** If false, the component will not be editable */
  editable: PropTypes.bool,
}

ContentEditable.defaultProps = {
  editable: true,
}

export default ContentEditable;
