import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { PropTypes } from 'prop-types';
import SuccessComponent from '../../../helpers/SuccessPage';
import TemplateOkIcon from '../../../../configuration/icons/svg/device-ok.svg';

const Success = props => (
  <SuccessComponent
    title={<FormattedMessage id="templates.add.success.title" />}
    icon={TemplateOkIcon}
    error={props.errors}
    fetching={props.fetching}
  />
);

const mapStateToProps = state => ({
  errorFetching: state.get('templates').get('errorFetching'),
  fetching: state.get('templates').get('fetching'),
});

Success.propTypes = {
  errorFetching: PropTypes.objectOf(PropTypes.object),
  fetching: PropTypes.objectOf(PropTypes.object),
};

Success.defaultProps = {
  errorFetching: undefined,
  fetching: undefined,
};

export default connect(
  mapStateToProps,
  {}
)(Success);
