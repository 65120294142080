import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import SuccessComponent from '../../../helpers/SuccessPage';
import DeviceOkIcon from '../../../../configuration/icons/svg/device-ok.svg'
import {connect} from "react-redux";
import { PropTypes } from 'prop-types';


const Success = (props) =>
  <SuccessComponent
    title={<FormattedMessage id='services.add.success.title'/>}
    icon={DeviceOkIcon}
    content={<p>Api key: {props.service.api_key}</p>}
    error={props.errorFetching}
    fetching={props.fetching}
    />;


const mapStateToProps = (state) => ({
  service: state.get('services').get('service'),
  errorFetching: state.get('services').get('errorFetching'),
  fetching: state.get('services').get('fetching'),
});

Success.propTypes = {
  errorFetching: PropTypes.objectOf(PropTypes.object),
  fetching: PropTypes.objectOf(PropTypes.object),
};

Success.defaultProps = {
  errorFetching: undefined,
  fetching: undefined,
};

export default connect(
  mapStateToProps,
  {}
)(Success);
