import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ButtonCustom } from '../../../elements/Button/index';
import ModalHOC from '../../../elements/Modal/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { StatelessCheckbox } from '../../../elements/CheckBox';

const Message = styled.h3`
color: #7B8494;
font-size: 16px
text-align: center;
margin-bottom: 20px;
`;
const ButtonsWrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 30px;
button {
  margin-right: 20px;
  &:last-child{
    margin: 0
  }
}
`;

const WrapperContent = styled.div`
padding: 30px 0;
text-align: center;
`;

const CheckboxWrapper = styled.div`
display: flex;
justify-content: flex-start;
margin-top: 20px;
`;

const AlertMessage = styled.div`
display: flex;
justify-content: center;
color: red;
`;

export const ModalTitle = ({ typeOfData }) => (
  <FormattedMessage
    id={typeOfData.concat('.delete.title')}
  />
);

export const CancelMsg = () => (
  <FormattedMessage
    id="options.cancel"
    defaultMessage="Cancel"
    description="Cancel option"
    component="span"
  />
);

export const DeleteMsg = () => (
  <FormattedMessage
    id="options.delete"
    defaultMessage="Delete"
    description="Delete option"
    component="span"
  />
);
export const parseArrayContent = (content) => content.map((row, i) => (
  i !== content.length - 1 ? row.name.concat(', ') : row.name
));

const ModalButtons = (rowContent, hideModal, typeOfData, items, available, handleDelete) => (
  <ButtonsWrapper>
    <ButtonCustom label={<CancelMsg />} type="secondary" handleOnClick={hideModal} />
    <ButtonCustom
      label={<DeleteMsg />}
      type="primary"
      handleOnClick={() => { handleDelete(rowContent, hideModal, typeOfData); }}
    />
  </ButtonsWrapper>
);

const ModalContent = (
  items,
  available,
  rowContent,
  typeOfData,
  handleChangeToParent,
  selectedItems,
) => {
  const [linkedList, setLinkedList] = useState([]);
  const [localSelected, setLocalSelected] = useState(selectedItems);
  const type = Array.isArray(rowContent) && rowContent.length > 1 ? 'plural' : 'singular';

  useEffect(() => {
    const newList = [];
    items.forEach((item) => {
      item.linkedList.list.forEach((linkedItem) => {
        if (!newList.some((nli) => nli.id === linkedItem.id)) {
          newList.push(linkedItem);
        }
      });
    });

    setLinkedList(newList);
  }, [items]);

  const handleChange = (name, value) => {
    setLocalSelected((old) => ({ ...old, [name]: value }));
    handleChangeToParent(name, value);
  };
  return (
    <div>
      <Message>
        <FormattedMessage
          id={typeOfData.concat('.delete.confirmMessage.', type)}
        />
        {' '}
        <em>{!Array.isArray(rowContent) ? rowContent.name : parseArrayContent(rowContent)}</em>
      </Message>
      <WrapperContent>
        {!available ? (
          <>
            <FormattedMessage id={typeOfData.concat('.linked.etl.filled')} />
            <CheckboxWrapper>
              {linkedList.map((elem) => (
                <StatelessCheckbox
                  name={elem.id}
                  label={elem.name}
                  onChange={handleChange}
                  checked={localSelected[elem.id]}
                />
              ))}
            </CheckboxWrapper>
          </>
        ) : <FormattedMessage id={typeOfData.concat('.linked.etl.empty')} />}
      </WrapperContent>
      <AlertMessage>
        <i className="uil uil-exclamation-triangle" />
        <FormattedMessage id="action.irreversible" />
      </AlertMessage>
    </div>
  );
};

const getInitialSelectedItems = (arr) => {
  const obj = {};
  arr.forEach((item) => {
    if (item.linkedList) {
      item.linkedList.list.forEach((i) => {
        obj[i.id] = false;
      });
    }
  });
  return obj;
};

export default (
  ButtonOpen,
  info,
) => class RemoveInner extends React.PureComponent {
  constructor(props) {
    super(props);
    const { rowContent } = props;
    this.state = {
      items: Array.isArray(rowContent) ? rowContent : [rowContent],
      available: false,
      selectedItems: {},
    };
  }

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps) {
    const { rowContent } = this.props;
    if (prevProps.rowContent.length !== rowContent.length) {
      this.setState({
        items: rowContent,
        selectedItems: getInitialSelectedItems(rowContent),
      }, this.check());
    }
  }

  handleCheck = (e, a) => {
    this.setState((old) => ({ ...old, selectedItems: { ...old.selectedItems, [e]: a } }));
  }

  check = () => {
    const { rowContent } = this.props;
    // Fetch
    info.checkAction(rowContent).then((data) => {
      const { items: CurrentItems } = this.state;
      this.setState({
        items: CurrentItems.map((rowData, index) => {
          rowData.linkedList = {
            hasEntityLinked: !!data[index].count, list: data[index].objects,
          };
          return rowData;
        }),
      }, () => {
        // when the new state is setted, check if any item hasEntityLinked
        const { items } = this.state;
        this.setState({
          available: !items.some((item) => item.linkedList.hasEntityLinked),
          selectedItems: getInitialSelectedItems(items),
        });
      });
    });
  }

  delete = (data, callback, typeOfData) => {
    const { available, selectedItems, items } = this.state;
    const Entity = typeOfData.class;
    const { LinkedEntity } = typeOfData;

    let LinkedList = [];
    if (!Array.isArray(data)) {
      data = [data];
    }

    data.forEach((d) => {
      LinkedList = [...LinkedList, ...d.linkedList.list];
      const aux = new Entity(d);
      aux.delete();
    });

    if (!available) {
      Object.keys(selectedItems).forEach((item) => {
        const entity = LinkedList.find((itm) => itm.id === item);
        const aux = new LinkedEntity(entity);
        aux.delete();
      });
    }

    callback(false);
  }

  render() {
    const updatedTypeOfData = info;
    const { rowContent } = this.props;
    const { items, selectedItems, available } = this.state;

    const ModalDOM = ModalHOC({
      ButtonOpen,
      ModalTitle: () => <ModalTitle typeOfData={updatedTypeOfData.name} />,
      ModalContent: () => ModalContent(
        items,
        available,
        rowContent,
        updatedTypeOfData.name,
        this.handleCheck,
        selectedItems,
      ),
      ModalButtons: ({ hideModal }) => ModalButtons(
        rowContent,
        hideModal,
        updatedTypeOfData,
        selectedItems,
        available,
        this.delete,
      ),
    }, { checkItems: true });

    return <ModalDOM rowContent={rowContent} />;
  }
};
