import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
  ListGroupItem, ListGroup, Row,
  Col,
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../CommonView';
import Rule from '../../../../models/Rule';
import Field from '../../../../elements/Field/index';
import {
  clearDevicesState,
  getDevices,
} from '../../../../services/redux/devices/actions';
import SelectableList from '../../../../components/SelectableList';

import DeleteButton from '../../../../elements/DeleteButton/index';


import { withWizardStep } from '../../../../Contexts/WizardStepContext';

const ListComponent = ({ data, onDelete }) => (
  <ListGroup>
    {data.map((s) => (
      <ListGroupItem
        key={s.name}
        className="d-flex justify-content-start"
      >
        <div className="selectedItem">{s.name}</div>
        <DeleteButton onClick={() => onDelete(s.id)} />
      </ListGroupItem>
    ))}
  </ListGroup>
);

class CreateRule extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      errors: {},
      name: this.props.newEntity.name || '',
      description: this.props.newEntity.description || '',
      devices: this.props.newEntity.devices,
      page: 1,
      size: 5,
    };
  }

  areDevicesSelected = () => {
    const { devices } = this.state;
    return devices && devices.length > 0;
  }

  initPageCounter = () => {
    this.setState({ page: 1 });
  }


  componentDidMount() {
    const { page, size, devices } = this.state;
    this.props.onRef(this);
    // TO-DO: Create an async select
    if (devices && devices.length >= 1) {
      getDevices({
        filters: { fiwareService: devices[0].fiware.service }, page: 1, size, mode: '',
      });
    } else {
      getDevices({ filters: [], page, size });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size, devices } = this.state;

    if (prevState.devices !== devices) {
      if (!this.areDevicesSelected()) {
        clearDevicesState();
        getDevices({ filters: [], page: 1, size });
      } else if (devices.length === 1) {
        clearDevicesState();
        getDevices({
          filters: { fiwareService: devices[0].fiware.service }, page: 1, size, mode: '',
        });
      }

      this.initPageCounter();
    }
    if (!this.props.fetching && prevState.page < page) {
      getDevices({
        filters: this.areDevicesSelected() ? { fiwareService: devices[0].fiware.service } : [],
        page,
        size,
      });
    }
  }

  validate = () => {
    this.savePartialRule();
  };

  savePartialRule = () => {
    this.setErrors({});

    const obj = this.state;
    const newEntity = new Rule(obj);
    const created = newEntity.validate(['name', 'devices', 'description']);

    if (created.error) {
      delete created.error;
      this.setErrors(created);
      this.props.onError(created.error);
    } else {
      this.props.onContinue(newEntity);
    }
  };

  getOptions = () => {
    const { devices } = this.props;
    return devices && devices.map((o) => ({ name: o.name, id: o.id, value: o }));
  };

  handleSelectableListChanges = (data) => {
    delete this.props.newEntity.conditions;
    this.setState({
      devices: data,
    });
  };

  handleFetchInfo = () => {
    const { total, devices } = this.props;
    if (total > devices.length) {
      this.setState((prev) => ({
        page: prev.page + 1,
      }));
    }
  };

  handleOnSearch = (value, init = false) => {
    const { size, devices } = this.state;
    if (init) clearDevicesState();
    if (devices && devices.length === 1) {
      getDevices({ filters: { fiwareService: devices[0].fiware.service, name: value }, page: 1, size });
    } else {
      getDevices({ filters: { name: value }, page: 1, size });
    }
  };

  render() {
    const {
      intl,
      fetching,
    } = this.props;

    const { errors } = this.state;
    return (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {['name'].map((key) => (
            <Field
              name={key}
              label={intl.formatMessage({ id: `rules.${key}.label` })}
              placeholder={intl.formatMessage({
                id: `rules.${key}.placeholder`,
              })}
              id={key}
              value={this.state[key]}
              type="text"
              onChange={this.handleInputChange}
              helperText={errors[key]}
              error={!!errors[key]}
              key={key}
            />
          ))}
          <SelectableList
            data={this.getOptions()}
            selected={this.state.devices}
            name="devices"
            translatePrefix="rules"
            error={errors}
            listComponent={ListComponent}
            onChange={this.handleSelectableListChanges}
            fetchInfo={this.handleFetchInfo}
            onSearch={this.handleOnSearch}
            loading={fetching}
            multiOption
            filterBy="name"
            selectOnTheFly
          />
          {['description'].map((key) => (
            <Field
              name={key}
              label={intl.formatMessage({ id: `rules.${key}.label` })}
              placeholder={intl.formatMessage({
                id: `rules.${key}.placeholder`,
              })}
              id={key}
              value={this.state[key]}
              type="textarea"
              onChange={this.handleInputChange}
              helperText={errors[key]}
              error={!!errors[key]}
              key={key}
            />
          ))}
        </Col>
      </Row>
    );
  }
}

ListComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onDelete: PropTypes.func.isRequired,
};

ListComponent.defaultProps = {
  data: [],
};

CreateRule.propTypes = {
};

CreateRule.defaultProps = {
  fetching: true,
};

const mapStateToProps = (state) => ({
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep((injectIntl(CreateRule))));
