import React from 'react';
import { Context } from './provider';

/** Apply Consumer to past component */
const Consumer = (Component) => {
  class withContext extends React.PureComponent {
    render() {
      const { menuOpenState, handleMenuState } = this.context;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
          menuOpenState={menuOpenState}
          handleMenuState={handleMenuState}
        />
      );
    }
  }

  withContext.contextType = Context;

  return withContext;
};

export default Consumer;
