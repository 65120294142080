import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import SuccessComponent from '../../../helpers/SuccessPage';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import DeviceOkIcon from '../../../../configuration/icons/svg/device-ok.svg';

const Success = ({ errorFetching }) => (
  <SuccessComponent
    title={<FormattedMessage id="widgets.wizard.success" />}
    icon={DeviceOkIcon}
    error={errorFetching}
  />
);

const mapStateToProps = (state) => ({
  errorFetching: state.get('widgets').get('errorFetching'),
});

Success.propTypes = {
  errorFetching: PropTypes.objectOf(PropTypes.object),
};

Success.defaultProps = {
  errorFetching: undefined,
};

export default connect(
  mapStateToProps,
  {},
)(Success);
