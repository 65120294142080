import { useRef, useEffect } from 'react';
import { Power3, TimelineMax, CSSPlugin } from 'gsap/all';

const C = CSSPlugin;

const useAnimation = ({ distance = -30, duration = 0.5, delay = 0.08}) => {
  const ref = useRef();
  const animation = useRef();

  const reverse = () => {
    animation.current.reverse();
  };

  useEffect(() => {
    animation.current = new TimelineMax();
    animation.current
      .staggerFrom(
        ref.current,
        duration,
        { alpha: 0, y: distance },
        delay,
      )
      .staggerTo(
        ref.current,
        0.1,
        { alpha: 1, y: 0, ease: Power3.easeOut },
        0.1,
      );
    return () => animation.current.clear();
  }, [distance]);

  return { ref, reverse };
};

export default useAnimation;
