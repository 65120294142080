import { Col, Row } from 'reactstrap';
import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import Switch from '../../../../elements/Switch';
import Field from '../../../../elements/Field';
import {
  getOperationsWithTranslation,
  getSamplingWithTranslation,
} from '../../../../helpers/utils';

const PeriodTimeKPI = ({
  resolutionValue,
  operationValue,
  aggregation,
  isProfile,
  handleSelectChange,
  handleEnableDisable,
  errors,
  intl,
}) => (
  <>
    <Row>
      <Col lg={{ size: isProfile ? 4 : 3, offset: isProfile ? 0 : 3 }}>
        <div>
          <Switch
            className="switch"
            positionTitle="left"
            title={intl.formatMessage({
              id: 'kpis.wizard.activateAggregation.name',
            })}
            checked={aggregation}
            onChange={handleEnableDisable}
          />
        </div>
      </Col>
      <Col lg={{ size: isProfile ? 6 : 3 }}>
        <span className="aggregationLabel pt-10">
          {intl.formatMessage({
            id: 'kpis.wizard.activateAggregation.label',
          })}
        </span>
      </Col>
    </Row>
    <br />
    <div>
      <Row>
        <Col lg={{ size: isProfile ? 6 : 3, offset: isProfile ? 0 : 3 }}>
          <Field
            name="resolution"
            label={intl.formatMessage({
              id: 'kpis.wizard.simple.resolution.label',
            })}
            placeholder={intl.formatMessage({
              id: 'kpis.wizard.simple.resolution.placeholder',
            })}
            id="resolution"
            value={resolutionValue}
            type="select"
            onChange={handleSelectChange}
            helperText={errors.resolution}
            error={!!errors.resolution}
            key="resolution"
            options={
              getSamplingWithTranslation(
                intl,
                ['lastFifteenDays', 'lastThreeMonth', 'lastYear'],
                ['lastSecond', 'lastMinute'],
              )
            }
            disabled={!aggregation}
          />
        </Col>
        <Col lg={{ size: isProfile ? 6 : 3 }}>
          <Field
            name="operation"
            label={intl.formatMessage({
              id: 'kpis.wizard.simple.operation.label',
            })}
            placeholder={intl.formatMessage({
              id: 'kpis.wizard.simple.operation.placeholder',
            })}
            id="operation"
            value={operationValue}
            type="select"
            onChange={handleSelectChange}
            helperText={errors.operation}
            error={!!errors.operation}
            key="operation"
            options={getOperationsWithTranslation(intl)}
            disabled={!aggregation}
          />
        </Col>
      </Row>
    </div>
  </>
);

PeriodTimeKPI.propTypes = {
  resolutionValue: PropTypes.string,
  operationValue: PropTypes.string,
  aggregation: PropTypes.bool,
  isProfile: PropTypes.bool,
  handleSelectChange: PropTypes.func,
  handleEnableDisable: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.any),
  intl: PropTypes.objectOf(PropTypes.any),
};

PeriodTimeKPI.defaultProps = {
  resolutionValue: '',
  operationValue: '',
  aggregation: false,
  isProfile: false,
  errors: {},
  handleSelectChange: () => {},
  handleEnableDisable: () => {},
  intl: {},
};

export default injectIntl(PeriodTimeKPI);
