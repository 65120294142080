import React from 'react';
import PropTypes from 'prop-types';
import RemoveHoc from '../../../helpers/RemoveComponent';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import Widget from '../../../../models/Widget';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const Share = (
  <FormattedMessage
    id="options.share"
    defaultMessage="Share"
    description="Title of share"
  />
);

const SendCommand = (
  <FormattedMessage
    id="options.sendCommand"
    defaultMessage="Share"
    description="Title of send command"
  />
);

const WIDGET_TYPES_WITHOUT_SENDING_COMMANDS = ['HTML', 'TEXT'];

const OptionsForWidget = ({
  widget, clickView, clickSendingCommandsView, selectedDevices, permissionToEdit, close
}) => {
  const ButtonOpenRemove = ({ showModal }) => (
    <li
      onClick={showModal}
      role="presentation"
    >
      {Delete}
    </li>
  );

  ButtonOpenRemove.propTypes = {
    showModal: PropTypes.func,
  };

  ButtonOpenRemove.defaultProps = {
    showModal: () => {},
  };

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Widget, nameData: 'widgets' });

  const isWidgetThatNeedsToSendCommand = (
    widgetVar,
  ) => !WIDGET_TYPES_WITHOUT_SENDING_COMMANDS.includes(widgetVar.widgetType);
  // eslint-disable-next-line no-shadow
  const hasDeviceCommands = (widget) => {
    let hasCommands = false;
    if (widget.origins) {
      widget.origins.forEach((o) => {
        const w = o.connectedDevices
        if (w.readDevice && w.readDevice.command_attributes.length > 0) hasCommands = true;
      });
    }
    return hasCommands;
  };

  return (
    <div className="tableMenu">
      <ul className="list-unstyled">
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            onClick={() => { clickView(widget, 'edit'); close(); }}
            role="presentation"
          >
            {Edit}
          </li>
        </PrivateComponent>
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            onClick={() => { clickView(widget, 'share'); close(); }}
            role="presentation"
          >
            {Share}
          </li>
        </PrivateComponent>
        { isWidgetThatNeedsToSendCommand(widget) && hasDeviceCommands(widget) && (
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="update"
        >
          <li
            onClick={() => {
              clickSendingCommandsView(
                { ...widget, selectedDevices }, 'sendingCommands',
              );
            }}
            role="presentation"
          >
            {SendCommand}
          </li>
        </PrivateComponent>
        ) }
        <PrivateComponent
          checkLogin
          checkPermission
          microsService="/app/widget"
          permission="delete"
        >
          {permissionToEdit && <RemoveDOM rowContent={widget} />}
        </PrivateComponent>
      </ul>
    </div>
  );
};

OptionsForWidget.propTypes = {
  widget: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  clickSendingCommandsView: PropTypes.func,
  selectedDevices: PropTypes.string,
  permissionToEdit: PropTypes.bool,
};

OptionsForWidget.defaultProps = {
  widget: {},
  clickView: () => {},
  clickSendingCommandsView: () => {},
  selectedDevices: '',
  permissionToEdit: undefined,
};

export default OptionsForWidget;
