/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import ButtonPointSlider from './buttonPointSlider';
import ModalCommands from '../modalCommands';
import WidgetCommandsGroup from '../WidgetCommandsGroup';
import ContentEditable from '../../../../../helpers/ContentEditable/ContentEditable';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../../../elements/Button';
import Field from '../../../../../../elements/Field';

const WidgetSliderAttributes = ({
  handleCommandGroup, intl, sources, handleChange,
  commandsToShow, addCommand, statePoint, availableCommands, errors, removeCommand,
  getAllAttributes, commandsGroups, handleEditCommandsGroup, handleDeleteCommandsGroup,
  handleAlias, handleDeleteIndividualCommand, stateIndex, isProfile,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [groupStates] = useState(statePoint.state);
  const [commandsData, setCommandsData] = useState();
  const [alias] = useState(statePoint.alias);
  const [showRow, setShowRow] = useState(true);
  const [simpleCommands, setSimpleCommands] = useState(statePoint.commands.filter((c) => !c.groupId));

  useEffect(() => {
    setSimpleCommands(statePoint.commands.filter((c) => !c.groupId));
  }, [statePoint, statePoint.commands.length]);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const acceptCommandsModal = (groupCommands, state, editCommands, toggleEditModal) => {
    const { commands } = groupCommands;
    if (editCommands) {
      handleEditCommandsGroup(groupCommands, state);
      toggleEditModal();
    } else {
      handleCommandGroup(commands, state);
      toggleShowModal();
    }
  };

  const deleteCommandGroup = (group, state) => {
    const index = commandsGroups[state].findIndex((c) => c.groupId === group.groupId);
    handleDeleteCommandsGroup(group, state, index);
  };

  const handleCommandsChange = (data) => {
    setCommandsData(data);
  };

  const onChangeAlias = (id, data) => {
    handleAlias(data, statePoint.state);
  };

  const handleShowRow = () => {
    setShowRow(!showRow);
  };

  const getAlias = (name, value) => {
    const commands = [];
    let final = {
      alias: value,
      value,
    };
    const arraySplit = value.split(' ');
    const valueSplit = arraySplit.length > 1 ? arraySplit[1] : arraySplit[0];

    sources.forEach((source) => {
      const command = source.commands.filter((c) => c.name === name
      && c.available_commands.length);
      if (command?.length) {
        command[0].available_commands.forEach((c) => {
          if (c.value === valueSplit || c.value === value) commands.push(c.name);
        });
      }
    });
    if (commands.length) {
      final = {
        alias: commands[0]?.toString(),
        value: valueSplit,
      };
    } else if (arraySplit.length === 2) {
      final = {
        alias: valueSplit,
        value: valueSplit,
      };
    }
    return final;
  };

  const numberOfCommands = () => {
    const countStateButton = statePoint.commands.filter((c) => c.sourcesId.length && !c.groupId).length;
    let countCommandsIntoGroups = 0;
    Object.keys(commandsGroups[groupStates]).forEach((index) => {
      countCommandsIntoGroups += commandsGroups[groupStates][index].sourcesId.length;
    });
    return countCommandsIntoGroups + countStateButton;
  };

  const getOptions = (row) => {
    let options = [];
    if (commandsToShow.length > 0) {
      options = commandsToShow.map((c) => (
        {
          value: c,
          name: c.name,
        }
      ));
    }
    options = sources.find((source) => source.id === row)?.commands.map((c) => (
      {
        value: c,
        name: c.name,
      }
    ));
    return options || [];
  };
  const getValueAttribute = (row) => {
    if (commandsToShow.length > 0) {
      return commandsToShow.find((c) => c.name === row.commandName);
    }
    return sources.find((source) => source.id === row.sourcesId[0])?.commands.find(
        (c) => c.name === row.commandName,
      ) || '';
  };

  return (
    <>
      { showModal && (
        <ModalCommands
          sources={sources}
          getAllAttributes={getAllAttributes}
          state={statePoint.state}
          toggleShowModal={toggleShowModal}
          acceptCommandsModal={acceptCommandsModal}
          handleCommandsChange={handleCommandsChange}
          getAlias={getAlias}
        />
      )}

      <Row>
        {!isProfile ? (
          <Col sm={1} className="offset-sm-3">
            <div>
              <span className="titlePoint">
                <FormattedMessage id="widgets.wizard.slider.point" />
                {statePoint.state}
              </span>
            </div>
            <div className="pt-2 row">
              <div onClick={handleShowRow}>
                <i className={`uil uil-angle-${showRow ? 'up' : 'down'} i`} />

              </div>
              <ContentEditable
                className="labelFocus"
                tag="at"
                onBlur={onChangeAlias}
                id={alias}
                editable
              >
                {alias}
              </ContentEditable>
            </div>
          </Col>
        ) : (
          <ButtonPointSlider
            statePoint={statePoint}
            onChangeAlias={onChangeAlias}
            showRow={showRow}
            handleShowRow={handleShowRow}
            alias={alias}
            commandsNumber={numberOfCommands}
          />
        )}
        {showRow
          ? (
            <Col sm={`${isProfile ? 12 : 7}`}>
              <div />
              <Row>
                <Col sm={`${isProfile ? 10 : 9}`} className="d-flex">
                  <Row className={`w-100 ${!isProfile ? 'mb-4' : 'mb-3'}`}>
                    <Col sm={`${isProfile ? 8 : 9}`} className="pr-0 d-flex align-items-center">
                      <Row className="w-100">
                        <Col sm={6} className="text-uppercase text-commands">
                          <span>
                            <FormattedMessage id="widgets.wizard.sendCommand.device" />
                          </span>
                        </Col>
                        <Col sm={6} className="text-uppercase text-commands">
                          <span>
                            <FormattedMessage id="widgets.wizard.sendCommand.command" />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={`${isProfile ? 4 : 3}`} className="pl-0 text-uppercase text-commands d-flex align-items-center">
                      <span>
                        <FormattedMessage id="widgets.wizard.sendCommand.value" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {commandsGroups && commandsGroups[groupStates]
              && Object.keys(commandsGroups[groupStates]).length > 0 && (
                Object.keys(commandsGroups[groupStates]).map((index) => (
                  <Row>
                    <WidgetCommandsGroup
                      commandName={commandsGroups[groupStates][index]?.commandName}
                      command={commandsGroups[groupStates][index]}
                      state={groupStates}
                      sources={sources}
                      getAllAttributes={getAllAttributes}
                      acceptCommandsModal={acceptCommandsModal}
                      commandsToShow={commandsData}
                      deleteCommandGroup={deleteCommandGroup}
                      handleChange={handleChange}
                      getAlias={getAlias}
                      handleDeleteIndividualCommand={handleDeleteIndividualCommand}
                      isProfile={isProfile}
                    />
                  </Row>
                ))
              )}
              <div>
                {simpleCommands?.map((row, index) => (
                  <Row key={`${row.sourcesId}-${row.commandName}-${stateIndex}-${index}`}>
                    <Col sm={`${isProfile ? 10 : 9}`} className={`ml-0 row d-flex ${isProfile && 'spaceButton'}`}>
                    <Row className="w-100">
                      <Col sm={`${isProfile ? 8 : 9}`} className="pr-0">
                      <Row className="w-100">
                        <Col sm={6} className={`fieldCol ${isProfile && 'pr-1'}`}>
                        <Field
                          key={1}
                          name="device"
                          placeholder={intl.formatMessage({
                            id: 'widgets.wizard.sendCommand.attribute.placeholder',
                          })}
                          type="select"
                          options={sources.map((source) => (
                            {
                              value: source,
                              name: source.name,
                            }
                          ))}
                          helperText={errors?.source?.find((d) => d.index === index
                            && d.state === statePoint.state)?.message}
                          error={errors?.source?.find((d) => d.index === index
                            && d.state === statePoint.state)?.error}
                          value={sources.find((source) => source.id === row.sourcesId[0])}
                          onChange={(name, value) => handleChange(
                            name,
                            value,
                            index,
                            statePoint.state,
                          )}
                        />
                      </Col>
                      <Col sm={6} className={`fieldCol ${isProfile && 'pr-1'}`}>
                        <Field
                          key={2}
                          name="attribute"
                          placeholder={intl.formatMessage({
                            id: 'widgets.wizard.sendCommand.command.selector.placeholder',
                          })}
                          type="select"
                          options={getOptions(row.sourcesId[0])}
                          helperText={errors?.attribute?.find((d) => d.index === index
                            && d.state === statePoint.state)?.message}
                          error={errors?.attribute?.find((d) => d.index === index
                            && d.state === statePoint.state)?.error}
                          onChange={(name, value) => handleChange(
                            name,
                            value,
                            index,
                            statePoint.state,
                          )}
                          value={getValueAttribute(row)}
                        />
                        </Col>
                      </Row>
                      </Col>
                      <Col sm={`${isProfile ? 4 : 3}`} className="commandCol fieldCol pl-0">
                        <Field
                          key={3}
                          name="command"
                          placeholder={intl.formatMessage({
                            id: 'widgets.wizard.sendCommand.command.placeholder',
                          })}
                          type="autoComplete"
                          availableCommands={availableCommands.find((c) => c.state === statePoint.state)?.commands[index]}
                          helperText={errors?.command?.find((d) => d.index === index
                            && d.state === statePoint.state)?.message}
                          error={errors?.command?.find((d) => d.index === index
                            && d.state === statePoint.state)?.error}
                          onChange={(name, value) => handleChange(name,
                            value,
                            index,
                            statePoint.state,
                            availableCommands)}
                          value={getAlias(row.commandName, row.value).alias}
                        />
                      </Col>
                    </Row>
                    </Col>
                    <Col sm={2} className="d-flex align-self-center">
                      <Row>
                        {statePoint.commands.filter((c) => !c.groupId).length > 1
              && (
                <Col sm={6} className="px-0 align-self-center">
                <ButtonCustom
                  type="circular"
                  name="minus"
                  onClick={() => removeCommand(statePoint.state, index)}
                />
              </Col>
              )}
                        {(statePoint.commands.filter((c) => !c.groupId).length < 1
                        || statePoint.commands.filter((c) => !c.groupId).length - 1 === index)
              && (
                <Col sm={6} className="px-0 align-self-center">
                  <ButtonCustom
                    type="circular"
                    name="plus"
                    onClick={() => addCommand(index, statePoint.state, true)}
                  />
                </Col>
              )}
                      </Row>
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
          )
          : (
            !isProfile && (
              <Col sm={4}>
                <div className="foldedCommands">
                  <span className="commandLabelList">Comandos listados</span>
                  <span className="ml-2 commandNumberOfCommands">
                    {numberOfCommands()}
                  </span>
                </div>

              </Col>
            )
          )}

      </Row>
      <Row className={`${!isProfile && 'justify-content-center'} mb-4`}>
        {showRow && (
          <Col sm={9} className={`${!isProfile && 'offset-sm-5'} fieldCol`}>
            <Button onClick={toggleShowModal} className="p-0 groupCommandBtn">
              <FormattedMessage id="widgets.wizard.sendCommand.modal.commandsInGroup" />
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default injectIntl(WidgetSliderAttributes);
