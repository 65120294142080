import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../components/Menu';
import OptionsForWidget from './OptionsForWidget';
import './styles.scss';

const WidgetOptionsMenu = ({
  widget, clickView, profileType, clickSendingCommandsView, permissionToEdit, ...rest
}) => {
  const openButton = (props) => (
    <div
      onClick={props.onClick}
      role="presentation"
    >
      <span className="titleText">
        <i className="uil uil-ellipsis-v" />
      </span>
    </div>
  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="right"
      className="more"
    >
      <OptionsForWidget
// eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        widget={widget}
        clickView={clickView}
        profileType={profileType}
        clickSendingCommandsView={clickSendingCommandsView}
        permissionToEdit={permissionToEdit}
      />
    </Menu>
  );
};

WidgetOptionsMenu.propTypes = {
  widget: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  profileType: PropTypes.string,
  clickSendingCommandsView: PropTypes.func,
  permissionToEdit: PropTypes.bool,
  onClick: PropTypes.func,
};

WidgetOptionsMenu.defaultProps = {
  widget: {},
  clickView: () => {},
  profileType: '',
  clickSendingCommandsView: () => {},
  permissionToEdit: undefined,
  onClick: () => {},
};

const WidgetMenuButton = (props) => {
  const {
    title, date, widget, clickView, clickSendingCommandsView, permissionToEdit, ...res
  } = props;

  return (
    <WidgetOptionsMenu
  // eslint-disable-next-line react/jsx-props-no-spreading
      {...res}
      clickSendingCommandsView={
  // eslint-disable-next-line no-shadow
  (widget, profileType) => clickSendingCommandsView(widget, profileType, props.entity)
  }
      clickView={
  // eslint-disable-next-line no-shadow
  (widget, profileType) => clickView(widget, profileType, props.entity)
  }
      title={title}
      date={date}
      widget={widget}
      permissionToEdit={permissionToEdit}
    />

  );
};

WidgetMenuButton.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  widget: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  clickSendingCommandsView: PropTypes.func,
  permissionToEdit: PropTypes.bool,
  entity: PropTypes.string,
};

WidgetMenuButton.defaultProps = {
  title: '',
  date: '',
  widget: {},
  clickView: () => {},
  clickSendingCommandsView: () => {},
  permissionToEdit: undefined,
  entity: '',
};

export default WidgetMenuButton;
