import React from 'react';
import './styles.scss';
import { getHash } from '../../helpers/utils';

class TabComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.selectedtab || 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (prevProps.id !== id) {
      this.handleOnClick(0);
    }
  }

    handleOnClick = (index) => {
      const { onChange } = this.props;
      const { activeTab } = this.state;

      if (index !== activeTab) {
        this.setState({
          activeTab: index,
        });
        onChange && onChange(index);
      }
    }

    render() {
      const { children, titles, id } = this.props;
      const { activeTab } = this.state;

      const childrenArray = Array.isArray(children) ? children : [children];
      return (
        <div className="tabWrapper" id={id}>
          <nav>
            <ul>
              {titles?.length > 0 && titles.map((title, i) => title && (
              <li
                key={getHash(`${title}-${i}`)}
                active={activeTab === i ? 'true' : 'false'}
                className={activeTab === i ? 'active' : ''}
                onClick={() => this.handleOnClick(i)}
              >

                {title}

              </li>
              ))}
            </ul>
          </nav>

          {childrenArray.map((child, i) => activeTab === i
          && <div key={getHash(`${child}-${i}`)}>{child}</div>)}
        </div>
      );
    }
}

TabComponent.defaultProps = {
  titles: [],
  id: '',
  selectedtab: 0,
  onChange: () => {},
};

export default TabComponent;
