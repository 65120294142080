export const GET_USERSGROUPS_REQUEST = 'GET_USERSGROUPS_REQUEST';
export const GET_USERSGROUPS_SUCCESS = 'GET_USERSGROUPS_SUCCESS';
export const GET_USERSGROUPS_ERROR = 'GET_USERSGROUPS_ERROR';

export const GET_USERSGROUP_REQUEST = 'GET_USERSGROUP_REQUEST';
export const GET_USERSGROUP_SUCCESS = 'GET_USERSGROUP_SUCCESS';
export const GET_USERSGROUP_ERROR = 'GET_USERSGROUP_ERROR';

export const ADD_USERSGROUP_REQUEST = 'ADD_USERSGROUP_REQUEST';
export const ADD_USERSGROUP_SUCCESS = 'ADD_USERSGROUP_SUCCESS';
export const ADD_USERSGROUP_ERROR = 'ADD_USERSGROUP_ERROR';
export const ADD_USERSGROUP_SUCCESS_FLAG = 'ADD_USERSGROUP_SUCCESS_FLAG';

export const MODIFY_USERSGROUP_REQUEST = 'MODIFY_USERSGROUP_REQUEST';
export const MODIFY_USERSGROUP_SUCCESS = 'MODIFY_USERSGROUP_SUCCESS';
export const MODIFY_USERSGROUP_ERROR = 'MODIFY_USERSGROUP_ERROR';
export const MODIFY_USERSGROUP_SUCCESS_FLAG = 'MODIFY_USERSGROUP_SUCCESS_FLAG';

export const DELETE_USERSGROUP_REQUEST = 'DELETE_USERSGROUP_REQUEST';
export const DELETE_USERSGROUP_SUCCESS = 'DELETE_USERSGROUP_SUCCESS';
export const DELETE_USERSGROUP_ERROR = 'DELETE_USERSGROUP_ERROR';
export const DELETE_USERSGROUP_SUCCESS_FLAG = 'DELETE_USERSGROUP_SUCCESS_FLAG';

export const ADD_USER_TO_USERSGROUP_REQUEST = 'ADD_USER_TO_USERSGROUP_REQUEST';
export const ADD_USER_TO_USERSGROUP_SUCCESS = 'ADD_USER_TO_USERSGROUP_SUCCESS';
export const ADD_USER_TO_USERSGROUP_ERROR = 'ADD_USER_TO_USERSGROUP_ERROR';
export const ADD_USER_TO_USERSGROUP_SUCCESS_FLAG = 'ADD_USER_TO_USERSGROUP_SUCCESS_FLAG';

export const DELETE_USER_FROM_USERSGROUP_REQUEST = 'DELETE_USER_FROM_USERSGROUP_REQUEST';
export const DELETE_USER_FROM_USERSGROUP_SUCCESS = 'DELETE_USER_FROM_USERSGROUP_SUCCESS';
export const DELETE_USER_FROM_USERSGROUP_ERROR = 'DELETE_USER_FROM_USERSGROUP_ERROR';
export const DELETE_USER_FROM_USERSGROUP_SUCCESS_FLAG = 'DELETE_USER_FROM_USERSGROUP_SUCCESS_FLAG';


export const ERRORFETCHING_USERSGROUP_RESET = 'ERRORFETCHING_USERSGROUP_RESET';
