import React from 'react';

import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import { injectIntl } from 'react-intl';
import Field from '../../../../../elements/Field/index';

import ColorPicker from '../../../../../components/ColorPicker';
import { ButtonCustom } from '../../../../../elements/Button';
import ContentEditable from '../../../../helpers/ContentEditable/ContentEditable';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

export const Buttons = ({ onClick, type }) => (
  <div className="buttons">
    <ButtonCustom handleOnClick={() => onClick()} className="btn btn-secondary" label="-" />
  </div>
);

class condition extends React.PureComponent {
  constructor(props) {
    super({ props });
    this.state = {
      colors: '#ff00ff',
      condition: props.condition ?? {
        id: null,
        from: '',
        to: '',
        color: '',
        name: 'Nuevo Grupo',
      },
      lastIteration: props.lastIteration,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.condition) !== JSON.stringify(this.props.condition)) {
      this.setState({ condition: this.props.condition, lastIteration: this.props.lastIteration });
    } else if (prevProps.lastIteration !== this.props.lastIteration) {
      this.setState({ lastIteration: this.props.lastIteration });
    }
  }

    onChangeTo = (e) => {
      const condition = { ...this.state.condition };
      condition.to = e.target.value;
      this.setState({ condition });
      this.props.updateCondition(condition);
    }

    onChangeFrom = (e) => {
      const condition = { ...this.state.condition };
      condition.from = e.target.value;
      this.setState({ condition });
      this.props.updateCondition(condition);
    }

    onChangeName = (id, data) => {
      const condition = { ...this.state.condition };
      condition.name = data;
      this.setState({ condition });
      this.props.updateCondition(condition);
    }

    handleChange = (color, name) => {
      const { onChangeSelect } = this.props;
      const condition = { ...this.state.condition };
      condition.color = color.hex;
      this.setState({ condition });
      this.props.updateCondition(condition);
    }

    render() {
      const {
        colors, condition, lastIteration,
      } = this.state;

      return (
        <div>
          <ContentEditable
            className="labelFocus"
            tag="at"
            onBlur={this.onChangeName}
            id="ConditionNAme"
            editable
          >
            <span className="spanEditable">{condition.name || 'Nombre Grupo'}</span>
          </ContentEditable>
          <div className="conditionContainer">
            <div className="fromContainer">
              <Field
                innerLabel="#F9FAFC"
                label={(
                  <FormattedMessage
                    id="widgetTable.inRangeEnd"
                  />
)}
                className="field"
                name="from"
                onBlur={this.onChangeFrom}
                value={condition.from}
                type="number"
                error={!!(condition.errors && condition.errors.from)}
                helperText={condition.errors ? condition.errors.from : undefined}
              />
            </div>

            <span className="bar">/</span>
            <div className="toContainer">
              <Field
                innerLabel="#F9FAFC"
                label={(
                  <FormattedMessage
                    id="widgetTable.inRangeStart"
                  />
)}
                className="field"
                name="to"
                onBlur={this.onChangeTo}
                value={condition.to}
                type="number"
                error={!!(condition.errors && condition.errors.to)}
                helperText={condition.errors ? condition.errors.to : undefined}
              />
            </div>

            <div className="colorPickerMap">
              <ColorPicker
                style={{ 'margin-top': '20px', 'border-radius': '5px' }}
                name="prueba"
                onChangeComplete={this.handleChange}
                color={condition.color ? condition.color : ''}
                label="Color"
              />
            </div>
            <Buttons
              className="button"
              type="-"
              onClick={() => this.props.removeCondition(this.state.condition)}
            />

          </div>
        </div>
      );
    }
}

export default injectIntl(condition);
