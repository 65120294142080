import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { validate } from 'validate.js';
import CommonView from '../../../CommonView';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import './widgetTypeStyle.scss';
import widgets from '../../../../models/WidgetV2/inherited/index';

export const WidgetItem = ({
  widget, className, active, onClick,
}) => {
  const WidgetIcon = widget.widgetIcon;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${className} ${active ? 'active' : ''}`}
      onClick={() => onClick(widget.widgetType)}
    >
      <div className="icon">
        <WidgetIcon className={active ? 'item-widget selected' : 'item-widget'} />
        <span className="name">
          <FormattedMessage
            id={`widgets.wizard.widgetType.${widget.widgetType.toLowerCase()}`}
          />
        </span>
      </div>
    </div>
  );
};

class WidgetTypeV2 extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      widgetType: undefined,
      errors: {},
      origins: [...this.props.newEntity.origins],
      prevWidgetType: this.props.newEntity.widgetType || undefined,
    };
  }

  validate = () => {
    const constraints = {};
    const { widgetType, origins } = this.state;

    constraints.widgetType = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="widgets.wizard.validation.widget.empty" />,
      },
    };

    const validation = validate({ widgetType }, constraints);

    if (validation) {
      this.setErrors(validation);
      if (validation.widgetType) {
        this.props.onError(validation.widgetType);
      } else {
        this.props.onError('');
      }
    } else {
      this.props.onContinue({ widgetType, origins, errors: '' });
    }
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  handleSelectType = (widgetType) => {
    if (widgetType !== this.state.prevWidgetType) {
      this.setState({
        widgetType,
        prevWidgetType: widgetType,
        origins: [],
      }, () => this.props.extra(widgetType));
    } else {
      this.setState({ widgetType }, () => this.props.extra(widgetType));
    }
  }

  getAvailableWidgets = () => Object.values(widgets);

  render() {
    return (
      <>
        <Container>
          <div className="addWidget">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <div className="widgetsGrid justify-content-center">
                  {this.getAvailableWidgets().map((widget) => (
                    <WidgetItem
                      key={`${widget.widgetType}`}
                      className="widget available wizard-widget p-4"
                      widget={widget}
                      active={this.state.widgetType === widget.widgetType}
                      onClick={(widgetName) => this.handleSelectType(widgetName)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

export default withWizardStep(injectIntl(WidgetTypeV2));
