import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import './styles.scss';

class StatusIndicator extends Component {
  static propTypes = {
    /** True, if the status is active; false, if it is inactive */
    status: PropTypes.bool
  };

  getClass = () => {
    const { status } = this.props;
    return status ? 'active' : 'inactive';
  };

  getText = () => {
    const { status } = this.props;
    return status ? (
      <FormattedMessage id={'rules.list.status.active'} />
    ) : (
      <FormattedMessage id={'rules.list.status.inactive'} />
    );
  };

  render() {
    return (
      <div className={`statusComponent ${this.getClass()}`}>
        <span className="statusPoint"></span>
        <span className="text">{this.getText()}</span>
      </div>
    );
  }
}

export default StatusIndicator;
