import React from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';

import { ReactComponent as OffStatus } from '../../../../../../configuration/icons/svg/ico-off-status.svg';
import { ReactComponent as OnStatus } from '../../../../../../configuration/icons/svg/ico-on-status.svg';

const ButtonOnOffProfile = ({
  stateButton, commandsNumber, showRow, handleShowRow,
}) => (
  <Col sm={12}>
    <Row>
      <Col sm={12} className="pr-0 align-content-start">
        <Col className="mb-4">
          {!stateButton.state && (
          <span>
            <FormattedMessage id="widgets.wizard.sendCommand.state" />
          </span>
          )}
        </Col>

        <Row sm={12} className="pb-3">
          <Col sm={1}>
            {stateButton.state ? <OnStatus /> : <OffStatus />}
          </Col>
          <Col sm={2} className="ml-4 ml-4 angleStyle d-flex justify-content-center" onClick={handleShowRow}>
            <i className={`uil uil-angle-${showRow ? 'down' : 'up'} i blockToggleIcon`} />
          </Col>

          {!showRow
                && (
                <Col sm={8}>
                  <div className="foldedCommands">
                    <p className="commandGroupLine">
                      <span className="commandLabelList">
                        <FormattedMessage id="widgets.wizard.sendCommand.listCommands" />
                      </span>
                      <span className="ml-2 commandNumberOfCommands">
                        {commandsNumber()}
                      </span>
                    </p>
                  </div>
                </Col>
                )}

        </Row>

      </Col>
    </Row>
  </Col>
);
export default injectIntl(ButtonOnOffProfile);
