import React from 'react';
import { injectIntl } from 'react-intl';
import Field from '../../elements/Field';
import SaveButton from '../../elements/SaveButton';
import { FormattedMessage } from '../../Contexts/LanguageContext';
import AddCommandValueHoc from '../../pages/helpers/Add/components/AddCommandValue';
import Device from '../../models/Device';

const Footer = (hideModal, addValueCommandOptions) => {
  const ButtonOpenSendCommand = ({ showModal }) => (
    <li onClick={showModal}>
      <FormattedMessage id="devices.addValueCommand.text" />
    </li>
  );
  const AddCommandValueDOM = AddCommandValueHoc(ButtonOpenSendCommand, { class: Device, nameData: 'devices' });

  return <AddCommandValueDOM rowContent={{}} addValueCommandOptions={addValueCommandOptions} />;
};

class InsertableTr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actualDataKey: this.props.addRowData.property || null,
      actualType: this.props.addRowData.type || null,
      addRowData: { ...this.props.addRowData },
      onValidation: false,
      data: [],
      index: 1,
    };
  }

  componentDidUpdate() {
    if (
      this.state.onValidation
      && Object.keys(this.props.errors).length === 0
    ) {
      this.setState({
        onValidation: false,
        addRowData: this.props.addRowData,
        actualDataKey: null,
        actualKey: null,
      });
    }
  }

  resetAddRowData = (value) => {
    const addRowData = { ...this.state.addRowData };
    this.setState({
      actualType: null,
    });
    this.props.header.forEach((h) => {
      if (value === 'command' && h.key === 'value') addRowData[h.key] = [];
      else addRowData[h.key] = '';
    });

    addRowData[this.props.dataKey] = value;

    return addRowData;
  };

  handleSelectChange = (name, value) => {

    if (name === this.props.dataKey) {
      this.setState({
        actualDataKey: value,
        addRowData: this.resetAddRowData(value),
        data: [],
      });
    } else {
      const addRowData = { ...this.state.addRowData };
      addRowData[name] = value;
      this.setState({
        addRowData,
        actualType: name === 'type' ? value : this.state.actualType,
      });
    }

    this.props.handleSelectChange(name, value);
  };

  handleInputChange = (e) => {

    const addRowData = { ...this.state.addRowData };

    addRowData[e.target.name] = e.target.value;

    this.setState({
      addRowData,
    });
    this.props.handleInputChange(e);
  };

  handleOnClickAddButton = () => {
    this.props.handleOnClickAddButton();
    this.setState({
      actualType: null,
      onValidation: true,
    });
  };

  getOptions = (header) => {
    if (header.type === 'select') {
      if (header.key === 'property') return header.data;

      if (header.data[this.state.actualType]) return header.data[this.state.actualType];

      if (header.data[this.state.actualDataKey]) return header.data[this.state.actualDataKey];

      return [];
    }
    return null;
  };

  isPropertyHeader = (headerkey) => headerkey === 'property';

  isPropertySelectedAttribute = (propertyFields, headerkey) => propertyFields.includes(headerkey);

  isEnabled = (subtypesFieldsGlobal, subtypesFields, propertyFields, headerkey) => {
    const { actualDataKey, actualType } = this.state;

    if (this.isPropertyHeader(headerkey)) {
      return false;
    }
    if (!actualDataKey) {
      return true;
    }

    if (!actualType) {
      return !this.isPropertySelectedAttribute(propertyFields.filter((x) => !subtypesFields.includes(x)), headerkey);
    }
    const filterSubtypes = subtypesFieldsGlobal.filter((x) => !subtypesFields.includes(x));
    return !(this.isPropertySelectedAttribute(propertyFields.filter((x) => !filterSubtypes.includes(x)), headerkey));
  };

  getType(typeAttribute, header) {
    if (typeAttribute === 'command' && header.key === 'value') {
      return 'multiselect';
    }

    return header.type || 'text';
  }

  getOnchange = (type) => {
    if (type === 'select' || type === 'multiselect') return this.handleSelectChange;
    return this.handleInputChange;
  }

  addValueCommandOptions = (option) => {
    const data = [...this.state.data, option];
    const addRowData = { ...this.state.addRowData };
    addRowData.value.push(option);
    this.setState({ data, addRowData });
    this.handleSelectChange('value', addRowData.value);
  };

  isValueCommand = (typeAttribute, header) => typeAttribute === 'command' && header.key === 'value';

  render() {
    const {
      header, errors, intl, modelOfData, className, style,
    } = this.props;
    const {
      actualDataKey, addRowData, actualType, data,
    } = this.state;
    const propertyFields = actualDataKey
      ? modelOfData.find((d) => d.value === actualDataKey).properties
      : header;

    let subtypesFields;
    const subtypesFieldsGlobal = actualDataKey && modelOfData
      .find((d) => d.value === actualDataKey).subtypes
      ? modelOfData.find((d) => d.value === actualDataKey).subtypes : [];
    if (actualType) {
      subtypesFields = actualDataKey
          && modelOfData
            .find((d) => d.value === actualDataKey)
            .type.find((t) => t.value === actualType).subtypes ? modelOfData
          .find((d) => d.value === actualDataKey)
          .type.find((t) => t.value === actualType).subtypes.map((s) => s.value)
        : [];
    } else {
      subtypesFields = actualDataKey && modelOfData
        .find((d) => d.value === actualDataKey).subtypes
        ? modelOfData.find((d) => d.value === actualDataKey).subtypes : [];
    }

    return (
      <tr className={`${className || ''} insertabletr`} style={style}>
        {header.map((h) => (
          <td key={`td-${h.key}`}>
            <Field
              name={h.key}
              type={this.getType(actualDataKey, h)}
              id={h.key}
              placeholder={intl.formatMessage({ id: h.label.props.id })}
              value={addRowData[h.key]}
              onChange={this.getOnchange(this.getType(actualDataKey, h))}
              helperText={errors && errors[h.key]}
              error={errors && !!errors[h.key]}
              options={this.getOptions(h)}
              selectedOptions={addRowData[h.key]}
              data={data}
              mappedBy="name"
              footer={this.isValueCommand(actualDataKey, h)
                ? Footer(true, this.addValueCommandOptions) : null}
              disabled={
                  this.isEnabled(subtypesFieldsGlobal, subtypesFields, propertyFields, h.key)
                }
            />
          </td>
        ))}
        <td>
          {actualDataKey && (
            <SaveButton onClick={this.handleOnClickAddButton} />
          )}
        </td>
        {/* Dont remove that SPAN. is important for style */}
        <span />
      </tr>
    );
  }
}

export default injectIntl(InsertableTr);
