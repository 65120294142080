import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { getLanguageFromBrowser } from '../../helpers';
import localeLanguages from './translations/localeLang';

const defaultValue = 'valor por defecto';
addLocaleData([...localeLanguages]);

/** Available Languages ******************************** */

// Get All JSON Files. One per language allowed
const JSONFilesFromTranslationFolder = require('./helpers');

const Messages = JSONFilesFromTranslationFolder.get_all_JSON_files();
const availableLanguages = Object.keys(Messages);

/** Context Creation. Can be in another external file */
export const Context = React.createContext(defaultValue);

/** Creating Provider */
class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.functions = {
      changeLanguage: this.handleLanguage,
    };

    this.state = {
      currentLanguageName: getLanguageFromBrowser() || 'en',
      availableLanguage: availableLanguages,
      ...this.functions,
    };
  }

    handleLanguage = (currentLanguageName) => {
      const { currentLanguageName: clnState } = this.state;
      if (clnState !== currentLanguageName) {
        this.setState({ currentLanguageName: clnState });
      }
    };

    render() {
      /** Filtering Props * */
      const { children } = this.props;

      /** Filtering State * */
      const { currentLanguageName } = this.state;

      /** Logic in render * */
      const message = Messages[currentLanguageName];

      return (
        <Context.Provider value={{ ...this.state }}>
          <IntlProvider locale={currentLanguageName} messages={message}>
            { children }
          </IntlProvider>
        </Context.Provider>
      );
    }
}
export default Provider;
