export const ADD_WIDGET_REQUEST = 'ADD_WIDGET_REQUEST';
export const ADD_WIDGET_SUCCESS_FLAG = 'ADD_WIDGET_SUCCESS_FLAG';
export const ADD_WIDGET_ERROR = 'ADD_WIDGET_ERROR';
export const ADD_WIDGET_SUCCESS = 'ADD_WIDGET_SUCCESS';

export const MODIFY_WIDGET_REQUEST = 'MODIFY_WIDGET_REQUEST';
export const MODIFY_WIDGET_SUCCESS_FLAG = 'MODIFY_WIDGET_SUCCESS_FLAG';
export const MODIFY_WIDGET_SUCCESS = 'MODIFY_WIDGET_SUCCESS';
export const MODIFY_WIDGET_ERROR = 'MODIFY_WIDGET_ERROR';

export const DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_ERROR = 'DELETE_WIDGET_ERROR';
export const DELETE_WIDGET_IN_PARENT_WIDGET_SUCCESS = 'DELETE_WIDGET_IN_PARENT_WIDGET_SUCCESS';

export const GET_WIDGET = 'GET_WIDGET';
export const GET_WIDGET_REQUEST = 'GET_WIDGET_REQUEST';
export const GET_WIDGET_ROLES_REQUEST = 'GET_WIDGET_ROLES_REQUEST';
export const GET_WIDGET_SUCCESS = 'GET_WIDGET_SUCCESS';
export const GET_WIDGET_ERROR = 'GET_WIDGET_ERROR';

export const GET_WIDGETS_REQUEST = 'GET_WIDGETS_REQUEST';
export const GET_WIDGETS_ERROR = 'GET_WIDGETS_ERROR';
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS';

export const GET_MINE_WIDGETS_REQUEST = 'GET_MINE_WIDGETS_REQUEST';
export const GET_MINE_WIDGETS_ERROR = 'GET_MINE_WIDGETS_ERROR';
export const GET_MINE_WIDGETS_SUCCESS = 'GET_MINE_WIDGETS_SUCCESS';

export const GET_PUBLIC_WIDGETS_REQUEST = 'GET_PUBLIC_WIDGETS_REQUEST';
export const GET_PUBLIC_WIDGETS_ERROR = 'GET_PUBLIC_WIDGETS_ERROR';
export const GET_PUBLIC_WIDGETS_SUCCESS = 'GET_PUBLIC_WIDGETS_SUCCESS';

export const CLONE_WIDGET_REQUEST = 'CLONE_WIDGET_REQUEST';
export const CLONE_WIDGET_ERROR = 'CLONE_WIDGET_ERROR';
export const CLONE_WIDGET_SUCCESS = 'CLONE_WIDGET_SUCCESS';

export const ERRORFETCHING_WIDGET_RESET = 'ERRORFETCHING_WIDGET_RESET';


