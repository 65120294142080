/* eslint-disable import/no-cycle */
import Moment from 'moment';
import { sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getTimeFromHistorical } from '../../../utils';
import { getLanguageFromBrowser } from '../../../../../helpers';
import { getResolutionByDateRange, getResolution } from '../../../../../helpers/samplingHistorical';

export default function formatToLineWidget(
  historicalValues,
  configuration,
  linkedOperation,
  simpleWidget,
  operation = 'avg',
) {
  const data = { series: [], legendX: 'Fecha', legendY: 'Valor' };
  if (!historicalValues) return data;
  const aggregatesHistoricalValues = historicalValues.filter((h) => h.entities);
  const deviceNames = [];
  Moment.locale(getLanguageFromBrowser() || 'en');
  const sourcesWithHistorical = {};
  aggregatesHistoricalValues.forEach((historical) => {
    historical.entities.forEach((entity) => {
      if (!sourcesWithHistorical[entity.urn]) {
        sourcesWithHistorical[entity.urn] = {};
      }
      if (sourcesWithHistorical[entity.urn][historical.origin]) {
        const { attrName, points } = entity;
        sourcesWithHistorical[entity.urn][historical.origin].attributes.push({ attrName, points });
      } else {
        const { attrName, points, ...rest } = entity;
        sourcesWithHistorical[entity.urn][historical.origin] = {
          ...rest,
          attributes: [{ attrName, points }],
        };
      }
    });
  });
  Object.keys(sourcesWithHistorical).forEach((urn) => {
    const filter = sourcesWithHistorical[urn];

    const attributes = [];
    Object.keys(filter).forEach((origin) => {
      filter[origin].attributes.forEach((attr) => {
        let name = `${filter[origin].entityId}-${attr.attrName}`;
        const index = attributes.findIndex((deviceName) => deviceName.name === name);
        if (index > -1) {
          name = `${deviceNames[index].name}-${deviceNames[index].number}`;
          deviceNames[index].number += 1;
          attributes[index].data = { ...attributes[index].data, [origin]: attr.points };
        } else {
          deviceNames.push({ name, number: 1 });
          attributes.push({
            name,
            attribute: attr.attrName,
            data: { [origin]: attr.points },
            entityId: filter[origin].entityId,
          });
        }
      });
    });
    attributes.forEach((attr) => {
      const rowData = {
        attribute: attr.attribute,
        device_id: linkedOperation && simpleWidget ? `random-${uuidv4()}` : attr.entityId,
        name: attr.name,
        type: 'line',
        showSymbol: false,
        hoverAnimation: false,
        data: [],
      };
      Object.keys(attr.data).forEach((attrData) => {
        attr.data[attrData].forEach((point) => {
          const resolution = configuration.data.type === 'historical'
            ? getResolutionByDateRange(configuration.data.startDate, configuration.data.endDate)
            : getResolution(configuration.data.sampling);
          const xTime = getTimeFromHistorical(resolution, attrData, point.offset);
          rowData.data.push({
            name: attr.name,
            value: [xTime, Number.parseFloat(point[operation]).toFixed(2)],
            offset: point.offset,
            samples: point.samples ?? 0,
          });
        });
      });
      rowData.data = sortBy(rowData.data, (p) => new Date(p.value[0]).getTime());
      data.series.push(rowData);
    });
  });
  const dataSorted = {
    ...data,
    series: data.series.map((serie) => {
      const newSerie = { ...serie };
      newSerie.data = sortBy(newSerie.data, (d) => new Date(d.value[0]).getTime());
      return newSerie;
    }),
  };
  return dataSorted;
}
