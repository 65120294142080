import React from 'react';
import ReactDOM from 'react-dom';
import Options from './Options';
import { OptionSelected, SelectStyle, ListOfOptions } from './AuxiliarStyles';
import ExpandIconHOC from '../../../components/ExpandIcon';
import CardHoc from '../../Card';
import Finder from '../../../components/Finder';

// eslint-disable-next-line react/jsx-props-no-spreading
const Card = CardHoc(Finder((props) => <Options {...props} />));

class Select extends React.Component {
  SelectRef = null;

  constructor(props) {
    super(props);
    const {
      open, translateChild, options, mappedBy, loading,
    } = props;
    this.state = {
      open,
      translateChild,
      options,
      mappedBy: mappedBy || 'name',
      loading: loading || false,
      coords: this.getCoords(),
    };

    this.optionsRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener(
      'scroll',
      () => this.setState({ coords: this.getCoords() }),
      true,
    );
    window.addEventListener(
      'resize',
      () => this.setState({ coords: this.getCoords() }),
      true,
    );
    this.setState({ coords: this.getCoords() });
  }

  componentDidUpdate(prevProps, prevState) {
    const { async, options } = this.props;
    const { open } = this.state;
    if (open !== prevState.open) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        translateChild: this.calcChildPosition(),
      });
    }

    if (async && options.length !== prevProps.options.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
        options,
      });
    }
  }

  getCoords = () => (this.SelectRef
    // eslint-disable-next-line react/no-find-dom-node
    ? ReactDOM.findDOMNode(this.SelectRef).getBoundingClientRect()
    : null);

  handleLister = (e) => {
    const { open } = this.state;
    const path = e.path || (e.composedPath && e.composedPath());
    if (
      open
      && !path.find((p) => p.classList && p.classList.contains('selectOptions'))
    ) {
      this.setState({ open: false });
      window.removeEventListener('click', this.handleLister, true);
    }
  };

  handleEvent = () => {
    const { open } = this.state;
    if (open) window.addEventListener('click', this.handleLister, true);
  };

  handleOpenSelect = () => {
    const {
      async, fetchInfo, disabled, blocked,
    } = this.props;
    const { loading, options, open } = this.state;
    const coords = this.getCoords();
    if (!disabled && !blocked) {
      this.setState(
        {
          open: !open,
          coords,
        },
        () => {
          this.handleEvent();
          if (async && !loading && options.length <= 7) {
            fetchInfo();
            this.setState({
              loading: true,
            });
          }
        },
      );
    }
  };

  handleOnChange = (e, option) => {
    const {
      onChange,
      value,
      name,
      noClose,
    } = this.props;
    if (option.value === value) {
      onChange(e, name, undefined);
    } else {
      onChange(e, name, option.value);
    }
    if (!noClose) {
      this.setState({
        open: false,
      });
    }
  };

  calcChildPosition = () => {
    const { openDirection } = this.props;
    if (this.SelectRef) {
      if (openDirection === 'down') return this.SelectRef.clientHeight;
      if (openDirection === 'up') return -this.SelectRef.querySelector('.select-panel').clientHeight;
    }

    return 0;
  };

  handleScroll = (e) => {
    const { loading, fetchInfo } = this.props;

    let dif = e.target.scrollHeight - Math.trunc(e.target.scrollTop);

    if ((dif >= e.target.clientHeight && dif<= e.target.clientHeight + 3) && !loading) {
      fetchInfo();
      this.setState({
        loading: true,
      });
    }
  };

  getSelectedOption = (SelectedOption) => {
    const { customSelectedOption: CustomSelectedOption } = this.props;
    const { mappedBy } = this.state;
    if (CustomSelectedOption) {
      return (
        <CustomSelectedOption selectedOption={SelectedOption} />
      );
    }
    return SelectedOption[mappedBy];
  };

  getHeight = () => {
    const { coords } = this.state;
    const gap = window.innerHeight + window.pageYOffset - coords.bottom;
    return gap < 300 ? gap : null;
  }

  render() {
    const {
      id,
      value,
      css,
      options,
      async,
      placeholder,
      openDirection,
      label,
      hPadding,
      vPadding,
      customOptions,
      loading,
      onSearch,
      auxItem,
      helperText,
      filter,
      check,
      footerWithAction,
      showModal,
    } = this.props;

    const {
      open,
      translateChild,
      coords,
    } = this.state;
    let SelectedOption = null;

    options.forEach((child) => {
      if (JSON.stringify(child.value) === JSON.stringify(value)) SelectedOption = child;
    });

    const OptionSelectedDOM = ExpandIconHOC(OptionSelected);

    return (
      <>
        <SelectStyle
          id={id}
          translateChild={translateChild}
          css={css}
          onClick={this.handleOpenSelect}
          // eslint-disable-next-line no-return-assign
          ref={(node) => (this.SelectRef = node)}
        >
          <OptionSelectedDOM
            isOpen={open}
            revert={openDirection === 'up'}
          >
            {SelectedOption
              ? this.getSelectedOption(SelectedOption)
              : placeholder || label}
          </OptionSelectedDOM>
        </SelectStyle>

        {open
          && ReactDOM.createPortal(
            <ListOfOptions
              ref={this.optionsRef}
              top={coords.top + window.pageYOffset}
              left={coords.left}
              width={this.SelectRef.clientWidth}
              hPadding={hPadding}
              vPadding={vPadding}
              height={this.SelectRef.clientHeight}
              listHeight={this.getHeight()}
              className="selectOptions"
            >
              {!customOptions ? (
                <Card
                  selected={value}
                  handleOnChange={this.handleOnChange}
                  data={options}
                  onScroll={async ? this.handleScroll : () => { }}
                  loading={loading}
                  onSearch={onSearch}
                  auxItem={auxItem}
                  error={helperText}
                  filter={filter}
                  footerWithAction={footerWithAction}
                  check={check}
                  onClick={showModal}
                />
              ) : (
                <this.props.customOptions
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...this.props}
                  selected={value}
                  handleOnChange={this.handleOnChange}
                  data={options}
                  onScroll={async ? this.handleScroll : () => { }}
                  loading={loading}
                  onSearch={onSearch}
                />
              )}
            </ListOfOptions>,
            document.body,
          )}
      </>
    );
  }
}

Select.defaultProps = {
  // onChange: () => this.onChangeSelect,
  open: false,
  css: '',
  translateChild: 40,
  openDirection: 'down',
  async: false,
  hPadding: 16,
  vPadding: 10,
};

export default Select;
