import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { categories, Components, getCategory } from '../../utilsConfigurationSheet';

export const SliderConfigurationSheetBasic = {

  description: [
    {
      name: 'description',
      component: Components.textArea,
      categories: [categories.LABELS],
    },
  ],

  commands: [
    {
      name: 'commandsSlider',
      component: Components.commandsSlider,
      categories: [categories.CONDITIONS],
    },
  ],
  colors: [
    {
      name: 'slider',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
    {
      name: 'background',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
  ],

};

export const SliderConfigurationSheet = {

  description: [
    {
      name: 'description',
      component: Components.textArea,
      categories: [categories.LABELS],
    },
  ],

  commands: [
    {
      name: 'commandsSlider',
      component: Components.commandsSlider,
      categories: [categories.CONDITIONS],
    },
  ],
  colors: [
    {
      name: 'slider',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
    {
      name: 'background',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
  ],

};

export const SliderWizardConfig = {
  commandsToShow: [],
  attributesSelected: '',
  commandSelected: '',
  offRowsNumber: 0,
  onRowsNumber: 0,
  availableCommands: [
    {
      state: 1,
      commands: [],
    },
    {
      state: 2,
      commands: [],
    },
  ],
  localErrors: {
    source: [],
    attribute: [],
    command: [],
  },
  showModalCommandsInGroup: false,
  allAttributes: [],
};

export const SliderDefaultTemplateConfig = ['description', 'numberOfPoints'];

export const validationSlider = (config) => {
  const constraints = {};
  SliderDefaultTemplateConfig.forEach((property) => {
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }
    constraints[`${category}.${property}`] = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };
    if (property === 'numberOfPoints') {
      config[category][property].forEach((num, index) => {
        constraints[`${category}.${property}.${index}.commands`] = {
          presence: {
            allowEmpty: false,
            message: <FormattedMessage id="rules.profile.validation.command.slider.empty" />,
          },
        };
      });
    }
  });

  return validate(config, constraints);
};
