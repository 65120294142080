/* eslint-disable import/no-cycle */
import StepWizard from 'react-step-wizard';
import React, { useEffect, useState } from 'react';
import Step1LinkedV2 from './steps/step1V2';
import Step2Linked from './steps/step2';
import Dashboard from '../../../models/Dashboard';
import Step3Linked from './steps/step3';
import Step4Linked from './steps/step4';
import { getDefaultMeasure } from '../../Dashboards/WidgetDefaultMeasures';
import { cloneInstance } from '../../../helpers/utils';
import transitions from './transitions.less';
import { getWidgetModel } from '../../../models/WidgetV2/utils';
import { TYPES, updateState } from '../../Dashboards/ShowV2/reducer';
import childrenModelParser from '../../../models/WidgetV2/inherited/linked/utils/childrenModelsParser';

export const getDefaultAttributeCount = (widget) => {
  const attributes = {};
  const { sources } = widget;
  sources.forEach((source) => {
    source.fields.forEach((attr) => {
      if (attributes[attr]) {
        attributes[attr] += 1;
      } else {
        attributes[attr] = 1;
      }
    });
  });

  return Object.keys(attributes)
    .map((k) => ({
      name: k,
      amount: attributes[k],
      selected: !!widget.config.data.attributeFilter?.some((a) => a === k),
    }));
};

const CreateLinkedWidgetV2 = (props) => {
  const [state] = useState({
    form: {},
    transitions: {
      enterRight: `${transitions.animated} ${transitions.enterRight}`,
      enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
      exitRight: `${transitions.animated} ${transitions.exitRight}`,
      exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
      intro: `${transitions.animated} ${transitions.intro}`,
    },
  });
  const { widget } = props; // Widget is the Linked widget

  const [widgetChild, setWidgetChild] = useState(() => getWidgetModel(widget));
  const [localAttributes, setLocalAttributes] = useState(() => getDefaultAttributeCount(widget));
  const [previewLoaded, setPreviewLoaded] = useState(false);

  const selectWidget = (widgetSelected) => {
    const widgetCopy = cloneInstance(widgetChild);
    widgetCopy.widgetType = widgetSelected.widgetType;
    setWidgetChild(childrenModelParser(widgetCopy));
  };

  const updateWidget = (name, value) => {
    const widgetCopy = cloneInstance(widgetChild);
    widgetCopy[name] = value;
    setWidgetChild(widgetCopy);
  };

  const updateDashboard = (createdWidget) => {
    const dashboardToUpdate = new Dashboard(widget.dashboard);

    if (!dashboardToUpdate.layoutProperties) {
      dashboardToUpdate.layoutProperties = {
        lg: [], md: [], sm: [], xs: [], xxs: [],
      };
    }

    const defaultMeasures = getDefaultMeasure(createdWidget, dashboardToUpdate.layoutProperties);
    const defaultLayoutPropertiesWidgetCreated = {
      lg: [], md: [], sm: [], xs: [], xxs: [],
    };

    const widgetDefaultMeasures = getDefaultMeasure(
      createdWidget,
      defaultLayoutPropertiesWidgetCreated,
    );
    const newWidget = cloneInstance(widget);

    if (!newWidget.config.appearance.layoutProperties) {
      newWidget.config.appearance.layoutProperties = defaultLayoutPropertiesWidgetCreated;
    }
    if (newWidget.config.appearance.layoutProperties.lg.length) {
      // reassign the y property if there is some items on layout.
      const { y: lastY } = newWidget.config.appearance.layoutProperties.lg[newWidget.config.appearance.layoutProperties.lg.length - 1];
      widgetDefaultMeasures.lg = { ...widgetDefaultMeasures.lg, y: lastY + 1 };
      widgetDefaultMeasures.md = { ...widgetDefaultMeasures.md, y: lastY + 1 };
      widgetDefaultMeasures.sm = { ...widgetDefaultMeasures.sm, y: lastY + 1 };
      widgetDefaultMeasures.xs = { ...widgetDefaultMeasures.xs, y: lastY + 1 };
      widgetDefaultMeasures.xxs = { ...widgetDefaultMeasures.xxs, y: lastY + 1 };
    }
    newWidget.config.appearance.layoutProperties = {
      lg: [...newWidget.config.appearance.layoutProperties.lg, widgetDefaultMeasures.lg],
      md: [...newWidget.config.appearance.layoutProperties.lg, widgetDefaultMeasures.md],
      sm: [...newWidget.config.appearance.layoutProperties.lg, widgetDefaultMeasures.sm],
      xs: [...newWidget.config.appearance.layoutProperties.lg, widgetDefaultMeasures.xs],
      xxs: [...newWidget.config.appearance.layoutProperties.lg, widgetDefaultMeasures.xxs],
    };
    dashboardToUpdate.layoutProperties.lg.push(defaultMeasures.lg);
    dashboardToUpdate.layoutProperties.md.push(defaultMeasures.md);
    dashboardToUpdate.layoutProperties.sm.push(defaultMeasures.sm);
    dashboardToUpdate.layoutProperties.xs.push(defaultMeasures.xs);
    dashboardToUpdate.layoutProperties.xxs.push(defaultMeasures.xxs);

    dashboardToUpdate.widgets = dashboardToUpdate.widgets.map((o) => o.id);
    dashboardToUpdate.widgets.push(createdWidget.id);

    newWidget.update(newWidget);
    dashboardToUpdate.update();
  };

  const saveWidget = () => {
    const widgetCopy = {
      name: widgetChild.name,
      config: widgetChild.config,
      widgetType: widgetChild.widgetType,
    };

    widgetCopy.config.data.operation = widgetChild.arithmeticOperation;
    widgetCopy.description = 'description';
    widgetCopy.container = widget.id;
    widgetCopy.permissions_policy = {
      groups: [],
      public: false,
      users: [],
    };
    const widgetToSave = widget.instanceChildren(widgetCopy);

    widgetToSave.save(updateDashboard, widget);
    props.hideModal();
  };

  useEffect(() => {
    if ( !widget.id.includes('preview') ){
      updateState(
        TYPES.setWidgetSection,
        { active: widget },
        props.dispatchState,
        );
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalAttributes(getDefaultAttributeCount(widgetChild));
  }, [widgetChild]);

  const handlePreview = () => {
    setPreviewLoaded(true);
  };
  return (
    <StepWizard className="w-100  overflow-hidden" transitions={state.transitions}>
      <Step1LinkedV2 update={updateWidget} handleSelectWidget={selectWidget} />
      <Step2Linked
        update={updateWidget}
        widgetType={widgetChild.widgetType}
        attributes={localAttributes}
        widgetChild={widgetChild}
      />
      <Step3Linked
        handlePreview={handlePreview}
        update={updateWidget}
        widgetChild={widgetChild}
      />
      <Step4Linked
        previewLoaded={previewLoaded}
        widgetChild={widgetChild}
        newEntity={widget}
        saveWidget={saveWidget}
      />
    </StepWizard>
  );
};

export default CreateLinkedWidgetV2;
