import * as TYPES from './types';
import { Map } from 'immutable';

const initialState = Map({
  token: null,
  refreshToken: 'null',
  expirationDate: 'null',
  me: Map({}),
  fetching: false,
  errorMessages: null
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.LOGIN_REQUEST:
      return state.set('fetching', true).set('errorMessages', null);

    case TYPES.LOGIN_REQUEST_FAILED:
      return state.set('errorMessages', payload.errors).set('fetching', false);

    case TYPES.LOGIN_REQUEST_SUCCESS:
      return state.set('fetching', false);

    case TYPES.LOGOUT_REQUEST_SUCCESS:
      removeUserFromLocalStorage();
      return state.set('fetching', false);

    default:
      return state;
  }
};



const removeUserFromLocalStorage = () => {
  localStorage.clear();
};
