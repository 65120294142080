/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Container, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../CommonView';
import { getDevices, clearDevicesState } from '../../../../services/redux/devices/actions';
import SelectableList from '../../../../components/SelectableList';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';

import StaticListComponent from '../components/staticListComponent';
import { getDefaultConfig, getInjectedData } from '../../../../models/Widget/configurationSheets';

import { categories } from '../../../helpers/Add/CreateEntity';

const filterBy = (arr, type) => arr.filter((item) => item.type === type);
const getFunc = (type) => {
  switch (type) {
    case 'devices':
    default:
      return getDevices;
  }
};

const ENTITY_TYPES = {
  DEVICE: 'Device',
};
class SelectDatasourcesForLinkedWidgetV2 extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      errors: {},
      filter: 'devices',
      devices: this.props.newEntity.sources?.length
        ? filterBy(this.props.newEntity.sources, ENTITY_TYPES.DEVICE)
        : [],
      etls: this.props.newEntity.sources?.length
        ? filterBy(this.props.newEntity.sources, ENTITY_TYPES.ETL)
        : [],
      kpis: this.props.newEntity.sources?.length
        ? filterBy(this.props.newEntity.sources, ENTITY_TYPES.KPI)
        : [],
      page: 1,
      size: 6,
      openedPanels: this.props.newEntity.sources
        ? this.initOpenedStatus(this.props.newEntity.sources)
        : [],
      categorySelected: 'OTHER',
    };
  }

  componentDidMount() {
    const {
      page, size, categorySelected,
    } = this.state;
    this.props.onRef(this);
    clearDevicesState();
      getDevices({ filters: { categories: categorySelected }, page, size });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page, size, filter, categorySelected,
    } = this.state;
    const { fetchingDevices } = this.props;

    if (prevState.categorySelected !== categorySelected) {
      clearDevicesState();
      getDevices({ filters: { categories: categorySelected }, page, size });
    }
    if ((!fetchingDevices && prevState.page < page)) {
      if (filter === 'devices') {
        getDevices({ filters: { categories: categorySelected }, page, size });
      }
    }
  }

  initOpenedStatus = (datasources) => datasources.map(() => false);

  validate = () => {
    const { newEntity } = this.props;
    const { devices, categorySelected } = this.state;
    const { widgetType } = newEntity;
    const category = devices[0].categories[0];
    let nextStep = true;
    newEntity.widgetType = widgetType;
    newEntity.sources = devices;
    newEntity.config = getDefaultConfig(widgetType);
    newEntity.config.data.category = categorySelected[0];
    if (this.state.devices.length) {
      this.state.devices.forEach((devic) => {
        if (devic.categories[0] !== category) {
          nextStep = false;
          this.props.onError(
            <FormattedMessage id="widgets.wizard.different.category.selected" />,
          );
        }
      });
      if (nextStep) {
        const datasources = this.state.devices.map((device) => {
          const newDevice = { ...device };
          newDevice.joinedAttributes = newDevice.joinedAttributes.map(
            (attr) => ({ ...attr, selected: true }),
          );
          return newDevice;
        });
        const newConfig = getInjectedData(newEntity);
        newEntity.config = newConfig;
        this.props.onContinue({
          datasources,
          newEntity,
        });
      }
    } else {
      this.props.onError(
        <FormattedMessage id="widgets.wizard.no.device.selected" />,
      );
    }
  };

  addJoinedAttrs = (sources) => sources.map((source) => (!source.joinedAttributes
    ? {
      ...source,
      joinedAttributes: source.attributes.map((field) => ({
        ...field,
        selected: true,
      })),
    }
    : source));

  handleSelectableListChanges = (data) => {
    const devicesWithoutJoinedAttrs = data.filter(
      (device) => device.type === ENTITY_TYPES.DEVICE,
    );
    const devices = this.addJoinedAttrs(devicesWithoutJoinedAttrs);
    this.setState({ devices });
  };

  handleOnPanelOpen = (index, status) => {
    const openedPanels = [...this.state.openedPanels];
    openedPanels[index] = status;
    this.setState({
      openedPanels,
    });
  };

  handleFetchInfo = () => {
    const { total, devices } = this.props;
    if (total > devices.length) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  };

  handleOnSearch = (value, init = false) => {
    const { size, filter, categorySelected } = this.state;
    filter === 'devices' && init && clearDevicesState();
    const getAction = getFunc(filter);
    getAction({ filters: { name: value, categories: categorySelected }, page: 1, size });
  };

  whichtFilterisLoading = () => {
    const { fetchingDevices } = this.props;
    return fetchingDevices;
  }

  getExcludedDelocalized = (oldList) => oldList.filter((dev) => {
    // const attrs = [
    //   ...dev.attributes,
    //   ...dev.commands];
    return oldList;
  })

  getOptionsWithFilters = () => {
    const { filter } = this.state;
    const completeList = this.getExcludedDelocalized(this.props.devices);
    return completeList && completeList
      .map((o) => ({ name: o.name, id: o.id, value: o }));
  }

  resetSelections = () => {
    this.setState({
      devices: [],
    });
  }

  handleSelectChange = (type, name) => {
    this.setState({ categorySelected: name, page: 1 });
  }

  render() {
    const {
      errors,
      openedPanels,
      devices,
      categorySelected,
    } = this.state;

    const { intl, newEntity } = this.props;

    return (
      <>
        <Container fluid>
          <Row>
            <Col lg={{ size: 12 }}>
              <SelectableList
                label={intl.formatMessage({ id: 'widgetTable.headers.device' })}
                data={this.getOptionsWithFilters()}
                selected={[...devices]}
                name="datasources"
                translatePrefix="widgets.wizard"
                error={errors}
                listComponent={(props) => StaticListComponent(
                  { ...props, trendline: null },
                  this.handleOnPanelOpen,
                  this.resetSelections,
                )}
                onChange={this.handleSelectableListChanges}
                fetchInfo={this.handleFetchInfo}
                loading={this.whichtFilterisLoading()}
                openedPanels={openedPanels}
                onSearch={this.handleOnSearch}
                multiOption={newEntity.hasMultipleSources}
                filterBy="name"
                selectOnTheFly
                filterForOptions={{
                  name: 'categories',
                  value: ["OTHER"],
                  options: categories,
                  disabled: true,
                  error: !!errors.categories,
                  helperText: errors.categories,
                  onChange: (type, name) => this.handleSelectChange(type, name),
                  label: intl.formatMessage({
                    id: 'devices.wizard.categories.label',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'devices.wizard.categories.placeholder',
                  }),
                }}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: Object.values(state
    .get('devices')
    .get('listSources')),
  fetchingDevices: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(SelectDatasourcesForLinkedWidgetV2)));
