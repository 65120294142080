import React from "react"
import { FormattedMessage } from '../Contexts/LanguageContext'

export default ({uri}) => {
        uri = uri || '';
        uri = decodeURI(uri);

        return (

            <FormattedMessage
                id="app.error404.body"
                defaultMessage="{route} is a route that doesn&#39;t exist... the sadness."
                description="Body of 404 error"
                values={{ route: uri }}
                component={'p'}
            />

    );
};
