import Faker from 'faker'
import { exampleFiware, exampleProtocol, exampleTransport, examplePermissionPolicy } from "../redux/templates/reducer"
import { deviceManagement } from '../../configuration/config'
import { requestAPI } from './../../helpers/'
import parseFilters from '../../helpers/parseFilters';

const endPoint = deviceManagement.urlAPI.concat(deviceManagement.paths.template);

const queryPageParam = "x-query-page";
const querySizeParam = "x-query-size";

const exampleBodyFn = (data = null, numberOfTemplateToGenerate = 50) => {
    const TemplatesFaker = [];
    for (let i = 0; i < numberOfTemplateToGenerate; i++) {
        TemplatesFaker.push({
            id: Faker.random.uuid(),
            name: Faker.random.word(),
            permissions_policy: Faker.random.arrayElement(examplePermissionPolicy),
            owner: Faker.random.uuid(),
            entity_type: Faker.random.word(),
            fiware: Faker.random.arrayElement(exampleFiware),
            transport: Faker.random.arrayElement(exampleTransport),
            attributes: [],
            lazy_attributes: [],
            command_attributes: [],
            static_attributes: [],
            internal_attributes: [],
            protocol: Faker.random.arrayElement(exampleProtocol)

        })
    }
    const templates = { templates: TemplatesFaker };
    return templates;
};

/*** First version of Template CRUD */
// PLURAL
export const readTemplates = async (page, size, filters) => {

    const queryPage = page ? `${queryPageParam}=${page}` : '';
    const querySize = size ? `${querySizeParam}=${size}` : '';
    const queryFilters = filters ? parseFilters(filters) : '';

    const params = {
        endPoint: endPoint + '/list/all?'.concat(queryPage, '&', querySize, queryFilters),
        statusOK: 200,
        toJSON: true,
        returnData: 'templates'
    }

    return requestAPI(params, exampleBodyFn);
};

export const readMyTemplates = async (page, size, filters) => {

    const queryPage = page ? `${queryPageParam}=${page}` : '';
    const querySize = size ? `${querySizeParam}=${size}` : '';
    const queryFilters = filters ? parseFilters(filters) : '';


    const params = {
        endPoint: endPoint + '/list/mine?'.concat(queryPage, '&', querySize, queryFilters),
        statusOK: 200,
        toJSON: true,
        returnData: 'templates'
    }
    return requestAPI(params, exampleBodyFn);
};


/*** First version of Template CRUD */
// PLURAL
export const readPublicTemplates = async (page, size) => {

    const queryPage = page ? `${queryPageParam}=${page}` : '';
    const querySize = size ? `${querySizeParam}=${size}` : '';

    const params = {
        endPoint: endPoint + '/list/public?'.concat(queryPage, '&', querySize),
        statusOK: 200,
        toJSON: true,
        returnData: 'templates'
    }
    return requestAPI(params, exampleBodyFn);
};


// SINGULAR
export const readTemplate = async (template) => {

    if (!template['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint + "/" + template.id,
        statusOK: 200,
        toJSON: true,
        returnData: 'template'
    }

    let answer = await requestAPI(params, exampleBodyFn);


    return answer;
};



export const addTemplate = async (template) => {

    if (template['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPoint,
        statusOK: 201,
        toJSON: true,
        returnData: 'template',
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(template),
            headers: {
                'Content-Type': 'application/json',
            }
        }
    }

    return requestAPI(params, exampleBodyFn);
};


export const updateTemplate = (template) => {
    if (!template['id']) return { status: 400, error: 'missing id attr' };

    const params = {
        endPoint: endPoint + "/" + template.id,
        statusOK: 200,
        toJSON: false,
        returnData: template,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify(template),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
};



export const deleteTemplate = (template) => {
    if (!template['id']) return { status: 400, error: 'missing id attr' }
    const params = {
        endPoint: endPoint + "/" + template.id,
        statusOK: 204,
        toJSON: false,
        returnData: template,
        requestConfig: {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
};
