import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { getHome } from '../../services/redux/menuElements/actions';
import Show from '../../pages/Dashboards/ShowV2/';
import Loading from '../../elements/Loading';
import { ExternalPage } from '../../pages/ExternalPage/index';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
    };
  }

  componentDidMount() {
    getHome();
  }

  componentDidUpdate(prevProps) {
    const { fetching: fetchingProp } = this.props;
    if (prevProps.fetching !== fetchingProp) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        fetching: fetchingProp,
      });
    }
  }

  render() {
    const { fetching } = this.state;
    if (fetching) {
      return <Loading />;
    }
    const { homeMenu } = this.props;
    if (homeMenu === undefined) {
      navigate('/app/dashboard');
      return null;
    }
    switch (homeMenu.type) {
      case 'dashboard':
        return <Show id={homeMenu.resource} />;
      case 'external':
      case 'knowage':
        return <ExternalPage externalUrl={homeMenu.resource} />;
      default:
        navigate('/app/dashboard');
        return null;
    }
  }
}

const mapStateToProps = (state) => ({
  notify: state.get('notify'),
  homeMenu: state.get('menuElements').get('homeMenu'),
  fetching: state.get('menuElements').get('fetchingHome'),
});

export default connect(mapStateToProps)(Home);
