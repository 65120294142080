import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

export default (type, config) => {
  const constraintsCeneralSC = {
    state_a: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    },
  };

  const constraintsCommandsSC = {
    device_id: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    },
    command: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    },
    value: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    },
  };

  const values = {};
  const valuesForValidate = {
    state_a: config.state_a,
    commands_a: config.commands_a ?? [],
  };
  values.state_a = {
    label: config.state_a,
    commands: config.commands_a ?? [],
  };
  const errorsCommandsA = [];
  const errorsCommandsB = [];
  valuesForValidate.commands_a.forEach((comandA, i) => {
    const validationCommandsA = validate(comandA, constraintsCommandsSC);
    if (validationCommandsA) {
      errorsCommandsA[i] = validationCommandsA;
    }
  });

  if (config.second_state) {
    values.second_state = true;
    values.state_b = {
      label: config.state_b,
      commands: config.commands_b ?? [],
    };

    valuesForValidate.state_b = config.state_b;
    valuesForValidate.commands_b = config.commands_b;

    constraintsCeneralSC.state_b = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };

    valuesForValidate.commands_b.forEach((comandB, i) => {
      const validationCommandsB = validate(
        comandB,
        constraintsCommandsSC,
      );

      if (validationCommandsB) {
        errorsCommandsB[i] = validationCommandsB;
      }
    });
  }

  return validate(valuesForValidate, constraintsCeneralSC);
};
