import * as TYPES from './types';
import {store} from '../store';


export const deleteNotification = (payload) => store.dispatch({
    type: TYPES.NOTIFY_DELETE,
    payload: payload
});





