import React from 'react';
import { connect } from 'react-redux';
import { TweenMax, Power3, CSSPlugin } from 'gsap/all';
import { Container, Row, Col } from 'reactstrap';
import { withTheme } from '../Contexts/ThemeContext';
import { withMenuCtxt } from '../Contexts/MenuContext';
import Header from '../components/Header';
import PageHeader from '../components/PageHeader';
import FiwooLogo from '../configuration/icons/svg/logo-fiwoo.svg';


import './styles.scss';
import getMe from '../helpers/getMe';
import { getUserMe } from '../services/redux/users/actions';
import WithDrawer from '../components/DrawerContainer/index';
import Profile from '../pages/Users/Users/Profile';
import { enterRoute } from '../configuration/config';
import MenuToggler from '../components/MenuToggler';

const C = CSSPlugin;

const ProfileComponent = WithDrawer(({ onChange, data, ...rest }) => (
  <Profile
    {...rest}
    onChange={onChange}
    data={data}
    enableChangePassword
  />
));

class BasicLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userMe: {
        name: 'Name',
        surname: 'Surname',
        email: 'email@email.com',
      },
      menuIsOpen: true,
    };
    this.containerAside = React.createRef();
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
  }

  async componentDidMount() {
    !this.props.userMe && getUserMe();
    const userMe = this.props.userMe ? this.props.userMe : await getMe();
    this.setState({
      userMe,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.userMe) !== JSON.stringify(this.props.userMe)
    ) {
      this.setState({
        userMe: this.props.userMe,
      });
    }
  }

  handleOpenMenu = () => {
    const ref = this.containerAside.current;
    const state = this.state.menuIsOpen;
    const setNewState = (newState) => this.setState(newState);
    const percent = state ? '-150%' : '0%';
    const position = state ? 'absolute' : 'relative';

    TweenMax.to(ref, 1, {
      onStart() {
        state
          ? setTimeout(() => {
            setNewState({
              menuIsOpen: !state,
            });
          }, 550)
          : setNewState({
            menuIsOpen: !state,
          });
        setTimeout(() => {
          TweenMax.set(ref, {
            position,
            left: 0,
            top: 0,
          });
        }, 550);
      },
      onComplete(){
        state ? 
          setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 550)
        : window.dispatchEvent(new Event('resize'));
      },
      left: 0,
      top: 0,
      x: percent,
      ease: Power3.easeInOut,
    });
  }

  handleOnViewProfileClick = () => {
    this.setState({
      profileOpened: true,
    });
  };

  handleOnProfileEvent = (opened) => {
    this.setState({
      profileOpened: opened,
    });
  };

  render() {
    const {
      children,
      title,
      subtitle,
      path,
      aside,
      subAside,
      menuElementsSelected,
      hiddenSubmenu,
      hiddenPageHeader,
      noPadding,
    } = this.props;
    const { userMe, profileOpened } = this.state;
    const Aside = aside;
    const SubAside = subAside;

    return (
      <>
        <header>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Header
                  logotype={FiwooLogo}
                  userMe={userMe}
                  onViewProfileClick={this.handleOnViewProfileClick}
                  logoRoute={enterRoute}
                  menuElementsSelected={menuElementsSelected}
                  hiddenSubmenu={hiddenSubmenu}
                >
                  {/* <div className="nav-search">

                    <i className="uil uil-search"/>
                  </div> */}
                  <div className="toggler-menu" onClick={this.handleOpenMenu}>
                    <MenuToggler isOpen={this.state.menuIsOpen} />
                  </div>
                </Header>
              </Col>
            </Row>
          </Container>
        </header>
        <main className={noPadding ? 'px-0' : ''}>
          <Container fluid>
            <Row className="justify-content-end">
              <div className="aside-container col-2" ref={this.containerAside}>
                
                <Col
                  id="aside"
                  className={`d-none d-lg-block aside ${!this.state.menuIsOpen ? 'hidden' : ''}`}
                  xs={12}
                  sm={12}
                >
                  {Aside && <Aside />}
                </Col>
              </div>
              <div className="d-none d-lg-block aside">
                {menuElementsSelected.length > 0 && !hiddenSubmenu && (
                  <>{SubAside && <SubAside />}</>
                )}
              </div>
              <Col
                id="content"
                href="content"
                className={`${noPadding ? 'px-0' : ''} container-content ${hiddenPageHeader
                  ? 'no-header-content' : ''} ${!this.state.menuIsOpen ? 'expanded' : ''}`}
                md={{ size: 10 }}
              >
                <Row className={noPadding ? 'mx-0' : ''}>
                  <Col>
                    {!hiddenPageHeader && (
                      <PageHeader
                        css={`
                        ${noPadding ? 'padding-left: 2.5rem' : ''}
                        max-width: 50%;
                      `}
                        title={title}
                        subtitle={subtitle}
                        path={path}
                      />
                    )}
                  </Col>
                </Row>
                <Row className={noPadding ? 'mx-0' : ''}>
                  <Col className={noPadding ? 'w-100 px-0' : 'button-margin'}>{children}</Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {profileOpened && (
            <ProfileComponent
              onChange={this.handleOnProfileEvent}
              data={userMe}
              path={path}
            />
          )}
        </main>

        <footer>
          <Container>
            <Row>
              <Col xs={12} />
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

BasicLayout.defaultProps = {
  title: 'Title from Basic Layout',
  subtitle: null,
  menuIsOpen: false,
  path: 'path/from/basic/layout',
};

const mapStateToProps = (state) => ({
  userMe: state.get('users').get('userMe'),
  menuElementsSelected: state.get('menuElements').get('menuElementsSelected'),
  hiddenSubmenu: state.get('menuElements').get('hiddenSubmenu'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(withMenuCtxt(BasicLayout)));
