import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import {
  addETLProcedure,
  updateETLProcedure,
  deleteETLProcedure,
  getETLProcedure,
} from '../../services/redux/etlProcedures/actions';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

/** Class ETLProcedure with Utils functions */
export default class ETLProcedure extends Utils {
  static entityName = 'ETLProcedure';
  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = ETLProcedure;
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? validation
      : { error: true, ...validation };
  }

  /** Call Redux action for Save ETLProcedure in DB and Redux */
  save() {
    addETLProcedure({
      ...this.getData(),
    });
  }

  /** Call Redux action for Update ETLProcedure in DB and Redux */
  update() {
    const validation = validate(this.getData(), this.constraints);
    return validation === undefined
      ? updateETLProcedure({
        ...this.getData(),
      })
      : { error: true, ...validation };
  }

  delete() {
    const validation = this.validate(['id']);

    return validation === undefined
      ? deleteETLProcedure(this.getData())
      : { error: true, ...validation };
  }

  get() {
    const validation = this.validate(['id']);
    return validation === undefined
      ? getETLProcedure(this.getData())
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('ETLProcedure', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleETLProcedure = new ETLProcedure();
export { SampleETLProcedure };
