import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import { getAlert } from '../../services/redux/alerts/actions';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

/** Class Alert with Utils functions */
export default class Alert extends Utils {
  static entityName = 'Alert';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  get() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? getAlert(this.getData())
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('Alert', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleAlert = new Alert();
export { SampleAlert };
