import * as TYPES from './types'
import { Map, List } from 'immutable';

export const examplePermissionPolicy = ["Group1", "Group2", "Group3", "Group4"];
export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];

// Initial State of Widgets
const initialState = Map({
    list: List([]),
    fetching: false,
    errorFetching: false,
    addSuccess: false,
    modifySuccess: false,
    updatedWidget: false,
});


// WIDGETS REDUCER
export default (state = initialState, { type, payload }) => {

    // Helper functions
    let index = -1;
    let aux;

    // Cases
    switch (type) {

        // INITIALIZE GET WIDGETS FROM DB
        case TYPES.GET_WIDGETS_REQUEST:
            return state.set('fetching', true).set('errorFetching', false);

        // INITIALIZE GET WIDGETS FROM DB
        case TYPES.GET_WIDGETS_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // GET ALL WIDGET FROM DB TO STATE
        case TYPES.GET_WIDGETS_SUCCESS:
            //TODO: Sure it is correct?
            aux = state.set('list', List(payload));
            aux = aux.set('fetching', false);
            return aux;

        // INITIALIZE GET WIDGET FROM DB
        case TYPES.GET_WIDGET_REQUEST:
            aux = state.set('errorFetching', true);
            aux = aux.set('fetching', false);
            return aux;

        // INITIALIZE GET WIDGET FROM DB ERROR
        case TYPES.GET_WIDGET_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // GET WIDGET FROM DB TO STATE
        case TYPES.GET_WIDGET_SUCCESS:
            index = state.get('list').findIndex(u => u.id === payload.id);
            //TODO
            //If doesn't exist in the current state, PUSH. // If it exist ?
            return index === -1 ? state.update('list', widgetList => widgetList.push(payload)) : state;



        case TYPES.GET_MINE_WIDGETS_REQUEST:
            return state.set('fetching', true).set('errorFetching', false);

        /** INITIALIZE GET DASHBOARDS FROM DB */
        case TYPES.GET_MINE_WIDGETS_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        /** GET ALL DASHBOARD FROM DB TO STATE */
        case TYPES.GET_MINE_WIDGETS_SUCCESS:
            //TODO: Sure it is correct?
            aux = state.set('list', List(payload));
            aux = aux.set('fetching', false);
            return aux;



        // get public widgets
        case TYPES.GET_PUBLIC_WIDGETS_REQUEST:
            return state.set('fetching', true).set('errorFetching', false);

        /** INITIALIZE GET DASHBOARDS FROM DB */
        case TYPES.GET_PUBLIC_WIDGETS_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // /** GET ALL DASHBOARD FROM DB TO STATE */
        // case TYPES.GET_PUBLIC_WIDGETS_ERROR:
        //     //TODO: Sure it is correct?
        //     aux = state.set('list', List(payload));
        //     aux = aux.set('fetching', false);
        //     return aux;







        //////// ADD A WIDGET
        // INITIALIZE ADD WIDGET TO DB AND STATE
        case TYPES.ADD_WIDGET_REQUEST:
            return state.set('fetching', true).set('errorFetching', false);

        // INITIALIZE ERROR ADD WIDGET
        case TYPES.ADD_WIDGET_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // ADD A NEW WIDGET TO STATE
        case TYPES.ADD_WIDGET_SUCCESS:
            aux = state.update('list', widgetList => widgetList.push(payload));
            aux = aux.set('added', payload)
            aux = aux.set('fetching', false);
            aux = aux.set('addSuccess', true);
            return aux;

        // SET AS FALSE THE addSuccess STATE FLAG
        case TYPES.ADD_WIDGET_SUCCESS_FLAG:
            return state.set('addSuccess', false);



        //////// UPDATE A WIDGET
        // INITIALIZE UPDATE WIDGET TO DB
        case TYPES.MODIFY_WIDGET_REQUEST:
            return state.set('fetching', true);

        //  UPDATE WIDGET ERROR
        case TYPES.MODIFY_WIDGET_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // UPDATE WIDGET IN STATE
        case TYPES.MODIFY_WIDGET_SUCCESS:
            index = state.get('list').findIndex((u) => u.id === payload.id);
            aux = state.update('list', (dList) => dList.splice(index, 1, payload));
            aux = aux.set('fetching', false);
            aux = aux.set('updatedWidget', payload);
            aux = aux.set('modifySuccess', true);
            return aux;

        // SET AS FALSE THE modifySuccess STATE FLAG
        case TYPES.MODIFY_WIDGET_SUCCESS_FLAG:
            return state.set('modifySuccess', false);

        //////// DELETE AN WIDGET
        // INITIALIZE DELATION OF A WIDGET IN DB
        case TYPES.DELETE_WIDGET_REQUEST:
            return state.set('fetching', true);

        // INITIALIZE DELATION OF AN WIDGET IN DB
        case TYPES.DELETE_WIDGET_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // DELETE WIDGET IN STATE
        case TYPES.DELETE_WIDGET_SUCCESS:{
            index = state.get('list').findIndex(u => u.id === payload.id);
            aux = index !== -1 ? state.update('list', widgetList => widgetList.delete(index)) : state;
            aux = aux.set('fetching', false);
            return aux;
        }

        case TYPES.DELETE_WIDGET_IN_PARENT_WIDGET_SUCCESS: {
            const parentWidget = state.get('list').find((u) => u.id === payload.container);
            if (parentWidget) {
                index = parentWidget.containedWidgets.findIndex((u) => u.id === payload.id);
                parentWidget.containedWidgets = parentWidget.containedWidgets.filter((o, i) => i !== index);
                const index2 = state.get('list').findIndex((u) => u.id === payload.container);
                aux = state.update('list', (dList) => dList.splice(index2, 1, parentWidget));
                aux = state.update('updatedWidget', parentWidget);
                return aux;
            }
                return state;
        }

        //////// DELETE AN WIDGET
        // INITIALIZE DELATION OF A WIDGET IN DB 
        case TYPES.CLONE_WIDGET_REQUEST:
            return state.set('fetching', true);

        // INITIALIZE DELATION OF AN WIDGET IN DB 
        case TYPES.CLONE_WIDGET_ERROR:
            aux = state.set('errorFetching', payload);
            aux = aux.set('fetching', false);
            return aux;

        // DELETE WIDGET IN STATE 
        case TYPES.CLONE_WIDGET_SUCCESS:
            aux = state.set('cloneWidget', payload);
            aux = aux.set('fetching', false);
            return aux;



        //////// RESET ERROR FETCHING TO FALSE
        // RESET ERROR FETCHING TO FALSE
        case TYPES.ERRORFETCHING_WIDGET_RESET:
            return state.set('errorFetching', false);


        default:
            return state
    }
};
