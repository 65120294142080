import React from 'react'
import validate from 'validate.js';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';

const widgetHTMLValidator = data => {

  return validate({html: data.html}, {
    html: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id={'Validation.presence'} />,
      },
    },
  });
};


export default widgetHTMLValidator;