import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: (<FormattedMessage
          id="Validation.presence"
        />),
      },
      length: {
        minimum: 6,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '6' }}
          />
        ),
      },
    },
  },
  {
    key: 'type',
    defaultValue: '',
  },
  {
    key: 'description',
    defaultValue: '',
  },
  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
    constraints: {
      presence: true,
    },
  },
  {
    key: 'owner',
    defaultValue: '',
    constraints: {
      presence: false,
    },
  },
  {
    key: 'source',
    defaultValue: {},
    constraints: {
      presence: true,
    },
  },
  {
    key: 'data',
    defaultValue: {},
    constraints: {
      presence: true,
    },
  },
  {
    key: 'createdAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
  {
    key: 'updatedAt',
    defaultValue: null,
    constraints: {
      presence: false,
    },
  },
];

export default ModelExpected;
