import * as TYPES from './types';
import { store } from '../store';

export const addService = Service =>
  store.dispatch({
    type: TYPES.ADD_SERVICE_REQUEST,
    payload: Service
  });

export const addServiceSuccessFlag = () =>
  store.dispatch({
    type: TYPES.ADD_SERVICE_SUCCESS_FLAG
  });

export const updateService = Service =>
  store.dispatch({
    type: TYPES.MODIFY_SERVICE_REQUEST,
    payload: Service
  });

export const modifyServiceSuccessFlag = () =>
  store.dispatch({
    type: TYPES.MODIFY_SERVICE_SUCCESS_FLAG
  });

export const deleteService = Service =>
  store.dispatch({
    type: TYPES.DELETE_SERVICE_REQUEST,
    payload: Service
  });

export const getServices = payload =>
  store.dispatch({
    type: TYPES.GET_SERVICES_REQUEST,
    payload: payload
  });

export const getMyServices = payload =>
  store.dispatch({
    type: TYPES.GET_MY_SERVICES_REQUEST,
    payload: payload
  });

export const getService = Service =>
  store.dispatch({
    type: TYPES.GET_SERVICE_REQUEST,
    payload: Service
  });

export const errorFetchingServiceReset = () =>
  store.dispatch({
    type: TYPES.ERRORFETCHING_SERVICE_RESET
  });

export const updateServicesState = payload =>
  store.dispatch({
    type: TYPES.UPDATE_SERVICES_STATE,
    payload: payload
  });

export const clearServicesState = payload =>
  store.dispatch({
    type: TYPES.CLEAR_SERVICES_STATE,
    payload: payload
  });
