import styled from "styled-components";
import colors from "../../../../configuration/colors";

const SelectCtr = styled.div`
  /* box-shadow: inset 0 0px 3px 0px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff,
    0 1px 0 #fff; */
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  //margin-bottom: 15px;

  .selected {
    width: 100%;
    display: flex;
  }

  &:focus {
    outline: none;
  }
`;

const InputSelect = styled.input`
  color: ${colors['ui-Black']};
  font-size: 1.07em;
  line-height: 2.25em;
  margin-left: 13px;
  width: 100%;
  margin-bottom: 0px;
`;

const SelectedWrapper = styled.div`
  width: 80%;
  padding: 0;
  top: 2px;
  display: inline-block;
  align-items: center;
/*   min-height: 2.3em; */
  flex-wrap: nowrap;
  flex: 1;

  & > * {
    flex: 1 1 auto;
  }
`;

const ArrowButton = styled.span`
  justify-self: flex-end;
  align-self: center;
  margin-right: 1%;
`;

const RemoveButton = styled(ArrowButton)`
  &.custom {
    cursor: pointer;
    font-size: 0.8em;
    color: ${colors['ui-Grey1']};
    &:hover{
        transform: scale(1.30);
    }
  }
`;

const SearchButton = styled.span`
  position: absolute;
  top: 14px;
  right: 10px;
  &.custom {
    color: #ccc;
  }
`;

const OptionWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: 1px;
  z-index: 999999999991;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  width: ${p => p.width}px;

  &.hidden {
    opacity: 0;
    display:none;
    transition: opacity 0.2s ease-out;
  }

  &.visible {
    opacity: 1;
    transition: opacity 0.1s ease-out;
    position: absolute;
    display: inline-block;
    overflow: visible;
    background-color: white;
    /* box-shadow: inset 0 0px 3px 0px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff,
      0 1px 0 #fff; */
  }
  & > div {
    border: 1px solid ${colors['ui-LightBG']};
    position: absolute;
    background: white;
    width: 100%;
    z-index: 100;
    left: -1px;
    display: flex;
    flex-direction: column;
    margin-top: 2px;

    div.dropDown {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      position: relative;
      max-height: ${p => p.listHeight || 300}px;
      height: 100%;
    }
    
    div.dropDown + div > li {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
    form {
      //flex: 1 1 auto; 
      flex: 0 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${colors['ui-LightBG']};
      padding: 0 23px;

       i {
         font-size: 0.933em;
       }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: auto;
      //max-height: 150px;
      margin: 23px 0;
      li {
        color: ${colors['ui-Black2']};
        font-size: 1em;
        line-height: 2.25em;
        padding: 0 23px;
      }
      li:hover {
        background-color: ${colors['ui-primary']}40;
        cursor: pointer;
      }
    }

    .circularButton {
      margin: 0px auto;
    }
  }
`;
export { SelectCtr, InputSelect, SelectedWrapper, ArrowButton, RemoveButton, SearchButton, OptionWrapper };