import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withParagraphDataContext from '../../../../../Contexts/ParagraphWidgetContext/consumer';
import Field from '../../../../../elements/Field';
import { ButtonCustom } from '../../../../../elements/Button';

const Selectable = ({
  loading,
  handleOnSearch,
  getOptions,
  handleSelectChange,
  device,
  handleFetchInfo,
  variables,
  variable,
  intl,
  addParam,
}) => (
  <div className="d-flex">
    <Field
      type="select"
      name="device"
      placeholder={intl.formatMessage({
        id: 'widgets.parametrizedText.device.placeholder',
      })}
      options={getOptions()}
      onChange={handleSelectChange}
      value={device}
      async
      fetchInfo={handleFetchInfo}
      loading={loading}
      onSearch={handleOnSearch}
      filterBy="name"
      className="mr-2"
    />
    {variables.length > 0
&& (
<>
  <Field
    type="select"
    name="variables"
    placeholder={intl.formatMessage({
      id: 'widgets.parametrizedText.variable.placeholder',
    })}
    options={variables}
    onChange={handleSelectChange}
    value={variable}
    async
    fetchInfo={handleFetchInfo}
    loading={loading}
    onSearch={(e, i, o) => handleOnSearch(e, i, o)}
    filterBy="name"
    className="mr-2"
  />
  {variable
&& <ButtonCustom handleOnClick={addParam} style={{ marginBottom: '1.6em' }} className="btn btn-primary" label="+" />}
</>
)}

  </div>
);

Selectable.propTypes = {
  loading: PropTypes.string,
  handleOnSearch: PropTypes.string,
  getOptions: PropTypes.string,
  handleSelectChange: PropTypes.string,
  device: PropTypes.string,
  handleFetchInfo: PropTypes.string,
  variables: PropTypes.string,
  variable: PropTypes.string,
  intl: PropTypes.string,
  addParam: PropTypes.string,
};

Selectable.defaultProps = {
  loading: '',
  handleOnSearch: '',
  getOptions: '',
  handleSelectChange: '',
  device: '',
  handleFetchInfo: '',
  variables: '',
  variable: '',
  intl: '',
  addParam: '',
};

export default withParagraphDataContext(injectIntl(Selectable));
