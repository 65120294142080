import React from 'react';

import { FormattedMessage } from 'react-intl';
import IcoTweet from '../configuration/icons/svg/ico-tweet.svg';
import IcoAlert from '../configuration/icons/svg/ico-alert.svg';
import IcoNotification from '../configuration/icons/svg/ico-notification.svg';
import IcoSendCommand from '../configuration/icons/svg/ico-send-command.svg';
import IcoAttributeUpdate from '../configuration/icons/svg/ico-attribute-update.svg';
import getBriefComponent, {
  getUpdateBriefComponent, getAlertBriefComponent, getEmailBriefComponent, getTwitterBriefComponent, getSendCommandBriefComponent,
} from '../pages/Rules/helpers/briefComponents';
import {
  parseUpdateAction, parseEmailAction, parseTwitterAction, parseAlertAction, parseCommandAction,
} from '../pages/Rules/helpers/parseActions';


const ruleActions = [
  // {
  //   type: 'device_update',
  //   icon: IcoAttributeUpdate,
  //   titles: {
  //     lead: <FormattedMessage id="rules.wizard.action.update.text" />,
  //     title: <FormattedMessage id="rules.wizard.action.update" />,
  //   },
  //   briefComponent: getUpdateBriefComponent,
  //   parseAction: parseUpdateAction,

  // },
  {
    type: 'notification',
    icon: IcoNotification,
    subTypes: [
      {
        type: 'email',
        icon: IcoNotification,
        titles: {
          lead: <FormattedMessage id="rules.wizard.action.notification.text" />,
          title: <FormattedMessage id="rules.wizard.action.notification" />,
          subtypeTitle: <FormattedMessage id="rules.wizard.action.email" />,
        },
        briefComponent: getEmailBriefComponent,
        parseAction: parseEmailAction,
      },
    ],
  },
  // {
  //   type: 'twitter',
  //   icon: IcoTweet,
  //   titles: {
  //     lead: <FormattedMessage id="rules.wizard.action.twitter.text" />,
  //     title: <FormattedMessage id="rules.wizard.action.twitter" />,
  //   },
  //   briefComponent: getTwitterBriefComponent,
  //   parseAction: parseTwitterAction,
  // },
  {
    type: 'alert',
    icon: IcoAlert,
    titles: {
      lead: <FormattedMessage id="rules.wizard.action.alert.text" />,
      title: <FormattedMessage id="rules.wizard.action.alert" />,
    },
    briefComponent: getAlertBriefComponent,
    parseAction: parseAlertAction,
  },
  // {
  //   type: 'send_command',
  //   icon: IcoSendCommand,
  //   titles: {
  //     lead: <FormattedMessage id="rules.wizard.action.send_command.text" />,
  //     title: <FormattedMessage id="rules.wizard.action.send_command" />,
  //   },
  //   briefComponent: getSendCommandBriefComponent,
  //   parseAction: parseCommandAction,
  // },
];

const getTypeDataForAction = (actionType) => ruleActions.find(
  (ruleAction) => ruleAction.type === actionType,
);

const getGeneralDataForAction = (actionType, parentType = null) => {
  if (parentType) {
    const type = getTypeDataForAction(parentType);
    return type.subTypes.find((subType) => subType.type === actionType);
  }
  return getTypeDataForAction(actionType);
};


export { getGeneralDataForAction };
export default ruleActions;
