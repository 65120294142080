import React from 'react';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { areAllChecked } from './utils';
import CheckBox from '../../../../../elements/CheckBox';

const ListHeader = ({
  checkedList, onClick, checkable = true, nameless,
}) => (
  <div className="sendCommandItem header">
    {checkable && (
    <div className="listCheckbox">
      <CheckBox id="checkAll" checked={areAllChecked(checkedList)} onClick={onClick} />
    </div>
    )}
    {!nameless && (
    <div className="deviceName">
      <FormattedMessage id="SendCommandList.header.name" />
    </div>
    )}
    <div>
      <FormattedMessage id="SendCommandList.header.attribute" />
    </div>
    <div>
      <FormattedMessage id="SendCommandList.header.command" />
    </div>
    <div className="sendingButton" />
    <div className="errorsMessages" />
  </div>
);

export default ListHeader;
