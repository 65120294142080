/* eslint-disable import/no-cycle */
import React, { useCallback, memo } from 'react';
import ModalHOC from '../../../../../elements/Modal';

import { TYPES } from '../../../../../pages/Dashboards/ShowV2/reducer';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import CreateLinkedWidgetV2 from '../../../../../pages/Widgets/AddLinkedWidget/AddLinkedWizardV2';
import OpenIcon from '../../../../../configuration/icons/svg/createDashboard';
import colors from '../../../../../configuration/colors';
import { cloneInstance } from '../../../../../helpers/utils';

const ModalDOM = ModalHOC({
  ButtonOpen: ({ showModal, buttonModal: ButtonModal }) => (
    <ButtonModal showModal={showModal} />
  ),
  ModalContent: (
    { hideModal, widget, dispatchState },
  ) => CreateLinkedWidgetV2({ widget, dispatchState, hideModal }),
  ModalTitle: ({ hideModal }) => (
    <i
      className="uil uil-times closeButton"
      onClick={hideModal}
      role="presentation"
    />
  ),
});

const OpenIconButton = ({ showModal }) => (
  <button
    type="button"
    className="btn-link no-button mr-3"
    onClick={showModal}
  >
    <OpenIcon
      width={25}
      fill={colors['ui-Black2']}
    />
  </button>
);

const CustomWidgetCard = ({
  children, widget, actions, widgetCard: { dispatchState }, title, ...rest
}) => {
  const updateWidgetLinkedMode = useCallback(() => {
    if (widget) {
      dispatchState({
        type: TYPES.setWidgetSection,
        value: { active: widget },
      });
      const widgetCopy = cloneInstance(widget);
      const actualMode = widgetCopy.config?.custom?.LINKED?.mode;
      widgetCopy.config.custom.LINKED.mode = actualMode === 'MAP' ? 'TABLE' : 'MAP';
      widgetCopy.update(widgetCopy);
    }
  }, [dispatchState, widget]);

  return (
    <div className="linkedWidgetWrapper">
      <div className="widgetLinkedHeader">
        <div className="widgetLinkedName">
          <h2>
            {title}
          </h2>
          {!widget.id.includes('preview') &&
          <button className="btn btn-link no-button" onClick={() => updateWidgetLinkedMode(widget)} type="button">
            <i className="uil uil-exchange-alt" />
            {widget?.config.custom.LINKED.mode === 'MAP'
              ? <span><FormattedMessage id="widgetLinked.switch.table" /></span>
              : <span><FormattedMessage id="widgetLinked.switch.map" /></span>}
          </button>
        }
        </div>
        {!widget.id.includes('preview') &&
        <div className="widgetOptions font-weight-bold">
          <ModalDOM buttonModal={OpenIconButton} widget={widget} dispatchState={dispatchState} className="modal-linked" />
          {actions}
        </div>
        }
      </div>
      {React.cloneElement(children,
        {
          modal: ModalDOM, dispatchState, ...rest,
        })}
    </div>
  );
};

export default memo(CustomWidgetCard);
