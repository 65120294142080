import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { categories, Components, getCategory } from '../../utilsConfigurationSheet';

export const PercentageConfigurationSheetBasic = {
  labels: [
    {
      name: 'unit',
      component: Components.text,
      categories: [categories.LABELS],
    },
  ],
};

export const PercentageConfigurationSheet = {
  colors: [
    {
      name: 'labelColor',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
    {
      name: 'fillColor',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
  ],
  effects: [
    {
      name: 'waves',
      component: Components.checkbox,
      categories: [categories.EFFECTS],
    },
  ],
  labels: [
    {
      name: 'unit',
      component: Components.text,
      categories: [categories.LABELS],
    },
  ],
};

export const validationPercentage = (templateConfig, config) => {
  const constraints = {};
  templateConfig.forEach((property) => {
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }
    constraints[`${category}.${property}`] = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };
  });

  return validate(config, constraints);
};
