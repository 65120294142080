import { PropTypes } from 'prop-types';
import React from 'react';
import AddTableButton from '../../../components/AddTableButton';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';
import Wizard from '../../../components/Wizard';
import WizardStep from '../../../components/Wizard/components/WizardStep';
import CreateKpiIcon from '../../../configuration/icons/svg/ico-kpi.svg';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import AssignPermissions from '../../helpers/AssignPermissions';
import Kpi from '../../../models/Kpi';
import { isMobile } from '../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../elements/Button';
import CommonView from '../../CommonView';
import CreateKpi from './steps/CreateKpi';
import Scheduler from './steps/Scheduler';
// eslint-disable-next-line import/no-named-as-default
import SelectArithmeticalSourceOfData from './steps/SelectArithmeticalSourceOfData';
import SelectSimpleSourceOfData from './steps/SelectSimpleSourceOfData';
import SimpleKPIConfiguration from './steps/SimpleKPIConfiguration';
import Starter from './steps/Starter';
import Success from './steps/Success';
import ArithmeticOperation from './steps/ArithmeticOperation';

const addKpisLabel = (
  <FormattedMessage
    id="kpis.add.title"
    defaultMessage="add kpis"
    description="Label of add kpis"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/kpis"
        permission="create"
      >
        <ButtonCustom type="circular" name="plus" onClick={showModal} />
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/kpis"
      permission="create"
    >
      <AddTableButton onClick={showModal} label={addKpisLabel} />
    </PrivateComponent>
  );
};

const renderNextSteps = (type, errors) => {
  const simpleSteps = [{
    component: SimpleKPIConfiguration,
    stepNumber: 4,
  }];

  const arithmeticalSteps = [{
    component: SelectArithmeticalSourceOfData,
    stepNumber: 3,
  }, {
    component: ArithmeticOperation,
    stepNumber: 4,
  }];
  let wizardsSteps = [];
  if (type === 'simple') {
    wizardsSteps = simpleSteps;
  } else if (type === 'arithmetical') {
    wizardsSteps = arithmeticalSteps;
  }

  return wizardsSteps.map((step) => (
    <WizardStep
      key={<FormattedMessage id={`kpis.wizard.step${step.stepNumber}.${type}.title`} />}
      component={step.component}
      title={<FormattedMessage id={`kpis.wizard.step${step.stepNumber}.${type}.title`} />}
      icon={CreateKpiIcon}
      lead={<FormattedMessage id={`kpis.wizard.step${step.stepNumber}.${type}.text`} />}
      shortTitle={<FormattedMessage id={`kpis.wizard.step${step.stepNumber}.${type}.shortTitle`} />}
      errors={errors}
      entity={Kpi}
    />
  ));
};

class CreateKpis extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      option: null,
    };
  }

  handleOnSave = (state, entity, onContinue) => {
    const newKpi = new Kpi({ ...state.newEntity });
    const validationErrors = newKpi.validate(['name', 'description',
      'permissions_policy', 'value', 'categories', 'unit']);
    if (validationErrors) {
      this.setErrors(validationErrors);
      this.handleError();
    } else {
      newKpi.layoutProperties = {
        lg: [], md: [], sm: [], xs: [], xxs: [],
      };
      newKpi.save();
      onContinue();
    }
  };

  setKpiType = (data) => {
    this.setState({ option: data });
  };

  render() {
    const { option, errors } = this.state;
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        entity={Kpi}
        option=""
        metadata={{
          setKpiType: this.setKpiType,
          wizardType: 'kpi',
        }}
      >

        <WizardStep
          component={CreateKpi}
          shortTitle={<FormattedMessage id="kpis.wizard.step1.shortTitle" />}
          title={<FormattedMessage id="kpis.wizard.step1.title" />}
          icon={CreateKpiIcon}
          lead={<FormattedMessage id="kpis.wizard.step1.text" />}
          entity={Kpi}
        />

        <WizardStep
          component={Starter}
          shortTitle={<FormattedMessage id="kpis.wizard.step2.shortTitle" />}
          title={<FormattedMessage id="kpis.wizard.step2.title" />}
          icon={CreateKpiIcon}
          lead={<FormattedMessage id="kpis.wizard.step2.text" />}
          entity={Kpi}
        />

        { (option === 'simple' || option === 'manual')
          && (
            <WizardStep
              component={SelectSimpleSourceOfData}
              shortTitle={<FormattedMessage id="kpis.wizard.step3.simple.shortTitle" />}
              title={<FormattedMessage id="kpis.wizard.step3.simple.title" />}
              icon={CreateKpiIcon}
              lead={<FormattedMessage id="kpis.wizard.step3.simple.text" />}
              entity={Kpi}
              errors={errors}
            />
          )}

        {renderNextSteps(option, errors)}

        {(option === 'simple' || option === 'arithmetical')
          && (
            <WizardStep
              component={Scheduler}
              title={<FormattedMessage id="kpis.wizard.step5.simple.title" />}
              icon={CreateKpiIcon}
              lead={<FormattedMessage id="kpis.wizard.step5.simple.text" />}
              shortTitle={<FormattedMessage id="kpis.wizard.step5.simple.shortTitle" />}
              errors={errors}
              entity={Kpi}
            />
          )}

        <WizardStep
          component={AssignPermissions}
          shortTitle={<FormattedMessage id="kpis.wizard.permissions.shortTitle" />}
          title={<FormattedMessage id="kpis.wizard.permissions.title" />}
          icon={CreateKpiIcon}
          lead={<FormattedMessage id="kpis.wizard.permissions.text" />}
          entity={Kpi}
          onSave={this.handleOnSave}
        />
        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateKpis;
