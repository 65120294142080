import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import {
  Components, categories, options, valuesData, getCategory,
} from '../utils';

export default {
  orientation: [{
    name: 'orientation',
    component: Components.select,
    categories: [categories.APPEARANCE],
    options: options.orientation,
  }],
  colorsData: [{
    name: 'colorsData',
    component: Components.colorsData,
    categories: [categories.COLORS, categories.LABELS],
  }],
  legend: [{
    name: 'legendPosition',
    component: Components.select,
    categories: [categories.APPEARANCE],
    options: options.legendPosition,
  }],
  axes: [{
    name: 'xAxis',
    component: Components.text,
    categories: [categories.LABELS],
  }, {
    name: 'yAxis',
    component: Components.text,
    categories: [categories.LABELS],
  }],
  dataConfig: [
    {
      name: 'dataConfig',
      component: Components.barData,
      categories: [categories.DATA],
    },
  ],
};

export const validationBars = (type, config) => {
  const constraints = {};
  valuesData[type].forEach((property) => {
    if (typeof property !== 'object' && property !== 'trendlines') {
      const category = getCategory(property);
      if (!constraints[category]) {
        constraints[category] = {
          presence: true,
        };
      }
      constraints[`${category}.${property}`] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.empty" />,
        },
      };

      if ((property === 'endDate' || property === 'startDate') && ((config.data.type === 'real-time')
        || (config.data.type === 'historical' && config.data.period === 'last'))) {
        delete constraints[`${category}.${property}`];
      }
    }
  });

  return validate(config, constraints);
};
