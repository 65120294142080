import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { injectIntl } from 'react-intl';
import UsersGroup from '../../../../models/UsersGroup/index';
import Field from '../../../../elements/Field/index';
import CommonView from '../../../CommonView';
import { Container, Row, Col } from 'reactstrap';
import GroupsIcon from '../../../../configuration/icons/svg/ico-groups.svg';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';


export class Add extends CommonView {
  constructor(props) {
    super({ props: props });

    this.state = {
      name: this.props.newEntity.name || '',
      description: this.props.newEntity.description || '',
      errors: this.props.errors || '',
      onValidation: false,
    };
  }

  validate = () => {
    this.savePartialUsersGroup();
  };

  savePartialUsersGroup = () => {
    this.setErrors({});

    const obj = { ...this.state };
    const NewUsersGroup = new UsersGroup(obj);
    const created = NewUsersGroup.validate(['description', 'name']);

    if (created.error) {
      delete created.error;
      this.setErrors(created);
      this.props.onError(created.error);
    } else {
      this.props.onContinue(NewUsersGroup);
    }
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.props.onRef(this);
  }

  render() {
    const { intl } = this.props;
    const { errors } = this.state;

    return (
      <>
        <Container>
          <div className="wizardWrapper">
            <Row>
              <Col sm={{size:6, offset:3}}>
                {['name', 'description'].map((key, i) => (
                  <Field
                    name={key}
                    label={intl.formatMessage({
                      id: `usersGroups.wizard.${key}.label`,
                    })}
                    placeholder={intl.formatMessage({
                      id: `usersGroups.wizard.${key}.placeholder`,
                    })}
                    id={key}
                    value={this.state[key]}
                    type={key === 'description' ? 'textarea' : 'text'}
                    onChange={this.handleInputChange}
                    helperText={errors[key]}
                    error={!!errors[key]}
                    key={`${key}-${i}`}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

export default injectIntl(withWizardStep(Add));
