import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import {
  addRule,
  modifyRule,
  deleteRule,
  getRule,
} from '../../services/redux/rules/actions';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

/** Class Rule with Utils functions */
export default class Rule extends Utils {
  static entityName = 'Rule';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = Rule;
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  /** Call Redux action for Save Rule in DB and Redux */
  save() {
    addRule({
      ...this.getData([
        'actions',
        'alert',
        'description',
        'engine',
        'fiware',
        'name',
        'permissions_policy',
        'disable_cron',
        'enable_cron',
      ]),
    });
  }

  /** Call Redux action for Update Rule in DB and Redux */
  update() {
    const validation = validate(this.getData(), this.constraints);
    return validation === undefined
      ? modifyRule({
        ...this.getData(),
      })
      : { error: true, ...validation };
  }

  delete() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? deleteRule(this.getData())
      : { error: true, ...validation };
  }

  get() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? getRule(this.getData())
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('Rule', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleRule = new Rule();
export { SampleRule };
