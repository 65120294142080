import React from 'react';
// eslint-disable-next-line import/no-cycle
import { withLanguage } from '../index';
import SelectByDefault from './SelectByDefault';

/** Allow Render a custom Select */
export const HOCSelectLanguage = (Select) => {
  class InnerSelectLanguage extends React.Component {
        /** This function is past like prop to Select Component past
         *  as Param to Hoc function
         */
        handleChangeOfLanguage = (event) => {
          const { languageFn } = this.props;
          const { changeLanguage } = languageFn;
          languageFn && changeLanguage(event.target.value);
        };

        render() {
          const { languageFn } = this.props;
          const { currentLanguageName, availableLanguage } = languageFn;

          return (
            <Select
              name="Select-Language-Component"
              id="Select-Language-Component"
              onChange={this.handleChangeOfLanguage}
              value={currentLanguageName}
              options={availableLanguage}
            />
          );
        }
  }
  return withLanguage(InnerSelectLanguage);
};

export default HOCSelectLanguage(SelectByDefault);
