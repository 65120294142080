import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../../../../Contexts/LanguageContext';
import {
  categories, Components, getCategory, options, filterConfigurationSheet,
} from '../../../../utilsConfigurationSheet';
import { BarsConfigurationSheet } from '../../../../bars/utils/configurationSheet';

// eslint-disable-next-line import/prefer-default-export
export const BarsConfigurationSheetForLinked = filterConfigurationSheet(
  {
    ...BarsConfigurationSheet,
    operationType: [
      {
        name: 'operation',
        component: Components.select,
        categories: [categories.DATA],
        options: options.operation,
      },
    ],
  }, ['dataConfig'],
);

export const BarsLinkedDefaultTemplateConfig = [
  { orientation: ['vertical', 'horizontal'] },
  { type: ['historical', 'real-time'] },
  { period: ['last', 'temporary-period'] },
  {
    sampling: [
      'lastHour',
      'lastDay',
      'lastFifteenDays',
      'lastMonth',
      'lastThreeMonth',
      'lastYear',
    ],
  },
  'startDate',
  'endDate',
  'xAxis',
  'yAxis',
  'trendlines',
];

export const validationLinkedBars = (config, templateConfig = BarsLinkedDefaultTemplateConfig) => {
  const constraints = {};
  templateConfig.forEach((property) => {
    if (typeof property !== 'object' && property !== 'trendlines') {
      const category = getCategory(property);
      if (!constraints[category]) {
        constraints[category] = {
          presence: true,
        };
      }
      constraints[`${category}.${property}`] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.empty" />,
        },
      };

      if ((property === 'endDate' || property === 'startDate') && ((config.data.type === 'real-time')
        || (config.data.type === 'historical' && config.data.period === 'last'))) {
        delete constraints[`${category}.${property}`];
      }
    }
  });

  return validate(config, constraints);
};
