export const GET_ORGANIZATION_LIST_REQUEST = 'GET_ORGANIZATION_LIST_REQUEST';
export const GET_ORGANIZATION_LIST_ERROR = 'GET_ORGANIZATION_LIST_ERROR';
export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS';

export const GET_ORGANIZATION_BY_ID_REQUEST = 'GET_ORGANIZATION_BY_ID_REQUEST';
export const GET_ORGANIZATION_BY_ID_ERROR = 'GET_ORGANIZATION_BY_ID_ERROR';
export const GET_ORGANIZATION_BY_ID_SUCCESS = 'GET_ORGANIZATION_BY_ID_SUCCESS';

export const GET_DATASET_LIST_REQUEST = 'GET_DATASET_LIST_REQUEST';
export const GET_DATASET_LIST_REQUEST_BY_ORG = 'GET_DATASET_LIST_REQUEST_BY_ORG';
export const GET_DATASET_LIST_ERROR = 'GET_DATASET_LIST_ERROR';
export const GET_DATASET_LIST_SUCCESS = 'GET_DATASET_LIST_SUCCESS';

export const ADD_DATASET_LIST_REQUEST = 'ADD_DATASET_LIST_REQUEST';
export const ADD_DATASET_LIST_ERROR = 'ADD_DATASET_LIST_ERROR';
export const ADD_DATASET_LIST_SUCCESS = 'ADD_DATASET_LIST_SUCCESS';

export const RESET_DATASET_LIST = 'RESET_DATASET_LIST';
export const CLEAR_DEVICES_STATE = 'CLEAR_DEVICES_STATE';

export const REMOVE_DATASET_SUBSCRIPTION_REQUEST = 'REMOVE_DATASET_SUBSCRIPTION_REQUEST';
export const REMOVE_DATASET_SUBSCRIPTION_ERROR = 'REMOVE_DATASET_SUBSCRIPTION_ERROR';
export const REMOVE_DATASET_SUBSCRIPTION_SUCCESS = 'REMOVE_DATASET_SUBSCRIPTION_SUCCESS';

export const CREATE_GIS_LAYER_REQUEST = 'CREATE_GIS_LAYER_REQUEST';
export const PUBLISH_DATASET_SUBSCRIPTION_REQUEST = 'PUBLISH_DATASET_SUBSCRIPTION_REQUEST';
export const PUBLISH_DATASET_SUBSCRIPTION_ERROR = 'PUBLISH_DATASET_SUBSCRIPTION_ERROR';
export const PUBLISH_DATASET_SUBSCRIPTION_SUCCESS = 'PUBLISH_DATASET_SUBSCRIPTION_SUCCESS';

export const OBJECT_TYPE_DEVICE = 'DEVICE_OBJECT';
export const OBJECT_TYPE_KPI = 'KPI_OBJECT';
export const OBJECT_TYPE_ETL = 'ETL_OBJECT';
export const OBJECT_TYPE_GIS = 'GIS_OBJECT';
