import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Col } from 'reactstrap';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import './TimeRule.scss';
import {
  FieldComponentWithoutLabel, UnitTimeSelector,
} from '../helpers';
import { ButtonCustom } from '../../../../../../elements/Button/index';

const TimeRule = ({
  intl, handleTimeCondition, condition, updateCondition, errors,
}) => {
  const [state, setState] = useState({
    unit: condition.timeWindow.unit || '',
    repeat: condition.timeWindow.repeat || '',
    interval: condition.timeWindow.interval || '',
  });

  const setTimeWindow = () => {
    const conditionCopy = { ...condition };
    const timeWindow = {
      repeat: state.repeat,
      interval: state.interval,
      unit: state.unit,
    };
    conditionCopy.timeWindow = timeWindow;
    updateCondition(conditionCopy);
  };

  useEffect(() => {
    setTimeWindow();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleMinusButtonClick = (orIndex, andIndex, type) => {
    handleTimeCondition(orIndex, andIndex, type);
  };

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const TIME_UNITS_OPTIONS = [
    { name: <FormattedMessage id="rules.wizard.time.unit.seconds" />, value: 'sec' },
    { name: <FormattedMessage id="rules.wizard.time.unit.minutes" />, value: 'minute' },
    { name: <FormattedMessage id="rules.wizard.time.unit.hours" />, value: 'hour' },
    { name: <FormattedMessage id="rules.wizard.time.unit.days" />, value: 'days' },
    { name: <FormattedMessage id="rules.wizard.time.unit.weeks" />, value: 'weeks' },
  ];

  return (
    <Col className="timeRule" lg={{ size: 9, offset: 3 }}>
      <div className="conditionContainer">
        <div className="occurCont">
          <div className="occurBox">
            <FormattedMessage id="rules.occurs" />
          </div>
        </div>
        <div className="lineCont box">
          <div className="timesBox">
            <FormattedMessage id="rules.times" />
          </div>
        </div>
        <FieldComponentWithoutLabel
          name="repeat"
          intl={intl}
          className="numberTimes"
          value={state}
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="conditionContainer">
        <div className="lineCont box">
          <div className="timesBox">
            <FormattedMessage id="rules.time" />
          </div>
        </div>
        <div className="lineCont big">
          <FieldComponentWithoutLabel
            className="valueField"
            name="interval"
            intl={intl}
            value={state}
            onChange={handleChange}
            errors={errors}
          />
        </div>
        <UnitTimeSelector
          intl={intl}
          className="timeUnit"
          value={state}
          onChange={handleChange}
          options={TIME_UNITS_OPTIONS}
          errors={errors}
        />
      </div>
      <ButtonCustom
        type="circular"
        name="minus"
        onClick={() => handleMinusButtonClick(condition.orIndex, condition.andIndex, condition.type)}
      />
    </Col>
  );
};

export default injectIntl(TimeRule);
