/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import Styled from 'styled-components';
import ExpandIconHOC from '../ExpandIcon';
import { margin } from '../../helpers/functions';
import { isMobile } from '../../helpers/getBreakpoint';
import ContextMenuForMobile from './components/ContextMenuForMobile';
import useOutsideClick from '../../pages/helpers/Hooks/useOutsideClick';

const MenuS = Styled('div')`
  position:absolute;
  z-index: 1000;
  width: 100%;
  overflow: auto;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
  right: ${(p) => (p.onRight ? '0px' : '10px')};
  left: ${(p) => (p.onRight ? '0px' : null)}
  bottom: ${(p) => p.bottom};
  padding: ${margin} 0;
  background: #fff;
  border-radius: 4px;
  transform: scale(${(p) => (p.open ? '1' : '0')});
  opacity: ${(p) => (p.open ? '1' : '0')};
  transform-origin: ${(p) => p.openFrom};
  transition: opacity 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 176ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const MenuA = Styled('div')`
  position:relative;
  //width: 100%;
  ${MenuS}{
    max-width: ${(p) => p.maxWidth};
    max-height: ${(p) => p.maxHeight};
    width: fit-content;
  }
  .more {
      text-align: right;
      height: auto;
  }

  .more:nth-child(2):empty { display: none; }
`;

function Menu(props) {
  const {
    children,
    maxWidth,
    maxHeight,
    openFrom,
    className,
    fullScreen,
    openComponent: OpenComponent,
    disabled,
    open: openProp,
    top,
    onRight,
  } = props;
  const [firstTime, setFirstTime] = useState(false);
  const [buttonTarget, setButtonTarget] = useState('');
  const [open, setOpen] = useState(openProp);
  const [initPosition, setInitPosition] = useState(null);
  const menuRef = useRef(null);

  const close = () => {
    setOpen(false);
    setFirstTime(false);
  };
  useOutsideClick(menuRef, (event) => {
    if (firstTime && buttonTarget !== event.target) close();
  });

  const handleOpen = (event) => {
    if (!disabled) {
      setButtonTarget(event.target);
      if (!firstTime) {
        setOpen(true);
        setFirstTime(true);
      } else {
        setOpen(false);
        setFirstTime(false);
      }
    }
  };

  const getCalcInitPosition = (position) => {
    const { right, bottom, left } = props;
    const init = {
      top: top || '0px',
      right: right || '0px',
      bottom: bottom || '0px',
      left: left || '0px',
    };
    const strPosition = position.trim().split(' ');
    Object.keys(init).forEach((pos) => {
      if (!strPosition.includes(pos)) delete init[pos];
    });

    return init;
  };

  useEffect(() => {
    setInitPosition(getCalcInitPosition(openFrom));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFrom]);

  const childrenWithProps = React.Children
    .map(children, (child) => React.cloneElement(child, { close }));

  return (
    <MenuA
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      className={top === '-100px' ? 'MenuA-class' : ''}
    >
      <OpenComponent {...props} isOpen={open} onClick={handleOpen} disabled={disabled} />
      {isMobile() && fullScreen ? (
        open && (
        <ContextMenuForMobile
          onChange={handleOpen}
          closeIcon={false}
          reverseCloseIcon
        >
          {children}
        </ContextMenuForMobile>
        )
      ) : (
        <MenuS
          ref={menuRef}
          {...initPosition}
          className={className}
          divisor={2}
          open={open}
          openFrom={openFrom}
          disabled={disabled}
          onRight={onRight}
        >
          {childrenWithProps}
        </MenuS>
      )}
    </MenuA>
  );
}

Menu.defaultProps = {
  open: false,
  top: '0px',
  bottom: '0px',
  right: '0px',
  left: '0px',
  openFrom: 'top left',
  maxWidth: '300px',
  maxHeight: 'max-content',
  fullScreen: false,
  disabled: false,
  className: '',
};

export { ExpandIconHOC };
export default Menu;
