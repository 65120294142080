/* eslint-disable import/no-cycle */
const formatToParametrizedWidget = (historicalData) => {
  if (!historicalData) return;
  const details = { data: [], index: [] };
  historicalData.forEach((historical) => {
    if (!historical.value?.value) return;
    const index = details.data.findIndex((rowData) => rowData.device_id === historical.entityId);
    if (index > -1) {
      details.data[index][historical.attrName] = historical.value?.value;
    } else {
      details.data.push({
        device: historical.entityId,
        device_id: historical.entityId,
        [historical.attrName]: historical.value.value,
      });
    }
    details.index.push(`${historical.attrName}-${historical.entityId}`);
  });
  // eslint-disable-next-line consistent-return
  return details;
};

export default formatToParametrizedWidget;
