/** Selectores sencillos basados en reselect para desconectar el componente
 * es importante que si tienes que hacer alguna transformación sigan siendo
 * inmutables.
 */
export const selectDevices = (state) => (
  state.get('devices').get('list')
);

export const selectDevicesTotal = (state) => (
  state.get('devices').get('total')
);

export const selectDevicesIsFetching = (state) => (
  state.get('devices').get('fetching')
);
