import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import defaultMapImage from '../../../../configuration/images/mapTypes/default.png';
import darkMapImage from '../../../../configuration/images/mapTypes/dark.png';
import silverMapImage from '../../../../configuration/images/mapTypes/silver.png';
import outdoorMapImage from '../../../../configuration/images/mapTypes/relieve.png';
import satMapImage from '../../../../configuration/images/mapTypes/sat.png';
import './style.scss';

function SelectMapStyle(props) {
  const { mapType, onChange, intl } = props;
  const [style, setStyle] = useState(mapType || false);

  const handleOnClick = (selected) => {
    if (selected !== style) {
      setStyle(selected);
      onChange({ name: 'viewType', value: selected }, 'custom', 'mapStyle', 'MAP');
    }
  };

  return (
    <div className="map-type-selector">
      <div
        onClick={() => handleOnClick('default')}
        className={`map-type-option ${style === 'default' ? 'selected' : ''}`}
        role="button"
        tabIndex={-1}
      >
        <div className="type-map-radio" />
        <img src={defaultMapImage} alt="defaultMapImage" />
        <span>{intl.formatMessage({ id: 'widget.config.map.style.default' })}</span>
      </div>
      <div
        onClick={() => handleOnClick('silver')}
        className={`map-type-option ${style === 'silver' ? 'selected' : ''}`}
        role="button"
        tabIndex={-1}
      >
        <div className="type-map-radio" />
        <img src={silverMapImage} alt="silverMapImage" />
        <span>{intl.formatMessage({ id: 'widget.config.map.style.silver' })}</span>
      </div>
      <div
        onClick={() => handleOnClick('dark')}
        className={`map-type-option ${style === 'dark' ? 'selected' : ''}`}
        role="button"
        tabIndex={-1}
      >
        <div className="type-map-radio" />
        <img src={darkMapImage} alt="darkMapImage" />
        <span>{intl.formatMessage({ id: 'widget.config.map.style.dark' })}</span>
      </div>
      <div
        onClick={() => handleOnClick('outdoor')}
        className={`map-type-option ${style === 'outdoor' ? 'selected' : ''}`}
        role="button"
        tabIndex={-1}
      >
        <div className="type-map-radio" />
        <img src={outdoorMapImage} alt="outdoorMapImage" />
        <span>{intl.formatMessage({ id: 'widget.config.map.style.relief' })}</span>
      </div>
      <div
        onClick={() => handleOnClick('sat')}
        className={`map-type-option ${style === 'sat' ? 'selected' : ''}`}
        role="button"
        tabIndex={-1}
      >
        <div className="type-map-radio" />
        <img src={satMapImage} alt="satMapImage" />
        <span>{intl.formatMessage({ id: 'widget.config.map.style.satellite' })}</span>
      </div>
    </div>
  );
}

SelectMapStyle.propTypes = {
  mapType: PropTypes.string,
  onChange: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any),
};

SelectMapStyle.defaultProps = {
  mapType: 'default',
  onChange: () => {},
  intl: {},
};

export default injectIntl(SelectMapStyle);
