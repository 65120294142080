import validate from 'validate.js';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';

/** Class Attribute with Utils functions */
export default class Attribute extends Utils {
    static entityName = 'Attribute';

    constructor(obj = {}) {
      /** Send to utils class the Model and the object passed for build this class */
      super(ModelExpected, obj);
      this.Model = Attribute;
    }

    validate(data) {
      const filteredConstraints = {};
      data.forEach((d) => { filteredConstraints[d] = this.constraints[d]; });

      const validation = validate(this.getData(), filteredConstraints);
      return validation === undefined
        ? { ...validation }
        : { error: true, ...validation };
    }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('Attribute', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleAttribute = new Attribute();
export { SampleAttribute };
