import React from 'react';
import { Feature, Layer } from 'react-mapbox-gl';
import { connect } from 'react-redux';

const lineLayout = {
  'line-cap': 'round',
  'line-join': 'round',
};

const linePaint = {
  'line-color': '#567de8',
  'line-width': 5,
};

class TrackingLayers extends React.Component {
  constructor(props) {
    super(props);
    const { indexInHistory } = this.props;
    this.state = {
      indexInHistory,
      historyTrack: [],
    };
  }

  componentDidMount() {
    this.findHistoryInRedux(this.findDeviceIndexInReduxHistorical());
  }

  componentDidUpdate(prevProps) {
    const { allHistorical } = this.props;
    if (prevProps.allHistorical !== allHistorical) {
      this.findHistoryInRedux(this.findDeviceIndexInReduxHistorical());
    }
  }

  findDeviceIndexInReduxHistorical = () => {
    const { indexInHistory } = this.state;
    const { allHistorical, device } = this.props;
    if (indexInHistory !== -1) return indexInHistory;
    const found = allHistorical.find(
      (e) => (e.data.historicals
      && e.data.historicals[0].entityId === device.data.entityId),
    );
    let i = null;
    if (found) {
      i = allHistorical.indexOf(found);
      this.setState({ indexInHistory: i });
    }
    return i;
  };

  isZeroCoords = (coords) => coords[0] === '0' && coords[1] === ' 0';

  findHistoryInRedux(index) {
    const { allHistorical } = this.props;
    if (index !== -1) {
      this.setState({
        historyTrack: allHistorical[index]
          ? allHistorical[index].data.historicals : [],
      });
    }
  }

  render() {
    const { device } = this.props;
    const { locationName } = device;
    const { historyTrack } = this.state;
    const geoPointArray = historyTrack.map((h) => {
      const loc = h[locationName];
      if (loc && typeof loc === 'string') {
        const locParsed = loc.split(',');
        return !this.isZeroCoords(locParsed) ? [locParsed[1], locParsed[0]] : [];
      }

      // bug 351 - we log any devices parsed incorrectly
      console.warn('Unexpected data has been received, ignoring:', loc, device);
      return undefined;
    });

    return (
      <>
        <Layer type="line" layout={lineLayout} paint={linePaint}>
          <Feature coordinates={geoPointArray} />
        </Layer>

        {/* <Layer type="circle" paint={getCirclePaint}> */}
        {/*  <Feature coordinates={geoPointArray[0]}/> */}
        {/* </Layer> */}
      </>
    );
  }
}

TrackingLayers.defaultProps = {
  allHistorical: [],
  indexInHistory: -1,
};

const mapStateToProps = (state) => ({
  allHistorical: state.get('historicals').get('historicalList'),
});

export default connect(mapStateToProps)(TrackingLayers);
