/* eslint-disable import/no-cycle */
import { random } from 'lodash';
import TableDefaultConfiguration, { injectTableData } from '../../../../table/utils/defaultConfiguration';
import { getRandomColor } from '../../../../../../../pages/Widgets/Add/components/utils';

export const TableDefaultConfigurationForLinked = {
  ...TableDefaultConfiguration,
  appearance: {
    hidden: [],
    orientation: 'rows',
  },
};

export const injectLinkedTableData = (widget) => {
  const defaultTableWidgetConfig = injectTableData(widget);
  const alias = { };
  const colors = { };
  widget.config.data.attributeFilter.forEach((attr) => {
    alias[`${attr}`] = attr;
    colors[`${attr}`] = getRandomColor(random(0, 12));
  });
  defaultTableWidgetConfig.colors = colors;
  defaultTableWidgetConfig.labels.alias = alias;
  return defaultTableWidgetConfig;
};

export default TableDefaultConfigurationForLinked;
