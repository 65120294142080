import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { getRoles } from '../../../services/redux/roles/actions';
import ProfileComponent from '../../helpers/Profile/Profile';
import Info from './Info';

export class Profile extends React.Component {
  constructor(props) {
    super(props);

    const { data } = this.props;
    this.state = {
      opened: false,
      original: {
        ...data.entity,
        description: data.entity.description,
      },
    };
  }

  handleOnClose = () => {
    const { opened, onChange } = this.state;
    this.setState({
      opened: !opened,
    });

    onChange(opened);
  };

  render() {
    const { original, errors } = this.state;
    const data = original;

    return (
      <ProfileComponent
        data={data}
        titles={[<FormattedMessage id="alerts.profile.info.title" />]}
        onTabsChange={this.handleOnTabsChange}
        errors={errors}
        editable={false}
      >
        <Info data={data} />
      </ProfileComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  alerts: state
    .get('alerts')
    .get('list')
    .toJS(),
  users: state
    .get('users')
    .get('list')
    .toJS(),
  modifySuccess: state.get('alerts').get('modifySuccess'),
  errorFetching: state.get('alerts').get('errorFetching'),
});

Profile.defaultProps = {
  data: {
    entity: { fiware: { data: { deviceName: { type: {}, attribute: '' } }, severity: 'INFORMATIONAL', dateIssued: '' } },
  },
};

Profile.propTypes = {
  data: PropTypes.shape,
};

export default connect(mapStateToProps, { getRoles })(injectIntl(Profile));
