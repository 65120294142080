import { internalRulesManagement } from '../../../configuration/config';

const parseUpdateAction = (data) => ({
  type: 'device_update',
  deviceId: data.device.device_id,
  deviceName: data.device.name,
  version: '2',
  attributes: [
    {
      name: data.attribute.name,
      type: data.attribute.type,
      value: data.value,
    },
  ],
});

const parseAlertAction = (data) => ({
  type: 'alert',
  severity: data.severity,
  description: data.description,
  name: data.name,
});

const parseCommandAction = (data) => ({
  type: 'send_command',
  url: internalRulesManagement.url.concat(
    internalRulesManagement.paths.sendCommand,
  ),
  method: 'POST',
  // headers: { 'Content-type': 'application/json' },
  headers: {
    service: data.device.fiware.service,
    servicepath: data.device.fiware.servicepath,
  },
  deviceId: data.device.device_id,
  command: {
    name: data.command.name,
    value: data.value,
  },
  entity_type: data.device.entity_type,
  fiware: data.device.fiware,
});

const parseEmailAction = (data) => ({
  type: 'email',
  to: data.to,
  from: data.from,
  subject: data.subject,
  template: data.template,
  parentType: 'notification',
});

const parseTwitterAction = (data) => ({
  type: 'twitter',
  template: data.tweetMsg,
});

export {
  parseTwitterAction,
  parseEmailAction,
  parseUpdateAction, parseCommandAction, parseAlertAction,
};
