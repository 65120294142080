import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  fork
} from 'redux-saga/effects';
import User from '../../../models/User';
import * as TYPES from './types';
import * as TYPESnotify from '../notify/types';
import * as Api from './../../api/users';
import * as ApiRoles from './../../api/roles';
import { saveUserDataInLocalStorage } from '../auth/saga';
import Permission from '../../../models/Permission';
import translatePermissionToFront from '../../../helpers/translatePermissions'

export function* getUsersThroughApi({ payload }) {
  try {
    const response = yield call(Api.readUsers, payload);

    if (response.status === 200 || response.status === 304) {
      const users = response.data.objects.map(user => new User(user));
      yield put({ type: TYPES.GET_USERS_SUCCESS, payload: users });
    } else {
      yield put({ type: TYPES.GET_USERS_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_USERS_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getUserThroughApi({ payload }) {
  try {
    const response = yield call(Api.readUser, payload);
    if (response.status === 200) {
      const userFromDB = new User(response.data.object);
      yield put({ type: TYPES.GET_USER_SUCCESS, payload: userFromDB });
    } else {
      yield put({ type: TYPES.GET_USER_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_USER_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getUserRolesThroughApi({ payload }) {
  try {
    const response = yield call(Api.readUserRoles, payload);
    if (response.status === 200) {
      payload.roles = response.data.objects.map(role => role);
      yield put({ type: TYPES.GET_USER_ROLES_SUCCESS, payload });
    } else {
      yield put({ type: TYPES.GET_USER_ROLES_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_USER_ROLES_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* addUsersThroughApi({ payload }) {
  try {
    const response = yield call(Api.addUser, payload);
    if (response.status === 201) {
      const userFromDB = new User(response.data.object.user);
      
      yield* resetPasswordThroughApi({ payload: response.data.object.user });

      yield put({ type: TYPES.ADD_USER_SUCCESS, payload: userFromDB });
      yield put({ type: TYPESnotify.NOTIFY_ADD_USER_SUCCESS });
      yield put({
        type: TYPESnotify.NOTIFY_INFO_ADD_USER_SUCCESS_PASSWORD,
        payload: userFromDB.object.password
      });
    } else {
      yield put({ type: TYPES.ADD_USER_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.ADD_USER_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* updateUsersThroughApi({ payload }) {
  try {
    const response = yield call(Api.updateUser, payload);
    if (response.status === 200) {
      yield put({
        type: TYPES.MODIFY_USER_SUCCESS,
        payload: new User({ ...response.data.object, id: payload.id })
      });
    } else {
      yield put({ type: TYPES.MODIFY_USER_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_USER_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* resetPasswordThroughApi({ payload }) {
  try {
    const response = yield call(Api.resetPassword, payload);
    if (response.status === 201) {
      yield put({
        type: TYPES.RESET_USER_PASSWORD_SUCCESS,
        payload: response.data.object.password
      });
    } else {
      yield put({ type: TYPES.RESET_USER_PASSWORD_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.RESET_USER_PASSWORD_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* changePasswordThroughApi({ payload }) {
  try {
    const response = yield call(Api.changePassword, payload);
    if (response.status === 201) {
      yield put({
        type: TYPESnotify.NOTIFY_INFO_ADD_USER_SUCCESS_PASSWORD,
        payload: response.data.object.password
      });
      yield put({
        type: TYPES.MODIFY_USER_PASSWORD_SUCCESS,
        payload: response.data.object.password
      });
    } else {
      yield put({ type: TYPES.MODIFY_USER_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.MODIFY_USER_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* deleteUsersThroughApi({ payload }) {
  try {
    const response = yield call(Api.deleteUser, payload);
    if (response.status === 204)
      yield put({ type: TYPES.DELETE_USER_SUCCESS, payload });
    else {
      yield put({ type: TYPES.DELETE_USER_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPES.DELETE_USER_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export function* getUserMeThroughApi() {
  try {
    const response = yield call(Api.readUserMe);

    if (response.status === 200 || response.status === 304) {
      let user = response.data.object
      user.permissions = [];
      
      //GET UserPermisions From role API
      const responseRoles = yield all(
        user.roles.map(role => call(ApiRoles.readRolePermissions, role))
      );
 
      let allPermissions = responseRoles.flatMap(p => p.data.objects)
      user.permissions = translatePermissionToFront(allPermissions)
      saveUserDataInLocalStorage(user);
      let userMeFromDB = new User(user);
      userMeFromDB = preventNoData(userMeFromDB);
      yield put({ type: TYPES.GET_USER_ME_SUCCESS, payload: userMeFromDB });
    } else {
      yield put({ type: TYPES.GET_USER_ME_ERROR, payload: response });
      yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: response.status });
    }
  } catch (error) {
    yield put({ type: TYPES.GET_USER_ME_ERROR, payload: error });
    yield put({ type: TYPESnotify.NOTIFY_ERROR, payload: error });
  }
}

export const preventNoData = (data) => {
  if (data.name === '') data.name = data.id;
  return data;
}

function* watcherGetUsers() {
  yield takeLatest('GET_USERS_REQUEST', getUsersThroughApi);
}

function* watcherGetUser() {
  yield takeLatest('GET_USER_REQUEST', getUserThroughApi);
}

function* watcherGetUserRoles() {
  yield takeLatest('GET_USER_ROLES_REQUEST', getUserRolesThroughApi);
}

function* watcherAddUsers() {
  yield takeEvery('ADD_USER_REQUEST', addUsersThroughApi);
}

function* watcherUpdateUsers() {
  yield takeLatest('MODIFY_USER_REQUEST', updateUsersThroughApi);
}

function* watcherDeleteUsers() {
  yield takeEvery('DELETE_USER_REQUEST', deleteUsersThroughApi);
}

function* watcherResetPassword() {
  yield takeLatest('RESET_USER_PASSWORD_REQUEST', resetPasswordThroughApi);
}

function* watcherChangePassword() {
  yield takeLatest('MODIFY_USER_PASSWORD_REQUEST', changePasswordThroughApi);
}

function* watcherGetUserMe() {
  yield takeLatest('GET_USER_ME_REQUEST', getUserMeThroughApi);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetUsers),
    fork(watcherGetUser),
    fork(watcherGetUserRoles),
    fork(watcherAddUsers),
    fork(watcherUpdateUsers),
    fork(watcherDeleteUsers),
    fork(watcherResetPassword),
    fork(watcherChangePassword),
    fork(watcherGetUserMe)
  ]);
}
