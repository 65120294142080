const getValueFromOptions = (Options) => Options.map((option) => option.value);

export const GEO_OPTIONS = [
  { name: 'GeoIN', value: 'geo-in' },
  { name: 'GeoOUT', value: 'geo-out' },
];

export const GEO_VALUES = getValueFromOptions(GEO_OPTIONS);

export const MATH_OPTIONS = [
  { name: '<', value: '<' },
  { name: '>', value: '>' },
  { name: '=', value: '=' },
  { name: '≤', value: '<=' },
  { name: '≥', value: '>=' },
  { name: '!=', value: '!='},
];

export const MATH_VALUES = getValueFromOptions(MATH_OPTIONS);
