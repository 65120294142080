import React from 'react';
import PropTypes from 'prop-types';
import StatelessCheckbox from './StatelessCheckbox';
import CheckBoxStyled from './CheckBox';

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked
      && this.state.checked !== this.props.checked) {
      this.setState({
        checked: this.props.checked,
      });
    }
  }

  onClick = () => {
    if (!this.props.fixed && !this.props.disabled) {
      this.setState({ checked: !this.state.checked }, () => (
        setTimeout(() => this.props.onClick && this.props.onClick(), 100)));
    }
  };

  render() {
    const { checked } = this.state;
    const {
      label,
      name,
      reverse,
      css,
      positionCheck,
      minimal,
      disabled,
      id,
      className
    } = this.props;

    return (
      <CheckBoxStyled
        className={className}
        label={label}
        name={name}
        reverse={reverse}
        css={css}
        positionCheck={positionCheck}
        minimal={minimal}
        checked={checked}
        onClick={this.onClick}
        id={id}
      />
    );
  }
}

CheckBox.defaultProps = {
  checked: true,
  reverse: false,
  svg: 'Done',
  css: '',
  fixed: false,
  label: '',
};

CheckBox.propTypes = {
  /** Label for show to user */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /** Name associate to original input */
  name: PropTypes.string.isRequired,

  /** State of the checkbox */
  checked: PropTypes.bool,

  /** Direction of the label */
  reverse: PropTypes.bool,

  /** SVG Icon to show when checkbox is checked */
  svg: PropTypes.string,

  /** Additional css to add */
  css: PropTypes.string,

  /** If checkbox must not change */
  fixed: PropTypes.bool,

  /** If true, minimal mode is active. Checkbox has a minimal design */
  minimal: PropTypes.bool,
};

export default CheckBox;
export { StatelessCheckbox };
