import React from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import CommonView from '../../../CommonView';
import Field from '../../../../elements/Field';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
// eslint-disable-next-line import/no-cycle
import { getWidgetModel } from '../../../../models/WidgetV2/utils';

class BasicInfo extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      errors: {},
      page: 1,
      size: 5,
      name: this.props.newEntity.name || '',
      description: this.props.newEntity.description || '',
      // type : this.props.newEntity.type
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.onEnter) {
      this.props.onEnter(false);
    }
  }

  validate = () => {
    this.savePartialWidget();
  };

  savePartialWidget = () => {
    this.setErrors({});
    const obj = this.state;
    const newEntity = getWidgetModel(obj);
    const created = newEntity.validate(['name']);

    if (created.error) {
      delete created.error;
      this.setErrors(created);
      this.props.onError(created.error);
    } else {
      const { errors, onValidation, ...rest } = this.state;
      this.props.onContinue(rest);
    }
  };

  render() {
    const { intl } = this.props;
    const { errors } = this.state;
    return (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            {['name', 'description'].map((index) => (
              <Field
                key={index}
                name={index}
                label={intl.formatMessage({
                  id: `widgets.wizard.${index}.label`,
                })}
                className={(index === 'name') ? 'required' : ''}
                placeholder={intl.formatMessage({
                  id: `widgets.wizard.${index}.placeholder`,
                })}
                type={index === 'description' ? 'textarea' : 'text'}
                helperText={errors[index]}
                error={!!errors[index]}
                onChange={this.handleInputChange}
                value={this.state[index]}
              />
            ))}
          </Col>
        </Row>
      </>
    );
  }
}

BasicInfo.defaultProps = {
};

BasicInfo.propTypes = {
};

export default withWizardStep(injectIntl(BasicInfo));
