import { Map, List } from 'immutable';

import * as TYPES from './types';

/** Initial State of Reports */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  report: {},
});

/** Report reducer */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /** initialize DB requests */
    case TYPES.GET_REPORTS_REQUEST:
    case TYPES.GET_REPORT_REQUEST:
    case TYPES.ADD_REPORT_REQUEST:
    case TYPES.ADD_REPORTPAGE_REQUEST:
    case TYPES.MODIFY_REPORT_REQUEST:
      return state.merge(Map({
        fetching: true,
        errorFetching: null,
      }));

    case TYPES.GET_REPORTS_ERROR:
    case TYPES.GET_REPORT_ERROR:
    case TYPES.MODIFY_REPORT_ERROR:
    case TYPES.ADD_REPORT_ERROR:
    case TYPES.ADD_REPORTPAGE_ERROR:
      return state.merge(Map({
        fetching: false,
        errorFetching: payload,
      }));

    case TYPES.ADD_REPORTPAGE_SUCCESS:
      return state.merge(Map({
        fetching: false,
      }));

    /** delete reports in state */
    case TYPES.DELETE_REPORTS_SUCCESS:
      return state.merge(
        Map({
          list: state.get('list').filterNot((r) => r.id === payload.id),
          fetching: true,
          ReportAdded: false,
          ReportDeleted: true,
          total: state.get('total') - 1,
        }),
      );

    /** reports received from DB */
    case TYPES.GET_REPORTS_SUCCESS:
    case TYPES.GET_MY_REPORTS_SUCCESS:
      return state
        .update(
          // get current state.list and iterate using immutable
          'list', (reports) => reports
            // remove duplicates from state.list
            .filterNot((report) => (
              payload.reports.find((i) => i.id === report.id)
            ))
            // concat new backend list
            .concat(payload.reports),
        )
        // finally merge
        .merge(Map({
          fetching: false,
          total: payload.total,
        }));

    /** reports received from DB */
    case TYPES.GET_REPORT_SUCCESS:
      return state.set('report', payload)
        .merge(Map({
          fetching: false,
          report: payload
        }));

    case TYPES.MODIFY_REPORT_SUCCESS:
      return state
        .update('list', (reportList) => reportList.map((report) => {
          if (report.id === payload.id) {
            return payload;
          }
          return report;
        }))
        .merge(Map({
          fetching: false,
          report: payload,
        }));

    case TYPES.CLEAR_REPORTS_STATE:
      return state.set('list', List([])).set('report', {});
    case TYPES.ADD_REPORT_SUCCESS:
      return state
        .update('list', (reportList) => reportList.unshift(payload))
        .merge(Map({
          fetching: false,
          addSuccess: true,
          reportAdded: true,
          reportDeleted: false,
          errorFetching: false,
        }))
        .update('total', (total) => total + 1);

    case TYPES.ADD_REPORT_SUCCESS_FLAG:
    case TYPES.ADD_REPORTPAGE_SUCCESS_FLAG:
      return state.set('addSuccess', false);

    default:
      return state;
  }
};
