import { random } from 'lodash';
import { getColor } from '../../../../pages/Widgets/Add/components/utils';

const BarDefaultConfiguration = {
  data: {
    type: 'real-time',
    period: '',
    sampling: '',
    startDate: '',
    endDate: '',
  },
  colors: { },
  labels: {
    xAxis: '',
    yAxis: '',
    alias: { },
  },
  conditions: { },
  trendlines: { },
  appearance: {
    orientation: 'vertical',
    legendPosition: 'top',
  },
};

export default BarDefaultConfiguration;

export const BarDefaultConfigurationForLinked = {
  ...BarDefaultConfiguration,
  labels: {
    xAxis: 'Time',
    yAxis: 'Value',
    alias: { },
  },
};

export const injectBarsData = (origins, config, linked) => {
  const newConfig = { ...config };
  if (linked) {
    newConfig.labels.alias = {};
    newConfig.colors = { custom: {} };
    newConfig.data.attributeFilter.forEach((attr) => {
      newConfig.labels.alias.custom = attr;
      newConfig.colors.custom[attr] = getColor(random(0, 12));
    });
    return newConfig;
  }
  origins.forEach((origin) => {
    if (origin.type !== 'KPI') { // TODO: Delete when kpi accepts joinedAttrs
      const attrs = [
        ...origin.connectedDevices.attributes,
        ...origin.connectedDevices.lazy_attributes,
        ...origin.connectedDevices.static_attributes,
      ];
      attrs.forEach((attr) => {
        if (!newConfig.labels.alias[attr]) newConfig.labels.alias[attr] = attr;
        const property = newConfig.colors[attr];
        if (!property) newConfig.colors[attr] = getColor(random(0, 12));
      });
    }
  });
  return newConfig;
};
