import React from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Field from '../../../../elements/Field';
import withVariables from '../../../../components/WithVariables/index';
import { parseToTag } from '../../helpers/briefComponents';

const FieldWithVariables = withVariables(Field);

const EditTweetComponent = ({
  tweet, errors, onEdit, index, onChange, devices, intl,
}) => (
  <>
    <dl>
      <dt className="col col-md-3 align-items-start">
        <FormattedMessage id="rules.profile.twitter.label" />
      </dt>
      <dd className="col col-md-7">
        {!onEdit ? (
          parseToTag(tweet.template)
        ) : (
          <FieldWithVariables
            type="complexTwitterTextarea"
            onChange={(data) => onChange({
              id: data.target.name,
              value: data.target.value,
              index,
              type: tweet.type,
            })}
            name="template"
            value={tweet.template}
            helperText={
              errors.template
              || errors.lengthOk
              || intl.formatMessage({ id: 'twitterTextarea.placeholder' })
                .concat('. ', intl.formatMessage({ id: 'complexTextarea.pattern' }))
            }
            error={!!errors.template || !!errors.lengthOk}
            devices={devices.map(
              ({ id, attributes }) => ({
                device: id,
                attributes,
              }),
            )}
          />
        )}
      </dd>
    </dl>
  </>
);

EditTweetComponent.propTypes = {
  tweet: PropTypes.objectOf(PropTypes.object).isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  onEdit: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EditTweetComponent.defaultProps = {
  errors: {},
};

export default injectIntl(EditTweetComponent);
