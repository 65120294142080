import React from 'react';
import RemoveHoc from "../../helpers/RemoveComponent";
import { FormattedMessage } from "../../../Contexts/LanguageContext/index";
import Service from '../../../models/Service';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent'


const Delete = <FormattedMessage
  id="options.delete"
  defaultMessage="delete"
  description="Title of delete"
/>

const Edit = <FormattedMessage
  id="options.edit"
  defaultMessage="edit"
  description="Title of edit"
/>

const Clone = <FormattedMessage
  id="options.clone"
  defaultMessage="clone"
  description="Title of clone"
/>

const OptionsForRow = ({ rowContent, viewClick }) => {
  const ButtonOpenRemove = ({ showModal }) => <li onClick={showModal}>{Delete}</li>;

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Service, nameData: "services" })

  return (
    <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/services'} permission={['delete','update','read']} >
      <div className="tableMenu">
        <ul className="list-unstyled">
          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/services'} permission={'update'} >
            <li onClick={() => { viewClick(rowContent) }} state={{ service: rowContent, mode: 'edit' }}>{Edit}</li>
          </PrivateComponent>
          <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/services'} permission={'delete'} >
            <RemoveDOM rowContent={rowContent} />
          </PrivateComponent>
        </ul>
      </div>
    </PrivateComponent>
  )
};

export default OptionsForRow;
