import React from 'react';
import Styled from 'styled-components';
import ModalHOC from '../../../elements/Modal/index';
import { ButtonCustom } from '../../../elements/Button/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { deleteMenuElement } from '../../../services/redux/menuElements/actions';

const StyledTitleName = Styled.em``;

const ModalTitle = () => (
  <FormattedMessage
    id="menu.delete.title"
    defaultMessage="Delete user"
    description="Title for delete modal window"
    component="p"
  />
);

const CancelMsg = () => (
  <FormattedMessage
    id="options.cancel"
    defaultMessage="Cancel"
    description="Cancel option"
    component="span"
  />
);

const DeleteMsg = () => (
  <FormattedMessage
    id="options.delete"
    defaultMessage="Delete"
    description="Delete option"
    component="span"
  />
);

const ModalContent = (title) => (
  <h1>
    <FormattedMessage
      id="menu.delete.confirmMessage"
      defaultMessage="Confirm you want to delete the menu named:"
      description="Message for delete widget modal window"
    />
    <StyledTitleName>
      {' '}
      {title}
    </StyledTitleName>
  </h1>
);

const DeleteMenuItem = (e, menuElement, hideModal) => {
  e.preventDefault();
  e.stopPropagation();
  deleteMenuElement(menuElement);
  hideModal();
};

const ModalButtons = (menuElement, hideModal) => (
  <div className="buttons">
    <ButtonCustom type="primary" handleOnClick={(e) => DeleteMenuItem(e, menuElement, hideModal)} label={<DeleteMsg />} id="deleteModalButton" />
    <ButtonCustom handleOnClick={hideModal} className="btn btn-secondary" label={<CancelMsg />} />
  </div>
);

export default (ButtonOpen) => {
  class RemoveInner extends React.PureComponent {
    render() {
      const { title, menuElement } = this.props;
      const ModalDOM = ModalHOC({
        ButtonOpen,
        ModalTitle,
        ModalContent: () => ModalContent(title),
        ModalButtons: ({ hideModal }) => ModalButtons(menuElement, hideModal),
      });

      return <ModalDOM title={title} menuElement={menuElement} />;
    }
  }

  RemoveInner.defaultProps = {
    title: '',
    menuElement: {},
  };

  return RemoveInner;
};
