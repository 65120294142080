import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';
import { isEmpty, clone } from 'ramda';
import { isEqual } from 'lodash';

import ConditionalRule from './ConditionalRule';
import TimeRule from './TimeRule';

class ConditionalRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: false,
      conditions: props.conditions.length ? this.numberConditions([...props.conditions]) : [[this.createEmptyCondition(0, 0, 'if')]],
    };
  }

  componentDidMount() {
    const { conditions } = this.props;
    if (!conditions.length) {
      const provConditions = [[this.createEmptyCondition(0, 0, 'if')]];
      this.saveData(provConditions);
    }
  }

  componentDidUpdate() {
    const { conditions } = this.props;
    if (!isEqual(conditions, this.state.conditions)) {
      this.setState({ conditions });
    }
  }

  createEmptyCondition = (orIndex, andIndex, type) => ({
    orIndex,
    andIndex,
    device: {},
    attribute: {},
    operator: {},
    value: '',
    type,
  });

  newIndependentCondition = () => {
    const { onChange } = this.props;
    const { conditions } = this.state;
    const conditionsCopy = [...conditions];
    conditionsCopy.push([
      this.createEmptyCondition(conditions.length, 0, 'or'),
    ]);

    onChange(conditionsCopy, 'conditions');
    this.setState({
      forceUpdate: true,
    }, () => this.setState({ forceUpdate: false }));
  };

  newNestedCondition = (parentDevice, parentOrIndex, type) => {
    const { onChange } = this.props;
    const { conditions } = this.state;

    const conditionsCopy = [...conditions];
    conditionsCopy[parentOrIndex].push({
      ...this.createEmptyCondition(
        parentOrIndex,
        conditions[parentOrIndex].length,
        type,
      ),
      device: parentDevice,
    });

    onChange(conditionsCopy, 'conditions');
    this.setState({
      forceUpdate: true,
    }, () => this.setState({ forceUpdate: false }));
  };

  handleTimeCondition = (orIndx, andIndx, typeCond) => {
    const { onChange } = this.props;
    const { conditions } = this.state;

    const conditionsCopy = clone(conditions);
    const foundIndex = conditionsCopy[orIndx].findIndex(
      (c) => c.andIndex === andIndx && c.type === typeCond,
    );

    if (!conditionsCopy[orIndx][foundIndex].timeWindow) {
      conditionsCopy[orIndx][foundIndex].timeWindow = {
        repeat: '',
        interval: '',
        unit: '',
      };
    } else {
      delete conditionsCopy[orIndx][foundIndex].timeWindow;
    }
    onChange(conditionsCopy, 'conditions');
  };

  updateCondition = (condition) => {
    const { onChange } = this.props;
    const { conditions } = this.state;
    const conditionsCopy = [...conditions];

    const foundIndex = conditionsCopy[condition.orIndex].findIndex(
      (c) => c.andIndex === condition.andIndex && c.type === condition.type,
    );

    conditionsCopy[condition.orIndex][foundIndex] = condition;

    onChange(conditionsCopy, 'conditions');
  }

  removeCondition = (orIndex, andIndex) => {
    const { onChange } = this.props;
    const { conditions } = this.state;

    const conditionsCopy = [...conditions];
    if (andIndex === 0) conditionsCopy.splice(orIndex, 1);
    else conditionsCopy[orIndex].splice(andIndex, 1);
    onChange(conditionsCopy, 'conditions');
    this.setState({
      forceUpdate: true,
    }, () => this.setState({ forceUpdate: false }));
  };

  numberConditions = (conditions) => conditions
    .map((orCondition, i) => orCondition.map((andCondition, z) => ({
      ...andCondition,
      orIndex: i,
      andIndex: z,
    })));

  saveData = (data) => {
    const { onChange, conditions } = this.props;

    let conditionsCopy = [...conditions];
    const equalAttributes = [];
    const conditionsToRemove = [];

    if (conditionsCopy.length > 0) {
      // Check if the parent device has changed.
      if (conditionsCopy
        && conditionsCopy[data.orIndex]
        && conditionsCopy[data.orIndex][1]
        && conditionsCopy[data.orIndex][1].device !== data.device
        && data.device
        && data.device.attributes) {
        // Now we check what attributes of the new device were also present
        // on the conditions that used the previous device.
        conditions[data.orIndex].forEach((c) => {
          data.device.attributes.forEach((a) => {
            if (JSON.stringify(a) === JSON.stringify(c.attribute)) {
              equalAttributes.push(a.name);
            }
          });
        });

        conditions[data.orIndex].forEach((c, i) => {
          if (conditions[data.orIndex][i].attribute.name && !equalAttributes.includes(conditions[data.orIndex][i].attribute.name)) {
            if (i !== 0) {
              // If the attribute is not the same and the condition is nested
              // we remove the entire condition.
              conditionsToRemove.push(c);
            } else {
              // If the attribute is not the same and the condition is not nested
              // we reset the entire condition and swap the device.
              conditionsCopy[data.orIndex][i].device = data.device;
              conditionsCopy[data.orIndex][i].attribute = '';
              conditionsCopy[data.orIndex][i].operator = '';
              conditionsCopy[data.orIndex][i].value = '';
            }
          } else {
            // If the attribute is the same we simply swap the device.
            conditionsCopy[data.orIndex][i].device = data.device;
          }
        });
        if (conditionsToRemove) {
          conditionsToRemove.forEach((c) => {
            conditionsCopy[data.orIndex].splice(conditionsCopy[data.orIndex].indexOf(c), 1);
          });
        }
      } else if (data.attribute && isEmpty(data.device) && data.andIndex > 0) {
        // This part fixes a weird bug that produces the device reset of a nested rule if the parent have not selected
        // an attribute yet.
        conditionsCopy[data.orIndex][data.andIndex] = {
          ...conditions[data.orIndex][data.andIndex],
          ...data,
          device: conditionsCopy[data.orIndex][0].device,
        };
      } else if (conditionsCopy.length > 0
        && conditionsCopy[data.orIndex]
        && conditionsCopy[data.orIndex][data.andIndex].operator !== data.operator) {
        conditionsCopy[data.orIndex][data.andIndex] = {
          ...conditions[data.orIndex][data.andIndex],
          ...data,
          value: '',
        };
      } else if (conditionsCopy.length > 0
        && conditionsCopy[data.orIndex]
        && conditionsCopy[data.orIndex][data.andIndex].attribute !== data.attribute) {
        conditionsCopy[data.orIndex][data.andIndex] = {
          ...conditions[data.orIndex][data.andIndex],
          ...data,
          operator: '',
          value: '',
        };
      } else {
        // If the parent device was not changed or was deselected:
        // we update the conditionsCopy array with the new changes.
        conditionsCopy[data.orIndex][data.andIndex] = {
          ...conditions[data.orIndex][data.andIndex],
          ...data,
        };

        // conditionsCopy[data.orIndex].forEach((c, i) => {
        //   conditionsCopy[data.orIndex][i].device = data.andIndex > 0 && this.props.data[0]
        //     ? this.props.data[0]
        //     : data.device;
        // });
      }

      if (data.andIndex === 0 && conditions[data.orIndex]) {
        conditionsCopy[data.orIndex] = conditions[data.orIndex].map((condition) =>
          // eslint-disable-next-line no-param-reassign
          condition);
      }
    } else {
      conditionsCopy = data;
    }
    onChange(conditionsCopy, 'conditions');
  };

  render() {
    const { errors, data } = this.props;
    const { forceUpdate, conditions } = this.state;

    return (
      <div>
        {conditions.map((conditionGroup, conditionGroupIndex) => conditionGroup.map((condition, conditionIndex) => (
          <>
            <ConditionalRule
              devices={data}
              condition={condition}
              device={condition.device}
              mainCondition={conditions[1] ? conditions[1][0] : undefined}
              newNestedCondition={this.newNestedCondition}
              handleTimeCondition={this.handleTimeCondition}
              newIndependentCondition={this.newIndependentCondition}
              removeCondition={this.removeCondition}
              onChange={this.saveData}
              orIndex={conditionGroupIndex}
              andIndex={conditionIndex}
            // eslint-disable-next-line react/no-array-index-key
              key={`${conditionGroupIndex}_${conditionIndex}`}
              errors={errors && errors[conditionGroupIndex] ? errors[conditionGroupIndex][conditionIndex] : {}}
              forceUpdate={forceUpdate}
            />
            {condition.timeWindow && (
            <TimeRule
              handleTimeCondition={this.handleTimeCondition}
              condition={condition}
              updateCondition={this.updateCondition}
              errors={errors && errors[conditionGroupIndex] ? errors[conditionGroupIndex][conditionIndex] : {}}
            />
            )}
          </>
        )))}
      </div>
    );
  }
}

ConditionalRules.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
};

ConditionalRules.defaultProps = {
  conditions: [],
  errors: {},
  data: [],
};

const mapDispatchToProps = {};

export default connect(
  () => {},
  mapDispatchToProps,
)(ConditionalRules);
