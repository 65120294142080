import React from "react";
import Styled from "styled-components";
import './styles.scss';

const CheckBoxStyled = Styled.div`
flex-direction:  ${p => (p.reverse ? 'row-reverse' : 'row')};
  ${p => p.css}
`;

export default ({label, name, reverse, css, positionCheck, minimal, checked, onClick, className, id})=>{
  return (
    <CheckBoxStyled
      onClick={onClick}
      checked={checked}
      reverse={reverse}
      css={css}
      className={`checkBox ${checked ? 'checked' : ''} ${minimal ? 'minimal' : ''} ${className ? className : ''}`}
    >
      {label && positionCheck === 'left' && (
        <label className="text-gray pl-2 pr-2" htmlFor={name}>
          {label}
        </label>
      )}
      <input type={'checkbox'} name={name} id={id} />
      <div>{checked ? <i className="uil uil-check"/> : null}</div>
      {label && positionCheck !== 'left' && (
        <label className="text-gray pl-2 pr-2" htmlFor={name}>
          {label}
        </label>
      )}
    </CheckBoxStyled>
  )
}