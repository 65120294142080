import React, { useState, useEffect } from 'react';

import '../styles.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import Field from '../../../../../elements/Field/index';
import { ButtonCustom } from '../../../../../elements/Button/index';
import {
  isDisabled, sendCommand, getValue, parseOptions,
} from './utils';
import CheckBox from '../../../../../elements/CheckBox';

const SendCommandItem = ({
  device,
  intl,
  checkable,
  checked,
  onCheckboxClick,
  onChange,
  errors,
  nameless,
}) => {
  const [attribute, setAttribute] = useState(getValue(device));
  const [command, setCommand] = useState('');
  const [status, setStatus] = useState('idle');
  const [isAdvancedOption, setIsAdvancedOption] = useState(false);

  const parseValueOptions = () => (attribute && attribute.available_commands
    ? attribute.available_commands : []);

  useEffect(() => {
    if (status !== 'idle') {
      setTimeout(() => setStatus('idle'), 5000);
    }
  }, [status]);

  useEffect(() => {
    onChange({
      device,
      setStatus,
      attribute,
      command,
      status,
      isDisabled: isDisabled(device),
      checked,
      validated: !(command === '' || !attribute),

    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute, command, status, checked]);

  useEffect(() => {
    if (attribute) {
      if (attribute.available_commands.length === 0) setIsAdvancedOption(true);
      else setIsAdvancedOption(false);
    }
  }, [attribute]);

  const getTextOption = () => (
    <div className="options-sendcommand-text">
      { isAdvancedOption
        ? (
          <FormattedMessage
            id="options.sendCommand.simple"
            component="span"
          />
        )
        : (
          <FormattedMessage
            id="options.sendCommand.advanced"
            component="span"
          />
        )}
    </div>
  );

  return (
    <div
      className={`sendCommandItem ${
        isDisabled(device) ? 'disabled' : 'enabled'
      }`}
    >
      {checkable && (
      <div className="listCheckbox">
        <CheckBox
          checked={isDisabled(device) ? false : checked}
          disabled={isDisabled(device)}
          onClick={onCheckboxClick}
          className="itemCheckbox"
        />
      </div>
      )}
      {!nameless && (
      <div className="deviceName">
        <p>
          {device.device_id || (
          <FormattedMessage id="SendCommandList.not.found.device" />
          )}
        </p>
      </div>
      )}

      <div>
        <Field
          placeholder={intl.formatMessage({
            id: 'SendCommandList.choose.property',
          })}
          type="select"
          value={attribute}
          options={parseOptions(device.commands)}
          onChange={(name, value) => setAttribute(value)}
          disabled={isDisabled(device)}
          name="attribute"
          error={!!errors.attributes}
          helperText={errors.attributes}
        />
      </div>
      <div className="center-multiselect">
        {isAdvancedOption
          ? (
            <Field
              placeholder={intl.formatMessage({
                id: 'SendCommandList.write.command',
              })}
              type="text"
              value={command}
              onChange={({ target }) => setCommand(target.value)}
              disabled={isDisabled(device)}
              name="command"
              error={!!errors.command}
              helperText={errors.command}
            />
          )
          : (
            <Field
              placeholder={intl.formatMessage({
                id: 'SendCommandList.choose.command',
              })}
              type="select"
              value={command}
              options={parseValueOptions()}
              onChange={(name, value) => setCommand(value)}
              disabled={isDisabled(device)}
              name="command"
              error={!!errors.attributes}
              helperText={errors.attributes}
            />
          )}
        <button
          className="unstyled-button"
          type="button"
          onClick={() => setIsAdvancedOption(!isAdvancedOption)}
        >
          {getTextOption()}
        </button>
      </div>
      <div className="sendingButton">
        <ButtonCustom
          id="sendCommandButton"
          label={<FormattedMessage id="options.sendCommand" />}
          type="circular"
          onClick={() => sendCommand(device, attribute.name, command, setStatus)}
          disabled={isDisabled(device) || command === '' || !attribute}
          name="send"
        />
      </div>
      <div className="errorsMessages">
        {/* <span className={status}>{getStatus(status)}</span> */}
        {status === 'error' ? (
          <i className="uil uil-exclamation-triangle error" />
        ) : (
          <span />
        )}
        {status === 'success' ? (
          <i className="uil uil-check success" />
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};

SendCommandItem.defaultProps = {
/*
device: { commands: [] },
*/
  device: {},
  intl: {},
  checkable: true,
  checked: true,
  onCheckboxClick: () => {},
  onChange: () => {},
  errors: {},
  nameless: false,
};

SendCommandItem.propTypes = {
/* The device selected for sending commands */
/*
device: PropTypes.shape({
commands: PropTypes.arrayOf,
}),
*/
  device: PropTypes.objectOf(PropTypes.any),
  intl: PropTypes.objectOf(PropTypes.any),
  /* If list is checkable */
  checkable: PropTypes.bool,
  /* If the item is checked */
  checked: PropTypes.bool,
  /* Event that triggers when there is a click in the checkbox */
  onCheckboxClick: PropTypes.func,
  /* Event that triggers when there is a change in the row */
  onChange: PropTypes.func,
  /* Errors for the row */
  errors: PropTypes.objectOf(PropTypes.any),
  /* If the device name must be displayed or not */
  nameless: PropTypes.bool,
};

export default injectIntl(SendCommandItem);
