import React from 'react';
import FormattedMessage from '../../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
      format: {
        pattern: /^[a-z][a-z0-9_]*?$/,
        flags: 'g',
        message: (
          <FormattedMessage
            id="Validation.noUpperCase.noSpecialCharacter.noSpace"
          />
        ),
      },
    },
  },
  {
    key: 'key',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
      format: {
        pattern: /^[a-z][a-z0-9_]*?$/,
        flags: 'g',
        message: (
          <FormattedMessage
            id="Validation.noUpperCase.noSpecialCharacter.noSpace"
          />
        ),
      },
    },
  },
  {
    key: 'type',
    defaultValue: '',
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
      /* format: {
        pattern: "[a-zA-Z0-9-_]+",
        flags: "g" ,
        message: (
          <FormattedMessage
            id={'Validation.noUpperCase.noSpecialCharacter.noSpace'}
          />
        )
      } */
    },
  },
  {
    key: 'available_commands',
    defaultValue: [],
    constraints: {
      presence: {
        message: "can't be empty",
      },
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
];

export default ModelExpected;
