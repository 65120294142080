import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { random } from 'lodash';
import { getDefaultAttributeCount } from '../../../AddLinkedWidget/AddLinkedWizardV2';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import SelectableList from '../../../../../components/SelectableList';
import { getColor } from '../../../Add/components/utils';

import './style.scss';
import ListComponent from './listComponent';
import { SIMPLE_WIDGETS } from '../../../Add/steps/SelectDatasources';

const WIDGETS_WITH_COLORS_ONCHANGE = ['LINES', 'BARS'];

export default function InfoLinked({
  data, entity: Entity, linkedParent,
}) {
  const isSimple = useMemo(() => SIMPLE_WIDGETS.includes(data.widgetType), [data.widgetType]);
  const allAttributes = useMemo(() => {
    const localAttrs = getDefaultAttributeCount(linkedParent);
    return localAttrs
      .map((attr) => ({
        name: attr.name,
        value: attr.name,
        id: attr.name,
      }));
  }, [linkedParent]);

  const selected = useMemo(() => allAttributes.filter(
    (attr) => data.config.data.attributeFilter
      .some((a) => a === attr.name),
  ), [allAttributes, data]);

  const onConfirmationChange = SIMPLE_WIDGETS.includes(data.widgetType) ? {
    add: {
      title: <FormattedMessage id="selectableList.check.change.title" />,
      description: <FormattedMessage id="selectableList.check.change.description" />,
    },
  } : false;

  const handleColorsOnAttributesChange = (entity, newAttributes) => {
    const attribute = entity.widgetType === 'LINES' ? 'data' : 'custom';
    const newColors = { [attribute]: {} };

    newAttributes.forEach((attr) => {
      if (entity.config.colors[attribute]?.[`${attr.name}`]) {
        newColors[attribute][`${attr.name}`] = entity.config.colors[attribute][`${attr.name}`];
      } else {
        newColors[attribute][`${attr.name}`] = getColor(random(0, 12));
      }
    });

    return newColors;
  };

  const onChange = (newAttributes) => {
    const entity = new Entity(data);
    if (WIDGETS_WITH_COLORS_ONCHANGE.includes(entity.widgetType)) {
      entity.config.colors = handleColorsOnAttributesChange(entity, newAttributes);
    }
    entity.config.data.attributeFilter = newAttributes.map((att) => att.name);
    entity.update();
  };

  return (
    <div className="tabSection info-tab">
      <Container>
        <Row className="mb-3">
          <Col>
            <h3><FormattedMessage id="widgets.wizard.step2.title" /></h3>
            <p><FormattedMessage id={`widget.title.${data.widgetType.toLowerCase()}`} /></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              <FormattedMessage id="widgetLinked.profile.attribute-list.title" />
            </h3>
            <SelectableList
              name="attributes"
              data={allAttributes}
              selected={selected}
              fetchInfo={() => null}
              onChange={onChange}
              listComponent={(props) => ListComponent(props, isSimple, data.sources)}
              selectOnTheFly
              onlyOneItem={!!onConfirmationChange}
              onConfirmationChange={onConfirmationChange}
            />
          </Col>
        </Row>
      </Container>
    </div>

  );
}
