import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import { typography } from '../../../configuration/fonts';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import CheckBox from '../../../elements/CheckBox';
import './style.scss';

const BoardStyleHistorical = styled.div`
  font-family: ${typography};
  color: #2B334A;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  z-index: 1;
  position: absolute;
  height: 58px;
  width: 100%;
  bottom: 0px;
  border-radius: 0 0 15px 15px;
  // box-shadow: 0 3px 6px 0 rgba(0,0,0,0.08);
  cursor: pointer;
  display: flex;
  align-content: center;
}
`;

const StyledSlider = styled(ReactSlider)`
  top: 25%;
  width: 100%;
  height: 25px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(32,80,255,0.08);
  border-image: initial;
  background: #F9FAFC;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
`;

const StyledThumb = styled.div`
  width: 8px;
  height: 15px;
  background: #577EE8;
  outline: none;
  margin-left: ${(props) => (props.className.includes('0') ? '-3.5px' : '3.5px')}
}
`;

const StyledValue = styled.p`
  margin-top: 18px;
  margin-left: -12px;
`;

const getValue = (sampling, value) => {
  switch (sampling) {
    case 'lastDay':
    default: {
      const hours = Math.trunc(value / 2).toString().padStart(2, '0');
      const minutes = (value / 2).toFixed(2).toString().split('.')[1] === '50' ? '30' : '00';
      return `${hours}:${minutes}`;
    }
  }
};

const Thumb = (props, state, sampling) => (
  <StyledThumb {...props}>
    <i className="uil uil-bars icon-bars" />
    <StyledValue>{getValue(sampling, state.valueNow)}</StyledValue>
  </StyledThumb>
);

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${(props) => (props.index === 1 ? 'rgba(87,126,232,0.2)' : '#F9FAFC')};
    ${(props) => (props.index === 1 ? '' : 'width: 0px;')};
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const getDefaultValue = (sampling) => {
  switch (sampling) {
    case 'lastDay':
    default:
      return [0, 48];
  }
};

const BoardHistorical = ({ onClick, checked, onChangeSlider, sampling }) => {
  const [defaultValues, setDefaultValues] = useState(getDefaultValue(sampling));
  const [max, setMax] = useState(defaultValues[1]);
  const [min, setMin] = useState(defaultValues[0]);

  useEffect(() => {
    const newDefaultValue = getDefaultValue(sampling);
    setDefaultValues(newDefaultValue);
    setMax(newDefaultValue[1]);
    setMin(newDefaultValue[0]);
  }, [sampling]);

  return (
    <BoardStyleHistorical>
      <CheckBox
        name="historical"
        className="checkbox-historical"
        label={
          <FormattedMessage id="widget.map.enabledHistorical" />
        }
        onClick={onClick}
        checked={checked}
      />
      <StyledSlider
        defaultValue={defaultValues}
        max={max}
        min={min}
        renderTrack={Track}
        renderThumb={(props, state) => Thumb(props, state, sampling)}
        onChange={onChangeSlider}
        minDistance={1}
      />
    </BoardStyleHistorical>
  );
};

BoardHistorical.defaultProps = {
  sampling: 'lastDay',
};

BoardHistorical.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChangeSlider: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  sampling: PropTypes.string,
};

export default BoardHistorical;
