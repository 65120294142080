// eslint-disable-next-line import/no-cycle
import { getTimeFromHistorical } from '../../../utils';
import { getResolution } from '../../../../../helpers/samplingHistorical';

const formatToTableWidget = (historicalValues, configuration, sources, isLinked) => {
  const details = [];
  if ((configuration.data.operation === 'last-value' && configuration.data.type === 'real-time')
  || isLinked) {
    sources.forEach((source) => {
      let data = {};
      historicalValues.forEach((historical) => {
        if (historical.entityId === (source.sourceId)) {
          Object.keys(historical).forEach((key) => {
            if (key === 'attrName') {
              if (historical?.value?.value !== undefined && historical?.value?.value !== null) {
                data[`${historical[key]}`] = historical?.value?.value ?? 0;
              } else {
                data[`${historical[key]}`] = historical.value ?? 0;
              }
            }
            if (!data.recvTime
                  || historical?.value?.metadata?.TimeInstant?.value > data.recvTime) {
              data.recvTime = historical?.value?.metadata?.TimeInstant?.value;
            }
          });
        }
      });
      data.id = source.id;
      if (Object.keys(data).length < 3) data = null;
      details.push({
        source: {
          id: source.id,
          source_id: source.sourceId,
          source_name: source.name,
          hasCommandAttributes: false,
          attributes: source.attributes,
        },
        data,
      });
    });
  } else {
    const operation = configuration.data.operation ?? 'avg';
    sources.forEach((source) => {
      historicalValues.forEach((historical) => {
        if (!historical.entities) return;
        historical.entities.filter((e) => e.entityId === source.sourceId).forEach(
          (dataAttr) => {
            dataAttr.points.forEach((point) => {
              const resolution = getResolution(configuration.data.sampling);
              const xTime = getTimeFromHistorical(resolution, historical.origin, point.offset);
              const datafound = details.find(
                (d) => d.source.time === xTime && d.source.id === source.id,
              );
              if (datafound) {
                datafound.data[dataAttr.attrName] = Number.parseFloat(
                  point[operation],
                ).toFixed(2);
              } else {
                details.push({
                  source: {
                    id: source.id,
                    source_id: source.sourceId,
                    source_name: source.name,
                    hasCommandAttributes: false,
                    attributes: source.attributes,
                    time: xTime,
                  },
                  data: {
                    [dataAttr.attrName]: Number.parseFloat(point[operation]).toFixed(2),
                    recvTime: xTime,
                  },
                });
              }
            });
          },
        );
      });
    });
  }
  return details;
};

export default formatToTableWidget;
