import React, { PureComponent } from 'react';
import Websocket from 'react-websocket';
import { getToken } from '../../helpers';
import { websocketConnection } from '../../configuration/config';

const endPoint = websocketConnection.urlAPI.concat(websocketConnection.paths.socket);

class CustomWebSocket extends PureComponent {
  constructor(props){
    super(props)
  }

  handleOpen = (socket, devicesId) => {
    socket.sendMessage(
      `{ "token":"${getToken()}","devicesId":"${devicesId}" }`
    );
  }

  render() {

    return <Websocket
        url={endPoint}
        onMessage={this.props.onMessage}
        onOpen={() => this.handleOpen(this.WSocket, this.props.devicesId)}
        reconnect={true}
        debug={false}
        ref={Websocket => {
          this.WSocket = Websocket;
        }}
      />
  }
}

export default CustomWebSocket;
