import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CommonView from '../../../pages/CommonView';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import Finder from '../../Finder';
import CheckBox from '../../../elements/CheckBox';
import { withWizardStep } from '../../../Contexts/WizardStepContext';

import iconDashboard from '../../../configuration/icons/svg/ico-dashboard.svg';
import iconExternal from '../../../configuration/icons/svg/ico-external.svg';
import iconKnowage from '../../../configuration/icons/svg/ico-knowage.svg';
import iconParent from '../../../configuration/icons/svg/ico-parent.svg';

const accessInfo = [
  {
    name: <FormattedMessage id="menuElement.access.dashboard.name" />,
    type: 'DASHBOARD',
    description: (
      <FormattedMessage id="menuElement.access.dashboard.description" />
    ),
    icon: iconDashboard,
    selected: false,
  },
  {
    name: <FormattedMessage id="menuElement.access.external.name" />,
    type: 'EXTERNAL',
    description: (
      <FormattedMessage id="menuElement.access.external.description" />
    ),
    icon: iconExternal,
    selected: false,
  },
  {
    name: <FormattedMessage id="menuElement.access.knowage.name" />,
    type: 'KNOWAGE',
    description: (
      <FormattedMessage id="menuElement.access.knowage.description" />
    ),
    icon: iconKnowage,
    selected: false,
  },
  {
    name: <FormattedMessage id="menuElement.access.parent.name" />,
    type: 'PARENT',
    description: (
      <FormattedMessage id="menuElement.access.parent.description" />
    ),
    icon: iconParent,
    selected: false,
  },
];

const AccessWithFinder = Finder((props) => {
  const { data } = props;

  return (
    <div className="accessTypesList">
      <p>
        <FormattedMessage id="menuElement.wizard.typeOfContent" />
        {' '}
        (
        {data.length}
        )
      </p>
      {data.map((access) => (
        <div key={access.name}>
          <p className="title">{access.name}</p>
          <div className="description">
            <div className="icon">
              <img src={access.icon} alt={access.name} />
            </div>
            <div className="text">{access.description}</div>
            <div className="check">
              <CheckBox
                checked={access.selected}
                onClick={() => props.onClick(access.type)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
});

export class SelectAccess extends CommonView {
  constructor(props) {
    super({ props });

    const { type } = this.props.newEntity;

    this.state = {
      errors: this.props.errors,
      onValidation: false,
      access: !type
        ? this.initChecked()
        : this.initChecked(type),
      type: type || '',
    };
  }

isChangedType = () => {
  const { newEntity } = this.props;
  const { type } = this.state;

  return newEntity.type !== type;
};

saveMenuElement = () => {
  const { type } = this.state;
  if (type !== '') {
    if (this.isChangedType()) {
      this.props.onContinue({ resource: { link: {}, type }, type });
    } else {
      this.props.onContinue({ type });
    }
  } else {
    this.props.onError(
      <FormattedMessage
        id="menuElement.wizard.validations.atLeastOneAccess"
      />,
    );
  }
};

validate = () => {
  this.saveMenuElement();
};

initChecked = (type = null) => {
  if (!type) return accessInfo.map((a) => ({ ...a, selected: false }));

  return this.initChecked().map((a) => {
    if (a.type === type) {
      return { ...a, selected: true };
    }
    return { ...a };
  });
};

selectType = (type) => {
  if (this.state.type !== '' && this.state.type === type) {
    this.setState({ type: '', accessInfo: this.initChecked() });
  } else {
    const newAccess = this.initChecked().map((a) => {
      if (a.type === type) {
        return { ...a, selected: true };
      }
      return { ...a };
    });
    this.setState({ type, accessInfo: newAccess });
  }
};

componentDidMount() {
  this.props.onRef(this);
}

render() {
  const { access } = this.state;

  return (
    <>
      <Container>
        <div className="wizardWrapper selectAccess">
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <AccessWithFinder data={access} onClick={this.selectType} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
}

SelectAccess.defaultProps = {
  title: 'title',
  name: '',
  errors: {},
};

const mapStateToProps = (state) => ({
  notify: state.get('notify'),
});

export default connect(mapStateToProps, {})(
  injectIntl(withWizardStep(SelectAccess)),
);
