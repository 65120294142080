import React from 'react';
import { PropTypes } from 'prop-types';
import Wizard from '../../../components/Wizard';
import WizardStep from '../../../components/Wizard/components/WizardStep';

// Steps
import Starter from './steps/Starter';
// If we put ConfigureProtocol between curly brackets, the site crashes
// eslint-disable-next-line import/no-named-as-default
import ConfigureProtocol from '../../helpers/Add/ConfigureProtocol';
// If we put AddProperties between curly brackets, the site crashes
// eslint-disable-next-line import/no-named-as-default
import AddProperties from '../../helpers/Add/AddProperties';
import Success from './steps/Success';

import AddTableButton from '../../../components/AddTableButton';

import { FormattedMessage } from '../../../Contexts/LanguageContext';
import AssignPermissions from '../../helpers/AssignPermissions';
// If we put CreateEntity between curly brackets, the site crashes
// eslint-disable-next-line import/no-named-as-default
import CreateEntity from '../../helpers/Add/CreateEntity';
import Device from '../../../models/Device';
import saveEntity from '../../helpers/Add/utils/saveEntity';
import { isMobile } from '../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../elements/Button';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const addDevicesLabel = (
  <FormattedMessage
    id="devices.add.title"
    defaultMessage="add devices"
    description="Label of add devices"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/devices"
        permission="create"
      >
        <ButtonCustom type="circular" name="plus" onClick={showModal} />
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/devices"
      permission="create"
    >
      <AddTableButton onClick={showModal} label={addDevicesLabel} />
    </PrivateComponent>
  );
};

class CreateDevices extends React.PureComponent {
  render() {
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        entity={Device}
        metadata={{
          wizardType: 'devices',
        }}
      >
        <WizardStep
          component={Starter}
          title={<FormattedMessage id="devices.wizard.step1.title" />}
          lead={<FormattedMessage id="devices.wizard.step1.text" />}
          entity={Device}
        />
        <WizardStep
          component={CreateEntity}
          title={<FormattedMessage id="devices.wizard.step2.title" />}
          lead={<FormattedMessage id="devices.wizard.step2.text" />}
          entity={Device}
        />
        <WizardStep
          component={ConfigureProtocol}
          title={<FormattedMessage id="devices.wizard.step3.title" />}
          lead={<FormattedMessage id="devices.wizard.step3.text" />}
          entity={Device}
        />

        <WizardStep
          component={AddProperties}
          title={<FormattedMessage id="devices.wizard.step4.title" />}
          lead={<FormattedMessage id="devices.wizard.step4.text" />}
          entity={Device}
        />
        <WizardStep
          component={AssignPermissions}
          title={<FormattedMessage id="devices.wizard.step5.title" />}
          lead={<FormattedMessage id="devices.wizard.step5.text" />}
          entity={Device}
          onSave={saveEntity}
        />
        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateDevices;
