import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import Field from '../../../elements/Field';
import { getDataFromCron, makeExampleCronEditable } from '../../../helpers/cron';
import Monthly, {
  Daily, Manually, NumberSelect, Weekly,
} from '../../../components/Scheduler/planner';
import ExpansionLabel from '../../../components/ExpansionLabel';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { getPlannersWithTranslation } from '../../../helpers/utils';

const RenderPlanner = React.memo(({ cron, planner, ...props }) => {
  const [local, setLocal] = useState({ cron, planner });
  const [localPlanner, setLocalPlanner] = useState(local.cron
    ? getDataFromCron(local.cron)
    : makeExampleCronEditable(local.planner));

  useEffect(() => {
    if (cron !== local.cron) {
      setLocal({ cron, planner });
    }
  }, [cron]);

  useEffect(() => {
    if (planner !== local.planner) {
      setLocal({ cron, planner });
      setLocalPlanner(makeExampleCronEditable(planner));
    }
  }, [planner]);

  const getRender = () => {
    switch (local.planner) {
      case 'minutes':
        return (
          <NumberSelect
            {...props}
            minimum={1}
            property="minutes"
            planner={localPlanner}
          />
        );
      // Its necessary both case. Crash if property is updated
      case 'hours':
        return (
          <NumberSelect
            {...props}
            minimum={1}
            property="hour"
            planner={localPlanner}
          />
        );

      case 'daily':
        return <Daily {...props} planner={localPlanner} />;
      case 'manually':
        return <Manually {...props} planner={localPlanner} />;
      case 'weekly':
        return <Weekly {...props} planner={localPlanner} />;
      case 'monthly':
        return <Monthly {...props} planner={localPlanner} />;
      default:
        return <div />;
    }
  };

  return getRender();
});

RenderPlanner.propTypes = {
  cron: PropTypes.string,
  planner: PropTypes.string,
};

RenderPlanner.defaultProps = {
  cron: '',
  planner: '',
};

const Planner = injectIntl(({
  plannerSelected,
  cron,
  handlerPlanner,
  onChangePlanner,
  intl,
}) => (
  <>
    <Field
      name="planner"
      type="select"
      value={plannerSelected}
      onChange={handlerPlanner}
      options={getPlannersWithTranslation(intl)}
    />
    {
      (cron || plannerSelected !== 'manually')
      && <RenderPlanner planner={plannerSelected} cron={cron} setValue={onChangePlanner} />
    }
  </>
));

Planner.propTypes = {
  cron: PropTypes.string,
  plannerSelected: PropTypes.string,
  plannerStrings: PropTypes.arrayOf(PropTypes.string),
  handlerPlanner: PropTypes.func,
  onChangePlanner: PropTypes.func,
};

Planner.defaultProps = {
  cron: '',
  plannerSelected: '',
  plannerStrings: [],
  handlerPlanner: () => {},
  onChangePlanner: () => {},
};

const ExpandablePlanner = ({
  plannerSelected,
  cron,
  opened,
  handlerPlanner,
  onChangePlanner,
}) => (
  <ExpansionLabel
    header={() => <FormattedMessage component={false} id="planner.title" />}
    content={() => (
      <Planner
        plannerSelected={plannerSelected}
        cron={cron}
        handlerPlanner={handlerPlanner}
        onChangePlanner={onChangePlanner}
      />
    )}
    opened={opened}
  />
);

ExpandablePlanner.propTypes = {
  cron: PropTypes.string,
  plannerSelected: PropTypes.string,
  opened: PropTypes.bool,
  handlerPlanner: PropTypes.func,
  onChangePlanner: PropTypes.func,
};

ExpandablePlanner.defaultProps = {
  cron: '',
  plannerSelected: '',
  opened: true,
  handlerPlanner: () => {},
  onChangePlanner: () => {},
};

export default ExpandablePlanner;
