import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import CheckBoxStyled from './CheckBox';

class StatelessCheckbox extends PureComponent {
  onClick = () => {
    const { onChange, name, checked } = this.props;
    !this.props.fixed && onChange(name, !checked);
  };

  render() {
    const { label, className, name, reverse, css, positionCheck, minimal, checked } = this.props;
    return (
      <CheckBoxStyled
        className={className}
        label={label}
        name={name}
        reverse={reverse}
        css={css}
        positionCheck={positionCheck}
        minimal={minimal}
        checked={checked}
        onClick={this.onClick}
      />
    );
  }
}

StatelessCheckbox.defaultProps = {
  name: 'name',
  checked: true,
  reverse: false,
  svg: 'Done',
  css: ``,
  fixed: false,
  onChange: () => {}
};

StatelessCheckbox.propTypes = {
  /** Function to handle checked state*/
  onChange: PropTypes.func,

  /** Label for show to user*/
  label: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),

  /** Name associate to original input*/
  name: PropTypes.string.isRequired,

  /** State of the checkbox*/
  checked: PropTypes.bool,

  /** Direction of the label*/
  reverse: PropTypes.bool,

  /** SVG Icon to show when checkbox is checked*/
  svg: PropTypes.string,

  /** Additional css to add*/
  css: PropTypes.string,

  /** If checkbox must not change */
  fixed: PropTypes.bool,

  /** If true, minimal mode is active. Checkbox has a minimal design */
  minimal: PropTypes.bool
};

export default StatelessCheckbox;
