import * as TYPES from './types'
import { Map, List } from 'immutable';
import { clone, cloneInstance, isShowV2 } from '../../../helpers/utils';
import { remove } from 'lodash'

export const examplePermissionPolicy = ["Group1", "Group2", "Group3", "Group4"];
export const exampleTransport = ["HTTP", "MQTT", "AMQP"];

/** Initial State of Dashboards */
const initialState = Map({
  list: List([]),
  readDashboard: false,
  readDashboardWidgets: false,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  currentLayouts: {}
});

/** DASHBOARDS REDUCER*/
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;
  let widget, dashboard;

  /** Cases */
  switch (type) {
    /** INITIALIZE GET DASHBOARDS FROM DB */
    case TYPES.GET_DASHBOARDS_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);

    /** INITIALIZE GET DASHBOARDS FROM DB */
    case TYPES.GET_DASHBOARDS_ERROR:
    case TYPES.GET_PUBLIC_DASHBOARDS_ERROR:
    case TYPES.GET_MINE_DASHBOARDS_ERROR:
    case TYPES.GET_DASHBOARD_ERROR:
    case TYPES.ADD_DASHBOARD_ERROR:
    case TYPES.MODIFY_DASHBOARD_ERROR:
    case TYPES.DELETE_DASHBOARD_ERROR:
      aux = state.set("errorFetching", payload);
      aux = aux.set("fetching", false);
      return aux;

    /** GET ALL DASHBOARD FROM DB TO STATE */
    case TYPES.GET_DASHBOARDS_SUCCESS:
    case TYPES.GET_MINE_DASHBOARDS_SUCCESS:
    case TYPES.GET_PUBLIC_DASHBOARDS_SUCCESS:
      const dashboardId = payload.dashboards.map(o => o.id);
      const dashboards = state
        .get('list')
        .toJS()
        .filter(o => !dashboardId.includes(o.id));
      aux = state.set('list', List([...dashboards, ...payload.dashboards]));
      aux = aux.set("fetching", false);
      aux = aux.set('total', payload.total);
      return aux;

    case TYPES.GET_MINE_DASHBOARDS_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);

    case TYPES.GET_PUBLIC_DASHBOARDS_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);
    //return state.set('fetching', true);

    /** INITIALIZE GET DASHBOARD FROM DB */
    case TYPES.GET_DASHBOARD_REQUEST:
      //aux = state.set('readDashboard', false);
      aux = state.set("fetching", true)
            .set('errorFetching', false)
            .set('readDashboard', false)
            .set('readDashboardWidgets',[]);
      return aux;

    case TYPES.CLOSE_DASHBOARD_REQUEST:
      aux = state.set("readDashboard", false).set('errorFetching', false);
      return aux;
      
    /** GET DASHBOARD FROM DB TO STATE */
    case TYPES.GET_DASHBOARD_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      if (index === -1) {
        aux = state.update('list', dashboardList => dashboardList.push(payload));
      } else {
        const devices = state.get('list').toJS();
        devices[index] = payload;
        aux = state.set('list', List([...devices]));
      }
      aux = aux.set("readDashboard", payload);
      aux = aux.set("currentLayouts", payload.layoutProperties);
      aux = aux.set("readDashboardWidgets", payload.widgets);
      aux = aux.set("fetching", false);
      aux = aux.update('total', total => total + 1);
      return aux;
    //////// ADD AN DASHBOARD
    /** INITIALIZE ADD DASHBOARD TO DB AND STATE */
    case TYPES.ADD_DASHBOARD_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);

    /** ADD NEW DASHBOARD TO STATE */
    case TYPES.ADD_DASHBOARD_SUCCESS:
    case TYPES.CLONE_REF_DASHBOARD_SUCCESS:
      aux = state.update("list", deviceList => deviceList.push(payload));
      aux = aux.set("fetching", false);
      aux = aux.set("addSuccess", true);
      aux = aux.update('total', total => total + 1 );
      return aux;
    /** SET AS FALSE THE addSuccess STATE FLAG */
    case TYPES.ADD_DASHBOARD_SUCCESS_FLAG:
      return state.set("addSuccess", false);

    //////// UPDATE AN DASHBOARD
    /** INITIALIZE UPDATE DASHBOARD TO DB */
    case TYPES.MODIFY_DASHBOARD_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);

    /** UPDATE DASHBOARD IN STATE */
    case TYPES.MODIFY_DASHBOARD_LAYOUT_SUCCESS:{
      aux = state;
      const newWidgetList = state.get('readDashboardWidgets').map((widget) => {
        if (widget.dashboard) {
          const updateWidget = cloneInstance(widget);
          updateWidget.dashboard.layoutProperties = payload;
          return updateWidget;
        }
        return widget;
      });
      aux = aux.set("readDashboardWidgets", newWidgetList);
      aux = aux.set("currentLayouts", payload);
      return aux;
    }
    case TYPES.MODIFY_DASHBOARD_LAYOUT_WITHOUT_SAVE_SUCCESS:
      if(isShowV2()) {
        index = state.get("list").findIndex(u => u.id === payload.id);
        dashboard = state.get('list').get(index);
        dashboard.layoutProperties = payload.layoutProperties;
        aux =
          index !== -1
            ? state.update("list", dashboardList =>
              dashboardList.splice(index, 1, dashboard)
            )
            : state;
        aux = aux.set("readDashboard", dashboard);
        aux = aux.set("currentLayouts", payload.layoutProperties);
        aux = aux.set("fetching", false);
        aux = aux.set("modifySuccess", true);
        return aux;
      }
    case TYPES.MODIFY_DASHBOARD_SUCCESS:
      index = state.get("list").findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update("list", dashboardList =>
            dashboardList.splice(index, 1, payload)
          )
          : state;
      aux = aux.set("readDashboardWidgets", payload.widgets);
      aux = aux.set("readDashboard", payload);
      aux = aux.set("currentLayouts", payload.layoutProperties);
      aux = aux.set("fetching", false);
      aux = aux.set("modifySuccess", true);
      return aux;
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_DASHBOARD_SUCCESS_FLAG:
      return state.set("modifySuccess", false);

    //////// DELETE AN DASHBOARD
    /** INITIALIZE DELATION OF A DASHBOARD IN DB */
    case TYPES.DELETE_DASHBOARD_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);

    /** DELETE DASHBOARD IN STATE */
    case TYPES.DELETE_DASHBOARD_SUCCESS:
      index = state.get("list").findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update("list", deviceList => deviceList.delete(index))
          : state;
      aux = aux.set("fetching", false);
      aux = aux.update('total', total => total - 1);
      return aux;


    //////// RESET ERROR FETCHING TO FALSE
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_DASHBOARD_RESET:
      return state.set("errorFetching", false);

    /* Clone a ref dashboard */
    case TYPES.CLONE_REF_DASHBOARD_REQUEST:
      return state.set("fetching", true).set('errorFetching', false);

    case TYPES.CLONE_REF_DASHBOARD_ERROR:
    case TYPES.CLONE_NOREF_DASHBOARD_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;


    /* Clone a noref dashboard */
    case TYPES.CLONE_NOREF_DASHBOARD_REQUEST:
      aux = state.set('fetching', true).set('errorFetching', false);
      return aux;

    case TYPES.CLONE_NOREF_DASHBOARD_SUCCESS:
      aux = state.set('cloneNoRefDashboard', payload);
      aux = aux.set('fetching', false);
      return aux;

    case TYPES.MODIFY_DASHBOARD_WIDGET_STATE:
      widget = payload;
      dashboard = isShowV2() ? cloneInstance(state.get('readDashboard')) : clone(state.get('readDashboard'))
      dashboard.widgets =  dashboard.widgets.map(o => o.id === widget.id ? widget : o);
      return state.set('readDashboard', dashboard);

    case TYPES.DELETE_DASHBOARD_WIDGET_STATE:
      dashboard = isShowV2() ? cloneInstance(state.get('readDashboard')) : clone(state.get('readDashboard'))
      const widgetDelete = dashboard.widgets.find((w) => w.id === payload.id);
      if (widgetDelete.container && isShowV2()) {
        const parentWidgetIndex = dashboard.widgets.findIndex(w => w.id === widgetDelete.container);
        remove(dashboard.widgets[parentWidgetIndex].dashboard.widgets, (w) => w.id === payload.id);
        remove(dashboard.widgets[parentWidgetIndex].containedWidgets, (w) => w.id === payload.id);
      }
      if (widgetDelete.containedWidgets) {
        widgetDelete.containedWidgets.forEach((w) => {
          remove(dashboard.widgets, (d) => d.id === w.id);
          Object.keys(dashboard.layoutProperties).forEach((meassure) => {
            remove(dashboard.layoutProperties[meassure], (d) => d.id === w.id);
          })
        })
      }
      remove(dashboard.widgets, (w) => w.id === payload.id)
      Object.keys(dashboard.layoutProperties).forEach(meassure => {
        remove(dashboard.layoutProperties[meassure], (w) => w.i === payload.id)
      })
      return state.set('readDashboard', dashboard).set('currentLayouts', dashboard.layoutProperties);

    case 'CLEAR_STATE':
      return initialState;

    case TYPES.CLEAR_DASHBOARDS_STATE:
      return state.set("list", List([]));

    case TYPES.CLEAR_DASHBOARD_STATE:
      return state.set("readDashboard", false);

    default:
      return state;
  }
};
