import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { getDevices, clearDevicesState } from '../../services/redux/devices/actions';

export const ParagraphContext = React.createContext();

const { Provider } = ParagraphContext;
const ParagraphDataProvider = ({
  children, devices, fetching, total, historicals,
}) => {
  // defaultParams: Para filtrar los params que tengamos ya insertados
  const [defaultParams, setDefaultParams] = useState([]);
  const [params, setParams] = useState([]);
  const [variables, setVariables] = useState([]);
  const [variable, setVariable] = useState(null);
  const [query, setQuery] = useState({ page: 1, size: 6 });
  const [device, setDevice] = useState(null);

  useEffect(() => {
    const { page, size } = query;
    if (!fetching) {
      getDevices({ filters: [], page, size });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const mapToSelect = (o) => ({ name: o.name, value: o });
  const getOptions = useCallback(() => devices && devices.map(mapToSelect), [devices]);

  const handleFetchInfo = useCallback(() => {
    if (total > devices.length) {
      setQuery({ ...query, page: query.page + 1, size: 5 });
    }
  }, [devices.length, query, total]);

  const handleOnSearch = useCallback((value, init = false, offline) => {
    if (offline) return;
    init && clearDevicesState();
    getDevices({ filters: { name: value }, page: 1, size: query.size });
  }, [query.size]);

  const handleSelectChange = useCallback((name, value) => {
    if (name === 'variables') {
      setVariable(value);
      return;
    }
    const mappedArr = value.attributes.map((e) => ({ name: e.name, value: e, deviceId: value.id }));
    const filtered = mappedArr.filter((elem) => (
      !defaultParams?.some(
        (e) => e.device && e.device.id === elem.deviceId && e.name === elem.name,
      )
    ));

    const mappedStaticAttrs = value.static_attributes.map(
      (e) => ({ name: e.name, value: e, deviceId: value.id }),
    );
    const filteredStatics = mappedStaticAttrs.filter(
      (elem) => !defaultParams?.some(
        (e) => e.device && e.device.id === elem.deviceId && e.name === elem.name,
      ),
    );

    setVariables([...filtered, ...filteredStatics]);
    setDevice(value);
  }, [defaultParams]);

  const addParam = () => {
    const paramsToBeSetted = [...params];
    const newParam = { name: variable.name, device };
    paramsToBeSetted.push(newParam);
    setParams(paramsToBeSetted);
    const newVariables = variables.filter((elem) => elem.name !== variable.name);
    setVariables(newVariables);
    setVariable(null);
  };

  return (
    <Provider value={{
      loading: fetching,
      getOptions,
      handleFetchInfo,
      handleOnSearch,
      handleSelectChange,
      device,
      variables,
      variable,
      addParam,
      params,
      setDefaultParams,
      defaultParams,
      historicals,
    }}
    >
      {children}
    </Provider>
  );
};

const mapStateToProps = (state) => ({
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetching: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
  historicals: state
    .get('historicals')
    .toJS(),
});

export default connect(
  mapStateToProps,
  {},
)(ParagraphDataProvider);
