import React, { memo, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { registerThemeWebApp } from '@fiwoo/lib-commons-front';
import colors from '../../../../configuration/colors';
import { typography } from '../../../../configuration/fonts';

const LineChart = React.lazy(
  () => import('@fiwoo/lib-commons-front').then((m) => ({ default: m.WidgetLineChart })),
);

function LinesComponent(props) {
  const {
    values,
    widget,
    onChangeHistoricalConfig,
    intl,
    globalSelection,
  } = props;

  const Skeleton = useMemo(() => widget.skeletonComp, []);

  const localeTranslate = (key, defaultValue) => (
    intl ? intl.formatMessage({ id: key }) : defaultValue
  );

  const echarts = registerThemeWebApp(typography);

  const configTheme = {
    name: 'fiwoo',
    colors,
    typography,
  };

  useEffect(() => {
    if (onChangeHistoricalConfig) {
      onChangeHistoricalConfig(widget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.config?.attributeFilter, widget?.origins, widget?.config?.data, globalSelection]);

  if (values === undefined) {
    return <Skeleton />;
  }
  return (
    <LineChart
      onTranslate={localeTranslate}
      height="100%"
      series={values?.series || []}
      echarts={echarts}
      theme={configTheme}
      config={widget.config}
      intl={intl}
    />
  );
}

export default memo(injectIntl(LinesComponent));
