// --------------------- [IMPORTS] ----------------------- //
import React, { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import ShowWidgets from '../ShowV2/components/showWidgets';
import { getUrnId } from '../../../helpers/utils';
import { getToken, getXDataAccessToken, imLogged } from '../../../helpers';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { getDashboard } from '../../../services/redux/dashboards/actions';
import FiwooLogo from '../../../configuration/icons/svg/logo-fiwoo.svg';
import './styles.scss';
// --------------------- [IMPORTS] ----------------------- //

// --------------------- [MAIN COMPONENT] ----------------------- //
function SharedDashboard(props) {
  // --------------------- [VARIABLES & STATE] ----------------------- //
  const { id } = props;
  // --------------------- [VARIABLES & STATE] ----------------------- //

  // --------------------- [REDUX] ----------------------- //
  const dashboard = useSelector((reduxState) => (
    reduxState.get('dashboards').get('readDashboard')));
  const errorFetching = useSelector((reduxState) => (
    reduxState.get('dashboards').get('errorFetching')));
  // --------------------- [REDUX] ----------------------- //

  // --------------------- [SOCKET] ----------------------- //
  const handleOpenPublicSocket = (socket) => {
    if (dashboard.widgets) {
      let message = {};
      if (dashboard.permissions_policy.worldwide?.active) {
        message.token = getXDataAccessToken();
        message.public = true;
      } else {
        const sourcesUrn = [];
        dashboard.widgets.forEach((w) => {
          if (w.needsSocket) {
            w.sources.forEach((source) => {
              sourcesUrn.push(getUrnId(source.urn));
            });
          }
        });
        message = {
          token: getToken(),
          sourcesUrn: [...new Set(sourcesUrn)].toString(),
        };
      }
      socket.sendMessage(JSON.stringify(message));
    }
  };
  // --------------------- [SOCKET] ----------------------- //

  // --------------------- [LIFECYCLES] ----------------------- //
  useEffect(() => {
    getDashboard({ id, isPublicDashboard: true });
  }, [id]);

  useEffect(() => {
    if (errorFetching.status === 403) {
      if (errorFetching.errors[0].type === 'ForbiddenShareError') {
        if (!imLogged()) {
          // TODO: change this when dashboardv2 is the default mode.
          navigate('/app/dashboard/forbidden');
        } else { // Try to get a private dashboard if authenticated
          getDashboard({ id });
        }
      }

      if (errorFetching.errors[0].type === 'ForbiddenReadError') {
        // TODO: change this when dashboardv2 is the default mode.
        navigate('/app/dashboard/forbidden');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFetching]);
  // --------------------- [LIFECYCLES] ----------------------- //

  // --------------------- [RETURN] ----------------------- //
  return (
    <>
      <div className="publicDashboard">
        <span className="publicDashboardTitle">{dashboard.name}</span>

        <ShowWidgets
          isPublicDashboard={!imLogged()}
          handleOpenSocket={handleOpenPublicSocket}
        />

        <div className="poweredBy">
          <div
            className="poweredByButton"
            role="presentation"
            onClick={() => navigate(`/app/dashboard/${id}`)}
          >
            <div className="buttonTex">
              <span>
                <FormattedMessage id="dashboard.button.poweredBy" />
              </span>
              <img src={FiwooLogo} alt="logotype" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(SharedDashboard);
// --------------------- [MAIN COMPONENT] ----------------------- //
