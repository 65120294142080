import React from 'react';
import DragDropBlackIcon from '../../../configuration/icons/svg/drag-drop-black.svg';
import DragDropWhiteIcon from '../../../configuration/icons/svg/drag-drop-white.svg';
import { EditMenuWizard, EditMenuWizardMobile } from '../Add/AddWizard';
import SecondaryMenu from '../../../configuration/icons/svg/secondary-menu.svg';
import SecondaryMenuWhite from '../../../configuration/icons/svg/secondary-menu-white.svg';
import RemoveConfirmationHOC from './RemoveConfirmation';
import HomeConfirmationHOC from './HomeConfirmation';
import { isMobile } from '../../../helpers/getBreakpoint';
import Menu from '../../Menu';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import PrivateComponent from '../../PrivateComponent/privateComponent';

const RemoveConfirmation = RemoveConfirmationHOC(({ showModal, isSubMenu }) => (
  <i
    className={'uil uil-times-circle'.concat(isSubMenu ? ' i-submenu ' : '')}
    onClick={showModal}
    role="presentation"
  />
));

const RemoveConfirmationMobile = RemoveConfirmationHOC(
  ({ showModal, isSubMenu }) => (
    <div
      onClick={showModal}
      role="presentation"
    >
      <i className={'uil uil-trash'.concat(isSubMenu ? ' i-submenu ' : '')} />
      {' '}
      <FormattedMessage id="options.delete" />
    </div>
  ),
);

const HomeConfirmation = HomeConfirmationHOC(({ showModal, isSubMenu }) => (
  <i
    className={'uil uil-home-alt'.concat(isSubMenu ? ' i-submenu ' : '')}
    onClick={showModal}
    role="presentation"
  />
));

const HomeConfirmationMobile = HomeConfirmationHOC(
  ({ showModal, isSubMenu }) => (
    <div
      onClick={showModal}
      role="presentation"
    >
      <i className={'uil uil-home'.concat(isSubMenu ? ' i-submenu ' : '')} />
      {' '}
      <FormattedMessage id="menu.home.title" />
    </div>
  ),
);

const OptionsMenu = (props) => {
  const { children } = props;
  return (
    <Menu
      fullScreen
      openComponent={() => (
        <i
          className="uil uil-ellipsis-h"
          onClick={props.onClick}
          role="presentation"
        />
      )}
    >
      {children}
    </Menu>
  );
};

OptionsMenu.defaultProps = {
  children: '',
  onClick: () => {},
};

export default function MenuTitle({
  title,
  isSubMenu,
  handleOpenMenu,
  isActive,
  isEditable,
  menuElement,
  handleViewContent,
}) {
  const defaultMenu = () => (
    <>
      <img
        alt="open menu"
        className="uil-list-ul"
        src={!isSubMenu ? SecondaryMenu : SecondaryMenuWhite}
        onClick={handleOpenMenu}
        onKeyPress={() => {}}
        role="presentation"
      />
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/menu"
        permission="delete"
      >
        <RemoveConfirmation
          title={title}
          isSubMenu={isSubMenu}
          menuElement={menuElement}
        />
      </PrivateComponent>
      { isEditable
        ? (
          <PrivateComponent
            checkLogin
            checkPermission
            microsService="/app/menu"
            permission="update"
          >
            <EditMenuWizard menuToEdit={menuElement} isSubMenu={isSubMenu} />
          </PrivateComponent>
        )
        : (
          <PrivateComponent
            checkLogin
            checkPermission
            microsService="/app/menu"
            permission="update"
          >
            <HomeConfirmation
              title={title}
              isSubMenu={isSubMenu}
              menuElement={menuElement}
            />
          </PrivateComponent>
        )}
    </>
  );

  const mobileMenu = () => (
    <>
      <OptionsMenu>
        <ul className="list-unstyled">
          <li>
            { isEditable
              ? (
                <PrivateComponent
                  checkLogin
                  checkPermission
                  microsService="/app/menu"
                  permission="update"
                >
                  <EditMenuWizardMobile
                    menuToEdit={menuElement}
                    isSubMenu={isSubMenu}
                  />
                </PrivateComponent>
              )
              : (
                <PrivateComponent
                  checkLogin
                  checkPermission
                  microsService="/app/menu"
                  permission="update"
                >
                  <HomeConfirmationMobile
                    title={title}
                    isSubMenu={isSubMenu}
                    menuElement={menuElement}
                  />
                </PrivateComponent>
              )}
          </li>
          <li>
            <PrivateComponent
              checkLogin
              checkPermission
              microsService="/app/menu"
              permission="delete"
            >
              <RemoveConfirmationMobile
                title={title}
                isSubMenu={isSubMenu}
                menuElement={menuElement}
              />
            </PrivateComponent>
          </li>
        </ul>
      </OptionsMenu>
      <i
        className="uil uil-angle-right d-md-none"
        alt="open menu"
        src={!isSubMenu ? SecondaryMenu : SecondaryMenuWhite}
        onClick={handleOpenMenu}
        onKeyPress={() => {}}
        role="presentation"
      />
    </>
  );

  const getMenu = () => (isMobile() ? mobileMenu() : defaultMenu());

  return (
    <div
      className={'menu-title-div d-flex justify-content-between align-items-center'.concat(
        isActive ? ' active ' : '',
        isSubMenu ? ' submenu ' : '',
      )}
    >
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/menu"
        permission="update-position"
      >
        <img
          className="d-none d-md-block icon-points-menu"
          src={isSubMenu ? DragDropWhiteIcon : DragDropBlackIcon}
          alt="service"
        />
      </PrivateComponent>
      <div className="pt-3 pb-3 MyNonDraggableAreaClassName">
        <div
          className="menu-title"
          onClick={handleViewContent}
          onKeyPress={() => {}}
          role="presentation"
        >
          <div className="title">{title}</div>
        </div>
        {getMenu()}
      </div>
    </div>
  );
}

MenuTitle.defaultProps = {
  title: '',
  isSubMenu: undefined,
  handleOpenMenu: () => {},
  isActive: undefined,
  isEditable: undefined,
  menuElement: {},
  handleViewContent: () => {},
};
