import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../components/Menu';
import OptionsForCard from './OptionsForCard';

const CardOptionsMenu = (props) => {
  const {
    dashboard, clickView, profileType,
  } = props;

  /* <div>
<div className="text-right">
<i className="uil uil-ellipsis-v" onClick={props.onClick}></i>
</div>
<div className="text-left">
<i class='uil uil-share-alt'></i>
</div>
</div> */

  const ButtonForOpenOptionMenu = (propsVar) => {
    const { onClick } = propsVar;
    return (
      <div>
        <div className="text-right">
          <i
            className="uil uil-ellipsis-v"
            onClick={onClick}
            role="presentation"
          />
        </div>
      </div>
    );
  };

  return (

    <Menu
      top="15px"
/* openComponent={openButton} */
      openComponent={ButtonForOpenOptionMenu}
      openFrom="top right"
      className="more"
      right="20px"
    >

      <OptionsForCard
        dashboard={dashboard}
        clickView={clickView}
        profileType={profileType}
      />
    </Menu>
  );
};

CardOptionsMenu.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
  clickView: PropTypes.func,
  profileType: PropTypes.string,
};

CardOptionsMenu.defaultProps = {
  dashboard: {},
  clickView: () => {},
  profileType: '',
};

const CardMenuButton = (props) => {
  const { title, date, dashboard } = props;

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <CardOptionsMenu {...props} title={title} date={date} dashboard={dashboard} />
  );
};

CardMenuButton.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  dashboard: PropTypes.objectOf(PropTypes.any),
};

CardMenuButton.defaultProps = {
  title: '',
  date: '',
  dashboard: {},
};

export default CardMenuButton;
