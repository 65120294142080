import React from 'react';
import { PropTypes } from 'prop-types';
import WizardStep from '../../../../components/Wizard/components/WizardStep';


import Add from './steps/Add';
import Success from './steps/Success';
import Wizard from '../../../../components/Wizard';
import AddTableButton from '../../../../components/AddTableButton';
import ModalHOC from '../../../../elements/Modal';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'


import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { isMobile } from '../../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../../elements/Button';
import Role from '../../../../models/Role';

const addRoleLabel = (
    <FormattedMessage
        id="roles.add.title"
        defaultMessage="add role"
        description="Label of add role"
    />
);

const ButtonOpen = ({ showModal, fromAnotherComponent }) => {
  if (isMobile())
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/roles'}
        permission={'create'}
      >
        <ButtonCustom type={'circular'} name={'plus'} onClick={showModal} />
      </PrivateComponent>
    );
  else
    return (
      <PrivateComponent
        checkLogin={true}
        checkPermission={true}
        microsService={'/app/roles'}
        permission={'create'}
      >
        {!fromAnotherComponent ? (
          <AddTableButton onClick={showModal} label={addRoleLabel} />
        ) : (
          <button onClick={showModal}>
            <FormattedMessage
              id="users.createRol.button"
              defaultMessage="Create rol"
              description="Text for create rol"
            />
          </button>
        )}
      </PrivateComponent>
    );
};


class CreateRole extends React.Component {
    render() {
        return (
        <Wizard 
          buttonOpen={ButtonOpen}
          entity={Role}
          metadata={{
            wizardType: 'users.roles',
          }}
          >
             <WizardStep
                component={Add}
                title={<FormattedMessage id="roles.add.title" />}
                entity={Role}
              />
            <Success/>
        </Wizard>);
    }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};


export default CreateRole;