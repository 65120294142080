/* eslint-disable import/no-cycle */
import { clone } from 'lodash';
import Widget from '../..';
import {
  KpiConfigurationSheetBasic, KpiConfigurationSheet, KpiDefaultTemplateConfig, validationKpi,
} from './utils/configurationSheet';
import KpiDefaultConfiguration, { KpiDefaultConfigurationForLinked } from './utils/defaultConfiguration';
import KpiComponent from './component';
import {
  getAllDeviceAttributes,
  formatToCommonWidgetSingleDevice,
} from '../../utils';
import formatToLineWidget from '../lines/utils/formatData';
import { ReactComponent as KpiWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-counter.svg';

export const NAME_WIDGET = 'TEXT_ACCOUNTANT';
export default class KpiWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = KpiWidgetIcon;

  skeleton = KpiWidgetIcon;

  defaultConfig = this.isLinked()
    ? ({ ...KpiDefaultConfigurationForLinked })
    : ({ ...KpiDefaultConfiguration });

  templateConfig = KpiDefaultTemplateConfig;

  component = KpiComponent;

  constructor(obj={}) {
    super(obj);
    if (obj.version === 'v1') {
      if (!this.config.data.sampling) this.config.data.sampling = 'lastYear';
    }
  }

  isLinked() {
    return this.typeContainer === 'LINKED';
  }

  // *! --------------------- [COMMON BODY] --------------------- !* //

  injectData = () => null

  getValidatorConfig = () => (config) => validationKpi(config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? KpiConfigurationSheetBasic
      : KpiConfigurationSheet
  )

  getWidgetChildBodyQuery = (data, { allSourcesParsed }) => {
    const { sampling, startDate, endDate } = this.config.data;
    const dataForQueries = clone(data);
    const attributes = getAllDeviceAttributes(this.origins[0].connectedDevices);
    dataForQueries[0].sources = allSourcesParsed;

    const historialQueryBody = this.getDataForQueries({
      type: 'DEVICE',
      devices: this.origins,
      attributes,
      queryType: 'aggregate',
      operation: 'avg',
      sampling,
      periodTime: {
        startDate,
        endDate,
      },
    });
    dataForQueries.push(historialQueryBody);
    return dataForQueries;
  }

  getQueryHistorical() {
    const {
      sampling, startDate, endDate, period,
    } = this.config.data;
    const dataForQueries = this.getEmptyHistoricalBody();
    const historialQueryBody = this.getDataForQueries({
      sources: this.sources,
      queryType: 'aggregate',
      sampling,
      periodTime: {
        startDate,
        endDate,
        period,
      },
    });
    const sources = this.getDataForQueries({ sources: this.sources });
    dataForQueries[0].sources = sources;
    dataForQueries.push(historialQueryBody);
    return dataForQueries;
  }

  formatToData(historicalValues) {
    return [formatToCommonWidgetSingleDevice(
      historicalValues,
    ), formatToLineWidget(historicalValues, this.config)];
  }

  needsSocketForHistorical() {
    return true;
  };
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
