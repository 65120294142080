import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import { getRoles } from '../../../services/redux/roles/actions';
import {
  addDeviceSuccessFlag,
  errorFetchingDeviceReset,
} from '../../../services/redux/devices/actions';
import Field from '../../../elements/Field';
import CommonView from '../../CommonView';
import { addServiceSuccessFlag, errorFetchingServiceReset } from '../../../services/redux/services/actions';
import { protocolData } from '../../../data/protocols';
import { withWizardStep } from '../../../Contexts/WizardStepContext';
import {
  requestOrganizationById,
  requestOrganizationList,
  requestDataSetListByOrganization,
  resetDataSetList,
} from '../../../services/redux/opendata/actions';
import {
  selectOrganizationList,
  selectOrganizationListTotal,
  selectDataSetList,
  selectDataSetListTotal,
  selectErrorOpenData,
  selectFetchingOpenData,
} from '../../../services/redux/opendata/selectors';
import DynamicSelect from './components/opendata/DynamicSelect';
import { opendata } from '../../../services/redux/actions';

const Form = styled.form`
  padding: 30px 0px 0px 0px;
  textarea {
    min-height: 169px;
  }
`;

export const categories = [
  {
    name: <FormattedMessage id="devices.wizard.categories.other" />,
    id: 13,
    value: ['OTHER'],
  },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.luminaire" />,
  //   id: 1,
  //   value: ['LUMINAIRE'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.waste_container" />,
  //   id: 2,
  //   value: ['WASTE_CONTAINER'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.weather_station" />,
  //   id: 3,
  //   value: ['WEATHER_STATION'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.environmental_quality" />,
  //   id: 4,
  //   value: ['ENVIRONMENTAL_QUALITY'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.gps" />,
  //   id: 5,
  //   value: ['GPS'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.parking" />,
  //   id: 6,
  //   value: ['PARKING'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.electric_panel" />,
  //   id: 7,
  //   value: ['ELECTRIC_PANEL'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.health" />,
  //   id: 8,
  //   value: ['HEALTH'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.video_camera" />,
  //   id: 9,
  //   value: ['VIDEO_CAMERA'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.electric_meter" />,
  //   id: 10,
  //   value: ['ELECTRIC_METER'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.water_meter" />,
  //   id: 11,
  //   value: ['WATER_METER'],
  // },
  // {
  //   name: <FormattedMessage id="devices.wizard.categories.irrigation" />,
  //   id: 12,
  //   value: ['IRRIGATION'],
  // },
];

export class CreateEntity extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      device_id: this.props.newEntity.device_id || '',
      description: this.props.newEntity.description || '',
      categories: this.props.newEntity.categories || '',
      organization: this.props.newEntity.organization || '',
      dataset: this.props.newEntity.dataset || '',
      name: this.props.newEntity.name || '',
      errors: this.props.errors,
      // eslint-disable-next-line new-cap
      sampleEntity: new this.props.entity(),
      organization_query: { total: 0, objects: [] },
      dataset_query: { total: 0, objects: [] },
    };
  }

  validate = () => {
    this.saveEntity();
  };

  saveEntity = () => {
    this.setErrors({});
    const obj = this.state;
    // eslint-disable-next-line new-cap
    const newEntity = new this.props.entity(obj);
    const created = newEntity.validate(this.getField());
    if (created) {
      delete created.error;
      this.setErrors(created);
      this.props.onError(created.error);
    } else {
      const { errors, onValidation, ...rest } = this.state;
      this.props.onContinue(rest);
    }
  };

  componentDidMount() {
    const { newEntity } = this.props;
    const { organization } = this.state;
    this.props.onRef(this);

    if (newEntity && newEntity.protocol) {
      protocolData.protocol.find((p) => (
        p.value === this.props.newEntity.protocol).translator && this.translateEntity());
    }
    requestOrganizationById(organization);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach((err) => {
          errors[err.field] = <FormattedMessage id={err.message} />;
        });
        this.setErrors(errors);
        this.errorFetchingEntityReset();
      }
    }

    if (this.props.addSuccess) {
      this.addEntitySuccessFlag();
    }
  }

  errorFetchingEntityReset() {
    const { entityName } = this.props.entity;
    if (entityName === 'Device') {
      errorFetchingDeviceReset();
    } else if (entityName === 'Template') {
      // Todo
      // errorFetchingTemplateReset()
    } else if (entityName === 'Service') {
      errorFetchingServiceReset();
    }
  }

  addEntitySuccessFlag() {
    const { entityName } = this.props.entity;
    if (entityName === 'Device') {
      addDeviceSuccessFlag();
    } else if (entityName === 'Template') {
      // Todo
      // addTemplateSuccessFlag()
    } else if (entityName === 'Service') {
      addServiceSuccessFlag();
    }
  }

  handleSetOrganization = (organization) => {
    this.setState({ organization });
    resetDataSetList();
    requestDataSetListByOrganization({
      organization,
      page: 1,
      size: 5,
      filter: [],
    });
  };

  handleGetOrganizationList = (page, size, filter) => {
    // TODO: Improve this in order to take all the data while scrolling
    requestOrganizationList({ page, size: 10, filter });
  };

  handleGetDatasetList = (page, size, filter) => {
    const { organization } = this.state;
    if (!organization) {
      return;
    }

    requestDataSetListByOrganization({
      organization,
      page,
      size,
      filter,
    });
  };

  handleMapOptions = (o) => ({ name: o.title, value: o.id });

  handleSetDataSet = (dataset) => {
    const { datasetTotal } = this.props;
    if (datasetTotal > 0) this.setState({ dataset });
  };

  getField() {
    const { entityName } = this.props.entity;
    // remove 'entity_type'
    const fields = ['organization', 'dataset', 'name', 'description'];
    if (entityName === 'Device') fields.splice(2, 0, 'device_id');
    return fields;
  }

  render() {
    const {
      intl,
      organizationList,
      organizationTotal,
      datasetList,
      datasetTotal,
      opendataFetching,
    } = this.props;
    const {
      errors,
      sampleEntity,
      organization,
    } = this.state;
    const entityName = Object.entries(
      sampleEntity.plainTranslations,
    )[0][1].id.toLowerCase().split('.')[0].concat('s');

    return (
      <>
        <Container>
          <Form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                {/* <Field
                  name="categories"
                  label={intl.formatMessage({
                    id: `${entityName}.wizard.categories.label`,
                  })}
                  // placeholder={intl.formatMessage({
                  //   id: `${entityName}.wizard.categories.placeholder`,
                  // })}
                  id="categories"
                  value={["OTHER"]}
                  type="select"
                  disabled= {true}
                  onChange={this.handleSelectChange}
                  helperText={errors.categories}
                  error={!!errors.categories}
                  key="categories"
                  options={categories}
                /> */}

                {this.getField().map(
                  (key, i) => (
                    key === 'organization' || key === 'dataset' ? (
                      <DynamicSelect
                        name={key}
                        placeholder={intl.formatMessage({
                          id: `${entityName}.wizard.${key}.placeholder`,
                        })}
                        id={key}
                        total={key === 'organization'
                          ? organizationTotal
                          : datasetTotal}
                        value={this.state[key]}
                        error={!!errors[key]}
                        options={key === 'organization'
                          ? organizationList.toJS()
                          : datasetList.toJS()}
                        helperText={errors[key]}
                        getOptions={key === 'organization'
                          ? this.handleGetOrganizationList
                          : this.handleGetDatasetList}
                        mapOptions={this.handleMapOptions}
                        onChange={this.handleSelectChange}
                        setValue={key === 'organization'
                          ? this.handleSetOrganization
                          : this.handleSetDataSet}
                        key={`${key}-${i}`}
                        disabled={key === 'dataset' && !organization}
                        fetching={opendataFetching}
                      />
                    ) : (
                      <Field
                        name={key}
                        label={intl.formatMessage(
                          sampleEntity.plainTranslations[key],
                        )}
                        placeholder={intl.formatMessage({
                          id: `${entityName}.wizard.${key}.placeholder`,
                        })}
                        id={key}
                        value={this.state[key]}
                        type={key === 'description' ? 'textarea' : 'text'}
                        onChange={this.handleInputChange}
                        helperText={errors[key]}
                        error={!!errors[key]}
                        key={`${key}-${i}`}
                      />
                    )
                  ),
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const entityType = props.entity.entityName.toLowerCase().concat('s');
  return ({
    allowedroles: state.get('roles').get('list').toJS(),
    errorFetching: state.get(entityType).get('errorFetching'),
    addSuccess: state.get(entityType).get('addSuccess'),
    created: state.get(entityType).get(`created${props.entity.entityName}`),
    newPassword: state.get(entityType).get('newPassword'),
    notify: state.get('notify'),
    entityType,
    organizationList: selectOrganizationList(state),
    organizationTotal: selectOrganizationListTotal(state),
    datasetList: selectDataSetList(state),
    datasetTotal: selectDataSetListTotal(state),
    opendataError: selectErrorOpenData(state),
    opendataFetching: selectFetchingOpenData(state),
  });
};

CreateEntity.propTypes = {
  errors: PropTypes.objectOf(PropTypes.any),
  newEntity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    device_id: PropTypes.string.isRequired,
    description: PropTypes.string,
    categories: PropTypes.string,
    organization: PropTypes.string,
    dataset: PropTypes.string,
  }).isRequired,
  entity: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

CreateEntity.defaultProps = {
  errors: {},
};

export default connect(
  mapStateToProps,
  { getRoles },
)(withWizardStep(injectIntl(CreateEntity)));
