import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import {
  categories, Components, options, getCategory,
} from '../../utilsConfigurationSheet';

export const LinesConfigurationSheetBasic = {
  dataConfig: [{
    name: 'dataConfig',
    component: Components.lineData,
    categories: [categories.DATA],
  }],
  legend: [{
    name: 'legendPosition',
    component: Components.select,
    categories: [categories.APPEARANCE],
    options: options.legendPosition,
  }],
  axes: [{
    name: 'xAxis',
    component: Components.text,
    categories: [categories.LABELS],
  }, {
    name: 'yAxis',
    component: Components.text,
    categories: [categories.LABELS],
  }],
};

export const LinesConfigurationSheet = {
  dataConfig: [{
    name: 'dataConfig',
    component: Components.lineData,
    categories: [categories.DATA],
  }],
  legend: [{
    name: 'legendPosition',
    component: Components.select,
    categories: [categories.APPEARANCE],
    options: options.legendPosition,
  }],
  axes: [{
    name: 'xAxis',
    component: Components.text,
    categories: [categories.LABELS],
  }, {
    name: 'yAxis',
    component: Components.text,
    categories: [categories.LABELS],
  }],
  colorsData: [{
    name: 'colorsData',
    component: Components.colorsData,
    categories: [categories.COLORS, categories.LABELS],
  }],
};

export const LinesDefaultTemplateConfig = [
  { type: ['from-date', 'real-time'] },
  {
    sampling: [
      'lastHour',
      'lastDay',
      'lastFifteenDays',
      'lastMonth',
      'lastThreeMonth',
      'lastYear',
    ],
  },
  'startDate',
  'endDate',
  'xAxis',
  'yAxis',
];

export const validationLines = (config, templateConfig = LinesDefaultTemplateConfig) => {
  const constraints = {};
  templateConfig.forEach((property) => {
    if (typeof property !== 'object') {
      const category = getCategory(property);
      if (!constraints[category]) {
        constraints[category] = {
          presence: true,
        };
      }
      constraints[`${category}.${property}`] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.empty" />,
        },
      };

      if ((property === 'endDate' || property === 'startDate') && config.data.type === 'real-time') {
        delete constraints[`${category}.${property}`];
      }
    }
  });

  return validate(config, constraints);
};
