import React, { Component } from 'react'
import Select from '../Select/index';
import { PropTypes } from 'prop-types';

class CustomSelect extends Component {
    
    render() {
        return (
            <>
                <Select {...this.props} type="select" css={`max-height: 300px; overflow: auto; ${this.props.css}`} />
            </>
        )
    }
}

CustomSelect.propTypes = {
    customOptions: PropTypes.func,
    customSelectedOption: PropTypes.func,
}
CustomSelect.defaultProps = {
    css: ''
}

export default CustomSelect;