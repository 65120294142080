import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import {
  addUsersGroup,
  deleteUsersGroup,
  getUsersGroup,
  updateUsersGroup,
} from '../../services/redux/usersGroups/actions';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

/** Class UsersGroup with Utils functions */
export default class UsersGroup extends Utils {
  static entityName = 'UsersGroup';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = UsersGroup;
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  /** Call Redux action for Save UsersGroup in DB and Redux */
  save() {
    const validation = validate(this.getData(), this.constraints);
    return validation === undefined
      ? addUsersGroup({
        ...this.getData(),
      })
      : { error: true, ...validation };
  }

  /** Call Redux action for Update UsersGroup in DB and Redux */
  update() {
    const validation = validate(this.getData(), this.constraints);
    return validation === undefined
      ? updateUsersGroup({ ...this.getData() })
      : { error: true, ...validation };
  }

  delete() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? deleteUsersGroup(this.getData())
      : { error: true, ...validation };
  }

  get() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? getUsersGroup(this.getData())
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels(
    'UsersGroup',
    ModelExpected,
  );

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleUsersGroup = new UsersGroup();
export { SampleUsersGroup };
