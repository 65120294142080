import * as TYPES from './types';
import { store } from '../store';

export const addRule = (Rule) => store.dispatch({
  type: TYPES.ADD_RULE_REQUEST,
  payload: Rule,
});

export const addRuleSuccessFlag = () => store.dispatch({
  type: TYPES.ADD_RULE_SUCCESS_FLAG,
});

export const modifyRule = (Rule) => store.dispatch({
  type: TYPES.MODIFY_RULE_REQUEST,
  payload: Rule,
});

export const modifyRuleCron = (Rule) => store.dispatch({
  type: TYPES.MODIFY_RULE_CRON_REQUEST,
  payload: Rule,
});

export const updateRulePermissions = (PermissionsPolicy) => store.dispatch({
  type: TYPES.MODIFY_RULE_PERMISSIONS_REQUEST,
  payload: PermissionsPolicy,
});

export const modifyRuleSuccessFlag = () => store.dispatch({
  type: TYPES.MODIFY_RULE_SUCCESS_FLAG,
});

export const updateRuleEngine = (Engine) => store.dispatch({
  type: TYPES.MODIFY_RULE_ENGINE_REQUEST,
  payload: Engine,
});

export const deleteRule = (Rule) => store.dispatch({
  type: TYPES.DELETE_RULE_REQUEST,
  payload: Rule,
});

export const getRules = (payload) => store.dispatch({
  type: TYPES.GET_RULES_REQUEST,
  payload,
});

// ACTIONS

export const addRuleAction = (payload) => store.dispatch({
  type: TYPES.ADD_RULE_ACTION_REQUEST,
  payload,
});

export const updateRuleAction = (payload) => store.dispatch({
  type: TYPES.UPDATE_RULE_ACTION_REQUEST,
  payload,
});

export const deleteRuleAction = (payload) => store.dispatch({
  type: TYPES.DELETE_RULE_ACTION_REQUEST,
  payload,
});


export const getMyRules = () => store.dispatch({
  type: TYPES.GET_RULES_REQUEST,
});

export const getRule = (Rule) => store.dispatch({
  type: TYPES.GET_RULE_REQUEST,
  payload: Rule,
});

export const errorFetchingRuleReset = () => store.dispatch({
  type: TYPES.ERRORFETCHING_RULE_RESET,
});

export const clearRulesState = (payload) => store.dispatch({
  type: TYPES.CLEAR_RULES_STATE,
  payload,
});

// Status

export const updateRuleStatus = (Status) => store.dispatch({
  type: TYPES.MODIFY_RULE_STATUS_REQUEST,
  payload: Status,
});
