import React from 'react';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const Footer = ({ onClose, onSend, isValidated }) => (
  <div className="footer">
    <div
      onClick={onClose}
      id="closeProfileButton"
      role="presentation"
    >
      <FormattedMessage id="SendCommandList.button.cancel" />
    </div>
    <div
      onClick={() => isValidated() && onSend()}
      className={!isValidated() ? 'disabled' : 'enabled'}
      id="sendingCommandButton"
      role="presentation"
    >
      <i className="uil uil-check" />
      {' '}
      <FormattedMessage id="SendCommandList.button.sendCommands" />
    </div>
  </div>
);

export default Footer;
