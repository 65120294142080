import React from 'react';
import { connect } from 'react-redux';
import SimpleKpi from '../../../../configuration/icons/svg/kpiSimple.svg';
import ArithmeticalKpi from '../../../../configuration/icons/svg/kpiArithmetical.svg';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import CommonView from '../../../CommonView';
import StarterComponent from '../../../helpers/StarterPage';
import '../styles.scss';

class Starter extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {
      definition: this.props.newEntity.definition,
      option: this.props.newEntity.option, /* simple or arithmetical */
    };
  }

  validate = () => {
    const { option, definition } = this.state;
    if (!option) {
      this.props.onError(
        <FormattedMessage id="kpis.wizard.noTypeSelected" />,
      );
    } else {
      this.props.onContinue({
        definition,
        option,
        errors: '',
      });
    }
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  handleOnClick = (type = 'simple', option) => {
    let definition = {
      type,
      unit: '',
      value: 0,
    };
    if (type !== 'manual') {
      definition = {
        type,
        unit: '',
        value: 0,
        process: {
          step: 'source',
          attribute: '',
          operation: 'last_occurence',
          urn: '',
        },
      };
    }

    this.props.metadata.setKpiType(option);
    this.setState({
      definition,
      option,
    });
  };

  render() {
    const { option } = this.state;
    return (
      <StarterComponent className="kpisStarter">
        <div className="d-flex flex-column flex-md-row align-items-center mainContent">
          <button
            type="button"
            className={option === 'simple' ? 'cardButton selected' : 'cardButton'}
            onClick={() => { this.handleOnClick('calculate', 'simple'); }}
          >
            <img
              src={SimpleKpi}
              alt="create simple automatic kpi"
            />
            <h3>
              <FormattedMessage id="kpi.create.simple.title" />
            </h3>
            <p>
              <FormattedMessage id="kpi.create.simple.text" />
            </p>
          </button>
          <button
            type="button"
            className={option === 'arithmetical' ? 'cardButton selected' : 'cardButton'}
            onClick={() => { this.handleOnClick('calculate', 'arithmetical'); }}
          >
            <img
              src={ArithmeticalKpi}
              alt="create automatic kpi with arithmetical operations"
            />
            <h3>
              <FormattedMessage id="kpi.create.arithmetical.title" />
            </h3>
            <p>
              <FormattedMessage id="kpi.create.arithmetical.text" />
            </p>
          </button>
        </div>
      </StarterComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  templates: state
    .get('templates')
    .get('list')
    .toJS(),
  total: state.get('templates').get('total'),
  fetching: state.get('templates').get('fetching'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(Starter));
