/* eslint-disable import/no-cycle */
import Moment from 'moment';
import {
  sum, max, min, mean, pullAll, round, cloneDeep,
} from 'lodash';
import Widget from '.';
import OldWidget from '../Widget';
import widgetModels from './inherited';
import { getUrnId, isShowV2 } from '../../helpers/utils';

export const getAllDeviceAttributes = (device, exclude = []) => {
  const deviceCopy = cloneDeep(device);
  exclude.forEach((o) => { deviceCopy[o] = undefined; });
  return [
    ...(deviceCopy && deviceCopy.attributes ? deviceCopy.attributes : []),
    ...(deviceCopy && deviceCopy.lazy_attributes ? deviceCopy.lazy_attributes : []),
    ...(deviceCopy && deviceCopy.command_attributes ? deviceCopy.command_attributes : []),
    ...(deviceCopy && deviceCopy.static_attributes ? deviceCopy.static_attributes : []),
    ...(deviceCopy && deviceCopy.fields ? deviceCopy.fields : []),
  ];
};

const buildQueryForDevice = (origin, attr, aggregate, configuration, size) => {
  const connectedDevice = origin.connectedDevices;
  const { readDevice } = connectedDevice;
  const defaultFiware = { service: 'service', servicepath: '/fiwoo' };
  const fiware = readDevice
    ? readDevice.fiware
    : defaultFiware;
  const entityType = readDevice ? readDevice.entity_type : '';
  return {
    id: connectedDevice.device_id,
    entity_type: entityType,
    attribute: attr,
    fiware,
    aggregate,
    configuration,
    size,
  };
};

const buildQueryForETL = () => (
  // We need the V2 of historical to use that (so far, it is branch 19-etl-query of historical)
  // Here is an exemple of what it will look like
  {
    type: 'standard',
    urn: '', // It will be something like`${fiwoo:etl:5fa3d550517cd304299a1b9e}`
    query: {},
    order: [
    ],
    pagination: {
      page: 1,
      size: 1,
    },
  });

const buildQueryForKPI = (origin, aggregate, configuration, size) => {
  const connectedDevice = origin.connectedDevices;
  const { readDevice } = connectedDevice;
  const defaultFiware = { service: 'service', servicepath: '/fiwoo' };
  const fiware = readDevice
    ? readDevice.fiware
    : defaultFiware;
  const entityType = readDevice?.entity_type ?? '';
  return {
    id: connectedDevice.id,
    entity_type: entityType,
    attribute: undefined,
    fiware,
    aggregate,
    configuration,
    size,
  };
};

export const buildQuery = (origin, aggregate, configuration, size = 1, attr = undefined) => {
  switch (origin?.type) {
    case 'KPI':
      return buildQueryForKPI(origin, aggregate, configuration, size);
    case 'ETL':
      return buildQueryForETL();
    case 'DEVICE':
    default:
      return buildQueryForDevice(origin, attr, aggregate, configuration, size);
  }
};

export const getTimeFromHistorical = (time, origin, offset) => {
  let xTime;
  if (time) {
    switch (time) {
      case 'year':
        xTime = `${Moment.utc(origin).local().format('MM-DD')}-${offset.toString()}`;
        break;
      case 'moth':
        xTime = `${offset.toString().padStart(2, '0')}-${Moment.utc(origin).local().format('DD-YYYY')}`;
        break;
      case 'day':
        xTime = Moment.utc(origin).local().format('YYYY/MM/DD');
        xTime = xTime.slice(0, -2) + offset.toString().padStart(2, '0');
        break;

      case 'hour':
        xTime = `${Moment.utc(origin).local().format('YYYY/MM/DD')} ${offset.toString().padStart(2, '0')}:00`;
        break;
      case 'minute':
        xTime = `${Moment.utc(origin).local().format('YYYY/MM/DD HH')}:${offset.toString().padStart(2, '0')}`;
        break;

      default:
        xTime = `${Moment.utc(origin).local().format('YYYY/MM/DD HH')}:${offset.toString().padStart(2, '0')}`;
        break;
    }
  }
  return xTime;
};

export const getDataFromUrn = (urn) => {
  const urnArray = urn.split(':');
  return urnArray[2];
};

export const calculate = (values, operation, decimals = 2) => {
  const cleanValues = [...values].map((o) => Number(o));
  pullAll(cleanValues, [null, NaN, undefined]);
  if (cleanValues.length) {
    switch (operation) {
      case 'min':
        return round(min(cleanValues), decimals);
      case 'max':
        return round(max(cleanValues), decimals);
      case 'sum':
        return round(sum(cleanValues), decimals);
      case 'avg':
        return round(mean(cleanValues), decimals);
      default:
        return null;
    }
  }
  return null;
};

export const getValuesOfAttribute = (data, attributeToExtract) => {
  const values = [];
  data.forEach((device) => {
    device.attrName === attributeToExtract && values.push(Number(device?.value?.value));
  });
  return values;
};

export const calculateSimpleLastValue = (values, attribute, operation, selection, precision) => {
  const lastValues = values.filter((v) => v.urn);
  const data = lastValues.filter((v) => {
    const deviceId = getUrnId(v.urn);
    return selection.includes(deviceId) && v.value;
  });
  const allValues = getValuesOfAttribute(data, attribute);
  return calculate(allValues, operation, precision);
};

export const mergeAggregateOrigins = (widget, globalObj, selection) => {
  const originsSelected = widget.origins.filter((o) => selection.includes(o.connectedDevices.id));
  const originSingleSelected = originsSelected.filter(
    (o) => o.connectedDevices?.historicaldata.find(
      (h) => h.attribute === widget.config.data.attributeFilter[0] && h.data?.length,
    ),
  );
  const newWidgetOrigins = [cloneDeep(originSingleSelected[0])];

  const oldHistorical = newWidgetOrigins[0].connectedDevices.historicaldata.find(
    (h) => h.attribute === widget.config.data.attributeFilter[0],
  );

  newWidgetOrigins[0].connectedDevices.historicaldata[1].data = Object.keys(globalObj)
    .map((origin) => {
      const id = {
        ...oldHistorical.data[0].id,
        origin,
      };
      const points = Object.keys(globalObj[id.origin]).map((originDate) => ({
        offset: parseInt(originDate, 10),
        ...globalObj[id.origin][originDate],
      }));

      return { id, points };
    });
  return newWidgetOrigins;
};

export const formatToCommonWidgetSingleDevice = (values) => values[0]?.value?.value;

export const updateLastValueBySocket = (historicals, newData) => {
  const updatedHistoricals = cloneDeep(historicals);
  Object.keys(historicals).forEach((widgetId) => {
    historicals[widgetId].forEach((device, deviceIndex) => {
      if (device.entityId === newData.id && newData[device.attrName]) {
        updatedHistoricals[widgetId][deviceIndex].value.value = newData[
          updatedHistoricals[widgetId][deviceIndex].attrName].value;
        updatedHistoricals[widgetId][deviceIndex].value.metadata = newData[
          updatedHistoricals[widgetId][deviceIndex].attrName].metadata;
      }
    });
  });
  return updatedHistoricals;
};

export const formatSourceForWidget = (source) => {
  let newSource = [];
  switch (source.constructor.entityName) {
    case 'Device':
      newSource = {
        fields: getAllDeviceAttributes(source, ['command_attributes']).map((att) => att.name),
        urn: `fiwoo:${source.constructor.name.toLowerCase()}:${source.id}`,
      };
      break;
    default:
      break;
  }
  return newSource;
};

export const getAlias = (source, attribute, isMulti) => {
  switch (source.type) {
    case 'Device':
      return isMulti ? `${source.sourceId}-${attribute}` : attribute;
    case 'Kpi':
      return source.name;
    default:
      return 'no-alias';
  }
};

const allWidgetModels = {
  LINKED: widgetModels.LinkedWidget,
  MAP: widgetModels.MapWidget,
  HEATMAP: widgetModels.HeatMapWidget,
  BARS: widgetModels.BarsWidget,
  TEXT_ACCOUNTANT: widgetModels.KpiWidget,
  LINES: widgetModels.LinesWidget,
  NEEDLE: widgetModels.NeedleWidget,
  IMAGE: widgetModels.ImageWidget,
  HTML: widgetModels.HtmlWidget,
  PERCENTAGE: widgetModels.PercentageWidget,
  TABLE: widgetModels.TableWidget,
  PARAMETRIZED_TEXT: widgetModels.ParametrizedTextWidget,
  ONOFF: widgetModels.OnOffWidget,
  SLIDER: widgetModels.SliderWidget,
};

export const genericModel = (listModels, widget, noInstanced) => {
  const Model = listModels[widget.widgetType] ?? Widget;
  return noInstanced ? Model : new Model(widget);
};

export const genericChildrenModelParser = (listModels, widget, noInstanced, typeContainer) => {
  const model = genericModel(listModels, widget, noInstanced);
  if (!noInstanced) model.typeContainer = typeContainer;
  return model;
};

export const getWidgetModel = (widget, noInstanced) => {
  if (!isShowV2()) return new OldWidget(widget);
  if (!widget.typeContainer) {
    return genericModel(allWidgetModels, widget, noInstanced);
  }
  const Model = allWidgetModels[widget.typeContainer];
  return Model.getChildModel(widget, noInstanced);
};

const getLimitOrigin = (date, sampling) => {
  const DATE_FORMAT_WITH_MS = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';
  switch (sampling) {
    case 'lastHour':
      return {
        offset: date.minutes(),
        origin: date.subtract(1, 'hour').set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    case 'lastDay':
      return {
        offset: date.minutes(),
        origin: date.subtract(1, 'day').set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    case 'lastFifteenDays':
      return {
        offset: date.hour(),
        origin: date.subtract(15, 'days').set('hour', 0).set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    case 'lastMonth':
      return {
        offset: date.hour(),
        origin: date.subtract(1, 'month').set('hour', 0).set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    case 'lastThreeMonth':
      return {
        offset: date.date(),
        origin: date.subtract(3, 'months').set('date', 1).set('hour', 0).set('minute', 0)
          .set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    case 'lastYear':
    default:
      return {
        offset: date.date(),
        origin: date.subtract(1, 'year').set('date', 1).set('hour', 0).set('minute', 0)
          .set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
  }
};

export const removeDeprecatedHistoricals = (historicals, sampling) => {
  const limitOrigin = getLimitOrigin(Moment.utc(), sampling);
  let clonedHistoricals = cloneDeep(historicals);

  clonedHistoricals = clonedHistoricals.filter((historical) => {
    const historicalTime = Moment.utc(historical.origin);
    return historicalTime.isSameOrAfter(Moment.utc(limitOrigin.origin));
  });

  const index = clonedHistoricals.findIndex(
    (historical) => historical.origin === limitOrigin.origin,
  );

  if (index !== -1) {
    clonedHistoricals[index].entities = clonedHistoricals[index].entities.map((entity) => {
      const points = entity.points.filter((point) => point.offset >= limitOrigin.offset);
      return points.length ? { ...entity, points } : undefined;
    }).filter((o) => o);
  }

  return clonedHistoricals;
};

export const getDateOrigin = (date, resolution) => {
  const DATE_FORMAT_WITH_MS = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';
  switch (resolution) {
    case 'minute': {
      return {
        offset: date.minutes(),
        origin: date.set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    }
    case 'hour': {
      return {
        offset: date.hour(),
        origin: date.set('hour', 0).set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    }
    case 'day':
    default: {
      return {
        offset: date.date(),
        origin: date.set('date', 1).set('hour', 0).set('minute', 0).set('seconds', 0)
          .format(DATE_FORMAT_WITH_MS),
      };
    }
  }
};
