/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import Faker from 'faker';
import { range } from 'ramda';
import { opendataManagement } from '../../configuration/config';
import parseFilters from '../../helpers/parseFilters';
import { requestAPI } from '../../helpers';

const endPointBase = opendataManagement.urlAPI.concat(opendataManagement.paths.opendata);
const endPoint = opendataManagement.urlAPI.concat(opendataManagement.paths.organization);

const getParams = (page, size, filters) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';

  return { queryPage, querySize, queryFilters };
};

const exampleOrganizationFn = (OrganizationsToGenerate = 10) => {
  const OrgFaker = range(0, OrganizationsToGenerate).map(() => ({
    id: Faker.random.uuid(),
    title: Faker.random.word(),
  }));
  OrgFaker.unshift({
    id: 'b7f723f0-22df-47fb-b210-486bcc4dd351',
    title: 'Organizacion de Muestra',
  });
  return { organizations: OrgFaker };
};

export const readOrganizations = async (page, size, filters, type) => {
  const { queryPage, querySize, queryFilters } = getParams(page, size, filters);

  const url = `${endPoint}/list/${type}?${queryPage}&${querySize}${queryFilters}`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'organizations',
  };

  return requestAPI(params, exampleOrganizationFn);
};

const exampleDataSetFn = (DataSetToGenerate = 10) => {
  const DSFaker = range(0, DataSetToGenerate).map(() => ({
    id: Faker.random.uuid(),
    title: Faker.random.word(),
  }));
  DSFaker.unshift({
    id: 'b7f723f0-22df-47fb-b210-486bcc4dd351',
    title: 'DataSet de Muestra',
  });
  return { dataset: DSFaker };
};

const exampleDataSetUniqueFn = (dataset) => {
  const newObject = JSON.parse(dataset);
  const DSFaker = {
    ...newObject,
    id: Faker.random.uuid(),
    description: Faker.random.word(),
    fiware: Faker.random.uuid(),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return { dataset: DSFaker };
};

export const readDataSet = async (page, size, filters, gis) => {
  const { queryPage, querySize, queryFilters } = getParams(page, size, filters);
  const queryGIS = gis === true ? '&geoserver' : '&geoserver=!';
  const url = `${endPointBase}/dataset/list?${queryPage}&${querySize}${queryFilters}${
    gis === undefined ? '' : queryGIS
  }`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'dataset',
  };

  return requestAPI(params, exampleDataSetFn);
};

export const readDataSetByOrganization = async (organization, page, size, filters) => {
  const { queryPage, querySize, queryFilters } = getParams(page, size, filters);

  const url = `${endPoint}/${organization}/dataset/list?${queryPage}&${querySize}${queryFilters}&autogenerated=!true`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'dataset',
  };

  return requestAPI(params, exampleDataSetFn);
};

export const addDataSet = async (organization, dataset_title) => {
  const params = {
    endPoint: `${endPoint}/${organization}/dataset`,
    statusOK: 201,
    toJSON: true,
    returnData: 'dataset',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify({ title: dataset_title }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataSetUniqueFn);
};

export const removeDataSetSubscription = (objects, type) => {
  const params = {
    endPoint: `${endPointBase}/unsubscribe/${type}`,
    statusOK: 204,
    toJSON: false,
    returnData: 'devices',
    requestConfig: {
      method: 'DELETE',
      body: JSON.stringify(objects),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataSetFn);
};

export const publishDataSetSubscription = (objects, type) => {
  const params = {
    endPoint: `${endPointBase}/subscribe/${type}`,
    statusOK: 204,
    toJSON: false,
    returnData: type,
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(objects),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataSetFn);
};

export const createGISLayer = (object, dataset) => {
  const params = {
    endPoint: `${endPointBase}/subscribe/dataset/${dataset}/gis`,
    statusOK: 201,
    toJSON: false,
    returnData: 'dataset',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(object),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataSetFn);
};

export const getOrganization = async (organization) => {
  const url = `${endPoint}/${organization}`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'organization',
  };

  return requestAPI(params, exampleDataSetFn);
};

export const getDataSet = async (organization, dataset) => {
  const url = `${endPoint}/${organization}/dataset/${dataset}`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'dataset',
  };

  return requestAPI(params, exampleDataSetFn);
};
