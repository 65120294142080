import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import Router from './router';
/**
 * Contexts
 * */

// Redux
import { store } from './services/redux/store';

// Theme Handle
import { ThemeProvider } from './Contexts/ThemeContext';

// Language Handle
import { LanguageProvider } from './Contexts/LanguageContext';

// Menu Handle
import { MenuCtxtProvider } from './Contexts/MenuContext';

// Blanket Handle
import { BlanketCtxtProvider } from './Contexts/BlanketContext';


// Dashboard Handle

import './configuration/icons/css/unicons.css';

import './general_styles.scss';

/** General CSS. Affect to all project */
import DashboardProvider from './Contexts/DashboardContext/provider';

/** Root Component of all App* */
const Index = (props) => (
  <Provider store={store}>
    <ThemeProvider>
      <LanguageProvider>
        <BlanketCtxtProvider>
          <MenuCtxtProvider>
            <DashboardProvider>
              <Router {...props} />
            </DashboardProvider>
          </MenuCtxtProvider>
        </BlanketCtxtProvider>
      </LanguageProvider>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<Index />, document.getElementById('root'));
