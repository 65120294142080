import React from 'react';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';

const Title = ({ devicesInfo }) => (
  <div className="title">
    <h1>
      <FormattedMessage id="SendCommandList.select.device.title" />
      {' '}
      {`(${devicesInfo.enabled}/${devicesInfo.total})`}
    </h1>
    <p>
      <FormattedMessage id="SendCommandList.select.device.text" />
    </p>
    <hr />
  </div>
);

export default Title;
