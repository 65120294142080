import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { complexTextarea } from './ComplexTextarea.module.scss';
import Tags from './Tags';

const ComplexTextarea = ({
  tags, onChange, name, label, placeholder, value, intl,
}) => {
  const reference = useRef();
  const settings = {
    enforceWhitelist: true,
    mode: 'mix',
    pattern: /#/,
    duplicates: false,
    dropdown: {
      enabled: 1,
      position: 'text',
    },
    whitelist: tags,
    callbacks: {
      input: onChange,
      add: onChange,
      remove: onChange,
    },
  };

  return (
    <Tags
      mode="textarea"
      className={complexTextarea}
      settings={settings}
      name={name}
      label={label}
      ref={reference}
      onChange={onChange}
      value={value}
    />

  );
};

ComplexTextarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  intl: PropTypes.objectOf(PropTypes.object).isRequired,
};

ComplexTextarea.defaultProps = {
  label: 'Textarea',
  placeholder: 'Write here...',
  name: 'TextareaName',
  value: '',
};

export default injectIntl(ComplexTextarea);
