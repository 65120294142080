import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SwitchDataSourceMode from '../../../Kpis/Add/steps/Components/SwitchDataSourceMode';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import SelectableList from '../../../../components/SelectableList';
import ListComponent from '../../../Widgets/Add/components/ListComponent';
import Field from '../../../../elements/Field';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import ListComponentETL from '../../../Kpis/Add/steps/Components/ListComponentETL';

const SimpleSourceOfDataKPI = (props) => {
  const {
    mode,
    options,
    selected,
    errors,
    value,
    unit,
    fetching,
    openedPanels,
    handleChangeMode,
    handleManualChange,
    handleOnSearch,
    handleFetchInfo,
    handleSelectableListChanges,
    handleOnPanelOpen,
    handleAttributeStatusChange,
    isProfile,
    intl,
    filter,
    selectableFilter,
  } = props;

  return (
    <Container>
      <Row>
        <Col lg={{ size: 4, offset: isProfile ? 0 : 4 }}>
          <SwitchDataSourceMode
            className={isProfile ? 'SwitchDataSourceModeProfile' : ''}
            mode={mode}
            onChangeMode={handleChangeMode}
          />
        </Col>
      </Row>

      <div>
        <Row>
          <Col lg={{ offset: isProfile ? 0 : 3 }} xl={{ offset: isProfile ? 0 : 3 }}>
            <div className="info-circle">
              <p className="uil-info-circle iconify ">
                <span className="info-text">
                  {mode === 'simple' && (
                    <FormattedMessage id="kpis.wizard.step3.automatic.info" />
                  )}
                  {mode === 'manual' && (
                    <FormattedMessage id="kpis.wizard.step3.manual.info" />
                  )}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {mode === 'simple' && (
          <Row>
            <Col lg={{ size: isProfile ? 8 : 4, offset: isProfile ? 0 : 4 }}>
              <SelectableList
                data={options}
                selected={selected}
                name="datasources"
                translatePrefix="kpis.wizard"
                error={errors}
                listComponent={filter === 'devices' ? (listProps) => ListComponent(
                  listProps,
                  handleAttributeStatusChange,
                  undefined,
                  handleOnPanelOpen,
                )
                  : (listProps) => ListComponentETL(
                    listProps,
                    handleAttributeStatusChange,
                    undefined,
                    handleOnPanelOpen,
                  )}
                onChange={handleSelectableListChanges}
                fetchInfo={handleFetchInfo}
                loading={fetching}
                openedPanels={openedPanels}
                onSearch={handleOnSearch}
                filterBy="name"
                selectOnTheFly
                uniqueValue
                filter={selectableFilter}
              />
            </Col>
          </Row>
        )}

        {mode === 'manual' && (
          <Row>
            <Col lg={{ size: isProfile ? 4 : 2, offset: isProfile ? 0 : 4 }}>
              <Field
                name="value"
                label={intl.formatMessage({
                  id: 'Kpi.model.value',
                })}
                placeholder={intl.formatMessage({
                  id: 'Kpi.model.value',
                })}
                id="value"
                value={value}
                type="number"
                onChange={handleManualChange}
                helperText={errors.value}
                error={!!errors.value}
                key="value-kpiCreationwizard"
              />
            </Col>
            <Col lg={{ size: isProfile ? 4 : 2 }}>
              <Field
                name="unit"
                label={intl.formatMessage({
                  id: 'Kpi.model.unit',
                })}
                placeholder={intl.formatMessage({
                  id: 'Kpi.model.unit',
                })}
                id="unit"
                value={unit}
                type="text"
                onChange={handleManualChange}
                helperText={errors.unit}
                error={!!errors.unit}
                key="unit-kpiCreationwizard"
              />
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

SimpleSourceOfDataKPI.propTypes = {
  mode: PropTypes.string,
  selectableFilter: PropTypes.objectOf(PropTypes.any),
  filter: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.number,
  unit: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.any),
  fetching: PropTypes.bool,
  openedPanels: PropTypes.arrayOf(PropTypes.any),
  isProfile: PropTypes.bool,
  handleChangeMode: PropTypes.func,
  handleManualChange: PropTypes.func,
  handleOnSearch: PropTypes.func,
  handleFetchInfo: PropTypes.func,
  handleSelectableListChanges: PropTypes.func,
  handleOnPanelOpen: PropTypes.func,
  handleAttributeStatusChange: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any),
};

SimpleSourceOfDataKPI.defaultProps = {
  mode: 'manual',
  filter: 'devices',
  options: undefined,
  selected: undefined,
  value: undefined,
  unit: undefined,
  errors: undefined,
  fetching: false,
  openedPanels: [],
  isProfile: false,
  handleChangeMode: undefined,
  handleManualChange: undefined,
  handleOnSearch: undefined,
  handleFetchInfo: undefined,
  handleSelectableListChanges: undefined,
  handleOnPanelOpen: undefined,
  handleAttributeStatusChange: undefined,
  intl: undefined,
  selectableFilter: undefined,
};

export default withWizardStep(injectIntl(SimpleSourceOfDataKPI));
