import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// const ButtonAutoZoom = styled.div`
//   right: 1em;
//   bottom: 5.2em;
//   z-index: 1;
//   position: absolute;
//   height: 30px;
//   width: 30px;
//   border-radius: 3px;
//   background-color: #FFFFFF;
//   box-shadow: 0 3px 6px 0 rgba(0,0,0,0.08);
//   cursor: pointer;
// }
// `;




const ZoomOut = (props) => {
  const conlog = () => {
    props.zoomOut();
  };
  return (
    <div  className='mapZoomButton' onClick={conlog}>
      <i className="uil uil-minus"></i>
    </div>
  );
};

ZoomOut.propTypes = {
  zoomOut: PropTypes.func.isRequired,
};

export default ZoomOut;
