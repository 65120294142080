import './styles.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import OptionsForWidgetToImageAddButton from './OptionsForWidgetImageAddButton';

const WidgetImageOptions = ({ dashboard, widgetImageId, actions }) => {
  const [pressed, setPressed] = useState(false);

  return (
    <div className="divAddWidgetToImage">
      <span>
        <button className="addWidgetToImageButton" type="button" onClick={() => setPressed(!pressed)}>
          <i className="uil uil-edit" />
          <p className="addWidgetToImageButtonText">
            <FormattedMessage id="widgetImage.button.addWidget" />
          </p>
        </button>
        <div className="actions">{actions}</div>
      </span>
      {pressed
        && (
        <div className="divaddWidgetToImageOptions">
          <OptionsForWidgetToImageAddButton dashboard={dashboard} widgetImageId={widgetImageId} />
        </div>
        )}
    </div>
  );
};

WidgetImageOptions.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
  widgetImageId: PropTypes.string,
};

WidgetImageOptions.defaultProps = {
  dashboard: {},
  widgetImageId: '',
};

export default WidgetImageOptions;
