const tempColors = [[0, 0, 255], [50, 178, 24], [255, 0, 0]];

const convertColor = (colorArray) => `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;

const layerPaintSolid = (tempRange) => ({
  'circle-radius': {
    type: 'exponential',
    stops: [[2, 150], [6, 150]],
  },
  'circle-color': {
    property: 'value', // we have to do "" + prop to make it a string
    type: 'exponential',
    stops: [
      [tempRange[0], convertColor(tempColors[0])],
      [tempRange[1], convertColor(tempColors[1])],
      [tempRange[2], convertColor(tempColors[2])],
    ],
  },
  'circle-opacity': {
    property: 'value',
    type: 'exponential',
    stops: [[-99, 0.4], [-50, 0.4]],
  },
  'circle-blur': 0.7,
});

export default layerPaintSolid;
