/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import Widget from '../..';
import {
  LinesConfigurationSheetBasic,
  LinesConfigurationSheet,
  LinesDefaultTemplateConfig,
  validationLines,
} from './utils/configurationSheet';
import LinesDefaultConfiguration, { injectLinesData } from './utils/defaultConfiguration';
import LinesComponent from './component';
import formatToLineWidget from './utils/formatData';
import { ReactComponent as LinesWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-line.svg';

export const NAME_WIDGET = 'LINES';
export default class LinesWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = LinesWidgetIcon;

  hasMultipleAttributes = true;

  hasMultipleSources = true;

  skeleton = LinesWidgetIcon;

  defaultConfig = ({ ...LinesDefaultConfiguration });

  component = LinesComponent;

  templateConfig = LinesDefaultTemplateConfig;

  constructor(obj = {}) {
    super(obj);
    if (obj.version === 'v1') {
      const { colors, labels, data } = this.config;
      const { alias } = labels;
      const newColors = {};
      const newAlias = {};
      Object.keys(colors).forEach((sourceId) => {
        Object.keys(colors[sourceId]).forEach((attr) => {
          newColors[`${sourceId}-${attr}`] = colors[sourceId][attr];
          newAlias[`${sourceId}-${attr}`] = alias?.[`${attr}-${sourceId}`] ?? `${sourceId}-${attr}`;
        });
      });
      this.config.colors = newColors;
      this.config.labels.alias = newAlias;
      if (!this.config.appearance.legendPosition) this.config.appearance.legendPosition = 'top';
      this.config.data.type = (data.startDate && data.endDate) ? 'historical' : 'real-time';
    }
  }

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => (config) => validationLines(config)

  injectData = (widget) => injectLinesData(widget)

  getConfigurationSheet = (basic = true) => (
    basic
      ? LinesConfigurationSheetBasic
      : LinesConfigurationSheet
  )

  getWidgetChildBodyQuery = (data, { historicalQueryBody }) => {
    const dataForQueries = cloneDeep(data);
    dataForQueries.push(historicalQueryBody);
    return dataForQueries;
  }

  formatToData = (historicalValues) => formatToLineWidget(historicalValues, this.config)

  getQueryHistorical() {
    const {
      sampling, startDate, endDate, type,
    } = this.config.data;
    const dataForQueries = [];
    const historialQueryBody = this.getDataForQueries({
      sources: this.sources,
      queryType: 'aggregate',
      operation: 'avg',
      sampling,
      periodTime: {
        startDate,
        endDate,
        period: type === 'historical' ? 'temporary-period' : '',
      },
    });
    dataForQueries.push(historialQueryBody);

    return dataForQueries;
  }

  needsSocketForHistorical() {
    const { config: { data: { type } } } = this;
    return type === 'real-time';
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
