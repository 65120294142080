/* eslint-disable import/no-cycle */
import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import {
  Card, DeleteButton, HeaderList, EtlItem, DeviceItem, KpiItem,
} from './utils';

import iconKPI from '../../../../configuration/icons/svg/ico-mini-kpi.svg';
import iconDataSet from '../../../../configuration/icons/svg/ico-mini-dataset.svg';
import iconDevice from '../../../../configuration/icons/svg/ico-mini-device.svg';

export const ListItem = memo(({ data, ...props }) => {
  const ENTITY_TYPES = {
    ETL: 'ETLProcedure',
    KPI: 'Kpi',
    DEVICE: 'Device',
  };
  switch (data.type) {
    case ENTITY_TYPES.ETL:
      return <EtlItem data={data} image={iconDataSet} {...props} />;
    case ENTITY_TYPES.DEVICE:
      return <DeviceItem data={data} image={iconDevice} {...props} />;
    case ENTITY_TYPES.KPI:
      return <KpiItem data={data} image={iconKPI} {...props} />;
    default:
      return (
        <Row>
          <FormattedMessage id="entity_types.not_found_model" />
        </Row>
      );
  }
}, (prevProps, nextProps) => prevProps.data.type !== nextProps.data.type);

export const getClassByDataLength = (dataLength) => {
  if (dataLength === 1) return '4';
  if (dataLength === 2) return '8';
  return '12';
};

const StaticListComponent = (
  {
    data,
    onDelete,
    openedPanels,
    uniqueValue,
    handleChange,
    noTitle,
    trendline,
  },
  handleOpenPanel,
  resetSelection,
) => (
  <Row className="d-flex justify-content-center">
    {data.length > 0 && !noTitle
    && (
    <Col className="d-flex justify-content-center" xs="12">
      <HeaderList className={`col col-sm-12 col-md-${getClassByDataLength(data.length)}`}>
        <p>
          <FormattedMessage id="widgets.wizard.datasources.quantity" />
          <span>{data.length}</span>
        </p>
        {resetSelection && (
        <button type="button" onClick={resetSelection}>
          <FormattedMessage id="widgetTable.conditions.clearList" />
        </button>
        )}
      </HeaderList>
    </Col>
    )}
    {data.map((s, i) => (
      <Col xs="12" lg="4">
        <Card>
          <ListItem
            data={s}
            onChange={handleChange}
            trendline={trendline}
            opened={openedPanels[i]}
            handleOpen={(status) => handleOpenPanel(i, status)}
          />
          {onDelete && (
            <DeleteButton onClick={() => onDelete(s.id)}>
              <i className="uil uil-times" />
            </DeleteButton>
          )}
        </Card>
      </Col>
    ))}
  </Row>
);

export default StaticListComponent;
