import React from "react";
import { FormattedMessage } from "../../../../../Contexts/LanguageContext/index";
import { injectIntl } from "react-intl";
import Role, { SampleRole } from "../../../../../models/Role/index";
import { getPermissions } from '../../../../../services/redux/permissions/actions'
import { addRoleSuccessFlag, errorFetchingRoleReset } from '../../../../../services/redux/roles/actions'
import Field from "../../../../../elements/Field/index";
import styled from "styled-components";
import PropTypes from "prop-types";
import CommonView from '../../../../CommonView';
import { connect } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import { withWizardStep } from '../../../../../Contexts/WizardStepContext';



const title = (
    <FormattedMessage
        id="roles.add.title"
        defaultMessage="Add role"
        description="Title of add role"
    />
);


const Form = styled.div`
    padding: 54px 0px 0px 0px;
`;


export class Add extends CommonView {
    constructor(props) {
        super({ props: props });

        this.state = {
            name: this.props.name,
            surname: this.props.surname,
            email: this.props.email,
            permissions: Array.isArray(this.props.permissions) ? this.props.permissions : [],
            ///groups: this.props.groups, TODO
            errors: this.props.errors,
            onValidation: false,
        };
    }

    static propTypes = {
        name: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string,
        permissions: PropTypes.array,
        //groups: PropTypes.array,
        errors: PropTypes.object
    };

    static defaultProps = {
        name: "",
        surname: "",
        email: "",
        permissions: [],
        //groups: [],
        allowedpermissions: [],
        errors: {}
    };



    saveRole = () => {
        this.setErrors({});

        const obj = this.state;
        delete obj.id;
        const NewRole = new Role(obj);
        const created = NewRole.save();

        if (created.error) {
            delete created.error;
            this.setErrors(created);
            this.props.onError(created.error);
        } 
    }

    validate = () => {
        this.saveRole();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.errorFetching !== this.props.errorFetching) {
            const errFromAPI = this.props.errorFetching.errors;
            if (errFromAPI) {
                const errors = {};
                errFromAPI.forEach(err => {
                    errors[err.field] = <FormattedMessage id={err.message} />;
                });
                this.setErrors(errors);
                errorFetchingRoleReset();
            }
        }

        if (this.props.addSuccess) {
            addRoleSuccessFlag();
            //this.goBack()
            const roleCreated= new Role({...this.props.created});
            this.props.onContinue(roleCreated);
        }

        if(this.props.onValidation) {
            this.saveRole();
        }
    }

    componentDidMount() {
        this.props.getPermissions();
        this.props.onRef(this);
    }


    render() {
        const { intl } = this.props;
        const { errors } = this.state;

        return (
            <>
            <Container>
            <Form onSubmit={this.handleSubmit} noValidate>
                    <Row>
                        <Col sm={6}>
                            {
                                ['name'].map((key, i) => (
                                    <Field
                                        name={key}
                                        label={intl.formatMessage(SampleRole.plainTranslations[key])}
                                        placeholder={intl.formatMessage({id: `Role.model.${key}`})}
                                        id={key}
                                        value={this.state[key]}
                                        type={key === "email" ? 'email' : 'text'}
                                        onChange={this.handleInputChange}
                                        helperText={errors[key]}
                                        error={!!errors[key]}
                                        key={`${key}-${i}`}
                                    />
                                ))
                            }
                        </Col>
                        <Col sm={6}>
                            {
                                ['permissions'/*'groups'*/].map((key, i) => (
                                    <Field
                                        name={key}
                                        id={key}
                                        label={intl.formatMessage(SampleRole.plainTranslations[key])}
                                        type={"multiselect"}
                                        value={this.state[key]}
                                        data={this.props[`allowed${key}`]}
                                        mappedBy={'name'}
                                        equalsBy={'id'}
                                        placeholder={intl.formatMessage({id: `roles.finder.${key}.placeholder`})}
                                        selectedOptions={this.state[key]}
                                        onChange={this.handleSelectChange}
                                        key={`${key}-${i}`}
                                    />
                                ))
                            }
                        </Col>
                    </Row>

            </Form>
            </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    allowedpermissions: state.get('permissions').get('list').toJS(),
    errorFetching: state.get('roles').get('errorFetching'),
    addSuccess: state.get('roles').get('addSuccess'),
    created: state.get('roles').get('createdRole'),
    notify: state.get('notify')
    //allowedgroups: ['Group1', 'Group2', 'Group3', 'Group4']
});

export default connect(mapStateToProps, { getPermissions })(withWizardStep(injectIntl(Add)));