import Provider, { Context as ContextLanguage } from './provider';
import Consumer from './consumer';
import FormattedMessage from './Components/FormattedMessage';
// eslint-disable-next-line import/no-cycle
import SelectLanguage, { HOCSelectLanguage } from './Components/SelectLanguage';

export {
  Consumer as withLanguage,
  Provider as LanguageProvider,
  FormattedMessage,
  SelectLanguage,
  ContextLanguage,
  HOCSelectLanguage,
};
