import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import StepperTitle from './StepperTitle';
import RenderIconWithContext from './RenderIconWithContext';
import './styles.scss';

const WizardStep = ({
  icon,
  customIcon,
  title: _title,
  lead: _lead,
  component,
  className,
  goToStep,
  onSave,
  prevSteps,
  entity,
  blackTitle,
  extraActionOnStepChange,
  extraActionOnEnter,
}) => {
  const [title, setTitle] = useState(_title);
  const [lead, setLead] = useState(_lead);

  const setTitles = ({ title: newTitle, lead: newLead }) => {
    if (newTitle) setTitle(newTitle);
    if (newLead) setLead(newLead);
  };

  const Component = component;

  return (
    <>
      <Container fluid>
        <Row className="wizard-step">
          <Col lg={{ size: 12 }} className="text-center">
            <StepperTitle black={blackTitle} />
            {icon
            && (
            <img
              src={icon}
              alt={title}
            />
            )}
            {customIcon
            && <RenderIconWithContext />}
            <div className="justify-content-center wizard-step-title">
              <h1>
                {title}
              </h1>
            </div>
            { lead && (
              <div className="justify-content-center mb-4 wizard-step-subtitle">
                <span>
                  {lead}
                </span>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 12 }}>
            <div className={`wizardWrapper ${className}`}>
              <Component
                setTitles={setTitles}
                goToStep={goToStep}
                onSave={onSave}
                prevSteps={prevSteps}
                entity={entity}
                extra={extraActionOnStepChange}
                onEnter={extraActionOnEnter}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

WizardStep.defaultProps = {
  icon: undefined,
  lead: undefined,
  className: '',
  goToStep: undefined,
  extraActionOnStepChange: undefined,
  onSave: undefined,
  entity: undefined,
  prevSteps: undefined,
  customIcon: false,
};

export default WizardStep;
