import React, { useState } from 'react';
import { protocolData } from '../../../../data/protocols';
import { Row, Col } from 'reactstrap';
import Field from '../../../../elements/Field';
import { injectIntl } from 'react-intl';

const Lora = ({ protocol, onChange, intl, errors, newEntity, entity }) => {

  const [values, setValues] = useState({});
  const loraData = protocolData.protocol.find(p => (p.value === protocol));

  const handleOnChange = (type, data, selectData) => {
    if (type === 'select') {
      setValues({ [data]: selectData });
    }
    onChange(type, data, selectData);
  };

  const getValue = (configuration) => {
    if (!!values[configuration.value])
      return values[configuration.value];
    return (newEntity.extraConfigurations && newEntity.extraConfigurations[configuration.value]) || null;
  }

  return (
    <Row className={'align-items-end'}>
      {loraData.extraConfigurations.map((configuration, i) => (
        (configuration.showInWizard && configuration.showInWizard.includes(entity.entityName)) &&
        <Col key={i} lg={`${configuration.value === 'username' ||
          configuration.value === 'password' ? 6 : 12}`}>
          <Field
            type={configuration.type}
            name={configuration.value}
            label={intl.formatMessage({
              id: `allEntities.wizard.${configuration.value}.label`,
            })}
            placeholder={intl.formatMessage({
              id: `allEntities.wizard.${configuration.value}.placeholder`,
            })}
            onChange={(data, selectData) => handleOnChange(
              configuration.type,
              data,
              selectData,
            )}
            options={configuration.options}
            error={!!errors[configuration.value]}
            helperText={errors[configuration.value]}
            value={getValue(configuration)}
          />
        </Col>
      ))}
    </Row>
  );
};

export const LoraExtraConfigurations = injectIntl(Lora)
