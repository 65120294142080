import { random } from 'lodash';
import { getColor } from '../../../../../../../pages/Widgets/Add/components/utils';
import BarsDefaultConfiguration from '../../../../bars/utils/defaultConfiguration';

export const BarDefaultConfigurationForLinked = {
  ...BarsDefaultConfiguration,
  labels: {
    xAxis: 'Time',
    yAxis: 'Value',
    alias: { },
  },
};

export const injectLinkedBarsData = (config) => {
  const newConfig = { ...config };
  newConfig.labels.alias = {};
  newConfig.colors = { custom: {} };
  newConfig.data.attributeFilter.forEach((attr) => {
    newConfig.labels.alias[attr] = attr;
    newConfig.colors.custom[attr] = getColor(random(0, 12));
  });
  return newConfig;
};

export default injectLinkedBarsData;
