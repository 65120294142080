/* eslint-disable import/no-named-as-default */
import React from 'react';
import { PropTypes } from 'prop-types';
import Add from './steps/Add';
import AssignPermissions from '../../helpers/AssignPermissions';
import Success from './steps/Success';
import Wizard from '../../../components/Wizard';
import WizardStep from '../../../components/Wizard/components/WizardStep';
import AddTableButton from '../../../components/AddTableButton';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import Dashboard from '../../../models/Dashboard';
import { isMobile } from '../../../helpers/getBreakpoint';
import { ButtonCustom } from '../../../elements/Button';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

const addDashboardLabel = (
  <FormattedMessage
    id="dashboards.add.title"
    defaultMessage="add dashboard"
    description="Label of add dashboard"
  />
);

const ButtonOpen = ({ showModal }) => {
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/dashboard"
        permission="create"
      >
        <ButtonCustom type="circular" name="plus" onClick={showModal} />
      </PrivateComponent>
    );
  }
  return (
    <PrivateComponent
      checkLogin
      checkPermission
      microsService="/app/dashboard"
      permission="create"
    >
      <AddTableButton onClick={showModal} label={addDashboardLabel} />
    </PrivateComponent>
  );
};

class CreateDashboard extends React.PureComponent {
  handleOnSave = (state, entity, onContinue) => {
    const newDashboard = new Dashboard({ ...state.newEntity });
    const created = newDashboard.validate(['name', 'description', 'permissions_policy']);

    if (created.error) {
      delete created.error;
      this.setErrors(created);
      this.handleError();
    } else {
      newDashboard.layoutProperties = {
        lg: [], md: [], sm: [], xs: [], xxs: [],
      };
      newDashboard.save();
      onContinue();
    }
  };

  render() {
    return (
      <Wizard
        buttonOpen={ButtonOpen}
        entity={Dashboard}
        metadata={{
          wizardType: 'dashboards',
        }}
      >
        <WizardStep
          component={Add}
          title={<FormattedMessage id="dashboards.wizard.step1.title" />}
          lead={<FormattedMessage id="dashboard.wizard.step1.text" />}
        />

        <WizardStep
          component={AssignPermissions}
          title={<FormattedMessage id="dashboards.wizard.step2.title" />}
          lead={<FormattedMessage id="dashboard.wizard.step2.text" />}
          onSave={this.handleOnSave}
        />

        <Success />

      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateDashboard;
