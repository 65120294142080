import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import InfoLinked from '../newcomponents/infoLinked';

class WidgetInfo extends React.PureComponent {
  render() {
    const {
      data, entity, isLinked,
    } = this.props;
    if (isLinked) {
      return (
        <InfoLinked
          data={data}
          entity={entity}
          linkedParent={isLinked}
        />
      );
    }
    return (
      <div className="tabSection p-5 backgroundWhite">
        <div className="row">
          <dt className="col-12">
            <FormattedMessage id="widget.attribute.widgetType" />
          </dt>
          <dd className="col-12">
            {data.widgetType}
          </dd>
        </div>
        {data.origins.map((o) => (
          <div>
            <div className="row">
              <dt className="col-12">
                <FormattedMessage id="widget.attribute.deviceId" />
              </dt>
              <dd className="col-12">
                {o.connectedDevices.device_id}
              </dd>
            </div>

            <div className="row">
              <dt className="col-12">
                <FormattedMessage id="widget.attribute.attributes" />
              </dt>
              {o.connectedDevices.attributes.length !== 0
                ? o.connectedDevices.attributes.map((attributes) => (
                  <dd className="col-12">
                    {attributes}
                  </dd>
                ))
                : (
                  <dd className="col-12">
                    <FormattedMessage id="widget.profile.none" />
                  </dd>
                )}
            </div>

            <div className="row">
              <dt className="col-12">
                <FormattedMessage id="widget.attribute.lazy_attributes" />
              </dt>
              {o.connectedDevices.lazy_attributes.length !== 0
                ? o.connectedDevices.lazy_attributes.map((lazyAttributes) => (
                  <dd className="col-12">
                    {lazyAttributes}
                  </dd>
                ))
                : (
                  <dd className="col-12">
                    <FormattedMessage id="widget.profile.none" />
                  </dd>
                )}
            </div>

            <div className="row">
              <dt className="col-12">
                <FormattedMessage id="widget.attribute.command_attributes" />
              </dt>
              {o.connectedDevices.command_attributes.length !== 0
                ? o.connectedDevices.command_attributes.map((commandAttributes) => (
                  <dd className="col-12">
                    {commandAttributes}
                  </dd>
                ))
                : (
                  <dd className="col-12">
                    <FormattedMessage id="widget.profile.none" />
                  </dd>
                )}
            </div>

            <div className="row">
              <dt className="col-12">
                <FormattedMessage id="widget.attribute.static_attributes" />
              </dt>
              {o.connectedDevices.static_attributes.length !== 0
                ? o.connectedDevices.static_attributes.map((staticAttributes) => (
                  <dd className="col-12">
                    {staticAttributes}
                  </dd>
                ))
                : (
                  <dd className="col-12">
                    <FormattedMessage id="widget.profile.none" />
                  </dd>
                )}
            </div>
          </div>
        ))}
      </div>
      /*  </div> */
    );
  }
}

export default WidgetInfo;
