const HtmlDefaultConfiguration = {

  custom: {
    HTML: {
      css: '',
      html: 'default',
      js: '',
    },
  },

};

export default HtmlDefaultConfiguration;
