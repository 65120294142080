import React from 'react';
import { injectIntl } from "react-intl";

import { FormattedMessage } from "../../../Contexts/LanguageContext/index";
import { errorFetchingPermissionReset } from '../../../services/redux/permissions/actions'
import { modifyPermissionSuccessFlag } from '../../../services/redux/permissions/actions';
import { clone } from 'ramda';

import TabComponent from '../../../components/Tab';
import Finder from '../../../components/Finder';
import CardHoc from '../../../elements/Card';
import { getPermissions } from '../../../services/redux/permissions/actions';
import { connect } from 'react-redux';


import Info from './Info';
import './styles.scss';
import colors from '../../../configuration/colors';


const Card = CardHoc(Finder(
    (props) => {
        const { data } = props;
        return <ul className="list-unstyled">
            {data.map((d, i) => <li key={i}>{d.name}</li>)}
        </ul>
    }
))

const OnEdit = ({onClick}) => <div className="onEditBand">
    <p><FormattedMessage id="profile.edit.mode" description="edit mode active" defaultMessage="edit mode active" /></p>
    <i className="uil uil-times" onClick={onClick}></i>
</div>;

const Permissions = ({ data, permissions, ...props }) => {
    return (
        <div className="tabSection">
            <h3><FormattedMessage id="Permission.model.name" description="permission" defaultMessage="permission" /></h3>
            <div className="optionsForTop">
                {/*                 <p className="total">ROL! <span>123</span></p>
                <p className="total">ROL! <span>123</span></p>
                <p className="total">ROL! <span>123</span></p> */}
            </div>
            <Card {...props}
                className="card"
                data={permissions} />
        </div>
    )
}


class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            original: this.props.data, 
            edit: false,
            permission: {},
        }
    }

    componentDidMount() {
        this.props.iconsColor(colors['ui-Grey1']);
        getPermissions();
    }

    componentDidUpdate(prevProps) {

        if (this.state.original === {} || this.props.permissionList !== prevProps.permissionList)
            this.updatePermissionToState();

        if (prevProps.errorFetching !== this.props.errorFetching) {
            const errFromAPI = this.props.errorFetching.errors;
            if (errFromAPI) {
                const errors = {};
                errFromAPI.forEach(err => {
                    errors[err.field] = <FormattedMessage id={err.message} />;
                });

                errorFetchingPermissionReset();
            }
        }

        if (this.props.modifySuccess) {
            modifyPermissionSuccessFlag();
            this.updatePermissionToState();
            //this.goBack()
        }
    }

    updatePermissionToState() {
        const StoredPermission = clone(this.props.permissionList.find(u => u.id === this.props.data.id));
        if (StoredPermission && this.state.original !== StoredPermission) {
            this.setState({ original: StoredPermission});
            return true;
        }
        return false;
    }

    handleOnClose = () => {
        this.setState({
            opened: !this.state.opened
        })

        this.props.onChange(this.state.opened);
    }

    editPermission = () => {
        this.setState({
            edit: true,
        })
    }

    turnOffEdit = () => {
        this.setState({
            edit: false,
        })
    }

    getState = () => this.state;

    handleOnEdit = (e) => {
        this.child.handleOnEdit(e);
    }

    successfullEdit = (data) => {
        this.setState({
            permission: data,
        })
    }

    render() {

        const data = this.state.original;
        const { edit } = this.state;
        return (
            <div className="profile profile-permissions">
                <section className="avatar">
                    <h1 className="mb-4">{data.name}</h1>
                    <div className="buttons">
                        {/* <button type="button" className="btn btn-primary" onClick={!edit ? this.editPermission : this.handleOnEdit}>
                            {!edit ? 
                                <FormattedMessage
                                    id="permissions.update.button"
                                    defaultMessage="Update Permission"
                                    description="Text for update permission"
                                />
                                :

                                <FormattedMessage
                                    id="permissions.apply.changes.button"
                                    defaultMessage="Apply changes"
                                    description="Text for apply changes"
                                />
                            }
                        </button> */}
                    </div>
                </section>
                <section>
                    <TabComponent
                        titles={['Info']}
                        onChange={this.handleOnTabsChange}>
                        <Info {...this.props} data={data} edit={this.state.edit} onRef={ref => (this.child = ref)} changeMode={this.turnOffEdit} succeed={this.successfullEdit} />
                        {!edit && <Permissions {...this.props} />}
                    </TabComponent>
                </section>
                
                {edit && <OnEdit onClick={this.turnOffEdit} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permissionList: state.get('permissions').get('list').toJS(),
    modifySuccess: state.get('permissions').get('modifySuccess'),
    errorFetching: state.get('permissions').get('errorFetching')
})

export default connect(
    mapStateToProps,
    { getPermissions }
)(injectIntl(Profile));