export const GET_ETL_PROCEDURES_REQUEST = 'GET_ETL_PROCEDURES_REQUEST';
export const GET_ETL_PROCEDURES_SUCCESS = 'GET_ETL_PROCEDURES_SUCCESS';
export const GET_ETL_PROCEDURES_ERROR = 'GET_ETL_PROCEDURES_ERROR';

export const GET_MY_ETL_PROCEDURES_REQUEST = 'GET_MY_ETL_PROCEDURES_REQUEST';
export const GET_MY_ETL_PROCEDURES_SUCCESS = 'GET_MY_ETL_PROCEDURES_SUCCESS';
export const GET_MY_ETL_PROCEDURES_ERROR = 'GET_MY_ETL_PROCEDURES_ERROR';

export const GET_PUBLIC_ETL_PROCEDURES_REQUEST = 'GET_PUBLIC_ETL_PROCEDURES_REQUEST';
export const GET_PUBLIC_ETL_PROCEDURES_SUCCESS = 'GET_PUBLIC_ETL_PROCEDURES_SUCCESS';
export const GET_PUBLIC_ETL_PROCEDURES_ERROR = 'GET_PUBLIC_ETL_PROCEDURES_ERROR';

export const GET_ETL_EXECUTIONS_REQUEST = 'GET_ETL_EXECUTIONS_REQUEST';
export const GET_ETL_EXECUTIONS_SUCCESS = 'GET_ETL_EXECUTIONS_SUCCESS';
export const GET_ETL_EXECUTIONS_ERROR = 'GET_ETL_EXECUTIONS_ERROR';

export const GET_ETL_PROCEDURE_REQUEST = 'GET_ETL_PROCEDURE_REQUEST';
export const GET_ETL_PROCEDURE_SUCCESS = 'GET_ETL_PROCEDURE_SUCCESS';
export const GET_ETL_PROCEDURE_ERROR = 'GET_ETL_PROCEDURE_ERROR';

export const GET_ETL_PROCEDURE_DEVICES_COUNT_REQUEST = 'GET_ETL_PROCEDURE_DEVICES_COUNT_REQUEST';
export const GET_ETL_PROCEDURE_DEVICES_COUNT_SUCCESS = 'GET_ETL_PROCEDURE_DEVICES_COUNT_SUCCESS';
export const GET_ETL_PROCEDURE_DEVICES_COUNT_ERROR = 'GET_ETL_PROCEDURE_DEVICES_COUNT_ERROR';

export const ADD_ETL_PROCEDURE_REQUEST = 'ADD_ETL_PROCEDURE_REQUEST';
export const ADD_ETL_PROCEDURE_SUCCESS = 'ADD_ETL_PROCEDURE_SUCCESS';
export const ADD_ETL_PROCEDURE_ERROR = 'ADD_ETL_PROCEDURE_ERROR';

export const MODIFY_ETL_PROCEDURE_REQUEST = 'MODIFY_ETL_PROCEDURE_REQUEST';
export const MODIFY_ETL_PROCEDURE_SUCCESS = 'MODIFY_ETL_PROCEDURE_SUCCESS';
export const MODIFY_ETL_PROCEDURE_ERROR = 'MODIFY_ETL_PROCEDURE_ERROR';

export const VERIFY_ETL_PROCEDURE_REQUEST = 'VERIFY_ETL_PROCEDURE_REQUEST';
export const VERIFY_ETL_PROCEDURE_SUCCESS = 'VERIFY_ETL_PROCEDURE_SUCCESS';
export const VERIFY_ETL_PROCEDURE_ERROR = 'VERIFY_ETL_PROCEDURE_ERROR';

export const EXECUTE_ETL_PROCEDURE_REQUEST = 'EXECUTE_ETL_PROCEDURE_REQUEST';
export const EXECUTE_ETL_PROCEDURE_SUCCESS = 'EXECUTE_ETL_PROCEDURE_SUCCESS';
export const EXECUTE_ETL_PROCEDURE_ERROR = 'EXECUTE_ETL_PROCEDURE_ERROR';

export const DELETE_ETL_PROCEDURE_REQUEST = 'DELETE_ETL_PROCEDURE_REQUEST';
export const DELETE_ETL_PROCEDURE_SUCCESS = 'DELETE_ETL_PROCEDURE_SUCCESS';
export const DELETE_ETL_PROCEDURE_ERROR = 'DELETE_ETL_PROCEDURE_ERROR';
/* plural used in opendata */
export const DELETE_ETL_PROCEDURES_SUCCESS = 'DELETE_ETL_PROCEDURES_SUCCESS';

export const ADD_ETL_PROCEDURE_SUCCESS_FLAG = 'ADD_ETL_PROCEDURE_SUCCESS_FLAG';
export const ERRORFETCHING_ETL_PROCEDURE_RESET = 'ERRORFETCHING_ETL_PROCEDURE_RESET';

export const GET_ETL_PROCEDURE_ROLES_ERROR = 'GET_ETL_PROCEDURE_ROLES_ERROR';
export const GET_ETL_PROCEDURE_ROLES_REQUEST = 'GET_ETL_PROCEDURE_ROLES_REQUEST';
export const GET_ETL_PROCEDURE_ROLES_SUCCESS = 'GET_ETL_PROCEDURE_ROLES_SUCCESS';
export const MODIFY_ETL_PROCEDURE_SUCCESS_FLAG = 'MODIFY_ETL_PROCEDURE_SUCCESS_FLAG';

export const CLEAR_ETL_PROCEDURES_STATE = 'CLEAR_ETL_PROCEDURES_STATE';
export const CLEAR_ETL_EXECUTIONS_STATE = 'CLEAR_ETL_EXECUTIONS_STATE';
export const UPDATE_ETL_PROCEDURES_STATE = 'UPDATE_ETL_PROCEDURES_STATE';

export const GET_ETL_V2 = 'GET_ETL_V2';

export const GET_ETL_TO_SOURCE_LIST = 'GET_ETL_TO_SOURCE_LIST';
