import * as TYPES from './types';
import { Map, List } from 'immutable';

export const exampleFiware = [
  { service: 'service1', servicepath: 'servicepath1' },
  { service: 'service2', servicepath: 'servicepath2' }
];
export const examplePermissionPolicy = ['Group1', 'Group2', 'Group3', 'Group4'];
export const exampleTransport = ['HTTP', 'MQTT', 'AMQP'];
export const exampleProtocol = ['IoTA-UL', 'json', 'SIGFOX', 'LoRa'];

/** Initial State of Services */
const initialState = Map({
  list: List([]),
  total: 0,
  fetching: false,
  errorFetching: false,
  addSuccess: false,
  modifySuccess: false,
  service: {}
});

/** SERVICES REDUCER*/
export default (state = initialState, { type, payload }) => {
  /** Helper functions */
  let index = -1;
  let aux;

  /** Cases */
  switch (type) {
    /** INITIALIZE GET SERVICES FROM DB */
    case TYPES.GET_SERVICES_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);

    /** INITIALIZE GET SERVICES FROM DB */
    case TYPES.GET_SERVICES_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;

    /** GET ALL SERVICE FROM DB TO STATE */
    case TYPES.GET_SERVICES_SUCCESS:
    case TYPES.GET_MY_SERVICES_SUCCESS:
    case TYPES.UPDATE_SERVICES_STATE:
      //TODO: Sure it is correct?
      const servicesId = payload.services.map(o => o.id);
      const services = state
        .get('list')
        .toJS()
        .filter(o => !servicesId.includes(o.id));

      return state
        .set('total', payload.total)
        .set('list', List([...services, ...payload.services]))
        .set('fetching', false);

    /** INITIALIZE GET SERVICE FROM DB */
    case TYPES.GET_SERVICE_REQUEST:
      aux = state.set('errorFetching', false);
      aux = aux.set('fetching', true);
      return aux;

    /** INITIALIZE GET SERVICE FROM DB ERROR*/
    case TYPES.GET_SERVICE_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;
    /** GET SERVICE FROM DB TO STATE */
    case TYPES.GET_SERVICE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      //TODO
      //If doesn't exist in the current state, PUSH. // If it exist ?
      return index === -1
        ? state.update('list', serviceList => serviceList.push(payload))
        : state;
    //////// ADD AN SERVICE
    /** INITIALIZE ADD SERVICE TO DB AND STATE */
    case TYPES.ADD_SERVICE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    /** INITIALIZE ERROR ADD SERVICE */
    case TYPES.ADD_SERVICE_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;
    /** ADD NEW SERVICE TO STATE */
    case TYPES.ADD_SERVICE_SUCCESS:
      return state
        .update('list', serviceList => serviceList.push(payload))
        .set('service', payload)
        .set('fetching', false)
        .set('addSuccess', true)
        .update('total', total => total + 1);
    /** SET AS FALSE THE addSuccess STATE FLAG */
    case TYPES.ADD_SERVICE_SUCCESS_FLAG:
      return state.set('addSuccess', false);

    //////// UPDATE AN SERVICE
    /** INITIALIZE UPDATE SERVICE TO DB */
    case TYPES.MODIFY_SERVICE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    /**  UPDATE SERVICE ERROR */
    case TYPES.MODIFY_SERVICE_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;
    /** UPDATE SERVICE IN STATE */
    case TYPES.MODIFY_SERVICE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', serviceList =>
              serviceList.splice(index, 1, payload)
            )
          : state;
      aux = aux.set('fetching', false);
      aux = aux.set('modifySuccess', true);
      return aux;
    /** SET AS FALSE THE modifySuccess STATE FLAG */
    case TYPES.MODIFY_SERVICE_SUCCESS_FLAG:
      return state.set('modifySuccess', false);

    //////// DELETE AN SERVICE
    /** INITIALIZE DELATION OF A SERVICE IN DB */
    case TYPES.DELETE_SERVICE_REQUEST:
      return state.set('fetching', true).set('errorFetching', false);
    /** INITIALIZE DELATION OF AN SERVICE IN DB */
    case TYPES.DELETE_SERVICE_ERROR:
      aux = state.set('errorFetching', payload);
      aux = aux.set('fetching', false);
      return aux;
    /** DELETE SERVICE IN STATE */
    case TYPES.DELETE_SERVICE_SUCCESS:
      index = state.get('list').findIndex(u => u.id === payload.id);
      aux =
        index !== -1
          ? state.update('list', serviceList => serviceList.delete(index))
          : state;
      aux = aux.set('fetching', false);
      aux = aux.update('total', total => total - 1);
      return aux;

    //////// RESET ERROR FETCHING TO FALSE
    /** RESET ERROR FETCHING TO FALSE */
    case TYPES.ERRORFETCHING_SERVICE_RESET:
      return state.set('errorFetching', false);

    case TYPES.CLEAR_SERVICES_STATE:
      return state.set('list', List([]));

    default:
      return state;
  }
};
