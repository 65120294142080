/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileComponent from '../../helpers/Profile/Profile';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import EditPermissions from '../../helpers/Profile/EditPermissions';
import Widget from '../../../models/WidgetV2';
import EditConfigurationWidgetTypeV2 from './components/EditConfigurationWidgetTypeV2';
import EditLinkedWidgets from './components/EditLinkedWidgets';
import WidgetInfo from './components/WidgetInfoV2';
import { getUrnEntity, getUrnId, havePermissionToEdit } from '../../../helpers/utils';
import LinkedWidgetInfo from './newcomponents/LinkedWidgetInfo';
import { getWidgetModel } from '../../../models/WidgetV2/utils';
import { TYPES, updateState } from '../../Dashboards/ShowV2/reducer';

export class Profile extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      data: { ...data },
    };
  }

  componentDidMount() {
    const { data } = this.props;
    havePermissionToEdit(data.permissions_policy, data.owner)
      .then((permissionToEdit) => this.setState({ permissionToEdit }));
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data: { ...data } });
    }
  }

  handleOnSave = (id, data) => {
    const { data: stateData } = this.state;
    if (stateData[id] !== data) {
      const newData = getWidgetModel({ ...stateData, [id]: data });
      newData.update(newData.getData(), stateData.config);
      this.setState({ data: newData });
    }
  };

  ifDescription = () => {
    const { data: { widgetType } } = this.props;
    const widgetsWithoutDescription = ['IMAGE'];
    return !widgetsWithoutDescription.includes(widgetType);
  }

  isLinked = () => {
    const { data } = this.props;
    const { widgetType } = data;
    return widgetType === 'LINKED';
  }

  getTitles = () => {
    const titles = [
      <FormattedMessage id="widgets.profile.config" />,
      <FormattedMessage id="widgets.profile.info" />,
      <FormattedMessage id="widgets.profile.permissions" />,
    ];
    const editLinkedWidgetsTitle = <FormattedMessage id="widgets.profile.editLinkedWidgets" />;
    if (this.isLinked()) titles.splice(1, 0, editLinkedWidgetsTitle);
    return titles;
  }

  getWidgetSources = () => {
    const { data, sources } = this.props;
    const sourceObj = [];
    data.getUrnSources().forEach((urn) => {
      const sourceType = getUrnEntity(urn);
      const sourceId = getUrnId(urn);
      if (sources[sourceType.toLowerCase()][sourceId]) {
        sourceObj.push(sources[sourceType.toLowerCase()][sourceId]);
      }
    });
    return sourceObj;
  };

  updateActiveWidget = (widget) => {
    const { dispatchState } = this.props;
    updateState(
      TYPES.setWidgetSection,
      { active: widget },
      dispatchState,
    );
  }

  render() {
    const { permissionToEdit } = this.state;
    const {
      isLinked, data, selection, widgetList, profileType, dispatchState,
    } = this.props;
    const widgetSources = this.getWidgetSources();

    if (profileType === 'edit') {
      return (
        <ProfileComponent
          data={data}
          titles={this.getTitles()}
          save={this.handleOnSave}
          editable={permissionToEdit}
          withDescription={this.ifDescription()}
        >
          <EditConfigurationWidgetTypeV2
            data={data}
            getParentWidgetType={() => null}
            isLinked={isLinked}
            sources={widgetSources}
            v2
          />
          {(this.isLinked()) && <EditLinkedWidgets data={data} dispatchState={dispatchState} />}
          {(this.isLinked())
            ? (
              <LinkedWidgetInfo
                data={data}
                entity={Widget}
                sources={widgetSources}
                dispatchState={dispatchState}
              />
            )
            : (
              <WidgetInfo
                data={data}
                isLinked={isLinked}
                entity={Widget}
                selection={selection}
                widgetList={widgetList}
                sources={widgetSources}
              />
            )}
          <EditPermissions
            permissionToEdit={permissionToEdit}
            data={data}
            entity={getWidgetModel(data, true)}
            callback={this.updateActiveWidget}
          />
        </ProfileComponent>
      );
    }
    if (profileType === 'share') {
      return (
        <ProfileComponent
          data={data}
          titles={[
            <FormattedMessage id="widgets.profile.permissions" />,
          ]}
          save={this.handleOnSave}
        >
          <EditPermissions
            permissionToEdit={permissionToEdit}
            data={data}
            entity={getWidgetModel(data, true)}
            callback={this.updateActiveWidget}
          />
        </ProfileComponent>
      );
    }
    return (
      <>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  widgets: state.get('widgets').get('list').toJS(),
  sources: {
    device: state.get('devices').get('listSources'),
    kpi: state.get('kpis').get('listSources'),
    etl: state.get('etlProcedures').get('listSources'),
  },
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
