import React from 'react';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';
import CommonView from '../../../../../CommonView';
import Field from '../../../../../../elements/Field';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { Value, severityData, Card } from '../../../../helpers/severitySelect';
import { withWizardStep } from '../../../../../../Contexts/WizardStepContext';
import { getGeneralDataForAction } from '../../../../../../data/ruleActions';
import withVariables from '../../../../../../components/WithVariables';
import { joinedAttributes } from '../helpers';

const FieldWithVariables = withVariables(Field);

class AlertComponent extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      severity: '',
      description: '',
      name: '',
      errors: {},
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.props.actionComponentRef(this);
  }

  handleInnerInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  validate = (callback = null) => {
    const generalActionData = getGeneralDataForAction(this.props.newEntity.actionToCreate);
    const constraints = {};
    ['severity', 'description', 'name'].forEach((v) => {
      constraints[v] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="Validation.presence" />,
        },
        length: {
          minimum: 2,
          tooShort: <FormattedMessage id="Validation.presence" />,
        },
      };
    });

    const errors = validate(this.state, constraints);
    if (errors) {
      this.setState({
        errors,
      });
    } else this.props.saveAction(callback, generalActionData.parseAction(this.state));
  };

  handleSelectChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { intl, newEntity } = this.props;
    const { errors } = this.state;
    return (
      <>
        <div className="d-flex flex-column justify-content-start align-items-center alerts inline-form">
          {['name'].map((item) => (
            <Field
              key={item}
              name={item}
              type="text"
              value={this.state[item]}
              onChange={this.handleInnerInputChange}
              helperText={errors[item] ? errors[item][0] : errors[item]}
              error={!!errors[item]}
              label={intl.formatMessage({
                id: `rules.wizard.action.${item}.label`,
              })}
              placeholder={intl.formatMessage({
                id: `rules.wizard.action.${item}.placeholder`,
              })}
            />
          ))}
          {['description'].map((item) => (
            <FieldWithVariables
              key={item}
              name={item}
              type="complexTextarea"
              value={this.state[item]}
              onChange={this.handleInnerInputChange}
              helperText={
                (errors[item] ? errors[item][0] : errors[item])
                || intl.formatMessage({ id: `rules.wizard.action.${item}.placeholder` })
                  .concat('. ', intl.formatMessage({ id: 'complexTextarea.pattern' }))
              }
              error={!!errors[item]}
              label={intl.formatMessage({
                id: `rules.wizard.action.${item}.label`,
              })}
              placeholder={intl.formatMessage({
                id: `rules.wizard.action.${item}.placeholder`,
              })}
              devices={newEntity.devices.map((d) => ({
                device: d.device_id,
                attributes: joinedAttributes(d),
              }))}
            />
          ))}
          <Field
            name="severity"
            type="customSelect"
            customOptions={Card}
            options={severityData}
            label={intl.formatMessage({
              id: 'rules.wizard.action.severity.label',
            })}
            placeholder={intl.formatMessage({
              id: 'rules.wizard.action.severity.placeholder',
            })}
            onChange={this.handleSelectChange}
            value={this.state.severity}
            customSelectedOption={Value}
            className="w-50 mr-auto"
            helperText={errors.severity ? errors.severity[0] : errors.severity}
            error={!!errors.severity}
          />
        </div>
      </>
    );
  }
}

export default withWizardStep(injectIntl(AlertComponent));
