import React, { PureComponent } from 'react';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../Contexts/WizardStepContext';

class StepperTitle extends PureComponent {
  getWizardType = () => {
    const { metadata } = this.props;
    const { wizardType } = metadata;
    return wizardType;
  }

  getStepperInfo = (mainType) => {
    const mainTypeId = `${mainType}.wizard.stepper.mainText`;
    const { black } = this.props;
    return (
      <div>
        <span className={`stepperTitle ${black ? 'blackTitle' : ''}`}><FormattedMessage id={mainTypeId} /></span>
      </div>
    );
  }

  render() {
    const mainType = this.getWizardType();

    if (mainType) {
      return (
        this.getStepperInfo(mainType)
      );
    }
    return (React.Fragment);
  }
}

export default withWizardStep(StepperTitle);
