import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@material-ui/icons';
import ButtonWrapper from '../ButtonWrapper';

class Icon extends React.PureComponent{
  render(){
    const {svg, className, ...rest} = this.props;
    const IconFinded =  Icons[svg];
    return(
          <IconFinded className={className} {...rest}/>
    )
  }
}

Icon.defaultProps = {
  svg: 'SentimentSatisfiedAlt',
  className: ''
};

Icon.propTypes = {
  /** Allows any MaterialUI icon by sending a string in CamelCase with the icon name. */
  svg: PropTypes.string.isRequired,
  /** Allows specify a css class that overrides the default styles fixed by MaterialUI */
  className: PropTypes.string,
};

export {ButtonWrapper}

export default Icon;

