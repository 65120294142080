import React from 'react';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { PropTypes } from 'prop-types';
import { sortBy } from 'lodash';

import { clone } from 'ramda';
import styled from '@emotion/styled';
import Wizard from '../../../components/Wizard';
import { FormattedMessage } from '../../../Contexts/LanguageContext';

// steps

import BasicInfo from './steps/basicInfo';
import WidgetType from './steps/WidgetType';
import SelectDatasources from './steps/SelectDatasources';
import SelectDatasourcesForLinkedWidget from './steps/SelectDatasourcesForLinkedWidget';
import ConfigureWidget from './steps/ConfigureWidget';
import ConfigureWidgetV2 from './steps/ConfigureWidgetV2';
import WidgetPreview from './steps/WidgetPreview';
import WidgetPreviewV2 from './steps/WidgetPreviewV2';
import AssignPermissions from '../../helpers/AssignPermissions';

import Success from './steps/Success';
// import CreateAction from './CreateAction';

import { getDefaultMeasure, getDefaultMeasures } from '../../Dashboards/WidgetDefaultMeasures';
import { isMobile } from '../../../helpers/getBreakpoint';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

import CreateWidgteIcon from '../../../configuration/icons/svg/ico-widget.svg';
import WizardStep from '../../../components/Wizard/components/WizardStep';
import Dashboard from '../../../models/Dashboard/index';
import Report from '../../../models/Report';
import Widget from '../../../models/Widget/index';

import AddWidgetHTML from './steps/customSteps/HTML/AddWidgetHTML';
import Preview from './steps/customSteps/HTML/Preview';
import AddWidgetImage from './steps/customSteps/Image/AddWidgetImage';
import ButtonOutline from '../../../elements/ButtonOutline';
import { getWidgetModel } from '../../../models/WidgetV2/utils';
import './styles.scss';
import ConfigureWidgetOnOff from './steps/customSteps/Commands/OnOff/configureWidgetOnOff';
import ConfigureWidgetSlider from './steps/customSteps/Commands/Slider/ConfigureWidgetSlider';
import WidgetTypeV2 from './steps/WidgetTypeV2';

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 10;

`;

const ButtonOpen = ({ showModal, customButton }) => {
  if (customButton) {
    const {
      disabled, label, bgwhite, ico,
    } = customButton;
    return (
      <ButtonOutline
        onClick={showModal}
        disabled={disabled}
        label={label}
        bgwhite={bgwhite}
        ico={ico}
      />
    );
  }
  if (isMobile()) {
    return (
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/rules"
        permission="create"
      >
        <div className="divAddWidgetButtonClass">
          <div
            onClick={showModal}
            className="addWidgetButtonClass"
            role="presentation"
          >
            <div className="buttonTex">
              <div>
                <i className="uil uil-plus" />
              </div>
              <div>
                <FormattedMessage id="dashboard.button.addWidget" />
              </div>
            </div>
          </div>
        </div>
      </PrivateComponent>
    );
  }
  return (
    <ButtonWrapper>
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/rules"
        permission="create"
      >
        <div className="divAddWidgetButtonClass">
          <div
            onClick={showModal}
            className="addWidgetButtonClass"
            role="presentation"
          >
            <div className="buttonTex">
              <div>
                <i className="uil uil-plus" />
              </div>
              <div>
                <FormattedMessage id="dashboard.button.addWidget" />
              </div>
            </div>
          </div>
        </div>
      </PrivateComponent>
    </ButtonWrapper>

  );
};

// const defaultRule = new Rule();

class CreateWidgets extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      widgetType: null,
    };
  }

  setWidgetType = (type) => {
    this.setState({ widgetType: type });
  };

  updateReport = (widget) => {
    const { report, currentPaper } = this.props;
    const clonedReport = clone(report);
    const reportToUpdate = new Report(clonedReport);

    const section = reportToUpdate.pages[currentPaper || 0].sections[0];
    const elements = section?.elements
      ? sortBy(section.elements, (e) => e.layoutConfig.lg[0].y)
      : [];
    // check lastPosition
    const lastY = elements.length ? elements[elements.length - 1] : 0;

    const defaultMeasures = getDefaultMeasures([widget]);
    Object.keys(defaultMeasures).forEach((key) => {
      defaultMeasures[key][0].y = lastY + 1;
      defaultMeasures[key][0].i = widget.id;
    });
    // Prepare widget to push on section
    const widgetToPush = {
      aspectRatio: 'NONE',
      config: { ...widget.config, name: widget.name },
      origins: widget.origins.map((o) => ({ urn: `fiwoo:device:${o.connectedDevices.id}`, ...o })),
      layoutConfig: defaultMeasures,
      type: widget.widgetType,
    };
    // Search current paper for update
    if (!section) {
      reportToUpdate.pages[currentPaper || 0].sections
        .push({ sectionConfig: { cols: 1, rows: 1 }, elements: [] });
    }
    reportToUpdate.pages[currentPaper || 0].sections[0].elements.push(widgetToPush);
    reportToUpdate.update();
  }

  updateDashboard = (widget) => {
    const { dashboard } = this.props;
    const clonedDashboard = clone(dashboard);
    const dashboardToUpdate = new Dashboard(clonedDashboard);

    if (!dashboardToUpdate.layoutProperties) {
      dashboardToUpdate.layoutProperties = {
        lg: [], md: [], sm: [], xs: [], xxs: [],
      };
    }

    const defaultMeasures = getDefaultMeasure(widget, dashboardToUpdate.layoutProperties);

    dashboardToUpdate.layoutProperties.lg.push(defaultMeasures.lg);
    dashboardToUpdate.layoutProperties.md.push(defaultMeasures.md);
    dashboardToUpdate.layoutProperties.sm.push(defaultMeasures.sm);
    dashboardToUpdate.layoutProperties.xs.push(defaultMeasures.xs);
    dashboardToUpdate.layoutProperties.xxs.push(defaultMeasures.xxs);

    dashboardToUpdate.widgets = dashboardToUpdate.widgets.map((o) => o.id);
    dashboardToUpdate.widgets.push(widget.id);
    dashboardToUpdate.update();
  };

  handleOnSave = (state, entity, onContinue) => {
    const { report } = this.props;
    const newWidget = clone(state.newEntity);
    newWidget.origins = newWidget.origins.map(
      (device) => {
        const d = {
          device_id: device.device_id,
          id: device.id,
          attributes: [],
          lazy_attributes: [],
          static_attributes: [],
          command_attributes: [],
        };

        if (device.joinedAttributes) {
          device.joinedAttributes.forEach((attribute) => {
            if (attribute.selected) {
              switch (attribute.attrType) {
                case 'lazy':
                  d.lazy_attributes.push(attribute.name);
                  break;
                case 'static':
                  d.static_attributes.push(attribute.name);
                  break;
                default:
                  d.attributes.push(attribute.name);
              }
            }
          });
        } else {
          d.attributes.push('data');
          d.device_id = d.id;
        }
        let type = '';
        if (newWidget.origins[0].Model.entityName === 'Device') {
          type = 'DEVICE';
        } else if (newWidget.origins[0].Model.entityName === 'ETLProcedure') {
          type = 'ETL';
        } else if (newWidget.origins[0].Model.entityName === 'Kpi') {
          type = 'KPI';
        }

        return { type, connectedDevices: d, categories: device.categories };
      },
    );

    if (newWidget.widgetType === 'SEND_COMMAND') {
      newWidget.widgetType = (newWidget.config.second_state ? 'SEND_COMMAND_DUAL' : 'SEND_COMMAND_SINGLE');
    }

    const widgetToSave = getWidgetModel(newWidget);

    if (report) {
      widgetToSave.save(this.updateReport);
    } else {
      widgetToSave.save(this.updateDashboard);
    }
    onContinue();
  }

  getComponents = (widgetType) => {
    switch (widgetType) {
      case 'HTML':
        return [AddWidgetHTML, Preview];
      case 'IMAGE':
        return [AddWidgetImage];
      case 'ONOFF':
        return [SelectDatasources, ConfigureWidgetOnOff, WidgetPreview];
      case 'SLIDER':
        return [SelectDatasources, ConfigureWidgetSlider, WidgetPreview];
      default:
        return [];
    }
  }

  render() {
    const { customButton } = this.props;
    const { widgetType } = this.state;

    return (
      <Wizard
        buttonOpen={ButtonOpen}
        customButton={customButton}
        entity={Widget}
        onSave={this.handleOnSave}
        metadata={{
          setWidgetType: this.setWidgetType,
          wizardType: 'widgets',
        }}
      >
        <WizardStep
          component={BasicInfo}
          title={<FormattedMessage id="widgets.wizard.step1.title" />}
          lead={<FormattedMessage id="widgets.wizard.step1.text" />}
          icon={CreateWidgteIcon}

        />

        <WizardStep
          component={WidgetType}
          title={<FormattedMessage id="widgets.wizard.step2.title" />}
          icon={CreateWidgteIcon}
          lead={<FormattedMessage id="widgets.wizard.step2.text" />}
          goToStep={(step) => step}
          extraActionOnStepChange={this.setWidgetType}
        />

        {widgetType !== 'HTML' && widgetType !== 'IMAGE' && widgetType !== 'PARAMETRIZED_TEXT' 
        && widgetType !== 'LINKED' && widgetType !== 'ONOFF' && widgetType !== 'SLIDER'
          && (
            [SelectDatasources, ConfigureWidget, WidgetPreview].map((step, index) => (
              <WizardStep
                component={step}
                title={<FormattedMessage id={`widgets.wizard.step${index + 3}.title`} />}
                lead={<FormattedMessage id={`widgets.wizard.step${index + 3}.text`} />}
                customIcon
                blackTitle
              />
            ))
          )}

        {(widgetType === 'HTML' || widgetType === 'IMAGE' || widgetType === 'PARAMETRIZED_TEXT' || widgetType === 'ONOFF' || widgetType === 'SLIDER')
        && (
          this.getComponents(widgetType).map((step, index) => (
            <WizardStep
              component={step}
              title={<FormattedMessage id={`widgets.wizard.step${index + 7}.${widgetType.toLowerCase()}.title`} />}
              lead={<FormattedMessage id={`widgets.wizard.step${index + 7}.${widgetType.toLowerCase()}.text`} />}
              customIcon
              blackTitle
            />
          ))
        )}

        {(widgetType === 'LINKED') && (
          [SelectDatasourcesForLinkedWidget, WidgetPreview].map((step, index) => (
            <WizardStep
              component={step}
              title={<FormattedMessage id={`widgets.wizard.step${index === 0 ? index + 3 : index + 4}.title`} />}
              lead={<FormattedMessage id={`widgets.wizard.step${index === 0 ? index + 3 : index + 4}.text`} />}
              customIcon
              blackTitle
            />
          ))
        )}
        {widgetType && (
          <WizardStep
            component={AssignPermissions}
            title={<FormattedMessage id="widgets.wizard.step6.title" />}
            lead={<FormattedMessage id="widgets.wizard.step6.text" />}
            customIcon
            blackTitle
            onSave={this.handleOnSave}
          />
        )}

        <Success />
      </Wizard>
    );
  }
}

ButtonOpen.propTypes = {
  showModal: PropTypes.func.isRequired,
};
ButtonOpen.defaultProps = {
};

export default CreateWidgets;
