import * as TYPES from './types';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export const addDevice = (Device) => store.dispatch({
  type: TYPES.ADD_DEVICE_REQUEST,
  payload: Device,
});

export const addDeviceSuccessFlag = () => store.dispatch({
  type: TYPES.ADD_DEVICE_SUCCESS_FLAG,
});

export const updateDevice = (Device) => store.dispatch({
  type: TYPES.MODIFY_DEVICE_REQUEST,
  payload: Device,
});

export const modifyDeviceSuccessFlag = () => store.dispatch({
  type: TYPES.MODIFY_DEVICE_SUCCESS_FLAG,
});

export const getDeviceList = (Device) => store.dispatch({
  type: TYPES.GET_DEVICE_LIST_REQUEST,
  payload: Device,
});

export const getDeviceListForWidgets = (Device) => store.dispatch({
  type: TYPES.GET_DEVICE_LIST_FOR_WIDGETS_REQUEST,
  payload: Device,
});

export const getDeviceListForSendingCommands = (Device, nameSpace) => store.dispatch({
  type: TYPES.GET_DEVICE_LIST_FOR_SENDING_COMMANDS_REQUEST,
  payload: Device,
  nameSpace,
});

export const deleteDevice = (Device) => store.dispatch({
  type: TYPES.DELETE_DEVICE_REQUEST,
  payload: Device,
});

export const getDevices = (payload) => store.dispatch({
  type: TYPES.GET_DEVICES_REQUEST,
  payload,
});

export const getMyDevices = (payload) => store.dispatch({
  type: TYPES.GET_MY_DEVICES_REQUEST,
  payload,
});

export const getDevice = (Device) => store.dispatch({
  type: TYPES.GET_DEVICE_REQUEST,
  payload: Device,
});

export const errorFetchingDeviceReset = () => store.dispatch({
  type: TYPES.ERRORFETCHING_DEVICE_RESET,
});

export const getDevicesForRules = (devices) => store.dispatch({
  type: TYPES.GET_DEVICES_FOR_RULES_REQUEST,
  payload: devices,
});

export const updateDevicesState = (payload) => store.dispatch({
  type: TYPES.UPDATE_DEVICES_STATE,
  payload,
});

export const clearDevicesState = (payload) => store.dispatch({
  type: TYPES.CLEAR_DEVICES_STATE,
  payload,
});
