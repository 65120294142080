import * as TYPES from './types';
import { store } from '../store';

export const addPage = () => store.dispatch({
  type: TYPES.ADD_REPORTPAGE_REQUEST,
});

export const getReports = (payload) => store.dispatch({
  type: TYPES.GET_REPORTS_REQUEST,
  payload,
});

export const getMyReports = (payload) => store.dispatch({
  type: TYPES.GET_MY_REPORTS_REQUEST,
  payload,
});

export const getReport = (Report) => store.dispatch({
  type: TYPES.GET_REPORT_REQUEST,
  payload: Report,
});

export const updateReport = (Report) => store.dispatch({
  type: TYPES.MODIFY_REPORT_REQUEST,
  payload: Report,
});

export const deleteReports = (Report) => store.dispatch({
  type: TYPES.DELETE_REPORTS_REQUEST,
  payload: Report,
});

export const generateReports = (Report) => store.dispatch({
  type: TYPES.GENERATE_REPORTS_REQUEST,
  payload: Report,
});

export const clearReportsState = (payload) => store.dispatch({
  type: TYPES.CLEAR_REPORTS_STATE,
  payload,
});

export const addReport = (Report) => store.dispatch({
  type: TYPES.ADD_REPORT_REQUEST,
  payload: Report,
});
