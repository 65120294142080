import { GEO_VALUES, MATH_VALUES } from './operatorOptions';
import { Value } from './severitySelect';

const GEOPOINT_OPERATORS = GEO_VALUES;
const MATH_OPERATORS = MATH_VALUES;

export const isGeoPointOperator = (operator) => GEOPOINT_OPERATORS.includes(operator);
export const isMathOperator = (operator) => MATH_OPERATORS.includes(operator);

const parseToPolygon = (value, operator) => {
  const polygon = Array.isArray(value) && value.find((val) => val && val.geometry && val.geometry.type.toLowerCase() === 'polygon');
  if (polygon) return { ...polygon.geometry, type: 'polygon' };
  if (value.coordinates) return value;
  if (operator) return { type: ['geo-in', 'geo-out'].includes(operator) ? 'polygon' : 'feature', coordinates: [value] };
  return { type: 'polygon', coordinates: [] };
};

// eslint-disable-next-line camelcase
export const parseToDevice = ({ device_id }, { key }) => ({
  type: 'device',
  entityId: device_id,
  attribute: key, // TODO: check if respond by key or by name
});

export const parseToValue = (operator, value) => {
  if (isGeoPointOperator(operator)) return { type: 'value', value: parseToPolygon(value, operator) };
  if (isMathOperator(operator)) return { type: 'value', value: isNaN(value) ? value : Number(value) };
  return null;
};

export const parseToCriteria = (criteria) => {
  const innerObject = [];
  if (criteria.device) innerObject.push(parseToDevice(criteria.device, criteria.attribute));
  if (criteria.value) innerObject.push(parseToValue(criteria.operator, criteria.value));
  return innerObject;
};

export const parseArrayToCriteria = (criteriaArray) => {
  if (criteriaArray.length > 1) {
    return {
      criteria: criteriaArray.map((criteria) => parseArrayToCriteria(criteria)),
      operation: 'and', // or?
      type: 'logic',
    };
  }

  if (criteriaArray) {
    const criteria = Array.isArray(criteriaArray) ? criteriaArray[0] : criteriaArray;
    return {
      criteria: parseToCriteria(criteria), // Siempre es un array
      operation: criteria.operator, // =, >, <, geo-in, geo-out
      type: 'logic',
    };
  }
  return null;
};

export default (conditions) =>
  // TODO: Check if conditions.length > 1, else parseArrayToCriteria.
  ({
    type: 'logic',
    operation: 'or',
    time_frame: '1s',
    criteria: conditions.map((criteria) => parseArrayToCriteria(criteria)),
  });
