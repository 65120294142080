/* eslint-disable import/no-cycle */
import Moment from 'moment';
import _, { orderBy } from 'lodash';
import { getTimeFromHistorical } from '../../../utils';
import { getLanguageFromBrowser } from '../../../../../helpers';
import { getUrnId } from '../../../../../helpers/utils';
import { getResolution, getResolutionByDateRange } from '../../../../../helpers/samplingHistorical';

const defaultParse = (details) => {
  details.data.forEach((d) => {
    const ordered = {};
    _(d).keys().sort().each((key) => {
      ordered[key] = d[key];
    });

    // eslint-disable-next-line no-param-reassign
    d = ordered;
  });
  return details;
};

export default function formatToBarWidget(
  historicalValues,
  sources,
  intl,
  configuration,
  parse = defaultParse,
) {
  const details = { data: [], index: [] };
  const lastValuesFromHistoricals = historicalValues.filter((h) => !h.entities);
  const aggregatesHistoricalValues = historicalValues.filter((h) => h.entities);
  const sourcesArray = [];

  if (configuration.data.type === 'historical') {
    const sourceNames = [];

    Moment.locale(getLanguageFromBrowser() || 'en');

    aggregatesHistoricalValues.forEach((h) => {
      h.entities.forEach((e) => {
        const index = sourcesArray.findIndex((d) => d.entityId === e.entityId);
        if (index > -1) {
          // We check that the attribute exists on the device.
          if (!Object.keys(sourcesArray[index].attributes).includes(e.attrName)) {
            // If it doesn't exist, we create it and then we inject the origin points
            sourcesArray[index].attributes[e.attrName] = { [h.origin]: e.points };
          } else {
            // If it exists, the only thing we have to do is create the new key
            // with the origin and its points.
            sourcesArray[index].attributes[e.attrName][h.origin] = e.points;
          }
        } else {
          sourcesArray.push({
            id: getUrnId(e.urn),
            entityId: e.entityId,
            attributes: {
              [e.attrName]: {
                [h.origin]: e.points,
              },
            },
          });
        }
      });
    });

    sourcesArray.forEach((source) => {
      Object.keys(source.attributes).forEach((attribute) => {
        if (
          configuration.data.attributeFilter
          && !configuration.data.attributeFilter.includes(attribute)
        ) {
          return;
        }
        let name = `${source.entityId}-${attribute}`;
        const equalName = sourceNames.filter((sourceName) => sourceName.entityId === name);
        if (equalName.length > 0) {
          name = `${equalName[0].entityId}(${equalName[0].number})`;
          equalName[0].number += 1;
        } else {
          sourceNames.push({ name, number: 1 });
        }

        Object.keys(source.attributes[attribute]).forEach((origin) => {
          source.attributes[attribute][origin].forEach((point) => {
            const resolution = configuration.data.period === 'temporary-period'
              ? getResolutionByDateRange(configuration.data.startDate, configuration.data.endDate)
              : getResolution(configuration.data.sampling);
            const xTime = getTimeFromHistorical(resolution, origin, point.offset);
            const datafound = details.data.find((d) => (d.sourceId === xTime));
            if (sourcesArray.length > 1) {
              if (datafound) {
                datafound[`${source.entityId}-${attribute}`] = Number.parseFloat(point.avg).toFixed(2);
              } else {
                details.data.push({
                  sourceId: xTime,
                  [`${source.entityId}-${attribute}`]: Number.parseFloat(point.avg).toFixed(2),
                });
              }
            } else if (datafound) {
              datafound[attribute] = Number.parseFloat(point.avg).toFixed(2);
            } else {
              details.data.push({
                [attribute]: Number.parseFloat(point.avg).toFixed(2),
                sourceId: xTime,
              });
            }
          });
        });
      });
    });
    details.index = sourceNames.map((d) => d.name);
    details.data = orderBy(details.data, ['sourceId'], ['asc']);
  } else {
    const attrs = [];
    const data = [];

    lastValuesFromHistoricals.forEach((h) => {
      if (!h.value) return;
      const index = attrs.findIndex((attr) => attr.name === h.attrName);
      if (index >= 0) {
        attrs[index].entities.push(
          {
            entityId: h.entityId,
            value: h.value.value,
          },
        );
      } else {
        attrs.push({
          name: configuration.labels.alias?.[h.attrName] || h.attrName,
          attrName: h.attrName,
          entities: [{
            entityId: h.entityId,
            value: h.value.value || h.value,
          }],
        });
      }
      if (data.length) {
        data[0][`${h.entityId}-${h.attrName}`] = h.value.value || h.value;
      } else {
        data.push({
          sourceId: intl.formatMessage({ id: 'widget.bars.lastValue' }),
          [`${h.entityId}-${h.attrName}`]: h.value.value || h.value,
        });
      }
    });

    details.data = data;
    details.index = attrs;
  }

  // This is the code for the future bars widget entity comparator

  // else {
  //   const attrs = [];

  //   lastValuesFromHistoricals.forEach((h) => {
  //     if (!h.value) return;
  //     const index = attrs.findIndex((attr) => attr.name === h.attrName);
  //     if (index >= 0) {
  //       attrs[index].entities.push(
  //         {
  //           entityId: getSourceTag(h, sources),
  //           value: h.value.value,
  //         },
  //       );
  //     } else {
  //       attrs.push({
  //         name: configuration.labels.alias[h.attrName] || h.attrName,
  //         attrName: h.attrName,
  //         entities: [{
  //           entityId: getSourceTag(h, sources),
  //           value: h.value.value || h.value,
  //         }],
  //       });
  //     }
  //   });

  //   details.data = attrs;
  //   details.index = attrs;
  // }
  return parse(details, configuration);
}
