import React from 'react';
import { injectIntl } from 'react-intl';
import InsertableTr from './InsertableTr';
import './styles.scss';
import { FormattedMessage } from '../../Contexts/LanguageContext/index';
import Static from '../../models/Attribute/Static';
import DragAndDrop from '../DragAndDropGrid';
import { getHash } from '../../helpers/utils';

const ROW_IDENTIFICATION = 'tr-';

class EditableTable extends React.Component {
  constructor(props) {
    super(props);

    const addRowData = this.resetAddRowData();

    this.state = {
      layouts: {},
      newErrors: {},
      editErrors: {},
      addRowData,
      body: this.numberRows(this.props.body) || [],
      onEditData: null,
    };
  }

  numberRows = (data) => data.map((d, i) => {
    d.rowId = i;
    return d;
  });

  resetAddRowData = () => {
    const addRowData = {};
    this.props.header.forEach((h) => {
      h && (addRowData[h.key] = '');
    });

    return addRowData;
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.body !== this.props.body &&
  //     this.props.header.find(h => h.key === 'size')
  //   ) {
  //     const availableBits = this.calcAvailableBits();
  //     this.setState({
  //       availableBits
  //     });
  //   }
  //
  //   if(prevProps.body !== this.props.body){
  //     this.setState({body: this.numberRows(this.props.body)})
  //
  //   }
  // }

  handleSelectChange = (id, value) => {
    const { addRowData, onEditData } = this.state;

    if (onEditData === null) {
      const newRowData = addRowData;
      newRowData[id] = value;
      this.setState({
        addRowData: newRowData,
      });
    } else {
      const newOnEdit = onEditData;
      newOnEdit[id] = value;
      this.setState({
        onEditData: newOnEdit,
      });
    }
  };

  handleInputChange = (event) => {
    const { addRowData, onEditData } = this.state;
    const { value, name } = event.target;

    if (onEditData === null) {
      const newRowData = addRowData;
      newRowData[name] = value;
      this.setState({
        addRowData: newRowData,
      });
    } else {
      const newOnEdit = onEditData;
      newOnEdit[name] = value;
      this.setState({
        onEditData: newOnEdit,
      });
    }
  };

  getValidators = (property) => {
    const attributes = this.props.modelOfData;
    return attributes.find((a) => a.value === property).properties;
  };

  getEntity = (modelOfData, attributeType) => modelOfData.find((m) => m.value === attributeType).class;

  thereAreUniqueFields = () => {
    const { header } = this.props;
    return header.findIndex((h) => h.unique === true) !== -1;
  };

  getUniqueFields = () => {
    const { header } = this.props;
    return header.filter((h) => h.unique === true).map((u) => u.key);
  };

  findCoincidences = (key, data, rowId) => {
    let coincidences = 0;
    let i = 0;
    const { body, onEditData } = this.state;
    while (coincidences === 0 && i < body.length) {
      body.forEach((o) => {
        if (onEditData) {
          o[key] === data && o.rowId !== rowId && coincidences++;
        } else {
          o[key] === data && coincidences++;
        }
      });
      i += 1;
    }
    return coincidences !== 0;
  };

  handleOnClickAddButton = () => {
    const { onEditData, addRowData } = this.state;
    const { header, dataKey, modelOfData } = this.props;

    let newData = [...this.state.body];
    const newRow = onEditData === null ? addRowData : onEditData;
    let errors = {};
    const Entity = this.getEntity(modelOfData, newRow[dataKey]);

    const createdEntity = new Entity({
      ...newRow,
    });

    const validators = !modelOfData
      ? header.map((h) => h.key)
      : this.getValidators(newRow[dataKey]);

    const validatedEntity = createdEntity.validate(validators);

    if (this.thereAreUniqueFields()) {
      this.getUniqueFields().forEach((key) => {
        if (
          !(createdEntity instanceof Static && key === 'key')
          && this.findCoincidences(key, createdEntity[key], newRow.rowId)
          && !validatedEntity[key]
        ) {
          validatedEntity.error = true;
          validatedEntity[key] = (
            <FormattedMessage id="devices.wizard.repeated.data" />
          );
        }
      });
    }

    if (validatedEntity.error) {
      delete validatedEntity.error;
      errors = validatedEntity;
    }

    if (Object.keys(errors).length > 0) {
      if (onEditData) {
        this.setState({
          editErrors: errors,
          newErrors: {},
        });
      } else {
        this.setState({
          newErrors: errors,
          editErrors: {},
        });
      }
    } else {
      if (onEditData === null) {
        newData.push(newRow);
        newData = this.numberRows(newData);
        const newAddRowData = this.resetAddRowData();
        this.setState({
          body: newData,
          addRowData: newAddRowData,
          newErrors: {},
          editErrors: {},
          onEditData: null,
        });
      } else {
        newData[newRow.rowId] = newRow;
        this.setState({
          body: newData,
          onEditData: null,
        });
      }
      this.props.onChange && this.props.onChange(newData);
    }
  };

  handleOnDeleteClick = (row) => {
    const { orderBody } = this.state;
    const newData = [...this.state.body];
    const prevData = newData.slice(0, row);
    let postData = newData.slice(row + 1, newData.length);

    postData = postData.map((data) => {
      data.rowId -= 1;
      return data;
    });

    this.setState(
      {
        body: [...prevData, ...postData],
        orderBody: orderBody.filter((r) => row.oriPos !== ROW_IDENTIFICATION.concat(r)),
      },
      () => this.props.onChange && this.props.onChange(this.state.body, this.state.orderBody),
    );
  };

  handleOnEditClick = (e, row) => {
    this.setState({
      onEditData: { ...row },
      // onEditDataStyle: e.target.parentNode.parentNode.style,
      onEditDataClassList: e.target.parentNode.parentNode.classList,
    });
  };

  getData = (header, data) => {
    if (header.key === 'property') return header.data.find((o) => o.value === data[header.key]).name;

    if (header.key === 'type') return <FormattedMessage id={`Attribute.model.type.${data[header.key]}`} />;

    if (data[header.key] === '') {
      return (
        <span className="empty">
          &mdash;
          <FormattedMessage id="devices.wizard.empty" />
        </span>
      );
    }

    return !Array.isArray(data[header.key]) ? data[header.key]
      : data[header.key].map((o) => o.name).toString();
  };

  getHeaders = () => {
    const headers = [...this.props.header];
    // Add data to selects attributes
    this.props.modelOfData.forEach((attribute) => {
      if (attribute.type) {
        const protocolType = headers.find((p) => p.key === 'type');
        protocolType.data = {
          ...protocolType.data,
          [attribute.value]: attribute.type,
        };
        protocolType.type = 'select';

        attribute.type.forEach((t) => {
          if (t.subtype) {
            t.subtype.forEach((s) => {
              const protocolSubtype = headers.find((p) => p.key === s.value);
              protocolSubtype.data = {
                ...protocolSubtype.data,
                [t.value]: typeof s.data === 'function' ? s.data(this.state) : s.data,
              };
            });
          }
        });
      }
    });
    return headers;
  };

  handleOnChangeDragAndDrop = (layout) => {
    const prevBody = [...this.props.body];
    const currentBody = [];

    layout.forEach((row, index) => {
      currentBody[row.y] = { ...prevBody[index], oriPos: row.i };
    });

    this.setState({
      orderBody: currentBody,
    }, () => this.props.onChangeLayout && this.props.onChangeLayout([...this.state.body],
      [...this.state.orderBody]));
  };

  render() {
    const { header, modelOfData, dataKey } = this.props;
    const {
      newErrors,
      editErrors,
      body,
      addRowData,
      availableBits,
    } = this.state;

    // On Edit Data
    const {
      onEditData,
      onEditDataClassList,
    } = this.state;

    return (
      <div className="editableTable">
        <table>
          <colgroup>
            {header
            && header.map((key, i) => <col key={getHash(`colgroup-col-${i}`)} />)}
            <col />
          </colgroup>
          <thead>
            <tr>
              {header.map((h, i) => <th key={getHash(`th_${i}`)}>{h.label}</th>)}
              <th />
            </tr>
          </thead>
          <DragAndDrop
            // layouts = {layouts}
            customComponent="tbody"
            // className={isSubMenu ? 'draggable-submenu': ''}
            onChange={this.handleOnChangeDragAndDrop}
            isDraggable
            isResizable={false}
            // draggableCancel={'.MyNonDraggableAreaClassName'}
            cols={{
              lg: 1, md: 1, sm: 1, xs: 1, xxs: 1,
            }}
            rows={availableBits}
            rowHeight={50}
          >
            {body.map((row) => (onEditData
              && onEditData.rowId === row.rowId ? (
                <InsertableTr
                  header={this.getHeaders()}
                  key={`${ROW_IDENTIFICATION}${row.rowId}`}
                  className={onEditDataClassList}
                  // style={onEditDataStyle}
                  addRowData={onEditData}
                  errors={editErrors}
                  handleSelectChange={this.handleSelectChange}
                  handleInputChange={this.handleInputChange}
                  handleOnClickAddButton={this.handleOnClickAddButton}
                  modelOfData={modelOfData}
                  dataKey={dataKey}
                />
              ) : (
                <tr key={`${ROW_IDENTIFICATION}${row.rowId}`}>
                  {header.map((h, i) => (
                    <td key={getHash(`td-${h.key}-${i}`)}>{this.getData(h, row)}</td>
                  ))}
                  <td>
                    <i
                      role="presentation"
                      className="uil uil-trash-alt"
                      onClick={() => this.handleOnDeleteClick(row.rowId)}
                    />
                    <i
                      role="presentation"
                      onClick={(e) => this.handleOnEditClick(e, row)}
                      className="uil uil-edit-alt"
                    />
                  </td>
                </tr>
              )))}
          </DragAndDrop>

          <tfoot>
            <InsertableTr
              header={this.getHeaders()}
              addRowData={addRowData}
              errors={newErrors}
              handleSelectChange={this.handleSelectChange}
              handleInputChange={this.handleInputChange}
              handleOnClickAddButton={this.handleOnClickAddButton}
              modelOfData={modelOfData}
              dataKey={dataKey}
            />
          </tfoot>
        </table>
      </div>
    );
  }
}
export default injectIntl(EditableTable);
