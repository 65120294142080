import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'ramda';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import validate from 'validate.js';
import CommonView from '../../../../../CommonView';
import { withWizardStep } from '../../../../../../Contexts/WizardStepContext';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext';
import { colorList, imageList } from '../../../../../../configuration/variables';
import './styles.scss';

const Slider = ({
  imageSelected, addImage, errors,
}) => (
  <div className="sliderContainer">
    <div className={`sliderHeader ${(isEmpty(errors) || imageSelected)
      ? '' : 'sliderHeaderError'}`}
    >
      <span><FormattedMessage id="widgetImage.wizard.step1.text-image" /></span>
    </div>
    <div className="sliderBody">
      {imageList.map((i) => {
        if (imageSelected) {
          return (
            <button type="button">
              <img
                id={i}
                className={imageSelected && imageSelected === i
                  ? 'imageSelected' : 'imageNoSelected'}
                onClick={addImage}
                src={i}
                alt=""
              />
            </button>
          );
        }
        return (
          <button type="button">
            <img id={i} className="defaultImage" onClick={addImage} src={i} alt="" />
          </button>
        );
      })}
    </div>
  </div>
);

Slider.propTypes = {
  imageSelected: PropTypes.string.isRequired,
  addImage: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
};

Slider.defaultProps = {
  errors: [],
};

const ColorOptions = ({ colorSelected, addColor, errors }) => (
  <div className="colorsContainer">
    <div className={`colorsHeader ${(isEmpty(errors) || colorSelected)
      ? '' : 'colorsHeaderError'}`}
    >
      <span><FormattedMessage id="widgetImage.wizard.step1.text-color" /></span>
    </div>
    <div className="colorsBody">
      {colorList.map((colorStyle) => {
        if (colorSelected) {
          return (
            <button
              className={`colorFrame ${colorSelected === colorStyle ? 'selectedColor'
                : 'unselectedColor'}`}
              onClick={() => addColor(colorStyle)}
              type="button"
            >
              <div className={`${`colorContent ${colorStyle}`}`} />
            </button>
          );
        }
        return (
          <button
            className="colorFrame"
            onClick={() => addColor(colorStyle)}
            type="button"
          >
            <div className={`${`colorContent ${colorStyle}`}`} />
          </button>
        );
      })}
    </div>
  </div>
);

ColorOptions.propTypes = {
  colorSelected: PropTypes.string.isRequired,
  addColor: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
};

ColorOptions.defaultProps = {
  errors: [],
};

class AddWidgetImage extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      config: {
        appearance: {},
        colors: {},
      },
      imageSelected: this.props.newEntity?.config?.imageSelected ?? null,
      colorSelected: this.props.newEntity?.config?.colorSelected ?? null,
      enabledHeader: this.props.newEntity?.config?.enabledHeader ?? false,
      errors: {},
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newEntity !== this.props.newEntity) {
      this.updateState();
    }
  }

  updateState() {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...this.props.newEntity,
      }),
    );
  }

  validate = () => {
    this.savePartialWidget();
  };

  savePartialWidget = () => {
    this.setErrors({});
    const constraints = {};
    const { imageSelected, colorSelected, enabledHeader } = this.state;
    const values = { imageSelected, colorSelected, enabledHeader };

    constraints.imageSelected = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };

    constraints.colorSelected = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };

    const validation = validate(values, constraints);

    if (validation) {
      this.setState({ errors: validation });
    } else {
      this.props.onContinue({
        config: this.state.config,
        connectedDevices: this.state.devices,
      });
    }
  };

  handleChangeSelect = (name, value, category = undefined, subCategory = undefined) => {
    let oldValue = category && !subCategory
      ? this.state.config[category][name]
      : this.state.config[name];
    if (subCategory) oldValue = this.state.config[category][subCategory][name];

    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      this.setState({ config }, this.handleSave);
    }
  };

  addImage = (e) => {
    this.setState({
      imageSelected: e.target.id,
    },
    () => {
      const { imageSelected, config = {} } = this.state;
      this.handleChangeSelect('imageSelected', imageSelected, 'appearance');
    });
  }

  addColor = (color) => {
    this.setState({
      colorSelected: color,
    },
    () => {
      const { colorSelected, config = {} } = this.state;
      this.handleChangeSelect('colorSelected', colorSelected, 'colors');
    });
  }

  handleChange = () => {
    const { config } = this.state;
    let { enabledHeader } = this.state;
    enabledHeader = !enabledHeader;
    this.setState({
      enabledHeader,
    },
    () => {
      this.handleSelectChange('config', { ...config, enabledHeader });
    });
  }

  render() {
    const {
      imageSelected, colorSelected, enabledHeader, errors,
    } = this.state;

    return (
      <>
        <Row>
          <Col xs="12" className="sliderImage">
            <Slider
              imageList={imageList}
              imageSelected={imageSelected}
              addImage={this.addImage}
              errors={errors.imageSelected}
            />
          </Col>
          <Col xs="12" className="colorOptions">
            <ColorOptions
              colorSelected={colorSelected}
              addColor={this.addColor}
              errors={errors.colorSelected}
            />
          </Col>
          <Col className="preview">
            <div className="previewContainer">
              <div className="previewText">
                <FormattedMessage id="widgetImage.wizard.step1.preview" />
              </div>
              <div className="previewBody">
                <div>
                  {(!imageSelected && !colorSelected)
                    ? (
                      <div className="defaultPreview">
                        <p>
                          <FormattedMessage
                            id="widgetImage.wizard.step1.previewText"
                          />
                        </p>
                      </div>
                    )
                    : (
                      <div className={(imageSelected && !colorSelected)
                        ? 'hideShadow' : `showShadow ${colorSelected}`}
                      />
                    )}
                  <img src={imageSelected} alt="" />
                </div>
              </div>
              <div className="checkboxHeader">
                <FormControlLabel
                  control={(
                    <Checkbox
                      onChange={this.handleChange}
                      checked={enabledHeader}
                      color="primary"
                      inputProps={{ 'aria-label': 'trendline checkbox' }}
                      style={{ padding: '0px' }}
                    />
                   )}
                  label={<FormattedMessage id="widgetImage.wizard.step1.enabled-header" />}
                />
              </div>
              {(!isEmpty(errors) && (!imageSelected || !colorSelected))
               && (
               <div className="validation">
                 <p className="validationError">
                   <FormattedMessage
                     id="widgetImage.wizard.step1.validation-error"
                   />
                 </p>
               </div>
               )}
            </div>
          </Col>

        </Row>
      </>
    );
  }
}

export default withWizardStep(injectIntl(AddWidgetImage));
