import Faker from 'faker'
import { examplePermissionPolicy } from "../redux/templates/reducer"
import { menuManagement } from '../../configuration/config'
import { requestAPI } from './../../helpers/'

const endPointElement = menuManagement.urlAPI.concat(menuManagement.paths.element);
const endPointTree = menuManagement.urlAPI.concat(menuManagement.paths.tree);
const endPointPosition = menuManagement.urlAPI.concat(menuManagement.paths.position);
const endPointSettings = menuManagement.urlAPI.concat(menuManagement.paths.settings);


const exampleBodyFn = (data = null, numberOfMenuElementsToGenerate = 50) => {
    const MenuElementsFaker = [];
    for (let i = 0; i < numberOfMenuElementsToGenerate; i++) {
        MenuElementsFaker.push({
            id: Faker.random.uuid(),
            name: Faker.random.word(),
            permissions_policy: Faker.random.arrayElement(examplePermissionPolicy),
            owner: Faker.random.uuid(),
            resource: {},
            parent: ''

        })
    }
    return { roots: MenuElementsFaker };
};

/*** First version of Template CRUD */
// PLURAL
export const readTree = async () => {

    const params = {
        endPoint: endPointTree,
        statusOK: 200,
        toJSON: true,
        returnData: 'Tree',
        headers: {
            'cache-control': 'no-cache',
            'Cache-Control': 'no-cache'
        }
    };

    return requestAPI(params, exampleBodyFn);
};

export const readMenuElement = async (menuElement) => {

    if (!menuElement['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPointElement.concat('/', menuElement.id),
        statusOK: 200,
        toJSON: true,
        returnData: 'menuElement'
    };

    return requestAPI(params, exampleBodyFn);
};


export const addMenuElement = async (menuElement) => {

    if (menuElement['id']) return { status: 400, error: 'invalid id attr' };

    const params = {
        endPoint: endPointElement,
        statusOK: 201,
        toJSON: true,
        returnData: 'menuElement',
        requestConfig: {
            method: 'POST',
            body: JSON.stringify(menuElement),
            headers: {
                'Content-Type': 'application/json',
            }
        }
    };

    return requestAPI(params, exampleBodyFn);
};


export const updateMenuElement = (menuElement) => {

    if (!menuElement['id']) return { status: 400, error: 'missing id attr' };

    const params = {
        endPoint: endPointElement.concat("/", menuElement.id),
        statusOK: 200,
        toJSON: false,
        returnData: menuElement,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify(menuElement),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};

export const updatePositionElement = (positionElements) => {

    // if (!positionElements['id']) return { status: 400, error: 'missing id attr' };
    const params = {
        endPoint: endPointPosition,
        statusOK: 200,
        toJSON: false,
        returnData: positionElements,
        requestConfig: {
            method: 'PUT',
            body: JSON.stringify({ menu_elements: positionElements }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};



export const deleteMenuElement = (menuElement) => {
    if (!menuElement['id']) return { status: 400, error: 'missing id attr' };
    const params = {
        endPoint: endPointElement.concat("/", menuElement.id),
        statusOK: 204,
        toJSON: false,
        returnData: menuElement,
        requestConfig: {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
    return requestAPI(params, exampleBodyFn);
};


export const getSettingsMenu = () => {
    const params = {
        endPoint: endPointSettings + '/mine',
        statusOK: 200,
        toJSON: true,
        returnData: 'menuElement',
        requestConfig: {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
}


export const updateSettingsMenu = (payload) => {
    if (!payload.payload) return { status: 400, error: 'missing id attr' };
    const params = {
        endPoint: endPointSettings + '/set/home/' + payload.payload,
        statusOK: 200,
        toJSON: true,
        requestConfig: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
}

export const setHome = (payload) => {
    if (!payload.payload) {
        return { status: 400, error: 'missing id attr' };
    }
    const params = {
        endPoint: `${endPointSettings}/set/home/${payload.payload}`,
        statusOK: 200,
        toJSON: true,
        returnData: 'menuElement',
        requestConfig: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    };
    return requestAPI(params, exampleBodyFn);
};

export const clearHome = () => {
    const params = {
        endPoint: endPointSettings + '/clear/home',
        statusOK: 200,
        toJSON: true,
        returnData: 'menuElement',
        requestConfig: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return requestAPI(params, exampleBodyFn);
}