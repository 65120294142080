import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

// TODO
// These expressions are suposed to work better than used ones,
// because of generalization, but somethin isn't working propperly.
// Cant start wit space or dot, after first letter, allows letter, spaces and dots
// Have to solve how to avoid @ # and other characters identified as letters.
// ^(?!(\.|\s).*$)(\p{L}|\p{Z}|\p{P})+
// ^(?!\\p{Z}|\\p{P})\\p{L}*[\\p{L}\\p{Z}\\p{P}]*

export const additionalPassConstraint = [
  {
    key: 'password',
    defaultValue: '',
    constraints: {
      length: {
        minimum: 8,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '8' }}
          />
        ),
      },
    },
  },
];

/** Model by keys, values expected and constraints ( optional ) */
export const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: {
        message: "can't be empty",
      },
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
      format: {
        pattern: /^(?!\s|\.)[a-zA-ZÁÉÍÓÚñáéíóúÑ ]((['. -][a-zA-ZÁÉÍÓÚñáéíóúÑ ])?[a-zA-ZÁÉÍÓÚñáéíóúÑ]*)*$/,
        flags: 'g',
        message: <FormattedMessage id="Validation.noSpecialCharacter.noNumber" />,
      },
    },
  },
  {
    key: 'surname',
    defaultValue: '',
    constraints: {
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '1' }}
          />
        ),
      },
      format: {
        pattern: /^(?!\s|\.)[a-zA-ZÁÉÍÓÚñáéíóúÑ ]((['. -][a-zA-ZÁÉÍÓÚñáéíóúÑ ])?[a-zA-ZÁÉÍÓÚñáéíóúÑ]*)*$/,
        flags: 'g',
        message: <FormattedMessage id="Validation.noSpecialCharacter.noNumber" />,
      },
    },
  },
  {
    key: 'email',
    defaultValue: '',
    constraints: {
      presence: true,
      email: {
        message: <FormattedMessage id="Validation.email" />,
      },
    },
  },
  {
    key: 'password',
    defaultValue: '',
  },
  {
    key: 'roles',
    defaultValue: [],
    constraints: {
      length: {
        minimum: 1,
        message: (
          <FormattedMessage
            id="Validation.minimum.item"
            values={{ minimum: '1' }}
          />
        ),
      },
    },
  },
  {
    key: 'permissions',
    defaultValue: [],
  },
  {
    key: 'groups',
    defaultValue: [],
  },
  {
    key: 'avatar',
    defaultValue: '',
  },
];
