import Styled from 'styled-components';
import { darken } from 'polished'

export const Index = Styled('div')`
  height: fit-content;
  width: fit-content;
  padding: 0.5em;
  border-radius: 50%;
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
  
  &:hover{
    background: ${p=>p.backgroundColor};
    color: ${p=>darken(1, p.backgroundColor)};
    cursor: pointer;
  }
  
  ${p=>p.css}
`;

Index.defaultProps={
  backgroundColor: 'rgba(219, 219, 219, 0.75)',
  css: ''
};

export default Index;