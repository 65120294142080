/* eslint-disable import/no-cycle */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { clone, isEmpty } from 'ramda';
import CommonView from '../../../../../../CommonView';
import { withWizardStep } from '../../../../../../../Contexts/WizardStepContext';
import WidgetCommandsAttributes from '../../../../../Profile/newcomponents/Commands/OnOff/WidgetOnOffAttributes';
import Field from '../../../../../../../elements/Field';
import { OnOffWizardConfig } from '../../../../../../../models/WidgetV2/inherited/onoff/utils/configurationSheet';
import { FormattedMessage } from '../../../../../../../Contexts/LanguageContext';
import { getWidgetModel } from '../../../../../../../models/WidgetV2/utils';
import { getCommandAttributes } from '../../../../../helpers/util';

class ConfigureWidgetCommands extends CommonView {
  constructor(props) {
    super({ props });
    const { newEntity } = this.props;

    this.state = {
      second_state: false,
      page: 1,
      size: 5,
      sources: newEntity.sources,
      config: props.config ?? newEntity.defaultConfig,
      ...OnOffWizardConfig,
    };
  }

  handleCommandGroup = (commands, state) => {
    const newList = clone(this.state.commandsGroups);
    const { sources } = this.state;
    sources.forEach((source) => {
      source.commands.forEach((att, index) => {
        const found = commands.find((at) => at.commandName === att.name);
        if (found) {
          const obj = {
            commandName: found.commandName,
            sourcesId: [source.id],
            value: found.value,
            groupId: new Date().getTime() + index,
          };

          const commandFound = newList[state].length && newList[state].find(
            (c) => c.commandName === found.commandName
          && c.value === found.value,
          );

          if (commandFound) {
            commandFound.sourcesId.push(source.id);
          } else if (Object.keys(newList[state]).length) {
            newList[state].push(obj);
          } else newList[state] = [obj];
        }
      });
    });
    this.setState({ commandsGroups: newList });
  }

  handleEditCommandsGroup = (point, state) => {
    const list = clone(this.state.commandsGroups);
    const index = list[state].findIndex((c) => c.groupId === point.groupId);
    if (index >= 0) {
      if (point.commandName !== list[state][index].commandName) {
        delete list[state][index];
      }
      list[state][index] = point;
    }
    this.setState({ commandsGroups: list });
  };

  handleDeleteCommandsGroup = (group, state, index) => {
    const list = clone(this.state.commandsGroups);
    list[state].splice(index, 1);
    this.setState({
      commandsGroups: list,
    });
  }

  handleDeleteIndividualCommand = (commands, state) => {
    const list = clone(this.state.commandsGroups);
    const commandIndex = list[state].findIndex((c) => c.groupId === commands.groupId);
    list[state][commandIndex] = commands;
    this.setState({
      commandsGroups: list,
    });
  }

  handleChange = (name, value, index, state, availableCommands) => {
    const commands = value?.available_commands?.map((c) => c);
    const availableCommandsClone = clone(this.state.availableCommands);
    const configClone = clone(this.state.config);
    const commandsToShowClone = clone(this.state.commandsToShow);
    if (name === 'device') {
      configClone.conditions.numberOfPoints.forEach((point) => {
        if (point.state === state) {
          const aux = point;
          aux.commands[index].sourcesId = [value.id];
          aux.commands[index].commandName = '';
          aux.commands[index].value = '';
        }
      });
      commandsToShowClone[value.id] = value.commands;

      this.setState({
        commandsToShow: commandsToShowClone,
        config: configClone,
      });
    }
    if (name === 'attribute') {
      configClone.conditions.numberOfPoints.forEach((point) => {
        if (point.state === state) {
          const aux = point;
          aux.commands[index].commandName = value.name;
          aux.commands[index].value = '';
        }
      });
      const found = availableCommandsClone.find((c) => c.state === state).commands;
      if (found[index]) found[index] = commands;
      else found.push(commands);
      this.setState({
        availableCommands: availableCommandsClone,
        config: configClone,
      });
    }
    if (name === 'command') {
      let indexOfCommand = 0;
      let addCommand = false;
      const commandsState = availableCommandsClone.find((c) => c.state === state).commands;
      let v = '';
      commandsState.forEach((com) => {
        v = com.find((co) => co.name === value)?.value;
      });
      let val = v || value;
      configClone.conditions.numberOfPoints.forEach((point) => {
        if (point.state === state) {
          const aux = point;
          availableCommands.forEach((pointIt) => {
            if (pointIt.state === state) {
              pointIt.commands.forEach((commandIt) => {
                commandIt.forEach((valueIt) => {
                  if (valueIt.name === value) {
                    val = valueIt.value;
                  }
                });
              });
            }
          });
          aux.commands[index].value = val;
          if (aux.commands[index].commandName !== ''
              && aux.commands[index].value !== ''
              && aux.commands[index].sourcesId.length) {
            indexOfCommand = index;
            addCommand = true;
          }
        }
      });
      this.setState({
        config: configClone,
      });
      if (addCommand) this.addCommand(indexOfCommand, state, false, configClone);
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  validateConmmand = (i, state, addNewCommand, config) => {
    const localErrorsClone = clone(this.state.localErrors);
    const configClone = config || clone(this.state.config);

    configClone.conditions.numberOfPoints.forEach((point) => {
      if (point.state === state) {
        point.commands.forEach((commandIt, index) => {
          if (index === i) {
            if (commandIt.sourcesId.length === 0) {
              localErrorsClone.source.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.device" />],
              });
            } else {
              localErrorsClone.source = [];
            }
            if (commandIt.commandName === '') {
              localErrorsClone.attribute.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.attribute" />],
              });
            } else {
              localErrorsClone.attribute = [];
            }
            if (commandIt.value === '') {
              localErrorsClone.command.push({
                index,
                state,
                error: true,
                message: [<FormattedMessage id="widgets.wizard.sendCommand.validation.command" />],
              });
            } else {
              localErrorsClone.command = [];
            }
          }
        });
        if (!localErrorsClone.source.length
          && !localErrorsClone.command.length
          && !localErrorsClone.attribute.length
          && addNewCommand) {
          point.commands.push({
            commandName: '',
            sourcesId: [],
            value: '',
          });
        }
      }
    });
    return { config: configClone, errors: localErrorsClone };
  }

  addCommand = (i, state, addNewCommand, config) => {
    const validate = this.validateConmmand(i, state, addNewCommand, config);
    this.setState({
      config: validate.config,
      localErrors: validate.errors,
    });
  };

  removeCommand = (state, index) => {
    const configClone = clone(this.state.config);
    configClone.conditions.numberOfPoints.forEach((num) => {
      if (num.state === state) {
        num.commands.splice(index, 1);
      }
    });
    this.setState({
      config: configClone,
    });
  }

  validate = () => {
    const { commandsGroups, config } = this.state;
    const configClone = clone(config);
    const newEntityClone = clone(this.props.newEntity);
    // add commandsGroup to the config
    Object.keys(commandsGroups).forEach((stateCommand) => {
      Object.keys(commandsGroups[stateCommand]).forEach((command, index) => {
        const commandsGroupToAdd = commandsGroups[stateCommand][index];
        configClone.conditions.numberOfPoints.forEach((p) => {
          const buttonState = p.state ? 'on' : 'off';
          if (stateCommand === buttonState) {
            let v = '';
            if (commandsGroupToAdd.value.split(' ').length === 2) {
              // eslint-disable-next-line prefer-destructuring
              v = commandsGroupToAdd.value.split(' ')[1];
            } else {
              v = commandsGroupToAdd.value;
            }
            const commandAdd = {
              commandName: commandsGroupToAdd.commandName,
              sourcesId: commandsGroupToAdd.sourcesId,
              value: v,
              groupId: commandsGroupToAdd.groupId,
            };
            p.commands.push(commandAdd);
          }
        });
      });
    });
    configClone.conditions.numberOfPoints = configClone.conditions.numberOfPoints
      .map((p) => {
        const aux = p;
        aux.commands = aux.commands.filter((c) => (c.sourcesId !== ''
        && c.commandName !== '' && c.value !== ''));
        return aux;
      });

    newEntityClone.config = configClone;
    const newWidget = getWidgetModel(newEntityClone);
    const validator = newWidget.getValidatorConfig();
    const validation = validator(configClone);
    if (validation) {
      this.setState({ errors: validation });
    } else {
      this.props.onContinue({
        config: configClone,
        origins: this.state.sources,
      });
    }
  };

  handleDescription = (e) => {
    const configClone = clone(this.state.config);
    const { value } = e.target;
    configClone.labels.description = value;
    this.setState({ config: configClone });
  };

  render() {
    const { intl, isProfile, newEntity } = this.props;
    const {
      commandsToShow, sources: _sources, config, availableCommands,
      allAttributes, commandsGroups, errors,
    } = this.state;
    const { sources } = newEntity;

    return (
      <div className="wizardWrapper addWidget">
        <Row className="justify-content-center mb-4">
          <Col sm={6}>
            <Field
              key={1}
              name="description"
              label={intl.formatMessage({ id: 'widgets.wizard.sendCommand.buttonDescription' })}
              placeholder={intl.formatMessage({
                id: 'widgets.wizard.sendCommand.buttonDescription.placeholder',
              })}
              type="textarea"
              onChange={this.handleDescription}
              value={config.labels.description}
            />
          </Col>
        </Row>
        {config.conditions.numberOfPoints.map((stateButton, index) => (
          <WidgetCommandsAttributes
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            handleCommandGroup={this.handleCommandGroup}
            sources={_sources}
            stateButton={stateButton}
            config={config}
            handleChange={this.handleChange}
            commandsToShow={commandsToShow}
            addCommand={this.addCommand}
            availableCommands={availableCommands}
            errors={this.state.localErrors}
            removeCommand={this.removeCommand}
            getAllAttributes={() => getCommandAttributes(sources, allAttributes)}
            commandsGroups={commandsGroups}
            handleEditCommandsGroup={this.handleEditCommandsGroup}
            handleDeleteCommandsGroup={this.handleDeleteCommandsGroup}
            handleDeleteIndividualCommand={this.handleDeleteIndividualCommand}
            stateIndex={index}
            isProfile={isProfile}
          />
        ))}
        {(!isEmpty(errors))
          && (
          <div className="validation m-auto">
            <p className="validationError">
              <FormattedMessage
                id="widgetOnOff.wizard.step4.validation.error"
              />
            </p>
          </div>
          )}
      </div>
    );
  }
}

export default withWizardStep(injectIntl(ConfigureWidgetCommands));
