import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { getOrganization, getDataSet } from '../../../../services/api/opendata';


export class ConfigureProtocol extends Component {
  constructor(props) {
    super(props);

    this.getInfoOpenData();
    this.state = {
      protocols: this.getInfoProtocol(),
      opendata: null,
      fiware: this.getInfoFiware(),
    };
  }

  getInfoProtocol = () => {
    const { protocol, transport } = this.props.data;
    if(protocol == 'LORA') {
      const { protocol, internal_attributes } = this.props.data;
      return {protocol, data_model:internal_attributes.lorawan.data_model };
    } else {
    return { protocol, transport };
    }
  };

  getInfoOpenData = () => {
    const { organization, dataset } = this.props.data;

    // NOTE: This validation could be removed when
    //       ORGANIZATION and DATASET becomes mandatory and populated on DB.
    if ( !organization || !dataset ) {
      return;
    }

    getOrganization(organization).then( (result) => {
      const orgTitle = result.data.object.title;
      getDataSet(organization, dataset).then(result => {
        const datasetTitle = result.data.object.title;
        this.setState({opendata: {
          organization: orgTitle,
          dataset: datasetTitle 
        }})
      })
    })
  }

  getInfoFiware = () => {
    const { service, servicepath } = this.props.data.fiware;
    return { service, servicepath };
  }

  render() {
    const { entity } = this.props;
    const { protocols, fiware, opendata } = this.state;
    return (
      <div className="tabSection p-5 backgroundWhite">
        {
          Object.keys(protocols).map((protocol) => (
            <div className="row">
              <dt className="col-12">
                <FormattedMessage id={`${entity.entityName}.model.${protocol}`} />
              </dt>
              <dd className="col-12">
                {protocols[protocol]}
              </dd>
            </div>
          ))
        }

        { opendata &&
          Object.keys(opendata).map((info) => (
            <div className="row">
              <dt className="col-12">
                <FormattedMessage id={`opendata.${info}.title`} />
              </dt>
              <dd className="col-12">
                {opendata[info]}
              </dd>
            </div>
          ))
        }

        <div className="row">
          <dt className="col-12">
            <FormattedMessage id={`${entity.entityName}.model.fiware`} />
          </dt>
          <dd className="col-12">
          {fiware.service}
          </dd>
        </div>
      </div>
    );
  }
}

export default (injectIntl(ConfigureProtocol));
