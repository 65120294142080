import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../../../../components/Menu';
import OptionsForRow from './OptionsForRow';
import './styles.scss';

const WidgetOptionsMenu = ({
  onChangeChoice,
}) => {
  const openButton = (props) => (
    <div
      onClick={props.onClick}
      role="presentation"
    >
      <span className="titleText" style={{ fontSize: '1.6em', color: '#9DA7B0' }}>
        <i className="uil uil-ellipsis-v" />
      </span>
    </div>
  );

  return (
    <Menu
      top="40px"
      openComponent={openButton}
      openFrom="left"
      className="more"
    >
      <OptionsForRow
        onChangeChoice={onChangeChoice}
      />
    </Menu>
  );
};

WidgetOptionsMenu.propTypes = {
  onClick: PropTypes.func,
  onChangeChoice: PropTypes.func,
};

WidgetOptionsMenu.defaultProps = {
  onClick: () => {},
  onChangeChoice: () => {},
};

const WidgetMenuButton = (props) => {
  const { ...res } = props;

  return (
    <WidgetOptionsMenu
  // eslint-disable-next-line react/jsx-props-no-spreading
      {...res}
    />

  );
};

export default WidgetMenuButton;
