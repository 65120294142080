import React from 'react';
import Chip from '../../../Chip';
import {SelectedWrapper} from './styles';

/**Main selected items list with chip format */
export const SelectedOptionsList = ({ data, chipClick,mappedBy=null, formatOption, ...props }) => {
  let selectedOptions = data;

  const parseOption = (format, option) => {
    let formattedOption= '';
    format.forEach(f => {
      formattedOption += option[f] + ' ';
    })

    return formattedOption;
  }

  if (selectedOptions) {
    let selectedList = selectedOptions.map((option, i) => (
      <Chip innerClick={() => chipClick(option, i)}  key={i}
            text={formatOption ? parseOption(formatOption, option) : mappedBy!== null ? option[mappedBy] : option}
      />
    ));
    return (
      <SelectedWrapper
        onClick={e => {
          props.onMainClick(e);
        }}
      >
        {selectedList}
      </SelectedWrapper>
    );
  } else {
    return <SelectedWrapper />;
  }
  
};
