/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import { cloneInstance } from '../../../helpers/utils';
import { updateWidget } from '../../../services/redux/widgets/actions';

import { getSelectionFromLocalStorage } from './utils';

export const TYPES = {
  // TYPE FORMAT -> <action>: '<CATEGORY>_<VARIABLE>_<ACTION>',
  setConfiguration: 'CONFIGURATION_SET',
  setGridSection: 'GRID_SET',
  setPermissionToEdit: 'PERMISSION_TO_EDIT_SET',
  setProfileSection: 'PROFILE_SET',
  setRunSocket: 'SOCKET_RUN_SOCKET_SET',
  setSelection: 'SELECTION_SET',
  setWidgetSection: 'WIDGET_SET',
  updateWidgetFromList: 'WIDGET_UPDATE_ITEM',
  addItemToWidgetList: 'WIDGET_LIST_ADD_ITEM',
  deleteItemFromWidgetList: 'WIDGET_LIST_DELETE_ITEM',
  updateItemConfigFromWidgetList: 'WIDGET_LIST_UPDATE_CONFIG_ITEM',
  updateWidgetInfo: 'WIDGET_LIST_UPDATE_INFO_ITEM',
  updateSourceList: 'SOURCE_LIST_UPDATE',
  updateDashboardContainerWidgets: 'UPDATE_DASHBOARD_CONTAINER_WIDGETS',
};

export default function reducer(state, { type, value }) {
  switch (type) {
    case TYPES.addItemToWidgetList: {
      const activeWidget = state.widget.list[state?.widget?.active?.id];
      const { newWidget, updatedLayout } = value;
      if (activeWidget && activeWidget.isWidgetContainer) {
        const widgetContainerCloned = cloneInstance(activeWidget);
        const instancedValue = activeWidget.instanceChildren(newWidget);
        widgetContainerCloned.containedWidgets.push(instancedValue);
        widgetContainerCloned.dashboard.widgets.push(newWidget);
        widgetContainerCloned.dashboard.layoutProperties = updatedLayout;

        return {
          ...state,
          widget: {
            ...state.widget,
            active: widgetContainerCloned,
            updatedList: new Date().getTime(),
            list: {
              ...state.widget.list,
              [activeWidget.id]: widgetContainerCloned,
              [newWidget.id]: newWidget,
            },
          },
        };
      }

      return {
        ...state,
        widget: {
          ...state.widget,
          updatedList: new Date().getTime(),
          list: {
            ...state.widget.list,
            [newWidget.id]: newWidget,
          },
        },
      };
    }
    case TYPES.deleteItemFromWidgetList: {
      const newState = { ...state };
      const { removedWidget, updatedLayout } = value;
      newState.widget.updatedList = new Date().getTime();
      if (removedWidget.container) {
        const containedWidgets = newState.widget.list[removedWidget.container].containedWidgets
          .filter((w) => w.id !== removedWidget.id);
        const parentDashboard = newState.widget.list[removedWidget.container].dashboard.widgets
          .filter((w) => w.id !== removedWidget.id);
        newState.widget.list[removedWidget.container].containedWidgets = containedWidgets;
        newState.widget.list[removedWidget.container].dashboard.widgets = parentDashboard;
        newState.widget.list[removedWidget.container].dashboard.layoutProperties = updatedLayout;
      }
      if (removedWidget.containedWidgets) {
        removedWidget.containedWidgets.forEach((w) => {
          delete newState.widget.list[w.id];
        });
      }
      delete newState.widget.list[removedWidget.id];
      return newState;
    }
    case TYPES.updateWidgetFromList: {
      if (!state.widget.active) return state;
      if (state.widget.list[state.widget.active.id].container) {
        const instanced = cloneInstance(state.widget.active);
        instanced.name = value.name;
        instanced.description = value.description;
        instanced.config = value.config;
        instanced.permissions_policy = value.permissions_policy;
        const parent = cloneInstance(state.widget.list[instanced.container]);
        parent.containedWidgets = parent.containedWidgets
          .map((w) => ((w.id === instanced.id) ? instanced : w));

        return {
          ...state,
          widget: {
            ...state.widget,
            updatedList: new Date().getTime(),
            list: {
              ...state.widget.list,
              [instanced.container]: parent,
              [instanced.id]: value,
            },
          },
        };
      }
      const instanced = cloneInstance(state.widget.list[state.widget.active.id]);
      instanced.name = value.name;
      instanced.description = value.description;
      instanced.config = value.config;
      instanced.sources = value.sources;
      instanced.permissions_policy = value.permissions_policy;
      return {
        ...state,
        widget: {
          ...state.widget,
          active: instanced,
          updatedList: new Date().getTime(),
          list: {
            ...state.widget.list,
            [instanced.id]: instanced,
          },
        },
      };
    }
    case TYPES.updateWidgetInfo: {
      const newWidget = state.widget.active;
      const clone = cloneInstance(newWidget);

      clone.name = value.name;
      clone.description = value.description;
      return {
        ...state,
        widget: {
          ...state.widget,
          list: {
            ...state.widget.list,
            [newWidget.id]: clone,
          },
        },
      };
    }
    case TYPES.updateItemConfigFromWidgetList: {
      const newWidget = state.widget.list[value.id];
      if (!newWidget) return state;
      /**
       * For updating a component, we need to lose the ref of the object.
       * To achieve that we're doing a shallow copy of the old widget, and after that we add
       * the prototypes of the model.
       */

      const clone = cloneInstance(newWidget);

      clone.config = value.config;

      return {
        ...state,
        widget: {
          ...state.widget,
          active: newWidget,
          list: {
            ...state.widget.list,
            [newWidget.id]: clone,
          },
        },
      };
    }
    case TYPES.updateDashboardContainerWidgets: {
      const clonedList = cloneDeep(state.widget.list);

      Object.keys(clonedList).forEach((key) => {
        if (clonedList[key].dashboard) {
          const clonedWidget = cloneInstance(clonedList[key]);
          clonedWidget.dashboard.layoutProperties = value;
          clonedList[key] = clonedWidget;
        }
      });

      return {
        ...state,
        widget: {
          ...state.widget,
          list: clonedList,
        },
      };
    }
    case TYPES.setConfiguration:
      return { ...state, value: { ...state.configuration, ...value } };
    case TYPES.setGridSection:
      return { ...state, grid: { ...state.grid, ...value } };
    case TYPES.setPermissionToEdit:
      return { ...state, permissionToEdit: value };
    case TYPES.setProfileSection:
      return { ...state, profile: { ...state.profile, ...value } };
    case TYPES.setRunSocket:
      return { ...state, runSocket: value };
    case TYPES.setSelection:
      return { ...state, selection: value };
    case TYPES.setWidgetSection:
      return { ...state, widget: { ...state.widget, updatedList: new Date().getTime(), ...value } };
    case TYPES.updateSourceList: {
      const sources = { ...state.sources };
      Object.keys(value).forEach((v) => {
        sources[v] = sources[v] ? [...sources[v], value[v]] : [value[v]];
      });
      return { ...state, sources: { ...sources } };
    }
    default: {
      return state;
    }
  }
}

export const InitialState = {
  configuration: {},
  grid: {
    isDraggable: false,
    isResizable: false,
    currentBreakPoint: 'md',
  },
  permissionToEdit: false,
  profile: {
    dashboardOpened: null,
    dashboardType: null,
    isOpenedWithLinked: false,
    sendingCommandsOpened: null,
    widgetOpened: null,
    widgetType: null,
  },
  runSocket: false,
  widget: {
    list: {},
    active: null,
    updatedList: null,
  },
  sources: {
  },
  selection: getSelectionFromLocalStorage(),
};

export const updateState = (type, value, dispatch) => {
  dispatch({
    type,
    value,
  }); 
};

export const handleResizable = (isResizable, isDraggable, dispatch) => updateState(
  TYPES.setGridSection,
  {
    isResizable: !isResizable,
    isDraggable: !isDraggable,
  },
  dispatch,
);

export const handleOnProfileDashboardEvent = (widget, dispatch) => updateState(
  TYPES.setProfileSection,
  {
    dashboardOpened: widget,
  },
  dispatch,
);

export const handleOnProfileWidgetEvent = (widget, dispatch) => updateState(
  TYPES.setProfileSection,
  {
    widgetOpened: widget,
  },
  dispatch,
);

export const handleOnProfileSendingCommandsEvent = (widget, dispatch) => updateState(
  TYPES.setProfileSection,
  {
    sendingCommandsOpened: widget,
  },
  dispatch,
);

export const handleChangeBreakpoint = (breakpoint, dispatch) => updateState(
  TYPES.setGridSection,
  {
    currentBreakPoint: breakpoint,
  },
  dispatch,
);

export const handleOpenProfileDashboard = (dash, profileType, dispatch) => updateState(
  TYPES.setProfileSection,
  {
    dashboardOpened: true,
    dashboardType: profileType,
  },
  dispatch,
);

export const handleSetActive = (
  widget,
  dispatch,
) => {
  updateState(TYPES.setWidgetSection, { active: widget }, dispatch);
};

export const handleOpenProfileWidget = (
  widget,
  profileType,
  dispatch,
) => {
  updateState(
    TYPES.setProfileSection,
    {
      widgetOpened: true,
      widgetType: profileType,
    },
    dispatch,
  );
  updateState(TYPES.setWidgetSection, { active: widget }, dispatch);
};

export const updateParagraphText = (widget, dispatch) => {
  updateState(TYPES.setWidgetSection, { active: widget }, dispatch);
  updateWidget(widget);
};

export const handleOpenProfileSendingCommands = (widget, profileType, dispatch) => {
  updateState(
    TYPES.setProfileSection,
    {
      sendingCommandsOpened: true,
      widgetType: profileType,
    },
    dispatch,
  );
  updateState(TYPES.setWidgetSection, { active: widget }, dispatch);
};
