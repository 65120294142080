import React from 'react';
import { connect } from 'react-redux';
import MenuTitle from './MenuTitle';
import DragAndDropGrid from '../../DragAndDropGrid';
import { updatePositionElement, getSettingsMenu } from '../../../services/redux/menuElements/actions';
import { haveMenuPermissionToEdit } from '../../../helpers/utils';

const areEqualsArrays = (a1, b1) => {
  let isEqual = true;
  let isEqual2;
  a1.forEach((a) => {
    isEqual2 = false;
    b1.forEach((b) => {
      if (JSON.stringify(a) === JSON.stringify(b)) isEqual2 = true;
    });

    if (!isEqual2) isEqual = false;
  });

  return isEqual;
};

class GroupMenuTitle extends React.Component {
  constructor(props) {
    super(props);
    const { isSubMenu, handleOpenMenu } = this.props;
    this.state = {
      data: [],
      isSubMenu,
      handleOpenMenu,
    };
  }

  componentDidMount() {
    this.prepareMenuData();
    getSettingsMenu();
  }

  componentDidUpdate(prevProps) {
  // nothing to update if userMe is not available
  // TODO: remove setState from CDU
    const { userMe, data } = this.props;
    if (userMe && data !== prevProps.data) {
      this.prepareMenuData();
    }
  }

  prepareMenuData = () => {
    const { data } = this.props;
    this.setState({
      data: this.checkEditablePermissions(
        data.sort((a, b) => a.position - b.position),
      ),
    });
  }

  // TODO: check if already is the user home menuElement
  checkEditablePermissions = (data) => {
    const { userMe } = this.props;
    return (
      data.map((e) => ({
        ...e,
        isEditable: haveMenuPermissionToEdit(
          userMe,
          e.permissions_policy,
          e.owner,
        ),
      }))
    );
  }

  handleOnChange = (layout) => {
    const { data } = this.state;
    const layoutElements = layout.map((o) => ({ id: o.i, position: o.y }));
    const menuElements = data.map((o) => ({ id: o.id, position: o.position }));

    if (!areEqualsArrays(layoutElements, menuElements)) updatePositionElement(layoutElements);
  };

    isActive = (id) => {
      const { menuElementsSelected } = this.props;
      const { isSubMenu } = this.state;
      return !isSubMenu && menuElementsSelected.length > 0
    && menuElementsSelected[menuElementsSelected.length - 1].id === id;
    };

    render() {
      const { data, isSubMenu, handleOpenMenu } = this.state;
      const { handleViewContent, settingsMenu } = this.props;
      return (
        <DragAndDropGrid
          className={isSubMenu ? 'draggable-submenu' : ''}
          onChange={(layout) => this.handleOnChange(layout)}
          isDraggable
          isResizable={false}
          draggableCancel=".MyNonDraggableAreaClassName"
          cols={{
            lg: 1, md: 1, sm: 1, xs: 1, xxs: 1,
          }}
          rows={50}
        >
          {data.map((menuElement) => (
            <div key={menuElement.id}>
              <MenuTitle
                title={menuElement.name}
                isSubMenu={!!isSubMenu}
                isActive={this.isActive(menuElement.id)}
                isEditable={menuElement.isEditable}
                handleOpenMenu={() => handleOpenMenu(menuElement, !!isSubMenu)}
                menuElement={menuElement}
                handleViewContent={() => handleViewContent(menuElement, !!isSubMenu)}
                homeMenu={settingsMenu}
              />
            </div>
          ))}

        </DragAndDropGrid>
      );
    }
}

GroupMenuTitle.defaultProps = {
  isSubMenu: '',
  handleOpenMenu: () => {},
  userMe: {},
  data: [],
  menuElementsSelected: [],
  handleViewContent: () => {},
  settingsMenu: {},
};

const mapStateToProps = (state) => ({
  menuElementsSelected: state.get('menuElements').get('menuElementsSelected'),
  settingsMenu: state.get('menuElements').get('settingsMenu'),
  userMe: state.get('users').get('userMe'),
});
export default connect(mapStateToProps, {})(GroupMenuTitle);
