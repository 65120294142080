import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

/** Model by keys, values expected and constraints ( optional ) */
const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },

  {
    key: 'actions',
    defaultValue: [],
    constraints: {
      presence: true,
    },
  },

  {
    key: 'enable_cron',
    defaultValue: null
  },

  {
    key: 'disable_cron',
    defaultValue: null
  },

  {
    key: 'description',
    defaultValue: '',
    constraints: {
      presence: true,
      length: {
        minimum: 6,
        message: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '6' }}
          />
        ),
      },
    },
  },

  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 30,
        message: (
          <FormattedMessage
            id="Validation.minimum.maximum"
            values={{ minimum: '6', maximum: '30' }}
          />
        ),
      },
    },
  },

  {
    key: 'engine',
    defaultValue: {
      criterion: {},
      entities: [],
    },
    constraints: {
      presence: true,
    },
  },

  {
    key: 'fiware',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },

  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
    constraints: {
      presence: true,
      length: {
        minimum: 6,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '6' }}
          />
        ),
        maximum: 30,
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '30' }}
          />
        ),
      },
    },
  },

  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
    constraints: {
      presence: true,
    },
  },

  // {
  //   key: 'events',
  //   defaultValue: [],
  //   constraints: {
  //     presence: true
  //   }
  // },
  {
    key: 'devices',
    defaultValue: [],
    constraints: {
      presence: {
        allowEmpty: false,
        message: (<FormattedMessage
          id={'Validation.presence'}
        />)
      },
    },
  },
  {
     key: 'owner',
     defaultValue: '',
     constraints: {
       presence: true
     }
  },
  // {
  //   key: 'visibility',
  //   defaultValue: [],
  //   constraints: {
  //     presence: true
  //   }
  // },
  {
    key: 'updatedAt',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },

  {
    key: 'status',
    defaultValue: false,
  },

  {
    key: 'createdAt',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },

];

export default ModelExpected;
