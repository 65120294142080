const getLocalStorageUser = () => new Promise(
    (resolve) => {
        resolve(localStorage.getItem('user_data'))
    }
);

const getMe = async () => {
    const userInLocalStorage = await getLocalStorageUser();
    return userInLocalStorage ? { name: 'Name', surname: 'Surname', email: 'email@email.com', ...JSON.parse(userInLocalStorage) } : { name: 'Name', surname: 'Surname', email: 'email@email.com' };
};

export default getMe;
