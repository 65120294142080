import React from 'react';
import ModalHOC from '../../../elements/Modal/index';
import { ButtonCustom } from '../../../elements/Button/index';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';

const ModalTitle = ({ stringTranslationHeader }) => <FormattedMessage id={stringTranslationHeader.concat(".delete.title")} />;

const CancelMsg = () => (
  <FormattedMessage
    id="options.cancel"
    defaultMessage="Cancel"
    description="Cancel option"
    component={'span'}
  />);

const DeleteMsg = () => (
  <FormattedMessage
    id="options.delete"
    defaultMessage="Delete"
    description="Delete option"
    component={'span'}
  />);

const parseArrayContent = (content) => {
  return content.map((row, i) => i !== content.length - 1 ? row.name.concat(', ') : row.name)
};

const ModalContent = (rowContent, typeOfData) => {
  const type = Array.isArray(rowContent) && rowContent.length > 1 ? 'plural':'singular';

  return <h1>
    <FormattedMessage
      id={typeOfData.concat(".delete.confirmMessage.", type)}
    /> <em>{!Array.isArray(rowContent) ? rowContent.name : parseArrayContent(rowContent)}</em>
  </h1>
};

const deleteAction = ( hideModal, removeFunction )=> {
  removeFunction();
  hideModal();
};

const ModalButtons = (hideModal, removeFunction) => {
  return (
    <div className="buttons">
      <ButtonCustom label={<DeleteMsg/>} id="deleteModalButton" type="primary" handleOnClick={ ()=>deleteAction(hideModal, removeFunction) }/>
      <ButtonCustom label={<CancelMsg />} type="secondary" handleOnClick={ hideModal }/>
    </div>
  )
};

export default (ButtonOpen, typeOfData) => {
  class RemoveInner extends React.Component {
    constructor(props){
      super(props);
    }

    render() {
      const {onDelete, stringTranslationHeader, data} = this.props;

      const ModalDOM = ModalHOC({
        ButtonOpen: ButtonOpen,
        ModalTitle: () => <ModalTitle stringTranslationHeader={ stringTranslationHeader } />,
        ModalContent: () => ModalContent(data, stringTranslationHeader),
        ModalButtons: ({hideModal}) => ModalButtons(hideModal, onDelete)
      });

      return <ModalDOM/>
    }

    static defaultProps = {
      stringTranslationHeader : 'devices.wizard.properties',
      data: {name: ''}
    }

  }

  return RemoveInner;
}