import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { FormGroup } from '@material-ui/core';
import CustomInput from 'reactstrap/es/CustomInput';
import Field from '../../../../elements/Field';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import ColorPicker from '../../../../components/ColorPicker';
import { Components } from '../../../../models/Widget/configurationSheets/utils';
import './style.scss';

const NeedleRange = ({
  conditions,
  colors,
  handleChange,
}) => {
  const { range, type } = conditions;
  const [localDisabled, setLocalDisabled] = useState(type !== 'percent');
  const [localErrors, setLocalErrors] = useState({
    early: {}, middleFrom: {}, middleTo: {}, late: {},
  });
  const [localRange, setLocalRange] = useState(range);
  const [localColors, setLocalColors] = useState(colors);
  const [localConditions, setLocalConditions] = useState(conditions);
  // methods

  useEffect(() => {
    const conditions = clone(localConditions);
    if (range !== localRange
      && Object.entries(localErrors.middleFrom).length === 0
      && Object.entries(localErrors.middleTo).length === 0) {
      conditions.range = localRange;
      setLocalConditions(conditions);
      handleChange({ name: 'conditions', value: conditions }, undefined, Components.needleRange);
    }
  }, [localRange]);

  const getTypeRange = (valueType) => {
    if (valueType === 'early') {
      return 'middleFrom';
    }
    if (valueType === 'late') {
      return 'middleTo';
    }

    return valueType;
  };

  const validateRange = (e) => {
    const rangeClone = clone(localRange);
    let middleFrom; let
      middleTo = null;

    const newType = getTypeRange(e.target.id);

    if (!parseInt(e.target.value, 10)) {
      e.target.value = '0';
    }
    rangeClone[newType] = parseInt(e.target.value, 10);

    if (rangeClone.middleFrom < rangeClone.middleTo) {
      if (rangeClone.middleFrom >= 99 && !localDisabled) {
        middleFrom = {
          error: true,
          errors: [<FormattedMessage id="Validation.middleFrom.percentage" />],
        };
      } else {
        middleFrom = {};
      }
    } else {
      middleFrom = {
        error: true,
        errors: [<FormattedMessage id="Validation.middleFrom" values={{ maximum: rangeClone.middleTo }} />],
      };
    }

    if (rangeClone.middleTo > rangeClone.middleFrom) {
      if (rangeClone.middleTo >= 100 && !localDisabled) {
        middleTo = {
          error: true,
          errors: [<FormattedMessage id="Validation.middleTo.percentage" />],
        };
      } else {
        middleTo = {};
      }
    } else {
      middleTo = {
        error: true,
        errors: [<FormattedMessage id="Validation.middleTo" values={{ minimum: rangeClone.middleFrom }} />],
      };
    }
    setLocalErrors((old) => ({
      ...old.localErrors,
      middleFrom,
      middleTo,
    }));
    setLocalRange(rangeClone);
  };

  // ready
  const handleChangeType = (e) => {
    const conditions = clone(localConditions);
    const type = e.target.id;
    let middleFrom = {};
    let middleTo = {};
    if (localDisabled) {
      if (localRange.middleFrom >= 99) {
        middleFrom = {
          error: true,
          errors: [<FormattedMessage id="Validation.middleFrom.percentage" />],
        };
      }

      if (localRange.middleTo >= 100) {
        middleTo = {
          error: true,
          errors: [<FormattedMessage id="Validation.middleTo.percentage" />],
        };
      }
    }
    conditions.type = type;
    setLocalConditions(conditions);
    setLocalDisabled(!localDisabled);
    setLocalErrors((old) => ({
      localErrors: {
        ...old.localErrors,
        middleFrom,
        middleTo,
      },
    }));
    handleChange({ name: 'conditions', value: conditions }, undefined, Components.needleRange);
  };

  const handleChangeColors = (color, name) => {
    const newColors = clone(localColors);
    newColors[name] = color.hex;
    setLocalColors(newColors);
    handleChange({ name, value: newColors[name] }, 'colors', Components.needleRange);
  };

  // return
  return (
    <div className="styleAndConfiguration">
      <FormGroup className="radioContainer">
        <CustomInput
          onChange={handleChangeType}
          value="percent"
          name="percent"
          type="radio"
          label="Valores por porcentaje"
          id="percent"
          checked={!localDisabled}
        />

        <CustomInput
          onChange={handleChangeType}
          value="absolute"
          name="absolute"
          type="radio"
          label="Valores absolutos"
          id="absolute"
          checked={localDisabled}
        />
      </FormGroup>
      <div>
        <div className="needleColorContainer">
          <div className="earlyContainer">
            <Field
              type="number"
              value={localRange.middleFrom}
              id="early"
              name="early"
              label={<FormattedMessage id="widget.config.needle-early-color" />}
              innerLabel="#F9FAFC"
              className="field"
              onBlur={validateRange}
            />
          </div>
          <div className="colorPicker">
            <ColorPicker
              style={{ 'margin-top': '20px' }}
              name="early"
              onChangeComplete={handleChangeColors}
              color={localColors ? localColors.early : ''}
              label="Color"
            />
          </div>
        </div>
        <div className="needleColorContainer">
          <div className="middleFromContainer">
            <Field
              type="number"
              value={localRange.middleFrom}
              id="middleFrom"
              name="middleFrom"
              label={<FormattedMessage id="widget.config.needle-middleFrom-color" />}
              innerLabel="#F9FAFC"
              className="field"
              helperText={localErrors.middleFrom?.errors}
              error={localErrors.middleFrom?.error}
              onBlur={validateRange}
            />
          </div>
          <div className="bar">/</div>
          <div className="middleToContainer">

            <Field
              type="number"
              value={localRange.middleTo}
              id="middleTo"
              name="middleTo"
              label={<FormattedMessage id="widget.config.needle-middleTo-color" />}
              innerLabel="#F9FAFC"
              className="field"
              helperText={localErrors.middleTo?.errors}
              error={localErrors.middleTo?.error}
              onBlur={validateRange}
            />
          </div>
          <div className="colorPicker">
            <ColorPicker
              style={{ 'margin-top': '20px' }}
              name="middle"
              onChangeComplete={handleChangeColors}
              color={localColors ? localColors.middle : ''}
              label="Color"
            />
          </div>
        </div>
        <div className="needleColorContainer">
          <div className="lateContainer">
            <Field
              type="number"
              value={localRange.middleTo}
              id="late"
              name="late"
              label={<FormattedMessage id="widget.config.needle-late-color" />}
              innerLabel="#F9FAFC"
              className="field"
              onBlur={validateRange}
            />
          </div>
          <div className="colorPicker">
            <ColorPicker
              style={{ 'margin-top': '20px' }}
              name="late"
              onChangeComplete={handleChangeColors}
              color={localColors ? localColors.late : ''}
              label="Color"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(NeedleRange);
