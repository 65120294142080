import React from 'react';
import FormattedMessage from '../../Contexts/LanguageContext/Components/FormattedMessage';

const ModelExpected = [
  {
    key: 'id',
    defaultValue: null,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'name',
    defaultValue: '',
    constraints: {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="Validation.presence" />,
      },
      length: {
        minimum: 6,
        maximum: 30,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '6' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '30' }}
          />
        ),
      },
    },
  },
  {
    key: 'description',
    constraints: {
      length: {
        minimum: 6,
        maximum: 280,
        tooShort: (
          <FormattedMessage
            id="Validation.minimum"
            values={{ minimum: '6' }}
          />
        ),
        tooLong: (
          <FormattedMessage
            id="Validation.maximum"
            values={{ maximum: '280' }}
          />
        ),
      },
    },
  },
  { key: 'execution', defaultValue: '' },
  { key: 'owner', defaultValue: '' },
  { key: 'onCascadeUpdate', defaultValue: false },
  { key: 'actions', defaultValue: [] },
  { key: 'cron', defaultValue: '' },
  {
    key: 'pages',
    defaultValue: [
      { size: null, orientation: null },
    ],
    /*
    constraints: {
      presence: true,
    },
    */
  },
  {
    key: 'permissions_policy',
    defaultValue: {
      users: [],
      groups: [],
      public: false,
    },
  },
  {
    key: 'createdAt',
    defaultValue: null,
  },
  {
    key: 'updatedAt',
    defaultValue: null,
  },
];

export default ModelExpected;
