import React from 'react';
import { withTheme } from 'styled-components';
import { Context } from './provider';

/** Apply Consumer to past component */
const Consumer = (Component) => {
  /** Apply Provider from styled-component */
  const ComponentWithTheme = withTheme(Component);

  /** Then, apply out provider with functions and themes */
  class withContext extends React.PureComponent {
    render() {
      const themeFromProvider = this.context;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ComponentWithTheme {...this.props} themeFn={themeFromProvider} />
      );
    }
  }

  withContext.contextType = Context;

  return withContext;
};

export default Consumer;
