import * as TYPES from './types';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export const addDashboard = (Dashboard) => store.dispatch({
  type: TYPES.ADD_DASHBOARD_REQUEST,
  payload: Dashboard,
});

export const addDashboardSuccessFlag = () => store.dispatch({
  type: TYPES.ADD_DASHBOARD_SUCCESS_FLAG,
});

export const updateDashboard = (Dashboard) => store.dispatch({
  type: TYPES.MODIFY_DASHBOARD_REQUEST,
  payload: Dashboard,
});

export const updateDashboardLayout = (Dashboard) => store.dispatch({
  type: TYPES.MODIFY_DASHBOARD_LAYOUT_REQUEST,
  payload: Dashboard,
});

export const updateDashboardWithoutSave = (Dashboard) => {
  store.dispatch({
    type: TYPES.MODIFY_DASHBOARD_LAYOUT_WITHOUT_SAVE_SUCCESS,
    payload: Dashboard,
  });
};

export const modifyDashboardSuccessFlag = () => store.dispatch({
  type: TYPES.MODIFY_DASHBOARD_SUCCESS_FLAG,
});

export const deleteDashboard = (Dashboard) => store.dispatch({
  type: TYPES.DELETE_DASHBOARD_REQUEST,
  payload: Dashboard,
});

export const getDashboards = (payload = {}) => store.dispatch({
  type: TYPES.GET_DASHBOARDS_REQUEST,
  payload,
});

export const getPublicDashboards = (payload = {}) => store.dispatch({
  type: TYPES.GET_PUBLIC_DASHBOARDS_REQUEST,
  payload,
});

export const getMineDashboards = (payload = {}) => store.dispatch({
  type: TYPES.GET_MINE_DASHBOARDS_REQUEST,
  payload,
});

export const getDashboard = (Dashboard) => store.dispatch({
  type: TYPES.GET_DASHBOARD_REQUEST,
  payload: Dashboard,
});

export const cloneRefDashboard = (Dashboard) => {
  store.dispatch({
    type: TYPES.CLONE_REF_DASHBOARD_REQUEST,
    payload: Dashboard,
  });
};

export const cloneNoRefDashboard = (Dashboard) => {
  store.dispatch({
    type: TYPES.CLONE_NOREF_DASHBOARD_REQUEST,
    payload: Dashboard,
  });
};

export const errorFetchingDashboardReset = () => store.dispatch({
  type: TYPES.ERRORFETCHING_DASHBOARD_RESET,
});

export const clearDashboardState = () => store.dispatch({
  type: TYPES.CLEAR_DASHBOARDS_STATE,
});

export const clearReadDashboard = () => store.dispatch({
  type: TYPES.CLEAR_DASHBOARD_STATE,
});
