/* eslint-disable import/no-cycle */
import { clone } from 'lodash';
import Widget from '../..';
import {
  PercentageConfigurationSheetBasic,
  PercentageConfigurationSheet,
  validationPercentage,
} from './utils/configurationSheet';
import PercentageDefaultConfiguration from './utils/defaultConfiguration';
import PercentageComponent from './component';
import { ReactComponent as PercentageWidgetIcon } from '../../../../configuration/icons/svg/ico-widget-percent.svg';

export const NAME_WIDGET = 'PERCENTAGE';
export default class PercentageWidget extends Widget {
  static widgetType = NAME_WIDGET;

  static widgetIcon = PercentageWidgetIcon;

  skeleton = PercentageWidgetIcon;

  defaultConfig = ({ ...PercentageDefaultConfiguration });

  templateConfig = ['unit'];

  component = PercentageComponent;

  // *! --------------------- [COMMON BODY] --------------------- !* //
  getValidatorConfig = () => (config) => validationPercentage(this.templateConfig, config);

  getConfigurationSheet = (basic = true) => (
    basic
      ? PercentageConfigurationSheetBasic
      : PercentageConfigurationSheet
  )

  getDefaultConfig() {
    return this.defaultConfig;
  }

  getWidgetChildBodyQuery = (data, { allSourcesParsed }) => {
    const dataForQueries = clone(data);
    dataForQueries[0].sources = allSourcesParsed;
    return dataForQueries;
  }

  getQueryHistorical() {
    const dataForQueries = this.getEmptyHistoricalBody();
    const sources = this.getDataForQueries({ sources: this.sources });
    dataForQueries[0].sources = sources;
    return dataForQueries;
  }
  // *! --------------------- [COMMON BODY] --------------------- !* //
}
