/* eslint-disable react/no-array-index-key */
import React from 'react';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import ReactApexChart from 'react-apexcharts';
import { Row } from 'reactstrap';
import validate from 'validate.js';
import { ButtonCustom } from '../../../../../elements/Button';
import FilterComponent from './FilterComponent';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';


const constraintsFilter = {
  name: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
  condition: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
  valueCondition: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
};


export const Buttons = ({ onClikAdd, hideModal }) => (
  <div className="buttons">
    <ButtonCustom type="primary" handleOnClick={onClikAdd} label="Guardar" />
    <ButtonCustom handleOnClick={hideModal} className="btn btn-secondary" label="Cancelar" />
  </div>
);
class EditFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      properties: props.entity && props.entity.transformation.properties ? props.entity.transformation.properties : [{
        name: '', type: '', condition: '', valueCondition: '',
      }],

    };
  }


  onUpdate() {
    const entityToUpdate = this.props.entity;
    entityToUpdate.transformation.properties = this.state.properties;
    if (this.validate()) {
      this.props.onUpdate(entityToUpdate);
      this.props.hideModal();
    }
  }


    saveData = (data) => {
      const properties = [...this.state.properties];


      properties[data.index] = {
        ...properties[data.index],
        ...data,
      };

      delete properties[data.index].index;


      this.setState({ properties });
    };

     /* Adding */
     addNewFilter = () => {
       const { properties: _properties } = this.state;
       const newProperty = {
         name: '', type: '', condition: '', valueCondition: '',
       };
       const properties = [..._properties];
       properties.push(newProperty);
       this.setState({
         properties,
       });
     };

     /* Deleting */
    deleteFilter = (Index) => {
      const { properties } = this.state;
      const propertiesCopy = clone(properties);

      propertiesCopy.splice(Index, 1);

      this.setState({
        properties: propertiesCopy,
      });
    };

    validate = () => {
      const errors = [];
      let allValid = true;
      const { properties } = this.state;
      properties.forEach((p, i) => {
        const validationProperty = validate(p, constraintsFilter);
        if (validationProperty) {
          errors[i] = validationProperty;
          allValid = false;
        }
      });
      this.setState({ errors });
      return allValid;
    }


    render() {
      const { properties, errors } = this.state;
      const {
        entity, intl, availableColumns, hideModal,
      } = this.props;
      return (
        <div>
          <Row style={{ padding: '25px' }}>
            <ButtonCustom handleOnClick={() => this.addNewFilter()} className="btn btn-secondary" label="Añadir más filtros" />
          </Row>

          {properties.map((p, i) => (
            <FilterComponent errors={errors[i]} filter={p} entity={entity} intl={intl} availableColumns={availableColumns} index={i} onChange={this.saveData} onDelete={this.deleteFilter} />
          ))}


          <Buttons onClikAdd={() => this.onUpdate()} hideModal={hideModal} />
        </div>


      );
    }
}

EditFilter.defaultProps = {
  saveOnTheFly: true,
  onChange: () => {},
  entity: {},
  onUpdate: () => {},
};


export default injectIntl(EditFilter);
