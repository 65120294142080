import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CommonView from '../../../CommonView';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import TabComponent from '../../../../components/Tab';
import Monthly, {
  Daily,
  Manually,
  NumberSelect,
  Weekly,
} from '../../../../components/Scheduler/planner';

import '../styles.scss';
import { convertFromMinutes, convertFromHours } from '../../../../helpers/cron';

const TAB_MINUTES = 1;
const TAB_HOURS = 2;

class Scheduler extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      ...this.props.newEntity,
      planner: this.props.newEntity.planner || {},
      errors: this.props.errors,
      onValidation: false,
      onCascadeUpdate: false,
    };
  }

  validate = () => {
    this.savePartialKpi();
  };

  savePartialKpi = () => {
    this.setErrors({});

    const haveErrors = undefined;

    if (haveErrors) {
      delete haveErrors.error;
      this.setErrors(haveErrors);
      this.props.onError(haveErrors.error);
    } else {
      const { errors, onValidation, ...rest } = this.state;
      this.props.onContinue({ ...rest }, this.props.extraData);
    }
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  handleTabChange = (selectedTab) => {
    switch (selectedTab) {
      case TAB_MINUTES:
        this.setState({ cron: convertFromMinutes(30) });
        break;
      case TAB_HOURS:
        this.setState({ cron: convertFromHours(1) });
        break;

      default:
        break;
    }
  };

  setPlanner = (cron) => {
    this.setState({ cron });
  };

  render() {
    return (
      <>
        <Container>
          <div className="wizardWrapper">
            <Row className="mt-5">
              <Col lg={{ size: 10, offset: 1 }}>
                <h3 className="planner-title">
                  <FormattedMessage id="reports.config.planner.title" />
                </h3>
                <TabComponent
                  titles={[
                    <FormattedMessage id="reports.config.planner.manually" />,
                    <FormattedMessage id="reports.config.planner.minutes" />,
                    <FormattedMessage id="reports.config.planner.hours" />,
                    <FormattedMessage id="reports.config.planner.daily" />,
                    <FormattedMessage id="reports.config.planner.weekly" />,
                    <FormattedMessage id="reports.config.planner.monthly" />,
                  ]}
                  onChange={this.handleTabChange}
                  className="m-0"
                >
                  <Manually label="kpis.wizard.scheduler.manually" />
                  <NumberSelect property="minutes" setValue={this.setPlanner} />
                  <NumberSelect property="hours" setValue={this.setPlanner} />
                  <Daily setValue={this.setPlanner} />
                  <Weekly setValue={this.setPlanner} />
                  <Monthly setValue={this.setPlanner} />
                </TabComponent>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addDashboardSuccessFlag: state.get('dashboards').get('addSuccess'),
  errorFetching: state.get('dashboards').get('errorFetching'),
  notify: state.get('notify'),
});

Scheduler.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

Scheduler.defaultProps = {
  name: '',
  description: '',
  permissionsPolicy: [],
  errors: {},
};

export default connect(mapStateToProps, {})(withWizardStep(Scheduler));
