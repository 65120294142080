import validate from 'validate.js';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';
// eslint-disable-next-line import/no-cycle
import {
  addService, deleteService, updateService, getService,
} from '../../services/redux/services/actions';

/** Class Service with Utils functions */
export default class Service extends Utils {
  static entityName = 'Service';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = Service;
    if (this.categories.length === 0) {
      this.categories.push('OTHER');
    }
  }

  validate(data) {
    const filteredConstraints = {};
    data.forEach((d) => {
      if (d === 'fiware') {
        filteredConstraints['fiware.service'] = this.constraints[d];
      } else {
        filteredConstraints[d] = this.constraints[d];
      }
    });

    const validation = validate(this.getData(), filteredConstraints);
    return validation === undefined
      ? validation
      : { error: true, ...validation };
  }

  /** Call Redux action for Save Service in DB and Redux */
  save() {
    // const filteredConstraints = clone(this.constraints);
    // delete filteredConstraints.id;
    // const validation = validate(this.getData(),filteredConstraints);
    // return validation  === undefined ? addService({...this.getData()}): {
    //  error: true, ...validation,
    // }

    addService({ ...this.getData() });
  }

  /** Call Redux action for Update Service in DB and Redux */
  update() {
    const validation = this.validate(Object.keys(this.getData()));
    return validation === undefined
      ? updateService({
        ...this.getData(),
      })
      : { error: true, ...validation };
  }

  delete() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined ? deleteService(this.getData()) : {
      error: true, ...validation,
    };
  }

  get() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? getService(this.getData())
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels('Service', ModelExpected);

  translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleService = new Service();
export { SampleService };
