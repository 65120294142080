import validate from 'validate.js';
import { clone } from 'ramda';
// eslint-disable-next-line import/no-cycle
import {
  addPermission, updatePermission, deletePermission, getPermission,
} from '../../services/redux/permissions/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import ModelExpected from './attributes';

/** Class Permission with Utils functions */
export default class Permission extends Utils {
    static entityName = 'Permission';

    constructor(obj = {}) {
      /** Send to utils class the Model and the object passed for build this class */
      super(ModelExpected, obj);
      this.Model = Permission;
    }

    /** Call Redux action for Save Permission in DB and Redux */
    save() {
      const filteredConstraints = clone(this.constraints);
      delete filteredConstraints.id;
      const validation = validate(this.getData(), filteredConstraints);
      return validation === undefined ? addPermission({ ...this.getData() }) : {
        error: true, ...validation,
      };
    }

    /** Call Redux action for Update Permission in DB and Redux */
    update() {
      const validation = validate(this.getData(), this.constraints);
      return validation === undefined ? updatePermission({ ...this.getData() }) : {
        error: true, ...validation,
      };
    }

    delete() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? deletePermission(this.getData()) : {
        error: true, ...validation,
      };
    }

    get() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getPermission(this.getData()) : {
        error: true, ...validation,
      };
    }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('Permission', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SamplePermission = new Permission();
export { SamplePermission };
