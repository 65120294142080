import React from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import { updateWidget } from '../../../../services/redux/widgets/actions';
import CommonView from '../../../CommonView';
import widgetHTMLValidator from '../validators/WidgetHTMLValidator';
import ExpansionContent from '../newcomponents/contentProfile';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import ExpansionLabel from '../../../../components/ExpansionLabel';
import SubMenu from '../newcomponents/subMenu';
import operationSelector from '../../../../models/Widget/configurationSheets/Linked/Commons';
import { getWidgetModel } from '../../../../models/WidgetV2/utils';
import './style.scss';

class EditConfigurationWidgetType extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      id: this.props.data.id,
      name: this.props.data.name,
      description: this.props.data.description,
      widgetType: this.props.data.widgetType,
      config: this.props.data.config,
      permissions_policy: this.props.data.permissions_policy,
      origins: this.props.data.origins,
      errors: {},
      container: this.props.data.container,
      submenuEnabled: false,
      submenuComponent: undefined,
      submenuCategorySelected: undefined,
      sources: this.props.data.sources,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        id: this.props.data.id,
        name: this.props.data.name,
        container: this.props.data.container,
        description: this.props.data.description,
        widgetType: this.props.data.widgetType,
        config: this.props.data.config,
        permissions_policy: this.props.data.permissions_policy,
        origins: this.props.data.origins,
        sources: this.props.data.sources,
      });
    }
  }

  handleSave = () => {
    const { v2, data } = this.props;
    this.setState({ errors: {} });
    const newWidget = getWidgetModel(R.clone(this.state));
    const validator = newWidget.getValidatorConfig();
    const validation = validator(this.state.config, this.state.container);
    if (validation) {
      this.setState({ errors: validation });
      return;
    }

    if (data.widgetType === 'HTML') {
      const validatedWidget = widgetHTMLValidator(this.state.config);

      if (validatedWidget) {
        this.setState({ errors: validatedWidget });
      } else {
        newWidget.origins = newWidget.origins.map((opt) => {
          const o = opt;
          delete o.connectedDevices.historicaldata; return o;
        });
        return v2
          ? newWidget.update(newWidget, data.config)
          : updateWidget(newWidget);
      }
    } else {
      newWidget.origins = newWidget.origins.map((opt) => {
        const o = opt;
        delete o.connectedDevices.historicaldata; return o;
      });
      return v2
        ? newWidget.update(newWidget, data.config)
        : updateWidget(newWidget);
    }
  };

  handleChange = (config) => this.setState({ config });

  handleChangeAndSave = (data) => {
    const config = { ...this.state.config, data };
    this.setState({ config }, this.handleSave);
  };

  handleChangeSelect = (name, value, category = undefined, subCategory = undefined) => {
    let oldValue = category && !subCategory
      ? this.state.config[category][name]
      : this.state.config[name];

    if (subCategory) oldValue = this.state.config[category][subCategory][name];

    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      this.setState({ config }, this.handleSave);
    }
  };

  handleInputChange = (event, category = undefined, subCategory = undefined) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    let oldValue = category && !subCategory
      ? this.state.config[category][name]
      : this.state.config[name];

    if (subCategory) oldValue = this.state.config[category][subCategory][name];

    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      this.setState({ config });
    }
  };

  handleSwitchChange = (name, value, category = undefined) => {
    const config = { ...this.state.config };
    if (category) {
      config[category][name] = value;
    } else {
      config[name] = value;
    }
    this.setState({ config }, this.handleSave);
  }

  handleCheckBoxChange = (name, value, category = undefined, subCategory = undefined) => {
    const config = { ...this.state.config };
    // eslint-disable-next-line no-unused-expressions
    (category && !subCategory) ? config[category][name] = value : config[name] = value;
    if (subCategory) config[category][subCategory][name] = value;
    this.setState({ config }, this.handleSave);
  };

  handleDateChange = (name, value, category = undefined, subCategory = undefined) => {
    const config = { ...this.state.config };
    // eslint-disable-next-line no-unused-expressions
    (category && !subCategory) ? config[category][name] = value : config[name] = value;
    if (subCategory) config[category][subCategory][name] = value;
    this.setState({ config }, this.handleSave);
  };

  handleGenericChange = (event, category, type, subCategory = undefined) => {
    switch (type) {
      case 'number':
      case 'text':
      case 'textArea':
        this.handleInputChange(event, category, subCategory);
        break;
      case 'checkbox':
        this.handleCheckBoxChange(event.name, event.value, category, subCategory);
        break;
      case 'tableConditions':
      case 'commandsOnOff':
      case 'commandsSlider':
      case 'colorPicker':
      case 'colorsData':
      case 'colorsDataMap':
      case 'listComponent':
      case 'barData':
      case 'selectColor':
      case 'selectImage':
      case 'tableData':
      case 'select':
      case 'lineData':
      case 'needleRange':
      case 'advancedLegendColors':
      case 'advancedLegendNames':
        if (event.name === 'sampling' && !event.value) {
          // eslint-disable-next-line no-param-reassign
          event.value = 'lastMonth';
        }
        this.handleChangeSelect(event.name, event.value, category, subCategory);
        break;
      case 'switch':
      case 'kpiHistoricalSwitch':
        this.handleSwitchChange(event.name, event.value, category, subCategory);
        break;
      case 'mapStyle':
        this.handleChangeSelect(event.name, event.value, category, subCategory);
        break;
      default:
        return null;
    }
    return true;
  }

  toggleSubmenu = (submenu, component, categorySelected) => {
    this.setState({
      submenuEnabled: submenu,
      submenuComponent: component,
      submenuCategorySelected: categorySelected,
    });
  }

  renderProfile = () => {
    const { getParentWidgetType, isLinked, sources } = this.props;
    const widget = getWidgetModel(this.state);
    const configurationSheet = widget.getConfigurationSheet(false);
    const sections = Object.keys(configurationSheet);

    const filteredSections = sections.filter((section) => {
      if (isLinked && widget.widgetType === 'TABLE') {
        return section !== 'dataConfig';
      }
      return section;
    });
    const {
      submenuEnabled,
      submenuComponent,
      submenuCategorySelected,
      errors,
    } = this.state;

    if (!submenuEnabled) {
      return (
        <>
          {filteredSections.map((panelSection, index) => (
            <ExpansionLabel
              opened={index === 0}
              header={() => (
                <FormattedMessage
                  component={false}
                  id={`widget.config.profile.header.${panelSection}`}
                />
              )}
              content={() => ExpansionContent(
                configurationSheet[panelSection],
                this.handleGenericChange,
                this.handleSave,
                widget.config,
                widget,
                this.toggleSubmenu,
                submenuCategorySelected,
                errors,
                isLinked,
                sources,
              )}
            />
          ))}
          {getParentWidgetType(widget) === 'LINKED' && widget.widgetType !== 'PERCENTAGE' && (
            <>
              <ExpansionLabel
                header={() => (
                  <FormattedMessage
                    component={false}
                    id="widget.config.profile.header.operationType"
                  />
                )}
                content={() => ExpansionContent(
                  operationSelector.operation,
                  this.handleGenericChange,
                  this.handleSave,
                  widget.config,
                  widget,
                  this.toggleSubmenu,
                  submenuCategorySelected,
                  errors,
                )}
              >
                <span className="operationTypeInfoText">
                  <FormattedMessage
                    id="widget.config.profile.header.operationType.info"
                  />
                </span>
              </ExpansionLabel>
            </>
          )}
        </>
      );
    }

    return (
      <SubMenu
        onMain={this.toggleSubmenu}
        type={submenuComponent}
        categorySelected={submenuCategorySelected}
      >
        {ExpansionContent(
          [submenuComponent],
          this.handleGenericChange,
          this.handleSave,
          widget.config,
          widget,
          this.toggleSubmenu,
          submenuCategorySelected,
          errors,
        )}
      </SubMenu>
    );
  }

  render() {
    return (
      <section style={{ padding: '1em 1em' }}>
        {this.renderProfile()}
      </section>
    );
  }
}

export default injectIntl(EditConfigurationWidgetType);
