import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import CommonView from '../../../../pages/CommonView';
import Field from '../../../../elements/Field';
import { getPublicDashboards } from '../../../../services/redux/dashboards/actions';
import parseResource from '../../helpers/index';

class ConfigureLink extends CommonView {
  constructor(props) {
    super({ props });

    const { resource } = this.props.newEntity;
    this.state = {
      link: resource.link.schema ? parseResource(resource) : '',
      errors: this.props.errors,
      onValidation: false,
      resource: resource.link.schema ? parseResource(resource) : '',
    };
  }

  componentDidMount() {
    if (this.props.newEntity.resource) {
      this.props.onChange({
        resource: this.state.resource,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, size } = this.state;
    if (!this.props.fetching && prevState.page < page) {
      getPublicDashboards({ page, size });
    }
    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach((err) => {
          errors[err.field] = <FormattedMessage id={err.message} />;
        });
        this.setErrors(errors);
        // errorFetchingMenuElementReset();
      }
    }
  }

  handleInputChange = (e) => {
    this.props.onChange({
      resource: e.target.value,
    });
  };

  render() {
    const { type, intl } = this.props;
    const { link } = this.state;

    return (
      <>
        <Container>
          <div className="wizardWrapper configureLink">
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <Field
                  type="text"
                  name={`${type.toLowerCase()}Link`}
                  onChange={this.handleInputChange}
                  value={link}
                  placeholder={intl.formatMessage({
                    id: `menuElement.configure.${type.toLowerCase()}.placeholder`,
                  })}
                  error={!!this.props.errors}
                  helperText={this.props.errors}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

export default injectIntl(ConfigureLink);
