export const GET_ALERTS_REQUEST = 'GET_ALERTS_REQUEST';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';

export const GET_ALERT_REQUEST = 'GET_ALERT_REQUEST';
export const GET_ALERT_SUCCESS = 'GET_ALERT_SUCCESS';
export const GET_ALERT_ERROR = 'GET_ALERT_ERROR';

export const CLEAR_ALERT_SUCCESS = 'CLEAR_ALERT_SUCCESS';
export const ERRORFETCHING_ALERT_RESET = 'ERRORFETCHING_ALERT_RESET';
