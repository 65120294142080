import React from 'react';
import {
  ModalHeader, ModalBody, ModalFooter, Modal,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import Alert from '../../../../../components/Alert';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import PolygonMap from '../../../../../components/WidgetMap/PolygonMap';
import { alertWrapper } from '../../RulesWizard.module.scss';
import { ButtonCustom } from '../../../../../elements/Button/index';

const PolygonMapModal = ({
  showMap,
  togglePolygonalMapModal,
  assignAreaToValue,
  features,
  mode,
}) => {
  let polygonMap;
  return (
    <Modal
      isOpen={showMap}
      toggle={togglePolygonalMapModal}
      style={{ width: '95%', maxWidth: '100%' }}
      zIndex="3000"
    >
      <ModalHeader>
        <FormattedMessage
          id="rules.wizard.assign.area.title"
          defaultMessage="Define an action area for this rule"
          description="Define an action area for this rule"
        />
      </ModalHeader>

      <ModalBody style={{ height: '80vh' }}>
        <PolygonMap
          ref={(_) => { polygonMap = _; }}
          features={features}
          onePolygon
          mode={mode}
        />
      </ModalBody>

      <ModalFooter>
        <div className={alertWrapper}>
          <Alert
            text={(
              <FormattedMessage
                id="rules.wizard.assign.area.alert"
                defaultMessage="Please enter an area to continue"
                description="Please enter an area to continue"
              />
              )}
            mode="warning"
          />
        </div>
        <ButtonCustom
          type="secondary"
          className="btn prevButton"
          handleOnClick={togglePolygonalMapModal}
          label={(
            <FormattedMessage
              id="options.cancel"
              defaultMessage="Cancel"
              description="Cancel"
            />
          )}
        />
        <ButtonCustom
          type="primary"
          className="btn nextButton"
          // eslint-disable-next-line no-underscore-dangle
          handleOnClick={() => assignAreaToValue(polygonMap._getFeatures())}
          label={(
            <FormattedMessage
              id="rules.wizard.assign.area"
              defaultMessage="Assign area"
              description="Assign area"
            />
          )}
        />
      </ModalFooter>
    </Modal>
  );
};

PolygonMapModal.defaultProps = {
  showMap: false,
  togglePolygonalMapModal: () => {},
  assignAreaToValue: () => {},
  features: [],
};

PolygonMapModal.propTypes = {
  showMap: PropTypes.bool,
  togglePolygonalMapModal: PropTypes.func,
  assignAreaToValue: PropTypes.func,
  features: PropTypes.arrayOf(PropTypes.object),
};

export default PolygonMapModal;
