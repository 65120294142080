import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Col } from 'reactstrap';
import { imageList } from '../../../../configuration/variables';
import { Components } from '../../../../models/Widget/configurationSheets/utils';

const SelectImage = ({
  imageSelected,
  handleChange,
}) => {
  const [localImageSelected, setLocalImageSelected] = useState(imageSelected);

  const handleContentEditableChange = (name, value, category) => {
    setLocalImageSelected(value);
    handleChange({ name, value }, category, Components.selectImage);
  };

  return (
    <Col className="sliderImage">
      <div className="sliderContainer">
        <div className="imagePickerBody">
          {imageList.map((backgroundImage) => {
            if (localImageSelected) {
              return (
                <button type="button">
                  <img
                    id={backgroundImage}
                    className={localImageSelected && localImageSelected === backgroundImage
                      ? 'imageSelected' : 'imageNoSelected'}
                    onClick={() => handleContentEditableChange('imageSelected', backgroundImage, 'appearance')}
                    src={backgroundImage}
                    alt=""
                  />
                </button>
              );
            }
            return (
              <button type="button">
                <img
                  id={backgroundImage}
                  className="defaultImage"
                  onClick={() => handleContentEditableChange('imageSelected', backgroundImage, 'appearance')}
                  src={backgroundImage}
                  alt=""
                />
              </button>
            );
          })}
        </div>
      </div>
    </Col>
  );
};

export default injectIntl(SelectImage);
