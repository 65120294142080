const ImageDefaultConfiguration = {
  appearance: {
    imageSelected: '/static/media/people.0a880402.jpg',
    enabledHeader: false,
  },
  colors: {
    colorSelected: 'blue',
  },
};

export default ImageDefaultConfiguration;
