import {
  OBJECT_TYPE_DEVICE, OBJECT_TYPE_KPI, OBJECT_TYPE_ETL, OBJECT_TYPE_GIS,
} from './types';

/** Selectores sencillos basados en reselect para desconectar el componente
 * es importante que si tienes que hacer alguna transformación sigan siendo
 * inmutables.
 */
export const selectOrganizationList = (state) => (
  state.get('opendata').get('organization_list')
);

export const selectOrganizationById = (state) => (
  state.get('opendata').get('organization_by_id')
);

export const selectOrganizationListTotal = (state) => (
  state.get('opendata').get('organization_total')
);

export const selectDataSetList = (state) => (
  state.get('opendata').get('dataset_list')
);

export const selectDataSetListTotal = (state) => (
  state.get('opendata').get('dataset_total')
);

export const selectErrorOpenData = (state) => (
  state.get('opendata').get('errorFetching')
);

export const selectFetchingOpenData = (state) => (
  state.get('opendata').get('fetching')
);

export const getOrganizationList = (state) => (
  state.get('opendata').get('organization_list')
);

export const getOrganizationListTotal = (state) => (
  state.get('opendata').get('organization_total')
);

export const getDataSetList = (state) => (
  state.get('opendata').get('dataset_list')
);

export const getDataSetListTotal = (state) => (
  state.get('opendata').get('dataset_total')
);

export const getErrorOpenData = (state) => (
  state.get('opendata').get('errorFetching')
);

export const selectOpenDataSafeType = (type) => {
  switch (type) {
    case OBJECT_TYPE_DEVICE:
      return { api: 'devices', state: 'devices' };
    case OBJECT_TYPE_KPI:
      return { api: 'kpis', state: 'kpis' };
    case OBJECT_TYPE_ETL:
      return { api: 'etls', state: 'etlProcedures' };
    case OBJECT_TYPE_GIS:
      return { api: 'gis', state: 'gis' };
    default:
      return null;
  }
};

export const selectOpenDataSafeTypeByModel = (modelname) => {
  switch (modelname) {
    case 'ETLProcedure':
      return selectOpenDataSafeType(OBJECT_TYPE_ETL);
    case 'Device':
      return selectOpenDataSafeType(OBJECT_TYPE_DEVICE);
    case 'Kpi':
      return selectOpenDataSafeType(OBJECT_TYPE_KPI);
    case 'GIS':
      return selectOpenDataSafeType(OBJECT_TYPE_GIS);
    default:
      return null;
  }
};
