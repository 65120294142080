import validate from 'validate.js';
// eslint-disable-next-line import/no-cycle
import { getHistorical } from '../../services/redux/historicals/actions';
import Utils, { CreateTranslationForModelAttributes, CreateTranslationPlainForModels } from '../Utils';
import { ModelExpected } from './attributes';

/** Class Historical with Utils functions */
export default class Historical extends Utils {
    static entityName = 'Historical';

    constructor(obj = {}) {
      /** Send to utils class the Model and the object passed for build this class */
      super(ModelExpected, obj);
      this.Model = Historical;
    }

    get() {
      const validation = validate(this.getData(), { id: this.constraints.id });
      return validation === undefined ? getHistorical(this.getData()) : {
        error: true, ...validation,
      };
    }

    /** Translations defined by model keys and create automatically from utils function */
    plainTranslations = CreateTranslationPlainForModels('Historical', ModelExpected);

    translations = CreateTranslationForModelAttributes(this.plainTranslations);
}
const SampleHistorical = new Historical();
export { SampleHistorical };
