import React from 'react';
import PropTypes from 'prop-types';
// import RemoveHoc from "./../../helpers/RemoveComponent";
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
// import Dashboard from '../../../models/Dashboard';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';

const Share = (
  <FormattedMessage
    id="options.share"
    defaultMessage="share"
    description="Title of share"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const OptionsForDashboard = ({ rowContent, clickView }) => (

  // const RemoveDOM = RemoveHoc(ButtonOpen, { class: Dashboard, nameData: "dashboards" })

  <div className="tableMenu">
    <ul className="list-unstyled">
      {/* <li onClick={() => { clickView(rowContent, 'clone') }}
        state={{ dashboard: rowContent, profileType: 'clone' }}>{Clone}</li> */}
      <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
        <li
          onClick={() => { clickView(rowContent, 'edit'); }}
          state={{ dashboard: rowContent, profileType: 'edit' }}
          role="presentation"
        >
          {Edit}
        </li>
      </PrivateComponent>
      <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
        <li
          onClick={() => { clickView(rowContent, 'share'); }}
          state={{ dashboard: rowContent, profileType: 'share' }}
          role="presentation"
        >
          {Share}
        </li>
      </PrivateComponent>
    </ul>
  </div>
);

OptionsForDashboard.propTypes = {
  rowContent: PropTypes.string,
  clickView: PropTypes.func,
};

OptionsForDashboard.defaultProps = {
  rowContent: '',
  clickView: () => {},
};

export default OptionsForDashboard;
