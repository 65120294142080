import React from 'react'
import { FormattedMessage } from '../Contexts/LanguageContext';

const oneSecondInMilliseconds = 1000;
const oneMinuteInMilliseconds = 60 * oneSecondInMilliseconds;
const oneHourInMilliseconds = 60 * oneMinuteInMilliseconds;
const oneDayInMilliseconds = 24 * oneHourInMilliseconds;

const howManySecondsDiffer = time => Math.floor(time / oneSecondInMilliseconds);
const howManyMinutesDiffer = time => Math.floor(time / oneMinuteInMilliseconds);
const howManyHoursDiffer = time => Math.floor(time / oneHourInMilliseconds);
const howManyDaysDiffer = time => Math.floor(time / oneDayInMilliseconds);

const secondsToString = seconds => `${seconds}`;
const minutesToString = minutes => `${minutes}`;
const hoursToString = hours => `${hours}`;
const daysToString = days => `${days}`;

export default time => {
  if(isNaN(parseInt(time))) return 'invalid-date';
  const date = new Date(time);
  const nowInMilliseconds = new Date().getTime();
  const dateInMilliseconds = date.getTime();
  const differenceInMilliseconds = nowInMilliseconds - dateInMilliseconds;

  let seconds = howManySecondsDiffer(differenceInMilliseconds);
  let minutes = howManyMinutesDiffer(differenceInMilliseconds);
  let hours = howManyHoursDiffer(differenceInMilliseconds);
  let days = howManyDaysDiffer(differenceInMilliseconds);

  seconds = seconds - (minutes * oneMinuteInMilliseconds) / oneSecondInMilliseconds;
  minutes = minutes - (hours * oneHourInMilliseconds) / oneMinuteInMilliseconds;
  hours = hours - (days * oneDayInMilliseconds) / oneHourInMilliseconds;

  if (minutes === 0)
    return (
      <FormattedMessage
        id={'time.seconds.ago'}
        description={'Time ago x'}
        values={{ seconds: secondsToString(seconds) }}
      />
    ); // Return total of seconds
  
  if (hours === 0) {
    // Return total of minutes
    if (seconds > 0)
      return (
        <FormattedMessage
          id={'time.seconds.minutes.ago'}
          description={'Time ago x'}
          values={{
            seconds: secondsToString(seconds),
            minutes: minutesToString(minutes),
          }}
        />
      );
    return (
      <FormattedMessage
        id={'time.minutes.ago'}
        description={'Time ago x'}
        values={{ minutes: minutesToString(minutes) }}
      />
    );
  }
  
  if (days === 0) {
    // Return total of hours
    if (minutes > 0)
      return (
        <FormattedMessage
          id={'time.hours.minutes.ago'}
          description={'Time ago x'}
          values={{
            hours: hoursToString(hours),
            minutes: minutesToString(minutes),
          }}
        />
      );
    return (
      <FormattedMessage
        id={'time.hours.ago'}
        description={'Time ago x'}
        values={{ hours: hoursToString(hours) }}
      />
    );
  }
  if (days < 5) {
    // Return total of days
    if (hours > 0) {
      if (minutes > 0) {
        return (
          <FormattedMessage
            id={'time.days.hours.minutes.ago'}
            description={'Time ago x'}
            values={{
              hours: hoursToString(hours),
              days: daysToString(days),
              minutes: minutesToString(minutes),
            }}
          />
        );
      }
      return (
        <FormattedMessage
          id={'time.days.hours.ago'}
          description={'Time ago x'}
          values={{ hours: hoursToString(hours), days: daysToString(days) }}
        />
      );
    }
    return (
      <FormattedMessage
        id={'time.days.ago'}
        description={'Time ago x'}
        values={{ days: daysToString(days) }}
      />
    );
  }

  else{
    return `${date.toLocaleTimeString()} - ${date.toLocaleDateString()} `;
  }
};
