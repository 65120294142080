import React from 'react';
import './styles.scss';
import colors from '../../../../configuration/colors';

import { injectIntl } from 'react-intl';

import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { errorFetchingUserReset } from '../../../../services/redux/users/actions';
import { modifyUserSuccessFlag } from '../../../../services/redux/users/actions';
import { clone } from 'ramda';

import Avatar from '../../../../elements/Avatar';
import TabComponent from '../../../../components/Tab';
import Finder from '../../../../components/Finder';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent'
import CardHoc from '../../../../elements/Card';
import { getRoles } from '../../../../services/redux/roles/actions';
import { connect } from 'react-redux';
import User from '../../../../models/User/index';
import { navigate } from '@reach/router';

import Info from './Info';
import CreateRole from '../../Roles/Add/AddWizard';

const OnEdit = ({ onClick }) => (
  <div className="onEditBand">
    <p>
      <FormattedMessage id="profile.edit.mode" />
    </p>
    <i className="uil uil-times" onClick={onClick} />
  </div>
);

export const Footer = (hideModal) =>
  <div className={'d-flex justify-content-center align-items-center'}>
    <p className={'d-none d-md-block mb-0'}><FormattedMessage
      id="users.createRol.text"
      defaultMessage="Want to create a new role?"
      description="Text for Want to create a new role?"
    /></p>

    <CreateRole fromAnotherComponent={true} />

  </div>;
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      original: this.props.data,
      edit: false,
      user: {}
    };
  }

  componentDidMount() {
    this.props.iconsColor(colors['ui-Grey1']);
    getRoles();
    //const StoredUser = this.props.userList.find(u => u.id === this.props.data.id);
    let u = new User({ ...this.props.data });
    u.get();

    u.getUserRoles();

    //this.setState({ original: u, user: u});
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.original.email ||
      this.props.userList !== prevProps.userList
    )
      this.updateUserToState();

    if (prevProps.errorFetching !== this.props.errorFetching) {
      const errFromAPI = this.props.errorFetching.errors;
      if (errFromAPI) {
        const errors = {};
        errFromAPI.forEach(err => {
          errors[err.field] = <FormattedMessage id={err.message} />;
        });

        errorFetchingUserReset();
      }
    }

    if (this.props.modifySuccess) {
      modifyUserSuccessFlag();
      let u = new User({ ...this.props.data });
      u.get();

      u.getUserRoles();
      //this.updateUserToState();
      //this.goBack()
    }
  }

  updateUserToState() {
    const StoredUser = clone(
      this.props.userList.find(u => u.id === this.props.data.id)
    );
    if (StoredUser && this.state.original !== StoredUser) {
      //prevent no data
      if (StoredUser.name === '') StoredUser.name = StoredUser.id;
      this.setState({ original: StoredUser });
      return true;
    }
    return false;
  }

  handleOnClose = () => {
    this.setState({
      opened: !this.state.opened
    });

    this.props.onChange(this.state.opened);
  };

  editUser = () => {
    this.setState({
      edit: true
    });
  };

  turnOffEdit = () => {
    this.setState({
      edit: false
    });
  };

  getState = () => this.state;

  handleOnEdit = e => {
    this.child.handleOnEdit(e);
  };

  successfullEdit = data => {
    this.setState({
      user: data
    });
  };

  render() {
    const data = this.state.original;
    const { edit } = this.state;
    return (
      <div className="profile profile-user">
        <section className="avatar">
          <Avatar
            shape={'circle'}
            size={'xl'}
            backgroundColor={'random'}
            letter={data.name.charAt(0).toUpperCase()}
          >
            {data.name.charAt(0).toUpperCase()}
          </Avatar>
          <h1>
            {data.name} {data.surname}{' '}
          </h1>
          <p>{data.email}</p>
          <div className="buttons">
            <PrivateComponent checkLogin={true} checkPermission={true} microsService={'/app/users'} permission={'update'} >
              <button
                type="button"
                className="btn btn-primary"
                onClick={!edit ? this.editUser : this.handleOnEdit}
              >

                {!edit ? (
                  <FormattedMessage
                    id="users.update.button"
                    defaultMessage="Update User"
                    description="Text for update user"
                  />
                ) : (
                    <FormattedMessage
                      id="users.apply.changes.button"
                      defaultMessage="Apply changes"
                      description="Text for apply changes"
                    />
                  )}
              </button>
            </PrivateComponent>
          </div>
        </section>
        <section>
          <TabComponent
            //titles={!edit ? ['Info', 'Roles'] : ['Info']}
            titles={['Info']}
            onChange={this.handleOnTabsChange}
          >
            <Info
              {...this.props}
              data={data}
              edit={this.state.edit}
              onRef={ref => (this.child = ref)}
              changeMode={this.turnOffEdit}
              succeed={this.successfullEdit}
              enableChangePassword={this.props.enableChangePassword}
            />
            {/* !edit && <Roles {...this.props} /> */}
          </TabComponent>
        </section>

        {edit && <OnEdit onClick={this.turnOffEdit} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  roles: state
    .get('roles')
    .get('list')
    .toJS(),
  userList: state
    .get('users')
    .get('list')
    .toJS(),
  allowedroles: state
    .get('roles')
    .get('list')
    .toJS(),
  //allowedgroups: ['Group1', 'Group2', 'Group3', 'Group4']
  modifySuccess: state.get('users').get('modifySuccess'),
  errorFetching: state.get('users').get('errorFetching')
});

Profile.defaultProps = {
  enableChangePassword: false
};

export default connect(
  mapStateToProps,
  { getRoles }
)(injectIntl(Profile));
