/* eslint-disable react/no-array-index-key */
import React from 'react';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import ReactApexChart from 'react-apexcharts';
import { Row } from 'reactstrap';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { ButtonCustom } from '../../../../../elements/Button';
import FiltertComponent from '../../../../ETLProcedures/ETLEditor/Profile/EditionModal/FilterComponent';
import Field from '../../../../../elements/Field';
import './styles.scss';
import Conditions from './Conditions';
import ContentEditable from '../../../../helpers/ContentEditable/ContentEditable';
import EditFilter from '../../../../ETLProcedures/ETLEditor/Profile/EditionModal/EditFilter';

export const Buttons = ({ onClick, type }) => (
  <div className="buttonAdd">
    <ButtonCustom handleOnClick={() => onClick()} className="btn btn-secondary" label="Añadir grupo" />
  </div>
);

const constraintsFilter = {
  name: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
  condition: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
  valueCondition: {
    presence: {
      allowEmpty: false,
      message: <FormattedMessage id="rules.profile.validation.empty" />,
    },
  },
};

class EditColorConditions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      properties: [{
        name: 'jesus', type: 'hola', condition: 'ee', valueCondition: props.attributes[0],
      }],
      attributes: props.attributes,
      optionSelected: props.attributes[0],
      conditions: Object.entries(props.conditions).length !== 0 ? props.conditions : {
        [props.attributes[0]]: [{
          id: Date.now(), from: '', to: '', color: '',
        }],
      },
      name: props.widgetName,
    };
  }

    onChangeSelect = (value, option) => {
      const { conditions: _conditions, attributes: _attributes } = this.state;
      const conditions = { ..._conditions };
      const attributes = [..._attributes];
      let isNewAtt = true;

      if (conditions[option]) {
        this.setState({ optionSelected: option });
        isNewAtt = false;
      }

      if (isNewAtt) {
        conditions[option] = [{
          id: Date.now(), from: '', to: '', color: '',
        }];
        this.setState({
          optionSelected: option,
          conditions,
        });
      }
    }

    between = (x, min, max) => (parseFloat(x) >= parseFloat(min)) && (parseFloat(x) <= parseFloat(max))

    validateCondition = (data) => {
      const { optionSelected } = this.state;
      const conditions = clone(this.state.conditions);
      let errors;
      let hasError = false;

      if (parseFloat(data.to) && parseFloat(data.from) && parseFloat(data.to) <= parseFloat(data.from)) {
        hasError = true;
        errors = {};
        errors.from = (<FormattedMessage id="widget.config.map.validation.minimun" />);
        errors.to = (<FormattedMessage id="widget.config.map.validation.minimun" />);
      }

      conditions[optionSelected] = conditions[optionSelected].map((c) => {
        if (c && c.id != data.id && (this.between(data.from, c.from, c.to) || this.between(data.to, c.from, c.to))) {
          errors = {};
          hasError = true;
          if (this.between(data.from, c.from, c.to)) {
            errors.from = (<FormattedMessage id="widget.config.map.validation.range" />);
          }
          if (this.between(data.to, c.from, c.to)) {
            errors.to = (<FormattedMessage id="widget.config.map.validation.range" />);
          }
        }
        return c;
      });

      conditions[optionSelected] = conditions[optionSelected].map((c) => {
        if (c && c.id === data.id) {
          c = data;
          if (errors) {
            c.errors = errors;
          } else {
            delete c.errors;
          }
          return c;
        }
        return c;
      });

      this.setState({ conditions });

      if (!hasError) {
        this.props.handleConditions(conditions);
      }
    }

    updateCondition = (data) => {
      this.validateCondition(data);
    }

    removeCondition = (data) => {
      const conditions = clone(this.state.conditions);
      let index = null;
      const attName = this.state.optionSelected;

      conditions[attName].forEach((c, i) => {
        if (c.id === data.id) {
          index = i;
        }
      });
      conditions[attName].splice(index, 1);
      this.setState({ conditions });
      this.props.handleConditions(conditions);
    }

    addCondition = () => {
      const { conditions: _conditions } = this.state;
      const attributeSelected = this.state.optionSelected;
      const conditions = clone(_conditions);
      if (conditions[attributeSelected]) {
        const newCondition = {
          id: Date.now(), from: '', to: '', color: '', name: 'Nuevo Grupo',
        };
        conditions[attributeSelected].push(newCondition);
      }
      this.setState({ conditions });
      this.props.handleConditions(conditions);
    }

    render() {
      const {
        conditions, optionSelected,
      } = this.state;
      const attributes = [];
      this.state.attributes.map((att) => {
        attributes.push({ value: att, name: att.charAt(0).toUpperCase() + att.slice(1) });
      });

      return (
        <div>
          <Row style={{ padding: '25px' }}>
            <div className="selectStyle">
              <Field
                name="conditions"
                type="select"
                options={attributes}
                value={this.state.optionSelected}
                className="field"
                onChange={this.onChangeSelect}
              />
            </div>
          </Row>
          {conditions[optionSelected]

            ? conditions[optionSelected].map((att, index) => {
              if (conditions[optionSelected].length > 0
                && index === conditions[optionSelected].length - 1) {
                return (
                  <div>
                    <div className="rangeGroupContainer">
                      <p>
                        Grupo de rango
                        {index + 1}
                      </p>
                    </div>
                    <Conditions
                      lastIteration
                      addCondition={this.addCondition}
                      removeCondition={this.removeCondition}
                      updateCondition={this.updateCondition}
                      condition={att}
                    />
                  </div>
                );
              }
              return (
                <div>
                  <div className="rangeGroupContainer">
                    <p>
                      Grupo de rango
                      {index + 1}
                    </p>
                  </div>
                  <Conditions
                    lastIteration={false}
                    addCondition={this.addCondition}
                    removeCondition={this.removeCondition}
                    updateCondition={this.updateCondition}
                    condition={att}
                  />
                </div>
              );
            })

            : null}

          <Buttons className="button" type="-" onClick={() => this.addCondition()} />
        </div>
      );
    }
}

EditColorConditions.defaultProps = {
  entity: {},
};

export default injectIntl(EditColorConditions);
