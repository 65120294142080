import React from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, FormattedDate } from 'react-intl';
import { clone } from 'ramda';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import TabComponent from '../../../components/Tab';
import CommonView from '../../CommonView';
import ContentEditable from '../ContentEditable/ContentEditable';
import Field from '../../../elements/Field';
import './styles.scss';

const categoriesSel = [
  // { name: <FormattedMessage id="devices.wizard.categories.luminaire" />, id: 1, value: ['LUMINAIRE'] },
  // { name: <FormattedMessage id="devices.wizard.categories.waste_container" />, id: 2, value: ['WASTE_CONTAINER'] },
  // { name: <FormattedMessage id="devices.wizard.categories.weather_station" />, id: 3, value: ['WEATHER_STATION'] },
  // { name: <FormattedMessage id="devices.wizard.categories.environmental_quality" />, id: 4, value: ['ENVIRONMENTAL_QUALITY'] },
  // { name: <FormattedMessage id="devices.wizard.categories.gps" />, id: 5, value: ['GPS'] },
  // { name: <FormattedMessage id="devices.wizard.categories.parking" />, id: 6, value: ['PARKING'] },
  // { name: <FormattedMessage id="devices.wizard.categories.electric_panel" />, id: 7, value: ['ELECTRIC_PANEL'] },
  // { name: <FormattedMessage id="devices.wizard.categories.health" />, id: 8, value: ['HEALTH'] },
  // { name: <FormattedMessage id="devices.wizard.categories.video_camera" />, id: 9, value: ['VIDEO_CAMERA'] },
  // { name: <FormattedMessage id="devices.wizard.categories.electric_meter" />, id: 10, value: ['ELECTRIC_METER'] },
  // { name: <FormattedMessage id="devices.wizard.categories.water_meter" />, id: 11, value: ['WATER_METER'] },
  // { name: <FormattedMessage id="devices.wizard.categories.irrigation" />, id: 12, value: ['IRRIGATION'] },
  { name: <FormattedMessage id="devices.wizard.categories.other" />, id: 13, value: ['OTHER'] },

];

class Profile extends CommonView {
  constructor(props) {
    super({ props });

    const data = clone(this.props.data);

    this.state = {
      opened: false,
      ...data,
    };
  }

  handleOnClose = () => {
    this.setState({
      opened: !this.state.opened,
    });

    this.props.onChange(this.state.opened);
  };

  turnOffEdit = () => {
    this.setState({
      edit: false,
    });
  };

  handleContentEditableChange = (id, data) => {
    this.setState(
      {
        [id]: data,
      },
      this.props.save(id, data),
    );
  };

  handleOnTabsChange = (index) => {
    this.props.onTabsChange && this.props.onTabsChange(index);
  }

  render() {
    const {
      createdAt, description, name, owner, api_key, categories,
    } = this.state;
    const {
      errors, editable, withTabs, withDescription, withFooter, intl, children,
    } = this.props;

    const filteredChildren = Array.isArray(children)
      ? children.filter((child) => child !== false)
      : children;

    return (
      <div className="profile">
        <section className="mainInfo">
          <ContentEditable
            errors={errors && errors.name}
            tag="h1"
            onBlur={this.handleContentEditableChange}
            id="name"
            editable={editable}
          >
            {name}
          </ContentEditable>
          {createdAt && (
          <p>
            <FormattedMessage id="profile.created" />
            :
            {' '}
            <FormattedDate value={createdAt} />
            {' | '}
            {owner}
          </p>
          )}
          {!api_key ? null : (
            <p>
              {' '}
              Api key:
              {api_key}
            </p>
          )}
        </section>
        {withDescription && (
        <section className="description">
          <h2>
            <FormattedMessage id="dashboard.description" />
          </h2>
          <ContentEditable
            errors={errors && errors.description}
            tag="p"
            onBlur={this.handleContentEditableChange}
            id="description"
            editable={editable}
          >
            {description}
          </ContentEditable>

          {/* { categories
          && (editable
            ? ( 
               <Field
                className="categoriesProfile"
                name="categories"
                type="text"
                label={intl.formatMessage({
                  id: 'devices.wizard.categories.label',
                })}
                options={categoriesSel}
                placeholder={intl.formatMessage({
                  id: 'devices.wizard.categories.placeholder',
                })}
                // id="categories"
                value={intl.formatMessage({
                  id: 'categories.other',
                })}
                onChange={this.handleContentEditableChange}
                // helperText={errors && errors.categories}
                // error={errors && !!errors.categories}
                // key="categories"

              />
            )
            : ( */}
              <>
                <h2>
                  <FormattedMessage id="devices.wizard.categories.label" />
                </h2>
                <FormattedMessage id={`devices.wizard.categories.other`} />
              </>
            {/* ) 
           )} */}
        </section>
        )}
        {withTabs && (
        <section className="tabs">
          <TabComponent
            titles={this.props.titles}
            onChange={this.handleOnTabsChange}
            id={this.props?.data?.id}
          >
            {filteredChildren}
          </TabComponent>
        </section>
        )}
        {!withTabs && (
        <section className="profileContent">
          {this.props.children}
        </section>
        )}
        {withFooter && (
        <section className="profileFooter">
          {this.props.withFooter}
        </section>
        )}
      </div>
    );
  }
}

Profile.propTypes = {
  /** If false, the profile name and description will not be editable */
  editable: PropTypes.bool,
  withTabs: PropTypes.bool,
  withFooter: PropTypes.bool,
};

Profile.defaultProps = {
  /** If false, the profile name and description will not be editable */
  editable: true,
  withTabs: true,
  withDescription: true,
  withFooter: false,
};

export default injectIntl(Profile);
