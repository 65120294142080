// eslint-disable-next-line import/no-cycle
import { getUrnId } from '../../../../../helpers/utils';

const getAllAttributes = (device) => [
  ...(device?.attributes || []),
  ...(device?.lazy_attributes || []),
  ...(device?.command_attributes || []),
  ...(device?.static_attributes || []),
];

const formatToMapWidget = (historicalValues, widgetSources) => {
  const lastValues = historicalValues.filter((h) => h.urn);
  const details = {
    data: [],
    devices: lastValues
      .filter((source) => source.value?.type === 'geo:point')
      .map((source) => {
        const widgetSource = widgetSources.find((o) => o.id === getUrnId(source.urn));
        return {
          id: widgetSource.id,
          name: widgetSource.name,
          entityId: widgetSource.device_id,
          urn: source.urn,
          attrName: source.attrName,
          categories: widgetSource.categories?.[0],
          location: {
            x: source.value.value.split(',')[0],
            y: source.value.value.split(',')[1],
          },
          attributes: [],
        };
      }),
  };

  historicalValues.forEach((historical) => {
    if (historical.urn) {
      const sourceId = getUrnId(historical.urn);
      const index = details.devices.findIndex((d) => d.id === sourceId);
      if (index > -1) {
        if (!details.devices[index].attributes.includes(historical.attrName)) {
          details.devices[index].attributes.push({ [historical.attrName]: historical.value.value });
        }
      }
    } else {
      const lastValue = historicalValues.filter((value) => value.entityId === historical.entityId);
      const source = widgetSources.find((o) => historical.entityId === o.sourceId);
      const attributes = getAllAttributes(source);
      const attrGeo = attributes.find((o) => o.type === 'geo:point');
      const id = lastValue?.length ? getUrnId(lastValue[0].urn) : 'error';
      if (!source.categories) {
        source.categories = historical['FIWOO-CATEGORY-ENTITY'];
      }
      const data = {
        id,
        attributes,
        TimeInstant: historical.TimeInstant,
        device_id: historical.entityId,
        device_name: historical.entityId,
        data: {},
        categories: historical['FIWOO-CATEGORY-ENTITY'],
        location: {
          x: historical[attrGeo.name].split(',')[0],
          y: historical[attrGeo.name].split(',')[1],
        },
        locationName: attrGeo.name,
      };
      attributes.forEach((att) => { data.data[att.name] = historical[att.name]; });
      details.data.push(data);
    }
  });
  return details;
};

export default formatToMapWidget;
