import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography } from '../../configuration/fonts';
import colors from '../../configuration/colors';
import { sendCommand as sendCommandApi } from "../../services/api/devices";
import SendButton from './components/button'

import './styles.scss';

const Actions = styled.div`
  float: right;
  color: ${colors['ui-Black']};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -10px;
  text-transform: uppercase;
`;


const sendCommand = async (e, device, command, callback) => {
  //e.preventDefault();
  //e.stopPropagation();
  return await sendCommandApi(device, command);

};

class WidgetSendCommandSingle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status: 'normal'
    }

  }

  handleSendCommands = async e => {
    const { state_a } = this.props
    const commands = state_a.commands
    let allSuccess = true;

    this.setState({
      status: 'loading'
    })

    for (let index = 0; index < commands.length; index++) {
      try{
        const response = await sendCommand(e, { id: commands[index].id }, { name: commands[index].command, value: commands[index].value });
        if (response.status !== 200) {
          allSuccess = false
        } 
      }
      catch{
        allSuccess = false;
      }
      
      

    }

    if(allSuccess){
      this.setState({
        status: 'done'
      })
    }else{
      this.setState({
        status: 'wrong'
      })
    }

   
  }
  resetButton = () => {
    this.setState({
      status:'normal'
    })
  }


  render() {
    const { text, subtext, actions, state_a } = this.props;
    const { status } = this.state
    const data = { status, label: state_a.label}

    
    return (
      <div className={'containerSendcommandWidget'}>
        <Actions>{actions}</Actions>
        <div>
          <h1>
            {text}
          </h1>
        </div>
        <div>
          <p>{subtext}</p>
        </div>
        <SendButton onClick={this.handleSendCommands} data={data} reset={this.resetButton}/>
      </div>
    );
  }
}

WidgetSendCommandSingle.propTypes = {
  text: PropTypes.string,
  subtext: PropTypes.string,
};

WidgetSendCommandSingle.defaultProps = {
  text: '',
  subtext: '',
};

export default WidgetSendCommandSingle;
