import React, {
  useState, memo, useCallback, useEffect,
} from 'react';
import { Row } from 'reactstrap';
import { clone } from 'ramda';

import ColorPicker from '../../../../components/ColorPicker';
import ContentEditable from '../../../helpers/ContentEditable/ContentEditable';
import './style.scss';
import { categories, Components } from '../../../../models/Widget/configurationSheets/utils';

const ColorsData = ({
  colors,
  handleChange,
  deviceNumber = 1,
  alias,
  widget,
  editable,
  categories: _categories,
}) => {
  const getDefaultAlias = useCallback(() => {
    const aliasdefault = {};
    const { origins } = widget;

    origins.forEach((o) => {
      const connectedDevice = o.connectedDevices;
      const allAttr = [
        ...connectedDevice.attributes,
        ...connectedDevice.lazy_attributes,
        ...connectedDevice.command_attributes,
        ...connectedDevice.static_attributes];
      allAttr.forEach((att) => {
        aliasdefault[`${att}-${connectedDevice.device_id}`] = att;
      });
    });

    return aliasdefault;
  }, [widget]);

  const [localAlias, setLocalAlias] = useState(alias || getDefaultAlias());
  const [localColors, setLocalColors] = useState();

  const handleContentEditableChangeAlias = (id, data) => {
    const category = _categories.find((e) => e === categories.LABELS);
    const newAlias = clone(localAlias);
    newAlias[id] = data;
    setLocalAlias(newAlias);
    handleChange({ name: 'alias', value: newAlias }, category, Components.colorsData);
  };

  const handleColorChange = (color, name) => {
    const newColors = clone(localColors);
    const splittedName = name.split('-');
    if (splittedName.length === 3) {
      const device = splittedName[0];
      const attribute = splittedName[2];
      if (newColors[device] && newColors[device][`Trendline-${attribute}`]) {
        newColors[device][`Trendline-${attribute}`] = color.hex;
      }
    } else {
      newColors[name] = color.hex;
    }
    setLocalColors(newColors);
    handleChange({ name: 'colors', value: newColors }, undefined, Components.colorsData);
  };

  useEffect(() => {
    if (alias) setLocalAlias(alias);
  }, [alias]);

  useEffect(() => {
    const colorArray = colors.custom ?? colors;
    if (colorArray) setLocalColors(colorArray);
  }, [colors]);

  return (
    <div className="styleAndConfiguration">
      <div className="colorPicker">
        <Row>
          {localColors && Object.keys(localColors).map((attribute) => (
            <div className="colorPickContainer">
              <ColorPicker
                className="colorPickerStyles"
                name={attribute}
                onChangeComplete={handleColorChange}
                color={localColors[attribute]}
                label="Color"
              />
              <div className="col">
                <span className="atLabel">
                  {attribute}
                </span>
                <ContentEditable
                  className="labelFocus"
                  tag="attribute"
                  onBlur={handleContentEditableChangeAlias}
                  id={attribute}
                  editable={editable}
                >
                  {localAlias[attribute]}
                </ContentEditable>
              </div>
            </div>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default memo(ColorsData);
