export const ADD_KPI_REQUEST = 'ADD_KPI_REQUEST';
export const ADD_KPI_SUCCESS_FLAG = 'ADD_KPI_SUCCESS_FLAG';
export const ADD_KPI_ERROR = 'ADD_KPI_ERROR';
export const ADD_KPI_SUCCESS = 'ADD_KPI_SUCCESS';

export const UPDATE_KPI_REQUEST = 'UPDATE_KPI_REQUEST';
export const UPDATE_KPI_SUCCESS_FLAG = 'UPDATE_KPI_SUCCESS_FLAG';
export const UPDATE_KPI_SUCCESS = 'UPDATE_KPI_SUCCESS';
export const UPDATE_KPI_ERROR = 'UPDATE_KPI_ERROR';

export const DELETE_KPI_REQUEST = 'DELETE_KPI_REQUEST';
export const DELETE_KPI_SUCCESS = 'DELETE_KPI_SUCCESS';
export const DELETE_KPI_ERROR = 'DELETE_KPI_ERROR';

export const GET_KPI = 'GET_KPI';
export const GET_KPI_REQUEST = 'GET_KPI_REQUEST';
export const GET_KPI_SUCCESS = 'GET_KPI_SUCCESS';
export const GET_KPI_ERROR = 'GET_KPI_ERROR';

export const GET_KPIS_REQUEST = 'GET_KPIS_REQUEST';
export const GET_KPIS_ERROR = 'GET_KPIS_ERROR';
export const GET_KPIS_SUCCESS = 'GET_KPIS_SUCCESS';

export const GET_KPI_TABLE_REQUEST = 'GET_KPI_TABLE_REQUEST';
export const GET_KPI_TABLE_ERROR = 'GET_KPI_TABLE_ERROR';
export const GET_KPI_TABLE_SUCCESS = 'GET_KPI_TABLE_SUCCESS';

export const GET_MY_KPIS_REQUEST = 'GET_MY_KPIS_REQUEST';
export const GET_MY_KPIS_ERROR = 'GET_MY_KPIS_ERROR';
export const GET_MY_KPIS_SUCCESS = 'GET_MY_KPIS_SUCCESS';

export const CLEAR_KPIS_STATE = 'CLEAR_KPIS_STATE';

export const EXECUTE_KPI_REQUEST = 'EXECUTE_KPI_REQUEST';
export const EXECUTE_KPI_SUCCESS = 'EXECUTE_KPI_SUCCESS';
export const EXECUTE_KPI_ERROR = 'EXECUTE_KPI_ERROR';

export const GET_KPI_V2 = 'GET_KPI_V2';

export const GET_KPI_TO_SOURCE_LIST = 'GET_KPI_TO_SOURCE_LIST';
