import * as TYPES from './types';
import {store} from '../store';

export const addPermission = (Permission) => store.dispatch({
    type: TYPES.ADD_PERMISSION_REQUEST,
    payload: Permission,
});

export const addPermissionSuccessFlag = () => store.dispatch({
    type: TYPES.ADD_PERMISSION_SUCCESS_FLAG
});

export const updatePermission = (Permission) => store.dispatch({
    type: TYPES.MODIFY_PERMISSION_REQUEST,
    payload: Permission,
});

export const modifyPermissionSuccessFlag = () => store.dispatch({
    type: TYPES.MODIFY_PERMISSION_SUCCESS_FLAG
});

export const deletePermission = (Permission) => store.dispatch({
    type: TYPES.DELETE_PERMISSION_REQUEST,
    payload: Permission,
});

export const getPermission = (Permission) => store.dispatch({
    type: TYPES.GET_PERMISSION_REQUEST,
    payload: Permission,
});

export const getPermissions = () => store.dispatch({
    type: TYPES.GET_PERMISSIONS_REQUEST
});

export const errorFetchingPermissionReset = () => store.dispatch({
    type: TYPES.ERRORFETCHING_PERMISSION_RESET
});