import Faker from 'faker';
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import MyWorker from 'comlink-loader!./historical-worker';
import { historicalManagement, historicalV2Management } from '../../configuration/config';
import { requestAPI, getToken, getXDataAccessToken } from '../../helpers';
import { getDate, getResolution } from '../../helpers/samplingHistorical';

const endPoint = historicalManagement.urlAPI.concat(historicalManagement.paths.search);
const endPointAggregates = (
  historicalManagement.urlAPI.concat(historicalManagement.paths.aggregates));
const endPointV2 = historicalV2Management.urlAPI.concat(historicalV2Management.paths.search);

const buildQuery = (historical, options) => (
  '/query/'.concat(historical.entity_type, '/', historical.id, '/', historical.attribute,
    '?operation=', options.operation, '&resolution=', options.resolution, '&from=', options.from.toString(),
    '&to=', options.to.toString()));

const exampleBodyFn = (numberOfHistoricalToGenerate = 50) => {
  const HistoricalsFaker = [];

  for (let i = 0; i < numberOfHistoricalToGenerate; i += 1) {
    HistoricalsFaker.push({
      id: Faker.random.uuid(),
      entityId: Faker.random.word(),
      entityType: Faker.random.words(),
      TimeInstant: Faker.date.past(),
      recvTime: Faker.date.past(),
      'FIWOO-UNIT-ATTRIBUTE': '%7B%22humidityULattr%22%3A%22L%22%7D',
      humidityULstatic: Faker.random.number(),
      humidityulcommand_status: 'PENDING',
      tempULcommand_status: 'PENDING',
      tempULstatic: Faker.random.number(),
    });
  }

  return { historicals: HistoricalsFaker };
};

/** First version of Historical CRUD */
// PLURAL

export const readHistoricals = async (query) => {
  const params = {
    endPoint: `${endPoint}?${query}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'historicals',
  };
  return requestAPI(params, exampleBodyFn);
};

// SINGULAR
export const readHistorical = async (historical) => {
  if (!historical.id) return { status: 301, error: 'invalid id attr' };
  const { configuration } = historical;
  const { data } = configuration;
  let query;
  if (!historical.aggregate) {
    const size = historical.size ? historical.size : 1;
    const page = historical.page ? historical.page : 1;
    const entityId = historical?.entity_type === 'service' ? `${historical.entity_type}:${historical.id}` : historical.id;
    const timeInstant = historical.startDate && historical.endDate ? `&recvTime=>${historical.startDate}&revcTime<=${historical.endDate}` : '';
    query = `?x-query-page=${page}&x-query-size=${size}&x-query-sort-recvTime=desc&entityId=${entityId}${timeInstant}`;
  } else {
    const { sampling } = data;
    const operation = data?.operation || 'avg';
    const resolution = getResolution(sampling);
    const from = getDate('startDate', historical);
    const to = getDate('endDate', historical);

    query = buildQuery(historical, {
      resolution, to, from, operation,
    });
  }

  const params = {
    endPoint: (historical.aggregate ? endPointAggregates : endPoint) + query,
    statusOK: 200,
    toJSON: true,
    returnData: 'historicals',
    requestConfig: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-fiware-service': historical.fiware.service,
        'x-fiware-servicepath': historical.fiware.servicepath,
      },
    },
  };

  // eslint-disable-next-line no-return-await
  return await requestAPI(params, exampleBodyFn);
};

export const readHistoricalV2 = async (requestBody) => {
  if (!requestBody.type) return { status: 301, error: 'invalid type attr' };
  const params = {
    endPoint: endPointV2,
    statusOK: 200,
    toJSON: true,
    returnData: 'historicals',
    requestConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },

  };
  const inst = new MyWorker();
  const answer = await inst.getData(params, undefined, getToken(), false);
  return answer;
};

export const readPublicHistoricalV2 = async (requestBody) => {
  if (!requestBody.type) return { status: 301, error: 'invalid type attr' };
  const params = {
    endPoint: `${endPointV2}/public`,
    statusOK: 200,
    toJSON: true,
    returnData: 'historicals',
    requestConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },
  };
  const inst = new MyWorker();
  const answer = await inst.getData(params, undefined, getXDataAccessToken(), true);
  return answer;
};
