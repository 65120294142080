/* eslint-disable import/no-cycle */
import React from 'react';
import ColorPicker from '../../../../components/ColorPicker';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Field from '../../../../elements/Field';
import BarData from './barData';
import ColorsData from './colorsData';
import ColorsDataMap from './ColorsDataMap';
import { StatelessCheckbox as CheckBox } from '../../../../elements/CheckBox';
import NeedleRange from './NeedleRange';
import Switch from '../../../../elements/Switch';
import KpiHistoricalSwitch from './KpiHistoricalSwitch';
import SelectImage from './SelectImage';
import SelectColor from './SelectColor';
import EditColorConditions from './EditColorConditions/EditColorConditions';
import ListComponent from './ListComponent';
import TableData from './TableData';
import LineData from './LineData';
import SelectMapStyle from './SelectMapStyle';
import AdvancedLegendNames from './AdvancedLegendNames';
import AdvancedLegendColors from './AdvancedLegendColors';
import { getCategory } from '../../../../models/Widget/configurationSheets/utils';
import CommandsOnOff from './Commands/OnOff/CommandsOnOff';
import CommandsSlider from './Commands/Slider/CommandsSlider';
import { isShowV2 } from '../../../../helpers/utils';

const getDevices = (widget) => {
  const devices = [];
  widget.origins.forEach((o) => {
    if (o.connectedDevices.readDevice) {
      devices.push(o.connectedDevices.readDevice);
    }
  });

  return devices;
};

const getCategories = (widget, sources) => {
  const categories = [];
  // ToDo Remove when implement showV2
  const devices = isShowV2()
    ? sources
    : getDevices(widget);
  devices.forEach((d) => {
    if (!categories.includes(d.categories[0] || 'OTHER')) {
      categories.push(d.categories[0] || 'OTHER');
    }
  });
  return categories;
};

export const getComponent = (
  configField,
  widget,
  handler,
  saver,
  config,
  handleSubmenu,
  categorySelected,
  errors,
  isLinked,
  sources,
) => {
  let fieldValue = null;
  if (Object.keys(config).length) {
    if (configField.subCategories && configField.subCategories[0]) {
      fieldValue = (
        config[configField.categories[0]][configField.subCategories[0]][configField.name]);
    } else if (config[configField.categories[0]]) {
      fieldValue = config[configField.categories[0]][configField.name];
    }
  }
  const subCategory = configField.subCategories?.[0] ?? undefined;
  const category = getCategory(configField.name);
  switch (configField.component) {
    case 'text':
      return (
        <Field
          name={configField.name}
          type="text"
          label={<FormattedMessage id={`widget.config.${configField.name}`} />}
          // mode="edit"
          onChange={(event) => handler(
            event, configField.categories[0], configField.component, subCategory,
          )}
          onBlur={saver}
          value={fieldValue || ''}
          error={typeof errors === 'object' && errors[`${category}.${configField.name}`]}
          helperText={errors[`${category}.${configField.name}`]}
        />
      );
    case 'textArea':
      return (
        <Field
          name={configField.name}
          type="textarea"
          label={<FormattedMessage id={`widget.config.${configField.name}`} />}
          onChange={(event) => handler(
            event, configField.categories[0], configField.component, subCategory,
          )}
          onBlur={saver}
          rows={25}
          value={fieldValue}
          error={typeof errors === 'object' && errors[`${category}.${configField.name}`]}
          helperText={errors[`${category}.${configField.name}`]}
        />
      );
    case 'colorsData':
      return (
        <ColorsData
          colors={config.colors}
          alias={config.labels?.alias}
          handleChange={handler}
          categories={configField.categories}
          widget={widget}
        />
      );
    case 'colorsDataMap':
      return (
        <div className="styleAndConfiguration">
          <ColorsDataMap
            colors={config.conditions?.categoryColors}
            alias={config.labels.categoryAlias}
            onChangeAlias={handler}
            onChangeColors={handler}
            categories={getCategories(widget, sources)}
            handleSubmenu={handleSubmenu}
            subMenus={configField.subMenus}
          />
        </div>

      );
    case 'number':
      return (
        <Field
          name={configField.name}
          type="number"
          label={<FormattedMessage id={`widget.config.${configField.name}`} />}
          // mode="edit"
          onChange={(event) => handler(
            event, configField.categories[0], configField.component, subCategory,
          )}
          onBlur={saver}
          value={fieldValue}
          error={typeof errors === 'object' && errors[`${category}.${configField.name}`]}
          helperText={errors[`${category}.${configField.name}`]}
        />
      );
    case 'checkbox':
      return (
        <CheckBox
          className="justifyLeftCheck"
          name={configField.name}
          onChange={(name, value) => {
            handler(
              { name, value }, configField.categories[0], configField.component, subCategory,
            );
          }}
          checked={!!fieldValue}
          label={<FormattedMessage id={`widget.config.${configField.name}`} />}
        />
      );
    case 'colorPicker':
      return (
        <div className="color-picker">
          <div className="label">
            <span><FormattedMessage id={`widget.config.${configField.name}`} /></span>
          </div>
          <ColorPicker
            name={configField.name}
            onChangeComplete={(event) => {
              handler({
                name: configField.name,
                value: event.hex,
              }, configField.categories[0], configField.component, subCategory);
            }}
            color={fieldValue}
          />
        </div>
      );
    case 'needleRange':
      return (
        <>
          <NeedleRange
            handleChange={handler}
            conditions={config.conditions}
            colors={config.colors}
            categories={configField.categories}
          />
        </>
      );

    case 'date':
      return (
        <Field
          name={configField.name}
          type="date"
          label={<FormattedMessage id={`widget.config.${configField.name}`} />}
          // mode="edit"
          onChange={(event) => handler(
            event, configField.categories[0], configField.component, subCategory,
          )}
          onBlur={saver}
          value={fieldValue}
          error={typeof errors === 'object' && errors[`${category}.${configField.name}`]}
          helperText={errors[`${category}.${configField.name}`]}
        />
      );

    case 'select':
      return (
        <Field
          name={configField.name}
          type="select"
          options={configField.options}
          value={fieldValue}
          onChange={(name, _value) => handler(
            { name, value: _value },
            configField.categories[0],
            configField.component,
            subCategory,
          )}
          errors={typeof errors === 'object' && errors[`${category}.${configField.name}`]}
          helperText={errors[`${category}.${configField.name}`]}
        />
      );

    case 'barData':
      return (
        <BarData
          config={config}
          handler={handler}
        />
      );

    case 'switch':
      return (
        <div className="switchDiv">
          <Switch
            className="switch"
            positionTitle="right"
            title={<FormattedMessage id={`widget.config.${configField.name}`} />}
            checked={!!config[configField.categories[0]][configField.name]}
            onChange={(value) => {
              handler(
                { name: configField.name, value },
                configField.categories[0],
                configField.component,
              );
            }}
          />
        </div>
      );
    case 'selectImage':
      return (
        <SelectImage
          imageSelected={config[configField.categories[0]][configField.name]}
          handleChange={handler}
        />
      );
    case 'selectColor':
      return (
        <SelectColor
          colorSelected={config[configField.categories[0]][configField.name]}
          handleChange={handler}
        />
      );
    case 'lineData':
      return (
        <LineData
          config={config}
          handler={handler}
          categories={configField.categories}
          errors={errors}
        />
      );
    case 'tableConditions':
      return (
        <div className="tableContainer">
          <EditColorConditions
            attributes={config.labels?.alias}
            conditions={config.conditions}
            handleConditions={handler}
          />
        </div>

      );
    case 'listComponent':
      return (
        <div className="headerEditable">
          <ListComponent
            widget={widget}
            handler={handler}
            activeAttribute={() => { }}
            onContentEditableChange={() => { }}
            onCheckAll={() => { }}
            openedPanels={[true]}
            onAttributeCheck={() => { }}
          />
        </div>
      );
    case 'kpiHistoricalSwitch':
      return (
        <div className="switchDiv">
          <KpiHistoricalSwitch
            checked={!!config[configField.categories[0]][configField.name]}
            onChange={(event) => handler(
              event, configField.categories[0], configField.component,
            )}
            title={<FormattedMessage id={`widget.config.${configField.name}`} />}
            widget={widget}
          />
        </div>
      );
    case 'tableData':
      return (
        <TableData
          widget={widget}
          config={config}
          handler={handler}
          errors={errors}
        />
      );
    case 'mapStyle':
      return (
        <SelectMapStyle
          mapType={fieldValue}
          onChange={(event) => handler(
            event, configField.categories[0], configField.component, subCategory,
          )}
        />
      );
    case 'advancedLegendColors':
      return (
        <AdvancedLegendColors
          widget={widget}
          categorySelected={categorySelected}
          handler={handler}
          widgetSources={sources}
        />
      );
    case 'advancedLegendNames':
      return (
        <AdvancedLegendNames
          widget={widget}
          categorySelected={categorySelected}
          widgetSources={sources}
          handler={handler}
        />
      );
    case 'commandsOnOff':
      return (
        <CommandsOnOff
          config={config}
          widget={widget}
          sources={sources}
          handler={handler}
        />
      );
    case 'commandsSlider':
      return (
        <CommandsSlider
          config={config}
          widget={widget}
          sources={sources}
          handler={handler}
        />
      );
    default:
      return Field;
  }
};

export default function ExpansionContent(
  configArray,
  handler,
  saver,
  config,
  widget,
  handleSubmenu,
  categorySelected,
  errors,
  isLinked,
  sources,
) {
  return configArray.map(
    (configField) => getComponent(
      configField,
      widget,
      handler,
      saver,
      config,
      handleSubmenu,
      categorySelected,
      errors,
      isLinked,
      sources,
    ),
  );
}
