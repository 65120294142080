import React from 'react';
import validate from 'validate.js';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import { categories, Components, getCategory } from '../../utilsConfigurationSheet';

export const OnOffConfigurationSheetBasic = {
  image: [
    {
      name: 'imageSelected',
      component: Components.selectImage,
      categories: [categories.APPEARANCE],
    },
  ],

  color: [
    {
      name: 'colorSelected',
      component: Components.selectColor,
      categories: [categories.COLORS],
    },
  ],
};

export const OnOffConfigurationSheet = {

  description: [
    {
      name: 'description',
      component: Components.textArea,
      categories: [categories.LABELS],
    },
  ],

  commands: [
    {
      name: 'commandsOnOff',
      component: Components.commandsOnOff,
      categories: [categories.CONDITIONS],
    },
  ],
  colors: [
    {
      name: 'off',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
    {
      name: 'on',
      component: Components.colorPicker,
      categories: [categories.COLORS],
    },
  ],

};

export const OnOffWizardConfig = {
  commandsToShow: {},
  attributesSelected: '',
  commandSelected: '',
  offRowsNumber: 0,
  onRowsNumber: 0,
  availableCommands: [
    {
      state: false,
      commands: [],
    },
    {
      state: true,
      commands: [],
    },
  ],
  localErrors: {
    source: [],
    attribute: [],
    command: [],
  },
  showModalCommandsInGroup: false,
  allAttributes: [],
  commandsGroups: { off: {}, on: {} },
};

export const OnOffDefaultTemplateConfig = ['description', 'numberOfPoints'];

export const validationOnOff = (config) => {
  const constraints = {};
  OnOffDefaultTemplateConfig.forEach((property) => {
    const category = getCategory(property);
    if (!constraints[category]) {
      constraints[category] = {
        presence: true,
      };
    }
    constraints[`${category}.${property}`] = {
      presence: {
        allowEmpty: false,
        message: <FormattedMessage id="rules.profile.validation.empty" />,
      },
    };
    if (property === 'numberOfPoints') {
      constraints[`${category}.${property}.0.commands`] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.command.off.empty" />,
        },
      };
      constraints[`${category}.${property}.1.commands`] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.command.on.empty" />,
        },
      };
    }
  });

  return validate(config, constraints);
};
