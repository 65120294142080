import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clone } from 'ramda';
import { injectIntl } from 'react-intl';
import { validate } from 'validate.js';
import PropTypes from 'prop-types';
import EditableCard from '../../EditableCard/EditableCard';
import {
  ATTRIBUTE,
  LAZY,
  COMMAND,
  STATIC,
  INTERNAL,
  getNameAttributeTranslate,
  ActionMenu,
  ValueField,
  ATTRIBUTE_PROPERTY,
  LAZY_PROPERTY,
  COMMAND_PROPERTY,
  STATIC_PROPERTY,
} from './helpers/index';

import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { protocolData } from '../../../../data/protocols';
import { getHistoricalList } from '../../../../services/redux/historicals/actions';

import './styles.scss';
import CustomWebSocket from '../../../../helperComponents/CustomWebSocket/index';
import { parseSigfox } from '../../Add/utils/saveEntity';
import AddCommandValueHoc from '../../Add/components/AddCommandValue';
import Device from '../../../../models/Device';

const Footer = (hideModal, addValueCommandOptions) => {
  // eslint-disable-next-line react/prop-types
  const ButtonOpenSendCommand = ({ showModal }) => (
    <li onClick={showModal}>
      <FormattedMessage id="devices.addValueCommand.text" />
    </li>
  );
  const AddCommandValueDOM = AddCommandValueHoc(ButtonOpenSendCommand, { class: Device, nameData: 'devices' });

  return <AddCommandValueDOM rowContent={{}} addValueCommandOptions={addValueCommandOptions} />;
};

export class EditProperties extends Component {
  constructor(props) {
    super(props);

    const properties = this.getProperties();

    this.state = {
      saved: clone(properties),
      cards: clone(properties),
      activeCards: this.initActiveCards(properties),
      errors: {},
      realTimeValues: {},
      dataOptions: [],
    };
  }

  componentDidMount() {
    const { data, realTimeEnabled } = this.props;
    realTimeEnabled
      && getHistoricalList([
        {
          id: data.device_id,
          fiware: data.fiware,
          entity_type: data.entity_type,
          configuration: { data: {} },
        },
      ]);
  }

  componentDidUpdate(prevProps) {
    const { realTimeEnabled, data, historicals } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      const properties = this.getProperties();
      this.setState({
        saved: clone(properties),
        cards: clone(properties),
        activeCards: this.initActiveCards(properties),
        errors: {},
      });
    }

    if (
      realTimeEnabled
      && JSON.stringify(prevProps.historicals) !== JSON.stringify(historicals)
    ) {
      this.setState({
        realTimeValues: historicals[0].data.historicals[0],
      });
    }
  }

  findProtocolGenericData = () => {
    const { data } = this.props;
    return protocolData.protocol.find((p) => p.value === data.protocol);
  }

  getData = (onEditData) => {
    const protocol = this.findProtocolGenericData();
    const type = {};
    const subtypes = {};
    const { saved } = this.state;

    protocol.attributes.forEach((attribute) => {
      type[attribute.key] = attribute.type;

      if (attribute.subtypes) {
        attribute.type.forEach((s) => {
          if (s.subtype) {
            s.subtype.forEach((subtype) => {
              if (!subtypes[subtype.value]) subtypes[subtype.value] = {};

              subtypes[subtype.value][s.value] = typeof subtype.data === 'function' ? subtype.data({ body: saved, onEditData }) : subtype.data;
            });
          }
        });
      }
    });
    return { type, ...subtypes };
  };

  getGenericProperties = (modelAttribute) => {
    const genericProperties = {};
    modelAttribute.properties.forEach((p) => {
      if (modelAttribute.key === 'command_attributes' && p === 'value') genericProperties[p] = [];
      else genericProperties[p] = '';
    });
    return genericProperties;
  };

  getSpecificProperties = (modelAttribute, entity, attributeData) => (
      modelAttribute.translator?.(entity, attributeData));

  getProperties = () => {
    const protocolModel = this.findProtocolGenericData();
    const { data } = this.props;

    const attributes = [...protocolModel.attributes.map((a) => [
      ...data[a.key].map((e) => {
        const o = e;
        if (a.key === 'command_attributes') {
          if (o.available_commands) {
            o.value = o.available_commands;
            delete o.available_commands;
          }
          delete o.unit;
        }

        return ({
          ...this.getGenericProperties(a),
          property: a.key,
          ...this.getSpecificProperties(protocolModel, data, o),
          ...o,
        });
      }),
    ])];
    return attributes.reduce((attribute, joinedAttributes) => [...joinedAttributes, ...attribute]);
  };

  getModelOfData = () => {
    const { data } = this.props;
    return protocolData.protocol.find((p) => p.value === data.protocol);
  }

  initActiveCards = (data) => data.map(() => false);

  findCoincidences = (key, data, index) => {
    let coincidences = 0;
    let i = 0;
    while (coincidences === 0 && i < data.length) {
      // eslint-disable-next-line no-loop-func,no-shadow
      data.forEach((o, i) => {
        o[key] === data[index][key] && i !== index && (coincidences += 1);
      });
      i += 1;
    }
    return coincidences !== 0;
  };

  isRepeatedData = (indexToCheck, data) => {
    let foundErrors = false;
    const errors = {};

    const keysToValidate = ['name'];
    if (data[indexToCheck].property !== 'static_attributes') keysToValidate.push('key');

    keysToValidate.forEach((key) => {
      if (this.findCoincidences(key, data, indexToCheck)) {
        errors[key] = <FormattedMessage id="devices.wizard.repeated.data" />;
        foundErrors = true;
      }
    });

    return foundErrors ? errors : undefined;
  };

  validateProperty = (index) => {
    const { cards } = this.state;
    const repeatedData = this.isRepeatedData(index, cards);
    if (repeatedData) {
      return repeatedData;
    }
    const rawProperty = cards[index];
    const { data } = this.props;
    const constraints = {};
    const validateProperties = ['name', 'type'];

    if (rawProperty.property === 'static_attributes') {
      validateProperties.push('value');
    } else {
      // if (rawProperty.property !== COMMAND) validateProperties.push('unit');

      validateProperties.push('key');
    }

    if (data.protocol === 'SIGFOX' && rawProperty.property === 'attributes') {
      validateProperties.push('parameter');
    }

    validateProperties.forEach((prop) => {
      constraints[prop] = {
        presence: {
          allowEmpty: false,
          message: <FormattedMessage id="rules.profile.validation.empty" />,
        },
      };
      if (prop === 'name' || prop === 'key') {
        constraints[prop].format = {
          pattern: /^[a-z][a-z0-9_]*?$/,
          flags: 'i',
          message: (
            <FormattedMessage
              id="Validation.noSpecialCharacter.noSpace"
            />
          ),
        };
      }
    });

    return validate(rawProperty, constraints);
  };

  updateEntity = (payload) => {
    const { entity } = this.props;
    // eslint-disable-next-line new-cap
    const editedEntity = new entity(payload);
    editedEntity.update();
    return editedEntity;
  };

  handleOnSave = (index) => {
    const errors = index < 0 ? null : this.validateProperty(index);
    if (errors) {
      this.setState({ errors });
    } else {
      const { cards } = this.state;
      const { data, saveOnTheFly, onChange } = this.props;
      const formerEntity = clone(data);

      if (formerEntity.protocol === 'SIGFOX') {
        formerEntity[INTERNAL] = [
          {
            mapping: parseSigfox(
              cards.filter((o) => o.property === ATTRIBUTE_PROPERTY || o.property === ATTRIBUTE),
            ),
          },
        ];
        formerEntity[ATTRIBUTE] = [
          ...cards
            .filter((o) => o.property === ATTRIBUTE_PROPERTY || o.property === ATTRIBUTE)
            .map((a) => {
              const o = a;
              delete o.property;
              delete o.size;
              if (o.unit === '') o.unit = null;
              return o;
            }),
        ];
        formerEntity[STATIC] = [
          ...cards
            .filter((o) => o.property === STATIC_PROPERTY || o.property === STATIC)
            .map((a) => {
              const o = a;
              delete o.property;
              delete o.size;
              if (o.unit === '') o.unit = null;
              return o;
            }),
        ];
      } else {
        formerEntity[ATTRIBUTE] = [
          ...cards
            .filter((o) => o.property === ATTRIBUTE_PROPERTY || o.property === ATTRIBUTE)
            .map((a) => {
              const o = a;
              delete o.property;
              if (o.unit === '') o.unit = null;
              return o;
            }),
        ];
        formerEntity[LAZY] = [
          ...cards
            .filter((o) => o.property === LAZY_PROPERTY || o.property === LAZY)
            .map((a) => {
              const o = a;
              delete o.property;
              if (o.unit === '') o.unit = null;
              return o;
            }),
        ];
        formerEntity[COMMAND] = [
          ...cards
            .filter((o) => o.property === COMMAND_PROPERTY || o.property === COMMAND)
            .map((a) => {
              const o = a;
              delete o.property;
              if (o.unit === '') o.unit = null;
              o.available_commands = o.value;
              return o;
            }),
        ];
        formerEntity[STATIC] = [
          ...cards
            .filter((o) => o.property === STATIC_PROPERTY || o.property === STATIC)
            .map((a) => {
              const o = a;
              delete o.property;
              if (o.unit === '') o.unit = null;
              return o;
            }),
        ];
      }

      const activeCards = this.initActiveCards(cards);

      const editedEntity = saveOnTheFly ? this.updateEntity(formerEntity) : formerEntity;

      onChange(formerEntity);

      const properties = this.getProperties(editedEntity);

      this.setState({
        saved: clone(properties),
        cards: clone(properties),
        activeCards,
        errors: {},
        dataOptions: [],
      });
    }
  };

  handleOnDelete = (index) => {
    const { cards } = this.state;
    const currentCards = [...cards];
    currentCards.splice(index, 1);

    this.setState({ cards: currentCards }, () => this.handleOnSave(-1));
  };

  handleOnEditStatusChange = (value, currentIndex) => {
    const { saved } = this.state;
    let { cards, activeCards } = this.state;
    let index = currentIndex;

    if (activeCards.some((c) => c === true)) {
      if (cards.length > saved.length) {
        index -= 1;
      }
      cards = clone(saved);
    }
    activeCards = this.initActiveCards(saved);
    activeCards[index] = true;
    this.setState({
      cards,
      activeCards,
      errors: {},
      dataForSelects: this.getData(value),
    });
  };

  handleOnCancel = () => {
    const { saved } = this.state;
    const activeCards = this.initActiveCards(saved);
    this.setState({
      cards: clone(saved),
      errors: {},
      activeCards,
    });
  };

  handleFieldChange = (payload) => {
    const { index, id, value } = payload;
    const { actions } = this.state;
    const currentActions = [...actions];
    if (currentActions[index].type === 'email') {
      if (id !== 'template') {
        currentActions[index].parameters[id] = value;
      } else {
        currentActions[index][id] = value;
      }
    } else {
      currentActions[index][id] = value;
    }
    this.setState({
      actions: currentActions,
    });
  };

  createSimpleProperty = (property) => {
    const protocol = this.getModelOfData();

    const attributeType = protocol.attributes.find((a) => a.key === property);

    const simpleProperty = {};
    attributeType.properties.forEach((p) => {
      simpleProperty[p] = '';
    });
    simpleProperty.property = property;

    return simpleProperty;
  };

  handleOnClickAddButton = (type) => {
    const { saved } = this.state;
    let { cards, activeCards } = this.state;

    if (activeCards.some((c) => c === true)) {
      cards = clone(saved);
    }
    activeCards = this.initActiveCards(cards);
    const property = this.createSimpleProperty(type);
    cards = [property, ...cards];
    activeCards = [true, ...activeCards];

    this.setState({
      cards,
      activeCards,
      dataForSelects: this.getData(property),
    });
  };

  handleSelectChange = (index, name, value) => {
    const { cards } = this.state;
    if (JSON.stringify(cards[index][name]) !== JSON.stringify(value)) {
      cards[index][name] = value;
      this.setState({ cards });
    }
  };

  isEditable = () => {
    const {
      saveOnTheFly, permissionToEdit,
      data, entityType,
    } = this.props;
    // if this is false, component is not working in creating/wizard mode.
    if (!saveOnTheFly) return true;
    return (
      permissionToEdit
      && ((data.protocol !== 'SIGFOX' && data.protocol !== 'LORA')
        || entityType === 'templates'));
  };

  getAttributesByWebSocket = (message) => {
    if (message.charAt(0) === '{') {
      const jsonMessage = JSON.parse(message);
      if (!jsonMessage.message) {
        this.setState({
          realTimeValues: jsonMessage,
        });
      }
    }
  };

  getCurrentValue = (attribute) => {
    const { realTimeValues } = this.state;
    const values = realTimeValues && realTimeValues[attribute.name];
    if (values) {
      if (values.value) {
        return values.value;
      }
      return values;
    }
    return '-';
  };

  getType = (index, key, attribute) => {
    const { dataForSelects, cards } = this.state;

    if (attribute.property === 'command_attributes' && key === 'value') {
      return 'multiselect';
    }

    return (dataForSelects[key]
      && dataForSelects[key][attribute.property])
      || (dataForSelects[key]
        && dataForSelects[key][cards[index].type])
      ? 'select'
      : 'text';
  };

  getOptions = (index, key, attribute) => {
    const { dataForSelects, cards } = this.state;

    if (dataForSelects[key]?.[attribute.property]) {
      return dataForSelects[key][attribute.property];
    }

    return dataForSelects[key]?.[cards[index].type] ?? null;
  }

  isDisabled = (index, key, attribute) => {
    const { cards } = this.state;
    const modelOfAttribute = this.getModelOfData().attributes.find(
      (a) => a.key === attribute.property || a.value === attribute.property,
    );
    if (modelOfAttribute.subtypes && modelOfAttribute.subtypes.includes(key)) {
      return !modelOfAttribute.type.some((t) => t.value === cards[index].type
        && t.subtype && t.subtype.some((sub) => sub.value === key));
    }
    return false;
  };

  isValueCommand = (typeAttribute, key) => typeAttribute === 'command_attributes' && key === 'value';

  addValueCommandOptions = (index, option) => {
    const { dataOptions, cards } = this.state;
    const currentDataOptions = [...dataOptions, option];
    const currentCards = [...cards];

    currentCards[index].value = [...currentCards[index].value, option];

    this.setState({ cards: currentCards, dataOptions: currentDataOptions });
  };

  getInfoCard = (key, onEdit, attribute, index, intl, errors, dataOptions) => {
    if (key === 'property') {
      return getNameAttributeTranslate(attribute[key]);
    }
    if (!onEdit && key !== 'data') {
      return Array.isArray(attribute[key])
        ? attribute[key].map((o) => o.name).toString() : attribute[key];
    }

    return key === 'data' ? null : (
      <ValueField
        onChange={(event, value) => this.handleSelectChange(
          index,
          event.target ? event.target.name : event,
          event.target ? event.target.value : value,
        )}
        id={key}
        placeholder={intl.formatMessage({ id: `Attribute.model.${key}` })}
        name={key}
        value={attribute[key]}
        intl={intl}
        errors={errors[key]}
        type={this.getType(index, key, attribute)}
        options={this.getOptions(index, key, attribute)}
        disabled={this.isDisabled(index, key, attribute)}
        selectedOptions={
          this.isValueCommand(attribute.property, key) ? attribute.value : []
        }
        data={dataOptions}
        mappedBy="name"
        footer={this.isValueCommand(attribute.property, key)
          ? Footer(true,
            (option) => this.addValueCommandOptions(index, option)) : null}
      />
    );
  }

  render() {
    const {
      cards, activeCards, errors, dataOptions,
    } = this.state;
    const {
      intl, entityType, realTimeEnabled, stringTranslationHeader, data,
    } = this.props;

    return (
      <div className="tabSection">
        <div className="actionTypeInfo d-flex justify-content-end align-items-center">
          {realTimeEnabled && (
            <CustomWebSocket
              onMessage={this.getAttributesByWebSocket}
              devicesId={data.device_id}
            />
          )}
          {!this.isEditable() ? null : (
            <ActionMenu
              onClick={this.handleOnClickAddButton}
              attributes={this.getModelOfData().attributes}
            />
          )}
        </div>
        {cards.map((attribute, index) => (
          <EditableCard
            style={{ zIndex: cards.length - index }}
            data={cards}
            isEditable={this.isEditable()}
            onSave={() => this.handleOnSave(index)}
            onEdit={activeCards[index]}
            onDelete={() => this.handleOnDelete(index)}
            stringTranslationHeader={stringTranslationHeader}
            name={attribute.name}
            onCancel={this.handleOnCancel}
            className={attribute.type}
            key={'editableCard'.concat(index)}
            editStatusChange={(value) => this.handleOnEditStatusChange(value, index)}
            render={(onEdit) => (
              <>
                {Object.keys(attribute)
                  .filter((o) => o !== 'id')
                  .map((key, i) => (
                    <dl key={'dl-'.concat(i)}>
                      <dt className="col-md-5">
                        {key === 'property' ? (
                          <FormattedMessage
                            id={`${entityType}.profile.${key}`}
                          />
                        ) : (
                          key !== 'data' && <FormattedMessage id={`Attribute.model.${key}`} />
                        )}
                      </dt>
                      <dd className="col-md-7">
                        {this.getInfoCard(key, onEdit, attribute, index, intl, errors, dataOptions)}
                      </dd>
                    </dl>
                  ))}
                {attribute.property !== 'static_attributes'
                    && realTimeEnabled
                    && !onEdit && (
                      <dl>
                        <dt className="col-md-5">
                          <FormattedMessage
                            id="devices.profile.current.value"
                          />
                        </dt>
                        <dd
                          className="col-md-7 incomingResult"
                          key={this.getCurrentValue(attribute)}
                        >
                          {this.getCurrentValue(attribute)}
                        </dd>
                      </dl>
                )}
              </>
            )}
          />
        ))}
      </div>
    );
  }
}

EditProperties.propTypes = {
  saveOnTheFly: PropTypes.bool,
  onChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  realTimeEnabled: PropTypes.bool,
  historicals: PropTypes.arrayOf(PropTypes.any),
  entity: PropTypes.func.isRequired,
  permissionToEdit: PropTypes.bool,
  entityType: PropTypes.string,
  stringTranslationHeader: PropTypes.string,
  intl: PropTypes.func.isRequired,
};

EditProperties.defaultProps = {
  realTimeEnabled: false,
  saveOnTheFly: true,
  historicals: [],
  onChange: () => {},
  permissionToEdit: false,
  entityType: '',
  stringTranslationHeader: '',
};

const mapStateToProps = (state, props) => {
  const entityType = props.entity.entityName.toLowerCase().concat('s');
  return {
    allEntities: state
      .get(entityType)
      .get('list')
      .toJS(),
    entityType,
    historicals: state.get('historicals').get('historicalList'),
  };
};

export default connect(mapStateToProps, {})(injectIntl(EditProperties));
