/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import ExpansionPanel from '../../../../../../components/ExpansionPanel';
import TrayItemWidget from '../../../../../ETLProcedures/ETLEditor/Tools/components/TrayItemWidget';
import TrayWidget from '../../../../../ETLProcedures/ETLEditor/Tools/components/TrayWidget';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext/index';
import Menu from '../../../../../../components/Menu';
import OptionsForRow from './OptionsForRows';
import './styles.scss';

const AttributesPanel = (device, handleOnClickSource) => {
  const trayItemWidget = (attribute, props) => (
    <TrayItemWidget name={attribute.name} onClick={props.onClick} />
  );

  return (
    <TrayWidget>
      {
         device.attributes.map((attribute) => (
           <Menu
             openComponent={(props) => trayItemWidget(attribute, props)}
             openFrom="top right"
           >
             <OptionsForRow
               className="optionsForRow"
               rowContent={{ device, attribute }}
               handleOnClickSource={handleOnClickSource}
             />
           </Menu>
         ))
       }
    </TrayWidget>
  );
};

const DeviceExpansionPanelHeader = (device) => (
  <Container fluid>
    {device.device_id}
  </Container>
);

const ExpansionPanelDevices = ({ devices, handleOnClickSource }) => {
  const getBody = (device, handleOnClickSource2) => AttributesPanel(device, handleOnClickSource2);

  return (
    devices.map((device) => (
      <ExpansionPanel
        onOpen={() => {}}
        header={() => DeviceExpansionPanelHeader(device)}
        content={() => getBody(device, handleOnClickSource)}
        opened
      />
    ))
  );
};

const ExpansionPanelMainHeader = () => (
  <Container fluid>
    <FormattedMessage component={false} id="kpis.wizard.step5.arithmetical.attributes.selector.title" />
  </Container>
);

export default function SourceSelectorComponent(devices, handleOnClickSource) {
  const panelDevices = ExpansionPanelDevices(devices, handleOnClickSource);
  return (
    <div className="sourceSelector">
      <ExpansionPanel
        onOpen={() => {}}
        header={ExpansionPanelMainHeader}
        content={() => panelDevices}
        styleclass="main-tool-header"
        opened
      />
    </div>
  );
}

AttributesPanel.propTypes = {
  onClick: PropTypes.func,
};

AttributesPanel.defaultProps = {
  onClick: undefined,
};
