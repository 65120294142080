import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import CommonView from '../../CommonView';
import { getUsers } from '../../../services/redux/users/actions';
import { FormattedMessage } from '../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../Contexts/WizardStepContext';

import { default as NewAssignPermissions } from '../AssignPermissions';

class AssignPermissions extends CommonView {
  constructor(props) {
    super({ props });
    this.state = {};
  }

  prevSteps = () => (this.props.prevStep ? this.props.prevStep() : undefined);

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.users !== this.props.users || prevProps.usersGroups !== this.props.usersGroups) this.getMembers();
  }

  getMembers = () => {
    if (this.props.newEntity.permissions_policy) {
      const { users, groups } = this.props.newEntity.permissions_policy;
      const userOwner = this.props.newEntity.owner;

      const usersId = users.map((u) => u.id);
      const usersWithPermissions = this.props.users.filter((o) => usersId.includes(o.id));

      const usersGroupsId = groups.map((u) => u.id);
      const usersGroupsWithPermissions = this.props.usersGroups.filter((o) => usersGroupsId.includes(o.id));

      const owner = this.props.users.find((o) => o.id === userOwner);

      this.setState({
        usersWithPermissions,
        usersGroupsWithPermissions,
        userOwner: owner,
      });
    } else {
      this.setState({
        usersWithPermissions: [],
        usersGroupsWithPermissions: [],
        userOwner: undefined,
      });
    }
  };

  render() {
    const { usersWithPermissions, usersGroupsWithPermissions, userOwner } = this.state;

    return (
      <>
        {/* <div className="titulo">
          <Container>
            <FormattedMessage id={`${this.props.entityType}.wizard.assign.permissions`} />
            <i className="uil uil-question-circle" />
          </Container>
        </div> */}
        <Container>
          <Row>
            <Col>
              <NewAssignPermissions
                {...this.props}
                userOwner={userOwner}
                usersWithPermissions={usersWithPermissions}
                usersGroupsWithPermissions={usersGroupsWithPermissions}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const entityType = props.entity.entityName.toLowerCase().concat('s');
  return {
    usersGroups: state.get('usersGroups').get('list').toJS(),
    users: state.get('users').get('list').toJS(),
    userMe: state.get('users').get('userMe'),
    entityType,
  };
};

export default connect(
  mapStateToProps,
  { getUsers },
)(withWizardStep(AssignPermissions));
