import React from 'react'
import Styled, {withTheme} from 'styled-components'
import _DEFAULT_COLORS from "../../helpers/colors"
import PropTypes from "prop-types"

const Bar = Styled('div')`
  background: ${p=>p.background};
  color: ${p=>p.color};
  position: ${p=>p.position};

  display: flex;
  align-items: center;
  
  ${p=>p.centered && `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
  
  ${p=>p.reverse && `
    flex-direction: row-reverse;
  `}
  
  ${p=>p.css}
`;

const Default = (props)=> <Bar {...props}/>;

Default.defaultProps = {
  minHeight: {value: 60, unit: 'px'},
  centered: true,
  position:'relative',
  reverse: false,
  background: _DEFAULT_COLORS.default.light,
  color: _DEFAULT_COLORS.default.dark ,
  css: ``,
};

Default.propTypes = {

  /** Defines the minimum size of the Bar element. Attention to the value of the shape*/
  minHeight: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),

  /** If it is in FALSE it will sort the elements in a natural way. If it is in TRUE it will show them distributed equitably with Flex */
  centered: PropTypes.bool,

  /** If it is in FALSE it orders from left to right the children of the element bar.
   * If it is in TRUE, it will position from right to left.*/
  reverse: PropTypes.bool,

  /** Accepts any usual string in the native property position of css */
  position: PropTypes.string,

  /** Changes the background color of the component. Accepts colors by names (css standards), HEX code and RGBA code.*/
  background: PropTypes.string,

  /** Changes the text color of the component. Accepts colors by names (css standards), HEX code and RGBA code.*/
  color: PropTypes.string,

  /** You can modify any of the existing properties by passing css through string*/
  css: PropTypes.string,

};

export { Default };
export default withTheme( Default );