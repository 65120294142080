import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import RemoveHoc from '../../../helpers/RemoveComponentSimplify';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { TextField, TimeSelect } from './helpers/helpers';

const TimeRuleEditable = ({
  intl, onEdit, onDelete, condition, updateCondition, errors,
}) => {
  const [state, setState] = useState({
    unit: condition.timeWindow.unit || '',
    repeat: condition.timeWindow.repeat || '',
    interval: condition.timeWindow.interval || '',
  });
  const ButtonOpenRemove = ({ showModal }) => <i className="uil uil-times" onClick={showModal} />;
  const RemoveDOM = RemoveHoc(ButtonOpenRemove);

  const TIME_UNITS_OPTIONS = [
    { name: <FormattedMessage id="rules.wizard.time.unit.seconds" />, value: 'sec' },
    { name: <FormattedMessage id="rules.wizard.time.unit.minutes" />, value: 'minute' },
    { name: <FormattedMessage id="rules.wizard.time.unit.hours" />, value: 'hour' },
    { name: <FormattedMessage id="rules.wizard.time.unit.days" />, value: 'days' },
    { name: <FormattedMessage id="rules.wizard.time.unit.weeks" />, value: 'weeks' },
  ];

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const setTimeWindow = () => {
    const conditionCopy = { ...condition };
    const timeWindow = {
      repeat: state.repeat,
      interval: state.interval,
      unit: state.unit,
    };
    conditionCopy.timeWindow = timeWindow;
    updateCondition(conditionCopy);
  };

  useEffect(() => {
    setTimeWindow();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="d-flex andConditionWrapper">
      <div className="editableCard occurCondition">
        <RemoveDOM
          onDelete={onDelete}
          stringTranslationHeader="rules.profile.occur"
        />
        <div className="editableCard-body">
          <div className="occurBtn">
            <FormattedMessage id="rules.occurs" />
          </div>
          <div className="d-flex flex-direction-row align-items-center">
            <dl className="valueCont col-4">
              <dt className="col-2">
                <FormattedMessage id="rules.times" />
              </dt>
              <dd className="col col-md-8">
                {!onEdit ? (
                  <div>{state.repeat}</div>
                ) : (
                  <TextField
                    intl={intl}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                    value={state.repeat}
                    type="number"
                    errors={errors.repeat}
                    dataName="repeat"
                  />
                )}
              </dd>
            </dl>
            <dl className="timeCont col-8">
              <dt className="col-2">
                <FormattedMessage id="rules.time" />
              </dt>
              {!onEdit ? (
                <dd className="col col-md-7">{`${state.interval} ${intl.formatMessage({ id: `rules.profile.time.unit.${state.unit}` })}`}</dd>
              ) : (
                <>
                  <dd className="col col-md-4">
                    <TextField
                      intl={intl}
                      onChange={({ target: { name, value } }) => handleChange(name, value)}
                      value={state.interval}
                      type="number"
                      errors={errors.interval}
                      dataName="interval"
                    />
                  </dd>
                  <dd className="col col-md-6">
                    <TimeSelect
                      options={TIME_UNITS_OPTIONS}
                      intl={intl}
                      onChange={(name, value) => handleChange(name, value)}
                      value={state.unit}
                      errors={errors.unit}
                    />
                  </dd>
                </>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TimeRuleEditable);
