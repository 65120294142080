import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withTheme } from '../../../Contexts/ThemeContext';
import Finder from '../../../components/Finder';
import Pagination from '../../../components/Pagination';
import TabComponent from '../../../components/Tab';
import PrivateComponent from '../../../components/PrivateComponent/privateComponent';

import {
  getMineDashboards,
  getDashboards,
  clearDashboardState,
} from '../../../services/redux/dashboards/actions';
import Dashboard, { SampleDashboard } from '../../../models/Dashboard/index';
import WithDrawer from '../../../components/DrawerContainer';
import CreateDashboard from '../Add/AddWizard';
import RemoveHoc from '../../helpers/RemoveComponent';
// if we put Profile between brackets, we cannot edit the description
// eslint-disable-next-line import/no-named-as-default
import Profile from '../Profile';
import CardList from '../CardList/CardList';
import { FormattedMessage } from '../../../Contexts/LanguageContext/index';
import FiltersFinder from '../../helpers/FiltersFinder';

const RemoveComponent = RemoveHoc(
  ({ showModal }) => (
    <i
      onClick={showModal}
      className="uil uil-trash-alt remove"
      onKeyPress={() => {}}
      role="button"
      aria-label=" "
      tabIndex="0"
    />
  ),
  { class: Dashboard, nameData: 'dashboards' },
);

const ProfileComponent = WithDrawer(({ onChange, data, profileType }) => (
  <Profile onChange={onChange} data={data} profileType={profileType} />
));
const headers = [];
const filters = [];

['name', 'description'].forEach((index) => {
  headers.push({
    label: SampleDashboard.translations[index],
    key: index,
  });
  filters.push({
    name: SampleDashboard.translations[index],
    key: index,
    value: index,
  });
});

headers.unshift({ label: '', key: 'dashboardAddOn', type: 'dashboardAddOn' });

const TablePaginated = Pagination(({ data, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CardList {...rest} dashboards={data} />));

const FindableTabsComponent = Finder((props) => {
  const {
    data,
    theme,
    itemsPerPage,
    page,
    optionsForRow,
    onPageChange,
    onSubmit,
    onRowClick,
    typeOfData,
    itemsShowed,
    total,
    clickView,
    ...rest
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TabComponent {...rest}>
      <TablePaginated
        optionsForRow={optionsForRow}
        data={data}
        header={headers}
        align="left"
        filters={headers}
        onSubmit={onSubmit}
        onPageChange={onPageChange}
        itemsPerPage={itemsPerPage}
        page={page}
        total={total}
        expandable
        itemsShowed={itemsShowed}
        typeOfData={typeOfData}
        selectable
        onRemove={RemoveComponent}
        onRowClick={onRowClick}
        clickView={clickView}
        entity={Dashboard}
      />
      <PrivateComponent
        checkLogin
        checkPermission
        microsService="/app/dashboard"
        permission="list-mine"
      >

        <TablePaginated
          optionsForRow={optionsForRow}
          data={data}
          header={headers}
          align="left"
          filters={headers}
          onSubmit={onSubmit}
          onPageChange={onPageChange}
          itemsPerPage={itemsPerPage}
          page={page}
          total={total}
          expandable
          itemsShowed={itemsShowed}
          typeOfData="dashboards"
          selectable
          onRemove={RemoveComponent}
          onRowClick={onRowClick}
          clickView={clickView}
        />
      </PrivateComponent>
    </TabComponent>
  );
}, CreateDashboard, FiltersFinder);

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      itemsPerPage: 8,
      profileOpened: false,
      selectedtab: 0,
      itemsShowed: 0,
      selectedFilter: filters[0],
      selectedValueForFilter: '',
    };
  }

  componentDidMount() {
    const { page, itemsPerPage, selectedFilter } = this.state;
    getDashboards({ filters: { [selectedFilter.key]: '' }, page, size: itemsPerPage });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedtab, selectedFilter, itemsPerPage, selectedValueForFilter,
    } = this.state;

    if (selectedtab !== prevState.selectedtab) {
      this.updateState(selectedFilter, selectedValueForFilter, itemsPerPage);
    }
  }

  handleFilteredSearch = (value, filter) => {
    const { itemsPerPage } = this.state;
    if (filter && filter.key) {
      clearDashboardState();
      this.selectDataSource({ [filter.key]: value }, 1, itemsPerPage, true);
      this.setState({
        selectedFilter: filter,
        selectedValueForFilter: value,
        page: 1,
      });
    }
  };

  handleOnSubmit = (filtersArg) => {
    const { page, itemsPerPage } = this.state;
    getDashboards({ filtersArg, page, size: itemsPerPage });
  };

  handleOnProfileEvent = (opened) => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = (data, profileType) => {
    this.setState({
      profileOpened: true,
      activeDashboard: data,
      profileType,
    });
  };

  handleOnPageChange = (page) => {
    const { selectedValueForFilter, selectedFilter, itemsPerPage } = this.state;
    this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter }, page, itemsPerPage);
    this.setState({
      page,
    });
  };

  // eslint-disable-next-line no-shadow
  selectDataSource = (filters, page, size, init = false) => {
    const { selectedtab } = this.state;
    switch (selectedtab) {
      case 0:
        if (init) {
          clearDashboardState();
        }
        getDashboards({ filters, page, size });
        break;
      case 1:
        if (init) {
          clearDashboardState();
        }
        getMineDashboards({ filters, page, size });
        break;
      default:
        break;
    }
  }

  handleOnTabsChange = (selectedtabArg) => {
    const { selectedtab } = this.state;
    if (selectedtab !== selectedtabArg) {
      this.setState({
        selectedtab: selectedtabArg,
      });
    }
  };

  updateState(selectedFilter, selectedValueForFilter, itemsPerPage) {
    this.setState({
      page: 1,
    }, () => this.selectDataSource({ [selectedFilter.key]: selectedValueForFilter || '' }, 1, itemsPerPage, true));
  }

  render() {
    const {
      path, theme, dashboards, fetching, total,
    } = this.props;
    const {
      itemsPerPage,
      selectedtab,
      profileOpened,
      activeDashboard,
      page,
      itemsShowed,
      selectedFilter,
      profileType,
    } = this.state;
    const dashboardsByData = dashboards.map((dashboard) => dashboard.toJson());

    return (
      <>
        <FindableTabsComponent
          titles={[<FormattedMessage id="dashboards.list.tab.dashboards" />,
            <PrivateComponent
              checkLogin
              checkPermission
              microsService="/app/dashboard"
              permission="list-mine"
            >
              <FormattedMessage id="dashboards.list.tab.mydashboards" />
              {' '}

            </PrivateComponent>]}
          onChange={this.handleOnTabsChange}
          selectedtab={selectedtab}
          fetching={fetching}
          theme={theme}
          data={dashboardsByData}

          page={page}
          total={total}
          expandable
          onPageChange={this.handleOnPageChange}
          itemsShowed={itemsShowed}
          itemsPerPage={itemsPerPage}
          onRowClick={this.handleOpenProfile}
          typeOfData="dashboards"
          clickView={this.handleOpenProfile}
          filters={filters}
          externalFilter={this.handleFilteredSearch}
          selectedFilter={selectedFilter}
        />

        {profileOpened && activeDashboard && (
          <ProfileComponent
            onChange={this.handleOnProfileEvent}
            data={activeDashboard}
            path={path}
            profileType={profileType}
          />
        )}
      </>
    );
  }
}

List.propTypes = {
  path: PropTypes.string,
  theme: PropTypes.objectOf(PropTypes.any),
  dashboards: PropTypes.arrayOf(PropTypes.any),
  fetching: PropTypes.bool,
  total: PropTypes.number,
};

List.defaultProps = {
  path: '',
  theme: {},
  dashboards: [],
  fetching: undefined,
  total: 0,
};

const mapStateToProps = (state) => ({
  dashboards: state.get('dashboards').get('list').toJS(),
  total: state.get('dashboards').get('total'),
  fetching: state.get('dashboards').get('fetching'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(injectIntl(List)));
