import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import './styles.scss';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Widget from '../../../../models/Widget';
import Dashboard from '../../../../models/Dashboard/index';
import CreateNumericWidget from '../AddNumericWidgetToImage/AddWizard';
import { getDefaultMeasure } from '../../WidgetDefaultMeasures';
import { getWidgetModel } from '../../../../models/WidgetV2/utils';

const CreateParametrizedTextWidget = ({ dashboard, intl, widgetImageId }) => {
  const updateDashboard = (widget) => {
    const dashboardToUpdate = new Dashboard(R.clone(dashboard));
    if (!dashboardToUpdate.layoutProperties) {
      dashboardToUpdate.layoutProperties = {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: [],
      };
    }
    const defaultMeasures = getDefaultMeasure(
      widget,
      dashboardToUpdate.layoutProperties,
    );

    ['lg', 'md', 'sm', 'xs', 'xxs'].forEach((size) => {
      if (dashboardToUpdate.layoutProperties[size]) {
        dashboardToUpdate.layoutProperties[size].push(defaultMeasures[size]);
      } else {
        (dashboardToUpdate.layoutProperties[size] = [defaultMeasures[size]]);
      }
    });

    dashboardToUpdate.widgets.push(widget.id);
    dashboardToUpdate.update();
  };

  const handleClick = () => {
    const { permissions_policy } = dashboard.widgets.find((w) => w.id === widgetImageId);
    const newWidget = {
      name: 'Default',
      description: 'Default',
      widgetType: 'PARAMETRIZED_TEXT',
      container: widgetImageId,
      permissions_policy,
      config: {
        content: intl.formatMessage({ id: 'widget.parametrizedText.defaultValue' }),
        parameters: [],
      },
    };
    const createdWidget = getWidgetModel(newWidget);
    createdWidget.save(updateDashboard);
  };

  return (
    <p
      onClick={handleClick}
      role="presentation"
    >
      <FormattedMessage id="widgetImage.button.addWidget.paragraph" />
    </p>
  );
};

CreateParametrizedTextWidget.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
  intl: PropTypes.objectOf(PropTypes.any),
};

CreateParametrizedTextWidget.defaultProps = {
  dashboard: {},
  intl: {},
};

class OptionsForWidgetImageAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dashboard, intl, widgetImageId } = this.props;
    return (
      <div className="tableMenu">
        <ul className="list-unstyled">
          <li><CreateNumericWidget dashboard={dashboard} widgetImageId={widgetImageId} /></li>
          <li>
            <CreateParametrizedTextWidget
              dashboard={dashboard}
              intl={intl}
              widgetImageId={widgetImageId}
            />
          </li>
        </ul>
      </div>
    );
  }
}

OptionsForWidgetImageAddButton.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
};

OptionsForWidgetImageAddButton.defaultProps = {
  dashboard: {},
};

export default injectIntl(OptionsForWidgetImageAddButton);
