import React, { useState, useCallback } from 'react';

export const DashboardContext = React.createContext({});

const { Provider } = DashboardContext;

export default function DashboardProvider(props) {
  const [activeWidget, setActiveWidget] = useState(null);
  const [profileWidgetOpened, setProfileWidgetOpened] = useState(null);
  const [profileWidgetType, setProfileWidgetType] = useState(null);
  const [widgetList, setWidgetList] = useState([]);
  const [historical, setHistorical] = useState([]);
  const [isProfileOpenedWithLinked, setIsProfileOpenedWithLinked] = useState(false);

  const handleOpenProfileWidget = useCallback((widget, profileTypeVar) => {
    if (widget.container) {
      setIsProfileOpenedWithLinked(widgetList.find((w) => w.id === widget.container && w.widgetType === 'LINKED'));
    }
    if (isProfileOpenedWithLinked && !widget.container) {
      setIsProfileOpenedWithLinked(false);
    }
    setProfileWidgetOpened(true);
    setActiveWidget(widget);
    setProfileWidgetType(profileTypeVar);
  }, [
    setProfileWidgetOpened,
    setActiveWidget,
    setProfileWidgetType,
    widgetList,
    isProfileOpenedWithLinked,
  ]);

  const { children } = props;

  return (
    <Provider
      value={
        {
          activeWidget,
          setActiveWidget,
          widgetList,
          setWidgetList,
          profileWidgetOpened,
          setProfileWidgetOpened,
          profileWidgetType,
          setProfileWidgetType,
          handleOpenProfileWidget,
          historical,
          setHistorical,
          isProfileOpenedWithLinked,
        }
      }
    >
      {children}
    </Provider>
  );
}
