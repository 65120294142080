export const ADD_RULE_REQUEST = 'ADD_RULE_REQUEST';
export const ADD_RULE_SUCCESS_FLAG = 'ADD_RULE_SUCCESS_FLAG';
export const ADD_RULE_ERROR = 'ADD_RULE_ERROR';
export const ADD_RULE_SUCCESS = 'ADD_RULE_SUCCESS';

export const MODIFY_RULE_REQUEST = 'MODIFY_RULE_REQUEST';
export const MODIFY_RULE_SUCCESS_FLAG = 'MODIFY_RULE_SUCCESS_FLAG';
export const MODIFY_RULE_SUCCESS = 'MODIFY_RULE_SUCCESS';
export const MODIFY_RULE_ERROR = 'MODIFY_RULE_ERROR';

export const MODIFY_RULE_ENGINE_REQUEST = 'MODIFY_RULE_ENGINE_REQUEST';
export const MODIFY_RULE_ENGINE_SUCCESS_FLAG = 'MODIFY_RULE_ENGINE_SUCCESS_FLAG';
export const MODIFY_RULE_ENGINE_SUCCESS = 'MODIFY_RULE_ENGINE_SUCCESS';
export const MODIFY_RULE_ENGINE_ERROR = 'MODIFY_RULE_ENGINE_ERROR';

export const MODIFY_RULE_PERMISSIONS_REQUEST = 'MODIFY_RULE_PERMISSIONS_REQUEST';
export const MODIFY_RULE_PERMISSIONS_SUCCESS_FLAG = 'MODIFY_RULE_PERMISSIONS_SUCCESS_FLAG';
export const MODIFY_RULE_PERMISSIONS_SUCCESS = 'MODIFY_RULE_PERMISSIONS_SUCCESS';
export const MODIFY_RULE_PERMISSIONS_ERROR = 'MODIFY_RULE_PERMISSIONS_ERROR';

export const MODIFY_RULE_CRON_REQUEST = 'MODIFY_RULE_CRON_REQUEST';
export const MODIFY_RULE_CRON_SUCCESS_FLAG = 'MODIFY_RULE_CRON_SUCCESS_FLAG';
export const MODIFY_RULE_CRON_SUCCESS = 'MODIFY_RULE_CRON_SUCCESS';
export const MODIFY_RULE_CRON_ERROR = 'MODIFY_RULE_CRON_ERROR';

export const DELETE_RULE_REQUEST = 'DELETE_RULE_REQUEST';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_ERROR = 'DELETE_RULE_ERROR';

export const GET_RULE = 'GET_RULE';
export const GET_RULE_REQUEST = 'GET_RULE_REQUEST';
export const GET_RULE_ROLES_REQUEST = 'GET_RULE_ROLES_REQUEST';
export const GET_RULE_SUCCESS = 'GET_RULE_SUCCESS';
export const GET_RULE_ERROR = 'GET_RULE_ERROR';

export const GET_RULES_REQUEST = 'GET_RULES_REQUEST';
export const GET_RULES_ERROR = 'GET_RULES_ERROR';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';

export const CLEAR_RULES_STATE = 'CLEAR_RULES_STATE';

// ACTIONS
export const ADD_RULE_ACTION_REQUEST = 'ADD_RULE_ACTION_REQUEST';
export const ADD_RULE_ACTION_SUCCESS = 'ADD_RULE_ACTION_SUCCESS';
export const ADD_RULE_ACTION_ERROR = 'ADD_RULE_ACTION_ERROR';

export const UPDATE_RULE_ACTION_REQUEST = 'UPDATE_RULE_ACTION_REQUEST';
export const UPDATE_RULE_ACTION_SUCCESS = 'UPDATE_RULE_ACTION_SUCCESS';
export const UPDATE_RULE_ACTION_ERROR = 'UPDATE_RULE_ACTION_ERROR';

export const DELETE_RULE_ACTION_REQUEST = 'DELETE_RULE_ACTION_REQUEST';
export const DELETE_RULE_ACTION_SUCCESS = 'DELETE_RULE_ACTION_SUCCESS';
export const DELETE_RULE_ACTION_ERROR = 'DELETE_RULE_ACTION_ERROR';

// STATUS

export const MODIFY_RULE_STATUS_REQUEST = 'MODIFY_RULE_STATUS_REQUEST';
export const MODIFY_RULE_STATUS_SUCCESS = 'MODIFY_RULE_STATUS_SUCCESS';
export const MODIFY_RULE_STATUS_ERROR = 'MODIFY_RULE_STATUS_ERROR';


export const ERRORFETCHING_RULE_RESET = 'ERRORFETCHING_RULE_RESET';
