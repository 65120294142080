/* eslint-disable import/prefer-default-export */
import Faker from 'faker';
import { range } from 'ramda';
import { reportManagement } from '../../configuration/config';

import { requestAPI } from '../../helpers';
import parseFilters from '../../helpers/parseFilters';

const endPoint = reportManagement.urlAPI.concat(reportManagement.paths.report);

const requestFakerAutoOK = async (params, exampleBodyFn) => {
  const { statusOK, returnData } = params;
  return ({
    status: statusOK,
    data: returnData,
  });
};

const exampleDataFn = (numberOfReportToGenerate = 50) => {
  const ReportsFaker = range(0, numberOfReportToGenerate).map(() => ({
    id: Faker.random.uuid(),
    name: Faker.random.word(),
    owner: Faker.random.uuid(),
    execution: Faker.random.word(),
    createdAt: new Date(),
    updatedAt: new Date(),
  }));
  ReportsFaker.unshift({
    updatedAt: new Date(),
    createdAt: new Date(),
    description: 'Report Descripción',
    permissions_policy: { users: [], groups: [], public: true },
    id: 'b7f723f0-22df-47fb-b210-486bcc4dd351',
    name: 'Report Muestra',
    owner: '2d8d8294-d0c5-4f67-bcd8-9ec6f8ee02af',
    onCascadeUpdate: true,

    mails: ['dasdas@asld.com'], // tus actions
    planner: { property: 'minutes', value: 49 }, // WIP ? XD

    orientation: 'vertical',
    size: 'a4', // esto supongo que estará en la pagina
  });
  return { reports: ReportsFaker };
};

const exampleDataUniqueFn = (report) => {
  const newReport = JSON.parse(report);
  const ReportsFaker = {
    ...newReport,
    id: Faker.random.uuid(),
    owner: Faker.random.uuid(),
    execution: Faker.random.word(),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return { report: ReportsFaker };
};


export const readReports = async (page, size, filters, type) => {
  const queryPage = page ? `x-query-page=${page}` : '';
  const querySize = size ? `x-query-size=${size}` : '';
  const queryFilters = filters ? parseFilters(filters) : '';
  const url = `${endPoint}/list/${type}?${queryPage}&${querySize}${queryFilters}`;
  const params = {
    endPoint: url,
    statusOK: 200,
    toJSON: true,
    returnData: 'reports',
  };

  return requestAPI(params, exampleDataFn);
};


export const readReport = async (report) => {
  if (!report.id) return { status: 301, error: 'invalid id attr' };

  const params = {
    endPoint: `${endPoint}/${report.id}`,
    statusOK: 200,
    toJSON: true,
    returnData: 'report',
  };

  const answer = await requestAPI(params, exampleDataUniqueFn);
  return answer;
};

export const addReport = async (report) => {
  const params = {
    endPoint,
    statusOK: 201,
    toJSON: true,
    returnData: 'report',
    requestConfig: {
      method: 'POST',
      body: JSON.stringify(report),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataUniqueFn);
};

export const updateReport = (report) => {
  if (!report.id) {
    return {
      status: 400,
      error: 'missing id attr',
    };
  }
  const params = {
    endPoint: `${endPoint}/${report.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: report,
    requestConfig: {
      method: 'PUT',
      body: JSON.stringify(report),
      headers: { 'Content-Type': 'application/json' },
    },
  };
  return requestAPI(params, exampleDataFn);
};


export const deleteReports = (report) => {
  if (!report.id) {
    return {
      status: 400,
      error: 'missing id attr',
    };
  }
  const params = {
    endPoint: `${endPoint}/${report.id}`,
    statusOK: 204,
    toJSON: false,
    returnData: report,
    requestConfig: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataFn);
};

export const generateReports = (report) => {
  if (!report.id) {
    return {
      status: 400,
      error: 'missing id attr',
    };
  }

  const params = {
    endPoint: `${endPoint}/generate/${report.id}`,
    statusOK: 200,
    toJSON: false,
    returnData: report,
    requestConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  return requestAPI(params, exampleDataFn);
};

const examplePage = () => {
  const PageFaker = {
    id: Faker.random.uuid(),
    report: Faker.random.uuid(),
    layoutProperties: {
      lg: [],
      md: [],
      sm: [],
    },
    widgets: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return { report: PageFaker };
};

export const addPage = () => {
  const params = {
    endPoint: `${endPoint}/page`,
    statusOK: 204,
    toJSON: false,
    returnData: 'page',
    requestConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
  // return requestAPI(params, exampleBodyFn);
  return requestFakerAutoOK(params, examplePage);
};
