/* eslint-disable import/no-cycle */
import React, { Suspense } from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { clone } from 'ramda';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import CommonView from '../../../CommonView';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import animationData from '../../../Dashboards/Show/components/Loading/dashboardLoadingLottie.json';
import { getWidgetModel } from '../../../../models/WidgetV2/utils';
import RenderWidget from '../../../Dashboards/ShowV2/components/renderWidget';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

class WidgetPreviewV2 extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      widgetType: this.props.newEntity.widgetType,
      newEntity: this.props.newEntity,
      errors: {},
    };
  }

  validate = () => {
    this.props.onContinue();
  };

  componentDidMount() {
    this.props.onRef(this);

    const { newEntity } = this.state;
    const newWidget = clone(newEntity);
    const widgetToSave = getWidgetModel(newWidget);
    widgetToSave.injectUrnAndFields(newWidget.sources);
    const injectedData = widgetToSave.injectData(widgetToSave);
    if (injectedData) widgetToSave.config = injectedData;
    widgetToSave.id = `preview-${new Date().getTime()}`;
    this.setState({
      widget: widgetToSave,
    });
  }

  render() {
    const { widget } = this.state;
    return (
      <>
        <Container>
          <div className="WizardPreview">
            <Row>
              {/* ToDo Remove class fix preview widgetMap */}
              <Col className={`preview-${widget?.widgetType?.toLowerCase()}`} lg={{ size: 8, offset: 2 }}>
                {(!widget)
                  ? (
                    <Lottie
                      className="lottie"
                      options={defaultOptions}
                      height={280}
                      width={280}
                    />
                  ) : (
                    <Suspense fallback="">
                      <RenderWidget
                        widget={widget}
                        updateWidgetLinkedMode={() => null}
                        previewMode
                      />
                    </Suspense>
                  )}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fetching: state.get('dashboards').get('fetching'),
  dashboard: state.get('dashboards').get('readDashboard'),
  errorFetching: state.get('dashboards').get('errorFetching'),
  widgetData: state.get('historicals').get('previewList'),
  devices: state.get('devices').get('devicesList'),
});

export default connect(
  mapStateToProps,
  {},
)(withWizardStep(injectIntl(WidgetPreviewV2)));
