const TableDefaultConfiguration = {
  appearance: {
    hidden: [],
    orientation: 'columns',
  },
  colors: {
    headerColor: '#FFFFFF',
    headerTableBackground: '#FFFFFF',
    headerTableColorText: '#2B334A',
  },
  conditions: { },
  effects: { },
  custom: { },
  data: {
    operation: 'last-value',
    type: 'real-time',
    sampling: '',
  },
  labels: {
    alias: { },
  },
  trendlines: { },
  values: { },
};

export default TableDefaultConfiguration;

export const TableDefaultConfigurationForLinked = {
  ...TableDefaultConfiguration,
  appearance: {
    hidden: [],
    orientation: 'rows',
  },
};

export const injectTableData = (origins, config) => {
  const newConfig = { ...config };
  origins.forEach((origin) => {
    if (origin.type !== 'KPI') { // TODO: Delete when kpi accepts joinedAttrs
      const attrs = [
        ...origin.connectedDevices.attributes,
        ...origin.connectedDevices.lazy_attributes,
        ...origin.connectedDevices.static_attributes,
      ];
      attrs.forEach((attr) => {
        if (!newConfig.labels.alias[attr]) {
          newConfig.labels.alias[attr] = attr;
        }
      });
    }
  });
  return newConfig;
};
