import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import { connect } from 'react-redux';
import { Add as BasicAdd } from '../Add/steps/Add';
import { injectIntl } from "react-intl";
import { clone } from 'ramda';
import { getRoles } from '../../../../services/redux/roles/actions'
import { addUserSuccessFlag, errorFetchingUserReset } from '../../../../services/redux/users/actions'
import User from '../../../../models/User/index'


const title = (
    <FormattedMessage
        id="users.copy.title"
        defaultMessage="Copy User"
        description="Title of Copy users"
        component={'h1'}
    />
);

const copy = (
    {
        id: "options.copy",
        defaultMessage: "copy",
        description: "Copy string"
    }
);

export class Copy extends BasicAdd {

    static defaultProps = {
        ...BasicAdd.defaultProps,
        title: title
   };
   
    componentWillMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.user) {

            const user = clone(this.props.location.state.user);

            const u = new User({ id: user.id });
            u.getUserRoles();

            delete user.id;
            delete user.password;
            
            user.name = `${user.name} - ${this.props.intl.formatMessage(copy)}`;

            this.setState({ ...user });
        }
    }

    componentDidUpdate(prevProps) {
        const index = this.props.userList.findIndex(u => u.id === this.props.location.state.user.id);
        if (index !== -1){
            const roles  = this.props.userList[index].roles;

            if(this.state.roles.length === 0 && roles !== this.state.roles){
                this.setState({ roles: roles });
            }
        }

        if (prevProps.errorFetching !== this.props.errorFetching) {
            const errFromAPI = this.props.errorFetching.errors;
            if (errFromAPI) {
                const errors = {};
                errFromAPI.forEach(err => {
                    errors[err.field] = <FormattedMessage id={err.message}/>;
                });
                this.setErrors(errors);
                errorFetchingUserReset();
            }
        }


        if(this.props.addSuccess){
            addUserSuccessFlag();
            this.goBack()
        }
    }

   
}

const mapStateToProps = (state) => ({
        userList: state.get('users').get('list').toJS(),
        allowedroles: state.get('roles').get('list').toJS(),
        //allowedgroups: ['Group1', 'Group2', 'Group3', 'Group4']
        errorFetching: state.get('users').get('errorFetching'),
        addSuccess: state.get('users').get('addSuccess')
});

export default injectIntl(connect(mapStateToProps, { getRoles })(Copy));