import React from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import WithDrawer from '../DrawerContainer/index';
import './styles.scss';
import AvatarCard from './components/AvatarCard';
import MainNav from '../MainNav';
import SecondNav from '../SecondNav';
import { logout } from '../../services/redux/auth/actions';

const MenuMobile = WithDrawer(
  ({
    userMe, onChange, menuElementsSelected, hiddenSubmenu, onProfileClick,
  }) => (
    <>
      <AvatarCard userMe={userMe} onCloseMenu={onChange} onProfileClick={onProfileClick} />
      <MainNav onCloseMenu={onChange} />
      {menuElementsSelected.length > 0 && !hiddenSubmenu && <SecondNav onCloseMenu={onChange} />}
      <div className="menuFooter">
        <div
          className="logOut"
          onClick={() => {
            logout();
            navigate('/app/login');
          }}
          role="presentation"
        >
          <FormattedMessage id="profile.logout" />
        </div>
      </div>
    </>
  ),
);

MenuMobile.defaultProps = {
  userMe: {},
  onChange: () => {},
  menuElementsSelected: {},
  hiddenSubmenu: true,
  onProfileClick: () => {},
};

export default MenuMobile;
