import { validate } from 'validate.js';
// eslint-disable-next-line import/no-cycle
import {
  deleteMenuElement,
  addMenuElement,
  updateMenuElement,
} from '../../services/redux/menuElements/actions';
import Utils, {
  CreateTranslationForModelAttributes,
  CreateTranslationPlainForModels,
} from '../Utils';
import ModelExpected from './attributes';

// Class MenuElement with Utils functions
export default class MenuElement extends Utils {
  static entityName = 'MenuElement';

  constructor(obj = {}) {
    /** Send to utils class the Model and the object passed for build this class */
    super(ModelExpected, obj);
    this.Model = MenuElement;
  }

  validate(data) {
    const filteredConstraints = {};

    data.forEach((d) => {
      filteredConstraints[d] = this.constraints[d];
    });

    const validation = validate(this.getData(), filteredConstraints);

    return validation === undefined
      ? { ...validation }
      : { error: true, ...validation };
  }

  /** Translations defined by model keys and create automatically from utils function */
  plainTranslations = CreateTranslationPlainForModels(
    'MenuElement',
    ModelExpected,
  );

  translations = CreateTranslationForModelAttributes(this.plainTranslations);

  /** Call Redux action for Save MenuElement in DB and Redux */
  save() {
    // const filteredConstraints = clone(this.constraints);
    // delete filteredConstraints.id;
    // const validation = validate(this.getData(), filteredConstraints);
    //
    // return validation === undefined
    //     ? addMenuElement({
    //         ...this.getData()
    //     })
    //     : { error: true, ...validation };

    addMenuElement({
      ...this.getData(),
    });

    return {};
  }

  /** Call Redux action for Update MenuElement in DB and Redux */
  update() {
    updateMenuElement({
      ...this.getData(),
      // permissions: this.permissions.map(per => per.id)
    });
  }

  delete() {
    const validation = validate(this.getData(), { id: this.constraints.id });
    return validation === undefined
      ? deleteMenuElement(this.getData())
      : { error: true, ...validation };
  }
}
const SampleMenuElement = new MenuElement();
export { SampleMenuElement };
