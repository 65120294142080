import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';

import CustomInput from 'reactstrap/es/CustomInput';
import ConditionalRules from './components/ConditionalComponents/ConditionalRules';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { withWizardStep } from '../../../../Contexts/WizardStepContext';
import { getDevicesForRules } from '../../../../services/redux/devices/actions';

import { isMobile } from '../../../../helpers/getBreakpoint';
import EditConditions from '../../Profile/EditConditions';
import { Weekly } from '../../../../components/Scheduler/planner';
import '../styles.scss';

const parseCronToPlanner = (startRange, endRange) => {
  const [startMinute, startHour] = startRange.split(' ');
  const [endMinute, endHour] = endRange.split(' ');
  return ({
    startMinute,
    startHour,
    endMinute,
    endHour,
    range: startRange.split(' ').pop(),
  });
};

class ConfigureRule extends Component {
  constructor(props) {
    super(props);

    const { newEntity } = this.props;

    this.state = {
      conditions: newEntity.conditions ? [...newEntity.conditions] : [],
      enableRuleCron: newEntity.enableRuleCron,
      disableRuleCron: newEntity.disableRuleCron,
      selected: !!newEntity.enableRuleCron,
      planner: !!newEntity.enableRuleCron
        && parseCronToPlanner(newEntity.enableRuleCron, newEntity.disableRuleCron),
    };
  }

  componentDidMount() {
    const { onRef, newEntity } = this.props;
    onRef(this);
    getDevicesForRules(newEntity.devices);
  }

  isAnyDataMissing = (field, index) => (
    field[index] === undefined
      || (field[index].constructor === Object
        && Object.entries(field[index]).length === 0)
      || (Array.isArray(field[index]) && field[index].length === 0)
      || field[index] === ''
  );

  timeWindowDataMissing = (field, index, condition) => (
    condition[index][field] === ''
    || condition[index][field] === null
    || condition[index][field] === 0
    || condition[index][field] === undefined
  );

  validateConditions = () => {
    const { conditions } = this.state;
    const newConditions = [...conditions];
    const conditionErrors = [];
    newConditions.forEach((conditionGroup, indexGroup) => {
      conditionGroup.forEach((condition, indexCondition) => {
        Object.keys(condition).forEach((field) => {
          if (field === 'timeWindow') {
            Object.keys(condition[field]).forEach((innerField) => {
              if (this.timeWindowDataMissing(innerField, field, condition) && innerField !== 'errors') {
                if (!conditionErrors[indexGroup]) conditionErrors[indexGroup] = [[]];
                conditionErrors[indexGroup][indexCondition] = {
                  ...conditionErrors[indexGroup][indexCondition],
                  [innerField]: <FormattedMessage id="rules.wizard.validation.empty" />,
                };
              }
              if (innerField === 'repeat' && condition[field][innerField] < 2 && !conditionErrors[indexGroup]) {
                conditionErrors[indexGroup] = [[]];
                conditionErrors[indexGroup][indexCondition] = {
                  ...conditionErrors[indexGroup][indexCondition],
                  [innerField]: <FormattedMessage id="rules.wizard.validation.min.value" />,
                };
              }
            });
          }
          if (this.isAnyDataMissing(condition, field) && field !== 'errors') {
            if (!conditionErrors[indexGroup]) conditionErrors[indexGroup] = [[]];
            conditionErrors[indexGroup][indexCondition] = {
              ...conditionErrors[indexGroup][indexCondition],
              [field]: <FormattedMessage id="rules.wizard.validation.empty" />,
            };
          }
        });
      });
    });

    return conditionErrors;
  };

  validate = () => {
    const { conditions, enableRuleCron, disableRuleCron } = this.state;
    const { onContinue, onError } = this.props;

    if (conditions.length !== 0) {
      const conditionErrors = isMobile() ? [] : this.validateConditions();

      if (conditionErrors.length === 0) {
        onContinue({ conditions, enableRuleCron, disableRuleCron });
      } else {
        this.setState({
          conditionErrors,
        });
        onError(
          <FormattedMessage id="rules.wizard.validation.error.validation" />,
        );
      }
    } else {
      onError(
        <FormattedMessage id="rules.wizard.validation.error.no.condition" />,
      );
    }
  };

  handleChanges = (data, name) => {
    this.setState({
      [name]: data,
    });
  };

  handleOnMobileChange = (value, data) => {
    this.setState({
      [value]: data,
    });
  };

  handleRadioChange = () => {
    const { selected } = this.state;

    this.setState({
      selected: !selected,
      enableRuleCron: undefined,
      disableRuleCron: undefined,
    });
  }

  setPlanner = ({ startRange, endRange }) => {
    this.setState({ enableRuleCron: startRange, disableRuleCron: endRange });
  }

  render() {
    const {
      devices, newEntity,
    } = this.props;
    const {
      conditionErrors, conditions, selected, planner,
    } = this.state;

    return (
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <div>
            <h3>{newEntity.name}</h3>
          </div>
          <Row className="timeModeContainer ml-4 mt-1">
            { isMobile()
              ? (
                <EditConditions
                  permissionToEdit
                  data={{
                    events: newEntity.conditions || [],
                    ...newEntity,
                    fiware: newEntity.devices[0].fiware,
                  }}
                  onChange={(data) => this.handleOnMobileChange('conditions', data)}
                  updating={false}
                  devices={devices}
                />
              )
              : (
                <ConditionalRules
                  data={devices}
                  onChange={this.handleChanges}
                  conditions={conditions}
                  errors={conditionErrors}
                />
              )}
            <Col sm={10} className="px-0">
              <div className="mt-5">
                <h3>
                  <FormattedMessage id="rules.wizard.time.mode" />
                </h3>
              </div>
              <div className="radioOption mb-4">
                <CustomInput
                  name="always"
                  label={<FormattedMessage id="rules.wizard.time.always" />}
                  id="always"
                  value={<FormattedMessage id="rules.wizard.time.always" />}
                  type="radio"
                  onChange={this.handleRadioChange}
                  checked={!selected}
                />
                <CustomInput
                  name="range"
                  label={<FormattedMessage id="rules.wizard.time.range" />}
                  id="range"
                  value={<FormattedMessage id="rules.wizard.time.range" />}
                  type="radio"
                  onChange={this.handleRadioChange}
                  checked={selected}
                />
              </div>
              {selected && (
                <Weekly planner={planner} setValue={this.setPlanner} range />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

ConfigureRule.defaultProps = {
  devices: [],
};

ConfigureRule.propTypes = {
  newEntity: PropTypes.objectOf(PropTypes.object).isRequired,
  onRef: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  devices: state.get('devices').get('devicesForRules'),
});

export default connect(mapStateToProps, {})(withWizardStep(injectIntl(ConfigureRule)));
