import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../Contexts/LanguageContext/index';
import RemoveHoc from '../../../helpers/RemoveComponent';
import Dashboard from '../../../../models/Dashboard';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';

const Delete = (
  <FormattedMessage
    id="options.delete"
    defaultMessage="delete"
    description="Title of delete"
  />
);

const Edit = (
  <FormattedMessage
    id="options.edit"
    defaultMessage="edit"
    description="Title of edit"
  />
);

const Share = (
  <FormattedMessage
    id="options.share"
    defaultMessage="Share"
    description="Title of share"
  />
);

const OptionsForCard = ({ rowContent, clickView, dashboard }) => {
  const ButtonOpenRemove = ({ showModal }) => (
    <li
      onClick={showModal}
      role="presentation"
    >
      {Delete}
    </li>
  );

  ButtonOpenRemove.propTypes = {
    showModal: PropTypes.func,
  };

  ButtonOpenRemove.defaultProps = {
    showModal: () => {},
  };

  const RemoveDOM = RemoveHoc(ButtonOpenRemove, { class: Dashboard, nameData: 'dashboards' });

  return (
    <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission={['update', 'delete']}>
      <div className="tableMenu">
        <ul className="list-unstyled">
          <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
            <li
              onClick={() => { clickView(dashboard, 'edit'); }}
              state={{ dashboard: rowContent, profileType: 'edit' }}
              role="presentation"
            >
              {Edit}
            </li>
          </PrivateComponent>
          <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="update">
            <li
              onClick={() => { clickView(dashboard, 'share'); }}
              state={{ dashboard: rowContent, profileType: 'share' }}
              role="presentation"
            >
              {Share}
            </li>
          </PrivateComponent>
          <PrivateComponent checkLogin checkPermission microsService="/app/dashboard" permission="delete">
            <RemoveDOM rowContent={dashboard} />
          </PrivateComponent>
        </ul>
      </div>
    </PrivateComponent>
  );
};

OptionsForCard.propTypes = {
  rowContent: PropTypes.string,
  clickView: PropTypes.func,
  dashboard: PropTypes.objectOf(PropTypes.any),
};

OptionsForCard.defaultProps = {
  rowContent: '',
  clickView: () => {},
  dashboard: {},
};

export default OptionsForCard;
