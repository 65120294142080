import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { clone } from 'ramda';
import styled from '@emotion/styled';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { getDevices } from '../../../../services/redux/devices/actions';
import { updateWidget } from '../../../../services/redux/widgets/actions';
import RemoveHoc from '../../../helpers/RemoveComponent';
import Device from '../../../../models/Device';
import SelectableList from '../../../../components/SelectableList';
import { withBlanketCtxt } from '../../../../Contexts/BlanketContext';
import { formatSourceForWidget } from '../../../../models/WidgetV2/utils';
import { TYPES, updateState } from '../../../Dashboards/ShowV2/reducer';
import { cloneInstance } from '../../../../helpers/utils';

const RemoveButton = ({ showModal }) => (
  <i
    className="uil uil-trash-alt trashIcon"
    onClick={showModal}
    role="button"
    tabIndex={-1}
  >
    <span className="trashText">
      <FormattedMessage id="widgetLinked.list.options.removeSelection" />
    </span>
  </i>
);

const RemoveDOM = RemoveHoc(RemoveButton, { class: Device, nameData: 'widgetLinked' }, updateWidget);

const UncheckedIcon = (
  <div className="unchecked" />
);

const CheckedIcon = (
  <div className="checkedBorder">
    <div className="checkedFill" />
  </div>
);

const IndeterminateCheckedIcon = (
  <div className="checkedBorder">
    <div className="checkedIndeterminate" />
  </div>
);

const Attribute = styled.div`
  color: #2E384D;
  display: flex;
  align-items: center;
  padding: 7px;
`;

export const AttributesWrapper = styled.section`
  background: #F7F8FA;
  border-radius: 1px;
  padding: 0px 0px 0px 7px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  color: #2E384D;
`;

const AttributeBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentDevice = ({ data }) => (
  <AttributesWrapper>
    {data.attributes.length && !data.joinedAttributes
      ? data.attributes.map((attr) => (
        <Attribute>
          {attr.name}
        </Attribute>
      ))
      : data.joinedAttributes.map((attr) => (
        <AttributeBox>
          <Attribute>
            {attr.name}
          </Attribute>
        </AttributeBox>
      ))}
  </AttributesWrapper>
);

const cardList = (
  localWidget,
  checkboxList,
  checkedAll,
  checkedIndeterminate,
  handleCheckAll,
  category,
  devicesToDelete,
  updateDevicesList,
  showAttributes,
  handleCheck,
  handleShowAttributes,
  sources,
) => (
  <>
    <div>
      <span className="alertCatWidget">
        <FormattedMessage id="widgetLinked.list.options.category" />
      </span>
      <span><FormattedMessage id={`categories.${category?.toLowerCase()}`} /></span>
    </div>
    <div className="listHeader">
      <div>
        <Checkbox
          checked={checkedAll}
          checkedIcon={CheckedIcon}
          className="checkAll"
          icon={checkedIndeterminate ? IndeterminateCheckedIcon : UncheckedIcon}
          onChange={() => handleCheckAll()}
        />
        <span className="selectAllLabel">
          <FormattedMessage id="widgetLinked.list.options.selectAll" />
        </span>
      </div>
      <div>
        {(checkedIndeterminate || checkedAll)
        && (
        <RemoveDOM
          rowContent={devicesToDelete}
          externalAction={localWidget}
          cleanupFunction={updateDevicesList}
        />
        )}
      </div>

    </div>
    {sources.map((o) => (
      (
        <>
          <div className="cardBase">
            <Checkbox
              checked={checkboxList[o.id]}
              checkedIcon={CheckedIcon}
              className="checkbox"
              icon={UncheckedIcon}
              onChange={() => handleCheck(o.id)}
            />
            <div className="textDiv">
              <span className="nameText">{o.sourceId}</span>
              <span className="typeText">
                {`${o.attributes.length} `}
                <FormattedMessage id="widgetLinked.list.options.attributes" />
              </span>
            </div>
            <div
              className="iconExpansionAttributes"
              onClick={() => handleShowAttributes(o.id)}
              role="presentation"
            >
              <i className="uil uil-angle-down i" />
            </div>
          </div>
          {showAttributes[o.id] ? (
            <ContentDevice
              data={o}
            />
          ) : null}

        </>
      )
    ))}
  </>
);

const initCheckboxList = (sources) => {
  const list = {};
  sources.forEach((source) => {
    list[source.id] = false;
  });
  return list;
};

const LinkedWidgetInfo = ({
  data,
  devices,
  total,
  fetchingDevices,
  updatedWidget,
  handleBlanketVisibility,
  sources,
  dispatchState,
}) => {
  const [localWidget, setLocalWidget] = useState(data);
  const [checkboxList, setCheckboxList] = useState(initCheckboxList(sources));
  const [category] = useState(localWidget.config.data?.category);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedIndeterminate, setCheckedIndeterminate] = useState(false);
  const [devicesToDelete, setDevicesToDelete] = useState([]);
  const [showAttributes, setShowAttributes] = useState(initCheckboxList(sources));
  const [devicesToShow, setDevicesToShow] = useState([]);
  const [actualPage, setActualPage] = useState(1);
  const [afterAdd, setAfterAdd] = useState(false);
  const devicesSelectOptions = (source) => sources.find((local) => local.id === source.id);

  useEffect(() => {
    const page = actualPage;
    const size = 6;
    getDevices({ filters: { categories: category }, page, size });
  }, [actualPage, category]);

  useEffect(() => {
    updateState(
      TYPES.setWidgetSection,
      { active: localWidget },
      dispatchState,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localWidget]);

  useEffect(() => {
    const { widgetType } = updatedWidget;

    if (widgetType === 'LINKED') {
      setLocalWidget(updatedWidget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedWidget]);

  useEffect(() => {
    const devicesToShowList = [];
    devices.forEach((d) => {
      if (!devicesSelectOptions(d)) {
        devicesToShowList.push({ name: d.name, id: d.id, value: d });
        setDevicesToShow(devicesToShowList);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, sources]);

  useEffect(() => {
    const newArray = {};
    if (sources) {
      sources.forEach((local) => {
        newArray[local.id] = false;
      });
      setCheckboxList({ ...checkboxList, ...newArray });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources]);

  const handleFetchInfo = () => {
    if (total > devices.length) {
      setActualPage(actualPage + 1);
    }
  };

  const handleCheck = (device) => {
    const checkboxListCopy = clone(checkboxList);
    checkboxListCopy[device] = !checkboxListCopy[device];
    setCheckboxList(checkboxListCopy);
    if (!Object.values(checkboxListCopy).includes(true)) {
      setCheckedAll(false);
      setCheckedIndeterminate(false);
      setDevicesToDelete([]);
    }
    if (!Object.values(checkboxListCopy).includes(false)) {
      setCheckedAll(true);
    }
    if (Object.values(checkboxListCopy).includes(true)
          && Object.values(checkboxListCopy).includes(false)) {
      !checkedIndeterminate && setCheckedIndeterminate(true);
      checkedAll && setCheckedAll(false);
    }
  };

  const handleCheckAll = () => {
    const checkboxListCopy = clone(checkboxList);
    if (!Object.values(checkboxListCopy).includes(false)) {
      Object.keys(checkboxList).forEach((device) => {
        checkboxListCopy[device] = false;
      });
      setCheckedAll(false);
      setDevicesToDelete([]);
    } else {
      Object.keys(checkboxList).forEach((device) => {
        checkboxListCopy[device] = true;
      });
      setCheckedAll(true);
    }
    setCheckedIndeterminate(false);
    setCheckboxList(checkboxListCopy);
  };

  useEffect(() => {
    const devicesIdsToDelete = Object.keys(checkboxList).filter((id) => checkboxList[id]);
    const arrayOfDevicesToDelete = [];
    devicesIdsToDelete.forEach((deviceId) => {
      const deviceData = sources.find((d) => d.id === deviceId);
      if (deviceData) {
        arrayOfDevicesToDelete.push({
          name: deviceData?.sourceId,
          data: deviceData,
        });
      }
    });
    setDevicesToDelete(arrayOfDevicesToDelete);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxList]);

  const updateDevicesList = () => {
    setDevicesToDelete([]);
    setCheckedAll(false);
    setCheckedIndeterminate(false);
  };

  const hideModal = () => {
    handleBlanketVisibility(false);
  };

  const handleSelectableListChanges = (dataDevices) => {
    const widgetToUpdate = cloneInstance(localWidget);
    let device = {};
    dataDevices.forEach((i) => {
      devices.forEach((d) => {
        if (d.id === i.id) {
          device = d;
          const deviceFormated = formatSourceForWidget(device);
          if (deviceFormated) widgetToUpdate.sources.push(deviceFormated);
        }
      });
    });
    widgetToUpdate.update(widgetToUpdate, localWidget);
    setLocalWidget(widgetToUpdate);
    setAfterAdd(true);
    hideModal();
  };

  const handleShowAttributes = (id) => {
    const showAttributesCopy = clone(showAttributes);
    Object.keys(showAttributesCopy).forEach((device) => {
      if (id === device) {
        showAttributesCopy[device] = !showAttributesCopy[device];
      }
    });
    setShowAttributes(showAttributesCopy);
  };

  const onConfirmationChange = {

    add: {
      title: <FormattedMessage id="widgets.linked.select.title" />,
      description: <FormattedMessage id="widgets.linked.select.subtitle" />,
    },
  };

  return (
    <div className="infoLinked">
      <div className="tabSection p-5 backgroundWhite">
        <div className="row">
          <dt className="col-12">
            <FormattedMessage id="widget.attribute.widgetType" />
          </dt>
          <dd className="col-12">
            {localWidget.widgetType}
          </dd>
        </div>
        <div className="row">
          <dt className="col-12">
            <FormattedMessage id="widget.attribute.dataSources" />
          </dt>
          <dd className="col-12">
            <SelectableList
              data={devicesToShow}
              name="devices"
              translatePrefix="widgets.wizard"
              error
              listComponent={() => cardList(
                localWidget,
                checkboxList,
                checkedAll,
                checkedIndeterminate,
                handleCheckAll,
                category,
                devicesToDelete,
                updateDevicesList,
                showAttributes,
                handleCheck,
                handleShowAttributes,
                sources,
              )}
              onChange={handleSelectableListChanges}
              fetchInfo={handleFetchInfo}
              loading={fetchingDevices}
              afterAdd={afterAdd}
              setAfterAdd={setAfterAdd}
              multiOption
              filterBy="name"
              selectOnTheFly
              filter="devices"
              type="LinkedProfile"
              footerWithAction
              onConfirmationChange={onConfirmationChange}
              bigSize
            />
          </dd>
        </div>
        <div className="row">
          <dt className="col-12" />
          <dd className="col-12">
            {}
          </dd>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  devices: state
    .get('devices')
    .get('list')
    .toJS(),
  fetchingDevices: state.get('devices').get('fetching'),
  total: state.get('devices').get('total'),
  updatedWidget: state.get('widgets').get('updatedWidget'),
});

export default connect(
  mapStateToProps,
  {},
)(withBlanketCtxt(LinkedWidgetInfo));
