import React, { useState } from 'react';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-cycle
import OptionsForWidgetToImageAddButton from './OptionsForWidgetImageAddButton';
import { FormattedMessage } from '../../../../../Contexts/LanguageContext';
import colors from '../../../../../configuration/colors';
import Switch from '../../../../../elements/Switch';

const Container = styled.div`
  max-width: 125px;
  margin-top: 1rem;
  background: white;
  border-radius: 5px;
  overflow: hidden;

  ul{
    margin-bottom: 0;
    li{
      button{
        text-align: left;
        font-family: "Source Sans Pro";
        cursor: pointer;
        padding: 0.6rem 1rem;
        outline: none;
        border: none;
        width: 100%;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 0.1px;
        color: ${colors['ui-Black']};
        &:hover{
          background: rgba(46,91,255,0.08);
        }
      }
    }
  }
`;

const WidgetImageOptions = ({
  dashboard, widgetImageId, actions, hasPermissionToEdit,
  dispatchState, widget, isDraggable, handleSwicht,
}) => {
  const [pressed, setPressed] = useState(false);

  return (
    <div className="divAddWidgetToImage">
      <div className="blockWidgets">
        <Switch
          className="switch"
          positionTitle="left"
          title={<FormattedMessage id="widgetImage.switch.block.widgets" />}
          checked={!isDraggable}
          onChange={handleSwicht}
        />
      </div>
      <div>
        <span>
          <button className="addWidgetToImageButton" type="button" onClick={() => setPressed(!pressed)}>
            <i className="uil uil-edit" />
            <p className="addWidgetToImageButtonText">
              <FormattedMessage id="widgetImage.button.addWidget" />
            </p>
          </button>
          {hasPermissionToEdit
            && <div className="actions">{actions}</div>}
        </span>
        {pressed
          && (
            <Container>
              <OptionsForWidgetToImageAddButton
                dashboard={dashboard}
                widgetImageId={widgetImageId}
                dispatchState={dispatchState}
                widget={widget}
                setPressed={setPressed}
                pressed={pressed}
              />
            </Container>
          )}
      </div>
    </div>
  );
};

export default WidgetImageOptions;
