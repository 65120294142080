import colors from '../../../../configuration/colors';

export const SliderDefaultConfiguration = {
  labels: {
    description: '',
  },
  appearance: {
    state: 1,
  },
  conditions: {
    numberOfPoints: [
      {
        state: 1,
        alias: '1',
        commands: [
          {
            commandName: '',
            devicesId: [],
            value: '',
          },
        ],
      },
      {
        state: 2,
        alias: '2',
        commands: [
          {
            commandName: '',
            devicesId: [],
            value: '',
          },
        ],
      },
    ],
  },
  colors: {
    slider: colors['ui-PurpleBlue'],
    background: colors['ui-LightGray'],
  },
};
export default SliderDefaultConfiguration;
