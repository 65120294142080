const HeatMapDefaultConfiguration = {
  data: {
    startDate: '',
  },
  colors: { },
  labels: { },
  tendlines: { },
  conditions: { },
  appearance: { },
  effects: { },
  custom: {
    viewType: 'from-date',
  },
};

export default HeatMapDefaultConfiguration;
