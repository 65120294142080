import React from 'react';
import { FormattedMessage } from "../../../../../Contexts/LanguageContext/index";
import SuccessComponent from '../../../../helpers/SuccessPage';
import UserOkIcon from '../../../../../configuration/icons/svg/user-ok.svg'
import { withWizardStep } from '../../../../../Contexts/WizardStepContext';

const Content = ({created}) => {
    return (<div className="passwordPanel">
        <div>
            <dt><FormattedMessage id='roles.add.success.role'/></dt>
            <dd>{created.name}</dd>
        </div>
        <div className="divisor"></div>
    </div>)
}


const Success = ({ newEntity }) =>
    <SuccessComponent
        title={<FormattedMessage id='roles.add.success.title'/>}
        content={<Content created={newEntity} />}
        icon={UserOkIcon} />


export default withWizardStep(Success);