import React from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import CommonView from '../../../CommonView';
import widgetHTMLValidator from '../validators/WidgetHTMLValidator';
import ExpansionContent from '../newcomponents/contentProfile';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import ExpansionLabel from '../../../../components/ExpansionLabel';
import SubMenu from '../newcomponents/subMenu';
import './style.scss';
import { cloneInstance } from '../../../../helpers/utils';
import { getWidgetModel } from '../../../../models/WidgetV2/utils';
import ConfigureWidgetSlider from '../../Add/steps/customSteps/Commands/Slider/ConfigureWidgetSlider';

class EditConfigurationWidgetTypeV2 extends CommonView {
  constructor(props) {
    super({ props });

    this.state = {
      widget: this.props.data,
      errors: {},
      submenuEnabled: false,
      submenuComponent: undefined,
      submenuCategorySelected: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        widget: this.props.data,
      });
    }
  }

  handleSave = () => {
    const { data, sources } = this.props;
    this.setState({ errors: {} });
    const newWidget = cloneInstance(this.state.widget);
    const validator = newWidget.getValidatorConfig();
    const validation = validator(this.state.widget.config, this.state.widget.container);
    if (validation) {
      this.setState({ errors: validation });
      return;
    }

    let validatedWidget;
    if (data.widgetType === 'HTML') {
      validatedWidget = widgetHTMLValidator(this.state.widget.config);
    }

    if (validatedWidget) {
      this.setState({ errors: validatedWidget });
    } else {
      newWidget.origins = newWidget.origins.map((opt) => {
        const o = opt;
        delete o.connectedDevices.historicaldata;
        return o;
      });
      newWidget.update(newWidget, data.config, sources);
    }
  };

  handleChange = (config) => this.setState({ config });

  handleChangeAndSave = (data) => {
    const { widget } = this.state;
    widget.config = { ...this.state.widget.config, data };
    this.setState({ widget }, this.handleSave);
  };

  handleChangeSelect = (name, value, category = undefined, subCategory = undefined) => {
    let oldValue = category && !subCategory
      ? this.state.widget.config[category][name]
      : this.state.widget.config[name];

    if (subCategory) oldValue = this.state.widget.config[category][subCategory][name];

    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.widget.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      const { widget } = this.state;
      widget.config = config;
      this.setState({ widget }, this.handleSave);
    }
  };

  handleInputChange = (event, category = undefined, subCategory = undefined) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    let oldValue = category && !subCategory
      ? this.state.widget.config[category][name]
      : this.state.widget.config[name];

    if (subCategory) oldValue = this.state.widget.config[category][subCategory][name];

    if (!R.equals(oldValue, value)) {
      const config = { ...this.state.widget.config };
      if (category && !subCategory) {
        config[category][name] = value;
      } else {
        config[name] = value;
      }
      if (subCategory) config[category][subCategory][name] = value;
      const { widget } = this.state;
      widget.config = config;
      this.setState({ widget });
    }
  };

  handleSwitchChange = (name, value, category = undefined) => {
    const config = { ...this.state.widget.config };
    if (category) {
      config[category][name] = value;
    } else {
      config[name] = value;
    }
    const { widget } = this.state;
    widget.config = config;
    this.setState({ widget }, this.handleSave);
  }

  handleCheckBoxChange = (name, value, category = undefined, subCategory = undefined) => {
    const config = { ...this.state.widget.config };
    // eslint-disable-next-line no-unused-expressions
    (category && !subCategory) ? config[category][name] = value : config[name] = value;
    if (subCategory) config[category][subCategory][name] = value;
    const { widget } = this.state;
    widget.config = config;
    this.setState({ widget }, this.handleSave);
  };

  handleDateChange = (name, value, category = undefined, subCategory = undefined) => {
    const config = { ...this.state.widget.config };
    // eslint-disable-next-line no-unused-expressions
    (category && !subCategory) ? config[category][name] = value : config[name] = value;
    if (subCategory) config[category][subCategory][name] = value;
    const { widget } = this.state;
    widget.config = config;
    this.setState({ widget }, this.handleSave);
  };

  handleGenericChange = (event, category, type, subCategory = undefined) => {
    switch (type) {
      case 'number':
      case 'text':
      case 'textArea':
        this.handleInputChange(event, category, subCategory);
        break;
      case 'checkbox':
        this.handleCheckBoxChange(event.name, event.value, category, subCategory);
        break;
      case 'tableConditions':
      case 'commandsOnOff':
      case 'commandsSlider':
      case 'colorPicker':
      case 'colorsData':
      case 'colorsDataMap':
      case 'listComponent':
      case 'barData':
      case 'selectColor':
      case 'selectImage':
      case 'tableData':
      case 'select':
      case 'lineData':
      case 'needleRange':
      case 'advancedLegendColors':
      case 'advancedLegendNames':
        if (event.name === 'sampling' && !event.value) {
          // eslint-disable-next-line no-param-reassign
          event.value = 'lastMonth';
        }
        this.handleChangeSelect(event.name, event.value, category, subCategory);
        break;
      case 'switch':
      case 'kpiHistoricalSwitch':
        this.handleSwitchChange(event.name, event.value, category, subCategory);
        break;
      case 'mapStyle':
        this.handleChangeSelect(event.name, event.value, category, subCategory);
        break;
      default:
        return null;
    }
    return true;
  }

  toggleSubmenu = (submenu, component, categorySelected) => {
    this.setState({
      submenuEnabled: submenu,
      submenuComponent: component,
      submenuCategorySelected: categorySelected,
    });
  }

  renderProfile = () => {
    const { sources } = this.props;
    const { widget } = this.state;
    const configurationSheet = widget.getConfigurationSheet(false);
    const sections = Object.keys(configurationSheet);
    const {
      submenuEnabled,
      submenuComponent,
      submenuCategorySelected,
      errors,
    } = this.state;

    if (!submenuEnabled) {
      return (
        <>
          {sections.map((panelSection, index) => (
            <ExpansionLabel
              opened={index === 0}
              header={() => (
                <FormattedMessage
                  component={false}
                  id={`widget.config.profile.header.${panelSection}`}
                />
              )}
              content={() => ExpansionContent(
                configurationSheet[panelSection],
                this.handleGenericChange,
                this.handleSave,
                widget.config,
                widget,
                this.toggleSubmenu,
                submenuCategorySelected,
                errors,
                false,
                sources,
              )}
            />
          ))}
        </>
      );
    }

    return (
      <SubMenu
        onMain={this.toggleSubmenu}
        type={submenuComponent}
        categorySelected={submenuCategorySelected}
      >
        {ExpansionContent(
          [submenuComponent],
          this.handleGenericChange,
          this.handleSave,
          widget.config,
          widget,
          this.toggleSubmenu,
          submenuCategorySelected,
          errors,
          false,
          sources,
        )}
      </SubMenu>
    );
  }

  render() {
    return (
      <section style={{ padding: '1em 1em' }}>
        {this.renderProfile()}
      </section>
    );
  }
}

export default injectIntl(EditConfigurationWidgetTypeV2);
