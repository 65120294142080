/* eslint-disable import/no-cycle */
import { random } from 'lodash';
import { getColor } from '../../../../../pages/Widgets/Add/components/utils';
import { getAlias } from '../../../utils';

const LinesDefaultConfiguration = {
  data: {
    type: 'real-time',
    sampling: 'lastFifteenDays',
    startDate: '',
    endDate: '',
  },
  colors: { },
  labels: {
    xAxis: '',
    yAxis: '',
    alias: { },
  },
  trendlines: { },
  conditions: { },
  appearance: {
    legendPosition: 'top',
  },
  effects: { },
  custom: { },
};

export default LinesDefaultConfiguration;

export const injectLinesData = (widget) => {
  const newConfig = { ...widget.config };
  const hasMultiSources = widget.sources.length > 1;
  widget.sources.forEach((source) => {
    source.fields.forEach((attr) => {
      if (!newConfig.labels.alias) newConfig.labels.alias = {};
      const name = `${source.sourceId}-${attr}`;
      if (!newConfig.labels.alias[attr]) {
        newConfig.labels.alias[name] = getAlias(source, attr, hasMultiSources);
      }
      const property = newConfig.colors[name];
      if (!property) newConfig.colors[name] = getColor(random(0, 12));
    });
  });

  return newConfig;
};
