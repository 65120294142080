import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withTheme } from '../../../../Contexts/ThemeContext';
import { exampleHeader, filters } from '../ExampleData';
import WithDrawer from '../../../../components/DrawerContainer';
import { getUsers } from '../../../../services/redux/users/actions';
import User, { SampleUser } from '../../../../models/User';
import CreateUser from '../Add/AddWizard';
import PrivateComponent from '../../../../components/PrivateComponent/privateComponent';
import RemoveHoc from '../../../helpers/RemoveComponent';
import Profile from '../Profile';
import BasicList from '../../../helpers/BasicList/index';
import OptionsForRow from './OptionsForRows';

const RemoveComponent = RemoveHoc(({ showModal }) => (
  <PrivateComponent
    checkLogin
    checkPermission
    microsService="/app/users"
    permission="delete"
  >
    <i onClick={showModal} className="uil uil-trash-alt remove" />
  </PrivateComponent>
),
{ class: User, nameData: 'users' });

const ProfileComponent = WithDrawer(
  ({
    onChange, data, enableChangePassword, ...rest
  }) => (
    <PrivateComponent checkLogin checkPermission microsService="/app/users" permission="read">
      <Profile
        {...rest}
        onChange={onChange}
        data={data}
        enableChangePassword={enableChangePassword}
      />
    </PrivateComponent>
  ),
);

class List extends React.Component {
  state = {
    page: 1,
    itemsPerPage: 5,
    itemsShowed: 5,
    profileOpened: false,
  };

  componentDidMount() {
    const { page, itemsPerPage } = this.state;
    getUsers({ filters: [], page, itemsPerPage });
  }

  isLoggedUser = (activeUser) => activeUser.id === this.props.userMe.id;

  handleOnSubmit = (filters2) => {
    const { page, itemsPerPage } = this.state;
    getUsers({ filters2, page, itemsPerPage });
  };

  handleOnPageChange = (page, itemsPerPage, itemsShowed) => {
    this.setState({
      page,
      itemsPerPage,
      itemsShowed,
    });
  };

  handleOnProfileEvent = (opened) => {
    this.setState({
      profileOpened: opened,
    });
  };

  handleOpenProfile = (data) => {
    this.setState({
      profileOpened: true,
      activeUser: data,
    });
  };

  render() {
    const { users, total } = this.props;
    const userByData = users.map((user) => user.toJson());
    return (
      <>
        <BasicList
          {...this.state}
          data={userByData}
          total={total}
          onSubmit={this.handleOnSubmit}
          entityName="users"
          entity={User}
          exampleEntity={SampleUser}
          header={exampleHeader}
          profileComponent={ProfileComponent}
          createEntityComponent={CreateUser}
          filters={filters}
          externalFilter={false}
          expandable
          selectable
          onPageChange={this.handleOnPageChange}
          optionsForRow={OptionsForRow}
          onRemove={RemoveComponent}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.get('users').get('list').toJS(),
  total: state.get('users').get('total'),
  userMe: state.get('users').get('userMe'),
});

export default connect(
  mapStateToProps,
  {},
)(withTheme(injectIntl(List)));
