import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { clone } from 'ramda';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import ContentEditable from '../../../helpers/ContentEditable/ContentEditable';
import Icon from '../../../../elements/Icons';
import './style.scss';
import ColorPicker from '../../../../components/ColorPicker';
import { Components } from '../../../../models/Widget/configurationSheets/utils';

const ColorsDataMap = ({
  categories,
  colors,
  alias,
  onChangeAlias,
  onChangeColors,
  handleSubmenu,
  subMenus,
}) => {
  const [localAlias, setLocalAlias] = useState(alias || {});
  const [localColors, setLocalColors] = useState(colors);
  const [localCategories] = useState(categories);

  const handleContentEditableChangeAlias = (id, data) => {
    const _alias = clone(localAlias);
    _alias[id] = data;
    setLocalAlias(_alias);
    onChangeAlias({ name: 'categoryAlias', value: _alias }, 'labels', Components.colorsDataMap);
  };

  const handleColorChange = (color, name) => {
    const _colors = clone(localColors);
    _colors[name].color = color.hex;
    setLocalColors(_colors);
    onChangeColors({ name: 'categoryColors', value: _colors }, 'conditions', Components.colorsDataMap);
  };

  const ColorPickerFooter = (cd) => (
    <div className="colorPickerFooter" role="button" tabIndex={-1} onClick={() => handleSubmenu(true, subMenus[0], cd)}>
      <div className="advancedColoConfig">
        <Icon
          fontSize="small"
          svg="SettingsOutlined"
          style={{
            color: '#577EE8', cursor: 'pointer', 'margin-right': '10px', 'margin-left': '5px', 'margin-top': '4px',
          }}
        />
        <div><FormattedMessage component={false} id="widget.config.map.details.title.color" /></div>
      </div>
    </div>
  );

  return (
    <Row className="colorPickContainer map">
      {localCategories.map((cd) => (
        <>
          <Col sm={3} md={2}><ColorPicker footer={ColorPickerFooter(cd)} style={{ 'margin-top': '20px' }} name={cd} onChangeComplete={handleColorChange} color={localColors && localColors[cd] ? localColors[cd].color : ''} label="Color" /></Col>
          <Col sm={9} md={10}>
            <span className="atLabel" style={{ 'margin-top': '6px' }}><FormattedMessage component={false} id={`devices.wizard.categories.${cd.toLowerCase()}`} /></span>

            <ContentEditable
              style={{ width: '100%' }}
              className="labelFocus"
              tag="at"
              onBlur={handleContentEditableChangeAlias}
              id={cd}
            >
              {localAlias[cd] ?? cd}

            </ContentEditable>

            <Icon
              fontSize="small"
              svg="SettingsOutlined"
              onClick={() => handleSubmenu(true, subMenus[1], cd)}
              style={{
                color: '#577EE8', cursor: 'pointer', 'margin-left': '5px', 'margin-top': '-5px',
              }}
            />

          </Col>
        </>
      ))}
    </Row>
  );
};

export default ColorsDataMap;
