import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../Contexts/LanguageContext/index';

const Max = (
  <FormattedMessage
    id="kpis.wizard.step5.arithmetical.operation.options.max"
  />
);

const Min = (
  <FormattedMessage
    id="kpis.wizard.step5.arithmetical.operation.options.min"
  />
);

const Average = (
  <FormattedMessage
    id="kpis.wizard.step5.arithmetical.operation.options.avg"
  />
);

const Sum = (
  <FormattedMessage
    id="kpis.wizard.step5.arithmetical.operation.options.sum"
  />
);

const Sum2 = (
  <FormattedMessage
    id="kpis.wizard.step5.arithmetical.operation.options.sum2"
  />
);

const LastOccurence = (
  <FormattedMessage
    id="kpis.wizard.step5.arithmetical.operation.options.lastOccurence"
  />
);

const OptionsForRow = ({ rowContent, handleOnClickSource }) => {
  const options = (
    <>
      <li onClick={() => { handleOnClickSource(rowContent.device, rowContent.attribute, 'max'); }}>
        {Max}
      </li>
      <li onClick={() => { handleOnClickSource(rowContent.device, rowContent.attribute, 'min'); }}>
        {Min}
      </li>
      <li onClick={() => { handleOnClickSource(rowContent.device, rowContent.attribute, 'avg'); }}>
        {Average}
      </li>
      <li onClick={() => { handleOnClickSource(rowContent.device, rowContent.attribute, 'sum'); }}>
        {Sum}
      </li>
      <li onClick={() => { handleOnClickSource(rowContent.device, rowContent.attribute, 'sum2'); }}>
        {Sum2}
      </li>
      <li onClick={() => {
        handleOnClickSource(rowContent.device,
          rowContent.attribute,
          'last_occurence');
      }}
      >
        {LastOccurence}
      </li>
    </>
  );

  return (
    <div className="tableMenu optionsForRow">
      <ul className="list-unstyled">
        {options}
      </ul>
    </div>
  );
};

OptionsForRow.propTypes = {
  rowContent: PropTypes.arrayOf(PropTypes.object),
  device: PropTypes.objectOf(PropTypes.any),
  attribute: PropTypes.objectOf(PropTypes.any),
  handleOnClickSource: PropTypes.func,
};

OptionsForRow.defaultProps = {
  rowContent: [],
  device: {},
  attribute: {},
  handleOnClickSource: undefined,
};

export default OptionsForRow;
