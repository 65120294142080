import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sendCommand as sendCommandApi, sendCommands as sendCommandsApi } from '../../../../../services/api/devices';

const isDisabled = (device) => !device || device.commands.length === 0;

const getValue = (device) => (
  device.commands.length === 1 ? device.commands[0] : undefined
);

const parseOptions = (options) => options.map((o) => ({ name: o.name, value: o }));

const sendCommand = async (device, attribute, command, setStatus) => {
  setStatus('processing');
  const response = await await sendCommandApi(device, {
    value: command,
    name: attribute,
  });
  if (response.status === 200) {
    setStatus('success');
  } else {
    setStatus('error');
  }
};

const sendCommands = async (commands, state) => {
  const commandsToSend = {
    targets: [],
  };
  commands.conditions.numberOfPoints.forEach((point) => {
    if (point.state === state) {
      point.commands.forEach((c) => {
        if (c.groupId) {
          c.devicesId.forEach((id) => {
            commandsToSend.targets.push({
              id,
              command: {
                name: c.commandName,
                value: c.value
              }
            });
          })
        } else {
          commandsToSend.targets.push({
            id: c.devicesId[0],
            command: {
              name: c.commandName,
              value: c.value
            }
          });
        }
      });
    }
  });
  sendCommandsApi(commandsToSend);
};

const getStatus = (status) => <FormattedMessage id={`SendCommandList.${status}`} />;

const getEnabledNumber = (devices) => {
  let total = 0;

  devices.forEach((d) => {
    if (!isDisabled(d)) total += 1;
  });

  return total;
};

const initCheckedList = (devices, value) => devices.map(() => value);

const updateCheckedList = (index, formerCheckedList, callback) => {
  const checkedList = [...formerCheckedList];
  checkedList[index] = !formerCheckedList[index];
  callback(checkedList);
};

const areAllChecked = (checkedList) => {
  let checked = 0;
  checkedList.forEach((i) => {
    if (i) {
      checked += 1;
    }
  });

  return checked === checkedList.length;
};

export {
  isDisabled, getValue, sendCommand, sendCommands, parseOptions, getStatus,
  getEnabledNumber, initCheckedList, updateCheckedList, areAllChecked,
};
