/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import WidgetMenuButton from './widgetMenuButton';
import useNearScreen from '../../../../elements/UseNearScreen';
import { getHistoricalListV2 } from '../../../../services/redux/historicals/actions';
import { getURN, getUrnEntity, getUrnId } from '../../../../helpers/utils';
import WidgetCard from '../../../../elements/WidgetCard';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import { TYPES, updateState } from '../reducer';

function RenderWidget(props) {
  const {
    widget,
    disableOptions,
    handleOpenProfileWidget,
    handleOpenProfileSendingCommands,
    /** TODO: Move that */
    widgetSize,
    hasBeenResized,
    /** TODO: Move that */
    renderChildrenWidgets,
    dispatchState,
    updateContainerLayout,
    isPublicDashboard,
    selection,
    previewMode,
    grid,
    childrenWidgetsLength,
    intl,
    ...rest
  } = props;

  const [widgetValues, setWidgetValues] = useState();
  const [rawValues, setRawValues] = useState();
  const [error, setError] = useState(false);
  const [sourcesLoaded, setSourcesLoaded] = useState();
  const [entitySources, setEntitySources] = useState([]);
  const { isNearScreen, fromRef } = useNearScreen({ once: false });
  const errorList = useSelector((state) => state.get('historicals').get('errorListV2'));
  const values = useSelector((state) => (
    state.get('historicals').get('historicalListV2')));
  const devices = useSelector((state) => (
    state.get('devices').get('listSources')
  ));
  const kpis = useSelector((state) => (
    state.get('kpis').get('listSources')
  ));
  const etlProcedures = useSelector((state) => (
    state.get('etlProcedures').get('listSources')
  ));
  const sources = useMemo(() => ({
    device: devices,
    kpi: kpis,
    etlprocedure: etlProcedures,
  }), [devices, kpis, etlProcedures]);

  const widgetSources = useMemo(() => {
    const sourceObj = [];
    widget.getUrnSources().forEach((urn) => {
      const sourceType = getUrnEntity(urn);
      const sourceId = getUrnId(urn);
      if (sources[sourceType.toLowerCase()][sourceId]) {
        sourceObj.push(sources[sourceType.toLowerCase()][sourceId]);
      }
    });
    return sourceObj;
  },
  [sources]);

  const actions = useMemo(() => (!disableOptions ? (
    <WidgetMenuButton
      widget={widget}
      sources={entitySources}
      entity="widget"
      clickView={handleOpenProfileWidget}
      clickSendingCommandsView={handleOpenProfileSendingCommands}
    />
  ) : (
    <div />
  )), [widget, widget.config?.data?.type, entitySources, handleOpenProfileWidget]);

  const Comp = useMemo(() => widget.getComponent(), [widget.widgetType, childrenWidgetsLength]);
  const Query = useMemo(() => widget.getQueryHistorical(selection),
    [
      widget.config?.data?.attributeFilter,
      widget.config?.data?.sampling,
      widget.config?.data?.type,
      widget.config?.data?.period,
      widget.config?.data?.startDate,
      widget.config?.data?.endDate,
      widget.sources,
      selection,
    ]);

  const getSources = () => {
    widget.getUrnSources().forEach((urn) => {
      const sourceType = getUrnEntity(urn);
      const sourceId = getUrnId(urn);
      if (!sources[sourceType.toLowerCase()][sourceId]) {
        getURN(urn, isPublicDashboard);
      }
    });
  };

  const onChangeHistoricalConfig = useCallback(() => {
    if (Query) getHistoricalListV2({ query: Query, widgetId: widget.id, isPublicDashboard });
    getSources();
    const sourcesUrns = widget.sources.map((o) => o.urn);
    const value = {};
    sourcesUrns.forEach((s) => {
      value[s] = widget;
    });
    if (dispatchState) updateState(TYPES.updateSourceList, value, dispatchState);
    return null;
  }, [Query, widget.id]);

  useEffect(() => {
    if (rawValues && sourcesLoaded) {
      setWidgetValues(widget.formatToData(
        rawValues,
        widgetSources,
        widget.config,
        selection,
        intl,
      ));
    }
  }, [rawValues, sourcesLoaded, selection, widget.config, widget.config?.data?.attributeFilter, widget.config?.data?.operation]);

  useEffect(() => {
    const previewValues = values?.[widget.ifUsesContainerValues ? widget.container : widget.id];
    if (previewValues) {
      if (!isEqual(previewValues, rawValues)) {
        setRawValues(previewValues);
      }
    }
  }, [values, selection]);

  useEffect(() => {
    if (errorList?.[widget.id]) {
      setError(true);
    }
  }, [errorList]);

  useEffect(() => {
    setSourcesLoaded(widgetSources.length === widget.getUrnSources().length);
  }, [widgetSources, widget.sources]);

  useEffect(() => {
    if (JSON.stringify(widgetSources) !== JSON.stringify(entitySources)) {
      setEntitySources(widgetSources);
    }
  }, [widgetSources]);

  return (
    <div className="h-100" ref={fromRef}>
      {isNearScreen && !error
        ? (
          <Comp
            widgetCard={{ dispatchState }}
            actions={actions}
            grid={grid}
            hasBeenResized={hasBeenResized}
            handleOpenProfileWidget={handleOpenProfileWidget}
            hasPermissionToEdit={!disableOptions && widget.hasPermissionsToEdit}
            onChangeHistoricalConfig={onChangeHistoricalConfig}
            renderChildrenWidgets={renderChildrenWidgets}
            updateContainerLayout={updateContainerLayout}
            dispatchState={dispatchState}
            sources={entitySources}
            values={widgetValues}
            widget={widget}
            widgetSize={widgetSize}
            globalSelection={selection}
            previewMode={previewMode}
            childrenWidgetsLength={childrenWidgetsLength}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
        ) : (
          // ToDo Create WidgetError
          <WidgetCard
            title={widget.name}
            actions={actions}
            hasPermissionToEdit={widget.hasPermissionsToEdit}
          >
            <FormattedMessage id="widget.error.loading" />
          </WidgetCard>
        )}
    </div>
  );
}

export default memo(injectIntl(RenderWidget));
