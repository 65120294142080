
export const MODIFY_REPORT_HISTORICAL_REQUEST = 'MODIFY_REPORT_REQUEST';
export const MODIFY_REPORT_HISTORICAL_SUCCESS = 'MODIFY_REPORT_SUCCESS';
export const MODIFY_REPORT_HISTORICAL_ERROR = 'MODIFY_REPORT_ERROR';

export const DELETE_REPORT_HISTORICAL_REQUEST = 'DELETE_REPORT_HISTORICAL_REQUEST';
export const DELETE_REPORT_HISTORICAL_SUCCESS = 'DELETE_REPORT_HISTORICAL_SUCCESS';
export const DELETE_REPORT_HISTORICAL_ERROR = 'DELETE_REPORT_HISTORICAL_ERROR';

export const GET_REPORT_HISTORICAL_REQUEST = 'GET_REPORT_HISTORICAL_REQUEST';
export const GET_REPORT_HISTORICAL_ERROR = 'GET_REPORT_HISTORICAL_ERROR';
export const GET_REPORT_HISTORICAL_SUCCESS = 'GET_REPORT_HISTORICAL_SUCCESS';

export const DOWNLOAD_REPORT_HISTORICAL_REQUEST = 'DOWNLOAD_REPORT_HISTORICAL_REQUEST';
export const DOWNLOAD_REPORT_HISTORICAL_REDIRECT = 'DOWNLOAD_REPORT_HISTORICAL_REDIRECT';
export const DOWNLOAD_REPORT_HISTORICAL_ERROR = 'DOWNLOAD_REPORT_HISTORICAL_ERROR';

export const CLEAR_REPORT_HISTORICAL_STATE = 'CLEAR_REPORT_HISTORICAL_STATE';

export const ERRORFETCHING_REPORT_HISTORICAL_RESET = 'ERRORFETCHING_REPORT_RESET';
export const UPDATE_REPORT_HISTORICAL_STATE = 'UPDATE_REPORTS_STATE';
