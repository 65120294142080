import { Components, categories, subCategories } from '../utils';

export default {
  configuration: [
    {
      name: 'css',
      component: Components.text,
      categories: [categories.CUSTOM],
      subCategories: [subCategories.HTML],
    },
    {
      name: 'js',
      component: Components.text,
      categories: [categories.CUSTOM],
      subCategories: [subCategories.HTML],
    },
    {
      name: 'html',
      component: Components.textArea,
      categories: [categories.CUSTOM],
      subCategories: [subCategories.HTML],
    },
  ],
};
