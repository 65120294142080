import React from 'react';
import { FormattedMessage } from '../../../../Contexts/LanguageContext';
import Field from '../../../../elements/Field';
import { sampling as samplingList } from '../../../../configuration/variables';
import { parseOptions } from '../../../../helpers/utils';
import { categories, Components, getCategory } from '../../../../models/Widget/configurationSheets/utils';
import './style.scss';
import { Col, Row } from 'reactstrap';

const LineData = ({
  config,
  handler,
  categories: _categories,
  errors,
}) => {
  const typeOptions = ['historical', 'real-time'];
  const { data } = config;
  const {
    type, startDate, endDate, sampling,
  } = data;

  const getDate = (date) => (date ? new Date(date) : null);

  const handleData = (name, value) => {
    const category = _categories.find((e) => e === categories.DATA);
    handler({ name, value }, category, Components.lineData);
  };

  return (
    <Row>

      <Col>
        <Field
          name="type"
          type="select"
          label={<FormattedMessage id="widget.config.type" />}
          options={typeOptions.map((t) => (
            {
              value: t,
              name: <FormattedMessage id={`widget.config.${t}`} />,
            }
          ))}
          value={type}
          onChange={(name, value) => handleData(name, value)}
          error={typeof errors === 'object' && errors[`${getCategory('type')}.${'type'}`]}
          helperText={errors[`${getCategory('type')}.type`]}
        />
      </Col>

      {type === 'real-time'
    && (
      <Col>
        <Field
          name="sampling"
          type="select"
          label={<FormattedMessage id="widget.config.sampling" />}
          options={samplingList.map(parseOptions)}
          value={sampling}
          onChange={(name, value) => handleData(name, value)}
          error={typeof errors === 'object' && errors[`${getCategory('sampling')}.${'sampling'}`]}
          helperText={errors[`${getCategory('sampling')}.sampling`]}
        />
      </Col>
    )}

      {type !== 'real-time'
    && (
      <Col>
        <Field
          name="startDate"
          type="date"
          label={<FormattedMessage id="widget.config.startDate" />}
          mode="edit"
          onChange={(name, value) => handleData(name, value)}
          value={getDate(startDate)}
          error={typeof errors === 'object' && errors[`${getCategory('startDate')}.${'startDate'}`]}
          helperText={errors[`${getCategory('startDate')}.startDate`]}
        />
      </Col>
    )}

      {type !== 'real-time'
    && (
      <Col>
        <Field
          name="endDate"
          type="date"
          label={<FormattedMessage id="widget.config.endDate" />}
          mode="edit"
          onChange={(name, value) => handleData(name, value)}
          value={getDate(endDate)}
          error={typeof errors === 'object' && errors[`${getCategory('endDate')}.${'endDate'}`]}
          helperText={errors[`${getCategory('endDate')}.endDate`]}
        />
      </Col>
    )}
    </Row>

  );
};

export default LineData;
